<div class="p-fluid">
  <div class="p-field">
    <label kodit-form-label>{{label}}</label>
    <p-selectButton
      [options]="options"
      [(ngModel)]="value"
      (onOptionClick)="handleValueChanged(value)"
      style="width: 250px;"
    >
    </p-selectButton>
  </div>
</div>
