import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { OtpremnicaDto, OtpremniceClient } from '@kodit/core/data-api';
import { BaseService } from '@kodit/core/services';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OtpremnicaIzPredracunaResolver
  extends BaseService
  implements Resolve<OtpremnicaDto> {
  constructor(private _client: OtpremniceClient) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<OtpremnicaDto> | Promise<OtpremnicaDto> | OtpremnicaDto | null {
    const id = Number.parseInt(route.paramMap.get('predracunId'));
    return this._client.getOtpremnicaForPredracun(id).pipe(
      map((x) => x.data),
      catchError(this.handleError),
      finalize(() => {
        ///
      })
    );
  }
}
