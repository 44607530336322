import { Injectable } from '@angular/core';
import {
  IPdvEvidencijaZbirnaDto,
  PdvEvidencijaStatus,
  PdvPeriod,
} from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { FormControl, Validators } from '@angular/forms';
import { PdvEvidencijaZbirnaService } from './pdv-evidencija-zbirna.service';

@Injectable({
  providedIn: 'root',
})
export class PdvEvidencijaZbirnaFormService {
  /* Props */
  private _form: FormGroupTypeSafe<IPdvEvidencijaZbirnaDto>;
  private _isInitialized: boolean;

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _service: PdvEvidencijaZbirnaService
  ) {
    this._isInitialized = false;
  }

  getForm(
    model: IPdvEvidencijaZbirnaDto,
    shouldDisableControls: boolean
  ): FormGroupTypeSafe<IPdvEvidencijaZbirnaDto> {
    if (!this._isInitialized) {
      this._init(model, shouldDisableControls);
      this._isInitialized = true;
    }
    this._service.setPdvForm = this._form;
    return this._form;
  }
  get jeEdit(): boolean {
    if (!this._form) {
      return false;
    }
    return this._form!.value!.id! > 0;
  }

  private _init(
    model: IPdvEvidencijaZbirnaDto,
    shouldDisableComponents: boolean
  ) {
    this._form = this._fb.group<IPdvEvidencijaZbirnaDto>({
      broj: new FormControl(
        {
          value: model?.broj,
          disabled: shouldDisableComponents,
        },
        {
          validators: [Validators.required],
        }
      ),
      godina: new FormControl(model?.godina ?? new Date().getFullYear(), {
        validators: [Validators.required],
      }),
      prometSNaknadomOpis20: new FormControl(model?.prometSNaknadomOpis20),
      prometSNaknadomOsnovica20: new FormControl(
        model?.prometSNaknadomOsnovica20 ?? 0
      ),
      prometSNaknadomPdv20: new FormControl(model?.prometSNaknadomPdv20 ?? 0),
      prometSNaknadomUkupanIznos20: new FormControl(
        model?.prometSNaknadomUkupanIznos20 ?? 0
      ),
      prometSNaknadomOpis10: new FormControl(model?.prometSNaknadomOpis10),
      prometSNaknadomOsnovica10: new FormControl(
        model?.prometSNaknadomOsnovica10 ?? 0
      ),
      prometSNaknadomPdv10: new FormControl(model?.prometSNaknadomPdv10 ?? 0),
      prometSNaknadomUkupanIznos10: new FormControl(
        model?.prometSNaknadomUkupanIznos10 ?? 0
      ),
      prometAvansOpis20: new FormControl(model?.prometAvansOpis20),
      prometAvansOsnovica20: new FormControl(model?.prometAvansOsnovica20 ?? 0),
      prometAvansPdv20: new FormControl(model?.prometAvansPdv20 ?? 0),
      prometAvansUkupanIznos20: new FormControl(
        model?.prometAvansUkupanIznos20 ?? 0
      ),
      prometAvansOpis10: new FormControl(model?.prometAvansOpis10),
      prometAvansOsnovica10: new FormControl(model?.prometAvansOsnovica10 ?? 0),
      prometAvansPdv10: new FormControl(model?.prometAvansPdv10 ?? 0),
      prometAvansUkupanIznos10: new FormControl(
        model?.prometAvansUkupanIznos10 ?? 0
      ),
      prometBezNaknadeOpis20: new FormControl(model?.prometBezNaknadeOpis20),
      prometBezNaknadeOsnovica20: new FormControl(
        model?.prometBezNaknadeOsnovica20 ?? 0
      ),
      prometBezNaknadePdv20: new FormControl(model?.prometBezNaknadePdv20 ?? 0),
      prometBezNaknadeUkupanIznos20: new FormControl(
        model?.prometBezNaknadeUkupanIznos20 ?? 0
      ),
      prometBezNaknadeOpis10: new FormControl(model?.prometBezNaknadeOpis10),
      prometBezNaknadeOsnovica10: new FormControl(
        model?.prometBezNaknadeOsnovica10 ?? 0
      ),
      prometBezNaknadePdv10: new FormControl(model?.prometBezNaknadePdv10 ?? 0),
      prometBezNaknadeUkupanIznos10: new FormControl(
        model?.prometBezNaknadeUkupanIznos10 ?? 0
      ),
      smanjenjePdv: new FormControl(model?.smanjenjePdv ?? 0),
      uvecanjePdv: new FormControl(model?.uvecanjePdv ?? 0),
      status: new FormControl(model?.status ?? PdvEvidencijaStatus.U_PRIPREMI),
      datumPromeneStatusa: new FormControl(
        model?.datumPromeneStatusa ?? new Date(),
        {
          validators: [Validators.required],
        }
      ),
      id: new FormControl(model?.id),
      period: new FormControl(model?.period ?? PdvPeriod.JANUAR),
    });
  }

  resetForm() {
    this._service.resetForm();
    this._isInitialized = false;
    this._init({}, false);
  }
}
