import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AlertService,
  ButtonService, SertifikatFiskalnogRacunaDropdownService,
  SharedService
} from '@kodit/core/services';
import { DynamicDialogRef, FileUploadService } from '@kodit/core/shared-ui';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CreateEmptyBezbednosniElementLPFRCommand,
  FileParameter,
  FiskalniRacuniClient,
  IPoslovnaJedinicaDto,
  TipBezbednosnogElementa,
  XmlRacuna,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-sertifikat-fiskalna-upload-form',
  templateUrl: './sertifikat-fiskalna-upload-form.component.html',
  styleUrls: ['./sertifikat-fiskalna-upload-form.component.scss'],
})
export class SertifikatFiskalnaUploadFormComponent
  implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  readonly MAX_FILE_SIZE: number = 10000000;

  /** Props */
  uploadCompleted: boolean;
  file: FileParameter | undefined;
  poslovnaJedinicaId!: number;
  imaMagacina: boolean = true;
  nazivMagacina: string = '';
  tableOptions: SelectItem[] = [];
  isVPFR: boolean = true;

  /** Form */
  form: FormGroup;

  constructor(
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _fb: FormBuilder,
    private _formHelper: FormHelper,
    private _client: FiskalniRacuniClient,
    private _uploadService: FileUploadService,
    private _sharedService: SharedService,
    private _buttonService: ButtonService,
    private _sertifikatFS : SertifikatFiskalnogRacunaDropdownService
  ) {}

  ngOnInit(): void {
    this._loadDropdownOptions();
    this._initForm();
    this._uploadService.setMaxAttachmentSize = this.MAX_FILE_SIZE;

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  handleUpload(file: File) {
    this.file = { fileName: file.name, data: file };
  }

  private save() {
    if (!this.form.valid || !this.poslovnaJedinicaId) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    const command = this.form.value;
    if (this.isVPFR) {
      this._sharedService.displayLoadingScreen(
        true,
        'Učitavanje sertifikata u toku...'
      );

      this._sertifikatFS.sertifikatJid = command.jid;

      this._subs.add(
        this._client
          .importSertifikatFiskalneKase(
            command.naziv,
            command.jid,
            command.lozinka,
            command.pak,
            this.poslovnaJedinicaId,
            this.file
          )
          .subscribe((result) => {
            if (result.succeeded) {
              this._alertService.addSuccessMsg(
                'Uspešno ste uvezli sertifikat!'
              );
            } else {
              this._alertService.addFailedMsg('Sertifikat nije uvezen!');
            }
            this._dialogRef.close(result);
          })
      );
      return;
    }

    this._sharedService.displayLoadingScreen(
      true,
      'Kreiranje bezbednosnog elementa u toku...'
    );
    let req = command as CreateEmptyBezbednosniElementLPFRCommand;
    req.poslovnaJedinicaId = this.poslovnaJedinicaId;
    this._subs.add(
      this._client
        .createEmptyLPFR(
          req
        )
        .subscribe((result) => {
          if (result.succeeded) {
            this._alertService.addSuccessMsg(result.data);
            this._dialogRef.close(result);
            return;
          }
          this._alertService.addFailedMsg(result.messages![0]);
        })
    );
  }

  private _initForm() {
    this.form = this._fb.group({
      id: new FormControl(0, {
        validators: [Validators.nullValidator],
      }),
      naziv: new FormControl('', {
        validators: [Validators.required],
      }),
      jid: new FormControl('', {
        validators: [Validators.required],
      }),
      lozinka: new FormControl('', {
        validators: [Validators.required],
      }),
      pak: new FormControl('', {
        validators: [Validators.required],
      }),
    });
  }

  handlePoslovnaJedinicaChanged(poslovnaJedinica: IPoslovnaJedinicaDto) {
    this.poslovnaJedinicaId = poslovnaJedinica.id!;
    if (poslovnaJedinica.magacini?.length === 0) {
      this.imaMagacina = false;
      this._buttonService.disableButton();
      return;
    }

    poslovnaJedinica.magacini?.forEach((x) => {
      this.nazivMagacina += x.naziv + ',';
    });

    this.imaMagacina = true;
    this._buttonService.enableButton();
  }

  private _loadDropdownOptions() {
    this.tableOptions.push({
      value: TipBezbednosnogElementa.VPFR,
      label: 'VPFR',
    });

    this.tableOptions.push({
      value: TipBezbednosnogElementa.LPFR,
      label: 'LPFR',
    });
  }

  private _clearLPFRValidators() {
    this.form.controls.naziv.clearValidators();
    this.form.controls.lozinka.clearValidators();
    this.form.controls.pak.clearValidators();
    this.form.controls.naziv.updateValueAndValidity();
    this.form.controls.lozinka.updateValueAndValidity();
    this.form.controls.pak.updateValueAndValidity();
    console.log("izbrisani")
  }

  private _setVPFRValidators() {
    this.form.controls.naziv.setValidators([Validators.required]);
    this.form.controls.lozinka.setValidators([Validators.required]);
    this.form.controls.pak.setValidators([Validators.required]);
    this.form.controls.naziv.updateValueAndValidity();
    this.form.controls.lozinka.updateValueAndValidity();
    this.form.controls.pak.updateValueAndValidity();
  }

  optionChanged(event: any) {
    this.isVPFR = event.value === TipBezbednosnogElementa.VPFR;
    if(this.isVPFR){
      this._setVPFRValidators();
      return;
    }

    this._clearLPFRValidators();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
