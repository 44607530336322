import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { areArraysEqual } from '@kodit/core/shared';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-mesec-dropdown',
  templateUrl: './mesec-dropdown.component.html',
  styleUrls: ['./mesec-dropdown.component.scss'],
})
export class MesecDropdownComponent implements OnInit, OnChanges {
  /** Props */
  items: SelectItem[];
  private allItems = [
    { label: 'Januar', value: 1 },
    { label: 'Februar', value: 2 },
    { label: 'Mart', value: 3 },
    { label: 'April', value: 4 },
    { label: 'Maj', value: 5 },
    { label: 'Jun', value: 6 },
    { label: 'Jul', value: 7 },
    { label: 'Avgust', value: 8 },
    { label: 'Septembar', value: 9 },
    { label: 'Oktobar', value: 10 },
    { label: 'Novembar', value: 11 },
    { label: 'Decembar', value: 12 },
  ];

  /** I/O */
  @Input() mesecCtrl: AbstractControl;
  @Input() availableFilter: number[];
  @Output() onItemChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    this.setItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !areArraysEqual(
        changes.availableFilter?.currentValue,
        changes.availableFilter?.previousValue
      )
    ) {
      this.setItems();
    }
  }

  private setItems() {
    this.items = [];
    if (this.availableFilter) {
      this.items = this.allItems.filter((x) =>
        this.availableFilter.includes(x.value)
      );
    } else {
      this.items = [...this.allItems];
    }
    this.items.splice(0, 0, { label: 'Svi meseci', value: -1 });
  }

  handleOnItemChange(event: any) {
    if (this.onItemChange) {
      this.onItemChange.emit(event);
    }
  }
}
