<form (keydown)="keyDownFunction($event)" [formGroup]="form" *ngIf="form">
  <div class="p-fluid">
    <!-- Naziv -->
    <kodit-text-input-field
      [formCtrl]="form.controls.naziv!"
      formCtrlId="naziv"
      formCtrlLabel="Naziv"
    ></kodit-text-input-field>

    <kodit-field fieldLabel="Kategorija dokumentarnog materijala">
      <kodit-dokumentarna-kategorija-autocomplete
        [formCtrl]="form.controls.kategorijaId"
        [selectedKategorija]="dialogConfig.data.kategorijaStr"
      ></kodit-dokumentarna-kategorija-autocomplete>
    </kodit-field>

    <kodit-field fieldLabel="Brojač">
      <kodit-pravilo-za-broj-delovodnog-dokumenta-dropdown
        formCtrlId="pravilo-za-broj"
        [formCtrl]="form.controls.praviloZaBrojId!"
      >
      </kodit-pravilo-za-broj-delovodnog-dokumenta-dropdown>
    </kodit-field>
  </div>
</form>

<div class="loading-card" *ngIf="!form">
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
</div>
