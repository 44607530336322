<p-table [value]="data" responsiveLayout="scroll">
  <ng-template pTemplate="header">
    <tr>
      <!-- <th style="width: 1rem"></th> -->
      <th>{{ naziv }}</th>
      <th class="p-text-right">Br faktura</th>
      <th class="p-text-right">Iznos</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-stranka let-idx="rowIndex">
    <tr>
      <td class="p-d-flex p-ai-center gap-6">
        <div
          [ngStyle]="{ 'background-color': stranka.boja }"
          class="row-color"
        ></div>
        <a
          class="p-text-left p-text-nowrap p-text-truncate row-link"
          style="width: 20rem"
          (click)="handleStrankaClick(idx)"
        >
          {{ stranka.naziv }}
        </a>
      </td>
      <td class="p-text-right">{{ stranka.brojFaktura }}</td>
      <td class="p-text-right">
        {{ stranka.ukupanIznos | currencyNumber }}
      </td>
    </tr>
  </ng-template>
</p-table>
