import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { RegisterService } from './register.service';
import { FormGroup } from '@angular/forms';
import { MixpanelService } from '@kodit/core/services';

@Component({
  selector: 'kodit-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent implements OnInit, OnDestroy {
  /** Subs */
  private _loaderSub: Subscription = new Subscription();

  /** Props */
  steps: MenuItem[];
  showLoader: boolean;

  form: FormGroup;

  constructor(
    public _registerService: RegisterService,
    private _router: Router,
    private _mixpanelService : MixpanelService
  ) {
    this.form = new FormGroup({});
  }

  ngOnInit(): void {
    this._mixpanelService.registrationFormEntered();

    this.steps = [
      { label: 'Podaci kompanije', routerLink: 'podaci-kompanije' },
      { label: 'Podaci za prijavu', routerLink: 'podaci-za-prijavu' },
      { label: 'Vaši podaci', routerLink: 'podaci-korisnika' },
      { label: 'Potvrda telefona', routerLink: 'potvrda-telefona' },
    ];

    this.form = this._registerService.getForm;
    this._loaderSub = this._registerService.getShowLoader.subscribe((value) => {
      this.showLoader = value;
    });
  }

  onLogin() {
    this._router.navigate(['/autentikacija', 'prijava']).then();
  }

  openSite(){
    window.open('https://ekompanija.com/cenovnik','_blank');
  }

  onPrijavaProblema() {
    alert('ToDo...');
  }

  ngOnDestroy() {
    this._loaderSub.unsubscribe();
    this._registerService.resetForm();
  }
}
