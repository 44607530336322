<div
  class="p-d-flex p-flex-column p-flex-md-row p-ai-center p-fluid p-flex-wrap header-box"
>
  <div class="p-field max-width">
    <label for="pretraga-oznake" kodit-form-label>Oznaka</label>
    <span class="p-input-icon-left pretraga-box">
      <i class="fas fa-search"></i>
      <p-autoComplete
        inputId="pretraga-oznake"
        #oznakaInput
        [suggestions]="oznakeSuggestions"
        field="naziv"
        placeholder="Naziv oznake..."
        pTooltip="Unesi naziv oznake za pretragu"
        (completeMethod)="searchOznake($event)"
        (onSelect)="onOznakaSelect($event)"
        appendTo="body"
      >
        <ng-template let-oznaka pTemplate="item">
          <div>
            {{ oznaka.punNaziv ? oznaka.punNaziv : oznaka.naziv }}
          </div>
        </ng-template>
      </p-autoComplete>
    </span>
  </div>
</div>

 <div class="p-d-flex p-flex-wrap kodit-chip-container" card-body>
    <ng-container>
      <div
        class="p-mr-2 p-mb-2 my-p-chip"
        *ngFor="let oznaka of oznakeToAdd; index as i"
      >
        <ng-container>
          <span class="pi pi-tags my-p-chip-icon"></span>
          <div>
            <div class="my-p-chip-text">
              {{ oznaka.punNaziv ? oznaka.punNaziv : oznaka.naziv }}
            </div>
            <div class="my-p-chip-text" *ngIf="oznaka.kolicina">
              {{ '[' + oznaka.nazivStavke + ']' + ' x ' + [oznaka.kolicina] }}
            </div>
            <!-- <div class="my-p-chip-text" *ngIf="!oznaka.kolicina">
              [Ceo račun]
            </div> -->
          </div>
          <span
            class="far fa-times-circle my-p-chip-remove-icon"
            pTooltip="Ukloni oznaku iz računa"
            tooltipPosition="bottom"
            (click)="onRemoveOznaka(i)"
          ></span>
        </ng-container>
      </div>
    </ng-container>
  </div>
