<form autocomplete="off" [formGroup]="form">
  <input autocomplete="false" name="hidden" type="text" style="display: none" />

  <div class="">
    <div class="p-fluid p-col-12">
      <div class="p-field p-grid">
        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Naziv:</label>
        <div class="p-col-12 p-md-10">
          <kodit-text-input [formCtrl]="form.controls.naziv"></kodit-text-input>
        </div>
      </div>
    </div>

    <div class="p-fluid p-col-12">
      <div class="p-field p-grid">
        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Stranke:</label>
        <div class="p-col-12 p-md-10">
          <kodit-stranka-muliselect
            [strankeFormArray]="form.controls.stranke"
          ></kodit-stranka-muliselect>
        </div>
      </div>
    </div>
  </div>

  <div class="p-fluid">
    <div class="p-field-checkbox p-col-12">
      <label class="p-md-2" style="margin-left: 0; padding-left: 0"
        >Opcije unosa novih artikala</label
      >
      <p-radioButton
        [formControl]="form.controls.isSingleEntry"
        name="groupname"
        [value]="false"
        (onClick)="onCheckKopiraj(false)"
        label="Pojedinačni unos artikla"
        class="p-mr-5"
      ></p-radioButton>

      <p-radioButton
        [formControl]="form.controls.isSingleEntry"
        name="groupname"
        [value]="true"
        (onClick)="onCheckKopiraj(true)"
        label="Kopiranje artikala iz postojećeg cenovnika"
      ></p-radioButton>
    </div>

    <div *ngIf="!form.value.isSingleEntry">
      <div class="p-field p-grid p-col-12" *ngIf="!form.value.isSingleEntry">
        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Roba / Usluge</label>
        <div class="p-col-12 p-md-10">
          <kodit-cenovnik-stavka-autocomplete
            (onStavkaCenovnikaChange)="onStavkaCenovnikaChange($event)"
          >
          </kodit-cenovnik-stavka-autocomplete>
        </div>
      </div>

      <div class="p-field p-col-6 p-sm-12 p-md-12">
        <kodit-cenovnik-stavke-table
          tableHeader="Roba / Usluge novog cenovnika"
          multiActionLabel="Definiši cene selektovanih artikala"
          multiActionIcon="fas fa-hand-holding-usd"
        ></kodit-cenovnik-stavke-table>
      </div>
    </div>

    <div *ngIf="form.value.isSingleEntry">
      <div class="p-grid p-col-12">
        <div class="p-field p-col-6">
          <label kodit-form-label>Odaberi cenovnik</label>
          <kodit-cenovnik-dropdown
            (onItemChange)="onCenovnikChange($event)"
          ></kodit-cenovnik-dropdown>
        </div>

        <!-- <div>
          <button
            icon="far fa-copy"
            pButton
            type="button"
            label="Dodaj sve u novi cenovnik"
            class="p-mr-2 p-button-text p-col-2"
            (click)="ontest()"
          ></button>
        </div> -->
      </div>

      <div class="p-grid p-sm-12 p-md-12 p-lg-12">
        <div class="p-field p-col-6 p-sm-12 p-md-6 p-lg-6">
          <kodit-cenovnik-stavke-table
            tableHeader="Roba / Usluge"
            [isLevaLista]="true"
            multiActionLabel="Kopiraj selektovane artikle"
            multiActionIcon="far fa-copy"
          >
          </kodit-cenovnik-stavke-table>
        </div>

        <div class="p-field p-col-6 p-sm-12 p-md-6 p-lg-6">
          <kodit-cenovnik-stavke-table
            [cenovnikId]="baseCenovnikId"
            tableHeader="Roba / Usluge novog cenovnika"
            multiActionLabel="Definiši cene selektovanih artikala"
            multiActionIcon="fas fa-hand-holding-usd"
          ></kodit-cenovnik-stavke-table>
        </div>
      </div>
    </div>
  </div>
</form>
