import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CreateDatotekaCommand,
  DmsClient,
  FileParameter,
  FolderListResponse,
  ResultOfListOfFolderListResponse,
  VrstaDatoteke,
} from '@kodit/core/data-api';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import { DatotekaForm, DatotekaService } from '../../datoteka-service';
import { SelectItem } from 'primeng/api';
import { AbstractControl } from '@angular/forms';
import { AlertService } from '@kodit/core/services';
import { FormHelper } from '@kodit/core/form-definitions';

@Component({
  selector: 'kodit-unos-datoteke-form',
  templateUrl: './unos-datoteke-form.component.html',
  styleUrls: ['./unos-datoteke-form.component.scss'],
})
export class UnosDatotekeFormComponent implements OnInit, OnDestroy {
  private _subs = new Subscription();

  /** Props */
  ctrlOptions: SelectItem[] | undefined = [];
  inputFieldCtrl: AbstractControl | undefined;
  selectedFolder: FolderListResponse | undefined;
  form!: DatotekaForm | null;
  jeUnosFoldera: boolean | undefined;
  jeUnosDokumenta: boolean | undefined;
  enableParentSelection: boolean | undefined;
  uploadCompleted: boolean = false;
  allDocumentTypes: string =
    '.pdf, .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .csv, .xml';

  constructor(
    private _dialogConfig: DynamicDialogConfig,
    private _dialogRef: DynamicDialogRef,
    private _datotekaService: DatotekaService,
    private _client: DmsClient,
    private _alertService: AlertService,
    private _formHelper: FormHelper
  ) {}

  ngOnInit(): void {
    this.jeUnosFoldera =
      this._dialogConfig.data.vrstaDatoteke === VrstaDatoteke.FOLDER;
    this.jeUnosDokumenta =
      this._dialogConfig.data.vrstaDatoteke === VrstaDatoteke.DOKUMENT;
    this.enableParentSelection = this._dialogConfig.data.enableParentSelection;
    this._initializeForm();
    this._dialogConfig.hideSubmit =
      this._dialogConfig.data.vrstaDatoteke === VrstaDatoteke.DOKUMENT;
    this.loadFoldereDropdown();
  }

  folderSelected() {}

  onUpload(files: File[]) {
    var fileParameters: FileParameter[] = files.map((file: File) => {
      return {
        fileName: file.name,
        data: file,
      };
    });

    if (this._dialogConfig.data.parentId) {
      this.form?.controls.parentId?.setValue(this._dialogConfig.data.parentId);
    }

    this._subs.add(
      this._client
        .uploadDokumente(fileParameters, this.form?.value.parentId)
        .subscribe((res) => {
          !res.succeeded
            ? this._alertService.addWarnMsg(res.messages![0])
            : this._alertService.addSuccessMsg(res.data!);

          this._datotekaService.setChangeOccured = true;
          this._dialogRef.close(true);
        })
    );
  }

  private loadFoldereDropdown() {
    this._subs.add(
      this._client
        .getFolderLsit()
        .subscribe((res: ResultOfListOfFolderListResponse) => {
          if (res) {
            this.ctrlOptions = res.data?.map((folder) => ({
              value: folder.id,
              label: folder.naziv,
            }));
          }
        })
    );

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );
  }

  private _save() {
    if (!this.form?.valid) {
      this._formHelper.invalidateForm(this.form!);
      return;
    }

    if (this._dialogConfig.data.parentId) {
      this.form.controls.parentId?.setValue(this._dialogConfig.data.parentId);
    }

    this._subs.add(
      this._client
        .create(this.form?.value as CreateDatotekaCommand)
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.messages![0]);
            this._datotekaService.setChangeOccured = true;
            this._dialogRef.close(true);
          } else {
            this._alertService.addFailedMsg(res.messages![0]);
          }
        })
    );
  }

  private _initializeForm() {
    this.form = this._datotekaService.getForm(
      this._dialogConfig.data.vrstaDatoteke
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    this._datotekaService.resetForm();
  }
}
