<div
  class="akcije-fiskalnog-racuna-card p-d-flex p-jc-between"
  [ngClass]="applyClass"
  (click)="action()"
>
  <div class="p-d-flex justify-content">
    <div class="k-icon">
      <img
        src=" {{ cardIcon }}"
        width="20"
        height="20"
        alt="user-image"
      />
    </div>
    <div class="p-d-flex p-flex-column p-ml-2">
      <div>
        <h5 class="_header">
          {{ cardTitle }}
        </h5>
      </div>
      <div class="p-text-nowrap p-text-truncate _subtitle">
        <span
          class="_value"
          [ngClass]="cardSubtitle === 'Izaberi' ? '_notselected' : '_selected'"
          >{{ cardSubtitle }}</span
        >
      </div>
    </div>
  </div>
  <div class="p-d-flex align-items-end arrows">
    <img
      src="assets/icons/arrow-up-down-fiskalna.svg"
      width="12"
      height="12"
      alt="user-image"
    />
  </div>
</div>
