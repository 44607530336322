import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { KorisnikTenantAccessDto } from '@kodit/core/data-api';
import { AppLayoutComponent } from '../../layouts/app-layout/app-layout.component';

const colors = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#27ae60',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#0F54A6',
  '#e67e22',
  '#e74c3c',
  '#95a5a6',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#bdc3c7',
  '#7f8c8d',
];

const TENANT_NAME_LEN = 21;

@Component({
  selector: 'kodit-tenant-name',
  templateUrl: './tenant-name.component.html',
  styleUrls: ['./tenant-name.component.scss'],
})
export class TenantNameComponent implements OnInit, AfterViewInit {
  companyImageStyle: any = {};
  tenantInitials: string;
  tenantName: string;
  tenantNameTooltip: string;

  /** I/O */
  @Input() currentTenant: KorisnikTenantAccessDto;

  constructor(public appLayout: AppLayoutComponent) {}

  ngOnInit(): void {
    const tenantNameWords = this.currentTenant.tenantName.split(' ');
    if (tenantNameWords.length > 1) {
      this.tenantInitials =
        tenantNameWords[0].charAt(0).toUpperCase() +
        tenantNameWords[1].charAt(0).toUpperCase();
    } else {
      this.tenantInitials =
        tenantNameWords[0].charAt(0).toUpperCase() +
        tenantNameWords[0].charAt(1).toUpperCase();
    }
    if (this.currentTenant.tenantName.length > TENANT_NAME_LEN) {
      this.tenantName =
        this.currentTenant.tenantName.substring(0, TENANT_NAME_LEN) + '...';
      this.tenantNameTooltip = this.currentTenant.tenantName;
    } else {
      this.tenantName = this.currentTenant.tenantName;
    }
  }

  ngAfterViewInit(): void {
    this.prepareCompanyInfo();
  }

  private prepareCompanyInfo() {
    const height = 35;
    const charIndex = this.tenantInitials.charCodeAt(0) - 65;
    const colorIndex = charIndex % 19;

    this.companyImageStyle = {
      'background-color': colors[colorIndex],
      width: `${height}px`,
      height: `${height}px`,
      font: `${height / 2}px Arial`,
      color: '#FFF',
      'text-align': 'center',
      'line-height': `${height}px`,
      'margin-right': '0.5rem',
    };

    //document.getElementById('company-initials').innerHTML = this.tenantInitials;
  }
}
