import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CreateOznakaCommand,
  ICreateOznakaCommand,
  OznakeClient, TipEntiteta,
  UpdateOznakaCommand,
} from '@kodit/core/data-api';
import { FormHelper, OznakaFormService } from '@kodit/core/form-definitions';
import { AlertService, Mode } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-oznaka-form',
  templateUrl: './oznaka-form.component.html',
  styleUrls: ['./oznaka-form.component.scss'],
})
export class OznakaFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Form */
  form: FormGroupTypeSafe<ICreateOznakaCommand>;
  mode: Mode = Mode.NEW;

  /** Props */
  excludeTipoveEntiteta: TipEntiteta[] = [];
  shouldDisableParentEdit : boolean = false;

  constructor(
    private _oznakaFS: OznakaFormService,
    private _formHelper: FormHelper,
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _client: OznakeClient
  ) {
    this._excludeTipoveEntitetaFromSearch();
  }

  ngOnInit(): void {
    this.form = this._oznakaFS.GetOznakaRequestFormGroup();

    this.mode = this._dialogConfig.data.mode;

    if (this._dialogConfig.data.id && this.mode === Mode.NEW) {
      this._loadForUnos(this._dialogConfig.data.id);
      this.shouldDisableParentEdit = true;
    }
    else if(this._dialogConfig.data.id){
      this._loadForEdit(this._dialogConfig.data.id);
    }

    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this._save();
    }
  }

  onClear(){
    this.form.controls.parent.patchValue(this._oznakaFS.GetOznakaFormGroup().value);
    this.form.controls.parent.updateValueAndValidity();
  }

  private _save() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    if (this.mode === Mode.EDIT) {
      this._subs.add(
        this._client
          .update(this.form.value.id, this.form.value as UpdateOznakaCommand)
          .subscribe(
            (result) => {
              this._alertService.addSuccessMsg(result.messages[0]);
              this._dialogRef.close(result);
            },
            (error) => {
              this._subs.add(
                error.subscribe((x: string) => {
                  this._alertService.addFailedMsg(x);
                })
              );
            }
          )
      );
    } else {
      this._subs.add(
        this._client.create(this.form.value as CreateOznakaCommand).subscribe(
          (result) => {
            this._alertService.addSuccessMsg(result.messages[0]);
            this._dialogRef.close(result);
          },
          (error) => {
            this._subs.add(
              error.subscribe((x: string) => {
                this._alertService.addFailedMsg(x);
              })
            );
          }
        )
      );
    }
  }

  private _loadForUnos(id: number) {
    this._subs.add(
      this._client.getOznakaById(id).subscribe((res)=>{
        this.form.controls.parent.patchValue(res.data);
      })
    );
  }

  private _loadForEdit(id: number) {
    this._subs.add(
      this._client.getForRequest(id).subscribe(
        (model) => {
          this.form.patchValue(model.data);
          this.form.controls.parent.patchValue(model.data.parent);
        },
        (error) => {
          this._subs.add(
            error.subscribe((x: string) => {
              this._alertService.addFailedMsg(x);
            })
          );
        }
      )
    );
  }

  private _excludeTipoveEntitetaFromSearch(){
    this.excludeTipoveEntiteta = [
      TipEntiteta.PREDMET_STAVKE,
      TipEntiteta.ROBA,
      TipEntiteta.USLUGA,
      TipEntiteta.VOZILO,
      TipEntiteta.RACUN,
      TipEntiteta.OTPREMNICA,
      TipEntiteta.DOKUMENT_O_SMANJENJU,
      TipEntiteta.DOKUMENT_O_POVECANJU,
      TipEntiteta.IZLAZNA_FAKTURA,
      TipEntiteta.ULAZNA_FAKTURA,
      TipEntiteta.PREDRACUN,
      TipEntiteta.AVANSNI_RACUN,
      TipEntiteta.RADNI_NALOG,
      TipEntiteta.STRANKA,
      TipEntiteta.PRAVNO_LICE,
      TipEntiteta.FIZICKO_LICE,
      TipEntiteta.MAGACIN,
      TipEntiteta.VOZILO_OSIGURANJE,
      TipEntiteta.POSLOVNA_JEDINICA
    ];
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
