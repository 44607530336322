<input
  *ngIf="!isDisabled"
  #textInput
  [id]="formCtrlId"
  kodit-select-all-on-focus
  pInputText
  [formControl]="formCtrl"
  [type]="formCtrlType"
  [placeholder]="ctrlPlaceholder"
  autocomplete="off"
  (keyup)="onKeyUp($event)"
  (blur)="inputLeft($event)"
  [ngClass]="
    (formCtrl.dirty || formCtrl.touched) && !formCtrl.valid
      ? 'p-invalid'
      : formCtrlClass
  "
  [styleClass]="
    (formCtrl.dirty || formCtrl.touched) && !formCtrl.valid ? 'p-invalid' : ''
  "
/>
<input
  *ngIf="isDisabled"
  #textInput
  [id]="formCtrlId"
  kodit-select-all-on-focus
  pInputText
  [formControl]="formCtrl"
  [type]="formCtrlType"
  [placeholder]="ctrlPlaceholder"
  autocomplete="off"
  (keyup)="onKeyUp($event)"
  (blur)="inputLeft($event)"
  disabled
/>
<kodit-error-info-max-length
  [formCtrl]="formCtrl"
></kodit-error-info-max-length>
<kodit-error-info-min-length
  [formCtrl]="formCtrl"
></kodit-error-info-min-length>
<kodit-error-info-required [formCtrl]="formCtrl"></kodit-error-info-required>
<kodit-error-info-email [formCtrl]="formCtrl"></kodit-error-info-email>
