import { Injectable } from '@angular/core';
import { PdvOpseg } from '@kodit/core/data-api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PdvEvidencijaService {
  private readonly pdvOpsegInit = null;

  pdvOpseg$ = new BehaviorSubject<PdvOpseg | null>(this.pdvOpsegInit);

  set setOpseg(value: PdvOpseg | null) {
    this.pdvOpseg$.next(value);
  }

  get getOpseg() {
    return this.pdvOpseg$.asObservable();
  }

  resetOpseg() {
    this.setOpseg = this.pdvOpsegInit;
  }
}
