<div [formGroup]="form" class="p-d-flex p-flex-row p-fluid">
  <div class="flex-1-1 p-mr-4">
    <!-- Kupac/Dobavljac -->
    <kodit-field fieldLabel="Stranka" fieldLabelFor="naziv">
      <kodit-text-input
        class="show"
        [formCtrl]="form.controls.kupac"
        formCtrlId="naziv"
      ></kodit-text-input>
    </kodit-field>
    <!-- Status transakcije izvoda -->
    <kodit-field
      [fieldLabel]="jeUplata ? 'Status uplate' : 'Status isplate'"
      fieldLabelFor="statusiTransakcije"
    >
      <kodit-status-transakcije-multiselect
        [ctrlPlaceholder]="jeUplata ? 'Status uplate' : 'Status isplate'"
        formCtrlId="statusiTransakcije"
        [statusFormArray]="form.controls.statusi"
        (selectedStatusiRacuna)="handleSelectedStatusi($event)"
      ></kodit-status-transakcije-multiselect>
    </kodit-field>
  </div>
  <div class="flex-1-1 p-mr-4">
    <!-- Svrha uplate -->
    <kodit-field fieldLabel="Svrha uplate" fieldLabelFor="svrhaUplate">
      <kodit-text-input
        class="show"
        [formCtrl]="form.controls.svrhaUplate"
        formCtrlId="svrhaUplate"
      ></kodit-text-input>
    </kodit-field>
    <!-- Datum izdavanja od -->
    <kodit-field
      fieldLabel="Datum izdavanja od"
      fieldLabelFor="datumIzdavanjaOd"
    >
      <kodit-calendar
        [formCtrl]="form.controls.datumIzdavanjaOd"
        formCtrlId="datumIzdavanjaOd"
      ></kodit-calendar>
    </kodit-field>
  </div>
  <div class="flex-1-1 p-mr-4">
    <!-- Poziv na broj -->
    <kodit-field fieldLabel="Poziv na broj" fieldLabelFor="pozivNaBroj">
      <kodit-text-input
        class="show"
        [formCtrl]="form.controls.pozivNaBroj"
        formCtrlId="pozivNaBroj"
      ></kodit-text-input>
    </kodit-field>
     <!-- Datum izdavanja do -->
    <kodit-field
      fieldLabel="Datum izdavanja do"
      fieldLabelFor="datumIzdavanjaDp"
    >
      <kodit-calendar
        [formCtrl]="form.controls.datumIzdavanjaDo"
        formCtrlId="datumIzdavanjaDo"
      ></kodit-calendar>
    </kodit-field>
  </div>
  <div class="flex-1-1 p-mr-4">
    <!-- Mesec -->
    <kodit-field fieldLabel="Mesec">
      <kodit-mesec-dropdown
        [mesecCtrl]="form.controls.mesec"
      ></kodit-mesec-dropdown>
    </kodit-field>
  </div>
</div>
