<p-sidebar
  styleClass="rating-component"
  [(visible)]="sholudDisplayRating"
  position="bottom"
  [baseZIndex]="9999999"
  [modal]="false"
>
  <span class="_title"
    >Koliko je verovatno da ćete nas preporučiti prijatelju ili kolegi?</span
  >

  <div class="button-wrapper">
    <div class="button-container">
      <ng-container *ngFor="let num of ratings">
        <kodit-button
          class="numBtn"
          btnLabel="{{ num }}"
          (btnClicked)="setRating(+num)"
        ></kodit-button>
      </ng-container>
    </div>
  </div>
  <div class="_description">
    <div>Nije verovatno</div>
    <div>Vrlo verovatno</div>
  </div>
</p-sidebar>
