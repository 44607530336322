import { Component, Input, OnInit, Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IMailReceiverDto,
  KontaktTableDto,
  RacunSaldoDto,
} from '@kodit/core/data-api';
import { SummaryService } from '@kodit/core/services';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { SlanjePodsetnikaFormComponent } from 'libs/core/moduli/stranka/src/lib/slanje-podsetnika-form/slanje-podsetnika-form.component';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-racun-van-valute-summary',
  templateUrl: './racun-van-valute-summary.component.html',
  styleUrls: ['./racun-van-valute-summary.component.scss'],
})
export class RacunVanValuteSummaryComponent implements OnInit {
  private _subs: Subscription = new Subscription();

  /** I/O */
  @Input() displayPodsetnikZaPlacanje: boolean = false;

  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);
  kontaktTable: KontaktTableDto[] = [];
  strankaId: number;
  racuniSaldo: RacunSaldoDto;

  constructor(
    private _route: ActivatedRoute,
    private _service: SummaryService,
    private _dialogService: DynamicDialogService
  ) {
    this.racuniSaldo = new RacunSaldoDto();
  }

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['strankaId']) {
        this.strankaId = Number.parseInt(params['strankaId']);
      }
    });

    this._subs.add(
      this._service.getRacunSaldo.subscribe((data) => {
        this.racuniSaldo = data;
      })
    );
  }

  onPosaljiPodsetnik() {
    this.dialogConfig.header = 'Slanje podsetnika';
    let sendTo: IMailReceiverDto[];
    let sendCc: IMailReceiverDto[];
    if (this.kontaktTable.length > 0) {
      sendTo = [
        {
          email: this.kontaktTable[0]?.email,
          name: this.kontaktTable[0]?.naziv,
        },
      ];

      sendCc = this.kontaktTable
        .filter((k, i) => i > 0)
        .map((k, i) => {
          return {
            email: k.email,
            name: k.naziv,
          };
        });
    }
    this.dialogConfig.data = {
      strankaId: this.strankaId,
      sendTo: sendTo,
      sendCc: sendCc,
    };
    this.dialogConfig.customSubmitButton = {
      icon: 'fas fa-paper-plane',
      label: 'Pošalji',
    };
    this.openDialog(SlanjePodsetnikaFormComponent);
  }

  openDialog(formType: Type<SlanjePodsetnikaFormComponent>): void {
    const ref = this._dialogService.open(formType, this.dialogConfig);
    this._subs.add(
      ref.onClose.subscribe((dto: any) => {
        if (dto) {
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
