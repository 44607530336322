import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IdentityClient } from '@kodit/core/data-api';
import { Message } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent implements OnInit, OnDestroy {
  private _subs = new Subscription();
  private _userId: string;
  private _code: string;
  private _tenantKey: string;
  showMessage: boolean = false;
  showError: boolean = false;
  messages: Message[] = [];

  constructor(
    private _client: IdentityClient,
    private route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this._userId = params.userId;
      this._code = params.code;
      this._tenantKey = params.tenantKey;
    });
    this._confirmEmail();
  }

  private _confirmEmail() {
    this.showMessage = false;
    this.showError = false;
    this.messages = [];
    this._subs.add(
      this._client
        .confirmEmail(this._userId, this._code, this._tenantKey)
        .subscribe(
          (res) => {
            res;
            this.messages.push({
              severity: 'success',
              summary: 'Obaveštenje',
              detail: res.messages[0],
              closable: false,
            });
            this.showMessage = true;
          },
          (error) => {
            this._subs.add(
              error.subscribe((e: any) => {
                this.messages.push({
                  severity: 'error',
                  summary: 'Greška',
                  detail: e,
                  closable: false,
                });
                this.showError = true;
              })
            );
          }
        )
    );
  }

  onLogin() {
    this._router.navigate(['/autentikacija', 'prijava']);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
