<form [formGroup]="fizickoLiceForm" (keydown)="keyDownFunction($event)">
  <kodit-divider dividerLabel="Osnovne informacije"></kodit-divider>
  <div class="p-fluid">
    <!-- Ime -->
    <div class="p-field">
      <label for="fizicko-lice-ime" kodit-form-label>Ime</label>
      <kodit-text-input
        [formCtrl]="fizickoLiceForm.controls.ime"
      ></kodit-text-input>
    </div>

    <!-- Prezime -->
    <div class="p-field">
      <label for="fizicko-lice-prezime" kodit-form-label>Prezime</label>
      <kodit-text-input
        [formCtrl]="fizickoLiceForm.controls.prezime"
      ></kodit-text-input>
    </div>

    <!-- JMBG -->
    <kodit-jmbg-input-mask-field [formCtrl]="fizickoLiceForm.controls.jmbg">
    </kodit-jmbg-input-mask-field>

    <!-- Rokovi placanja za racune -->
    <kodit-rok-placanja-input-numbers
      [rokZaIzlazneCtrl]="fizickoLiceForm.controls.rokPlacanjaZaIzlazne"
      [rokZaUlazneCtrl]="fizickoLiceForm.controls.rokPlacanjaZaUlazne"
    ></kodit-rok-placanja-input-numbers>

    <!-- Datum rodenja -->
    <div class="p-field">
      <label for="fizicko-lice-datumRodjenja" kodit-form-label
        >Datum Rođenja</label
      >
      <span class="p-input-icon-right date-box">
        <i class="far fa-calendar-alt"></i>
        <p-calendar
          inputId="fizicko-lice-datumRodjenja"
          formControlName="datumRodjenja"
          dateFormat="dd.mm.yy"
          [ngClass]="
            fizickoLiceForm.controls.datumRodjenja.touched &&
            !fizickoLiceForm.controls.datumRodjenja.valid
              ? 'p-invalid'
              : ''
          "
        ></p-calendar>
      </span>
    </div>

    <!-- Pol -->
    <div class="p-field">
      <label for="fizicko-lice-pol" kodit-form-label>Pol</label>

      <kodit-dropdown
        formCtrlId="fizicko-lice-pol"
        [options]="polItems"
        ctrlTooltip="Izaberite pol"
        [formCtrl]="fizickoLiceForm.controls.pol"
      ></kodit-dropdown>
    </div>

    <!-- Valuta poslovanja -->
    <kodit-field fieldLabel="Valuta poslovanja">
      <kodit-valuta-dropdown
        [valutaCtrl]="fizickoLiceForm.controls.valutaPoslovanjaId"
      >
      </kodit-valuta-dropdown>
    </kodit-field>

    <!-- Mobilni telefon -->
    <div class="p-field">
      <label for="mobilniTelefon" kodit-form-label>Mobilni telefon</label>
      <kodit-telefon-input
        [formCtrl]="fizickoLiceForm.controls.mobilniTelefon"
      ></kodit-telefon-input>
    </div>

    <!-- Napomena -->
    <div class="p-field">
      <label for="fizicko-lice-napomena" kodit-form-label>Napomena</label>
      <textarea
        id="fizicko-lice-napomena"
        rows="5"
        pInputText
        autoResize="autoResize"
        formControlName="napomena"
      ></textarea>
      <kodit-error-info-max-length
        [formCtrl]="fizickoLiceForm.controls.napomena"
      ></kodit-error-info-max-length>
      <kodit-error-info-required
        [formCtrl]="fizickoLiceForm.controls.napomena"
      ></kodit-error-info-required>
    </div>

    <!-- IsActive -->
    <kodit-checkbox
      *ngIf="mode === 2"
      labelText="Aktivno lice"
      [formCtrl]="fizickoLiceForm.controls.isActive"
    ></kodit-checkbox>
  </div>

  <kodit-divider dividerLabel="Podaci o adresi"></kodit-divider>
  <kodit-adresa-form
    [addressForm]="fizickoLiceForm.controls.adresa"
  ></kodit-adresa-form>
</form>
