import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UplataRacunaTableDto } from '@kodit/core/data-api';
import {
  OnTableInit,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-uplate-po-racunu-table',
  templateUrl: './uplate-po-racunu-table.component.html',
  styleUrls: ['./uplate-po-racunu-table.component.scss'],
})
export class UplatePoRacunuTableComponent
  implements OnInit, OnDestroy, OnTableInit {
  /** Subs */
  private _getSub: Subscription = new Subscription();

  /** Configurations */
  tableConfig: TableConfig;

  /** I/O */
  @Input() uplateTable: UplataRacunaTableDto[] = [];

  constructor() {}

  ngOnInit(): void {
    this.setTableConfig();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: '',
      columns: [
        { field: 'izvor', header: 'Izvor', type: 'text' },
        {
          field: 'datumEvidentiranja',
          header: 'Datum evidentiranja',
          type: 'text',
        },
        {
          field: 'iznos',
          header: 'Iznos',
          type: 'number',
        },
      ],
    });
  }

  // private _goToIzvod(rowIndex: number) {
  //   this._router.navigate([
  //     '/banka',
  //     'izvodi',
  //     'obrada-izvoda',
  //     // this.uplateTable[rowIndex].rutiranjeIzvodaDto.izvodId,
  //   ]);
  // }

  ngOnDestroy(): void {
    this._getSub.unsubscribe();
  }
}
