import { Injectable } from '@angular/core';
import { VrstaRadnogNaloga } from '@kodit/core/data-api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RadniNalogService {
  vrstaRadnogNaloga$ = new BehaviorSubject<VrstaRadnogNaloga>(VrstaRadnogNaloga.PROIZVODNJA_PO_NARUDZBINI);
  
  constructor() {}

  jeUsluznaDelatnost(vrsta: VrstaRadnogNaloga): boolean {
    return (
      vrsta === VrstaRadnogNaloga.USLUZNA_DELATNOST ||
      vrsta === VrstaRadnogNaloga.GRAĐEVINA ||
      vrsta === VrstaRadnogNaloga.EVIDENCIJA_VREMENA_I_MATERIJALA
    );
  }

  changeVrstaRadnogNaloga(vrsta: VrstaRadnogNaloga) {
    this.vrstaRadnogNaloga$.next(vrsta);
  }

  get getVrstaRadnogNaloga() {
    return this.vrstaRadnogNaloga$.asObservable();
  }
}
