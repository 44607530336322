import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AddOznakeRacunaCommand, GetOznakeQuery, OznakaDto, OznakeClient, RacuniClient, TipEntiteta } from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-add-oznaka-form',
  templateUrl: './add-oznaka-form.component.html',
  styleUrls: ['./add-oznaka-form.component.scss'],
})
export class AddOznakaFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  oznakeToAdd: OznakaDto[] = [];
  oznakeSuggestions: OznakaDto[] = [];

  /** I/O */
  @ViewChild('oznakaInput') oznakaInput: any;

  constructor(
    private _oznakeClient: OznakeClient,
    private _dialogRef: DynamicDialogRef,
    private _alertService: AlertService,
    private _racuniClient: RacuniClient,
    public dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  private save() {
    if (!this.oznakeToAdd || this.oznakeToAdd.length === 0) {
      this._alertService.addWarnMsg('Morate uneti bar jednu oznaku');
      return;
    }

    this._subs.add(
      this._racuniClient
        .addOznake(new AddOznakeRacunaCommand({ racunId: this.dialogConfig.data.racunId, oznake: this.oznakeToAdd }))
        .subscribe((result) => {
          if (result.succeeded) {
            this._alertService.addSuccessMsg(result.messages![0]);
            this._dialogRef.close(this.oznakeToAdd);
          } else {
            this._alertService.addWarnMsg(result.messages![0]);
          }
        })
    );
  }

  searchOznake(event: any) {
    this._subs.add(
      this._oznakeClient
        .get(
          new GetOznakeQuery({
            naziv: event.query,
            excludeTipoveEntiteta: [
              TipEntiteta.PRAVNO_LICE,
              TipEntiteta.FIZICKO_LICE,
            ],
          })
        )
        .subscribe((result) => {
          this.oznakeSuggestions = result.oznake.filter((x) =>
            this.filterOznaka(x.naziv)
          );
        })
    );
  }

  filterOznaka(naziv: string): boolean {
    let alreadyAdded: boolean;
    this.oznakeToAdd.forEach((o) => {
      if (o.punNaziv && o.punNaziv.includes(naziv)) {
        alreadyAdded = true;
        return;
      } else if (o.naziv.includes(naziv)) {
        alreadyAdded = true;
        return;
      }
    });

    return (
      this.oznakeToAdd.findIndex((x) => x.naziv == naziv) === -1 &&
      !alreadyAdded
    );
  }

  onOznakaSelect(oznaka: OznakaDto) {
    this.oznakeToAdd.push(oznaka);

    this.oznakaInput.inputEL.nativeElement.value = '';
  }

  onRemoveOznaka(index: number) {
    this.oznakeToAdd.splice(index, 1);
  }

  ngOnDestroy(): void {}
}
