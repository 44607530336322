<div class="k-card" card (click)="redirectToLink()">
  <div class="p-d-flex p-flex-column p-jc-between">
    <div class="">
      <h4 class="_header">
        <i [ngClass]="'k-icon ' + cardIcon"></i> {{ cardTitle }}
      </h4>
    </div>
    <div
      class="p-text-nowrap p-text-truncate"
      style="max-width: 30rem"
      [pTooltip]="cardSubtitle"
      tooltipPosition="bottom"
    >
      {{ cardSubtitle }}
    </div>
  </div>
</div>
