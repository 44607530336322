import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import {
  ArhivskaKnjigaClient,
  GetSkladisneJediniceDropdownQuery,
  ISkladisnaJedinicaDto,
  SkladisnaJedinicaDto,
} from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { ArhivskaKnjigaService } from 'libs/core/moduli/podesavanje/src/lib/arhivska-knjiga/arhivska-knjiga-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-skladisna-jedinica-autocomplete',
  templateUrl: './skladisna-jedinica-autocomplete.component.html',
  styleUrls: ['./skladisna-jedinica-autocomplete.component.scss'],
})
export class SkladisnaJedinicaAutocompleteComponent
  implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  skladisnaJedinicaModel: SkladisnaJedinicaDto;
  skladisneJediniceSuggestions: SkladisnaJedinicaDto[] = [];
  dropdownEnabled: boolean = true;
  errorMsg: string = 'Obavezno polje';
  formSubmitted: boolean = false;
  form: FormGroupTypeSafe<any>;

  /** I/0 */
  @Input() skladisnaJedinicaInput: AbstractControl | undefined;

  constructor(
    private _arhivskaClient: ArhivskaKnjigaClient,
    private _arhivskaService: ArhivskaKnjigaService,
    private _fb: FormBuilderTypeSafe
  ) {
    this._subs.add(
      this._arhivskaService.getArhivskaFormSubmitted.subscribe((res) => {
        this.formSubmitted = res;
      })
    );

    this.form = _arhivskaService.getSkladisnaJedinicaDtoFormGroup();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.skladisnaJedinicaInput) {
      this._subs.add(
        this.skladisnaJedinicaInput.valueChanges.subscribe((value) => {
          if (value) {
            this.form.patchValue(value);
            this.skladisnaJedinicaModel = value;
          }
        })
      );
    }
  }

  onSelectChange(event: SkladisnaJedinicaDto) {
    this._arhivskaService.setSkladisnaJedinica(event);
  }

  handleInputClear() {
    this._arhivskaService.arhivskaKnjigaForm.controls.skladisnaJedinicaDto?.reset();
  }

  searchSkladisneJediniceNaziv(event: any) {
    this._subs.add(
      this._arhivskaClient
        .getSkladisneJediniceDropdown(
          new GetSkladisneJediniceDropdownQuery({
            naziv: event.query,
          })
        )
        .subscribe((res) => {
          this.skladisneJediniceSuggestions = [
            ...res.data?.skladisneJediniceDropdown!,
          ];
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
