import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IOpstaPravilaKonfiguracijaDto,
  RacuniClient,
} from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { OpstaPravilaFormComponent } from '../opsta-pravila-form/opsta-pravila-form.component';

@Component({
  selector: 'kodit-opsta-pravila',
  templateUrl: './opsta-pravila.component.html',
  styleUrls: ['./opsta-pravila.component.scss'],
})
export class OpstaPravilaComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Configuration */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  opstaPravilaDto: IOpstaPravilaKonfiguracijaDto;
  uSistemuPDVText: string;

  constructor(
    private _dialogService: DynamicDialogService,
    private _client: RacuniClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.load();
  }

  onEdit() {
    this.dialogConfig.data = {
      model: this.opstaPravilaDto,
    };
    this.dialogConfig.header = 'Izmena opštih pravila';
    this.openDialog(this.dialogConfig);
  }

  openDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(OpstaPravilaFormComponent, config);

    this._subs.add(
      ref.onClose.subscribe((dto: IOpstaPravilaKonfiguracijaDto) => {
        if (dto) {
          this.opstaPravilaDto = dto;
        }
      })
    );
  }

  private load() {
    this._subs.add(
      this._client.getOpstaPravilaKonfiguracija().subscribe(
        (result) => {
          this.opstaPravilaDto = result.data;
        },
        (error) => {
          this._subs.add(
            error.subscribe((x: string) => {
              this._alertService.addFailedMsg(x);
            })
          );
        }
      )
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
