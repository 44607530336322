<div class="p-d-flex p-flex-column p-ai-center p-jc-center gap-6 text-center">
  <div style="text-align: center;">
    Ukoliko u vašoj kompaniji postoji više korisnika, možete im otvoriti novi
    korisnički nalog.

    <a href="https://ekompanija.tawk.help/article/kako-da-dodam-novog-korisnika"
      >Pogledajte kako.</a
    >
  </div>
  <div>{{ remainingTime }}</div>
</div>
