import { RouterModule, Routes } from '@angular/router';
import { FileManagerPageComponent } from './file-manager-page/file-manager-page.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: FileManagerPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FileManagerRoutingModule {}
