<kodit-dropdown
  formCtrlId="pdv-kategorija-select"
  [options]="items"
  [formCtrl]="pdvKategorijaCtrl"
  ctrlPlaceholder="Izaberite PDV kategoriju"
  (optionChanged)="handleOnItemChange($event)"
>
  <ng-template #selectedItem let-obj>
    {{ obj.naziv }}
  </ng-template>

  <ng-template #item let-obj>
    <div [pTooltip]="obj.opis">
      {{ obj.naziv }}
    </div>
  </ng-template>
</kodit-dropdown>
