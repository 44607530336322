import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  GetNeplaceniRacunTableQuery,
  IGetNeplaceniRacunTableQuery,
  IStrankaDto,
  NeplaceniRacunTableDto,
  RacuniClient,
  TipPristupa,
  UplataIsplataZaRacunDto,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { AlertService, LocationService } from '@kodit/core/services';
import { DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { BankaService } from 'libs/core/moduli/banka/src/lib/banka.service';
import {
  ActionType,
  OnTableInit,
  TableAction,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NeplaceniRacunTableFilterFormService } from './neplaceni-racun-table-filter-form.service';
import { FormArray } from '@angular/forms';
import { IzvodiService } from '../../izvod/izvodi.service';

@Component({
  selector: 'kodit-neplaceni-racun-table',
  templateUrl: './neplaceni-racun-table.component.html',
  styleUrls: ['./neplaceni-racun-table.component.scss'],
})
export class NeplaceniRacunTableComponent
  implements OnInit, OnDestroy, OnTableInit, OnChanges {
  /** Subs */
  private _getSub: Subscription = new Subscription();

  /** Configuration */
  tableConfig: TableConfig;
  tableActions: TableAction[];

  /** Props */
  racunTable: NeplaceniRacunTableDto[] = [];
  jeDevizna: boolean = false;
  pretragaStranke: IStrankaDto[] = [];
  evidentiraniRacuni: number[] = [];
  resetStrankaIds: boolean = false;
  showStrankaSearch: boolean;

  /** Form */
  form: FormGroupTypeSafe<IGetNeplaceniRacunTableQuery>;

  /** I/O */
  @Input() dialogRef: DynamicDialogRef;
  @Input() uplataId: number = 0;
  @Input() ukupanIznosUplate: number = 0;
  @Input() strankaId: number;
  @Input() vrstaRacuna: VrstaRacuna = VrstaRacuna.EMPTY;
  @Input() pozivNaBrojDrugeStrane: string;
  @Input() sviRacuniSelected: boolean;
  @Output() onEvidentirajUplatu = new EventEmitter<UplataIsplataZaRacunDto>();
  @Output() onPonistiEvidencijuUplate = new EventEmitter<number>();

  constructor(
    private _racuniClient: RacuniClient,
    private _locationService: LocationService,
    private _alertService: AlertService,
    private _bankaService: BankaService,
    private _formService: NeplaceniRacunTableFilterFormService,
    private _izvodService: IzvodiService
  ) {}

  ngOnInit(): void {
    this._initializeForm();
    this._getSub.add(
      this._bankaService.getJeDevizniIzvod.subscribe(
        (res) => (this.jeDevizna = res)
      )
    );
    this._getSub.add(
      this._izvodService.getShowStrankaSearch.subscribe(val => {
        if(!!val){
          this.showStrankaSearch = false;
          this.form.controls.strankaId.patchValue(val);
        }
        else {
          this.showStrankaSearch = true;
          this.form.controls.strankaId.patchValue(null);
        }
      })
    );
    this._getNeplaceniRacunTableQuery();
    this.setTableConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.strankaId && !changes.strankaId.firstChange) {
      if (
        changes.strankaId.currentValue != changes.strankaId.previousValue
      ) {
        this.setTableConfig();
        this._getNeplaceniRacunTableQuery();
      }
    }
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: '',
      columns: [
        {
          field: 'broj',
          header: 'Broj',
          type: 'link',
          linkCallbackFunction: (itemIndex: number) =>
            this._onRacunInfo(itemIndex),
        },
        {
          field: 'originalanBroj',
          header: 'Originalan broj',
          type: 'text',
          isVisible: this.vrstaRacuna === VrstaRacuna.ULAZNI,
        },
        {
          field: 'rutiranjeStranke',
          subField: 'naziv',
          header: 'Stranka',
          type: 'link',
          isVisible: !this.strankaId,
          linkCallbackFunction: (itemIndex: number) =>
            this._goToStranka(itemIndex),
        },
        {
          field: 'preostaliIznos',
          header: 'Preostali iznos',
          type: 'currency',
          currencyAlphaCharField: 'kodValuteAlfaChar',
        },
        {
          field: 'datumValuteTableDto',
          subField: 'datumValute',
          header: 'Datum valute',
          type: 'text',
          columns: [
            {
              field: 'datumValuteTableDto',
              subField: 'napomena',
              styleClass: 'datum-valute-table',
              styleClassField: 'jeIsteklaValuta',
              type: 'text',
            },
          ],
        },
        {
          field: 'statusUplateDto',
          subField: 'statusStr',
          header: 'Status',
          styleClass: 'status-uplate-racuna',
          styleClassField: 'statusBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
        },
      ],
      rowActions: [
        {
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.IZVOD_CRUD,
          label: 'Evidentiraj',
          icon: 'fa-regular fa-check',
          hasAccessTooltip: 'Evidentirajte uplatu',
          noAccessTooltip: 'Nemate ovlašćenja za evidentiranje uplate',
          callback: (index: number) => this._onEvidencijaUplate(index),
          shouldDisplayByCondition: (rowData: NeplaceniRacunTableDto) => {
            return !this.evidentiraniRacuni.includes(rowData.id);
          },
        },
        {
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.IZVOD_CRUD,
          label: 'Poništi',
          icon: 'fa-regular fa-xmark',
          actionClass: 'p-button-danger',
          hasAccessTooltip: 'Poništite evidentiranu uplatu',
          callback: (index: number) =>
            this._onPonistavanjeEvidencijeUplate(index),
          shouldDisplayByCondition: (rowData: NeplaceniRacunTableDto) => {
            return this.evidentiraniRacuni.includes(rowData.id);
          },
        },
      ],
      updateActions$: new BehaviorSubject<number>(-1),
      rowsPerPage: 5,
    });
  }

  private _onEvidencijaUplate(rowIndex: number) {
    if (this.ukupanIznosUplate == 0) {
      this._alertService.addWarnMsg('Nema dovoljno sredstava');
      return;
    }
    let uplaceniIznos = 0;
    if (this.ukupanIznosUplate >= this.racunTable[rowIndex].preostaliIznos) {
      // imamo dovoljno sredstava pa cemo da skinemo ceo preoostali iznos
      uplaceniIznos = this.racunTable[rowIndex].preostaliIznos;
    } else {
      // nemamo dovoljno sredstava da isplatimo ceo racun, pa cemo to odraditi delimicno
      uplaceniIznos = this.ukupanIznosUplate;
    }
    this.evidentiraniRacuni.push(this.racunTable[rowIndex].id);
    this.onEvidentirajUplatu.emit(
      new UplataIsplataZaRacunDto({
        racunId: this.racunTable[rowIndex].id,
        iznos: uplaceniIznos,
        tipRacuna: this.racunTable[rowIndex].tip
      })
    );
    this.tableConfig.setUpdateActions = rowIndex;
  }

  private _onPonistavanjeEvidencijeUplate(rowIndex: number) {
    const idx = this.evidentiraniRacuni.findIndex(
      (x) => x === this.racunTable[rowIndex].id
    );
    this.evidentiraniRacuni.splice(idx, 1);
    this.onPonistiEvidencijuUplate.emit(this.racunTable[rowIndex].id);
    this.tableConfig.setUpdateActions = rowIndex;
  }

  private _onRacunInfo(rowIndex: number) {
    if (this.dialogRef) {
      this.dialogRef.close();
    }

    this._locationService.routeToRacunKartica(
      this.racunTable[rowIndex].id,
      this.racunTable[rowIndex].tip,
      this.vrstaRacuna
    );
  }

  private _goToStranka(itemIndex: number) {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this._locationService.routeToStrankaKartica(
      this.racunTable[itemIndex].rutiranjeStranke.strankaId,
      this.racunTable[itemIndex].rutiranjeStranke.tipStranke
    );
  }

  private _initializeForm(){
    this.form = this._formService.GetFormGroup(
      this.strankaId,
      this.vrstaRacuna,
      this.jeDevizna
    );
  }

  onSearchSubmit() {
    this._getNeplaceniRacunTableQuery();
  }

  onSearchReset() {
    (this.form.controls.stranke as FormArray).clear();
    this.form.controls.brojRacuna.reset();
    this.resetStrankaIds = true;
    this._getNeplaceniRacunTableQuery();
  }

  private _getNeplaceniRacunTableQuery() {
    this._getSub = this._racuniClient
      .getNeplaceniForTable(this.form.value as GetNeplaceniRacunTableQuery)
      .subscribe((res) => {
        this.racunTable = res.data.neplaceniRacunTableDtoList;
        this.resetStrankaIds = false;
      });
  }

  ngOnDestroy(): void {
    this._getSub.unsubscribe();
    this.form.reset();
  }
}
