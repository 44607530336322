import {Component, OnDestroy, OnInit} from '@angular/core';
import {SistemClient, SoftwareInformationDto} from "@kodit/core/data-api";
import {Subscription} from "rxjs";
import { environment } from '../../../../../../../apps/core/src/environments/environment.prod';

@Component({
  selector: 'kodit-verzija-softvera-dijalog',
  templateUrl: './verzija-softvera-dijalog.component.html',
  styleUrls: ['./verzija-softvera-dijalog.component.scss']
})
export class VerzijaSoftveraDijalogComponent implements OnInit,OnDestroy {
  /** Subs */
  private _subs : Subscription = new Subscription();

  /** Props */
  softwareInformation : SoftwareInformationDto;
  softwareVersion : string =  environment.version;

  constructor(private _sistemClient : SistemClient) { }

  ngOnInit(): void {
    this._load();
  }

  private _load(){
    this._subs.add(
      this._sistemClient.getSoftwareInformationDto().subscribe((res)=>{
        this.softwareInformation = res.data;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
