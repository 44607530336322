import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KorisniciClient } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-korisnicka-grupa-dropdown',
  templateUrl: './korisnicka-grupa-dropdown.component.html',
  styleUrls: ['./korisnicka-grupa-dropdown.component.scss'],
})
export class KorisnickaGrupaDropdownComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];

  /** I/O */
  @Input() korisnickaGrupaCtrl: FormControl;
  @Input() formCtrlId: string;

  constructor(private _client: KorisniciClient) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client.getKorisnickaGrupaDropdown().subscribe((res) => {
        res.data.korisnickaGrupaDropdownDtoList.forEach((g) => {
          this.items.push({
            value: g.id,
            label: g.naziv,
          });
        });
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
