import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Sidebar } from 'primeng/sidebar';
import { AdvancedFilterDirective } from './advanced-filter-content';
import { AdvancedFilterItem } from './advanced-filter-item';
import { AdvancedFilter } from '@kodit/core/shared-ui';
import { ButtonSeverity } from '../../../ui/button/button';

@Component({
  selector: 'kodit-advanced-filter',
  templateUrl: './advanced-filter.component.html',
  styleUrls: ['./advanced-filter.component.scss'],
})
export class AdvancedFilterComponent
  implements OnInit, AfterViewInit, OnChanges
{
  /** Props */
  @ViewChild(AdvancedFilterDirective, { static: true })
  adFilterContent!: AdvancedFilterDirective;

  @ViewChild('NavSideBar') sidebarRef: Sidebar;
  // @ViewChild("NavSideBar") el : HTMLElement
  visible: boolean;
  cancelBtnSeverity: ButtonSeverity = ButtonSeverity.DANGER;
  el: any;
  /** I/O */
  @Input() advancedFilterItem: AdvancedFilterItem;
  componentRef: ComponentRef<AdvancedFilter<any>>;
  protected readonly ButtonSeverity = ButtonSeverity;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.advancedFilterItem.currentValue !=
      changes.advancedFilterItem.previousValue
    ) {
      this._loadComponent();
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.advancedFilterItem) {
      this._loadComponent();
    }
  }

  onReset() {
    if (!this.visible) {
      // jer nam dva puta udje u reset..verovatno zbog sidebarRef.destroyModal()
      return;
    }
    this.componentRef.instance.onFilterHide(true);
    this._destroy();
  }

  onSubmit() {
    this.componentRef.instance.onFilterSubmit();
    this._destroy();
  }

  onHide() {
    if (!this.visible) {
      // jer nam dva puta udje u reset..verovatno zbog sidebarRef.destroyModal()
      return;
    }

    this.componentRef.instance.onFilterHide(false);
    this._destroy();
  }

  onShow(e) {
    this.el = this.elementRef.nativeElement.querySelector('.p-sidebar-top');
    if (e) {
      // this.el.style.transition="transform .4s cubic-bezier(0.08, 0.82, 0.17, 1)";
      this.el.style.transform = 'translate3d(0, 0px, 0)';
    } else {
      this.el.style.display = 'none';
    }
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }

  private _loadComponent() {
    const viewContainerRef = this.adFilterContent.viewContainerRef;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent<
      AdvancedFilter<any, any>
    >(this.advancedFilterItem.component);
    this.componentRef.instance.filterData = this.advancedFilterItem.data;
    this.componentRef.instance.availableFilters =
      this.advancedFilterItem.availableFilters;
    this.visible = true;
  }

  private _destroy() {
    this.el = this.elementRef.nativeElement.querySelector('.p-sidebar-top');
    this.el.style.display = 'none';
    this.visible = false;
    this.sidebarRef.destroyModal();
  }
}
