import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { UnosDatotekeFormComponent } from './unos-datoteke-form/unos-datoteke-form.component';
import { IDatotekaTreeDto, VrstaDatoteke } from '@kodit/core/data-api';
import { MenuItem, TreeNode } from 'primeng/api';
import { DatotekaService } from '../datoteka-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-file-manager-page',
  templateUrl: './file-manager-page.component.html',
  styleUrls: ['./file-manager-page.component.scss'],
})
export class FileManagerPageComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Config */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  items: MenuItem[] = [];
  selectedOption: string | null = null;
  selectedDatoteka: TreeNode<IDatotekaTreeDto> | undefined;

  /** Conditional props */
  jeFolder: boolean = false;
  jeDokument: boolean = false;
  jeDelovodnik: boolean = false;

  constructor(
    private _dialogService: DynamicDialogService,
    private _datotekaService: DatotekaService
  ) {}

  ngOnInit(): void {
    this.setDropdownItems();

    this._subs.add(
      this._datotekaService.getSelectedDatoteka.subscribe((datoteka) => {
        if (datoteka) {
          this.selectedDatoteka = datoteka;
          this._setVrstaDokumenta(datoteka);
        }
      })
    );
  }

  setDropdownItems() {
    this.items = [
      {
        label: 'Novi folder',
        icon: 'fa-solid fa-folder-closed',
        command: () => {
          this.openUnosForm(VrstaDatoteke.FOLDER);
        },
      },
      {
        label: 'Novi dokument',
        icon: 'fa-solid fa-file-lines',
        command: () => {
          this.openUnosForm(VrstaDatoteke.DOKUMENT);
        },
      },
      {
        label: 'Novi delovodnik',
        icon: 'fa-regular fa-table',
        command: () => {},
      },
    ];
  }

  openUnosForm(vrstaDatoteke: VrstaDatoteke) {
    this.dialogConfig.data = { vrstaDatoteke: vrstaDatoteke, enableParentSelection: true };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.customSubmitButton = { label: 'Kreiraj' } as any;
    this.dialogConfig.customCancelButton = { label: 'Otkaži' } as any;
    this.dialogConfig.header =
      vrstaDatoteke === VrstaDatoteke.FOLDER ? 'NOVI FOLDER' : 'NOVI DOKUMENT';
    this._dialogService.open(UnosDatotekeFormComponent, this.dialogConfig);
  }

  private _setVrstaDokumenta(datoteka: TreeNode<IDatotekaTreeDto>) {
    if (
      !datoteka.data ||
      datoteka.data.vrstaDatoteke === VrstaDatoteke.FOLDER
    ) {
      this.jeFolder = true;
      this.jeDokument = false;
      this.jeDelovodnik = false;
    } else if (datoteka.data.vrstaDatoteke === VrstaDatoteke.DOKUMENT) {
      this.jeFolder = false;
      this.jeDokument = true;
      this.jeDelovodnik = false;
    } else if (datoteka.data.vrstaDatoteke === VrstaDatoteke.DELOVODNIK) {
      this.jeFolder = false;
      this.jeDokument = false;
      this.jeDelovodnik = true;
    }
  }

  ngOnDestroy(): void {
    this._datotekaService.setSelectedDatoteka = undefined;
    this._subs.unsubscribe();
  }
}
