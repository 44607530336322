import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  IInformacijaIsporukeDto,
} from '@kodit/core/data-api';

@Component({
  selector: 'kodit-informacija-isporuke-stranke-dropdown',
  templateUrl: './informacija-isporuke-stranke-dropdown.component.html',
  styleUrls: ['./informacija-isporuke-stranke-dropdown.component.scss'],
})
export class InformacijaIsporukeStrankeDropdownComponent implements OnInit {
  @Input() items: IInformacijaIsporukeDto[];
  @Input() shouldDisable: boolean = false;

  selectedItem: IInformacijaIsporukeDto;

  @Output() onItemChanged = new EventEmitter<IInformacijaIsporukeDto>();

  newItemSelected(item: IInformacijaIsporukeDto) {
    this.onItemChanged.emit(item);
  }

  constructor() {}

  ngOnInit(): void {}
}
