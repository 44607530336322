import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  IPrviLoginKorisnikCommand,
  KorisniciClient,
  PrviLoginKorisnikCommand,
} from '@kodit/core/data-api';
import { AlertService, MixpanelService } from '@kodit/core/services';
import {
  FormGroupTypeSafe,
  FormBuilderTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { CoreService } from '../../core-service';

@Component({
  selector: 'kodit-welcome-korisnik-card',
  templateUrl: './welcome-korisnik-card.component.html',
  styleUrls: ['./welcome-korisnik-card.component.scss'],
})
export class WelcomeKorisnikCardComponent implements OnInit, OnDestroy {
  private _sub = new Subscription();

  form: FormGroupTypeSafe<IPrviLoginKorisnikCommand>;

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _client: KorisniciClient,
    private _alertService: AlertService,
    private _coreService: CoreService,
    private _mixpanelService : MixpanelService
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group<IPrviLoginKorisnikCommand>({
      prihvaceniUsloviKoriscenja: new FormControl(null, {
        validators: [Validators.required],
      }),
    });
  }

  handleSave() {
    if (this.form.invalid) {
      this.form.controls.prihvaceniUsloviKoriscenja.markAsDirty();
      return;
    }

    this._sub = this._client
      .prviLoginKorisnik(this.form.value as PrviLoginKorisnikCommand)
      .subscribe((res) => {
        this._mixpanelService.useAndTermsSubmitted();
        this._alertService.addSuccessMsg(res.data);
        this._coreService.setJePrviLoginKorisnika = false;
      });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
