<div class="p-d-flex p-flex-column p-ai-center p-jc-center">
  <div class="confirm-check">
    <i class="fas fa-check"></i>
  </div>
  <div class="p-d-flex p-flex-column wrapper p-ai-center">
    <div class="uspesno-text">
      Uspešno ste registrovali firmu
    </div>
    <div class="uspesno-description">
      Uspešno ste registrovali firmu, kliknite na NASTAVI dugme kako biste se prijavili. Automatski ćete biti prebačeni za 10 sekundi.
    </div>
    <div class="nastavi-button" (click)="routeToLogin()">
      Nastavi
    </div>
  </div>
</div>
