import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import {
  IRepromaterijalDto,
  RepromaterijaliClient,
} from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-repromaterijal-robe-multiselect',
  templateUrl: './repromaterijal-robe-multiselect.component.html',
  styleUrls: ['./repromaterijal-robe-multiselect.component.css'],
})
export class RepromaterijalRobeMultiselectComponent
  implements OnInit, OnDestroy {
  private _subs: Subscription;

  /** Props */
  items: SelectItem[];
  selectedItems: IRepromaterijalDto[] = [];

  /** I/O */
  @Input() repromaterijaliFormArray: FormArray | AbstractControl;
  @Output() selectedRepromaterijali = new EventEmitter<IRepromaterijalDto[]>();

  constructor(private _repromaterijaliClient: RepromaterijaliClient) {
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._repromaterijaliClient
        .getRepromaterijalMultiSelect()
        .subscribe((res) => {
          this.items = res.data.repromaterijalMultiselectDtoList.map((x) => ({
            value: x,
            label: x.naziv,
            title: x.naziv,
          }));
        })
    );

    this._subs.add(
      this.repromaterijaliFormArray.valueChanges.subscribe(() => {
        if (
          this.repromaterijaliFormArray.value &&
          this.repromaterijaliFormArray.value.length > 0
        ) {
          this.selectedItems = [...this.repromaterijaliFormArray.value];
        }
      })
    );
  }

  selectedItemsOnChange() {
    this.selectedRepromaterijali.next(this.selectedItems);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
