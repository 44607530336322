import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { replace } from '@kodit/core/shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-error-info-max',
  templateUrl: './error-info-max.component.html',
  styleUrls: ['./error-info-max.component.scss'],
})
export class ErrorInfoMaxComponent implements OnInit, OnDestroy {
  /** Props */
  private _sub = new Subscription();
  initMsg: string = 'Najveća vrednost može biti #MAX, a trenutno je #CURR';
  errorMsg: string;

  /** I/O */
  @Input() formCtrl: AbstractControl;

  constructor() {}

  ngOnInit(): void {
    this._setErrorMsg();

    this._sub = this.formCtrl.valueChanges.subscribe((newValue) => {
      this._setErrorMsg();
    });
  }

  private _setErrorMsg() {
    if (this.formCtrl.errors?.['max']) {
      this.errorMsg = replace(
        this.initMsg,
        '#CURR',
        this.formCtrl.errors?.['max']?.actual
      ).replace('#MAX', this.formCtrl.errors?.['max']?.max);
    }
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
