import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GetSastavniceTableQuery,
  ResultOfCreateSastavnicaResponse,
  SastavnicaTableDto,
  SastavniceClient,
} from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogService } from '@kodit/core/shared-ui';
import {
  DialogSize,
  DynamicDialogConfig,
  OnDynamicDialogInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  ActionType,
  OnTableInit,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { Subscription } from 'rxjs';
import { SastavnicaFormComponent } from '../sastavnica-form/sastavnica-form.component';

@Component({
  selector: 'kodit-sastavnica-table',
  templateUrl: './sastavnica-table.component.html',
  styleUrls: ['./sastavnica-table.component.scss'],
})
export class SastavnicaTableComponent
  implements OnInit, OnDestroy, OnTableInit, OnDynamicDialogInit {
  /** Subscriptions */
  private _tableRefreshSub: Subscription = new Subscription();
  private _deleteSub: Subscription = new Subscription();
  private _dialogCloseSub: Subscription = new Subscription();
  private _loadSub: Subscription = new Subscription();

  /** Configurations */
  tableConfig: TableConfig;

  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.SMALL_TINY
  );

  /** Props */
  sastavnicaTable: SastavnicaTableDto[] = [];
  robaId: number;

  @Input() showCardBlock: boolean = true;

  constructor(
    private _client: SastavniceClient,
    private _alertService: AlertService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _dialogService: DynamicDialogService
  ) {}

  ngOnInit(): void {
    // get router data
    this._route.params.subscribe((data) => {
      if (data['id']) {
        this.robaId = Number.parseInt(data['id']);
      }
    });
    this._load();
    // set table config
    this.setTableConfig();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Sastavnica',
      deleteMessage:
        'Da li ste sigurni da želite da obrišete artikal iz sastavnice',
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete selektovane proizvode?',
      deleteMessageParams: 'nazivStavke',
      columns: [
        {
          field: 'nazivStavke',
          header: 'Naziv',
          type: 'link',
          linkCallbackFunction: (itemIndex: number) => this._onInfo(itemIndex),
          columns: [
            {
              field: 'nazivJediniceMere',
              header: 'Jedinica mere',
              type: 'badge',
            },
          ],
        },
        {
          field: 'kolicina',
          header: 'Količina',
          type: 'number',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          hasAccessTooltip: 'Unesite robu',
          noAccessTooltip: 'Nemate ovlašćenja za unos robe',
          callback: () => {
            this.dialogConfig.data = {
              id: null,
              robaId: this.robaId,
              excludeIds: this.sastavnicaTable
                .map(x => x.predmetStavkeId)
                .concat(this.robaId)
            };
            this.dialogConfig.header = 'Unos podataka artikla';
            this.openDialog(this.dialogConfig);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          hasAccessTooltip: 'Izmenite robu',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu robe',
          callback: (index: number) => {
            this.dialogConfig.data = {
              id: this.sastavnicaTable[index]?.id,
              robaId: this.robaId,
            };
            this.dialogConfig.header = 'Izmena podataka artikla';
            this.openDialog(this.dialogConfig);
          },
        },
        // {
        //   type: ActionType.DELETE,
        //   tipPristupa: TipPristupa.JEDINICA_MERE_SOFT_DELETE,
        //   hasAccessTooltip: 'Obrišite robu',
        //   noAccessTooltip: 'Nemate ovlašćenja za brisanje robe',
        //   callback: (index: number) => {
        //     const forDelete = this.sastavnicaTable[itemIndex];
        // this._deleteSub = this._client.delete(forDelete.id).subscribe(
        //   () => {
        //     this.sastavnicaTable.splice(itemIndex, 1);
        //     this._alertService.addSuccessMsg(
        //       `Roba `
        //     );
        //   },
        //   (error) => {
        //     this._alertService.addFailedMsg(error);
        //   }
        // );
        //   },
        // },
      ],
    });
  }

  private _onInfo(itemIndex: number) {
    this._router
      .navigate([
        '/kompanija',
        'roba',
        this.sastavnicaTable[itemIndex]?.predmetStavkeId,
      ])
      .then();
  }

  openDialog(config: DynamicDialogConfig) {
    const ref = this._dialogService.open(SastavnicaFormComponent, config);

    this._dialogCloseSub = ref.onClose.subscribe(
      (dto: ResultOfCreateSastavnicaResponse) => {
        if (dto) {
          const idx = this.sastavnicaTable.findIndex(
            (x) => x.id === dto.data.sastavnicaDto.id
          );
          if (idx > -1) {
            // update
            this.sastavnicaTable[idx] = dto.data.sastavnicaTableDto;
          } else {
            // create
            this.sastavnicaTable.push(dto.data.sastavnicaTableDto);
          }
        }
      }
    );
  }

  private _load() {
    this._loadSub = this._client
      .getForTable(
        new GetSastavniceTableQuery({
          id: this.robaId,
        })
      )
      .subscribe(
        (res) => {
          this.sastavnicaTable = res.data.sastavnicaTableDtoList;
        }
      );
  }

  ngOnDestroy() {
    this._tableRefreshSub.unsubscribe();
    this._deleteSub.unsubscribe();
    this._dialogCloseSub.unsubscribe();
    this._loadSub.unsubscribe();
  }
}
