import { Component, OnDestroy, OnInit } from '@angular/core';
import { IKorisnickaGrupaCardDto, KorisniciClient } from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { Subscription } from 'rxjs';
import { KorisnickaGrupaService } from '../korisnicka-grupa.service';

@Component({
  selector: 'kodit-korisnicka-grupa-list',
  templateUrl: './korisnicka-grupa-list.component.html',
  styleUrls: ['./korisnicka-grupa-list.component.scss'],
})
export class KorisnickaGrupaListComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  korisnickeGrupe?: IKorisnickaGrupaCardDto[] = [];

  constructor(
    private _client: KorisniciClient,
    private _alertService: AlertService,
    private _service: KorisnickaGrupaService
  ) {}

  ngOnInit(): void {
    this._load();
    this._subs.add(
      this._service.getShouldReloadCards.subscribe((res) => {
        if (res) {
          this._load();
        }
      })
    );
  }

  private _load() {
    this._subs.add(
      this._client.getKorisnickaGrupaList().subscribe(
        (res) => {
          this.korisnickeGrupe = res.data.korisnickaGrupaCardDtoList;
        },
        (error) => {
          this._subs.add(
            error.subscribe((x: string) => {
              this._alertService.addFailedMsg(x);
            })
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
