import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileParameter, MojaFirmaClient } from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService, ButtonService, SharedService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef, FileUploadService } from '@kodit/core/shared-ui';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-kreiraj-tiket-form',
  templateUrl: './kreiraj-tiket-form.component.html',
  styleUrls: ['./kreiraj-tiket-form.component.scss']
})
export class KreirajTiketFormComponent implements OnInit, OnDestroy {

  private _subs: Subscription = new Subscription();

  form: FormGroup;
  uploadCompleted: boolean;
  priorityOptions: SelectItem[] = [];
  readonly MAX_FILE_SIZE: number = 75000000;
  type = this._dialogConfig.data?.type;

  constructor(
    public _dialogConfig: DynamicDialogConfig,
    private _dialogRef: DynamicDialogRef,
    private _fb: FormBuilder,
    private _uploadService: FileUploadService,
    private _formHelper: FormHelper,
    private _buttonService: ButtonService,
    private _client: MojaFirmaClient,
    private _alertService: AlertService,
    private _sharedService: SharedService
  ) { }


  ngOnInit(): void {
    this._uploadService.setMaxAttachmentSize = this.MAX_FILE_SIZE;
    this._loadDropdownOptions();
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.onSend();
      })
    );

    this.form = this._fb.group({
      subject: this.type === 'problem' ? new FormControl('',  {validators: Validators.required}) : new FormControl(null),
      description: new FormControl(''),
      attachments: new FormArray([]),
      priority: new FormControl(this.type === 'problem' ? '' : null)
    });

    this._subs.add(
      this._uploadService.getAttachments.subscribe((attachments: File[]) => {
        console.log("Prilozi koji su prosleđeni:", attachments);
        (this.form.controls.attachments as FormArray).clear();
        attachments.forEach((x) => {
          (this.form.controls.attachments as FormArray).push(
            new FormControl(x)
          );
        });
      })
    );

  }

  private _loadDropdownOptions() {
    this.priorityOptions.push({
      value: "Low",
      label: 'Nizak',
    });

    this.priorityOptions.push({
      value: "Medium",
      label: 'Srednji',
    });

    this.priorityOptions.push({
      value: "High",
      label: 'Visok',
    });
  }

  handleUpload(files: File[]) {
    files.forEach((file) => {
      (this.form.controls.attachments as FormArray).push(new FormControl(file));
    });
  }

  private onSend() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._buttonService.disableButton();

    if (this.type === 'problem') {
      if (!this.form.get('priority').value) {
        this.form.get('priority').setValue('Low');
      }
    }

    const attachments: FileParameter[] = this.form.value.attachments
      ? this.form.value.attachments.map((x: File) => ({
        data: x,
        fileName: x.name,
      }))
      : null;


    this._sharedService.displayLoadingScreen(true, "Kreiranje tiketa");
    this._subs.add(
      this._client
        .createZohoDeskTickets(
          this.form.value.priority,
          this.form.value.subject,
          this.form.value.description,
          attachments
        )
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.data);
            this._dialogRef.close(true);
            return;
          }
          this._alertService.addFailedMsg(res.messages[0]);
          this._dialogRef.close(true);
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}

