import { Component, Input, OnInit } from '@angular/core';
import { PostmarkOutboundMessageDetailDto } from '@kodit/core/data-api';

@Component({
  selector: 'kodit-email-poruke-po-racunu',
  templateUrl: './email-poruke-po-racunu.component.html',
  styleUrls: ['./email-poruke-po-racunu.component.scss'],
})
export class EmailPorukePoRacunuComponent implements OnInit {
  /** I/O */
  @Input() emailList: PostmarkOutboundMessageDetailDto[] = [];

  /** Props */
  messageEvents: string[] = [];

  constructor() {}

  ngOnInit(): void {
    // mapiramo svaki od MessageEvent-ova i biramo po jedan za svaki mail
    // da ne bi ispisivali sve statuse kroz koje je mail prolazio uzecemo samo jedan (poslednji)
    this._getMessageEvents();
  }

  private _getMessageEvents() {
    this.emailList.map(x => x.messageEvents).forEach((messages) => {
      this.messageEvents.push(messages[messages?.length - 1]?.type);
    })
  }
}
