import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocationService } from '@kodit/core/services';
import {
  FilterChipDto,
  FizickaLicaClient,
  GetFizickaLicaTableQuery,
  IFizickoLiceTableDto,
  IGetFizickaLicaTableQuery,
  PravnoLiceFilterDto,
  ResultOfFizickoLiceVm,
  TipPristupa,
  TipStranke,
} from '@kodit/core/data-api';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import {
  DialogSize,
  OnDynamicDialogInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { FizickoLiceFormComponent } from '../fizicko-lice-form/fizicko-lice-form.component';
import {
  ActionType,
  OnTableInit,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { FizickoLiceFilterComponent } from '../fizicko-lice-filter/fizicko-lice-filter.component';
import { FizickoLiceFilterService } from '../fizicko-lice-filter/fizicko-lice-filter.service';
import { toCamelCase } from '@kodit/core/shared';
import { FizickoLiceStorageService } from './fizicko-lice-storage.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectFilterByKey } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.selectors';
import { clearFilter, setFilter } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';

@Component({
  selector: 'kodit-fizicko-lice-table',
  templateUrl: './fizicko-lice-table.component.html',
  styleUrls: ['./fizicko-lice-table.component.scss'],
})
export class FizickoLiceTableComponent
  implements OnInit, OnDestroy, OnTableInit, OnDynamicDialogInit {
  private _subs: Subscription = new Subscription();

  /** Props */
  fizickaLicaTable: IFizickoLiceTableDto[] = [];
  filterDto: IGetFizickaLicaTableQuery;
  filters: GetFizickaLicaTableQuery;

  /** Configs */
  tableConfig: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.LARGE);
  chipItems: FilterChipDto[] = [];

  //save filter and paginate variables
  tableSessionStorage: IGetFizickaLicaTableQuery;
  routerChangeDetected: boolean;

  constructor(
    private _locationService: LocationService,
    private _client: FizickaLicaClient,
    private _dialogService: DynamicDialogService,
    private _filterService: FizickoLiceFilterService,
    private _router: Router,
    private _storage: Store
  ) {}

  ngOnInit(): void {
    //check if page is reloaded
    if (this._router.onSameUrlNavigation == 'reload') {
      this.routerChangeDetected = true;
    }

    this._subs.add(
      this._storage
        .select(selectFilterByKey('FIZICKA_LICA'))
        .subscribe((data) => {
          if (data) {
            this.filters = JSON.parse(data);
          } else {
            this.filters = new PravnoLiceFilterDto();
          }
          this._load();
        })
    );
    this.setTableConfig();
  }

  private _load() {
    this._subs.add(
      this._client
        .getForTable(this.filters as GetFizickaLicaTableQuery)
        .subscribe((res) => {
          this.fizickaLicaTable = res.data.responseList;
          this.chipItems = res.data.activeFilters;
          this.tableConfig.advancedFilter.data = this.filters;
        })
    );
  }

  //#region Table configs

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Fizička lica',
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete izabrana fizička lica i sve njihove podatke?',
      deleteMessage:
        'Da li ste sigurni da želite da izbrišete fizičko lice {param} i sve njegove podatke?',
      deleteMessageParams: 'imePrezime',
      columns: [
        {
          field: 'imePrezime',
          header: 'Ime i prezime',
          type: 'link',
          linkCallbackFunction: (itemIndex: number) => this._onInfo(itemIndex),
        },
        { field: 'mesto', header: 'Mesto', type: 'text' },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          tipPristupa: TipPristupa.STRANKA_CRUD,
          hasAccessTooltip: 'Unesite fizičko lice',
          noAccessTooltip: 'Nemate ovlašćenja za unos fizičkog lica',
          callback: () => {
            this.dialogConfig.data = { id: null };
            this.dialogConfig.header = 'Unos podataka fizičkog lica';
            this.openDialog(this.dialogConfig);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          tipPristupa: TipPristupa.STRANKA_CRUD,
          hasAccessTooltip: 'Izmenite fizičko lice',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu fizičkog lica',
          callback: (index: number) => {
            this.dialogConfig.data = { id: this.fizickaLicaTable[index]?.id };
            this.dialogConfig.header = 'Izmena podataka fizičkog lica';
            this.openDialog(this.dialogConfig);
          },
        },
        // {
        //   type: ActionType.DELETE,
        //   tipPristupa: TipPristupa.STRANKA_DELETE,
        //   hasAccessTooltip: 'Obrišite fizičko lice',
        //   noAccessTooltip: 'Nemate ovlašćenja za brisanje fizičkog lica',
        //   callback: (index: number) => {
        //      const forDelete = this.fizickaLicaTable[itemIndex];
        // this._deleteSub = this._client.delete(forDelete.id).subscribe(
        //   () => {
        //     this.fizickaLicaTable.splice(itemIndex, 1);
        //     this._alertService.addSuccessMsg(
        //       `Fizičko lice ${forDelete.imePrezime} uspešno obrisano.`
        //     );
        //   },
        //   (error) => {
        //     this._alertService.addFailedMsg(error);
        //   }
        // );
        //   },
        // },
        {
          type: ActionType.CUSTOM,
          icon: 'fab fa-buffer',
          hasAccessTooltip: 'Pogledajte detalje fizičkog lica',
          noAccessTooltip: 'Nemate ovlašćenja za pregled detalja fizičkog lica',
          callback: (index: number) => {
            this._onInfo(index);
          },
        },
      ],
      advancedFilter: {
        component: FizickoLiceFilterComponent,
        data: new GetFizickaLicaTableQuery(),
      },
    });
  }

  //#endregion Table configs

  openDialog(config: DynamicDialogConfig) {
    const ref = this._dialogService.open(FizickoLiceFormComponent, config);

    this._subs.add(
      ref.onClose.subscribe((result: ResultOfFizickoLiceVm) => {
        if (result) {
          const idx = this.fizickaLicaTable.findIndex(
            (x) => x.id === result.data.fizickoLiceDto.id
          );
          if (idx > -1) {
            // update
            this.fizickaLicaTable[idx] = result.data.fizickoLiceTableDto;
          } else {
            // create
            this.fizickaLicaTable.push({
              id: result.data.fizickoLiceTableDto.id,
              imePrezime: result.data.fizickoLiceTableDto.imePrezime,
              grad: result.data.fizickoLiceTableDto.grad,
              drzava: result.data.fizickoLiceTableDto.drzava,
              mesto: result.data.fizickoLiceTableDto.mesto,
            });
          }
        }
      })
    );
  }

  private _onInfo(itemIndex: number) {
    this._locationService.routeToStrankaKartica(
      this.fizickaLicaTable[itemIndex].id,
      TipStranke.FIZIKO_LICE
    );
  }

  private _handleChipRemove(removedChip: FilterChipDto) {
    this.tableConfig.advancedFilter.data[toCamelCase(removedChip.key)] =
      removedChip.defaultValue;

    this._updateFilters();
  }

  private _updateFilters() {
    if (this._suFilteriPrazni()) {
      this._storage.dispatch(
        clearFilter({
          key: 'FIZICKA_LICA',
        })
      );
      return;
    }

    this._storage.dispatch(
      setFilter({
        key: 'FIZICKA_LICA',
        filter: JSON.stringify(this.filters),
      })
    );
  }

  private _suFilteriPrazni() {
    for (const prop of Object.keys(this.filters) as Array<
      keyof GetFizickaLicaTableQuery
    >) {
      if (
       this.filters[prop]
      ) {
        return false;
      }
    }
    return true;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
