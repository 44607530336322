<div [formGroup]="form" class="p-d-flex p-flex-row p-fluid">
  <kodit-filter-name class="flex-1-1" title="Filter pravnih lica"></kodit-filter-name>
  <div class="flex-1-1 p-mr-4">
    <!-- Stranka -->
    <div class="p-field">
      <label for="stranke" kodit-form-label>Pravna lica</label>
      <kodit-stranka-filter-multiselect
        ctrlId="stranke"
        [strankeFormArray]="form.controls.stranke"
      ></kodit-stranka-filter-multiselect>
    </div>
    <!-- Delatnost -->
    <kodit-delatnost-dropdown
      [formCtrl]="form.controls.sifraDelatnosti"
    ></kodit-delatnost-dropdown>
  </div>
</div>
