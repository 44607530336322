<div class="p-grid">
  <div
    class="p-col-12 p-md-12 p-xl-12 p-pt-3 p-pb-3"
    *ngIf="shouldDisplayPromet"
  >
    <kodit-dashboard-promet-cards></kodit-dashboard-promet-cards>
  </div>

  <div
    class="p-col-12 p-md-12 p-xl-12 p-pt-3 p-pb-3"
    *ngIf="shouldDisplayPromet"
  >
    <kodit-promet-chart-bar></kodit-promet-chart-bar>
  </div>

  <div
    class="p-col-12 p-md-6 p-xl-6 p-pt-3 p-pr-3"
    *ngIf="shouldDisplayTopNStranki"
  >
    <kodit-top-n-stranki-chart-donut [vrstaStranke]="vrstaStrankeDobavljac">
    </kodit-top-n-stranki-chart-donut>
  </div>

  <div
    class="p-col-12 p-md-6 p-xl-6 p-pt-3 p-pl-lg-3"
    *ngIf="shouldDisplayTopNStranki"
  >
    <kodit-top-n-stranki-chart-donut [vrstaStranke]="vrstaStrankeKupac">
    </kodit-top-n-stranki-chart-donut>
  </div>
</div>
