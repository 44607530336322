import { Component, OnDestroy, OnInit } from '@angular/core';
import { IzvestajiClient, KpoIzlazniRacunDto } from '@kodit/core/data-api';
import { TableConfig } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import { GodinaRacunaDropdownService } from '@kodit/core/services';

@Component({
  selector: 'kodit-kpo-knjiga-table',
  templateUrl: './kpo-knjiga-table.component.html',
  styleUrls: ['./kpo-knjiga-table.component.scss'],
})
export class KpoKnjigaTableComponent implements OnInit, OnDestroy {
  /** Configurations */
  tableConfig!: TableConfig;

  /** Subscriptions */
  private _subs: Subscription = new Subscription();

  /** Props */
  faktureTable!: KpoIzlazniRacunDto[] | undefined;

  constructor(
    private _client: IzvestajiClient,
    private _dropdownService: GodinaRacunaDropdownService
  ) {}

  ngOnInit(): void {
    this.setTableConfig().then();
    this._loadTable();

    this._subs.add(
      this._dropdownService.getForm().valueChanges.subscribe((res) => {
        this._loadTable();
      })
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'KPO izveštaj',
      columns: [
        {
          field: 'datumKnjizenja',
          header: 'Datum i opis knjiženja',
          type: 'text',
          columns: [
            {
              field: 'brojFakture',
              header: 'Broj fakture',
              type: 'text',
            },
          ],
        },
        {
          field: 'iznosRoba',
          header: 'Od prodaje proizvoda',
          type: 'currency',
        },
        {
          field: 'iznosUsluga',
          header: 'Od izvršenih usluga',
          type: 'currency',
        },
        {
          field: 'ukupniPrihodi',
          header: 'Ukupni prihodi od delatnosti',
          type: 'currency',
        },
      ],
    });
  }

  private _loadTable() {
    this._subs.add(
      this._client.getForTable().subscribe((res) => {
        this.faktureTable = res.data?.kpoIzlazniRacunDtoList;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
