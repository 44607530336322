<kodit-racun-form-container
  [racunFormConfig]="racunFormConfig"
  [excludeTipovePredmeta]="excludedTipovePredmeta"
>
  <div
    class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid"
    osnovne-info-racuna-dodatni-input
  >
    <kodit-field
      fieldLabelFor="vrsta-radnog-naloga"
      fieldLabel="Vrsta"
      class="flex-2"
    >
      <kodit-vrsta-radnog-naloga-dropdown
        [disabled]="shouldBeDisabled"
        formCtrlId="vrsta-radnog-naloga"
        [formCtrl]="racunForm.controls.vrsta"
        (onItemChange)="handleVrstaRadnogNalogaChange($event)"
      >
      </kodit-vrsta-radnog-naloga-dropdown>
    </kodit-field>

    <kodit-field fieldLabelFor="rok" fieldLabel="Rok" class="flex-1">
      <kodit-calendar
        formCtrlId="rok"
        [formCtrl]="racunForm.controls.rok"
      ></kodit-calendar>
    </kodit-field>
  </div>
</kodit-racun-form-container>
