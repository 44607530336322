<form [formGroup]="repromaterijalForm">
  <div class="p-fluid">
    <div>
      <div class="p-field">
        <label for="naziv" kodit-form-label>Naziv *</label>
        <kodit-text-input
          formCtrlId="naziv"
          [formCtrl]="repromaterijalForm.controls.naziv"
        ></kodit-text-input>
      </div>

      <div class="p-field">
        <label for="naziv" kodit-form-label>Tarifni broj</label>
        <kodit-text-input
          formCtrlId="tarifniBrojStr"
          [formCtrl]="repromaterijalForm.controls.tarifniBrojStr"
        ></kodit-text-input>
      </div>
    </div>
  </div>
</form>