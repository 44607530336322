import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
  /** I/O */
  @Input() formCtrl: AbstractControl;
  @Input() optionLabel: string;
  @Input() options: any[] = [];
  @Input() formCtrlId: string;
  @Input() ctrlTooltip: string;
  @Input() ctrlPlaceholder: string;
  @Input() ctrlTooltipPosition: string;
  @Input() canFilter: boolean;
  @Input() ctrlFilterPlaceholder: string;
  @Input() ctrlFilterBy: string;
  @Input() ctrlStyleClass: string;
  @Input() isGrouped: boolean;
  @Input() canClear: boolean;
  @Input() ctrlDisabled: boolean;

  @Output() optionChanged = new EventEmitter();

  /**
   * Pass a template for the autocomplete in this component
   *
   * @usage
   * Can be passed inside the body of this component as:
   *  <app-my-wrapper ...>
   *      <ng-template ... #item let-obj>...</ng-template>
   *  </app-my-wrapper>
   */
  @ContentChild('item') item: TemplateRef<ElementRef>;
  @ContentChild('selectedItem') selectedItem: TemplateRef<ElementRef>;

  constructor() {}

  ngOnInit(): void {}

  handleOnItemChange(event: any) {
    this.optionChanged.emit(event);
  }
}
