import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import {
  CreatePdvEvidencijaPojedinacnaCommand,
  IPdvEvidencijaPojedinacnaDto,
  IVezaniRacunDto,
  PdvEvidencijaStatus,
  PdvEvidencijaTipDokumenta,
  PdvEvidencijeClient,
  SefKorigujPdvEvidencijaPojedinacnaCommand,
  TipRacuna,
  UpdatePdvEvidencijaPojedinacnaCommand,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { PdvEvidencijaPojedinacnaFormService } from '../pdv-evidencija-pojedinacna-form.service';
import {
  AlertService,
  LocationService,
  SharedService,
} from '@kodit/core/services';
import { ActivatedRoute } from '@angular/router';
import {
  FormHelper,
  VezaniRacunFormService,
} from '@kodit/core/form-definitions';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ConfirmationService } from 'primeng/api';
import { FormArray, Validators } from '@angular/forms';
import { PdvEvidencijaPojedinacnaService } from '../pdv-evidencija-pojedinacna.service';

@Component({
  selector: 'kodit-pdv-evidencija-pojedinacna-form',
  templateUrl: './pdv-evidencija-pojedinacna-form.component.html',
  styleUrls: ['./pdv-evidencija-pojedinacna-form.component.scss'],
})
export class PdvEvidencijaPojedinacnaFormComponent
  implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  useOnlyTipove: TipRacuna[];

  form: FormGroupTypeSafe<IPdvEvidencijaPojedinacnaDto>;
  isKoriguj: boolean;
  isDrugiInterniObracun: boolean = false;

  shouldDisableForm: boolean = false;

  constructor(
    private _fs: PdvEvidencijaPojedinacnaFormService,
    private _client: PdvEvidencijeClient,
    private _alert: AlertService,
    private _route: ActivatedRoute,
    private _formHelper: FormHelper,
    private _locationService: LocationService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _breadcrumbService: BreadcrumbService,
    private _confirmationService: ConfirmationService,
    private _vezaniRacunFS: VezaniRacunFormService,
    private _pdvPojedinacnaService: PdvEvidencijaPojedinacnaService,
    private _sharedService: SharedService
  ) {
    this.isKoriguj = false;
    this.isDrugiInterniObracun = false;
    this.form = this._fs.getForm(
      this._route.snapshot.data['evidencija'],
      this.isKoriguj
    );
    this.useOnlyTipove = [TipRacuna.AVANSNI_RACUN];
  }

  ngOnInit(): void {
    this.shouldDisableForm = this._route.snapshot.data['isPregledKorigovane'];
    this.isKoriguj = this._route.snapshot.data['isKoriguj'];
    this._pdvPojedinacnaService.setPdvForm = this.form;

    this._setBreadcrumb();

    this._setOsnovOdabiraDokumenta();
  }

  private _setOsnovOdabiraDokumenta() {
    if (
      this.form.value.osnovOdabiraDokumenta !== null &&
      this.form.value.osnovOdabiraDokumenta !== undefined
    )
      this.isDrugiInterniObracun = true;

    this.form.controls.tip!.valueChanges.subscribe(
      (tip: PdvEvidencijaTipDokumenta) => {
        const validators = [Validators.required];

        if (
          tip === PdvEvidencijaTipDokumenta.DRUGI_INTERNI_RACUN ||
          tip === PdvEvidencijaTipDokumenta.INTERNI_RACUN_ZA_PROMET_STRANOG_LICA
        ) {
          this.isDrugiInterniObracun = true;
          this.form.controls.osnovOdabiraDokumenta?.addValidators(validators);
        } else {
          this.form.controls.osnovOdabiraDokumenta?.removeValidators(
            validators
          );
          this.isDrugiInterniObracun = false;
          this.form.controls.osnovOdabiraDokumenta?.patchValue(null);
        }
        this.form.updateValueAndValidity();
      }
    );

    this._subs.add(
      this.form.controls.osnovica20?.valueChanges.subscribe((res) => {
        this.form.controls.obracunatPdv20?.patchValue(res! * 0.2);
      })
    );

    this._subs.add(
      this.form.controls.osnovica10?.valueChanges.subscribe((res) => {
        this.form.controls.obracunatPdv10?.patchValue(res! * 0.1);
      })
    );
  }

  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  private _setBreadcrumb() {
    this._breadcrumbService.set(
      '@izmenaPojedinacnePdvEvidencije',
      `Izmena pojedinačne pdv evidencije  ${this.form.value.broj}`
    );
    this._breadcrumbService.set(
      '@korigovanjePojedinacnePdvEvidencije',
      `Korigovanje pojedinačne pdv evidencije  ${this.form.getRawValue().broj}`
    );
    this._breadcrumbService.set(
      '@pregledKorigovanePojedinacnePdvEvidencije',
      `Pregled korigovane pdv evidencije: ${this.form.getRawValue().broj}`
    );
  }
  handleCancel() {
    this._confirmationService.confirm({
      message: 'Ukoliko otkažete sav nesnimljen sadržaj će biti izgubljen.',
      acceptLabel: 'Otkaži',
      rejectLabel: 'Ostani na stranici',
      header: 'Potvrdite otkazivanje',
      icon: 'far fa-check-circle',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._locationService.routeToCancelPdvEvidencija();
      },
    });
  }

  handleRouteBack() {
    this._locationService.routeBack();
  }

  handleEvidentiraj() {}

  handleSave() {
    // check is it valid
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      this._pdvPojedinacnaService.setFormSaveClicked = true;
      return;
    }

    // everything is ok, let's save it
    if (this.isKoriguj) {
      this._sharedService.displayLoadingScreen(
        true,
        'Korigovanje pojedinačne pdv evidencija...'
      );
      this._subs.add(
        this._client
          .sefKorigujPdvEvidencijaPojedinacna(
            this.form.value.id!,
            this.form.value as SefKorigujPdvEvidencijaPojedinacnaCommand
          )
          .subscribe((res) => {
            // if (res.succeeded) {
            // }
            this._alert.addSuccessMsg(
              res.messages != undefined ? res.messages[0] : ''
            );
            this._locationService.routeBack();
          })
      );
    } else if (this._fs.jeEdit) {
      this._sharedService.displayLoadingScreen(
        true,
        'Ažuriranje pojedinačne pdv evidencija...'
      );
      this._subs.add(
        this._client
          .updatePojedinacna(
            this.form.value.id!,
            this.form.value as UpdatePdvEvidencijaPojedinacnaCommand
          )
          .subscribe((res) => {
            this._alert.addSuccessMsg(
              res.messages != undefined ? res.messages[0] : ''
            );
            this._locationService.routeBack();
          })
      );
    } else {
      this._sharedService.displayLoadingScreen(
        true,
        'Kreiranje pojedinačne pdv evidencija...'
      );
      this._subs.add(
        this._client
          .createPojedinacna(
            this.form.value as CreatePdvEvidencijaPojedinacnaCommand
          )
          .subscribe((res) => {
            // if (res.succeeded) {
            // }
            this._alert.addSuccessMsg(
              res.messages != undefined ? res.messages[0] : ''
            );
            this._locationService.routeBack();
          })
      );
    }
  }

  handleVezaniRacunChange(vezaniRacun: IVezaniRacunDto) {
    (this.form.controls.povezaniDokumenti as FormArray).push(
      this._vezaniRacunFS.getFormGroup(vezaniRacun)
    );
  }

  ngOnDestroy() {
    this._fs.resetForm();
    this._pdvPojedinacnaService.setFormSaveClicked = false;
    this._subs.unsubscribe();
  }
}
