import { Component, OnInit } from '@angular/core';
import { PackageType } from '@kodit/core/data-api';
import { AuthService } from '@kodit/core/services';

@Component({
  selector: 'kodit-podesavanja-page',
  templateUrl: './podesavanja-page.component.html',
  styleUrls: ['./podesavanja-page.component.scss'],
})
export class PodesavanjaPageComponent implements OnInit {
  /** Props */
  shouldDisplayKompanijaCard: boolean;
  shouldDisplayKorisnickiNaloziCard: boolean;
  shouldDisplayRacunCard: boolean;
  shouldDisplayListeCard: boolean;
  shouldDisplayOznakeCard: boolean;
  shouldDisplayPocetnaStanjaCard: boolean;
  shouldDisplayFiskalnaCard: boolean;
  shouldDisplayArhivskaCard: boolean;

  constructor(
    private _authService: AuthService,
  ) {}

  ngOnInit(): void {
    this._loadDisplays();
  }
  
  private _loadDisplays() {
    //Kompanija
    this.shouldDisplayKompanijaCard = true;
    //Korisnicki nalozi
    this.shouldDisplayKorisnickiNaloziCard = true;
    //Racuni
    this.shouldDisplayRacunCard = this._authService.hasPackage([
      PackageType[PackageType.FAKTURISANJE],
      PackageType[PackageType.VELEPRODAJA],
    ]);
    //Registri
    this.shouldDisplayListeCard = this._authService.hasPackage([
      PackageType[PackageType.FAKTURISANJE],
      PackageType[PackageType.VELEPRODAJA],
      PackageType[PackageType.MALOPRODAJA]
    ]);
    //Oznake
    this.shouldDisplayOznakeCard = this._authService.hasPackage([
      PackageType[PackageType.FAKTURISANJE],
      PackageType[PackageType.VELEPRODAJA],
      PackageType[PackageType.MALOPRODAJA],
    ]);
    //Pocetna stanja
    this.shouldDisplayPocetnaStanjaCard = this._authService.hasPackage([
      PackageType[PackageType.FAKTURISANJE],
      PackageType[PackageType.VELEPRODAJA],
      PackageType[PackageType.MALOPRODAJA],
    ]);
    //Fiskalna
    this.shouldDisplayFiskalnaCard = this._authService.hasPackage(
      PackageType[PackageType.MALOPRODAJA]
    );
    //Arhiva
    this.shouldDisplayArhivskaCard = this._authService.hasPackage(
      PackageType[PackageType.ARHIVSKA_KNJIGA_I_DELOVODNIK]
    );
  }

  onEditProfil(): void {
    alert('work in progress');
  }
}
