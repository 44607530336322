import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stopaPdvText',
})
export class StopaPdvTextPipe implements PipeTransform {
  constructor() {}

  transform(stopa: number, ...args: any[]): any {
    switch (stopa) {
      case -1:
        return 'Izaberite stopu PDV-a';
      default:
        return `${stopa.toString()} %`;
    }
  }
}
