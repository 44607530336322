<kodit-card-container>
  <div card-title>Opšta pravila</div>

  <div card-right-side>
    <button
      icon="far fa-edit"
      pButton
      type="button"
      label="Izmeni"
      class="p-button p-button-outlined"
      (click)="onEdit()"
    ></button>
  </div>

  <div class="p-d-flex p-jc-between" card-body *ngIf="opstaPravilaDto">
    <div class="p-d-flex p-flex-column gap-6">
      <kodit-card-body-text
        label="Veličina papira"
        [text]="opstaPravilaDto.velicinaPapiraDto.velicinaPapiraStr"
      ></kodit-card-body-text>
    </div>
  </div>

</kodit-card-container>
