<div class="p-fluid">
  <p-autoComplete
    #controlRef
    [inputId]="ctrlId"
    [suggestions]="suggestions"
    [(ngModel)]="model"
    [field]="ctrlField"
    [placeholder]="ctrlPlaceholder"
    (completeMethod)="handleSearch($event)"
    (onSelect)="handleSelect($event)"
    (onUnselect)="handleUnselect($event)"
    (onKeyUp)="handleEnter($event)"
    appendTo="body"
    [multiple]="true"
    [forceSelection]="forceSelect"
  >
    <ng-template let-item pTemplate="item">
      <span class="new-item">
        ({{ item.email }}) ({{ item.isNew ? 'nov' : 'postojeći' }})
      </span>
    </ng-template>

    <ng-template let-item pTemplate="selectedItem">
      <!-- <span *ngIf="item.isNew" #newItemRef></span> -->
      <span> ({{ item.email }})  
        <span *ngIf="showNew"> 
          ({{ item.isNew ? 'nov' : 'postojeći' }})
      </span> </span>
    </ng-template>
  </p-autoComplete>
  <kodit-error-info-required
    [formCtrl]="ctrlFormArray"
  ></kodit-error-info-required>
</div>
