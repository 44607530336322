import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig} from "@kodit/core/shared-ui";

@Component({
  selector: 'kodit-razlog-storniranja-odbijanja-dialog',
  templateUrl: './razlog-storniranja-odbijanja-dialog.component.html',
  styleUrls: ['./razlog-storniranja-odbijanja-dialog.component.scss']
})
export class RazlogStorniranjaOdbijanjaDialogComponent implements OnInit {
  /** Props */
  komentar : string;

  constructor(public _dialogConfig: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.komentar = this._dialogConfig.data?.komentar;
  }
}
