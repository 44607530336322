<div class="p-d-flex p-jc-between p-mb-3 header">
  <div class="p-as-center">
    <b>Poziv na broj: </b> &nbsp; {{ pozivNaBrojDrugeStrane }}
  </div>
  <div class="p-d-flex p-jc-end">
    <input
      type="text"
      pInputText
      placeholder="Broj računa"
      class="p-mr-2"
      [formControl]="this.form.controls.brojRacuna"
      (keydown.enter)="onSearchSubmit()"
    />
    <button
      pButton
      icon="fas fa-search"
      type="submit"
      label="Pretraži"
      class="p-button p-button-primary p-mr-2"
      (click)="onSearchSubmit()"
    ></button>
    <button
      pButton
      type="button"
      label="Poništi"
      class="p-button p-button-outlined"
      (click)="onSearchReset()"
    ></button>
  </div>
</div>
<kodit-stranka-filter-multiselect
      *ngIf="showStrankaSearch"
      ctrlId="stranke"
      [strankeFormArray]="form.controls.stranke"
      [reset]="resetStrankaIds"
      class="p-mr-2 p-fluid"
    ></kodit-stranka-filter-multiselect>
<kodit-table [tableConfig]="tableConfig" [items]="racunTable"> </kodit-table>
