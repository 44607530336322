import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeof',
})
export class TypeofPipe implements PipeTransform {
  constructor() {}

  transform(value: any): any {
    //return typeof value;
    return Object.prototype.toString.call(value);
  }
}
