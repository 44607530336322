import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AvansniRacuniClient,
  GetAvansniRacunTableQuery,
  IAvansniRacunTableDto,
  TipPristupa,
  TipRacuna,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { LocationService, RacunService } from '@kodit/core/services';
import { concat, filterBy } from '@kodit/core/shared';
import {
  ActionClass,
  ActionType,
  OnTableInit,
  SelectMode,
  TableConfig,
} from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-avansni-racun-table',
  templateUrl: './avansni-racun-table.component.html',
  styleUrls: ['./avansni-racun-table.component.scss'],
})
export class AvansniRacunTableComponent
  implements OnInit, OnDestroy, OnTableInit {
  private _subs: Subscription = new Subscription();

  /** Props */
  avansniRacunTableList: IAvansniRacunTableDto[] = [];
  odabraneAvansneUplateZaRacunNonModified: IAvansniRacunTableDto[] = [];
  strankaId: number | null;
  vrstaRacuna: VrstaRacuna;

  /** Configurations */
  tableConfig: TableConfig;

  /** I/O */
  @Output() onRegistrujUplate = new EventEmitter();

  constructor(
    private _locationService: LocationService,
    private _client: AvansniRacuniClient,
    private _racunService: RacunService
  ) {
    this.strankaId = _racunService.getStrankaRacunaId;
    this.vrstaRacuna = _racunService.getVrstaRacuna;
  }

  ngOnInit(): void {
    this._load();

    this._subs.add(
      this._racunService.getOdabraneAvansneUplateZaRacunNonModified.subscribe(
        (res) => {
          this.odabraneAvansneUplateZaRacunNonModified = res;
        }
      )
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      selectMode: this.strankaId ? SelectMode.MULTI : SelectMode.SINGLE,
      rowsPerPage: 5,
      columns: [
        {
          field: 'broj',
          header: 'Broj',
          type: 'link',
          linkCallbackFunction: (itemIndex: number) => this._onInfo(itemIndex),
        },
        {
          field: 'datumIzdavanja',
          header: 'Datum izdavanja',
          type: 'text',
        },
        {
          field: 'brojUgovora',
          header: 'Broj ugovora',
          type: 'text',
        },
        {
          field: 'preostaloUkupnoOsnovica20',
          header: 'S20',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some(
            (x) => x.preostaloUkupnoOsnovica20 > 0
          ),
        },
        {
          field: 'pdv20',
          header: 'PDV 20%',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some((x) => x.pdv20 > 0),
        },
        {
          field: 'preostaloUkupnoOsnovica10',
          header: 'S10',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some(
            (x) => x.preostaloUkupnoOsnovica10 > 0
          ),
        },
        {
          field: 'pdv10',
          header: 'PDV 10%',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some((x) => x.pdv10 > 0),
        },
        {
          field: 'preostaloUkupnoOsnovica8',
          header: 'S8',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some(
            (x) => x.preostaloUkupnoOsnovica8 > 0
          ),
        },
        {
          field: 'pdv8',
          header: 'PDV 8%',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some((x) => x.pdv8 > 0),
        },
        {
          field: 'preostaloUkupnoOsnovicaAE10',
          header: 'AE10',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some(
            (x) => x.preostaloUkupnoOsnovicaAE10 > 0
          ),
        },
        {
          field: 'preostaloUkupnoOsnovicaAE20',
          header: 'AE20',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some(
            (x) => x.preostaloUkupnoOsnovicaAE20 > 0
          ),
        },
        {
          field: 'preostaloUkupnoOsnovicaO',
          header: 'O',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some(
            (x) => x.preostaloUkupnoOsnovicaO > 0
          ),
        },
        {
          field: 'preostaloUkupnoOsnovicaE',
          header: 'E',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some(
            (x) => x.preostaloUkupnoOsnovicaE > 0
          ),
        },
        {
          field: 'preostaloUkupnoOsnovicaR',
          header: 'R',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some(
            (x) => x.preostaloUkupnoOsnovicaR > 0
          ),
        },
        {
          field: 'preostaloUkupnoOsnovicaZ',
          header: 'Z',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some(
            (x) => x.preostaloUkupnoOsnovicaZ > 0
          ),
        },
        {
          field: 'preostaloUkupnoOsnovicaSS',
          header: 'SS',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some(
            (x) => x.preostaloUkupnoOsnovicaSS > 0
          ),
        },
        {
          field: 'preostaloUkupnoOsnovicaOE',
          header: 'OE',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some(
            (x) => x.preostaloUkupnoOsnovicaOE > 0
          ),
        },
        {
          field: 'preostaloUkupnoOsnovicaN',
          header: 'N',
          type: 'currency',
          currencyAlphaCharField: 'valuta',
          isVisible: this.avansniRacunTableList.some(
            (x) => x.preostaloUkupnoOsnovicaN > 0
          ),
        },
      ],
      headerActions: [
        {
          type: ActionType.MULTI_CUSTOM,
          actionClass: ActionClass.TEXT,
          label: 'Registruj avansne uplate na račun',
          icon: 'fas fa-plus',
          tipPristupa: TipPristupa.AVANSNI_RACUN_CRUD,
          isVisible: this.strankaId > 0,
          callback: (result: IAvansniRacunTableDto[]) => {
            this._racunService.setAvansneUplateRacuna = concat(
              this._racunService.getAvansneUplateRacuna,
              this._racunService.convertAvansneRacuneTableToAvansnaUplataRacuna(
                result
              )
            );
            this._racunService.setOdabraneAvansneUplateZaRacunNonModified = concat(
              this.odabraneAvansneUplateZaRacunNonModified,
              result
            );
            this.onRegistrujUplate.emit();
          },
        },
      ],
    });
  }

  private _onInfo(itemIndex: number) {
    this._locationService.routeToRacunKartica(
      this.avansniRacunTableList[itemIndex].id,
      TipRacuna.AVANSNI_RACUN,
      this.avansniRacunTableList[itemIndex].vrsta
    );
  }

  /**
   * ToDo: zeza nas jos brisanje, jer se ne pojavi ponovo u listi... kao da ostane u odabraneAvansneUplateZaRacunNonModified ili sta vec...
   *      + zeza dodavanje, jer ubacuje samo ono sto je selektovano, tako da ove prethodne izbaci
   * ToDo: ubaciti loadsh u novi fajl i napraviti fasadu za funkcije...
   */

  private _load() {
    const stavkeRacuna = this._racunService.getStavkeArray.getRawValue();
    const pdvStopeSet = new Set(stavkeRacuna.map((item) => item.stopaPDV));
    const pdvKategoijeSet = new Set(
      stavkeRacuna
        .filter((item) => item.pdvKategorija !== null)
        .map((item) => item.pdvKategorija)
    );

    this._subs.add(
      this._client
        .getList(
          new GetAvansniRacunTableQuery({
            samoAktivniRacuni: true,
            strankaId: this.strankaId,
            vrstaRacuna: this.vrstaRacuna,
            pdvStope: [...pdvStopeSet],
            pdvKategorije: [...pdvKategoijeSet]
          })
        )
        .subscribe((res) => {
          if (this.odabraneAvansneUplateZaRacunNonModified?.length > 0) {
            this.avansniRacunTableList = filterBy(
              res.data.responseList,
              (x: IAvansniRacunTableDto) => {
                return (
                  this.odabraneAvansneUplateZaRacunNonModified.findIndex(
                    (a) => a.id === x.id
                  ) === -1
                );
              }
            );
          } else {
            this.avansniRacunTableList = res.data.responseList;
          }
          this.setTableConfig().then();
        })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
