import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IGetPdvEvidencijaZbirna39TableQuery } from '@kodit/core/data-api';
import { Store } from '@ngrx/store';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { clearFilter, setFilter } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';

@Component({
  selector: 'kodit-pdv-evidencija-zbirna-table39-filter',
  templateUrl: './pdv-evidencija-zbirna-table39-filter.component.html',
  styleUrls: ['./pdv-evidencija-zbirna-table39-filter.component.scss'],
})
export class PdvEvidencijaZbirnaTable39FilterComponent implements OnInit {
  /**Props */
  filterData!: IGetPdvEvidencijaZbirna39TableQuery;
  form!: FormGroupTypeSafe<IGetPdvEvidencijaZbirna39TableQuery>;

  /** I/O */
  @Output()
  onSubmitForm = new EventEmitter<IGetPdvEvidencijaZbirna39TableQuery>();

  constructor(private _fb: FormBuilderTypeSafe, private _storage: Store) {}

  ngOnInit(): void {
    this.form = this.getForm(this.filterData);
  }

  onFilterSubmit() {
    this._storage.dispatch(
      setFilter({
        key: 'PDV|Zbirne39',
        filter: JSON.stringify(this.form.value),
      })
    );
  }

  onFilterHide(shouldReset: boolean) {
    if (shouldReset) {
      this._storage.dispatch(
        clearFilter({
          key: 'PDV|Zbirne39',
        })
      );
    }
  }

  private getForm(
    model: IGetPdvEvidencijaZbirna39TableQuery
  ): FormGroupTypeSafe<IGetPdvEvidencijaZbirna39TableQuery> {
    return this._fb.group<IGetPdvEvidencijaZbirna39TableQuery>({
      brojObracuna: new FormControl(model.brojObracuna),
      statusi: new FormControl(model.statusi),
      mesec: new FormControl(model.mesec),
    });
  }
}
