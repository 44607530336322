import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { BaseService } from '@kodit/core/services';
import {
  GetKorisnikQuery,
  KorisniciClient,
  KorisnikDto,
} from '@kodit/core/data-api';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KorisnikResolver
  extends BaseService
  implements Resolve<KorisnikDto> {
  constructor(private _client: KorisniciClient) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<KorisnikDto> | Promise<KorisnikDto> | KorisnikDto {
    const email = route.paramMap.get('email');
    return this._client.get(new GetKorisnikQuery({ email: email })).pipe(
      map((res) => res.data),
      catchError(this.handleError),
      finalize(() => {})
    );
  }
}
