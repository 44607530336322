import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IUplatnicaDto } from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';

@Injectable({
  providedIn: 'root',
})
export class UplatnicaFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetUplatnicaFormGroup(
    racunId: number
  ): FormGroupTypeSafe<IUplatnicaDto> {
    return this._fb.group<IUplatnicaDto>({
      racunId: new FormControl(racunId, {
        validators: [Validators.required],
      }),
      sifraPlacanja: new FormControl(null, {
        validators: [Validators.required],
      }),
      svrhaUplate: new FormControl(null, {
        validators: [Validators.required, Validators.maxLength(35)],
      }),
      brojRata: new FormControl(null, {
        validators: [Validators.required],
      }),
      tekuciRacunPrimaoca: new FormControl(null, {
        validators: [Validators.required],
      }),
    });
  }
}

export declare type UplatnicaForm = FormGroupTypeSafe<IUplatnicaDto>;
