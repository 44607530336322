<kodit-info-container *ngIf="neobradjenRacun && racunForm">
  <!-- Leva strana -->
  <ng-container body-2-of-3>
    <!-- osnovne info sa racuna -->
    <kodit-card-container>
      <div card-title>
        Dobavljač: <span>{{ neobradjenRacun.dobavljac }}</span>
      </div>
      <div card-subtitle>
        <div *ngIf="neobradjenRacun.dobavljacMB?.length > 0">
          Matični broj: {{ neobradjenRacun.dobavljacMB }}
        </div>
        <div *ngIf="neobradjenRacun.dobavljacJBKJS?.length > 0">
          JBKJS: {{ neobradjenRacun.dobavljacJBKJS }}
        </div>
        <div *ngIf="neobradjenRacun.dobavljacPib?.length > 0">
          Pib: {{ neobradjenRacun.dobavljacPib }}
        </div>
        <div
          *ngIf="
            neobradjenRacun.dobavljacMB?.length === 0 &&
            neobradjenRacun.dobavljacJBKJS?.length === 0 &&
            neobradjenRacun.dobavljacPib?.length === 0
          "
        >
          <span class="p-error">Stranka nije validna</span>
        </div>
      </div>

      <div
        [ngClass]="
          'p-text-center info-badge tip-racuna-' +
          neobradjenRacun.tipRacunaDto.tipBadgeStr
        "
        card-right-side
      >
        {{ neobradjenRacun.tipRacunaDto.tipStr.toUpperCase() }}
      </div>

      <div class="p-d-flex p-flex-column gap-12" card-body>
        <div class="p-d-flex gap-25 p-fluid">
          <kodit-field
            fieldLabel="Generiši broj po pravilu"
            fieldLabelFor="pravilo-za-broj"
          >
            <kodit-pravilo-za-broj-racuna-dropdown
              [formCtrl]="
                racunForm.controls.racunDto.get('praviloSerijskogBrojaId')
              "
              formCtrlId="pravilo-za-broj"
            >
            </kodit-pravilo-za-broj-racuna-dropdown>
          </kodit-field>
          <!-- <div class="ek-max-w-200-px">
            <kodit-broj-racuna-input
              inputLabel="Ulazni broj računa"
              [brojCtrl]="racunForm.controls.racunDto.get('originalanBroj')"
              [shouldBeDisabled]="true"
            ></kodit-broj-racuna-input>
          </div> -->
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row p-jc-between">
          <div class="p-d-flex p-flex-column gap-12">
            <kodit-card-body-text
              label="Datum izdavanja"
              [text]="neobradjenRacun.datumIzdavanjaStr"
            ></kodit-card-body-text>
            <kodit-card-body-text
              label="Mesto izdavanja"
              [text]="neobradjenRacun.mestoIzdavanjaIPrometa"
            ></kodit-card-body-text>
            <kodit-card-body-text
              label="Datum valute / datum dospeća"
              [text]="neobradjenRacun.datumValuteStr"
            ></kodit-card-body-text>
            <kodit-card-body-text
              label="Šifra plaćanja"
              [text]="neobradjenRacun.sifraPlacanja"
            ></kodit-card-body-text>
            <kodit-card-body-text
              *ngIf="neobradjenRacun.tipRacunaDto.tip !== dokumentOSmanjenjuValue"
              label="Datum prometa"
              [text]="neobradjenRacun.datumPrometaStr"
            ></kodit-card-body-text>
          </div>
          <div class="p-d-flex p-flex-column gap-12">
            <kodit-card-body-text
              label="Poziv na broj"
              [text]="neobradjenRacun.pozivNaBroj"
            ></kodit-card-body-text>
            <kodit-card-body-text
              label="Broj modela"
              [text]="neobradjenRacun.brojModela"
            ></kodit-card-body-text>
            <kodit-card-body-text
              label="Broj ugovora"
              [text]="neobradjenRacun.brojUgovora"
            ></kodit-card-body-text>
            <kodit-card-body-text
              label="Broj narudžbenice/fakture/ponude"
              [text]="neobradjenRacun.brojNarudzbenice"
            ></kodit-card-body-text>
            <kodit-card-body-text
              label="Broj tendera"
              [text]="neobradjenRacun.brojTendera"
            ></kodit-card-body-text>

            <kodit-card-body-text
              label="Šifra objekta"
              [text]="neobradjenRacun.sifraObjekta"
            ></kodit-card-body-text>
          </div>
        </div>
      </div>
    </kodit-card-container>

    <!-- relevantni (PDF) dokumenti -->
    <div class="p-mt-5" *ngIf="neobradjenRacun.pdfDokumentList.length > 0">
      <kodit-relevantni-dokumenti-po-racunu
        [dokumentList]="relevantniDokumenti"
      ></kodit-relevantni-dokumenti-po-racunu>
    </div>

    <!-- obrada stavki racuna -->
    <div class="p-mt-5">
      <kodit-neobradjen-racun-obrada-stavke-card
        [neobradjeneStavke]="neobradjenRacun.stavkeDto"
        [tipRacuna]="neobradjenRacun.tipRacunaDto.tip"
      ></kodit-neobradjen-racun-obrada-stavke-card>
    </div>

    <!-- Oznake racuna -->
    <kodit-card-container cardClass="p-mt-3">
      <div card-title>Oznake</div>
      <div card-body>
        <kodit-oznake-racuna
          [racunForm]="racunForm.controls.racunDto"
        ></kodit-oznake-racuna>
      </div>
    </kodit-card-container>

    <!-- Ukupan iznos -->
    <div class="p-mt-5">
      <kodit-card-container>
        <div card-title>Ukupan iznos</div>
        <ng-container card-body>
          <kodit-ukupan-iznos-table minWidth="100%"></kodit-ukupan-iznos-table>
        </ng-container>
      </kodit-card-container>
    </div>
  </ng-container>

  <!-- Desna strana -->
  <!-- <ng-container body-1-of-3> -->
  <div class="p-d-flex p-flex-row p-flex-md-column" body-1-of-3>
    <div
      style="height: 90vh"
      *ngIf="pdfInnerHtml"
      [innerHTML]="pdfInnerHtml"
    ></div>
  </div>
  <!-- </ng-container> -->
</kodit-info-container>

<!-- AKCIJE -->
<div class="p-mt-5" card style="position: sticky; bottom: 0; z-index: 9">
  <kodit-akcije-racuna
    *ngIf="!showOdobriOdbaci"
    saveLabel="Obradi"
    saveIcon="fa-regular fa-arrow-right-arrow-left"
    saveTooltip="Obradite ulazni račun"
    cancelLabel="Nazad"
    cancelIcon="fa-regular fa-arrow-left"
    cancelTooltip="Vratiti se na tabelu"
    (saveClicked)="handleObradi()"
    (cancelClicked)="onReturn()"
  >
  </kodit-akcije-racuna>

  <kodit-akcije-racuna
    *ngIf="showOdobriOdbaci"
  >
    <button
      pButton
      icon="fa-regular fa-arrow-right-arrow-left"
      type="submit"
      label="Obradi"
      class="p-button-primary"
      pTooltip="Obradite ulazni račun"
      tooltipPosition="bottom"
      (click)="handleObradi()"
    ></button>
  </kodit-akcije-racuna>
</div>
