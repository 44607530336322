<p-sidebar
  #NotifikacijeSidebar
  [(visible)]="showSidebar"
  position="right"
  [(showCloseIcon)]="showcloseIcon"
  class="p-sidebar-md"
  (onHide)="onHide()"
  (onShow)="onShow()"
>
  <div class="header p-d-flex p-jc-between">
    <span
      style="
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        color: rgb(46, 57, 75);
      "
    >
      Obaveštenja
    </span>
    <a
      (click)="onHide()"
      class="p-d-flex p-ai-center p-jc-center close"
      tabindex="0"
    >
      <img src="assets/icons/x.svg" width="24" height="24" alt="user-image" />
    </a>
  </div>

  <p-scrollPanel
    class="layout-menu-container"
    [style]="{ width: '100%', height: 'calc(100vh - 130px)' }"
  >
    <div class="_body">
      <ul>
        <!-- <p-button
      (click)="handleOznaciKaoProcitano()"
      *ngIf="notifikacijeKorisnika.length > 0"
      label="Označi kao pročitano"
      styleClass="p-button-link"
    ></p-button> -->
        <!-- <kodit-divider></kodit-divider> -->
        <ng-container *ngIf="notifikacijeKorisnika.length > 0">
          <kodit-notification-item
            *ngFor="let notifikacija of notifikacijeKorisnika | slice: 0:5"
            [notifikacija]="notifikacija"
          ></kodit-notification-item>
        </ng-container>
        <ng-container *ngIf="notifikacijeKorisnika.length == 0">
          <span class="p-text-italic p-p-2">Nemate novih obaveštenja</span>
        </ng-container>
        <kodit-divider></kodit-divider>
        <div class="p-d-flex p-p-2">
          <p-button
            (click)="gotToNotifikacije()"
            label="Pogledaj sva obaveštenja"
            styleClass="p-button-link"
          ></p-button>
        </div>
      </ul>
    </div>
  </p-scrollPanel>
</p-sidebar>
