<kodit-racun-form-container [racunFormConfig]="racunFormConfig">
  <div class="p-flex-md-row even-columns gap-12" dodatni-input>
    <kodit-field fieldLabel="Broj ugovora" fieldLabelFor="broj-ugovora">
      <kodit-text-input
        formCtrlId="broj-ugovora"
        [formCtrl]="racunForm.controls.racunDto.get('brojUgovora')"
        [formCtrlClass]="{
          'p-invalid':
            racunForm.errors?.dodatniPodaci &&
            racunForm.controls.racunDto.get('brojUgovora').touched
        }"
      >
      </kodit-text-input>
    </kodit-field>

    <kodit-field
      fieldLabel="Broj narudžbenice/fakture/ponude"
      fieldLabelFor="broj-narudzbenice"
    >
      <kodit-text-input
        formCtrlId="broj-narudzbenice"
        [formCtrl]="racunForm.controls.racunDto.get('brojNarudzbenice')"
        [formCtrlClass]="{
          'p-invalid':
            racunForm.errors?.dodatniPodaci &&
            racunForm.controls.racunDto.get('brojNarudzbenice').touched
        }"
      >
      </kodit-text-input>
    </kodit-field>

    <kodit-field fieldLabel="Broj tendera" fieldLabelFor="broj-tendera">
      <kodit-text-input
        formCtrlId="broj-tendera"
        [formCtrl]="racunForm.controls.racunDto.get('brojTendera')"
        [formCtrlClass]="{
          'p-invalid':
            racunForm.errors?.dodatniPodaci &&
            racunForm.controls.racunDto.get('brojTendera').touched
        }"
      >
      </kodit-text-input>
    </kodit-field>

    <kodit-field fieldLabel="Tekući račun">
      <kodit-tekuci-racun-dropdown
        [tekuciRacunCtrl]="racunForm.controls.racunDto.get('tekuciRacunId')"
      ></kodit-tekuci-racun-dropdown>
    </kodit-field>

    <kodit-field fieldLabel="Šifra objekta" fieldLabelFor="sifra-objekta">
      <kodit-text-input
        formCtrlId="sifra-objekta"
        [formCtrl]="racunForm.controls.racunDto.get('sifraObjekta')"
        class="flex-50"
      >
      </kodit-text-input>
    </kodit-field>
  </div>
</kodit-racun-form-container>
