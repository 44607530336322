import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'kodit-izvestaj-card',
  templateUrl: './izvestaj-card.component.html',
  styleUrls: ['./izvestaj-card.component.scss'],
})
export class IzvestajCardComponent implements OnInit {
  //
  @Input() izvestajCardTitle!: string;
  @Input() izvestajCardSubtitle!: string;
  @Input() cardIcon!: string;
  @Input() cardLink!: string;

  constructor(private _router: Router) {}

  ngOnInit(): void {}

  redirectToLink() {
    this._router.navigate(['kompanija/izvestaji', this.cardLink]);
  }
}
