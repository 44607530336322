
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  IRazlogPdvIzuzecaDto,
  OznakaPdvKategorije,
  SharedClient,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-pdv-razlog-izuzeca-dropdown',
  templateUrl: './pdv-razlog-izuzeca-dropdown.component.html',
  styleUrls: ['./pdv-razlog-izuzeca-dropdown.component.scss'],
})
export class PdvRazlogIzuzecaDropdownComponent
  implements OnInit, OnDestroy, OnChanges {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  items: {
    naziv: string;
    opis: string;
    value: number;
  }[] = [];
  razlogPdvIzuzecaDtoList: IRazlogPdvIzuzecaDto[] = [];

  /** Conditional props */
  showDataAfterApiResponse : boolean = false;

  /** I/O */
  @Input() pdvRazlogIzuzecaCtrl: AbstractControl;
  @Input() oznakaPdvKategorije: OznakaPdvKategorije;
  @Input() isVisible : boolean = false;
  @Output() onItemChange = new EventEmitter<any>();

  // add output()

  constructor(
    private _client: SharedClient,
  ) {
    this._load();
  }

  ngOnInit(): void {
    this._load();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.oznakaPdvKategorije.isFirstChange() &&
      changes.oznakaPdvKategorije.currentValue !==
        changes.oznakaPdvKategorije.previousValue
    ) {
      this._mapItems();
    }
  }

  handleOptionChanged(event: any) {
    this.onItemChange.emit(event);
  }

  private _load() {
    this._subs.add(
      this._client.getRazlogPdvIzuzecaDropdown().subscribe((res) => {
        this.razlogPdvIzuzecaDtoList = res.data.razlogPdvIzuzecaDtoList;
        if (this.oznakaPdvKategorije) {
          this._mapItems();
        }
        this.showDataAfterApiResponse = true;
      })
    );
  }

  private _mapItems() {
    this.items = this.razlogPdvIzuzecaDtoList
      .filter((x) => x.oznakaPdvKategorije === this.oznakaPdvKategorije)
      .map((o) => ({
        value: o.id,
        naziv: o.naziv,
        opis: o.opis,
      }));
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
