import { FormControl } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import {
  IPredmetStavkeFilterDto,
  IUslugaDto,
  TipArtikla,
  TipPredmeta,
} from '@kodit/core/data-api';
import {
  PredmetStavkeFormGroupRequest,
  PredmetStavkeFormService,
} from './predmet-stavke-form.service';

@Injectable({
  providedIn: 'root',
})
export class UslugaFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _predmetStavkeFS: PredmetStavkeFormService
  ) {}

  /**
   * Konvertuje model za uslugu i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model usluge od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetUslugaFormGroup(model?: IUslugaDto): FormGroupTypeSafe<IUslugaDto> {
    return this._fb.group<IUslugaDto>({
      predmetStavkeDto: this._predmetStavkeFS.GetPredmetStavkeFormGroup(
        new PredmetStavkeFormGroupRequest({
          model: model?.predmetStavkeDto,
          tipPredmeta: TipPredmeta.USLUGA,
          tipArtikla: TipArtikla.USLUGA,
        })
      ),
    });
  }

  public GetUslugaTableRequest(
    model?: IPredmetStavkeFilterDto
  ): FormGroupTypeSafe<IPredmetStavkeFilterDto> {
    return this._fb.group<IPredmetStavkeFilterDto>({
      naziv: new FormControl(model?.naziv),
      sifra: new FormControl(model?.sifra),
    });
  }
}
