import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddPredefinisaniTekstCommand, FiskalniRacuniClient} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-predefinisani-tekst-fiskalni-form',
  templateUrl: './predefinisani-tekst-fiskalni-form.component.html',
  styleUrls: ['./predefinisani-tekst-fiskalni-form.component.scss']
})
export class PredefinisaniTekstFiskalniFormComponent implements OnInit, OnDestroy {

  private _subs: Subscription = new Subscription();
  form!: FormGroup;

  constructor(
    private _client: FiskalniRacuniClient, 
    private _alertService: AlertService,
    private _fb: FormBuilder,
    private _formHelper: FormHelper,
    private _config: DynamicDialogConfig,
    public _dialogRef: DynamicDialogRef,
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group({
      textBefore: new FormControl('', [Validators.maxLength(2048)]),
      textAfter: new FormControl('', [Validators.maxLength(2048)])
    });
  
    const predefinisaniTekst = this._config.data;
    if (predefinisaniTekst) {
      this.form.patchValue({
        textBefore: predefinisaniTekst.textBefore,
        textAfter: predefinisaniTekst.textAfter
      });
    }

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleSave();
      })
    );
  }

  public handleSave() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }
    
      const textData = {
        textBefore: this.form.value.textBefore,
        textAfter: this.form.value.textAfter
      };

      this._subs.add(
       this._client
        .addPredefinisaniTekst(textData as AddPredefinisaniTekstCommand)
        .subscribe((res) => {
        if (res.succeeded) {
          this._alertService.addSuccessMsg(res.data);
          this._dialogRef.close(res.data);
          return;
        }
        this._alertService.addFailedMsg(res.messages[0]);
      })
      );
    
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
