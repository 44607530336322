<kodit-table
  [tableConfig]="tableConfig"
  [items]="avansniRacunTableList"
  *ngIf="avansniRacunTableList.length > 0"
>
</kodit-table>

<div
  *ngIf="avansniRacunTableList.length === 0 && strankaId"
  class="p-text-left p-p-3"
>
  Za stranku ne postoje poslati ili odobreni avansni računi
</div>
