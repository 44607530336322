<div class="p-d-flex p-flex-column p-flex-md-row p-jc-end p-ai-end p-fluid">
  <div id="tabela-ukupno" [ngStyle]="{ 'min-width': minWidth }">
    <table>
      <!-- ************************************************************** -->
      <!-- Zbir stavki -->
      <!-- ************************************************************** -->
      <tr
        *ngIf="
          ukupanIznos.value.zbirStavki20 !== null &&
          ukupanIznos.value.zbirStavki20 !== 0
        "
      >
        <td>Zbir stavki sa stopom 20%</td>
        <td>
          {{ ukupanIznos.value.zbirStavki20 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.zbirStavki10 !== null &&
          ukupanIznos.value.zbirStavki10 !== 0
        "
      >
        <td>Zbir stavki sa stopom 10%</td>
        <td>
          {{ ukupanIznos.value.zbirStavki10 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.zbirStavki8 !== null &&
          ukupanIznos.value.zbirStavki8 !== 0
        "
      >
        <td>Zbir stavki sa stopom 8%</td>
        <td>
          {{ ukupanIznos.value.zbirStavki8 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.zbirStavkiAE10 !== null &&
          ukupanIznos.value.zbirStavkiAE10 > 0
        "
      >
        <td>Zbir stavki - obrnuto obračunavanje PDV - 10%</td>
        <td>
          {{ ukupanIznos.value.zbirStavkiAE10 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.zbirStavkiAE20 !== null &&
          ukupanIznos.value.zbirStavkiAE20 > 0
        "
      >
        <td>Zbir stavki - obrnuto obračunavanje PDV - 20%</td>
        <td>
          {{ ukupanIznos.value.zbirStavkiAE20 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.zbirStavkiO !== null &&
          ukupanIznos.value.zbirStavkiO > 0
        "
      >
        <td>Zbir stavki - nije predmet oporezivanja PDV</td>
        <td>
          {{ ukupanIznos.value.zbirStavkiO | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.zbirStavkiE !== null &&
          ukupanIznos.value.zbirStavkiE > 0
        "
      >
        <td>
          Zbir stavki - poresko oslobođenje bez prava na odbitak prethodnog
          poreza
        </td>
        <td>
          {{ ukupanIznos.value.zbirStavkiE | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.zbirStavkiR !== null &&
          ukupanIznos.value.zbirStavkiR > 0
        "
      >
        <td>Zbir stavki - izuzimanje od PDV</td>
        <td>
          {{ ukupanIznos.value.zbirStavkiR | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.zbirStavkiZ !== null &&
          ukupanIznos.value.zbirStavkiZ > 0
        "
      >
        <td>
          Zbir stavki - poresko oslobođenje sa pravom na odbitak prethodnog
          poreza
        </td>
        <td>
          {{ ukupanIznos.value.zbirStavkiZ | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.zbirStavkiSS !== null &&
          ukupanIznos.value.zbirStavkiSS > 0
        "
      >
        <td>Zbir stavki - posebni postupci oporezivanja</td>
        <td>
          {{ ukupanIznos.value.zbirStavkiSS | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.zbirStavkiOE !== null &&
          ukupanIznos.value.zbirStavkiOE > 0
        "
      >
        <td>Zbir stavki - nije predmet oporezivanja PDV 2</td>
        <td>
          {{ ukupanIznos.value.zbirStavkiOE | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.zbirStavkiN !== null &&
          ukupanIznos.value.zbirStavkiN > 0
        "
      >
        <td>Zbir stavki - umanjenje</td>
        <td>
          {{ ukupanIznos.value.zbirStavkiN | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr>
        <td colspan="2" class="table-divider"></td>
      </tr>
      <!-- ************************************************************** -->
      <!-- Smanjenje naknade -->
      <!-- ************************************************************** -->
      <tr
        *ngIf="
          (ukupanIznos.value.zbirStavki20 !== null &&
            ukupanIznos.value.zbirStavki20 !== 0 &&
            !jeObrada && !jeKonvertovanje)
          ||
          ((jeObrada || jeKonvertovanje) &&
            ukupanIznos.value.smanjenjeNaknade20 > 0 &&
            ukupanIznos.value.smanjenjeNaknade20 !== null)
        "
      >
        <td>Smanjenje osnovice - stopa 20%</td>
        <td *ngIf="!jeObrada && !jeKonvertovanje">
          <kodit-number-input
            [formCtrl]="ukupanIznos.controls.smanjenjeNaknade20"
            (onInputLeft)="updateUkupanIznos()"
          ></kodit-number-input>
        </td>
        <td *ngIf="jeObrada || jeKonvertovanje ">
          {{
            ukupanIznos.value.smanjenjeNaknade20 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          (ukupanIznos.value.zbirStavki10 !== null &&
            ukupanIznos.value.zbirStavki10 !== 0 &&
            !jeObrada && !jeKonvertovanje)
          ||
          ((jeObrada || jeKonvertovanje) &&
            ukupanIznos.value.smanjenjeNaknade10 > 0 &&
            ukupanIznos.value.smanjenjeNaknade10 !== null)
        "
      >
        <td>Smanjenje osnovice - stopa 10%</td>
        <td *ngIf="!jeObrada && !jeKonvertovanje">
          <kodit-number-input
            [formCtrl]="ukupanIznos.controls.smanjenjeNaknade10"
            (onInputLeft)="updateUkupanIznos()"
          ></kodit-number-input>
        </td>
        <td *ngIf="jeObrada || jeKonvertovanje">
          {{
            ukupanIznos.value.smanjenjeNaknade10 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          (ukupanIznos.value.zbirStavki8 !== null &&
            ukupanIznos.value.zbirStavki8 !== 0 &&
            !jeObrada && !jeKonvertovanje)
          ||
          ((jeObrada || jeKonvertovanje) &&
            ukupanIznos.value.smanjenjeNaknade8 > 0 &&
            ukupanIznos.value.smanjenjeNaknade8 !== null)
        "
      >
        <td>Smanjenje naknade - stopa 8%</td>
        <td *ngIf="!jeObrada && !jeKonvertovanje">
          <kodit-number-input
            [formCtrl]="ukupanIznos.controls.smanjenjeNaknade8"
            (onInputLeft)="updateUkupanIznos()"
          ></kodit-number-input>
        </td>
        <td *ngIf="jeObrada || jeKonvertovanje ">
          {{
          ukupanIznos.value.smanjenjeNaknade8 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          (ukupanIznos.value.zbirStavkiAE10 !== null &&
            ukupanIznos.value.zbirStavkiAE10 !== 0 &&
            !jeObrada && !jeKonvertovanje) ||
          ((jeObrada || jeKonvertovanje) &&
            ukupanIznos.value.smanjenjeNaknadeAE10 > 0 &&
            ukupanIznos.value.smanjenjeNaknadeAE10 !== null)
        "
      >
        <td>Smanjenje osnovice - obrnuto obračunavanje PDV - stopa 10%</td>
        <td *ngIf="!jeObrada && !jeKonvertovanje">
          <kodit-number-input
            [formCtrl]="ukupanIznos.controls.smanjenjeNaknadeAE10"
            (onInputLeft)="updateUkupanIznos()"
          ></kodit-number-input>
        </td>
        <td *ngIf="jeObrada || jeKonvertovanje">
          {{
            ukupanIznos.value.smanjenjeNaknadeAE10 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          (ukupanIznos.value.zbirStavkiAE20 !== null &&
            ukupanIznos.value.zbirStavkiAE20 !== 0 &&
            !jeObrada && !jeKonvertovanje) ||
          ((jeObrada || jeKonvertovanje) &&
            ukupanIznos.value.smanjenjeNaknadeAE20 > 0 &&
            ukupanIznos.value.smanjenjeNaknadeAE20 !== null)
        "
      >
        <td>Smanjenje osnovice - obrnuto obračunavanje PDV - stopa 20%</td>
        <td *ngIf="!jeObrada && !jeKonvertovanje">
          <kodit-number-input
            [formCtrl]="ukupanIznos.controls.smanjenjeNaknadeAE20"
            (onInputLeft)="updateUkupanIznos()"
          ></kodit-number-input>
        </td>
        <td *ngIf="jeObrada || jeKonvertovanje">
          {{
            ukupanIznos.value.smanjenjeNaknadeAE20 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          (ukupanIznos.value.zbirStavkiO !== null &&
            ukupanIznos.value.zbirStavkiO !== 0 &&
            !jeObrada && !jeKonvertovanje)
          ||
          ((jeObrada || jeKonvertovanje) &&
            ukupanIznos.value.smanjenjeNaknadeO > 0 &&
            ukupanIznos.value.smanjenjeNaknadeO !== null)
        "
      >
        <td>Smanjenje naknade - nije predmet oporezivanja PDV</td>
        <td *ngIf="!jeObrada && !jeKonvertovanje">
          <kodit-number-input
            [formCtrl]="ukupanIznos.controls.smanjenjeNaknadeO"
            (onInputLeft)="updateUkupanIznos()"
          ></kodit-number-input>
        </td>
        <td *ngIf="jeObrada || jeKonvertovanje">
          {{ ukupanIznos.value.smanjenjeNaknadeO | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          (ukupanIznos.value.zbirStavkiE !== null &&
            ukupanIznos.value.zbirStavkiE !== 0 &&
            !jeObrada && !jeKonvertovanje)
          ||
          ((jeObrada || jeKonvertovanje) &&
            ukupanIznos.value.smanjenjeNaknadeE > 0 &&
            ukupanIznos.value.smanjenjeNaknadeE !== null)
        "
      >
        <td>
          Smanjenje naknade - poresko oslobođenje bez prava na odbitak
          prethodnog poreza
        </td>
        <td *ngIf="!jeObrada && !jeKonvertovanje">
          <kodit-number-input
            [formCtrl]="ukupanIznos.controls.smanjenjeNaknadeE"
            (onInputLeft)="updateUkupanIznos()"
          ></kodit-number-input>
        </td>
        <td *ngIf="jeObrada || jeKonvertovanje">
          {{ ukupanIznos.value.smanjenjeNaknadeE | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          (ukupanIznos.value.zbirStavkiR !== null &&
            ukupanIznos.value.zbirStavkiR !== 0 &&
            !jeObrada && !jeKonvertovanje)
          ||
          ((jeObrada || jeKonvertovanje) &&
            ukupanIznos.value.smanjenjeNaknadeR > 0 &&
            ukupanIznos.value.smanjenjeNaknadeR !== null)
        "
      >
        <td>Smanjenje naknade - izuzimanje od PDV</td>
        <td *ngIf="!jeObrada && !jeKonvertovanje">
          <kodit-number-input
            [formCtrl]="ukupanIznos.controls.smanjenjeNaknadeR"
            (onInputLeft)="updateUkupanIznos()"
          ></kodit-number-input>
        </td>
        <td *ngIf="jeObrada || jeKonvertovanje">
          {{ ukupanIznos.value.smanjenjeNaknadeR | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          (ukupanIznos.value.zbirStavkiZ !== null &&
            ukupanIznos.value.zbirStavkiZ !== 0 &&
            !jeObrada && !jeKonvertovanje)
          ||
          ((jeObrada || jeKonvertovanje) &&
            ukupanIznos.value.smanjenjeNaknadeZ > 0 &&
            ukupanIznos.value.smanjenjeNaknadeZ !== null)
        "
      >
        <td>
          Smanjenje naknade - poresko oslobođenje sa pravom na odbitak
          prethodnog poreza
        </td>
        <td *ngIf="!jeObrada && !jeKonvertovanje">
          <kodit-number-input
            [formCtrl]="ukupanIznos.controls.smanjenjeNaknadeZ"
            (onInputLeft)="updateUkupanIznos()"
          ></kodit-number-input>
        </td>
        <td *ngIf="jeObrada || jeKonvertovanje">
          {{ ukupanIznos.value.smanjenjeNaknadeZ | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
         (ukupanIznos.value.zbirStavkiSS !== null &&
            ukupanIznos.value.zbirStavkiSS !== 0 &&
            !jeObrada && !jeKonvertovanje)
          ||
          ((jeObrada || jeKonvertovanje) &&
            ukupanIznos.value.smanjenjeNaknadeSS > 0 &&
            ukupanIznos.value.smanjenjeNaknadeSS !== null)
        "
      >
        <td>Smanjenje naknade - posebni postupci oporezivanja</td>
        <td *ngIf="!jeObrada && !jeKonvertovanje">
          <kodit-number-input
            [formCtrl]="ukupanIznos.controls.smanjenjeNaknadeSS"
            (onInputLeft)="updateUkupanIznos()"
          ></kodit-number-input>
        </td>
        <td *ngIf="jeObrada || jeKonvertovanje">
          {{
            ukupanIznos.value.smanjenjeNaknadeSS | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          (ukupanIznos.value.zbirStavkiOE !== null &&
            ukupanIznos.value.zbirStavkiOE !== 0 &&
            !jeObrada && !jeKonvertovanje)
          ||
          ((jeObrada || jeKonvertovanje) &&
            ukupanIznos.value.smanjenjeNaknadeOE > 0 &&
            ukupanIznos.value.smanjenjeNaknadeOE !== null)
        "
      >
        <td>Smanjenje naknade - nije predmet oporezivanja PDV 2</td>
        <td *ngIf="!jeObrada && !jeObrada">
          <kodit-number-input
            [formCtrl]="ukupanIznos.controls.smanjenjeNaknadeOE"
            (onInputLeft)="updateUkupanIznos()"
          ></kodit-number-input>
        </td>
        <td *ngIf="jeObrada || jeObrada">
          {{
            ukupanIznos.value.smanjenjeNaknadeOE | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          (ukupanIznos.value.zbirStavkiN !== null &&
            ukupanIznos.value.zbirStavkiN !== 0 &&
            !jeObrada && !jeKonvertovanje)
          ||
          ((jeObrada || jeKonvertovanje) &&
            ukupanIznos.value.smanjenjeNaknadeN > 0 &&
            ukupanIznos.value.smanjenjeNaknadeN !== null)
        "
      >
        <td>Smanjenje osnovice / naknade - umanjenje</td>
        <td *ngIf="!jeObrada && !jeKonvertovanje">
          <kodit-number-input
            [formCtrl]="ukupanIznos.controls.smanjenjeNaknadeN"
            (onInputLeft)="updateUkupanIznos()"
          ></kodit-number-input>
        </td>
        <td *ngIf="jeObrada || jeKonvertovanje">
          {{ ukupanIznos.value.smanjenjeNaknadeN | currencyNumber: valutaText }}
        </td>
      </tr>

      <tr>
        <td colspan="2" class="table-divider"></td>
      </tr>
      <!-- ************************************************************** -->
      <!-- Ukupno osnovica -->
      <!-- ************************************************************** -->
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoOsnovica20 !== null &&
          ukupanIznos.value.ukupnoOsnovica20 !== 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna osnovica po avansu - stopa 20%
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana osnovica PDV - stopa 20%
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena osnovica PDV - stopa 20%
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna osnovica - stopa 20%
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.ukupnoOsnovica20 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoOsnovica10 !== null &&
          ukupanIznos.value.ukupnoOsnovica10 !== 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna osnovica po avansu - stopa 10%
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana osnovica PDV - stopa 10%
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena osnovica PDV - stopa 10%
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna osnovica - stopa 10%
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.ukupnoOsnovica10 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoOsnovica8 !== null &&
          ukupanIznos.value.ukupnoOsnovica8 !== 0
        "
      >
        <td>Ukupno osnovica - stopa 8%</td>
        <td>
          {{ ukupanIznos.value.ukupnoOsnovica8 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoOsnovicaAE10 !== null &&
          ukupanIznos.value.ukupnoOsnovicaAE10 > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna osnovica po avansu - obrnuto obračunavanje PDV - stopa 10%
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana osnovica PDV  - obrnuto obračunavanje PDV - stopa 10%
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena osnovica PDV  - obrnuto obračunavanje PDV - stopa 10%
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna osnovica - obrnuto obračunavanje PDV - stopa 10%
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.ukupnoOsnovicaAE10 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoOsnovicaAE20 !== null &&
          ukupanIznos.value.ukupnoOsnovicaAE20 > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna osnovica po avansu - obrnuto obračunavanje PDV - stopa 20%
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana osnovica PDV  - obrnuto obračunavanje PDV - stopa 20%
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena osnovica PDV  - obrnuto obračunavanje PDV - stopa 20%
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna osnovica - obrnuto obračunavanje PDV - stopa 20%
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.ukupnoOsnovicaAE20 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoOsnovicaO !== null &&
          ukupanIznos.value.ukupnoOsnovicaO > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna naknada po avansu - nije predmet oporezivanja PDV
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana naknada - nije predmet oporezivanja PDV
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena naknada - nije predmet oporezivanja PDV
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna naknada - nije predmet oporezivanja PDV
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.ukupnoOsnovicaO | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoOsnovicaE !== null &&
          ukupanIznos.value.ukupnoOsnovicaE > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna naknada po avansu - poresko oslobođenje bez prava na odbitak prethodnog poreza
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana naknada - poresko oslobođenje bez prava na odbitak prethodnog poreza
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena naknada - poresko oslobođenje bez prava na odbitak prethodnog poreza
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna naknada - poresko oslobođenje bez prava na odbitak prethodnog poreza
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.ukupnoOsnovicaE | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoOsnovicaR !== null &&
          ukupanIznos.value.ukupnoOsnovicaR > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna naknada po avansu- izuzimanje od PDV
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana naknada - izuzimanje od PDV
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena naknada - izuzimanje od PDV
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna naknada - izuzimanje od PDV
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.ukupnoOsnovicaR | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoOsnovicaZ !== null &&
          ukupanIznos.value.ukupnoOsnovicaZ > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna naknada po avansu - poresko oslobođenje sa pravom na odbitak prethodnog poreza
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana naknada - poresko oslobođenje sa pravom na odbitak prethodnog poreza
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena naknada - poresko oslobođenje sa pravom na odbitak prethodnog poreza
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna naknada - poresko oslobođenje sa pravom na odbitak prethodnog poreza
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.ukupnoOsnovicaZ | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoOsnovicaSS !== null &&
          ukupanIznos.value.ukupnoOsnovicaSS > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna naknada po avansu - posebni postupci oporezivanja
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana naknada - posebni postupci oporezivanja
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena naknada - posebni postupci oporezivanja
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna naknada - posebni postupci oporezivanja
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.ukupnoOsnovicaSS | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoOsnovicaOE !== null &&
          ukupanIznos.value.ukupnoOsnovicaOE > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna naknada po avansu - nije predmet oporezivanja PDV2
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana naknada - nije predmet oporezivanja PDV2
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena naknada - nije predmet oporezivanja PDV2
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna naknada - nije predmet oporezivanja PDV2
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.ukupnoOsnovicaOE | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr *ngIf="ukupanIznos.value.ukupnoOsnovicaN !== null &&
          ukupanIznos.value.ukupnoOsnovicaN > 0">
        <td [ngSwitch]="true">
          <ng-container *ngSwitchCase="jeAvansniRacun">
            Ukupna osnovica / naknada po avansu - Umanjenje
          </ng-container>
          <ng-container *ngSwitchCase="jeDokumentOPovecanju">
            Ukupan iznos za koji je povećana osnovica / naknada - Umanjenje
          </ng-container>
          <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
            Ukupan iznos za koji je smanjena osnovica / naknada - Umanjenje
          </ng-container>
          <ng-container *ngSwitchDefault>
            Ukupna osnovica / nakanada - Umanjenje
          </ng-container>
        </td>
        <td>
          {{ ukupanIznos.value.ukupnoOsnovicaN | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoPdv10 !== null &&
          ukupanIznos.value.ukupnoPdv10 !== 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupan PDV po avansu - stopa 10%
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećan PDV - stopa 10%
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjen PDV - stopa 10%
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupan PDV - stopa 10%
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.ukupnoPdv10 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoPdv20 !== null &&
          ukupanIznos.value.ukupnoPdv20 !== 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupan PDV po avansu - stopa 20%
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećan PDV - stopa 20%
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjen PDV - stopa 20%
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupan PDV - stopa 20%
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.ukupnoPdv20 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoPdv8 !== null &&
          ukupanIznos.value.ukupnoPdv8 !== 0
        "
      >
        <td>Ukupno PDV - stopa 8%</td>
        <td>
          {{ ukupanIznos.value.ukupnoPdv8 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr>
        <td colspan="2" class="table-divider"></td>
      </tr>

      <!-- ************************************************************** -->
      <!-- Ukupno osnovica (RSD) -->
      <!-- ************************************************************** -->
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoOsnovica20 !== null &&
          ukupanIznos.value.rsdUkupnoOsnovica20 > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna osnovica po avansu - stopa 20%(RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana osnovica PDV - stopa 20%(RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena osnovica PDV - stopa 20%(RSD)
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna osnovica - stopa 20%(RSD)
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoOsnovica20 | currencyNumber }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoOsnovica10 !== null &&
          ukupanIznos.value.rsdUkupnoOsnovica10 > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna osnovica po avansu - stopa 10%(RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana osnovica PDV - stopa 10%(RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena osnovica PDV - stopa 10%(RSD)
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna osnovica - stopa 10%(RSD)
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoOsnovica10 | currencyNumber }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoOsnovica8 !== null &&
          ukupanIznos.value.rsdUkupnoOsnovica8 > 0
        "
      >
        <td>Ukupno osnovica - stopa 8% (RSD)</td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoOsnovica8 | currencyNumber }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoOsnovicaAE10 !== null &&
          ukupanIznos.value.rsdUkupnoOsnovicaAE10 > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna osnovica po avansu - obrnuto obračunavanje PDV - stopa 10%(RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana osnovica PDV - obrnuto obračunavanje PDV - stopa 10%(RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena osnovica PDV - obrnuto obračunavanje PDV - stopa 10%(RSD)
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna osnovica - obrnuto obračunavanje PDV - stopa 10%(RSD)
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoOsnovicaAE10 | currencyNumber }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoOsnovicaAE20 !== null &&
          ukupanIznos.value.rsdUkupnoOsnovicaAE20 > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna osnovica po avansu - obrnuto obračunavanje PDV - stopa 20%(RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana osnovica PDV - obrnuto obračunavanje PDV - stopa 20%(RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena osnovica PDV - obrnuto obračunavanje PDV - stopa 20%(RSD)
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna osnovica - obrnuto obračunavanje PDV - stopa 20%(RSD)
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoOsnovicaAE20 | currencyNumber }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoOsnovicaO !== null &&
          ukupanIznos.value.rsdUkupnoOsnovicaO > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna naknada po avansu - nije predmet oporezivanja PDV (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana naknada - nije predmet oporezivanja PDV (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena naknada - nije predmet oporezivanja PDV (RSD)
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna naknada - nije predmet oporezivanja PDV (RSD)
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoOsnovicaO | currencyNumber }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoOsnovicaE !== null &&
          ukupanIznos.value.rsdUkupnoOsnovicaE > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna naknada po avansu - poresko oslobođenje bez prava na odbitak prethodnog poreza (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana naknada - poresko oslobođenje bez prava na odbitak prethodnog poreza (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena naknada - poresko oslobođenje bez prava na odbitak prethodnog poreza (RSD)
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna naknada - poresko oslobođenje bez prava na odbitak prethodnog poreza (RSD)
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoOsnovicaE | currencyNumber }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoOsnovicaR !== null &&
          ukupanIznos.value.rsdUkupnoOsnovicaR > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna naknada po avansu - izuzimanje od PDV (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana naknada - izuzimanje od PDV (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena naknada - izuzimanje od PDV (RSD)
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna naknada - izuzimanje od PDV (RSD)
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoOsnovicaR | currencyNumber }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoOsnovicaZ !== null &&
          ukupanIznos.value.rsdUkupnoOsnovicaZ > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna naknada po avansu - poresko oslobođenje sa pravom na odbitak prethodnog poreza (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana naknada - poresko oslobođenje sa pravom na odbitak prethodnog poreza (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena naknada - poresko oslobođenje sa pravom na odbitak prethodnog poreza (RSD)
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna naknada - poresko oslobođenje sa pravom na odbitak prethodnog poreza (RSD)
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoOsnovicaZ | currencyNumber }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoOsnovicaSS !== null &&
          ukupanIznos.value.rsdUkupnoOsnovicaSS > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna naknada po avansu - posebni postupci oporezivanja (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana naknada - posebni postupci oporezivanja (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena naknada - posebni postupci oporezivanja (RSD)
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna naknada - posebni postupci oporezivanja (RSD)
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoOsnovicaSS | currencyNumber }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoOsnovicaOE !== null &&
          ukupanIznos.value.rsdUkupnoOsnovicaOE > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna naknada po avansu - nije predmet oporezivanja PDV2 (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana naknada - nije predmet oporezivanja PDV2 (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena naknada - nije predmet oporezivanja PDV2 (RSD)
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna naknada - nije predmet oporezivanja PDV2 (RSD)
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoOsnovicaOE | currencyNumber }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoOsnovicaN !== null &&
          ukupanIznos.value.rsdUkupnoOsnovicaN > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupna osnovica / naknada po avansu - Umanjenje (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećana osnovica / naknada - Umanjenje (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjena osnovica / naknada - Umanjenje (RSD)
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupna osnovica / nakanada - Umanjenje (RSD)
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoOsnovicaN | currencyNumber }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoPdv10 !== null &&
          ukupanIznos.value.rsdUkupnoPdv10 > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupan PDV po avansu - stopa 10% (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećan PDV - stopa 10% (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjen PDV - stopa 10% (RSD)
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupan PDV - stopa 10% (RSD)
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoPdv10 | currencyNumber }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoPdv20 !== null &&
          ukupanIznos.value.rsdUkupnoPdv20 > 0
        "
      >
      <td [ngSwitch]="true">
        <ng-container *ngSwitchCase="jeAvansniRacun">
          Ukupan PDV po avansu - stopa 20% (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOPovecanju">
          Ukupan iznos za koji je povećan PDV - stopa 20% (RSD)
        </ng-container>
        <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
          Ukupan iznos za koji je smanjen PDV - stopa 20% (RSD)
        </ng-container>
        <ng-container *ngSwitchDefault>
          Ukupan PDV - stopa 20% (RSD)
        </ng-container>
      </td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoPdv20 | currencyNumber }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.rsdUkupnoPdv8 !== null &&
          ukupanIznos.value.rsdUkupnoPdv8 > 0
        "
      >
        <td>Ukupno PDV - stopa 8% (RSD)</td>
        <td>
          {{ ukupanIznos.value.rsdUkupnoPdv8 | currencyNumber }}
        </td>
      </tr>
      <tr>
        <td colspan="2" class="table-divider"></td>
      </tr>
      <!-- ************************************************************** -->
      <!-- Avansiran iznos -->
      <!-- ************************************************************** -->
      <tr
        *ngIf="
          ukupanIznos.value.avansiraniPdv10 !== null &&
          ukupanIznos.value.avansiraniPdv10 > 0
        "
      >
        <td>PDV po avansu - stopa 10%</td>
        <td>
          {{ ukupanIznos.value.avansiraniPdv10 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiraniPdv20 !== null &&
          ukupanIznos.value.avansiraniPdv20 > 0
        "
      >
        <td>PDV po avansu - stopa 20%</td>
        <td>
          {{ ukupanIznos.value.avansiraniPdv20 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiraniPdv8 !== null &&
          ukupanIznos.value.avansiraniPdv8 > 0
        "
      >
        <td>Avansni PDV - stopa 8% (RSD)</td>
        <td>
          {{ ukupanIznos.value.avansiraniPdv8 | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiranaOsnovicaS !== null &&
          ukupanIznos.value.avansiranaOsnovicaS > 0
        "
      >
        <td>Avansna osnovica - standardno obračunavanje PDV (RSD)</td>
        <td>
          {{
            ukupanIznos.value.avansiranaOsnovicaS | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiranaOsnovica20 !== null &&
          ukupanIznos.value.avansiranaOsnovica20 > 0
        "
      >
        <td>Osnovica po avansu - stopa 20% (RSD)</td>
        <td>
          {{
            ukupanIznos.value.avansiranaOsnovica20 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiranaOsnovica10 !== null &&
          ukupanIznos.value.avansiranaOsnovica10 > 0
        "
      >
        <td>Osnovica po avansu - stopa 10% (RSD)</td>
        <td>
          {{
            ukupanIznos.value.avansiranaOsnovica10 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiranaOsnovica8 !== null &&
          ukupanIznos.value.avansiranaOsnovica8 > 0
        "
      >
        <td>Avansna osnovica - stopa 8% (RSD)</td>
        <td>
          {{
          ukupanIznos.value.avansiranaOsnovica8 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiranaOsnovicaAE10 !== null &&
          ukupanIznos.value.avansiranaOsnovicaAE10 > 0
        "
      >
        <td>Osnovica po avansu - obrnuto obračunavanje PDV (RSD) - 10%</td>
        <td>
          {{
            ukupanIznos.value.avansiranaOsnovicaAE10 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiranaOsnovicaAE20 !== null &&
          ukupanIznos.value.avansiranaOsnovicaAE20 > 0
        "
      >
        <td>Osnovica po avansu - obrnuto obračunavanje PDV (RSD) - 20%</td>
        <td>
          {{
            ukupanIznos.value.avansiranaOsnovicaAE20 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiranaOsnovicaO !== null &&
          ukupanIznos.value.avansiranaOsnovicaO > 0
        "
      >
        <td>Naknada po avansu - nije predmet oporezivanja PDV (RSD)</td>
        <td>
          {{
            ukupanIznos.value.avansiranaOsnovicaO | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiranaOsnovicaE !== null &&
          ukupanIznos.value.avansiranaOsnovicaE > 0
        "
      >
        <td>
          Naknada po avansu - poresko oslobođenje bez prava na odbitak
          prethodnog poreza (RSD)
        </td>
        <td>
          {{
            ukupanIznos.value.avansiranaOsnovicaE | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiranaOsnovicaR !== null &&
          ukupanIznos.value.avansiranaOsnovicaR > 0
        "
      >
        <td>Naknada po avansu - izuzimanje od PDV (RSD)</td>
        <td>
          {{
            ukupanIznos.value.avansiranaOsnovicaR | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiranaOsnovicaZ !== null &&
          ukupanIznos.value.avansiranaOsnovicaZ > 0
        "
      >
        <td>
          Naknada po avansu - poresko oslobođenje sa pravom na odbitak
          prethodnog poreza (RSD)
        </td>
        <td>
          {{
            ukupanIznos.value.avansiranaOsnovicaZ | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiranaOsnovicaSS !== null &&
          ukupanIznos.value.avansiranaOsnovicaSS > 0
        "
      >
        <td>Naknada po avansu - posebni postupci oporezivanja (RSD)</td>
        <td>
          {{
            ukupanIznos.value.avansiranaOsnovicaSS | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiranaOsnovicaOE !== null &&
          ukupanIznos.value.avansiranaOsnovicaOE > 0
        "
      >
        <td>Naknada po avansu - nije predmet oporezivanja PDV 2 (RSD)</td>
        <td>
          {{
            ukupanIznos.value.avansiranaOsnovicaOE | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.avansiranaOsnovicaN !== null &&
          ukupanIznos.value.zbirStavkiN > 0
        "
      >
        <td>Osnovica / naknada po avansu - Umanjenje (RSD)</td>
        <td>
          {{
            ukupanIznos.value.avansiranaOsnovicaN | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupanAvansiranIznos !== null &&
          ukupanIznos.value.ukupanAvansiranIznos > 0
        "
      >
        <td>Ukupan iznos po avansu (RSD)</td>
        <td>
          {{
            ukupanIznos.value.ukupanAvansiranIznos | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          hasAvans8 ||
          hasAvans10 ||
          hasAvans20 ||
          hasAvansAE10 ||
          hasAvansAE20 ||
          hasAvansE ||
          hasAvansN ||
          hasAvansO ||
          hasAvansOE ||
          hasAvansR ||
          hasAvansSS ||
          hasAvansZ
        "
      >
        <td colspan="2" class="table-divider"></td>
      </tr>
      <!-- ************************************************************** -->
      <!-- Umanjena osnovica -->
      <!-- ************************************************************** -->
      <tr
        *ngIf="
          ukupanIznos.value.umanjenaOsnovicaS !== null &&
          (ukupanIznos.value.umanjenaOsnovicaS > 0 || hasAvans10 || hasAvans20 || hasAvans8)
        "
      >
        <td>Umanjena osnovica - standardno obračunavanje PDV</td>
        <td>
          {{ ukupanIznos.value.umanjenaOsnovicaS | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.umanjenaOsnovica20 !== null &&
          (ukupanIznos.value.umanjenaOsnovica20 !== 0 || hasAvans20)
        "
      >
        <td>Ukupan iznos osnovice umanjen za osnovicu po avansu - stopa 20%</td>
        <td>
          {{
            ukupanIznos.value.umanjenaOsnovica20 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.umanjenaOsnovica10 !== null &&
          (ukupanIznos.value.umanjenaOsnovica10 !== 0 || hasAvans10)
        "
      >
        <td>Ukupan iznos osnovice umanjen za osnovicu po avansu - stopa 10%</td>
        <td>
          {{
            ukupanIznos.value.umanjenaOsnovica10 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.umanjenaOsnovica8 !== null &&
          (ukupanIznos.value.umanjenaOsnovica8 !== 0 || hasAvans8)
        "
      >
        <td>Ukupno osnovica umanjena za avanse - stopa 8%</td>
        <td>
          {{
          ukupanIznos.value.umanjenaOsnovica8 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.umanjenaOsnovicaAE10 !== null &&
          (ukupanIznos.value.umanjenaOsnovicaAE10 > 0 || hasAvansAE10)
        "
      >
        <td>Umanjen iznos osnovice umanjen za osnovicu po avansu - obrnuto obračunavanje PDV -  stopa 10%</td>
        <td>
          {{
            ukupanIznos.value.umanjenaOsnovicaAE10 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.umanjenaOsnovicaAE20 !== null &&
          (ukupanIznos.value.umanjenaOsnovicaAE20 > 0 || hasAvansAE20)
        "
      >
      <td>Umanjen iznos osnovice umanjen za osnovicu po avansu - obrnuto obračunavanje PDV -  stopa 20%</td>
      <td>
          {{
            ukupanIznos.value.umanjenaOsnovicaAE20 | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.umanjenaOsnovicaO !== null &&
          (ukupanIznos.value.umanjenaOsnovicaO > 0 || hasAvansO)
        "
      >
        <td>Umanjen iznos naknade za iznos naknade po avansu - nije predmet oporezivanja PDV</td>
        <td>
          {{ ukupanIznos.value.umanjenaOsnovicaO | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.umanjenaOsnovicaE !== null &&
          (ukupanIznos.value.umanjenaOsnovicaE > 0 || hasAvansE)
        "
      >
        <td>
          Umanjen iznos naknade za iznos naknade po avansu - poresko oslobođenje bez prava na odbitak
          prethodnog poreza
        </td>
        <td>
          {{ ukupanIznos.value.umanjenaOsnovicaE | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.umanjenaOsnovicaR !== null &&
          (ukupanIznos.value.umanjenaOsnovicaR > 0 || hasAvansR)
        "
      >
        <td>Umanjen iznos naknade za iznos naknade po avansu - izuzimanje od PDV</td>
        <td>
          {{ ukupanIznos.value.umanjenaOsnovicaR | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.umanjenaOsnovicaZ !== null &&
          (ukupanIznos.value.umanjenaOsnovicaZ > 0 || hasAvansZ)
        "
      >
        <td>
          Umanjen iznos naknade za iznos naknade po avansu - poresko oslobođenje sa pravom na odbitak
          prethodnog poreza
        </td>
        <td>
          {{ ukupanIznos.value.umanjenaOsnovicaZ | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.umanjenaOsnovicaSS !== null &&
          (ukupanIznos.value.umanjenaOsnovicaSS > 0 || hasAvansSS)
        "
      >
        <td>Umanjena osnovica - posebni postupci oporezivanja</td>
        <td>
          {{
            ukupanIznos.value.umanjenaOsnovicaSS | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.umanjenaOsnovicaOE !== null &&
          (ukupanIznos.value.umanjenaOsnovicaOE > 0 || hasAvansOE)
        "
      >
        <td>Umanjen iznos naknade za iznos naknade po avansu - nije predmet oporezivanja PDV 2</td>
        <td>
          {{
            ukupanIznos.value.umanjenaOsnovicaOE | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.umanjenaOsnovicaN !== null &&
          ukupanIznos.value.zbirStavkiN > 0
        "
      >
        <td>Umanjen iznos osnovice / naknade za iznos osnovice / naknade po avansu - Umanjenje</td>
        <td>
          {{ ukupanIznos.value.umanjenaOsnovicaN | currencyNumber: valutaText }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoPdvUmanjenZaAvanse10 !== null &&
          (ukupanIznos.value.ukupnoPdvUmanjenZaAvanse10 !== 0 || hasAvans10)
        "
      >
        <td>Ukupan PDV umanjen za PDV po avansu – stopa 10%</td>
        <td>
          {{
            ukupanIznos.value.ukupnoPdvUmanjenZaAvanse10
              | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoPdvUmanjenZaAvanse20 !== null &&
          (ukupanIznos.value.ukupnoPdvUmanjenZaAvanse20 !== 0 || hasAvans20)
        "
      >
        <td>Ukupan PDV umanjen za PDV po avansu – stopa 20%</td>
        <td>
          {{
            ukupanIznos.value.ukupnoPdvUmanjenZaAvanse20
              | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoPdvUmanjenZaAvanse8 !== null &&
          (ukupanIznos.value.ukupnoPdvUmanjenZaAvanse8 !== 0 || hasAvans8)
        "
      >
        <td>Ukupno PDV umanjen za avanse – stopa 8%</td>
        <td>
          {{
          ukupanIznos.value.ukupnoPdvUmanjenZaAvanse8
            | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoZaUplatu !== 0 &&
          ukupanIznos.value.ukupnoZaUplatu !== null &&
          !jeObrada && !jeKonvertovanje
        "
      >
        <td>Iznos za zaokruživanje</td>
        <td>
          <kodit-number-input
            [formCtrl]="ukupanIznos.controls.iznosZaZaokruzivanje"
            (onInputLeft)="onEditIznosZaZaokruzivanje()"
          ></kodit-number-input>
        </td>
      </tr>
      <tr *ngIf="(jeObrada || jeKonvertovanje) && ukupanIznos.value.iznosZaZaokruzivanje > 0">
        <td>Iznos za zaokruživanje</td>
        <td>
          {{
            ukupanIznos.value.iznosZaZaokruzivanje | currencyNumber: valutaText
          }}
        </td>
      </tr>
      <tr
        *ngIf="
          ukupanIznos.value.ukupnoZaUplatu !== 0 &&
          ukupanIznos.value.ukupnoZaUplatu !== null &&
          jeObrada
        "
      ></tr>
      <tr>
        <td [ngSwitch]="true">
          <ng-container *ngSwitchCase="jeAvansniRacun">
            Ukupan iznos avansa
          </ng-container>
          <ng-container *ngSwitchCase="jeDokumentOPovecanju">
            Ukupan iznos povećanja
          </ng-container>
          <ng-container *ngSwitchCase="jeDokumentOSmanjenju">
            Ukupan iznos smanjenja
          </ng-container>
          <ng-container *ngSwitchDefault>
            Iznos za plaćanje
          </ng-container>
        </td>
        <td>
          {{ ukupanIznos.value.ukupnoZaUplatu | currencyNumber: valutaText }}
        </td>
      </tr>
    </table>

    <kodit-error-info-min [formCtrl]="ukupanIznos.get('ukupnoZaUplatu')">
    </kodit-error-info-min>

    <!-- <p-accordion [multiple]="true">
      <p-accordionTab header="Zbir stavki">
        <table>
          <tr
            *ngIf="
              ukupanIznos.value.zbirStavki20 !== null &&
              ukupanIznos.value.zbirStavki20 > 0
            "
          >
            <td>Zbir stavki sa stopom 20%</td>
            <td>
              {{ ukupanIznos.value.zbirStavki20 | currencyNumber: valutaText }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.zbirStavki10 !== null &&
              ukupanIznos.value.zbirStavki10 > 0
            "
          >
            <td>Zbir stavki sa stopom 10%</td>
            <td>
              {{ ukupanIznos.value.zbirStavki10 | currencyNumber: valutaText }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.zbirStavkiAE !== null &&
              ukupanIznos.value.zbirStavkiAE > 0
            "
          >
            <td>Zbir stavki - obrnuto obračunavanje PDV</td>
            <td>
              {{ ukupanIznos.value.zbirStavkiAE | currencyNumber: valutaText }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.zbirStavkiO !== null &&
              ukupanIznos.value.zbirStavkiO > 0
            "
          >
            <td>Zbir stavki - nije predmet oporezivanja PDV</td>
            <td>
              {{ ukupanIznos.value.zbirStavkiO | currencyNumber: valutaText }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.zbirStavkiE !== null &&
              ukupanIznos.value.zbirStavkiE > 0
            "
          >
            <td>
              Zbir stavki - poresko oslobođenje bez prava na odbitak prethodnog
              poreza
            </td>
            <td>
              {{ ukupanIznos.value.zbirStavkiE | currencyNumber: valutaText }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.zbirStavkiR !== null &&
              ukupanIznos.value.zbirStavkiR > 0
            "
          >
            <td>Zbir stavki - izuzimanje od PDV</td>
            <td>
              {{ ukupanIznos.value.zbirStavkiR | currencyNumber: valutaText }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.zbirStavkiZ !== null &&
              ukupanIznos.value.zbirStavkiZ > 0
            "
          >
            <td>
              Zbir stavki - poresko oslobođenje sa pravom na odbitak prethodnog
              poreza
            </td>
            <td>
              {{ ukupanIznos.value.zbirStavkiZ | currencyNumber: valutaText }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.zbirStavkiSS !== null &&
              ukupanIznos.value.zbirStavkiSS > 0
            "
          >
            <td>Zbir stavki - posebni postupci oporezivanja</td>
            <td>
              {{ ukupanIznos.value.zbirStavkiSS | currencyNumber: valutaText }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.zbirStavkiOE !== null &&
              ukupanIznos.value.zbirStavkiOE > 0
            "
          >
            <td>Zbir stavki - nije predmet oporezivanja PDV 2</td>
            <td>
              {{ ukupanIznos.value.zbirStavkiOE | currencyNumber: valutaText }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.zbirStavkiN !== null &&
              ukupanIznos.value.zbirStavkiN > 0
            "
          >
            <td>Zbir stavki - umanjenje</td>
            <td>
              {{ ukupanIznos.value.zbirStavkiN | currencyNumber: valutaText }}
            </td>
          </tr>
        </table>
      </p-accordionTab>
      <p-accordionTab header="Smanjenje naknade">
        <table>
          <tr>
            <td>Smanjenje naknade - stopa 20%</td>
            <td>
              {{
                ukupanIznos.value.smanjenjeNaknade20
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr>
            <td>Smanjenje naknade - stopa 10%</td>
            <td>
              {{
                ukupanIznos.value.smanjenjeNaknade10
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr>
            <td>Smanjenje naknade - obrnuti obračun PDV-a</td>
            <td>
              {{
                ukupanIznos.value.smanjenjeNaknadeAE
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr>
            <td>Smanjenje naknade - nije predmet oporezivanja PDV</td>
            <td>
              {{
                ukupanIznos.value.smanjenjeNaknadeO | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr>
            <td>
              Smanjenje naknade - poresko oslobođenje bez prava na odbitak
              prethodnog poreza
            </td>
            <td>
              {{
                ukupanIznos.value.smanjenjeNaknadeE | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr>
            <td>Smanjenje naknade - izuzimanje od PDV</td>
            <td>
              {{
                ukupanIznos.value.smanjenjeNaknadeR | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr>
            <td>
              Smanjenje naknade - poresko oslobođenje sa pravom na odbitak
              prethodnog poreza
            </td>
            <td>
              {{
                ukupanIznos.value.smanjenjeNaknadeZ | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr>
            <td>Smanjenje naknade - posebni postupci oporezivanja</td>
            <td>
              {{
                ukupanIznos.value.smanjenjeNaknadeSS
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr>
            <td>Smanjenje naknade - nije predmet oporezivanja PDV 2</td>
            <td>
              {{
                ukupanIznos.value.smanjenjeNaknadeOE
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr>
            <td>Smanjenje osnovice / naknade - umanjenje</td>
            <td>
              {{
                ukupanIznos.value.smanjenjeNaknadeN | currencyNumber: valutaText
              }}
            </td>
          </tr>
        </table>
      </p-accordionTab>
      <p-accordionTab header="Ukupno osnovica">
        <table>
          <tr
            *ngIf="
              ukupanIznos.value.ukupnoOsnovica20 !== null &&
              ukupanIznos.value.ukupnoOsnovica20 > 0
            "
          >
            <td>Ukupno osnovica - stopa 20%</td>
            <td>
              {{
                ukupanIznos.value.ukupnoOsnovica20 | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.ukupnoOsnovica10 !== null &&
              ukupanIznos.value.ukupnoOsnovica10 > 0
            "
          >
            <td>Ukupno osnovica - stopa 10%</td>
            <td>
              {{
                ukupanIznos.value.ukupnoOsnovica10 | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.ukupnoOsnovicaAE !== null &&
              ukupanIznos.value.ukupnoOsnovicaAE > 0
            "
          >
            <td>Ukupno osnovica - obrnuto obračunavanje PDV</td>
            <td>
              {{
                ukupanIznos.value.ukupnoOsnovicaAE | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.ukupnoOsnovicaO !== null &&
              ukupanIznos.value.ukupnoOsnovicaO > 0
            "
          >
            <td>Ukupno osnovica - nije predmet oporezivanja PDV</td>
            <td>
              {{
                ukupanIznos.value.ukupnoOsnovicaO | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.ukupnoOsnovicaE !== null &&
              ukupanIznos.value.ukupnoOsnovicaE > 0
            "
          >
            <td>
              Ukupno osnovica - poresko oslobođenje bez prava na odbitak
              prethodnog poreza
            </td>
            <td>
              {{
                ukupanIznos.value.ukupnoOsnovicaE | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.ukupnoOsnovicaR !== null &&
              ukupanIznos.value.ukupnoOsnovicaR > 0
            "
          >
            <td>Ukupno osnovica - izuzimanje od PDV</td>
            <td>
              {{
                ukupanIznos.value.ukupnoOsnovicaR | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.ukupnoOsnovicaZ !== null &&
              ukupanIznos.value.ukupnoOsnovicaZ > 0
            "
          >
            <td>
              Ukupno osnovica - poresko oslobođenje sa pravom na odbitak
              prethodnog poreza
            </td>
            <td>
              {{
                ukupanIznos.value.ukupnoOsnovicaZ | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.ukupnoOsnovicaSS !== null &&
              ukupanIznos.value.ukupnoOsnovicaSS > 0
            "
          >
            <td>Ukupno osnovica - posebni postupci oporezivanja</td>
            <td>
              {{
                ukupanIznos.value.ukupnoOsnovicaSS | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.ukupnoOsnovicaOE !== null &&
              ukupanIznos.value.ukupnoOsnovicaOE > 0
            "
          >
            <td>Ukupno osnovica - nije predmet oporezivanja PDV 2</td>
            <td>
              {{
                ukupanIznos.value.ukupnoOsnovicaOE | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.ukupnoOsnovicaN !== null &&
              ukupanIznos.value.ukupnoOsnovicaN > 0
            "
          >
            <td>Ukupno osnovica - anuliranje</td>
            <td>
              {{
                ukupanIznos.value.ukupnoOsnovicaN | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.ukupnoPdv10 !== null &&
              ukupanIznos.value.ukupnoPdv10 > 0
            "
          >
            <td>Ukupno PDV - stopa 10%</td>
            <td>
              {{ ukupanIznos.value.ukupnoPdv10 | currencyNumber: valutaText }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.ukupnoPdv20 !== null &&
              ukupanIznos.value.ukupnoPdv20 > 0
            "
          >
            <td>Ukupno PDV - stopa 20%</td>
            <td>
              {{ ukupanIznos.value.ukupnoPdv20 | currencyNumber: valutaText }}
            </td>
          </tr>
        </table>
      </p-accordionTab>
      <p-accordionTab header="Avansiran iznos">
        <table>
          <tr
            *ngIf="
              ukupanIznos.value.avansiraniPdv10 !== null &&
              ukupanIznos.value.avansiraniPdv10 > 0
            "
          >
            <td>Avansni PDV - stopa 10%</td>
            <td>
              {{
                ukupanIznos.value.avansiraniPdv10 | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.avansiraniPdv20 !== null &&
              ukupanIznos.value.avansiraniPdv20 > 0
            "
          >
            <td>Avansni PDV - stopa 20%</td>
            <td>
              {{
                ukupanIznos.value.avansiraniPdv20 | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.avansiranaOsnovicaS !== null &&
              ukupanIznos.value.avansiranaOsnovicaS > 0
            "
          >
            <td>Avansirana osnovica - standardno obračunavanje PDV</td>
            <td>
              {{
                ukupanIznos.value.avansiranaOsnovicaS
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.avansiranaOsnovica20 !== null &&
              ukupanIznos.value.avansiranaOsnovica20 > 0
            "
          >
            <td>Avansirana osnovica - stopa 20%</td>
            <td>
              {{
                ukupanIznos.value.avansiranaOsnovica20
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.avansiranaOsnovica10 !== null &&
              ukupanIznos.value.avansiranaOsnovica10 > 0
            "
          >
            <td>Avansirana osnovica - stopa 10%</td>
            <td>
              {{
                ukupanIznos.value.avansiranaOsnovica10
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.avansiranaOsnovicaAE !== null &&
              ukupanIznos.value.avansiranaOsnovicaAE > 0
            "
          >
            <td>Avansirana osnovica - obrnuto obračunavanje PDV</td>
            <td>
              {{
                ukupanIznos.value.avansiranaOsnovicaAE
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.avansiranaOsnovicaO !== null &&
              ukupanIznos.value.avansiranaOsnovicaO > 0
            "
          >
            <td>Avansirana osnovica - nije predmet oporezivanja PDV</td>
            <td>
              {{
                ukupanIznos.value.avansiranaOsnovicaO
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.avansiranaOsnovicaE !== null &&
              ukupanIznos.value.avansiranaOsnovicaE > 0
            "
          >
            <td>
              Avansirana osnovica - poresko oslobođenje bez prava na odbitak
              prethodnog poreza
            </td>
            <td>
              {{
                ukupanIznos.value.avansiranaOsnovicaE
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.avansiranaOsnovicaR !== null &&
              ukupanIznos.value.avansiranaOsnovicaR > 0
            "
          >
            <td>Avansirana osnovica - izuzimanje od PDV</td>
            <td>
              {{
                ukupanIznos.value.avansiranaOsnovicaR
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.avansiranaOsnovicaZ !== null &&
              ukupanIznos.value.avansiranaOsnovicaZ > 0
            "
          >
            <td>
              Avansirana osnovica - poresko oslobođenje sa pravom na odbitak
              prethodnog poreza
            </td>
            <td>
              {{
                ukupanIznos.value.avansiranaOsnovicaZ
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.avansiranaOsnovicaSS !== null &&
              ukupanIznos.value.avansiranaOsnovicaSS > 0
            "
          >
            <td>Avansirana osnovica - posebni postupci oporezivanja</td>
            <td>
              {{
                ukupanIznos.value.avansiranaOsnovicaSS
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.avansiranaOsnovicaOE !== null &&
              ukupanIznos.value.avansiranaOsnovicaOE > 0
            "
          >
            <td>Avansirana osnovica - nije predmet oporezivanja PDV 2</td>
            <td>
              {{
                ukupanIznos.value.avansiranaOsnovicaOE
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.avansiranaOsnovicaN !== null &&
              ukupanIznos.value.avansiranaOsnovicaN > 0
            "
          >
            <td>Avansirana osnovica - anuliranje</td>
            <td>
              {{
                ukupanIznos.value.avansiranaOsnovicaN
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.ukupanAvansiranIznos !== null &&
              ukupanIznos.value.ukupanAvansiranIznos > 0
            "
          >
            <td>Ukupan avansiran iznos</td>
            <td>
              {{
                ukupanIznos.value.ukupanAvansiranIznos
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
        </table>
      </p-accordionTab>
      <p-accordionTab header="Umanjena osnovica">
        <table>
          <tr
            *ngIf="
              ukupanIznos.value.umanjenaOsnovicaS !== null &&
              (ukupanIznos.value.umanjenaOsnovicaS > 0 ||
                hasAvans10 ||
                hasAvans20)
            "
          >
            <td>Umanjena osnovica - standardno obračunavanje PDV</td>
            <td>
              {{
                ukupanIznos.value.umanjenaOsnovicaS | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.umanjenaOsnovica20 !== null &&
              (ukupanIznos.value.umanjenaOsnovica20 > 0 || hasAvans20)
            "
          >
            <td>Umanjena osnovica - stopa 20%</td>
            <td>
              {{
                ukupanIznos.value.umanjenaOsnovica20
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.umanjenaOsnovica10 !== null &&
              (ukupanIznos.value.umanjenaOsnovica10 > 0 || hasAvans10)
            "
          >
            <td>Umanjena osnovica - stopa 10%</td>
            <td>
              {{
                ukupanIznos.value.umanjenaOsnovica10
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.umanjenaOsnovicaAE !== null &&
              (ukupanIznos.value.umanjenaOsnovicaAE > 0 || hasAvansAE)
            "
          >
            <td>Umanjena osnovica - obrnuti obračun PDV-a</td>
            <td>
              {{
                ukupanIznos.value.umanjenaOsnovicaAE
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.umanjenaOsnovicaO !== null &&
              (ukupanIznos.value.umanjenaOsnovicaO > 0 || hasAvansO)
            "
          >
            <td>Umanjena osnovica - nije predmet oporezivanja PDV</td>
            <td>
              {{
                ukupanIznos.value.umanjenaOsnovicaO | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.umanjenaOsnovicaE !== null &&
              (ukupanIznos.value.umanjenaOsnovicaE > 0 || hasAvansE)
            "
          >
            <td>
              Umanjena osnovica - poresko oslobođenje bez prava na odbitak
              prethodnog poreza
            </td>
            <td>
              {{
                ukupanIznos.value.umanjenaOsnovicaE | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.umanjenaOsnovicaR !== null &&
              (ukupanIznos.value.umanjenaOsnovicaR > 0 || hasAvansR)
            "
          >
            <td>Umanjena osnovica - izuzimanje od PDV</td>
            <td>
              {{
                ukupanIznos.value.umanjenaOsnovicaR | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.umanjenaOsnovicaZ !== null &&
              (ukupanIznos.value.umanjenaOsnovicaZ > 0 || hasAvansZ)
            "
          >
            <td>
              Umanjena osnovica - poresko oslobođenje sa pravom na odbitak
              prethodnog poreza
            </td>
            <td>
              {{
                ukupanIznos.value.umanjenaOsnovicaZ | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.umanjenaOsnovicaSS !== null &&
              (ukupanIznos.value.umanjenaOsnovicaSS > 0 || hasAvansSS)
            "
          >
            <td>Umanjena osnovica - posebni postupci oporezivanja</td>
            <td>
              {{
                ukupanIznos.value.umanjenaOsnovicaSS
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.umanjenaOsnovicaOE !== null &&
              (ukupanIznos.value.umanjenaOsnovicaOE > 0 || hasAvansOE)
            "
          >
            <td>Umanjena osnovica - nije predmet oporezivanja PDV 2</td>
            <td>
              {{
                ukupanIznos.value.umanjenaOsnovicaOE
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.umanjenaOsnovicaN !== null &&
              (ukupanIznos.value.umanjenaOsnovicaN > 0 || hasAvansN)
            "
          >
            <td>Umanjena osnovica - anuliranje</td>
            <td>
              {{
                ukupanIznos.value.umanjenaOsnovicaN | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.ukupnoPdvUmanjenZaAvanse10 !== null &&
              (ukupanIznos.value.ukupnoPdvUmanjenZaAvanse10 > 0 || hasAvans10)
            "
          >
            <td>Ukupno PDV umanjen za avanse – stopa 10%</td>
            <td>
              {{
                ukupanIznos.value.ukupnoPdvUmanjenZaAvanse10
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
          <tr
            *ngIf="
              ukupanIznos.value.ukupnoPdvUmanjenZaAvanse20 !== null &&
              (ukupanIznos.value.ukupnoPdvUmanjenZaAvanse20 > 0 || hasAvans20)
            "
          >
            <td>Ukupno PDV umanjen za avanse – stopa 20%</td>
            <td>
              {{
                ukupanIznos.value.ukupnoPdvUmanjenZaAvanse20
                  | currencyNumber: valutaText
              }}
            </td>
          </tr>
        </table>
      </p-accordionTab>
    </p-accordion> -->
  </div>
</div>
