import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ArhivskaKnjigaClient,
  DmsClient,
  FileParameter,
  UpdateArhivskaPodesavanjaCommand,
} from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogService } from '@kodit/core/shared-ui';
import { ArhivskaKnjigaService } from 'libs/core/moduli/podesavanje/src/lib/arhivska-knjiga/arhivska-knjiga-service';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { FileUpload } from 'primeng/fileupload';
import { Subscription } from 'rxjs';
import { ArhivskaDokumentiDialogComponent } from './arhivska-dokumenti-dialog/arhivska-dokumenti-dialog.component';

@Component({
  selector: 'kodit-arhivska-does-not-exists-page',
  templateUrl: './arhivska-does-not-exists-page.component.html',
  styleUrls: ['./arhivska-does-not-exists-page.component.scss'],
})
export class ArhivskaDoesNotExistsPageComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  uradjenoEnabled: boolean;
  showKoraciKomponent: boolean;
  uploadCompleted: boolean = false;
  showUpload: boolean = false;

  /** Configs */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  @ViewChild('fileUpload') fileUploadComponent: FileUpload;

  constructor(
    private _arhivskaClient: ArhivskaKnjigaClient,
    private _dmsClient: DmsClient,
    private _alertService: AlertService,
    private _arhivskaService: ArhivskaKnjigaService,
    private _dialogService: DynamicDialogService
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._arhivskaClient.getArhivskaPodesavanja().subscribe((res) => {
        this.showKoraciKomponent = res.data?.kategorijeAutoGenerated!;
      })
    );

    this._subs.add(
      this._arhivskaService.getOznaciKaoUradjeno.subscribe((res) => {
        this.uradjenoEnabled = res;
      })
    );
  }

  openArhivskaDokumentaDialog() {
    this.dialogConfig.header = 'Podaci za generisanje dokumenta';
    this.dialogConfig.customSubmitButton = {
      label: 'Preuzmi',
      icon: 'fas fa-download',
    };

    const ref = this._dialogService.open(
      ArhivskaDokumentiDialogComponent,
      this.dialogConfig
    );
    // this._subs.add(
    //   this._arhivskaClient.getArhivskaZipedFiles().subscribe((res) => {
    //     var a = document.createElement('a');
    //     a.href = URL.createObjectURL(res.data);
    //     a.download =
    //       'Potrebna dokumentacija za dobijanje saglasnosti nadležnog ministarstva';
    //     a.click();
    //   })
    // );
  }

  preusmeriNaAdresu() {
    const arhiviURL =
      'https://arhivsrbije.rs/arhivska-delatnost/arhivi-u-srbiji';

    window.open(arhiviURL);
  }

  uploadResenjeFile(event: any) {
    var fileParameters: FileParameter[] = event.files.map((file: File) => {
      return {
        fileName: file.name,
        data: file,
      };
    });

    this._subs.add(
      this._dmsClient.uploadDokumente(fileParameters).subscribe((res) => {
        if (!res.succeeded) {
          this._alertService.addWarnMsg(res.messages![0]);
        }
        this._alertService.addSuccessMsg(res.data!);
        this.uploadCompleted = true;
        this.fileUploadComponent.clear();
      })
    );
  }

  oznaciKaoUradjenoHandle() {
    this._subs.add(
      this._arhivskaClient
        .updateArhivskaPodesavanja(
          new UpdateArhivskaPodesavanjaCommand({
            kategorijaAutomatskiCreated: false,
          })
        )
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.messages![0]);
            this.showKoraciKomponent = false;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
