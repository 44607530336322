<kodit-racun-form-container [racunFormConfig]="racunFormConfig">
  <kodit-text-input-field
    dodatni-input
    formCtrlId="broj-fiskalnog-isecka"
    formCtrlLabel="Broj fiskalnog isečka"
    [formCtrl]="fakturaForm.controls.brojFiskalnogIsecka"
    [isVertial]="false"
    formCtrlLabelWidth="150px"
  ></kodit-text-input-field>
</kodit-racun-form-container>
