import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpRequest } from '@angular/common/http';

const RACUN_YEAR_FILTER: string = 'racun_year_filter';

@Injectable({
  providedIn: 'root',
})
export class GodinaRacunaDropdownService {
  //#region Form control
  godinaCtrl: FormControl;

  getForm(): FormControl {
    if (!this.godinaCtrl) {
      this._initForm();
    }

    return this.godinaCtrl;
  }

  private _initForm() {
    const currentYear = new Date().getFullYear();
    this.godinaCtrl = new FormControl(currentYear);
  }

  get getGodina() {
    if (!this.godinaCtrl) {
      return new Date().getFullYear();
    }
    return this.godinaCtrl.value;
  }

  //#endregion

  //#region Should display racun year filter
  //shouldDisplayRacunYearFilter$ = new BehaviorSubject<boolean>(false);

  /* enableShouldDisplayRacunYearFilter() {
           this.shouldDisplayRacunYearFilter$.next(true);
           this._initForm();
         }*/

  /* disableShouldDisplayRacunYearFilter() {
           this.shouldDisplayRacunYearFilter$.next(false);
         }*/

  /* get getShouldDisplayRacunYearFilter() {
         return this.shouldDisplayRacunYearFilter$.asObservable();
       }
     
       private _getShouldDisplayRacunYearFilterValue() {
         if (!this.shouldDisplayRacunYearFilter$) {
           return false;
         }
         return this.shouldDisplayRacunYearFilter$.getValue();
       }*/

  /*resetShouldDisplayRacunYearFilter() {
        //this.disableShouldDisplayRacunYearFilter();
        this._initForm();
      }
    */
  //#endregion

  //#region Set header value
  applyHeaderFilter(request: HttpRequest<any>): HttpRequest<any> {
    // if (!this._getShouldDisplayRacunYearFilterValue()) {
    //   return request;
    // }
    if (request.headers.has(RACUN_YEAR_FILTER)) {
      return request;
    }
    return request.clone({
      setHeaders: {
        RACUN_YEAR_FILTER: this.getGodina.toString(),
      },
    });
  }

  //#endregion
}
