import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ArhivskaKnjigaClient, FileParameter } from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { ArhivskaKnjigaService } from 'libs/core/moduli/podesavanje/src/lib/arhivska-knjiga/arhivska-knjiga-service';
import { FileUpload } from 'primeng/fileupload';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-arhivska-file-upload',
  templateUrl: './arhivska-file-upload.component.html',
  styleUrls: ['./arhivska-file-upload.component.scss'],
})
export class ArhivskaFileUploadComponent
  implements OnInit, OnDestroy, OnChanges {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  importMultiple: boolean = false;
  customUpload: boolean = true;
  kategorijaId: number | undefined;
  uploadCompleted: boolean = false;

  constructor(
    private _arhivskaService: ArhivskaKnjigaService,
    private _client: ArhivskaKnjigaClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['uploadCompleted']) {
      // this.reset();
    }
  }

  otpremiDatoteku(event: any) {
    this._subs.add(
      this._arhivskaService.getGrupaKategorijaId.subscribe((res) => {
        this.kategorijaId = res;
      })
    );

    this._subs.add(
      this._client
        .importKategorijeSablon(this.kategorijaId, {
          data: event.files[0],
          fileName: event.files[0].name,
        })
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.messages![0]);
            this._arhivskaService.setStep01Completed = true;
            this._arhivskaService.setSledeciKorakEnabled = true;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
