import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { BaseService } from '@kodit/core/services';
import { IzlaznaFakturaDto, IzlazneFaktureClient } from '@kodit/core/data-api';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IzlaznaFakturaZaPredracunResolver
  extends BaseService
  implements Resolve<IzlaznaFakturaDto> {
  constructor(private _client: IzlazneFaktureClient, private _router: Router) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<IzlaznaFakturaDto>
    | Promise<IzlaznaFakturaDto>
    | IzlaznaFakturaDto
    | null {
    let predracunId = 0;
    try {
      predracunId = this._router.getCurrentNavigation().extras.state
        .predracunId;
    } catch {
      return null;
    }

    return this._client.getNewForPredracun(predracunId).pipe(
      map((res) => res.data),
      catchError(this.handleError),
      finalize(() => {})
    );
  }
}
