<div class="p-d-flex p-flex-column p-fluid">
  <kodit-field fieldLabel="Kome" fieldLabelFor="to">
    <p class="prilog-subtitle">* Pritisnite {{ "\<Enter\>"}} kako bi potvrdili E-Mail</p>
    <kodit-email-receiver-autocomplete-multiselect
      [ctrlFormArray]="toFormArray"
      [ctrlFormGroup]="ctrlFormGroup"
      ctrlId="to"
      ctrlField="name"
      ctrlPlaceholder="E-Mail..."
      (onSearch)="handleSearch($event)"
      (onSelect)="handleSelect($event)"
      (onUnselect)="handleUnselect($event)"
      [forceSelect]="false"
      [suggestions]="receiversSuggestions"
      (onEnter)="handleEnter($event)"
      [showNew]="showNew"
    >
    </kodit-email-receiver-autocomplete-multiselect>
  </kodit-field>

  <kodit-field fieldLabel="Cc" fieldLabelFor="cc" *ngIf="ccFormArray">
    <kodit-email-receiver-autocomplete-multiselect
      [ctrlFormArray]="ccFormArray"
      [ctrlFormGroup]="ctrlFormGroup"
      ctrlId="cc"
      ctrlField="name"
      ctrlPlaceholder="E-Mail..."
      (onSearch)="handleSearch($event, typeCC)"
      (onSelect)="handleSelect($event, typeCC)"
      (onUnselect)="handleUnselect($event, typeCC)"
      [forceSelect]="false"
      [suggestions]="receiversSuggestions"
      (onEnter)="handleEnter($event, typeCC)"
    ></kodit-email-receiver-autocomplete-multiselect>
  </kodit-field>

  <kodit-field
   *ngIf="naslovEmail"
    fieldLabel="E-mail naslov (Subject)"
    fieldLabelFor="emailNaslov"
  >
    <kodit-text-input
      formCtrlId="emailNaslov"
      [formCtrl]="naslovEmail"
    ></kodit-text-input>
  </kodit-field>

  <kodit-field
    fieldLabel="Dodatna poruka"
    fieldLabelFor="customPoruka"
    *ngIf="customPoruka"
  >
    <kodit-textarea-input
      ctrlId="customPoruka"
      [formCtrl]="customPoruka"
      ctrlPlaceholder="Uneti propratni tekst..."
    >
    </kodit-textarea-input>
  </kodit-field>

  <kodit-field
    fieldLabel="Dodatni prilog"
    fieldLabelFor="attachment"
    *ngIf="attachmentsFormArray"
  >
    <p class="prilog-subtitle">
      * PDF računa će automatski biti ubačen u E-Mail
    </p>
    <kodit-file-upload
      (onUpload)="handleUpload($event)"
      [uploadCompleted]="uploadCompleted"
      fileTypes=".jpg,.png,.jpeg,.pdf"
      [shouldAutoUpload]="true"
      [allowMultipleFiles]="true"
    ></kodit-file-upload>
  </kodit-field>

  <kodit-field fieldLabel="Sadržaj" fieldLabelFor="sadrzaj" *ngIf="bodyCtrl">
    <p-editor [formControl]="bodyCtrl" [style]="{ height: '320px' }"></p-editor>
  </kodit-field>
</div>
