import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'kodit-rok-placanja-input-numbers',
  templateUrl: './rok-placanja-input-numbers.component.html',
  styleUrls: ['./rok-placanja-input-numbers.component.scss'],
})
export class RokPlacanjaInputNumbersComponent implements OnInit {
  @Input()
  rokZaIzlazneCtrl: FormControl;

  @Input()
  rokZaUlazneCtrl: FormControl;

  constructor() {}

  ngOnInit(): void {}

  get getSuffixIzlazne() {
    if (!this.rokZaIzlazneCtrl) {
      return '';
    }
    return this.rokZaIzlazneCtrl.value == 1 ||
      (this.rokZaIzlazneCtrl.value % 10 == 1 &&
        this.rokZaIzlazneCtrl.value != 11)
      ? ' dan'
      : ' dana';
  }

  get getSuffixUlazne() {
    if (!this.rokZaUlazneCtrl) {
      return '';
    }
    return this.rokZaUlazneCtrl.value == 1 ||
      (this.rokZaUlazneCtrl.value % 10 == 1 && this.rokZaUlazneCtrl.value != 11)
      ? ' dan'
      : ' dana';
  }
}
