<div [formGroup]="form" class="p-d-flex p-flex-row p-fluid">
  <kodit-filter-name class="flex-1-1" title="Filter lagera"></kodit-filter-name>
  <div class="flex-1-1 p-mr-4">
    <kodit-field>
      <kodit-text-input
        ctrlPlaceholder="Naziv"
        [formCtrl]="form.controls.naziv"
        formCtrlId="naziv"
      ></kodit-text-input>
    </kodit-field>

    <kodit-field>
      <kodit-text-input
        ctrlPlaceholder="Šifra"
        [formCtrl]="form.controls.sifra"
        formCtrlId="sifra"
      ></kodit-text-input>
    </kodit-field>

    <div class="p-field">
      <kodit-stranka-muliselect
        ctrlPlaceholder="Stranke"
        [strankeFormArray]="form.controls.stranke"
      ></kodit-stranka-muliselect>
    </div>
  </div>
  <div class="flex-1-1 p-mr-4">
    <kodit-calendar
      ctrlPlaceholder="Datum prometa"
      formCtrlId="datumPrometa"
      [formCtrl]="form.controls.datumPrometa"
    ></kodit-calendar>
    <kodit-field class="p-mt-3 p-ml-3">
      <kodit-checkbox
        labelText="Prikaži nule i minus na štampanju lagera"
        formCtrlId="prikaziNuleIMinus"
        [formCtrl]="form.controls.prikaziNuleIMinus"
      ></kodit-checkbox>
    </kodit-field>
  </div>
</div>
