import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SharedClient } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-prikaz-rabata-dropdown',
  templateUrl: './prikaz-rabata-dropdown.component.html',
  styleUrls: ['./prikaz-rabata-dropdown.component.scss'],
})
export class PrikazRabataDropdownComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];

  /** I/O */
  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string;
  @Input() parentCtrl: any = 'body';

  constructor(private _client: SharedClient) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client.getPrikazRabata().subscribe((res) => {
        this.items = res.data.prikazRabataDtoList.map((x) => ({
          value: x.prikazRabata,
          label: x.prikazRabataStr,
          title: x.opis,
        }));
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
