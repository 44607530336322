<kodit-info-container>
  <ng-container body-2-of-3>
    <kodit-stanje-magacina [robaId]="robaId"></kodit-stanje-magacina>

    <!-- Tabela robe na lageru -->
    <div class="p-mt-5">
      <kodit-lager-transakcije-table></kodit-lager-transakcije-table>
    </div>
  </ng-container>

  <ng-container body-1-of-3>
    <kodit-card-container
      [isTitleWithIcon]="true"
      titleIcon="fa-regular fa-cart-flatbed"
      [showDivider]="true"
    >
      <div card-title-with-icon>
        {{ robaDto?.predmetStavkeDto.naziv }}
      </div>

      <div class="p-d-flex p-flex-column p-jc-between gap-6" card-body>
        <kodit-card-body-text
          label="Šifra"
          [text]="robaDto?.predmetStavkeDto.sifra"
        ></kodit-card-body-text>
        <kodit-card-body-text
          label="Jedinica mere"
          [text]="robaDto?.predmetStavkeDto.jedinicaMereDto?.naziv"
        ></kodit-card-body-text>

        <!-- <kodit-card-body-text
                  label="Dobavljač"
                  text="#Dodati info"
                ></kodit-card-body-text> -->
      </div>

      <div class="p-d-flex p-jc-end p-mb-2" card-actions>
        <button
          icon="far fa-edit"
          pButton
          type="button"
          label=""
          class="p-button p-button-outlined"
          (click)="onEdit()"
        ></button>
      </div>
    </kodit-card-container>

    <div class="p-mt-5" *ngIf="showSastavnica">
      <kodit-sastavnica-table></kodit-sastavnica-table>
    </div>
  </ng-container>
</kodit-info-container>
