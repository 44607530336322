import { Injectable } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { IMagacinDto, IPoslovnaJedinicaDto, VrstaPoslovneJedinice } from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { MagacinFormService } from '../..';
import { AdresaFormService } from '../common/adresa-form.service';

@Injectable({
  providedIn: 'root',
})
export class PoslovnaJedinicaFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _adresaFS: AdresaFormService,
    private _magacinFS: MagacinFormService
  ) {}

  /**
   * Konvertuje model za PJ i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model PJ od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetPoslovnaJedinicaFormGroup(
    strankaId: number,
    model?: IPoslovnaJedinicaDto
  ): FormGroupTypeSafe<IPoslovnaJedinicaDto> {
    return this._fb.group<IPoslovnaJedinicaDto>({
      id: new FormControl(model?.id),
      strankaId: new FormControl(strankaId, {
        validators: [Validators.min(0)],
      }),
      naziv: new FormControl(model?.naziv, {
        validators: [Validators.required, Validators.maxLength(512)],
      }),
      opis: new FormControl(model?.opis, {
        validators: [Validators.maxLength(512)],
      }),
      sifraDelatnosti: new FormControl(model?.sifraDelatnosti, {
        validators: [Validators.maxLength(5)],
      }),
      vrsta : new FormControl(model?.vrsta ?? VrstaPoslovneJedinice.SEDISTE),
      jeAktivna: new FormControl(model?.jeAktivna ?? true),
      adresa: this._adresaFS.GetAdresaFormGroup(model?.adresa),
      magacini: this._magacinFS.GetMagaciniFormArray(model?.magacini),
    });
  }
}
