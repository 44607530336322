<form [formGroup]="form">
  <div class="p-fluid">
    <kodit-field *ngIf="showSaveEmail">
      <kodit-email-input
        [formCtrl]="form.controls.kontaktEmail"
      >
      </kodit-email-input>
    </kodit-field>

    <kodit-field fieldLabel="Dodatni prilog/prilozi" fieldLabelFor="attachment">
      <p class="prilog-subtitle">
        * Možete dodati najviše 2 PDF fajla od po 25 MB
      </p>
      <kodit-file-upload
        (onUpload)="handleUpload($event)"
        fileTypes=".pdf"
        [shouldAutoUpload]="true"
        [allowMultipleFiles]="true"
      ></kodit-file-upload>
    </kodit-field>
    <kodit-field
      *ngIf="prikaziCrf"
    >
      <kodit-checkbox
        labelText="Pošalji na CRF"
        formCtrlId="posaljiNaCrf"
        [formCtrl]="form.controls.posaljiNaCrf"
      ></kodit-checkbox>
    </kodit-field>
  </div>
</form>
