<kodit-card-container>
  <div card-title>Konfiguracija deviznog računa</div>

  <div card-right-side>
    <button
      icon="far fa-edit"
      pButton
      type="button"
      label="Izmeni"
      class="p-button p-button-outlined"
      (click)="onEdit()"
    ></button>
  </div>

  <div
    class="p-d-flex p-jc-between"
    card-body
    *ngIf="konfiguracijaDeviznogRacunaDto"
  >
    <div class="p-d-flex p-flex-column gap-6" *ngIf="!showEmptyMessage">
      <kodit-card-body-text
        label="Account name"
        [text]="konfiguracijaDeviznogRacunaDto.accountName"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Account address"
        [text]="konfiguracijaDeviznogRacunaDto.accountAddress"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Bank"
        [text]="konfiguracijaDeviznogRacunaDto.bank"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="IBAN"
        [text]="konfiguracijaDeviznogRacunaDto.iban"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="SWIFT"
        [text]="konfiguracijaDeviznogRacunaDto.swift"
      ></kodit-card-body-text>
    </div>
  </div>

  <div card-body *ngIf="!konfiguracijaDeviznogRacunaDto">
    <span>Podrazumevani podaci za devizne račune nisu unešeni</span>
  </div>
</kodit-card-container>
