import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FormatIzvoza, RacuniClient} from '@kodit/core/data-api';
import {AlertService, ButtonService, SharedService} from '@kodit/core/services';
import {DynamicDialogConfig, DynamicDialogRef} from '@kodit/core/shared-ui';
import {SelectItem} from 'primeng/api';
import {Subscription} from 'rxjs';

@Component({
  selector: 'kodit-racun-export-form',
  templateUrl: './racun-export-form.component.html',
  styleUrls: ['./racun-export-form.component.scss'],
})
export class RacunExportFormComponent implements OnInit, OnDestroy {
  /** Subscription */
  _subs: Subscription;

  /** Props */
  racuni: number[] = [];
  dropdownItems: SelectItem[];
  form: FormGroup;
  shouldShowWarningMessage : boolean;

  constructor(
    private _fb: FormBuilder,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _client: RacuniClient,
    private _sharedService : SharedService,
    private _alertService : AlertService,
    private _buttonService : ButtonService
  ) {
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    // ucitavamo spisak odabranih racuna
    this.racuni = this._dialogConfig.data.racuni;

    // init form and dropdown
    this._initForm();
    this._initDropdown();

    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._export();
      })
    );

    this._subs.add(
      this.form.valueChanges.subscribe((res)=>{
        if(res.format === FormatIzvoza.INFORMATIKA_TXT && this.racuni.length === 0){
          this.shouldShowWarningMessage = true;
          this._buttonService.disableButton();
        }
        else{
          this.shouldShowWarningMessage = false;
          this._buttonService.enableButton();
        }
      })
    )
  }

  private _export() {
    this._sharedService.displayLoadingScreen(true, 'Export računa u toku...');
    this._subs.add(
      this._client.exportRacun(this.form.value).subscribe((res : any) => {
        if(res.data.contentType === 'text/plain'){
          this._sharedService.downloadFileAsTXT(
            res.data.content,
            res.data.fileName,
            res.data.contentType
          );
        }
        else{
          this._sharedService.downloadFileAsExcel(
            res.data.content,
            res.data.fileName,
            res.data.contentType
          )
        }

        this._alertService.addSuccessMsg("Računi su uspešno izveženi!");
        this._dialogRef.close(true);
      })
    );
  }

  private _initForm() {
    this.form = this._fb.group({
      format: new FormControl(FormatIzvoza.EXCEL_XLS),
      racuni: new FormControl(this.racuni),
    });
  }

  private _initDropdown() {
    this.dropdownItems = [
      {
        label : 'Excel (.xls, .xlsx)',
        value : FormatIzvoza.EXCEL_XLS
      },
      {
        label: 'Informatika (.txt)',
        value: FormatIzvoza.INFORMATIKA_TXT
      }
    ];
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
