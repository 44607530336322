import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup} from '@angular/forms';
import { Router } from '@angular/router';
import {
  CheckEmailExistanceQuery,
  CheckPasswordValidityQuery,
  ICreateMojaFirmaCommand,
  IdentityClient,
  SendConfirmationCodeMojaFirmaCommand,
  TokensClient,
  UserAccessVia,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService, MixpanelService } from '@kodit/core/services';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { RegisterService } from '../register.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kodit-podaci-korisnika',
  templateUrl: './podaci-korisnika.component.html',
  styleUrls: ['./podaci-korisnika.component.scss'],
})
export class PodaciKorisnikaComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<ICreateMojaFirmaCommand>;

  /** Conditional props */
  showError: boolean;
  alreadyChecking: boolean;
  isExistingEmail: boolean;
  shouldDisableInput : boolean = false;

  constructor(
    private _router: Router,
    private _registerService: RegisterService,
    private _formHelper: FormHelper,
    private _client: TokensClient,
    private _identityClient: IdentityClient,
    private _alertService: AlertService,
    private _mixpanelService : MixpanelService
  ) {}

  ngOnInit(): void {
    this.form = this._registerService.getForm;

    if(!!this.form.controls.podaciKorisnikaDto.get('email').value){
      this.shouldDisableInput = true;
    }
  }

  routeToPreviousStep(){
    this._router.navigate([
        '/autentikacija',
        'registracija',
        'podaci-kompanije',
      ]);
  }

  routeToNextStep(){
    if (!this.form.controls.podaciKorisnikaDto.valid) {
        this._formHelper.invalidateForm(
          this.form.controls.podaciKorisnikaDto as FormGroup
        );
        return;
      }

      this._subs.add(
        this._identityClient
          .checkPasswordValidity(
            new CheckPasswordValidityQuery({
              password: this.form.value.podaciKorisnikaDto.sifra,
              passwordRepeat: this.form.value.podaciKorisnikaDto.sifraRepeat,
            })
          )
          .subscribe((res) => {
            if (res.succeeded) {
              this._sendConfirmationCode();
            } else {
              this._alertService.addWarnMsg(res.messages[0]);
            }
          })
      );
  }

  private _sendConfirmationCode() {
    this._subs.add(
      this._client
        .sendConfirmationCode(
          new SendConfirmationCodeMojaFirmaCommand({
            ime: this.form.value.podaciKorisnikaDto.ime,
            korisnikEmail: this.form.value.podaciKorisnikaDto.email,
            verifyVia: UserAccessVia.EMAIL,
          })
        )
        .subscribe(() => {
          this._mixpanelService.registrationConfirmEmailStep(this.form.value);
          this._router.navigate([
            '/autentikacija',
            'registracija',
            'potvrda-emaila',
          ]);
        })
    );
  }

  // trenutno ne koristimo na email field-u (vraca nam user podatke ako njegov mail postoji u bazi)
  checkIsExistingUser() {
    if (
      !this.form.controls.podaciKorisnikaDto.get('email').valid ||
      this.alreadyChecking
    ) {
      return;
    }
    this.alreadyChecking = true;
    this._subs.add(
      this._client
        .checkEmailExistance(
          new CheckEmailExistanceQuery({
            email: this.form.value.podaciKorisnikaDto.email,
          })
        )
        .subscribe(
          (res) => {
            if (res.data) {
              this.form.controls.podaciKorisnikaDto.patchValue(res.data);
              this.form.controls.podaciKorisnikaDto.updateValueAndValidity();
              this.isExistingEmail = true;
              this.shouldDisableInput = true;
            }
          },
          (error) => {
            this._subs.add(
              error.subscribe((x: string) => {
                this._alertService.addFailedMsg(x);
              })
            );
          }
        )
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
