import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PdvEvidencijaTableService {
  constructor() {}

  activeTab$ = new BehaviorSubject<number>(0);
  set setActiveTab(value: number) {
    this.activeTab$.next(value);
  }
  get getActiveTab() {
    return this.activeTab$.asObservable();
  }
  resetActiveTab() {
    this.activeTab$.next(0);
  }
}
