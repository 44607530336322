<form [formGroup]="form">
  <div class="p-fluid">
    <!-- U sistemu PDV-a -->
    <div class="p-field">
      <kodit-checkbox
        [formCtrl]="form.controls.uSistemuPDVa"
        formCtrlId="u-sistemu-pdva"
        labelText="Moja firma je u sistemu PDV-a"
      ></kodit-checkbox>
    </div>
    <!-- Podrazumevana stopa PDV -->
    <div class="p-field">
      <kodit-field fieldLabel="Podrazumevana stopa" fieldLabelFor="stopa-pdv">
        <kodit-pdv-stopa-dropdown
          [pdvStopaCtrl]="form.controls.stopaPDV"
          formCtrlId="stopa-pdv"
        ></kodit-pdv-stopa-dropdown>
      </kodit-field>
    </div>
    <!-- Razlog pdv izuzeca -->
    <kodit-field fieldLabel="Oznaka PDV kategorije" *ngIf="shouldShowRazlogPdvIzuzeca">
      <kodit-pdv-kategorija-dropdown
        [pdvKategorijaCtrl]="form.controls.oznakaPdvKategorije"
      ></kodit-pdv-kategorija-dropdown>
    </kodit-field>

    <kodit-field fieldLabel="Razlog PDV izuzeća" *ngIf="shouldShowRazlogPdvIzuzeca">
      <kodit-pdv-razlog-izuzeca-dropdown
        [isVisible]="true"
        [pdvRazlogIzuzecaCtrl]="form.controls.razlogPdvIzuzecaId"
        [oznakaPdvKategorije]="form.controls.oznakaPdvKategorije.value"
      >
      </kodit-pdv-razlog-izuzeca-dropdown>
    </kodit-field>
    <!-- Upisani kapital -->
    <div class="p-d-flex">
      <div class="p-field p-mr-3">
        <kodit-field
          fieldLabel="Upisani kapital"
          fieldLabelFor="upisani-kapital"
        >
          <kodit-text-input
            [formCtrl]="form.controls.upisaniKapital"
            formCtrlId="upisani-kapital"
          ></kodit-text-input>
        </kodit-field>
      </div>
      <!-- Valuta upisanog kapitala -->
      <div class="p-field">
        <kodit-field fieldLabel="Valuta" fieldLabelFor="valuta">
          <kodit-valuta-dropdown
            [valutaCtrl]="form.controls.valutaId"
            formCtrlId="valuta"
          ></kodit-valuta-dropdown>
        </kodit-field>
      </div>
    </div>
    <!-- Ovlascena osoba za potpis -->
    <div class="p-field">
      <kodit-field
        fieldLabel="Ovlašćeno lice za potpis"
        fieldLabelFor="ovlascenoLice"
      >
        <kodit-text-input
          [formCtrl]="form.controls.imeOsobeOvlasceneZaPotpisivanje"
          formCtrlId="ovlascenoLice"
        ></kodit-text-input>
      </kodit-field>
    </div>
    <!-- GLN -->
    <div class="p-field">
      <kodit-field fieldLabel="Krovni GLN" fieldLabelFor="gln">
        <kodit-text-input
          [formCtrl]="form.controls.gln"
          formCtrlId="gln"
        ></kodit-text-input>
      </kodit-field>
    </div>
    <!-- AprBD -->
    <div class="p-field">
      <kodit-field fieldLabel="AprBD" fieldLabelFor="aprbd">
        <kodit-text-input
          [formCtrl]="form.controls.aprBD"
          formCtrlId="aprbd"
        ></kodit-text-input>
      </kodit-field>
    </div>
    <!-- PePDV -->
    <div class="p-field">
      <kodit-field fieldLabel="PePDV" fieldLabelFor="pepdv">
        <kodit-text-input
          [formCtrl]="form.controls.pePDV"
          formCtrlId="pepdv"
        ></kodit-text-input>
      </kodit-field>
    </div>

    <div class="p-field">
      <kodit-field fieldLabel="Website url" fieldLabelFor="website-url">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">www</span>
          <input
            type="text"
            pInputText
            placeholder="Website"
            [formControl]="form.controls.websiteUrl"
            formCtrlId="website-url"
          />
        </div>
      </kodit-field>
    </div>

    <div class="p-field">
      <kodit-field fieldLabel="E-mail" fieldLabelFor="email">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">@</span>
          <input
            type="text"
            pInputText
            placeholder="e-mail"
            [formControl]="form.controls.email"
            formCtrlId="email"
          />
        </div>
      </kodit-field>
    </div>

    <div class="p-field">
      <kodit-field fieldLabel="Vlasnik / Vlasnici" fieldLabelFor="vlasnici">
        <kodit-text-input
          [formCtrl]="form.controls.vlasnici"
          formCtrlId="vlasnici"
        ></kodit-text-input>
      </kodit-field>
    </div>

    <div class="p-field">
      <kodit-field
        fieldLabel="Zastupnik / Zastupnici"
        fieldLabelFor="zastupnici"
      >
        <kodit-text-input
          [formCtrl]="form.controls.zastupnici"
          formCtrlId="zastupnici"
        ></kodit-text-input>
      </kodit-field>
    </div>

    <div class="p-field">
      <kodit-field fieldLabel="Ulica i broj" fieldLabelFor="ulica-i-broj">
        <kodit-text-input
          [formCtrl]="form.controls.ulicaIBroj"
          formCtrlId="ulica-i-broj"
        ></kodit-text-input>
      </kodit-field>
    </div>
    <div class="p-field">
      <kodit-field fieldLabel="Grad" fieldLabelFor="grad">
        <kodit-text-input
          [formCtrl]="form.controls.grad"
          formCtrlId="grad"
        ></kodit-text-input>
      </kodit-field>
    </div>
    <div class="p-field">
      <kodit-field fieldLabel="Poštanski broj" fieldLabelFor="postanski-broj">
        <kodit-text-input
          [formCtrl]="form.controls.postanskiBroj"
          formCtrlId="postanski-broj"
        ></kodit-text-input>
      </kodit-field>
    </div>
  </div>
</form>
