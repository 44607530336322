import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'kodit-potvrda-registracije',
  templateUrl: './potvrda-registracije.component.html',
  styleUrls: ['./potvrda-registracije.component.scss']
})
export class PotvrdaRegistracijeComponent implements OnInit,OnDestroy {
  private intervalId: number;

  constructor(private _router : Router) { }

  ngOnInit(): void {
    this.intervalId = setTimeout(() => {
      this.routeToLogin();
    }, 10000);
  }

  routeToLogin(){
    this._router.navigate(['/autentikacija', 'prijava']).then();
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
