import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ArhivskaKnjigaClient,
  SkladisnaJedinicaDto,
  TipPristupa,
} from '@kodit/core/data-api';
import {
  ActionType,
  DynamicDialogConfig,
  DynamicDialogRef,
  DynamicDialogService,
  TableConfig,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { SkladisneJediniceFormComponent } from '../skladisne-jedinice-form/skladisne-jedinice-form.component';
import { ArhivskaKnjigaService } from '../../arhivska-knjiga-service';

@Component({
  selector: 'kodit-skladisne-jedinice-table',
  templateUrl: './skladisne-jedinice-table.component.html',
  styleUrls: ['./skladisne-jedinice-table.component.scss'],
})
export class SkladisneJediniceTableComponent implements OnInit, OnDestroy {
  /** Configurations */
  tableConfig: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  skladisneJediniceTable: SkladisnaJedinicaDto[];

  constructor(
    private _client: ArhivskaKnjigaClient,
    private _dialogService: DynamicDialogService,
    private _arhivskaService: ArhivskaKnjigaService
  ) {}

  ngOnInit(): void {
    this.setTableConfig().then();
    this._loadTable();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Skladišne jedinice',
      columns: [
        {
          field: 'naziv',
          header: 'Naziv skladišne jedinice',
          type: 'text',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          hasAccessTooltip: 'Unos nove skladišne jedinice',
          callback: () => {
            this.dialogConfig.header = 'Unos nove skladišne jedinice';
            this.dialogConfig.data = { id: null };
            this.openDialog(this.dialogConfig);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          tipPristupa: TipPristupa.ADMIN_CRUD,
          hasAccessTooltip: 'Izmenite skladišnu jedinicu',
          callback: (index: number) => {
            this.dialogConfig.data = {
              id: this.skladisneJediniceTable[index]?.id,
            };
            this.dialogConfig.header = 'Izmena podataka skladišne jedinice';
            this.openDialog(this.dialogConfig);
          },
        },
      ],
    });
  }

  private _loadTable() {
    this._subs.add(
      this._client.getSkladisneJediniceList().subscribe((res) => {
        this.skladisneJediniceTable = res.data.responseList;
        if (this.skladisneJediniceTable.length !== 0) {
          this.setArhivskaConfig();
        }
      })
    );
  }

  private setArhivskaConfig() {
    this._arhivskaService.setStep01Completed = true;
    this._arhivskaService.setStep02Completed = true;
    this._arhivskaService.setSledeciKorakEnabled = true;
  }

  openDialog(config: DynamicDialogConfig) {
    const ref: DynamicDialogRef = this._dialogService.open(
      SkladisneJediniceFormComponent,
      config
    );

    this._subs.add(
      ref.onClose.subscribe((dto: any) => {
        if (dto) {
          this._loadTable();
        }
      })
    );
  }

  ngOnDestroy(): void {}
}
