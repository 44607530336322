import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  IPredmetStavkeFilterDto,
  PredmetStavkeFilterDto,
} from '@kodit/core/data-api';

import { UslugaFormService } from '@kodit/core/form-definitions';
import { AdvancedFilter } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { UslugaFilterService } from './usluga-filter.service';

@Component({
  selector: 'kodit-usluga-filter',
  templateUrl: './usluga-filter.component.html',
  styleUrls: ['./usluga-filter.component.scss'],
})
export class UslugaFilterComponent
  implements OnInit, AdvancedFilter<IPredmetStavkeFilterDto> {
  /** Props */
  filterData: IPredmetStavkeFilterDto;
  availableFilters?: {};
  form: FormGroupTypeSafe<IPredmetStavkeFilterDto>;

  constructor(
    private _uslugaFS: UslugaFormService,
    private _filterService: UslugaFilterService
  ) {}

  ngOnInit(): void {
    this.form = this._uslugaFS.GetUslugaTableRequest(this.filterData);
  }

  onFilterSubmit() {
    this._filterService.setAdvanceFilterSubmitted = {
      filterData: this.form.value,
      shouldApplyFilter: true,
    };
  }

  onFilterHide(shouldReset: boolean) {
    this._filterService.setAdvanceFilterSubmitted = {
      filterData: shouldReset ? new PredmetStavkeFilterDto() : this.form.value,
      shouldApplyFilter: shouldReset,
    };
  }
}
