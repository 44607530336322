import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {DynamicDialogConfig, DynamicDialogService} from "@kodit/core/shared-ui";
import {DialogSize} from "../../../../../../shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config";
import {OpstaPravilaFiskalnaFormComponent} from "../opsta-pravila-fiskalna-form/opsta-pravila-fiskalna-form.component";
import {FiskalniRacuniClient, IOpstaPravilaFisklaniDto, IOpstaPravilaKonfiguracijaDto} from "@kodit/core/data-api";
import {AlertService} from "@kodit/core/services";

@Component({
  selector: 'kodit-opsta-pravila-fiskalna',
  templateUrl: './opsta-pravila-fiskalna.component.html',
  styleUrls: ['./opsta-pravila-fiskalna.component.scss']
})
export class OpstaPravilaFiskalnaComponent implements OnInit, OnDestroy {

  private _subs : Subscription = new Subscription();
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);
  opstaPravilaDto: IOpstaPravilaFisklaniDto;
  constructor( private _dialogService: DynamicDialogService, private _client: FiskalniRacuniClient, private _alertService: AlertService) { }

  ngOnInit(): void {
    this.load();
  }

  private load(): void{
    this._subs.add(
      this._client.getOpstaPravilaFiskalna().subscribe(
        (result) => {
          this.opstaPravilaDto = result.data;
        },
        (error) => {
          this._subs.add(
            error.subscribe((x: string) => {
              this._alertService.addFailedMsg(x);
            })
          );
        }
      )
    );
  }

  onEdit() {
    this.dialogConfig.data = {
      model: this.opstaPravilaDto
    };
    this.dialogConfig.header = 'Izmena opštih pravila';
    this.openDialog(this.dialogConfig);
  }

  openDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(OpstaPravilaFiskalnaFormComponent, config);

    this._subs.add(
      ref.onClose.subscribe((dto: IOpstaPravilaFisklaniDto) => {
        if (dto) {
          this.opstaPravilaDto = dto;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
