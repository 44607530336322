import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IMojaFirmaKompenzacijaDto,
  MojaFirmaClient,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-moja-firma-kompenzacija-form',
  templateUrl: './moja-firma-kompenzacija-form.component.html',
  styleUrls: ['./moja-firma-kompenzacija-form.component.scss'],
})
export class MojaFirmaKompenzacijaFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  vrstaRacuna: VrstaRacuna;

  mojaFirmaKompenzacija: IMojaFirmaKompenzacijaDto = {};

  constructor(private _mojaFirmaClient: MojaFirmaClient) {}

  ngOnInit(): void {
    this._getMojaFirmaKompenzacija();
  }

  private _getMojaFirmaKompenzacija() {
    this._subs.add(
      this._mojaFirmaClient.getMojaFirmaKompenzacija().subscribe((res) => {
        this.mojaFirmaKompenzacija = res.data;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
