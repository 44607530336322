<div class="p-d-flex p-flex-column">
  <div class="p-d-flex p-flex-row p-jc-between p-ai-center p-mb-3">
    <div class="p-flex align-items-center title">
      Novi unos
    </div>
    <div class="p-d-flex p-flex-row p-ai-center">
      <div *ngIf="shouldDisplaySertifikate">
        <kodit-sertifikati-fiskalnog-racuna-dropdown></kodit-sertifikati-fiskalnog-racuna-dropdown>
      </div>
      <div *ngIf="shouldDisplayEsirVreme" class="p-ml-4">
        <i class="fa-solid fa-stopwatch esir_vreme" (click)="openEsirVremeDialog()"></i>
      </div>
    </div>
  </div>
  <!--Vrsta racuna -->
  <kodit-akcija-fiskalnog-racuna-card
    class="p-fluid vrsta-racuna"
    cardIcon="assets/icons/layers-minimalistic-bold-gray.svg"
    cardTitle="Vrsta računa"
    cardSubtitle="{{ vrstaRacunaSubtitle ?? 'Izaberi'}}"
    [useFullRadius]="true"
    (click)="openVrstaRacunaDialog()"
  >
  </kodit-akcija-fiskalnog-racuna-card>
  <div class="p-d-flex p-flex-column p-flex-md-row p-mt-2">
      <kodit-akcija-fiskalnog-racuna-card
        class="flex-grow"
        cardIcon="assets/icons/user-id-bold-gray.svg"
        cardTitle="Odaberite kupca"
        cardSubtitle="{{ kupacSubtitle ?? 'Izaberi'}}"
        [useLeftRadius]="true"
        (click)="openOdabirKupcaDialog()"
      >
      </kodit-akcija-fiskalnog-racuna-card>
      <!--Referentni racun  -->
      <kodit-akcija-fiskalnog-racuna-card
        class="flex-grow"
        cardIcon="assets/icons/bill-bold-gray.svg"
        cardTitle="Referentni račun"
        cardSubtitle="{{ referentniRacunSubtitle ?? 'Izaberi' }}"
        [useBorderNone]="true"
        (click)="openReferentniRacunDialog()"
      >
      </kodit-akcija-fiskalnog-racuna-card>
      <!--Reklamni blok  -->
      <kodit-akcija-fiskalnog-racuna-card
        class="flex-grow"
        cardIcon="assets/icons/clapperboard-edit-bold-gray.svg"
        cardTitle="Reklamni blok"
        cardSubtitle="{{ reklamniBlokSubtitle ?? 'Izaberi'}}"
        [useRightRadius]="true"
        (click)="openReklamniBlokDialog()"
      >
      </kodit-akcija-fiskalnog-racuna-card>
  </div>
</div>
