<ng-container *ngIf="formCtrl">
  <p-dropdown
    [formControl]="formCtrl"
    appendTo="body"
    [optionLabel]="optionLabel"
    [options]="options"
    [inputId]="formCtrlId"
    [tooltip]="ctrlTooltip"
    [tooltipPosition]="ctrlTooltipPosition"
    [placeholder]="ctrlPlaceholder"
    [filter]="canFilter"
    [filterPlaceholder]="ctrlFilterPlaceholder"
    [filterBy]="ctrlFilterBy"
    [styleClass]="ctrlStyleClass"
    [group]="isGrouped"
    [showClear]="canClear"
    [disabled]="ctrlDisabled"
    (onChange)="handleOnItemChange($event)"
  >
    <ng-template let-outerContext pTemplate="selectedItem" *ngIf="selectedItem">
      <ng-container
        *ngTemplateOutlet="selectedItem; context: { $implicit: outerContext }"
      >
      </ng-container>
    </ng-template>

    <ng-template let-outerContext pTemplate="item" *ngIf="item">
      <ng-container
        *ngTemplateOutlet="item; context: { $implicit: outerContext }"
      >
      </ng-container>
    </ng-template>
  </p-dropdown>

  <kodit-error-info-required [formCtrl]="formCtrl"></kodit-error-info-required>
</ng-container>

<ng-container *ngIf="!formCtrl">
  <p-dropdown
    appendTo="body"
    [optionLabel]="optionLabel"
    [options]="options"
    [inputId]="formCtrlId"
    [tooltip]="ctrlTooltip"
    [tooltipPosition]="ctrlTooltipPosition"
    [placeholder]="ctrlPlaceholder"
    [filter]="canFilter"
    [filterPlaceholder]="ctrlFilterPlaceholder"
    [filterBy]="ctrlFilterBy"
    [styleClass]="ctrlStyleClass"
    [group]="isGrouped"
    [showClear]="canClear"
    [disabled]="ctrlDisabled"
    (onChange)="handleOnItemChange($event)"
  >
    <ng-template let-outerContext pTemplate="selectedItem" *ngIf="selectedItem">
      <ng-container
        *ngTemplateOutlet="selectedItem; context: { $implicit: outerContext }"
      >
      </ng-container>
    </ng-template>

    <ng-template let-outerContext pTemplate="item" *ngIf="item">
      <ng-container
        *ngTemplateOutlet="item; context: { $implicit: outerContext }"
      >
      </ng-container>
    </ng-template>
  </p-dropdown>
</ng-container>
