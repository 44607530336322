<div class="p-d-flex p-flex-column p-flex-md-row header-box p-mb-5">
  <div card class="k-card info-card">
    <div class="p-d-flex p-jc-between _header">
      <div class="p-text-left _title">Računi izašli iz valute</div>
    </div>
    <kodit-divider></kodit-divider>
    <div class="p-d-flex p-jc-between p-ai-center p-flex-wrap">
      <div class="p-d-flex p-flex-column">
        <div class="p-pr-2 p-label p-mb-2">
          Vrednost izdatih računa van valute:
        </div>
        <div class="p-text-left p-mb-2" style="font-size: 1.5rem">
          {{ saldoStrankaDto?.potrazivanjaVanValute | currencyNumber }}
        </div>
        <div class="p-text-left">
          Na osnovu
          {{ saldoStrankaDto?.brojFakturaPotrazivanjaVanValute }} faktura
        </div>
      </div>
      <div class="p-d-flex p-flex-column">
        <div class="p-pr-2 p-label p-mb-2">
          Vrednost primljenih računa van valute:
        </div>
        <div class="p-text-left p-mb-2" style="font-size: 1.5rem">
          {{ saldoStrankaDto?.dugovanjaVanValute | currencyNumber }}
        </div>
        <div class="p-text-left">
          Na osnovu {{ saldoStrankaDto?.brojFakturaDugovanjaVanValute }} faktura
        </div>
      </div>
    </div>
  </div>

  <div
    card
    class="k-card promet-card p-d-flex p-jc-between p-ai-center p-flex-wrap"
  >
    <div class="p-d-flex p-flex-column">
      <div class="p-pr-2 p-label p-mb-2">Potraživanja:</div>
      <div
        class="p-text-left p-mb-2"
        style="font-size: 1.5rem; font-weight: 500"
      >
        {{ saldoStrankaDto?.potrazivanja | currencyNumber }}
      </div>
      <div class="p-text-left">
        Na osnovu {{ saldoStrankaDto?.brojFakturaPotrazivanja }} fakture/a
      </div>
    </div>
    <div class="p-d-flex p-flex-column">
      <div class="p-pr-2 p-label p-mb-2">Dugovanja:</div>
      <div
        class="p-text-left p-mb-2"
        style="font-size: 1.5rem; font-weight: 500"
      >
        {{ saldoStrankaDto?.dugovanja | currencyNumber }}
      </div>
      <div class="p-text-left">
        Na osnovu {{ saldoStrankaDto?.brojFakturaDugovanja }} fakture/a
      </div>
    </div>
  </div>
</div>

<kodit-page>
  <kodit-table [tableConfig]="tableConfig" [items]="pravnaLicaTable" [chipItems]="chipItems">
  </kodit-table>
</kodit-page>
