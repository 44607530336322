import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-helper-icon',
  templateUrl: './helper-icon.component.html',
  styleUrls: ['./helper-icon.component.scss'],
})
export class HelperIconComponent implements OnInit {
  @Input() helperText: string = '';

  constructor() {}

  ngOnInit(): void {}
}
