import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import {
  BankaIzvoda,
  FileType,
  IIzvodFajlIzvorDropdownDto,
  IzvodiClient,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-izvod-fajl-izvor-dropdown',
  templateUrl: './izvod-fajl-izvor-dropdown.component.html',
  styleUrls: ['./izvod-fajl-izvor-dropdown.component.scss'],
})
export class IzvodFajlIzvorDropdownComponent implements OnInit, OnDestroy {
  /** Subs */
  private _getSub: Subscription = new Subscription();

  /** Props */
  ctrlOptions: SelectItem[] = [];
  izvodFajlIzvori: IIzvodFajlIzvorDropdownDto[] = [];
  formGroup: FormGroup;

  /** I/O */
  @Input() formCtrl: AbstractControl;
  @Output()
  onItemChanged: EventEmitter<IIzvodFajlIzvorDropdownDto> = new EventEmitter<IIzvodFajlIzvorDropdownDto>();

  constructor(private _client: IzvodiClient, private _fb: FormBuilder) {}

  ngOnInit(): void {
    this.formGroup = this._fb.group({
      isDefault: new FormControl(),
      nazivBanke: new FormControl(),
      banka: new FormControl(BankaIzvoda.ASSECO),
      fileType: new FormControl(FileType.XML),
    });

    this._load();
  }

  itemChanged(event: any) {
    this.formGroup.patchValue(
      this.izvodFajlIzvori.find((x) => x.banka === event.value)
    );

    if (this.onItemChanged) {
      this.onItemChanged.emit(
        this.izvodFajlIzvori.find((x) => x.banka === event.value)
      );
    }
  }

  private _load() {
    this._getSub = this._client
      .getIzvodFajlIzvorDropdown()
      .subscribe((result) => {
        this.izvodFajlIzvori = result.data.izvodFajlIzvorDropdownDtoList;
        this.ctrlOptions = result.data.izvodFajlIzvorDropdownDtoList
          .filter((x) => x.banka !== BankaIzvoda.EMPTY)
          .map((x) => ({
            value: x.banka,
            label: x.nazivBanke,
          }));
        const defaultOne = result.data.izvodFajlIzvorDropdownDtoList.find(
          (x) => x.isDefault
        );
        if (defaultOne) {
          this.itemChanged({ value: defaultOne.banka });
        }
      });
  }

  ngOnDestroy() {
    this._getSub.unsubscribe();
  }
}
