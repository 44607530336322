import {Component, OnDestroy, OnInit} from '@angular/core';
import {IKonfiguracijaMojeFirmeDto, MojaFirmaClient, UpdateKonfiguracijaMojeFirmeCommand,} from '@kodit/core/data-api';
import {KonfiguracijaMojeFirmeFormService} from '@kodit/core/form-definitions';
import {AlertService} from '@kodit/core/services';
import {StopaPdv} from '@kodit/core/shared';
import {DynamicDialogConfig, DynamicDialogRef, PdvStopaService,} from '@kodit/core/shared-ui';
import {FormGroupTypeSafe} from 'angular-typesafe-reactive-forms-helper';
import {Subscription} from 'rxjs';

@Component({
  selector: 'kodit-moja-firma-dodatne-informacije-form',
  templateUrl: './moja-firma-dodatne-informacije-form.component.html',
  styleUrls: ['./moja-firma-dodatne-informacije-form.component.scss'],
})
export class MojaFirmaDodatneInformacijeFormComponent
  implements OnInit, OnDestroy {

  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<IKonfiguracijaMojeFirmeDto>;
  dodatneInfoDto: IKonfiguracijaMojeFirmeDto;

  /** Conditional props */
  shouldShowRazlogPdvIzuzeca: boolean = false;
  shouldHideStopaPDVa: boolean;

  constructor(
    private _fs: KonfiguracijaMojeFirmeFormService,
    private _client: MojaFirmaClient,
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _pdvService: PdvStopaService
  ) {
  }

  ngOnInit(): void {
    this.form = this._fs.GetKonfiguracijaMojeFirmeFormGroup(
      this._dialogConfig.data.dodatneInfoDto
    );

    if (this.form.controls.uSistemuPDVa.value) {
      this.form.controls.stopaPDV.enable();
    } else {
      this.form.controls.stopaPDV.disable();
      this.form.controls.stopaPDV.patchValue(StopaPdv.BEZ_PDV);
    }

    // ukoliko je EDIT
    this._setShouldShowRazlogPdvIzuzeca(this.form.controls.stopaPDV.value);

    // subscribe on dialog save button
    this._subs.add(this._dialogRef.onSubmit.subscribe(() => {
      this.onSave();
    }));

    // handle u sistemu pdv & stopa pdv
    this.form.controls.uSistemuPDVa.valueChanges.subscribe((uPdv: boolean) => {
      this._pdvService.setJeKompanijaUPdv = uPdv;
      if (uPdv) {
        this.form.controls.stopaPDV.enable();
        this.form.controls.stopaPDV.patchValue(StopaPdv.OSNOVNA);
      } else {
        this.form.controls.stopaPDV.disable();
        this.form.controls.stopaPDV.patchValue(StopaPdv.BEZ_PDV);
      }
    });

    this._subs.add(
      this.form.controls.stopaPDV.valueChanges.subscribe((res) => {
        this._setShouldShowRazlogPdvIzuzeca(res);
      })
    )
  }

  onSave() {
    this._subs.add(this._client
      .updateKonfiguracija(
        this.form.value as UpdateKonfiguracijaMojeFirmeCommand
      )
      .subscribe((result) => {
        this.form.patchValue(result.data);
        this._alertService.addSuccessMsg(
          'Dodatne informacije je uspešno izmenjena.'
        );
        this._dialogRef.close(result);
      }));
  }

  private _setShouldShowRazlogPdvIzuzeca(stopa: StopaPdv) {
    if(stopa == StopaPdv.BEZ_PDV){
      this.shouldShowRazlogPdvIzuzeca = true;
    }
    else{
      this.shouldShowRazlogPdvIzuzeca = false;
      this.form.controls.oznakaPdvKategorije.patchValue(null);
      this.form.controls.razlogPdvIzuzecaId.patchValue(null);
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
