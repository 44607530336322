import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  GetPredmeteStavkiZaRacunQuery,
  IPredmetStavkeDto,
  IPredmetStavkeZaRacunDto,
  PackageType,
  PredmetStavkiClient,
  ResultOfRobaCommandResponse,
  ResultOfUslugaCommandResponse,
  TipPredmeta,
  TipRacuna,
  VrstaRacuna,
  VrstaRadnogNaloga,
} from '@kodit/core/data-api';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { RobaFormComponent } from 'libs/core/moduli/roba/src/lib/roba-form/roba-form.component';
import { UslugaFormComponent } from 'libs/core/moduli/usluga/src/lib/usluga-form/usluga-form.component';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { AuthService, RacunService } from '@kodit/core/services';
import { RadniNalogService } from '../../radni-nalog/radni-nalog.service';
import { ActivatedRoute } from '@angular/router';

export interface PredmetStavkeResult {
  predmetStavke: IPredmetStavkeDto;
  amount: number;
  // price: number;
}

@Component({
  selector: 'kodit-rezultat-pretrage-predmeta-stavke',
  templateUrl: './rezultat-pretrage-predmeta-stavke.component.html',
  styleUrls: ['./rezultat-pretrage-predmeta-stavke.component.scss'],
})
export class RezultatPretragePredmetaStavkeComponent
  implements OnInit, OnDestroy, OnChanges {
  /** Subs */
  private _subs: Subscription = new Subscription();
  private _searchPredmetStavkeSub: Subscription = new Subscription();
  private _dialogCloseSub: Subscription = new Subscription();

  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.MEDIUM
  );

  /** Props */
  predmetiStavkiZaRacuneResult: IPredmetStavkeZaRacunDto[] = [];
  predmetiStavkiResult: IPredmetStavkeDto[] = [];
  shouldDisplayLoader: boolean;
  tipoviPredmeta: TipPredmeta[] = [
    TipPredmeta.PREDMET_IZMENE_OSNOVICE,
    TipPredmeta.PREDMET_STAVKE,
    TipPredmeta.ROBA,
    TipPredmeta.USLUGA,
    TipPredmeta.VOZILO,
    TipPredmeta.DATI_AVANS,
    TipPredmeta.PRIMLJENI_AVANS,
  ];
  tipRoba: TipPredmeta = TipPredmeta.ROBA;
  tipUsluga: TipPredmeta = TipPredmeta.USLUGA;
  defaultKolicina: number = 1;
  cenovnikId: number;
  strankaId: number;
  existingStavkeRacuna: number[] = [];
  iznosPoKursu: { id: number; iznos: number }[] = [];
  jeOtpremnica: boolean;
  jeAvansniRacun: boolean;
  jeIzmenaOsnovice: boolean;
  jeUsluznaDelatnost: boolean;
  lastSearchValue: string;
  vrstaRadnogNaloga: VrstaRadnogNaloga = 0;
  hasRobno: boolean = false;

  /** Konvertovanje */
  //jeKonvertovanje: boolean;
  jeKonvertovanjeOtpremniceUFakturu: boolean;

  /** I/O */
  @Input() searchValue: string = '';
  @Input() iznosDefaultValue: number;
  @Input() jmDefaultValue: string;
  @Input() excludeTipovePredmeta: TipPredmeta[] = [];
  @Input() tipRacuna: TipRacuna;
  @Output() onPredmetStavkeAdd = new EventEmitter<PredmetStavkeResult>();

  constructor(
    private _client: PredmetStavkiClient,
    private _dialogService: DynamicDialogService,
    private _racunService: RacunService,
    private _radniNalogService: RadniNalogService,
    private _authService: AuthService,
    private _routing: ActivatedRoute
  ) {
    //this.jeKonvertovanje = this._routing.snapshot.data.jeKonvertovanje ?? false;
    this.jeKonvertovanjeOtpremniceUFakturu =
      this._routing.snapshot.data.jeKonvertovanjeOtpremniceUFakturu ?? false;
  }

  ngOnInit(): void {
    this._subs.add(
      this._racunService.getAddedPredmetiStavki.subscribe((res) => {
        if (res?.length > 0) {
          this.existingStavkeRacuna = [...res];
        } else {
          this.existingStavkeRacuna = [];
        }
      })
    );
    this.hasRobno = this._authService.hasPackage([
      PackageType[PackageType.VELEPRODAJA],
    ]);
    this.jeOtpremnica = this.tipRacuna === TipRacuna.OTPREMNICA;
    this.jeAvansniRacun = this.tipRacuna === TipRacuna.AVANSNI_RACUN;
    this.jeIzmenaOsnovice =
      this.tipRacuna === TipRacuna.DOKUMENT_O_SMANJENJU ||
      this.tipRacuna === TipRacuna.DOKUMENT_O_POVECANJU;
    this._getStrankaIdForRacun();
    this._subs.add(
      this._radniNalogService.getVrstaRadnogNaloga.subscribe((res) => {
        this.vrstaRadnogNaloga = res;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.searchValue?.currentValue != changes.searchValue?.previousValue
    ) {
      this.searchPredmetStavke(this.searchValue);
    }
  }

  handleIznosPoKursuChange(id: number, iznos: number) {
    const idx = this.iznosPoKursu.findIndex((x) => x.id === id);
    if (idx > -1) {
      this.iznosPoKursu[idx].iznos = iznos;
    } else {
      this.iznosPoKursu.push({
        id: id,
        iznos: iznos,
      });
    }
  }

  /**
   * Na svaki unos u autocomplete, pretrazuje predmete stavki iz baze
   * @param event event poslat iz autocomplete
   */
  searchPredmetStavke(searchValue: string) {
    if (this.tipRacuna === TipRacuna.RADNI_NALOG) {
      this.jeUsluznaDelatnost = !!this._racunService.getCustomObjectValue()
        ? (this._racunService.getCustomObjectValue() as boolean)
        : false;
    }

    this.lastSearchValue = searchValue;
    if (!searchValue || searchValue.length == 0) {
      return;
    }
    this.shouldDisplayLoader = true;
    this._searchPredmetStavkeSub = this._client
      .getPredmeteStavkeZaRacune(
        new GetPredmeteStavkiZaRacunQuery({
          sifraIliNaziv: searchValue,
          cenovnikId: this.cenovnikId,
          tipRacuna: this.tipRacuna,
          vrstaRadnogNaloga: this.vrstaRadnogNaloga,
          excludeTipovePredmeta: this.intersect(
            this.tipoviPredmeta,
            this._getExcludeTipovePredmeta()
          ),
          includeMaterijal:
            this.tipRacuna === TipRacuna.RADNI_NALOG &&
            (this.vrstaRadnogNaloga ==
              VrstaRadnogNaloga.PROIZVODNJA_PO_NARUDZBINI ||
              this.vrstaRadnogNaloga == VrstaRadnogNaloga.PROIZVODNJA_ZA_LAGER),
        })
      )
      .subscribe(
        (result) => {
          this.predmetiStavkiZaRacuneResult = result.predmetiStavkiZaRacune;
          this.predmetiStavkiResult = result.predmetiStavki;
        },
        (error) => {},
        () => {
          this.shouldDisplayLoader = false;
        }
      );
  }

  dodajPredmetStavke(id: number) {
    if (id === -1) {
      this.onPredmetStavkeAdd.emit(null);
    } else {
      const ps = this.predmetiStavkiResult.find((x) => x.id === id);
      if (this.iznosPoKursu.some((x) => x.id === id)) {
        ps.prodajnaCenaBezPdv = this.iznosPoKursu.find(
          (x) => x.id === id
        ).iznos;
        if (this._racunService.getVrstaRacuna === VrstaRacuna.ULAZNI) {
          ps.nabavnaCena = this.iznosPoKursu.find((x) => x.id === id).iznos;
        }
      }
      // ps.cena = convertCurrencyStringToDecimal(
      //   this.predmetiStavkiZaRacuneResult.find((x) => x.id === id).osnovnaCena
      // );
      this.onPredmetStavkeAdd.emit({
        predmetStavke: ps,
        amount: this.defaultKolicina,
        //   price: this.predmetiStavkiZaRacuneResult.find((x) => x.id === id)
        //     .osnovnaCena,
      });
      this._racunService.setAddedPredmetiStavki = [
        ...this.existingStavkeRacuna,
        id,
      ];
      this._racunService.updateUkupnaNetoTezinaForStavke();
    }
  }

  openRobaDialog(config: DynamicDialogConfig) {
    config.data = {
      naziv: this.searchValue,
      iznos: this.iznosDefaultValue,
      jm: this.jmDefaultValue,
    };
    config.setDialogSize = DialogSize.MEDIUM;
    config.header = 'Unos podataka robe';
    const ref = this._dialogService.open(RobaFormComponent, config);
    this._dialogCloseSub = ref.onClose.subscribe(
      (result: ResultOfRobaCommandResponse) => {
        this.onPredmetStavkeAdd.emit({
          predmetStavke: result?.data?.robaDto?.predmetStavkeDto,
          amount: this.defaultKolicina,
        });
      }
    );
  }

  openUslugaDialog(config: DynamicDialogConfig) {
    config.data = {
      naziv: this.searchValue,
      iznos: this.iznosDefaultValue,
      jm: this.jmDefaultValue,
    };
    config.setDialogSize = DialogSize.MEDIUM_SMALL;
    config.header = 'Unos podataka usluge';
    const ref = this._dialogService.open(UslugaFormComponent, config);
    this._dialogCloseSub = ref.onClose.subscribe(
      (result: ResultOfUslugaCommandResponse) => {
        this.onPredmetStavkeAdd.emit({
          predmetStavke: result?.data?.uslugaDto?.predmetStavkeDto,
          amount: this.defaultKolicina,
        });
      }
    );
  }

  handlePredmetIzmenaOsnoviceCreate(config: DynamicDialogConfig) {
    alert('ToDo...');
  }

  onCenovnikChange(cenovnikId: number) {
    this.cenovnikId = cenovnikId;
    this.searchPredmetStavke(this.lastSearchValue);
  }

  private _getStrankaIdForRacun() {
    this.strankaId = this._racunService.getStrankaRacunaId;
  }

  private _getExcludeTipovePredmeta(): TipPredmeta[] {
    return this.jeKonvertovanjeOtpremniceUFakturu
      ? this.tipoviPredmeta.filter((x) => x !== TipPredmeta.USLUGA)
      : this.excludeTipovePredmeta;
  }

  private intersect(a: TipPredmeta[], b: TipPredmeta[]) {
    let temp: TipPredmeta[];
    if (b.length > a.length) (temp = b), (b = a), (a = temp); // indexOf to loop over shorter
    return a.filter(function (e) {
      return b.indexOf(e) > -1;
    });
  }

  inputFocused(event: any) {
    event.target.select();
  }

  ngOnDestroy() {
    this._searchPredmetStavkeSub.unsubscribe();
    this._dialogCloseSub.unsubscribe();
    this._subs.unsubscribe();
  }
}
