import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,} from '@angular/router';
import {LoginType, RefreshTokenRequest, TokensClient} from '@kodit/core/data-api';
import {stringIsNullOrEmpty} from '@kodit/core/shared';
import {DynamicDialogConfig, DynamicDialogService,} from '@kodit/core/shared-ui';
import {
  IzlogujUpozorenjeDialogComponent
} from 'libs/core/core-ui/src/lib/ui/izloguj-upozorenje-dialog/izloguj-upozorenje-dialog.component';
import {DialogSize} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  /** Props */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);
   REFRESH_TOKEN_REMAINING_TIME_STRING =
  'REFRESH_TOKEN_REMAINING_TIME';
   REFRESH_TOKEN_REMAINING_TIME_IN_SECONDS = 1800;

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _client: TokensClient,
    private _dialogService: DynamicDialogService,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Get property name on security object to check
    let claimName: string = next.data['claimName'];
    let packageName: string = next.data['packageName'];

    if (
      this._authService.isUserAuthenticated() &&
      (!claimName || this._authService.hasClaim(claimName)) &&
      (!packageName || this._authService.hasPackage(packageName))
    ) {
      return true;
    }

    const isRefreshSuccess = await this.tryRefreshingTokens();

    if (!isRefreshSuccess) {
      this._router
        .navigate(['/autentikacija/prijava'], {
          queryParams: { returnUrl: state.url },
        })
        .then();
    }

    return isRefreshSuccess;
  }

  public async tryRefreshingTokens(): Promise<boolean> {
    // Try refreshing tokens using refresh token
    const refreshToken: string = this._authService.getRefreshToken;
    const token: string = this._authService.getToken;
    const sessionId: string = this._authService.getSessionId;

    if (!token || !refreshToken) {
      return false;
    }

    let isRefreshSuccess: boolean;
    const currentTenant = this._authService.getCurrentTenant;
    //this._authService.removeTokens();

    try {
      const response = this._client
        .refresh(
          new RefreshTokenRequest({
            refreshToken: refreshToken,
            token: token,
            sessionId: sessionId,
            loginType : LoginType.WEB
          }),
          // 'root'
          currentTenant
        )
        .toPromise();

      if (stringIsNullOrEmpty((await response).data.sessionId)) {
        this._openIzlogujUpozorenjeDialog();
        return;
      }

      // If token refresh is successful, set new tokens in local storage.
      const newToken = (await response).data.token;
      const newRefreshToken = (await response).data.refreshToken;
      const newSessionId = (await response).data.sessionId;
      this._authService.setTokens(newToken, newRefreshToken, newSessionId);
      isRefreshSuccess = true;
    } catch (ex) {
      isRefreshSuccess = false;
      this._authService.logout();
    }

    return isRefreshSuccess;
  }

  private _openIzlogujUpozorenjeDialog() {
     if (
      this._getRemainingTimeFromLocalStorage() <=
      this.REFRESH_TOKEN_REMAINING_TIME_IN_SECONDS
     )
     {
        this.dialogConfig.header =
          'Vaš korisnički nalog je ulogovan na drugom računaru ili pretraživaču!';
        this.dialogConfig.closable = false;
        this.dialogConfig.closeOnEscape = false;
        this.dialogConfig.hideCancel = true;
        this.dialogConfig.customSubmitButton = { label: 'Odjavi se' } as any;
        this._dialogService.open(
          IzlogujUpozorenjeDialogComponent,
          this.dialogConfig
        );
     }
  }

  private _getRemainingTimeFromLocalStorage(): number{
    return Number.parseInt(localStorage.getItem(this.REFRESH_TOKEN_REMAINING_TIME_STRING));
  }

}
