<p-dropdown
  appendTo="body"
  [options]="ctrlOptions!"
  [(ngModel)]="izabraniItem"
  (onChange)="itemChanged($event)"
  [style]="{ width: '300px' }"
>
  <ng-template let-grupa pTemplate="item">
    <div>
      {{ grupa.label }}
      <i
        *ngIf="grupa.imported"
        class="fa-duotone fa-badge-check"
        style="color: green"
      ></i>
    </div>
  </ng-template>
</p-dropdown>
