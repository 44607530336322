<div class="p-d-flex p-justify-end p-mb-4">
  <div style="width: 20%">
    <kodit-dropdown
      formCtrlId="vrsta-zbirne-pdv-evidencije-dropdown"
      [options]="tableOptions"
      (optionChanged)="optionChanged($event)"
    ></kodit-dropdown>
  </div>
</div>
<kodit-page>
  <kodit-pdv-evidencija-zbirna-table
    *ngIf="!novaTabelaSelected"
  ></kodit-pdv-evidencija-zbirna-table>
  <kodit-pdv-evidencija-zbirna-table39
    *ngIf="novaTabelaSelected"
  ></kodit-pdv-evidencija-zbirna-table39>
</kodit-page>
