<form [formGroup]="formGroup">
  <p-autoComplete
    #autocompleteRef
    [suggestions]="pravnaLicaSuggestions"
    formControlName="searchValue"
    field="naziv"
    (completeMethod)="searchPravnoLice($event)"
    (onSelect)="selectionChanged($event)"
    [placeholder]="ctrlPlaceholder"
    [appendTo]="parentCtrl"
    [forceSelection]="mustSelect"
    (onKeyUp)="nazivChanged($event)"
    (onClear)="onClear()"
    emptyMessage="Stranka nije pronađena"
    dropdownMode="current"
    [disabled]="ctrlDisabled"
  >
    <ng-template let-stranka pTemplate="item">
      <div class="stranka-item">
        <div>{{ stranka.naziv }}</div>
      </div>
    </ng-template>
  </p-autoComplete>
  <kodit-error-info-required
    [formCtrl]="formGroup.controls.searchValue"
  ></kodit-error-info-required>
</form>
