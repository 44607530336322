import { RouterModule, Routes } from '@angular/router';
import { ArhivskaKnjigaMenuPageComponent } from './arhivska-knjiga-menu-page/arhivska-knjiga-menu-page.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ArhivskaKnjigaTableComponent } from './arhivska-knjiga-table/arhivska-knjiga-table.component';
import { compact } from 'lodash';
import { ArhivskaDoesNotExistsPageComponent } from './arhivska-knjiga-menu-page/arhivska-does-not-exists-page/arhivska-does-not-exists-page.component';

const routes: Routes = [
  {
    path: '',
    component: ArhivskaKnjigaMenuPageComponent,
    children: [
      {
        path: 'arhivska-table',
        data: {
          breadcrumb: 'Tabela',
        },
        component: ArhivskaDoesNotExistsPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ArhivskaKnjigaRoutingModule {}
