import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IVezaniRacunDto, TipRacuna, VrstaRacuna } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class VezaniRacunFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  /**
   * Konvertuje model vezanih racuna u form array
   *
   * Ukoliko se posalje prazan niz, kreira se prazan niz
   * @param vezaniRacuni Vezani racuni za convert (model -> formArray)
   * @returns Reaktivan form array od vezanih racuna
   */
  getFormArray(vezaniRacuni?: IVezaniRacunDto[]): FormArray {
    if (!vezaniRacuni || vezaniRacuni.length === 0) {
      //return this._fb.array([this.getFormGroup()]);
      return this._fb.array([]);
    }

    let result: FormArray = this._fb.array([]);
    vezaniRacuni.forEach((s) => {
      result.push(this.getFormGroup(s));
    });
    return result;
  }

  /**
   * Konvertuje model vezanog racuna u form grupu
   *
   * Ukoliko se izostavi parametar vezani racun,
   * kreira se grupu sa default vrednostima
   *
   * @param vezaniRacun Model vezanog racuna za convert u form grupu
   * @returns Reaktivna form grupa od prosledjenog vezanog racuna
   */
  public getFormGroup(
    vezaniRacun?: IVezaniRacunDto
  ): FormGroupTypeSafe<IVezaniRacunDto> {
    return this._fb.group<IVezaniRacunDto>({
      tip: new FormControl(vezaniRacun?.tip ?? TipRacuna.EMPTY),
      label: new FormControl(vezaniRacun?.label),
      emptyText: new FormControl(vezaniRacun?.emptyText),
      racunId: new FormControl(vezaniRacun?.racunId),
      vrsta: new FormControl(vezaniRacun?.vrsta ?? VrstaRacuna.EMPTY),
      tipStr: new FormControl(vezaniRacun?.tipStr),
    });
  }
}
