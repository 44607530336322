import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IPodaciUplateDto } from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';

@Component({
  selector: 'kodit-podaci-uplate-form',
  templateUrl: './podaci-uplate-form.component.html',
  styleUrls: ['./podaci-uplate-form.component.scss'],
})
export class PodaciUplateFormComponent implements OnInit {
  @Input() podaciUplateFormGroup: FormGroupTypeSafe<IPodaciUplateDto>;
  @Input() tekuciRacunCtrl: AbstractControl;

  constructor(private _route: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.jeUnos) {
      this._setInstrukcijaPlacanja();
      this._setSifraPlacanja();
      this._setModelPlacanja();
    }
  }

  get jeUnos(): boolean {
    return !this._route.snapshot.data.racun?.racunDto?.id;
  }

  private _setInstrukcijaPlacanja() {
    if (this.podaciUplateFormGroup.controls.instrukcijaPlacanja.value) {
      return;
    }
    this.podaciUplateFormGroup.controls.instrukcijaPlacanja.patchValue(
      this._route.snapshot.data.formConfiguration.instrukcijaPlacanja
    );
    this.podaciUplateFormGroup.controls.instrukcijaPlacanja.updateValueAndValidity();
  }

  private _setSifraPlacanja() {
    if (this.podaciUplateFormGroup.controls.sifraPlacanja.value) {
      return;
    }
    this.podaciUplateFormGroup.controls.sifraPlacanja.patchValue(
      this._route.snapshot.data.formConfiguration.sifraPlacanja
    );
    this.podaciUplateFormGroup.controls.sifraPlacanja.updateValueAndValidity();
  }

  private _setModelPlacanja() {
    if (this.podaciUplateFormGroup.controls.model.value) {
      return;
    }
    this.podaciUplateFormGroup.controls.model.patchValue(
      this._route.snapshot.data.formConfiguration.modelPlacanja
    );
    this.podaciUplateFormGroup.controls.model.updateValueAndValidity();
  }
}
