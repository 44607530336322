<form [formGroup]="form" (keydown)="keyDownFunction($event)">
  <div class="p-fluid">
    <div class="p-field">
      <label kodit-form-label
        >Predefinisani datum prometa na računima:</label
      >
      <kodit-dropdown
        ctrlPlaceholder="Izaberite datum prometa"
        [formCtrl]="form.controls.defaultDatumPrometa"
        [options]="datumPrometaOptions"
      ></kodit-dropdown>
    </div>
    
    <div class="p-field">
      <label kodit-form-label
        >Predefinisani vremenski period za prikaz računa:</label
      >
      <kodit-period-za-prikaz-racuna-dropdown
        [periodCtrl]="form.controls.periodZaPrikazRacuna"
      ></kodit-period-za-prikaz-racuna-dropdown>
    </div>

    <kodit-field fieldLabel="Prikaži rabat kao" fieldLabelId="prikaz-rabata">
      <kodit-prikaz-rabata-dropdown
        [formCtrl]="form.controls.prikazRabataDto.get('prikazRabata')"
        formCtrlId="prikaz-rabata"
      ></kodit-prikaz-rabata-dropdown>
    </kodit-field>

    <kodit-field
      fieldLabel="Prikaži otpremnicu"
      fieldLabelId="prikaz-otpremnice"
    >
      <kodit-prikaz-otpremnice-dropdown
        [formCtrl]="form.controls.prikazOtpremniceDto.get('prikazOtpremnice')"
        formCtrlId="prikaz-otpremnice"
      ></kodit-prikaz-otpremnice-dropdown>
    </kodit-field>

    <kodit-field
      fieldLabel="Prikaži radni nalog"
      fieldLabelId="prikaz-radnog-naloga"
    >
      <kodit-prikaz-radnog-naloga-dropdown
        [formCtrl]="
          form.controls.prikazRadnogNalogaDto.get('prikazRadnogNaloga')
        "
        formCtrlId="prikaz-radnog-naloga"
      ></kodit-prikaz-radnog-naloga-dropdown>
    </kodit-field>

    <!-- Automatsko kreiranje otpremnice uz izlaznu fakturu -->
    <div class="p-field">
      <kodit-checkbox
        [formCtrl]="form.controls.kreirajOtpremnicu"
        labelText="Kreiraj otpremnicu uz izlaznu fakturu"
      ></kodit-checkbox>
    </div>

    <!-- Prikaz sekcije za računanje težine -->
    <div class="p-field">
      <kodit-checkbox
        [formCtrl]="form.controls.prikaziSekcijuZaRacunanjeTezine"
        labelText="Prikaži sekciju za računanje težine"
      ></kodit-checkbox>
    </div>

    <!-- Prikaz sekcije za informaciju o isporuci -->
    <div class="p-field">
      <kodit-checkbox
        [formCtrl]="form.controls.prikaziSekcijuZaInformacijeOIsporuci"
        labelText="Prikaži sekciju za informacije o isporuci"
      ></kodit-checkbox>
    </div>

    <!-- Opis -->
    <kodit-text-input-field
      [formCtrl]="form.controls.mestoIzdavanjaPrometa"
      formCtrlId="mestoIzdavanjaPrometa"
      formCtrlLabel="Mesto izdavanja i prometa"
    ></kodit-text-input-field>

    <!-- Šifra plaćanja -->
    <kodit-field fieldLabel="Šifra plaćanja">
      <kodit-sifra-placanja-dropdown
        [sifrePlacanjaCtrl]="form.controls.sifraPlacanja"
      ></kodit-sifra-placanja-dropdown>
    </kodit-field>

    <!-- Model -->
    <kodit-text-input-field
      formCtrlId="model"
      formCtrlLabel="Model"
      [formCtrl]="form.controls.modelPlacanja"
      ctrlPlaceholder="Model plaćanja"
    ></kodit-text-input-field>

    <!-- Instrukcija placanja -->
    <kodit-field fieldLabel="Instrukcija plaćanja">
      <kodit-instrukcija-placanja-dropdown
        [instrukcijePlacanjaCtrl]="form.controls.instrukcijaPlacanja"
      >
      </kodit-instrukcija-placanja-dropdown>
    </kodit-field>

    <!-- Sud za slucaj spora -->
    <div class="p-field">
      <kodit-checkbox
        [formCtrl]="form.controls.prikaziNadlezniSudZaSpor"
        labelText="Prikaži nadležan sud za spor"
      ></kodit-checkbox>
    </div>
    <kodit-text-input-field
      [formCtrl]="form.controls.nadlezanSudZaSpor"
      formCtrlId="nadlezanSudZaSpor"
      formCtrlLabel="Napomena u slučaju spora"
    ></kodit-text-input-field>

    <!-- Oznake -->
    <div class="p-field">
      <kodit-checkbox
        [formCtrl]="form.controls.prikaziOznake"
        labelText="Prikaži oznake na računu"
      ></kodit-checkbox>
    </div>

    <!-- Ponude -->
    <div class="p-field">
      <kodit-checkbox
        [formCtrl]="form.controls.prikaziPonudu"
        labelText="Prikaži ponude"
      ></kodit-checkbox>
    </div>
  </div>
</form>
