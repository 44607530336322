<!-- <input
  *ngIf="!isDisabled"
  #textInput
  [id]="formCtrlId"
  kodit-select-all-on-focus
  pInputText
  [formControl]="formCtrl"
  [type]="formCtrlType"
  [placeholder]="ctrlPlaceholder"
  autocomplete="off"
  (keyup)="onKeyUp($event)"
  (onblur)="inputLeft($event)"
/>
<input
  *ngIf="isDisabled"
  #textInput
  [id]="formCtrlId"
  kodit-select-all-on-focus
  pInputText
  [formControl]="formCtrl"
  [type]="formCtrlType"
  [placeholder]="ctrlPlaceholder"
  autocomplete="off"
  (keyup)="onKeyUp($event)"
  (onblur)="inputLeft($event)"
  disabled
/> -->

<textarea
  *ngIf="!isDisabled"
  #textInput
  [id]="formCtrlId"
  kodit-select-all-on-focus
  rows="5"
  pInputText
  [formControl]="formCtrl"
  autoResize="autoResize"
  [placeholder]="ctrlPlaceholder"
  autocomplete="off"
  (keyup)="onKeyUp($event)"
  (onblur)="inputLeft($event)"
></textarea>
<textarea
  *ngIf="isDisabled"
  #textInput
  [id]="formCtrlId"
  [formControl]="formCtrl"
  [placeholder]="ctrlPlaceholder"
  kodit-select-all-on-focus
  rows="5"
  pInputText
  autoResize="autoResize"
  autocomplete="off"
  (keyup)="onKeyUp($event)"
  (onblur)="inputLeft($event)"
  disabled
></textarea>

<kodit-error-info-max-length
  [formCtrl]="formCtrl"
></kodit-error-info-max-length>
<kodit-error-info-min-length
  [formCtrl]="formCtrl"
></kodit-error-info-min-length>
<kodit-error-info-required [formCtrl]="formCtrl"></kodit-error-info-required>
<kodit-error-info-email [formCtrl]="formCtrl"></kodit-error-info-email>
