import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { PdvEvidencijaTipDokumenta } from '@kodit/core/data-api';
import { FormBuilderTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { SelectItem } from 'primeng/api';

const tipDokumentaMap: Map<PdvEvidencijaTipDokumenta, string> = new Map<
  PdvEvidencijaTipDokumenta,
  string
>([
  [PdvEvidencijaTipDokumenta.FAKTURA, 'Faktura'],
  [PdvEvidencijaTipDokumenta.DOKUMENT_O_SMANJENJU, 'Dokument o smanjenju'],
  [PdvEvidencijaTipDokumenta.DOKUMENT_O_POVECANJU, 'Dokument o povećanju'],
  [PdvEvidencijaTipDokumenta.AVANSNA_FAKTURA, 'Avansna faktura'],
  [
    PdvEvidencijaTipDokumenta.INTERNI_RACUN_ZA_PROMET_STRANOG_LICA,
    'Interni račun za promet stranog lica',
  ],
  [PdvEvidencijaTipDokumenta.DRUGI_INTERNI_RACUN, 'Drugi interni račun'],
]);

@Component({
  selector: 'kodit-tip-dokumenta-evidencije-multiselect',
  templateUrl: './tip-dokumenta-evidencije-multiselect.component.html',
  styleUrls: ['./tip-dokumenta-evidencije-multiselect.component.scss'],
})
export class TipDokumentaEvidencijeMultiselectComponent implements OnInit {
  //* Props */
  items: SelectItem[] | undefined;
  selectedItems: PdvEvidencijaTipDokumenta[] = [];

  /** I/O */
  @Input() tipFormArray: AbstractControl | undefined;
  @Input() formCtrlId: string | undefined;
  @Input() parentCtrl: any = 'body';
  constructor() {}

  ngOnInit(): void {
    if (this.tipFormArray?.value) {
      this.selectedItems = [...this.tipFormArray?.value];
    }
    this._load();
  }

  private _load() {
    this.items = [];
    for (let tipEntry of tipDokumentaMap.entries()) {
      this.items?.push({ value: tipEntry[0], label: tipEntry[1] });
    }
  }

  selectedItemsOnChange() {
    this.tipFormArray?.setValue(this.selectedItems);
  }
}
