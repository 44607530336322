<kodit-card-container>
  <div card-title>Opšta pravila</div>

  <div card-right-side>
    <button
      icon="far fa-edit"
      pButton
      type="button"
      label="Izmeni"
      class="p-button p-button-outlined"
      (click)="onEdit()"
    ></button>
  </div>

  <div class="p-d-flex p-jc-between" card-body *ngIf="opstaPravilaDto">
    <div class="p-d-flex p-flex-column gap-6">
      <kodit-card-body-text
        label="Predefinisan datum prometa na računima"
        [text]="opstaPravilaDto.defaultDatumPrometaStr"
      ></kodit-card-body-text>
     
      <kodit-card-body-text
        label="Predefinisani vremenski period za prikazivanje računa"
        [text]="opstaPravilaDto.periodZaPrikazRacunaStr"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Prikaži rabat kao"
        [text]="opstaPravilaDto.prikazRabataDto.prikazRabataStr"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Prikaži otpremnicu"
        [text]="opstaPravilaDto.prikazOtpremniceDto.prikazOtpremniceStr"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Prikaži radni nalog"
        [text]="opstaPravilaDto.prikazRadnogNalogaDto.prikazRadnogNalogaStr"
      ></kodit-card-body-text>

      <kodit-card-body-icon
        [isOk]="opstaPravilaDto.kreirajOtpremnicu"
        label="Kreiraj otpremnicu uz izlaznu fakturu"
        okIconClass="kodit-success fas fa-check"
        notOkIconClass="kodit-error fas fa-minus"
      ></kodit-card-body-icon>

      <kodit-card-body-text
        label="Mesto izdavanja i promena"
        [text]="opstaPravilaDto.mestoIzdavanjaPrometa"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Model uplate"
        [text]="opstaPravilaDto.modelPlacanja"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Šifra plaćanja"
        [text]="opstaPravilaDto.sifraPlacanja"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Instrukcija plaćanja"
        [text]="opstaPravilaDto.instrukcijaPlacanja"
      ></kodit-card-body-text>

      <kodit-card-body-icon
        [isOk]="opstaPravilaDto.prikaziNadlezniSudZaSpor"
        label="Prikaži nadležan sud u slučaju sporova"
        okIconClass="kodit-success fas fa-check"
        notOkIconClass="kodit-error fas fa-minus"
      ></kodit-card-body-icon>

      <kodit-card-body-icon
        [isOk]="opstaPravilaDto.prikaziSekcijuZaRacunanjeTezine"
        label="Prikaži sekciju za računanje težine"
        okIconClass="kodit-success fas fa-check"
        notOkIconClass="kodit-error fas fa-minus"
      ></kodit-card-body-icon>

      <kodit-card-body-icon
        [isOk]="opstaPravilaDto.prikaziSekcijuZaInformacijeOIsporuci"
        label="Prikaži sekciju za informacije o  isporuci"
        okIconClass="kodit-success fas fa-check"
        notOkIconClass="kodit-error fas fa-minus"
      ></kodit-card-body-icon>

      <kodit-card-body-text
        label="Napomena u slučaju spora"
        [text]="opstaPravilaDto.nadlezanSudZaSpor"
      ></kodit-card-body-text>

      <kodit-card-body-icon
        [isOk]="opstaPravilaDto.prikaziOznake"
        label="Prikaži oznake na računu"
        okIconClass="kodit-success fas fa-check"
        notOkIconClass="kodit-error fas fa-minus"
      ></kodit-card-body-icon>
      <kodit-card-body-icon
        [isOk]="opstaPravilaDto.prikaziPonudu"
        label="Prikaži ponude"
        okIconClass="kodit-success fas fa-check"
        notOkIconClass="kodit-error fas fa-minus"
      ></kodit-card-body-icon>
    </div>
  </div>
</kodit-card-container>
