<kodit-card-container>
  <ng-container card-title> Top {{ N }} {{ nazivCard }} </ng-container>
  <ng-container
    card-subtitle
    *ngIf="
      topNStrankiDonutResponseList.length > 0 &&
      topNStrankiDonutResponseList.length < N
    "
    >Napomena: trenutno nema {{ N }} {{ nazivCard.toLowerCase() }}</ng-container
  >
  <div
    class="p-d-flex p-flex-column gap-25"
    card-body
    *ngIf="topNStrankiDonutResponseList.length > 0"
  >
    <div>
      <p-chart
        type="doughnut"
        [data]="chartData"
        [options]="chartOptions"
      ></p-chart>
    </div>
    <div>
      <kodit-top-n-stranki-table
        [data]="topNStrankiDonutResponseList"
        [naziv]="tabelaNaziv"
      ></kodit-top-n-stranki-table>
    </div>
  </div>

  <div
    class="p-d-flex"
    card-body
    *ngIf="topNStrankiDonutResponseList.length === 0"
  >
    <div class="p-text-left">Trenutno nema podataka...</div>
  </div>
</kodit-card-container>
