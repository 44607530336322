<div *ngIf="logoImage" class="moja-firma-image-wrapper">
  <p-image
    [src]="logoImage"
    alt="logo-image"
    width="200"
  >
  </p-image>
</div>

<kodit-file-upload
  (onUpload)="onUpload($event)"
  [uploadCompleted]="uploadCompleted"
  descriptionText="Izaberite logo klikom na plus ili ga prevucite ovde"
  fileTypes=".jpg,.png,.jpeg"
>
</kodit-file-upload>
