import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AvansniRacuniClient,
  IRacunDto,
  IStrankaRacunaDto,
  ResultOfFizickoLiceVm,
  TipRacuna,
  TipStranke,
} from '@kodit/core/data-api';
import { FizickoLiceFormComponent } from '@kodit/core/moduli/stranka';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import {
  DialogSize,
  OnDynamicDialogInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { RacunService } from '../../../../../../services/src/lib/racun.service';
import { StrankaRacunaForm } from '../../../../../../services/src/lib/racun.service';
import { RadniNalogService } from '../../radni-nalog/radni-nalog.service';
import { StrankaRacunaFormService } from 'libs/core/form-definitions/src/lib/racun/stranka-racuna-form.service';

@Component({
  selector: 'kodit-stranka-racuna',
  templateUrl: './stranka-racuna.component.html',
  styleUrls: ['./stranka-racuna.component.scss'],
})
export class StrankaRacunaComponent
  implements OnInit, OnDestroy, OnDynamicDialogInit {
  /** Configs */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.LARGE);

  /** Props */
  private _subs: Subscription = new Subscription();
  nazivNepronadjeneStranke: string;
  fizickoLiceTip = TipStranke.FIZIKO_LICE;
  fizickoLiceNaziv: string;
  isFirstLoad: boolean = true;

  jeInostranoPravnoLice: boolean;
  form: StrankaRacunaForm;

  /** I/O */
  @Input() racunFormGroup: FormGroupTypeSafe<IRacunDto>;

  constructor(
    private _racunService: RacunService,
    private _route: ActivatedRoute,
    private _dialogService: DynamicDialogService,
    private _avansniRacuniClient: AvansniRacuniClient,
    private _radniNalogService: RadniNalogService,
    private _strankaRacunaFormService: StrankaRacunaFormService
  ) {}

  ngOnInit(): void {
    // racunDto?.strankaRacunaDto?.naziv, koristimo .naziv jer sa back-a nekad vratimo prazan StrankaRacunaDto koji nije null ali sadrzi skoro sve null vrednosti
    if (!!this._route.snapshot.data.racun?.racunDto?.strankaRacunaDto?.naziv) {
      this._getStrankaHasAvansneRacune(
        this._route.snapshot.data.racun?.racunDto?.strankaRacunaDto?.id
      );
    }
    this.setStranka();

    this._subs.add(
      this._racunService.getShowFizickoLiceCreate.subscribe((res) => {
        this.nazivNepronadjeneStranke = res;
      })
    );
    if (
      this._route.snapshot.data.racun?.racunDto?.strankaRacunaDto
        ?.tipStranke === TipStranke.FIZIKO_LICE &&
      !this._route.snapshot.data.racun?.racunDto?.strankaRacunaDto?.id
    ) {
      this._racunService.setPerformSearchFizickogLica = this.racunFormGroup.value.strankaRacunaDto?.naziv;
    }

    this._radniNalogService.getVrstaRadnogNaloga.subscribe((res) => {
      if (!this.isFirstLoad) {
        this.form = this._strankaRacunaFormService.GetStrankaRacunaFormGroup();
        this.racunFormGroup.controls.strankaRacunaDto = this._strankaRacunaFormService.GetStrankaRacunaFormGroup() as any;
      } else {
        this.isFirstLoad = false;
      }
    });
  }

  onSelectionChange(stranka: IStrankaRacunaDto) {
    this._racunService.setStrankaracuna = stranka;

    if (stranka.id) {
      this._getStrankaHasAvansneRacune(stranka.id);
      this.jeInostranoPravnoLice =
        stranka.registracioniBroj != undefined &&
        stranka.registracioniBroj != null;

      if (this.racunFormGroup.value.tip == TipRacuna.IZLAZNA_FAKTURA) {
        this.racunFormGroup.controls.rokPlacanja.patchValue(
          stranka.rokPlacanjaZaIzlazne
        );
      } else if (this.racunFormGroup.value.tip == TipRacuna.ULAZNA_FAKTURA) {
        this.racunFormGroup.controls.rokPlacanja.patchValue(
          stranka.rokPlacanjaZaUlazne
        );
      }
    }
  }
  setStranka() {
    this.form = this._racunService.getStrankaRacunaFormGroup;
    this.jeInostranoPravnoLice =
      this.form.controls.registracioniBroj.value != undefined &&
      this.form.controls.registracioniBroj.value != null;
  }

  onSelectionClear() {
    this.racunFormGroup.controls.strankaRacunaDto.reset();
  }

  handleCreateFizickoLice() {
    this.dialogConfig.header = 'Unos fizičkog lica';

    const nazivFizickoLica = this.racunFormGroup.value.strankaRacunaDto.naziv
      ? this.racunFormGroup.value.strankaRacunaDto.naziv.split(' ')
      : this.fizickoLiceNaziv?.split(' ') ?? [''];
    this.dialogConfig.data = {
      ime: nazivFizickoLica[0],
      prezime: nazivFizickoLica
        .filter((x) => x != nazivFizickoLica[0])
        .join(' '),
      grad: this.racunFormGroup.value.strankaRacunaDto?.adresa,
    };

    this.openDialog(this.dialogConfig);
    this._racunService.setShowFizickoLiceCreate = null;
  }

  handleNazivChange(naziv: string) {
    this.fizickoLiceNaziv = naziv;
  }

  openDialog(config: DynamicDialogConfig) {
    const ref = this._dialogService.open(FizickoLiceFormComponent, config);

    this._subs.add(
      ref.onClose.subscribe((result: ResultOfFizickoLiceVm) => {
        if (result) {
          this.racunFormGroup.controls.strankaRacunaDto.patchValue({
            id: result.data.fizickoLiceDto.id,
            naziv: result.data.fizickoLiceDto.naziv,
            adresa: result.data.fizickoLiceDto.adresa.punaAdresa,
            mb: result.data.fizickoLiceDto.jmbg,
            tipStranke: TipStranke.FIZIKO_LICE,
            rokPlacanjaZaIzlazne:
              result.data.fizickoLiceDto.rokPlacanjaZaIzlazne,
            rokPlacanjaZaUlazne: result.data.fizickoLiceDto.rokPlacanjaZaUlazne,
          });
        }
      })
    );
  }

  private _getStrankaHasAvansneRacune(strankaId: number) {
    this._subs.add(
      this._avansniRacuniClient
        .getStrankaHasAvansniRacun(strankaId, this.racunFormGroup.value.vrsta)
        .subscribe((res) => {
          this._racunService.setStrankaHasAvansneRacune = res.data;
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
