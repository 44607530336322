import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ChangeActivityCommand,
  KorisniciClient,
  KorisnikDto,
  ResultOfKorisnikTableDto,
  SendSmsConfirmationCodeKorisnikCommand,
  SendVerificationEmailCommand,
} from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ChangePasswordFormComponent } from '../change-password-form/change-password-form.component';
import { ConfirmMobilniTelefonFormComponent } from '../confirm-mobilni-telefon-form/confirm-mobilni-telefon-form.component';
import { KorisnikFormComponent } from '../korisnik-form/korisnik-form.component';

@Component({
  selector: 'kodit-korisnik-info',
  templateUrl: './korisnik-info.component.html',
  styleUrls: ['./korisnik-info.component.scss'],
})
export class KorisnikInfoComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  korisnikDto: KorisnikDto;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  constructor(
    private _route: ActivatedRoute,
    private _dialogService: DynamicDialogService,
    private _client: KorisniciClient,
    private _alertService: AlertService,
    private _breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.korisnikDto = this._route.snapshot.data.korisnik;
    this.setBr();
  }

  openPasswordChangeDialog() {
    this.dialogConfig.setDialogSize = DialogSize.SMALL;
    this.dialogConfig.data = {
      korisnikEmail: this.korisnikDto.email,
    };
    this.dialogConfig.header = 'Promena šifre korisničkog naloga';
    const ref = this._dialogService.open(
      ChangePasswordFormComponent,
      this.dialogConfig
    );
  }

  sendVerificationEmail() {
    this._subs.add(
      this._client
        .sendVerificationEmail(
          new SendVerificationEmailCommand({
            korisnikEmail: this.korisnikDto.email,
            origin: null,
          })
        )
        .subscribe(
          (result) => {
            this._alertService.addSuccessMsg(`Verifikacioni mail je poslat...`);
          },
          (error) => {
            this._subs.add(
              error.subscribe((e: string) => {
                this._alertService.addFailedMsg(e);
              })
            );
          }
        )
    );
  }

  openConfirmPhoneDialog() {
    this.dialogConfig.setDialogSize = DialogSize.SMALL;
    this.dialogConfig.data = {
      korisnikEmail: this.korisnikDto.email,
      mobilniTelefon: this.korisnikDto.mobilniDto.getMobilniTelefon,
    };
    this.dialogConfig.header = 'Potvrđivanje mobilnog telefona';
    const ref = this._dialogService.open(
      ConfirmMobilniTelefonFormComponent,
      this.dialogConfig
    );
  }

  confirmMobile() {
    this._subs.add(
      this._client
        .sendConfirmationCode(
          new SendSmsConfirmationCodeKorisnikCommand({
            brojTelefona: this.korisnikDto.mobilniDto.brojTelefona,
            korisnikEmail: this.korisnikDto.email,
            pozivniBroj: this.korisnikDto.mobilniDto.pozivniBroj,
          })
        )
        .subscribe(
          (result) => {
            this.openConfirmPhoneDialog();
          },
          (error) => {
            this._subs.add(
              error.subscribe((e: string) => {
                this._alertService.addFailedMsg(e);
              })
            );
          }
        )
    );
  }

  changeActivity() {
    this._subs.add(
      this._client
        .changeActivity(
          new ChangeActivityCommand({
            id: this.korisnikDto.id,
            isActivated: !this.korisnikDto.isActive,
          })
        )
        .subscribe((result) => {
          if (result.succeeded) {
            this._alertService.addSuccessMsg(result.data);
            window.location.reload();
          } else {
            this._alertService.addFailedMsg(result.messages[0]);
          }
        })
    );
  }

  onEdit() {
    this.dialogConfig.header = 'Izmena korisničkog naloga';
    this.dialogConfig.data = {
      id : this.korisnikDto.id,
      email: this.korisnikDto.email,
    };
    this.openDialog(KorisnikFormComponent);
  }

  openDialog(formType: Type<KorisnikFormComponent>): void {
    const ref = this._dialogService.open(formType, this.dialogConfig);
    this._subs.add(
      ref.onClose.subscribe((dto: ResultOfKorisnikTableDto) => {
        if (dto) {
          //   const idx = this.korisniciTable.findIndex(
          //     (x) => x.id === dto.data.id
          //   );
          //   if (idx > -1) {
          //     // update
          //     this.korisniciTable[idx] = dto.data;
          //   } else {
          //     // create
          //     this.korisniciTable.push(dto.data);
          //   }
          // }
          location.reload();
        }
      })
    );
  }

  private setBr() {
    this._breadcrumbService.set('@KarticaEmail', this.korisnikDto.email);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
