<div *ngIf="vezaniRacuni && vezaniRacuni.length > 0">
  <div kodit-form-label class="p-text-center">Povezani račun(i)</div>
  <div class="p-d-flex p-flex-column">
    <div
      *ngFor="let x of vezaniRacuni; index as i"
      class="p-d-flex p-ai-baseline"
    >
      <button
        pButton
        type="button"
        [label]="x.label"
        class="p-button-link"
        pTooltip="Prikaži račun"
        tooltipPosition="bottom"
        (click)="onClick(x)"
      ></button>
      <button
      *ngIf="!jeKonvertovanje"
        pButton
        type="button"
        icon="fa-solid fa-xmark"
        class="p-button-rounded p-button-danger p-button-text"
        pTooltip="Uklonite vezani račun"
        tooltipPosition="bottom"
        (click)="onRemove(i)"
      ></button>
    </div>
  </div>
</div>
