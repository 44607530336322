import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { IStavkaRacunaDto, SharedClient } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { RacunService } from '../../..';

@Component({
  selector: 'kodit-napomene-racuna',
  templateUrl: './napomene-racuna.component.html',
  styleUrls: ['./napomene-racuna.component.scss'],
})
export class NapomeneRacunaComponent implements OnInit, OnDestroy {
  /** Subs */
  private readonly _subs: Subscription = new Subscription();
  private usedRazlogIzuzecaIdList: number[];

  /** I/O */
  @Input()
  napOPoreskomOslobCtrl: FormControl | AbstractControl;

  @Input()
  napomenaCtrl: FormControl | AbstractControl;

  @Input()
  shouldDisplayNapomenaOPoreskomOlobadjanju: boolean = true;

  constructor(private _service: RacunService, private _client: SharedClient) {
    this.usedRazlogIzuzecaIdList = [];
  }

  ngOnInit(): void {
    this._subs.add(
      this._service.getRazlogIzuzecaChanged.subscribe(() => {
        this._setNapomena();
      })
    );
  }

  private _getRazlogIzuzeca(razlogId: number) {
    this._subs.add(
      this._client
        .getRazlogPdvIzuzecaNapomenaById(razlogId)
        .subscribe((res) => {
          if (this.napOPoreskomOslobCtrl.value !== 'Nema') {
            this.napOPoreskomOslobCtrl.patchValue(
              this.napOPoreskomOslobCtrl.value.concat(', ', res.data)
            );
          } else {
            this.napOPoreskomOslobCtrl.patchValue(res.data);
          }
        })
    );
  }

  private _setNapomena() {
    this._resetNapomenaPdv();
    this.usedRazlogIzuzecaIdList = [];

    this._service.getStavkeArray.value
      .filter((s: IStavkaRacunaDto) => !!s.razlogPdvIzuzecaId)
      .forEach((stavka: IStavkaRacunaDto) => {
        if (this.usedRazlogIzuzecaIdList.includes(stavka.razlogPdvIzuzecaId)) {
          return;
        }

        this._getRazlogIzuzeca(stavka.razlogPdvIzuzecaId);
        this.usedRazlogIzuzecaIdList.push(stavka.razlogPdvIzuzecaId);
      });
  }

  private _resetNapomenaPdv() {
    this.napOPoreskomOslobCtrl.patchValue('Nema');
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
