import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { StatusUplateRacuna } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-status-uplate-racuna-multiselect',
  templateUrl: './status-uplate-racuna-multiselect.component.html',
  styleUrls: ['./status-uplate-racuna-multiselect.component.scss'],
})
export class StatusUplateRacunaMultiselectComponent implements OnInit {
  //* Props */
  items: SelectItem[];
  selectedItems: StatusUplateRacuna[] = [];
  @Input() statusFormArray: FormArray | AbstractControl;

  @Output() selectedStatusiUplateRacuna = new EventEmitter<StatusUplateRacuna[]>();

  constructor() {}

  ngOnInit(): void {
    this.selectedItems = [...this.statusFormArray.value.map((x) => x.status)];

    this.items = [
      { label: 'Izaberite status uplate računa', value: StatusUplateRacuna.EMPTY },
      { label: 'Neplaćen', value: StatusUplateRacuna.NEPLACEN },
      { label: 'Nevalidan', value: StatusUplateRacuna.NEVALIDAN },
      { label: 'Delimično plaćen', value: StatusUplateRacuna.DELIMICNO_PLACEN },
      { label: 'Plaćen', value: StatusUplateRacuna.PLACEN },
      { label: 'Otkazan', value: StatusUplateRacuna.OTKAZAN },
      { label: 'Asigniran', value: StatusUplateRacuna.ASIGNIRAN },
      { label: 'Delimično iskorišćen', value: StatusUplateRacuna.DELIMICNO_ISKORISCEN },
      { label: 'Iskorišćen', value: StatusUplateRacuna.ISKORISCEN },
      { label: 'Nov', value: StatusUplateRacuna.NOV },
    ];
  }

  selectedItemsOnChange() {
    this.selectedStatusiUplateRacuna.next(this.selectedItems);
  }
}
