<div class="p-fluid">
  <div class="p-field">
    <label kodit-form-label
      >Postojeće informacije isporuke</label
    >
    <p-dropdown
      [options]="items"
      [(ngModel)]="selectedItem"
      optionLabel="imeLokacijeMestaIsporuke"
      placeholder="Izaberite informaciju isporuke"
      (onChange)="newItemSelected(selectedItem)"
      [disabled]="shouldDisable"
      [filter]="true"
    ></p-dropdown>
  </div>
</div>
