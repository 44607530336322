import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AdvancedFilter } from '@kodit/core/shared-ui';
import { IGetPPPDVTableQuery } from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { FormHelper } from '@kodit/core/form-definitions';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';
import {
  clearFilter,
  setFilter
} from '../../../../../../../shared-subforms/src/lib/racun/state/filters/filter.actions';

@Component({
  selector: 'kodit-pppdv-table-filter',
  templateUrl: './pppdv-table-filter.component.html',
  styleUrls: ['./pppdv-table-filter.component.scss'],
})
export class PppdvTableFilterComponent
  implements OnInit, AdvancedFilter<IGetPPPDVTableQuery> {
  /**Props */
  filterData!: IGetPPPDVTableQuery;
  form!: FormGroupTypeSafe<IGetPPPDVTableQuery>;

  @Output() onSubmitForm = new EventEmitter<IGetPPPDVTableQuery>();

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _formHelper: FormHelper,
    private _storage: Store
  ) {}

  ngOnInit(): void {
    this.form = this.getForm(this.filterData);
  }

  onFilterSubmit() {
    this._storage.dispatch(
      setFilter({
        key: 'PDV|PP',
        filter: JSON.stringify(this.form.value),
      })
    );
  }

  onFilterHide(shouldReset: boolean) {
    if (shouldReset) {
      this._storage.dispatch(
        clearFilter({
          key: 'PDV|PP',
        })
      );
    }
  }

  private getForm(
    model: IGetPPPDVTableQuery
  ): FormGroupTypeSafe<IGetPPPDVTableQuery> {
    return this._fb.group<IGetPPPDVTableQuery>({
      broj: new FormControl(model.broj),
      statusi: new FormControl(model.statusi),
      datumOd: this._formHelper.initializeDateFormControl(model.datumOd!),
      datumDo: this._formHelper.initializeDateFormControl(model.datumDo!),
    });
  }
}
