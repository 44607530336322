<div class="p-d-flex p-flex-column p-flex-md-row gap-25">
  <div class="left-box gap-25">
    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-between">
      <div>
        <div class="p-field-radiobutton">
          <p-radioButton
            name="prikazZa"
            value="selektovana"
            [(ngModel)]="prikaziFakturaZa"
            inputId="selektovana-stranka"
            [disabled]="jeFizickoLice && !strankaId"
            [pTooltip]="
              jeFizickoLice && !strankaId
                ? 'Nije dostupna opcija jer stranka nije pronađena'
                : undefined
            "
            (onClick)="onRadioButtonChange()"
          ></p-radioButton>
          <label for="selektovana-stranka">Računi selektovane stranke</label>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            name="prikazZa"
            value="pocetnoStanje"
            [(ngModel)]="prikaziFakturaZa"
            inputId="pocetna-stanja"
            (onClick)="onRadioButtonChange()"
          ></p-radioButton>
          <label for="pocetna-stanja">Početna stanja</label>
        </div>
        <div class="p-field-radiobutton">
          <p-radioButton
            name="prikazZa"
            value="sve"
            [(ngModel)]="prikaziFakturaZa"
            inputId="sve-stranke"
            (onClick)="onRadioButtonChange()"
          ></p-radioButton>
          <label for="sve-stranke">Svi računi</label>
        </div>
      </div>
      <div>
        <p-menu
          #menu
          [popup]="true"
          [model]="createAvansniRacunItems"
          appendTo="body"
          classList="a"
        ></p-menu>
        <button
          type="button"
          pButton
          pRipple
          icon="fas fa-plus"
          ngClass="p-mr-3 p-button p-button-outlined"
          (click)="menu.toggle($event)"
          label="Unesi avansni račun"
        ></button>

        <button
          icon="fas fa-plus"
          pButton
          type="button"
          label="Unesi fakturu"
          class="p-button p-button-outlined"
          (click)="createFaktura()"
        ></button>
      </div>
    </div>

    <kodit-obrada-pocetnog-stanja
      *ngIf="prikaziFakturaZa === 'selektovana'"
      [uplataId]="transakcijaIzvodaId"
      [ukupanIznosUplate]="raspolozivo"
      [vrstaRacuna]="vrstaPocetnogStanjaRacuna"
      [strankaId]="strankaId"
      (onEvidentirajUplatu)="addToRequestList($event)"
      (onPonistiEvidencijuUplate)="removeFromRequestList($event)"
    ></kodit-obrada-pocetnog-stanja>
    <kodit-pocetno-stanje-racun-table
      *ngIf="prikaziFakturaZa === 'pocetnoStanje'"
      [vrstaRacuna]="vrstaPocetnogStanjaRacuna"
      [uplataId]="transakcijaIzvodaId"
      [ukupanIznosUplate]="raspolozivo"
      (onEvidentirajUplatu)="addToRequestList($event)"
      (onPonistiEvidencijuUplate)="removeFromRequestList($event)"
  >
    </kodit-pocetno-stanje-racun-table>
    <kodit-neplaceni-racun-table
      *ngIf="prikaziFakturaZa !== 'pocetnoStanje'"
      [dialogRef]="_dialogRef"
      [uplataId]="transakcijaIzvodaId"
      [ukupanIznosUplate]="raspolozivo"
      [vrstaRacuna]="vrstaNeplacenihRacuna"
      [strankaId]="prikaziFakturaZa === 'selektovana'? strankaId : null"
      [pozivNaBrojDrugeStrane]="pozivNaBrojDrugeStrane"
      (onEvidentirajUplatu)="addToRequestList($event)"
      (onPonistiEvidencijuUplate)="removeFromRequestList($event)"
    ></kodit-neplaceni-racun-table>

    <p-message
      class="tttest"
      severity="info"
      text="Vezivanjem uplate za predračun, sistem automatski kreira izlazni račun u statusu 'plaćen'."
      styleClass="p-mr-2"
    ></p-message>
  </div>
  <div class="right-box">
    <div class="ukupno-ostalo">
      <div class="iznos-uplate">
        {{ ukupanIznos | currencyNumber: valutaText }}
      </div>
      <div class="evidentirano">
        <span>-</span>{{ evidentirano | currencyNumber: valutaText }}
      </div>
      <hr />
      <div class="ostalo">{{ raspolozivo | currencyNumber: valutaText }}</div>
    </div>
  </div>
</div>
