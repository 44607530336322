import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { BlockableUI } from 'primeng/api';

@Component({
  selector: 'kodit-blockable-div',
  templateUrl: './blockable-div.component.html',
  styleUrls: ['./blockable-div.component.scss'],
})
export class BlockableDivComponent implements BlockableUI {
  @Input() divStyle: any;
  @Input() divClass: any;

  constructor(private el: ElementRef) {}

  getBlockableElement(): HTMLElement {
    return this.el.nativeElement.children[0];
  }
}
