import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  CreateKompenzacijuCommand,
  IKompenzacijaDto,
  KompenzacijeClient,
  UpdateKompenzacijuCommand,
  VrstaRacuna,
} from '@kodit/core/data-api';
import {
  FormHelper,
  KompenzacijaFormService,
} from '@kodit/core/form-definitions';
import {
  AlertService,
  LocationService,
  SharedService,
} from '@kodit/core/services';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';
import { KompenzacijaService } from '../kompezacija.service';

@Component({
  selector: 'kodit-kompenzacija-form',
  templateUrl: './kompenzacija-form.component.html',
  styleUrls: ['./kompenzacija-form.component.scss'],
})
export class KompenzacijaFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription;

  /** Props */
  kompenzacijaForm: FormGroupTypeSafe<IKompenzacijaDto>;
  vrstaRacuna: VrstaRacuna;
  vrstaRacunaIzlazni = VrstaRacuna.IZLAZNI;

  jeMojaFirmaPoverilac: boolean;

  kompenzacijaId: number = 0;
  jeEditMode: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _kompenzacijaFormService: KompenzacijaFormService,
    private _service: KompenzacijaService,
    private _formHelper: FormHelper,
    private _kompenzacijeClient: KompenzacijeClient,
    private _alertService: AlertService,
    private _locationService: LocationService,
    private _breadcrumbService: BreadcrumbService,
    private _sharedService: SharedService
  ) {
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this._route.data.subscribe((data) => {
      if (data['vrstaRacuna']) {
        this.vrstaRacuna = data['vrstaRacuna'] as VrstaRacuna;
      }
      if (data.racun && data.racun['kompenzacijaId']) {
        this.kompenzacijaId = data.racun['kompenzacijaId'] as number;
        this.jeEditMode = true;
      }
    });
    // ToDo: add model za edit...
    this.kompenzacijaForm = this._kompenzacijaFormService.GetKompenzacijaFormGroup(
      this.vrstaRacuna
    );
    this._service.setForm = this.kompenzacijaForm;

    if (this.jeEditMode) {
      this._subs.add(
        this._kompenzacijeClient
          .getKompenzacijaById(this.kompenzacijaId)
          .subscribe((res) => {
            this.patchFormByModel(res.data);
            this._setBreadcrumb();
          })
      );
    }
    this.jeMojaFirmaPoverilac = this.vrstaRacuna == VrstaRacuna.IZLAZNI;

    // pri menjanju stranke brisemo odabrane postojece kompenzacione obaveze
    this.kompenzacijaForm.controls.stranka.valueChanges.subscribe(() => {
      this._resetKompenzacioneObaveze();
    });
  }

  onSave() {
    if (!this.kompenzacijaForm.valid) {
      this._formHelper.invalidateForm(this.kompenzacijaForm);
      return;
    }

    // everything is ok, let's save it
    if (this.jeEditMode) {
      this._sharedService.displayLoadingScreen(
        true,
        'Ažuriranje kompenzacije...'
      );
      this._subs.add(
        this._kompenzacijeClient
          .updateKompenzaciju(
            this.kompenzacijaForm.value as UpdateKompenzacijuCommand
          )
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.data);
            this._locationService.routeBack();
          })
      );
    } else {
      this._sharedService.displayLoadingScreen(
        true,
        'Kreiranje kompenzacije...'
      );
      this._subs.add(
        this._kompenzacijeClient
          .create(this.kompenzacijaForm.value as CreateKompenzacijuCommand)
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.messages[0]);
            this._locationService.routeBack();
          })
      );
    }
  }

  patchFormByModel(model: IKompenzacijaDto) {
    this.kompenzacijaForm.patchValue(model);
    (this.kompenzacijaForm?.controls.obavezePremaDuzniku as FormArray).clear();
    (this.kompenzacijaForm?.controls
      .obavezePremaPoveriocu as FormArray).clear();

    model.obavezePremaPoveriocu.forEach((x) => {
      (this.kompenzacijaForm?.controls.obavezePremaPoveriocu as FormArray).push(
        this._kompenzacijaFormService.GetObavezeFormGroup(x)
      );
    });

    model.obavezePremaDuzniku.forEach((x) => {
      (this.kompenzacijaForm?.controls.obavezePremaDuzniku as FormArray).push(
        this._kompenzacijaFormService.GetObavezeFormGroup(x)
      );
    });
  }

  private _setBreadcrumb() {
    this._breadcrumbService.set(
      '@izmenaKompenzacije',
      `Izmena kompenzacije ${this.kompenzacijaForm.value.broj}`
    );
  }

  private _resetKompenzacioneObaveze() {
    (this.kompenzacijaForm.controls.obavezePremaPoveriocu as FormArray).clear();
    (this.kompenzacijaForm.controls.obavezePremaDuzniku as FormArray).clear();
    (this.kompenzacijaForm.controls.obavezePremaDuzniku as FormArray).push(
      this._kompenzacijaFormService.GetObavezeFormGroup()
    );
    (this.kompenzacijaForm.controls.obavezePremaPoveriocu as FormArray).push(
      this._kompenzacijaFormService.GetObavezeFormGroup()
    );
  }

  onCancel() {
    this._locationService.routeBack();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
