<form autocomplete="off" [formGroup]="form">
  <div class="p-fluid">
    <div class="p-d-flex p-flex-column">
      <div class="p-field">
        <label for="naziv" kodit-form-label>Tačna cena</label>
        <kodit-number-input
          [formCtrl]="form.controls.novaCena"
        ></kodit-number-input>
      </div>
      <div class="p-field">
        <label kodit-form-label for="povecanjeZaProcenat"
          >Povećanje za % na osnovnu cenu</label
        >
        <kodit-number-input
          [formCtrl]="form.controls.povecanjeZaProcenat"
          [ctrlMaxLength]="100"
          [ctrlMinValue]="0"
          [ctrlOnlyIntegers]="true"
          [ctrlUseGrouping]="false"
          prefix="%"
        ></kodit-number-input>
      </div>

      <div class="p-field">
        <label kodit-form-label for="umanjenjeZaProcenat"
          >Umanjenje za % na osnovnu cenu</label
        >
        <kodit-number-input
          [formCtrl]="form.controls.umanjenjeZaProcenat"
          [ctrlMaxLength]="100"
          [ctrlMinValue]="0"
          [ctrlOnlyIntegers]="true"
          [ctrlUseGrouping]="false"
          prefix="-%"
        ></kodit-number-input>
      </div>
    </div>
  </div>
</form>
