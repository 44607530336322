<div class="p-d-flex p-justify-end p-mb-4">
  <div style="width: 20%">
    <kodit-dropdown
      formCtrlId="vrsta-pojedinacne-pdv-evidencije-dropdown"
      [options]="tableOptions"
      (optionChanged)="optionChanged($event)"
    ></kodit-dropdown>
  </div>
</div>
<kodit-page>
 <kodit-pdv-evidencija-pojedinacna-table *ngIf="!novaTabelaSelected"></kodit-pdv-evidencija-pojedinacna-table>
    <kodit-pdv-evidencija-pojedinacna-table39 *ngIf="novaTabelaSelected"></kodit-pdv-evidencija-pojedinacna-table39>
</kodit-page>

