import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-card-body-icon',
  templateUrl: './card-body-icon.component.html',
  styleUrls: ['./card-body-icon.component.scss'],
})
export class CardBodyIconComponent implements OnInit {
  @Input() label: string;
  @Input() okIconClass: string;
  @Input() notOkIconClass: string;
  @Input() isOk: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
