import { Injectable } from '@angular/core';
import { mixpanelToken } from 'apps/core/src/environments/environment';
import * as mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  /** Props */
  baseRequest: MixpanelRequest = {} as MixpanelRequest;

  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(): void {
    mixpanel.init(mixpanelToken, { testMode: true, debug: true });
    mixpanel.identify(mixpanelToken);
  }

  /** Initialize base request */
  set setBaseRequest(value: MixpanelRequest) {
    this.baseRequest = value;
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }

  //#region Registracija i Login

  registrationFormEntered(data?: any, responseCode?: string) {
    this.track(REGISTRATION_FORM_ENTERED, {});
  }

  registrationAdminStep(data?: any, responseCode?: string) {
    this.baseRequest.data = data;
    this.track(REGISTRATION_ADMIN, this.baseRequest);
  }

  registrationConfirmEmailStep(data?: any, responseCode?: string) {
    this.baseRequest.data = data;
    this.track(REGISTRATION_CONFIRM_EMAIL, this.baseRequest);
  }

  registrationSuccessful(data?: any, responseCode?: string) {
    this.baseRequest.data = data;
    this.track(REGISTRATION_SUCCESSFUL, this.baseRequest);
  }

  loginUserSuccessful(data?: any, responseCode?: string) {
    this.baseRequest = data;
    this.track(LOGIN_USER, this.baseRequest);
  }

  //#endregion Registracija i Login

  //#region PrviLogin i TenantChange

  useAndTermsSubmitted(data?: any, responseCode?: string) {
    this.track(USE_AND_TERMS_SUBMITTED, this.baseRequest);
  }

  tenantChange(data?: any, responseCode?: string) {
    this.track(TENANT_CHANGE, this.baseRequest);
  }

  newTrialAccountLoggedIn(data? : any, responseCode? : string){
    this.baseRequest = data;
    this.track(NEW_TRIAL_ACCOUNT_LOGGED,this.baseRequest);
  }

  //#endregion PrviLogin i TenantChange

  //#region IzlazneFakture

  izlaznaFakturaSubmitted(data?: any, responseCode?: string) {
    this.baseRequest.data = data;
    this.track(IF_SUBMITTED, this.baseRequest);
  }

  izlaznaFakturaSucceeded(data?: any, responseCode?: string) {
    this.track(IF_SUCCEEDED, this.baseRequest);
  }

  izlaznaFakturaSentToSefSubmitted(data?: any, responseCode?: string) {
    this.track(IF_SENT_TO_SEF_SUBMITTED, this.baseRequest);
  }

  izlaznaFakturaSentToSefSucceeded(data?: any, responseCode?: string) {
    this.track(IF_SENT_TO_SEF_SUCCEEDED, this.baseRequest);
  }

  izlaznaFakturaSentToEmailSubmitted(data?: any, responseCode?: string) {
    this.track(IF_SENT_TO_EMAIL_SUBMITTED, this.baseRequest);
  }

  izlaznaFakturaSentToEmailSucceeded(data?: any, responseCode?: string) {
    this.track(IF_SENT_TO_EMAIL_SUCCEEDED, this.baseRequest);
  }

  izlaznaFakturaSentMultipleToSefSubmitted(data?: any, responseCode?: string) {
    this.track(IF_SENT_MULTIPLE_TO_SEF_SUBMITTED, this.baseRequest);
  }

  izlaznaFakturaSentMultipleToSefSucceeded(data?: any, responseCode?: string) {
    this.track(IF_SENT_MULTIPLE_TO_SEF_SUCCEEDED, this.baseRequest);
  }

  //#endregion IzlazneFakture

  //#region UlazneFakture

  ulaznaFakturaApproval(data?: any, responseCode?: string) {
    this.track(UF_APPROVAL, this.baseRequest);
  }

  ulaznaFakturaRejection(data?: any, responseCode?: string) {
    this.track(UF_REJECTION, this.baseRequest);
  }

  //#endregion UlazneFakture

  //#region ErrorHandling

  handleErrorInterceptor(data?: any, responseCode?: string) {
    this.baseRequest.data = data;
    this.baseRequest.responseCode = responseCode;
    this.track(HANDLE_ERROR_INTERCEPTOR, this.baseRequest);
  }

  //#endregion ErrorHandling
}

export interface MixpanelRequest {
  email?: string | undefined;
  tenantKey?: string | undefined;
  responseCode?: string | undefined;
  data?: any | undefined;
}

/** Mix panel tracking name */

// IZLAZNE FAKTURE
export const IF_SUBMITTED = 'if-submitted';
export const IF_CANCELLED = 'if-cancelled';
export const IF_SUCCEEDED = 'if-succeeded';
export const IF_FAILED = 'if-failed';
export const IF_SENT_TO_SEF_SUBMITTED = 'if-sent-to-sef-submitted';
export const IF_SENT_TO_SEF_SUCCEEDED = 'if-sent-to-sef-succeeded';
export const IF_SENT_TO_EMAIL_SUBMITTED = 'if-sent-to-email-submitted';
export const IF_SENT_TO_EMAIL_SUCCEEDED = 'if-sent-to-email-succeded';
export const IF_SENT_MULTIPLE_TO_SEF_SUBMITTED =
  'if-sent-multiple-to-sef-submitted';
export const IF_SENT_MULTIPLE_TO_SEF_SUCCEEDED =
  'if-sent-multiple-to-sef-succeeded';

// ULAZNE FAKTURE
export const UF_APPROVAL = 'uf-approval';
export const UF_REJECTION = 'uf-rejection';

// REGISTRACIJA i LOGIN
export const REGISTRATION_FORM_ENTERED = 'registration-form-entered';
export const REGISTRATION_ADMIN = 'registration-admin';
export const REGISTRATION_CONFIRM_EMAIL = 'registration-confirm-email';
export const REGISTRATION_SUCCESSFUL = 'registration-successful';
export const LOGIN_USER = 'login-successful';
export const NEW_TRIAL_ACCOUNT_LOGGED = 'new-trial-account-logged'

// PRVI LOGIN i TENANT
export const USE_AND_TERMS_SUBMITTED = 'use-and-terms-submitted';
export const TENANT_CHANGE = 'tenant-change';

// ERROR HANDLING
export const HANDLE_ERROR_INTERCEPTOR = 'handle-error';
