import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FilterChipDto,
  GetKompenzacijaTableQuery,
  IGetKompenzacijaTableQuery,
  IPaginatedResultOfKompenzacijaTableDto,
  KompenzacijaTableDto,
  KompenzacijeClient,
  PaginatedResultOfKompenzacijaTableDto,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { ConfigService, LocationService } from '@kodit/core/services';
import { ActionType, TableConfig } from '@kodit/core/shared-ui';
import { LazyLoadEvent } from 'primeng/api';
import * as printJS from 'print-js';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-kompenzacija-table',
  templateUrl: './kompenzacija-table.component.html',
  styleUrls: ['./kompenzacija-table.component.scss'],
})
export class KompenzacijaTableComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    private _configService: ConfigService,
    private _client: KompenzacijeClient,
    private _locationService: LocationService  ) {}

  paginatedData: IPaginatedResultOfKompenzacijaTableDto;
  tableConfig: TableConfig;
  chipItems: FilterChipDto[] = [];
  skipFirst: number = 0;
  numberOfRowsDisplayed: number = 0;
  _subs = new Subscription();

  vrstaRacuna: VrstaRacuna;
  kompenzacijeTable: KompenzacijaTableDto[] = [];

  ngOnInit(): void {
    this._route.data.subscribe((data) => {
      if (data['vrstaRacuna']) {
        this.vrstaRacuna = data['vrstaRacuna'] as VrstaRacuna;
      }
    });

    this.setTableConfig().then();
  }

  private _load(request?: IGetKompenzacijaTableQuery) {
    if (!request) {
      request = {
        vrsta: this.vrstaRacuna,
        pageNumber: 0,
        pageSize: 10,
      };
    }

    this._subs.add(
      this._client.getForTable(request as GetKompenzacijaTableQuery).subscribe(
        (res: PaginatedResultOfKompenzacijaTableDto) => {
          this.kompenzacijeTable = res.data;
          this.paginatedData = res;
          this.chipItems = res.activeFilters;
          this._configService.setIsBusy = false;
        },
        () => {
          this._configService.setIsBusy = false;
        }
      )
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event: LazyLoadEvent) => {
        this.skipFirst = event.first;
        this.numberOfRowsDisplayed = event.rows;

        this._load({
          vrsta: this.vrstaRacuna,
          pageNumber: this.skipFirst,
          pageSize: this.numberOfRowsDisplayed,
        } as GetKompenzacijaTableQuery);
      },
      //selectMode: SelectMode.MULTI,
      tableHeader: 'Kompenzacije',
      // multiDeleteMessage:
      //   'Da li ste sigurni da želite da obrišete odabrane kompenzacije?',
      // deleteMessage: 'Da li ste sigurni da želite da obrišete kompenzaciju {param}?',
      // deleteMessageParams: 'broj',
      // isSelectableRowDisabled: (rowData: IRacunTableDto) => {
      //   return rowData?.vezaniRacuniDto?.length > 0;
      // },
      columns: [
        {
          field: 'broj',
          header: 'Broj',
          type: 'text',
        },
        { field: 'datumKreiranja', header: 'Datum izdavanja', type: 'text' },
        {
          field: 'stranka',
          subField: 'naziv',
          header: 'Stranka',
          type: 'link',
          linkCallbackFunction: (itemIndex: number) =>
            this._goToStranka(itemIndex),
        },
        {
          field: 'razlika',
          header: 'Razlika',
          type: 'currency',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          hasAccessTooltip: 'Unesite kompenzaciju',
          noAccessTooltip: 'Nemate ovlašćenja za unos kompenzacije',
          icon: 'fas fa-plus',
          callback: () => {
            this._locationService.routeToKompenzacijaCreate(this.vrstaRacuna);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          hasAccessTooltip: 'Izmenite kompenzaciju',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu kompenzacije',
          icon: 'fas fa-edit',
          callback: (index: number) => {
            this._goToEdit(index);
          },
        },
        {
          type: ActionType.CUSTOM,
          hasAccessTooltip: 'Odštampaj PDF računa',
          icon: 'fas fa-print',
          callback: (index: number) => {
            this._subs.add(
              this._client
                .getKompenzacijaPDF(
                  this.kompenzacijeTable[index - this.skipFirst].id
                )
                .subscribe((result) => {
                  let fileURL = URL.createObjectURL(result.data);
                  printJS(fileURL);
                })
            );
          },
        },
      ],
    });
  }

  private _goToStranka(itemIndex: number) {
    const kompenzacija = this.kompenzacijeTable[itemIndex - this.skipFirst];
    this._locationService.routeToStrankaKartica(
      kompenzacija.stranka.strankaId,
      kompenzacija.stranka.tipStranke
    );
  }

  private _goToEdit(rowIndex: number) {
    const kompenzacija = this.kompenzacijeTable[rowIndex - this.skipFirst];
    this._locationService.routeToKompenzacijaEdit(
      kompenzacija.id,
      this.vrstaRacuna
    );
  }

}
