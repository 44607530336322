<kodit-card-container>
  <div card-title>
    <div>eKompanija API menadžment</div>
  </div>

  <div card-right-side>
    API status:
    <div class="api-status" [ngClass]="apiStatus ? 'aktivan' : 'neaktivan'">
      {{ apiStatus ? 'Aktivan' : 'Neaktivan' }}
    </div>
  </div>

  <div card-body>
    <div class="p-d-flex p-flex-column">
      <div class="p-d-flex p-flex-column p-mt-4">
        <div class="subtitle">Linkovi za API endpoint-e</div>
        <div class="p-d-flex p-flex-row p-mt-3">
          <a
            target="_blank"
            href="{{ environmentUrl + links[0] }}"
            class="p-mr-4"
          >
            eKompanija API
          </a>
          <a
            target="_blank"
            href="{{ environmentUrl + links[1] }}"
            class="p-mr-4"
          >
            Fiskalna kasa API
          </a>
          <a
            target="_blank"
            href="{{ environmentUrl + links[2] }}"
            class="p-mr-4"
          >
            Mobile API
          </a>
        </div>
      </div>
      <div class="p-d-flex p-flex-column p-mt-4">
        <div class="subtitle">Ključ za autentifikaciju</div>
        <div class="p-d-flex p-flex-row p-ai-center p-mt-3">
          <input
            pInputText
            class="api-key"
            [disabled]="true"
            [placeholder]="apiKey ?? ''"
          />

          <img
            (click)="copyApiKey()"
            src="assets/icons/copy.svg"
            width="24"
            height="24"
            alt="user-image"
            class="p-ml-3 copy-icon"
          />
          <kodit-button
            class="p-ml-3"
            btnLabel="{{ apiKey === null ? 'Generiši' : 'Obnovi' }}"
            (click)="generisiApiKey()"
          ></kodit-button>
        </div>
      </div>
    </div>
  </div>
</kodit-card-container>

<div class="p-mt-5">
  <kodit-moja-firma-api-card></kodit-moja-firma-api-card>
</div>
