import { Injectable } from '@angular/core';
import {HttpRequest} from "@angular/common/http";

const SERTIFIKAT_FISKALNE_KASE: string = 'JID';

@Injectable({
  providedIn: 'root'
})
export class SertifikatFiskalnogRacunaDropdownService {
  sertifikatJid  = '';

  applyHeaderFilter(request: HttpRequest<any>): HttpRequest<any> {
    if (request.headers.has(SERTIFIKAT_FISKALNE_KASE)) {
      return request;
    }
    return request.clone({
      setHeaders: {
        JID: this.sertifikatJid,
      },
    });
  }
}
