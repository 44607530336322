import { FormArray, FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  IInformacijaIsporukeDto,
  IStrankaRacunaDto,
  TipStranke,
} from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { InformacijaIsporukeFormService } from './informacija-isporuke-form.service';

@Injectable({
  providedIn: 'root',
})
export class StrankaRacunaFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _informacijaIsporukeService: InformacijaIsporukeFormService
  ) {}

  /**
   * Konvertuje model i od njega pravi reaktivnu formu (form grupu) za predracun
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   * @param model model predracuna
   * @returns Reaktivnu formu kao FormGroup za predracun
   */
  public GetStrankaRacunaFormGroup(
    model?: IStrankaRacunaDto
  ): FormGroupTypeSafe<IStrankaRacunaDto> {
    return this._fb.group<IStrankaRacunaDto>({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv, {
        validators: [Validators.required],
      }),
      adresa: new FormControl(model?.adresa),
      pib: new FormControl(model?.pib),
      registracioniBroj: new FormControl(model?.registracioniBroj),
      mb: new FormControl(model?.mb),
      tipStranke: new FormControl(model?.tipStranke ?? TipStranke.PRAVNO_LICE),
      jbkjs : new FormControl(model?.jbkjs),
      rokPlacanjaZaIzlazne: new FormControl(model?.rokPlacanjaZaIzlazne ?? 0),
      rokPlacanjaZaUlazne: new FormControl(model?.rokPlacanjaZaUlazne ?? 0),
      informacijeIsporuke: this.getInformacijaIsporukeStrankeFormArray(
        model?.informacijeIsporuke
      ),
    });
  }

  getInformacijaIsporukeStrankeFormArray(
    informacijeIsporuke: IInformacijaIsporukeDto[]
  ): FormArray {
    if (!informacijeIsporuke || informacijeIsporuke.length === 0) {
      //return this._fb.array([this.getFormGroup()]);
      return this._fb.array([]);
    }

    let result: FormArray = this._fb.array([]);
    informacijeIsporuke.forEach((s) => {
      result.push(this.getInformacijaIsporukeStrankaFormGroup(s));
    });
    return result;
  }

  public getInformacijaIsporukeStrankaFormGroup(
    model?: IInformacijaIsporukeDto
  ): FormGroupTypeSafe<IInformacijaIsporukeDto> {
    return this._fb.group<IInformacijaIsporukeDto>({
      id: new FormControl(model?.id),
      imeLokacijeMestaIsporuke: new FormControl(
        model?.imeLokacijeMestaIsporuke
      ),
      gln: new FormControl(model?.gln, {
        validators: [Validators.maxLength(13)],
      }),
      ulicaIBroj: new FormControl(model?.ulicaIBroj),
      grad: new FormControl(model?.grad),
      postanskiBroj: new FormControl(model?.postanskiBroj),
      drzavaId: new FormControl(model?.drzavaId),
      strankaId: new FormControl(model?.strankaId),
    });
  }
}
