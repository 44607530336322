import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  constructor() {}

  loadingText$ = new BehaviorSubject<string>('Momenat...');
  get getLoadingText() {
    return this.loadingText$.asObservable();
  }
  set setLoadingText(data: string) {
    this.loadingText$.next(data);
  }

  errorText$ = new BehaviorSubject<string>(null);
  get getErrorText() {
    return this.errorText$.asObservable();
  }
  set setErrorText(data: string) {
    this.errorText$.next(data);
  }

  showLoader$ = new BehaviorSubject<boolean>(false);
  get getShowLoader() {
    return this.showLoader$.asObservable();
  }
  set setShowLoader(data: boolean) {
    this.showLoader$.next(data);
  }

  reset() {
    this.setErrorText = '';
    this.setLoadingText = 'Momenat...';
    this.setShowLoader = false;
  }
}
