import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[card]',
})
export class CardDirective implements OnInit {
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.setStyle(
      this.elRef.nativeElement,
      'background-color',
      '#fdfdfd'
    );
    this.renderer.setStyle(
      this.elRef.nativeElement,
      'border',
      '1px solid #dee2e6'
    );
    this.renderer.setStyle(this.elRef.nativeElement, 'padding', '1rem');
    this.renderer.setStyle(this.elRef.nativeElement, 'border-radius', '3px');
  }
}
