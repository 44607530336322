import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  GetSefRacunTableQuery,
  IGetSefRacunTableQuery,
  IStatusRacunaDto,
  StatusRacuna,
  StatusRacunaDto,
  TipRacuna,
} from '@kodit/core/data-api';
import { AdvancedFilter } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { StatusRacunaFormService } from '../../../../../../form-definitions/src/lib/racun/status-racuna-form.service';
import { NeobradjeniSefRacuniFilterService } from './neobradjeni-sef-racuni-filter.service';
import { NeobradjeniSefRacuniFormService } from './neobradjeni-sef-racuni-form.service';

@Component({
  selector: 'kodit-neobradjeni-sef-racuni-filter',
  templateUrl: './neobradjeni-sef-racuni-filter.component.html',
  styleUrls: ['./neobradjeni-sef-racuni-filter.component.scss'],
})
export class NeobradjeniSefRacuniFilterComponent
  implements OnInit, AdvancedFilter<IGetSefRacunTableQuery> {
  /**Props */
  filterData: IGetSefRacunTableQuery;
  form: FormGroupTypeSafe<IGetSefRacunTableQuery>;
  tipoviRacuna: TipRacuna[] = [
    TipRacuna.EMPTY,
    TipRacuna.ULAZNA_FAKTURA,
    TipRacuna.AVANSNI_RACUN,
    TipRacuna.DOKUMENT_O_SMANJENJU,
    TipRacuna.DOKUMENT_O_POVECANJU,
  ];

  statusiRacuna: StatusRacuna[] = [
    StatusRacuna.STORNIRANO,
    StatusRacuna.PRIHVACENO,
    StatusRacuna.ODBIJENO,
    StatusRacuna.NOVO,
    StatusRacuna.PREGLEDANO,
  ];

  @Output() onSubmitForm = new EventEmitter<IGetSefRacunTableQuery>();

  constructor(
    private _sefRacuniFilterService: NeobradjeniSefRacuniFilterService,
    private _filterFormService: NeobradjeniSefRacuniFormService,
    private _statusRacunaFormService: StatusRacunaFormService
  ) {}

  ngOnInit(): void {
    this.form = this._filterFormService.GetNeobradjeniSefRacuniFilterFormGroup(
      this.filterData
    );
    this._filterFormService.setForm = this.form;
  }

  onFilterSubmit() {
    this._sefRacuniFilterService.setAdvancedFilterSubmitted = {
      filterData: this.form.value,
      shouldApplyFilter: true,
    };
  }

  onFilterHide(shouldReset: boolean) {
    this._sefRacuniFilterService.setAdvancedFilterSubmitted = {
      filterData: shouldReset
        ? new GetSefRacunTableQuery({
            statusi: [
              new StatusRacunaDto({
                status: StatusRacuna.NOVO,
              }),
              new StatusRacunaDto({
                status: StatusRacuna.PREGLEDANO,
              }),
              new StatusRacunaDto({
                status: StatusRacuna.PRIHVACENO,
              }),
            ],
          })
        : this.form.value,
      shouldApplyFilter: shouldReset,
    };
  }

  selectedStatusiRacuna(selectedItems: any) {
    (this.form.controls.statusi as FormArray).clear();
    selectedItems.forEach((element) => {
      (this.form.controls.statusi as FormArray).push(
        this._statusRacunaFormService.GetStatusFormGroup({
          status: element,
        } as IStatusRacunaDto)
      );
    });
  }
}
