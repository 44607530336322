import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.css']
})
export class EmailInputComponent implements OnInit {
   /** I/O */
   @Input() formCtrl: AbstractControl | undefined;
   @Input() ctrlPlaceholder: string = '';
   @Input() formCtrlId: string = '';
   @Output() onBlur = new EventEmitter();
   @Output() onFocusIn = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleOnBlur() {
    if (this.onBlur) {
      this.onBlur.emit();
    }
  }

  handleOnFocus() {
    if (this.onFocusIn) {
      this.onFocusIn.emit();
    }
  }
}
