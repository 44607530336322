<div
  class="p-d-flex p-ai-center p-jc-between"
  [ngSwitch]="true"
>
  <div *ngSwitchCase="!items || lenght === 0">U tabeli nema entiteta.</div>
  <div
    *ngSwitchCase="
      (items && lenght === 1) || (lenght > 20 && lenght % 10 === 1)
    "
  >
    Ukupno {{ lenght }} entitet.
  </div>
  <div *ngSwitchDefault>Ukupno {{ lenght }} entiteta.</div>
</div>
