<div class="rezultat-box">
  <div class="p-d-flex p-jc-between">
    <button
      pButton
      type="button"
      label="Zatvori"
      class="p-button-text advanced-button"
      pTooltip="Zatvorite rezultate pretrage"
      tooltipPosition="bottom"
      icon="fas fa-angle-double-right"
      (click)="dodajPredmetStavke(-1)"
    ></button>
    <div class="p-d-flex p-jc-end">
      <ng-container *ngTemplateOutlet="akcijeZaUnos"> </ng-container>
    </div>
    <!-- <div class="p-d-flex p-jc-end" *ngIf="jeIzmenaOsnovice">
      <ng-container *ngTemplateOutlet="akcijeZaUnosIzmeneOsnovice">
      </ng-container>
    </div> -->
  </div>

  <!-- <div class="p-mt-3 p-mb-3" *ngIf="predmetiStavkiZaRacuneResult.length > 1">
    <span
      >Pronađeno <b>{{ predmetiStavkiZaRacuneResult.length }}</b> rezultata po
      datom kriterijumu.</span
    >
  </div>
  <div class="p-mt-3 p-mb-3" *ngIf="predmetiStavkiZaRacuneResult.length == 1">
    <span
      >Pronađen <b>{{ predmetiStavkiZaRacuneResult.length }}</b> rezultata po
      datom kriterijumu.</span
    >
  </div> -->

  <div class="p-fluid p-mt-3" *ngIf="!jeAvansniRacun">
    <div class="p-field p-col-12">
      <kodit-cenovnik-dropdown
        [strankaId]="strankaId"
        (onItemChange)="onCenovnikChange($event)"
      ></kodit-cenovnik-dropdown>
    </div>
  </div>

  <div class="p-mt-3 p-mb-3" *ngIf="predmetiStavkiZaRacuneResult.length == 0">
    <span>Traženi predmet stavke ne postoji</span>
  </div>

  <div *ngIf="predmetiStavkiZaRacuneResult.length > 0">
    <p-scrollPanel styleClass="result-panel p-mt-1">
      <p-dataView
        [value]="predmetiStavkiZaRacuneResult"
        [loading]="shouldDisplayLoader"
        emptyMessage="Nema rezultata po unetom kriterijumu"
      >
        <ng-template let-result pTemplate="listItem">
          <div class="p-col-12 list-item-wrapper">
            <div class="result-list-item card">
              <div class="result-list-detail">
                <div class="result-naziv">
                  <span>
                    {{ result.naziv }}
                  </span>
                </div>
                <div class="result-sifra p-mb-3">
                  <label class="p-mr-1">ŠIFRA:</label>
                  <span>{{ result.sifra }}</span>
                </div>

                <div class="result-details">
                  <label kodit-form-label>Tip artikla:</label>
                  <span>{{ result.tipArtiklaStr }}</span>
                </div>

                <div class="result-details">
                  <label kodit-form-label>Jedinica mere:</label>
                  <span>{{ result.jedinicaMereStr }}</span>
                </div>

                <ng-container *ngIf="result.tipPredmeta === tipRoba">
                  <div class="result-details p-d-inline-flex">
                    <label kodit-form-label>Magacin(i):</label>
                    <div class="p-d-flex p-flex-column">
                      <div
                        class="p-d-flex gap-12"
                        *ngFor="
                          let magacinLager of result.magacinLagerResponseList;
                          let i = index
                        "
                      >
                        <div>{{ magacinLager.nazivMagacina }}</div>
                        <div>(stanje: {{ magacinLager.stanje }})</div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>

              <div class="result-list-action p-mt-3">
                <div class="result-price">
                  {{ result.cenaStr }}
                </div>
                <div *ngIf="result.stranaValutaId" class="p-mt-3">
                  <label kodit-form-label>Odaberite kurs:</label>
                  <div class="p-mt-3">
                    <kodit-rezultat-pretrage-kurs
                      [rowId]="result.id"
                      [valutaText]="result.valutaText"
                      [iznosUStranojValuti]="result.cena"
                      (iznosChanged)="
                        handleIznosPoKursuChange(result.id, $event)
                      "
                    ></kodit-rezultat-pretrage-kurs>
                  </div>
                </div>
                <div class="p-d-flex p-mt-3">
                  <div class="p-mr-3">
                    <p-inputNumber
                      mode="decimal"
                      [(ngModel)]="defaultKolicina"
                      inputId="minmax-buttons"
                      placeholder="Količina"
                      [size]="4"
                      [min]="1"
                      [useGrouping]="false"
                      (onFocus)="inputFocused($event)"
                    >
                    </p-inputNumber>
                  </div>

                  <div class="ek-w-100">
                    <p-button
                      icon="fas fa-plus"
                      label="Dodaj na račun"
                      (click)="dodajPredmetStavke(result.id)"
                    ></p-button>
                  </div>
                </div>

                <!-- <span
                [class]="
                  'result-badge status-' +
                  result.inventoryStatus.toLowerCase()
                "
                >{{ result.inventoryStatus }}</span
              > -->
              </div>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </p-scrollPanel>
  </div>
</div>

<ng-template #akcijeZaUnos>
  <div>
    <button
      pButton
      *ngIf="hasRobno && !jeKonvertovanjeOtpremniceUFakturu"
      type="button"
      label="Roba"
      class="p-button-text advanced-button"
      icon="fas fa-plus"
      pTooltip="Unesite novu robu"
      tooltipPosition="bottom"
      (click)="openRobaDialog(dialogConfig)"
    ></button>
  </div>
  <div>
    <button
      pButton
      *ngIf="!jeOtpremnica"
      type="button"
      label="Usluga"
      class="p-button-text advanced-button"
      icon="fas fa-plus"
      pTooltip="Unesite novu uslugu"
      tooltipPosition="bottom"
      (click)="openUslugaDialog(dialogConfig)"
    ></button>
  </div>
</ng-template>

<!-- *ngIf="tipRacuna.OTPREMNICA" -->
<!--  disabled="jeOtpremnica" -->

<ng-template #akcijeZaUnosIzmeneOsnovice>
  <div>
    <button
      pButton
      type="button"
      label="Unesi predmet stavke"
      class="p-button-text advanced-button"
      icon="fas fa-plus"
      pTooltip="Unesite novi predmet stavke"
      tooltipPosition="bottom"
      (click)="handlePredmetIzmenaOsnoviceCreate(dialogConfig)"
    ></button>
  </div>
</ng-template>
