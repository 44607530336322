import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FizickoLiceTableComponent } from './fizicko-lice/fizicko-lice-table/fizicko-lice-table.component';
import { PravnoLiceTableComponent } from './pravno-lice/pravno-lice-table/pravno-lice-table.component';
import { StrankaInfoComponent } from './stranka-info/stranka-info.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'fizicka-lica',
        data: {
          breadcrumb: 'Fizička lica',
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: FizickoLiceTableComponent,
          },
          {
            path: 'kartica/:strankaId',
            component: StrankaInfoComponent,
            data: {
              breadcrumb: {
                alias: 'nazivStranke',
              },
            },
          },
        ],
      },

      {
        path: 'pravna-lica',
        data: {
          breadcrumb: 'Pravna lica',
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: PravnoLiceTableComponent,
          },
          {
            path: 'kartica/:strankaId',
            component: StrankaInfoComponent,
            data: {
              breadcrumb: {
                alias: 'PravnoLiceId',
              },
            },
          },
        ],
      },
      // {
      //   path: 'pravna-lica/:strankaId',
      //   component: StrankaInfoComponent,
      //   resolve: {
      //     cenovnici: CenovnikResolver,
      //     kontakti: StrankaKontaktResolver,
      //     saldo: SaldoStrankaResolver,
      //     //poslovneJedinice: PoslovneJediniceResolver,
      //   },
      // },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ModuliStrankeRoutingModule {}
