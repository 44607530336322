import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';

@Pipe({
  name: 'formControl'
})
export class FormControlPipe implements PipeTransform {
  transform(abstractControl: AbstractControl): FormControl {
    if (!(abstractControl instanceof FormControl)) {
      throw new Error('Provided control is not a FormControl');
    }
    return abstractControl as FormControl;
  }
}
