import { Component, OnDestroy, OnInit } from '@angular/core';
import { IRacunInfoDto, StatusUplateRacuna } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { RacunInfoService } from '../racun-info/racun-info.service';

@Component({
  selector: 'kodit-uplate-po-racunu',
  templateUrl: './uplate-po-racunu.component.html',
  styleUrls: ['./uplate-po-racunu.component.scss'],
})
export class UplatePoRacunuComponent implements OnInit, OnDestroy {
  /** Props */
  private _subs: Subscription = new Subscription();
  racunInfoDto: IRacunInfoDto;
  statusPlacen: StatusUplateRacuna = StatusUplateRacuna.PLACEN;

  constructor(private _racunInfoService: RacunInfoService) {}

  ngOnInit(): void {
    this._subs = this._racunInfoService.getRacunInfoDto.subscribe((res) => {
      this.racunInfoDto = res;
    });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
