import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-kpo-knjiga-page',
  templateUrl: './kpo-knjiga-page.component.html',
  styleUrls: ['./kpo-knjiga-page.component.scss'],
})
export class KpoKnjigaPageComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit(): void {
    //this._godinaService.enableShouldDisplayRacunYearFilter();
  }

  ngOnDestroy() {
    //this._godinaService.resetShouldDisplayRacunYearFilter();
  }
}
