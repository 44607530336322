<div class="p-d-flex p-flex-wrap gap-12">
  <ng-container *ngFor="let item of items">
    <div
      class="p-field-checkbox p-mb-0 radiobtn-box"
      [ngClass]="
        item.value === selectedCategory.value ? 'selected' : 'non-selected'
      "
      (click)="handleClick(item)"
    >
      <p-radioButton
        [inputId]="'vrsta-' + item.value"
        name="category"
        [value]="item.value"
        [formControl]="selectedCategory"
      ></p-radioButton>
      <label style="cursor: pointer;" [for]="'vrsta-' + item.value">{{ item.label }}</label>
    </div>
  </ng-container>
</div>
