<kodit-dropdown
  formCtrlId="identifikator-stavke-select"
  [options]="items"
  [formCtrl]="identifikatorCtrl"
  ctrlPlaceholder="Izaberite identifikator stavke"
  (optionChanged)="handleOnItemChange($event)"
>
  <ng-template #selectedItem let-obj>
    {{ obj.naziv }}
  </ng-template>

  <ng-template #item let-obj>
    <div>
      {{ obj.naziv }}
    </div>
  </ng-template>
</kodit-dropdown>
