import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DelatnostPodGrupomDto, SharedClient } from '@kodit/core/data-api';
import { SelectItemGroup } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-delatnost-dropdown',
  templateUrl: './delatnost-dropdown.component.html',
  styleUrls: ['./delatnost-dropdown.component.scss'],
})
export class DelatnostDropdownComponent implements OnInit, OnDestroy {
  private _delatnostiSub: Subscription = new Subscription();

  delatnosti: SelectItemGroup[] = [];

  @Input()
  formCtrl: FormControl;

  @Input()
  showLabel: boolean = true;

  constructor(private _client: SharedClient) {}

  ngOnInit() {
    this._delatnostiSub = this._client.getDelatnosti().subscribe((result) => {
      const grouped = this.groupDelatnosti(
        result.data.delatnosti,
        'nazivGrupe'
      );
      //('grupisani: ', grupisani);
      for (let g in grouped) {
        const items = grouped[g].map((x: DelatnostPodGrupomDto) => {
          return {
            label: `${x.sifraDelatnosti} - ${x.nazivDelatnosti}`,
            value: x.sifraDelatnosti,
          };
        });
        //('g: ', g);
        //('grupisani[g]', grupisani[g]);
        //('items: ', items);

        this.delatnosti.push({
          label: g,
          items: items,
        });
      }
      //('delatnosti: ', this.delatnosti);
    });
  }

  private groupDelatnosti = function (
    xs: DelatnostPodGrupomDto[],
    key: string
  ) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  ngOnDestroy() {
    this._delatnostiSub.unsubscribe();
  }
}
