import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-card-body-text',
  templateUrl: './card-body-text.component.html',
  styleUrls: ['./card-body-text.component.scss'],
})
export class CardBodyTextComponent implements OnInit {
  @Input() label: string;
  @Input() text: string;
  @Input() textClass: string;

  constructor() {}

  ngOnInit(): void {}
}
