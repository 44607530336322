<div class="p-field" *ngIf="!isVertial" [pTooltip]="formCtrlTooltip">
  <label [for]="formCtrlId" kodit-form-label
    >{{ formCtrlLabel }}{{ formCtrl.errors?.required ? ' *' : '' }}</label
  >
  <kodit-number-input
    [formCtrlId]="formCtrlId"
    [formCtrl]="formCtrl"
    [suffix]="suffix"
    [prefix]="prefix"
    [ctrlMaxLength]="ctrlMaxLength"
    [ctrlMinValue]="ctrlMinValue"
    [ctrlUseGrouping]="ctrlUseGrouping"
    [ctrlOnlyIntegers]="ctrlOnlyIntegers"
    (onBlur)="inputLeft($event)"
  ></kodit-number-input>
</div>

<div class="p-field p-grid" [pTooltip]="formCtrlTooltip" *ngIf="isVertial">
  <label [for]="formCtrlId" class="p-col-fixed p-m-0" kodit-left-side-label
    >{{ formCtrlLabel }}{{ formCtrl.errors?.required ? ' *' : '' }}</label
  >
  <div class="p-col">
    <kodit-number-input
      [formCtrlId]="formCtrlId"
      [formCtrl]="formCtrl"
      [suffix]="suffix"
      [prefix]="prefix"
      [ctrlMaxLength]="ctrlMaxLength"
      [ctrlMinValue]="ctrlMinValue"
      [ctrlUseGrouping]="ctrlUseGrouping"
      [ctrlOnlyIntegers]="ctrlOnlyIntegers"
      (onBlur)="inputLeft($event)"
    ></kodit-number-input>
  </div>
</div>
