import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CreateIzlaznaFakturaForPredracunUplataCommand,
  EvidentirajUplatuCommand,
  IzlazneFaktureClient,
  IzvodiClient,
  OznakaPdvKategorije,
  TipRacuna,
  UplataIsplataZaRacunDto,
} from '@kodit/core/data-api';
import { AlertService, LocationService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IzvodiService } from '../../izvodi.service';

@Component({
  selector: 'kodit-evidentiranje-predracuna-option-dialog',
  templateUrl: './evidentiranje-predracuna-option-dialog.component.html',
  styleUrls: ['./evidentiranje-predracuna-option-dialog.component.scss'],
})
export class EvidentiranjePredracunaOptionDialogComponent
  implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  kreirajIf: boolean = true;
  options: SelectItem[] = [
    { label: 'Izlazna faktura', value: true },
    { label: 'Avansni račun', value: false },
  ];
  oznakaPdvKategorije: OznakaPdvKategorije;

  /** Config data */
  requestList: UplataIsplataZaRacunDto[];
  transakcijaId: number;

  constructor(
    private _dialogConfig: DynamicDialogConfig,
    private _dialogRef: DynamicDialogRef,
    private _izvodiClient: IzvodiClient,
    private _ifClient: IzlazneFaktureClient,
    private _alertService: AlertService,
    private _locationService: LocationService,
    private _izvodService: IzvodiService
  ) {}

  ngOnInit(): void {
    this.requestList = this._dialogConfig.data.requestList;
    this.transakcijaId = this._dialogConfig.data.transakcijaIzvodaId;
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );
  }

  private _save() {
    if (!this.kreirajIf) {
      this._subs.add(
        this._izvodiClient
          .evidentirajUplatu(
            new EvidentirajUplatuCommand({
              transakcijaIzvodaId: this.transakcijaId,
              uplataIsplataZaRacunList: this.requestList.filter(
                (x) => x.tipRacuna !== TipRacuna.PREDRACUN
              ),
            })
          )
          .pipe(
            finalize(() => {
              this._dialogRef.close();

              this._locationService.routeToAvansniForPredracunUplata(
                this.requestList.filter(
                  (x) => x.tipRacuna === TipRacuna.PREDRACUN
                ),
                this.transakcijaId
              );
            })
          )
          .subscribe()
      );
    } else {
      this._subs.add(
        this._izvodiClient
          .evidentirajUplatu(
            new EvidentirajUplatuCommand({
              transakcijaIzvodaId: this.transakcijaId,
              uplataIsplataZaRacunList: this.requestList.filter(
                (x) => x.tipRacuna !== TipRacuna.PREDRACUN
              ),
            })
          )
          .subscribe()
      );

      this._subs.add(
        this._ifClient
          .getIzlaznaFakturaForPredracunUplata(
            new CreateIzlaznaFakturaForPredracunUplataCommand({
              transakcijaIzvodaId: this.transakcijaId,
              predracunList: this.requestList.filter(
                (x) => x.tipRacuna === TipRacuna.PREDRACUN
              ),
            })
          )
          .subscribe((res) => {
            if (res.succeeded) {
              this._dialogRef.close();
              this._alertService.addSuccessMsg(res.messages[0]);
              this._izvodService.setReloadUplataTable = true;
            }
          })
      );
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
