import { FormArray, FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { ICenovnikDto, TipStranke,  CenovnikStavkaDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { BaseStrankaFormService } from './_base_stranka-form.service';

export interface IDefinisanjeCenaDto {
  novaCena?: number | undefined;
  povecanjeZaProcenat?: number | undefined;
  umanjenjeZaProcenat?: number | undefined;
  stavkeCenovnika?: CenovnikStavkaDto[] | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class DefinisanjeCenaFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _strankaFormService: BaseStrankaFormService
  ) {}


  public GetDefinisanjeCenaFormGroup(
    model?: IDefinisanjeCenaDto
  ): FormGroupTypeSafe<IDefinisanjeCenaDto> {
    return this._fb.group<IDefinisanjeCenaDto>({
      novaCena: new FormControl(model?.novaCena),
      povecanjeZaProcenat: new FormControl(model?.povecanjeZaProcenat),
      umanjenjeZaProcenat: new FormControl(model?.umanjenjeZaProcenat),
      stavkeCenovnika: this._strankaFormService.GetStrankeFormArray(model?.stavkeCenovnika)
    });
  }
}
