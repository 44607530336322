import { FormArray, FormControl } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import { IGetLageriTableQuery } from '@kodit/core/data-api';
import { BaseStrankaFormService } from '../stranka/_base_stranka-form.service';

@Injectable({
  providedIn: 'root',
})
export class LagerFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _strankaFormService: BaseStrankaFormService
  ) {}

  public GetLagerTableRequest(
    model?: IGetLageriTableQuery
  ): FormGroupTypeSafe<IGetLageriTableQuery> {
    return this._fb.group<IGetLageriTableQuery>({
      magacinId: new FormControl(model?.magacinId),
      naziv: new FormControl(model?.naziv),
      sifra: new FormControl(model?.sifra),
      datumPrometa: new FormControl(model?.datumPrometa),
      stranke: this._strankaFormService.GetStrankeFormArray(model?.stranke),
      prikaziNuleIMinus: new FormControl(model?.prikaziNuleIMinus ?? true),
    });
  }
}
