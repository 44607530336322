<div class="k-card" card *ngIf="!isCreateNew">
  <div class="p-d-flex p-flex-column p-jc-between">
    <div class="p-d-flex p-jc-between">
      <h4 class="_header">{{ roleName }}</h4>
      <div>
        <button
          *ngIf="isNotDefaultRole"
          pButton
          pRipple
          type="button"
          icon="far fa-edit"
          class="p-button-text"
          (click)="handleEditKorisnickaGrupa()"
        ></button>
        <button
          *ngIf="isNotDefaultRole"
          pButton
          pRipple
          type="button"
          icon="fas fa-times"
          class="p-button-text p-button-danger"
          (click)="handleDeleteKorisnickaGrupa()"
        ></button>
      </div>
    </div>
    <div class="p-d-flex p-jc-between">
      <div class="_description">
        <span>{{ roleDescription }}</span>
      </div>
      <div>
        <button
          style="padding-left: 0px; padding-right: 0px"
          pButton
          type="button"
          label="Izmeni korisnike"
          class="p-button-link"
          (click)="handleIzmenaKorisnika()"
        ></button>
      </div>
    </div>
  </div>
</div>

<div class="k-card" card *ngIf="isCreateNew">
  <div class="p-d-flex p-jc-center p-ai-center">
    <span class="_new_description">Kreirajte novu korisničku grupu</span>
    <div>
      <button
        pButton
        icon="fas fa-plus"
        type="button"
        label="Kreiraj"
        class="p-button"
        (click)="handleCreateKorisnickaGrupa()"
      ></button>
    </div>
  </div>
</div>
