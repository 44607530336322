<div class="p-d-flex p-flex-column p-fluid">
  <div class="even-columns gap-12 p-flex-md-row">
    <kodit-field fieldLabel="Broj koleta">
      <kodit-number-input-field
        formCtrlId="broj-koleta"
        [formCtrl]="racunForm.controls.koleto"
      ></kodit-number-input-field>
    </kodit-field>
    <kodit-field fieldLabel="Broj paleta">
      <kodit-number-input-field
        formCtrlId="broj-paleta"
        [formCtrl]="racunForm.controls.brojPaleta"
      ></kodit-number-input-field>
    </kodit-field>
    <kodit-field fieldLabel="Broj ramova">
      <kodit-number-input-field
        formCtrlId="broj-ramova"
        [formCtrl]="racunForm.controls.brojRamova"
      ></kodit-number-input-field>
    </kodit-field>
    <kodit-field fieldLabel="Neto težina">
      <kodit-number-input-field
        formCtrlId="neto-tezina"
        [formCtrl]="racunForm.controls.netoTezina"
      ></kodit-number-input-field>
    </kodit-field>
    <kodit-field fieldLabel="Bruto težina">
      <kodit-number-input-field
        formCtrlId="bruto-tezina"
        [formCtrl]="racunForm.controls.brutoTezina"
      ></kodit-number-input-field>
    </kodit-field>
  </div>
</div>
