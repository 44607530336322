<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
    />
  </head>
  <body>
    <div card class="k-card p-mt-3">
      <div class="p-d-flex p-jc-between _header">
        <div class="ek-w-70">
          <p class="_title p-mb-2">Istorija email poruka</p>
        </div>
      </div>
      <kodit-divider></kodit-divider>
      <div class="" *ngFor="let mail of emailList; let i = index">
        <div class="p-d-flex p-flex-row gap-4">
          <div class="p-d-flex p-flex-column left-side">
            <div>
              <span class="text-time">{{ mail.receivedAtDate }}</span>
            </div>
            <div>
              <span class="text-time"> {{ mail.receivedAtTime }} </span>
            </div>
          </div>
          <div class="ikonica">
            <div
              class="badge_status {{
                mail.status.status === 'Sent' ? 'success' : 'danger'
              }}"
            >
              <i
                class="icon_color {{
                  mail.status.status === 'Sent'
                    ? 'fa-light fa-paper-plane'
                    : 'fa-solid fa-circle-exclamation'
                }}"
              >
              </i>
            </div>
          </div>
          <div class="p-d-flex right-side p-ac-between">
            <div class="p-d-flex timeline p-flex-column">
              <div>
                <span class="title">{{ mail.subject }}</span>
              </div>
              <div>
                <span class="description">Primaoci: {{ mail.recipients }}</span>
              </div>
            </div>
            <div>
              <span
                class="email-event {{
                  messageEvents[i] ?? 'Processed'
                }}"
                >{{ messageEvents[i] ?? 'Processed' }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
