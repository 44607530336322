import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-error-info-email',
  templateUrl: './error-info-email.component.html',
  styleUrls: ['./error-info-email.component.scss'],
})
export class ErrorInfoEmailComponent implements OnInit {
  /** Props */
  errorMsg: string = 'E-Mail nije u dobrom formatu.';

  /** I/O */
  @Input() formCtrl: AbstractControl;

  constructor() {}

  ngOnInit(): void {}
}
