import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IKonfiguracijaDeviznogRacunaDto,
  RacuniClient,
} from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { KonfiguracijaDeviznogRacunaFormComponent } from '../konfiguracija-deviznog-racuna-form/konfiguracija-deviznog-racuna-form.component';

@Component({
  selector: 'kodit-konfiguracija-deviznog-racuna',
  templateUrl: './konfiguracija-deviznog-racuna.component.html',
  styleUrls: ['./konfiguracija-deviznog-racuna.component.scss'],
})
export class KonfiguracijaDeviznogRacunaComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Configuration */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  konfiguracijaDeviznogRacunaDto: IKonfiguracijaDeviznogRacunaDto;
  showEmptyMessage: boolean;

  constructor(
    private _dialogService: DynamicDialogService,
    private _client: RacuniClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.load();
  }

  onEdit() {
    this.dialogConfig.data = {
      model: this.konfiguracijaDeviznogRacunaDto,
    };
    this.dialogConfig.header = 'Izmena konfiguracije deviznog računa';
    this.openDialog(this.dialogConfig);
    this.showEmptyMessage =
      !this.konfiguracijaDeviznogRacunaDto.accountAddress &&
      !this.konfiguracijaDeviznogRacunaDto.bank &&
      !this.konfiguracijaDeviznogRacunaDto.iban &&
      !this.konfiguracijaDeviznogRacunaDto.accountName &&
      !this.konfiguracijaDeviznogRacunaDto.swift;
  }

  openDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(
      KonfiguracijaDeviznogRacunaFormComponent,
      config
    );

    this._subs.add(
      ref.onClose.subscribe((dto: IKonfiguracijaDeviznogRacunaDto) => {
        if (dto) {
          this.konfiguracijaDeviznogRacunaDto = dto;
        }
      })
    );
  }

  private load() {
    this._subs.add(
      this._client.getFormConfigurationDevizni().subscribe((result) => {
        this.konfiguracijaDeviznogRacunaDto = result.data;
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
