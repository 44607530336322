import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

/** Modules */
import { PdvEvidencijaRoutingModule } from './pdv-evidencija-routing.module';
import { CoreSharedModule } from '@kodit/core/shared';

/** Components */
import { PdvEvidencijaPojedinacnaFormComponent } from './pojedinacna/pdv-evidencija-pojedinacna-form/pdv-evidencija-pojedinacna-form.component';
import { PdvEvidencijaPojedinacnaTableComponent } from './pojedinacna/pdv-evidencija-pojedinacna-table/pdv-evidencija-pojedinacna-table.component';
import { PdvEvidencijaPageComponent } from './pdv-evidencija-page/pdv-evidencija-page.component';
import { TipDokumentaEvidencijeDropdownComponent } from './ui/tip-dokumenta-evidencije-dropdown/tip-dokumenta-evidencije-dropdown';
import { PdvOpsegDropdownComponent } from './ui/pdv-opseg-dropdown/pdv-opseg-dropdown';
import { PdvPeriodDropdownComponent } from './ui/pdv-period-dropdown/pdv-period-dropdown';
import { StrankaForPdvAutocompleteComponent } from './ui/stranka-for-pdv-autocomplete/stranka-for-pdv-autocomplete';
import { DokumentForPdvAutocompleteComponent } from './ui/dokument-for-pdv-autocomplete/dokument-for-pdv-autocomplete';
import { PdvEvidencijaZbirnaFormComponent } from './zbirna/pdv-evidencija-zbirna-form/pdv-evidencija-zbirna-form.component';
import { PdvEvidencijaZbirnaTableComponent } from './zbirna/pdv-evidencija-zbirna-table/pdv-evidencija-zbirna-table.component';
import { PovezaniAvansniRacuniComponent } from './pojedinacna/povezani-avansni-racuni/povezani-avansni-racuni.component';
import { OsnovOdabiraDokumentaDropdownComponent } from './ui/osnov-odabira-dokumenta-dropdown/osnov-odabira-dokumenta-dropdown.component';
import { PdvEvidencijaPojedinacnaFilterComponent } from './pojedinacna/pdv-evidencija-pojedinacna-table/pdv-evidencija-pojedinacna-filter/pdv-evidencija-pojedinacna-filter.component';
import { TipDokumentaEvidencijeMultiselectComponent } from './pojedinacna/pdv-evidencija-pojedinacna-table/pdv-evidencija-pojedinacna-filter/tip-dokumenta-evidencije-multiselect/tip-dokumenta-evidencije-multiselect.component';
import { StatusEvidencijeMultiselectComponent } from './pojedinacna/pdv-evidencija-pojedinacna-table/pdv-evidencija-pojedinacna-filter/status-evidencije-multiselect/status-evidencije-multiselect.component';
import { PdvEvidencijaZbirnaFilterComponent } from './zbirna/pdv-evidencija-zbirna-table/pdv-evidencija-zbirna-filter/pdv-evidencija-zbirna-filter.component';
import { PdvEvidencijaZbirnaPageComponent } from './zbirna/pdv-evidencija-zbirna-page/pdv-evidencija-zbirna-page.component';
import { PdvEvidencijaZbirnaTable39Component } from './zbirna/pdv-evidencija-zbirna-table39/pdv-evidencija-zbirna-table39.component';
import { PdvEvidencijaZbirnaForm39Component } from './zbirna/pdv-evidencija-zbirna-form39/pdv-evidencija-zbirna-form39.component';
import { PdvEvidencijaZbirnaTable39FilterComponent } from './zbirna/pdv-evidencija-zbirna-table39/pdv-evidencija-zbirna-table39-filter/pdv-evidencija-zbirna-table39-filter.component';
import { PdvEvidencijaPojedinacnaPageComponent } from './pojedinacna/pdv-evidencija-pojedinacna-page/pdv-evidencija-pojedinacna-page.component';
import { PdvEvidencijaPojedinacnaTable39Component } from './pojedinacna/pdv-evidencija-pojedinacna-table39/pdv-evidencija-pojedinacna-table39.component';
import { PdvEvidencijaPojedinacna39FormComponent } from './pojedinacna/pdv-evidencija-pojedinacna39-form/pdv-evidencija-pojedinacna39-form.component';
import { PovezanaFakturaForPdvAutocompleteComponent } from './ui/povezana-faktura-for-pdv-autocomplete/povezana-faktura-for-pdv-autocomplete.component';
import { PovezaniAvansniRacuniVanSefaComponent } from './pojedinacna/povezani-avansni-racuni-van-sefa/povezani-avansni-racuni-van-sefa.component';
import { PdvEvidencijaPojedinacna39FilterComponent } from './pojedinacna/pdv-evidencija-pojedinacna-table39/pdv-evidencija-pojedinacna39-filter/pdv-evidencija-pojedinacna39-filter.component';
import { TipPovezanogDokumentaDropdownComponent } from './ui/tip-povezanog-dokumenta-dropdown/tip-povezanog-dokumenta-dropdown.component';
import { TipPovezanogInternogDropdownComponent } from './ui/tip-povezanog-internog-dropdown/tip-povezanog-internog-dropdown.component';
import { PppdvTableComponent } from './prethodni-porez/pppdv-table/pppdv-table.component';
import { PppdvFormComponent } from './prethodni-porez/pppdv-form/pppdv-form.component';
import { PppdvTableFilterComponent } from './prethodni-porez/pppdv-table/pppdv-table-filter/pppdv-table-filter.component';

@NgModule({
  imports: [PdvEvidencijaRoutingModule, CoreSharedModule],
  declarations: [
    PdvEvidencijaPojedinacnaFormComponent,
    PdvEvidencijaPojedinacnaTableComponent,
    PdvEvidencijaPageComponent,
    TipDokumentaEvidencijeDropdownComponent,
    PdvOpsegDropdownComponent,
    PdvPeriodDropdownComponent,
    StrankaForPdvAutocompleteComponent,
    DokumentForPdvAutocompleteComponent,
    PdvEvidencijaZbirnaFormComponent,
    PdvEvidencijaZbirnaTableComponent,
    PovezaniAvansniRacuniComponent,
    OsnovOdabiraDokumentaDropdownComponent,
    PdvEvidencijaPojedinacnaFilterComponent,
    TipDokumentaEvidencijeMultiselectComponent,
    StatusEvidencijeMultiselectComponent,
    PdvEvidencijaZbirnaFilterComponent,
    PdvEvidencijaZbirnaPageComponent,
    PdvEvidencijaZbirnaForm39Component,
    PdvEvidencijaZbirnaTable39FilterComponent,
    PdvEvidencijaPojedinacnaPageComponent,
    PdvEvidencijaPojedinacnaTable39Component,
    PdvEvidencijaPojedinacna39FormComponent,
    PovezanaFakturaForPdvAutocompleteComponent,
    PovezaniAvansniRacuniVanSefaComponent,
    PdvEvidencijaPojedinacna39FilterComponent,
    TipPovezanogDokumentaDropdownComponent,
    TipPovezanogInternogDropdownComponent,
    PdvEvidencijaZbirnaTable39Component,
    PppdvTableComponent,
    PppdvFormComponent,
    PppdvTableFilterComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModuliPdvEvidencijaModule {}
