<form [formGroup]="form">
  <div class="p-fluid">
    <!-- Banka -->
    <kodit-field fieldLabel="Banka" fieldLabelFor="banka">
      <kodit-banka-autocomplete
        formCtrlId="banka"
        [inputInitValue]="form.value.bankaDto.naziv"
        (onBankaChange)="onBankaChange($event)"
      ></kodit-banka-autocomplete>
    </kodit-field>

    <!-- Tekuci racun -->
    <div class="p-field">
      <label for="ceoBrojRacuna" kodit-form-label>Tekući račun</label>
      <input
        pInputText
        id="ceoBrojRacuna"
        type="text"
        formControlName="ceoBrojRacuna"
        [pKeyFilter]="racunRegex"
        [pValidateOnly]="true"
        placeholder="000-0000000000000-00"
      />
    </div>

    <!-- IBAN -->
    <div class="p-field">
      <label for="iban" kodit-form-label>IBAN</label>
      <kodit-text-input [formCtrl]="form.controls.iban"></kodit-text-input>
    </div>
  </div>

  <!-- IsDefault -->
  <kodit-checkbox
    labelText="Postaviti kao osnovni račun"
    [formCtrl]="form.controls.isDefault"
  ></kodit-checkbox>

  <!-- Prikazati na Pdf-u -->
  <kodit-checkbox
    labelText="Prikazati na fakturi?"
    [formCtrl]="form.controls.prikaziNaPdf"
  ></kodit-checkbox>

  <!-- IsActive -->
  <kodit-checkbox
    *ngIf="mode === 2"
    labelText="Aktivan tekući račun"
    [formCtrl]="form.controls.isActive"
  ></kodit-checkbox>
</form>
