<div class="p-d-flex p-flex-column p-flex-md-row p-flex-wrap gap-12 p-fluid">
  <!-- Izmena osnovice dropdown -->
  <kodit-field fieldLabel="Dokument o smanjenju/povećanju se odnosi na">
    <kodit-izmena-osnovice-dropdown
      [formCtrl]="
        racunForm.controls.prikazIzmenaOsnoviceDto.get('prikazIzmenaOsnovice')
      "
      formCtrlId="prikazIzmenaOsnovice"
    >
    </kodit-izmena-osnovice-dropdown>
  </kodit-field>
  <!-- Odabir izvorne fakture -->
  <div
    class="p-d-flex p-flex-column p-flex-md-row p-flex-wrap gap-12 p-fluid"
    *ngIf="!periodSelected"
  >
    <div>
      <kodit-odabir-izvorne-fakture
      ></kodit-odabir-izvorne-fakture>
    </div>
    <div class="fixed-input">
      <kodit-field
        fieldLabel="Broj ref. dokumenta"
        fieldLabelFor="brojNarudzbenice"
      >
        <kodit-text-input
          formCtrlId="brojReferentnogDokumenta"
          [formCtrl]="racunForm.controls.brojReferentnogDokumenta"
        ></kodit-text-input>
      </kodit-field>
    </div>

    <kodit-field
      fieldLabel="Datum ref. dokumenta"
      fieldLabelFor="datum-narudzbenice"
    >
      <kodit-calendar
        formCtrlId="datum-narudzbenice"
        [formCtrl]="racunForm.get('racunDto.datumNarudzbenice')"
      ></kodit-calendar>
    </kodit-field>
  </div>
  <div
    *ngIf="periodSelected"
    class="p-d-flex p-flex-column p-flex-md-row p-flex-wrap gap-12 p-fluid"
  >
    <kodit-field fieldLabel="Period od">
      <kodit-calendar
        formCtrlId="periodOd"
        [formCtrl]="racunForm.controls.periodOd"
      ></kodit-calendar>
    </kodit-field>
    <kodit-field fieldLabel="Period do">
      <kodit-calendar
        formCtrlId="periodDo"
        [formCtrl]="racunForm.controls.periodDo"
      ></kodit-calendar>
    </kodit-field>
  </div>
</div>
<br />
