import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RatingService {
  constructor() {}

  checkUserRating$ = new BehaviorSubject<boolean>(null);

  get getCheckUserRating() {
    return this.checkUserRating$.asObservable();
  }

  set setCheckUserRating(data: boolean) {
    this.checkUserRating$.next(data);
  }
}
