<div [formGroup]="form" class="p-d-flex p-flex-column p-fluid">
  <div class="p-d-flex p-flex-column p-flex-md-row p-mr-4 p-fluid gap-8">
    <!-- Broj obracuna -->
    <div class="flex-1">
      <kodit-field fieldLabel="Broj obračuna" fieldLabelFor="brojObracuna">
        <kodit-text-input
          class="show"
          [formCtrl]="form.controls.brojObracuna"
          formCtrlId="brojObracuna"
        ></kodit-text-input>
      </kodit-field>
    </div>
    <!-- Mesec -->
    <div class="flex-1">
      <kodit-field fieldLabel="Period">
        <kodit-mesec-dropdown
          [mesecCtrl]="form.controls.mesec!"
        ></kodit-mesec-dropdown>
      </kodit-field>
    </div>
    <div class="flex-1">
      <kodit-field fieldLabel="Status" fieldLabelFor="status">
        <kodit-status-evidencije-multiselect
          [tipFormArray]="form.controls.statusi"
          formCtrlId="status"
        ></kodit-status-evidencije-multiselect>
      </kodit-field>
    </div>
  </div>
</div>
