<div class="message-wrapper">
  <img
    src="/assets/core-ui/images/welcome_image.png"
    alt="welcome image"
    class="message-image"
  />
  <div class="message-text">Dobrodošli!</div>
  <div class="message-description">
    Kako biste nesmetano koristili aplikaciju potrebno je da popunite sledeće
    podatke.
  </div>
</div>
