<form [formGroup]="form">
  <div class="p-fluid">
    <kodit-field
      fieldLabel="Ime lokacije mesta isporuke"
      fieldLabelFor="imeLokacijeMestaIsporuke"
    >
      <kodit-text-input
        formCtrlId="ime_lokacije_mesta_isporuke"
        [formCtrl]="form.controls.imeLokacijeMestaIsporuke"
      ></kodit-text-input>
    </kodit-field>

    <kodit-field fieldLabel="GLN" fieldLabelFor="gln">
      <kodit-text-input
        formCtrlId="gln"
        [formCtrl]="form.controls.gln"
      ></kodit-text-input>
    </kodit-field>

    <kodit-field fieldLabel="Ulica i broj" fieldLabelFor="ulica_i_broj">
      <kodit-text-input
        formCtrlId="ulica_i_broj"
        [formCtrl]="form.controls.ulicaIBroj"
      ></kodit-text-input>
    </kodit-field>

    <kodit-field fieldLabel="Grad" fieldLabelFor="grad">
      <kodit-text-input
        formCtrlId="grad"
        [formCtrl]="form.controls.grad"
      ></kodit-text-input>
    </kodit-field>

    <kodit-field fieldLabel="Postanski broj" fieldLabelFor="postanskiBroj">
      <kodit-text-input
        formCtrlId="postanski_broj"
        [formCtrl]="form.controls.postanskiBroj"
      ></kodit-text-input>
    </kodit-field>

    <kodit-drzava-dropdown
      [drzavaCtrl]="form.controls.drzavaId"
    ></kodit-drzava-dropdown>
  </div>
</form>
