import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IStrankaRacunaDto, VrstaRacuna } from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { KompenzacijaService } from '../../kompezacija.service';

@Component({
  selector: 'kodit-stranka-kompenzacija-form',
  templateUrl: './stranka-kompenzacija-form.component.html',
  styleUrls: ['./stranka-kompenzacija-form.component.scss'],
})
export class StrankaKompenzacijaFormComponent implements OnInit, OnDestroy {
  private _subs = new Subscription();

  /** */
  @Input() strankaFormGroup: FormGroupTypeSafe<IStrankaRacunaDto>;
  @Input() vrstaRacuna: string;

  constructor(private _kompenzacijaService: KompenzacijaService) {}

  ngOnInit(): void {}

  onSelectionChange(stranka: IStrankaRacunaDto) {
    this.strankaFormGroup.patchValue(stranka);
    this.strankaFormGroup.updateValueAndValidity();
  }

  onSelectionClear() {
    this.strankaFormGroup.reset();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
