<div class="p-d-flex p-flex-column">
  <!-- Mesto i datum prometa -->
  <div
    class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid"
    *ngIf="displayPromet"
  >
    <!--mesto prometa-->
    <kodit-text-input-field
      formCtrlId="mesto-prometa"
      formCtrlLabel="Mesto prometa"
      [formCtrl]="racunFormGroup.controls.mestoPrometa"
      class="flex-2"
    ></kodit-text-input-field>
    <!--datum prometa-->
    <div class="p-field flex-1">
      <label for="datum-prometa" kodit-form-label>Datum prometa</label>
      <kodit-calendar
        formCtrlId="datum-prometa"
        [formCtrl]="racunFormGroup.controls.datumPrometa"
      ></kodit-calendar>
    </div>
  </div>

  <!--Mesto i (datum izdavanja ili datum obracuna) -->
  <div
    class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid"
    *ngIf="displayMestoIzdavanja"
  >
    <!--mesto izdavanja-->
    <kodit-text-input-field
      class="flex-2"
      formCtrlId="mesto-izdavanja"
      formCtrlLabel="Mesto izdavanja"
      formCtrlLabel="Mesto izdavanja"
      [formCtrl]="racunFormGroup.controls.mestoIzdavanja"
    ></kodit-text-input-field>

    <!--datum izdavanja-->
    <div class="p-field flex-1" *ngIf="displayDatumIzdavanja">
      <label for="datum-izdavanja" kodit-form-label>Datum izdavanja</label>
      <kodit-calendar
        formCtrlId="datum-izdavanja"
        [formCtrl]="racunFormGroup.controls.datumIzdavanja"
      ></kodit-calendar>
    </div>

    <!--datum uplate-->
    <div class="p-field flex-1" *ngIf="displayDatumUplate">
      <label for="datum-uplate" kodit-form-label>Datum avansne uplate</label>
      <kodit-calendar
        formCtrlId="datum-izdavanja"
        [formCtrl]="racunFormGroup.controls.datumValute"
      ></kodit-calendar>
    </div>
  </div>

  <!-- Rok vazenja, rok placanja i datum valute -->
  <div
    class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid"
    *ngIf="
      displayRokVazenja ||
      displayRokPlacanja ||
      displayDatumValute ||
      displayDatumObracunaPdv
    "
  >
    <!--rok vazenja-->
    <div class="p-field flex-2" *ngIf="displayRokVazenja">
      <label for="rok" kodit-form-label>Rok važenja</label>
      <kodit-rok-vazenja-input
        formCtrlId="rok"
        [formCtrl]="rokVazenjaCtrl"
      ></kodit-rok-vazenja-input>
    </div>
    <!--datum obracuna pdv & rok placanja -->
    <div
      class="flex-2 p-d-flex gap-6"
      *ngIf="displayDatumObracunaPdv || displayRokPlacanja"
    >
      <!--datum obracuna pdv -->
      <div class="p-field flex-2" *ngIf="displayDatumObracunaPdv">
        <label for="datum-obracuna-pdv" kodit-form-label
          >Nastanak PDV obaveze</label
        >
        <kodit-datum-obracuna-pdv-dropdown
          [formCtrl]="racunFormGroup.controls.datumObracunaPdv"
          formCtrlId="datum-obracuna-pdv"
        ></kodit-datum-obracuna-pdv-dropdown>
      </div>
      <!--rok placanja-->
      <div class="p-field flex-1" *ngIf="displayRokPlacanja">
        <label for="rok" kodit-form-label>Rok plaćanja</label>
        <kodit-rok-vazenja-input
          formCtrlId="rok"
          [formCtrl]="racunFormGroup.controls.rokPlacanja"
        ></kodit-rok-vazenja-input>
      </div>
    </div>

    <!--datum valute-->
    <div class="p-field flex-1" *ngIf="displayDatumValute">
      <label for="datum-valute" kodit-form-label>Datum dospeća (valute)</label>
      <kodit-calendar
        formCtrlId="datum-valute"
        [formCtrl]="racunFormGroup.controls.datumValute"
      ></kodit-calendar>
    </div>
  </div>
</div>
