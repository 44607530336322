<kodit-dropdown
  *ngIf="showDataAfterApiResponse || isVisible"
  formCtrlId="pdv-razlog-izuzeca-select"
  [options]="items"
  [formCtrl]="pdvRazlogIzuzecaCtrl"
  ctrlPlaceholder="Izaberite razlog PDV izuzeća"
  [ctrlDisabled]="items.length === 0"
  (optionChanged)="handleOptionChanged($event)"
>
  <ng-template #selectedItem let-obj>
    {{ obj.naziv }}
  </ng-template>

  <ng-template #item let-obj>
    <div [pTooltip]="obj.opis">
      {{ obj.naziv }}
    </div>
  </ng-template>
</kodit-dropdown>
