<form [formGroup]="form">
  <p-autoComplete
    #autocompleteRef
    [suggestions]="dokumentSuggestionsStr"
    formControlName="searchValue"
    (completeMethod)="handleSearch($event)"
    (onSelect)="handleSelectChange($event)"
    placeholder="Unesite broj fakture i ostali podaci će automatski biti popunjeni"
    [appendTo]="parentCtrl"
    (onClear)="handleClear()"
    emptyMessage="Dokument nije pronađen"
    dropdownMode="current"
    [disabled]="shouldDisable"
  >
  </p-autoComplete>
  <kodit-error-info-required
    [formCtrl]="form.controls.searchValue"
  ></kodit-error-info-required>
</form>

