import { Component, OnInit } from '@angular/core';
import { PackageType } from '@kodit/core/data-api';
import { AuthService } from '@kodit/core/services';

@Component({
  selector: 'kodit-izvestaj-page',
  templateUrl: './izvestaj-page.component.html',
  styleUrls: ['./izvestaj-page.component.scss'],
})
export class IzvestajPageComponent implements OnInit {
  /** Props */
  shouldDisplayFiskalna: boolean;

  constructor(private _authService: AuthService) {}

  ngOnInit(): void {
    this._loadDisplay();
  }

  private _loadDisplay() {
    this.shouldDisplayFiskalna = this._authService.hasPackage([
      PackageType[PackageType.MALOPRODAJA],
    ]);
  }
}
