import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CreateSastavnicaCommand,
  GetSastavniceTableQuery,
  ISastavnicaDto,
  SastavniceClient,
  TipArtikla,
  TipPredmeta,
  UpdateSastavnicaCommand,
} from '@kodit/core/data-api';
import {
  FormHelper,
  SastavnicaFormService,
} from '@kodit/core/form-definitions';
import { AlertService, Mode } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { map } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-sastavnica-form',
  templateUrl: './sastavnica-form.component.html',
  styleUrls: ['./sastavnica-form.component.scss'],
})
export class SastavnicaFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _getSub: Subscription = new Subscription();
  private _saveSub: Subscription = new Subscription();
  private _submitSub: Subscription = new Subscription();

  /** Props */
  sastavnicaForm: FormGroupTypeSafe<ISastavnicaDto>;
  currentStavkaId: number;
  excludeTipovePredmeta: TipPredmeta[];
  excludeTipoveArtikla: TipArtikla[];
  excludeIds: number[];
  mode: Mode = Mode.NEW;

  constructor(
    private _client: SastavniceClient,
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _sastavnicaFormService: SastavnicaFormService,
    private _formHelper: FormHelper
  ) {
    this.excludeTipovePredmeta = [
      TipPredmeta.DATI_AVANS,
      TipPredmeta.PRIMLJENI_AVANS,
      TipPredmeta.PREDMET_IZMENE_OSNOVICE,
      TipPredmeta.VOZILO,
    ];
    this.excludeTipoveArtikla = [
      TipArtikla.ROBA,
      TipArtikla.PROIZVOD,
      TipArtikla.VOZILO,
    ];
    this.currentStavkaId = this._dialogConfig.data.robaId;
  }

  ngOnInit(): void {
    this.sastavnicaForm = this._sastavnicaFormService.GetFormGroup({
      proizvodId: this.currentStavkaId,
      id: this._dialogConfig.data.id,
    });

    if (this._dialogConfig.data && this._dialogConfig.data.id) {
      this.getSastavnica(this._dialogConfig.data.id);
      this.mode = Mode.EDIT;
    }

    if (this._dialogConfig.data && this._dialogConfig.data.excludeIds) {
      this.excludeIds = [...this._dialogConfig.data.excludeIds];
    }

    // subscribe on dialog save button
    this._submitSub = this._dialogRef.onSubmit.subscribe(() => {
      this.onSave();
    });
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.onSave();
    }
  }

  private onSave() {
    if (!this.sastavnicaForm.valid) {
      this._formHelper.invalidateForm(this.sastavnicaForm);
      return;
    }

    if (this.mode === Mode.EDIT) {
      this._saveSub = this._client
        .update(this.sastavnicaForm.value as UpdateSastavnicaCommand)
        .subscribe((result) => {
          this._alertService.addSuccessMsg(`Roba `);
          this._dialogRef.close(result);
        });
    } else {
      this._saveSub = this._client
        .create(this.sastavnicaForm.value as CreateSastavnicaCommand)
        .subscribe((result) => {
          this._alertService.addSuccessMsg(`Akcija uspešno izvršena`);
          this._dialogRef.close(result);
        });
    }
  }

  getSastavnica(id: number) {
    this._getSub = this._client.get(id).subscribe((model) => {
      this.sastavnicaForm.patchValue(model.data);
    });
  }

  ngOnDestroy(): void {
    this._getSub.unsubscribe();
    this._submitSub.unsubscribe();
    this._saveSub.unsubscribe();
  }
}
