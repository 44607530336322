<kodit-info-container>
  <div body-full>
  <div class="kodit-card-tab">
    <kodit-opsta-pravila-fiskalna></kodit-opsta-pravila-fiskalna>
  </div>
  <div  class="p-mt-3 kodit-card-tab">
    <kodit-predefinisani-tekst-fiskalni></kodit-predefinisani-tekst-fiskalni>
  </div>

<div class="p-mt-3 kodit-card-tab">
<p-tabView
  (activeIndexChange)="handleIndexChange($event)"
>
  <p-tabPanel #bezbednosniElementiPanel header="Sertifikati"></p-tabPanel>
  <p-tabPanel #poreskeStopePanel header="Poreske stope"></p-tabPanel>
</p-tabView>
</div>
<div [ngSwitch]="activeIndex">
  <div *ngSwitchCase="0">
    <kodit-bezbednosni-elementi-table>
    </kodit-bezbednosni-elementi-table>
  </div>

  <div *ngSwitchCase="1">
    <kodit-page>
      <kodit-poreske-stop-fiskalne-kase>
      </kodit-poreske-stop-fiskalne-kase>
    </kodit-page>
  </div>
</div>
  </div>
</kodit-info-container>
