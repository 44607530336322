import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPredmetStavkeDto } from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';

@Component({
  selector: 'kodit-predmet-stavke-racuna-input',
  templateUrl: './predmet-stavke-racuna-input.component.html',
  styleUrls: ['./predmet-stavke-racuna-input.component.scss'],
})
export class PredmetStavkeRacunaInputComponent implements OnInit {
  /** I/O */
  @Input() predmetStavkeFormGroup: FormGroupTypeSafe<IPredmetStavkeDto>;
  @Output() onNazivChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onKeyReleased(event: any) {
    this.onNazivChange.emit(event.target.value);
  }
}
