import {Injectable} from "@angular/core";
import {FormBuilderTypeSafe, FormGroupTypeSafe} from "angular-typesafe-reactive-forms-helper";
import {IPrikazRabataDto, IVelicinaPapiraDto} from "@kodit/core/data-api";
import {FormControl} from "@angular/forms";

@Injectable({
  providedIn: 'root',
})

export class VelicinaPapiraFormService{
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetVelicinaPapiraFormGroup(
    model?: IVelicinaPapiraDto
  ): FormGroupTypeSafe<IVelicinaPapiraDto>{
    return this._fb.group<IVelicinaPapiraDto>({
      opis: new FormControl(model?.opis),
      velicinaPapira: new FormControl(model?.velicinaPapira),
      velicinaPapiraStr: new FormControl(model?.velicinaPapiraStr),
    });
  }
}
