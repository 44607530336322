<div *ngIf="form" [formGroup]="form">
  <kodit-card-container>
    <div card-body class="p-d-flex p-flex-column">
      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <div class="flex-50">
          <div class="flex-50 page-description-badge">
            <kodit-field
              fieldLabelFor="smer"
              fieldLabel="Promet"
              class="flex-50"
            >
              <kodit-vrsta-racuna-dropdown
                [isForEvidencija]="true"
                [formCtrl]="form.controls?.smer!"
                (onItemChange)="onPrometChange($event)"
                [shouldDisable]="shouldDisableForm"
              >
              </kodit-vrsta-racuna-dropdown>
            </kodit-field>
            <!-- <p-message
              severity="info"
              text="Unesite broj fakture u polje 'Broj dokumenta' i ostali podaci će automatski biti popunjeni"
            ></p-message> -->
          </div>
          <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
            <kodit-field
              fieldLabelFor="tip"
              fieldLabel="Tip dokumenta"
              class="flex-50"
            >
              <kodit-tip-dokumenta-evidencije-dropdown
                [formCtrl]="form.controls.tip!"
                (onItemChange)="onTipDokumentaChange($event)"
                [shouldDisable]="shouldDisableForm"
              >
              </kodit-tip-dokumenta-evidencije-dropdown>
            </kodit-field>

            <kodit-field
              fieldLabelFor="povezani-dokument"
              fieldLabel="Broj dokumenta"
              class="flex-50"
            >
              <!-- Autocomplete ako je isporuka  -->
              <kodit-povezana-faktura-for-pdv-autocomplete
                *ngIf="_pdvPojedinacnaService.getIsIsporuka"
                [dokumentCtrl]="form.controls.povezanaFaktura!"
                formCtrlId="povezana-faktura"
                [shouldDisable]="shouldDisableForm"
              >
              </kodit-povezana-faktura-for-pdv-autocomplete>
              <!-- Text input ako  -->
              <kodit-text-input
                *ngIf="!_pdvPojedinacnaService.getIsIsporuka"
                formCtrlId="povezana-faktura"
                [formCtrl]="form.controls.brojDokumentaNabavka"
                [shouldDisable]="shouldDisableForm"
              ></kodit-text-input>
            </kodit-field>
          </div>
        </div>
      </div>
      <kodit-divider></kodit-divider>

      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabel="Broj pojedinačne PDV evidencije"
          fieldLabelFor="pravilo-za-broj"
          class="flex-50"
        >
          <kodit-text-input
            [formCtrl]="form.controls.broj"
            [isDisabled]="shouldDisableForm"
          ></kodit-text-input>
        </kodit-field>
      </div>

      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field fieldLabel="Godina" fieldLabelFor="godina" class="flex-50">
          <kodit-number-input
            formCtrlId="godina"
            [formCtrl]="form.controls.godina"
            [ctrlMaxLength]="4"
            [ctrlMinValue]="1900"
            [ctrlOnlyIntegers]="true"
            [ctrlUseGrouping]="false"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
        <kodit-field
          fieldLabelFor="datum-evidentiranja"
          fieldLabel="Datum evidentiranja"
          class="flex-50"
        >
          <kodit-calendar
            [disabled]="true"
            formCtrlId="datum-prometa"
            [formCtrl]="form.controls.datumEvidentiranja!"
          ></kodit-calendar>
        </kodit-field>
      </div>

      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabelFor="vremenski-opseg"
          fieldLabel="Poreski period"
          class="flex-50"
        >
          <kodit-pdv-opseg-dropdown
            formCtrlId="vremenski-opseg"
            [periodValue]="form.value.period!"
            [shouldDisable]="shouldDisableForm"
          ></kodit-pdv-opseg-dropdown>
        </kodit-field>
        <kodit-field fieldLabelFor="period" fieldLabel="Period" class="flex-50">
          <kodit-pdv-period-dropdown
            formCtrlId="period"
            [formCtrl]="form.controls.period"
            [shouldDisable]="shouldDisableForm"
          ></kodit-pdv-period-dropdown>
        </kodit-field>
      </div>

      <div
        class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid"
        *ngIf="form.value.smer == promet.ULAZNI
        "
      >
        <kodit-field
          fieldLabelFor="osnovOdabira"
          fieldLabel="Osnov odabira dokumenta"
          class="flex-50"
        >
          <kodit-osnov-odabira-dokumenta-dropdown
            [formCtrl]="form.controls.osnovOdabiraDokumenta!"
            formCtrlId="osnov-odabira"
            (onItemChange)="onOsnovaOdabiraChange($event)"
            [shouldDisable]="shouldDisableForm"
          >
          </kodit-osnov-odabira-dokumenta-dropdown>
        </kodit-field>
      </div>

      <!-- <div
        class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid"
        *ngIf="
          form.controls.osnovOdabiraDokumenta?.value !== osnovOdabira.AVANS &&
          (form.controls.povezanaFaktura?.get('smer'))!.value == promet.ULAZNI
        "
      >
        <kodit-field
          fieldLabelFor="tipPovezanogInternog"
          fieldLabel="Povezani interni račun"
          class="flex-50"
        >
          <kodit-tip-povezanog-internog-dropdown
            [formCtrl]="form.controls.tipPovezanogInternog!"
            (onItemChange)="onTipPovezanogInternogChange($event)"
            formCtrlId="tip-povezanog-internog"
            [shouldDisable]="shouldDisableForm"
          >
          </kodit-tip-povezanog-internog-dropdown>
        </kodit-field>
      </div> -->
    </div>
  </kodit-card-container>
  <div class="p-mt-5">
    <kodit-card-container>
      <div card-title>Podaci stranke</div>

      <div
        card-body
        class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid"
      >
        <kodit-field
          fieldLabelFor="stranka"
          fieldLabel="Stranka"
          class="flex-50"
        >
          <kodit-stranka-for-pdv-autocomplete
            [strankaCtrl]="form.controls.stranka!"
            formCtrlId="stranka"
            [shouldDisable]="shouldDisableForm"
          ></kodit-stranka-for-pdv-autocomplete>
        </kodit-field>
        <kodit-field
          fieldLabelFor="stranka-id"
          fieldLabel="Identifikator lica kojem se vrši promet (PIB/ PIB i JBKJS/ Poreski broj stranog lica)"
          class="flex-50"
        >
          <kodit-text-input
            formCtrlId="stranka-id"
            [formCtrl]="form.controls.stranka!.get('pibOrJbkjs')!"
            [isDisabled]="shouldDisableForm"
          ></kodit-text-input>
        </kodit-field>
      </div>
    </kodit-card-container>
  </div>
  <div class="p-mt-5">
    <div>
      <kodit-card-container
        *ngIf="
          form.controls.tip?.value === tipDokumenta.AVANSNA_FAKTURA ||
          form.controls.osnovOdabiraDokumenta?.value === osnovOdabira.AVANS
        "
      >
        <div card-title>Podaci avansne uplate</div>
        <div card-body>
          <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
            <kodit-field
              fieldLabelFor="datum-avansa"
              fieldLabel="Datum avansa"
              class="flex-50"
            >
              <kodit-calendar
                formCtrlId="datum-avansa"
                [formCtrl]="form.controls.datumAvansa!"
                [disabled]="shouldDisableForm"
              ></kodit-calendar>
            </kodit-field>
            <kodit-field
              fieldLabel="Osnov za avansno plaćanje"
              fieldLabelFor="osnov-avansa"
              class="flex-50"
            >
              <kodit-text-input
                [formCtrl]="form.controls.osnovAvansnogPlacanja"
                [isDisabled]="isKoriguj || shouldDisableForm"
              ></kodit-text-input>
            </kodit-field>
          </div>
        </div>
      </kodit-card-container>
    </div>

    <kodit-card-container
      class="p-mt-5"
      *ngIf="
        form.controls.tip?.value !== tipDokumenta.AVANSNA_FAKTURA &&
        form.controls.osnovOdabiraDokumenta?.value !== osnovOdabira.AVANS
      "
    >
      <div card-title>Povezane fakture</div>

      <div card-body>
        <div>
          <!-- Tip povezanog dokumenta  -->
          <kodit-field
            *ngIf="_pdvPojedinacnaService.getIsIsporuka"
            fieldLabelFor="tip"
            fieldLabel="Tip povezane fakture"
            class="flex-50"
          >
            <kodit-tip-povezanog-dokumenta-dropdown
              [formCtrl]="form.controls.tipPovezaneFakture!"
              (onItemChange)="onTipPovezanogDokumentaChange($event)"
              formCtrlId="tip"
              [shouldDisable]="shouldDisableForm"
            >
            </kodit-tip-povezanog-dokumenta-dropdown>
          </kodit-field>

          <!-- Tip povezanog internog -->
          <kodit-field
            *ngIf="!_pdvPojedinacnaService.getIsIsporuka"
            fieldLabelFor="tipPovezanogInternog"
            fieldLabel="Povezani interni račun"
            class="flex-50"
          >
            <kodit-tip-povezanog-internog-dropdown
              [formCtrl]="form.controls.tipPovezanogInternog!"
              (onItemChange)="onTipPovezanogInternogChange($event)"
              formCtrlId="tip-povezanog-internog"
              [shouldDisable]="shouldDisableForm"
            >
            </kodit-tip-povezanog-internog-dropdown>
          </kodit-field>
        </div>
        <!-- Vremenski period -->
        <div
          class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid"
          *ngIf="
            form.controls.tipPovezaneFakture?.value ===
            tipPovezaneFakture.VREMENSKI_PERIOD
          "
        >
          <kodit-field
            fieldLabelFor="datum-pocetka"
            fieldLabel="Početak"
            class="flex-50"
          >
            <kodit-calendar
              formCtrlId="datum-pocetka"
              [formCtrl]="form.controls.vremenskiPeriodPocetak!"
              [disabled]="shouldDisableForm"
            ></kodit-calendar>
          </kodit-field>
          <kodit-field
            fieldLabelFor="datum-zavrsetka"
            fieldLabel="Završetak"
            class="flex-50"
          >
            <kodit-calendar
              formCtrlId="datum-zavrsetka"
              [formCtrl]="form.controls.vremenskiPeriodZavrsetak!"
              [disabled]="shouldDisableForm"
            ></kodit-calendar>
          </kodit-field>
        </div>
        <!-- Fakture sa SEF-a -->
        <div
          class="p-mt-5"
          *ngIf="
            (_pdvPojedinacnaService.getIsIsporuka &&
              form.controls.tipPovezaneFakture?.value !==
                tipPovezaneFakture.VREMENSKI_PERIOD &&
              form.controls.tipPovezaneFakture?.value !==
                tipPovezaneFakture.EMPTY) ||
            (!_pdvPojedinacnaService.getIsIsporuka &&
              form.controls.tipPovezanogInternog?.value !==
                tipPovezanogInternog.EMPTY)
          "
        >
          <h5>Fakture sa SEF-a</h5>
          <kodit-racun-autocomplete
            formCtrlId="izvorna-faktura"
            [includeTipoveRacuna]="useOnlyTipove!"
            [isUnosPojedinacneEvidencije]="true"
            (onChange)="handleVezaniRacunChange($event)"
            [shouldDisable]="shouldDisableForm"
          ></kodit-racun-autocomplete>
          <kodit-povezani-avansni-racuni
            formCtrlId="povezani-avansni"
            [form39]="form"
            [shouldDisable]="shouldDisableForm"
          >
          </kodit-povezani-avansni-racuni>
        </div>
        <!-- Fakture van SEF-a -->
        <div
          class="p-mt-5"
          *ngIf="
            (_pdvPojedinacnaService.getIsIsporuka &&
              form.controls.tipPovezaneFakture?.value !==
                tipPovezaneFakture.VREMENSKI_PERIOD &&
              form.controls.tipPovezaneFakture?.value !==
                tipPovezaneFakture.EMPTY) ||
            (!_pdvPojedinacnaService.getIsIsporuka &&
              form.controls.tipPovezanogInternog?.value !==
                tipPovezanogInternog.EMPTY)
          "
        >
          <h5>Fakture van SEF-a</h5>
          <div class="p-inputgroup" *ngIf="!shouldDisableForm">
            <input
              style="max-width: 13%"
              #avansniVanSefaInput
              type="text"
              pInputText
              placeholder="Broj fakture"
            />
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-plus"
              styleClass="p-button-warn"
              (click)="handleAvansniVanSefaClick()"
            ></button>
          </div>
          <kodit-povezani-avansni-racuni-van-sefa
            formCtrlId="povezani-avansni-van-sefa"
            [form]="form"
            [shouldDisable]="shouldDisableForm"
          >
          </kodit-povezani-avansni-racuni-van-sefa>
        </div>
      </div>
    </kodit-card-container>
  </div>

  <!-- Promet za isporuku -->
  <div class="p-mt-5" *ngIf="_pdvPojedinacnaService.getIsIsporuka">
    <kodit-card-container>
      <div card-title>Promet</div>
      <div card-body class="p-d-flex p-flex-row gap-7">
        <!-- Promet po stopi od 20%-S20 -->
        <div class="p-d-flex p-flex-column p-fluid">
          <div class="stopa-header">
            {{
              shouldLabelBeAvans
                ? 'Avans za promet po stopi od 20%-S20'
                : 'Promet po stopi od 20%-S20'
            }}
          </div>
          <!--Opis 20  -->
          <kodit-field
            fieldLabelFor="opis20"
            fieldLabel="Opis"
            class="flex-50 p-mt-4"
          >
            <kodit-textarea-input
              formCtrlId="opis20"
              [formCtrl]="form.controls.opisPrometa20"
              [isDisabled]="shouldDisableForm"
            ></kodit-textarea-input>
          </kodit-field>
          <!-- Osnovica 20 -->
          <kodit-field
            fieldLabelFor="osnovica20"
            fieldLabel="Osnovica"
            class="flex-50"
          >
            <kodit-number-input
              [isDisabled]="false"
              formCtrlId="osnovica20"
              [formCtrl]="form.controls.osnovica20"
              [isDisabled]="shouldDisableForm"
            ></kodit-number-input>
          </kodit-field>
          <!-- Obracunati PDV -->
          <kodit-field
            fieldLabelFor="obracunatPdv20"
            fieldLabel="Obračunati PDV"
            class="flex-50"
          >
            <kodit-number-input
              [isDisabled]="false"
              formCtrlId="obracunatPdv20"
              [formCtrl]="form.controls.obracunatPdv20"
              [isDisabled]="shouldDisableForm"
            ></kodit-number-input>
          </kodit-field>
        </div>
        <!-- Promet po stopi od 10%-S10 -->
        <div class="p-d-flex p-flex-column p-fluid">
          <div class="stopa-header">
            {{
              shouldLabelBeAvans
                ? 'Avans za promet po stopi od 10%-S10'
                : 'Promet po stopi od 10%-S10'
            }}
          </div>
          <!--Opis 10  -->
          <kodit-field
            fieldLabelFor="opis10"
            fieldLabel="Opis"
            class="flex-50 p-mt-4"
          >
            <kodit-textarea-input
              formCtrlId="opis10"
              [formCtrl]="form.controls.opisPrometa10"
              [isDisabled]="shouldDisableForm"
            ></kodit-textarea-input>
          </kodit-field>
          <!-- Osnovica 10 -->
          <kodit-field
            fieldLabelFor="osnovica10"
            fieldLabel="Osnovica"
            class="flex-50"
          >
            <kodit-number-input
              [isDisabled]="false"
              formCtrlId="osnovica10"
              [formCtrl]="form.controls.osnovica10"
              [isDisabled]="shouldDisableForm"
            ></kodit-number-input>
          </kodit-field>
          <!-- Obracunati PDV -->
          <kodit-field
            fieldLabelFor="obracunatPdv10"
            fieldLabel="Obračunati PDV"
            class="flex-50"
          >
            <kodit-number-input
              [isDisabled]="false"
              formCtrlId="obracunatPdv10"
              [formCtrl]="form.controls.obracunatPdv10"
              [isDisabled]="shouldDisableForm"
            ></kodit-number-input>
          </kodit-field>
        </div>
      </div>
    </kodit-card-container>
  </div>
  <!-- Promet za nabavku -->
  <div class="p-mt-5" *ngIf="!_pdvPojedinacnaService.getIsIsporuka">
    <kodit-card-container>
      <div card-title>Promet za naknadu</div>
      <div card-body class="p-d-flex p-flex-column">
        <!-- Stopa 20 -->
        <div class="p-d-flex p-flex-row gap-7 p-fluid">
          <!-- Promet po stopi od 20% - AE20, osim OBJ -->
          <div class="p-d-flex p-flex-column">
            <div class="stopa-header">
              {{
                shouldLabelBeAvans
                  ? 'Avans za promet po stopi od 20%-AE20, osim OBJ'
                  : 'Promet po stopi od 20%-AE20, osim OBJ'
              }}
            </div>
            <kodit-field
              fieldLabelFor="opisAE20"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisAE20"
                [formCtrl]="form.controls.opisPrometaAE20"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaAE20"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="osnovicaAE20"
                [formCtrl]="form.controls.osnovicaAE20"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatPdvAE20"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="obracunatPdvAE20"
                [formCtrl]="form.controls.obracunatPdvAE20"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet po stopi od 20%-AE20-OBJ -->
          <div class="p-d-flex p-flex-column">
            <div class="stopa-header">
              {{
                shouldLabelBeAvans
                  ? 'Avans za promet po stopi od 20%-AE20-OBJ'
                  : 'Promet po stopi od 20%-AE20-OBJ'
              }}
            </div>
            <kodit-field
              fieldLabelFor="opisAE20Obj"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisAE20Obj"
                [formCtrl]="form.controls.opisPrometaAE20Obj"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaAE20Obj"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="osnovicaAE20Obj"
                [formCtrl]="form.controls.osnovicaAE20Obj"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatPdvAE20Obj"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="obracunatPdvAE20Obj"
                [formCtrl]="form.controls.obracunatPdvAE20Obj"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet bez naknade po stopi od 20%-AE20, osim OBJ -->
          <div *ngIf="shouldDisplayNabavkaBN" class="p-d-flex p-flex-column">
            <div class="stopa-header">
              Promet bez naknade po stopi od 20%-AE20, osim OBJ
            </div>
            <kodit-field
              fieldLabelFor="opisAE20BN"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisAE20BN"
                [formCtrl]="form.controls.opisPrometaAE20BN"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaAE20BN"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="osnovicaAE20BN"
                [formCtrl]="form.controls.osnovicaAE20BN"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatPdvAE20BN"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="obracunatPdvAE20BN"
                [formCtrl]="form.controls.obracunatPdvAE20BN"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet bez naknade po stopi od 20%-AE20-OBJ -->
          <div *ngIf="shouldDisplayNabavkaBN" class="p-d-flex p-flex-column">
            <div class="stopa-header">
              Promet bez naknade po stopi od 20%-AE20-OBJ
            </div>
            <kodit-field
              fieldLabelFor="opisAE20ObjBN"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisAE20ObjBN"
                [formCtrl]="form.controls.opisPrometaAE20ObjBN"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaAE20ObjBN"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="osnovicaAE20ObjBN"
                [formCtrl]="form.controls.osnovicaAE20ObjBN"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatPdvAE20ObjBN"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="obracunatPdvAE20ObjBN"
                [formCtrl]="form.controls.obracunatPdvAE20ObjBN"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
        </div>
        <!-- Stopa 10 -->
        <div class="p-d-flex p-flex-row gap-7 p-fluid p-mt-3">
          <!-- Promet po stopi od 10% - AE10, osim OBJ -->
          <div class="p-d-flex p-flex-column">
            <div class="stopa-header">
              {{
                shouldLabelBeAvans
                  ? 'Avans za promet po stopi od 10%-AE10, osim OBJ'
                  : 'Promet po stopi od 10%-AE10, osim OBJ'
              }}
            </div>
            <kodit-field
              fieldLabelFor="opisAE10"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisAE10"
                [formCtrl]="form.controls.opisPrometaAE10"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaAE10"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="osnovicaAE10"
                [formCtrl]="form.controls.osnovicaAE10"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatPdvAE10"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="obracunatPdvAE10"
                [formCtrl]="form.controls.obracunatPdvAE10"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet po stopi od 10%-AE10-OBJ -->
          <div class="p-d-flex p-flex-column">
            <div class="stopa-header">
              {{
                shouldLabelBeAvans
                  ? 'Avans za promet po stopi od 10%-AE10-OBJ'
                  : 'Promet po stopi od 10%-AE10-OBJ'
              }}
            </div>
            <kodit-field
              fieldLabelFor="opisAE10Obj"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisAE10Obj"
                [formCtrl]="form.controls.opisPrometaAE10Obj"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaAE10Obj"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="osnovicaAE10Obj"
                [formCtrl]="form.controls.osnovicaAE10Obj"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatPdvAE10Obj"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="obracunatPdvAE10Obj"
                [formCtrl]="form.controls.obracunatPdvAE10Obj"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet bez naknade po stopi od 10%-AE10, osim OBJ -->
          <div *ngIf="shouldDisplayNabavkaBN" class="p-d-flex p-flex-column">
            <div class="stopa-header">
              Promet bez naknade po stopi od 10%-AE10, osim OBJ
            </div>
            <kodit-field
              fieldLabelFor="opisAE10BN"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisAE10BN"
                [formCtrl]="form.controls.opisPrometaAE10BN"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaAE10BN"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="osnovicaAE10BN"
                [formCtrl]="form.controls.osnovicaAE10BN"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatPdvAE10BN"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="obracunatPdvAE10BN"
                [formCtrl]="form.controls.obracunatPdvAE10BN"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!--  Promet bez naknade po stopi od 10%-AE10-OBJ -->
          <div *ngIf="shouldDisplayNabavkaBN" class="p-d-flex p-flex-column">
            <div class="stopa-header">
              Promet bez naknade po stopi od 10%-AE10-OBJ
            </div>
            <kodit-field
              fieldLabelFor="opisAE10ObjBN"
              fieldLabel="Opis"
              class="flex-50 p-mt-4"
            >
              <kodit-textarea-input
                formCtrlId="opisAE10ObjBN"
                [formCtrl]="form.controls.opisPrometaAE10ObjBN"
                [isDisabled]="shouldDisableForm"
              ></kodit-textarea-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="osnovicaAE10ObjBN"
              fieldLabel="Osnovica"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="osnovicaAE10ObjBN"
                [formCtrl]="form.controls.osnovicaAE10ObjBN"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="obracunatPdvAE10ObjBN"
              fieldLabel="Obračunati PDV"
              class="flex-50 p-mt-4"
            >
              <kodit-number-input
                [isDisabled]="false"
                formCtrlId="obracunatPdvAE10ObjBN"
                [formCtrl]="form.controls.obracunatPdvAE10ObjBN"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
        </div>
      </div>
    </kodit-card-container>
  </div>

  <div class="p-mt-5" card style="position: sticky; bottom: 0; z-index: 9">
    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-between">
      <div
        class="dodatne-akcije p-d-flex p-flex-column p-jc-around p-ml-3"
      ></div>
      <div class="primarne-akcije p-as-center p-d-flex gap-6">
        <ng-content></ng-content>
        <button
          *ngIf="shouldDisableForm"
          pButton
          pRipple
          type="button"
          label="Zatvori"
          class="p-button-secondary p-button-outlined"
          pTooltip="Zatvori"
          tooltipPosition="bottom"
          (click)="handleRouteBack()"
          [disabled]="false"
        ></button>
        <button
          *ngIf="!shouldDisableForm"
          pButton
          pRipple
          icon="far fa-window-close"
          type="button"
          label="Otkaži"
          class="p-button-secondary p-button-outlined"
          pTooltip="Otkaži PDV evidenciju"
          tooltipPosition="bottom"
          (click)="handleCancel()"
          [disabled]="false"
        ></button>
        <button
          *ngIf="!shouldDisableForm"
          pButton
          icon="fas fa-save"
          type="submit"
          label="Sačuvaj"
          class="p-button-success"
          pTooltip="Sačuvaj PDV evidenciju"
          tooltipPosition="bottom"
          (click)="handleSave()"
          [disabled]="false"
          [loading]="false"
        ></button>
      </div>
    </div>
  </div>
</div>
