import {Component, OnInit} from '@angular/core';
import {IVezaniRacunDto, StatusRacuna, TipRacuna, VrstaRacuna,} from '@kodit/core/data-api';
import {RacunService} from '../../../../../../services/src/lib/racun.service';
import {FormArray} from '@angular/forms';

@Component({
  selector: 'kodit-odabir-izvorne-fakture',
  templateUrl: './odabir-izvorne-fakture.component.html',
  styleUrls: ['./odabir-izvorne-fakture.component.scss'],
})
export class OdabirIzvorneFaktureComponent implements OnInit {
  useOnlyTipove: TipRacuna[];
  useOnlyStatuse: StatusRacuna[];
  jeIzmenaOsnovice : boolean;
  vezaniRacunArray: FormArray;

  constructor(private _racunService: RacunService) {
    this.useOnlyTipove =
      this._racunService.getVrstaRacuna === VrstaRacuna.ULAZNI
        ? [TipRacuna.ULAZNA_FAKTURA, TipRacuna.AVANSNI_RACUN]
        : [TipRacuna.IZLAZNA_FAKTURA, TipRacuna.AVANSNI_RACUN];

    this.jeIzmenaOsnovice =
      (this._racunService.getTipRacuna === TipRacuna.DOKUMENT_O_SMANJENJU ||
        this._racunService.getTipRacuna === TipRacuna.DOKUMENT_O_POVECANJU)

    this.useOnlyStatuse = [StatusRacuna.PRIHVACENO, StatusRacuna.NACRT, StatusRacuna.POSLATO];
  }

  ngOnInit(): void {
    this.vezaniRacunArray = this._racunService.getVezaniRacunArray;
  }

  handleVezaniRacunChange(vezaniRacun: IVezaniRacunDto) {
    this._racunService.setVezaniRacunArray = [vezaniRacun];
    this._racunService.setReferentniDokument = vezaniRacun.datumIzdavanja;
    this.setDatumValuteToDatumPrometa();
  }

  setDatumValuteToDatumPrometa(){
    const datumPrometa = this._racunService.getRacunFormObject.controls.datumPrometa.value;
    this._racunService.getRacunFormObject.controls.datumValute.patchValue(datumPrometa);
  }

  handleVezaniRacunClear() {
    this._racunService.clearVezaniRacun();
  }
}
