import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { JedinicaMereDto, JediniceMeraClient } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-jedinica-mere-dropdown',
  templateUrl: './jedinica-mere-dropdown.component.html',
  styleUrls: ['./jedinica-mere-dropdown.component.scss'],
})
export class JedinicaMereDropdownComponent implements OnInit, OnDestroy {
  /** Subs */
  private _getSub: Subscription = new Subscription();

  /** Props */
  options: SelectItem[] = [];
  jedinicaMereList: JedinicaMereDto[] = [];
  jedinicaMereCtrl: FormControl | AbstractControl;

  /** I/O */
  @Input() jedinicaMereGroup: FormGroup;
  @Input() jedinicaMereDefaultValue: string;
  @Input() formCtrlId: string;
  @Input() ctrlPlaceholder: string = 'Izaberite jedinicu mere';
  @Output() onChange = new EventEmitter<any>();

  constructor(private _client: JediniceMeraClient) {}

  ngOnInit(): void {
    this._load();
  }

  handleOnItemChange(event: any) {
    const jm = this.jedinicaMereList.find((x) => x.id === event.value);
    this.jedinicaMereGroup.controls.naziv.patchValue(jm.naziv);
    this.onChange.emit(event);
  }

  private _load() {
    this._getSub = this._client.getJedinicaMereDropdown().subscribe((res) => {
      this.jedinicaMereList = res.data.jedinicaMereDropdownDtoList;
      res.data.jedinicaMereDropdownDtoList.forEach((jm) => {
        this.options.push({
          value: jm.id,
          label: jm.naziv,
        });
      });

      this.jedinicaMereCtrl = this.jedinicaMereGroup.controls.id;
      if (this.jedinicaMereDefaultValue) {
        const jm = this.jedinicaMereList.find(
          (x) => x.naziv === this.jedinicaMereDefaultValue
        );
        this.jedinicaMereGroup.controls.naziv.patchValue(jm.naziv);
        this.jedinicaMereCtrl.patchValue(jm.id);
      }
    });
  }

  ngOnDestroy(): void {
    this._getSub.unsubscribe();
  }
}
