import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IGetUplateQuery } from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { UplateIsplateFilterService } from './uplate-isplate-filter.service';
import { FormHelper } from '@kodit/core/form-definitions';

@Injectable({
  providedIn: 'root',
})
export class UplateIsplateFormService {
  constructor(
    private _fb: FormBuilderTypeSafe, 
    private _service: UplateIsplateFilterService,
    private _formHelper: FormHelper) {}

  /**
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */

  public GetUplateIsplateFiliterFormGroup(
    model: IGetUplateQuery // | IGetIsplateQuery dodati
  ): FormGroupTypeSafe<IGetUplateQuery> {
    return this._fb.group<IGetUplateQuery>({
      kupac: new FormControl(model.kupac),
      pozivNaBroj: new FormControl(model.pozivNaBroj),
      svrhaUplate: new FormControl(model.svrhaUplate),
      statusi: this._service.GetStatusFormArray(model.statusi),
      mesec: new FormControl(model.mesec),
      datumIzdavanjaOd: this._formHelper.initializeDateFormControl(model.datumIzdavanjaOd),
      datumIzdavanjaDo: this._formHelper.initializeDateFormControl(model.datumIzdavanjaDo),
      izvodId: new FormControl(model.izvodId)
    });
  }
}
