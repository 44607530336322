import { Component, OnDestroy, OnInit } from '@angular/core';
import { IOznakaTreeDto, OznakeClient } from '@kodit/core/data-api';
import {AlertService, Mode} from '@kodit/core/services';
import { DynamicDialogService } from '@kodit/core/shared-ui';
import {
  DialogSize,
  DynamicDialogConfig,
  OnDynamicDialogInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { TreeNode, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { OznakaFormComponent } from '../oznaka-form/oznaka-form.component';

@Component({
  selector: 'kodit-oznaka-page',
  templateUrl: './oznaka-page.component.html',
  styleUrls: ['./oznaka-page.component.scss'],
})
export class OznakaPageComponent
  implements OnInit, OnDestroy, OnDynamicDialogInit {
  /** Subscription */
  private _subs: Subscription = new Subscription();

  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  oznake: TreeNode<IOznakaTreeDto>[] = [];
  emptyMessage: string = 'Nema oznaka u bazi';
  displayType: string = 'horizontal';
  displayTypes: SelectItem[];
  selectedOznaka: TreeNode;
  nodeId: number;

  /** Conditional props */
  editEnabled: boolean;
  deleteEnabled: boolean;

  constructor(
    private _client: OznakeClient,
    private _dialogService: DynamicDialogService,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._load();
    this.displayTypes = [
      { value: 'vertical', label: 'Vertikalan prikaz' },
      { value: 'horizontal', label: 'Horizontalan prikaz' },
    ];
  }

  openDialog(config: DynamicDialogConfig) {
    const ref = this._dialogService.open(OznakaFormComponent, config);

    this._subs.add(
      ref.onClose.subscribe((dto: IOznakaTreeDto) => {
        this.oznake = [];
        this._load();
      })
    );
  }

  nodeSelect(event: any) {
    this.editEnabled = true;
    this.deleteEnabled = true;
    this.nodeId = event.node.data.id;
  }

  nodeUnselect(event: any) {
    this.editEnabled = false;
    this.deleteEnabled = false;
    this.selectedOznaka = undefined;
    this.dialogConfig.data = null;
    this.nodeId = null;
  }

  expandAll() {
    this.oznake.forEach((node) => {
      this._expandRecursive(node, true);
    });
  }

  collapseAll() {
    this.oznake.forEach((node) => {
      this._expandRecursive(node, false);
    });
  }

  onCreate() {
    this.dialogConfig.header = 'Unos oznake';
    this.dialogConfig.data = { mode: Mode.NEW, id: this.nodeId };
    this.openDialog(this.dialogConfig);
  }

  onEdit() {
    this.dialogConfig.header = 'Izmena oznake';
    this.dialogConfig.data = { mode: Mode.EDIT, id: this.nodeId };
    this.openDialog(this.dialogConfig);
  }

  onDelete() {
    this._subs.add(
      this._client.delete(31).subscribe(
        (res) => {
          this._alertService.addSuccessMsg(res.messages[0]);
        },
        (error) => {
          this._subs.add(
            error.subscribe((x: string) => {
              this._alertService.addFailedMsg(x);
            })
          );
        }
      )
    );
  }

  private _load() {
    this._subs.add(
      this._client.getForTree().subscribe(
        (result) => {
          const temp: TreeNode<IOznakaTreeDto>[] = [];
          result.oznakeTree.forEach((o) => temp.push(this.setOznakeTree(o)));
          this.oznake.push({
            label: 'Početak',
            children: temp,
            icon: 'fas fa-hashtag',
            expanded: true,
            selectable: false,
          });
          this.nodeId = null;
        },
        (error) => {
          this._subs.add(
            error.subscribe((x: string) => {
              this._alertService.addFailedMsg(x);
            })
          );
        }
      )
    );
  }

  /**
   * Rekurzivna fukncija koja postavlja oznake za prikaz u tree komponentu
   */
  private setOznakeTree(oznakaTree: IOznakaTreeDto): TreeNode<IOznakaTreeDto> {
    let result: TreeNode<IOznakaTreeDto> = {
      label: oznakaTree.naziv,
      children: [],
      data: oznakaTree,
      selectable: true,
    };

    if (oznakaTree.childrenDto.length > 0) {
      result.icon = 'fas fa-tags';
      oznakaTree.childrenDto.forEach((c) =>
        result.children.push(this.setOznakeTree(c))
      );
    } else {
      result.icon = 'fas fa-tag';
    }

    return result;
  }

  private _expandRecursive(node: TreeNode, isExpand: boolean) {
    if (node.children?.length > 0) {
      node.expanded = isExpand;
      node.children.forEach((childNode) => {
        this._expandRecursive(childNode, isExpand);
      });
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
