import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CreatePraviloZaBrojCommand,
  IKonfiguracijaBrojaRacunaDto,
  RacuniClient,
  TipRacuna,
  UpdatePraviloZaBrojCommand,
} from '@kodit/core/data-api';
import {
  BrojRacunaFormService,
  FormHelper,
} from '@kodit/core/form-definitions';
import { AlertService, Mode } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-pravila-za-broj-form',
  templateUrl: './pravila-za-broj-form.component.html',
  styleUrls: ['./pravila-za-broj-form.component.scss'],
})
export class PravilaZaBrojFormComponent implements OnInit, OnDestroy {
  /** Props */
  form: FormGroupTypeSafe<IKonfiguracijaBrojaRacunaDto>;
  mode: Mode = Mode.NEW;
  currentYear: any = new Date().getFullYear();
  shouldInputBeDisabled: boolean = false;
  tipoviRacuna: TipRacuna[] = []; //! ukoliko je [] vraca nam sve tipove sa back-a
  private _subs: Subscription = new Subscription();

  constructor(
    private _fs: BrojRacunaFormService,
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _client: RacuniClient,
    private _formHelper: FormHelper
  ) {}

  ngOnInit(): void {
    this.form = this._fs.GetBrojRacunaFormGroup();
    if (this._dialogConfig?.data?.id) {
      this._load(this._dialogConfig.data.id);
      this.mode = Mode.EDIT;
      this.shouldInputBeDisabled = !this._dialogConfig?.data?.editable;
    }

    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.save();
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  private save() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    if (this.mode === Mode.EDIT) {
      this._subs.add(
        this._client
          .updatePraviloZaBroj(
            this.form.value as UpdatePraviloZaBrojCommand,
            this.form.value.id
          )
          .subscribe((result) => {
            if (result.data && result.succeeded) {
              this._alertService.addSuccessMsg(result.messages[0]);
            } else {
              this._alertService.addWarnMsg(result.messages[0]);
            }
            this._dialogRef.close(result);
          })
      );
    } else {
      this._subs.add(
        this._client
          .createPraviloZaBroj(this.form.value as CreatePraviloZaBrojCommand)
          .subscribe((result) => {
            if (result.succeeded) {
              this._alertService.addSuccessMsg(result.messages[0]);
              this._dialogRef.close(result);
            } else {
              this._alertService.addWarnMsg(result.messages[0]);
            }
          })
      );
    }
  }

  private _load(id: number) {
    this._subs.add(
      this._client.getPravilaZaBrojKonfiguracija(id).subscribe((model) => {
        this.form.patchValue(model.data.konfiguracijeBrojaRacunaDto[0]);
      })
    );
  }
}
