<div>
  <div class="p-text-left">
    Preuzmite sledeći šablon, popunite ga, a zatim ga otpremite:
  </div>
  <div class="p-text-left p-mt-3">
    <button
      pButton
      pRipple
      type="button"
      icon="fas fa-download"
      class="p-button-text"
      label="Usluge šablon"
      (click)="downloadTemplate()"
    ></button>
  </div>
  <div class="p-mt-5">
    <kodit-file-upload
      (onUpload)="onUpload($event)"
      [uploadCompleted]="uploadCompleted"
    ></kodit-file-upload>
  </div>
</div>
