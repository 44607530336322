import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'kodit-info-container',
  templateUrl: './info-container.component.html',
  styleUrls: ['./info-container.component.scss'],
})
export class InfoContainerComponent implements OnInit, AfterViewInit {
  /** Props */
  @ViewChild('col12Ref') col12Ref: any;
  showCol12: boolean;

  @ViewChild('col6Left') col6Left: any;
  showCol6Left: boolean;

  @ViewChild('col6Right') col6Right: any;
  showCol6Right: boolean;

  @ViewChild('col8Ref') col8Ref: any;
  showCol8: boolean;

  @ViewChild('col4Ref') col4Ref: any;
  showCol4: boolean;

  @ViewChild('col8ReverseRef') col8ReverseRef: any;
  showCol8Reverse: boolean;

  @ViewChild('col4ReverseRef') col4ReverseRef: any;
  showCol4Reverse: boolean;

  constructor(private _cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.showCol12 =
      this.col12Ref?.nativeElement &&
      this.col12Ref?.nativeElement.children.length > 0;

    this.showCol6Left =
      this.col6Left?.nativeElement &&
      this.col6Left?.nativeElement.children.length > 0;

    this.showCol6Right =
      this.col6Right?.nativeElement &&
      this.col6Right?.nativeElement.children.length > 0;

    this.showCol8 =
      this.col8Ref?.nativeElement &&
      this.col8Ref?.nativeElement.children.length > 0;

    this.showCol4 =
      this.col4Ref?.nativeElement &&
      this.col4Ref?.nativeElement.children.length > 0;

    this.showCol8Reverse =
      this.col8ReverseRef?.nativeElement &&
      this.col8ReverseRef?.nativeElement.children.length > 0;

    this.showCol4Reverse =
      this.col4ReverseRef?.nativeElement &&
      this.col4ReverseRef?.nativeElement.children.length > 0;

    this._cdRef.detectChanges();
  }
}
