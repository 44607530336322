import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DelovodnikPageComponent } from './delovodnik-page/delovodnik-page.component';
import { DelovodnikRoutingModule } from './delovodnik-routing';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreSharedModule } from '@kodit/core/shared';
import { CoreSharedSubformsModule } from '@kodit/core/shared-subforms';
import { PodesavanjeRoutingModule } from 'libs/core/moduli/podesavanje/src/lib/moduli-podesavanje-routing.module';
import { CoreSharedUiModule } from '@kodit/core/shared-ui';
import { CreateDelovodnikFormComponent } from './create-delovodnik-form/create-delovodnik-form.component';
import { DokumentarnaKategorijaAutocompleteComponent } from './dokumentarna-kategorija-autocomplete/dokumentarna-kategorija-autocomplete.component';
import { PraviloZaBrojDelovodnogDokumentaDropdownComponent } from './pravilo-za-broj-delovodnog-dokumenta-dropdown/pravilo-za-broj-delovodnog-dokumenta-dropdown.component';
import { DelovodnikFilterComponent } from './delovodnik-filter/delovodnik-filter.component';
import { UpdateDelovodnikFormComponent } from './update-delovodnik-form/update-delovodnik-form.component';
import { DelovodnikInfoComponent } from './delovodnik-info/delovodnik-info.component';
import { DelovodniDokumentFormComponent } from './delovodni-dokument-form/delovodni-dokument-form.component';
import { DelovodniDokumentTableComponent } from './delovodnik-info/delovodni-dokument-table/delovodni-dokument-table.component';
import { CoreModuliRacunModule } from '@kodit/core/moduli/racun';
import { PosaljilacDokumentaAutocompleteComponent } from './delovodni-dokument-form/posaljilac-dokumenta-autocomplete/posaljilac-dokumenta-autocomplete.component';
import { DelovodniDokumentPrilogPreviewComponent } from './delovodnik-info/delovodni-dokument-prilog-preview/delovodni-dokument-prilog-preview.component';

@NgModule({
  imports: [
    CommonModule,
    DelovodnikRoutingModule,
    ReactiveFormsModule,
    CoreSharedModule,
    CoreSharedUiModule,
    PodesavanjeRoutingModule,
    CoreSharedSubformsModule,
    CoreModuliRacunModule
  ],
  declarations: [DelovodnikPageComponent, CreateDelovodnikFormComponent, DokumentarnaKategorijaAutocompleteComponent, PraviloZaBrojDelovodnogDokumentaDropdownComponent, DelovodnikFilterComponent, UpdateDelovodnikFormComponent, DelovodnikInfoComponent, DelovodniDokumentFormComponent, DelovodniDokumentTableComponent, PosaljilacDokumentaAutocompleteComponent, DelovodniDokumentPrilogPreviewComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModuliDelovodnikModule {}
