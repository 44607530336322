<div [formGroup]="form">
  <div class="p-mt-5">
    <kodit-card-container>
      <div card-body class="p-d-flex p-flex-column">
        <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
          <kodit-field
            fieldLabel="Broj Zbirne evidencije PDV"
            fieldLabelFor="pravilo-za-broj"
            class="flex-50"
          >
            <kodit-text-input
              [formCtrl]="form.controls.broj"
              [isDisabled]="shouldDisableForm"
            ></kodit-text-input>
          </kodit-field>

          <kodit-field
            fieldLabel="Godina"
            fieldLabelFor="godina"
            class="flex-50"
          >
            <kodit-number-input
              formCtrlId="godina"
              [formCtrl]="form.controls.godina"
              [ctrlMaxLength]="4"
              [ctrlMinValue]="1900"
              [ctrlOnlyIntegers]="true"
              [ctrlUseGrouping]="false"
              [isDisabled]="shouldDisableForm"
            ></kodit-number-input>
          </kodit-field>
          <!-- <kodit-field
            fieldLabel="Status"
            fieldLabelFor="status"
            class="flex-50"
          >
            <kodit-text-input
            ></kodit-text-input>
          </kodit-field> -->
          <kodit-field
            fieldLabelFor="datum-promene-statusa"
            fieldLabel="Datum evidentiranja"
            class="flex-50"
          >
            <kodit-calendar
              formCtrlId="datum-promene-statusa"
              [formCtrl]="form.controls.datumPromeneStatusa!"
              [disabled]="true"
            ></kodit-calendar>
          </kodit-field>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
          <kodit-field
            fieldLabelFor="vremenski-opseg"
            fieldLabel="Poreski period"
            class="flex-50"
          >
            <kodit-pdv-opseg-dropdown
              formCtrlId="vremenski-opseg"
              [periodValue]="form.value.period!"
              [shouldDisable]="shouldDisableForm"
            ></kodit-pdv-opseg-dropdown>
          </kodit-field>
          <kodit-field
            fieldLabelFor="period"
            fieldLabel="Period"
            class="flex-50"
          >
            <kodit-pdv-period-dropdown
              formCtrlId="period"
              [formCtrl]="form.controls.period"
              [shouldDisable]="shouldDisableForm"
            ></kodit-pdv-period-dropdown>
          </kodit-field>
        </div>
      </div>
    </kodit-card-container>
  </div>
  <div class="p-d-flex p-flex-column">
    <p-accordion [multiple]="true">
      <p-accordionTab header="Avans za promet">
        <div class="p-d-flex p-flex-column gap-8">
          <div
            class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid p-justify-between p-align-center"
          >
            <div class="p-d-flex">Avans za promet po stopi od 20%</div>
            <kodit-field
              fieldLabelFor="avans-promet-iznos-20"
              fieldLabel="Iznos avansne uplate"
            >
              <kodit-number-input
                formCtrlId="avans-promet-iznos-20"
                [formCtrl]="form.controls.avansIznosUplate20"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="avans-promet-osnovica-20"
              fieldLabel="Osnovica"
            >
              <kodit-number-input
                formCtrlId="avans-promet-osnovica-20"
                [formCtrl]="form.controls.avansOsnovica20"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="avans-promet-obracunati-pdv-20"
              fieldLabel="
              Obračunati
              PDV"
            >
              <kodit-number-input
                formCtrlId="avans-promet-obracunati-pdv-20"
                [formCtrl]="form.controls.avansObracunatiPdv20"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <div
            class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid p-justify-between p-align-center"
          >
            <div>Avans za promet po stopi od 10%</div>
            <kodit-field
              fieldLabelFor="avans-promet-iznos-10"
              fieldLabel="Iznos avansne uplate"
            >
              <kodit-number-input
                formCtrlId="avans-promet-iznos-10"
                [formCtrl]="form.controls.avansIznosUplate10"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="avans-promet-osnovica-10"
              fieldLabel="Osnovica"
            >
              <kodit-number-input
                formCtrlId="avans-promet-osnovica-10"
                [formCtrl]="form.controls.avansOsnovica10"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="avans-promet-obracunati-pdv-10"
              fieldLabel="
              Obračunati
              PDV"
            >
              <kodit-number-input
                formCtrlId="avans-promet-obracunati-pdv-10"
                [formCtrl]="form.controls.avansObracunatiPdv10"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </kodit-field>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab header="Promet uz naknadu">
        <div class="p-d-flex p-flex-column gap-8">
          <div
            class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid p-justify-between p-align-center"
          >
            <div class="p-d-flex">Promet po stopi od 20%</div>
            <kodit-field
              fieldLabelFor="promet-uz-naknadu-osnovica-20"
              fieldLabel="Osnovica"
            >
              <kodit-number-input
                formCtrlId="promet-uz-naknadu-osnovica-20"
                [formCtrl]="form.controls.prometSaNaknadomOsnovica20"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="promet-uz-naknadu-obracunati-pdv-20"
              fieldLabel="Obračunati PDV"
            >
              <kodit-number-input
                formCtrlId="promet-uz-naknadu-obracunati-pdv-20"
                [formCtrl]="form.controls.prometSaNaknadomObracunatiPdv20"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <div
            class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid p-justify-between p-align-center"
          >
            <div>Promet po stopi od 10%</div>
            <kodit-field
              fieldLabelFor="promet-uz-naknadu-osnovica-10"
              fieldLabel="Osnovica"
            >
              <kodit-number-input
                formCtrlId="promet-uz-naknadu-osnovica-10"
                [formCtrl]="form.controls.prometSaNaknadomOsnovica10"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="promet-uz-naknadu-obracunati-pdv-10"
              fieldLabel="Obračunati PDV"
            >
              <kodit-number-input
                formCtrlId="promet-uz-naknadu-obracunati-pdv-10"
                [formCtrl]="form.controls.prometSaNaknadomObracunatiPdv10"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </kodit-field>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab header="Promet bez naknade">
        <div class="p-d-flex p-flex-column gap-8">
          <div
            class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid p-justify-between p-align-center"
          >
            <div class="p-d-flex">Promet po stopi od 20%</div>
            <kodit-field
              fieldLabelFor="promet-bez-naknade-osnovica-20"
              fieldLabel="Osnovica"
            >
              <kodit-number-input
                formCtrlId="promet-bez-naknade-osnovica-20"
                [formCtrl]="form.controls.prometBezNaknadeOsnovica20"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="promet-bez-naknade-obracunati-pdv-20"
              fieldLabel="Obračunati PDV"
            >
              <kodit-number-input
                formCtrlId="promet-bez-naknade-obracunati-pdv-20"
                [formCtrl]="form.controls.prometBezNaknadeObracunatiPdv20"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <div
            class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid p-justify-between p-align-center"
          >
            <div>Promet po stopi od 10%</div>
            <kodit-field
              fieldLabelFor="promet-bez-naknade-osnovica-10"
              fieldLabel="Osnovica"
            >
              <kodit-number-input
                formCtrlId="promet-bez-naknade-osnovica-10"
                [formCtrl]="form.controls.prometBezNaknadeOsnovica10"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </kodit-field>
            <kodit-field
              fieldLabelFor="promet-bez-naknade-obracunati-pdv-10"
              fieldLabel="Obračunati PDV"
            >
              <kodit-number-input
                formCtrlId="promet-bez-naknade-obracunati-pdv-10"
                [formCtrl]="form.controls.prometBezNaknadeObracunatiPdv10"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </kodit-field>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab header="Posebni postupci oporezivanja">
        <div class="p-d-flex p-flex-column gap-12">
          <!-- Turisticki -->
          <div class="p-d-flex p-flex-column gap-8">
            <div><b>Turističke usluge</b></div>
            <hr />
            <div class="p-d-flex gap-12 p-fluid p-justify-between">
              <kodit-field
                fieldLabelFor="tur-osnovica-20"
                fieldLabel="Osnovica za promet po stopi od 20%"
              >
                <kodit-number-input
                  formCtrlId="tur-osnovica-20"
                  [formCtrl]="form.controls.osnovica20Tur"
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="tur-obracunati-pdv-20"
                fieldLabel="Obračunati PDV za promet po stopi od 20%"
              >
                <kodit-number-input
                  formCtrlId="tur-obracunati-pdv-20"
                  [formCtrl]="form.controls.obracunatiPdv20Tur"
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="tur-avans-osnovica-20"
                fieldLabel="Osnovica - avans po stopi od 20%"
              >
                <kodit-number-input
                  formCtrlId="tur-avans-osnovica-20"
                  [formCtrl]="form.controls.avansOsnovica20Tur"
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="tur-avans-obracunati-pdv-20"
                fieldLabel="Obračunati PDV - avans po stopi od 20%"
              >
                <kodit-number-input
                  formCtrlId="tur-avans-obracunati-pdv-20"
                  [formCtrl]="form.controls.avansObracunatiPdv20Tur"
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
            </div>
          </div>
          <!-- Polovna dobra, umetnička dela, kolekcionarska dobra i antikviteti -->
          <div class="p-d-flex p-flex-column gap-8">
            <div>
              <b
                >Polovna dobra, umetnička dela, kolekcionarska dobra i
                antikviteti</b
              >
            </div>
            <hr />
            <div class="p-d-flex p-flex-column">
              <div class="p-d-flex gap-12 p-fluid p-justify-between">
                <kodit-field
                  fieldLabelFor="art-osnovica-20"
                  fieldLabel="Osnovica za promet po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="art-osnovica-20"
                    [formCtrl]="form.controls.osnovica20Art"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="art-obracunati-pdv-20"
                  fieldLabel="Obračunati PDV za promet po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="art-obracunati-pdv-20"
                    [formCtrl]="form.controls.obracunatiPdv20Art"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="art-avans-osnovica-20"
                  fieldLabel="Osnovica - avans po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="art-avans-osnovica-20"
                    [formCtrl]="form.controls.avansOsnovica20Art"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="art-avans-obracunati-pdv-20"
                  fieldLabel="Obračunati PDV - avans po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="art-avans-obracunati-pdv-20"
                    [formCtrl]="form.controls.avansObracunatiPdv20Art"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
              </div>
              <div class="p-d-flex gap-12 p-fluid p-justify-between">
                <kodit-field
                  fieldLabelFor="art-osnovica-10"
                  fieldLabel="Osnovica za promet po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="art-osnovica-10"
                    [formCtrl]="form.controls.osnovica10Art"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="art-obracunati-pdv-10"
                  fieldLabel="Obračunati PDV za promet po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="art-obracunati-pdv-10"
                    [formCtrl]="form.controls.obracunatiPdv10Art"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="art-avans-osnovica-10"
                  fieldLabel="Osnovica - avans po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="art-avans-osnovica-10"
                    [formCtrl]="form.controls.avansOsnovica10Art"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="art-avans-obracunati-pdv-10"
                  fieldLabel="Obračunati PDV - avans po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="art-avans-obracunati-pdv-10"
                    [formCtrl]="form.controls.avansObracunatiPdv10Art"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
              </div>
            </div>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab
        header="Povećanje/smanjenje osnovice, odnosno PDV - poreski dužnik isporučilac"
      >
        <div class="p-d-flex p-flex-column gap-12">
          <!-- Povecanje -->
          <div class="p-d-flex p-flex-column gap-8">
            <div><b>Povećanje osnovice, odnosno PDV</b></div>
            <hr />
            <div class="p-d-flex gap-12 p-fluid p-justify-between">
              <kodit-field
                fieldLabelFor="povecanje-isporucilac-osnovica-20"
                fieldLabel="Povećanje osnovice za promet po stopi od 20%"
              >
                <kodit-number-input
                  formCtrlId="povecanje-isporucilac-osnovica-20"
                  [formCtrl]="
                    form.controls.povecanjeSmanjenjeIsporucilac!.get(
                      'povecanjeOsnovica20'
                    )!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="povecanje-isporucilac-obracunati-pdv-20"
                fieldLabel="Povećanje PDV za promet po stopi od 20%"
              >
                <kodit-number-input
                  formCtrlId="povecanje-isporucilac-obracunati-pdv-20"
                  [formCtrl]="
                    form.controls.povecanjeSmanjenjeIsporucilac!.get(
                      'povecanjePdv20'
                    )!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="povecanje-isporucilac-osnovica-10"
                fieldLabel="Povećanje osnovice za promet po stopi od 10%"
              >
                <kodit-number-input
                  formCtrlId="povecanje-isporucilac-osnovica-10"
                  [formCtrl]="
                    form.controls.povecanjeSmanjenjeIsporucilac!.get(
                      'povecanjeOsnovica10'
                    )!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="povecanje-isporucilac-obracunati-pdv-10"
                fieldLabel="Povećanje PDV za promet po stopi od 10%"
              >
                <kodit-number-input
                  formCtrlId="povecanje-isporucilac-obracunati-pdv-10"
                  [formCtrl]="
                    form.controls.povecanjeSmanjenjeIsporucilac!.get(
                      'povecanjePdv10'
                    )!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
            </div>
          </div>
          <!-- Smanjenje -->
          <div class="p-d-flex p-flex-column gap-8">
            <div>
              <b>Smanjenje osnovice, odnosno PDV</b>
            </div>
            <hr />
            <div class="p-d-flex p-flex-column">
              <div class="p-d-flex gap-12 p-fluid p-justify-between">
                <kodit-field
                  fieldLabelFor="smanjenje-isporucilac-osnovica-20"
                  fieldLabel="Smanjenje osnovice za promet po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-isporucilac-osnovica-20"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjeIsporucilac!.get(
                        'smanjenjeOsnovica20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="smanjenje-isporucilac-obracunati-pdv-20"
                  fieldLabel="Smanjenje PDV za promet po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-isporucilac-obracunati-pdv-20"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjeIsporucilac!.get(
                        'smanjenjePdv20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="smanjenje-isporucilac-osnovica-10"
                  fieldLabel="Smanjenje osnovice za promet po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-isporucilac-osnovica-10"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjeIsporucilac!.get(
                        'smanjenjeOsnovica10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="smanjenje-isporucilac-obracunati-pdv-10"
                  fieldLabel="Smanjenje PDV za promet po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-isporucilac-obracunati-pdv-10"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjeIsporucilac!.get(
                        'smanjenjePdv10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
              </div>
              <div class="p-d-flex gap-12 p-fluid p-justify-between">
                <kodit-field
                  fieldLabelFor="smanjenje-isporucilac-avans-osnovica-20"
                  fieldLabel="Smanjenje osnovice - avans po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-isporucilac-avans-osnovica-20"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjeIsporucilac!.get(
                        'smanjenjeAvansOsnovica20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="smanjenje-isporucilac-avans-obracunati-pdv-20"
                  fieldLabel="Smanjenje PDV - avans po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-isporucilac-avans-obracunati-pdv-20"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjeIsporucilac!.get(
                        'smanjenjeAvansPdv20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="smanjenje-isporucilac-avans-osnovica-10"
                  fieldLabel="Smanjenje osnovice - avans po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-isporucilac-avans-osnovica-10"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjeIsporucilac!.get(
                        'smanjenjeAvansOsnovica10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="smanjenje-isporucilac-avans-obracunati-pdv-10"
                  fieldLabel="Smanjenje PDV - avans po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-isporucilac-avans-obracunati-pdv-10"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjeIsporucilac!.get(
                        'smanjenjeAvansPdv10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
              </div>
            </div>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab
        header="Povećanje/smanjenje osnovice, odnosno PDV - poreski dužnik primalac"
      >
        <div class="p-d-flex p-flex-column gap-12">
          <!-- Povecanje -->
          <div class="p-d-flex p-flex-column gap-8">
            <div><b>Povećanje osnovice, odnosno PDV</b></div>
            <hr />
            <div class="p-d-flex gap-12 p-fluid p-justify-between">
              <kodit-field
                fieldLabelFor="povecanje-primalac-osnovica-20"
                fieldLabel="Povećanje osnovice za promet po stopi od 20%"
              >
                <kodit-number-input
                  formCtrlId="povecanje-primalac-osnovica-20"
                  [formCtrl]="
                    form.controls.povecanjeSmanjenjePrimalac!.get(
                      'povecanjeOsnovica20'
                    )!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="povecanje-primalac-obracunati-pdv-20"
                fieldLabel="Povećanje PDV za promet po stopi od 20%"
              >
                <kodit-number-input
                  formCtrlId="povecanje-primalac-obracunati-pdv-20"
                  [formCtrl]="
                    form.controls.povecanjeSmanjenjePrimalac!.get(
                      'povecanjePdv20'
                    )!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="povecanje-primalac-osnovica-10"
                fieldLabel="Povećanje osnovice za promet po stopi od 10%"
              >
                <kodit-number-input
                  formCtrlId="povecanje-primalac-osnovica-10"
                  [formCtrl]="
                    form.controls.povecanjeSmanjenjePrimalac!.get(
                      'povecanjeOsnovica10'
                    )!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="povecanje-primalac-obracunati-pdv-10"
                fieldLabel="Povećanje PDV za promet po stopi od 10%"
              >
                <kodit-number-input
                  formCtrlId="povecanje-primalac-obracunati-pdv-10"
                  [formCtrl]="
                    form.controls.povecanjeSmanjenjePrimalac!.get(
                      'povecanjePdv10'
                    )!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
            </div>
          </div>
          <!-- Smanjenje -->
          <div class="p-d-flex p-flex-column gap-8">
            <div>
              <b>Smanjenje osnovice, odnosno PDV</b>
            </div>
            <hr />
            <div class="p-d-flex p-flex-column">
              <div class="p-d-flex gap-12 p-fluid p-justify-between">
                <kodit-field
                  fieldLabelFor="smanjenje-primalac-osnovica-20"
                  fieldLabel="Smanjenje osnovice za promet po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-primalac-osnovica-20"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjePrimalac!.get(
                        'smanjenjeOsnovica20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="smanjenje-primalac-obracunati-pdv-20"
                  fieldLabel="Smanjenje PDV za promet po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-primalac-obracunati-pdv-20"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjePrimalac!.get(
                        'smanjenjePdv20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="smanjenje-primalac-osnovica-10"
                  fieldLabel="Smanjenje osnovice za promet po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-primalac-osnovica-10"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjePrimalac!.get(
                        'smanjenjeOsnovica10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="smanjenje-primalac-obracunati-pdv-10"
                  fieldLabel="Smanjenje PDV za promet po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-primalac-obracunati-pdv-10"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjePrimalac!.get(
                        'smanjenjePdv10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
              </div>
              <div class="p-d-flex gap-12 p-fluid p-justify-between">
                <kodit-field
                  fieldLabelFor="smanjenje-primalac-avans-osnovica-20"
                  fieldLabel="Smanjenje osnovice - avans po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-primalac-avans-osnovica-20"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjePrimalac!.get(
                        'smanjenjeAvansOsnovica20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="smanjenje-primalac-avans-obracunati-pdv-20"
                  fieldLabel="Smanjenje PDV - avans po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-primalac-avans-obracunati-pdv-20"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjePrimalac!.get(
                        'smanjenjeAvansPdv20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="smanjenje-primalac-avans-osnovica-10"
                  fieldLabel="Smanjenje osnovice - avans po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-primalac-avans-osnovica-10"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjePrimalac!.get(
                        'smanjenjeAvansOsnovica10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="smanjenje-primalac-avans-obracunati-pdv-10"
                  fieldLabel="Smanjenje PDV - avans po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="smanjenje-primalac-avans-obracunati-pdv-10"
                    [formCtrl]="
                      form.controls.povecanjeSmanjenjePrimalac!.get(
                        'smanjenjeAvansPdv10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
              </div>
            </div>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab header="Storniranje - poreski dužnik isporučilac">
        <div class="p-d-flex p-flex-column gap-12">
          <!-- Povecanje -->
          <div class="p-d-flex p-flex-column gap-8">
            <div><b>Povećanje osnovice, odnosno PDV</b></div>
            <hr />
            <div class="p-d-flex gap-12 p-fluid p-justify-between">
              <kodit-field
                fieldLabelFor="storniranje-povecanje-isporucilac-osnovica-20"
                fieldLabel="Povećanje osnovice po stopi od 20%"
              >
                <kodit-number-input
                  formCtrlId="storniranje-povecanje-isporucilac-osnovica-20"
                  [formCtrl]="
                    form.controls.storniranjeIsporucilac!.get(
                      'povecanjeOsnovica20'
                    )!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="storniranje-povecanje-isporucilac-obracunati-pdv-20"
                fieldLabel="Povećanje PDV po stopi od 20%"
              >
                <kodit-number-input
                  formCtrlId="storniranje-povecanje-isporucilac-obracunati-pdv-20"
                  [formCtrl]="
                    form.controls.storniranjeIsporucilac!.get('povecanjePdv20')!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="storniranje-povecanje-isporucilac-osnovica-10"
                fieldLabel="Povećanje osnovice po stopi od 10%"
              >
                <kodit-number-input
                  formCtrlId="storniranje-povecanje-isporucilac-osnovica-10"
                  [formCtrl]="
                    form.controls.storniranjeIsporucilac!.get(
                      'povecanjeOsnovica10'
                    )!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="storniranje-povecanje-isporucilac-obracunati-pdv-10"
                fieldLabel="Povećanje PDV po stopi od 10%"
              >
                <kodit-number-input
                  formCtrlId="storniranje-povecanje-isporucilac-obracunati-pdv-10"
                  [formCtrl]="
                    form.controls.storniranjeIsporucilac!.get('povecanjePdv10')!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
            </div>
          </div>
          <!-- Smanjenje -->
          <div class="p-d-flex p-flex-column gap-8">
            <div>
              <b>Smanjenje osnovice, odnosno PDV</b>
            </div>
            <hr />
            <div class="p-d-flex p-flex-column">
              <div class="p-d-flex gap-12 p-fluid p-justify-between">
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-isporucilac-osnovica-20"
                  fieldLabel="Smanjenje osnovice za promet po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-isporucilac-osnovica-20"
                    [formCtrl]="
                      form.controls.storniranjeIsporucilac!.get(
                        'smanjenjeOsnovica20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-isporucilac-obracunati-pdv-20"
                  fieldLabel="Smanjenje PDV za promet po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-isporucilac-obracunati-pdv-20"
                    [formCtrl]="
                      form.controls.storniranjeIsporucilac!.get(
                        'smanjenjePdv20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-isporucilac-osnovica-10"
                  fieldLabel="Smanjenje osnovice za promet po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-isporucilac-osnovica-10"
                    [formCtrl]="
                      form.controls.storniranjeIsporucilac!.get(
                        'smanjenjeOsnovica10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-isporucilac-obracunati-pdv-10"
                  fieldLabel="Smanjenje PDV za promet po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-isporucilac-obracunati-pdv-10"
                    [formCtrl]="
                      form.controls.storniranjeIsporucilac!.get(
                        'smanjenjePdv10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
              </div>
              <div class="p-d-flex gap-12 p-fluid p-justify-between">
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-isporucilac-avans-osnovica-20"
                  fieldLabel="Smanjenje osnovice - avans po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-isporucilac-avans-osnovica-20"
                    [formCtrl]="
                      form.controls.storniranjeIsporucilac!.get(
                        'smanjenjeAvansOsnovica20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-isporucilac-avans-obracunati-pdv-20"
                  fieldLabel="Smanjenje PDV - avans po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-isporucilac-avans-obracunati-pdv-20"
                    [formCtrl]="
                      form.controls.storniranjeIsporucilac!.get(
                        'smanjenjeAvansPdv20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-isporucilac-avans-osnovica-10"
                  fieldLabel="Smanjenje osnovice - avans po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-isporucilac-avans-osnovica-10"
                    [formCtrl]="
                      form.controls.storniranjeIsporucilac!.get(
                        'smanjenjeAvansOsnovica10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-isporucilac-avans-obracunati-pdv-10"
                  fieldLabel="Smanjenje PDV - avans po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-isporucilac-avans-obracunati-pdv-10"
                    [formCtrl]="
                      form.controls.storniranjeIsporucilac!.get(
                        'smanjenjeAvansPdv10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
              </div>
            </div>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab header="Storniranje - poreski dužnik primalac">
        <div class="p-d-flex p-flex-column gap-12">
          <!-- Povecanje -->
          <div class="p-d-flex p-flex-column gap-8">
            <div><b>Povećanje osnovice, odnosno PDV</b></div>
            <hr />
            <div class="p-d-flex gap-12 p-fluid p-justify-between">
              <kodit-field
                fieldLabelFor="storniranje-povecanje-primalac-osnovica-20"
                fieldLabel="Povećanje osnovice po stopi od 20%"
              >
                <kodit-number-input
                  formCtrlId="storniranje-povecanje-primalac-osnovica-20"
                  [formCtrl]="
                    form.controls.storniranjePrimalac!.get(
                      'povecanjeOsnovica20'
                    )!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="storniranje-povecanje-primalac-obracunati-pdv-20"
                fieldLabel="Povećanje PDV po stopi od 20%"
              >
                <kodit-number-input
                  formCtrlId="storniranje-povecanje-primalac-obracunati-pdv-20"
                  [formCtrl]="
                    form.controls.storniranjePrimalac!.get('povecanjePdv20')!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="storniranje-povecanje-primalac-osnovica-10"
                fieldLabel="Povećanje osnovice po stopi od 10%"
              >
                <kodit-number-input
                  formCtrlId="storniranje-povecanje-isporucilac-osnovica-10"
                  [formCtrl]="
                    form.controls.storniranjePrimalac!.get(
                      'povecanjeOsnovica10'
                    )!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
              <kodit-field
                fieldLabelFor="storniranje-povecanje-primalac-obracunati-pdv-10"
                fieldLabel="Povećanje PDV po stopi od 10%"
              >
                <kodit-number-input
                  formCtrlId="storniranje-povecanje-primalac-obracunati-pdv-10"
                  [formCtrl]="
                    form.controls.storniranjePrimalac!.get('povecanjePdv10')!
                  "
                  [isDisabled]="shouldDisableForm"
                  [maxFractionDigits]="2"
                ></kodit-number-input>
              </kodit-field>
            </div>
          </div>
          <!-- Smanjenje -->
          <div class="p-d-flex p-flex-column gap-8">
            <div>
              <b>Smanjenje osnovice, odnosno PDV</b>
            </div>
            <hr />
            <div class="p-d-flex p-flex-column">
              <div class="p-d-flex gap-12 p-fluid p-justify-between">
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-primalac-osnovica-20"
                  fieldLabel="Smanjenje osnovice za promet po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-primalac-osnovica-20"
                    [formCtrl]="
                      form.controls.storniranjePrimalac!.get(
                        'smanjenjeOsnovica20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-primalac-obracunati-pdv-20"
                  fieldLabel="Smanjenje PDV za promet po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-primalac-obracunati-pdv-20"
                    [formCtrl]="
                      form.controls.storniranjePrimalac!.get('smanjenjePdv20')!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-primalac-osnovica-10"
                  fieldLabel="Smanjenje osnovice za promet po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-primalac-osnovica-10"
                    [formCtrl]="
                      form.controls.storniranjePrimalac!.get(
                        'smanjenjeOsnovica10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-primalac-obracunati-pdv-10"
                  fieldLabel="Smanjenje PDV za promet po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-primalac-obracunati-pdv-10"
                    [formCtrl]="
                      form.controls.storniranjePrimalac!.get('smanjenjePdv10')!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
              </div>
              <div class="p-d-flex gap-12 p-fluid p-justify-between">
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-primalac-avans-osnovica-20"
                  fieldLabel="Smanjenje osnovice - avans po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-primalac-avans-osnovica-20"
                    [formCtrl]="
                      form.controls.storniranjePrimalac!.get(
                        'smanjenjeAvansOsnovica20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-primalac-avans-obracunati-pdv-20"
                  fieldLabel="Smanjenje PDV - avans po stopi od 20%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-primalac-avans-obracunati-pdv-20"
                    [formCtrl]="
                      form.controls.storniranjePrimalac!.get(
                        'smanjenjeAvansPdv20'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-primalac-avans-osnovica-10"
                  fieldLabel="Smanjenje osnovice - avans po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-primalac-avans-osnovica-10"
                    [formCtrl]="
                      form.controls.storniranjePrimalac!.get(
                        'smanjenjeAvansOsnovica10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
                <kodit-field
                  fieldLabelFor="storniranje-smanjenje-primalac-avans-obracunati-pdv-10"
                  fieldLabel="Smanjenje PDV - avans po stopi od 10%"
                >
                  <kodit-number-input
                    formCtrlId="storniranje-smanjenje-primalac-avans-obracunati-pdv-10"
                    [formCtrl]="
                      form.controls.storniranjePrimalac!.get(
                        'smanjenjeAvansPdv10'
                      )!
                    "
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </kodit-field>
              </div>
            </div>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>

<div class="p-mt-5" card style="position: sticky; bottom: 0; z-index: 9">
  <div class="p-d-flex p-flex-column p-flex-md-row p-jc-between">
    <div class="dodatne-akcije p-d-flex p-flex-column p-jc-around p-ml-3"></div>

    <div class="primarne-akcije p-as-center p-d-flex gap-6">
      <ng-content></ng-content>
      <button
        *ngIf="shouldDisableForm"
        pButton
        pRipple
        type="button"
        label="Zatvori"
        class="p-button-secondary p-button-outlined"
        pTooltip="Zatvori"
        tooltipPosition="bottom"
        (click)="handleRouteBack()"
        [disabled]="false"
      ></button>
      <button
        *ngIf="!shouldDisableForm"
        pButton
        pRipple
        icon="far fa-window-close"
        type="button"
        label="Otkaži"
        class="p-button-secondary p-button-outlined"
        pTooltip="Otkaži PDV evidenciju"
        tooltipPosition="bottom"
        (click)="handleCancel()"
        [disabled]="false"
      ></button>
      <button
        *ngIf="!shouldDisableForm"
        pButton
        icon="fas fa-save"
        type="submit"
        label="Sačuvaj"
        class="p-button-success"
        pTooltip="Sačuvaj PDV evidenciju"
        tooltipPosition="bottom"
        (click)="handleSave()"
        [disabled]="false"
        [loading]="false"
      ></button>
    </div>
  </div>
</div>
