import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  CancelIzlazniRacunCommand,
  ICancelIzlazniRacunCommand,
  SefClient,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import {AlertService, SharedService} from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-cancel-izlazni-racun-form',
  templateUrl: './cancel-izlazni-racun-form.component.html',
  styleUrls: ['./cancel-izlazni-racun-form.component.scss'],
})
export class CancelIzlazniRacunFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  form: FormGroupTypeSafe<ICancelIzlazniRacunCommand>;
  brojRacuna: string;

  constructor(
    private _fs: FormBuilderTypeSafe,
    private _client: SefClient,
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _formHelper: FormHelper,
    private _sharedService : SharedService
  ) {}

  ngOnInit(): void {
    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.handleCancel();
      })
    );
    this.form = this._fs.group<ICancelIzlazniRacunCommand>({
      racunId: new FormControl(this._dialogConfig.data.racunId, {
        validators: Validators.required,
      }),
      komentar: new FormControl(null, {
        validators: [Validators.maxLength(128), Validators.required],
      }),
    });
    this.brojRacuna = this._dialogConfig.data.brojRacuna;
    this._dialogConfig.header = 'Otkazivanje računa';
  }

  handleCancel() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    // everything is ok, let's cancel it
    this._sharedService.displayLoadingScreen(true,"Otkazivanje računa u toku");
    this._subs.add(
      this._client
        .cancelIzlazniRacun(this.form.value as CancelIzlazniRacunCommand)
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.data);
          } else {
            this._alertService.addFailedMsg(res.messages[0]);
          }
          this._dialogRef.close(true);
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
