import { replace as _replace } from 'lodash';

/**
 * Replaces matches for pattern in string with replacement.
 * Note: This method is based on String#replace.
 * @param currentString The string to modify.
 * @param pattern The pattern to replace.
 * @param replacement The match replacement.
 * @returns Returns the modified string.
 */
export function replace(
  currentString: string,
  pattern: string,
  replacement: string
): string {
  return _replace(currentString, pattern, replacement);
}
