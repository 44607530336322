<form [formGroup]="form">
  <p-autoComplete
    [suggestions]="kategorijeSuggestions"
    formControlName="naziv"
    [(ngModel)]="kategorijaModel"
    (onSelect)="onSelectChange($event)"
    placeholder="Naziv sadržaja..."
    inputStyleClass="input-el"
    (completeMethod)="searchOznakeNaziv($event)"
    [dropdown]="true"
    field="naziv"
    (onClear)="clearInput()"
    [ngClass]="!kategorijaModel && formSubmitted ? 'test-invalid' : ''"
  >
  </p-autoComplete>

  <small *ngIf="!kategorijaModel && formSubmitted" class="p-invalid">{{
    errorMsg
  }}</small>
</form>
