import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    CreateKontaktFromEmailCommand,
    FileParameter,
    ICreateKontaktFromEmailCommand,
    KontaktiClient,
    MailReceiverDto,
    RacuniClient,
    TipRacuna,
} from '@kodit/core/data-api';
import {MailFormService, FormHelper} from '@kodit/core/form-definitions';
import {AlertService, AuthService, ButtonService, MixpanelService, SharedService} from '@kodit/core/services';
import {
    DynamicDialogRef,
    DynamicDialogConfig,
    FileUploadService,
} from '@kodit/core/shared-ui';
import {Subscription} from 'rxjs';
import {KontaktiService} from '@kodit/core/moduli/kontakt';

@Component({
    selector: 'kodit-posalji-elektronski-form',
    templateUrl: './posalji-elektronski-form.component.html',
    styleUrls: ['./posalji-elektronski-form.component.scss'],
})
export class PosaljiElektronskiFormComponent implements OnInit, OnDestroy {
    /** Subscription */
    private _subs: Subscription = new Subscription();

    readonly MAX_FILE_SIZE: number = 10000000;

    /** Form */
    form: FormGroup;

    /** Props */
    tipRacuna: TipRacuna;
    strankaId: number;
    stampatiNaEngleskom: boolean;

    private readonly _racunTipNaziv: { [key in TipRacuna]?: string } = {
        [TipRacuna.IZLAZNA_FAKTURA]: 'Račun',
        [TipRacuna.PREDRACUN]: 'Predračun',
        [TipRacuna.OTPREMNICA]: 'Otpremnica',
        [TipRacuna.DOKUMENT_O_SMANJENJU]: 'Dokument o smanjenju',
        [TipRacuna.DOKUMENT_O_POVECANJU]: 'Dokument o povećanju',
        [TipRacuna.AVANSNI_RACUN]: 'Avansni račun',
        [TipRacuna.EMPTY]: 'Novi račun',
    };

    constructor(
        private _fb: FormBuilder,
        private _alertService: AlertService,
        private _dialogRef: DynamicDialogRef,
        private _formHelper: FormHelper,
        private _uploadService: FileUploadService,
        private _dialogConfig: DynamicDialogConfig,
        private _mailFS: MailFormService,
        private _buttonService: ButtonService,
        private _authService: AuthService,
        private _kontaktService: KontaktiService,
        private _client: RacuniClient,
        private _kontaktiClient: KontaktiClient,
        private _mixpanelService: MixpanelService,
        private _sharedService: SharedService
    ) {
        this.tipRacuna = this._dialogConfig.data.racunTip;
    }

    ngOnInit(): void {
        // stranka id iz racuna ->  this._dialogConfig.data...
        this.strankaId = this._dialogConfig.data.strankaId;

        this._uploadService.setMaxAttachmentSize = this.MAX_FILE_SIZE;

        this._subs.add(
            this._dialogRef.onSubmit.subscribe(() => {
                this.onSend();
            })
        );

        this.form = this._fb.group({
            strankaId: new FormControl(this._dialogConfig.data.strankaId),
            racunId: new FormControl(this._dialogConfig.data.racunId, {
                validators: Validators.required,
            }),
            to: this._mailFS.GetMailReceiversFormArray(
                this._dialogConfig.data.sendTo
            ),
            cc: this._mailFS.GetMailReceiversFormArray(
                this._dialogConfig.data.sendCc,
                false
            ),
            emailNaslov: new FormControl(
                `${this._racunTipNaziv[this.tipRacuna]} od ${
                    this._authService.getCurrentTenantName
                }`,
                {
                    validators: Validators.maxLength(65),
                }
            ),
            customPoruka: new FormControl(this._dialogConfig.data.customPoruka),
            stampatiNaEngleskom: new FormControl(this._dialogConfig.data.stampatiNaEngleskom),
            attachments: new FormArray([]),
        });

        this._uploadService.getAttachments.subscribe((attachments: File[]) => {
            (this.form.controls.attachments as FormArray).clear();
            attachments.forEach((x) => {
                (this.form.controls.attachments as FormArray).push(new FormControl(x));
            });
        });
    }

    onSend() {
        if (!this.form.valid) {
            this._formHelper.invalidateForm(this.form);
            return;
        }

        this._mixpanelService.izlaznaFakturaSentToEmailSubmitted();

        this._buttonService.disableButton();

        const attachments: FileParameter[] = this.form.value.attachments
            ? this.form.value.attachments.map((x: File) => ({
                data: x,
                fileName: x.name,
            }))
            : null;

        // everything is ok, let's send it
        this._sharedService.displayLoadingScreen(true, "Slanje na email u toku")
        this._subs.add(
            this._client
                .sendRacunElektronski(
                    this.form.value.racunId,
                    this.form.value.to.map((x: MailReceiverDto) => x.email),
                    this.form.value.cc.map((x: MailReceiverDto) => x.email),
                    this.form.value.emailNaslov,
                    this.form.value.stampatiNaEngleskom,
                    this.form.value.customPoruka,
                    attachments
                )
                .subscribe((res) => {
                    this._alertService.addSuccessMsg(res.messages[0]);
                    this._mixpanelService.izlaznaFakturaSentToEmailSucceeded();
                    this._creteKontaktiFromNewEmails();
                })
        );
    }

    private _creteKontaktiFromNewEmails() {
        const req: ICreateKontaktFromEmailCommand = {
            strankaId: this.strankaId,
            kontakti: [
                ...this.form.value.to.filter((x) => x.isNew),
                ...this.form.value.cc.filter((x) => x.isNew),
            ],
        };

        if (req.kontakti.length > 0) {
            this._subs.add(
                this._kontaktiClient
                    .createKontaktFromEmail(req as CreateKontaktFromEmailCommand)
                    .subscribe((res) => {
                        this._alertService.addSuccessMsg(res.messages[0]);
                        this._kontaktService.loadKontakti.next(true);
                        this._dialogRef.close(true);
                    })
            );
        } else {
            this._dialogRef.close(true);
        }
    }

    ngOnDestroy(): void {
        this._subs.unsubscribe();
    }
}
