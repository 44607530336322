<div [formGroup]="form" class="p-d-flex p-flex-row p-fluid">
  <!-- Vrsta -->
  <!-- <div class="p-field">
      <label kodit-form-label for="vrsta-racuna">Vrsta računa:</label>
      <kodit-vrsta-racuna-dropdown
        formCtrlId="vrsta-racuna"
        [formCtrl]="form.controls.vrstaRacuna"
      ></kodit-vrsta-racuna-dropdown>
    </div> -->

  <div class="flex-1-1 p-mr-4">
    <div class="p-d-flex">
      <!-- Broj -->
      <div class="p-field p-mr-4">
        <kodit-text-input
          [formCtrl]="form.controls.broj"
          ctrlPlaceholder="Broj računa"
          formCtrlId="brojRacuna"
        ></kodit-text-input>
      </div>

      <!-- Originalni Broj -->
      <div class="p-field">
        <kodit-text-input
          [formCtrl]="form.controls.originalanBroj"
          ctrlPlaceholder="Originalni broj"
          formCtrlId="originalanBroj"
        ></kodit-text-input>
      </div>
    </div>
    <div class="p-d-flex">
      <!-- Status racuna -->
      <div class="p-field p-mr-4 component-width">
        <!-- Status -->
        <kodit-status-racuna-multiselect
          (selectedStatusiRacuna)="selectedStatusiRacuna($event)"
          [allowedStatuses]="statusiRacuna"
          [statusFormArray]="form.controls.statusi"
          ctrlPlaceholder="Status računa"
          formCtrlId="statusi"
        ></kodit-status-racuna-multiselect>
      </div>

      <!-- Status uplate racuna -->
      <div class="p-field component-width">
        <kodit-status-uplate-racuna-multiselect
          (selectedStatusiUplateRacuna)="selectedStatusiUplateRacuna($event)"
          [statusFormArray]="form.controls.statusiUplateRacuna"
          ctrlPlaceholder="Status uplate računa"
          formCtrlId="statusiUplateRacuna"
        >
        </kodit-status-uplate-racuna-multiselect>
      </div>
    </div>
    <!-- Opis Stavke -->
    <div class="p-field">
      <kodit-text-input
        [formCtrl]="form.controls.dodatniOpisStavke"
        ctrlPlaceholder="Dodatni opis stavke"
        formCtrlId="dodatniOpisStavke"
      ></kodit-text-input>
    </div>

    <div class="p-d-flex">
      <!-- Datum prometa od -->
      <div class="p-field p-mr-4">
        <kodit-calendar
          [formCtrl]="form.controls.datumPrometaOd"
          ctrlPlaceholder="Datum prometa od"
          formCtrlId="datumPrometaOd"
        ></kodit-calendar>
      </div>

      <!-- Datum prometa do -->
      <div class="p-field">
        <kodit-calendar
          [formCtrl]="form.controls.datumPrometaDo"
          ctrlPlaceholder="Datum prometa do"
          formCtrlId="datumPrometaDo"
        ></kodit-calendar>
      </div>
    </div>
  </div>

  <div class="flex-1-1 p-mr-4">
    <!-- Datum slanja na sef od -->
    <div class="p-field">
      <kodit-calendar
        [formCtrl]="form.controls.datumSlanjaSefOd"
        ctrlPlaceholder="Datum slanja na SEF od"
        formCtrlId="datumSlanjaSefOd"
      ></kodit-calendar>
    </div>
    <!-- Datum izdavanja od -->
    <!-- <div class="p-field">
      <kodit-calendar
        ctrlPlaceholder="Datum izdavanja od"
        formCtrlId="datumIzdavanjaOd"
        [formCtrl]="form.controls.datumIzdavanjaOd"
      ></kodit-calendar>
    </div> -->

    <!-- Promet od -->
    <div class="p-field">
      <!-- <label kodit-form-label for="prometOd">Promet od (din):</label> -->
      <kodit-number-input
        [ctrlMinValue]="0"
        [formCtrl]="form.controls.prometOd"
        ctrlPlaceholder="Promet od (din)"
        formCtrlId="prometOd"
        suffix=" din"
      ></kodit-number-input>
    </div>

    <!-- Valuta istice za X dana -->
    <div class="p-field">
      <kodit-number-input
        [attr.data-val]="form.value.valutaIsticeZa"
        [ctrlMinValue]="0"
        [ctrlOnlyIntegers]="true"
        [ctrlUseGrouping]="false"
        [formCtrl]="form.controls.valutaIsticeZa"
        ctrlPlaceholder="Valuta ističe za X dana"
        formCtrlId="valutaIsticeZa"
        preffix="za"
        suffix=" dan(a)"
      ></kodit-number-input>
    </div>

    <kodit-racun-filter-opseg-dropdown
      [periodCtrl]="form.controls.period"
      formCtrlId="racun-filter-opseg-dropdown"
    ></kodit-racun-filter-opseg-dropdown>
  </div>

  <div class="flex-1-1 p-mr-4">
    <!-- Datum slanja na sef do -->
    <div class="p-field">
      <kodit-calendar
        [formCtrl]="form.controls.datumSlanjaSefDo"
        ctrlPlaceholder="Datum slanja na SEF do"
        formCtrlId="datumSlanjaSefDo"
      ></kodit-calendar>
    </div>
    <!-- Datum izdavanja do -->
    <!-- <div class="p-field">
      <kodit-calendar
        ctrlPlaceholder="Datum izdavanja do"
        formCtrlId="datumIzdavanjaDo"
        [formCtrl]="form.controls.datumIzdavanjaDo"
      ></kodit-calendar>
    </div> -->
    <!-- Promet do -->
    <div class="p-field">
      <!-- <label kodit-form-label for="prometDo">Promet do (din):</label> -->
      <kodit-number-input
        [ctrlMinValue]="0"
        [formCtrl]="form.controls.prometDo"
        ctrlPlaceholder="Promet do (din)"
        formCtrlId="prometDo"
        suffix=" din"
      ></kodit-number-input>
    </div>

    <!-- Valuta istice od datuma -->
    <div class="p-field">
      <kodit-calendar
        [formCtrl]="form.controls.datumValute"
        ctrlPlaceholder="Valuta ističe dana"
        formCtrlId="datumValute"
      ></kodit-calendar>
    </div>

    <kodit-racun-filter-period-dropdown
      [formCtrl]="form.controls.period"
      formCtrlId="period"
    ></kodit-racun-filter-period-dropdown>
  </div>

  <div class="flex-1-1 p-mr-4">
    <div class="p-field">
      <!-- <label kodit-form-label for="stranke">Stranke:</label> -->
      <!-- <kodit-stranka-muliselect
        ctrlPlaceholder="Stranke"
        [strankeFormArray]="form.controls.stranke"
      ></kodit-stranka-muliselect> -->
      <kodit-stranka-filter-multiselect
        [strankeFormArray]="form.controls.stranke"
        ctrlPlaceholder="Stranke"
      ></kodit-stranka-filter-multiselect>
    </div>

    <!-- Grad Stranke -->
    <div class="p-field">
      <!-- <label kodit-form-label for="gradStranke">Grad stranke:</label> -->
      <kodit-text-input
        [formCtrl]="form.controls.gradStranke"
        ctrlPlaceholder="Grad stranke"
        formCtrlId="gradStranke"
      ></kodit-text-input>
    </div>

    <!-- Oznake -->
    <div class="p-field">
      <!-- <label kodit-form-label for="oznake">Oznake:</label> -->
      <kodit-oznaka-multiselect
        [oznakeFormArray]="form.controls.oznake"
      ></kodit-oznaka-multiselect>
    </div>

    <!-- Van valute -->
    <div class="p-field">
      <kodit-checkbox
        [formCtrl]="form.controls.vanValute"
        formCtrlId="vanValute"
        labelText="Van valute"
      ></kodit-checkbox>
    </div>
  </div>
</div>
