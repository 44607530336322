import { Component, OnDestroy, OnInit } from '@angular/core';
import { IUplateIsplateSummaryDto } from '@kodit/core/data-api';
import { UplateIsplateFilterService } from 'libs/core/shared-subforms/src/lib/izvod/uplate-isplate-filter/uplate-isplate-filter.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-uplate-page',
  templateUrl: './uplate-page.component.html',
  styleUrls: ['./uplate-page.component.scss'],
})
export class UplatePageComponent implements OnInit, OnDestroy {
  /** Props */
  private _subs: Subscription = new Subscription();

  uplateSummary: IUplateIsplateSummaryDto;

  constructor(private _uplateIsplateService: UplateIsplateFilterService) {}

  ngOnInit(): void {
    this._subs.add(
      this._uplateIsplateService.getTransakcijeSymmary.subscribe((res) => {
        this.uplateSummary = res;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
