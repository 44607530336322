import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetTopNStrankiDonutResponse } from '@kodit/core/data-api';
import { LocationService } from '@kodit/core/services';

@Component({
  selector: 'kodit-top-n-stranki-table',
  templateUrl: './top-n-stranki-table.component.html',
  styleUrls: ['./top-n-stranki-table.component.scss'],
})
export class TopNStrankiTableComponent implements OnInit {
  @Input() data: GetTopNStrankiDonutResponse[];
  @Input() naziv: string;

  constructor(
    private _router: Router,
    private _locationService: LocationService
  ) {}

  ngOnInit(): void {}

  handleStrankaClick(rowIndex: number) {
    const stranka = this.data[rowIndex];
    this._locationService.routeToStrankaKartica(stranka.id, stranka.tipStranke);
  }
}
