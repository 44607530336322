import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IInformacijaIsporukeDto } from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';

@Injectable({
  providedIn: 'root',
})
export class InformacijaIsporukeFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
  ) {}

  public GetInformacijaIsporukeFormGroup(
    model?: IInformacijaIsporukeDto
  ): FormGroupTypeSafe<IInformacijaIsporukeDto> {
    return this._fb.group<IInformacijaIsporukeDto>({
      id: new FormControl(model?.id),
      imeLokacijeMestaIsporuke: new FormControl(
        model?.imeLokacijeMestaIsporuke,
        {
          validators: [Validators.maxLength(128)],
        }
      ),
      gln: new FormControl(model?.gln, {
        validators: [Validators.maxLength(13)],
      }),
      ulicaIBroj: new FormControl(model?.ulicaIBroj),
      grad: new FormControl(model?.grad, {
        validators: [Validators.maxLength(64)],
      }),
      postanskiBroj: new FormControl(model?.postanskiBroj,
    {
      validators: [Validators.maxLength(64)],
    }),
      drzavaId: new FormControl(model?.drzavaId),
      shouldCreate : new FormControl(model?.shouldCreate ?? false)
    });
  }
}
