import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {
  AuthService,
  ConfigService,
  JoyrideHandleService,
  MixpanelRequest,
  MixpanelService,
  SefService,
} from '@kodit/core/services';
import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MenuService } from '../../menu/menu.service';
import {
  ApiMenadzmentDto,
  GetSefHealthStatusResponse,
  IGetSefHealthStatusResponse,
  KorisniciClient,
  MojaFirmaClient,
  ResultOfApiMenadzmentDto,
  ResultOfPrviLoginDto,
} from '@kodit/core/data-api';
import { CoreService } from '../../core-service';
import { UpdatePasswordFormComponent } from '@kodit/core/moduli/podesavanje';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { filter } from 'rxjs/operators';
import { AfterLoginService } from '../../../../../services/src/lib/security/after-login.service';
import { IzlogujUpozorenjeDialogComponent } from '../../ui/izloguj-upozorenje-dialog/izloguj-upozorenje-dialog.component';
import { TokenService } from 'libs/core/services/src/lib/security/token.service';
import { RatingService } from 'libs/core/services/src/lib/rating.service';
import { ProgressService } from '../../progress.service';
import { MojaFirmaApiCardFormComponent } from 'libs/core/moduli/podesavanje/src/lib/moja-firma/moja-firma-api-card-form/moja-firma-api-card-form.component';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Component({
  selector: 'kodit-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, OnDestroy, AfterViewChecked {
  /** Constants */
  maloprodaja_unos_url = '/maloprodaja/fiskalni/unos';

  /** Subs */
  private _subs: Subscription = new Subscription();
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  items: MenuItem[] = [];

  showLoadingPage: boolean = false;
  jePrviLoginKompanije: boolean = false;
  jePrviLoginKorisnika: boolean = false;

  menuMode = 'static';
  colorScheme = 'light';
  menuTheme = 'layout-sidebar-darkgray';
  overlayMenuActive: boolean;
  staticMenuDesktopInactive: boolean;
  staticMenuMobileActive: boolean;
  menuClick: boolean;
  search = false;
  searchClick = false;
  userMenuClick: boolean;
  companyMenuClick: boolean;
  topbarUserMenuActive: boolean;
  topbarCompanyMenuActive: boolean;
  notificationMenuClick: boolean;
  tutorialMenuClick: boolean;
  progressMenuClick: boolean;
  topbarNotificationMenuActive: boolean;
  topbarTutorialMenuActive: boolean;
  topbarProgressMenuActive: boolean;
  rightMenuClick: boolean;
  rightMenuActive: boolean;
  configActive: boolean;
  configClick: boolean;
  resetMenu: boolean;
  shouldDisplayFiskalnaKasaStavkeRacunaTitle: boolean;
  menuHoverActive = false;
  inputStyle = 'outlined';
  ripple: boolean;
  showSpinner: boolean = false;
  isSefActiveDto: IGetSefHealthStatusResponse;
  showRating: boolean = false;
  apiMenadzmentDto: ApiMenadzmentDto;

  constructor(
    private menuService: MenuService,
    private primengConfig: PrimeNGConfig,
    private _configService: ConfigService,
    private _loader: LoaderService,
    private _mojaFirmaClient: MojaFirmaClient,
    private _korisnikClient: KorisniciClient,
    private _router: Router,
    private _coreService: CoreService,
    private _dialogService: DynamicDialogService,
    private _authService: AuthService, //private _init: InitDataClient,
    private _sefService: SefService,
    private _afterLoginService: AfterLoginService,
    private _joyrideHandleService: JoyrideHandleService,
    private _cdr: ChangeDetectorRef,
    private _tokenService: TokenService,
    private _mixpanelService: MixpanelService,
    private _ratingService: RatingService,
    private _progressService: ProgressService,
    private _firebase: AngularFireMessaging
  ) {
    this._detectPageRefresh();
  }

  // Prevent backspace navigation u aplikaciji
  // @HostListener('document:keydown', ['$event'])
  // onKeyDown(event: any) {
  //   if (event.keyCode === 8) {
  //     event.preventDefault();
  //   }
  // }

  ngOnInit(): void {
    const tenantKey = this._authService.getCurrentTenant;
    const email = this._authService.getUserData.email;

    if (true) {
      this._requestFirebaseToken();
    }

    this._mixpanelService.setBaseRequest = {
      tenantKey: tenantKey,
      email: email,
    } as MixpanelRequest;

    // this._loaderSub = this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationStart) {
    //     this.showLoadingPage = true;
    //   }
    //   if (event instanceof NavigationEnd) {
    //     this.showLoadingPage = false;
    //   }
    // });

    this._authService.setAppInitialized = true;

    this._subs.add(
      this._loader.getShowLoader.subscribe((showLoader) => {
        this.showLoadingPage = showLoader;
      })
    );

    this.primengConfig.ripple = true;
    this._subs.add(
      this._configService.ShowSpinner.subscribe((showSpinner) => {
        this.showSpinner = showSpinner;
      })
    );

    // this._getShouldSyncWithSefStatus();
    this._getJePrviLoginKompanije();

    this._subs.add(
      this._coreService.getJePrviLoginKorisnika.subscribe(
        (prviLoginKorisnika) => {
          this.jePrviLoginKorisnika = prviLoginKorisnika;
        }
      )
    );

    this._subs.add(
      this._coreService.getJePrviLoginKompanije.subscribe(
        (prviLoginKompanije) => {
          this.jePrviLoginKompanije = prviLoginKompanije;
        }
      )
    );

    this._subs.add(
      this._authService.getShouldUpdatePassword.subscribe(
        (shouldUpdatePassword) => {
          if (shouldUpdatePassword) {
            /** Open dialog */
            this.dialogConfig.closable = false;
            this.dialogConfig.closeOnEscape = false;
            this.dialogConfig.maximisable = false;
            this.dialogConfig.hideCancel = true;
            this.dialogConfig.header = 'Izmena lozinke';
            this.openDialog(this.dialogConfig);
          }
        }
      )
    );

    this._subs.add(
      this._authService.getUserChanged.subscribe((res) => {
        if (res) {
          this._getJePrviLoginKompanije();
        }
      })
    );

    this._subs.add(
      this._sefService.getIsSefActive.subscribe(
        (isSefActive: GetSefHealthStatusResponse) => {
          if (isSefActive.isActive) {
            this.isSefActiveDto = isSefActive;
          }
        }
      )
    );

    this._subs.add(
      this._tokenService.getShowLogoutDialog.subscribe((res) => {
        if (res) {
          if (this._progressService.progresses.length > 0) {
            this._coreService.resetRemainingTimerSeconds();
          }
          this.openIzlogujUpozorenjeDialog();
        }
      })
    );

    if (this.jePrviLoginKorisnika) {
      this._joyrideHandleService.startTutorial([
        'firstStep',
        'secondStep',
        'thirdStep',
      ]);
    }

    this._addRatingSub();
  }

  ngAfterViewChecked() {
    this._cdr.detectChanges();
  }

  private _handleApiKeyDialog() {
    this.dialogConfig.data = {
      apiKeySEF: this.apiMenadzmentDto?.apiKeySEF,
    };
    this.dialogConfig.header = 'Unos API ključa';
    this.openAPIDialog(this.dialogConfig);
  }

  openAPIDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(MojaFirmaApiCardFormComponent, config);

    this._subs.add(
      ref.onClose.subscribe((dto: ResultOfApiMenadzmentDto) => {
        if (dto) {
          this.apiMenadzmentDto = dto.data;
        }
      })
    );
  }

  private _detectPageRefresh() {
    this._subs.add(
      this._router.events
        .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
        .subscribe((event) => {
          if (event.id === 1 && event.url === event.urlAfterRedirects) {
            this._authService.startSignalR();
            this._afterLoginService.getDataAfterLogin();
          }

          this.shouldDisplayFiskalnaKasaStavkeRacunaTitle =
            event.url === this.maloprodaja_unos_url;
        })
    );
  }

  private _detectPageRouting() {
    this._subs.add(
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          !this._router.navigated;
        }
      })
    );
  }

  private _requestFirebaseToken() {
    this._subs.add(
      this._firebase.requestToken.subscribe((res) => {
        console.log(res);
      })
    );
  }

  openDialog(config: DynamicDialogConfig) {
    const ref = this._dialogService.open(UpdatePasswordFormComponent, config);
  }

  // private _testInit() {
  //   this._subs.add(this._init.init().subscribe((res) => {}));
  // }

  handleDashboardClick() {
    this._router.navigate(['/dashboard']);
  }

  onLayoutClick() {
    if (!this.searchClick) {
      this.search = false;
    }

    if (!this.userMenuClick) {
      this.topbarUserMenuActive = false;
    }

    if (!this.companyMenuClick) {
      this.topbarCompanyMenuActive = false;
    }

    if (!this.notificationMenuClick) {
      this.topbarNotificationMenuActive = false;
    }

    if (!this.tutorialMenuClick) {
      this.topbarTutorialMenuActive = false;
    }

    if (!this.progressMenuClick) {
      this.topbarProgressMenuActive = false;
    }

    if (!this.rightMenuClick) {
      this.rightMenuActive = false;
    }

    if (!this.menuClick) {
      if (this.isSlim()) {
        this.menuService.reset();
      }

      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.menuHoverActive = false;
      this.unblockBodyScroll();
    }

    if (this.configActive && !this.configClick) {
      this.configActive = false;
    }

    this.searchClick = false;
    this.configClick = false;
    this.userMenuClick = false;
    this.companyMenuClick = false;
    this.rightMenuClick = false;
    this.notificationMenuClick = false;
    this.tutorialMenuClick = false;
    this.progressMenuClick = false;
    this.menuClick = false;
  }

  onMenuButtonClick(event: any) {
    this.menuClick = true;
    this.topbarUserMenuActive = false;
    this.topbarCompanyMenuActive = false;
    this.topbarNotificationMenuActive = false;
    this.topbarTutorialMenuActive = false;
    this.rightMenuActive = false;

    if (this.isOverlay()) {
      this.overlayMenuActive = !this.overlayMenuActive;
    }

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.staticMenuMobileActive = !this.staticMenuMobileActive;
      if (this.staticMenuMobileActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    event.preventDefault();
  }

  onSearchClick(event) {
    this.search = !this.search;
    this.searchClick = !this.searchClick;
  }

  onMenuClick($event) {
    this.menuClick = true;
    this.resetMenu = false;
  }

  onTopbarUserMenuButtonClick(event) {
    this.userMenuClick = true;
    this.topbarUserMenuActive = !this.topbarUserMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarCompanyMenuButtonClick(event) {
    this.companyMenuClick = true;
    this.topbarCompanyMenuActive = !this.topbarCompanyMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarNotificationMenuButtonClick(event) {
    this.notificationMenuClick = true;
    this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarTutorialMenuButtonClick(event) {
    this.tutorialMenuClick = true;
    this.topbarTutorialMenuActive = !this.topbarTutorialMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarProgressMenuButtonClick(event) {
    this.progressMenuClick = true;
    this.topbarProgressMenuActive = !this.topbarProgressMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onRightMenuClick(event) {
    this.rightMenuClick = true;
    this.rightMenuActive = !this.rightMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onRippleChange(event) {
    this.ripple = event.checked;
  }

  onConfigClick(event) {
    this.configClick = true;
  }

  isSlim() {
    return this.menuMode === 'slim';
  }

  isOverlay() {
    return this.menuMode === 'overlay';
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isMobile() {
    return window.innerWidth <= 991;
  }

  hideOverlayMenu() {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
    }
  }

  openIzlogujUpozorenjeDialog() {
    this.dialogConfig.header =
      'Vaš korisnički nalog je ulogovan na drugom računaru ili pretraživaču!';
    this.dialogConfig.closable = false;
    this.dialogConfig.closeOnEscape = false;
    this.dialogConfig.hideCancel = true;
    this.dialogConfig.customSubmitButton = { label: 'Odjavi se' } as any;
    this._dialogService.open(
      IzlogujUpozorenjeDialogComponent,
      this.dialogConfig
    );
  }

  private _getJePrviLoginKompanije() {
    this._subs.add(
      this._mojaFirmaClient
        .getJePrviLogin()
        .subscribe((res: ResultOfPrviLoginDto) => {
          if (!res.data.jePrviLogin) {
            this._getJePrviLoginKorisnika();
            this.jePrviLoginKompanije = false;
            return;
          }
          this.jePrviLoginKompanije = true;
          // Ukoliko je korisnik ulogovan prvi put i poseduje Trial nalog evidentiramo ga na mixpanel report
          if (res.data.jeTrialNalog) {
            const tenantKey = this._authService.getCurrentTenant;
            this._mixpanelService.newTrialAccountLoggedIn({
              tenantKey: tenantKey,
            } as MixpanelRequest);
          }
        })
    );
  }

  private _getJePrviLoginKorisnika() {
    this._subs.add(
      this._korisnikClient.getJePrviLogin().subscribe((res) => {
        this.jePrviLoginKorisnika = res.data;
      })
    );
  }

  private _addRatingSub() {
    this._subs.add(
      this._ratingService.getCheckUserRating.subscribe((res: boolean) => {
        this.showRating = res;
      })
    );
  }

  // private _getSefHealthInfo() {
  //   this._subs.add(
  //     this._sefClient.getSefHealhStatus().subscribe((isSefActive) => {
  //       if (isSefActive.succeeded) {
  //         debugger
  //         this._sefService.setIsSefActive = {
  //           isActive: isSefActive.data.isActive,
  //           background: isSefActive.data.background,
  //         };
  //       }
  //     })
  //   );
  // }

  ngOnDestroy() {
    this._subs.unsubscribe();
    this._tokenService.setShowLogoutDialog = false;
  }
}
