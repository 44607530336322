import { FormArray, FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import {
  ICreateKorisnickaGrupaCommand,
  IKeyValuePairOfStringAndString,
  IUpdateKorisnickaGrupaCommand,
} from '@kodit/core/data-api';

@Injectable({
  providedIn: 'root',
})
export class KorisnickaGrupaFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  /**
   * Konvertuje model za korisnicku grupu i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model korisnicke grupe od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetKorisnickaGrupaFormGroup(
    model?: ICreateKorisnickaGrupaCommand | IUpdateKorisnickaGrupaCommand
  ): FormGroupTypeSafe<
    ICreateKorisnickaGrupaCommand | IUpdateKorisnickaGrupaCommand
  > {
    return this._fb.group<
      ICreateKorisnickaGrupaCommand | IUpdateKorisnickaGrupaCommand
    >({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv, {
        validators: [Validators.required, Validators.maxLength(20)],
      }),
      opis: new FormControl(model?.opis, {
        validators: [Validators.required, Validators.maxLength(64)],
      }),
      ovlascenjaDto: this.GetOvlascenjeDtoFormArray(model?.ovlascenjaDto),
    });
  }

  public GetOvlascenjeDtoFormArray(
    ovlascenja?: IKeyValuePairOfStringAndString[]
  ): FormArray {
    if (!ovlascenja || ovlascenja.length === 0) {
      return this._fb.array([], {
        validators: [Validators.required],
      });
    }

    let result: FormArray = this._fb.array([]);
    ovlascenja.forEach((o) => {
      result.push(this.GetOvlascenjeDtoFormGroup(o));
    });
    return result;
  }

  public GetOvlascenjeDtoFormGroup(
    model?: IKeyValuePairOfStringAndString
  ): FormGroupTypeSafe<any> {
    return this._fb.group<IKeyValuePairOfStringAndString>({
      key: new FormControl(model?.key),
      value: new FormControl(model?.value),
    });
  }
}
