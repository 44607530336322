import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CreateMemorandumCommand,
  FileParameter,
  ICreateMemorandumCommand,
  MemorandumiClient,
  MojaFirmaClient,
  ResultOfMemorandumDto,
  UpdateMemorandumCommand,
} from '@kodit/core/data-api';
import { MemorandumFormService } from '@kodit/core/form-definitions';
import { AlertService, Mode } from '@kodit/core/services';
import { FileUploadService } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'kodit-memorandum-form',
  templateUrl: './memorandum-form.component.html',
  styleUrls: ['./memorandum-form.component.scss'],
})
export class MemorandumFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  readonly MAX_FILE_SIZE: number = 10000000;
  /** Props */
  form: FormGroupTypeSafe<ICreateMemorandumCommand>;
  mode: Mode = Mode.NEW;
  editMode: Mode = Mode.EDIT;

  logo: string = 'logo-kompanije';
  value1: string;
  tableHeaderColor: string = '#cae4ff';
  uploadCompleted: boolean;
  headerOption: string;

  logoImage: string;

  napomena: string;
  napomenaOPoreskomOslobadjanju: string = 'Nema';
  website: string;
  email: string;
  nazivKompanije: string;
  brojTelefona: string;

  koristiInformacijePoslovneJedincie: boolean = false;

  koristiLogoKompanije: boolean = true;
  prikaziNapomenu: boolean = true;
  prikaziAktOValidnosti: boolean = true;
  prikaziWebsite: boolean = true;
  prikaziEmail: boolean = true;
  prikaziFooterSekciju: boolean = true;

  memorandumId: number;

  logoFile?: FileParameter;

  headerOptions: any[] = [
    { labelUrl: '/assets/podesavanje/images/header_2.png', key: 'h1' },
    { labelUrl: '/assets/podesavanje/images/header_1.png', key: 'h2' },
  ];
  selectedHeader: any;

  footerOptions: any[] = [
    { labelUrl: '/assets/podesavanje/images/footer.png', key: 'f1' },
    { labelUrl: '', key: 'f2' },
  ];
  selectedFooter: any;

  logoOptions: any[] = [
    { label: 'Koristi logo kompanije', key: 'existing' },
    { label: 'Koristi novi logo', key: 'new' },
  ];
  selectedLogoOption: any;
  id: number;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _alertService: AlertService,
    private _mojaFirmaClient: MojaFirmaClient,
    private _memorandumClient: MemorandumiClient,
    private _memorandumFormService: MemorandumFormService,
    private _breadcrumbService: BreadcrumbService,
    private _uploadService: FileUploadService
  ) {
    this.selectedHeader = this.headerOptions[0];
    this.selectedFooter = this.footerOptions[0];
    this.selectedLogoOption = this.logoOptions[0];
  }

  ngOnInit(): void {
    this._uploadService.setMaxAttachmentSize = this.MAX_FILE_SIZE;
    this.form = this._memorandumFormService.GetMemorandumFormGroup();
    // get router data
    this._route.params.subscribe((params) => {
      if (params['id']) {
        this.mode = Mode.EDIT;
        this.id = Number.parseInt(params['id']);
        this.onLoadMemorandum(Number.parseInt(params['id']));
      } else {
        this._getLogo();
      }
    });
  }

  private _getLogo() {
    if (this.koristiLogoKompanije) {
      this._subs.add(
        this._mojaFirmaClient.getLogo().subscribe((res) => {
          this.logoImage = res.data;
        })
      );
    } else {
      this._subs.add(
        this._memorandumClient.getLogo(this.id).subscribe((res) => {
          this.logoImage = res.data;
        })
      );
    }
  }

  onCheck(val) {
    this.form.controls.koristiKompanijskiLogo.patchValue(val);
    this.koristiLogoKompanije = val;
  }

  onUpload(file: File) {
    this.uploadCompleted = false;

    this._subs.add(
      this._memorandumClient
        .uploadLogo({ data: file, fileName: file.name }, this.form.value.id)
        .subscribe(
          (res) => {
            this.uploadCompleted = true;
            this._alertService.addSuccessMsg(res.messages[0]);
            this._getLogo();
          },
          (error) => {
            this._subs.add(
              error.subscribe((x: string) => {
                this._alertService.addFailedMsg(x);
              })
            );
          }
        )
    );
  }

  onLoadMemorandum(id: number) {
    this._subs.add(
      this._memorandumClient
        .getMemorandum(id)
        .subscribe((res: ResultOfMemorandumDto) => {
          this.form.patchValue(res.data);
          this.koristiLogoKompanije = res.data.koristiKompanijskiLogo;
          this.nazivKompanije = res.data.naziv;
          this.email = res.data.email;
          this.website = res.data.website;
          this.brojTelefona = res.data.phoneNumber;
          this._getLogo();
          this.setBr();
          this.koristiInformacijePoslovneJedincie =
            res.data.poslovnaJedinicaId != null;
        })
    );
  }

  onSave() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.mode === Mode.EDIT) {
      this._subs.add(
        this._memorandumClient
          .update(
            this.form.value.id,
            this.form.value as UpdateMemorandumCommand
          )
          .subscribe((result) => {
            this._alertService.addSuccessMsg('Memorandum je uspešno izmenjen');
            this._router.navigate(['/podesavanja', 'racun']);
          })
      );
    } else {
      // this.form.value.logoFile = this.logoFile;

      this._subs.add(
        this._memorandumClient
          .create(this.form.value as CreateMemorandumCommand)
          .subscribe((result) => {
            this._alertService.addSuccessMsg('Memorandum je uspešno kreiran');
            this._router.navigate(['/podesavanja', 'racun']);
          })
      );
    }
  }

  private setBr() {
    this._breadcrumbService.set('@IzmenaMemoranduma', this.nazivKompanije);
  }

  onCancel() {
    this._router.navigate(['/podesavanja', 'racun']);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
