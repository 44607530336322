import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import {
  IGetPdvEvidencijaPojedinacnaTableQuery,
  PdvEvidencijaStatus,
  PdvEvidencijaTipDokumenta,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AdvancedFilter } from '@kodit/core/shared-ui';
import { Store } from '@ngrx/store';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  clearFilter,
  setFilter,
} from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';

@Component({
  selector: 'kodit-pdv-evidencija-pojedinacna-filter',
  templateUrl: './pdv-evidencija-pojedinacna-filter.component.html',
  styleUrls: ['./pdv-evidencija-pojedinacna-filter.component.scss'],
})
export class PdvEvidencijaPojedinacnaFilterComponent
  implements OnInit, AdvancedFilter<IGetPdvEvidencijaPojedinacnaTableQuery> {
  /**Props */
  filterData!: IGetPdvEvidencijaPojedinacnaTableQuery;
  form!: FormGroupTypeSafe<IGetPdvEvidencijaPojedinacnaTableQuery>;

  @Output() onSubmitForm = new EventEmitter<IGetPdvEvidencijaPojedinacnaTableQuery>();

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _formHelper: FormHelper,
    private _storage: Store
  ) {}

  ngOnInit(): void {
    this.form = this.getForm(this.filterData);
  }

  onFilterSubmit() {
    this._storage.dispatch(
      setFilter({
        key: 'PDV|Pojedinacne',
        filter: JSON.stringify(this.form.value),
      })
    );
  }

  onFilterHide(shouldReset: boolean) {
    if (shouldReset) {
      this._storage.dispatch(
        clearFilter({
          key: 'PDV|Pojedinacne',
        })
      );
    }
  }

  private getForm(
    model: IGetPdvEvidencijaPojedinacnaTableQuery
  ): FormGroupTypeSafe<IGetPdvEvidencijaPojedinacnaTableQuery> {
    return this._fb.group<IGetPdvEvidencijaPojedinacnaTableQuery>({
      brojObracuna: new FormControl(model.brojObracuna),
      originalniBroj: new FormControl(model.originalniBroj),
      statusi: new FormControl(model.statusi),
      tipoviDokumenta: new FormControl(model.tipoviDokumenta),
      datumOd: this._formHelper.initializeDateFormControl(model.datumOd!),
      datumDo: this._formHelper.initializeDateFormControl(model.datumDo!),
    });
  }
}
