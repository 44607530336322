import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-korisnik-page',
  templateUrl: './korisnik-page.component.html',
  styleUrls: ['./korisnik-page.component.scss']
})
export class KorisnikPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
