<div card class="k-card p-mt-3">
  <div class="p-d-flex p-jc-between _header">
    <div class="ek-w-60">
      <p class="_title p-mb-2">{{ title }}</p>
    </div>
    <div *ngIf="showSyncBtn" class="p-d-flex p-flex-row">
      <button
        (click)="onDownloadUblClicked()"
        [disabled]="shouldDisableDownloadUbl"
        *ngIf="shouldDisplayDownloadUbl"
        class="p-mr-2 p-button p-button-outlined"
        icon="fa-light fa-download"
        label="Skini UBL fajl"
        pButton
        type="button"
      ></button>
      <button
        (click)="onCreate()"
        [icon]="createIcon"
        [label]="btnLabel"
        class="p-button p-button-outlined"
        pButton
        type="button"
      ></button>
    </div>
  </div>
  <kodit-divider></kodit-divider>

  <div>
    <p-dataView
      #dv
      [paginator]="showPaginator"
      [rows]="maxRows"
      [value]="dokumentList"
      layout="list"
    >
      <ng-template let-item let-rowIndex="rowIndex" pTemplate="listItem">
        <div class="p-d-flex p-jc-between p-p-2 item">
          <div>
            <i class="fa-regular fa-paperclip"></i>
            <span class="p-ml-2">{{ item.naziv }}</span>
          </div>
          <div class="p-d-flex p-d-row p-column-md gap-25">
            <div>
              <button
                (click)="handlePreview(item)"
                class="p-button-text p-ai-baseline"
                icon="fa-regular fa-eye"
                pButton
                pRipple
                type="button"
              ></button>
            </div>
            <div>
              <button
                (click)="handleDownload(item)"
                class="p-button-text p-ai-baseline"
                icon="fa-regular fa-download"
                pButton
                pRipple
                type="button"
              ></button>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template let-item let-rowIndex="rowIndex" pTemplate="gridItem">
        <div class="p-col-12 p-md-4" style="text-align: center">
          <div class="item-grid-item p-mr-2 p-mb-2 card">
            <div class="item-grid-item-content">
              <img
                alt="Girl in a jacket"
                height="600"
                src="https://www.w3schools.com/tags/img_girl.jpg"
                style="width: 5rem; height: 5rem"
                width="500"
              />
              <!-- <div class="item-name">{{ item.name }}</div> -->
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>
</div>
