<form [formGroup]="form" (keydown)="keyDownFunction($event)">
  <div class="p-fluid">
    <div class="p-field-checkbox">
      <p-checkbox
        inputId="jeAktivna"
        [binary]="true"
        [formControl]="form.controls.jeAktivna"
      ></p-checkbox>
      <label for="jeAktivna">{{
        form.controls['jeAktivna'].value
          ? 'Poslovna jedinica je aktivna'
          : 'Poslovna jedinica nije aktivna'
      }}</label>
    </div>

    <!-- Sifra delatnosti -->
    <div class="p-field">
      <kodit-delatnost-dropdown
        [formCtrl]="form.controls.sifraDelatnosti"
      ></kodit-delatnost-dropdown>
    </div>

    <!-- Magacini -->
    <div class="p-field" *ngIf="hasVeleprodaja">
      <label for="opis">Magacini</label>
      <kodit-magacini-multiselect
        formCtrlId="magacini"
        [magaciniFormArray]="form.controls.magacini"
        (selectedMagacini)="selectedMagacini($event)"
      ></kodit-magacini-multiselect>
    </div>

    <!-- Vrsta poslovne jedinice -->
    <div class="p-field">
      <label for="opis">Vrsta poslovne jedinice</label>
      <kodit-vrsta-poslovne-jedinice-dropdown
        [formCtrl]="form.controls.vrsta"
      ></kodit-vrsta-poslovne-jedinice-dropdown>
    </div>

    <!-- Naziv -->
    <div class="p-field">
      <label for="naziv">Naziv</label>
      <kodit-text-input [formCtrl]="form.controls.naziv"></kodit-text-input>
    </div>

    <!-- Opis -->
    <div class="p-field">
      <label for="opis">Opis</label>
      <kodit-text-input [formCtrl]="form.controls.opis"></kodit-text-input>
    </div>

    <kodit-adresa-form [addressForm]="form.controls.adresa"></kodit-adresa-form>
  </div>
</form>
