<p-autoComplete
  [ngClass]="!skladisnaJedinicaModel && formSubmitted ? 'test-invalid' : ''"
  [(ngModel)]="skladisnaJedinicaModel"
  [suggestions]="skladisneJediniceSuggestions"
  (onSelect)="onSelectChange($event)"
  placeholder="Naziv skladisne jedinice..."
  inputStyleClass="input-el"
  (completeMethod)="searchSkladisneJediniceNaziv($event)"
  appendTo="body"
  field="naziv"
  [dropdown]="true"
  (onClear)="handleInputClear()"
></p-autoComplete>

<small *ngIf="!skladisnaJedinicaModel && formSubmitted" class="p-invalid">{{
  errorMsg
}}</small>
