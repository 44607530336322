import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IRacunUkupanIznosDto, TipRacuna } from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { RacunService } from '../../../../../../services/src/lib/racun.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'kodit-ukupan-iznos-table',
  templateUrl: './ukupan-iznos-table.component.html',
  styleUrls: ['./ukupan-iznos-table.component.scss'],
})
export class UkupanIznosTableComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  ukupanIznos: FormGroupTypeSafe<IRacunUkupanIznosDto>;
  valutaText: string;
  jeAvansniRacun: boolean;
  jeFaktura: boolean;
  jeDokumentOSmanjenju: boolean;
  jeDokumentOPovecanju: boolean;
  jeObrada: boolean;
  jeKonvertovanje: boolean;

  //#region avans flags
  hasAvans8: boolean;
  hasAvans10: boolean;
  hasAvans20: boolean;
  hasAvansAE10: boolean;
  hasAvansAE20: boolean;
  hasAvansO: boolean;
  hasAvansE: boolean;
  hasAvansR: boolean;
  hasAvansZ: boolean;
  hasAvansSS: boolean;
  hasAvansOE: boolean;
  hasAvansN: boolean;
  //#endregion avans flags

  /** I/O */
  @Input() minWidth: string = '500px';

  constructor(
    private _service: RacunService,
    private _routing: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.ukupanIznos = this._service.getUkupanIznos;
    this.jeKonvertovanje = this._routing.snapshot.data.jeKonvertovanje;
    this.valutaText = this._service.getValutaTextValue;
    this._subs.add(
      this._service.getValutaText.valueChanges.subscribe((res) => {
        this.valutaText = res;
      })
    );
    this._subs.add(
      this._service.getHasRegistrovanuAvansnuUplatu.subscribe(
        (hasAvansnuUplatu) => {
          this.hasAvans8 =
            hasAvansnuUplatu &&
            this._service.getUkupanIznos.value.avansiranaOsnovica8 > 0;
          this.hasAvans10 =
            hasAvansnuUplatu &&
            this._service.getUkupanIznos.value.avansiranaOsnovica10 > 0;
          this.hasAvans20 =
            hasAvansnuUplatu &&
            this._service.getUkupanIznos.value.avansiranaOsnovica20 > 0;
          this.hasAvansAE10 =
            hasAvansnuUplatu &&
            this._service.getUkupanIznos.value.avansiranaOsnovicaAE10 > 0;
          this.hasAvansAE20 =
            hasAvansnuUplatu &&
            this._service.getUkupanIznos.value.avansiranaOsnovicaAE20 > 0;
          this.hasAvansO =
            hasAvansnuUplatu &&
            this._service.getUkupanIznos.value.avansiranaOsnovicaO > 0;
          this.hasAvansE =
            hasAvansnuUplatu &&
            this._service.getUkupanIznos.value.avansiranaOsnovicaE > 0;
          this.hasAvansR =
            hasAvansnuUplatu &&
            this._service.getUkupanIznos.value.avansiranaOsnovicaR > 0;
          this.hasAvansZ =
            hasAvansnuUplatu &&
            this._service.getUkupanIznos.value.avansiranaOsnovicaZ > 0;
          this.hasAvansSS =
            hasAvansnuUplatu &&
            this._service.getUkupanIznos.value.avansiranaOsnovicaSS > 0;
          this.hasAvansOE =
            hasAvansnuUplatu &&
            this._service.getUkupanIznos.value.avansiranaOsnovicaOE > 0;
          this.hasAvansN =
            hasAvansnuUplatu &&
            this._service.getUkupanIznos.value.avansiranaOsnovicaN > 0;
        }
      )
    );
    this.jeAvansniRacun =
      this._service.getTipRacuna === TipRacuna.AVANSNI_RACUN;
    this.jeDokumentOPovecanju =
      this._service.getTipRacuna === TipRacuna.DOKUMENT_O_POVECANJU;
    this.jeDokumentOSmanjenju = 
      this._service.getTipRacuna === TipRacuna.DOKUMENT_O_SMANJENJU;

    this._subs.add(
      this._service.getJeObrada.subscribe((res) => {
        this.jeObrada = res;
      })
    );
  }

  updateUkupanIznos() {
    this._service.calculateUkupno();
  }

  onEditIznosZaZaokruzivanje() {
    this._service.updateUkupnoZaUplatu();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    this._service.setJeObrada = false;
  }
}
