import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterChipDto } from '@kodit/core/data-api';

@Component({
  selector: 'kodit-active-filters-chip',
  templateUrl: './active-filters-chip.component.html',
  styleUrls: ['./active-filters-chip.component.scss'],
})
export class ActiveFiltersChipComponent implements OnInit {
  @Input() chipItems: FilterChipDto[];
  @Output() onRemove: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onRemoveChip(item) {
    const index = this.chipItems.indexOf(item);
    this.chipItems.splice(index, 1);
    this.onRemove.emit(item);
  }
}
