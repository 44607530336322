import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MagaciniClient } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-magacin-dropdown',
  templateUrl: './magacin-dropdown.component.html',
  styleUrls: ['./magacin-dropdown.component.scss'],
})
export class MagacinDropdownComponent implements OnInit, OnDestroy {
  /** Subs */
  private _magaciniSub: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];

  /** I/O */
  @Input() magacinCtrl: AbstractControl;
  @Input() mustSelect: boolean = false;
  @Input() interniMagacinCtrl: AbstractControl;

  constructor(private _client: MagaciniClient) {}

  ngOnInit(): void {
    this._magaciniSub = this._client.getByNaziv('%').subscribe((result) => {
      this.items = result.magacini.map((x) => ({
        value: x.id,
        label: x.sifra ? `${x.sifra} | ${x.naziv}` : x.naziv,
        title: x.sifra ? `${x.sifra} | ${x.naziv}` : x.naziv,
      }));
      this.items.unshift({ value: null, label: 'Izaberite magacin' });
    });
  }

  ngOnDestroy() {
    this._magaciniSub.unsubscribe();
  }
}
