import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'kodit-mb-input-mask',
  templateUrl: './mb-input-mask.component.html',
  styleUrls: ['./mb-input-mask.component.scss'],
})
export class MbInputMaskComponent implements OnInit {
  @Input()
  showLabel = false;
  @Input()
  control: FormControl;

  constructor() {}

  ngOnInit(): void {}
}
