import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import {
  IVoziloDto,
  IVoziloOsiguranjeDto,
  TipOsiguranja,
} from '@kodit/core/data-api';
import { VoziloFormService } from './vozilo-form.service';

@Injectable({
  providedIn: 'root',
})
export class VoziloOsiguranjeFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetVoziloOsiguranjeFormGroup(
    voziloId: number,
    model?: IVoziloOsiguranjeDto
  ): FormGroupTypeSafe<IVoziloOsiguranjeDto> {
    return this._fb.group<IVoziloOsiguranjeDto>({
      id: new FormControl(model?.id),
      nazivOsiguravajuceKuce: new FormControl(model?.nazivOsiguravajuceKuce, {
        validators: [Validators.required, Validators.maxLength(50)],
      }),
      nazivOsiguranja: new FormControl(model?.nazivOsiguranja, {
        validators: [Validators.required, Validators.maxLength(50)],
      }),
      cena: new FormControl(model?.cena),
      datumPocetka: new FormControl(model?.datumPocetka),
      datumZavrsetka: new FormControl(model?.datumZavrsetka),
      datumValute: new FormControl(model?.datumValute),
      napomena: new FormControl(model?.napomena, {
        validators: [Validators.maxLength(150)],
      }),
      tipOsiguranja: new FormControl(
        model?.tipOsiguranja ?? TipOsiguranja.EMPTY,
        {
          validators: [Validators.required, Validators.min(0)],
        }
      ),
      voziloId: new FormControl(voziloId),
    });
  }
}
