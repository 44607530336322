import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FilterChipDto,
  GetIzvodTableQuery,
  IGetIzvodTableQuery,
  IIzvodTableAvailableFiltersDto,
  IIzvodTableDto,
  IzvodiClient,
  StatusIzvoda,
} from '@kodit/core/data-api';
import { AlertService, AuthService } from '@kodit/core/services';
import { toCamelCase } from '@kodit/core/shared';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  ActionType,
  OnTableInit,
  TableAction,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { Subscription } from 'rxjs';
import { IzvodFilterService } from '../izvod-filter/izvod-filter.service';
import { IzvodFileuploadComponent } from '../ui/izvod-fileupload/izvod-fileupload.component';

@Component({
  selector: 'kodit-izvod-table',
  templateUrl: './izvod-table.component.html',
  styleUrls: ['./izvod-table.component.scss'],
})
export class IzvodTableComponent implements OnInit, OnDestroy, OnTableInit {
  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);
  tableConfig: TableConfig;

  showMessage: boolean = false;
  messageText: string = '';
  messages: string[] = [];
  /** Props */
  private _subs: Subscription = new Subscription();

  izvodiTable: IIzvodTableDto[] = [];
  availableFilters: IIzvodTableAvailableFiltersDto;
  chipItems: FilterChipDto[] = [];
  isPageLoaded: boolean;

  /** I/O */
  @Input() showCardBlock: boolean = true;

  constructor(
    private _alertService: AlertService,
    private _izvodFilterService: IzvodFilterService,
    private _dialogService: DynamicDialogService,
    private _router: Router,
    private _client: IzvodiClient,
    private _auth: AuthService
  ) {
    this.messageText =
      `Poštovani ${_auth.getUserData.fullName}, nakon puštanja nove verzije aplikacije primetili smo da je moguće da pojedini izvodi nedostaju u aplikaciji.` +
      'Ukoliko je to slučaj i kod Vas, molimo Vas da kontaktirate našu <br> tehničku podršku da bismo rešili problem.' +
      'Izvinjavamo se na neprijatnosti i zahvaljujemo Vam se na razumevanju. Vaša eKompanija.';

    this.messages = [
      `Poštovani ${_auth.getUserData.fullName},`,
      'Nakon puštanja nove verzije primetili smo da pojedini izvodi mogu da nedostaju u aplikaciji.',
      'Ukoliko je to slučaj i kod Vas, molimo Vas da kontaktirate našu tehničku podršku da bismo znali da imate problem i što pre ga rešili.',
      'Izvinjavamo se na neprijatnosti i zahvaljujemo Vam se na razumevanju.',
      'Vaša eKompanija.',
    ];
  }

  ngOnInit(): void {
    // get data for table
    this._load(new GetIzvodTableQuery());
    // config table
    this.setTableConfig().then();
    //sub to filters
    this._subs.add(
      this._izvodFilterService.getAdvanceFilterSubmitted.subscribe(
        (filterResult) => {
          if (filterResult?.shouldApplyFilter) {
            this.tableConfig.advancedFilter.data = filterResult.filterData;
            this._load(filterResult.filterData);
          }
        }
      )
    );
  }

  onIzvodiUpload(uploadedIzvodi: IIzvodTableDto[]) {
    // const sortedIzvodi = this.izvodiTable
    //   .concat(uploadedIzvodi)
    //   .sort(this._compareIzvodTable);
    // this.izvodiTable = sortedIzvodi;
    this._load(new GetIzvodTableQuery());
  }

  private _compareIzvodTable(a: IIzvodTableDto, b: IIzvodTableDto) {
    if (a.nazivGrupe < b.nazivGrupe) {
      return -1;
    }
    if (a.nazivGrupe > b.nazivGrupe) {
      return 1;
    }
    return 0;
  }

  //#region Table configs

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Izvodi',
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      columns: [
        { field: 'brojIzvoda', header: 'Broj izvoda', type: 'text' },
        { field: 'datumIzdavanja', header: 'Datum', type: 'text' },
        {
          field: 'nazivGrupe',
          header: 'Banka',
          type: 'text',
        },
        {
          field: 'konacnoStanje',
          header: 'Konačno stanje',
          type: 'currency',
          currencyAlphaCharField: 'valutaText',
        },
        {
          field: 'brojUplata',
          header: 'Broj uplata',
          type: 'number',
        },
        {
          field: 'brojIsplata',
          header: 'Broj isplata',
          type: 'number',
        },
        {
          field: 'statusDto',
          subField: 'statusStr',
          header: 'Status',
          styleClass: 'status-izvoda',
          styleClassField: 'statusBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
        },
      ],
      headerActions: [
        {
          type: ActionType.CUSTOM,
          label: 'Uvezi izvod(e)',
          icon: 'fas fa-file-import',
          hasAccessTooltip: 'Uvezite izvod u XML ili CSV formatu',
          noAccessTooltip: 'Nemate ovlašćenja za uvoz izvoda',
          callback: () => {
            this.dialogConfig.header = 'Uvoz izvoda u XML/CSV formatu';
            this.dialogConfig.hideFooter = true;
            this.dialogConfig.maximisable = false;
            this._openDialog(this.dialogConfig);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.CUSTOM,
          label: 'Obradi',
          icon: 'far fa-keyboard',
          hasAccessTooltip: 'Obradite izvod',
          noAccessTooltip: 'Nemate ovlašćenja za obradu izvoda',
          callback: (index: number) => {
            this._router.navigate([
              'banka',
              'izvodi',
              'obrada-izvoda',
              this.izvodiTable[index].id,
            ]);
          },
        },
      ],
      //   advancedFilter: {
      //     component: IzvodFilterComponent,
      //     data: new GetIzvodTableQuery(),
      //     availableFilters: null, // moramo dole da popunimo jer nam load prodje nakon ovoga
      //   },
    });
  }

  /**
   * ToDo:
   * ! Ostalo da vidim kako ce se na osnovu nekog uslova dodavati dodatne akcije
   */
  getRowActions(rowIndex: number, itemId: number): TableAction[] {
    if (this.izvodiTable[rowIndex].statusDto.status === StatusIzvoda.OBRADEN) {
      return [
        // new TableAction({
        //   type: ActionType.NO_ACTIONS,
        //   config: new Promise((resolve) => {
        //     resolve({
        //       isAvailable: false,
        //       tooltip: 'Izvod je obrađen i ne može se menjati',
        //     });
        //   }),
        // }),
      ];
    }

    return [
      // new TableAction({
      //   type: ActionType.CUSTOM,
      //   label: 'Obradi izvod',
      //   icon: 'far fa-keyboard',
      //   config: this.getObradaActionConfig(),
      //   callback: () => {
      //     this._router.navigate([
      //       'banka',
      //       'izvodi',
      //       'obrada-izvoda',
      //       this.izvodiTable[rowIndex].id,
      //     ]);
      //   },
      // }),
    ];
  }

  //#endregion Table configs

  private _openDialog(config: DynamicDialogConfig) {
    const ref = this._dialogService.open(IzvodFileuploadComponent, config);

    this._subs.add(
      ref.onClose.subscribe((izvodi: IIzvodTableDto[]) => {
        if (izvodi) {
          this.onIzvodiUpload(izvodi);
        }
      })
    );
  }

  // onFilterSubmit(data: GetIzvodTableQuery) {
  //   this._onTableRefresh(new GetIzvodTableQuery(data));
  // }

  private _load(request: IGetIzvodTableQuery) {
    this._subs.add(
      this._client.getForTable(request as GetIzvodTableQuery).subscribe(
        (res) => {
          this.izvodiTable = res.data.responseList;
          // this.availableFilters = res.data.availableFilters;
          // this.tableConfig.advancedFilter.availableFilters = this.availableFilters;
          // this.chipItems = res.data.activeFilters;
        },
        () => {},
        () => {
          this.isPageLoaded = true;
        }
      )
    );
  }

  private _handleChipRemove(removedChip: FilterChipDto) {
    this.tableConfig.advancedFilter.data[toCamelCase(removedChip.key)] =
      removedChip.defaultValue;

    this._load(new GetIzvodTableQuery(this.tableConfig.advancedFilter.data));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
