<form>
  <span class="p-input-icon-left pretraga-box">
    <i class="fas fa-search"></i>
    <p-autoComplete
      #stavkaAutocompleteInput
      [suggestions]="stavkeSuggestions"
      (completeMethod)="searchRacuni($event)"
      (onSelect)="onSelectChange($event)"
      [placeholder]="ctrlPlaceholder"
      [appendTo]="parentCtrl"
      (onClear)="onClear()"
      [inputStyleClass]="'p-pl-5'"
      [forceSelection]="true"
    ></p-autoComplete>
  </span>
</form>
