import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent implements OnInit {
  /** I/O */
  @Input() formCtrl: AbstractControl | undefined;
  @Input() formCtrlId: string = 'number-input';
  @Input() suffix: string;
  @Input() prefix: string;
  @Input() ctrlPlaceholder: string;
  @Input() ctrlMaxLength: number;
  @Input() ctrlMinValue: number;
  @Input() ctrlUseGrouping: boolean = true;
  @Input() ctrlOnlyIntegers: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() showButtons: boolean = false;
  @Input() buttonLayout: string = '';
  @Input() spinnerMode: string = '';
  @Input() decrementButtonClass: string = '';
  @Input() incrementButtonClass: string = '';
  @Input() incrementButtonIcon: string = '';
  @Input() decrementButtonIcon: string = '';
  @Input() minFractionDigits: number = 2;
  @Input() maxFractionDigits: number = 4;
  @Output() onInputLeft = new EventEmitter<string | number>();

  constructor() {}

  ngOnInit(): void {}

  inputFocused(event: any) {
    event.target.select();
  }

  inputLeft(event: any) {
    this.onInputLeft.emit(event.target.value);
  }
}
