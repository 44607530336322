<form (keydown)="keyDownFunction($event)">
  <div class="p-fluid mw-500">
    <div class="p-field">
      <label for="currentPassword" kodit-form-label>Trenutna šifra</label>

      <kodit-text-input
        formCtrlId="currentPassword"
        [formCtrl]="form.controls.currentPassword"
      ></kodit-text-input>
    </div>
    <div class="p-field">
      <label for="newPassword" kodit-form-label>Nova šifra</label>
      <kodit-text-input
        formCtrlId="newPassword"
        [formCtrl]="form.controls.newPassword"
      ></kodit-text-input>
    </div>
    <div class="p-field">
      <label for="newPasswordCheck" kodit-form-label>Ponovite šifru</label>
      <kodit-text-input
        formCtrlId="newPasswordCheck"
        [formCtrl]="form.controls.newPasswordCheck"
      ></kodit-text-input>
    </div>
  </div>
</form>
