import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IzvodiService {
  constructor() {}

  showStrankaSearch$ = new BehaviorSubject<number>(null);

  set setShowStrankaSearch(value: number) {
    this.showStrankaSearch$.next(value);
  }

  get getShowStrankaSearch() {
    return this.showStrankaSearch$.asObservable();
  }

  reloadUplataTable$ = new BehaviorSubject<boolean>(false);

  set setReloadUplataTable(value: boolean) {
    this.reloadUplataTable$.next(value);
  }

  get getReloadUplataTable() {
    return this.reloadUplataTable$.asObservable();
  }
}
