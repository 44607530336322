import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-notifikacije-page',
  templateUrl: './notifikacije-page.component.html',
  styleUrls: ['./notifikacije-page.component.scss'],
})
export class NotifikacijePageComponent implements OnInit {
  //** Props */
  tableOptions!: SelectItem[];
  samoNeprocitane: boolean = false;
  tableSelectOption: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.tableOptions = [
      { value: false, label: 'Sve' },
      { value: true, label: 'Nepročitane' },
    ];
  }

  handleOnItemChange(e: any) {
    this.samoNeprocitane = e.value;
  }
}
