import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AlertService,
  LocationService,
  MixpanelService,
  SharedService,
} from '@kodit/core/services';
import {
  CreateIzlaznaFakturaCommand,
  IIzlaznaFakturaDto,
  IOpstaPravilaKonfiguracijaDto, IVoziloDropdownDto, IzlazneFaktureClient, TipRacuna,
  UpdateIzlaznaFakturaCommand,
  VrstaFakture,
} from '@kodit/core/data-api';
import { ConfirmationService } from 'primeng/api';
import { RacunService } from '../../../../../../services/src/lib/racun.service';
import {
  FormHelper,
  IzlaznaFakturaFormService,
  OpstaPravilaFormService, VoziloFormService,
} from 'libs/core/form-definitions/src';
import { ActivatedRoute } from '@angular/router';
import { RacunFormConfig } from '../../subforms/racun-form-container/racun-form.config';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { FormArray } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { IzlaznaFakturaService } from '../izlazna-faktura.service';
import {DynamicDialogConfig, DynamicDialogRef, DynamicDialogService} from "@kodit/core/shared-ui";
import {HasAvansnaUplataDialogComponent} from "../has-avansna-uplata-dialog/has-avansna-uplata-dialog.component";
import {DialogSize} from "../../../../../../shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config";

@Component({
  selector: 'kodit-izlazna-faktura-form',
  templateUrl: './izlazna-faktura-form.component.html',
  styleUrls: ['./izlazna-faktura-form.component.scss'],
})
export class IzlaznaFakturaFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  fakturaForm: FormGroupTypeSafe<IIzlaznaFakturaDto>;
  racunFormConfig: RacunFormConfig;
  opstaPravilaForm: FormGroupTypeSafe<IOpstaPravilaKonfiguracijaDto>;

  /** Configuration */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.SMALL
  );

  constructor(
    private _client: IzlazneFaktureClient,
    private _alertService: AlertService,
    private _confirmationService: ConfirmationService,
    private _locationService: LocationService,
    private _racunService: RacunService,
    private _formService: IzlaznaFakturaFormService,
    private _route: ActivatedRoute,
    private _formHelper: FormHelper,
    private _breadcrumbService: BreadcrumbService,
    private _service: IzlaznaFakturaService,
    private _opstaPravilaService: OpstaPravilaFormService,
    private _mixpanelService: MixpanelService,
    private _sharedService: SharedService,
    private _voziloService : VoziloFormService,
    private _dialogService: DynamicDialogService
  ) {}

  ngOnInit(): void {
    // get route data
    this.fakturaForm = this._formService.GetIzlaznaFakturaFormGroup(
      this._route.snapshot.data.formConfiguration,
      this._route.snapshot.data.racun
    );
    this.opstaPravilaForm = this._opstaPravilaService.GetOpstaPravilaFormGroup(
      this._route.snapshot.data.formConfiguration
    );

    // if (this._route.snapshot.data.jeKonvertovanjeOtpremniceUFakturu) {
    //   this.fakturaForm.controls.racunDto.get('tekuciRacunId').patchValue(1);
    // }

    this._service.setForm = this.fakturaForm;

    this._racunService.setBrojRacunaLabel = 'Broj fakture';
    this.racunFormConfig = {
      tip: TipRacuna.IZLAZNA_FAKTURA,
      racunForm: this.fakturaForm.controls.racunDto,
      brojRacunaTooltip: 'Dodeljen broj fakture',
      originalanBrojRacunaTooltip:
        'Unesite broj fakture koji ste dobili od izdavaoca',
      saveCallback: () => this.onSave(),
      cancelCallback: () => this.onCancel(),
    };

    this._subs.add(
      this._racunService.getJeDomaciRacun.subscribe((val) => {
        this.fakturaForm.controls.vrstaFakture.patchValue(
          val ? VrstaFakture.DOMACA : VrstaFakture.STRANA
        );
      })
    );

    if(!!this.fakturaForm.controls.avansnaUplata?.value.avansniRacunId){
      this.openDialog();
    }

    this._setBreadcrumb();
  }

  get jeEditMode(): boolean {
    return this._route.snapshot.data.racun?.racunDto?.id;
  }

  onSave() {
    this._formHelper.removeEmptyStavka(
      this.fakturaForm.controls.racunDto.get('stavkeDto') as FormArray
    );

    // check is it valid
    if (!this.fakturaForm.valid) {
      console.log(this.fakturaForm);
      this._formHelper.invalidateForm(this.fakturaForm);
      return;
    }

    this._mixpanelService.izlaznaFakturaSubmitted(this.fakturaForm.value);

    //add informacija isporuke if informacijaIsporukeId == null
    this.fakturaForm.value.informacijaIsporuke.strankaId = this.fakturaForm.controls.racunDto.value.strankaRacunaDto.id;

    // everything is ok, so let's save it
    if (this.jeEditMode) {
      this._sharedService.displayLoadingScreen(
        true,
        'Ažuriranje izlazne fakture...'
      );
      // it's edit
      this._subs.add(
        this._client
          .update(
            this.fakturaForm.value.racunDto.id,
            this.fakturaForm.value as UpdateIzlaznaFakturaCommand
          )
          .subscribe(() => {
            this._mixpanelService.izlaznaFakturaSucceeded();
            this._alertService.addSuccessMsg(
              `Faktura ${this.fakturaForm.value.racunDto.broj} je uspešno izmenjena.`
            );
            this._locationService.routeBack();
          })
      );
    } else {
      this._sharedService.displayLoadingScreen(
        true,
        'Kreiranje izlazne fakture...'
      );
      // it's new
      this._subs.add(
        this._client
          .create(this.fakturaForm.value as CreateIzlaznaFakturaCommand)
          .subscribe(() => {
            this._mixpanelService.izlaznaFakturaSucceeded();
            this._alertService.addSuccessMsg(`Faktura je uspešno kreirana.`);
            if (
              !this.opstaPravilaForm.controls.kreirajOtpremnicu.value ||
              this.fakturaForm.value.racunDto.vezaniRacuniDto.length !== 0
            ) {
              this._locationService.routeBack();
              return;
            }
            this._alertService.addSuccessMsg(`Otpremnica je uspešno kreirana.`);
            this._locationService.routeBack();
          })
      );
    }
  }

  onCancel() {
    this._confirmationService.confirm({
      message: 'Ukoliko otkažete sav ne snimljen sadržaj će biti izgubljen.',
      acceptLabel: 'Ipak otkaži',
      rejectLabel: 'Ostani na stranici',
      header: 'Potvrdite otkazivanje',
      icon: 'far fa-check-circle',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._locationService.routeBack();
      },
    });
  }

  selectedVozila(selectedItems: any){
    (this.fakturaForm.controls.vozila as FormArray).clear();
    selectedItems.forEach((res : IVoziloDropdownDto) => {
      (this.fakturaForm.controls.vozila as FormArray).push(
        this._voziloService.GetVoziloDropdownFormGroup(res)
      );
    });
  }

  openDialog() {
    this.dialogConfig.data = {
      brojAvansnog : this.fakturaForm.controls.avansnaUplata.value.avansniRacunBroj,
      datumIzdavanja : this.fakturaForm.controls.avansnaUplata.value.avansniRacunDatumIzdavanja
    };
    this.dialogConfig.header = 'Vezana avansna uplata za račun';
    this.dialogConfig.hideSubmit = true;
    this.dialogConfig.closable = false;
    this.dialogConfig.customCancelButton = { label : 'U redu', icon : '' };
    const ref: DynamicDialogRef = this._dialogService.open(
      HasAvansnaUplataDialogComponent,
      this.dialogConfig
    );

    this._subs.add(
      ref.onClose.subscribe(() => {
        const avansneArray = [this.fakturaForm.controls.avansnaUplata.value];
        this._racunService.setAvansneUplateRacuna = avansneArray;
        this._racunService.setOdabraneAvansneUplateZaRacunNonModified = avansneArray
      })
    );
  }

  private _setBreadcrumb() {
    this._breadcrumbService.set(
      '@unosIzlazneFaktureZaStranku',
      `Unos izlazne fakture za '${this.fakturaForm.value.racunDto.strankaRacunaDto.naziv}'`
    );
    this._breadcrumbService.set(
      '@izmenaIzlazneFakture',
      `Izmena izlazne fakture ${this.fakturaForm.value.racunDto.broj}`
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
    this._service.resetService();
  }
}
