import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PackageType, TipRacuna} from '@kodit/core/data-api';
import {AuthService} from '@kodit/core/services';
import {Subscription} from 'rxjs';
import {RacunTableService} from '../racun-table.service';

@Component({
  selector: 'kodit-ulazni-racun-page',
  templateUrl: './ulazni-racun-page.component.html',
  styleUrls: ['./ulazni-racun-page.component.scss'],
})
export class UlazniRacunPageComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs = new Subscription();

  /** Conditional props */
  hasBesplatniPaket: boolean = false;

  /** Tabs props */
  tipUlaznaFaktura = TipRacuna.ULAZNA_FAKTURA;
  tipAvansniRacun = TipRacuna.AVANSNI_RACUN;
  tipOdobrenje = TipRacuna.DOKUMENT_O_SMANJENJU;
  tipZaduzenje = TipRacuna.DOKUMENT_O_POVECANJU;
  activeIndex: number = 0;
  allowedIndecies = [0, 1, 2, 3, 4, 5];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _tableService: RacunTableService,
    private _authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.hasBesplatniPaket =
      this._authService.hasPackage(PackageType[PackageType.DZABE]) &&
      !this._authService.hasPackage(PackageType[PackageType.ROOT]);

    this._route.queryParams.subscribe((params) => {
      if (params.activeTab) {
        const activeTabIdx = Number.parseInt(params.activeTab);
        if (!isNaN(activeTabIdx) && this.allowedIndecies.includes(0, 0)) {
          this.activeIndex = activeTabIdx;
          this._tableService.setActiveTab = activeTabIdx;
        }
      }
    });

    this._subs.add(
      this._tableService.getActiveTab.subscribe((tabIndex) => {
        this.activeIndex = tabIndex;
      })
    );
  }

  handleIndexChange(tabIndex: number) {
    this._tableService.setActiveTab = tabIndex;
    // ToDo: dodati tutorials u zavisnosti od taba
  }

  ngOnDestroy(): void {
    // reset active tab if we leaving the page
    if (this._router.routerState.snapshot.url.indexOf('ulazni-racuni') === -1) {
      this._tableService.resetActiveTab();
    }
    this._subs.unsubscribe();
  }
}
