import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KorisnickaGrupaService {
  constructor() {}

  shouldReloadCards$ = new BehaviorSubject<boolean>(true);
  set setShouldReloadCards(value: boolean) {
    this.shouldReloadCards$.next(value);
  }
  get getShouldReloadCards() {
    return this.shouldReloadCards$.asObservable();
  }
}
