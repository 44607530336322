import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IGetPdvEvidencijaPojedinacna39TableQuery } from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AdvancedFilter } from '@kodit/core/shared-ui';
import { Store } from '@ngrx/store';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { clearFilter, setFilter } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';

@Component({
  selector: 'kodit-pdv-evidencija-pojedinacna39-filter',
  templateUrl: './pdv-evidencija-pojedinacna39-filter.component.html',
  styleUrls: ['./pdv-evidencija-pojedinacna39-filter.component.scss'],
})
export class PdvEvidencijaPojedinacna39FilterComponent
  implements OnInit, AdvancedFilter<IGetPdvEvidencijaPojedinacna39TableQuery> {
  /**Props */
  filterData!: IGetPdvEvidencijaPojedinacna39TableQuery;
  form!: FormGroupTypeSafe<IGetPdvEvidencijaPojedinacna39TableQuery>;

  @Output()
  onSubmitForm = new EventEmitter<IGetPdvEvidencijaPojedinacna39TableQuery>();

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _formHelper: FormHelper,
    private _storage: Store
  ) {}

  ngOnInit(): void {
    this.form = this.getForm(this.filterData);
  }

  onFilterSubmit() {
    this._storage.dispatch(
      setFilter({
        key: 'PDV|Pojedinacne39',
        filter: JSON.stringify(this.form.value),
      })
    );
  }

  onFilterHide(shouldReset: boolean) {
    if (shouldReset) {
      this._storage.dispatch(
        clearFilter({
          key: 'PDV|Pojedinacne39',
        })
      );
    }
  }

  private getForm(
    model: IGetPdvEvidencijaPojedinacna39TableQuery
  ): FormGroupTypeSafe<IGetPdvEvidencijaPojedinacna39TableQuery> {
    return this._fb.group<IGetPdvEvidencijaPojedinacna39TableQuery>({
      brojObracuna: new FormControl(model.brojObracuna),
      originalniBroj: new FormControl(model.originalniBroj),
      statusi: new FormControl(model.statusi),
      tipoviDokumenta: new FormControl(model.tipoviDokumenta),
      datumOd: this._formHelper.initializeDateFormControl(model.datumOd!),
      datumDo: this._formHelper.initializeDateFormControl(model.datumDo!),
    });
  }
}
