import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  IGetSefRacunTableQuery,
  TipRacuna,
} from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { BehaviorSubject } from 'rxjs';
import { StatusRacunaFormService } from '../../..';

@Injectable({
  providedIn: 'root',
})
export class NeobradjeniSefRacuniFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _statusRacunaFormService: StatusRacunaFormService
  ) {}

  /**
   * Postavljanje forme neobradjenog sef racuna
   */
  neobradjeniSefRacunForm$ = new BehaviorSubject<NeobradjeniSefRacuniForm>(
    null
  );

  set setForm(value: NeobradjeniSefRacuniForm) {
    this.neobradjeniSefRacunForm$.next(value);
  }

  get getFormObject(): NeobradjeniSefRacuniForm {
    return this.neobradjeniSefRacunForm$.getValue();
  }

  public GetNeobradjeniSefRacuniFilterFormGroup(
    model?: IGetSefRacunTableQuery
  ): FormGroupTypeSafe<IGetSefRacunTableQuery> {
    return this._fb.group<IGetSefRacunTableQuery>({
      tip: new FormControl(model?.tip ?? TipRacuna.EMPTY),
      statusi: this._statusRacunaFormService.GetStatusFormArray(model?.statusi),
      broj: new FormControl(model?.broj),
      datumPrometaOd: new FormControl(model?.datumPrometaOd),
      datumPrometaDo: new FormControl(model?.datumPrometaDo),
      stranka: new FormControl(model?.stranka),
      period: new FormControl(model?.period),
    });
  }
}

export declare type NeobradjeniSefRacuniForm = FormGroupTypeSafe<IGetSefRacunTableQuery>;
