import {
  AbstractControl,
  FormArray,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  IPredmetStavkeDto,
  ISastavnicaDto,
  TipArtikla,
  TipPredmeta,
} from '@kodit/core/data-api';
import {OznakaFormService} from '../common/oznaka-form.service';
import {Injectable} from '@angular/core';
import {JedinicaMereFormService} from '../..';
import {StopaPdv} from '@kodit/core/shared';

interface IPredmetStavkeFormGroupRequest {
  model?: IPredmetStavkeDto;
  tipPredmeta?: TipPredmeta;
  tipArtikla?: TipArtikla;
  isNazivRequired?: boolean;
  isCenaRequired?: boolean;
  isSifraRequired?: boolean;
  isJedinicaMereRequired?: boolean;
}

export class PredmetStavkeFormGroupRequest
  implements IPredmetStavkeFormGroupRequest {
  model?: IPredmetStavkeDto;
  tipPredmeta: TipPredmeta;
  tipArtikla: TipArtikla;
  isNazivRequired: boolean;
  isCenaRequired: boolean;
  isSifraRequired: boolean;
  isJedinicaMereRequired: boolean;

  constructor(data?: IPredmetStavkeFormGroupRequest) {
    this.isNazivRequired = data?.isNazivRequired ?? true;
    this.isCenaRequired = data?.isCenaRequired ?? true;
    this.isSifraRequired = data?.isSifraRequired ?? false;
    this.isJedinicaMereRequired = data?.isJedinicaMereRequired ?? true;
    this.tipPredmeta = data?.tipPredmeta ?? TipPredmeta.PREDMET_STAVKE;
    this.tipArtikla = data?.tipArtikla ?? TipArtikla.EMPTY;
    this.model = data?.model;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PredmetStavkeFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _oznakeFormFS: OznakaFormService,
    private _jedinicaMereFS: JedinicaMereFormService
  ) {
  }

  /**
   * Konvertuje predmet stavke u form group
   * @param request Request objekat za kreiranje form grupe
   * @returns FormGroup od prosledenog predmeta stavke
   */
  public GetPredmetStavkeFormGroup(
    request: PredmetStavkeFormGroupRequest
  ): FormGroupTypeSafe<IPredmetStavkeDto> {
    return this._fb.group<IPredmetStavkeDto>({
      id: new FormControl(request.model?.id ?? 0),
      idek: new FormControl(request.model?.idek),
      naziv: new FormControl(request.model?.naziv, {
        validators: request.isNazivRequired
          ? [Validators.required, Validators.maxLength(2000)]
          : [Validators.maxLength(2000)],
      }),
      nabavnaCena: new FormControl(request.model?.nabavnaCena ?? 0, {
        validators: request.isCenaRequired
          ? [Validators.required, Validators.min(0.000001)]
          : [Validators.nullValidator],
      }),
      marza: new FormControl(request.model?.marza ?? 0, {
        validators: [Validators.nullValidator],
      }),
      razlikaUCeni: new FormControl(request.model?.razlikaUCeni ?? 0, {
        validators: [Validators.nullValidator],
      }),
      prodajnaCenaBezPdv: new FormControl(
        request.model?.prodajnaCenaBezPdv ?? 0,
        {
          validators: request.isCenaRequired
            ? [Validators.required, Validators.min(0.000001)]
            : [Validators.nullValidator],
        }
      ),
      prodajnaCenaSaPdv: new FormControl(
        request.model?.prodajnaCenaSaPdv ?? 0,
        {
          validators: [Validators.nullValidator],
        }
      ),
      tipPredmeta: new FormControl(
        request.model?.tipPredmeta ?? request.tipPredmeta,
        {
          validators: [this.tipPredmetaValidator()],
        }
      ),
      jedinicaMereDto: this._jedinicaMereFS.GetJedinicuMereFormGroup(
        request.model?.jedinicaMereDto,
        request.isJedinicaMereRequired
      ),
      netoTezinaKg: new FormControl(request.model?.netoTezinaKg ?? 0),
      // jedinicaMereId: new FormControl(request.model?.jedinicaMereId, {
      //   validators: request.isJedinicaMereRequired
      //     ? [Validators.required]
      //     : [Validators.nullValidator],
      // }),
      sifra: new FormControl(request.model?.sifra, {
        validators: request.isSifraRequired
          ? [Validators.required]
          : [Validators.nullValidator, Validators.maxLength(64)],
      }),
      oznakeDto: this._oznakeFormFS.GetOznakeFormArray(
        request.model?.oznakeDto
      ),
      createdBy: new FormControl(request.model?.createdBy),
      createdOn: new FormControl(request.model?.createdOn ?? new Date()),
      lastModifiedBy: new FormControl(request.model?.lastModifiedBy),
      lastModifiedOn: new FormControl(
        request.model?.lastModifiedOn ?? new Date()
      ),
      stopaPDV: new FormControl(request.model?.stopaPDV ?? StopaPdv.BEZ_PDV),
      tipArtikla: new FormControl(
        request.model?.tipArtikla ?? request.tipArtikla
      ),
      valutaId: new FormControl(request.model?.valutaId),
      materijali: this.GetMaterijaliFormArray(request.model?.materijali),
      sellerItemIdentification: new FormControl(
        request.model?.sellerItemIdentification
      ),
      buyerItemIdentification: new FormControl(
        request.model?.buyerItemIdentification
      ),
      standardItemIdentification: new FormControl(
        request.model?.standardItemIdentification
      ),
      label: new FormControl(request.model?.label),
      oznakaPdvKategorije : new FormControl(request.model?.oznakaPdvKategorije),
      razlogPdvIzuzecaId : new FormControl(request.model?.razlogPdvIzuzecaId)
    });
  }

  public GetMaterijaliFormArray(materijali?: ISastavnicaDto[]): FormArray {
    if (!materijali || materijali.length === 0) {
      return this._fb.array([]);
    }

    let result: FormArray = this._fb.array([]);
    materijali.forEach((m) => {
      result.push(
        this._fb.group<ISastavnicaDto>({
          predmetStavkeDto: this.GetPredmetStavkeFormGroup(
            new PredmetStavkeFormGroupRequest({
              model: m?.predmetStavkeDto,
              tipPredmeta: TipPredmeta.PREDMET_STAVKE,
              tipArtikla: TipArtikla.SIROVINE_MATERIJAL,
              isCenaRequired: false,
              isJedinicaMereRequired: false,
              isNazivRequired: false,
              isSifraRequired: false,
            })
          ),
          kolicina: new FormControl(m?.kolicina),
        })
      );
    });
    return result;
  }

  private tipPredmetaValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      control.value != TipPredmeta.PREDMET_STAVKE
        ? null
        : {pogresanTipPredmeta: control.value};
  }
}
