import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  IzvestajFiskalnaKasaPageComponent,
  TipIzvestajaFiskalneKase,
} from './fiskalni-racun/izvestaj-fiskalna-kasa-page/izvestaj-fiskalna-kasa-page.component';
import { IzvestajPageComponent } from './izvestaj-page/izvestaj-page.component';
import { KpoKnjigaPageComponent } from './kpo-knjiga/kpo-knjiga-page/kpo-knjiga-page.component';
import { IosIzvestajPageComponent } from './ios-izvestaj/ios-izvestaj-page/ios-izvestaj-page.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        // pathMatch: 'full',
        component: IzvestajPageComponent,
      },
      {
        path: 'kpo-knjiga',
        data: {
          breadcrumb: 'KPO knjiga',
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: KpoKnjigaPageComponent,
          },
        ],
      },
      {
        path: 'fiskalna-kasa-dnevni-izvestaj',
        data: {
          breadcrumb: 'Dnevni izveštaj fiskalne kase',
          tipIzvestaja: TipIzvestajaFiskalneKase.DNEVNI,
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: IzvestajFiskalnaKasaPageComponent,
          },
        ],
      },
      {
        path: 'fiskalna-kasa-periodicni-izvestaj',
        data: {
          breadcrumb: 'Periodični izveštaj fiskalne kase',
          tipIzvestaja: TipIzvestajaFiskalneKase.PERIODICNI,
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: IzvestajFiskalnaKasaPageComponent,
          },
        ],
      },
      {
        path: '',
        component: IzvestajPageComponent,
      },
      {
        path: 'ios-izvestaj',
        data: {
          breadcrumb: 'IOS izveštaj',
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: IosIzvestajPageComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class IzvestajRoutingModule {}
