<div>
  <div class="p-field p-grid">
    <label class="p-col-fixed p-m-0" kodit-form-label>Naziv:</label>
    <div class="p-col stranka-text">
      <span>{{mojaFirmaKompenzacija.naziv}}</span>
    </div>
  </div>
  <div class="p-field p-grid">
    <label class="p-col-fixed p-m-0" kodit-form-label>Adresa:</label>
    <div class="p-col stranka-text">
      <span>{{mojaFirmaKompenzacija.adresa}}</span>
    </div>
  </div>
  <div class="p-field p-grid">
    <label class="p-col-fixed p-m-0" kodit-form-label>PIB:</label>
    <div class="p-col stranka-text">
      <span>{{mojaFirmaKompenzacija.pib}}</span>
    </div>
  </div>
  <div class="p-field p-grid">
    <label class="p-col-fixed p-m-0" kodit-form-label>MB:</label>
    <div class="p-col stranka-text">
      <span>{{mojaFirmaKompenzacija.mb}}</span>
    </div>
  </div>
</div>
