import { Injectable } from '@angular/core';
import { IGetSefRacunTableQuery } from '@kodit/core/data-api';
import { AdvancedFilterActionData } from '@kodit/core/shared-ui';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NeobradjeniSefRacuniFilterService {
  constructor() {}

  /**
   * Advanced filter methods
   */
  advancedFilterSubmitted$ = new BehaviorSubject<
    AdvancedFilterActionData<IGetSefRacunTableQuery>
  >(null);

  get getAdvencedFilterSubmitted() {
    return this.advancedFilterSubmitted$.asObservable();
  }
  set setAdvancedFilterSubmitted(
    data: AdvancedFilterActionData<IGetSefRacunTableQuery>
  ) {
    this.advancedFilterSubmitted$.next(data);
  }
}
