import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ArhivskaKnjigaService } from '../../../../podesavanje/src/lib/arhivska-knjiga/arhivska-knjiga-service';
import {
  ArhivskaKnjigaClient,
  GetKategorijeDropdownQuery,
  IKategorijaDokMaterijalaDto,
  KategorijaDokMaterijalaDto,
} from '../../../../../data-api/src';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';

@Component({
  selector: 'kodit-klasifikaciona-oznaka-autocomplete',
  templateUrl: './klasifikaciona-oznaka-autocomplete.component.html',
  styleUrls: ['./klasifikaciona-oznaka-autocomplete.component.scss'],
})
export class KlasifikacionaOznakaAutocompleteComponent
  implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  kategorijaModel: any;
  kategorijeSuggestions: KategorijaDokMaterijalaDto[] = [];
  formSubmitted: boolean = false;
  errorMsg: string = 'Obavezno polje';
  form: FormGroupTypeSafe<IKategorijaDokMaterijalaDto>;

  /** I/0 */
  @Input() klasifikacionaOznakaInput: AbstractControl | undefined;

  constructor(
    private _arhivskaClient: ArhivskaKnjigaClient,
    private _arhivskaService: ArhivskaKnjigaService,
    private _fb: FormBuilderTypeSafe
  ) {
    this._subs.add(
      this._arhivskaService.getArhivskaFormSubmitted.subscribe((res) => {
        this.formSubmitted = res;
      })
    );

    this.form = this._arhivskaService.getKategorijeDtoFormGroup();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.klasifikacionaOznakaInput) {
      this._subs.add( 
        this.klasifikacionaOznakaInput.valueChanges.subscribe((value) => {
          if (value) {
            this.form.patchValue(value);
            this.kategorijaModel = value;
          }
        })
      );
    }
  }

  clearInput() {
    this._arhivskaService.arhivskaKnjigaForm.controls.kategorijaDto?.reset();
  }

  searchOznakeNaziv(event: any) {
    this._subs.add(
      this._arhivskaClient
        .getKategorijeDropdown(
          new GetKategorijeDropdownQuery({
            naziv: event.query,
          })
        )
        .subscribe((res) => {
          this.kategorijeSuggestions = [...res.data?.kategorijeDropdown!];
        })
    );
  }

  onSelectChange(event: IKategorijaDokMaterijalaDto) {
    this._arhivskaService.setKlasifikacionaOznaka(event);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
