import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CenovniciClient,
  CenovnikCreateUpdateResponse,
  ICenovnikListDto,
  TipPristupa,
} from '@kodit/core/data-api';
import { AlertService, AuthService } from '@kodit/core/services';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  ActionConfigResponse,
  ActionType,
  ListAction,
  ListConfig,
} from 'libs/core/shared-ui/src/lib/list/list.config';
import { ConfirmationService } from 'primeng/api';
import { CenovnikFormComponent } from '../cenovnik-form/cenovnik-form.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-cenovnik-list',
  templateUrl: './cenovnik-list.component.html',
  styleUrls: ['./cenovnik-list.component.scss'],
})
export class CenovnikListComponent implements OnInit, OnDestroy {
  /** Subscriptions */
  private _cenovniciSub: Subscription = new Subscription();
  private _dialogCloseSub: Subscription = new Subscription();
  private _archiveSub: Subscription = new Subscription();

  /** Configurations */
  listConfig: ListConfig = new ListConfig();
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.EXTRA_LARGE
  );

  /** Props */
  cenovnicList: ICenovnikListDto[] = [];

  @Input() strankaId: number;

  constructor(
    private _route: ActivatedRoute,
    private _dialogService: DynamicDialogService,
    private _authService: AuthService,
    private _client: CenovniciClient,
    private _alertService: AlertService,
    private _confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    // get router data
    this._route.params.subscribe((params) => {
      if (params['strankaId']) {
        this.strankaId = Number.parseInt(params['strankaId']);
      }
    });
    this.setListConfig();
    this._getCenovnicList();
  }

  // ngAfterViewInit(): void {}

  async setListConfig(): Promise<void> {
    this.listConfig = {
      title: 'Cenovnici',
      subtitle: 'Aktivni cenovnici stranke',
      deleteMessage: 'Da li ste...',
      actions: this._getActions(),
      createCallbackFunction: () => this.onCreate(),
    };
  }

  private _getCenovnicList() {
    this._cenovniciSub.add(
      this._client.getForList(this.strankaId).subscribe((res) => {
        this.cenovnicList = res.data;
      })
    );
  }

  // getCenovnici() {
  //   this._cenovniciSub.add(
  //     this._client
  //       .getForTable(
  //         new GetCenovniciTableQuery({
  //           strankaId: 0,
  //         })
  //       )
  //       .subscribe((res) => {
  //         this.cenovniciTable = res.data.cenovniciTable;
  //       })
  //   );
  // }

  // mapToListItem() {
  //   this.listItems = [];
  //   this.cenovniciTable.map((e) => {
  //     var item: ListItem = new ListItem();
  //     item.name = e.naziv;
  //     this.listItems.push(item);
  //   });
  // }

  private _getActions(): ListAction[] {
    return [
      new ListAction({
        type: ActionType.EDIT,
        config: this.getEditListConfig(),
        callback: (index: number) => {
          this.onEdit(index);
        },
      }),
      new ListAction({
        type: ActionType.ARCHIVE,
        config: this.getDeleteListConfig(),
        callback: (index: number) => {
          this._confirmationService.confirm({
            header: 'Potvrda brisanja',
            message: 'Da li želite da obrišete cenovnik?',
            acceptLabel: 'Obriši',
            rejectLabel: 'Odustani',
            rejectButtonStyleClass: 'p-button-outlined',
            accept: () => this.onArchive(index),
          });
        },
      }),
    ];
  }

  private getEditListConfig(): ActionConfigResponse {
    if (this._authService.hasClaim(TipPristupa[TipPristupa.STRANKA_CRUD])) {
      return { isAvailable: true };
    }

    return {
      isAvailable: false,
    };
  }

  private getDeleteListConfig(): ActionConfigResponse {
    if (this._authService.hasClaim(TipPristupa[TipPristupa.STRANKA_CRUD])) {
      return { isAvailable: true };
    }

    return {
      isAvailable: false,
    };
  }

  onCreate(): void {
    this.dialogConfig.data = { id: null, strankaId: this.strankaId };
    this.dialogConfig.header = 'Kreiranje novog cenovnika';
    this.openDialog(this.dialogConfig);
  }

  onEdit(itemIndex: number): void {
    this.dialogConfig.data = {
      id: this.cenovnicList[itemIndex].id,
      strankaId: this.strankaId,
    };
    this.dialogConfig.header = 'Izmena cenovnika';
    this.openDialog(this.dialogConfig);
  }

  onArchive(itemIndex: number): void {
    this._archiveSub = this._client
      .archive(this.cenovnicList[itemIndex].id)
      .subscribe((res) => {
        if (res.succeeded) {
          this._alertService.addSuccessMsg(res.data);
          this.cenovnicList.splice(itemIndex, 1);
          this._getCenovnicList();
        } else {
          this._alertService.addWarnMsg(res.messages[0]);
        }
      });
  }

  openDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(CenovnikFormComponent, config);

    this._dialogCloseSub = ref.onClose.subscribe(
      (res: CenovnikCreateUpdateResponse) => {
        if (res) {
          const idx = this.cenovnicList.findIndex(
            (x) => x.id === res.cenovnikListDto.id
          );
          if (idx > -1) {
            // update
            this.cenovnicList[idx] = res.cenovnikListDto;
          } else {
            // create
            this.cenovnicList.push(res.cenovnikListDto);
            // this._getCenovnicList();
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this._cenovniciSub.unsubscribe();
    this._dialogCloseSub.unsubscribe();
    this._archiveSub.unsubscribe();
  }
}
