import { Component, OnInit } from '@angular/core';
import { KorisniciClient, TenantsClient } from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
  ratings: number[] = [];
  sholudDisplayRating = false;

  private _subs: Subscription = new Subscription();

  constructor(
    private _korisnikClient: KorisniciClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._initNums();

    this._subs.add(
      this._korisnikClient.checkIfRatingExists().subscribe((res) => {
        this.sholudDisplayRating = res.data;
      })
    );
  }

  private _initNums() {
    this.ratings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  }

  setRating(event: number) {
    this._subs.add(
      this._korisnikClient.setRating(event).subscribe((res) => {
        this.sholudDisplayRating = false;
        if (res.status == 200) {
          this._alertService.addSuccessMsg("Uspešno ste ostavili ocenu!")
        }
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
