<!-- Template za joyride step 1(meni) -->
<ng-template #meniObjasnjenje>
  <p>Sa leve strane se nalazi meni</p>
</ng-template>

<div class="layout-sidebar" joyrideStep='firstStep' [stepContent]="meniObjasnjenje" title="Meni">
  <p-scrollPanel
    class="layout-menu-container"
    [style]="{ width: '100%', height: 'calc(100vh - 80px)' }"
  >
    <ul class="layout-menu" *ngIf="menuItems.length > 0">
      <ng-container *ngFor="let item of menuItems; let i = index">
        <li
          kodit-menu-item
          *ngIf="
            !item.separator && (item.isVisible == undefined || item.isVisible)
          "
          [item]="item"
          [index]="i"
          [root]="true"
          [rootVisible]="i < menuItems.length - 1"
        ></li>
        <li *ngIf="i < menuItems.length - 1" class="menu-separator"></li>
      </ng-container>
    </ul>

    <ul class="layout-menu" *ngIf="menuItems.length === 0">
      <div class="loading-skeleton-bar header"></div>
      <div class="loading-skeleton-bar a"></div>
      <div class="loading-skeleton-bar a"></div>
      <div class="loading-skeleton-bar c"></div>
      <div class="loading-skeleton-bar b"></div>
      <div class="loading-skeleton-bar b"></div>
      <div class="loading-skeleton-bar header p-mt-5"></div>
      <div class="loading-skeleton-bar a"></div>
      <div class="loading-skeleton-bar b"></div>
      <div class="loading-skeleton-bar c"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar a"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar header p-mt-5"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar c"></div>
      <div class="loading-skeleton-bar a"></div>
      <div class="loading-skeleton-bar b"></div>
    </ul>
  </p-scrollPanel>
</div>
