import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'kodit-registration-stepper',
  templateUrl: './registration-stepper.component.html',
  styleUrls: ['./registration-stepper.component.scss'],
})
export class RegistrationStepperComponent implements OnInit {
  items: MenuItem[];

  constructor() {}

  ngOnInit(): void {
    this.items = [
      {
        label: 'Odabir paketa',
        routerLink: 'odabir-paketa',
      },
      { label: 'Podaci kompanije', routerLink: 'podaci-kompanije' },
      { label: 'Podaci korisnika', routerLink: 'podaci-korisnika' },
      { label: 'Potvrda e-mail-a', routerLink: 'potvrda-emaila' },
    ];
  }

  ngOnDestroy() {

  }
}
