<div class="p-d-flex p-flex-column p-fluid">

    <kodit-field *ngIf="form.get('subject') && form.get('subject').value !== null"
    fieldLabel="Naslov"
    fieldLabelFor="tiketNaslov"
  >
    <kodit-text-input
      formCtrlId="tiketNaslov"
      [formCtrl]="form.get('subject')"
    ></kodit-text-input>
  </kodit-field>

  <kodit-field *ngIf="form.get('priority') && form.get('priority').value !== null"
  fieldLabel="Prioritet"
  fieldLabelFor="priority-dropdown"
>
  <kodit-dropdown
    formCtrlId="priority-dropdown"
    [options]="priorityOptions"
    [formCtrl]="form.get('priority')"
  ></kodit-dropdown>
</kodit-field>

  <kodit-field
  fieldLabel="{{ _dialogConfig.data?.type === 'pitanje' ? 'Pitanje' : 'Opis problema' }}"
  fieldLabelFor="opisTiket"
>
  <kodit-textarea-input
    ctrlId="opisTiket"
    [formCtrl]="form.get('description')"
    ctrlPlaceholder="{{ _dialogConfig.data?.type === 'pitanje' ? 'Uneti pitanje...' : 'Uneti opis problema...'}}"
  >
  </kodit-textarea-input>
</kodit-field>

<kodit-field
    fieldLabel="Dodatni prilog"
    fieldLabelFor="attachments"
>
    <kodit-file-upload
    (onUpload)="handleUpload($event)"
    [uploadCompleted]="uploadCompleted"
    fileTypes=".jpg,.png,.jpeg,.pdf"
    [shouldAutoUpload]="true"
    [allowMultipleFiles]="true"
    ></kodit-file-upload>
</kodit-field>

</div>
