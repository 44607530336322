import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { Subscription } from 'rxjs';
import { PdvEvidencijaTableService } from '../pdv-evidencija-table.service';

@Component({
  selector: 'kodit-pdv-evidencija-page',
  templateUrl: './pdv-evidencija-page.component.html',
  styleUrls: ['./pdv-evidencija-page.component.scss'],
})
export class PdvEvidencijaPageComponent implements OnInit, OnDestroy {
  private _subs = new Subscription();

  activeIndex: number = 0;
  allowedIndecies = [0, 1];

  constructor(private route: ActivatedRoute, private _router: Router ,private _tableService : PdvEvidencijaTableService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.activeTab) {
        const activeTabIdx = Number.parseInt(params.activeTab);
        if (!isNaN(activeTabIdx) && this.allowedIndecies.includes(0, 0)) {
          this.activeIndex = activeTabIdx;
          this._tableService.setActiveTab = activeTabIdx;
        }
      }
    });

    this._subs.add(
      this._tableService.getActiveTab.subscribe((tabIndex) => {
        this.activeIndex = tabIndex;
      })
    );
  }

  handleIndexChange(tabIndex: number) {
    this._tableService.setActiveTab = tabIndex;
  }

  ngOnDestroy(): void {
    //reset active tab if we leaving the page
    if (
      this._router.routerState.snapshot.url.indexOf('pdv-evidencije') === -1
    ) {
      this._tableService.resetActiveTab();
    }
    this._subs.unsubscribe();
  }
}
