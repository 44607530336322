import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService, LocationService, SharedService } from '@kodit/core/services';
import {
  CreateDokumentOPovecanjuCommand,
  IDokumentOPovecanjuDto,
  IzmeneOsnovicaClient,
  RacuniClient,
  TipPredmeta,
  TipRacuna,
  UpdateDokumentOPovecanjuCommand,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { ConfirmationService } from 'primeng/api';
import { RacunService } from '../../../../../../services/src/lib/racun.service';
import {
  FormHelper,
  IzmenaOsnoviceFormService,
} from 'libs/core/form-definitions/src';
import { ActivatedRoute } from '@angular/router';
import { RacunFormConfig } from '../../subforms/racun-form-container/racun-form.config';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { FormArray } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { IzmenaOsnoviceService } from '../../izmena-osnovice.service';

@Component({
  selector: 'kodit-dokument-o-povecanju-form',
  templateUrl: './dokument-o-povecanju-form.component.html',
  styleUrls: ['./dokument-o-povecanju-form.component.scss'],
})
export class DokumentOPovecanjuFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  racunForm: FormGroupTypeSafe<IDokumentOPovecanjuDto>;
  racunFormConfig: RacunFormConfig;
  useOnlyIzmenaOsnovicePredmetStavke: TipPredmeta[] = [];
  vrstaRacuna: VrstaRacuna;

  constructor(
    private _client: IzmeneOsnovicaClient,
    private _racuniClient: RacuniClient,
    private _alertService: AlertService,
    private _confirmationService: ConfirmationService,
    private _locationService: LocationService,
    private _racunService: RacunService,
    private _fs: IzmenaOsnoviceFormService,
    private _route: ActivatedRoute,
    private _formHelper: FormHelper,
    private _breadcrumbService: BreadcrumbService,
    private _service: IzmenaOsnoviceService,
    private _sharedService : SharedService
  ) {}

  ngOnInit(): void {
    // get router data
    this._route.data.subscribe((params) => {
      if (params['vrstaRacuna']) {
        this.vrstaRacuna = params['vrstaRacuna'] as VrstaRacuna;
      }
    });
    // get route data
    this.racunForm = this._fs.GetFormGroup(
      TipRacuna.DOKUMENT_O_POVECANJU,
      this.vrstaRacuna,
      this._route.snapshot.data.formConfiguration,
      this._route.snapshot.data.racun
    );

    this._service.setForm = this.racunForm;

    // configure labels
    this._racunService.setBrojRacunaLabel = 'Broj dokumenta o povećanju';
    this.racunFormConfig = {
      tip: TipRacuna.DOKUMENT_O_POVECANJU,
      racunForm: this.racunForm.controls.racunDto,
      brojRacunaTooltip: 'Dodeljen broj dokumenta o povećanju',
      originalanBrojRacunaTooltip:
        'Unesite broj dokumenta o povećanju koji ste dobili od izdavaoca',
      saveCallback: () => this.onSave(),
      cancelCallback: () => this.onCancel(),
    };
    this._setBreadcrumb();

    this.useOnlyIzmenaOsnovicePredmetStavke = [
      TipPredmeta.ROBA,
      TipPredmeta.PREDMET_STAVKE,
      TipPredmeta.USLUGA,
      TipPredmeta.VOZILO,
      TipPredmeta.DATI_AVANS,
      TipPredmeta.PRIMLJENI_AVANS,
    ];

    this._subs.add(
      this._racunService.hasVezaniRacun().subscribe((hasSomeVezaniRacun) => {
        if (hasSomeVezaniRacun) {
          const povezanRacun = this.racunForm.value.racunDto['vezaniRacuniDto'][0];
          this._getStrankaRacunaZaRacun(povezanRacun.racunId);
          this.racunForm.controls.brojReferentnogDokumenta.patchValue(povezanRacun.label);
          this.racunForm.controls.brojReferentnogDokumenta.updateValueAndValidity();
        }
      })
    );
  }

  get jeEditMode(): boolean {
    return this._route.snapshot.data.racun?.racunDto?.id;
  }

  onSave() {
    this._formHelper.removeEmptyStavka(
      this.racunForm.controls.racunDto.get('stavkeDto') as FormArray
    );
    // check is it valid
    if (!this.racunForm.valid) {
      this._formHelper.invalidateForm(this.racunForm);
      return;
    }
    // everything is ok, so let's save it
    if (this.jeEditMode) {
      this._sharedService.displayLoadingScreen(true, 'Ažuriranje dokumenta o povećanju...');
      // it's edit
      this._subs.add(
        this._client
          .updateDokumentOPovecanju(
            this.racunForm.value.racunDto.id,
            this.racunForm.value as UpdateDokumentOPovecanjuCommand
          )
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.messages[0]);
            this._locationService.routeBack();
          })
      );
    } else {
      this._sharedService.displayLoadingScreen(true, 'Kreiranje dokumenta o povećanju...');
      // it's new
      this._subs.add(
        this._client
          .createDokumentOPovecanju(
            this.racunForm.value as CreateDokumentOPovecanjuCommand
          )
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.messages[0]);
            this._locationService.routeBack();
          })
      );
    }
  }

  onCancel() {
    this._confirmationService.confirm({
      message: 'Ukoliko otkažete sav ne snimljen sadržaj će biti izgubljen.',
      acceptLabel: 'Ipak otkaži',
      rejectLabel: 'Ostani na stranici',
      header: 'Potvrdite otkazivanje',
      icon: 'far fa-check-circle',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._locationService.routeBack();
      },
    });
  }

  private _getStrankaRacunaZaRacun(racunId: number) {
    this._subs.add(
      this._racuniClient.getStrankaRacunaSaRacuna(racunId).subscribe((res) => {
        this._racunService.updateStrankaRacuna(res.data);
      })
    );
  }

  private _setBreadcrumb() {
    this._breadcrumbService.set(
      '@unosDokumentaOPovecanjuZaStranku',
      `Unos dokumenta o povećanju za '${this.racunForm.value.racunDto.strankaRacunaDto.naziv}'`
    );
    this._breadcrumbService.set(
      '@izmenaDokumentaOPovecanju',
      `Izmena dokumenta o povećanju  ${this.racunForm.value.racunDto.broj}`
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
