<form [formGroup]="form" (keydown)="keyDownFunction($event)">
  <div class="p-fluid">
    <!-- Naziv / Ime -->
    <div class="p-field">
      <label for="naziv" kodit-form-label>Ime / Naziv</label>
      <kodit-text-input [formCtrl]="form.controls.naziv"></kodit-text-input>
    </div>
    <!-- Telefon 1 -->
    <div class="p-field">
      <label for="telefon1" kodit-form-label>Telefon 1</label>
      <kodit-telefon-input
        [formCtrl]="form.controls.telefon1"
      ></kodit-telefon-input>
    </div>
    <!-- Telefon 2 -->
    <div class="p-field">
      <label for="telefon2" kodit-form-label>Telefon 2</label>
      <kodit-telefon-input
        [formCtrl]="form.controls.telefon2"
      ></kodit-telefon-input>
    </div>
    <!-- Telefon 3 -->
    <div class="p-field">
      <label for="telefo3" kodit-form-label>Telefon 3</label>
      <kodit-telefon-input
        [formCtrl]="form.controls.telefon3"
      ></kodit-telefon-input>
    </div>
    <!-- Email -->
    <div class="p-field">
      <label for="email" kodit-form-label>Email</label>
      <kodit-text-input [formCtrl]="form.controls.email"></kodit-text-input>
    </div>
    <!-- Website -->
    <div class="p-field">
      <label for="websiteUrl" kodit-form-label>Website Url</label>
      <kodit-text-input
        [formCtrl]="form.controls.websiteUrl"
      ></kodit-text-input>
    </div>
    <!-- Fax -->
    <div class="p-field">
      <label for="fax" kodit-form-label>Fax</label>
      <kodit-text-input [formCtrl]="form.controls.fax"></kodit-text-input>
    </div>
    <!-- Funkcija -->
    <div class="p-field">
      <label for="funkcija" kodit-form-label>Funkcija</label>
      <kodit-text-input [formCtrl]="form.controls.funkcija"></kodit-text-input>
    </div>
  </div>
</form>
