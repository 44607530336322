<div class="p-d-flex p-flex-md-column">
  <kodit-field fieldLabel="Broj rata">
      <kodit-number-input
        class="broj-rata"
        id="broj-rata"
        [formCtrl]="form.controls.brojRata"
        [minFractionDigits]="0"
      ></kodit-number-input>
  </kodit-field>
  <kodit-field fieldLabel="Šifra plaćanja">
    <kodit-sifra-placanja-dropdown
      [sifrePlacanjaCtrl]="form.controls.sifraPlacanja"
    >
    </kodit-sifra-placanja-dropdown>
    <kodit-error-info-required
      [formCtrl]="form.controls.sifraPlacanja"
    ></kodit-error-info-required>
  </kodit-field>

  <kodit-field fieldLabel="Tekući račun">
    <kodit-tekuci-racun-dropdown
      (onTekuciRacunChanged)="updateTekuciRacun($event)"
    ></kodit-tekuci-racun-dropdown>
    <kodit-error-info-required
      [formCtrl]="form.controls.tekuciRacunPrimaoca"
    ></kodit-error-info-required>
  </kodit-field>

  <kodit-field class="svrha-uplate">
    <label for="svrha" kodit-form-label>Svrha uplate</label>
    <textarea
      class="svrha-uplate"
      id="svrha"
      [rows]="4"
      [cols]="55"
      pInputTextarea
      [formControl]="form.controls.svrhaUplate"
    ></textarea>
    <kodit-error-info-required
      [formCtrl]="form.controls.svrhaUplate"
    ></kodit-error-info-required>
    <kodit-error-info-max-length
      [formCtrl]="form.controls.svrhaUplate"
    ></kodit-error-info-max-length>
    <div></div>
  </kodit-field>
</div>
