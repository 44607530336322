import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DrzavaDto, SharedClient } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-drzava-dropdown',
  templateUrl: './drzava-dropdown.component.html',
  styleUrls: ['./drzava-dropdown.component.scss'],
})
export class DrzavaDropdownComponent implements OnInit, OnDestroy {
  /** Subs */
  private _getSub: Subscription = new Subscription();

  /** Props */
  drzave: DrzavaDto[] = [];
  drzaveOptions: {
    naziv: string;
    value: string;
    alfaChar: string;
  }[] = [];

  /** I/O */
  @Input() drzavaCtrl: AbstractControl;
  @Output()
  onDrzavaChanged: EventEmitter<DrzavaDto> = new EventEmitter<DrzavaDto>();

  constructor(private _client: SharedClient) {}

  ngOnInit() {
    this.loadDrzave();
    this.drzavaCtrl.valueChanges.subscribe((newValue) => {
      if (!newValue) {
        this._setDefaultValue();
      }
    });
  }

  drzavaChanged(event: any) {
    if (this.onDrzavaChanged) {
      this.onDrzavaChanged.emit(this.drzave.find((x) => x.id === event.value));
    }
  }

  private loadDrzave() {
    this._getSub = this._client.getDrzave().subscribe((result) => {
      this.drzave = result.data.drzave;
      this.drzaveOptions = result.data.drzave.map((d) => ({
        value: d.id,
        alfaChar: d.kodDrzaveAlfaChar3,
        naziv: d.nazivSrbLat,
      }));
      if (!this.drzavaCtrl.value) {
        this._setDefaultValue();
      }
    });
  }

  private _setDefaultValue() {
    this.drzavaCtrl.patchValue(
      this.drzave.find((x) => x.kodDrzaveAlfaChar3 === 'SRB').id
    );
  }

  ngOnDestroy() {
    this._getSub.unsubscribe();
  }
}
