import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ISastavnicaDto, TipPredmeta } from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  PredmetStavkeFormGroupRequest,
  PredmetStavkeFormService,
} from './predmet-stavke-form.service';

@Injectable({
  providedIn: 'root',
})
export class SastavnicaFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _predmetStavkeFS: PredmetStavkeFormService
  ) {}

  public GetFormGroup(
    model?: ISastavnicaDto
  ): FormGroupTypeSafe<ISastavnicaDto> {
    return this._fb.group<ISastavnicaDto>({
      predmetStavkeDto: this._predmetStavkeFS.GetPredmetStavkeFormGroup(
        new PredmetStavkeFormGroupRequest({
          model: model?.predmetStavkeDto,
          tipPredmeta: TipPredmeta.ROBA,
          isSifraRequired: false,
        })
      ),
      kolicina: new FormControl(model?.kolicina ?? 1),
      proizvodId: new FormControl(model?.proizvodId),
      // materijalId: new FormControl(model?.materijalId),
      id: new FormControl(model?.id ?? 0),
    });
  }
}
