import { Component, OnInit } from '@angular/core';
import {
  IUpdateUserAccessPasswordCommand,
  IdentityClient,
  UpdateUserAccessPasswordCommand,
} from '@kodit/core/data-api';
import {
  FormHelper,
  UpdatePasswordFormService,
} from '@kodit/core/form-definitions';
import { AlertService, AuthService } from '@kodit/core/services';
import { DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-update-password-form',
  templateUrl: './update-password-form.component.html',
  styleUrls: ['./update-password-form.component.scss'],
})
export class UpdatePasswordFormComponent implements OnInit {
  /** Subs */
  private _saveSub: Subscription = new Subscription();
  private _submitSub: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<IUpdateUserAccessPasswordCommand>;

  constructor(
    private _fs: UpdatePasswordFormService,
    private _dialogRef: DynamicDialogRef,
    private _alertService: AlertService,
    private _formHelper: FormHelper,
    private _client: IdentityClient,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.form = this._fs.GetFormGroup(this._authService.getUserData.email);

    // // subscribe on dialog save button
    this._submitSub = this._dialogRef.onSubmit.subscribe(() => {
      this.save();
    });
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.save();
    }
  }
  private save() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }
    this._saveSub = this._client
      .updateUserAccessPassword(
        this.form.value as UpdateUserAccessPasswordCommand,
        this._authService.getCurrentTenant
      )
      .subscribe((result) => {
        if (result.succeeded) {
          this._alertService.addSuccessMsg('Lozinka je uspešno promenjena.');
          this._dialogRef.close();
        } else {
          this._alertService.addFailedMsg(result.messages.join(', '));
        }
      });
  }

  ngOnDestroy(): void {
    this._submitSub.unsubscribe();
    this._saveSub.unsubscribe();
  }
}
