import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PdvEvidencijaStatus } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';

const statusMap: Map<PdvEvidencijaStatus, string> = new Map<
  PdvEvidencijaStatus,
  string
>([
  [PdvEvidencijaStatus.U_PRIPREMI, 'U pripremi'],
  [PdvEvidencijaStatus.EVIDENTIRANO, 'Evidentirano'],
  [PdvEvidencijaStatus.KORIGOVANO, 'Korigovano'],
  [PdvEvidencijaStatus.PONISTENO, 'Poništeno'],
]);

@Component({
  selector: 'kodit-status-evidencije-multiselect',
  templateUrl: './status-evidencije-multiselect.component.html',
  styleUrls: ['./status-evidencije-multiselect.component.scss'],
})
export class StatusEvidencijeMultiselectComponent implements OnInit {
  //* Props */
  items: SelectItem[] | undefined;
  selectedItems: PdvEvidencijaStatus[] = [];

  /** I/O */
  @Input() tipFormArray: AbstractControl | undefined;
  @Input() formCtrlId: string | undefined;
  @Input() parentCtrl: any = 'body';
  @Input() isPPPDV: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.tipFormArray?.value) {
      this.selectedItems = [...this.tipFormArray?.value];
    }
    this._load();
  }

  private _load() {
    this.items = [];
    for (let tipEntry of statusMap.entries()) {
      // za ovaj tip evidencije ne postoji status ponisteno(mozda kasnije SEF uvede)
      if(this.isPPPDV && tipEntry[0] === PdvEvidencijaStatus.PONISTENO){
        continue;
      }
      this.items?.push({ value: tipEntry[0], label: tipEntry[1] });
    }
  }

  selectedItemsOnChange() {
    this.tipFormArray?.setValue(this.selectedItems);
  }
}
