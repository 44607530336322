import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';
import { PeriodZaPrikazRacuna } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-period-za-prikaz-racuna-dropdown',
  templateUrl: './period-za-prikaz-racuna-dropdown.component.html',
  styleUrls: ['./period-za-prikaz-racuna-dropdown.component.scss'],
})
export class PeriodZaPrikazRacunaDropdownComponent implements OnInit {
  /** Props */
  items: SelectItem[];

  /** I/O */
  @Input() periodCtrl: FormControl | AbstractControl;
  @Input() parent: any = 'body';
  @Output() onItemChange = new EventEmitter<PeriodZaPrikazRacuna>();

  constructor() {}

  ngOnInit(): void {
    this.items = [
      { label: 'Trenutna godina', value: 0 },
      { label: 'Kvartalno', value: 1 },
      { label: 'Tromesečno', value: 2 },
    ];
  }

  handleOnItemChange(event: any) {
    this.onItemChange.emit(event);
  }
}
