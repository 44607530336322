<div class="p-fluid">
  <span class="p-input-icon-left input-box">
    <p-autoComplete
      [ngClass]="!lokacijaModel && formSubmitted ? 'test-invalid' : ''"
      [(ngModel)]="lokacijaModel"
      [suggestions]="lokacijeSuggestions"
      (onSelect)="onSelectChange($event)"
      placeholder="Naziv fizičke lokacije..."
      inputStyleClass="input-el"
      (completeMethod)="searchLokacija($event)"
      appendTo="body"
      field="punNaziv"
      (onClear)="handleInputClear()"
      dropdown="true"
    >
    </p-autoComplete>
  </span>
</div>

<small *ngIf="!lokacijaModel && formSubmitted" class="p-invalid">{{
  errorMsg
}}</small>
