<form [formGroup]="form">
  <div class="p-fluid">
    <!-- Redni broj -->
    <div class="p-grid">
      <kodit-field fieldLabel="Redni broj" class="p-col-12 p-md-6 p-lg-6">
        <kodit-number-input
          [formCtrl]="form.controls.redniBroj"
          [ctrlMinValue]="minValueRedniBroj!"
          [minFractionDigits]="0"
        ></kodit-number-input>
      </kodit-field>
    </div>

    <!-- Godina nastanka / Datum upisa -->
    <div class="p-grid">
      <kodit-field
        fieldLabel="Nastanak"
        [ngClass]="
          isOspegSelected ? 'p-col-12 p-md-3 p-lg-3' : 'p-col-12 p-md-4 p-lg-4'
        "
      >
        <kodit-dropdown
          formCtrlId="godina-opseg-option-dropdown"
          [formCtrl]="godinaOptionCtrl"
          [options]="godinaOptions"
        ></kodit-dropdown>
      </kodit-field>
      <!-- Godina nastanka -->
      <kodit-field
        [fieldLabel]="isOspegSelected ? 'Od' : 'Godina nastanka'"
        [ngClass]="
          isOspegSelected ? 'p-col-12 p-md-3 p-lg-3' : 'p-col-12 p-md-4 p-lg-4'
        "
      >
        <kodit-dropdown
          [options]="godineItems"
          [formCtrl]="form.controls.godinaNastanka!"
          formCtrlId="godina-nastanka"
        ></kodit-dropdown>
      </kodit-field>
      <kodit-field
        *ngIf="isOspegSelected"
        fieldLabel="Do"
        [ngClass]="
          isOspegSelected ? 'p-col-12 p-md-3 p-lg-3' : 'p-col-12 p-md-4 p-lg-4'
        "
      >
        <kodit-dropdown
          [options]="godineItems"
          [formCtrl]="form.controls.godinaNastankaDo!"
          formCtrlId="godina-nastanka-do"
        ></kodit-dropdown>
      </kodit-field>
      <!-- Datum upisa -->
      <kodit-field
        fieldLabel="Datum upisa"
        [ngClass]="
          isOspegSelected ? 'p-col-12 p-md-3 p-lg-3' : 'p-col-12 p-md-4 p-lg-4'
        "
      >
        <kodit-calendar
          ctrlPlaceholder="Datum upisa"
          formCtrlId="datumUpisa"
          [formCtrl]="form.controls.datumUpisa!"
        ></kodit-calendar>
      </kodit-field>
    </div>

    <!-- Br. saglasnosi/Klas. oznaka -->
    <div class="p-grid">
      <kodit-field class="p-col-12 p-md-6 p-lg-6" fieldLabel="Broj saglasnosti">
        <kodit-text-input
          [formCtrl]="form.controls.brojSaglasnosti"
          ctrlPlaceholder="Broj saglasnosti..."
        ></kodit-text-input>
      </kodit-field>

      <kodit-field
        class="p-col-12 p-md-6 p-lg-6"
        fieldLabel="Klasifikaciona oznaka"
      >
        <kodit-text-input
          [formCtrl]="form.controls.klasifikacionaOzanka"
          ctrlPlaceholder="Klasifikaciona oznaka"
        ></kodit-text-input>
      </kodit-field>
    </div>

    <!-- Sadrzaj / Rok cuvanja -->
    <div class="p-grid">
      <kodit-field fieldLabel="Sadržaj" class="p-col-6 p-md-6 p-lg-6">
        <kodit-klasifikaciona-oznaka-autocomplete
          [klasifikacionaOznakaInput]="form.controls.kategorijaDto"
        ></kodit-klasifikaciona-oznaka-autocomplete>
      </kodit-field>

      <kodit-field
        fieldLabel="Rok čuvanja (broj meseci)"
        class="p-col-6 p-md-6 p-lg-6"
      >
        <kodit-number-input
          *ngIf="
            form.controls.rokCuvanja?.value! > 0 ||
            form.controls.rokCuvanja?.value == null
          "
          [formCtrl]="form.controls.rokCuvanja"
          [minFractionDigits]="0"
          [isDisabled]="true"
        ></kodit-number-input>
        <input
          *ngIf="form.controls.rokCuvanja?.value! == 0"
          #textInput
          kodit-select-all-on-focus
          pInputText
          placeholder="Trajno"
          autocomplete="off"
          disabled
          class="trajno-text"
        />
      </kodit-field>
    </div>

    <!-- Kolicina / Skladisna jedinica -->
    <div class="p-grid">
      <kodit-field
        fieldLabel="Količina dokumentarnog materijala"
        class="p-col-12 p-md-6 p-lg-6"
      >
        <kodit-number-input
          [formCtrl]="form.controls.kolicinaDokumentarnogMaterijala"
          [minFractionDigits]="0"
        ></kodit-number-input>
      </kodit-field>

      <kodit-field
        fieldLabel="Skladišna jedinica"
        class="p-col-12 p-md-6 p-lg-6"
      >
        <kodit-skladisna-jedinica-autocomplete
          [skladisnaJedinicaInput]="form.controls.skladisnaJedinicaDto"
        ></kodit-skladisna-jedinica-autocomplete>
      </kodit-field>
    </div>

    <!-- Fizicke lokacije -->
    <div>
      <kodit-field fieldLabel="Fizička lokacija">
        <kodit-fizicke-lokacije-autocomplete
          [jeArhivskaKnjigaForm]="true"
          [fizickaLokacijaInput]="form.controls.fizickaLokacijaDto"
        ></kodit-fizicke-lokacije-autocomplete>
      </kodit-field>
    </div>

    <!-- Zalba -->
    <div>
      <kodit-field fieldLabel="Žalbe">
        <kodit-textarea-input
          [formCtrl]="form.controls.primedba"
          ctrlPlaceholder="Unesite žalbu..."
        ></kodit-textarea-input>
      </kodit-field>
    </div>
  </div>
</form>
