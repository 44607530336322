import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { BaseService } from '@kodit/core/services';
import {
  AvansniRacunDto,
  AvansniRacuniClient,
  GetAvansniRacunQuery,
} from '@kodit/core/data-api';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AvansniRacunResolver
  extends BaseService
  implements Resolve<AvansniRacunDto> {
  constructor(private _client: AvansniRacuniClient) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<AvansniRacunDto>
    | Promise<AvansniRacunDto>
    | AvansniRacunDto
    | null {
    const id = Number.parseInt(route.paramMap.get('id'));
    return this._client.get(new GetAvansniRacunQuery({ id: id })).pipe(
      map((x) => x.data),
      catchError(this.handleError),
      finalize(() => {
        //this._configService.setIsBusy = false;
      })
    );
  }
}
