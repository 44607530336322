import { Component, OnDestroy, OnInit } from '@angular/core';
import { ArhivskaTableResponse, ArhivskaKnjigaClient, ArhivskaDokumentaFormDto, GetZipedDocumentsQuery } from '@kodit/core/data-api';
import { DynamicDialogRef } from '@kodit/core/shared-ui';
import { ArhivskaDokumentaForm, ArhivskaKnjigaService } from 'libs/core/moduli/podesavanje/src/lib/arhivska-knjiga/arhivska-knjiga-service';
import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'kodit-arhivska-dokumenti-dialog',
  templateUrl: './arhivska-dokumenti-dialog.component.html',
  styleUrls: ['./arhivska-dokumenti-dialog.component.scss'],
})
export class ArhivskaDokumentiDialogComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Forms */
  form: ArhivskaDokumentaForm;

  constructor(
    private _service: ArhivskaKnjigaService,
    private _client: ArhivskaKnjigaClient,
    private _dialogRef: DynamicDialogRef,
    private _loader: LoaderService
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._client.getArhivskaDokumentaForm().subscribe((res) => {
        if (res.succeeded) {
          this.form = this._service.getArhivskaDokumentaForm(res.data!);
        }
      })
    );

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );
  }

  private _save() {
    // everything is ok , let's sync it
    this._loader.setLoadingText = 'Preuzimanje je u toku...';
    this._loader.setShowLoader = true;
    this._subs.add(
      this._client
        .getArhivskaZipedFiles(
          new GetZipedDocumentsQuery({
            dto: new ArhivskaDokumentaFormDto(this.form.value)
          })
        )
        .pipe(
          finalize(() => {
            this._loader.reset();
          })
        )
        .subscribe((res) => {
           var a = document.createElement('a');
           a.href = URL.createObjectURL(res.data);
           a.download =
             'Potrebna dokumentacija za dobijanje saglasnosti nadležnog ministarstva';
           a.click();
          this._dialogRef.close(true);
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
