<form [formGroup]="form">
  <kodit-email-receivers-form
    [strankaId]="strankaId"
    [toFormArray]="form.controls.to"
    [ccFormArray]="form.controls.cc"
    [naslovEmail]="form.controls.emailNaslov"
    [customPoruka]="form.controls.customPoruka"
    [attachmentsFormArray]="form.controls.attachments"
    [fakturaNaEngleskom]="form.controls.stampatiNaEngleskom"
  ></kodit-email-receivers-form>
</form>
