<div class="p-fluid">
  <div class="p-field">
    <label for="drzava-select" kodit-form-label>Država</label>

    <kodit-dropdown
      formCtrlId="drzava-select"
      [options]="drzaveOptions"
      [formCtrl]="drzavaCtrl"
      [canFilter]="true"
      ctrlFilterBy="naziv,alfaChar"
      ctrlPlaceholder="Izaberite državu"
      (optionChanged)="drzavaChanged($event)"
    >
      <ng-template #selectedItem let-obj>
        <div class="p-text-left">{{ obj.naziv }}</div>
      </ng-template>

      <ng-template #item let-obj>
        <div class="p-text-left">
          {{ obj.naziv }}
          <div class="p-text-bold">({{ obj.alfaChar }})</div>
        </div>
      </ng-template>
    </kodit-dropdown>
  </div>
</div>
