import { Injectable } from '@angular/core';
import {
  BasicNotification,
  EsdcCardStatusChangeNotification,
  JobBegginingEndingNotification,
  JobNotification,
  NotificationMessageType,
  SefHealthNotification,
  SessionTokenNotification,
  StatsChangedNotification,
  TableColumnIconDto,
} from '@kodit/core/data-api';
import { DynamicDialogConfig } from '@kodit/core/shared-ui';
import * as signalR from '@microsoft/signalr';
import { environment } from 'apps/core/src/environments/environment';
import { FiskalniRacunService } from 'libs/core/moduli/maloprodaja/src/lib/fiskalni-racun/fiskalni-racun.service';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { SESSION_ID_NAME } from '..';
import { NotificationService } from './notification.service';
import { TokenService } from './security/token.service';
import { SefService } from './sef.service';
import { RatingService } from './rating.service';
import { ProgressService } from 'libs/core/core-ui/src/lib/progress.service';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  /** Props */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  private _hubConnection: signalR.HubConnection;

  constructor(
    private _notificationService: NotificationService,
    private _sefService: SefService,
    private _tokenService: TokenService,
    private _ratingService: RatingService,
    private _progressService: ProgressService,
    private _fiskalniService: FiskalniRacunService
  ) {}

  public startConnection = (tenantKey: string, token: string) => {
    // (
    //   `Client [${tenantKey}] starting connection for notifications...`
    // );
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiBaseUrl}/notifications?access_token=${token}`)
      .build();

    this._hubConnection
      .start()
      .then(() => {
        this._addNotificationListener();
      })
      .catch(
        (err) =>
          `Client [${tenantKey}] got error while starting connection: ${err}`
      );
  };

  public closeConnection() {
    if (this._hubConnection) {
      this._hubConnection.stop().then();
      this._hubConnection = undefined;
    }
  }

  private _addNotificationListener = () => {
    this._hubConnection.on(
      'NOTIFICATION_FROM_SERVER',
      (type: NotificationMessageType, data: any) => {
        switch (type) {
          case NotificationMessageType.STATUS_CHANGE:
            const statusChangeData: StatsChangedNotification =
              data as StatsChangedNotification;
            this._notificationService.addTopbarNotifications([
              {
                id: statusChangeData.id,
                jeProcitana: false,
                naslov: statusChangeData.title,
                tekst: statusChangeData.message,
                iconDto: new TableColumnIconDto({
                  backgroundColor: statusChangeData.backgroundColor,
                  iconColor: statusChangeData.iconColor,
                  iconName: statusChangeData.iconName,
                }),
                actionUrl: statusChangeData.actionUrl,
              },
            ]);
            break;

          case NotificationMessageType.JOB_BEGGINING_ENDING:
            const jobBegginingEndingData = data as JobBegginingEndingNotification;
            if (jobBegginingEndingData.jePocetak) {
              this._progressService.addProgresses(
                jobBegginingEndingData.vrstaJoba
              );
            } else {
              this._progressService.deleteProgress(
                jobBegginingEndingData.vrstaJoba
              );
            }
            break;

          case NotificationMessageType.JOB:
            const jobData = data as JobNotification;
            this._progressService.updateProgress(
              jobData.vrstaJoba,
              jobData.progress
            );
            break;

          case NotificationMessageType.BASIC:
            const basicData = data as BasicNotification;
            break;

          case NotificationMessageType.SEF_ACTIVITY:
            const sefActivityData = data as SefHealthNotification;
            this._sefService.setIsSefActive = {
              isActive: sefActivityData.isActive,
              background: sefActivityData.backgroundColor,
            };
            break;

          case NotificationMessageType.SESSION_TOKEN_VALIDITY:
          const sessionToken = data as SessionTokenNotification;
            this._tokenService.setShowLogoutDialog =
              sessionToken.sessionId !== localStorage.getItem(SESSION_ID_NAME);
            break;

          // case NotificationMessageType.ESDC_CARD_STATUS_CHANGE:
          //   const esdcCardStatusData = data as EsdcCardStatusChangeNotification;
          //   this._fiskalniService.setEsdcCardStatus = esdcCardStatusData;
          //   break;

          case NotificationMessageType.RATING:
            this._ratingService.setCheckUserRating = true;
            break;

          default:
            return;
        }
      }
    );
  };
}
