import { Injectable } from '@angular/core';
import {
  IPdvEvidencijaZbirna39Dto,
  IPoreskiDuznikIsporucilacPrimalacDto,
  PdvEvidencijaStatus,
  PdvPeriod,
  PoreskiDuznikIsporucilacPrimalacDto,
} from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { PdvEvidencijaZbirna39Service } from './pdv-evidencija-zbrina39.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class PdvEvidencijaZbirna39FormService {
  /* Props */
  private _form!: FormGroupTypeSafe<IPdvEvidencijaZbirna39Dto>;
  private _isInitialized: boolean;

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _service: PdvEvidencijaZbirna39Service
  ) {
    this._isInitialized = false;
  }

  getForm(
    model: IPdvEvidencijaZbirna39Dto,
    shouldDisableControls: boolean
  ): FormGroupTypeSafe<IPdvEvidencijaZbirna39Dto> {
    if (!this._isInitialized) {
      this._init(model, shouldDisableControls);
      this._isInitialized = true;
    }
    this._service.setPdvForm = this._form;
    return this._form;
  }
  get jeEdit(): boolean {
    if (!this._form) {
      return false;
    }
    return this._form!.value!.id! > 0;
  }

  private _init(
    model: IPdvEvidencijaZbirna39Dto,
    shouldDisableComponents: boolean
  ) {
    this._form = this._fb.group<IPdvEvidencijaZbirna39Dto>({
      //   Common
      broj: new FormControl(
        {
          value: model?.broj,
          disabled: shouldDisableComponents,
        },
        {
          validators: [Validators.required],
        }
      ),
      godina: new FormControl(model?.godina ?? new Date().getFullYear(), {
        validators: [Validators.required],
      }),
      status: new FormControl(model?.status ?? PdvEvidencijaStatus.U_PRIPREMI),
      datumPromeneStatusa: new FormControl(
        model?.datumPromeneStatusa ?? new Date(),
        {
          validators: [Validators.required],
        }
      ),
      id: new FormControl(model?.id),
      period: new FormControl(model?.period ?? PdvPeriod.JANUAR),
      //   Avans za promet
      avansIznosUplate20: new FormControl(model?.avansIznosUplate20 ?? 0 ,{validators: [Validators.required]}),
      avansOsnovica20: new FormControl(model?.avansOsnovica20 ?? 0,{validators: [Validators.required]}),
      avansObracunatiPdv20: new FormControl(model?.avansObracunatiPdv20 ?? 0 ,{validators: [Validators.required]}),

      avansIznosUplate10: new FormControl(model?.avansIznosUplate10 ?? 0 ,{validators: [Validators.required]}),
      avansOsnovica10: new FormControl(model?.avansOsnovica10 ?? 0 ,{validators: [Validators.required]}),
      avansObracunatiPdv10: new FormControl(model?.avansObracunatiPdv10 ?? 0 ,{validators: [Validators.required]}),

      //   Promet uz naknadu
      prometSaNaknadomOsnovica20: new FormControl(
        model?.prometSaNaknadomOsnovica20 ?? 0 ,{validators: [Validators.required]}
      ),
      prometSaNaknadomObracunatiPdv20: new FormControl(
        model?.prometSaNaknadomObracunatiPdv20 ?? 0 ,{validators: [Validators.required]}
      ),

      prometSaNaknadomOsnovica10: new FormControl(
        model?.prometSaNaknadomOsnovica10 ?? 0 ,{validators: [Validators.required]}
      ),
      prometSaNaknadomObracunatiPdv10: new FormControl(
        model?.prometSaNaknadomObracunatiPdv10 ?? 0 ,{validators: [Validators.required]}
      ),
      // Promet bez naknade
      prometBezNaknadeOsnovica20: new FormControl(
        model?.prometBezNaknadeOsnovica20 ?? 0 ,{validators: [Validators.required]}
      ),
      prometBezNaknadeObracunatiPdv20: new FormControl(
        model?.prometBezNaknadeObracunatiPdv20 ?? 0 ,{validators: [Validators.required]}
      ),
      //
      prometBezNaknadeOsnovica10: new FormControl(
        model?.prometBezNaknadeOsnovica10 ?? 0 ,{validators: [Validators.required]}
      ),
      prometBezNaknadeObracunatiPdv10: new FormControl(
        model?.prometBezNaknadeObracunatiPdv10 ?? 0 ,{validators: [Validators.required]}
      ),
      // Turisticke usluge
      osnovica20Tur: new FormControl(model?.osnovica20Tur ?? 0 ,{validators: [Validators.required]}),
      obracunatiPdv20Tur: new FormControl(model?.obracunatiPdv20Tur ?? 0 ,{validators: [Validators.required]}),
      avansOsnovica20Tur: new FormControl(model?.avansOsnovica20Tur ?? 0 ,{validators: [Validators.required]}),
      avansObracunatiPdv20Tur: new FormControl(
        model?.avansObracunatiPdv20Tur ?? 0 ,{validators: [Validators.required]}
      ),
      // Polovna dobra, umetnicka dela, kolekcionarska dobra i antikviteti
      osnovica20Art: new FormControl(model?.osnovica20Art ?? 0 ,{validators: [Validators.required]}),
      obracunatiPdv20Art: new FormControl(model?.obracunatiPdv20Art ?? 0 ,{validators: [Validators.required]}),
      avansOsnovica20Art: new FormControl(model?.avansOsnovica20Art ?? 0 ,{validators: [Validators.required]}),
      avansObracunatiPdv20Art: new FormControl(
        model?.avansObracunatiPdv20Art ?? 0 ,{validators: [Validators.required]}
      ),
      osnovica10Art: new FormControl(model?.osnovica10Art ?? 0 ,{validators: [Validators.required]}),
      obracunatiPdv10Art: new FormControl(model?.obracunatiPdv10Art ?? 0 ,{validators: [Validators.required]}),
      avansOsnovica10Art: new FormControl(model?.avansOsnovica10Art ?? 0 ,{validators: [Validators.required]}),
      avansObracunatiPdv10Art: new FormControl(
        model?.avansObracunatiPdv10Art ?? 0 ,{validators: [Validators.required]}
      ),
      //   Isporucilac/Primalac
      povecanjeSmanjenjeIsporucilac: this._getIsporucilacPrimalacFormGroup(
        model?.povecanjeSmanjenjeIsporucilac
      ),
      povecanjeSmanjenjePrimalac: this._getIsporucilacPrimalacFormGroup(
        model?.povecanjeSmanjenjePrimalac
      ),
      storniranjeIsporucilac: this._getIsporucilacPrimalacFormGroup(
        model?.storniranjeIsporucilac
      ),
      storniranjePrimalac: this._getIsporucilacPrimalacFormGroup(
        model?.storniranjePrimalac
      ),
    });
  }
  private _getIsporucilacPrimalacFormGroup(
    dto: PoreskiDuznikIsporucilacPrimalacDto | undefined
  ): FormGroupTypeSafe<IPoreskiDuznikIsporucilacPrimalacDto> {
    return this._fb.group<IPoreskiDuznikIsporucilacPrimalacDto>({
      id: new FormControl(dto?.id),
      //   Povecanje
      povecanjeOsnovica20: new FormControl(dto?.povecanjeOsnovica20 ?? 0 ,{validators: [Validators.required]}),
      povecanjePdv20: new FormControl(dto?.povecanjePdv20 ?? 0 ,{validators: [Validators.required]}),
      povecanjeOsnovica10: new FormControl(dto?.povecanjeOsnovica10 ?? 0 ,{validators: [Validators.required]}),
      povecanjePdv10: new FormControl(dto?.povecanjePdv10 ?? 0 ,{validators: [Validators.required]}),
      //   Smanjenje
      smanjenjeOsnovica20: new FormControl(dto?.smanjenjeOsnovica20 ?? 0 ,{validators: [Validators.required]}),
      smanjenjePdv20: new FormControl(dto?.smanjenjePdv20 ?? 0 ,{validators: [Validators.required]}),
      smanjenjeAvansOsnovica20: new FormControl(
        dto?.smanjenjeAvansOsnovica20 ?? 0 ,{validators: [Validators.required]}
      ),
      smanjenjeAvansPdv20: new FormControl(dto?.smanjenjeAvansPdv20 ?? 0 ,{validators: [Validators.required]}),
      smanjenjeOsnovica10: new FormControl(dto?.smanjenjeOsnovica10 ?? 0 ,{validators: [Validators.required]}),
      smanjenjePdv10: new FormControl(dto?.smanjenjePdv10 ?? 0 ,{validators: [Validators.required]}),
      smanjenjeAvansOsnovica10: new FormControl(
        dto?.smanjenjeAvansOsnovica10 ?? 0 ,{validators: [Validators.required]}
      ),
      smanjenjeAvansPdv10: new FormControl(dto?.smanjenjeAvansPdv10 ?? 0 ,{validators: [Validators.required]}),
    });
  }

  resetForm() {
    this._service.resetForm();
    this._isInitialized = false;
    this._init({}, false);
  }
}
