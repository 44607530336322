<form [formGroup]="form">
  <div class="p-d-flex p-flex-column p-fluid gap-12">
    <!-- Naziv pravila -->
    <div>
      Naziv će biti generisan po pravilu:
      <strong>{{ delovodnikDto?.nazivPravila }}</strong>
    </div>
    <!-- Podbroj i kategorija -->
    <div class="p-d-flex p-flex-row p-justify-between p-fluid gap-6">
      <div class="flex-1">
        <kodit-text-input-field
          [formCtrl]="form.controls.podbroj!"
          formCtrlId="podbroj"
          formCtrlLabel="Podbroj"
        ></kodit-text-input-field>
      </div>
      <div class="flex-1">
        <kodit-field fieldLabel="Kategorija dokumentarnog materijala">
          <kodit-dokumentarna-kategorija-autocomplete
            [formCtrl]="form.controls.kategorijaId"
            [selectedKategorija]="delovodnikDto?.kategorijaNaziv"
            [jeUnosDelovodnogDokumenta]="true"
          ></kodit-dokumentarna-kategorija-autocomplete>
        </kodit-field>
      </div>
    </div>
    <!-- Datum nastanka i unosa -->
    <div class="p-d-flex p-flex-row p-justify-between p-fluid gap-6">
      <div class="flex-1">
        <kodit-field fieldLabel="Datum nastanka">
          <kodit-calendar
            [formCtrl]="form.controls.datumNastanka!"
            class="p-fluid"
            formCtrlId="datumnastanka"
          ></kodit-calendar>
        </kodit-field>
      </div>
      <div class="flex-1">
        <kodit-field fieldLabel="Datum unosa">
          <kodit-calendar
            [formCtrl]="form.controls.datumUnosa!"
            class="p-fluid"
            formCtrlId="datumUnosa"
          ></kodit-calendar>
        </kodit-field>
      </div>
    </div>
    <!-- Stranka -->
    <div>
     <kodit-posaljilac-dokumenta-autocomplete [formCtrl]="form.controls.posaljilacId"></kodit-posaljilac-dokumenta-autocomplete>
    </div>
    <!-- Upload fajla -->
    <div>
       <kodit-field
    fieldLabel="Dodatni prilog"
    fieldLabelFor="attachment"
  >
    <kodit-file-upload
      (onUpload)="onUpload($event)"
      fileTypes=".jpg,.png,.jpeg,.pdf"
      [shouldAutoUpload]="true"
      [allowMultipleFiles]="false"
    ></kodit-file-upload>
  </kodit-field>
    </div>
  </div>
</form>
