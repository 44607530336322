import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AddKorisnikeToGrupaCommand,
  IKorisnikAutocompleteDto,
  IKorisnikZaGrupuDto,
  KorisniciClient,
  RobeClient,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogRef, DynamicDialogConfig } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-dodavanje-korisnika-form',
  templateUrl: './dodavanje-korisnika-form.component.html',
  styleUrls: ['./dodavanje-korisnika-form.component.scss'],
})
export class DodavanjeKorisnikaFormComponent implements OnInit, OnDestroy {
  /** Props */
  private _subs: Subscription = new Subscription();
  korisniciZaGrupu: IKorisnikZaGrupuDto[] = [];
  nazivGrupe: string;

  constructor(
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _client: KorisniciClient
  ) {}

  ngOnInit(): void {
    this.nazivGrupe = this._dialogConfig.data.nazivGrupe;
    this._load();
    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );
  }

  private _save() {
    this._subs.add(
      this._client
        .addKorisnikeToGrupa(
          new AddKorisnikeToGrupaCommand({
            korisnikIdList: this.korisniciZaGrupu.map((x) => x.id),
            nazivGrupe: this.nazivGrupe,
          })
        )
        .subscribe(
          (res) => {
            this._alertService.addSuccessMsg(res.messages[0]);
            this._dialogRef.close(res.data);
          },
          (error) => {
            this._subs.add(
              error.subscribe((x: string) => {
                this._alertService.addFailedMsg(x);
              })
            );
          }
        )
    );
  }

  handleKorisnikChange(korisnik: IKorisnikAutocompleteDto) {
    if (this.korisniciZaGrupu.findIndex((x) => x.id === korisnik.id) === -1) {
      this.korisniciZaGrupu.push(korisnik);
    }
  }

  private _load() {
    this._subs.add(
      this._client.getKorisnikeZaGrupu(this.nazivGrupe).subscribe(
        (res) => {
          this.korisniciZaGrupu = res.data.korisnikZaGrupuDtoList;
        },
        (error) => {
          this._subs.add(
            error.subscribe((x: string) => {
              this._alertService.addFailedMsg(x);
            })
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
