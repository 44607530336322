<div class="p-inputgroup" *ngIf="!formCtrlDisabled">
  <span class="p-inputgroup-addon">{{
    mobilniFormGroup.value.pozivniBroj
    }}</span>
  <input
    [id]="formCtrlId"
    pInputText
    [formControl]="mobilniFormGroup.controls.brojTelefona"
    autocomplete="off"
    type="text"
    pKeyFilter="pint"
  />
</div>

<div class="p-inputgroup" *ngIf="formCtrlDisabled">
  <span class="p-inputgroup-addon">{{
    mobilniFormGroup.value.pozivniBroj
    }}</span>
  <input
    [id]="formCtrlId"
    pInputText
    [formControl]="mobilniFormGroup.controls.brojTelefona"
    autocomplete="off"
    type="text"
    pKeyFilter="pint"
    disabled
  />
</div>

<kodit-error-info-required
  [formCtrl]="mobilniFormGroup.controls.brojTelefona"
></kodit-error-info-required>
<small
  *ngIf="
    mobilniFormGroup.controls.brojTelefona.errors?.pattern &&
    mobilniFormGroup.controls.brojTelefona.touched
  "
  class="p-invalid"
>Mobilni telefon nije u validnom formatu.</small
>
