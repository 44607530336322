import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IPrviLoginMojaFirmaCommand} from '@kodit/core/data-api';
import {PdvStopaService} from '@kodit/core/shared-ui';
import {FormGroupTypeSafe} from 'angular-typesafe-reactive-forms-helper';
import {SelectItem} from 'primeng/api';
import {StopaPdv} from '@kodit/core/shared';
import {Subscription} from "rxjs";

@Component({
  selector: 'kodit-prvi-login-kompanija-form',
  templateUrl: './prvi-login-kompanija-form.component.html',
  styleUrls: ['./prvi-login-kompanija-form.component.scss'],
})
export class PrviLoginKompanijaFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  options: SelectItem[];

  /** I/O */
  @Input() form: FormGroupTypeSafe<IPrviLoginMojaFirmaCommand>;

  /** Conditional props */
  shouldShowRazlogPdvIzuzeca: boolean = false;

  constructor(private _pdvService: PdvStopaService) {
  }

  ngOnInit(): void {
    this.options = [
      {label: 'Da', value: true},
      {label: 'Ne', value: false},
    ];

    this._subs.add(this.form.controls.uSistemuPDVa.valueChanges.subscribe((uPdv: boolean) => {
      this._pdvService.setJeKompanijaUPdv = uPdv;
      if (uPdv) {
        this.form.controls.stopaPDV.enable();
        this.form.controls.stopaPDV.patchValue(StopaPdv.OSNOVNA);
      } else {
        this.form.controls.stopaPDV.disable();
        this.form.controls.stopaPDV.patchValue(StopaPdv.BEZ_PDV);
      }
    }));

    this._subs.add(
      this.form.controls.stopaPDV.valueChanges.subscribe((res)=>{
        this._setShouldShowRazlogPdvIzuzeca(res);
      })
    );
  }

  private _setShouldShowRazlogPdvIzuzeca(stopa: StopaPdv) {
    if(stopa == StopaPdv.BEZ_PDV){
      this.shouldShowRazlogPdvIzuzeca = true;
    }
    else{
      this.shouldShowRazlogPdvIzuzeca = false;
      this.form.controls.oznakaPdvKategorije.patchValue(null);
      this.form.controls.razlogPdvIzuzecaId.patchValue(null);
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
