import { Injectable } from '@angular/core';
import { TableColumnIconDto } from '@kodit/core/data-api';
import { JoyrideService } from 'ngx-joyride';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Tutorial {
  naziv: string | undefined;
  iconDto?: TableColumnIconDto | undefined;
  steps: string[];
}

export interface TutorialService {
  prepareTutorials(currentRoute: string): void;
  tutorials$: BehaviorSubject<Tutorial[] | []>;
  getTutorials(curretnRoute: string): Observable<Tutorial[] | []>;
  set setTutorials(value: Tutorial[] | []);
}

@Injectable({ providedIn: 'root' })
export class JoyrideHandleService {
  constructor(
    private _joyrideService: JoyrideService,
  ) {}

  startTutorial(steps: string[]) {
    this._joyrideService.startTour({
      steps: steps,
      customTexts: {
        next: 'Sledeći korak',
        prev: 'Prethodni korak',
        done: 'Kraj',
      },
      stepDefaultPosition: 'left',
      waitingTime: 100,
      //themeColor: '#05194099'
    });
  }
}
