<ng-container >
    <div
      class="k-card"
      card
    >
      <div  *ngFor="let stanje of stanjaLagera" class="promet-card p-d-flex p-jc-between p-ai-center p-flex-wrap">
        <div class="p-d-flex p-flex-column" style="width:250px;"  >
          <div class="p-pr-2 p-label p-mb-2 f-w-400">Magacin:</div>
          <div
            class="p-text-left p-mb-2"
            style="font-size: 1.2rem; font-weight: 500; min-height: 28px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
          >
            {{ stanje.nazivMagacina }}
          </div>
        </div>
        <div class="p-d-flex p-flex-column">
          <div class="p-pr-2 p-label p-mb-2 f-w-400" >Raspoloživo:</div>
          <div
            class="p-text-left p-mb-2"
            style="font-size: 1.2rem; font-weight: 500"
          >
            {{ stanje.slobodno }}
          </div>
        </div>
        <div class="p-d-flex p-flex-column">
          <div class="p-pr-2 p-label p-mb-2 f-w-400" >Rezervisano:</div>
          <div
            class="p-text-left p-mb-2"
            style="font-size: 1.2rem; font-weight: 500"
          >
            {{ stanje.rezervisano }}
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-mb-2">
          <div class="p-pr-2 p-label p-mb-2 f-w-400">Pros. ponderisana cena:</div>
          <div
            style="font-size: 1.2rem; font-weight: 500"
          >
            {{ stanje.cena }} RSD
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-mb-2">
          <div class="p-pr-2 p-label p-mb-2 f-w-400">Vrednost stanja</div>
          <div
            style="font-size: 1.2rem; font-weight: 500"
          >
          {{ stanje.vrednost }} RSD
          </div>
        </div>

      </div>
    </div>
    <p-skeleton width="10rem" styleClass="p-mb-2"></p-skeleton>
    <kodit-divider></kodit-divider>
</ng-container>
