import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  GetStrankeQuery,
  IStrankaDto,
  StrankaClient,
  StrankaDto,
  TipStranke,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { BaseStrankaFormService } from 'libs/core/form-definitions/src';

@Component({
  selector: 'kodit-stranka-muliselect',
  templateUrl: './stranka-muliselect.component.html',
  styleUrls: ['./stranka-muliselect.component.scss'],
})
export class StrankaMuliselectComponent implements OnDestroy, OnChanges {
  /** Subs */
  private _searchSub: Subscription = new Subscription();

  /** Props */
  strankeSuggestions: any[] = [];
  strankeModel: StrankaDto[] = [];

  /** I/O */
  @Input() strankeFormArray: FormArray;
  @Input() includeTipStranke: TipStranke[] = [
    TipStranke.FIZIKO_LICE,
    TipStranke.PRAVNO_LICE,
  ];
  @Input() ctrlId: string;

  constructor(
    private _client: StrankaClient,
    private _strankaFS: BaseStrankaFormService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.strankeFormArray.currentValue.length !=
      changes.strankeFormArray.previousValue?.length
    ) {
      this.strankeModel = this.strankeFormArray.value;
    }
  }

  searchStranka(event: any) {
    this._searchSub = this._client
      .get(
        new GetStrankeQuery({
          nazivPibMb: event.query,
          includePravnoLice: this.includeTipStranke.includes(TipStranke.PRAVNO_LICE),
          includeFizickoLice: this.includeTipStranke.includes(TipStranke.FIZIKO_LICE),
          includeAdresa: true,
          includeKontakt: false,
          includeTekuciRacuni: false
        })
      )
      .subscribe((result) => {
        this.strankeSuggestions = result.strankeDto.filter((el) => {
          return this.strankeFormArray?.value.every((f: any) => {
            return f.id !== el.id;
          });
        });
      });
  }

  onStrankaSelect(stranka: IStrankaDto) {
    this.strankeFormArray.push(
      this._strankaFS.GetBaseStrankaFormGroup(stranka.tipStranke, stranka)
    );
  }

  onStrankaUnSelect(stranka: StrankaDto) {
    const idx = (this.strankeFormArray.value as Array<StrankaDto>).findIndex(
      (x) => x.id === stranka.id
    );
    this.strankeFormArray.removeAt(idx);
  }

  ngOnDestroy(): void {
    this._searchSub.unsubscribe();
  }
}
