import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router} from '@angular/router';
import { LoginType ,SwitchTenantRequest, TokensClient } from '@kodit/core/data-api';
import {AuthService} from '@kodit/core/services';
import { Subscription } from 'rxjs';
import { CoreService, REFRESH_TOKEN_REMAINING_TIME_IN_SECONDS } from '../core-service';
import {filter} from "rxjs/operators";

@Component({
  selector: 'kodit-promena-firme-loader',
  templateUrl: './promena-firme-loader.component.html',
  styleUrls: ['./promena-firme-loader.component.scss'],
})
export class PromenaFirmeLoaderComponent implements OnInit, OnDestroy {
  /** Subs */
  private _switchSub: Subscription = new Subscription();

  /** Props */
  tenantName: string = '';
  korisnikEmail : string = '';
  tenantKey : string = '';
  showError: boolean;
  errorMsg: string;

  constructor(
    private _client: TokensClient,
    private _router: Router,
    private _authService: AuthService,
    private _coreService: CoreService
  ) {
   this._load();
  }

  ngOnInit(): void {
    this._router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      if (event.url === '/promena-firme') {
        const state = this._router.getCurrentNavigation()?.extras?.state;
        if (state) {
          this.korisnikEmail = state.korisnikEmail;
          this.tenantKey = state.tenantKey;
          this.tenantName = state.tenantName;

          this._load(this.korisnikEmail, this.tenantKey);
        }
      }
    });
  }

  private _load(korisnikEmail? : string, tenantKey? : string){
    this.tenantName = this._router.getCurrentNavigation().extras.state.tenantName;
    this._switchSub = this._client
      .switchTenant(
        new SwitchTenantRequest({
          korisnikEmail: korisnikEmail ?? this._router.getCurrentNavigation().extras.state
            .korisnikEmail,
          tenantKey: tenantKey ?? this._router.getCurrentNavigation().extras.state.tenantKey,
          loginType : LoginType.WEB
        })
      )
      .subscribe(
        (result) => {
          this._authService.setTokens(
            result.data.token,
            result.data.refreshToken,
            result.data.sessionId
          );

          this._coreService.setRemainingTimeToLocalStorage(
            REFRESH_TOKEN_REMAINING_TIME_IN_SECONDS
          );

          setTimeout(() => {
            this._router.navigate(['/dashboard']);
            this._authService.setUserChanged = true;
          }, 1000);
        },
        (error) => {
          error.subscribe((e: string) => {
            this.errorMsg = e;
            this.showError = true;

            setTimeout(() => {
              this._authService.logout();
            }, 1000);
          });
        }
      );
  }

  ngOnDestroy() {
    this._switchSub.unsubscribe();
  }
}
