import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  DelovodniDokumentTableDto,
  DelovodniciClient,
  DelovodnikInfoDto,
  GetDelovodniDokumentTableQuery,
  PaginatedResultOfDelovodniDokumentTableDto,
} from '@kodit/core/data-api';
import {
  ActionType,
  DynamicDialogConfig,
  DynamicDialogService,
  TableConfig,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription, config } from 'rxjs';
import { DelovodniDokumentFormComponent } from '../../delovodni-dokument-form/delovodni-dokument-form.component';
import { Store } from '@ngrx/store';
import { selectFilterByKey } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.selectors';
import { ConfigService, LocationService } from '@kodit/core/services';
import { setFilter } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';
import { finalize } from 'rxjs/operators';
import { DelovodniDokumentPrilogPreviewComponent } from '../delovodni-dokument-prilog-preview/delovodni-dokument-prilog-preview.component';

@Component({
  selector: 'kodit-delovodni-dokument-table',
  templateUrl: './delovodni-dokument-table.component.html',
  styleUrls: ['./delovodni-dokument-table.component.scss'],
})
export class DelovodniDokumentTableComponent implements OnInit {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  // ToDo: bice implementirano u okviru EK-3070(svi komentari)
  paginatedData!: PaginatedResultOfDelovodniDokumentTableDto;
  // chipItems: FilterChipDto[] = [];
  filters!: GetDelovodniDokumentTableQuery;
  private _skipFirst = 0;
  numberOfRowsDisplayed = 0;
  private _isBusy = false;

  isFirstLoad = true;

  delovodniDokumentTable: DelovodniDokumentTableDto[] = [];

  /** Configurations */
  tableConfig!: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.MEDIUM_LARGE
  );

  /** I/O */
  @Input() delovodnikDto: DelovodnikInfoDto | undefined;

  constructor(
    private _client: DelovodniciClient,
    private _route: ActivatedRoute,
    private _dialogService: DynamicDialogService,
    private _storage: Store,
    private _configService: ConfigService,
    private _locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.setTableConfig();

    this._subs.add(
      this._storage
        .select(selectFilterByKey(this._getFilterSearchKey()))
        .subscribe((data) => {
          if (data) {
            this.filters = JSON.parse(data);
            this._skipFirst = this.filters.pageNumber ?? 0;
            this.numberOfRowsDisplayed = this.filters.pageSize ?? 0;
          } else {
            this.filters = new GetDelovodniDokumentTableQuery({
              pageNumber: this._skipFirst,
              pageSize: this.numberOfRowsDisplayed,
              delovodnikId: this.delovodnikDto?.id
            });
          }
          this._load();
        })
    );
  }

  private _load() {
    if (this._isBusy) {
      return;
    }

    this._configService.setIsBusy = true;
    this._isBusy = true;

    if (!this.filters) {
      this.filters = new GetDelovodniDokumentTableQuery({
        pageNumber: this._skipFirst,
        pageSize: this.numberOfRowsDisplayed,
        delovodnikId: this.delovodnikDto?.id
      });
    }

    this._subs.add(
      this._client
        .getDelovodniDokumentTable(this.filters)
        .pipe(
          finalize(() => {
            this._configService.setIsBusy = false;
            this._isBusy = false;
          })
        )
        .subscribe((res) => {
          if (res.succeeded) {
            this.delovodniDokumentTable = res.data!;
            this.paginatedData = res;
            // this.tableConfig.advancedFilter!.data = this.filters;
          }
        })
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: this.delovodnikDto?.naziv,
      isLazy: true,
      lazyCallbackFunction: (event) => {
        if (!this.isFirstLoad) {
          this._skipFirst = event.first!;
          this.numberOfRowsDisplayed = event.rows!;
        }
        this.updateFilterDataAndReload();
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        }
      },
      // onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      tableFilterFields: ['data.naziv'],
      columns: [
        {
          field: 'osnovniBroj',
          header: 'Osnovni broj',
          type: 'text',
        },
        {
          field: 'podbroj',
          header: 'Podbroj',
          type: 'text',
        },
        {
          field: 'posiljalac',
          header: 'Pošiljalac',
          type: 'link',
          linkCallbackFunction: (itemIndex: number) =>
            this._goToStranka(itemIndex),
        },
        {
          field: 'datumNastanka',
          header: 'Datum nastanka',
          type: 'text',
        },
        {
          field: 'datumUnosa',
          header: 'Datum unosa',
          type: 'text',
        },
        {
          field: 'kategorija',
          header: 'Kategorija',
          type: 'text',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          hasAccessTooltip: 'Unesite delovodni dokument',
          noAccessTooltip: 'Nemate ovlašćenja za unos delovodnog dokumenta',
          callback: () => {
            this._setDialogConfig(
              { delovodnikDto: this.delovodnikDto },
              'Unos delovodnog dokumenta'
            );
          },
        },
      ],
      rowActions: [
        // {
        //   type: ActionType.EDIT,
        //   hasAccessTooltip: 'Izmenite delovodnik',
        //   noAccessTooltip: 'Nemate ovlašćenja za izmenu pravila',
        //   // ToDo: bice odradjeno u okviru EK-3070
        //   // shouldDisplayByCondition: (
        //   //   rowData: KonfiguracijaBrojaDelovodnogDokumentaTableDto
        //   // ) => {
        //   //   return rowData.isEditable;
        //   // },
        //   callback: (index: number) => {
        //     this._setDialogConfig(
        //       {
        //         id: this._getPaginatedItem(index).id,
        //         kategorijaStr: this._getPaginatedItem(index).kategorija,
        //       },
        //       'Izmena delovodnika',
        //       true
        //     );
        //   },
        // },
        {
          type: ActionType.CUSTOM,
          hasAccessTooltip: 'Pregledaj prilog',
          icon:'fa-solid fa-paperclip',
          shouldDisplayByCondition: (
            rowData: DelovodniDokumentTableDto
          ) => {
            return !!rowData.prilog;
          },
          callback: (index: number) => {
            this._openPrilogPreview(
              {
                prilog: this._getPaginatedItem(index).prilog
              },
              'Pregled priloga',
            );
          },
        },
      ],
      // advancedFilter: {
      //   component: DelovodnikFilterComponent,
      //   data: new GetDelovodnikTableQuery(),
      // },
    });
  }

  private _getFilterSearchKey(): string {
    return `$DELOVODNIK_INFO|${this.delovodnikDto?.id}}`;
  }

  private updateFilterDataAndReload() {
    if (
      (this._skipFirst !== this.filters.pageNumber ||
        this.numberOfRowsDisplayed !== this.filters.pageSize) &&
      !this.isFirstLoad
    ) {
      this.filters.pageNumber = this._skipFirst;
      this.filters.pageSize = this.numberOfRowsDisplayed;
      this._updateStorage();
    }
  }

  private _updateStorage() {
    this._storage.dispatch(
      setFilter({
        key: this._getFilterSearchKey(),
        filter: JSON.stringify(this.filters),
      })
    );
  }

  private _getPaginatedIndex(index: number): number {
    return index - this._skipFirst;
  }

  private _getPaginatedItem(index: number): DelovodniDokumentTableDto {
    return this.delovodniDokumentTable[this._getPaginatedIndex(index)];
  }

  private _goToStranka(itemIndex: number) {
    const delovodniDokument = this._getPaginatedItem(itemIndex);
    this._locationService.routeToStrankaKartica(
      delovodniDokument.rutiranjeStranke!.strankaId!,
      delovodniDokument.rutiranjeStranke!.tipStranke!
    );
  }

  private _openPrilogPreview(data: any, header: string){
    this.dialogConfig.data = data;
    this.dialogConfig.header = header;
    this.dialogConfig.hideFooter = true;

    this._dialogService.open(
      DelovodniDokumentPrilogPreviewComponent,
      this.dialogConfig
    )
  }

  private _setDialogConfig(data: any, header: string) {
    this.dialogConfig.data = data;
    this.dialogConfig.header = header;
    this._openDialog(this.dialogConfig);
  }

  private _openDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(
      DelovodniDokumentFormComponent,
      config
    );

    this._subs.add(
      ref.onClose.subscribe((result) => {
        if (result) {
          this._load();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
