import { Component, OnDestroy, OnInit } from '@angular/core';
import { CenovniciClient, CenovnikStavkaDto } from '@kodit/core/data-api';
import {
  DefinisanjeCenaFormService,
  IDefinisanjeCenaDto,
} from '@kodit/core/form-definitions';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-definisanje-cena-form',
  templateUrl: './definisanje-cena-form.component.html',
  styleUrls: ['./definisanje-cena-form.component.scss'],
})
export class DefinisanjeCenaFormComponent implements OnInit, OnDestroy {
  /** Subscriptions */
  private _saveSub: Subscription = new Subscription();
  private _submitSub: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<IDefinisanjeCenaDto>;
  stavkeCenovnika: CenovnikStavkaDto[];
  cenovnikId: number;

  constructor(
    public _dialogRef: DynamicDialogRef,
    public _dialogConfig: DynamicDialogConfig,
    private _definisanjeCenaFormService: DefinisanjeCenaFormService
  ) {}

  ngOnInit(): void {
    this.form = this._definisanjeCenaFormService.GetDefinisanjeCenaFormGroup();

    // get mode and patch if edit
    if (this._dialogConfig.data) {
      this.stavkeCenovnika = this._dialogConfig.data.stavke;
      this.cenovnikId = this._dialogConfig.data.cenovnikId;
    }

    // sub on dialog save button
    this._submitSub = this._dialogRef.onSubmit.subscribe(() => {
      this.save();
    });
  }

  save(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.form.value.novaCena !== null) {
      this.setExactPrice();
    }

    if (this.form.value.povecanjeZaProcenat !== null) {
      this.setPriceByPercentageIncrease();
    }

    if (this.form.value.umanjenjeZaProcenat !== null) {
      this.setPriceByPercentageDecrease();
    }

    this._dialogRef.close();
  }

  setExactPrice() {
    this.stavkeCenovnika.forEach((element) => {
      element.prodajnaCena = this.form.value.novaCena;
    });
  }

  setPriceByPercentageIncrease() {
    var procenat = this.form.value.povecanjeZaProcenat / 100;
    this.stavkeCenovnika.forEach((element) => {
      element.prodajnaCena = element.ulaznaCena * (1 + procenat);
    });
  }

  setPriceByPercentageDecrease() {
    var procenat = this.form.value.umanjenjeZaProcenat / 100;
    this.stavkeCenovnika.forEach((element) => {
      element.prodajnaCena = element.ulaznaCena * (1 - procenat);
    });
  }

  ngOnDestroy(): void {
    this._saveSub.unsubscribe();
    this._submitSub.unsubscribe();
  }
}
