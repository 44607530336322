<div>
  <div class="steps-title p-mb-5">KORAK 02</div>

  <h2>Skladišne jedinice</h2>
  <p>Unesite skladišne jedinice koje koristite za čuvanje dokumentacije.</p>

  <div class="table-header">SKLADIŠNE JEDINICE</div>

  <kodit-skladisne-jedinice-table></kodit-skladisne-jedinice-table>
</div>
