import { Component, OnDestroy, OnInit } from '@angular/core';
import { IRacunInfoDto } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { RacunInfoService } from '../racun-info/racun-info.service';

@Component({
  selector: 'kodit-iskoriscenost-avansnog-racuna',
  templateUrl: './iskoriscenost-avansnog-racuna.component.html',
  styleUrls: ['./iskoriscenost-avansnog-racuna.component.scss'],
})
export class IskoriscenostAvansnogRacunaComponent implements OnInit, OnDestroy {
  /** Props */
  private _subs: Subscription = new Subscription();
  racunInfoDto: IRacunInfoDto;

  constructor(private _racunService: RacunInfoService) {}

  ngOnInit(): void {
    this._subs = this._racunService.getRacunInfoDto.subscribe((res) => {
      this.racunInfoDto = res;
    });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
