import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { IzvodDto, IzvodiClient } from '@kodit/core/data-api';
import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ObradaIzvodaResolver implements Resolve<IzvodDto> {
  constructor(
    private _client: IzvodiClient,
    private _router: Router,
    private _loaderService: LoaderService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IzvodDto> | Promise<IzvodDto> | IzvodDto {
    this._loaderService.setLoadingText =
      'Obrada izvoda je u toku. Molimo sačekajte.';
    this._loaderService.setShowLoader = true;
    return this._client.obradiIzvod(Number(route.paramMap.get('id'))).pipe(
      map((x) => x.data),
      catchError((err) => {
        const currentUrl = this._router.url;
        this._router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this._router.navigate([currentUrl]);
            currentUrl;
          });
        throw err;
      }),
      finalize(() => {
        this._loaderService.reset();
      })
    );
  }
}
