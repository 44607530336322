import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ChangeActivityCommand,
  IKorisnikTableDto,
  KorisniciClient,
  KorisnikTableDto,
  ResultOfKorisnikTableDto,
  TipPristupa,
} from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogService } from '@kodit/core/shared-ui';
import {
  DialogSize,
  DynamicDialogConfig,
  OnDynamicDialogTypedInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  ActionType,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { Subscription } from 'rxjs';
import { InviteKorisnikFormComponent } from '../invite-korisnik-form/invite-korisnik-form.component';
import { KorisnikFormComponent } from '../korisnik-form/korisnik-form.component';

@Component({
  selector: 'kodit-korisnik-table',
  templateUrl: './korisnik-table.component.html',
  styleUrls: ['./korisnik-table.component.scss'],
})
export class KorisnikTableComponent
  implements OnInit, OnDestroy, OnDynamicDialogTypedInit<any> {
  /** Configurations */
  tableConfig: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  private _subs: Subscription = new Subscription();
  korisniciTable: KorisnikTableDto[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _dialogService: DynamicDialogService,
    private _client: KorisniciClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.korisniciTable = this._route.snapshot.data.korisnici;
    this.setTableConfig();
  }

  //#region Table configs

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Korisnički nalozi',
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete izabrane korisničke naloge?',
      deleteMessage:
        'Da li ste sigurni da želite da obrišete korisnički nalog {param}?',
      deleteMessageParams: 'email',
      columns: [
        {
          field: 'name',
          header: 'Ime',
          type: 'text',
        },
        {
          field: 'email',
          header: 'Korisničko ime / E-Mail',
          type: 'text',
          columns: [
            {
              field: 'emailConfirmed',
              header: 'E-Mail potvrđen',
              type: 'text',
            },
          ],
        },

        {
          field: 'phoneNumber',
          header: 'Mobilni telefon',
          type: 'text'
        },
        { field: 'isActive', header: 'Aktivan', type: 'text' },
        { field: 'roleNames', header: 'Korisničke grupe', type: 'text' },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          tipPristupa: TipPristupa.ADMIN_CRUD,
          hasAccessTooltip: 'Unesite korisnički nalog',
          noAccessTooltip: 'Nemate ovlašćenja za unos korisničkog naloga',
          callback: () => {
            this.dialogConfig.header = 'Unos korisničkog naloga';
            this.dialogConfig.data = null;
            this.dialogConfig.customSubmitButton = {
              icon: 'far fa-envelope',
              label: 'Pošalji pristupnicu',
            };
            this.openDialog(InviteKorisnikFormComponent);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          tipPristupa: TipPristupa.ADMIN_CRUD,
          hasAccessTooltip: 'Izmenite korisnički nalog',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu korisničkog naloga',
          callback: (index: number) => {
            this.dialogConfig.header = 'Izmena korisničkog naloga';
            this.dialogConfig.data = {
              email: this.korisniciTable[index].email,
              id: this.korisniciTable[index].id,
            };
            this.openDialog(KorisnikFormComponent);
          },
        },
        {
          type: ActionType.CUSTOM,
          actionClass: 'p-button-danger',
          icon: 'fas fa-user-times',
          tipPristupa: TipPristupa.ADMIN_CRUD,
          hasAccessTooltip: 'Deaktivirajte korisnički nalog',
          noAccessTooltip:
            'Nemate ovlašćenja za deaktiviranje korisničkog naloga',
          callback: (index: number) => {
            this._changeActivity(index, false);
          },
          shouldDisplayByCondition: (rowData: IKorisnikTableDto) => {
            return rowData.isActive === 'Da';
          },
        },
        {
          type: ActionType.CUSTOM,
          actionClass: 'p-button-success',
          icon: 'fas fa-user-check',
          tipPristupa: TipPristupa.ADMIN_CRUD,
          hasAccessTooltip: 'Aktivirajte korisnički nalog',
          noAccessTooltip:
            'Nemate ovlašćenja za aktiviranje korisničkog naloga',
          callback: (index: number) => {
            this._changeActivity(index, true);
          },
          shouldDisplayByCondition: (rowData: IKorisnikTableDto) => {
            return rowData.isActive === 'Ne';
          },
        },
      ],
    });
  }

  //#endregion

  private _changeActivity(index: number, isActivated: boolean) {
    this._subs.add(
      this._client
        .changeActivity(
          new ChangeActivityCommand({
            id: this.korisniciTable[index].id,
            isActivated: isActivated,
          })
        )
        .subscribe((result) => {
          if (result.succeeded) {
            this._alertService.addSuccessMsg(result.data);
            window.location.reload();
          } else {
            this._alertService.addFailedMsg(result.messages[0]);
          }
        })
    );
  }

  openDialog(formType: Type<any>): void {
    const ref = this._dialogService.open(formType, this.dialogConfig);

    this._subs.add(
      ref.onClose.subscribe((res: ResultOfKorisnikTableDto) => {
        if (res) {
          const idx = this.korisniciTable.findIndex(
            (x) => x.id === res.data.id
          );
          if (idx > -1) {
            this.korisniciTable[idx] = res.data;
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
