<div class="card no-gutter widget-overview-box widget-overview-box-1">
  <div class="overview-title-header p-d-flex p-jc-between">
    <h2 class="overview-title chart">
      {{ periodPrefix }} - {{ periodCurrent | titlecase }}
    </h2>
    <!-- <button
      pButton
      type="button"
      label="Izveštaj"
      class="p-col-3 p-button-text p-jc-end"
    ></button> -->
  </div>
  <div
    class="p-d-flex p-flex-column p-flex-lg-row p-jc-between overview-detail"
  >
    <div class="value">{{ value | currencyNumber }}</div>

    <div>
      <div *ngIf="isPercentageNegative; else percentagePositive">
        <div class="p-d-flex p-jc-end comparison negative">
          <div class="direction">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="currentColor"
            >
              <path d="M2.8,8L8,2.9L9,9L2.8,8z"></path>
              <circle cx="1" cy="1" r="1"></circle>
              <rect
                x="0"
                y="2.5"
                transform="matrix(0.7071 0.7071 -0.7071 0.7071 3.5 -1.4497)"
                width="7.1"
                height="2"
              ></rect>
              `
            </svg>
          </div>
          <div class="value">{{ percentage }}%</div>
        </div>
      </div>

      <ng-template #percentagePositive>
        <div class="p-d-flex p-jc-end comparison">
          <div class="direction">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="currentColor"
            >
              <path d="M9,0L8,6.1L2.8,1L9,0z"></path>
              <circle cx="1" cy="8" r="1"></circle>
              <rect
                x="0"
                y="4.5"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.864 4.0858)"
                width="7.1"
                height="2"
              ></rect>
            </svg>
          </div>
          <div class="value" *ngIf="percentage !== '-1'">{{ percentage }}%</div>
          <div class="value" *ngIf="percentage === '-1'">
            <i class="fa-solid fa-infinity"></i>
          </div>
        </div>
      </ng-template>

      <div class="caption">U odnosu na {{ periodBefore | titlecase }}</div>
    </div>
  </div>
</div>
