import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {AbstractControl, FormArray} from '@angular/forms';
import { IMagacinDto, MagaciniClient } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-magacini-multiselect',
  templateUrl: './magacini-multiselect.component.html',
  styleUrls: ['./magacini-multiselect.component.scss'],
})
export class MagaciniMultiselectComponent implements OnInit, OnDestroy {
  private _subs: Subscription;

  /** Props */
  items: SelectItem[];
  selectedItems: number[] = [];
  allItems: IMagacinDto[] = [];

  /** I/O */
  @Input() magaciniFormArray: FormArray | AbstractControl;
  @Output() selectedMagacini = new EventEmitter<IMagacinDto[]>();

  constructor(private _magaciniClient: MagaciniClient) {
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._magaciniClient.getMagaciniMultiselect().subscribe((res) => {
        this.allItems = [...res.data.magacini];
        this.items = res.data.magacini.map((x: IMagacinDto) => ({
          value: x.id,
          label: x.naziv,
          title: x.naziv,
        }));
      })
    );

    this._subs.add(
      this.magaciniFormArray.valueChanges.subscribe(() => {
        if (
          this.magaciniFormArray.value &&
          this.magaciniFormArray.value.length > 0
        ) {
          this.selectedItems = [
            ...this.magaciniFormArray.value.map((x: IMagacinDto) => x.id),
          ];
        }
      })
    );
  }

  selectedItemsOnChange() {
    const magacini = [];
    this.selectedItems.forEach((mId) => {
      const magacin = this.allItems.find((x) => x.id === mId);
      magacini.push(magacin);
    });
    this.selectedMagacini.next(magacini);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
