import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CreateTekuciRacunCommand,
  IBankaDto,
  ITekuciRacunDto,
  ResultOfTekuciRacunDto,
  TekuciRacuniClient,
  UpdateTekuciRacunCommand,
} from '@kodit/core/data-api';
import { AlertService, ButtonService, Mode } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { TekuciRacunFormService } from 'libs/core/form-definitions/src/lib/banka/tekuci-racun-form.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'kodit-tekuci-racun-form',
  templateUrl: './tekuci-racun-form.component.html',
  styleUrls: ['./tekuci-racun-form.component.scss'],
})
export class TekuciRacunFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<ITekuciRacunDto>;
  mode: Mode = Mode.NEW;
  racunRegex: RegExp = /[0-9]{3}-[0-9]{0,13}-[0-9]{2}$/;

  constructor(
    private _client: TekuciRacuniClient,
    private _tekuciRacunFS: TekuciRacunFormService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _alertService: AlertService,
    private _btnService: ButtonService
  ) {}

  ngOnInit(): void {
    /** init form */
    this.form = this._tekuciRacunFS.GetTekuciRacunFormGroup({}, false);
    /** get mode and patch if needed */
    if (this._dialogConfig.data && this._dialogConfig.data.id) {
      this._load(this._dialogConfig.data.id);
      this.mode = Mode.EDIT;
    }
    /** sub to save click */
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
    this.form.controls.isDefault.valueChanges.subscribe((res) => {
      if (res) {
        this.form.controls.isActive.patchValue(res);
      }
    });
  }

  onBankaChange(banka: IBankaDto) {
    /// Za sta treba da sluzi ovo?
    this.form.controls.bankaDto.patchValue(banka);
  }

  private save() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this._btnService.disableButton();

    if (this.mode === Mode.EDIT) {
      this._subs.add(
        this._client
          .update(
            this.form.value.id,
            this.form.value as UpdateTekuciRacunCommand
          )
          .subscribe((result) => {
            if (result.data) {
              this._alertService.addSuccessMsg(
                `Tekući račun je uspešno izmenjen.`
              );
            }
            this._dialogRef.close(result);
          })
      );
    } else {
      this._subs.add(
        this._client
          .create(this.form.value as CreateTekuciRacunCommand)
          .subscribe((result) => {
            this._alertService.addSuccessMsg(
              `Tekući račun je uspešno izmenjen.`
            );
            this._dialogRef.close(result);
          })
      );
    }
  }

  private _load(id: number) {
    this._subs.add(
      this._client
        .getTekuciRacun(id)
        .subscribe((res: ResultOfTekuciRacunDto) => {
          this.form.patchValue(res.data);
        })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
