<!-- with form directive -->
<div
  *ngIf="useDirective"
  [ngClass]="useMargins ? 'hr-divider ' + dividerClass : dividerClass"
  app-directive-dialog-form
>
  <label
    [ngStyle]="{ display: useLabel ? 'block' : 'none' }"
    class="hr-label"
    >{{ dividerLabel }}</label
  >
  <hr />
</div>

<!-- without form directive -->
<div
  *ngIf="!useDirective"
  [ngClass]="useMargins ? 'hr-divider ' + dividerClass : dividerClass"
>
  <label
    [ngStyle]="{ display: useLabel ? 'block' : 'none' }"
    class="hr-label"
    >{{ dividerLabel }}</label
  >
  <hr />
</div>
