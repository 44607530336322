<div class="dialog-container">
  <h1>Da li imate arhivsku knjigu za prošlu godinu?</h1>

  <div class="p-d-flex p-jc-between gap-12">
    <kodit-button
      btnLabel="Da"
      [btnClass]="
        arhivskaPostoji
          ? 'p-button-outlined bool-btn selected'
          : 'p-button-outlined bool-btn '
      "
      (btnClicked)="selectOption(true)"
    ></kodit-button>
    <kodit-button
      btnLabel="Ne"
      [btnClass]="
        !arhivskaPostoji
          ? 'p-button-outlined bool-btn selected'
          : 'p-button-outlined bool-btn'
      "
      (btnClicked)="selectOption(false)"
    ></kodit-button>
  </div>

  <div class="p-fluid p-mt-5">
    <kodit-button
      btnLabel="Potvrdi"
      (btnClicked)="submitAnwser()"
    ></kodit-button>
  </div>
</div>
