<form
    [formGroup]="form"
    class="p-mt-5"
  >
  <div class="p-flex-column">
   
        <kodit-field fieldLabelFor="tekst-pre" fieldLabel="Tekst pre računa">
            <p-editor
            id="tekst-pre"
            [formControl]="form.get('textBefore')"
            [style]="{ height: '150px' }"
            >
            <ng-template pTemplate="header">
                <span class="ql-formats">
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                </span>
            </ng-template>
            </p-editor>
            <kodit-error-info-max-length
            [formCtrl]="form.get('textBefore')"
            ></kodit-error-info-max-length>
        </kodit-field>

        <kodit-field fieldLabelFor="tekst-posle" fieldLabel="Tekst posle računa">
            <p-editor
            id="tekst-posle"
            [formControl]="form.get('textAfter')"
            [style]="{ height: '150px' }"
            >
            <ng-template pTemplate="header">
                <span class="ql-formats">
                <button type="button" class="ql-bold" aria-label="Bold"></button>
                <button type="button" class="ql-italic" aria-label="Italic"></button>
                </span>
            </ng-template>
            </p-editor>
            <kodit-error-info-max-length
            [formCtrl]="form.get('textAfter')"
            ></kodit-error-info-max-length>
        </kodit-field>
    </div>
</form>

