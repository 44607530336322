import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { IMemorandumDto, MemorandumiClient } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-memorandumi-dropdown',
  templateUrl: './memorandumi-dropdown.component.html',
  styleUrls: ['./memorandumi-dropdown.component.scss'],
})
export class MemorandumiDropdownComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  options: {
    value: number;
    naziv: string;
  }[] = [];

  memorandumi: IMemorandumDto[] = [];

  /** I/O */
  @Input() formCtrlId: string;
  @Input() formCtrl: any;
  @Input() availableFilters: IMemorandumDto[];
  @Input() useAsFilter: boolean = false;
  @Input() disabled: boolean = false;
  @Output() onMemorandumChanged = new EventEmitter<IMemorandumDto>();

  constructor(private _client: MemorandumiClient) {}

  ngOnInit(): void {
    if (this.availableFilters) {
      this.options = this.availableFilters.map((x) => ({
        value: x.id,
        naziv: x.naziv,
      }));
      this.memorandumi = this.availableFilters;
      this._addEmptyFilter();
    } else {
      this._addEmptyFilter();
      this._load();
    }
  }

  private _load() {
    this._subs.add(
      this._client.getMemorandumiAutocomplete().subscribe((res) => {
        if (res.data?.memorandumAutocompleteDtoList) {
          this.memorandumi = res.data.memorandumAutocompleteDtoList;

          this.options = this.memorandumi.map((b) => ({
            value: b.id,
            naziv: b.naziv,
          }));

          if (
            !this.useAsFilter &&
            (!this.formCtrl.value || this.formCtrl.value === 0)
          ) {
            this.formCtrl.patchValue(this.memorandumi[0].id);
            this.onMemorandumChanged.emit(this.memorandumi[0]);
          }
          this._addEmptyFilter();
        } else {
          this.options = [];
        }
      })
    );
  }

  private _addEmptyFilter() {
    if (this.useAsFilter) {
      this.options.splice(0, 0, {
        value: null,
        naziv: 'Izaberite memorandum',
      });
    }
  }

  handleMemorandumChange(event: any) {
    if (this.onMemorandumChanged) {
      const memorandum = this.memorandumi.find((x) => x.id === event.value);
      this.onMemorandumChanged.emit(memorandum);
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
