<div *ngIf="jeUnos && jeDomacePravnoLice" class="p-mb-6 p-fluid">
  <div class="p-field">
    <label for="pretraga" kodit-form-label>Pretraga iz javne baze</label>
    <kodit-pravno-lice-kps-autocomplete
      formCtrlId="pretraga"
      (onPravnoLiceChange)="patchFormByModel($event)"
    ></kodit-pravno-lice-kps-autocomplete>
  </div>
</div>

<form [formGroup]="pravnoLiceForm" (keydown)="keyDownFunction($event)">
  <kodit-divider dividerLabel="Osnovne informacije"></kodit-divider>
  <div class="p-fluid">
    <!-- U sistemu PDV-a -->
    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-between">
      <div class="p-field" *ngIf="jeDomacePravnoLice">
        <label for="pun-naziv" kodit-form-label>U sistemu PDV-a</label>
        <p-selectButton
          [options]="daNeOptions"
          [formControl]="pravnoLiceForm.controls.uSistemuPDVa"
        ></p-selectButton>
      </div>
      <div class="p-field">
        <label for="pun-naziv" kodit-form-label>Pravno lice je kupac</label>
        <p-selectButton
          [options]="daNeOptions"
          [formControl]="pravnoLiceForm.controls.jeKupac"
        ></p-selectButton>
      </div>
      <div class="p-field">
        <label for="pun-naziv" kodit-form-label>Pravno lice je dobavljač</label>
        <p-selectButton
          [options]="daNeOptions"
          [formControl]="pravnoLiceForm.controls.jeDobavljac"
        ></p-selectButton>
      </div>
    </div>

    <!-- GLN -->
    <kodit-text-input-field
      formCtrlLabel="Krovni GLN"
      formCtrlId="gln"
      [formCtrl]="pravnoLiceForm.controls.gln"
    ></kodit-text-input-field>

    <!-- Naziv -->
    <kodit-text-input-field
      formCtrlLabel="Naziv"
      formCtrlId="naziv"
      [formCtrl]="pravnoLiceForm.controls.naziv"
    ></kodit-text-input-field>

    <!-- Pun naziv -->
    <kodit-text-input-field
      formCtrlLabel="Pun naziv"
      formCtrlId="pun-naziv"
      [formCtrl]="pravnoLiceForm.controls.punNaziv"
    ></kodit-text-input-field>

    <!-- Registracioni broj -->
    <kodit-field
      *ngIf="!jeDomacePravnoLice"
      fieldLabel="Registracioni broj"
      fieldLabelFor="registracioni-broj"
    >
      <kodit-text-input
        [formCtrl]="pravnoLiceForm.controls.registracioniBroj"
        [formCtrlMaxLength]="30"
        formCtrlId="registracioni-broj"
      >
      </kodit-text-input>
    </kodit-field>

    <!-- PIB i MB -->
    <div class="p-grid">
      <!-- PIB -->
      <div class="p-col-12 p-md-6 p-lg-6">
        <kodit-pib-input-mask
          *ngIf="jeDomacePravnoLice"
          [control]="pravnoLiceForm.controls.pib"
          [showLongLabel]="true"
        ></kodit-pib-input-mask>
      </div>

      <!-- MB -->
      <div class="p-col-12 p-md-6 p-lg-6">
        <kodit-mb-input-mask
          *ngIf="jeDomacePravnoLice"
          [control]="pravnoLiceForm.controls.maticniBroj"
          [showLabel]="true"
        ></kodit-mb-input-mask>
      </div>
    </div>

    <kodit-jbkjs-input-mask
      *ngIf="jeDomacePravnoLice"
      [control]="pravnoLiceForm.controls.jbkjs"
      [showLongLabel]="true"
    ></kodit-jbkjs-input-mask>

    <!-- Rokovi placanja za racune -->
    <kodit-rok-placanja-input-numbers
      [rokZaIzlazneCtrl]="pravnoLiceForm.controls.rokPlacanjaZaIzlazne"
      [rokZaUlazneCtrl]="pravnoLiceForm.controls.rokPlacanjaZaUlazne"
    ></kodit-rok-placanja-input-numbers>

    <!-- Pravni oblik -->
    <kodit-field
      fieldLabel="Pravni oblik"
      fieldLabelFor="pravni-oblik"
      *ngIf="jeDomacePravnoLice"
    >
      <kodit-pravni-oblik-dropdown
        formCtrlId="pravni-oblik"
        [formCtrl]="pravnoLiceForm.controls.pravniOblik"
      ></kodit-pravni-oblik-dropdown>
    </kodit-field>

    <!-- Valuta poslovanja -->
    <kodit-field fieldLabel="Valuta poslovanja">
      <kodit-valuta-dropdown
        [valutaCtrl]="pravnoLiceForm.controls.valutaPoslovanjaId"
      >
      </kodit-valuta-dropdown>
    </kodit-field>

    <div class="p-grid">
      <!-- Broj Ugovora -->
      <div class="p-col-12 p-md-6 p-lg-6">
        <kodit-text-input-field
          formCtrlLabel="Broj ugovora"
          formCtrlId="brojUgovora"
          [formCtrl]="pravnoLiceForm.controls.brojUgovora"
        >
        </kodit-text-input-field>
      </div>

      <!-- Broj tendera -->
      <div class="p-col-12 p-md-6 p-lg-6">
        <kodit-text-input-field
          formCtrlLabel="Broj tendera"
          formCtrlId="brojTendera"
          [formCtrl]="pravnoLiceForm.controls.brojTendera"
        >
        </kodit-text-input-field>
      </div>
    </div>

    <!-- Sifra delatnosti -->
    <div class="p-field">
      <kodit-delatnost-dropdown
        *ngIf="jeDomacePravnoLice"
        [formCtrl]="pravnoLiceForm.controls.sifraDelatnosti"
      ></kodit-delatnost-dropdown>
    </div>

    <!-- Vlasnici -->
    <kodit-text-input-field
      formCtrlLabel="Vlasnik / Vlasnici"
      formCtrlId="vlasnici"
      [formCtrl]="pravnoLiceForm.controls.vlasnici"
    ></kodit-text-input-field>

    <!-- Zastupnici -->
    <kodit-text-input-field
      formCtrlLabel="Zastupnik / Zastupnici"
      formCtrlId="zastupnici"
      [formCtrl]="pravnoLiceForm.controls.zastupnici"
    ></kodit-text-input-field>

    <!-- <div class="p-field">
      <label for="datum-osnivanja" kodit-form-label>Datum osnivanja:</label>
      <kodit-calendar
        formCtrlId="datum-osnivanja"
        [formCtrl]="pravnoLiceForm.controls.datumOsnivanja"
      ></kodit-calendar>
    </div> -->

    <!-- Napomena -->
    <div class="p-field">
      <label for="napomena" kodit-form-label>Napomena</label>
      <textarea
        id="napomena"
        rows="5"
        pInputText
        autoResize="autoResize"
        formControlName="napomena"
      ></textarea>
      <kodit-error-info-max-length
        [formCtrl]="pravnoLiceForm.controls.napomena"
      ></kodit-error-info-max-length>
      <kodit-error-info-required
        [formCtrl]="pravnoLiceForm.controls.napomena"
      ></kodit-error-info-required>
    </div>

    <!-- IsActive -->
    <kodit-checkbox
      *ngIf="!jeUnos"
      labelText="Aktivna stranka"
      [formCtrl]="pravnoLiceForm.controls.isActive"
    ></kodit-checkbox>
  </div>

  <kodit-divider dividerLabel="Podaci o adresi"></kodit-divider>
  <kodit-adresa-form
    [addressForm]="pravnoLiceForm.controls.adresa"
  ></kodit-adresa-form>
</form>
