<form [formGroup]="form">
  <div class="p-fluid">
    <!-- Redni broj od -->
    <kodit-field fieldLabel="Redni broj od:" fieldLabelFor="redniBrojOd">
      <kodit-number-input
        formCtrlId="redniBrojOd"
        [formCtrl]="form.controls.redniBrojOd"
        [ctrlOnlyIntegers]="true"
      ></kodit-number-input>
    </kodit-field>
    <!-- Redni broj od -->
    <kodit-field fieldLabel="Redni broj do:" fieldLabelFor="redniBrojDo">
      <kodit-number-input
        formCtrlId="redniBrojDo"
        [formCtrl]="form.controls.redniBrojDo"
        [ctrlOnlyIntegers]="true"
      ></kodit-number-input>
    </kodit-field>
    <!-- Godina nastanka -->
    <kodit-field fieldLabel="Godina nastanka">
      <kodit-dropdown
        [options]="godineItems"
        [formCtrl]="form.controls.godinaDokumenta!"
      ></kodit-dropdown>
    </kodit-field>
  </div>

  <hr />

  <p-message
    severity="info"
    text="Akcijom štampanja redovi će biti zaključani i izmene neće biti dozvoljene.
        Ukoliko ste napravili grešku, uvek možete sami otključati redove."
  ></p-message>
</form>

<div class="loading-card" *ngIf="!form || !isDropdownLoaded">
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
</div>
