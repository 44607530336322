import { Injectable } from '@angular/core';
import {
  NeobradjenRacunStavkaDto,
  PredmetStavkeDto,
  StavkaRacunaDto,
} from '@kodit/core/data-api';
import { round } from '@kodit/core/shared';

@Injectable({
  providedIn: 'root',
})
export class KalkulacijeService {
  constructor() {}

  calculateNabavnaCenaChanged(
    updatedValue: number,
    stavka: StavkaRacunaDto | PredmetStavkeDto
  ): StavkaRacunaDto | PredmetStavkeDto {
    if (stavka instanceof StavkaRacunaDto) {
      const newStavka = stavka;

      newStavka.nabavnaCenaBezPdv = updatedValue;
      newStavka.predmetStavkeDto.marza = this.calculateMarza(
        updatedValue,
        newStavka.prodajnaCenaBezPdv
      );
      newStavka.predmetStavkeDto.razlikaUCeni = this.calculateRazlikaUCeni(
        updatedValue,
        newStavka.prodajnaCenaBezPdv
      );
      newStavka.predmetStavkeDto.prodajnaCenaSaPdv = this.calculateProdajnaCenaSaPdv(
        newStavka.prodajnaCenaBezPdv,
        newStavka.stopaPDV
      );

      return newStavka;
    }
    const newPredmetStavke = stavka;

    newPredmetStavke.nabavnaCena = updatedValue;
    newPredmetStavke.marza = this.calculateMarza(
      updatedValue,
      newPredmetStavke.prodajnaCenaBezPdv
    );
    newPredmetStavke.razlikaUCeni = this.calculateRazlikaUCeni(
      updatedValue,
      newPredmetStavke.prodajnaCenaBezPdv
    );
    newPredmetStavke.prodajnaCenaSaPdv = this.calculateProdajnaCenaSaPdv(
      newPredmetStavke.prodajnaCenaBezPdv,
      newPredmetStavke.stopaPDV
    );
    return newPredmetStavke;
  }

  calculateProdajnaCenaBezPdvChanged(
    updatedValue: number,
    stavka: StavkaRacunaDto | PredmetStavkeDto,
    neobradjenRacunStavka?: NeobradjenRacunStavkaDto
  ): StavkaRacunaDto | PredmetStavkeDto {
    if (stavka instanceof StavkaRacunaDto) {
      const newStavka = stavka;

      newStavka.predmetStavkeDto.marza = this.calculateMarza(
        newStavka.nabavnaCenaBezPdv,
        updatedValue
      );
      newStavka.predmetStavkeDto.razlikaUCeni = this.calculateRazlikaUCeni(
        newStavka.nabavnaCenaBezPdv,
        updatedValue
      );
      newStavka.predmetStavkeDto.prodajnaCenaSaPdv = this.calculateProdajnaCenaSaPdv(
        updatedValue,
        newStavka.stopaPDV
      );

      return newStavka;
    }

    const newPredmetStavke = stavka;

    newPredmetStavke.marza = this.calculateMarza(
      newPredmetStavke.nabavnaCena,
      updatedValue
    );
    newPredmetStavke.razlikaUCeni = this.calculateRazlikaUCeni(
      newPredmetStavke.nabavnaCena,
      updatedValue
    );
    newPredmetStavke.prodajnaCenaSaPdv = this.calculateProdajnaCenaSaPdv(
      updatedValue,
      neobradjenRacunStavka.pdvStopa
    );

    return newPredmetStavke;
  }

  calculateProdajnaCenaSaPdvChanged(
    updatedValue: number,
    stavka: StavkaRacunaDto | PredmetStavkeDto,
    neobradjenRacunStavka?: NeobradjenRacunStavkaDto
  ): StavkaRacunaDto | PredmetStavkeDto {
    if (stavka instanceof StavkaRacunaDto) {
      const newStavka = stavka;
      return newStavka;
    }

    const newPredmetStavke = stavka;
    const prodajnaCenaBezPdv = this.calculateProdajnaCenaBezPdv(updatedValue,neobradjenRacunStavka.pdvStopa);

    newPredmetStavke.marza = this.calculateMarza(
      newPredmetStavke.nabavnaCena,
      prodajnaCenaBezPdv
    );

    newPredmetStavke.razlikaUCeni = this.calculateRazlikaUCeni(
      newPredmetStavke.nabavnaCena,
      prodajnaCenaBezPdv
    );

    newPredmetStavke.prodajnaCenaBezPdv = prodajnaCenaBezPdv;

    return newPredmetStavke;
  }

  calculateMarzaCenaChanged(
    updatedValue: number,
    stavka: StavkaRacunaDto | PredmetStavkeDto,
    neobradjenRacunStavka?: NeobradjenRacunStavkaDto
  ): StavkaRacunaDto | PredmetStavkeDto {
    if (stavka instanceof StavkaRacunaDto) {
      const newStavka = stavka;
      const updatedProdajnaCena = this.calculateProdajnaCena(
        newStavka.nabavnaCenaBezPdv,
        updatedValue
      );

      newStavka.prodajnaCenaBezPdv = updatedProdajnaCena;

      newStavka.predmetStavkeDto.razlikaUCeni = this.calculateRazlikaUCeni(
        newStavka.nabavnaCenaBezPdv,
        updatedProdajnaCena
      );
      newStavka.predmetStavkeDto.prodajnaCenaSaPdv = this.calculateProdajnaCenaSaPdv(
        updatedProdajnaCena,
        newStavka.stopaPDV
      );

      return newStavka;
    }

    const newPredmetStavke = stavka;
    const updatedProdajnaCena = this.calculateProdajnaCena(
      newPredmetStavke.nabavnaCena,
      updatedValue
    );

    newPredmetStavke.prodajnaCenaBezPdv = updatedProdajnaCena;

    newPredmetStavke.razlikaUCeni = this.calculateRazlikaUCeni(
      newPredmetStavke.nabavnaCena,
      updatedProdajnaCena
    );

    newPredmetStavke.prodajnaCenaSaPdv = this.calculateProdajnaCenaSaPdv(
      updatedProdajnaCena,
      neobradjenRacunStavka.pdvStopa
    );

    return newPredmetStavke;
  }

  calculateRazlikaUCeniChanged(
    updatedValue: number,
    stavka: StavkaRacunaDto | PredmetStavkeDto,
    neobradjenRacunStavka?: NeobradjenRacunStavkaDto
  ): StavkaRacunaDto | PredmetStavkeDto {
    if (stavka instanceof StavkaRacunaDto) {
      const newStavka = stavka;

      newStavka.prodajnaCenaBezPdv = this.calculateProdajnaCenaIzRazlike(
        newStavka.nabavnaCenaBezPdv,
        updatedValue
      );
      newStavka.predmetStavkeDto.prodajnaCenaSaPdv = this.calculateProdajnaCenaSaPdv(
        newStavka.prodajnaCenaBezPdv,
        newStavka.stopaPDV
      );
      newStavka.predmetStavkeDto.marza = this.calculateMarza(
        newStavka.nabavnaCenaBezPdv,
        newStavka.prodajnaCenaBezPdv
      );

      return newStavka;
    }

    const newPredmetStavke = stavka;

    newPredmetStavke.prodajnaCenaBezPdv = this.calculateProdajnaCenaIzRazlike(
      newPredmetStavke.nabavnaCena,
      updatedValue
    );
    newPredmetStavke.prodajnaCenaSaPdv = this.calculateProdajnaCenaSaPdv(
      newPredmetStavke.prodajnaCenaBezPdv,
      neobradjenRacunStavka.pdvStopa
    );
    newPredmetStavke.marza = this.calculateMarza(
      newPredmetStavke.nabavnaCena,
      newPredmetStavke.prodajnaCenaBezPdv
    );

    return newPredmetStavke;
  }

  calculateRazlikaUCeni(nabavnaCena: number, prodajnaCena: number): number {
    return round(prodajnaCena - nabavnaCena, 4);
  }

  calculateMarza(nabavnaCena: number, prodajnaCena: number) {
    if (nabavnaCena === 0) {
      // Da bismo izblegli deljenje sa 0 -> marza je 100% kada je nabavna cena = 0
      return round(100, 4);
    }

    return round(((prodajnaCena - nabavnaCena) / nabavnaCena) * 100, 4);
  }

  calculateProdajnaCena(nabavnaCena: number, marza: number) {
    const addition = round(((nabavnaCena * marza) / 100),4);
    return round(nabavnaCena + addition, 4);
  }

  calculateProdajnaCenaBezPdv(prodajnaCenaSaPdv : number, stopaPdv : number) : number{
    return round(prodajnaCenaSaPdv / (1+(stopaPdv/100)), 4)
  }

  calculateProdajnaCenaSaPdv(prodajnaCena: number, stopaPdv: number) {
    return round(prodajnaCena + prodajnaCena * (stopaPdv / 100), 4);
  }

  calculateProdajnaCenaIzRazlike(nabavnaCena: number, razlikaUCeni: number) {
    return round(nabavnaCena + razlikaUCeni, 4);
  }
}
