import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthGuard, AuthService } from '@kodit/core/services';
import { DynamicDialogRef } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import { CoreService } from '../../core-service';

@Component({
  selector: 'kodit-refresh-token-confirmation-dialog',
  templateUrl: './refresh-token-confirmation-dialog.component.html',
  styleUrls: ['./refresh-token-confirmation-dialog.component.scss'],
})
export class RefreshTokenConfirmationDialogComponent
  implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription;

  /** Props */
  remainingTime: number;

  constructor(
    private _coreService: CoreService,
    private _dialogRef: DynamicDialogRef,
    private _authGuard: AuthGuard,
    private _authService: AuthService
  ) {
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this._subs.add(
      this._coreService.getRemainingTimerSeconds.subscribe((res) => {
        this.remainingTime = res;
      })
    );

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._coreService.resetRemainingTimerSeconds();
        this._coreService.setRemainingTimeToLocalStorage(
          this._coreService.getRefreshTimerRemainingSecondsValue()
        );
        const res = this._authGuard.tryRefreshingTokens();
        res
          ? (this._authService.setAppInitialized = true)
          : this._authService.logout();

        this._dialogRef.close(true);
      })
    );

    this._subs.add(
      this._dialogRef.onClose.subscribe(() => {
        return false;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
