import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { AbstractControl } from '@angular/forms';
import {
  BuyerReference,
  BuyerReferenceDto,
  RacuniClient,
  VrstaFakture,
} from '@kodit/core/data-api';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

@Component({
  selector: 'kodit-buyer-reference-autocomplete',
  templateUrl: './buyer-reference-autocomplete.component.html',
  styleUrls: ['./buyer-reference-autocomplete.component.scss'],
})
export class BuyerReferenceAutocompleteComponent implements OnInit {
  /** Subs */
  private _subs: Subscription;

  /** Props */
  items: SelectItem[];
  podtipDokumenti: BuyerReferenceDto[] = [];
  podtipOdobrenjaZaduzenja: BuyerReferenceDto[] = [];
  podtipIzlazni: BuyerReferenceDto[] = [];
  podtipDropdown: BuyerReferenceDto[] = [];
  options: {
    value: number;
    naziv: string;
  }[] = [];

  /** I/O */
  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string;
  @Input() parentCtrl: string;
  @Input() tip: boolean;

  constructor(private _client: RacuniClient) {
    this._subs = new Subscription();
    this.items = [];
    this.formCtrl = undefined!;
    this.formCtrlId = 'buyer-reference-dropdown';
    this.parentCtrl = 'body';
    this.podtipDokumenti = [];
  }

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client.getPodtipDokumentaDropdown().subscribe((res) => {
        if (!res.succeeded) {
          return;
        }
        this.podtipDokumenti = res.data!;
        this.podtipDokumenti.forEach((e) => {
          if (e.buyerReference < 7) {
            this.podtipIzlazni.push(e);
          } else {
            this.podtipOdobrenjaZaduzenja.push(e);
          }
        });
        this.podtipDropdown.push({
          buyerReference: null,
          buyerReferenceStr: 'Izaberite podtip dokumenta',
          buyerReferenceBadgeStr: '',
        } as BuyerReferenceDto);

        if (this.tip) {
          this.podtipDropdown.push(...this.podtipIzlazni);
        } else {
          this.podtipDropdown.push(...this.podtipOdobrenjaZaduzenja);
        }
        this.options = this.podtipDropdown.map((b) => ({
          value: b.buyerReference,
          naziv: b.buyerReferenceStr,
        }));
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
