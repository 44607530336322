import { Component, OnInit } from '@angular/core';
import { FileUploadService } from 'libs/core/shared-ui/src/lib/file-upload/file-upload.service';

@Component({
  selector: 'kodit-import-kategorije',
  templateUrl: './import-kategorije.component.html',
  styleUrls: ['./import-kategorije.component.scss'],
})
export class ImportKategorijeComponent implements OnInit {
  showImportSection: boolean = false;
  importMode: string = 'basic';
  uploadComleted: boolean = false;

  readonly MAX_FILE_SIZE: number = 10000000;

  constructor(private _uploadService: FileUploadService) {}

  ngOnInit(): void {
    this._uploadService.setMaxAttachmentSize = this.MAX_FILE_SIZE;
  }

  preuzmiSablon() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute(
      'href',
      '/assets/podesavanje/import_files/kategorije_dokumentarnog_sablon.csv'
    );
    link.setAttribute('download', 'kategorije_dokumentarnog_sablon.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
