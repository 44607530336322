<div class="p-grid p-mt-3">
  <kodit-card-container class="p-col-6">
    <div card-title>Domaći kupci i dobavljači</div>
    <div class="p-d-flex p-flex-column gap-12" card-body>
      <kodit-kupci-i-dobavljaci-import></kodit-kupci-i-dobavljaci-import>
    </div>
  </kodit-card-container>

  <kodit-card-container class="p-col-6">
    <div card-title>Inostrani kupci i dobavljači</div>
    <div class="p-d-flex p-flex-column gap-12" card-body>
      <kodit-kupci-i-dobavljaci-inostrani-import></kodit-kupci-i-dobavljaci-inostrani-import>
    </div>
  </kodit-card-container>

  <!-- <kodit-card-container class="p-col-6">
    <div card-title>Uvoz šifarnika artikala</div>
    <div class="p-d-flex p-flex-column gap-12" card-body>
      <kodit-artikli-import
      ></kodit-artikli-import>
    </div>
  </kodit-card-container> -->

  <kodit-card-container class="p-col-6">
    <div card-title>Uvoz šifarnika usluga</div>
    <div class="p-d-flex p-flex-column gap-12" card-body>
      <kodit-usluge-import
      ></kodit-usluge-import>
    </div>
  </kodit-card-container>
</div>
