import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { CLEAR_FILTERS, clearFilter, deleteAllFilters, setFilter } from './filter.actions';

export interface FilterState {
  //filterKey je string koji se dobije kombinacijom vrste racuna i tipa racuna
  //on je oblika: `${Enum1.Value1}_${Enum2.OptionB}`
  [filterKey: string]: { filter: string | null };
}

export const initialState: FilterState = {};

export const filtersReducer = createReducer(
  initialState,
  on(setFilter, (state, { key, filter }) => ({
    ...state,
    [key]: { filter },
  })),
  on(clearFilter, (state, { key }) => {
   const { [key]: _, ...updatedState } = state;
   return updatedState;
  }),
  on(deleteAllFilters, () => ({}))
);

export function clearStateMetaReducer<State extends {}>(
  reducer: ActionReducer<State>
): ActionReducer<State> {
  return function clearStateFn(state: State, action: Action) {
    if (action.type === CLEAR_FILTERS) {
      state = {} as State; 
    }
    return reducer(state, action);
  };
}
