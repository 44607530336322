<kodit-card-container>
  <div card-title class="p-mb-3">Uplate po računu ({{ racunInfoDto.procenatIznos }}%)</div>

  <p-progressBar
    card-subtitle
    [showValue]="false"
    [value]="racunInfoDto.procenatIznos"
  ></p-progressBar>

  <div class="p-d-flex p-flex-column" style="gap: 12px" card-right-side>
    <div
      [ngClass]="
        'p-text-center info-badge status status-uplate-racuna-' +
        racunInfoDto.statusUplateDto.statusBadgeStr
      "
    >
      {{ racunInfoDto.statusUplateDto.statusStr.toUpperCase() }}
    </div>
    <!-- <button
      icon="far fa-credit-card"
      pButton
      type="button"
      label="Evidentiraj uplatu"
      class="p-button p-button-outlined"
      (click)="onSubmit()"
    ></button> -->
  </div>

  <div class="p-d-flex p-flex-column gap-12" card-body>
    <div class="p-d-flex p-jc-between">
      <div>
        <div>
          <div class="p-d-flex p-mb-2">
            <div class="p-pr-2 p-label">Za uplatu:</div>
            <div class="p-text">
              {{ racunInfoDto.iznosStr }}
            </div>
          </div>
          <div class="p-d-flex p-mb-2">
            <div class="p-pr-2 p-label">Uplaćeno:</div>
            <div class="p-text">
              {{ racunInfoDto.uplacenoStr }}
            </div>
          </div>
          <div class="p-d-flex p-mb-2">
            <div class="p-pr-2 p-label">Preostalo:</div>
            <div class="p-text">
              {{ racunInfoDto.preostaloStr }}
            </div>
          </div>
        </div>
      </div>
      <!-- TODO: Refactor, prikazati samo ako je status uplate razlicit od placen -->
      <div>
        <div>
          <div class="p-d-flex p-jc-end p-mb-2">
            <div class="p-pr-2 p-label">Rok za plaćanje:</div>
            <div class="p-text">{{ racunInfoDto.datumValuteStr }}</div>
          </div>
          <div
            class="p-d-flex p-jc-end p-mb-2"
            *ngIf="
              racunInfoDto.datumValuteTekstUpozorenja &&
              racunInfoDto.statusUplateDto.status != statusPlacen
            "
          >
            <div class="k-label-warning">
              {{ racunInfoDto.datumValuteTekstUpozorenja }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <kodit-uplate-po-racunu-table
        [uplateTable]="racunInfoDto.uplateRacunaTable"
      ></kodit-uplate-po-racunu-table>
    </div>
  </div>
</kodit-card-container>
