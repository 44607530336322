<form [formGroup]="form" (keydown)="keyDownFunction($event)">
  <div class="p-fluid">
    <kodit-field fieldLabel="Naziv" fieldLabelFor="naziv">
      <kodit-text-input
        [formCtrl]="form.controls.naziv"
        formCtrlId="naziv"
      ></kodit-text-input>
    </kodit-field>
    <kodit-field fieldLabel="Roditeljska oznaka" fieldLabelFor="oznaka">
      <kodit-oznaka-autocomplete
        [disabled]="shouldDisableParentEdit"
        (onOznakaClear)="onClear()"
        [excludeTipoveEntiteta]="excludeTipoveEntiteta"
        [oznakaFormGroup]="form.controls.parent"
      ></kodit-oznaka-autocomplete>
    </kodit-field>
  </div>
</form>
