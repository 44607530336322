<kodit-dropdown
  [options]="options"
  [formCtrl]="tekuciRacunCtrl"
  [formCtrlId]="tekuciRacunIdCtrl"
  [ctrlPlaceholder]="useAsFilter ? '' : 'Izaberite račun za uplatu'"
  (optionChanged)="handleTekuciRacunChange($event)"
>
  <ng-template #selectedItem let-obj>
    <div class="p-text-left">{{ obj.racun }}</div>
  </ng-template>

  <ng-template #item let-obj>
    <ng-container *ngIf="item.banka">
      <div class="p-text-bold">{{ obj.banka }}</div>
      <div class="p-text-left">{{ obj.racun }}</div>
    </ng-container>
    <ng-container *ngIf="!item.banka">
      <div class="p-text-bold">{{ obj.racun }}</div>
      <div class="p-text-left">{{ obj.banka }}</div>
    </ng-container>
  </ng-template>
</kodit-dropdown>
