import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, AbstractControl, FormControl } from '@angular/forms';
import { StatusUplateRacuna, StatusRacuna, SharedClient } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-status-transakcije-multiselect',
  templateUrl: './status-transakcije-multiselect.component.html',
  styleUrls: ['./status-transakcije-multiselect.component.scss']
})
export class StatusTransakcijeMultiselectComponent implements OnInit, OnDestroy {
  /** subs */
  private _subs: Subscription = new Subscription();
  items: SelectItem[];
  selectedItems: StatusUplateRacuna[] = [];
  /** I/O */
  @Input() statusFormArray: FormArray | AbstractControl;
  @Output() selectedStatusiRacuna = new EventEmitter<StatusUplateRacuna[]>();
  @Input() formCtrlId: string;
  @Input() parentCtrl: any = 'body';
  @Input() tipCtrl: FormControl | AbstractControl;
  @Output() onItemChange = new EventEmitter<StatusRacuna>();
  
  constructor(private _client: SharedClient) { }

  ngOnInit(): void {
    this.selectedItems = [...this.statusFormArray.value.map((x) => x.status)];
    this._load();
  }
  private _load() {
    this._subs.add(
      this._client
        .getStatuseTransakcijeIzvoda()
        .subscribe((res) => {
          this.items = res.data.statusiDtoList.map((x) => ({
            value: x.status,
            label: x.statusStr,
          }));
          this.items = this.items.filter((x) => x.value != StatusRacuna.EMPTY);
        })
    );
  }

  selectedItemsOnChange() {
    this.selectedStatusiRacuna.next(this.selectedItems);
  }
  handleOnItemChange(event: any) {
    this.onItemChange.emit(event);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
