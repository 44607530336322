import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import { KorisnikFormService } from '../..';
import { IResetPasswordKorisnikCommand } from '@kodit/core/data-api';

@Injectable({
  providedIn: 'root',
})
export class ChangePasswordFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _korisnikFS: KorisnikFormService
  ) {}

  /**
   * Konvertuje model za korisnika i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model korisnika od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetPasswordChangeFormGroup(
    korisnikEmail: string
  ): FormGroupTypeSafe<IResetPasswordKorisnikCommand> {
    return this._fb.group<IResetPasswordKorisnikCommand>(
      {
        currentPassword: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(50),
          ],
        }),
        newPassword: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(50),
          ],
        }),

        newPasswordCheck: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(50),
          ],
        }),
        korisnikEmail: new FormControl(korisnikEmail, {
          validators: Validators.required,
        }),
      },
      {
        validators: this._korisnikFS.PasswordMatchValidator,
      }
    );
  }
}
