import { Component, Input, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'kodit-informacija-isporuke-stranke-checkbox',
  templateUrl: './informacija-isporuke-stranke-checkbox.component.html',
  styleUrls: ['./informacija-isporuke-stranke-checkbox.component.scss'],
})
export class InformacijaIsporukeStrankeCheckboxComponent implements OnInit {
  /** I/O */
  @Input() formCtrl: AbstractControl;

  options: SelectItem[];

  constructor() {}

  ngOnInit(): void {
    this.options = [
      { label: 'Da', value: true },
      { label: 'Ne', value: false },
    ];
  }
}
