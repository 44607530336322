<kodit-field fieldLabel="Izvorna faktura" fieldLabelFor="izvorna-faktura">
  <kodit-racun-autocomplete
    formCtrlId="izvorna-faktura"
    [jeIzmenaOsnovice]="jeIzmenaOsnovice"
    [includeTipoveRacuna]="useOnlyTipove"
    [includeStatuseRacuna]="useOnlyStatuse"
    (onChange)="handleVezaniRacunChange($event)"
    (onClear)="handleVezaniRacunClear()"
  ></kodit-racun-autocomplete>

  <small
    *ngIf="!vezaniRacunArray.valid && vezaniRacunArray.touched"
    class="p-invalid"
    >Morate odabrati račun
  </small>
</kodit-field>
