import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[kodit-select-all-on-focus]',
})
export class SelectAllOnFocusDirective {
  constructor(private elRef: ElementRef) {}

  @HostListener('focus', []) onFocus() {
    this.elRef.nativeElement.select();
  }
}
