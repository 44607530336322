import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  ArhivskaKnjigaClient,
  CreateFizickaLokacijaCommand,
  FizickaLokacijaAutocompleteItem,
  UpdateFizickaLokacijaCommand,
} from '@kodit/core/data-api';
import {FormHelper} from '@kodit/core/form-definitions';
import {AlertService, Mode} from '@kodit/core/services';
import {DynamicDialogConfig, DynamicDialogRef} from '@kodit/core/shared-ui';
import {Subscription} from 'rxjs';
import {
  ArhivskaKnjigaService,
  FizickaLokacijaForm,
} from '../../../arhivska-knjiga-service';

@Component({
  selector: 'kodit-fizice-lokacije-form',
  templateUrl: './fizice-lokacije-form.component.html',
  styleUrls: ['./fizice-lokacije-form.component.scss'],
})

export class FiziceLokacijeFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _sub: Subscription = new Subscription();

  /** Props */
  form: FizickaLokacijaForm;
  mode: Mode = Mode.NEW;
  isFormPopulated: boolean = false;

  constructor(
    private _dialogConfig: DynamicDialogConfig,
    private _client: ArhivskaKnjigaClient,
    private _dialogRef: DynamicDialogRef,
    private _formHelper: FormHelper,
    private _alertService: AlertService,
    private _ahivskaService: ArhivskaKnjigaService
  ) {
  }

  ngOnInit(): void {
    this.form = this._ahivskaService.getFizickaLokacijaForm();

    if (
      this._dialogConfig.data.id != undefined &&
      (this._dialogConfig.data.mode == Mode.NEW ||
        this._dialogConfig.data.mode == Mode.EDIT)
    ) {
      this._load(this._dialogConfig.data.id, this._dialogConfig.data.mode);
    } else {
      this._dialogConfig.data = null;
    }

    this._sub.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );
  }

  private _save() {
    if (this.form.invalid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    if (this.mode === Mode.NEW) {
      this._sub.add(
        this._client
          .create(this.form.value as CreateFizickaLokacijaCommand)
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.messages[0]);
            this._dialogRef.close(true);
          })
      );
    } else {
      if (this.form.controls.id.value == this.form.controls.parent.value.id) {
        this._alertService.addWarnMsg(
          'Nije dozvoljeno vezati istu roditeljsku lokaciju'
        );
      } else {
        this._sub.add(
          this._client
            .updateLokacija(this.form.value as UpdateFizickaLokacijaCommand)
            .subscribe((res) => {
              this._alertService.addSuccessMsg(res.messages[0]);
              this._dialogRef.close(res);
            })
        );
      }
    }
  }

  private _load(id: number, mode: Mode) {
    this._sub.add(
      this._client.getById(id).subscribe((res) => {
        if (mode === Mode.EDIT) {
          this.form.patchValue(res.data);
          this.mode = Mode.EDIT;
        } else {
          this.form.controls.parent.patchValue(
            new FizickaLokacijaAutocompleteItem({
              id: res.data.id,
              naziv: res.data.naziv,
              punNaziv: res.data.punNaziv
            })
          );
          this.mode = Mode.NEW;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
    this._ahivskaService.resetFizickaLokacijaForm();
  }
}
