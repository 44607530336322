import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-pdv-evidencija-pojedinacna-page',
  templateUrl: './pdv-evidencija-pojedinacna-page.component.html',
  styleUrls: ['./pdv-evidencija-pojedinacna-page.component.scss'],
})
export class PdvEvidencijaPojedinacnaPageComponent implements OnInit {
  novaTabelaSelected: boolean = true;
  tableOptions: SelectItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this._loadDropdownOptions();
  }

  private _loadDropdownOptions() {
    this.tableOptions.push({
      value: VrstaPojedinacneEvidencije.SEF_39,
      label: 'SEF 3.9',
    });

    this.tableOptions.push({
      value: VrstaPojedinacneEvidencije.STARA,
      label: 'Stare',
    });
  }

  optionChanged(event: any) {
    this.novaTabelaSelected = event.value === VrstaPojedinacneEvidencije.SEF_39;
  }
}

export enum VrstaPojedinacneEvidencije  {
  STARA = 0,
  SEF_39 = 1,
}
