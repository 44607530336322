import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
  @Input()
  showCardBlock: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
