import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  IGetFormConfigurationResponse,
  IOtpremnicaDto,
  TipEntiteta,
  TipRacuna,
  VrstaOtpremnice,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { BaseRacunFormService } from './_base_racun-form.service';
import { InformacijaIsporukeFormService } from './informacija-isporuke-form.service';
import {VoziloFormService} from "../predmet-stavke/vozilo-form.service";

@Injectable({
  providedIn: 'root',
})
export class OtpremnicaFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _baseRacunForm: BaseRacunFormService,
    private _informacijaIsporukeForm: InformacijaIsporukeFormService,
    private _voziloForm : VoziloFormService
  ) {}

  /**
   * Konvertuje model i od njega pravi reaktivnu formu (form grupu) za otpremnicu
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   * @param model model otpremnice
   * @returns Reaktivnu formu kao FormGroup za otpremnicu
   */
  public GetOtpremnicaFormGroup(
    formConfiguration: IGetFormConfigurationResponse,
    model?: IOtpremnicaDto
  ): FormGroupTypeSafe<IOtpremnicaDto> {
    const baseRacunForm = this._baseRacunForm.GetBaseRacunFormGroup(
      TipRacuna.OTPREMNICA,
      VrstaRacuna.IZLAZNI,
      TipEntiteta.OTPREMNICA,
      formConfiguration,
      model?.racunDto
    );
    return this._fb.group<IOtpremnicaDto>({
      racunDto: baseRacunForm,
      informacijaIsporuke: this._informacijaIsporukeForm.GetInformacijaIsporukeFormGroup(
        model?.informacijaIsporuke
      ),
      vozila : this._voziloForm.GetVozilaDropdownFormArray(model?.vozila),
      vrsta: new FormControl(model?.vrsta ?? VrstaOtpremnice.STANDARDNA, {
        validators: [Validators.min(VrstaOtpremnice.STANDARDNA)],
      }),
      interniMagacinId: new FormControl(model?.interniMagacinId),
    });
  }
}
