import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {
  BankaIzvoda,
  ITekuciRacunDropdownDto,
  ITekuciRacunDto,
  TekuciRacuniClient,
} from '@kodit/core/data-api';
import { TekuciRacunDropdownService } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-tekuci-racun-dropdown',
  templateUrl: './tekuci-racun-dropdown.component.html',
  styleUrls: ['./tekuci-racun-dropdown.component.scss'],
})
export class TekuciRacunDropdownComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  options: {
    racun: string;
    value: number;
    banka: string;
  }[] = [];
  tekuciRacuni: ITekuciRacunDropdownDto[] = [];
  tekuciRacuniZaBanku: BankaIzvoda = BankaIzvoda.EMPTY;

  /** I/O */
  @Input() tekuciRacunCtrl: FormControl | AbstractControl;
  @Input() tekuciRacunIdCtrl: string = 'tekuci-racun';
  @Input() availableFilters: ITekuciRacunDropdownDto[];
  @Input() useAsFilter: boolean = false;
  @Output() onTekuciRacunChanged = new EventEmitter<ITekuciRacunDropdownDto>();

  constructor(
    private _client: TekuciRacuniClient,
    private _service: TekuciRacunDropdownService
  ) {}

  ngOnInit(): void {
    if (this.availableFilters) {
      this.options = this.availableFilters.map((x) => ({
        racun: x.ceoBrojRacuna,
        banka: x.nazivBanke,
        value: x.id,
      }));
      this.tekuciRacuni = this.availableFilters;
      this._addEmptyFilter();
    } else {
      this._service.getTekuciRacuniZaBanku.subscribe((res) => {
        this.tekuciRacuniZaBanku = res;
      });
      this._load();
    }
  }

  private _load() {
    this._subs.add(
      this._client
        .getTekuciRacuniDropdown(null, this.tekuciRacuniZaBanku)
        .subscribe((res) => {
          if (res.data?.tekuciRacunDropdownDtoList) {
            this.tekuciRacuni = res.data.tekuciRacunDropdownDtoList;
            this.options = this.tekuciRacuni.map((b) => ({
              value: b.id,
              banka: b.nazivBanke,
              racun: b.ceoBrojRacuna,
            }));
            const defaultValue = res.data.tekuciRacunDropdownDtoList.find(
              (x) => x.isDefault
            );
            if (
              !this.useAsFilter &&
              (!this.tekuciRacunCtrl?.value || this.tekuciRacunCtrl.value === 0)
            ) {
              this.tekuciRacunCtrl?.patchValue(
                defaultValue?.id ?? this.options[0]?.value
              );
            }
            this._addEmptyFilter();
          } else {
            this.options = [];
          }
        })
    );
  }

  private _addEmptyFilter() {
    if (this.useAsFilter) {
      this.options.splice(0, 0, {
        racun: 'Svi žiro računi',
        banka: null,
        value: null,
      });
    }
  }

  handleTekuciRacunChange(event: any) {
    if (this.onTekuciRacunChanged) {
      const tekuciRacun = this.tekuciRacuni.find((x) => x.id === event.value);
      this.onTekuciRacunChanged.emit(tekuciRacun);
    }
  }

  ngOnDestroy(): void {
    this._service.reset();
    this._subs.unsubscribe();
  }
}
