export class ListConfig {
  title: string;
  subtitle?: string;
  toggleable?: boolean;
  collapsed?: boolean;
  paginator?: boolean;
  rows?: string;
  deleteMessageParams?: string;
  deleteMessage?: string;
  /** events */
  actions?: ListAction[];
  createCallbackFunction?: () => void;
  editCallbackFunction?: (itemIndex: number) => void;
  deleteCallbackFunction?: (itemIndex: number | string) => void;

  constructor(data?: ListConfig) {
    this.title = (data && data.title) || '';
    this.subtitle = (data && data.subtitle) || '';
    this.toggleable = (data && data.toggleable) || false;
    this.collapsed = (data && data.collapsed) || true;
    this.paginator = (data && data.paginator) || true;
    this.rows = (data && data.rows) || '5';
    this.createCallbackFunction = (data && data.createCallbackFunction) || null;
    this.editCallbackFunction = (data && data.editCallbackFunction) || null;
    this.deleteCallbackFunction = (data && data.deleteCallbackFunction) || null;
    (this.actions = (data && data.actions) || []),
      (this.deleteMessageParams = (data && data.deleteMessageParams) || '');
    this.deleteMessage = (data && data.deleteMessage) || '';
  }
}

export declare interface ActionConfigResponse {
  isAvailable: boolean;
}

interface ListActionData {
  type: ActionType;
  label?: string;
  icon?: string;
  actionClass?: ActionClass;
  config?: Promise<ActionConfigResponse> | ActionConfigResponse;
  callback?: (request?: any) => void;
  shouldDisplayRow?: (rowIndex: number) => void;
}

export class ListAction implements ListActionData {
  public type: ActionType;
  public label?: string;
  public icon?: string;
  public actionClass?: ActionClass;
  public config?: Promise<ActionConfigResponse> | ActionConfigResponse;
  public callback?: (request?: any) => void;
  public shouldDisplayRow?: (rowIndex: number) => void;

  constructor(data: ListActionData) {
    if (!data) {
      throw new Error('Data objekat je null!');
    }
    this.type = data.type;
    this.label = data.label;
    this.icon = data.icon;
    this.actionClass = data.actionClass ?? ActionClass.PRIMARY;
    this.config = data.config;
    this.callback = data.callback;
    this.shouldDisplayRow = data.shouldDisplayRow;
  }
}

export enum ActionType {
  CREATE = 0,
  EDIT = 1,
  DELETE = 2,
  CUSTOM = 3,
  NO_ACTIONS = 4,
  MULTIDELETE = 5,
  DROPDOWN = 6,
  DROPDOWN_ITEM = 7,
  MULTI_CUSTOM = 8,
  ARCHIVE = 9,
}

export enum ActionClass {
  PRIMARY = 'p-button-primary',
  SECONDARY = 'p-button-secondary',
  TEXT = 'p-button-text',
  EDIT = 'p-button-success',
  DELETE = 'p-button-danger',
}
