import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IKonfiguracijaBrojaRacunaDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrojRacunaFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetBrojRacunaFormGroup(
    model?: IKonfiguracijaBrojaRacunaDto
  ): FormGroupTypeSafe<IKonfiguracijaBrojaRacunaDto> {
    return this._fb.group<IKonfiguracijaBrojaRacunaDto>({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv, {
        validators: [Validators.required, Validators.maxLength(30)],
      }),
      tipRacuna: new FormControl(model?.tipRacuna, {
        validators: [Validators.required, Validators.min(0)],
      }),
      prefiks: new FormControl(model?.prefiks, {
        validators: [Validators.maxLength(9)],
      }),
      brojac: new FormControl(model?.prefiks, {
        validators: [Validators.required, Validators.min(1)],
      }),
      sufiks: new FormControl(model?.sufiks ?? '/[god]', {
        validators: [Validators.maxLength(9)],
      }),
      isDefault: new FormControl(model?.isDefault ?? false),
    });
  }
}
