<div class="p-fluid">
  <kodit-field
    *ngIf="jeUnosFoldera"
    fieldLabel="Naziv foldera:"
    fieldLabelFor="naziv"
  >
    <kodit-text-input
      [formCtrl]="form?.controls?.naziv"
      formCtrlId="naziv"
    ></kodit-text-input>
  </kodit-field>

  <kodit-field fieldLabel="Izaberite nadredjeni folder:" *ngIf="enableParentSelection">
    <kodit-dropdown
      formCtrlId="parent-datoteka-select"
      [options]="ctrlOptions!"
      [formCtrl]="form?.controls?.parentId!"
      [canClear]="true"
      [canFilter]="true"
      ctrlPlaceholder="Izaberite folder"
      ctrlFilterBy="label"
      (optionChanged)="folderSelected()"
    >
    </kodit-dropdown>
    <small style="color: #8d8d8d">
      <i class="fa-thin fa-circle-exclamation" style="color: 99ee9e"></i>
      Ukoliko polje ostane prazno, folder neće pripadati nijednom nadređenom
      folderu
    </small>
  </kodit-field>

  <div class="p-mb-4">
    <kodit-file-upload
      *ngIf="jeUnosDokumenta"
      (onUpload)="onUpload($event)"
      [uploadCompleted]="uploadCompleted"
      [allowMultipleFiles]="true"
      [fileTypes]="allDocumentTypes"
    ></kodit-file-upload>
  </div>
</div>
