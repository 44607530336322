import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  AvansniRacuniClient,
  CreateAvansniRacunCommand,
  IAvansniRacunDto,
  TipPredmeta,
  TipRacuna,
  UpdateAvansniRacunCommand,
} from '@kodit/core/data-api';
import {
  AvansniRacunFormService,
  FormHelper,
} from '@kodit/core/form-definitions';
import { AlertService, LocationService, SharedService } from '@kodit/core/services';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';
import { RacunService } from '../../../../../../services/src/lib/racun.service';
import { RacunFormConfig } from '../../subforms/racun-form-container/racun-form.config';

@Component({
  selector: 'kodit-avansni-racun-form',
  templateUrl: './avansni-racun-form.component.html',
  styleUrls: ['./avansni-racun-form.component.scss'],
})
export class AvansniRacunFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  racunForm: FormGroupTypeSafe<IAvansniRacunDto>;
  racunFormConfig: RacunFormConfig;
  excludeTipovePredmeta: TipPredmeta[] = [
    TipPredmeta.PREDMET_IZMENE_OSNOVICE,
    TipPredmeta.ROBA,
    TipPredmeta.USLUGA,
    TipPredmeta.VOZILO,
    TipPredmeta.PREDMET_STAVKE,
  ];

  constructor(
    private _client: AvansniRacuniClient,
    private _alertService: AlertService,
    private _confirmationService: ConfirmationService,
    private _locationService: LocationService,
    private _racunService: RacunService,
    private _formService: AvansniRacunFormService,
    private _route: ActivatedRoute,
    private _formHelper: FormHelper,
    private _breadcrumbService: BreadcrumbService,
    private _sharedService : SharedService
  ) {}

  ngOnInit(): void {
    // get route data
    this.racunForm = this._formService.GetAvansniRacunFormGroup(
      this._route.snapshot.data.formConfiguration,
      this._route.snapshot.data.racun
    );

    // configure labels
    this._racunService.setBrojRacunaLabel = 'Broj avansnog računa';
    this.racunFormConfig = {
      tip: TipRacuna.AVANSNI_RACUN,
      racunForm: this.racunForm.controls.racunDto,
      brojRacunaTooltip: 'Dodeljen broj avansnog računa',
      originalanBrojRacunaTooltip:
        'Unesite broj avansnog računa koji ste dobili od izdavaoca',
      saveCallback: () => this.onSave(),
      cancelCallback: () => this.onCancel(),
    };
    this._setBreadcrumb();
  }

  get jeEditMode(): boolean {
    return this._route.snapshot.data.racun?.racunDto?.id;
  }

  get stavkeArray(): FormArray {
    return this.racunForm.get('stavke') as FormArray;
  }

  onSave() {
    //remove nabavna i prodajnaCena (PredmetStavkeDto) validator s'obzirom da avansni racun nema cenovnik stavke
    this._removeNabavnaIProdajnaCenaValidator();

    this._formHelper.removeEmptyStavka(
      this.racunForm.controls.racunDto.get('stavkeDto') as FormArray
    );

    // check is it valid
    if (this._isFormInvalid()) {
      this._formHelper.invalidateForm(this.racunForm);
      return;
    }

    // everything is ok, so let's save it
    if (this.jeEditMode) {
      this._sharedService.displayLoadingScreen(true, 'Ažuriranje avansnog računa...');
      // it's edit
      this._subs.add(
        this._client
        .update(
          this.racunForm.value.racunDto.id,
          this.racunForm.value as UpdateAvansniRacunCommand
          )
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.messages[0]);
            this._locationService.routeBack();
          })
        );
    }
    else {
      this._sharedService.displayLoadingScreen(true, 'Kreiranje avansnog računa...');
      // it's new
      this._subs.add(
        this._client
          .create(this.racunForm.value as CreateAvansniRacunCommand)
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.messages[0]);
            this._locationService.routeBack();
          })
      );
    }
  }

  onCancel() {
    this._confirmationService.confirm({
      message: 'Ukoliko otkažete sav ne snimljen sadržaj će biti izgubljen.',
      acceptLabel: 'Ipak otkaži',
      rejectLabel: 'Ostani na stranici',
      header: 'Potvrdite otkazivanje',
      icon: 'far fa-check-circle',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._locationService.routeBack();
      },
    });
  }

  private _removeNabavnaIProdajnaCenaValidator() {
    //! pronaci bolji nacin
    (this.racunForm.controls.racunDto.get('stavkeDto') as FormArray).controls.forEach((x) =>
      x.get('predmetStavkeDto.nabavnaCena').clearValidators()
    );

    (this.racunForm.controls.racunDto.get('stavkeDto') as FormArray).controls.forEach((x) =>
      x.get('predmetStavkeDto.nabavnaCena').updateValueAndValidity()
    );

    (this.racunForm.controls.racunDto.get('stavkeDto') as FormArray).controls.forEach((x) =>
      x.get('predmetStavkeDto.prodajnaCenaBezPdv').clearValidators()
    );

    (this.racunForm.controls.racunDto.get('stavkeDto') as FormArray).controls.forEach((x) =>
      x.get('predmetStavkeDto.prodajnaCenaBezPdv').updateValueAndValidity()
    );
  }

  private _isFormInvalid(){
    const racun = this._route.snapshot.data.racun;
    const ukupnoZaUplatu = this._racunService.getUkupanIznos.value.ukupnoZaUplatu;
    const ukupanIznosUplate = racun.ukupanIznosUplate;

    if (!!ukupanIznosUplate && ukupnoZaUplatu !== ukupanIznosUplate) {
      this._alertService.addWarnMsg('Ukupan iznos uplate i avansnog računa nisu jednaki!');
      return true;
    }

    return !this.racunForm.valid;
  }

  private _setBreadcrumb() {
    this._breadcrumbService.set(
      '@unosDokumentaOSmanjenjuZaStranku',
      `Unos dokumenta o smanjenju za '${this.racunForm.value.racunDto.strankaRacunaDto.naziv}'`
    );
    this._breadcrumbService.set(
      '@izmenaAvansnogRacuna',
      `Izmena avansnog računa  ${this.racunForm.value.racunDto.broj}`
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
