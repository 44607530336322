<kodit-dropdown
  formCtrlId="valuta-select"
  [options]="valuteOptions"
  [formCtrl]="valutaCtrl"
  [canFilter]="true"
  ctrlFilterBy="naziv,alfaChar,drzava"
  ctrlPlaceholder="Izaberite valutu"
  (optionChanged)="valutaChanged($event)"
>
  <ng-template #selectedItem let-obj>
    <div class="p-text-left">{{ obj.alfaChar }}</div>
  </ng-template>

  <ng-template #item let-obj>
    <div class="p-text-bold">{{ obj.naziv }} ({{ obj.alfaChar }})</div>
    <div class="p-text-left">{{ obj.drzava }}</div>
  </ng-template>
</kodit-dropdown>
