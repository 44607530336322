import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  FiskalniRacuniClient,
  InvoiceStatusResponse,
  TaxCategoryResponse, TaxGroupResponse,
  TaxRateResponse
} from "@kodit/core/data-api";
import {Subscription} from "rxjs";
import {AlertService} from "@kodit/core/services";
import {TreeNode} from "primeng/api";
import {formatDate} from "@angular/common";

@Component({
  selector: 'kodit-poreske-stop-fiskalne-kase',
  templateUrl: './poreske-stop-fiskalne-kase.component.html',
  styleUrls: ['./poreske-stop-fiskalne-kase.component.scss']
})
export class PoreskeStopFiskalneKaseComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs : Subscription = new Subscription();

  /** Configurations */
  displayType: string = 'vertical';
  emptyMessage: string = 'Nema poreskih stopa.';

  /** Props */
  poreskeStope: TreeNode<any>[] = [];
  statusFiskalneKase : InvoiceStatusResponse;

  constructor(
    private _client : FiskalniRacuniClient,
    private _alertService : AlertService
  ) { }

  ngOnInit(): void {
    this._loadPoreskeStope();
  }

  iscitajIzPfr(){
    this._loadPoreskeStope(true);
  }

  private _loadPoreskeStope(manual : boolean = false){
    this.poreskeStope = [];
    this._subs.add(
      this._client.getStatusFiskalneKase().subscribe((res)=>{
        this.statusFiskalneKase = res.data;
        this.poreskeStope.push(
          {
            label: 'Trenutne poreske stope',
            data: this.statusFiskalneKase.currentTaxRates,
            children: this._getPoreskeStopeChildren([this.statusFiskalneKase.currentTaxRates]),
            icon: 'fa-solid fa-folder-closed',
            expanded: false,
            selectable: true,
          },
          {
            label: 'Sve poreske stope',
            data: this.statusFiskalneKase.allTaxRates,
            children: this._getPoreskeStopeChildren(this.statusFiskalneKase.allTaxRates),
            icon: 'fa-solid fa-folder-closed',
            expanded: false,
            selectable: true,
          }
        )

        if(manual){
          this._alertService.addSuccessMsg('Uspešno ste iščitali poreske stope!');
        }
      })
    );
  }

  private _getPoreskeStopeChildren(taxGroups : TaxGroupResponse[]) : TreeNode<TaxGroupResponse>[]{
    const categoryResponse = [];
    taxGroups.forEach((x)=>{
      categoryResponse.push(
        {
          label: formatDate(x.validFrom, 'yyyy-MM-dd hh:mm:ssZZZZZ', 'en_US'),
          data: x,
          children: this._getTaxCategoriesResponse(x),
          icon: 'fa-solid fa-folder-closed',
          expanded: false,
          selectable: true,
        }
      );
    })

    return categoryResponse;
  }

  private _getTaxCategoriesResponse(taxGroup : TaxGroupResponse) : TreeNode<TaxCategoryResponse> []{
    const categoryResponseChildren = [];
    taxGroup.taxCategories.forEach((x)=>{
      categoryResponseChildren.push(
        {
          label: x.name,
          data: x,
          children: this._getTaxRatesResponse(x.taxRates),
          icon: 'fa-solid fa-folder-closed',
          expanded: false,
          selectable: true,
        }
      )
    });
    return categoryResponseChildren;
  }

  private _getTaxRatesResponse(taxRates : TaxRateResponse[]) : TreeNode<TaxRateResponse> []{
    const taxRateResponse = [];
    taxRates.forEach((x)=>{
      taxRateResponse.push({
        label: x.label + ' (' + x.rate + '%)',
        data: x,
        icon: 'fa-solid fa-folder-closed',
        expanded: false,
        selectable: true,
      })
    });
    return taxRateResponse;
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
