import { Component, OnInit } from '@angular/core';
import { AhrivskaKnjigaPdfVrsta, ArhivskaKnjigaClient } from '@kodit/core/data-api';
import { SharedService } from '@kodit/core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-arhivska-knjiga-page',
  templateUrl: './arhivska-knjiga-page.component.html',
  styleUrls: ['./arhivska-knjiga-page.component.scss'],
})
export class ArhivskaKnjigaPageComponent implements OnInit {
  _subs: Subscription = new Subscription();

  constructor(
    private _client: ArhivskaKnjigaClient,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {}

  preuzmiCuvanje() {
    this._subs.add(
      this._client
        .getPdf(AhrivskaKnjigaPdfVrsta.PRAVILNIK_O_NACINU_EVIDENTIRANJA_CUVANJE)
        .subscribe((res) => {
          const url = window.URL.createObjectURL(res.data);
          this._sharedService.downloadUrlAsPDF(url, 'Pravilnik o načinu evidentiranja, klasifikovanja, arhiviranja i čuvanja arhivske građe i dokumentarnog materijala');
        })
    );
  }

  // preuzmiElektronsko() {
  //   this._subs.add(
  //     this._client
  //       .getPdf(
  //         AhrivskaKnjigaPdfVrsta.PRAVILNIK_O_NACINU_EVIDENTIRANJA_ELEKTRONSKO
  //       )
  //       .subscribe((res) => {
  //         const url = window.URL.createObjectURL(res.data);
  //         this._sharedService.downloadUrlAsPDF(url, 'Pravilnik o načinu evidentiranja, zaštite i korišćenja elektronskih dokumenata');
  //       })
  //   );
  // }

  // preuzmiOdluku() {
  //   this._subs.add(
  //     this._client
  //       .getPdf(AhrivskaKnjigaPdfVrsta.ODLUKA_O_IMENOVANJU)
  //       .subscribe((res) => {
  //         const url = window.URL.createObjectURL(res.data);
  //         this._sharedService.downloadUrlAsPDF(url, 'Odluka o imenovanju stručnog lica za zaštitu arhivske građe');
  //       })
  //   );
  // }

  // preuzmListu() {
  //   this._subs.add(
  //     this._client
  //       .getPdf(AhrivskaKnjigaPdfVrsta.LISTA_KATEGORIJA)
  //       .subscribe((res) => {
  //         const url = window.URL.createObjectURL(res.data);
  //         this._sharedService.downloadUrlAsPDF(url, 'Lista kategorija dokumentarnog materijala sa rokovima čuvanja');
  //       })
  //   );
  // }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
