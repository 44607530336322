import { FormControl } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IPrikazOtpremniceDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrikazOtpremniceFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetPrikazOtpremniceFormGroup(
    model?: IPrikazOtpremniceDto
  ): FormGroupTypeSafe<IPrikazOtpremniceDto> {
    return this._fb.group<IPrikazOtpremniceDto>({
      opis: new FormControl(model?.opis),
      prikazOtpremnice: new FormControl(model?.prikazOtpremnice),
      prikazOtpremniceStr: new FormControl(model?.prikazOtpremniceStr),
    });
  }
}
