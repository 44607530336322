import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';
import {
  GetStatusiRacunaQuery,
  SharedClient,
  StatusRacuna,
} from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-status-racuna-multiselect',
  templateUrl: './status-racuna-multiselect.component.html',
  styleUrls: ['./status-racuna-multiselect.component.scss'],
})
export class StatusRacunaMultiselectComponent implements OnInit {
  //* Props */
  items: SelectItem[];
  selectedItems: StatusRacuna[] = [];
  @Input() statusFormArray: FormArray | AbstractControl;

  @Output() selectedStatusiRacuna = new EventEmitter<StatusRacuna[]>();

  private _subs: Subscription = new Subscription();

  /** Props */
  @Input() allowedStatuses: StatusRacuna[] = [];

  /** I/O */

  @Input() formCtrlId: string;
  @Input() parentCtrl: any = 'body';
  @Input() tipCtrl: FormControl | AbstractControl;
  @Output() onItemChange = new EventEmitter<StatusRacuna>();

  constructor(private _client: SharedClient) {}

  ngOnInit(): void {
    this.selectedItems = [...this.statusFormArray.value.map((x) => x.status)];
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client
        .getStatusiRacuna({
          allowedStatuses: this.allowedStatuses,
        } as GetStatusiRacunaQuery)
        .subscribe((res) => {
          this.items = res.data.statusiRacunaDtoList.map((x) => ({
            value: x.status,
            label: x.statusStr,
            title: x.opis,
          }));
          this.items = this.items.filter((x) => x.value != StatusRacuna.EMPTY);
        })
    );
  }

  selectedItemsOnChange() {
    this.selectedStatusiRacuna.next(this.selectedItems);
  }
  handleOnItemChange(event: any) {
    this.onItemChange.emit(event);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
