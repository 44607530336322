import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-telefon-input',
  templateUrl: './telefon-input.component.html',
  styleUrls: ['./telefon-input.component.scss'],
})
export class TelefonInputComponent implements OnInit {
  telefonFilter: RegExp = /^[\+]?[(]?[0-9{3}]?[)]?[0-9{3}]?[/\s\.]?[-\s\.]*$/;

  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string;
  @Input() formMaxLength: number;

  constructor() {}

  ngOnInit(): void {}
}
