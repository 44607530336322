import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DatumObracunaPdv, TipRacuna } from '@kodit/core/data-api';
import { RacunService } from '@kodit/core/services';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-datum-obracuna-pdv-dropdown',
  templateUrl: './datum-obracuna-pdv-dropdown.component.html',
  styleUrls: ['./datum-obracuna-pdv-dropdown.component.scss'],
})
export class DatumObracunaPdvDropdownComponent implements OnInit {
  /** Props */
  items: SelectItem[] = [];

  /** I/O */
  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string;
  @Input() parentCtrl: any = 'body';
  tipRacuna: TipRacuna;

  constructor(private _racunService: RacunService) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    if (this._racunService.getTipRacuna === TipRacuna.DOKUMENT_O_POVECANJU) {
      this.items = [
        {
          value: DatumObracunaPdv.NA_DAN_IZDAVANJA,
          label: 'Datum izdavanja - zaračunavanje troškova',
          title: 'Datum izdavanja - zaračunavanje troškova',
        },
        {
          value: DatumObracunaPdv.NA_DAN_PROMETA,
          label: 'Datum povećanja - ugovor',
          title: 'Datum povećanja - ugovor',
        },
        {
          value: DatumObracunaPdv.BEZ_OBAVEZE,
          label: 'Ne nastaje obaveza obračuna PDV',
          title: 'Ne nastaje obaveza obračuna PDV',
        },
      ];
    } else if (this._racunService.getTipRacuna === TipRacuna.AVANSNI_RACUN) {
      this.items = [
        {
          value: DatumObracunaPdv.NA_DAN_PLACANJA,
          label: 'Datum avansa',
          title: 'Datum avansa',
        },
        {
          value: DatumObracunaPdv.BEZ_OBAVEZE,
          label: 'Ne nastaje obaveza obračuna PDV',
          title: 'Ne nastaje obaveza obračuna PDV',
        },
      ];
    } else {
      this.items = [
        {
          value: DatumObracunaPdv.NA_DAN_PROMETA,
          label: 'Datum prometa',
          title: 'Datum prometa',
        },
        {
          value: DatumObracunaPdv.NA_DAN_IZDAVANJA,
          label: 'Član 16. Tačka 2a) ZPDV',
          title: 'Član 16. Tačka 2a) ZPDV',
        },
        {
          value: DatumObracunaPdv.NA_DAN_PLACANJA,
          label: 'Član 36a ZPDV',
          title: 'Član 36a ZPDV',
        },
        {
          value: DatumObracunaPdv.BEZ_OBAVEZE,
          label: 'Ne nastaje obaveza obračuna PDV',
          title: 'Ne nastaje obaveza obračuna PDV',
        },
      ];
    }
  }
}
