import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService, ButtonService, SharedService } from '@kodit/core/services';
import {
  IPravnoLiceDto,
  PravnaLicaClient,
  TipPravnogLica,
  PravnoLiceDto,
  UpdatePravnoLiceCommand,
  CreatePravnoLiceCommand,
} from '@kodit/core/data-api';

import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import {
  FormHelper,
  PravnoLiceFormService,
} from 'libs/core/form-definitions/src';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-pravno-lice-form',
  templateUrl: './pravno-lice-form.component.html',
  styleUrls: ['./pravno-lice-form.component.scss'],
})
export class PravnoLiceFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _saveSub: Subscription = new Subscription();
  private _submitSub: Subscription = new Subscription();
  private _getSub: Subscription = new Subscription();
  private _errorSub: Subscription = new Subscription();

  /** Props */
  pravnoLiceForm: FormGroupTypeSafe<IPravnoLiceDto>;
  tipPravnog: TipPravnogLica;
  activeIndex: number = 0;
  strankaId: number;
  jeDomacePravnoLice: boolean;
  jeUnos: boolean;
  daNeOptions: SelectItem[] = [];

  constructor(
    private _client: PravnaLicaClient,
    private _alertService: AlertService,
    public _dialogRef: DynamicDialogRef,
    public _dialogConfig: DynamicDialogConfig,
    private _formService: PravnoLiceFormService,
    private _FormHelper: FormHelper,
    private _buttonService: ButtonService,
    private _sharedService : SharedService
  ) {}

  ngOnInit(): void {
    // subscribe on dialog save button
    this._submitSub = this._dialogRef.onSubmit.subscribe(() => {
      this.onSave();
    });

    /** get mode and patch if needed */
    this.tipPravnog = this._dialogConfig.data.tipPravnogLica;
    
    /** init form */
    this.pravnoLiceForm = this._formService.GetPravnoLiceFormGroup({
      tipPravnogLica: this.tipPravnog,
    });
    if (this._dialogConfig.data.id) {
      this.strankaId = this._dialogConfig.data.id;
      this.getPravnoLice(this._dialogConfig.data.id);
    } else {
      this.jeUnos = true;
    }
    this.jeDomacePravnoLice = this.tipPravnog === TipPravnogLica.DOMACE;
    this.daNeOptions = [
      { label: 'Da', value: true },
      { label: 'Ne', value: false },
    ];
  }

  onSave() {
    if (!this.pravnoLiceForm.valid) {
      this._FormHelper.invalidateForm(this.pravnoLiceForm);
      return;
    }

    this._buttonService.disableButton();

    // everything is ok, let's save it
    if (!this.jeUnos) {
      this._sharedService.displayLoadingScreen(true, 'Ažuriranje pravnog lica...');
      /** Edit */
      this._saveSub = this._client
        .update(
          this.pravnoLiceForm.value.id,
          this.pravnoLiceForm.value as UpdatePravnoLiceCommand
        )
        .subscribe((result) => {
          this._alertService.addSuccessMsg(
            `Pravno lice ${this.pravnoLiceForm.value.naziv} je uspešno izmenjeno.`
          );
          this._dialogRef.close(result);
        });
    } else {
      this._sharedService.displayLoadingScreen(true, 'Kreiranje pravnog lica...');
      /** Create */
      this._saveSub = this._client
        .create(this.pravnoLiceForm.value as CreatePravnoLiceCommand)
        .subscribe((result) => {
          this._alertService.addSuccessMsg(
            `Pravno lice ${this.pravnoLiceForm.value.naziv} je uspešno kreirano.`
          );
          this._dialogRef.close(result);
        });
    }
  }

  private getPravnoLice(id: number) {
    this._getSub = this._client.getById(id).subscribe(
      (model) => {
        this.patchFormByModel(model.data);
      },
      (error) => {
        this._alertService.addFailedMsg(error);
      }
    );
  }

  private patchFormByModel(model: PravnoLiceDto) {
    this.pravnoLiceForm.patchValue(model);
    this.pravnoLiceForm.controls.adresa.patchValue(model.adresa);
    this.pravnoLiceForm.updateValueAndValidity();
    this.pravnoLiceForm.controls.adresa.updateValueAndValidity();
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.onSave();
    }
  }

  ngOnDestroy(): void {
    this._saveSub.unsubscribe();
    this._submitSub.unsubscribe();
    this._getSub.unsubscribe();
    this._errorSub.unsubscribe();
  }
}
