import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  PdvEvidencijeClient,
  PdvOpseg,
  PdvPeriod,
  PdvPeriodDto,
} from '@kodit/core/data-api';
import { PdvEvidencijaService } from 'libs/core/moduli/pdv-evidencija/src/lib/pdv-evidencija.service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { RacunFilterService } from '../../racun-filter.service';

@Component({
  selector: 'kodit-racun-filter-period-dropdown',
  templateUrl: './racun-filter-period-dropdown.component.html',
  styleUrls: ['./racun-filter-period-dropdown.component.css'],
})
export class RacunFilterPeriodDropdownComponent implements OnInit {
  /** Subs */
  private _subs: Subscription;

  /** Props */
  items: SelectItem[];
  pdvPeriodi: PdvPeriodDto[];

  /** I/O */
  @Input() formCtrl: AbstractControl | undefined;
  @Input() formCtrlId: string;
  @Input() parentCtrl: string;

  constructor(
    private _client: PdvEvidencijeClient,
    private _service: RacunFilterService
  ) {
    this._subs = new Subscription();
    this.items = [];
    this.formCtrl = undefined!;
    this.formCtrlId = 'pdv-period-dropdown';
    this.parentCtrl = 'body';
    this.pdvPeriodi = [];
  }

  ngOnInit() {
    this._load();
    this._subs.add(
      this._service.getOpseg.subscribe((res) => {
        if (res === null || this.pdvPeriodi.length === 0) {
          return;
        }

        if (res === PdvOpseg.KVARTALNO) {
          this._setKvartalni();
        } else {
          this._setMesecni();
        }
      })
    );
  }

  private _load() {
    this._subs.add(
      this._client.getPdvPeriodDropdown().subscribe((res) => {
        if (!res.succeeded) {
          return;
        }
        this.pdvPeriodi = res.data!.responseList!;

        if (this.formCtrl?.value > PdvPeriod.DECEMBAR) {
          this._setKvartalni();
        } else {
          this._setMesecni();
        }
      })
    );
  }

  private _setMesecni() {
    this.items = this.pdvPeriodi
      .filter((x) => x.opseg === PdvOpseg.MESECNO)
      .map((x) => ({
        value: x.period,
        label: x.periodStr,
        title: x.opis,
      }));
    this.items.unshift({
      value: null,
      label: 'Izaberite period',
      title: 'Izaberite period',
    });
  }

  private _setKvartalni() {
    this.items = this.pdvPeriodi
      .filter((x) => x.opseg === PdvOpseg.KVARTALNO)
      .map((x) => ({
        value: x.period,
        label: x.periodStr,
        title: x.opis,
      }));
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
