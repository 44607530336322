<!--Dodati [formGroup]-->
<div *ngIf="!form">Loading...</div>
<div *ngIf="form" [formGroup]="form">
  <div class="p-mt-5">
    <kodit-card-container>
      <div card-body class="p-d-flex p-flex-row gap-12">
        <!--Leva strana-->
        <div class="flex-50 p-d-flex p-flex-column">
          <!-- Broj-->
          <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
            <kodit-field
              fieldLabel="Broj pojedinačne PDV evidencije"
              fieldLabelFor="pravilo-za-broj"
              class="flex-50"
            >
              <kodit-text-input
                [formCtrl]="form.controls.broj"
                [isDisabled]="shouldDisableForm"
              ></kodit-text-input>
            </kodit-field>
          </div>
          <!-- Godina-->
          <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
            <kodit-field fieldLabel="Godina" fieldLabelFor="godina" class="flex-50">
              <kodit-number-input
                formCtrlId="godina"
                [formCtrl]="form.controls.godina"
                [ctrlMaxLength]="4"
                [ctrlMinValue]="1900"
                [ctrlOnlyIntegers]="true"
                [ctrlUseGrouping]="false"
                [isDisabled]="shouldDisableForm"
              ></kodit-number-input>
            </kodit-field>
          </div>
          <!-- Poreski period-->
          <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
            <kodit-field
              fieldLabelFor="vremenski-opseg"
              fieldLabel="Poreski period"
              class="flex-50"
            >
              <kodit-pdv-opseg-dropdown
                formCtrlId="vremenski-opseg"
                [periodValue]="form.value.period!"
                [shouldDisable]="shouldDisableForm"
              ></kodit-pdv-opseg-dropdown>
            </kodit-field>
          </div>
          <!-- Period-->
          <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
            <kodit-field fieldLabelFor="period" fieldLabel="Period" class="flex-50">
              <kodit-pdv-period-dropdown
                formCtrlId="period"
                [formCtrl]="form.controls.period"
                [shouldDisable]="shouldDisableForm"
              ></kodit-pdv-period-dropdown>
            </kodit-field>
          </div>
        </div>
        <!-- Desno-->
        <div class="flex-50 p-d-flex p-flex-column">
          <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
            <kodit-field
              fieldLabel="Broj pojedinačne PDV evidencije"
              fieldLabelFor="pravilo-za-broj"
              class="flex-50"
            >
              <kodit-text-input
                [formCtrl]="form.controls.sefId"
                [isDisabled]="true"
              ></kodit-text-input>
            </kodit-field>
          </div>
          <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
            <kodit-field
              fieldLabelFor="datum-evidentiranja"
              fieldLabel="Datum evidentiranja"
              class="flex-50"
            >
              <kodit-calendar
                [disabled]="true"
                formCtrlId="datum-prometa"
                [formCtrl]="form.controls.datumEvidentiranja!"
              ></kodit-calendar>
            </kodit-field>
          </div>
        </div>
      </div>
    </kodit-card-container>
  </div>
  <div class="p-d-flex p-flex-column">
    <p-accordion [multiple]="true" class="p-mt-5">
      <!-- Deo 1-->
      <p-accordionTab [transitionOptions]="'0ms'" class="main-header"
                      header="Deo 1. Nabavka dobara i usluga u Republici Srbiji od obveznika PDV – promet za koji je poreski dužnik isporučilac dobara, odnosno pružalac usluga">
        <p-accordion [multiple]="true">
          <!-- 1.1-->
          <p-accordionTab [transitionOptions]="'0ms'" class="secondary-header"
                          header="1.1 Prvi prenos prava raspolaganja na novoizgrađenim građevinskim objektima">
            <div class="grid-rows-7 grid grid-flow-col ">
              <div></div>
              <div class="p-d-flex"><h4 class="font-semibold margin-auto">Izvori:</h4></div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.1.1 Elektronske fakture</p>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.1.2 Fiskalni računi</p>
              </div>
              <div class="p-d-flex "><p class="margin-auto">1.1.3 Drugi računi</p></div>
              <div class="p-d-flex "><p class="margin-auto">1.1.4 Ukupno</p></div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.1.5 PDV koji se može odbiti kao prethodni porez</p>
              </div>
              <!-- Druga kolona-->
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 20%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 20%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-5 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF20Osnovica11"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica11,
                      [
                      form.controls.eF20Osnovica11,
                      form.controls.fiskalni20Osnovica11,
                      form.controls.drugi20Osnovica11,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF20Pdv11"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv11,
                      [
                      form.controls.eF20Pdv11,
                      form.controls.fiskalni20Pdv11,
                      form.controls.drugi20Pdv11,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni20Osnovica11"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica11,
                      [
                      form.controls.eF20Osnovica11,
                      form.controls.fiskalni20Osnovica11,
                      form.controls.drugi20Osnovica11,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni20Pdv11"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv11,
                      [
                      form.controls.eF20Pdv11,
                      form.controls.fiskalni20Pdv11,
                      form.controls.drugi20Pdv11,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi20Osnovica11"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica11,
                      [
                      form.controls.eF20Osnovica11,
                      form.controls.fiskalni20Osnovica11,
                      form.controls.drugi20Osnovica11,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi20Pdv11"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv11,
                      [
                      form.controls.eF20Pdv11,
                      form.controls.fiskalni20Pdv11,
                      form.controls.drugi20Pdv11,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno20Osnovica11"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno20Pdv11"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.odbitak20Pdv11"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 10%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 10%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-5 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF10Osnovica11"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica11,
                      [
                      form.controls.eF10Osnovica11,
                      form.controls.fiskalni10Osnovica11,
                      form.controls.drugi10Osnovica11,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF10Pdv11"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv11,
                      [
                      form.controls.eF10Pdv11,
                      form.controls.fiskalni10Pdv11,
                      form.controls.drugi10Pdv11,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni10Osnovica11"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica11,
                      [
                      form.controls.eF10Osnovica11,
                      form.controls.fiskalni10Osnovica11,
                      form.controls.drugi10Osnovica11,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni10Pdv11"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv11,
                      [
                      form.controls.eF10Pdv11,
                      form.controls.fiskalni10Pdv11,
                      form.controls.drugi10Pdv11,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi10Osnovica11"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica11,
                      [
                      form.controls.eF10Osnovica11,
                      form.controls.fiskalni10Osnovica11,
                      form.controls.drugi10Osnovica11,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi10Pdv11"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv11,
                      [
                      form.controls.eF10Pdv11,
                      form.controls.fiskalni10Pdv11,
                      form.controls.drugi10Pdv11,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno10Osnovica11"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno10Pdv11"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.odbitak10Pdv11"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
            </div>
          </p-accordionTab>
          <!-- 1.2-->
          <p-accordionTab [transitionOptions]="'0ms'" class="secondary-header"
                          header="1.2 Promet dobara i usluga, osim prvog prenosa prava raspolaganja na novoizgrađenim građevinskim objektima">
            <div class="grid-rows-7 grid grid-flow-col ">
              <div></div>
              <div class="p-d-flex"><h4 class="font-semibold margin-auto">Izvori:</h4></div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.2.1 Elektronske fakture</p>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.2.2 Fiskalni računi</p>
              </div>
              <div class="p-d-flex "><p class="margin-auto">1.2.3 Drugi računi</p></div>
              <div class="p-d-flex "><p class="margin-auto">1.2.4 Ukupno</p></div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.2.5 PDV koji se može odbiti kao prethodni porez</p>
              </div>
              <!-- Druga kolona-->
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 20%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 20%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-5 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF20Osnovica12"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica12,
                      [
                      form.controls.eF20Osnovica12,
                      form.controls.fiskalni20Osnovica12,
                      form.controls.drugi20Osnovica12,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF20Pdv12"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv12,
                      [
                      form.controls.eF20Pdv12,
                      form.controls.fiskalni20Pdv12,
                      form.controls.drugi20Pdv12,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni20Osnovica12"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica12,
                      [
                      form.controls.eF20Osnovica12,
                      form.controls.fiskalni20Osnovica12,
                      form.controls.drugi20Osnovica12,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni20Pdv12"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv12,
                      [
                      form.controls.eF20Pdv12,
                      form.controls.fiskalni20Pdv12,
                      form.controls.drugi20Pdv12,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi20Osnovica12"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica12,
                      [
                      form.controls.eF20Osnovica12,
                      form.controls.fiskalni20Osnovica12,
                      form.controls.drugi20Osnovica12,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi20Pdv12"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv12,
                      [
                      form.controls.eF20Pdv12,
                      form.controls.fiskalni20Pdv12,
                      form.controls.drugi20Pdv12,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno20Osnovica12"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno20Pdv12"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.odbitak20Pdv12"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 10%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 10%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-5 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF10Osnovica12"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica12,
                      [
                      form.controls.eF10Osnovica12,
                      form.controls.fiskalni10Osnovica12,
                      form.controls.drugi10Osnovica12,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF10Pdv12"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv12,
                      [
                      form.controls.eF10Pdv12,
                      form.controls.fiskalni10Pdv12,
                      form.controls.drugi10Pdv12,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni10Osnovica12"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica12,
                      [
                      form.controls.eF10Osnovica12,
                      form.controls.fiskalni10Osnovica12,
                      form.controls.drugi10Osnovica12,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni10Pdv12"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv12,
                      [
                      form.controls.eF10Pdv12,
                      form.controls.fiskalni10Pdv12,
                      form.controls.drugi10Pdv12,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi10Osnovica12"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica12,
                      [
                      form.controls.eF10Osnovica12,
                      form.controls.fiskalni10Osnovica12,
                      form.controls.drugi10Osnovica12,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi10Pdv12"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv12,
                      [
                      form.controls.eF10Pdv12,
                      form.controls.fiskalni10Pdv12,
                      form.controls.drugi10Pdv12,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno10Osnovica12"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno10Pdv12"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.odbitak10Pdv12"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
            </div>
          </p-accordionTab>
          <!-- 1.3-->
          <p-accordionTab [transitionOptions]="'0ms'" class="secondary-header"
                          header="1.3 Povećanje osnovice, odnosno PDV">
            <div class="grid-rows-7 grid grid-flow-col ">
              <div></div>
              <div class="p-d-flex"><h4 class="font-semibold margin-auto">Izvori:</h4></div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.3.1 Elektronske fakture</p>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.3.2 Fiskalni računi</p>
              </div>
              <div class="p-d-flex "><p class="margin-auto">1.3.3 Drugi računi</p></div>
              <div class="p-d-flex "><p class="margin-auto">1.3.4 Ukupno</p></div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.3.5 PDV koji se može odbiti kao prethodni porez</p>
              </div>
              <!-- Druga kolona-->
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 20%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 20%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-5 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF20Osnovica13"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica13,
                      [
                      form.controls.eF20Osnovica13,
                      form.controls.fiskalni20Osnovica13,
                      form.controls.drugi20Osnovica13,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF20Pdv13"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv13,
                      [
                      form.controls.eF20Pdv13,
                      form.controls.fiskalni20Pdv13,
                      form.controls.drugi20Pdv13,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni20Osnovica13"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica13,
                      [
                      form.controls.eF20Osnovica13,
                      form.controls.fiskalni20Osnovica13,
                      form.controls.drugi20Osnovica13,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni20Pdv13"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv13,
                      [
                      form.controls.eF20Pdv13,
                      form.controls.fiskalni20Pdv13,
                      form.controls.drugi20Pdv13,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi20Osnovica13"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica13,
                      [
                      form.controls.eF20Osnovica13,
                      form.controls.fiskalni20Osnovica13,
                      form.controls.drugi20Osnovica13,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi20Pdv13"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv13,
                      [
                      form.controls.eF20Pdv13,
                      form.controls.fiskalni20Pdv13,
                      form.controls.drugi20Pdv13,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno20Osnovica13"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno20Pdv13"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.odbitak20Pdv13"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 10%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">PDV</h4>
              </div>
              <!-- 10%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-5 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF10Osnovica13"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica13,
                      [
                      form.controls.eF10Osnovica13,
                      form.controls.fiskalni10Osnovica13,
                      form.controls.drugi10Osnovica13,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF10Pdv13"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv13,
                      [
                      form.controls.eF10Pdv13,
                      form.controls.fiskalni10Pdv13,
                      form.controls.drugi10Pdv13,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni10Osnovica13"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica13,
                      [
                      form.controls.eF10Osnovica13,
                      form.controls.fiskalni10Osnovica13,
                      form.controls.drugi10Osnovica13,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni10Pdv13"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv13,
                      [
                      form.controls.eF10Pdv13,
                      form.controls.fiskalni10Pdv13,
                      form.controls.drugi10Pdv13,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi10Osnovica13"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica13,
                      [
                      form.controls.eF10Osnovica13,
                      form.controls.fiskalni10Osnovica13,
                      form.controls.drugi10Osnovica13,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi10Pdv13"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv13,
                      [
                      form.controls.eF10Pdv13,
                      form.controls.fiskalni10Pdv13,
                      form.controls.drugi10Pdv13,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno10Osnovica13"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno10Pdv13"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.odbitak10Pdv13"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
            </div>
          </p-accordionTab>
          <!-- 1.4-->
          <p-accordionTab [transitionOptions]="'0ms'" class="secondary-header"
                          header="1.4 Smanjenje osnovice, odnosno PDV">
            <div class="grid-rows-7 grid grid-flow-col ">
              <div></div>
              <div class="p-d-flex"><h4 class="font-semibold margin-auto">Izvori:</h4></div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.4.1 Elektronske fakture</p>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.4.2 Fiskalni računi</p>
              </div>
              <div class="p-d-flex "><p class="margin-auto">1.4.3 Drugi dokumenti o smanjenju</p></div>
              <div class="p-d-flex "><p class="margin-auto">1.4.4 Druga dokumentacija</p></div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.4.5 Ukupno</p>
              </div>
              <!-- Druga kolona-->
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 20%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">PDV</h4>
              </div>
              <!-- 20%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-5 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF20Osnovica14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica14,
                      [
                      form.controls.eF20Osnovica14,
                      form.controls.fiskalni20Osnovica14,
                      form.controls.drugaDok20Osnovica14,
                      form.controls.drugi20Osnovica14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF20Pdv14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv14,
                      [
                      form.controls.eF20Pdv14,
                      form.controls.fiskalni20Pdv14,
                      form.controls.drugaDok20Pdv14,
                      form.controls.drugi20Pdv14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni20Osnovica14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica14,
                      [
                      form.controls.eF20Osnovica14,
                      form.controls.fiskalni20Osnovica14,
                      form.controls.drugaDok20Osnovica14,
                      form.controls.drugi20Osnovica14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni20Pdv14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv14,
                      [
                      form.controls.eF20Pdv14,
                      form.controls.fiskalni20Pdv14,
                      form.controls.drugaDok20Pdv14,
                      form.controls.drugi20Pdv14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi20Osnovica14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica14,
                      [
                      form.controls.eF20Osnovica14,
                      form.controls.fiskalni20Osnovica14,
                      form.controls.drugaDok20Osnovica14,
                      form.controls.drugi20Osnovica14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi20Pdv14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv14,
                      [
                      form.controls.eF20Pdv14,
                      form.controls.fiskalni20Pdv14,
                      form.controls.drugaDok20Pdv14,
                      form.controls.drugi20Pdv14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugaDok20Osnovica14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica14,
                      [
                      form.controls.eF20Osnovica14,
                      form.controls.fiskalni20Osnovica14,
                      form.controls.drugaDok20Osnovica14,
                      form.controls.drugi20Osnovica14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugaDok20Pdv14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv14,
                      [
                      form.controls.eF20Pdv14,
                      form.controls.fiskalni20Pdv14,
                      form.controls.drugaDok20Pdv14,
                      form.controls.drugi20Pdv14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno20Osnovica14"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno20Pdv14"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 10%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">PDV</h4>
              </div>
              <!-- 10%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-5 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF10Osnovica14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica14,
                      [
                      form.controls.eF10Osnovica14,
                      form.controls.fiskalni10Osnovica14,
                      form.controls.drugaDok10Osnovica14,
                      form.controls.drugi10Osnovica14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF10Pdv14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv14,
                      [
                      form.controls.eF10Pdv14,
                      form.controls.fiskalni10Pdv14,
                      form.controls.drugaDok10Pdv14,
                      form.controls.drugi10Pdv14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni10Osnovica14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica14,
                      [
                      form.controls.eF10Osnovica14,
                      form.controls.fiskalni10Osnovica14,
                      form.controls.drugaDok10Osnovica14,
                      form.controls.drugi10Osnovica14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni10Pdv14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv14,
                      [
                      form.controls.eF10Pdv14,
                      form.controls.fiskalni10Pdv14,
                      form.controls.drugaDok10Pdv14,
                      form.controls.drugi10Pdv14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi10Osnovica14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica14,
                      [
                      form.controls.eF10Osnovica14,
                      form.controls.fiskalni10Osnovica14,
                      form.controls.drugaDok10Osnovica14,
                      form.controls.drugi10Osnovica14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi10Pdv14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv14,
                      [
                      form.controls.eF10Pdv14,
                      form.controls.fiskalni10Pdv14,
                      form.controls.drugaDok10Pdv14,
                      form.controls.drugi10Pdv14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugaDok10Osnovica14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica14,
                      [
                      form.controls.eF10Osnovica14,
                      form.controls.fiskalni10Osnovica14,
                      form.controls.drugaDok10Osnovica14,
                      form.controls.drugi10Osnovica14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugaDok10Pdv14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv14,
                      [
                      form.controls.eF10Pdv14,
                      form.controls.fiskalni10Pdv14,
                      form.controls.drugaDok10Pdv14,
                      form.controls.drugi10Pdv14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno10Osnovica14"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno10Pdv14"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
            </div>
          </p-accordionTab>
          <!-- 1.5-->
          <p-accordionTab [transitionOptions]="'0ms'" class="secondary-header"
                          header="1.5 Naknada ili deo naknade koji je plaćen pre izvršenog prometa (avans)">
            <div class="grid-rows-7 grid grid-flow-col ">
              <div></div>
              <div class="p-d-flex"><h4 class="font-semibold margin-auto">Izvori:</h4></div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.5.1 Elektronske fakture</p>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.5.2 Fiskalni računi</p>
              </div>
              <div class="p-d-flex "><p class="margin-auto">1.5.3 Drugi računi</p></div>
              <div class="p-d-flex "><p class="margin-auto">1.5.4 Ukupno</p></div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.5.5 PDV koji se može odbiti kao prethodni porez</p>
              </div>
              <!-- Druga kolona-->
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 20%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">PDV</h4>
              </div>
              <!-- 20%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-5 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF20Osnovica15"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica15,
                      [
                      form.controls.eF20Osnovica15,
                      form.controls.fiskalni20Osnovica15,
                      form.controls.drugi20Osnovica15,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF20Pdv15"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv15,
                      [
                      form.controls.eF20Pdv15,
                      form.controls.fiskalni20Pdv15,
                      form.controls.drugi20Pdv15,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni20Osnovica15"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica15,
                      [
                      form.controls.eF20Osnovica15,
                      form.controls.fiskalni20Osnovica15,
                      form.controls.drugi20Osnovica15,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni20Pdv15"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv15,
                      [
                      form.controls.eF20Pdv15,
                      form.controls.fiskalni20Pdv15,
                      form.controls.drugi20Pdv15,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi20Osnovica15"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica15,
                      [
                      form.controls.eF20Osnovica15,
                      form.controls.fiskalni20Osnovica15,
                      form.controls.drugi20Osnovica15,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi20Pdv15"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv15,
                      [
                      form.controls.eF20Pdv15,
                      form.controls.fiskalni20Pdv15,
                      form.controls.drugi20Pdv15,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno20Osnovica15"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno20Pdv15"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.odbitak20Pdv15"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 10%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">PDV</h4>
              </div>
              <!-- 10%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-5 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF10Osnovica15"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica15,
                      [
                      form.controls.eF10Osnovica15,
                      form.controls.fiskalni10Osnovica15,
                      form.controls.drugi10Osnovica15,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF10Pdv15"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv15,
                      [
                      form.controls.eF10Pdv15,
                      form.controls.fiskalni10Pdv15,
                      form.controls.drugi10Pdv15,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni10Osnovica15"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica15,
                      [
                      form.controls.eF10Osnovica15,
                      form.controls.fiskalni10Osnovica15,
                      form.controls.drugi10Osnovica15,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni10Pdv15"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv15,
                      [
                      form.controls.eF10Pdv15,
                      form.controls.fiskalni10Pdv15,
                      form.controls.drugi10Pdv15,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi10Osnovica15"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica15,
                      [
                      form.controls.eF10Osnovica15,
                      form.controls.fiskalni10Osnovica15,
                      form.controls.drugi10Osnovica15,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi10Pdv15"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv15,
                      [
                      form.controls.eF10Pdv15,
                      form.controls.fiskalni10Pdv15,
                      form.controls.drugi10Pdv15,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno10Osnovica15"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno10Pdv15"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.odbitak10Pdv15"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
            </div>
          </p-accordionTab>
          <!-- 1.6-->
          <p-accordionTab [transitionOptions]="'0ms'" class="secondary-header"
                          header="1.6 Smanjenje avansa">
            <div class="grid-rows-7 grid grid-flow-col ">
              <div></div>
              <div class="p-d-flex"><h4 class="font-semibold margin-auto">Izvori:</h4></div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.6.1 Elektronske fakture</p>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.6.2 Fiskalni računi</p>
              </div>
              <div class="p-d-flex "><p class="margin-auto">1.6.3 Drugi dokumenti o smanjenju</p></div>
              <div class="p-d-flex "><p class="margin-auto">1.6.4 Druga dokumentacija</p></div>
              <div class="p-d-flex ">
                <p class="margin-auto">1.6.5 Ukupno</p>
              </div>
              <!-- Druga kolona-->
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 20%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">PDV</h4>
              </div>
              <!-- 20%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-5 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF20Osnovica14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica14,
                      [
                      form.controls.eF20Osnovica14,
                      form.controls.fiskalni20Osnovica14,
                      form.controls.drugaDok20Osnovica14,
                      form.controls.drugi20Osnovica14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF20Pdv14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv14,
                      [
                      form.controls.eF20Pdv14,
                      form.controls.fiskalni20Pdv14,
                      form.controls.drugaDok20Pdv14,
                      form.controls.drugi20Pdv14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni20Osnovica14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica14,
                      [
                      form.controls.eF20Osnovica14,
                      form.controls.fiskalni20Osnovica14,
                      form.controls.drugaDok20Osnovica14,
                      form.controls.drugi20Osnovica14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni20Pdv14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv14,
                      [
                      form.controls.eF20Pdv14,
                      form.controls.fiskalni20Pdv14,
                      form.controls.drugaDok20Pdv14,
                      form.controls.drugi20Pdv14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi20Osnovica14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica14,
                      [
                      form.controls.eF20Osnovica14,
                      form.controls.fiskalni20Osnovica14,
                      form.controls.drugaDok20Osnovica14,
                      form.controls.drugi20Osnovica14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi20Pdv14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv14,
                      [
                      form.controls.eF20Pdv14,
                      form.controls.fiskalni20Pdv14,
                      form.controls.drugaDok20Pdv14,
                      form.controls.drugi20Pdv14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugaDok20Osnovica14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Osnovica14,
                      [
                      form.controls.eF20Osnovica14,
                      form.controls.fiskalni20Osnovica14,
                      form.controls.drugaDok20Osnovica14,
                      form.controls.drugi20Osnovica14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugaDok20Pdv14"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno20Pdv14,
                      [
                      form.controls.eF20Pdv14,
                      form.controls.fiskalni20Pdv14,
                      form.controls.drugaDok20Pdv14,
                      form.controls.drugi20Pdv14,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno20Osnovica14"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno20Pdv14"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 10%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">PDV</h4>
              </div>
              <!-- 10%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-5 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF10Osnovica16"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica16,
                      [
                      form.controls.eF10Osnovica16,
                      form.controls.fiskalni10Osnovica16,
                      form.controls.drugaDok10Osnovica16,
                      form.controls.drugi10Osnovica16,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.eF10Pdv16"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv16,
                      [
                      form.controls.eF10Pdv16,
                      form.controls.fiskalni10Pdv16,
                      form.controls.drugaDok10Pdv16,
                      form.controls.drugi10Pdv16,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni10Osnovica16"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica16,
                      [
                      form.controls.eF10Osnovica16,
                      form.controls.fiskalni10Osnovica16,
                      form.controls.drugaDok10Osnovica16,
                      form.controls.drugi10Osnovica16,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.fiskalni10Pdv16"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv16,
                      [
                      form.controls.eF10Pdv16,
                      form.controls.fiskalni10Pdv16,
                      form.controls.drugaDok10Pdv16,
                      form.controls.drugi10Pdv16,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi10Osnovica16"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica16,
                      [
                      form.controls.eF10Osnovica16,
                      form.controls.fiskalni10Osnovica16,
                      form.controls.drugaDok10Osnovica16,
                      form.controls.drugi10Osnovica16,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugi10Pdv16"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv16,
                      [
                      form.controls.eF10Pdv16,
                      form.controls.fiskalni10Pdv16,
                      form.controls.drugaDok10Pdv16,
                      form.controls.drugi10Pdv16,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugaDok10Osnovica16"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Osnovica16,
                      [
                      form.controls.eF10Osnovica16,
                      form.controls.fiskalni10Osnovica16,
                      form.controls.drugaDok10Osnovica16,
                      form.controls.drugi10Osnovica16,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.drugaDok10Pdv16"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form.controls.ukupno10Pdv16,
                      [
                      form.controls.eF10Pdv16,
                      form.controls.fiskalni10Pdv16,
                      form.controls.drugaDok10Pdv16,
                      form.controls.drugi10Pdv16,
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno10Osnovica16"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form.controls.ukupno10Pdv16"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
      </p-accordionTab>
      <!-- Deo 2-->
      <p-accordionTab [transitionOptions]="'0ms'" class="main-header"
                      header="Deo 2. Nabavka dobara i usluga u Republici Srbiji – promet za koji je poreski dužnik primalac dobara, odnosno usluga">
        <p-accordion [multiple]="true">
          <!-- 2.1-->
          <p-accordionTab [transitionOptions]="'0ms'" class="secondary-header"
                          header="2.1 Prenos prava raspolaganja na građevinskim objektima za koji je poreski dužnik obveznik PDV – primalac dobara">
            <div class="grid-rows-6 grid grid-flow-col ">
              <div></div>
              <div class="p-d-flex"><h4 class="font-semibold margin-auto">Izvori - Pojedinačna evidencija PDV:</h4>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">2.1.1 Interni račun – strano lice</p>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">2.1.2 Interni račun – obveznik PDV</p>
              </div>
              <div class="p-d-flex "><p class="margin-auto">2.1.3 Ukupno</p></div>
              <div class="p-d-flex "><p class="margin-auto">2.1.4 PDV koji se može odbiti kao prethodni porez</p></div>
              <!-- Druga kolona-->
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 20%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 20%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-4 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.irS20Osnovica21"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Osnovica21,
                      [
                      form!.controls.irS20Osnovica21,
                      form!.controls.iR20Osnovica21
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.irS20Pdv21"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Pdv21,
                      [
                      form!.controls.irS20Pdv21,
                      form!.controls.iR20Pdv21
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR20Osnovica21"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Osnovica21,
                      [
                      form!.controls.irS20Osnovica21,
                      form!.controls.iR20Osnovica21
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR20Pdv21"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Pdv21,
                      [
                      form!.controls.irS20Pdv21,
                      form!.controls.iR20Pdv21
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno20Osnovica21"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno20Pdv21"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.odbitak20Pdv21"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 10%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 10%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-4 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.irS10Osnovica21"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Osnovica21,
                      [
                      form!.controls.irS10Osnovica21,
                      form!.controls.iR10Osnovica21
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.irS10Pdv21"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Pdv21,
                      [
                      form!.controls.irS10Pdv21,
                      form!.controls.iR10Pdv21
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR10Osnovica21"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Osnovica21,
                      [
                      form!.controls.irS10Osnovica21,
                      form!.controls.iR10Osnovica21
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR10Pdv21"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Pdv21,
                      [
                      form!.controls.irS10Pdv21,
                      form!.controls.iR10Pdv21
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno10Osnovica21"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno10Pdv21"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.odbitak20Pdv21"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
            </div>
          </p-accordionTab>
          <!-- 2.2-->
          <p-accordionTab [transitionOptions]="'0ms'" class="secondary-header"
                          header="2.2 Promet dobara i usluga (osim prenosa prava raspolaganja na građevinskim objektima) za koji je poreski dužnik obveznik PDV – primalac dobara i usluga">
            <div class="grid-rows-6 grid grid-flow-col ">
              <div></div>
              <div class="p-d-flex"><h4 class="font-semibold margin-auto">Izvori - Pojedinačna evidencija PDV:</h4>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">2.2.1 Interni račun – strano lice</p>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">2.2.2 Interni račun – obveznik PDV</p>
              </div>
              <div class="p-d-flex "><p class="margin-auto">2.2.3 Ukupno</p></div>
              <div class="p-d-flex "><p class="margin-auto">2.2.4 PDV koji se može odbiti kao prethodni porez</p></div>
              <!-- Druga kolona-->
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 20%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 20%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-4 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.irS20Osnovica22"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Osnovica22,
                      [
                      form!.controls.irS20Osnovica22,
                      form!.controls.iR20Osnovica22
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.irS20Pdv22"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Pdv22,
                      [
                      form!.controls.irS20Pdv22,
                      form!.controls.iR20Pdv22
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR20Osnovica22"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Osnovica22,
                      [
                      form!.controls.irS20Osnovica22,
                      form!.controls.iR20Osnovica22
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR20Pdv22"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Pdv22,
                      [
                      form!.controls.irS20Pdv22,
                      form!.controls.iR20Pdv22
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno20Osnovica22"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno20Pdv22"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.odbitak20Pdv22"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 10%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 10%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-4 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.irS10Osnovica22"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Osnovica22,
                      [
                      form!.controls.irS10Osnovica22,
                      form!.controls.iR10Osnovica22
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.irS10Pdv22"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Pdv22,
                      [
                      form!.controls.irS10Pdv22,
                      form!.controls.iR10Pdv22
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR10Osnovica22"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Osnovica22,
                      [
                      form!.controls.irS10Osnovica22,
                      form!.controls.iR10Osnovica22
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR10Pdv22"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Pdv22,
                      [
                      form!.controls.irS10Pdv22,
                      form!.controls.iR10Pdv22
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno10Osnovica22"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno10Pdv22"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.odbitak20Pdv22"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
            </div>
          </p-accordionTab>
          <!-- 2.3-->
          <p-accordionTab [transitionOptions]="'0ms'" class="secondary-header"
                          header="2.3 Povećanje osnovice, odnosno PDV">
            <div class="grid-rows-6 grid grid-flow-col ">
              <div></div>
              <div class="p-d-flex"><h4 class="font-semibold margin-auto">Izvori - Pojedinačna evidencija PDV:</h4>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">2.3.1 Interni račun – strano lice</p>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">2.3.2 Interni račun – obveznik PDV</p>
              </div>
              <div class="p-d-flex "><p class="margin-auto">2.3.3 Ukupno</p></div>
              <div class="p-d-flex "><p class="margin-auto">2.3.4 PDV koji se može odbiti kao prethodni porez</p></div>
              <!-- Druga kolona-->
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 20%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 20%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-4 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.irS20Osnovica23"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Osnovica23,
                      [
                      form!.controls.irS20Osnovica23,
                      form!.controls.iR20Osnovica23
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.irS20Pdv23"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Pdv23,
                      [
                      form!.controls.irS20Pdv23,
                      form!.controls.iR20Pdv23
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR20Osnovica23"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Osnovica23,
                      [
                      form!.controls.irS20Osnovica23,
                      form!.controls.iR20Osnovica23
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR20Pdv23"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Pdv23,
                      [
                      form!.controls.irS20Pdv23,
                      form!.controls.iR20Pdv23
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno20Osnovica23"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno20Pdv23"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.odbitak20Pdv23"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 10%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 10%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-4 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.irS10Osnovica23"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Osnovica23,
                      [
                      form!.controls.irS10Osnovica23,
                      form!.controls.iR10Osnovica23
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.irS10Pdv23"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Pdv23,
                      [
                      form!.controls.irS10Pdv23,
                      form!.controls.iR10Pdv23
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR10Osnovica23"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Osnovica23,
                      [
                      form!.controls.irS10Osnovica23,
                      form!.controls.iR10Osnovica23
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR10Pdv23"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Pdv23,
                      [
                      form!.controls.irS10Pdv23,
                      form!.controls.iR10Pdv23
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno10Osnovica23"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno10Pdv23"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.odbitak20Pdv23"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
            </div>
          </p-accordionTab>
          <!-- 2.4-->
          <p-accordionTab [transitionOptions]="'0ms'" class="secondary-header"
                          header="2.4 Smanjenje osnovice, odnosno PDV">
            <div class="grid-rows-6 grid grid-flow-col ">
              <div></div>
              <div class="p-d-flex"><h4 class="font-semibold margin-auto">Izvori - Pojedinačna evidencija PDV:</h4>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">2.4.1 Interni račun – strano lice</p>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">2.4.2 Interni račun – obveznik PDV</p>
              </div>
              <div class="p-d-flex "><p class="margin-auto">2.4.3 Ukupno</p></div>
              <div class="p-d-flex "><p class="margin-auto">2.4.4 Smanjenje prethodnog poreza</p></div>
              <!-- Druga kolona-->
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 20%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 20%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-4 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.irS20Osnovica24"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Osnovica24,
                      [
                      form!.controls.irS20Osnovica24,
                      form!.controls.iR20Osnovica24
                      ])"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR20Osnovica24"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Osnovica24,
                      [
                      form!.controls.irS20Osnovica24,
                      form!.controls.iR20Osnovica24
                      ])"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno20Osnovica24"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.odbitak20Pdv24"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 10%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 10%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-4 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.irS10Osnovica24"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Osnovica24,
                      [
                      form!.controls.irS10Osnovica24,
                      form!.controls.iR10Osnovica24
                      ])"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR10Osnovica24"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Osnovica24,
                      [
                      form!.controls.irS10Osnovica24,
                      form!.controls.iR10Osnovica24
                      ])"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno10Osnovica24"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.odbitak10Pdv24"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
            </div>
          </p-accordionTab>
          <!-- 2.5-->
          <p-accordionTab [transitionOptions]="'0ms'" class="secondary-header"
                          header="2.5 Naknada ili deo naknade koji je plaćen pre izvršenog prometa (avans)">
            <div class="grid-rows-6 grid grid-flow-col ">
              <div></div>
              <div class="p-d-flex"><h4 class="font-semibold margin-auto">Izvori - Pojedinačna evidencija PDV:</h4>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">2.5.1 Interni račun – strano lice</p>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">2.5.2 Interni račun – obveznik PDV</p>
              </div>
              <div class="p-d-flex "><p class="margin-auto">2.5.3 Ukupno</p></div>
              <div class="p-d-flex "><p class="margin-auto">2.5.4 PDV koji se može odbiti kao prethodni porez</p></div>
              <!-- Druga kolona-->
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 20%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 20%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-4 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.irS20Osnovica25"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Osnovica25,
                      [
                      form!.controls.irS20Osnovica25,
                      form!.controls.iR20Osnovica25
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.irS20Pdv25"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Pdv25,
                      [
                      form!.controls.irS20Pdv25,
                      form!.controls.iR20Pdv25
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR20Osnovica25"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Osnovica25,
                      [
                      form!.controls.irS20Osnovica25,
                      form!.controls.iR20Osnovica25
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR20Pdv25"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Pdv25,
                      [
                      form!.controls.irS20Pdv25,
                      form!.controls.iR20Pdv25
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno20Osnovica25"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno20Pdv25"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.odbitak20Pdv25"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 10%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 10%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-4 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.irS10Osnovica25"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Osnovica25,
                      [
                      form!.controls.irS10Osnovica25,
                      form!.controls.iR10Osnovica25
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.irS10Pdv25"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Pdv25,
                      [
                      form!.controls.irS10Pdv25,
                      form!.controls.iR10Pdv25
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR10Osnovica25"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Osnovica25,
                      [
                      form!.controls.irS10Osnovica25,
                      form!.controls.iR10Osnovica25
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR10Pdv25"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Pdv25,
                      [
                      form!.controls.irS10Pdv25,
                      form!.controls.iR10Pdv25
                      ])"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno10Osnovica25"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno10Pdv25"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.odbitak20Pdv25"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
            </div>
          </p-accordionTab>
          <!-- 2.6-->
          <p-accordionTab [transitionOptions]="'0ms'" class="secondary-header"
                          header="2.6 Smanjenje avansa">
            <div class="grid-rows-6 grid grid-flow-col ">
              <div></div>
              <div class="p-d-flex"><h4 class="font-semibold margin-auto">Izvori - Pojedinačna evidencija PDV:</h4>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">2.6.1 Interni račun – strano lice</p>
              </div>
              <div class="p-d-flex ">
                <p class="margin-auto">2.6.2 Interni račun – obveznik PDV</p>
              </div>
              <div class="p-d-flex "><p class="margin-auto">2.6.3 Ukupno</p></div>
              <div class="p-d-flex "><p class="margin-auto">2.6.4 Smanjenje prethodnog poreza</p></div>
              <!-- Druga kolona-->
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 20%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 20%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-4 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.irS20Osnovica26"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Osnovica26,
                      [
                      form!.controls.irS20Osnovica26,
                      form!.controls.iR20Osnovica26
                      ])"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR20Osnovica26"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno20Osnovica26,
                      [
                      form!.controls.irS20Osnovica26,
                      form!.controls.iR20Osnovica26
                      ])"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno20Osnovica26"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.odbitak20Pdv26"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
              <h4 class="font-semibold col-span-2">
                <div class="margin-auto p-fluid text-center">Promet po stopi od 10%</div>
              </h4>
              <div class="p-d-flex col-span-2">
                <h4 class="inline-block w-05 text-center  margin-auto">
                  Osnovica
                </h4>
                <h4 class="inline-block w-05 text-center  margin-auto">
                  PDV
                </h4>
              </div>
              <!-- 10%-->
              <div class="col-span-2 grid grid-cols-2 grid-flow-row  row-span-4 text-center">
                <div class="p-d-flex p-align-center p-justify-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.irS10Osnovica26"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Osnovica26,
                      [
                      form!.controls.irS10Osnovica26,
                      form!.controls.iR10Osnovica26
                      ])"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.iR10Osnovica26"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                    (onInputLeft)="recalculate(
                      form!.controls.ukupno10Osnovica26,
                      [
                      form!.controls.irS10Osnovica26,
                      form!.controls.iR10Osnovica26
                      ])"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls!.ukupno10Osnovica26"
                    [isDisabled]="true"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
                <div></div>
                <div></div>
                <div class="p-d-flex p-justify-center p-align-center">
                  <kodit-number-input
                    [formCtrl]="form!.controls.odbitak10Pdv26"
                    [isDisabled]="shouldDisableForm"
                    [maxFractionDigits]="2"
                  ></kodit-number-input>
                </div>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
      </p-accordionTab>
      <!-- Deo 3-->
      <p-accordionTab [transitionOptions]="'0ms'" class="main-header"
                      header="Deo 3. PDV za uvoz/dopremanje dobara stavljenih u slobodan promet u skladu sa carinskim propisima">
        <div class="grid grid-cols-5 gap-1-6">
          <div class="col-span-1"></div>
          <h4 class="col-span-2 text-center">PDV po stopi od 20%</h4>
          <h4 class="col-span-2 text-center">PDV po stopi od 10%</h4>
          <div class="grid col-span-1 items-center">
            <p class="text-left margin-auto">
              3.1.
              Plaćeni PDV
            </p>
          </div>
          <div class="col-span-1"></div>
          <div class="col-span-1">
            <div class="p-d-flex p-align-center p-justify-center">
              <kodit-number-input
                [formCtrl]="form!.controls.placeniPdv20"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
                (onInputLeft)="recalculate(
                form!.controls.ukupnoPlaceniPdv,
                [
                form!.controls.placeniPdv20,
                form!.controls.placeniPdv10
                ])"
              ></kodit-number-input>
            </div>
          </div>
          <div class="col-span-1"></div>
          <div class="col-span-1">
            <div class="p-d-flex p-align-center p-justify-center">
              <kodit-number-input
                [formCtrl]="form!.controls.placeniPdv10"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
                (onInputLeft)="recalculate(
                form!.controls.ukupnoPlaceniPdv,
                [
                form!.controls.placeniPdv20,
                form!.controls.placeniPdv10
                ])"
              ></kodit-number-input>
            </div>
          </div>
          <div class="grid col-span-1 items-center">
            <p class="text-left margin-auto">
              3.2.
              Plaćeni PDV koji se može odbiti
            </p>
          </div>
          <div class="col-span-1"></div>
          <div class="col-span-1">
            <div class="p-d-flex p-align-center p-justify-center">
              <kodit-number-input
                [formCtrl]="form!.controls.placeniPdvOdb20"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
                (onInputLeft)="recalculate(
                form!.controls.ukupnoPlaceniPdvOdb,
                [
                form!.controls.placeniPdvOdb20,
                form!.controls.placeniPdvOdb10
                ])"
              ></kodit-number-input>
            </div>
          </div>
          <div class="col-span-1"></div>
          <div class="col-span-1">
            <div class="p-d-flex p-align-center p-justify-center">
              <kodit-number-input
                [formCtrl]="form!.controls.placeniPdvOdb10"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
                (onInputLeft)="recalculate(
                form!.controls.ukupnoPlaceniPdvOdb,
                [
                form!.controls.placeniPdvOdb20,
                form!.controls.placeniPdvOdb10
                ])"
              ></kodit-number-input>
            </div>
          </div>
          <div class="grid col-span-2 items-center">
            <p class="text-left margin-auto">
              3.3.
              Ukupno plaćeni PDV
            </p>
          </div>
          <div class="col-span-2"></div>
          <div class="col-span-1">
            <div class="p-d-flex p-align-center p-justify-center">
              <kodit-number-input
                [formCtrl]="form!.controls.ukupnoPlaceniPdv"
                [isDisabled]="true"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </div>
          </div>
          <div class="grid col-span-2 items-center">
            <p class="text-left">
              3.4.
              Ukupno plaćeni PDV koji se može odbiti
            </p>
          </div>
          <div class="col-span-2"></div>
          <div class="col-span-1">
            <div class="p-d-flex p-align-center p-justify-center">
              <kodit-number-input
                [formCtrl]="form!.controls.ukupnoPlaceniPdvOdb"
                [isDisabled]="true"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </div>
          </div>
        </div>
      </p-accordionTab>
      <!-- Deo 4-->
      <p-accordionTab [transitionOptions]="'0ms'" class="main-header"
                      header="Deo 4. PDV nadoknada plaćena poljoprivredniku, uključujući i povećanje">
        <div class="grid grid-cols-5 gap-1-6">
          <div class="grid col-span-4 items-center">
            <p class="text-left margin-auto">
              4.1.
              Ukupna PDV nadoknada plaćena poljoprivredniku
            </p>
          </div>
          <div class="col-span-1">
            <div class="p-d-flex p-align-center p-justify-center">
              <kodit-number-input
                [formCtrl]="form!.controls.ukupnoPdvPolj"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </div>
          </div>
          <div class="grid col-span-4 items-center">
            <p class="text-left margin-auto">
              4.2.
              Ukupna PDV nadoknada koja se može odbiti kao prethodni porez
            </p>
          </div>
          <div class="col-span-1">
            <div class="p-d-flex p-align-center p-justify-center">
              <kodit-number-input
                [formCtrl]="form!.controls.ukupnoPdvPoljOdb"
                [isDisabled]="shouldDisableForm"
                [maxFractionDigits]="2"
              ></kodit-number-input>
            </div>
          </div>
        </div>
      </p-accordionTab>
      <!-- Deo 5-->
      <p-accordionTab [transitionOptions]="'0ms'" class="main-header"
                      header="Deo 5. Ispravke odbitka prethodnog poreza">
        <p-accordion [multiple]="true">
          <!-- 5.1-->
          <p-accordionTab [transitionOptions]="'0ms'" class="secondary-header"
                          header="5.1 Ispravke odbitka prethodnog poreza – povećanje">
            <div class="grid grid-cols-5 ">
              <div class="grid col-span-4 items-center">
                <h4 class="text-center">Osnov</h4>
              </div>
              <h4 class="col-span-1 text-center">Iznos</h4>
            </div>
            <!-- 5.1.1-->
            <div class="p-mt-3">
              <div class="grid grid-cols-5 ">
                <div class="grid col-span-4 items-center">
                  <p class="text-left margin-auto">
                    5.1.1 Sticanje prava na odbitak prethodnog poreza po osnovu naknadnog prihvatanja elektronske
                    fakture
                  </p>
                </div>
                <div class="col-span-1">
                  <div class="p-d-flex p-align-center p-justify-center">
                    <kodit-number-input
                      [formCtrl]="form!.controls.sticanjePravaOdb"
                      [isDisabled]="shouldDisableForm"
                      [maxFractionDigits]="2"
                    ></kodit-number-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- 5.1.2-->
            <div class="p-mt-3">
              <div class="grid grid-cols-5 ">
                <div class="grid col-span-4 items-center">
                  <p class="text-left margin-auto">
                    5.1.2 Naknadno sticanje prava na odbitak prethodnog poreza za opremu i objekte za vršenje
                    delatnosti, odnosno ulaganja u objekte za vršenje delatnosti
                  </p>
                </div>
                <div class="col-span-1">
                  <div class="p-d-flex p-align-center p-justify-center">
                    <kodit-number-input
                      [formCtrl]="form!.controls.naknadnoSticanjePravaOdb"
                      [isDisabled]="shouldDisableForm"
                      [maxFractionDigits]="2"
                    ></kodit-number-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- 5.1.3-->
            <div class="p-mt-3">
              <div class="grid grid-cols-5 ">
                <div class="grid col-span-4 items-center">
                  <p class="text-left margin-auto">
                    5.1.3 Odluka poreskog organa
                  </p>
                </div>
                <div class="col-span-1">
                  <div class="p-d-flex p-align-center p-justify-center">
                    <kodit-number-input
                      [formCtrl]="form!.controls.odlukaPoreskogOrgana51"
                      [isDisabled]="shouldDisableForm"
                      [maxFractionDigits]="2"
                    ></kodit-number-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- 5.1.4-->
            <div class="p-mt-3">
              <div class="grid grid-cols-5 ">
                <div class="grid col-span-4 items-center">
                  <p class="text-left margin-auto">
                    5.1.4 Povećanje srazmernog poreskog odbitka u poslednjem poreskom periodu kalendarske godine ili poslednjem poreskom periodu
                  </p>
                </div>
                <div class="col-span-1">
                  <div class="p-d-flex p-align-center p-justify-center">
                    <kodit-number-input
                      [formCtrl]="form!.controls.povecanjeSrazmernogOdb"
                      [isDisabled]="shouldDisableForm"
                      [maxFractionDigits]="2"
                    ></kodit-number-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- 5.1.5-->
            <div class="p-mt-3">
              <div class="grid grid-cols-5 ">
                <div class="grid col-span-4 items-center">
                  <p class="text-left margin-auto">
                    5.1.5 Sticanje prava na odbitak prethodnog poreza kod evidentiranja za obavezu plaćanja PDV
                  </p>
                </div>
                <div class="col-span-1">
                  <div class="p-d-flex p-align-center p-justify-center">
                    <kodit-number-input
                      [formCtrl]="form!.controls.sticanjePravaOdbPdv"
                      [isDisabled]="shouldDisableForm"
                      [maxFractionDigits]="2"
                    ></kodit-number-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- 5.1.6-->
            <div class="p-mt-3">
              <div class="grid grid-cols-5 ">
                <div class="grid col-span-4 items-center">
                  <p class="text-left margin-auto">
                    5.1.6 Drugi osnov (npr. storniranje)
                  </p>
                </div>
                <div class="col-span-1">
                  <div class="p-d-flex p-align-center p-justify-center">
                    <kodit-number-input
                      [formCtrl]="form!.controls.drugiOsnov51"
                      [isDisabled]="shouldDisableForm"
                      [maxFractionDigits]="2"
                    ></kodit-number-input>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>
          <!-- 5.2-->
          <p-accordionTab [transitionOptions]="'0ms'" class="secondary-header"
                          header="5.2 Ispravke odbitka prethodnog poreza – smanjenje">
            <div class="grid grid-cols-5 ">
              <div class="grid col-span-4 items-center">
                <h4 class="text-center">Osnov</h4>
              </div>
              <h4 class="col-span-1 text-center">Iznos</h4>
            </div>
            <!-- 5.2.1-->
            <div class="p-mt-3">
              <div class="grid grid-cols-5 ">
                <div class="grid col-span-4 items-center">
                  <p class="text-left margin-auto">
                    5.2.1 Prestanak uslova za ostvarivanje prava na odbitak prethodnog poreza za opremu i objekte za vršenje delatnosti, odnosno ulaganja u objekte za vršenje delatnosti
                  </p>
                </div>
                <div class="col-span-1">
                  <div class="p-d-flex p-align-center p-justify-center">
                    <kodit-number-input
                      [formCtrl]="form!.controls.prestanakUslova"
                      [isDisabled]="shouldDisableForm"
                      [maxFractionDigits]="2"
                    ></kodit-number-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- 5.2.2-->
            <div class="p-mt-3">
              <div class="grid grid-cols-5 ">
                <div class="grid col-span-4 items-center">
                  <p class="text-left margin-auto">
                    5.2.2 Odluka poreskog organa
                  </p>
                </div>
                <div class="col-span-1">
                  <div class="p-d-flex p-align-center p-justify-center">
                    <kodit-number-input
                      [formCtrl]="form!.controls.odlukaPoreskogOrgana52"
                      [isDisabled]="shouldDisableForm"
                      [maxFractionDigits]="2"
                    ></kodit-number-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- 5.2.3-->
            <div class="p-mt-3">
              <div class="grid grid-cols-5 ">
                <div class="grid col-span-4 items-center">
                  <p class="text-left margin-auto">
                    5.2.3 Smanjenje srazmernog poreskog odbitka u poslednjem poreskom periodu kalendarske godine ili poslednjem poreskom periodu
                  </p>
                </div>
                <div class="col-span-1">
                  <div class="p-d-flex p-align-center p-justify-center">
                    <kodit-number-input
                      [formCtrl]="form!.controls.smanjenjeSrazmernogOdb"
                      [isDisabled]="shouldDisableForm"
                      [maxFractionDigits]="2"
                    ></kodit-number-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- 5.2.4-->
            <div class="p-mt-3">
              <div class="grid grid-cols-5 ">
                <div class="grid col-span-4 items-center">
                  <p class="text-left margin-auto">
                    5.2.4 Podnošenje zahteva za brisanje iz evidencije obveznika PDV
                  </p>
                </div>
                <div class="col-span-1">
                  <div class="p-d-flex p-align-center p-justify-center">
                    <kodit-number-input
                      [formCtrl]="form!.controls.podnosenjeZahteva"
                      [isDisabled]="shouldDisableForm"
                      [maxFractionDigits]="2"
                    ></kodit-number-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- 5.2.5-->
            <div class="p-mt-3">
              <div class="grid grid-cols-5 ">
                <div class="grid col-span-4 items-center">
                  <p class="text-left margin-auto">
                    5.2.5 Odluka carinskog organa na osnovu koje dolazi do smanjenja PDV
                  </p>
                </div>
                <div class="col-span-1">
                  <div class="p-d-flex p-align-center p-justify-center">
                    <kodit-number-input
                      [formCtrl]="form!.controls.odlukaCarinskogOrgana"
                      [isDisabled]="shouldDisableForm"
                      [maxFractionDigits]="2"
                    ></kodit-number-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- 5.2.6-->
            <div class="p-mt-3">
              <div class="grid grid-cols-5 ">
                <div class="grid col-span-4 items-center">
                  <p class="text-left margin-auto">
                    5.2.6 Smanjenje PDV nadoknade plaćene poljoprivredniku
                  </p>
                </div>
                <div class="col-span-1">
                  <div class="p-d-flex p-align-center p-justify-center">
                    <kodit-number-input
                      [formCtrl]="form!.controls.smanjenjePdv"
                      [isDisabled]="shouldDisableForm"
                      [maxFractionDigits]="2"
                    ></kodit-number-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- 5.2.7-->
            <div class="p-mt-3">
              <div class="grid grid-cols-5 ">
                <div class="grid col-span-4 items-center">
                  <p class="text-left margin-auto">
                    5.2.7 Drugi osnov (npr. storniranje)
                  </p>
                </div>
                <div class="col-span-1">
                  <div class="p-d-flex p-align-center p-justify-center">
                    <kodit-number-input
                      [formCtrl]="form!.controls.drugiOsnov52"
                      [isDisabled]="shouldDisableForm"
                      [maxFractionDigits]="2"
                    ></kodit-number-input>
                  </div>
                </div>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>
<div class="p-mt-5" card style="position: sticky; bottom: 0; z-index: 9">
  <div class="p-d-flex p-flex-column p-flex-md-row p-jc-between">
    <div class="dodatne-akcije p-d-flex p-flex-column p-jc-around p-ml-3"></div>

    <div class="primarne-akcije p-as-center p-d-flex gap-6">
      <ng-content></ng-content>
      <button
        *ngIf="shouldDisableForm"
        pButton
        pRipple
        type="button"
        label="Zatvori"
        class="p-button-secondary p-button-outlined"
        pTooltip="Zatvori"
        tooltipPosition="bottom"
        (click)="handleRouteBack()"
        [disabled]="false"
      ></button>
      <button
        *ngIf="!shouldDisableForm"
        pButton
        pRipple
        icon="far fa-window-close"
        type="button"
        label="Otkaži"
        class="p-button-secondary p-button-outlined"
        pTooltip="Otkaži PDV evidenciju"
        tooltipPosition="bottom"
        (click)="handleCancel()"
        [disabled]="false"
      ></button>
      <button
        *ngIf="!shouldDisableForm"
        pButton
        icon="fas fa-save"
        type="submit"
        label="Sačuvaj"
        class="p-button-success"
        pTooltip="Sačuvaj PDV evidenciju"
        tooltipPosition="bottom"
        (click)="handleSave()"
        [disabled]="false"
        [loading]="false"
      ></button>
    </div>
  </div>
</div>
