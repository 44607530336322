<form [formGroup]="form" (keydown)="keyDownFunction($event)">
  <div class="p-fluid">
    <kodit-field fieldLabel="E-Mail / Korisničko ime" fieldLabelFor="email">
      <kodit-text-input
        formCtrlId="email"
        formCtrlType="email"
        [formCtrl]="form.controls.email"
      ></kodit-text-input>
    </kodit-field>

    <kodit-field fieldLabel="Korisnička grupa" fieldLabelFor="grupa">
      <kodit-korisnicka-grupa-dropdown
        formCtrlId="grupa"
        [korisnickaGrupaCtrl]="form.controls.roleId"
      ></kodit-korisnicka-grupa-dropdown>
    </kodit-field>
  </div>
</form>
