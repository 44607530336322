import { Injectable } from '@angular/core';
import {
  IDokumentForPdvDto,
  IPdvEvidencijaPojedinacnaDto,
  IStrankaForPdvDto,
  PdvEvidencijaPravnoNaOdbitak,
  PdvEvidencijaStatus,
  PdvEvidencijaTipDokumenta,
  PdvPeriod,
  VrstaRacuna,
} from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { PdvEvidencijaPojedinacnaService } from './pdv-evidencija-pojedinacna.service';
import { VezaniRacunFormService } from '@kodit/core/form-definitions';

@Injectable({
  providedIn: 'root',
})
export class PdvEvidencijaPojedinacnaFormService {
  /* Props */
  private _form: FormGroupTypeSafe<IPdvEvidencijaPojedinacnaDto>;
  private _isInitialized: boolean;

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _service: PdvEvidencijaPojedinacnaService,
    private _vezaniRacunFS: VezaniRacunFormService
  ) {
    this._isInitialized = false;
  }

  getForm(
    model: IPdvEvidencijaPojedinacnaDto,
    shouldDisableControls: boolean
  ): FormGroupTypeSafe<IPdvEvidencijaPojedinacnaDto> {
    if (!this._isInitialized) {
      this._init(model, shouldDisableControls);
      this._isInitialized = true;
    }
    this._service.setPdvForm = this._form;
    return this._form;
  }

  get jeEdit(): boolean {
    if (!this._form) {
      return false;
    }
    return this._form!.value!.id! > 0;
  }

  private _init(
    model: IPdvEvidencijaPojedinacnaDto,
    shouldDisableComponents: boolean
  ) {
    this._form = this._fb.group<IPdvEvidencijaPojedinacnaDto>({
      broj: new FormControl(
        {
          value: model?.broj,
          disabled: shouldDisableComponents,
        },
        {
          validators: [Validators.required, Validators.maxLength(32)],
        }
      ),
      tip: new FormControl(model?.tip ?? PdvEvidencijaTipDokumenta.FAKTURA),
      osnovOdabiraDokumenta: new FormControl(
        model?.osnovOdabiraDokumenta ?? null
      ),
      datumPrometa: new FormControl(model?.datumPrometa ?? new Date()),
      datumEvidentiranja: new FormControl(
        model?.datumEvidentiranja ?? new Date()
      ),
      datumSlanja: new FormControl(model?.datumSlanja ?? new Date()),
      datumValute: new FormControl(model?.datumValute ?? new Date()),
      dokument: this._getDokumentFormGroup(model?.dokument),
      obracunatPdv10: new FormControl(model?.obracunatPdv10 ?? 0),
      obracunatPdv20: new FormControl(model?.obracunatPdv20 ?? 0),
      obracunatPdv: new FormControl(model?.obracunatPdv ?? 0),
      status: new FormControl(model?.status ?? PdvEvidencijaStatus.U_PRIPREMI),
      opisPrometa: new FormControl(model?.opisPrometa, {
        validators: [Validators.maxLength(200)],
      }),
      opisPrometa10: new FormControl(model?.opisPrometa10, {
        validators: [Validators.maxLength(200)],
      }),
      opisPrometa20: new FormControl(model?.opisPrometa20, {
        validators: [Validators.maxLength(200)],
      }),
      id: new FormControl(model?.id),
      osnovica10: new FormControl(model?.osnovica10 ?? 0),
      osnovica20: new FormControl(model?.osnovica20 ?? 0),
      period: new FormControl(model?.period ?? PdvPeriod.JANUAR),
      stranka: this._getStrankaFormGroup(model?.stranka),
      pravnoNaOdbitak: new FormControl(
        model?.pravnoNaOdbitak ?? PdvEvidencijaPravnoNaOdbitak.BEZ_PRAVA
      ),
      ukupanIznos: new FormControl(model?.ukupanIznos ?? 0),
      ukupanIznosNaknade: new FormControl(model?.ukupanIznosNaknade ?? 0),
      povezaniDokumenti: this._vezaniRacunFS.getFormArray(
        model?.povezaniDokumenti
      ),
      sefId: new FormControl(model?.sefId ?? 0),
      godina: new FormControl(model?.godina ?? new Date().getFullYear(), {
        validators: [Validators.required],
      }),
    });
  }

  private _getStrankaFormGroup(model?: IStrankaForPdvDto) {
    return this._fb.group<IStrankaForPdvDto>({
      naziv: new FormControl(model?.naziv),
      id: new FormControl(model?.id),
      pibOrJbkjs: new FormControl({
        value: model?.pibOrJbkjs,
        disabled: true,
      }),
    });
  }

  private _getDokumentFormGroup(model?: IDokumentForPdvDto) {
    return this._fb.group<IDokumentForPdvDto>({
      id: new FormControl(model?.id),
      broj: new FormControl(model?.broj),
      smer: new FormControl(model?.smer ?? VrstaRacuna.IZLAZNI),
      stranka: this._getStrankaFormGroup(model?.stranka),
      tip: new FormControl(model?.tip),
      jeInostranaFaktura: new FormControl(model?.jeInostranaFaktura ?? false),
    });
  }

  resetForm() {
    this._service.resetForm();
    this._isInitialized = false;
    this._init({}, false);
  }
}
