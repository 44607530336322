import { Component, OnDestroy, OnInit } from '@angular/core';
import { PravnaLicaClient } from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { FileUploadService } from '@kodit/core/shared-ui';
import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-kupci-i-dobavljaci-import',
  templateUrl: './kupci-i-dobavljaci-import.component.html',
  styleUrls: ['./kupci-i-dobavljaci-import.component.scss'],
})
export class KupciIDobavljaciImportComponent implements OnInit, OnDestroy {
  /** Subs */
  private _uploadSub: Subscription = new Subscription();
  private _errorSub: Subscription = new Subscription();

  /** Props */
  uploadCompleted: boolean;

  readonly MAX_FILE_SIZE: number = 10000000;

  constructor(
    private _client: PravnaLicaClient,
    private _alertService: AlertService,
    private _loaderService: LoaderService,
    private _uploadService: FileUploadService
  ) {}

  ngOnInit(): void {
    this._uploadService.setMaxAttachmentSize = this.MAX_FILE_SIZE;
  }

  onUpload(file: File) {
    this._loaderService.setLoadingText =
      'Molimo sačekajte.';
    this._loaderService.setShowLoader = true;
    this.uploadCompleted = false;
    this._uploadSub = this._client
      .import({
        data: file,
        fileName: '',
      })
      .subscribe(
        (res) => {
          this.uploadCompleted = true;
          this._alertService.addSuccessMsg(res.messages[0]);
        },
        (error) => {
          this.uploadCompleted = true;
        },
        () => {
          this._loaderService.reset();
        }
      );
  }

  downloadTemplate() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute(
      'href',
      '/assets/podesavanje/import_files/kupci_i_dobavljaci_sablon.csv'
    );
    link.setAttribute('download', 'kupci_i_dobavljaci_šablon.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  ngOnDestroy(): void {
    this._uploadSub.unsubscribe();
    this._errorSub.unsubscribe();
  }
}
