import { Injectable } from '@angular/core';
import { IPdvEvidencijaZbirnaDto } from '@kodit/core/data-api';
import { BehaviorSubject } from 'rxjs';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';

@Injectable({
  providedIn: 'root',
})
export class PdvEvidencijaZbirnaService {
  pdvForm$ = new BehaviorSubject<PdvForm>(null);

  set setPdvForm(value: PdvForm) {
    this.pdvForm$.next(value);
  }

  get getPdvFormObject(): PdvForm {
    return this.pdvForm$.getValue();
  }

  resetForm() {
    this.setPdvForm = null;
  }
}

export declare type PdvForm = FormGroupTypeSafe<IPdvEvidencijaZbirnaDto> | null;
