import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  INotifikacijaKorisnikaDto,
  NotifikacijeClient,
} from '@kodit/core/data-api';
import { NotificationService } from '@kodit/core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit, OnDestroy {
  private _subs = new Subscription();

  /** Props */
  defaultIcon = 'fa-regular fa-circle-exclamation';

  /** I/O */
  @Input() notifikacija: INotifikacijaKorisnikaDto;

  constructor(
    private _client: NotifikacijeClient,
    private _service: NotificationService,
    private _router: Router
  ) {}

  ngOnInit(): void {}

  handleClick() {
    this._subs.add(
      this._client
        .readNotifikacijaKorisnika(this.notifikacija.id)
        .subscribe(() => {
          this._service.removeTopbarNotification(this.notifikacija.id);
          this._nagivateToAction();
        })
    );
  }

  private _nagivateToAction() {
    const urlParts = this.notifikacija.actionUrl
      .split('/')
      .filter((x) => x != '');
    const queryParams: QueryParam[] = this._getQueryParams(urlParts);

    this._router.navigate(urlParts, {
      queryParams: queryParams,
    });
  }

  /**
   * Vraca nam query parametre iz URL
   * @param urlParts URL iz kog zelimo da izvucemo query stringove
   * @returns Niz query parametara ako postoje, u suprotnom prazan niz
   */
  private _getQueryParams(urlParts: string[]): QueryParam[] {
    const queryParamIdx = urlParts.findIndex((x: string) => x.includes('?'));
    if (queryParamIdx === -1) {
      return [];
    }

    // ako imamo query param(s)
    const queryParamParts = urlParts[queryParamIdx].split('?');

    // uzimamo 'cist' url za index na kom je bio query param(s)
    urlParts[queryParamIdx] = queryParamParts[0];

    return this._extractQueryParamsFromString(queryParamParts[1]);
  }

  /**
   * Gleda ceo string deo koji pripada query parametrima i ekstraktuje ih u niz od po 1
   * @param queryParamPart Ceo string koji proipada query parametrima
   * @returns Niz pojedinacnih query parametara
   */
  private _extractQueryParamsFromString(queryParamPart: string): QueryParam[] {
    const queryParams: QueryParam[] = [];
    const queryParamsAll = queryParamPart.split('&');
    for (let i = 0; i < queryParamsAll.length; ++i) {
      const qpCurrent = queryParamsAll[i].split('=');
      queryParams[qpCurrent[0]] = qpCurrent[1];
    }
    return queryParams;
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}

interface QueryParam {
  name: string;
  value: string;
}
