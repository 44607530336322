<div class="p-fluid">
  <span class="p-input-icon-left input-box">
    <i class="fas fa-search"></i>
    <p-autoComplete
      [(ngModel)]="oznakaModel"
      [suggestions]="oznakeSuggestions"
      placeholder="Naziv oznake..."
      (completeMethod)="searchOznaka($event)"
      (onSelect)="onSelectChange($event)"
      [appendTo]="parentCtrl"
      (onClear)="onClear()"
      [disabled]="disabled"
      inputStyleClass="input-el"
      [field]="oznakaModel?.punNaziv === null ? 'naziv' : 'punNaziv'"
    >
      <ng-template let-oznaka pTemplate="item">
        {{ oznaka?.punNaziv ?? oznaka?.naziv }}
      </ng-template>
    </p-autoComplete>
  </span>
  <kodit-error-info-required
    [formCtrl]="oznakaFormGroup.controls.naziv"
  ></kodit-error-info-required>
</div>
