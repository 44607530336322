import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  FilterChipDto,
  GetLageriTableQuery,
  GetLagerPdfQuery,
  IGetLageriTableQuery,
  LageriClient,
  LagerTableDto,
  PaginatedResultOfLagerTableDto,
  RacunFilterDto,
  TipRacuna,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { ConfigService } from '@kodit/core/services';
import { toCamelCase } from '@kodit/core/shared';
import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';
import {
  ActionClass,
  ActionType,
  IPaginatedResultTableDto,
  OnTableInit,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { LazyLoadEvent } from 'primeng/api';
import * as printJS from 'print-js';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LagerFilterComponent } from '../lager-filter/lager-filter.component';
import { LagerFilterService } from '../lager-filter/lager-filter.service';
import { Store } from '@ngrx/store';
import { selectFilterByKey } from '../../racun/state/filters/filter.selectors';
import { setFilter } from '../../racun/state/filters/filter.actions';

@Component({
  selector: 'kodit-lager-table',
  templateUrl: './lager-table.component.html',
  styleUrls: ['./lager-table.component.scss'],
})
export class LagerTableComponent implements OnInit, OnDestroy, OnTableInit {
  /** Subs */
  private _tableRefreshSub: Subscription = new Subscription();
  private _deleteSub: Subscription = new Subscription();
  private _dialogCloseSub: Subscription = new Subscription();
  private _subs: Subscription = new Subscription();

  /** Props */
  paginatedData: IPaginatedResultTableDto;
  lagerTable: LagerTableDto[];
  chipItems: FilterChipDto[] = [];
  filters: GetLageriTableQuery;

  isFirstLoad = true;
  pdfFileUrl: string;
  pdfFileName: string;
  kpoInnerHtml: SafeHtml;
  isPdfAvailable: boolean = false;

  //save filter and paginate variables
  tableSessionStorage: IGetLageriTableQuery;
  routerChangeDetected: boolean;

  /** Confings */
  tableConfig: TableConfig;
  skipFirst: number = 0;
  numberOfRowsDisplayed: number = 0;

  @Input() showCardBlock: boolean = true;
  @Input() magacinId: number = null;

  constructor(
    private _client: LageriClient,
    private _router: Router,
    private _filterService: LagerFilterService,
    private _loaderService: LoaderService,
    private _configService: ConfigService,
    private _storage: Store
  ) {}

  ngOnInit(): void {
    //check if page is reloaded
    if (this._router.onSameUrlNavigation == 'reload') {
      this.routerChangeDetected = true;
    }

    this._subs.add(
      this._storage
        .select(
          selectFilterByKey(
            'LAGERI'
          )
        )
        .subscribe((data) => {
          if (data) {
           this.filters = JSON.parse(data);
            this.skipFirst = this.filters.pageNumber ?? 0;
            this.numberOfRowsDisplayed = this.filters.pageSize ?? 0;
          } 
          else {
           this.filters = new GetLageriTableQuery();
          }
          this._load();
        })
    );

    this.setTableConfig();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Lager',
      isLazy: true,
      lazyCallbackFunction: (event: LazyLoadEvent) => {
        if(!this.isFirstLoad){
          this.skipFirst = event.first;
          this.numberOfRowsDisplayed = event.rows;
        }
          this._updateFilterDataAndReload();
        if(this.isFirstLoad) {
          this.isFirstLoad = false;
        }
      },
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      columns: [
        {
          field: 'sifraINaziv',
          header: 'Šifra | Naziv',
          type: 'link',
          linkCallbackFunction: (index: number) => this._onInfo(index),
          columns: [
            // {
            //   field: 'jedinicaMereNaziv',
            //   header: 'J.M',
            //   type: 'badge',
            // },
            { field: 'nazivMagacina', header: 'Magacin', type: 'text' },
          ],
        },
        { field: 'stanje', header: 'Zalihe', type: 'number' },
        {
          field: 'rezervisano',
          header: 'Rezervisano',
          type: 'number',
        },
        {
          field: 'slobodno',
          header: 'Raspoloživo',
          type: 'number',
        },
        {
          field: 'cenaDecimal',
          header: 'Pros. ponderisana cena',
          type: 'currency',
        },
        {
          field: 'vrednostDecimal',
          header: 'Vrednost',
          type: 'currency',
        },
      ],
      headerActions: [
        {
          type: ActionType.CUSTOM,
          actionClass: ActionClass.OUTLINED,
          icon: 'fa-regular fa-print',
          hasAccessTooltip: 'Štampanje lagera',
          callback: () => {
            this._onPrint();
          },
        },
      ],
      advancedFilter: {
        component: LagerFilterComponent,
        data: new GetLageriTableQuery(),
      },
    });
  }

  private _onPrint() {
    this._loaderService.setLoadingText = 'Generisanje pdf dokumenta u toku';
    this._loaderService.setShowLoader = true;
    this._subs.add(
      this._client
        .getLagerPdf({
          naziv: this.tableConfig.advancedFilter.data.naziv ?? '',
          sifra: this.tableConfig.advancedFilter.data.sifra ?? '',
          magacinId: this.tableConfig.advancedFilter.data.magacinId,
          predmetStavkeId: this.tableConfig.advancedFilter.data.predmetStavkeId,
          stranke: this.tableConfig.advancedFilter.data.stranke ?? [],
          datumPrometa: this.tableConfig.advancedFilter.data.datumPrometa,
          pageNumber: this.tableConfig.advancedFilter.data.pageNumber,
          pageSize: this.tableConfig.advancedFilter.data.pageSize,
          prikaziNuleIMinus:
            this.tableConfig.advancedFilter.data.prikaziNuleIMinus ?? true,
        } as GetLagerPdfQuery)
        .pipe(
          finalize(() => {
            this._loaderService.reset();
          })
        )
        .subscribe((result) => {
          let fileURL = URL.createObjectURL(result.data);
          printJS(fileURL);
        })
    );
  }

  private _onInfo(index: number): void {
    this._router.navigate([
      '/kompanija',
      'roba',
      this.lagerTable[index - this.skipFirst].predmetStavkeId,
    ]);
  }

  private _handleChipRemove(removedChip: FilterChipDto) {
    this.tableConfig.advancedFilter.data[toCamelCase(removedChip.key)] =
      removedChip.defaultValue;
    this._updateStorage();
  }

  private _updateFilterDataAndReload() {
   if (
     (this.skipFirst !== this.filters.pageNumber ||
       this.numberOfRowsDisplayed !== this.filters.pageSize) &&
     !this.isFirstLoad
   ) {
     this.filters.pageNumber = this.skipFirst;
     this.filters.pageSize = this.numberOfRowsDisplayed;
     this._updateStorage();
   }
  }
 
  private _updateStorage() {
    this._storage.dispatch(
      setFilter({
        key: 'LAGERI',
        filter: JSON.stringify(this.filters),
      })
    );
  }

  private _load() {
    this._configService.setIsBusy = true;

    if (this.magacinId != null) {
      this.filters.magacinId = this.magacinId;
    }

    this._subs.add(
      this._client
        .getForTable(this.filters as GetLageriTableQuery)
        .subscribe((res: PaginatedResultOfLagerTableDto) => {
          this.paginatedData = res;
          this.chipItems = res.activeFilters;
          this.lagerTable = res.data;
          this._configService.setIsBusy = false;
          this.tableConfig.advancedFilter.data = this.filters;
        })
    );
  }

  ngOnDestroy(): void {
    this._tableRefreshSub.unsubscribe();
    this._deleteSub.unsubscribe();
    this._dialogCloseSub.unsubscribe();
    this._subs.unsubscribe();
  }
}
