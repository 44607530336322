<div class="p-fluid">
  <p-autoComplete
    [inputId]="ctrlId"
    [(ngModel)]="strankeModel"
    [suggestions]="strankeSuggestions"
    field="naziv"
    placeholder="Naziv stranke, MB ili PIB..."
    (completeMethod)="searchStranka($event)"
    (onSelect)="onStrankaSelect($event)"
    (onUnselect)="onStrankaUnSelect($event)"
    appendTo="body"
    [multiple]="true"
    [forceSelection]="false"
    inputStyleClass="input-field"
  >
    <ng-template let-stranka pTemplate="item">
      <div>
        {{ stranka.naziv }}
      </div>
    </ng-template>
    <ng-template let-stranka pTemplate="selectedItem">
      <div [pTooltip]="stranka.naziv">
        {{ stranka.naziv }}
      </div>
    </ng-template>
  </p-autoComplete>
</div>
