import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {SistemClient} from "@kodit/core/data-api";
import {AlertService} from "@kodit/core/services";
import { Clipboard } from '@angular/cdk/clipboard';
import {environment} from "../../../../../../../../apps/core/src/environments/environment";

@Component({
  selector: 'kodit-api-menadzment-page',
  templateUrl: './api-menadzment-page.component.html',
  styleUrls: ['./api-menadzment-page.component.scss']
})
export class ApiMenadzmentPageComponent implements OnInit {
  private _subs : Subscription = new Subscription();

  /** Props */
  links = [];
  apiStatus = false;
  apiKey : string = null;
  environmentUrl = environment.apiBaseUrl;

  constructor(
    private _sistemClient : SistemClient,
    private _alertService : AlertService,
    private _clipboard: Clipboard) { }

  ngOnInit(): void {
    this._load();
  }

  generisiApiKey() {
    this._subs.add(
      this._sistemClient.generisiApiKey().subscribe((res)=>{
        this._load();
        this._alertService.addSuccessMsg(res.messages[0]);
      })
    );
  }

  copyApiKey(){
    this._clipboard.copy(this.apiKey);
    this._alertService.addSuccessMsg('API ključ je kopiran!');
  }

  private _load(){
    this._subs.add(
      this._sistemClient.getApiManagement().subscribe((res)=>{
        console.log(res);
        this.links = res.data.links;
        this.apiStatus = res.data.apiStatus ?? false;
        this.apiKey = res.data.apiKey;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
