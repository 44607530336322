import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import {RacuniClient, SistemClient} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { GodinaRacunaDropdownService } from '@kodit/core/services';

@Component({
  selector: 'kodit-godina-racuna-dropdown',
  templateUrl: './godina-racuna-dropdown.component.html',
  styleUrls: ['./godina-racuna-dropdown.component.scss'],
})
export class GodinaRacunaDropdownComponent implements OnInit, OnDestroy {
  private _subs: Subscription;

  /** Props */
  items: SelectItem[];
  godinaCtrl: AbstractControl;

  @Output() onItemChange = new EventEmitter<any>();

  constructor(
    private _client: SistemClient,
    private _godinaService: GodinaRacunaDropdownService
  ) {
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this.godinaCtrl = this._godinaService.getForm();
    this._load();
  }

  private _load() {
    this._subs = this._client.getGodinaRacunaDropdown().subscribe((res) => {
      this.items = res.data.availableYears.map((x) => ({
        label: x.toString(),
        value: x,
      }));
      this.items?.splice(0, 0, { label: 'Sve godine', value: 0 });
    });
  }

  handleOnItemChange(event: any) {
    if (this.onItemChange) {
      this.onItemChange.emit(event);
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
