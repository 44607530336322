import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import {
  GetNewUlaznaFakturaForIsplataQuery,
  UlaznaFakturaDto,
  UlazneFaktureClient,
} from '@kodit/core/data-api';
import { BaseService } from '@kodit/core/services';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UlaznaFakturaZaIsplatuResolver
  extends BaseService
  implements Resolve<UlaznaFakturaDto> {
  constructor(private _client: UlazneFaktureClient, private _router: Router) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<UlaznaFakturaDto>
    | Promise<UlaznaFakturaDto>
    | UlaznaFakturaDto
    | null {
    return this._client
      .getNewForIsplata(
        new GetNewUlaznaFakturaForIsplataQuery({
          strankaId: this._router.getCurrentNavigation().extras.state.strankaId,
          datumPrometa: this._router.getCurrentNavigation().extras.state
            .datumPrometa,
          datumIzdavanja: this._router.getCurrentNavigation().extras.state
            .datumIzdavanja,
          isplataId: this._router.getCurrentNavigation().extras.state
            .transakcijaIzvodaId,
        })
      )
      .pipe(
        map((res) => res.data),
        catchError(this.handleError),
        finalize(() => {
          //this._configService.setIsBusy = false;
        })
      );
  }
}
