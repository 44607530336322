import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ButtonService {
  constructor() {}

  /**
   *
   */
  shouldDisableBtn$ = new BehaviorSubject<boolean>(false);

  get getShouldDisableBtn() {
    return this.shouldDisableBtn$.asObservable();
  }
  set setShouldDisableBtn(data: boolean) {
    this.shouldDisableBtn$.next(data);
  }

  enableButton() {
    this.setShouldDisableBtn = false;
  }

  disableButton() {
    this.setShouldDisableBtn = true;
  }
}
