<div class="card-container">
  <kodit-card-container>
    <div card-title>Stavke</div>
    <div card-right-side>Veži za postojeću stavku</div>
    <ng-container card-body>
      <div
        *ngFor="let stavka of neobradjeneStavke; let idx = index"
        class="stavka-wrapper"
      >
        <div class="even-columns p-flex-md-row">
          <div class="col">
            <div class="p-d-flex p-jc-between stavka-naziv">
              <div>
                {{ stavka.naziv }}
              </div>
              <div>{{ stavka.kolicina }} {{ stavka.jedinicaMere }}</div>
            </div>
            <div class="stavka-details-wrapper">
              <kodit-card-body-text
                label="Cena po JM"
                [text]="stavka.cenaPoJMStr"
              ></kodit-card-body-text>

              <kodit-card-body-text
                label="Popust"
                [text]="stavka.rabatIznosStr"
              ></kodit-card-body-text>

              <kodit-card-body-text
                label="Iznos bez PDV"
                [text]="stavka.poreskaOsnovicaStr"
              ></kodit-card-body-text>

              <kodit-card-body-text
                label="Stopa PDV"
                [text]="stavka.pdvStopa + '%'"
              ></kodit-card-body-text>

              <kodit-card-body-text
                *ngIf="stavka.pdvStopa == 0"
                label="PDV kategorija"
                [text]="stavka.pdvKategorija"
              ></kodit-card-body-text>

              <kodit-card-body-text
                *ngIf="stavka.pdvStopa == 0"
                label="Razlog PDV izuzeća"
                [text]="stavka.razlogPdvIzuzecaStr"
              ></kodit-card-body-text>

              <kodit-card-body-text
                label="PDV"
                [text]="stavka.pdvIznosStr"
              ></kodit-card-body-text>

              <kodit-card-body-text
                label="Iznos"
                [text]="stavka.iznosStr"
              ></kodit-card-body-text>
            </div>
          </div>
          <div class="col">
            <div class="p-d-flex p-flex-column p-fluid gap-12">
              <div *ngIf="
                  stavkeRacuna.at(idx).get('predmetStavkeDto').get('id').value >
                  0
                "
               class="p-d-flex p-jc-between stavka-naziv">
                <div>{{ stavkeRacuna.at(idx).value.naziv }}</div>

                <div class="p-d-flex p-flex-md-row p-ai-center">
                   <!-- Kolicina -->
                        <p-inplace #kolicinaInplace styleClass="title-inplace">
                          <ng-template pTemplate="display">
                            {{ stavkeRacuna.at(idx).value.kolicina }}
                          </ng-template>
                          <ng-template pTemplate="content">
                            <kodit-number-input
                              [formCtrl]="stavkeRacuna.at(idx).get('kolicina')"
                              (onInputLeft)="hideContentKolicina()"
                            >
                            </kodit-number-input>
                          </ng-template>
                        </p-inplace>

                        <!-- Jedinica mere -->
                        <p-inplace #jmInplace styleClass="title-inplace">
                          <ng-template pTemplate="display">
                            {{ stavkeRacuna.at(idx).value.jedinicaMere.naziv}}
                          </ng-template>
                          <ng-template pTemplate="content">
                            <kodit-jedinica-mere-dropdown
                              (onChange)="hideContentJM()"
                              [jedinicaMereGroup]="
                                stavkeRacuna.at(idx).get('jedinicaMere')
                              "
                              formCtrlId="jedinicaMere"
                              jedinicaMereDefaultValue="{{
                                stavka.jedinicaMere
                              }}"
                            >
                            </kodit-jedinica-mere-dropdown>
                          </ng-template>
                        </p-inplace>
                </div>
              </div>
              <!-- pretraga stavki -->
              <kodit-predmet-stavke-racuna-input
                *ngIf="
                  !stavkeRacuna.at(idx).get('predmetStavkeDto').get('id')
                    .value ||
                  stavkeRacuna.at(idx).get('predmetStavkeDto').get('id')
                    .value === 0
                "
                [predmetStavkeFormGroup]="
                  stavkeRacuna.at(idx).get('predmetStavkeDto')
                "
                (onNazivChange)="updateRezultatePretrage($event, idx)"
              ></kodit-predmet-stavke-racuna-input>

              <!-- odabrana stavka -->
              <ng-container
                *ngIf="
                  stavkeRacuna.at(idx).get('predmetStavkeDto').get('id').value >
                  0
                "
              >

              <!-- Nabavna cena -->
               <div  *ngIf="
                    stavkeRacuna.at(idx).get('predmetStavkeDto.tipPredmeta')
                      .value === tipPredmetaRoba
                  "
               class="p-d-flex p-flex-md-row p-jc-between p-ai-center">
                   <div>Nabavna cena:</div>
                   <div>
                      <p-inplace #cenaInplace>
                          <ng-template pTemplate="display">
                             {{ stavkeRacuna.at(idx).value.nabavnaCenaBezPdv | currencyNumber}}
                          </ng-template>
                          <ng-template pTemplate="content">
                            <kodit-number-input
                              [formCtrl]="stavkeRacuna.at(idx).get('nabavnaCenaBezPdv')"
                              (onInputLeft)="hideCenaContent(idx)"
                            >
                            </kodit-number-input>
                          </ng-template>
                        </p-inplace>
                   </div>
               </div>
              <!-- Magacin -->
                <kodit-field
                  *ngIf="
                    stavkeRacuna.at(idx).get('predmetStavkeDto.tipPredmeta')
                      .value === tipPredmetaRoba
                  "
                  fieldLabel="Magacin"
                  fieldLabelFor="magacin"
                >
                  <kodit-magacin-dropdown
                    [magacinCtrl]="stavkeRacuna.at(idx).get('magacinId')"
                  ></kodit-magacin-dropdown>
                </kodit-field>

                <div class="p-as-end">
                  <button
                    pButton
                    pRipple
                    type="button"
                    icon="fa-regular fa-xmark"
                    class="p-button-text p-button-danger p-pr-0"
                    pTooltip="Uklonite izabrani predmet stavke"
                    label="Poništi stavku"
                    (click)="cancelStavka(idx)"
                  ></button>
                </div>
                <div
                  *ngIf="
                    stavkeRacuna.at(idx).get('predmetStavkeDto.tipPredmeta')
                      .value === tipPredmetaRoba
                  "
                >
                  <hr />
                  <kodit-field
                    fieldLabel="Prodajna cena:"
                    fieldLabelFor="prodajna_cena"
                  >
                    <kodit-number-input
                      [formCtrl]="
                        stavkeRacuna
                          .at(idx)
                          .get('predmetStavkeDto.prodajnaCenaBezPdv')
                      "
                      (onInputLeft)="handleProdajnaCenaChanged(idx)"
                      maxFractionDigits="2"
                    ></kodit-number-input>
                  </kodit-field>

                  <kodit-field fieldLabel="Marža % :" fieldLabelFor="marza">
                    <kodit-number-input
                      [formCtrl]="
                        stavkeRacuna.at(idx).get('predmetStavkeDto.marza')
                      "
                      (onInputLeft)="handleMarzaChanged(idx)"
                      maxFractionDigits="4"
                    ></kodit-number-input>
                  </kodit-field>

                  <kodit-field
                    fieldLabel="Razlika u ceni:"
                    fieldLabelFor="razlika_u_ceni"
                  >
                    <kodit-number-input
                      [formCtrl]="
                        stavkeRacuna
                          .at(idx)
                          .get('predmetStavkeDto.razlikaUCeni')
                      "
                      (onInputLeft)="handleRazlikaUCeniChanged(idx)"
                      maxFractionDigits="2"
                    ></kodit-number-input>
                  </kodit-field>
                  <kodit-field
                    fieldLabel="Prodajna cena sa PDV:"
                    fieldLabelFor="prodajna_cena_sa_pdv"
                  >
                    <kodit-number-input
                      [formCtrl]="
                        stavkeRacuna
                          .at(idx)
                          .get('predmetStavkeDto.prodajnaCenaSaPdv')
                      "
                      (onInputLeft)="handleProdajnaCenaSaPdvChanged(idx)"
                      maxFractionDigits="2"
                    ></kodit-number-input>
                  </kodit-field>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </kodit-card-container>
</div>

<div
  class="layout-sidebar-right rezultati"
  [ngClass]="{ 'layout-sidebar-right-active': shouldDisplayRezultate }"
>
  <kodit-rezultat-pretrage-predmeta-stavke
    *ngIf="shouldDisplayRezultate"
    [searchValue]="rezultatiSearchValue"
    [iznosDefaultValue]="rezultatiIznosValue"
    [jmDefaultValue]="rezultatiJmValue"
    [tipRacuna]="tipRacuna"
    [excludeTipovePredmeta]="excludeTipovePredmeta"
    (onPredmetStavkeAdd)="predmetStavkeAdded($event)"
  ></kodit-rezultat-pretrage-predmeta-stavke>
</div>
