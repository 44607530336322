import { Component, OnInit } from '@angular/core';
import { ConfigService, LocationService } from '@kodit/core/services';
import { Subscription } from 'rxjs';
import {
  FilterChipDto,
  GetPdvEvidencijaZbirna39TableQuery,
  GetPPPDVTableQuery,
  IPdvEvidencijaZbirna39TableDto,
  IPPPDVEvidencijaTableDto,
  PaginatedResultOfPdvEvidencijaZbirna39TableDto,
  PaginatedResultOfPPPDVEvidencijaTableDto,
  PdvEvidencijaStatus, PdvEvidencijeClient,
  SefEvidentirajPdvEvidencijaZbirna39Command,
  SefPonistiPdvEvidencijaZbirna39Command,
  TipPristupa
} from '@kodit/core/data-api';
import {
  ActionMode,
  ActionType,
  DynamicDialogConfig,
  SelectMode,
  TableConfig,
} from '@kodit/core/shared-ui';
import { DialogSize } from '../../../../../../shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { PdvEvidencijaZbirnaTable39FilterComponent } from '../../zbirna/pdv-evidencija-zbirna-table39/pdv-evidencija-zbirna-table39-filter/pdv-evidencija-zbirna-table39-filter.component';
import { PppdvTableFilterComponent } from './pppdv-table-filter/pppdv-table-filter.component';
import { finalize } from 'rxjs/operators';
import { clearFilter, setFilter } from '../../../../../../shared-subforms/src/lib/racun/state/filters/filter.actions';
import { nameofFactory, toCamelCase } from '@kodit/core/shared';
import { Store } from '@ngrx/store';
import { selectFilterByKey } from '../../../../../../shared-subforms/src/lib/racun/state/filters/filter.selectors';

@Component({
  selector: 'kodit-pppdv-table',
  templateUrl: './pppdv-table.component.html',
  styleUrls: ['./pppdv-table.component.scss'],
})
export class PppdvTableComponent implements OnInit {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  paginatedData!: PaginatedResultOfPPPDVEvidencijaTableDto;
  chipItems: FilterChipDto[] = [];
  filters!: GetPPPDVTableQuery;
  private _skipFirst = 0;
  numberOfRowsDisplayed = 0;
  private _isBusy = false;

  isFirstLoad = true;

  evidencijaTable: IPPPDVEvidencijaTableDto[] = [];

  /** Configurations */
  tableConfig!: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.MEDIUM_LARGE
  );

  constructor(
    private _locationService: LocationService,
    private _storage: Store,
    private _configService: ConfigService,
    private _client: PdvEvidencijeClient,
  ) {}

  ngOnInit(): void {
    this.setTableConfig().then();

    // reload tabele kada se filter promeni
    this._subs.add(
      this._storage
        .select(selectFilterByKey('PDV|PP'))
        .subscribe((data) => {
          if (data) {
            this.filters = JSON.parse(data);
            this._skipFirst = this.filters.pageNumber ?? 0;
            this.numberOfRowsDisplayed = this.filters.pageSize ?? 0;
          } else {
            this.filters = new GetPPPDVTableQuery({
              pageNumber: this._skipFirst,
              pageSize: this.numberOfRowsDisplayed,
            });
          }
          this._load();
        })
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event) => {
        if (!this.isFirstLoad) {
          this._skipFirst = event.first!;
          this.numberOfRowsDisplayed = event.rows!;
        }
        this.updateFilterDataAndReload();
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        }
      },
      selectMode: SelectMode.SINGLE,
      tableHeader: 'Evidencije prethodnog poreza',
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete izabranu pdv evidenciju?',
      deleteMessage:
        'Da li ste sigurni da želite da obrišete pdv evidenciju {param}?',
      deleteMessageParams: 'broj',
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      columns: [
        {
          field: 'broj',
          header: 'Broj',
          type: 'text',
        },
        {
          field: 'periodDto',
          subField: 'periodStr',
          header: 'Period',
          styleClass: 'pdv-period',
          styleClassField: 'periodBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
        },
        {
          field: 'godina',
          header: 'Godina',
          type: 'number',
        },
        {
          field: 'statusDto',
          subField: 'statusStr',
          header: 'Status',
          styleClass: 'pdv-status',
          styleClassField: 'statusBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
        },
        {
          field: 'datumPromeneStatusa',
          header: 'Datum promene statusa',
          type: 'text',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          tipPristupa: TipPristupa.PDV_CRUD,
          hasAccessTooltip: 'Unesite pdv evidenciju',
          noAccessTooltip: 'Nemate ovlašćenja za pdv evidencije',
          callback: () => this._routeToCreate(),
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          tipPristupa: TipPristupa.PDV_CRUD,
          hasAccessTooltip: 'Izmenite pdv evidenciju',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu pdv evidencije',
          callback: (index: number) => {
            this._routeToEdit(index);
          },
          shouldDisplayByCondition: (rowData: IPPPDVEvidencijaTableDto) => {
            return this._shouldDisplayEdit(rowData.statusDto!.status!);
          },
        },
        // {
        //   mode: ActionMode.SINGLE,
        //   type: ActionType.CUSTOM,
        //   tipPristupa: TipPristupa.PDV_CRUD,
        //   icon: 'fa-light fa-clipboard',
        //   //label: 'Evidentiraj',
        //   hasAccessTooltip: 'Evidentirajte pdv evidenciju',
        //   noAccessTooltip: 'Nemate ovlašćenja za evidentiranje pdv evidencije',
        //   shouldDisableWhenSefInactive: true,
        //   callback: (index: number) => {
        //     this._sharedService.displayLoadingScreen(
        //       true,
        //       'Evidentiranje zbirne pdv evidencije...'
        //     );
        //     this._subs.add(
        //       this._client
        //         .sefEvidentirajPdvEvidencijaZbirna39(
        //           new SefEvidentirajPdvEvidencijaZbirna39Command({
        //             id: this._getPaginatedItem(index).id,
        //           })
        //         )
        //         .subscribe((res) => {
        //           if (res.succeeded) {
        //             this._alert.addSuccessMsg(res.data!);
        //             this._load();
        //           }
        //           this._alert.addFailedMsg(res.messages![0]);
        //         })
        //     );
        //   },
        //   shouldDisplayByCondition: (
        //     rowData: IPdvEvidencijaZbirna39TableDto
        //   ) => {
        //     return this._shouldDisplayEdit(rowData.statusDto!.status!);
        //   },
        // },
        // {
        //   mode: ActionMode.SINGLE,
        //   type: ActionType.CUSTOM,
        //   tipPristupa: TipPristupa.PDV_CRUD,
        //   icon: 'fa-light fa-pen-to-square',
        //   hasAccessTooltip: 'Korigujte pdv evidenciju',
        //   noAccessTooltip: 'Nemate ovlašćenja za korigovanje pdv evidencije',
        //   shouldDisableWhenSefInactive: true,
        //   callback: (index: number) => {
        //     this.routeToKoriguj(index);
        //   },
        //   shouldDisplayByCondition: (
        //     rowData: IPdvEvidencijaZbirna39TableDto
        //   ) => {
        //     return this._shouldDisplayCancel(rowData.statusDto!.status!);
        //   },
        // },
        // {
        //   mode: ActionMode.SINGLE,
        //   type: ActionType.CUSTOM,
        //   tipPristupa: TipPristupa.PDV_CRUD,
        //   icon: 'fa-solid fa-eye',
        //   hasAccessTooltip: 'Pregledajte korigovanu pdv evidenciju',
        //   noAccessTooltip:
        //     'Nemate ovlašćenja za pregled Korigovane pdv evidencije',
        //   shouldDisableWhenSefInactive: false,
        //   callback: (index: number) => {
        //     this.routeToPregledKorigovane(index);
        //   },
        //   shouldDisplayByCondition: (
        //     rowData: IPdvEvidencijaZbirna39TableDto
        //   ) => {
        //     return this._shouldDisplayPregledKorigovane(
        //       rowData.statusDto!.status!
        //     );
        //   },
        // },
      ],
      advancedFilter: {
        component: PppdvTableFilterComponent,
        data: new GetPPPDVTableQuery(),
      },
    });
  }

  private _load() {
    if (this._isBusy) {
      return;
    }

    this._configService.setIsBusy = true;
    this._isBusy = true;

    if (!this.filters) {
      this.filters = new GetPPPDVTableQuery({
        pageNumber: this._skipFirst,
        pageSize: this.numberOfRowsDisplayed,
      });
    }
    this._subs.add(
      this._client
        .getForPPPDVTable(this.filters as GetPPPDVTableQuery)
        .pipe(
          finalize(() => {
            this._configService.setIsBusy = false;
            this._isBusy = false;
          })
        )
        .subscribe(
          (res) => {
            this.paginatedData = res;
            this.evidencijaTable = res.data!;
            this.chipItems = res.activeFilters!;
            this.tableConfig.advancedFilter!.data = this.filters;
          },
          (error) => {
            this._configService.setIsBusy = false;
          }
        )
    );
  }

  private _updateFilters() {
    if (this._suFilteriPrazni()) {
      this._storage.dispatch(
        clearFilter({
          key: 'PDV|PP',
        })
      );
      return;
    }

    this._storage.dispatch(
      setFilter({
        key: 'PDV|PP',
        filter: JSON.stringify(this.filters),
      })
    );
  }
  private _suFilteriPrazni() {
    for (const prop of Object.keys(this.filters) as Array<
      keyof GetPPPDVTableQuery
    >) {
      if (this.filters[prop]) {
        return false;
      }
    }
    return true;
  }

  private updateFilterDataAndReload() {
    if (
      (this._skipFirst !== this.filters.pageNumber ||
        this.numberOfRowsDisplayed !== this.filters.pageSize) &&
      !this.isFirstLoad
    ) {
      this.filters.pageNumber = this._skipFirst;
      this.filters.pageSize = this.numberOfRowsDisplayed;
      this._updateStorage();
    }
  }

  private _updateStorage() {
    this._storage.dispatch(
      setFilter({
        key: 'PDV|PP',
        filter: JSON.stringify(this.filters),
      })
    );
  }

  private _getPaginatedIndex(index: number): number {
    return index - this._skipFirst;
  }

  private _getPaginatedItem(index: number): IPdvEvidencijaZbirna39TableDto {
    return this.evidencijaTable![this._getPaginatedIndex(index)];
  }

  private _handleChipRemove(removedChip: FilterChipDto) {
    const nameof = nameofFactory<GetPdvEvidencijaZbirna39TableQuery>();
    const nameOfStatusi = nameof('statusi');

    if (removedChip.key!.toLowerCase() === nameOfStatusi.toLowerCase()) {
      const idx = (this.tableConfig.advancedFilter!
        .data as GetPPPDVTableQuery).statusi!.findIndex(
        (x) => x === removedChip.defaultValue
      );
      (this.tableConfig.advancedFilter!
        .data as GetPPPDVTableQuery).statusi!.splice(idx, 1);
    } else if (removedChip!.key!.toLocaleLowerCase() === nameof('mesec')) {
      this.tableConfig.advancedFilter!.data[
        toCamelCase(removedChip.key!)
        ] = undefined;
    } else {
      this.tableConfig.advancedFilter!.data[toCamelCase(removedChip.key!)] =
        removedChip.defaultValue;
    }
    this._updateFilters();
  }

  private _routeToCreate() {
    this._locationService.routeToPPPDVCreate().then();
  }

  private _routeToEdit(idx: number) {
    this._locationService.routeToPPPDVUpdate(this._getPaginatedItem(idx).id).then();
  }

  private _shouldDisplayEdit(status: PdvEvidencijaStatus) {
    return status === PdvEvidencijaStatus.U_PRIPREMI;
  }
}
