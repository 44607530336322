import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-pocetno-stanje-page',
  templateUrl: './pocetno-stanje-page.component.html',
  styleUrls: ['./pocetno-stanje-page.component.scss']
})
export class PocetnoStanjePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
