import { Component, OnInit } from '@angular/core';
import { ArhivskaKnjigaClient } from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogRef } from '@kodit/core/shared-ui';
import { ArhivskaKnjigaService } from 'libs/core/moduli/podesavanje/src/lib/arhivska-knjiga/arhivska-knjiga-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-automatsko-kreiranje-kategorija-dialog',
  templateUrl: './automatsko-kreiranje-kategorija-dialog.component.html',
  styleUrls: ['./automatsko-kreiranje-kategorija-dialog.component.scss'],
})
export class AutomatskoKreiranjeKategorijaDialogComponent implements OnInit {
  private _subs: Subscription = new Subscription();

  constructor(
    private _dialogRef: DynamicDialogRef,
    private _client: ArhivskaKnjigaClient,
    private _alertService: AlertService,
    private _arhivskaService: ArhivskaKnjigaService
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._importKategorijeAuto();
      })
    );
  }

  private _importKategorijeAuto() {
    this._subs.add(
      this._client.seedKategorijeDokumenata().subscribe((res) => {
        if (res.succeeded) {
          this._alertService.addSuccessMsg(res.messages![0]);
          this._arhivskaService.setStep01Completed = true;
          this._arhivskaService.setSledeciKorakEnabled = true;
          // this._arhivskaService.setKategorijeImportovaneAutomatski = true;
          this._dialogRef.close();
        } else {
          this._alertService.addFailedMsg(
            'Popunjavanje kategorija nije uspelo!'
          );
        }
      })
    );
  }
}
