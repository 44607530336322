<form [formGroup]="form">
  <div class="p-fluid">
    <kodit-field fieldLabel="Veličina papira za fiskalni racun">
      <kodit-velicina-papira-dropdown
        [formCtrl]="form.controls.velicinaPapiraDto.get('velicinaPapira')"
        formCtrlId="velicina-papira"
      ></kodit-velicina-papira-dropdown>
    </kodit-field>
  </div>
</form>
