<!-- <kodit-dropdown
  formCtrlId="tenant-select"
  [formCtrl]="selectedTenantForm"
  [options]="tenantOptions"
  styleClass="tenant-select"
  (optionChanged)="tenantChanged($event)"
>
  <ng-template #item let-obj>
    <div class="p-text-nowrap p-text-truncate" style="max-width: 12rem">
      {{ obj.label }}
    </div>
  </ng-template>
  <ng-template #selectedItem let-obj>
    <div class="p-text-nowrap p-text-truncate" style="max-width: 12rem">
      {{ obj.label }}
    </div>
  </ng-template>
</kodit-dropdown>
 -->

<p-dropdown
  inputId="tenant-select"
  [(ngModel)]="selectedTenant"
  [options]="tenantOptions"
  (onChange)="tenantChanged($event)"
  styleClass="tenant-select"
>
  <ng-template let-item pTemplate="selectedItem">
    <div class="p-text-nowrap p-text-truncate" style="max-width: 12rem">
      {{ item.label }}
    </div>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <div class="p-text-nowrap p-text-truncate" style="max-width: 12rem">
      {{ item.label }}
    </div>
  </ng-template>
</p-dropdown>
