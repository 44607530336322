import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { AlertService } from '@kodit/core/services';

/**
 * Sadrži metode za validaciju forme
 */
@Injectable({
  providedIn: 'root',
})
export class FormHelper {
  constructor(private _alertService: AlertService) {}
  /**
   * Prode kroz celu formu sve potrebne komponente markuje na dirty
   * @param form forma za invalidaciju
   */
  invalidateForm(form: FormGroup) {
    //const invalidControlNames = this._markGroupDirty(form);
    this._alertService.addWarnMsg('Morate uneti sva obavezna polja');
    form.markAllAsTouched();
  }

  /** Zbog calendar primeNg component */
  initializeDateFormControl(date : Date): FormControl {
    const defaultValue = date ? new Date(date) : null;
    return new FormControl(defaultValue);
  }

  private _markGroupDirty(formGroup: FormGroup): string[] {
    const invalidNames: string[] = [];
    Object.keys(formGroup.controls).forEach((key) => {
      switch (formGroup.get(key).constructor.name) {
        case 'FormGroup':
          invalidNames.concat(this._markGroupDirty(formGroup.get(key) as FormGroup));
          break;
        case 'FormArray':
          invalidNames.concat(this._markArrayDirty(formGroup.get(key) as FormArray));
          break;
        case 'FormControl':
          invalidNames.concat(this._markControlDirty(formGroup.get(key) as FormControl));
          break;
      }
    });
    return invalidNames;
  }

  private _markArrayDirty(formArray: FormArray): string[] {
    if (formArray.controls.length === 0) {
      formArray.markAsDirty();
      return;
    }
    const invalidNames: string[] = [];
    formArray.controls.forEach((control) => {
      switch (control.constructor.name) {
        case 'FormGroup':
          invalidNames.concat(this._markGroupDirty(control as FormGroup));
          break;
        case 'FormArray':
          invalidNames.concat(this._markArrayDirty(control as FormArray));
          break;
        case 'FormControl':
          invalidNames.concat(this._markControlDirty(control as FormControl));
          break;
      }
    });
    return invalidNames;
  }

  private _markControlDirty(formControl: FormControl): string {
    formControl.markAsDirty();
    if (formControl.invalid) {
      return this.getControlName(formControl);
    }
    return '';
  }

  getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
  }

  /**
   * ToDo: treba da ide u racun.service
   *
   * Prode kroz stavke i ukoliko je poslednja stavka u nizu prazna, ukloni je iz niza
   * @param stavkeArray stavke za obradu
   */
  removeEmptyStavka(stavkeArray: FormArray) {
    const index = stavkeArray.length - 1;
    if (
      stavkeArray.at(index).get('predmetStavkeDto.id').value === 0 &&
      stavkeArray.length > 1
    ) {
      stavkeArray.removeAt(index);
      stavkeArray.updateValueAndValidity();
    }
  }

   getInvalidControls(formGroup: FormGroup): string[] {
    const invalidNames: string[] = [];
    Object.keys(formGroup.controls).forEach((key) => {
      // if (formGroup.get(key).invalid) {
      //   invalidNames.push(key);
      // }
      switch (formGroup.get(key).constructor.name) {
        case 'FormGroup':
          invalidNames.concat(this.getInvalidControls(formGroup.get(key) as FormGroup));
          break;
        case 'FormArray':
          if (this._getInvalidArray(formGroup.get(key) as FormArray)) {
            invalidNames.push(key);
          }
          break;
        case 'FormControl':
          if (this._isControlInvalid(formGroup.get(key) as FormControl)) {
            invalidNames.push(key);
          }
          break;
      }
    });

    return invalidNames;
  }

  disableFormGroupControls(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);
      control?.disable();
    });
  }

  private _getInvalidArray(formArray: FormArray): boolean {
    if (formArray.controls.length === 0) {
      return false;
    }
    const invalidNames: string[] = [];
    formArray.controls.forEach((control) => {
      switch (control.constructor.name) {
        case 'FormGroup':
          invalidNames.concat(this.getInvalidControls(control as FormGroup));
          break;
        case 'FormArray':
          return this._getInvalidArray(control as FormArray);
          break;
        case 'FormControl':
          return this._isControlInvalid(control as FormControl);
          break;
      }
    });
    return invalidNames.length === 0;
  }

  private _isControlInvalid(formControl: FormControl): boolean {
    return formControl.invalid;
  }
}
