
<div [formGroup]="form" class="p-d-flex p-flex-row p-fluid">
  <kodit-filter-name class="flex-1-1" title="Filter fizičkih lica"></kodit-filter-name>
  <div class="flex-1-1 p-mr-4">
    <kodit-field fieldLabel="Ime i prezime" fieldLabelFor="ime">
      <kodit-text-input
        class="show"
        [formCtrl]="form.controls.ime"
        formCtrlId="ime"
      >
      </kodit-text-input>
    </kodit-field>
 
    <kodit-field fieldLabel="Adresa" fieldLabelFor="adresa">
      <kodit-text-input
        class="show"
        [formCtrl]="form.controls.adresa"
        formCtrlId="adresa"
      >
      </kodit-text-input>
    </kodit-field>
  </div>

</div>
