import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { OznakaPdvKategorije, SharedClient } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-identifikator-stavke-dropdown',
  templateUrl: './identifikator-stavke-dropdown.component.html',
  styleUrls: ['./identifikator-stavke-dropdown.component.scss'],
})
export class IdentifikatorStavkeDropdownComponent implements OnInit, OnDestroy {
  /** Props */
  private _subs: Subscription = new Subscription();
  items: {
    naziv: string;
    value: number;
  }[] = [];

  /** I/O */
  @Input() identifikatorCtrl: AbstractControl;
  @Output() onItemChange = new EventEmitter<any>();

  constructor(private _sharedClient: SharedClient) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._sharedClient.getIdentifikatorStavkeDropdown().subscribe((res) => {
        this.items = res.data.map((x) => ({
          value: x.identifikator,
          naziv: x.identifikatorStavkeStr,
        }));
      })
    );
  }

  handleOnItemChange(event: any) {
    this.onItemChange.emit(event);
  }

  ngOnDestroy(): void {}
}
