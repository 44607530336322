import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl} from '@angular/forms';
import {
  ArhivskaKnjigaClient,
  FizickaLokacijaAutocompleteItem,
  GetFizickaLokacijaAutocompleteQuery,
  IFizickaLokacijaAutocompleteItem,
} from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  ArhivskaKnjigaService,
  ParentForm,
} from 'libs/core/moduli/podesavanje/src/lib/arhivska-knjiga/arhivska-knjiga-service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'kodit-fizicke-lokacije-autocomplete',
  templateUrl: './fizicke-lokacije-autocomplete.component.html',
  styleUrls: ['./fizicke-lokacije-autocomplete.component.scss'],
})
export class FizickeLokacijeAutocompleteComponent implements OnInit, OnDestroy {
  /** Subs */
  private _sub: Subscription = new Subscription();

  /** I/O */
  @Input() jeArhivskaKnjigaForm: boolean = false;
  @Input() fizickaLokacijaInput: AbstractControl | undefined;

  /** Props */
  parentForm: ParentForm;
  lokacijeSuggestions: FizickaLokacijaAutocompleteItem[] = [];
  lokacijaModel: FizickaLokacijaAutocompleteItem;
  errorMsg: string = 'Obavezno polje';
  formSubmitted: boolean = true;
  form: FormGroupTypeSafe<IFizickaLokacijaAutocompleteItem>;

  constructor(
    private _client: ArhivskaKnjigaClient,
    private _arhivskaService: ArhivskaKnjigaService,
    private _fb: FormBuilderTypeSafe
  ) {
    this._sub.add(
      this._arhivskaService.getArhivskaFormSubmitted.subscribe((res) => {
        this.formSubmitted = res;
      })
    );

    this.form = _arhivskaService.getFizickaLokacijaForm();
  }

  ngOnInit(): void {
    if (!this.jeArhivskaKnjigaForm) {
      this.parentForm = this._arhivskaService.getParentForm;
      this._sub.add(
        this.parentForm.valueChanges.subscribe(
          (res: FizickaLokacijaAutocompleteItem) => {
            if (res.id) {
              this.lokacijaModel = res;
            }
          }
        )
      );
    } else {
      return;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.fizickaLokacijaInput) {
      this._sub.add(
        this.fizickaLokacijaInput.valueChanges.subscribe((res) => {
          if (res) {
            this.form.patchValue(res);
            this.lokacijaModel = res;
          }
        })
      );
    }
  }

  searchLokacija(event: any) {
    this._sub.add(
      this._client
        .getForSearch(
          new GetFizickaLokacijaAutocompleteQuery({
            naziv: event.query,
          })
        )
        .subscribe((res) => {
          this.lokacijeSuggestions = [...res.data.fizickeLokacije];
          this.lokacijeSuggestions.unshift(
            new FizickaLokacijaAutocompleteItem({
              id: null,
              naziv: '# Početak',
              punNaziv: '# Početak'
            })
          );
        })
    );
  }

  handleInputClear() {
    this._arhivskaService.arhivskaKnjigaForm.controls.fizickaLokacijaDto.reset();
  }

  onSelectChange(lokacija: FizickaLokacijaAutocompleteItem) {
    if (!this.jeArhivskaKnjigaForm) {
      this._arhivskaService.setParent(lokacija);
    } else {
      this._arhivskaService.setFizickaLokacijaValue(lokacija);
    }
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
