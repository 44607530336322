import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ICenovnikStavkaDto, TipPristupa } from '@kodit/core/data-api';
import { concat, filterBy } from '@kodit/core/shared';
import { DynamicDialogService } from '@kodit/core/shared-ui';
import {
  DialogSize,
  DynamicDialogConfig,
  OnDynamicDialogInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  ActionClass,
  ActionType,
  OnTableInit,
  SelectMode,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { Subscription } from 'rxjs';
import { CenovnikStavkeService } from '../../../../../services/src/lib/cenovnik-stavke.service';
import { DefinisanjeCenaFormComponent } from '../definisanje-cena/definisanje-cena-form/definisanje-cena-form.component';

@Component({
  selector: 'kodit-cenovnik-stavke-table',
  templateUrl: './cenovnik-stavke-table.component.html',
  styleUrls: ['./cenovnik-stavke-table.component.scss'],
})
export class CenovnikStavkeTableComponent
  implements OnInit, OnDestroy, OnTableInit, OnDynamicDialogInit {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Configurations */
  tableConfig: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.TINY);

  /** Props */

  items: any[] = [];
  @Input() cenovnikId: number;
  @Input() isLevaLista: boolean = false;
  @Input() tableHeader: string;
  @Input() moreActionLabel: string;
  @Input() moreActionIcon: string;

  @Input() multiActionLabel: string;
  @Input() multiActionIcon: string;

  stavkeCenovnika: ICenovnikStavkaDto[] = [];
  leva: ICenovnikStavkaDto[] = [];
  desna: ICenovnikStavkaDto[] = [];

  constructor(
    private _cenovnikStavkeService: CenovnikStavkeService,
    private _dialogService: DynamicDialogService
  ) {}

  ngOnInit(): void {
    this.setTableConfig();

    // uvek ucitavamo desnu listu, da bismo mogli da izbacimo stavke iz leve liste sto su ubaceni u desnu
    this._subs.add(
      this._cenovnikStavkeService.getDesnaLista.subscribe(
        (desnaLista: ICenovnikStavkaDto[]) => {
          this.desna = desnaLista;
          if (!this.isLevaLista) {
            this.stavkeCenovnika = [...this.desna];
          }
        }
      )
    );
    if (this.isLevaLista) {
      this._subs.add(
        this._cenovnikStavkeService.getLevaLista.subscribe(
          (levaLista: ICenovnikStavkaDto[]) => {
            // ako je leva lista, moramo da izbacimo sve one stavke sto su u desnoj
            this.leva = filterBy(levaLista, (x: ICenovnikStavkaDto) => {
              return (
                this.desna.length === 0 ||
                this.desna.findIndex(
                  (d) => d.predmetStavkeId === x.predmetStavkeId
                ) === -1
              );
            });
            this.stavkeCenovnika = [...this.leva];
          }
        )
      );
    }
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: this.tableHeader,
      selectMode: SelectMode.MULTI,
      //rowsPerPage: 5,
      // tableFilterFields: ['sifra'],
      columns: [
        {
          field: 'nazivPredmetaStavke',
          header: 'Naziv',
          type: 'text',
          columns: [
            { field: 'sifra', header: 'Šifra', type: 'text' },
            {
              field: 'nazivJediniceMere',
              header: 'Jedinica mere',
              type: 'badge',
            },
          ],
        },
        {
          field: 'ulaznaCena',
          header: 'Osnovna cena',
          type: 'currency',
          currencyAlphaCharField: 'valutaText',
        },
        {
          field: 'prodajnaCena',
          header: 'Prodajna cena',
          type: 'currency',
          currencyAlphaCharField: 'valutaText',
        },
      ],
      headerActions: [
        {
          type: ActionType.MULTI_CUSTOM,
          actionClass: ActionClass.TEXT,
          label: this.multiActionLabel,
          icon: this.multiActionIcon,
          tipPristupa: TipPristupa.CENOVNIK_CRUD,
          hasAccessTooltip: this.isLevaLista
            ? 'Prebacite stavke u novi cenovnik'
            : 'Izbacite stavke iz novov cenovnika',
          noAccessTooltip: 'Nemate ovlašćenja za ovu akciju',
          callback: (result: ICenovnikStavkaDto[]) => {
            if (this.isLevaLista) {
              // radimo update desne liste
              // this.desna = filterBy(this.desna, (x: ICenovnikStavkaDto) => {
              //   return (
              //     result.length === 0 ||
              //     result.findIndex(
              //       (d) => d.predmetStavkeId === x.predmetStavkeId
              //     ) === -1
              //   );
              // });
              this._cenovnikStavkeService.setDesnaLista = concat(
                result,
                this.desna
              );
              // radimo update leve liste
              this._cenovnikStavkeService.setLevaLista = filterBy(
                this.leva,
                (x: ICenovnikStavkaDto) => {
                  return (
                    result.length === 0 ||
                    result.findIndex(
                      (d) => d.predmetStavkeId === x.predmetStavkeId
                    ) === -1
                  );
                }
              );
            } else {
              this.dialogConfig.data = {
                cenovnikId: this.cenovnikId,
                stavke: result,
              };
              this.dialogConfig.header =
                'Definisanje cena za selektovane stavke';
              this.openDialog(this.dialogConfig);
            }
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.MULTI_CUSTOM,
          tipPristupa: TipPristupa.CENOVNIK_CRUD,
          label: this.isLevaLista ? 'Dodaj' : 'Ukloni',
          icon: this.isLevaLista ? 'far fa-plus-square' : 'far fa-minus-square',
          hasAccessTooltip: this.isLevaLista
            ? 'Dodajte robu u cenovnik'
            : 'Izbacite robu iz novog cenovnika',
          noAccessTooltip: 'Nemate ovlašćenja za kreiranje cenovnika',
          callback: (index: number) => {
            this.isLevaLista
              ? this._handleAddToDesna(index)
              : this._handleRemoveFromDesna(index);
          },
        },
      ],
    });
  }

  openDialog(config: DynamicDialogConfig): void {
    this._dialogService.open(DefinisanjeCenaFormComponent, config);
  }

  private _handleAddToDesna(rowIndex: number) {
    let uklonjena = this.leva.splice(rowIndex, 1);
    this._cenovnikStavkeService.addToDesnaLista(uklonjena);
    this._cenovnikStavkeService.setLevaLista = this.leva;
  }

  private _handleRemoveFromDesna(rowIndex: number) {
    let uklonjena = this.desna.splice(rowIndex, 1);
    this._cenovnikStavkeService.setDesnaLista = this.desna;
    this._cenovnikStavkeService.addToLevaLista(uklonjena);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
