import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AlertService,
  LocationService,
  RacunService,
  SharedService,
} from '@kodit/core/services';
import {
  CreateUlaznaFakturaCommand,
  IUlaznaFakturaDto,
  TipRacuna,
  UlazneFaktureClient,
  UpdateUlaznaFakturaCommand,
  VrstaFakture,
} from '@kodit/core/data-api';
import { ConfirmationService } from 'primeng/api';
import {
  FormHelper,
  UlaznaFakturaFormService,
} from 'libs/core/form-definitions/src';
import { ActivatedRoute } from '@angular/router';
import { RacunFormConfig } from '../../subforms/racun-form-container/racun-form.config';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { FormArray } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'kodit-ulazna-faktura-form',
  templateUrl: './ulazna-faktura-form.component.html',
  styleUrls: ['./ulazna-faktura-form.component.scss'],
})
export class UlaznaFakturaFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _saveSub: Subscription = new Subscription();
  private _errorSub: Subscription = new Subscription();
  private _valutaChangedSub: Subscription = new Subscription();

  /** Props */
  fakturaForm: FormGroupTypeSafe<IUlaznaFakturaDto>;
  racunFormConfig: RacunFormConfig;

  constructor(
    private _client: UlazneFaktureClient,
    private _alertService: AlertService,
    private _confirmationService: ConfirmationService,
    private _locationService: LocationService,
    private _racunService: RacunService,
    private _formService: UlaznaFakturaFormService,
    private _route: ActivatedRoute,
    private _formHelper: FormHelper,
    private _breadcrumbService: BreadcrumbService,
    private _sharedService : SharedService
  ) {}

  ngOnInit(): void {
    // get route data
    this.fakturaForm = this._formService.GetUlaznaFakturaFormGroup(
      this._route.snapshot.data.racun,
      this._route.snapshot.data.formConfiguration
    );
    // configure labels
    this._racunService.setBrojRacunaLabel = 'Broj fakture';
    this.racunFormConfig = {
      tip: TipRacuna.ULAZNA_FAKTURA,
      racunForm: this.fakturaForm.controls.racunDto,
      brojRacunaTooltip: 'Dodeljen broj fakture',
      originalanBrojRacunaTooltip:
        'Unesite broj fakture koji ste dobili od izdavaoca',
      saveCallback: () => this.onSave(),
      cancelCallback: () => this.onCancel(),
    };

    this._valutaChangedSub = this._racunService.getJeDomaciRacun.subscribe(
      (val) => {
        this.fakturaForm.controls.vrstaFakture.patchValue(
          val ? VrstaFakture.DOMACA : VrstaFakture.STRANA
        );
      }
    );
    this._setBreadcrumb();
  }

  get jeEditMode(): boolean {
    return this._route.snapshot.data.racun?.racunDto?.id;
  }

  onSave() {
    this._formHelper.removeEmptyStavka(
      this.fakturaForm.controls.racunDto.get('stavkeDto') as FormArray
    );
    // check is it valid
    if (!this.fakturaForm.valid) {
      this._formHelper.invalidateForm(this.fakturaForm);
      return;
    }

    // everything is ok, so let's save it
    if (this.jeEditMode) {
      this._sharedService.displayLoadingScreen(true, 'Ažuriranje ulazne fakture...');
      // it's edit
      this._saveSub = this._client
        .update(
          this.fakturaForm.value.racunDto.id,
          this.fakturaForm.value as UpdateUlaznaFakturaCommand
        )
        .subscribe(() => {
          this._alertService.addSuccessMsg(
            `Faktura ${this.fakturaForm.value.racunDto.broj} je uspešno izmenjena.`
          );
          this._locationService.routeBack();
        });
    } else {
      this._sharedService.displayLoadingScreen(true, 'Kreiranje ulazne fakture...');
      // it's new
      this._saveSub = this._client
        .create(this.fakturaForm.value as CreateUlaznaFakturaCommand)
        .subscribe(() => {
          this._alertService.addSuccessMsg(`Faktura je uspešno kreirana.`);
          this._locationService.routeBack();
        });
    }
  }

  onCancel() {
    this._confirmationService.confirm({
      message: 'Ukoliko otkažete sav ne snimljen sadržaj će biti izgubljen.',
      acceptLabel: 'Ipak otkaži',
      rejectLabel: 'Ostani na stranici',
      header: 'Potvrdite otkazivanje',
      icon: 'far fa-check-circle',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._locationService.routeBack();
      },
    });
  }

  private _setBreadcrumb() {
    this._breadcrumbService.set(
      '@unosUlazneFaktureZaStranku',
      `Unos ulazne fakture za '${this.fakturaForm.value.racunDto.strankaRacunaDto.naziv}'`
    );
    this._breadcrumbService.set(
      '@izmenaUlazneFakture',
      `Izmena ulazne fakture ${this.fakturaForm.value.racunDto.broj}`
    );
  }

  ngOnDestroy() {
    this._saveSub.unsubscribe();
    this._errorSub.unsubscribe();
    this._valutaChangedSub.unsubscribe();
  }
}
