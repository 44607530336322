import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { Subscription } from 'rxjs';
import { FileUploadService } from './file-upload.service';

@Component({
  selector: 'kodit-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnDestroy, OnChanges {
  private _subs: Subscription = new Subscription();

  /** Props */
  hasFiles: boolean;
  showProgressBar: boolean;
  fileSizeExceeded: boolean;
  errorMsg: string = 'Premašena maksimalna veličina datoteka.';
  maxFileSize: number = 0;
  @ViewChild('fileUpload') fileUploadRef: FileUpload;

  /** I/O */
  @Input() allowMultipleFiles: boolean;
  @Input() uploadCompleted: boolean = false;
  @Input() shouldAutoUpload: boolean = false;
  @Input() fileTypes: string = '.csv';
  @Input() fileInputId: string = 'upload';
  @Input() descriptionText: string = 'Izaberite fajl klikom na plus polje';
  @Output() onUpload = new EventEmitter<File[] | File>();

  constructor(private _service: FileUploadService) {}

  ngOnInit(): void {
    this._subs = this._service.getShouldReset.subscribe((res) => {
      if (res) {
        this._reset();
      }
    });

    this._subs = this._service.getMaxAttachmentSize.subscribe((res) => {
      if (res) {
        this.maxFileSize = res;
        this.errorMsg = `Maksimalna veličina datoteka je ${Math.ceil(
          this.maxFileSize / 1024 ** 2
        )} MB.`;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.uploadCompleted?.currentValue) {
      this._reset();
    }
  }

  private _reset() {
    if (this.fileUploadRef) {
      this.fileUploadRef.files = [];
    }
    this.hasFiles = false;
    this.showProgressBar = false;
  }

  upload() {
    this.showProgressBar = true;
    if (this.allowMultipleFiles) {
      this.onUpload.emit(this.fileUploadRef.files);
    } else {
      this.onUpload.emit(this.fileUploadRef.files[0]);
    }
  }

  fileSelected(event: any) {
    const files: File[] = [];
    let uploadedFileSize: number = 0;
    for (let i = 0; i < event.srcElement.files.length; ++i) {
      files.push(event.srcElement.files[i]);
    }

    files.forEach((f) => {
      uploadedFileSize += f.size;
    });

    if (uploadedFileSize > this.maxFileSize) {
      this.fileSizeExceeded = true;
      return;
    }

    this.fileUploadRef.files = files;
    this.hasFiles = true;
    if (this.shouldAutoUpload) {
      this.upload();
    }
    this.fileSizeExceeded = false;
  }

  removeFiles(i) {
    this.hasFiles = this.fileUploadRef.files.length - 1 > 0;
    this.fileUploadRef.files.splice(i, 1);
    this._service.setAttachments = this.fileUploadRef.files;
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
