<div class="p-d-flex p-flex-column p-fluid gap-12">
  <kodit-field
    fieldLabel="Napomena o poreskom oslobađanju"
    fieldLabelFor="poresko-oslobadjanje"
    *ngIf="shouldDisplayNapomenaOPoreskomOlobadjanju"
  >
    <kodit-text-input
      [formCtrl]="napOPoreskomOslobCtrl"
      formCtrlId="poresko-oslobadjanje"
    >
    </kodit-text-input>
  </kodit-field>

  <kodit-field fieldLabelFor="dodatna-napomena" fieldLabel="Dodatna napomena">
    <p-editor
      id="dodatna-napomena"
      [formControl]="napomenaCtrl"
      [style]="{ height: '150px' }"
    >
      <ng-template pTemplate="header">
        <span class="ql-formats">
          <button type="button" class="ql-bold" aria-label="Bold"></button>
          <button type="button" class="ql-italic" aria-label="Italic"></button>
        </span>
      </ng-template>
    </p-editor>
    <kodit-error-info-max-length
      [formCtrl]="napomenaCtrl"
    ></kodit-error-info-max-length>
  </kodit-field>
</div>
