import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  IGetPravnoLiceTableQuery,
  IPravnoLiceDto,
  PravniOblik,
  TipPravnogLica,
  TipStranke,
} from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { BaseStrankaFormService } from './_base_stranka-form.service';

@Injectable({
  providedIn: 'root',
})
export class PravnoLiceFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private __baseStrankaForm: BaseStrankaFormService,
    private _strankaFormService: BaseStrankaFormService
  ) {}

  /**
   * Konvertuje model za pravno lice i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model pravnog lica od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetPravnoLiceFormGroup(
    model?: IPravnoLiceDto
  ): FormGroupTypeSafe<IPravnoLiceDto> {
    const baseStrankaForm = this.__baseStrankaForm.GetBaseStrankaFormGroup(
      TipStranke.PRAVNO_LICE,
      model
    );

    return this._fb.group<IPravnoLiceDto>({
      id: baseStrankaForm.controls.id as FormControl,
      naziv: baseStrankaForm.controls.naziv as FormControl,
      kupac: baseStrankaForm.controls.kupac as FormControl,
      dobavljac: baseStrankaForm.controls.dobavljac as FormControl,
      napomena: baseStrankaForm.controls.napomena as FormControl,
      tipStranke: baseStrankaForm.controls.tipStranke as FormControl,
      adresa: baseStrankaForm.controls.adresa as FormGroup,
      tekuciRacuniDto: baseStrankaForm.controls.tekuciRacuniDto as FormArray,
      uSistemuPDVa: baseStrankaForm.controls.uSistemuPDVa as FormControl,
      rokPlacanjaZaIzlazne: baseStrankaForm.controls
        .rokPlacanjaZaIzlazne as FormControl,
      rokPlacanjaZaUlazne: baseStrankaForm.controls
        .rokPlacanjaZaUlazne as FormControl,
      createdBy: baseStrankaForm.controls.createdBy as FormControl,
      createdOn: baseStrankaForm.controls.createdOn as FormControl,
      lastModifiedBy: baseStrankaForm.controls.lastModifiedBy as FormControl,
      lastModifiedOn: baseStrankaForm.controls.lastModifiedOn as FormControl,
      datumOsnivanja: new FormControl(model?.datumOsnivanja),
      datumPocetkaStatusa: new FormControl(model?.datumPocetkaStatusa),
      datumRegistracije: new FormControl(model?.datumRegistracije),
      jeKupac: baseStrankaForm.controls.jeKupac as FormControl,
      jeDobavljac: baseStrankaForm.controls.jeDobavljac as FormControl,
      isActive: baseStrankaForm.controls.isActive as FormControl,
      maticniBroj: new FormControl(model?.maticniBroj, {
        validators: [
          model?.tipPravnogLica === TipPravnogLica.DOMACE
            ? Validators.required
            : Validators.nullValidator,
          Validators.maxLength(8),
        ],
      }),
      pib: new FormControl(model?.pib, {
        validators: [Validators.maxLength(9), Validators.minLength(8)],
      }),
      punNaziv: new FormControl(model?.punNaziv, {
        validators: [Validators.maxLength(150), Validators.required],
      }),
      sifraDelatnosti: new FormControl(model?.sifraDelatnosti, {
        validators: [Validators.maxLength(5)],
      }),
      tipPravnogLica: new FormControl(
        model?.tipPravnogLica ?? TipPravnogLica.EMPTY
      ),
      vlasnici: new FormControl(model?.vlasnici, {
        validators: [Validators.maxLength(150)],
      }),
      zastupnici: new FormControl(model?.zastupnici, {
        validators: [Validators.maxLength(150)],
      }),
      pravniOblik: new FormControl(model?.pravniOblik ?? PravniOblik.EMPTY),
      jbkjs: new FormControl(model?.jbkjs),
      registracioniBroj: new FormControl(model?.registracioniBroj, {
        validators: [Validators.maxLength(32)],
      }),
      valutaPoslovanjaId: baseStrankaForm.controls
        .valutaPoslovanjaId as FormControl,
      brojUgovora: new FormControl(model?.brojUgovora, {
        validators: [Validators.maxLength(30)],
      }),
      brojTendera: new FormControl(model?.brojTendera, {
        validators: [Validators.maxLength(30)],
      }),
      gln: new FormControl(model?.gln, {
        validators: [Validators.maxLength(13)],
      }),
    });
  }

  public GetPravnoLiceTableRequest(
    model?: IGetPravnoLiceTableQuery
  ): FormGroupTypeSafe<IGetPravnoLiceTableQuery> {
    return this._fb.group<IGetPravnoLiceTableQuery>({
      sifraDelatnosti: new FormControl(model?.sifraDelatnosti),
      stranke: this._strankaFormService.GetStrankeFormArray(
        model?.stranke
      ),
    });
  }
}
