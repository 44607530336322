<kodit-card-container>
  <div card-title>Avansne uplate</div>

  <div card-body>
    <kodit-avansna-uplata-racuna-table></kodit-avansna-uplata-racuna-table>

    <button
      *ngIf="shouldAllowButton"
      pButton
      pRipple
      type="button"
      icon="fas fa-plus"
      class="p-button-outlined p-mt-5"
      (click)="overlayPanelRef.toggle($event)"
      label="Nova avansna uplata"
      pTooltip="Registruj avansnu uplatu na račun"
      [disabled]="ukupanIznos.value.ukupnoZaUplatu === 0"
    ></button>

    <p-overlayPanel #overlayPanelRef [showCloseIcon]="true">
      <ng-template pTemplate>
        <div class="overlay-content">
          <kodit-avansni-racun-table
            (onRegistrujUplate)="overlayPanelRef.hide()"
          >
          </kodit-avansni-racun-table>
        </div>
      </ng-template>
    </p-overlayPanel>

    <kodit-ukupan-iznos-table
      *ngIf="shouldDisplayUkupanIznos"
    ></kodit-ukupan-iznos-table>
  </div>
</kodit-card-container>
