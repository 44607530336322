import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TopbarService {

  //#region Podesavanja sidebar behaviour subject
  showPodesavanjaSidebar$ = new BehaviorSubject(false);

  showPodesavanjaSidebar() {
    this.showPodesavanjaSidebar$.next(true);
  }

  hidePodesavanjaSidebar() {
    this.showPodesavanjaSidebar$.next(false);
  }

  get getShowPodesavanjaSidebar() {
    return this.showPodesavanjaSidebar$.asObservable();
  }
  //#endregion

  //#region Notifikacije sidebar behaviour subject
  showNotifikacijeSidebar$ = new BehaviorSubject(false);

  showNotifikacijeSidebar() {
    this.showNotifikacijeSidebar$.next(true);
  }

  hideNotifikacijeSidebar() {
    this.showNotifikacijeSidebar$.next(false);
  }

  get getShowNotifikacijeSidebar() {
    return this.showNotifikacijeSidebar$.asObservable();
  }
  //#endregion

  //#region  Profile sidebar behaviour subject
  showProfileSidebar$ = new BehaviorSubject(false);

  showProfileSidebar() {
    this.showProfileSidebar$.next(true);
  }

  hideProfileSidebar() {
    this.showProfileSidebar$.next(false);
  }

  get getShowProfileSidebar() {
    return this.showProfileSidebar$.asObservable();
  }
  //#endregion
}
