<form (keydown)="keyDownFunction($event)">
  <div class="p-text-left">
    Na mobilni telefon {{ mobilniTelefon }} je poslat verifikacioni kod.
  </div>
  <div class="p-text-left">Molimo Vas unesite ga.</div>
  <div class="p-fluid mw-500 p-mt-3">
    <div class="p-field">
      <label for="code" kodit-form-label>Verifikacioni kod</label>
      <kodit-text-input
        formCtrlId="code"
        [formCtrl]="form.controls.code"
      ></kodit-text-input>
    </div>
  </div>
</form>
