<kodit-blockable-div #content>
  <div class="p-d-flex p-flex-column p-flex-md-row">
    <div class="p-d-flex container-left">
      <!-- <div class="snowflakes" aria-hidden="true">
        <div class="snowflake">
          ❅
        </div>
        <div class="snowflake">
          ❆
        </div>
        <div class="snowflake">
          ❅
        </div>
        <div class="snowflake">
          ❆
        </div>
        <div class="snowflake">
          ❅
        </div>
        <div class="snowflake">
          ❆
        </div>
        <div class="snowflake">
          ❅
        </div>
        <div class="snowflake">
          ❆
        </div>
        <div class="snowflake">
          ❅
        </div>
        <div class="snowflake">
          ❆
        </div>
        <div class="snowflake">
          ❅
        </div>
        <div class="snowflake">
          ❆
        </div>
      </div> -->
    </div>
    <div class="p-d-flex p-flex-column p-jc-between p-ai-center container-right">
      <div class="p-d-flex header">
        <svg width="166" height="36" viewBox="0 0 166 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M42.1489 17.9998L26.1492 33.9996L10.1494 17.9998L22.735 5.41408L29.5633 5.41408L42.1489 17.9998Z"
                stroke="#2E394B" stroke-width="2.8284"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M20.1495 6.00018L18.1493 3.99988L4.14945 17.9998L18.1493 31.9996L20.1489 30L22.1488 32L19.1492 34.9996C18.597 35.5519 17.7015 35.5519 17.1493 34.9996L1.14949 18.9997C0.597216 18.4475 0.597216 17.552 1.14949 16.9998L17.1493 0.999902C17.7015 0.447622 18.597 0.447622 19.1492 0.999902L22.2395 4.09018C22.0518 4.16039 21.8794 4.27035 21.7349 4.41478L20.1495 6.00018Z"
                fill="#2E394B"/>
          <path
            d="M20.5939 22.4442C20.3484 22.1987 20.3484 21.8008 20.5939 21.5553L29.7048 12.4443C29.9503 12.1988 30.3483 12.1988 30.5937 12.4443L31.7048 13.5554C31.9503 13.8008 31.9503 14.1988 31.7048 14.4443L22.5938 23.5553C22.3484 23.8008 21.9504 23.8008 21.7049 23.5553L20.5939 22.4442Z"
            fill="#2E394B"/>
          <rect width="8.48519" height="2.8284" rx="0.628532"
                transform="matrix(0.707105 -0.707109 0.707105 0.707109 16.1494 17.9998)" fill="#2E394B"/>
          <path
            d="M24.5939 26.4442C24.3484 26.1987 24.3484 25.8008 24.5939 25.5553L33.7048 16.4443C33.9503 16.1988 34.3483 16.1988 34.5937 16.4443L35.7048 17.5554C35.9503 17.8008 35.9503 18.1988 35.7048 18.4443L26.5938 27.5553C26.3484 27.8008 25.9504 27.8008 25.7049 27.5553L24.5939 26.4442Z"
            fill="#2E394B"/>
          <path
            d="M21.2579 7.11412C21.0125 6.86866 21.0125 6.47069 21.2579 6.22524L23.296 4.18713C23.4139 4.06925 23.5738 4.00303 23.7405 4.00303L28.553 4.00303C28.7197 4.00303 28.8796 4.06925 28.9975 4.18713L31.0356 6.22524C31.281 6.47069 31.281 6.86866 31.0356 7.11412L26.5912 11.5585C26.3457 11.804 25.9478 11.804 25.7023 11.5585L21.2579 7.11412Z"
            fill="#2E394B"/>
          <path
            d="M163.478 24.9999V23.6139C162.743 24.6639 161.63 25.2099 160.097 25.2099C157.766 25.2099 156.212 23.8449 156.212 21.7449C156.212 19.5609 157.976 18.3849 161.252 18.3849C161.882 18.3849 162.428 18.4269 163.121 18.5109V17.8389C163.121 16.5789 162.407 15.8439 161.189 15.8439C159.929 15.8439 159.173 16.5789 159.068 17.8389H156.443C156.611 15.2559 158.48 13.5759 161.189 13.5759C164.129 13.5759 165.851 15.1929 165.851 17.9439V24.9999H163.478ZM158.963 21.6609C158.963 22.6059 159.635 23.1939 160.727 23.1939C162.218 23.1939 163.121 22.3749 163.121 21.0729V20.2749C162.428 20.1699 161.945 20.1279 161.441 20.1279C159.782 20.1279 158.963 20.6529 158.963 21.6609Z"
            fill="#2E394B"/>
          <path
            d="M154.689 11.1817C154.689 12.1687 153.891 12.9037 152.736 12.9037C151.581 12.9037 150.783 12.1687 150.783 11.1817C150.783 10.1527 151.581 9.41772 152.736 9.41772C153.891 9.41772 154.689 10.1527 154.689 11.1817ZM154.206 13.7857V24.9577C154.206 27.9817 152.568 29.2627 150.342 29.2627C149.817 29.2627 149.271 29.2207 148.767 29.0317V26.3227C149.082 26.4907 149.502 26.5747 149.985 26.5747C150.762 26.5747 151.308 26.1127 151.308 24.8737V13.7857H154.206Z"
            fill="#2E394B"/>
          <path
            d="M149.167 11.1817C149.167 12.1687 148.369 12.9037 147.214 12.9037C146.08 12.9037 145.282 12.1687 145.282 11.1817C145.282 10.1527 146.08 9.41772 147.214 9.41772C148.369 9.41772 149.167 10.1527 149.167 11.1817ZM148.705 24.9997H145.786V13.7857H148.705V24.9997Z"
            fill="#2E394B"/>
          <path
            d="M136.256 18.8889V24.9999H133.337V13.7859H136.025V15.3819C136.844 14.2689 137.999 13.5759 139.469 13.5759C141.716 13.5759 143.27 15.0459 143.27 17.7549V24.9999H140.372V18.7419C140.372 17.0829 139.721 16.1589 138.398 16.1589C137.243 16.1589 136.256 17.0829 136.256 18.8889Z"
            fill="#2E394B"/>
          <path
            d="M128.454 24.9999V23.6139C127.719 24.6639 126.606 25.2099 125.073 25.2099C122.742 25.2099 121.188 23.8449 121.188 21.7449C121.188 19.5609 122.952 18.3849 126.228 18.3849C126.858 18.3849 127.404 18.4269 128.097 18.5109V17.8389C128.097 16.5789 127.383 15.8439 126.165 15.8439C124.905 15.8439 124.149 16.5789 124.044 17.8389H121.419C121.587 15.2559 123.456 13.5759 126.165 13.5759C129.105 13.5759 130.827 15.1929 130.827 17.9439V24.9999H128.454ZM123.939 21.6609C123.939 22.6059 124.611 23.1939 125.703 23.1939C127.194 23.1939 128.097 22.3749 128.097 21.0729V20.2749C127.404 20.1699 126.921 20.1279 126.417 20.1279C124.758 20.1279 123.939 20.6529 123.939 21.6609Z"
            fill="#2E394B"/>
          <path
            d="M119.905 19.3929C119.905 22.7109 117.952 25.2099 114.928 25.2099C113.605 25.2099 112.471 24.6429 111.631 23.5509V29.0529H108.712V13.7859H111.4V15.4239C112.261 14.2269 113.5 13.5759 114.928 13.5759C117.952 13.5759 119.905 16.0959 119.905 19.3929ZM116.902 19.3929C116.902 17.2929 115.705 16.1589 114.277 16.1589C112.849 16.1589 111.652 17.2719 111.652 19.3929C111.652 21.5139 112.849 22.6059 114.277 22.6059C115.705 22.6059 116.902 21.5349 116.902 19.3929Z"
            fill="#2E394B"/>
          <path
            d="M92.3106 24.9999H89.3916V13.7859H92.0796V15.3819C92.8986 14.2479 93.9486 13.5759 95.4396 13.5759C96.9726 13.5759 98.1486 14.2899 98.7156 15.5709C99.5976 14.3319 100.732 13.5759 102.412 13.5759C104.68 13.5759 106.213 15.0669 106.213 17.7549V24.9999H103.315V18.6789C103.315 17.1039 102.643 16.1589 101.362 16.1589C100.207 16.1589 99.2616 17.0619 99.2616 18.7419V24.9999H96.3636V18.6789C96.3636 17.1039 95.6916 16.1589 94.4106 16.1589C93.2346 16.1589 92.3106 17.0619 92.3106 18.7419V24.9999Z"
            fill="#2E394B"/>
          <path
            d="M81.8404 25.2099C78.5854 25.2099 76.1494 22.6479 76.1494 19.3929C76.1494 16.1169 78.5854 13.5759 81.8404 13.5759C85.0954 13.5759 87.5314 16.1169 87.5314 19.3929C87.5314 22.6479 85.0954 25.2099 81.8404 25.2099ZM81.8404 22.6269C83.2894 22.6269 84.5284 21.4719 84.5284 19.3929C84.5284 17.3139 83.2894 16.1799 81.8404 16.1799C80.3914 16.1799 79.1524 17.3139 79.1524 19.3929C79.1524 21.4719 80.3914 22.6269 81.8404 22.6269Z"
            fill="#2E394B"/>
          <path
            d="M67.1383 24.9996H63.9463V9.92163H67.1383V16.5576L72.6193 9.92163H76.2733L70.2883 17.1456L76.4413 24.9996H72.5143L67.1383 18.1116V24.9996Z"
            fill="#2E394B"/>
          <path
            d="M56.4049 25.1889C53.0449 25.1889 50.7979 22.8579 50.7979 19.3509C50.7979 16.0119 53.1289 13.5759 56.3629 13.5759C59.9119 13.5759 62.2639 16.4529 61.7389 20.1069H53.7799C53.9689 21.9339 54.8509 22.9419 56.3419 22.9419C57.6229 22.9419 58.4839 22.3119 58.8199 21.1989H61.7179C61.0879 23.7399 59.1349 25.1889 56.4049 25.1889ZM56.2999 15.7179C54.9349 15.7179 54.0739 16.5999 53.8219 18.2589H58.6519C58.5679 16.7049 57.6859 15.7179 56.2999 15.7179Z"
            fill="#2E394B"/>
        </svg>
      </div>
      <div class="p-d-flex p-flex-column p-ai-center p-jc-center flex-1">
        <div class="p-d-flex p-flex-column" *ngIf="!showResetForm">
          <div class="title-header-potvrda">Moramo da potvrdimo Vaš nalog</div>
          <div class="subtitle-header-potvrda p-mt-3">Unesite E-Mail adresu</div>
          <div class="p-field p-mt-3">
            <span class="p-d-flex p-flex-row p-ai-center p-input-icon-left">
                <i>
                  <svg width="21" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3 16.6667C4.94649 14.6021 7.58918 13.3333 10.5 13.3333C13.4108 13.3333 16.0535 14.6021 18 16.6667M14.25 6.25C14.25 8.32107 12.5711 10 10.5 10C8.42893 10 6.75 8.32107 6.75 6.25C6.75 4.17893 8.42893 2.5 10.5 2.5C12.5711 2.5 14.25 4.17893 14.25 6.25Z"
                      stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </i>
                <input type="text" class="flex-1" pInputText [formControl]="requestForm.controls.korisnikEmail"/>
            </span>
            <kodit-error-info-max-length
              [formCtrl]="requestForm.controls.korisnikEmail"
            ></kodit-error-info-max-length>
            <kodit-error-info-min-length
              [formCtrl]="requestForm.controls.korisnikEmail"
            ></kodit-error-info-min-length>
            <kodit-error-info-required
              [formCtrl]="requestForm.controls.korisnikEmail"
            ></kodit-error-info-required>
            <small
              *ngIf="
                requestForm.controls.korisnikEmail.errors?.email &&
                requestForm.controls.korisnikEmail.touched &&
                requestForm.controls.korisnikEmail.value.length > 0
              "
              class="p-invalid"
            >E-Mail nije validan.</small
            >
          </div>
          <div class="posalji-button" (click)="sendRequest()">Pošalji zahtev</div>
        </div>
        <div class="p-d-flex p-flex-column" *ngIf="showResetForm">
          <div class="title-header-potvrda">Moramo da potvrdimo Vaš nalog</div>
          <div class="subtitle-header-potvrda p-mt-3">Kreiranje nove lozinke</div>
          <div class="p-field p-mt-4">
            <label kodit-form-label>Verifikacioni kod</label>
            <div class="p-field">
                <span class="p-d-flex p-flex-row p-ai-center p-input-icon-left">
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g opacity="0.4" clip-path="url(#clip0_5200_495)">
                        <path
                          d="M7.50033 9.99996L9.16699 11.6666L12.917 7.91663M14.918 4.16538C15.0896 4.5804 15.419 4.91029 15.8337 5.08252L17.288 5.68494C17.7031 5.85686 18.0328 6.18662 18.2047 6.60168C18.3766 7.01673 18.3766 7.48308 18.2047 7.89813L17.6028 9.35146C17.4308 9.7667 17.4305 10.2335 17.6033 10.6486L18.2042 12.1015C18.2894 12.307 18.3333 12.5274 18.3334 12.7499C18.3334 12.9725 18.2896 13.1928 18.2044 13.3984C18.1193 13.604 17.9944 13.7908 17.8371 13.9482C17.6797 14.1055 17.4928 14.2303 17.2872 14.3154L15.834 14.9173C15.4189 15.089 15.0891 15.4183 14.9168 15.8331L14.3145 17.2875C14.1425 17.7025 13.8128 18.0323 13.3978 18.2042C12.9827 18.3761 12.5164 18.3761 12.1013 18.2042L10.6481 17.6022C10.233 17.4307 9.76685 17.4311 9.35206 17.6032L7.89774 18.2048C7.48293 18.3763 7.017 18.3761 6.6023 18.2044C6.1876 18.0326 5.85804 17.7032 5.686 17.2886L5.08343 15.8338C4.91183 15.4188 4.58245 15.0889 4.1677 14.9166L2.71338 14.3142C2.29852 14.1424 1.96887 13.8128 1.79689 13.398C1.62492 12.9832 1.62468 12.5171 1.79625 12.1021L2.39821 10.6488C2.5697 10.2337 2.56935 9.7675 2.39724 9.3527L1.79614 7.89728C1.71091 7.69171 1.66703 7.47136 1.66699 7.24882C1.66696 7.02628 1.71077 6.80592 1.79593 6.60032C1.88109 6.39472 2.00592 6.20792 2.1633 6.05059C2.32068 5.89325 2.50751 5.76847 2.71313 5.68338L4.16641 5.08139C4.58105 4.90994 4.91073 4.58096 5.08308 4.16667L5.68548 2.7123C5.85739 2.29725 6.18714 1.96749 6.60218 1.79557C7.01722 1.62365 7.48355 1.62365 7.89859 1.79557L9.35186 2.39756C9.76691 2.56905 10.2331 2.5687 10.6479 2.39658L12.1028 1.7965C12.5178 1.62468 12.984 1.62471 13.399 1.7966C13.8139 1.96849 14.1436 2.29815 14.3156 2.7131L14.9182 4.1679L14.918 4.16538Z"
                          stroke="#2E394B" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_5200_495">
                        <rect width="21" height="20" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </i>
                    <input type="text" class="flex-1" pInputText autocomplete="off"
                           [formControl]="resetForm.controls.confirmationCode"/>
                </span>
            </div>
            <kodit-error-info-required
              [formCtrl]="resetForm.controls.confirmationCode"
            ></kodit-error-info-required>
          </div>
          <div class="p-field p-mt-4">
            <label kodit-form-label>Nova lozinka</label>
            <div class="p-field">
                <span class="p-d-flex p-flex-row p-ai-center p-input-icon-left">
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                          <path
                            d="M14.6667 9.16667V6.66667C14.6667 4.36548 12.8012 2.5 10.5 2.5C8.19881 2.5 6.33333 4.36548 6.33333 6.66667V9.16667M7 17.5H14C15.4001 17.5 16.1002 17.5 16.635 17.2275C17.1054 16.9878 17.4878 16.6054 17.7275 16.135C18 15.6002 18 14.9001 18 13.5V13.1667C18 11.7665 18 11.0665 17.7275 10.5317C17.4878 10.0613 17.1054 9.67883 16.635 9.43915C16.1002 9.16667 15.4001 9.16667 14 9.16667H7C5.59987 9.16667 4.8998 9.16667 4.36502 9.43915C3.89462 9.67883 3.51217 10.0613 3.27248 10.5317C3 11.0665 3 11.7665 3 13.1667V13.5C3 14.9001 3 15.6002 3.27248 16.135C3.51217 16.6054 3.89462 16.9878 4.36502 17.2275C4.8998 17.5 5.59987 17.5 7 17.5Z"
                            stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </i>
                    <input type="password" class="flex-1" autocomplete="off" pInputText [formControl]="resetForm.controls.newPassword"/>
                </span>
            </div>
            <kodit-error-info-max-length
              [formCtrl]="resetForm.controls.newPassword"
            ></kodit-error-info-max-length>
            <kodit-error-info-min-length
              [formCtrl]="resetForm.controls.newPassword"
            ></kodit-error-info-min-length>
            <kodit-error-info-required
              [formCtrl]="resetForm.controls.newPassword"
            ></kodit-error-info-required>
          </div>
          <div class="p-field p-mt-4">
            <label kodit-form-label>Potvrdite lozinku</label>
            <div class="p-field">
                  <span class="p-d-flex p-flex-row p-ai-center p-input-icon-left">
                      <i>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <path
                              d="M14.6667 9.16667V6.66667C14.6667 4.36548 12.8012 2.5 10.5 2.5C8.19881 2.5 6.33333 4.36548 6.33333 6.66667V9.16667M7 17.5H14C15.4001 17.5 16.1002 17.5 16.635 17.2275C17.1054 16.9878 17.4878 16.6054 17.7275 16.135C18 15.6002 18 14.9001 18 13.5V13.1667C18 11.7665 18 11.0665 17.7275 10.5317C17.4878 10.0613 17.1054 9.67883 16.635 9.43915C16.1002 9.16667 15.4001 9.16667 14 9.16667H7C5.59987 9.16667 4.8998 9.16667 4.36502 9.43915C3.89462 9.67883 3.51217 10.0613 3.27248 10.5317C3 11.0665 3 11.7665 3 13.1667V13.5C3 14.9001 3 15.6002 3.27248 16.135C3.51217 16.6054 3.89462 16.9878 4.36502 17.2275C4.8998 17.5 5.59987 17.5 7 17.5Z"
                              stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </i>
                      <input type="password" class="flex-1" pInputText autocomplete="off"
                             [formControl]="resetForm.controls.newPasswordCheck"/>
                  </span>
            </div>
            <kodit-error-info-max-length
              [formCtrl]="resetForm.controls.newPasswordCheck"
            ></kodit-error-info-max-length>
            <kodit-error-info-min-length
              [formCtrl]="resetForm.controls.newPasswordCheck"
            ></kodit-error-info-min-length>
            <kodit-error-info-required
              [formCtrl]="resetForm.controls.newPasswordCheck"
            ></kodit-error-info-required>
            <small
              *ngIf="
                resetForm.errors?.passwordMatchValidator &&
                resetForm.controls.newPasswordCheck.touched &&
                resetForm.value.newPasswordCheck.length > 0
              "
              id="password-check-help2"
              class="p-invalid"
            >Lozinke se ne podudaraju.</small
            >
          </div>
          <div class="p-d-flex p-fluid">
            <button
              pButton
              (click)="resetPassword()"
              label="Resetuj lozinku"
              type="Submit"
              class="login-btn"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</kodit-blockable-div>
