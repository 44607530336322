<!-- RUBIKOVA KOCKA -->
<!-- <div class="wrapper">
  <div class="rubik-loader"></div>
</div> -->

<!-- TACKE I TEKST -->
<div class="wrapper p-d-flex p-jc-center p-ai-center">
  <div class="loading p-d-flex p-flex-column p-ai-center" *ngIf="!showError">
    <div class="p-text-left loading-text">
      Učitavanje kompanije '{{ tenantName }}'
    </div>
    <div>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <div *ngIf="showError" class="p-d-flex p-flex-column p-ai-center">
    <div>
      <h3>Došlo je do greške</h3>
    </div>
    <div>{{ errorMsg }}</div>
  </div>
</div>
