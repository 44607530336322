import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { replace } from '@kodit/core/shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-error-info-min',
  templateUrl: './error-info-min.component.html',
  styleUrls: ['./error-info-min.component.scss'],
})
export class ErrorInfoMinComponent implements OnInit, OnDestroy {
  /** Props */
  private _sub = new Subscription();
  initMsg: string = 'Najmanja vrednost može biti #MIN, a trenutno je #CURR';
  errorMsg: string;

  /** I/O */
  @Input() formCtrl: AbstractControl;

  constructor() {}

  ngOnInit(): void {
    this._setErrorMsg();

    this._sub = this.formCtrl.valueChanges.subscribe((newValue) => {
      this._setErrorMsg();
    });
  }

  private _setErrorMsg() {
    if (this.formCtrl.errors?.['min']) {
      this.errorMsg = replace(
        this.initMsg,
        '#CURR',
        this.formCtrl.errors?.['min']?.actual
      ).replace('#MIN', this.formCtrl.errors?.['min']?.min);
    }
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
