<div class="p-inputgroup">
  <span class="p-inputgroup-addon"><i class="fa fa-envelope"></i></span>
  <input
    pInputText
    id="email"
    type="email"
    [formControl]="formCtrl"
    (blur)="handleOnBlur()"
    (focusin)="handleOnBlur()"
  />
</div>
<kodit-error-info-max-length
  [formCtrl]="formCtrl"
></kodit-error-info-max-length>
<kodit-error-info-min-length
  [formCtrl]="formCtrl"
></kodit-error-info-min-length>
<kodit-error-info-required
  [formCtrl]="formCtrl"
></kodit-error-info-required>
<kodit-error-info-email 
  [formCtrl]="formCtrl">
</kodit-error-info-email>