import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { TipLagerTransakcije, TipTransakcijeDto } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-tip-lager-transakcije-dropdown',
  templateUrl: './tip-lager-transakcije-dropdown.component.html',
  styleUrls: ['./tip-lager-transakcije-dropdown.component.scss']
})
export class TipLagerTransakcijeDropdownComponent implements OnInit {

  /** Props */
  items: SelectItem[];

  /** I/O */
  @Input() tipCtrl: FormControl | AbstractControl;
  @Input() parent: any = 'body';
  @Output() onItemChange = new EventEmitter<TipLagerTransakcije>();

  constructor() { }

  ngOnInit(): void {
    this.items = [
      { label: 'Izaberite tip transakcije', value: TipLagerTransakcije.MANUELNI },
      { label: 'Po ulaznom računu', value: TipLagerTransakcije.PO_ULAZNOM_RACUNU },
      { label: 'Po izlaznom računu', value: TipLagerTransakcije.PO_IZLAZNOM_RACUNU },
      { label: 'Po povratnici', value: TipLagerTransakcije.PO_POVRATNICI },
      { label: 'Po predračunu', value: TipLagerTransakcije.PO_PREDRACUNU },
      { label: 'Po otpremnici', value: TipLagerTransakcije.PO_OTPREMNICI },
    ]
  }

   handleOnItemChange(event: any) {
    this.onItemChange.emit(event);
  }
}
