import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FilterChipDto,
  GetLagerTransakcijeTableQuery,
  IGetLagerTransakcijeTableQuery,
  LagerTransakcijeClient,
  LagerTransakcijeTableDto,
} from '@kodit/core/data-api';
import { LocationService } from '@kodit/core/services';
import { toCamelCase } from '@kodit/core/shared';
import { DynamicDialogService } from '@kodit/core/shared-ui';
import {
  DialogSize,
  DynamicDialogConfig,
  OnDynamicDialogInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';
import {
  ActionClass,
  ActionType,
  OnTableInit,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import * as printJS from 'print-js';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LagerTransakcijeFilterComponent } from '../lager-transakcije-filter/lager-transakcije-filter.component';
import { LagerTransakcijeFilterService } from '../lager-transakcije-filter/lager-transakcije-filter.service';
import { LagerTransakcijeFormComponent } from '../lager-transakcije-form/lager-transakcije-form.component';

@Component({
  selector: 'kodit-lager-transakcije-table',
  templateUrl: './lager-transakcije-table.component.html',
  styleUrls: ['./lager-transakcije-table.component.scss'],
})
export class LagerTransakcijeTableComponent
  implements OnInit, OnDestroy, OnTableInit, OnDynamicDialogInit {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Confings */
  tableConfig: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.MEDIUM_SMALL
  );

  /** Props */
  lagerTransakcijeTable: LagerTransakcijeTableDto[] = [];
  chipItems: FilterChipDto[] = [];
  predmetStavkeId: number;
  lagerId: number;

  @Input() showCardBlock: boolean = true;

  constructor(
    private _loaderService: LoaderService,
    private _client: LagerTransakcijeClient,
    private _route: ActivatedRoute,
    private _dialogService: DynamicDialogService,
    private _locationService: LocationService,
    private _filterService: LagerTransakcijeFilterService
  ) {}

  ngOnInit(): void {
    // get router data
    this._route.params.subscribe((data) => {
      if (data['id']) {
        this.predmetStavkeId = Number.parseInt(data['id']);
      }
    });
    this._load(new GetLagerTransakcijeTableQuery());
    this.setTableConfig();

    this._subs.add(
      this._filterService.getAdvancedFilterSubmitted.subscribe(
        (filterResult) => {
          if (filterResult?.shouldApplyFilter) {
            this.tableConfig.advancedFilter.data = filterResult.filterData;
            this._load(this.tableConfig.advancedFilter.data);
          }
        }
      )
    );
  }

  // nazivGrupe
  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Transakcije robe na lageru',
      rowsPerPage: 10,
      onChipRemove: (chips: FilterChipDto) => this._handleCihpRemove(chips),
      columns: [
        // {
        //   field: 'nazivGrupe',
        //   header: 'Magacin',
        //   type: 'text',
        //   //shouldDisplayByCondition: () => this._displayNazivMagacina(),
        // },
        {
          field: 'tipDto',
          subField: 'tipStr',
          header: 'Tip transakcije',
          type: 'badge',
          styleClass: 'tip-transakcije',
          styleClassField: 'tipBadgeStr',
        },
        {
          header: 'Račun',
          field: 'rutiranjeVezanogRacuna',
          subField: 'label',
          type: 'link',
          linkCallbackFunction: (itemIndex: number) => this._onInfo(itemIndex),
        },
        {
          field: 'rutiranjeStranke',
          subField: 'naziv',
          header: 'Stranka',
          type: 'link',
          linkCallbackFunction: (itemIndex: number) =>
            this._goToStranka(itemIndex),
        },
        {
          field: 'promena',
          header: 'Promena stanja',
          type: 'number',
        },
        {
          field: 'prodajnaCena',
          header: 'Prodajna cena i vrednost',
          type: 'currency',
          currencyAlphaCharField: 'valutaText',
          columns: [{ field: 'prodajnaVrednost', type: 'currency' }],
        },
        {
          field: 'nabavnaCena',
          header: 'Nabavna cena i vrednost',
          type: 'currency',
          currencyAlphaCharField: 'valutaText',
          columns: [{ field: 'nabavnaVrednost', type: 'currency' }],
        },
      ],
      headerActions: [
        {
          type: ActionType.CUSTOM,
          actionClass: ActionClass.OUTLINED,
          icon: 'fa-regular fa-print',
          isVisible: true,
          callback: () => {
            this._onPrint();
          },
        },
      ],
      advancedFilter: {
        component: LagerTransakcijeFilterComponent,
        data: new GetLagerTransakcijeTableQuery(),
      },
    });
  }

  // private _displayNazivMagacina() {
  //   const uniqueValues = new Set(
  //     this.lagerTransakcijeTable.map((v) => v.nazivGrupe)
  //   );
  //
  //   if (uniqueValues.size > 1) {
  //     console.log('duplicates found');
  //   }
  //   return uniqueValues.size > 1;
  // }

  openDialog(config: DynamicDialogConfig) {
    const ref = this._dialogService.open(LagerTransakcijeFormComponent, config);

    this._subs.add(
      ref.onClose
        .subscribe
        // (dto: ResultOfRobaCommandResponse) => {
        //   if (dto) {
        //     const idx = this.robaTable.findIndex(
        //       (x) => x.id === dto.data.robaTableDto.id
        //     );
        //     if (idx > -1) {
        //       // update
        //       this.robaTable[idx] = dto.data.robaTableDto;
        //     } else {
        //       // create
        //       this.robaTable.push(dto.data.robaTableDto);
        //     }
        //   }
        // }
        ()
    );
  }

  private _onPrint() {
    this._loaderService.setLoadingText = 'Generisanje pdf dokumenta u toku';
    this._loaderService.setShowLoader = true;
    this._subs.add(
      this._client
        .getLagerTransakcijePdf(this.predmetStavkeId)
        .pipe(
          finalize(() => {
            this._loaderService.reset();
          })
        )
        .subscribe((res) => {
          let fileURL = URL.createObjectURL(res.data);
          printJS(fileURL);
        })
    );
  }

  private _handleCihpRemove(removedChip: FilterChipDto) {
    this.tableConfig.advancedFilter.data[toCamelCase(removedChip.key)] =
      removedChip.defaultValue;
    this._load(this.tableConfig.advancedFilter.data);
  }

  private _goToStranka(itemIndex: number) {
    const raucn = this.lagerTransakcijeTable[itemIndex];
    this._locationService.routeToStrankaKartica(
      raucn.rutiranjeStranke.strankaId,
      raucn.rutiranjeStranke.tipStranke
    );
  }

  private _onInfo(itemIndex: number): void {
    const vezaniRacun = this.lagerTransakcijeTable[itemIndex]
      .rutiranjeVezanogRacuna;
    this._locationService.routeToRacunKartica(
      vezaniRacun.racunId,
      vezaniRacun.tip,
      vezaniRacun.vrsta
    );
  }

  private _load(request?: IGetLagerTransakcijeTableQuery) {
    request.predmetStavkeId = this.predmetStavkeId;
    this._subs.add(
      this._client
        .getForTable(request as GetLagerTransakcijeTableQuery)
        .subscribe((res) => {
          this.lagerTransakcijeTable = res.data.lagerTransakcijeTableDtoList;
          this.chipItems = res.data.activeFilters;
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
