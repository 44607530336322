import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CreateRacunFromSefRacunCommand,
  ICreateRacunFromSefRacunCommand,
  SefClient,
  TipRacuna,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService, SharedService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { CreateOdobrenRacunFormService } from './create-odobren-racun-form.service';
import { UnosBrojaOption } from '../../ui/unos-broja-option/unos-broja-option';
import { Validators } from '@angular/forms';

@Component({
  selector: 'kodit-create-odobren-racun-form',
  templateUrl: './create-odobren-racun-form.component.html',
  styleUrls: ['./create-odobren-racun-form.component.scss'],
})
export class CreateOdobrenRacunFormComponent implements OnInit, OnDestroy {
  /** Subscription */
  private _subs: Subscription = new Subscription();

  /** Form */
  form: FormGroupTypeSafe<ICreateRacunFromSefRacunCommand>;

  /** Props */
  brojRacuna: string;
  showUnosBrojaAutomatski: boolean = true;
  tipRacuna: TipRacuna;
  jeOdobravanje: boolean;

  constructor(
    private _client: SefClient,
    private _alertService: AlertService,
    public _dialogRef: DynamicDialogRef,
    public _dialogConfig: DynamicDialogConfig,
    private _formHelper: FormHelper,
    private _formService: CreateOdobrenRacunFormService,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    /** init form */
    this.form = this._formService.GetFormGroup(this._dialogConfig.data.sefIds);

    this.brojRacuna = this._dialogConfig.data?.brojRacuna;
    this.tipRacuna = this._dialogConfig.data.tipRacuna;
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );
  }
  private _save() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._sharedService.displayLoadingScreen(true, 'Kreiranje računa u toku...');
    this._subs.add(
      this._client
        .createRacunFromSefRacun(
          this.form.value as CreateRacunFromSefRacunCommand
        )
        .subscribe((res) => {
          if (!res.succeeded) {
            this._alertService.addWarnMsgs(res.messages);
          } else {
            this._alertService.addSuccessMsg(res.data);
          }
          this._dialogRef.close(true);
        })
    );
  }

  handleUnosBrojaOptionChanged(option: UnosBrojaOption) {
    this.showUnosBrojaAutomatski = option === UnosBrojaOption.AUTOMATSKI;

    if (option === UnosBrojaOption.MANUELNO) {
      this.form.controls.brojRacuna.setValidators([
        Validators.required,
        Validators.maxLength(128),
      ]);

      this.form.controls.praviloZaBrojRacuna.patchValue(null);
      this.form.controls.praviloZaBrojRacuna.clearValidators();
    } else {
      this.form.controls.praviloZaBrojRacuna.setValidators(
        Validators.nullValidator
      );

      this.form.controls.brojRacuna.patchValue(null);
      this.form.controls.brojRacuna.clearValidators();
    }
    this.form.controls.praviloZaBrojRacuna.updateValueAndValidity();
    this.form.controls.brojRacuna.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
