import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-number-input-field',
  templateUrl: './number-input-field.component.html',
  styleUrls: ['./number-input-field.component.scss'],
})
export class NumberInputFieldComponent implements OnInit {
  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string = 'number-input-field';
  @Input() formCtrlLabel: string = '';
  @Input() formCtrlTooltip: string = '';
  @Input() isVertial: boolean;

  @Input() suffix: string;
  @Input() prefix: string;
  @Input() ctrlMaxLength: number;
  @Input() ctrlMinValue: number;
  @Input() ctrlUseGrouping: boolean = true;
  @Input() ctrlOnlyIntegers: boolean = false;
  @Output() onInputLeft = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  inputLeft(event: any) {
    this.onInputLeft.emit();
  }
}
