import { Component, Input, OnInit } from '@angular/core';
import { IMobilniTelefonDto } from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';

@Component({
  selector: 'kodit-mobilni-telefon-input',
  templateUrl: './mobilni-telefon-input.component.html',
  styleUrls: ['./mobilni-telefon-input.component.scss'],
})
export class MobilniTelefonInputComponent implements OnInit {
  /** Props */
  pozivniBroj: string = '+381';

  /** I/O */
  @Input() mobilniFormGroup: FormGroupTypeSafe<IMobilniTelefonDto>;
  @Input() formCtrlId: string;
  @Input() formCtrlDisabled: boolean;

  constructor() {}

  ngOnInit(): void {}
}
