import { SERBIA_CURRENCY_ALPHA_CHAR } from './constants';

export function isEmptyCheck(value: any) {
  return (
    value == null || // NULL value
    value == undefined || // undefined
    value == 'undefined' || // undefined
    value.length == 0 || // Array is empty
    value == EMPTY_GUID || // Guid empty
    (value instanceof Date &&
      !isNaN(value.valueOf()) && // Validate DateTime value and check min-max value
      (value <= new Date(1753, 1, 1) || // SQL DateTime minimum value
        value >= new Date(9999, 12, 31, 23, 59, 59, 999))) // SQL DateTime maximum value
  );
}

export function convertCurrencyStringToDecimal(value: string) {
  return value.includes(SERBIA_CURRENCY_ALPHA_CHAR)
    ? Number.parseFloat(
        value
          .replace(SERBIA_CURRENCY_ALPHA_CHAR, '')
          .replace('.', '')
          .replace(',', '.')
      )
    : Number.parseFloat(value);
}

export function stringIsNullOrEmpty(str: string | null | undefined): boolean {
  return !str || str.length === 0;
}

export function byteSizeConversion(value: number, currentUnit: string, targetUnit: string): number {
  const units: { [key: string]: number } = {
    bytes: 1,
    KB: Math.pow(1024, 1),
    MB: Math.pow(1024, 2),
    GB: Math.pow(1024, 3),
    TB: Math.pow(1024, 4),
    // Add more units as needed
  };

  if (!units[currentUnit] || !units[targetUnit]) {
    throw new Error('Invalid current or target unit');
  }

  const valueInBytes = value * units[currentUnit];
  const targetValue = valueInBytes / units[targetUnit];

  return targetValue;
}

export function isEmailValid(email: string): boolean {
  const regularExpression = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return regularExpression.test(String(email).toLowerCase());
}

export function areArraysEqual(current: any[], previous: any[]) {
  if (current && !previous) return false;
  if (!current && previous) return false;
  if (!current && !previous) return true;
  if (current.length !== previous.length) return false;
  for (let i = 0; i < current.length; ++i) {
    if (current[i].id !== previous[i].id) {
      return false;
    }
  }
  return true;
}

export function sumArrayByProperty(items: any[], property: any) {
  return items.reduce((a, b) => {
    return a + b[property];
  }, 0);
}

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

// use a method
export const getYesterday = (dateOnly = false) => {
  let d = new Date();
  d.setDate(d.getDate() - 1);
  return dateOnly ? new Date(d.toDateString()) : d;
};

// Helper
export const stringIsNumber = (value: string) => isNaN(Number(value)) === false;

export function enumToStringArray(enumToConvert: any): string[] {
  return Object.keys(enumToConvert)
    .filter(stringIsNumber)
    .map((key) => enumToConvert[key]);
}

export function enumToNumberArray(enumToConvert: any): number[] {
  const resString = Object.keys(enumToConvert).filter((v) => !isNaN(Number(v)));
  let res: number[] = new Array(resString.length);
  resString.forEach((r, i) => {
    res[i] = Number.parseInt(r);
  });
  return res;
}

export function toCamelCase(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export enum StopaPdv {
  BEZ_PDV = 0,
  POSEBNA_POLJOPRIVREDNO_GAZDINSTVO = 8,
  POSEBNA = 10,
  OSNOVNA = 20,
}
