import { Injectable } from '@angular/core';
import {
  IDokumentForPdvDto,
  IPdvEvidencijaPojedinacna39Dto,
  IStrankaForPdvDto,
  PdvEvidencijaOsnovOdabiraDokumenta,
  PdvEvidencijaStatus,
  PdvEvidencijaTipDokumenta,
  PdvEvidencijaTipPovezaneFakture,
  PdvEvidencijaTipPovezanogInternog,
  PdvPeriod,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { FormBuilderTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import {
  AbstractControl,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  Pdv39Form,
  PdvEvidencijaPojedinacnaService,
} from './pdv-evidencija-pojedinacna.service';
import { VezaniRacunFormService } from '@kodit/core/form-definitions';

@Injectable({
  providedIn: 'root',
})
export class PdvEvidencijaPojedinacnaForm39Service {
  /* Props */
  private _form!: Pdv39Form;
  private _isInitialized: boolean;

  private osnovicaGroups: OsnovicaGroup[] = [];

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _service: PdvEvidencijaPojedinacnaService,
    private _vezaniRacunFS: VezaniRacunFormService
  ) {
    this._isInitialized = false;
  }

  getForm(
    model: IPdvEvidencijaPojedinacna39Dto,
    shouldDisableControls: boolean
  ): Pdv39Form {
    if (!this._isInitialized) {
      this._init(model, shouldDisableControls);
      this._isInitialized = true;
    }
    this._service.setPdv39Form = this._form;
    return this._form;
  }

  initOsnovicaGroups() {
    this.osnovicaGroups = [];
    this.osnovicaGroups.push(
      // S10
      {
        opis: this._form!.controls!.opisPrometa10!,
        osnovica: this._form!.controls!.osnovica10!,
        obracunatiPdv: this._form!.controls!.obracunatPdv10!,
      },
      // S20
      {
        opis: this._form!.controls!.opisPrometa20!,
        osnovica: this._form!.controls!.osnovica20!,
        obracunatiPdv: this._form!.controls!.obracunatPdv20!,
      },
      // AE10
      {
        opis: this._form!.controls!.opisPrometaAE10!,
        osnovica: this._form!.controls!.osnovicaAE10!,
        obracunatiPdv: this._form!.controls!.obracunatPdvAE10!,
      },
      // AE20
      {
        opis: this._form!.controls!.opisPrometaAE20!,
        osnovica: this._form!.controls!.osnovicaAE20!,
        obracunatiPdv: this._form!.controls!.obracunatPdvAE20!,
      },
      // AE10 OBJ
      {
        opis: this._form!.controls!.opisPrometaAE10Obj!,
        osnovica: this._form!.controls!.osnovicaAE10Obj!,
        obracunatiPdv: this._form!.controls!.obracunatPdvAE10Obj!,
      },
      // AE 20 OBJ
      {
        opis: this._form!.controls!.opisPrometaAE20Obj!,
        osnovica: this._form!.controls!.osnovicaAE20Obj!,
        obracunatiPdv: this._form!.controls!.obracunatPdvAE20Obj!,
      },
      // AE 10 BN
      {
        opis: this._form!.controls!.opisPrometaAE10BN!,
        osnovica: this._form!.controls!.osnovicaAE10BN!,
        obracunatiPdv: this._form!.controls!.obracunatPdvAE10BN!,
      },
      // AE 20 BN
      {
        opis: this._form!.controls!.opisPrometaAE20BN!,
        osnovica: this._form!.controls!.osnovicaAE20BN!,
        obracunatiPdv: this._form!.controls!.obracunatPdvAE20BN!,
      },
      // AE 10 BN OBJ
      {
        opis: this._form!.controls!.opisPrometaAE10ObjBN!,
        osnovica: this._form!.controls!.osnovicaAE10ObjBN!,
        obracunatiPdv: this._form!.controls!.obracunatPdvAE10ObjBN!,
      },
      // AE 20 BN OBJ
      {
        opis: this._form!.controls!.opisPrometaAE20ObjBN!,
        osnovica: this._form!.controls!.osnovicaAE20ObjBN!,
        obracunatiPdv: this._form!.controls!.obracunatPdvAE20ObjBN!,
      }
    );
  }

  areOsnovicaGroupsValid(): boolean {
    return this.osnovicaGroups.some(
      (x) => x.opis.value && x.osnovica.value && x.obracunatiPdv.value
    );
  }

  get jeEdit(): boolean {
    if (!this._form) {
      return false;
    }
    return this._form!.value!.id! > 0;
  }

  private _init(
    model: IPdvEvidencijaPojedinacna39Dto,
    shouldDisableComponents: boolean
  ) {
    this._form = this._fb.group<IPdvEvidencijaPojedinacna39Dto>({
      broj: new FormControl(
        {
          value: model?.broj,
          disabled: shouldDisableComponents,
        },
        {
          validators: [Validators.required, Validators.maxLength(32)],
        }
      ),
      tip: new FormControl(model?.tip ?? PdvEvidencijaTipDokumenta.FAKTURA),
      osnovOdabiraDokumenta: new FormControl(
        model?.osnovOdabiraDokumenta ??
          PdvEvidencijaOsnovOdabiraDokumenta.PROMET
      ),
      tipPovezanogInternog: new FormControl(
        model?.tipPovezanogInternog ??
          PdvEvidencijaTipPovezanogInternog.INTERNI_RACUN_ZA_PROMET
      ),
      tipPovezaneFakture: new FormControl(
        model?.tipPovezaneFakture ?? PdvEvidencijaTipPovezaneFakture.FAKTURA
      ),
      datumEvidentiranja: new FormControl(
        model?.datumEvidentiranja ?? new Date()
      ),
      vremenskiPeriodPocetak: new FormControl(model?.vremenskiPeriodPocetak),
      vremenskiPeriodZavrsetak: new FormControl(
        model?.vremenskiPeriodZavrsetak
      ),
      povezanaFaktura:
        !model || model.smer === VrstaRacuna.IZLAZNI
          ? this._getDokumentFormGroup(model?.povezanaFaktura)
          : undefined,
      brojDokumentaNabavka:
        model && model.smer === VrstaRacuna.ULAZNI
          ? new FormControl(model?.brojDokumentaNabavka, {
              validators: [Validators.maxLength(40), Validators.required],
            })
          : undefined,
      opisPrometa10: new FormControl(model?.opisPrometa10, {
        validators: [Validators.maxLength(200)],
      }),
      osnovica10: new FormControl(model?.osnovica10),
      obracunatPdv10: new FormControl(model?.obracunatPdv10),

      opisPrometa20: new FormControl(model?.opisPrometa20, {
        validators: [Validators.maxLength(200)],
      }),
      osnovica20: new FormControl(model?.osnovica20),
      obracunatPdv20: new FormControl(model?.obracunatPdv20),
      obracunatPdv: new FormControl(model?.obracunatPdv),
      opisPrometaAE10: new FormControl(model?.opisPrometaAE10),
      osnovicaAE10: new FormControl(model?.osnovicaAE10),
      obracunatPdvAE10: new FormControl(model?.obracunatPdvAE10),
      opisPrometaAE20: new FormControl(model?.opisPrometaAE20),
      osnovicaAE20: new FormControl(model?.osnovicaAE20),
      obracunatPdvAE20: new FormControl(model?.obracunatPdvAE20),
      opisPrometaAE10Obj: new FormControl(model?.opisPrometaAE10Obj),
      osnovicaAE10Obj: new FormControl(model?.osnovicaAE10Obj),
      obracunatPdvAE10Obj: new FormControl(model?.obracunatPdvAE10Obj),
      opisPrometaAE20Obj: new FormControl(model?.opisPrometaAE20Obj),
      osnovicaAE20Obj: new FormControl(model?.osnovicaAE20Obj),
      obracunatPdvAE20Obj: new FormControl(model?.obracunatPdvAE20Obj),
      opisPrometaAE10BN: new FormControl(model?.opisPrometaAE10BN),
      osnovicaAE10BN: new FormControl(model?.osnovicaAE10BN),
      obracunatPdvAE10BN: new FormControl(model?.obracunatPdvAE10BN),
      opisPrometaAE20BN: new FormControl(model?.opisPrometaAE20BN),
      osnovicaAE20BN: new FormControl(model?.osnovicaAE20BN),
      obracunatPdvAE20BN: new FormControl(model?.obracunatPdvAE20BN),
      opisPrometaAE10ObjBN: new FormControl(model?.opisPrometaAE10ObjBN),
      osnovicaAE10ObjBN: new FormControl(model?.osnovicaAE10ObjBN),
      obracunatPdvAE10ObjBN: new FormControl(model?.obracunatPdvAE10ObjBN),
      opisPrometaAE20ObjBN: new FormControl(model?.opisPrometaAE20ObjBN),
      osnovicaAE20ObjBN: new FormControl(model?.osnovicaAE20ObjBN),
      obracunatPdvAE20ObjBN: new FormControl(model?.obracunatPdvAE20ObjBN),
      id: new FormControl(model?.id),
      status: new FormControl(model?.status ?? PdvEvidencijaStatus.U_PRIPREMI),
      smer: new FormControl(model?.smer ?? VrstaRacuna.IZLAZNI),
      datumAvansa: new FormControl(model?.datumAvansa),
      period: new FormControl(model?.period ?? PdvPeriod.JANUAR),
      stranka: this._getStrankaFormGroup(model?.stranka),
      osnovAvansnogPlacanja: new FormControl(model?.osnovAvansnogPlacanja, {
        validators: [Validators.maxLength(200)],
      }),
      ukupanIznosNaknade: new FormControl(model?.ukupanIznosNaknade),
      povezaniRacuni: this._vezaniRacunFS.getFormArray(model?.povezaniRacuni),
      povezaniVanSefa: this.getAvansniVanSefaFormArray(model?.povezaniVanSefa),
      sefId: new FormControl(model?.sefId ?? 0),
      godina: new FormControl(model?.godina ?? new Date().getFullYear(), {
        validators: [Validators.required],
      }),
    });

    this.initOsnovicaGroups();

    this._form.controls.stranka!.get('pibOrJbkjs')!.disable();

    this._service.setIsIsporuka =
      this._form.value.povezanaFaktura?.smer === VrstaRacuna.IZLAZNI;
    this._service.setTipDokumentaSubject = this._form.value.tip!;
    this._service.setOsnovOdabira = this._form.value.osnovOdabiraDokumenta!;
  }

  private _getStrankaFormGroup(model?: IStrankaForPdvDto) {
    return this._fb.group<IStrankaForPdvDto>({
      naziv: new FormControl(model?.naziv),
      id: new FormControl(model?.id),
      pibOrJbkjs: new FormControl(model?.pibOrJbkjs, {}),
    });
  }

  private _getDokumentFormGroup(model?: IDokumentForPdvDto) {
    return this._fb.group<IDokumentForPdvDto>({
      id: new FormControl(model?.id),
      broj: new FormControl(model?.broj),
      smer: new FormControl(model?.smer ?? VrstaRacuna.IZLAZNI),
      stranka: this._getStrankaFormGroup(model?.stranka),
      tip: new FormControl(model?.tip),
      jeInostranaFaktura: new FormControl(model?.jeInostranaFaktura ?? false),
    });
  }

  getAvansniVanSefaFormArray(racuni?: string[]): FormArray {
    if (!racuni || racuni.length === 0) {
      return this._fb.array([]);
    }

    let result: FormArray = this._fb.array([]);
    racuni.forEach((s) => {
      result.push(new FormControl(s));
    });
    return result;
  }

  resetBNProps() {
    // ae10 bez naknade, osim obj
    this._form?.controls.opisPrometaAE10BN?.patchValue('');
    this._form?.controls.osnovicaAE10BN?.patchValue(null);
    this._form?.controls.obracunatPdvAE10BN?.patchValue(null);

    // ae20 bez naknade, osim obj
    this._form?.controls.opisPrometaAE20BN?.patchValue('');
    this._form?.controls.osnovicaAE20BN?.patchValue(null);
    this._form?.controls.obracunatPdvAE20BN?.patchValue(null);

    // ae10 bez naknade, sa obj
    this._form?.controls.opisPrometaAE10ObjBN?.patchValue('');
    this._form?.controls.osnovicaAE10ObjBN?.patchValue(null);
    this._form?.controls.obracunatPdvAE10ObjBN?.patchValue(null);

    // ae20 bez naknade, sa obj
    this._form?.controls.opisPrometaAE20ObjBN?.patchValue('');
    this._form?.controls.osnovicaAE20ObjBN?.patchValue(null);
    this._form?.controls.obracunatPdvAE20ObjBN?.patchValue(null);
  }

  resetOnIsporukaNabavkaChange(isIsporuka: boolean) {
    this.resetDokumentAndStranka();
    this.resetGlavniDokumentForm(isIsporuka);
    if (!isIsporuka) {
      this.resetSProps();
      return;
    }

    this.resetAEProps();
  }

  resetDokumentAndStranka() {
    this._form?.controls.povezanaFaktura?.reset();
    this._form?.controls.stranka?.reset();
  }

  resetDatumAvansa() {
    this._form?.controls.datumAvansa?.reset();
  }

  resetVezaneRacune(){
    (this._form?.controls.povezaniRacuni as FormArray).clear();
    (this._form?.controls.povezaniVanSefa as FormArray).clear();
  }

  resetForm() {
    this._service.resetForm39();
    this._isInitialized = false;
    this._init({}, false);
  }

  private resetGlavniDokumentForm(isIsporuka: boolean) {
    if (isIsporuka) {
      this._form!.setControl('povezanaFaktura', this._getDokumentFormGroup());
      this._form?.setControl('brojDokumentaNabavka', undefined as any);
      return;
    }

    this._form?.setControl(
      'brojDokumentaNabavka',
      new FormControl(null, {
        validators: [Validators.maxLength(40), Validators.required],
      })
    );
    this._form?.setControl('povezanaFaktura', undefined as any);
  }

  resetAEProps() {
    // ae10, osim obj
    this._form?.controls.opisPrometaAE10?.patchValue('');
    this._form?.controls.osnovicaAE10?.patchValue(null);
    this._form?.controls.obracunatPdvAE10?.patchValue(null);

    // ae20 osim obj
    this._form?.controls.opisPrometaAE20?.patchValue('');
    this._form?.controls.osnovicaAE20?.patchValue(null);
    this._form?.controls.obracunatPdvAE20?.patchValue(null);

    // ae10 sa obj
    this._form?.controls.opisPrometaAE10Obj?.patchValue('');
    this._form?.controls.osnovicaAE10Obj?.patchValue(null);
    this._form?.controls.obracunatPdvAE10Obj?.patchValue(null);

    // ae20 sa obj
    this._form?.controls.opisPrometaAE20Obj?.patchValue('');
    this._form?.controls.osnovicaAE20Obj?.patchValue(null);
    this._form?.controls.obracunatPdvAE20Obj?.patchValue(null);

    this.resetBNProps();
  }

   resetSProps() {
    // S10
    this._form?.controls.opisPrometa10?.patchValue('');
    this._form?.controls.osnovica10?.patchValue(null);
    this._form?.controls.obracunatPdv10?.patchValue(null);

    // S20
    this._form?.controls.opisPrometa20?.patchValue('');
    this._form?.controls.osnovica20?.patchValue(null);
    this._form?.controls.obracunatPdv20?.patchValue(null);
  }

   _resetDokumentForm(isIsporuka: boolean) {
    this._form?.controls.povezanaFaktura?.patchValue(null);
  }
}

export interface OsnovicaGroup {
  opis: AbstractControl;
  osnovica: AbstractControl;
  obracunatiPdv: AbstractControl;
}
