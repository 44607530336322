<div>
  <p-dataView
    [value]="dokumentList"
    [paginator]="false"
    [rows]="maxRows"
    layout="list"
  >
    <ng-template let-item pTemplate="listItem" let-rowIndex="rowIndex">
      <div class="p-d-flex p-jc-between p-p-2 item">
        <div>
          <i class="fa-regular fa-paperclip"></i>
          <span class="p-ml-2">{{item.fileName}}</span>
        </div>
        <div>
          <button
            type="button"
            pButton
            pRipple
            icon="fa-regular fa-eye"
            class="p-button-text p-ai-baseline"
            (click)="handlePreview(item.fileUrl)"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-dataView>
</div>
