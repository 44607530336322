<form (keydown)="keyDownFunction($event)" [formGroup]="form" *ngIf="form">
  <div class="p-fluid">
    <!-- Naziv -->
    <kodit-text-input-field
      [formCtrl]="form.controls.naziv"
      formCtrlId="naziv"
      formCtrlLabel="Naziv"
    ></kodit-text-input-field>

    <kodit-field fieldLabel="Prefiks">
      <kodit-text-input [formCtrl]="form.controls.prefiks" formCtrlId="prefiks">
      </kodit-text-input>
    </kodit-field>

    <kodit-field fieldLabel="Brojač">
      <kodit-number-input
        [ctrlOnlyIntegers]="true"
        [formCtrl]="form.controls.brojac"
        formCtrlId="brojac"
      >
      </kodit-number-input>
    </kodit-field>
  </div>

  <!-- <kodit-checkbox
    [formCtrl]="form.controls.isDefault"
    labelText="Postaviti kao osnovno pravilo"
  ></kodit-checkbox> -->
</form>

<div class="loading-card" *ngIf="!form">
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
</div>
