import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  DelovodniDokumentPraviloForm,
  DelovodnikService,
} from '../delovodnik-service';
import { FormHelper } from '@kodit/core/form-definitions';
import {
  CreatePraviloZaDelovodniDokumentCommand,
  DelovodniciClient,
  UpdatePraviloZaDelovodnikDokumentCommand,
} from '@kodit/core/data-api';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { AlertService } from '@kodit/core/services';

@Component({
  selector: 'kodit-delovodni-dokument-pravilo-form',
  templateUrl: './delovodni-dokument-pravilo-form.component.html',
  styleUrls: ['./delovodni-dokument-pravilo-form.component.scss'],
})
export class DelovodniDokumentPraviloFormComponent implements OnInit {
  /** Subs */
  private _subs: Subscription = new Subscription();
  /** Forms */
  form: DelovodniDokumentPraviloForm;

  constructor(
    private _delovodnikService: DelovodnikService,
    private _formHelper: FormHelper,
    private _client: DelovodniciClient,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    if (!this._dialogConfig.data.id) {
      this.form = this._delovodnikService.getDelovodniDokumentPraviloForm();
    } else {
      this._subs.add(
        this._client
          .getPraviloZaBroj(this._dialogConfig.data.id)
          .subscribe((res) => {
            if (res.succeeded) {
              this.form = this._delovodnikService.getDelovodniDokumentPraviloForm(
                res.data
              );
            }
          })
      );
    }

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this._save();
    }
  }

  private _save() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    if (!this._dialogConfig.data.id) {
      this._subs.add(
        this._client
          .createPravilo(
            this.form.value as CreatePraviloZaDelovodniDokumentCommand
          )
          .subscribe((result) => {
            if (result.succeeded) {
              this._alertService.addSuccessMsg(result.messages[0]);
            } else {
              this._alertService.addWarnMsg(result.messages[0]);
            }
            this._dialogRef.close(result);
          })
      );
    } else {
      this._subs.add(
        this._client
          .updatePravilo(
            this.form.value as UpdatePraviloZaDelovodnikDokumentCommand
          )
          .subscribe((result) => {
            if (result.succeeded) {
              this._alertService.addSuccessMsg(result.messages[0]);
              this._dialogRef.close(result);
            } else {
              this._alertService.addWarnMsg(result.messages[0]);
            }
          })
      );
    }
  }
}
