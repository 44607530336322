import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IAdresaDto, IDrzavaDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AdresaFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  /**
   * Pravi FormGroup od prosledene adresaDto
   * @param model Adresa dto objekat za pretvaranje u FormGroup
   * @returns FormGroup od prosledene adresaDto
   */
  public GetAdresaFormGroup(model?: IAdresaDto): FormGroupTypeSafe<IAdresaDto> {
    return this._fb.group<IAdresaDto>({
      id: new FormControl(model?.id),
      ulicaIBroj: new FormControl(model?.ulicaIBroj),
      grad: new FormControl(model?.grad, {
        validators: [Validators.required],
      }),
      region: new FormControl(model?.region),
      opstina: new FormControl(model?.opstina),
      postanskiBroj: new FormControl(model?.postanskiBroj),
      drzavaId: new FormControl(model?.drzavaId),
      punaAdresa: new FormControl(model?.punaAdresa),
    });
  }

  public GetDrzavaFormGroup(
    model?: IDrzavaDto
  ): FormGroupTypeSafe<IDrzavaDto> {
    return this._fb.group<IDrzavaDto>({
      id: new FormControl(model?.id),
      kodDrzave: new FormControl(model?.kodDrzave),
      kodDrzaveAlfaChar2: new FormControl(model?.kodDrzaveAlfaChar2),
      kodDrzaveAlfaChar3: new FormControl(model?.kodDrzaveAlfaChar3),
      kodDrzaveNumChar: new FormControl(model?.kodDrzaveNumChar),
      nazivEng: new FormControl(model?.nazivEng),
      nazivSrbCir: new FormControl(model?.nazivSrbCir),
      nazivSrbLat: new FormControl(model?.nazivSrbLat),
    });
  }
}
