<kodit-dropdown
  [formCtrlId]="formCtrlId"
  [options]="options"
  [formCtrl]="formCtrl"
>
<ng-template #selectedItem let-obj>
    <div class="p-text-left">{{ obj.naziv }}</div>
  </ng-template>

  <ng-template #item let-obj>
    <ng-container>
      <div>{{ obj.naziv }}</div>
    </ng-container>
  </ng-template>
</kodit-dropdown>
