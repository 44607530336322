<div
  card
  class="k-card promet-card p-d-flex p-jc-between p-ai-center p-flex-wrap"
  style="height: 76px"
>
  <div class="p-d-flex p-flex-column">
    <div class="p-pr-2 p-label p-mb-2">
      Broj faktura van valute
      <kodit-helper-icon
        class="p-ml-2"
        helperText="Odnosi se na fakture u statusu POSLATO i PRIHVACENO"
      ></kodit-helper-icon>
    </div>
    <div class="p-text-left p-mb-2 summary-value">
      {{ racuniSaldo?.brojFakturaVanValute }}
    </div>
    <div></div>
  </div>
  <div class="p-d-flex p-flex-column">
    <div class="p-pr-2 p-label p-mb-2">Iznos:</div>
    <div class="p-text-left p-mb-2 summary-value">
      {{ racuniSaldo?.iznosVanValute | currencyNumber }}
    </div>
  </div>
  <div *ngIf="displayPodsetnikZaPlacanje" class="p-d-flex p-flex-row p-mb-2">
    <button
      (click)="onPosaljiPodsetnik()"
      [disabled]="racuniSaldo?.brojFakturaVanValute < 1"
      class="p-button p-button-outlined"
      icon="far fa-paper-plane"
      label="Pošalji podsetnik"
      pButton
      type="button"
    ></button>
  </div>
</div>
