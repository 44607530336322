import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BankaDto, IBankaDto, SharedClient } from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { BankaService } from '../../banka.service';

@Component({
  selector: 'kodit-banka-dropdown',
  templateUrl: './banka-dropdown.component.html',
  styleUrls: ['./banka-dropdown.component.scss'],
})
export class BankaDropdownComponent implements OnInit, OnDestroy {
  /** Subs */
  private _getSub: Subscription = new Subscription();

  /** Props */
  items: string[];

  /** I/O */
  @Input() bankaFormGroup: FormGroupTypeSafe<IBankaDto>;
  @Input() availableFilter: BankaDto[];
  @Input() parent: any = 'body';
  @Output() onItemChange = new EventEmitter<any>();

  constructor(
    private _client: SharedClient,
    private _bankaService: BankaService
  ) {}

  ngOnInit(): void {
    if (this.availableFilter) {
      this.items = this.availableFilter?.map((x) => x.naziv);
    } else {
      this._getSub = this._client.getBanke().subscribe((result) => {
        this.availableFilter = result.data.bankeDtoList;
        this.items = this.availableFilter?.map((x) => x.naziv);
      });
    }

    this.items?.splice(0, 0, 'Sve banke');
  }

  handleOnItemChange(event: any) {
    const banka: BankaDto =
      this.availableFilter?.find((x) => x.naziv === event.value) ?? null;

    this.bankaFormGroup?.patchValue(banka);
    if (this.onItemChange) {
      this.onItemChange.emit(banka);
    }
  }

  ngOnDestroy() {
    this._getSub.unsubscribe();
  }
}
