import {Component, OnInit} from '@angular/core';
import {DynamicDialogRef} from '@kodit/core/shared-ui';
import {
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {Subscription} from 'rxjs';
import {FiskalniRacunFormService} from "../../fiskalni-racun-form.service";
import {FiskalniRacunService} from "../../fiskalni-racun.service";
import {FiskalniRacuniClient, IJournalOptionDto} from "@kodit/core/data-api";

@Component({
  selector: 'kodit-reklamni-blok-fiskalnog-racuna-dialog',
  templateUrl: './reklamni-blok-fiskalnog-racuna-dialog.component.html',
  styleUrls: ['./reklamni-blok-fiskalnog-racuna-dialog.component.scss'],
})
export class ReklamniBlokFiskalnogRacunaDialogComponent implements OnInit {
  private _subs: Subscription = new Subscription();

  form!: FormGroupTypeSafe<IJournalOptionDto>;

  constructor(
    private _dialogRef: DynamicDialogRef,
    private _fiskalniFS: FiskalniRacunFormService,
    private _fiskalniService: FiskalniRacunService,
    private _client: FiskalniRacuniClient
  ) {
  }

  ngOnInit(): void {

    this.form = this._fiskalniFS.GetJournalOptions(this._fiskalniService.getFormObject?.controls.journalOption?.value);

    this._client.getPredefinisaniTekst().subscribe((response) => {
      if (response.succeeded && response.data) {
        this.form.patchValue({
          preamble: response.data.textBefore,
          message: response.data.textAfter
        });
      }
      this._fiskalniService.setPredefinisaniTekst = this.form.value;
    });

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._fiskalniService.getFormObject.controls.journalOption?.patchValue(this.form.value);
        this._fiskalniService.setPredefinisaniTekst = this.form.value;
        this._dialogRef.close(true);
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
