<kodit-loader
  [content]="content"
  [showLoader]="showLoader"
  loadingText="Registracija kompanije je u toku"
></kodit-loader>

<kodit-blockable-div #content>
  <div class="p-d-flex p-flex-column wrapper background">
    <div class="p-d-flex p-ai-center p-jc-center header-register">
      <div class="p-d-flex p-flex-row">
        <div
          class="p-d-flex p-flex-column layout p-flex-md-row p-jc-between p-ai-center flex-1"
        >
          <div style="cursor: pointer" (click)="openSite()">
            <svg
              width="166"
              height="36"
              viewBox="0 0 166 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41.9995 17.9998L25.9997 33.9996L9.99997 17.9998L22.5856 5.41408L29.4139 5.41408L41.9995 17.9998Z"
                stroke="#2E394B"
                stroke-width="2.8284"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.0001 6.00018L17.9998 3.99988L4.00004 17.9998L17.9998 31.9996L19.9994 30L21.9994 32L18.9998 34.9996C18.4476 35.5519 17.5521 35.5519 16.9999 34.9996L1.00008 18.9997C0.447802 18.4475 0.447802 17.552 1.00008 16.9998L16.9999 0.999902C17.5521 0.447622 18.4475 0.447622 18.9998 0.999902L22.0901 4.09018C21.9024 4.16039 21.7299 4.27035 21.5855 4.41478L20.0001 6.00018Z"
                fill="#2E394B"
              />
              <path
                d="M20.4444 22.4442C20.199 22.1987 20.199 21.8008 20.4444 21.5553L29.5554 12.4443C29.8009 12.1988 30.1988 12.1988 30.4443 12.4443L31.5554 13.5554C31.8009 13.8008 31.8009 14.1988 31.5554 14.4443L22.4444 23.5553C22.199 23.8008 21.801 23.8008 21.5555 23.5553L20.4444 22.4442Z"
                fill="#2E394B"
              />
              <rect
                width="8.48519"
                height="2.8284"
                rx="0.628532"
                transform="matrix(0.707105 -0.707109 0.707105 0.707109 16 17.9998)"
                fill="#2E394B"
              />
              <path
                d="M24.4444 26.4442C24.199 26.1987 24.199 25.8008 24.4444 25.5553L33.5554 16.4443C33.8009 16.1988 34.1988 16.1988 34.4443 16.4443L35.5554 17.5554C35.8009 17.8008 35.8009 18.1988 35.5554 18.4443L26.4444 27.5553C26.199 27.8008 25.801 27.8008 25.5555 27.5553L24.4444 26.4442Z"
                fill="#2E394B"
              />
              <path
                d="M21.1085 7.11412C20.863 6.86866 20.863 6.47069 21.1085 6.22524L23.1466 4.18713C23.2645 4.06925 23.4243 4.00303 23.591 4.00303L28.4036 4.00303C28.5703 4.00303 28.7302 4.06925 28.848 4.18713L30.8861 6.22524C31.1316 6.47069 31.1316 6.86866 30.8861 7.11412L26.4418 11.5585C26.1963 11.804 25.7983 11.804 25.5529 11.5585L21.1085 7.11412Z"
                fill="#2E394B"
              />
              <path
                d="M163.329 24.9999V23.6139C162.594 24.6639 161.481 25.2099 159.948 25.2099C157.617 25.2099 156.062 23.8449 156.062 21.7449C156.062 19.5609 157.827 18.3849 161.103 18.3849C161.733 18.3849 162.279 18.4269 162.972 18.5109V17.8389C162.972 16.5789 162.258 15.8439 161.04 15.8439C159.78 15.8439 159.024 16.5789 158.919 17.8389H156.294C156.462 15.2559 158.331 13.5759 161.04 13.5759C163.98 13.5759 165.701 15.1929 165.701 17.9439V24.9999H163.329ZM158.813 21.6609C158.813 22.6059 159.486 23.1939 160.578 23.1939C162.069 23.1939 162.972 22.3749 162.972 21.0729V20.2749C162.279 20.1699 161.796 20.1279 161.292 20.1279C159.633 20.1279 158.813 20.6529 158.813 21.6609Z"
                fill="#2E394B"
              />
              <path
                d="M154.539 11.1817C154.539 12.1687 153.741 12.9037 152.586 12.9037C151.431 12.9037 150.633 12.1687 150.633 11.1817C150.633 10.1527 151.431 9.41772 152.586 9.41772C153.741 9.41772 154.539 10.1527 154.539 11.1817ZM154.056 13.7857V24.9577C154.056 27.9817 152.418 29.2627 150.192 29.2627C149.667 29.2627 149.121 29.2207 148.617 29.0317V26.3227C148.932 26.4907 149.352 26.5747 149.835 26.5747C150.612 26.5747 151.158 26.1127 151.158 24.8737V13.7857H154.056Z"
                fill="#2E394B"
              />
              <path
                d="M149.018 11.1817C149.018 12.1687 148.22 12.9037 147.065 12.9037C145.931 12.9037 145.133 12.1687 145.133 11.1817C145.133 10.1527 145.931 9.41772 147.065 9.41772C148.22 9.41772 149.018 10.1527 149.018 11.1817ZM148.556 24.9997H145.637V13.7857H148.556V24.9997Z"
                fill="#2E394B"
              />
              <path
                d="M136.106 18.8889V24.9999H133.188V13.7859H135.875V15.3819C136.694 14.2689 137.849 13.5759 139.319 13.5759C141.566 13.5759 143.12 15.0459 143.12 17.7549V24.9999H140.222V18.7419C140.222 17.0829 139.571 16.1589 138.248 16.1589C137.093 16.1589 136.106 17.0829 136.106 18.8889Z"
                fill="#2E394B"
              />
              <path
                d="M128.305 24.9999V23.6139C127.57 24.6639 126.457 25.2099 124.924 25.2099C122.593 25.2099 121.039 23.8449 121.039 21.7449C121.039 19.5609 122.803 18.3849 126.079 18.3849C126.709 18.3849 127.255 18.4269 127.948 18.5109V17.8389C127.948 16.5789 127.234 15.8439 126.016 15.8439C124.756 15.8439 124 16.5789 123.895 17.8389H121.27C121.438 15.2559 123.307 13.5759 126.016 13.5759C128.956 13.5759 130.678 15.1929 130.678 17.9439V24.9999H128.305ZM123.79 21.6609C123.79 22.6059 124.462 23.1939 125.554 23.1939C127.045 23.1939 127.948 22.3749 127.948 21.0729V20.2749C127.255 20.1699 126.772 20.1279 126.268 20.1279C124.609 20.1279 123.79 20.6529 123.79 21.6609Z"
                fill="#2E394B"
              />
              <path
                d="M119.756 19.3929C119.756 22.7109 117.802 25.2099 114.778 25.2099C113.455 25.2099 112.321 24.6429 111.481 23.5509V29.0529H108.562V13.7859H111.25V15.4239C112.111 14.2269 113.35 13.5759 114.778 13.5759C117.802 13.5759 119.756 16.0959 119.756 19.3929ZM116.752 19.3929C116.752 17.2929 115.555 16.1589 114.127 16.1589C112.699 16.1589 111.502 17.2719 111.502 19.3929C111.502 21.5139 112.699 22.6059 114.127 22.6059C115.555 22.6059 116.752 21.5349 116.752 19.3929Z"
                fill="#2E394B"
              />
              <path
                d="M92.1612 24.9999H89.2422V13.7859H91.9302V15.3819C92.7492 14.2479 93.7992 13.5759 95.2902 13.5759C96.8232 13.5759 97.9992 14.2899 98.5662 15.5709C99.4482 14.3319 100.582 13.5759 102.262 13.5759C104.53 13.5759 106.063 15.0669 106.063 17.7549V24.9999H103.165V18.6789C103.165 17.1039 102.493 16.1589 101.212 16.1589C100.057 16.1589 99.1122 17.0619 99.1122 18.7419V24.9999H96.2142V18.6789C96.2142 17.1039 95.5422 16.1589 94.2612 16.1589C93.0852 16.1589 92.1612 17.0619 92.1612 18.7419V24.9999Z"
                fill="#2E394B"
              />
              <path
                d="M81.691 25.2099C78.436 25.2099 76 22.6479 76 19.3929C76 16.1169 78.436 13.5759 81.691 13.5759C84.946 13.5759 87.382 16.1169 87.382 19.3929C87.382 22.6479 84.946 25.2099 81.691 25.2099ZM81.691 22.6269C83.14 22.6269 84.379 21.4719 84.379 19.3929C84.379 17.3139 83.14 16.1799 81.691 16.1799C80.242 16.1799 79.003 17.3139 79.003 19.3929C79.003 21.4719 80.242 22.6269 81.691 22.6269Z"
                fill="#2E394B"
              />
              <path
                d="M66.9889 24.9996H63.7969V9.92163H66.9889V16.5576L72.4699 9.92163H76.1239L70.1389 17.1456L76.2919 24.9996H72.3649L66.9889 18.1116V24.9996Z"
                fill="#2E394B"
              />
              <path
                d="M56.2554 25.1889C52.8954 25.1889 50.6484 22.8579 50.6484 19.3509C50.6484 16.0119 52.9794 13.5759 56.2134 13.5759C59.7624 13.5759 62.1144 16.4529 61.5894 20.1069H53.6304C53.8194 21.9339 54.7014 22.9419 56.1924 22.9419C57.4734 22.9419 58.3344 22.3119 58.6704 21.1989H61.5684C60.9384 23.7399 58.9854 25.1889 56.2554 25.1889ZM56.1504 15.7179C54.7854 15.7179 53.9244 16.5999 53.6724 18.2589H58.5024C58.4184 16.7049 57.5364 15.7179 56.1504 15.7179Z"
                fill="#2E394B"
              />
            </svg>
          </div>
          <div class="p-d-flex p-flex-row">
            <div class="login-text">Već imate eKompanija nalog?</div>
            <div class="login-button" (click)="onLogin()">Prijavite se</div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-d-flex p-flex-column p-jc-center">
      <div class="p-d-flex p-jc-center p-flex-row">
        <div class="p-d-flex p-flex-column layout">
          <div class="p-d-flex flex-1 ">
            <kodit-registration-stepper
              class="stepper flex-1 p-mb-6"
            ></kodit-registration-stepper>
          </div>
        </div>
      </div>
    </div>
  </div>
</kodit-blockable-div>
