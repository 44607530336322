import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IPodaciUplateDevizniDto } from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';

@Injectable({
  providedIn: 'root',
})
export class PodaciUplateDevizniFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetPodaciUplateDevizniFormGroup(
    model: IPodaciUplateDevizniDto
  ): FormGroupTypeSafe<IPodaciUplateDevizniDto> {
    return this._fb.group<IPodaciUplateDevizniDto>({
      accountName: new FormControl(model?.accountName, {
        validators: [Validators.maxLength(256)],
      }),
      accountAddres: new FormControl(model?.accountAddres, {
        validators: [Validators.maxLength(256)],
      }),
      bank: new FormControl(model?.bank, {
        validators: [Validators.maxLength(128)],
      }),
      iban: new FormControl(model?.iban, {
        validators: [Validators.maxLength(33)],
      }),
      swift: new FormControl(model?.swift, {
        validators: [Validators.maxLength(50)],
      }),
      srednjiKursNaDan: new FormControl(model?.srednjiKursNaDan ?? 117),
    });
  }
}
