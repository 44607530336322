<div class="p-grid p-flex-column-reverse p-flex-lg-row">
  <div class="p-col-12" #col12Ref [hidden]="!showCol12">
    <ng-content select="[body-full]"> </ng-content>
  </div>
  <div class="p-col-12 p-lg-6" #col6Left [hidden]="!showCol6Left">
    <ng-content select="[body-1-of-2]"> </ng-content>
  </div>
  <div class="p-col-12 p-lg-6" #col6Right [hidden]="!showCol6Right">
    <ng-content select="[body-2-of-2]"> </ng-content>
  </div>
  <div class="p-col-12 p-lg-8" #col8Ref [hidden]="!showCol8">
    <ng-content select="[body-2-of-3]"> </ng-content>
  </div>
  <div class="p-col-12 p-md-12 p-lg-4" #col4Ref [hidden]="!showCol4">
    <ng-content select="[body-1-of-3]"> </ng-content>
  </div>
  <div
    class="p-col-12 p-md-12 p-lg-4"
    #col4ReverseRef
    [hidden]="!showCol4Reverse"
  >
    <ng-content select="[body-1-of-3-reverse]"> </ng-content>
  </div>
  <div class="p-col-12 p-lg-8" #col8ReverseRef [hidden]="!showCol8Reverse">
    <ng-content select="[body-2-of-3-reverse]"> </ng-content>
  </div>
</div>
