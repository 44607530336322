<!-- <div class="p-grid"> -->
<div class="p-field">
  <label *ngIf="showLabel" for="maticni-broj" kodit-form-label
    >Matični broj {{ control.errors?.required ? ' *' : '' }}</label
  >
  <p-inputMask
    id="maticni-broj"
    mask="99999999"
    placeholder="99999999"
    [formControl]="control"
    [ngClass]="
      (control.dirty || control.touched) && !control.valid ? 'p-invalid' : ''
    "
    [styleClass]="
      (control.dirty || control.touched) && !control.valid ? 'p-invalid' : ''
    "
  ></p-inputMask>
  <kodit-error-info-required [formCtrl]="control"></kodit-error-info-required>
</div>
