<div class="p-d-flex p-flex-md-column">
    <kodit-field
    fieldLabel="Status računa">
        <kodit-dropdown
            ctrlPlaceholder="Izaberite status računa"
            [formCtrl]="form.controls.statusRacuna"
            [options]="statusOptions"
        ></kodit-dropdown>
    </kodit-field>
    <kodit-field
    fieldLabel="Datum od">
        <kodit-calendar
            class="p-fluid"
            [formCtrl]="form.controls.datumOd!"
            formCtrlId="dateFrom"
        ></kodit-calendar>
    </kodit-field>
     <kodit-field
    fieldLabel="Datum do">
        <kodit-calendar
            class="p-fluid"
            [maxDate]="maxAllowedDate"
            [formCtrl]="form.controls.datumDo!"
            formCtrlId="dateTo"
        ></kodit-calendar>
    </kodit-field>
</div>
