import { Injectable } from '@angular/core';
import { IRacunInfoDto } from '@kodit/core/data-api';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root',
})
export class RacunInfoService {
  constructor() {}

  racunInfoDto$ = new BehaviorSubject<IRacunInfoDto>(null);
  get getRacunInfoDto() {
    return this.racunInfoDto$.asObservable();
  }
  set setRacunInfoDto(data: IRacunInfoDto) {
    this.racunInfoDto$.next(data);
  }

  reloadDocuments$ = new BehaviorSubject<number | undefined>(undefined);
  get getReloadedDocuments() {
    return this.reloadDocuments$.asObservable();
  }
  set setReloadDocuments(racunId: number) {
    this.reloadDocuments$.next(racunId);
  }

  racunId$ = new BehaviorSubject<number>(null);
  get getRacunId() {
    return this.racunId$.asObservable();
  }
  set setRacunId(data: number) {
    this.racunId$.next(data);
  }
}
