import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroupTypeSafe} from "angular-typesafe-reactive-forms-helper";
import {
  FiskalniRacuniClient,
  IOpstaPravilaFisklaniDto,
  IOpstaPravilaKonfiguracijaDto,
  RacuniClient,
  ResultOfOpstaPravilaFisklaniDto,
  ResultOfOpstaPravilaKonfiguracijaDto,
  UpdateOpstaPravilaCommand,
  UpdateOpstaPravilaFiskalnaCommand
} from "@kodit/core/data-api";
import {DynamicDialogConfig, DynamicDialogRef} from "@kodit/core/shared-ui";
import {AlertService} from "@kodit/core/services";
import {FormHelper, OpstaPravilaFormService} from "@kodit/core/form-definitions";
import {Subscription} from "rxjs";
import {
  OpstaPravilaFiskalnaFormService
} from "../../../../../../form-definitions/src/lib/racun/konfiguracija/opsta-pravila-fiskalna-form-service";

@Component({
  selector: 'kodit-opsta-pravila-fiskalna-form',
  templateUrl: './opsta-pravila-fiskalna-form.component.html',
  styleUrls: ['./opsta-pravila-fiskalna-form.component.scss']
})
export class OpstaPravilaFiskalnaFormComponent implements OnInit, OnDestroy {

  private _subs: Subscription = new Subscription();

  form: FormGroupTypeSafe<IOpstaPravilaFisklaniDto>;

  constructor(
      public _dialogRef: DynamicDialogRef,
      public _dialogConfig: DynamicDialogConfig,
      private _alertService: AlertService,
      private _client: FiskalniRacuniClient,
      private _fs: OpstaPravilaFiskalnaFormService,
      private _formHelper: FormHelper
  ) { }

  ngOnInit(): void {
    this.form = this._fs.GetOpstaPravilaFiskalnaFormGroup(
      this._dialogConfig?.data.model
    );

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  private save() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._subs.add(
      this._client
        .updateOpstaPravilaFiskalna(this.form.value as UpdateOpstaPravilaFiskalnaCommand)
        .subscribe(
          (result: ResultOfOpstaPravilaFisklaniDto) => {
            this._alertService.addSuccessMsg(result.messages[0]);

            this._dialogRef.close(result.data);
          },
          (error) => {
            this._subs.add(
              error.subscribe((x: string) => {
                this._alertService.addFailedMsg(x);
              })
            );
          }
        )
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
