import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  DashboardClient,
  GetPrometChartQueryResult,
} from '@kodit/core/data-api';
import {GodinaRacunaDropdownService} from "@kodit/core/services";

const MONTH_NAMES = [
  'Januar',
  'Februar',
  'Mart',
  'April',
  'Maj',
  'Jun',
  'Jul',
  'Avgust',
  'Septembar',
  'Oktobar',
  'Novembar',
  'Decembar',
];

@Component({
  selector: 'kodit-promet-chart-bar',
  templateUrl: './promet-chart-bar.component.html',
  styleUrls: ['./promet-chart-bar.component.scss'],
})
export class PrometChartBarComponent implements OnInit, OnDestroy {
  private _getSub: Subscription = new Subscription();

  /** Props */
  prometChartQueryResultList: GetPrometChartQueryResult[] = [];
  chartData: any = {};
  chartOptions: any = {};

  constructor(
    private _client: DashboardClient,
    private _godinaService : GodinaRacunaDropdownService
  ) {}

  ngOnInit(): void {
    this._load();
    this._getSub.add(
      this._godinaService.getForm().valueChanges.subscribe(() => {
        this._load();
      })
    );
  }

  private _load() {
    this._getSub.add(
      this._client.getPrometChart().subscribe((res) => {
        this.prometChartQueryResultList = res.data.prometChartQueryResultList;
        this._initChart();
      })
    );
  }

  private _initChart(): void {
    // data
    this.chartData = {
      labels: [
        ...this.prometChartQueryResultList.map((x) => MONTH_NAMES[x.mesec - 1]),
      ],
      datasets: [
        {
          label: 'Izlaz',
          backgroundColor: '#42A5F5',
          data: [...this.prometChartQueryResultList.map((x) => x.sumIzlaz)],
        },
        {
          label: 'Ulaz',
          backgroundColor: '#FFA726',
          data: [...this.prometChartQueryResultList.map((x) => x.sumUlaz)],
        },
      ],
    };
    // options
    this.chartOptions = {
      aspectRatio: 4,
      plugins: {
        legend: {
          labels: {
            color: '#495057',
          },
          position: 'bottom',
        },
      },
    };
  }

  ngOnDestroy() {
    this._getSub.unsubscribe();
  }
}
