<div class="p-d-flex p-jc-center p-ai-center p-flex-column content-box">
  <h1>Verifikacija korisničkog naloga</h1>

  <p-messages
    *ngIf="showMessage || showError"
    [(value)]="messages"
    [enableService]="false"
    [escape]="false"
  ></p-messages>

  <button
    *ngIf="showMessage"
    pButton
    type="button"
    label="Prijavi se"
    (click)="onLogin()"
  ></button>
</div>
