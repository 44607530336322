import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { OdabirPaketaComponent } from './register/odabir-paketa/odabir-paketa.component';
import { PodaciKompanijeComponent } from './register/podaci-kompanije/podaci-kompanije.component';
import { PodaciKorisnikaComponent } from './register/podaci-korisnika/podaci-korisnika.component';
import { PotvrdaEmailaComponent } from './register/potvrda-emaila/potvrda-emaila.component';
import { RegisterComponent } from './register/register.component';
import { UnosKorisnickogNalogaComponent } from './unos-korisnickog-naloga/unos-korisnickog-naloga.component';
import {PotvrdaRegistracijeComponent} from "./register/potvrda-registracije/potvrda-registracije.component";

const routes: Routes = [
  { path: 'prijava', component: LoginComponent },
  {
    path: 'registracija',
    component: RegisterComponent,
    children: [
      { path: '', redirectTo: 'odabir-paketa', pathMatch: 'full' },
      { path: 'odabir-paketa', component: OdabirPaketaComponent },
      { path: 'podaci-kompanije', component: PodaciKompanijeComponent },
      { path: 'podaci-korisnika', component: PodaciKorisnikaComponent },
      { path: 'potvrda-emaila', component: PotvrdaEmailaComponent },
      { path: 'potvrda-registracije', component : PotvrdaRegistracijeComponent},
      { path: '**', redirectTo: 'odabir-paketa' },
    ],
  },
  {
    path: 'zaboravljena-sifra',
    component: ForgotPasswordComponent,
  },
  {
    path: 'verifikacija-emaila', // /:userId/:code/:tenantKey
    component: EmailVerificationComponent,
  },
  {
    path: 'unos-korisnickog-naloga', //:userEmail/:userRoles/:tenantKey
    component: UnosKorisnickogNalogaComponent,
  },
  { path: '**', redirectTo: 'prijava' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
