<div class="izvor-wrapper p-fluid">
  <kodit-field fieldLabel="Format" fieldLabelFor="format-fajla">
    <kodit-dropdown
      formCtrlId="format-fajla"
      [formCtrl]="miniForm.controls.xml"
      [options]="dropdownItems"
    ></kodit-dropdown>
  </kodit-field>
  <!--  <kodit-field-->
  <!--    fieldLabel="Generiši broj po pravilu"-->
  <!--    fieldLabelFor="pravilo-za-broj"-->
  <!--  >-->
  <!--    <kodit-pravilo-za-broj-racuna-dropdown-->
  <!--      formCtrlId="pravilo-za-broj"-->
  <!--      [formCtrl]="miniForm.controls.praviloSerijskogBrojaId"-->
  <!--      [tipRacuna]="tipRacunaIzlaznaFaktura"-->
  <!--    ></kodit-pravilo-za-broj-racuna-dropdown>-->
  <!--  </kodit-field>-->
  <kodit-field fieldLabel="Memorandum" fieldLabelFor="memorandum">
    <kodit-memorandumi-dropdown
      formCtrlId="memorandum"
      [formCtrl]="miniForm.controls.memorandumId"
    >
    </kodit-memorandumi-dropdown>
  </kodit-field>

  <kodit-field fieldLabel="Tekući račun" fieldLabelFor="tekuci-racun">
    <kodit-tekuci-racun-dropdown
      [tekuciRacunCtrl]="miniForm.controls.tekuciRacunId"
      tekuciRacunIdCtrl="tekuci-racun"
    ></kodit-tekuci-racun-dropdown>
  </kodit-field>
</div>

<kodit-file-upload
  (onUpload)="handleUpload($event)"
  [uploadCompleted]="uploadCompleted"
  [allowMultipleFiles]="true"
  fileTypes="text/xml"
  descriptionText="Izaberite fajlove računa koje želite da otpremite ili ih prevucite ovde"
></kodit-file-upload>
