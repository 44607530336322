import {Component, OnInit, ViewChild} from '@angular/core';
import {TabPanel} from "primeng/tabview";
import {RacunTableService} from "@kodit/core/moduli/racun";

@Component({
  selector: 'kodit-fiskalna-kasa-page',
  templateUrl: './fiskalna-kasa-page.component.html',
  styleUrls: ['./fiskalna-kasa-page.component.scss'],
})
export class FiskalnaKasaPageComponent implements OnInit {
  /** Props */
  activeIndex : number = 0;

  /** DOM components */
  @ViewChild('bezbednosniElementiPanel') bezbednosniElementiPanel: TabPanel;
  @ViewChild('poreskeStopePanel') poreskeStopePanel: TabPanel;
  constructor(
    private _tableService: RacunTableService,
  ) {}

  ngOnInit(): void {

  }

  handleIndexChange(tabIndex: number) {
    this._handleActiveIndex();
    this._tableService.setActiveTab = this.activeIndex;
  }

  private _handleActiveIndex() {
    if (this.bezbednosniElementiPanel && this.bezbednosniElementiPanel.selected) {
      this.activeIndex = 0;
    } else if (this.poreskeStopePanel && this.poreskeStopePanel.selected) {
      this.activeIndex = 1;
    }
  }
}
