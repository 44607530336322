import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  IStrankaRacunaDto,
  RacuniClient,
  TipRacuna,
  VrstaRacuna,
} from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { AutoComplete } from 'primeng/autocomplete';
import { Subscription } from 'rxjs';
import { RacunService } from '../../../../../../../services/src/lib/racun.service';

export interface IRequest {
  searchValue: string;
}

@Component({
  selector: 'kodit-stranka-racuna-autocomplete',
  templateUrl: './stranka-racuna-autocomplete.component.html',
  styleUrls: ['./stranka-racuna-autocomplete.component.scss'],
})
export class StrankaRacunaAutocompleteComponent
  implements OnInit, OnDestroy, AfterViewInit {
  /** Subs */
  private _searchSub: Subscription = new Subscription();
  private _vezaniRacunSub: Subscription = new Subscription();

  /** Props */
  formGroup: FormGroupTypeSafe<IRequest>;
  pravnaLicaSuggestions: IStrankaRacunaDto[] = [];
  ctrlPlaceholder: string = 'Matični broj, PIB ili naziv...';
  ctrlDisabled: boolean;
  vrstaRacuna: VrstaRacuna;

  @ViewChild('autocompleteRef') autocompleteRef: AutoComplete;

  /** I/O */
  @Input() parentCtrl: any = 'body';
  @Input() mustSelect: boolean = false;
  @Output() onNazivChange = new EventEmitter<string>();
  @Output() onPravnoLiceChange = new EventEmitter<IStrankaRacunaDto>();
  @Output() onPravnoLiceClear = new EventEmitter<void>();

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _client: RacuniClient,
    private _racunService: RacunService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._route.data.subscribe((data) => {
      if (data['vrstaRacuna']) {
        this.vrstaRacuna = data['vrstaRacuna'] as VrstaRacuna;
      }
    });
    this.formGroup = this._fb.group<IRequest>({
      searchValue: new FormControl(''),
    });
    this._vezaniRacunSub = this._racunService
      .hasVezaniRacun()
      .subscribe((hasSomeVezaniRacun) => {
        if (
          this._racunService.getTipRacuna === TipRacuna.DOKUMENT_O_SMANJENJU ||
          this._racunService.getTipRacuna === TipRacuna.DOKUMENT_O_POVECANJU
        ) {
          this.ctrlDisabled = hasSomeVezaniRacun;
        }
      });
  }

  /**FAKTURE
   * Sluzi da nam da predlozena pravna lica na osnovu pretrage
   * Ukoliko forma nije tacna, nece se ni raditi pretraga
   */
  searchPravnoLice(event: any) {
    if (this.formGroup.value.searchValue.length < 1) {
      this.pravnaLicaSuggestions = [];
      return;
    }
    this._searchSub.unsubscribe();
    this._searchSub = this._client
      .searchStrankaRacuna(this.formGroup.value.searchValue)
      .subscribe((result) => {
        this.pravnaLicaSuggestions = result.data.strankaRacunaDtoList;
        if (this.pravnaLicaSuggestions.length == 0) {
          // dajemo indikator da pretraga nije nista nasla i saljemo unetu vrednost da bismo je prikazali
          this._racunService.setShowFizickoLiceCreate = this.formGroup.value.searchValue;
        }
      });
  }

  ngAfterViewInit() {
    this._racunService.getPerformSearchFizickogLica.subscribe((res) => {
      if (res) {
        this.formGroup.controls.searchValue.patchValue(res);
        this.autocompleteRef.handleDropdownClick(null);
      }
    });
  }

  /**
   * Pri odabiru pravnog lica iz liste ponudenih setuje model u servisu
   * @param value odabrano pravno lice
   */
  selectionChanged(value: IStrankaRacunaDto) {
    if (this.onPravnoLiceChange) {
      this.onPravnoLiceChange.emit(value);
      this.formGroup.controls.searchValue.reset('');
      this._racunService.setShowFizickoLiceCreate = null;
      if(this._racunService.getTipRacuna !== TipRacuna.PONUDA && this._racunService.getTipRacuna !== TipRacuna.PREDRACUN) {
        this.setDefaultDatumValute(value.rokPlacanjaZaUlazne,value.rokPlacanjaZaIzlazne);
      }
    }
  }

  setDefaultDatumValute(rokPlacanjaZaUlazne : number, rokPlacanjaZaIzlazne : number){
    const datumPrometa = this._racunService.getRacunFormObject.controls.datumPrometa.value;
    const datumValute = new Date(datumPrometa);

    this._racunService.getRacunFormObject.controls.vrsta.value === VrstaRacuna.IZLAZNI ?
      datumValute.setDate(datumValute.getDate() + rokPlacanjaZaIzlazne) :
      datumValute.setDate(datumValute.getDate() + rokPlacanjaZaUlazne);

    this._racunService.getRacunFormObject.controls.datumValute.patchValue(datumValute);
  }

  nazivChanged(event: any) {
    if (this.onNazivChange && event.target?.value) {
      this.onNazivChange.emit(event.target.value);
    }
  }

  onClear() {
    this.formGroup.reset();
    if (this.onPravnoLiceClear) {
      this.onPravnoLiceClear.emit();
    }
  }

  ngOnDestroy(): void {
    this._searchSub.unsubscribe();
    this._vezaniRacunSub.unsubscribe();
  }
}
