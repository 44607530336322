<div
  class="p-d-flex p-flex-column p-jc-center p-ai-center"
>
  <div class="p-d-flex p-flex-column p-jc-center">
    <div class="step-subtitle">Korak 4</div>
    <div class="step-title">Potvrda email-a</div>
    <div class="step-description">Na email koji ste uneli, poslat je
      kod za verifikaciju</div>
  </div>
  <div class="p-d-flex p-flex-column kod-wrapper">
    <div class="p-d-flex p-flex-row p-jc-between p-mt-6">
      <div class="kod-text">Kod</div>
      <div class="posalji-kod-link" (click)="sendNewCode()">Pošalji novi kod</div>
    </div>
    <div class="p-field flex-1">
      <div class="p-field flex-1 p-mt-2">
          <span class="p-d-flex p-flex-row p-ai-center p-input-icon-left">
              <i class="fal fa-keyboard"></i>
              <input type="text" class="flex-1" pInputText [formControl]="form.controls.confirmationCode" />
          </span>
      </div>
      <kodit-error-info-required
        [formCtrl]="form.controls.confirmationCode"
      ></kodit-error-info-required>
    </div>
    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-between p-ai-center p-mt-4">
      <div class="p-field-checkbox">
        <p-checkbox name="group" value="remember_me" id="uslovni-checkbox"
                    [formControl]="form.controls.prihvaceniUsloviKoriscenja"
                    [binary]="true" inputId="remember_me"></p-checkbox>
        <label class="checkbox-label">
          <div class="p-d-flex p-flex-row p-ai-center">Slažem se sa <p class="p-ml-2 uslovi-koriscenja-text" (click)="usloviKoriscenja()">Uslovi korišćenja</p></div></label>
      </div>
    </div>
    <div class="p-d-flex p-flex-row p-jc-between">
      <div class="nazad-button p-ai-center" (click)="navigateToPrev()">Nazad</div>
      <div class="registruj-button p-ai-center" (click)="registerUser()">Registruj kompaniju</div>
    </div>
  </div>
  </div>
