import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FilterChipDto,
  GetRobaTableQuery,
  IPredmetStavkeFilterDto,
  PredmetStavkeFilterDto,
  ResultOfRobaCommandResponse,
  RobaTableDto,
  RobeClient,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { RobaFormComponent } from '../roba-form/roba-form.component';
import {
  DynamicDialogConfig,
  DynamicDialogRef,
  DynamicDialogService,
} from '@kodit/core/shared-ui';

import {
  DialogSize,
  OnDynamicDialogInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  ActionType,
  OnTableInit,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { RobaFilterService } from '../roba-filter/roba-filter.service';
import { RobaFilterComponent } from '../roba-filter/roba-filter.component';
import { toCamelCase } from '@kodit/core/shared';

@Component({
  selector: 'kodit-roba-table',
  templateUrl: './roba-table.component.html',
  styleUrls: ['./roba-table.component.scss'],
})
export class RobaTableComponent
  implements OnInit, OnDestroy, OnTableInit, OnDynamicDialogInit {
  /** Configurations */
  tableConfig: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.MEDIUM
  );

  /** Props */
  private _subs: Subscription = new Subscription();
  robaTable: RobaTableDto[] = [];
  chipItems: FilterChipDto[] = [];
  skipFirst: number = 0;
  numberOfRowsDisplayed: number = 0;

  /** I/O */
  @Input() showCardBlock: boolean = true;

  constructor(
    private _client: RobeClient,
    private _router: Router,
    private _dialogService: DynamicDialogService,
    private _robaFilterService: RobaFilterService
  ) {}

  ngOnInit(): void {
    this._load(new PredmetStavkeFilterDto());
    // set table config
    this.setTableConfig();
    //sub to filters
    this.setFilters();
  }

  //#region Table configs

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Artikli',
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      deleteMessage: 'Da li ste sigurni da želite da obrišete artikal {param}?',
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete selektovane proizvode?',
      deleteMessageParams: 'naziv',
      columns: [
        {
          field: 'naziv',
          header: 'Naziv',
          type: 'link',
          linkCallbackFunction: (itemIndex: number) => this._onInfo(itemIndex),
          columns: [
            { field: 'sifra', header: 'Šifra', type: 'text' },
            {
              field: 'jedinicaMereDescription',
              header: 'Jedinica mere',
              type: 'badge',
            },
          ],
        },
        {
          field: 'cena',
          header: 'Cena',
          type: 'currency',
          currencyAlphaCharField: 'valutaText',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          hasAccessTooltip: 'Unesite robu',
          noAccessTooltip: 'Nemate ovlašćenja za unos robe',
          callback: () => {
            this.dialogConfig.data = { id: null };
            this.dialogConfig.header = 'Unos podataka artikla';
            this.openDialog(this.dialogConfig);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          hasAccessTooltip: 'Izmenite robu',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu robe',
          callback: (index: number) => {
            this.dialogConfig.data = {
              id: this.robaTable[index]?.id,
              jm: this.robaTable[index].jedinicaMereDescription,
            };
            this.dialogConfig.header = 'Izmena podataka artikla';
            this.openDialog(this.dialogConfig);
          },
        },
        // {
        //   type: ActionType.DELETE,
        //   tipPristupa: TipPristupa.ROBA_DELETE,
        //   hasAccessTooltip: 'Obrišite robu',
        //   noAccessTooltip: 'Nemate ovlašćenja za brisanje robe',
        //   callback: (index: number) => {
        //     const forDelete = this.robaTable[index];
        //     this._deleteSub = this._client.delete(forDelete.id).subscribe(
        //       () => {
        //         this.robaTable.splice(index, 1);
        //         this._alertService.addSuccessMsg(
        //           `Roba ${forDelete.naziv} je uspešno obrisana.`
        //         );
        //       },
        //       (error) => {
        //         this._alertService.addFailedMsg(error);
        //       }
        //     );
        //   },
        // },
      ],
      advancedFilter: {
        component: RobaFilterComponent,
        data: new PredmetStavkeFilterDto(),
      },
    });
  }

  private _onInfo(itemIndex: number) {
    this._router
      .navigate(['/kompanija', 'roba', this.robaTable[itemIndex].id])
      .then();
  }

  //#endregion Table configs

  setFilters() {
    this._subs.add(
      this._robaFilterService.getAdvanceFilterSubmitted.subscribe(
        (filterResult) => {
          if (filterResult?.shouldApplyFilter) {
            this.tableConfig.advancedFilter.data = filterResult.filterData;
            this._load(filterResult.filterData);
          }
        }
      )
    );
  }
  private _handleChipRemove(removedChip: FilterChipDto) {
    this.tableConfig.advancedFilter.data[toCamelCase(removedChip.key)] =
      removedChip.defaultValue;
    this._updateFilterDataAndReload();
  }
  private _updateFilterDataAndReload() {
    this.tableConfig.advancedFilter.data.pageNumber = this.skipFirst;
    this.tableConfig.advancedFilter.data.pageSize = this.numberOfRowsDisplayed;
    this._load(this.tableConfig.advancedFilter.data);
  }

  openDialog(config: DynamicDialogConfig) {
    const ref: DynamicDialogRef = this._dialogService.open(
      RobaFormComponent,
      config
    );

    this._subs.add(
      ref.onClose.subscribe((result: ResultOfRobaCommandResponse) => {
        if (result) {
          this._updateFilterDataAndReload();
        }
      })
    );
  }

  private _load(request: IPredmetStavkeFilterDto) {
    this._subs.add(
      this._client
        .getForTable(request as GetRobaTableQuery)
        .subscribe((res) => {
          this.robaTable = res.data.responseList;
          this.chipItems = res.data.activeFilters;
        })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
