import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { BaseService } from '@kodit/core/services';
import {
  PredracunDto,
  PredracuniClient,
  GetPredracunQuery,
} from '@kodit/core/data-api';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PredracunResolver
  extends BaseService
  implements Resolve<PredracunDto> {
  constructor(private _client: PredracuniClient) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PredracunDto> | Promise<PredracunDto> | PredracunDto | null {
    const id = Number.parseInt(route.paramMap.get('id'));
    return this._client
      .getDomaciPredracun(new GetPredracunQuery({ id: id }))
      .pipe(
        map((x) => x.data),
        catchError(this.handleError),
        finalize(() => {
          //this._configService.setIsBusy = false;
        })
      );
  }
}
