<form [formGroup]="form">
  <div class="p-fluid">
    <kodit-field fieldLabel="Naziv" fieldLabelFor="naziv">
      <kodit-text-input
        [formCtrl]="form.controls.naziv"
        formCtrlId="naziv"
      ></kodit-text-input>
    </kodit-field>

    <kodit-field fieldLabel="Roditeljska lokacija">
      <kodit-fizicke-lokacije-autocomplete>
      </kodit-fizicke-lokacije-autocomplete>
    </kodit-field>
    <!-- 
    <kodit-field fieldLabel="Skladišna jedinica">
      <kodit-skladisna-jedinica-dropdown></kodit-skladisna-jedinica-dropdown>
    </kodit-field> -->
  </div>
</form>
