<div class="p-grid">
  <div class="p-col-12 p-md-4 p-xl-4 p-pr-3 p-pb-0">
    <kodit-dashboard-promet-card
      periodPrefix="Dnevni promet"
      [value]="prometCardsResponse?.dnevni.promet"
      [percentage]="prometCardsResponse?.dnevni?.procentualnaRazlika"
      [isPercentageNegative]="prometCardsResponse?.dnevni?.isNegative"
      [periodCurrent]="prometCardsResponse?.dnevni?.trenutniNaziv"
      [periodBefore]="prometCardsResponse?.dnevni?.prethodniNaziv"
    ></kodit-dashboard-promet-card>
  </div>

  <div class="p-col-12 p-md-4 p-xl-4 p-pr-3 p-pl-lg-3 p-pb-0">
    <kodit-dashboard-promet-card
      periodPrefix="Mesečni promet"
      [value]="prometCardsResponse?.mesecni.promet"
      [percentage]="prometCardsResponse?.mesecni?.procentualnaRazlika"
      [isPercentageNegative]="prometCardsResponse?.mesecni?.isNegative"
      [periodCurrent]="prometCardsResponse?.mesecni?.trenutniNaziv"
      [periodBefore]="prometCardsResponse?.mesecni?.prethodniNaziv"
    ></kodit-dashboard-promet-card>
  </div>

  <div class="p-col-12 p-md-4 p-xl-4 p-pl-lg-3 p-pb-0">
    <kodit-dashboard-promet-card
      periodPrefix="Godišnji promet"
      [value]="prometCardsResponse?.godisnji.promet"
      [percentage]="prometCardsResponse?.godisnji?.procentualnaRazlika"
      [isPercentageNegative]="prometCardsResponse?.godisnji?.isNegative"
      [periodCurrent]="prometCardsResponse?.godisnji?.trenutniNaziv"
      [periodBefore]="prometCardsResponse?.godisnji?.prethodniNaziv"
    ></kodit-dashboard-promet-card>
  </div>
</div>
