<form [formGroup]="sastavnicaForm" (keydown)="keyDownFunction($event)">
  <div class="p-fluid">
    <div class="p-field">
      <label for="select-tip" kodit-form-label>Artikal:</label>
      <kodit-predmet-stavke-autocomplete
        [predmetStavkeCtrl]="sastavnicaForm.controls.predmetStavkeDto"
        [excludePredmeteStavkiIds]="excludeIds"
        [excludeTipovePredmeta]="excludeTipovePredmeta"
        [excludeTipoveArtikla]="excludeTipoveArtikla"
      ></kodit-predmet-stavke-autocomplete>
    </div>

    <kodit-number-input-field
      formCtrlLabel="Količina"
      [formCtrl]="sastavnicaForm.controls.kolicina"
    ></kodit-number-input-field>
  </div>
</form>
