import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GetUplateQuery, IGetUplateQuery, IStatusTransakcijeIzvodaDto } from '@kodit/core/data-api';
import { AdvancedFilter } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { UplateIsplateFilterService } from './uplate-isplate-filter.service';
import { UplateIsplateFormService } from './uplate-isplate-form.service';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { clearFilter, setFilter } from '../../racun/state/filters/filter.actions';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'kodit-uplate-isplate-filter',
  templateUrl: './uplate-isplate-filter.component.html',
  styleUrls: ['./uplate-isplate-filter.component.scss'],
})
export class UplateIsplateFilterComponent
  implements OnInit, AdvancedFilter<IGetUplateQuery> {
  /**Props */
  filterData: IGetUplateQuery;
  form: FormGroupTypeSafe<IGetUplateQuery>;
  jeUplata: boolean;

  @Output() onSubmitForm = new EventEmitter<IGetUplateQuery>();

  constructor(
    private _uplateIsplateFilterService: UplateIsplateFilterService,
    private _filterFormService: UplateIsplateFormService,
    private _storage: Store,
    private _routing: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.form = this._filterFormService.GetUplateIsplateFiliterFormGroup(
      this.filterData
    );
    this.jeUplata = this._routing.snapshot.data.jeUplata;
  }

  onFilterSubmit() {
    this._storage.dispatch(
      setFilter({
        key: this.jeUplata ? 'UPLATE' : 'ISPLATE',
        filter: JSON.stringify(this.form.value),
      })
    );
  }

  onFilterHide(shouldReset: boolean) {
    if (shouldReset) {
      this._storage.dispatch(
        clearFilter({
          key: this.jeUplata ? 'UPLATE' : 'ISPLATE',
        })
      );
    }
    
    this._uplateIsplateFilterService.setAdvancedFilterSubmitted = {
      filterData: shouldReset ? new GetUplateQuery() : this.form.value,
      shouldApplyFilter: shouldReset,
    };
  }

  handleSelectedStatusi(selectedItems: any){
    (this.form.controls.statusi as FormArray).clear();
    selectedItems.forEach((element) => {
      (this.form.controls.statusi as FormArray).push(
        this._uplateIsplateFilterService.GetStatusFormGroup({
          status: element,
        } as IStatusTransakcijeIzvodaDto)
      );
    });
  }
}
