<div class="p-d-flex p-flex-column p-flex-md-row odobri-odbaci-form">
  <div class="p-d-flex flex-1">
    <div class="pdf-data" [innerHTML]="pdfInnerHtml"></div>
  </div>
  <div class="p-d-flex flex-1">
    <form [formGroup]="form" class="pdf-form">
      <div class="p-d-flex p-flex-column">
        <!-- <div class="p-d-flex p-text-center p-jc-center pitanje">
          Da li ste sigurni da želite da {{ akcija }} račun:
          <div *ngIf="_dialogConfig.data?.sefIds > 1" class="p-text-bold">
            {{ brojRacuna }}?
          </div>
        </div> -->
        <p-message *ngIf="jeOdobravanje"
        severity="warn"
        class="p-mb-5"
        text="Akcijom odobravanja, račun dobija UF broj i automatski se prebacuje u tab Fakture. Time dobijate potpunu finansijsku karticu vaših dobavljača jednostavnim odobravanjem računa."
      ></p-message>
        <div class="p-fluid ">
          <div *ngIf="jeOdobravanje">
            <div>
              <kodit-unos-broja-option
                (valueChanged)="handleUnosBrojaOptionChanged($event)"
                label="Način generisanja broja računa"
              ></kodit-unos-broja-option>
            </div>
            <!-- Pravilo za broj input -->
            <div>
              <div class="p-fluid">
                <kodit-broj-racuna-input
                  class="p-d-flex input-field"
                  *ngIf="!showUnosBrojaAutomatski"
                  [brojCtrl]="form.controls.brojRacuna"
                ></kodit-broj-racuna-input>
              </div>
            </div>

            <div *ngIf="showUnosBrojaAutomatski" class="p-mr-2">
              <!-- Pravilo za broj dropdown -->
              <kodit-field
                fieldLabel="Generiši broj po pravilu"
                fieldLabelFor="pravilo-za-broj"
              >
                <kodit-pravilo-za-broj-racuna-dropdown
                  formCtrlId="pravilo-za-broj"
                  [formCtrl]="form.controls.praviloZaBrojRacuna"
                  [tipRacuna]="tipRacuna"
                >
                </kodit-pravilo-za-broj-racuna-dropdown>
              </kodit-field>
            </div>
          </div>
          <div *ngIf="jeOdobravanje">
            <kodit-odobravanje-oznake-form
              [oznakeArray]="form.controls.oznakeDto"
            ></kodit-odobravanje-oznake-form>
          </div>
          <kodit-field fieldLabel="Komentar" fieldLabelFor="komentar">
            <textarea
              id="komentar"
              [rows]="5"
              [cols]="30"
              pInputTextarea
              [formControl]="form.controls.komentar"
              [autoResize]="true"
            ></textarea>
            <kodit-error-info-max-length
              [formCtrl]="form.controls.komentar"
            ></kodit-error-info-max-length>
            <kodit-error-info-required
              [formCtrl]="form.controls.komentar"
            ></kodit-error-info-required>
          </kodit-field>
        </div>
      </div>
    </form>
  </div>
</div>
