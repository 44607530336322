import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {
  CreateMojaFirmaCommand,
  ICreateMojaFirmaCommand,
  SendConfirmationCodeMojaFirmaCommand,
  TokensClient,
  UserAccessVia,
} from '@kodit/core/data-api';
import {AlertService, MixpanelService} from '@kodit/core/services';
import {FormGroupTypeSafe} from 'angular-typesafe-reactive-forms-helper';
import {RegisterService} from '../register.service';
import {Subscription} from 'rxjs';
import {sumIfNotNull} from '@kodit/core/shared';

@Component({
  selector: 'kodit-potvrda-emaila',
  templateUrl: './potvrda-emaila.component.html',
  styleUrls: ['./potvrda-emaila.component.scss'],
})
export class PotvrdaEmailaComponent implements OnInit, OnDestroy {
  /** Subs */
  private _saveSub: Subscription = new Subscription();
  private _rensedCodeSub: Subscription = new Subscription();

  basePackagesAmount: number = 0;

  /** Props */
  form: FormGroupTypeSafe<ICreateMojaFirmaCommand>;
  showResend: boolean;

  constructor(
    private _router: Router,
    private _registerService: RegisterService,
    private _client: TokensClient,
    private _alertService: AlertService,
    private _mixpanelService: MixpanelService
  ) {
  }

  ngOnInit(): void {
    this.form = this._registerService.getForm;

    this.basePackagesAmount = sumIfNotNull(
      ...this.form.value.basePackages.map((x) => x.price)
    );
  }

  navigateToPrev() {
    this._router
      .navigate(['/autentikacija', 'registracija', 'podaci-korisnika'])
      .then();
  }

  sendNewCode() {
    this._registerService.setShowLoader = true;
    this._rensedCodeSub = this._client
      .sendConfirmationCode(
        new SendConfirmationCodeMojaFirmaCommand({
          ime: this.form.value.podaciKorisnikaDto.ime,
          korisnikEmail: this.form.value.podaciKorisnikaDto.email,
          verifyVia: UserAccessVia.EMAIL,
        })
      )
      .subscribe(
        () => {
          this._alertService.addSuccessMsg(`Novi verifikacioni kod je poslat na mail.`);
          this.showResend = true;
          this._registerService.setShowLoader = false;
        },
        () => {
          this._registerService.setShowLoader = false;
        }
      );
  }

  registerUser() {
    if (!this.form.valid) {
      this.form.controls.confirmationCode.markAsDirty();
      this.form.controls.prihvaceniUsloviKoriscenja.markAsDirty();
      return;
    }
    this._registerService.setShowLoader = true;
    this._saveSub = this._client
      .createMojaFirma(this.form.value as CreateMojaFirmaCommand)
      .subscribe(
        (res) => {
          this._mixpanelService.registrationSuccessful(this.form.value);
          this._alertService.addSuccessMsg(res.data);
          this._registerService.setShowLoader = false;
          this._router.navigate(['/autentikacija', 'registracija', 'potvrda-registracije']).then();
        },
        () => {
          this._registerService.setShowLoader = false;
        }
      );
  }

  usloviKoriscenja() {
    window.open('/assets/ugovori/opsti-uslovi-ekompanija.pdf', '_blank');
  }

  ngOnDestroy() {
    this._saveSub.unsubscribe();
    this._rensedCodeSub.unsubscribe();
  }
}
