<kodit-info-container>
  <ng-container body-2-of-3>
    <p-messages severity="info">
      <ng-template pTemplate>
        <i class="fa-light fa-memo-circle-info" style="font-size: 24px"></i>
        <div class="p-ml-2">
          Bez skrivenih troskova. Na ovom ekranu u svakom trenutku možete videti
          koliko ćete platiti za tekući mesec.
        </div>
      </ng-template>
    </p-messages>
    <kodit-card-container >
        <div card-title>Troškovi za tekući mesec</div>
        <div card-body>
            <div>asdads</div>
            <div>asdadsasdasd</div>
            <div>asdadsasdasdsdasdasd</div>
        </div>
    </kodit-card-container>
  </ng-container>

  <ng-container body-1-of-3>
    <kodit-card-container  [showDivider]="false">
      
    </kodit-card-container>
  </ng-container>
</kodit-info-container>
