import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
})
export class FieldComponent implements OnInit {
  @Input() fieldLabel: string;
  @Input() fieldLabelFor: string;
  @Input() isFieldRequired: boolean;

  constructor() {}

  ngOnInit(): void {}
}
