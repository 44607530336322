import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IGetPdvEvidencijaZbirnaTableQuery } from '@kodit/core/data-api';
import { AdvancedFilter } from '@kodit/core/shared-ui';
import { Store } from '@ngrx/store';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { clearFilter, setFilter } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';

@Component({
  selector: 'kodit-pdv-evidencija-zbirna-filter',
  templateUrl: './pdv-evidencija-zbirna-filter.component.html',
  styleUrls: ['./pdv-evidencija-zbirna-filter.component.scss'],
})
export class PdvEvidencijaZbirnaFilterComponent
  implements OnInit, AdvancedFilter<IGetPdvEvidencijaZbirnaTableQuery> {
  /**Props */
  filterData!: IGetPdvEvidencijaZbirnaTableQuery;
  form!: FormGroupTypeSafe<IGetPdvEvidencijaZbirnaTableQuery>;

  @Output()
  onSubmitForm = new EventEmitter<IGetPdvEvidencijaZbirnaTableQuery>();

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _storage: Store
  ) {}

  ngOnInit(): void {
    this.form = this.getForm(this.filterData);
  }

  onFilterSubmit() {
    this._storage.dispatch(
      setFilter({
        key: 'PDV|Zbirne',
        filter: JSON.stringify(this.form.value),
      })
    );
  }

  onFilterHide(shouldReset: boolean) {
    if (shouldReset) {
      this._storage.dispatch(
        clearFilter({
          key: 'PDV|Zbirne',
        })
      );
    }
  }

  private getForm(
    model: IGetPdvEvidencijaZbirnaTableQuery
  ): FormGroupTypeSafe<IGetPdvEvidencijaZbirnaTableQuery> {
    return this._fb.group<IGetPdvEvidencijaZbirnaTableQuery>({
      brojObracuna: new FormControl(model.brojObracuna),
      statusi: new FormControl(model.statusi),
      mesec: new FormControl(model.mesec),
    });
  }
}
