<!-- Template za kreiranje izlazne fakture step 2(content) -->
<ng-template #izlaznaFakturaKreiranjeStep2>
  <p>Ovde se nalaze osnovni podaci o racunu.</p>
</ng-template>

<!-- Template za kreiranje izlazne fakture step 3(content) -->
<ng-template #izlaznaFakturaKreiranjeStep3>
  <p>
    Ovde se nalaze podaci o kupcu. Ukoliko firma ili lice nije u bazi, dovoljno
    je naci je po pibu i prilikom cuvanja racuna ce se sacuvati.
  </p>
</ng-template>

<!-- Template za kreiranje izlazne fakture step 5(content) -->
<ng-template #izlaznaFakturaKreiranjeStep5>
  <p>Ovde se nalaze podaci bitni za kreiranje QR koda.</p></ng-template
>

<!-- Template za kreiranje izlazne fakture step 6(content) -->
<ng-template #izlaznaFakturaKreiranjeStep6>
  <p>Ovde se nalaze napomene vezane za fakturu.</p>
</ng-template>

<!-- Template za kreiranje izlazne fakture step 7(content) -->
<ng-template #izlaznaFakturaKreiranjeStep7>
  <p>Ovde se nalaze dodatne informacije vezane za fakturu.</p>
</ng-template>

<div [formGroup]="racunFormConfig.racunForm">
  <!-- PODESAVANJE RACUNA -->
  <div
    class="p-mt-3 k-card podesavanja-racuna-card"
    card
    *ngIf="!jeAvansniRacun"
  >
    <div
      class="p-d-flex p-flex-column p-flex-md-row p-flex-wrap gap-12 p-fluid"
    >
      <div class="p-d-flex p-flex-row" *ngIf="!jeEditMode">
        <!-- Unos broja racuna switch -->
        <div *ngIf="jePredracun && !jeEditMode" class="p-mr-2">
          <div>
            <kodit-unos-broja-option
              (valueChanged)="handleUnosBrojaOptionChanged($event)"
            ></kodit-unos-broja-option>
          </div>
        </div>

        <!-- Pravilo za broj input -->
        <div class="p-d-flex p-flex-column gap-6">
          <div>
            <kodit-broj-racuna-input
              *ngIf="!showUnosBrojAutomatski && !jeEditMode"
              [inputTooltip]="racunFormConfig.brojRacunaTooltip"
              [brojCtrl]="racunFormConfig.racunForm.controls.broj"
              (onInputLeft)="handleCheckIfBrojRacunaExists()"
            ></kodit-broj-racuna-input>
          </div>
          <div *ngIf="showMessage">
            <p-message severity="warn" [text]="messageText"></p-message>
          </div>
        </div>

        <div *ngIf="showUnosBrojAutomatski! && !jeEditMode" class="p-mr-2">
          <!-- Pravilo za broj dropdown -->
          <kodit-field
            fieldLabel="Generiši broj po pravilu"
            fieldLabelFor="pravilo-za-broj"
          >
            <kodit-pravilo-za-broj-racuna-dropdown
              formCtrlId="pravilo-za-broj"
              [formCtrl]="
                racunFormConfig.racunForm.controls.praviloSerijskogBrojaId
              "
            >
            </kodit-pravilo-za-broj-racuna-dropdown>
          </kodit-field>
        </div>
      </div>

      <!-- Pravilo za broj input -->
      <kodit-broj-racuna-input
        *ngIf="jeEditMode"
        [inputTooltip]="racunFormConfig.brojRacunaTooltip"
        [shouldBeDisabled]="true"
        [brojCtrl]="racunFormConfig.racunForm.controls.broj"
      ></kodit-broj-racuna-input>

      <!-- Originalni broj input -->
      <div
        class="ek-max-w-200-px"
        *ngIf="vrstaRacunaUlazni === racunFormConfig.racunForm.value.vrsta"
      >
        <kodit-broj-racuna-input
          inputLabel="Ulazni broj fakture"
          [inputTooltip]="racunFormConfig.originalanBrojRacunaTooltip"
          [brojCtrl]="racunFormConfig.racunForm.controls.originalanBroj"
        ></kodit-broj-racuna-input>
      </div>

      <!-- Valuta dropdown -->
      <kodit-valuta-dropdown-field
        [valutaCtrl]="racunFormConfig.racunForm.controls.valutaId"
        (onValutaChanged)="updateValutaText($event)"
      ></kodit-valuta-dropdown-field>

      <!-- Kurs -->
      <kodit-field
        fieldLabel="Kurs"
        fieldLabelFor="kurs"
        *ngIf="!jeDomaciRacun"
      >
        <kodit-number-input
          formCtrlId="kurs"
          [formCtrl]="
            racunFormConfig.racunForm.get('podaciUplateDevizniDto').controls
              .srednjiKursNaDan
          "
          (onInputLeft)="updateSrednjiKurs()"
        ></kodit-number-input>
      </kodit-field>

      <!-- Rabat dropdown -->
      <kodit-field
        fieldLabel="Prikaži popust kao"
        fieldLabelFor="prikaz-rabata"
      >
        <kodit-prikaz-rabata-dropdown
          formCtrlId="prikaz-rabata"
          [formCtrl]="racunFormConfig.racunForm.controls.prikazRabata"
        ></kodit-prikaz-rabata-dropdown>
      </kodit-field>

      <!-- Memorandum dropdown -->
      <kodit-field
        fieldLabel="Memorandum"
        fieldLabelFor="memorandum"
        *ngIf="vrstaRacunaUlazni !== racunFormConfig.racunForm.value.vrsta"
      >
        <kodit-memorandumi-dropdown
          formCtrlId="memorandum"
          [formCtrl]="racunFormConfig.racunForm.controls.memorandumId"
          (onMemorandumChanged)="onMemorandumChanged($event)"
        >
        </kodit-memorandumi-dropdown>
      </kodit-field>

      <!-- Podtip dokumenta dropdown-->
      <kodit-field
        fieldLabel="Podtip dokumenta"
        fieldLabelFor="podtipDokumenta"
        *ngIf="vrstaRacunaIzlazni === racunFormConfig.racunForm.value.vrsta"
      >
        <kodit-buyer-reference-autocomplete
          [tip]="jeIzlaznaFaktura"
          [formCtrl]="racunFormConfig.racunForm.controls.podtipDokumenta"
        >
        </kodit-buyer-reference-autocomplete>
      </kodit-field>

      <!-- Interni broj za rutiranje-->
      <kodit-field
        fieldLabel="Interni broj za rutiranje"
        fieldLabelFor="interniBrojZaRutiranje"
        *ngIf="
          showInterniBrojZaRutiranje &&
          vrstaRacunaIzlazni == racunFormConfig.racunForm.value.vrsta
        "
      >
        <kodit-text-input
          formCtrlId="interniBrojZaRutiranje"
          [formCtrl]="racunFormConfig.racunForm.controls.interniBrojZaRutiranje"
        >
        </kodit-text-input>
      </kodit-field>

      <!-- Vrsta otpremnice dropdown -->
      <kodit-field
        *ngIf="jeOtpremnica"
        fieldLabel="Vrsta otpremnice"
        fieldLabelFor="vrsta-otpremnice"
      >
        <kodit-vrsta-otpremnice-dropdown
          [isDisabled]="jeEditMode"
          (vrstaChanged)="handleVrstaOtpremniceChanged($event)"
        ></kodit-vrsta-otpremnice-dropdown>
      </kodit-field>

      <!-- Paritet dropdown -->
      <kodit-field
        *ngIf="!jeDomaciRacun"
        fieldLabel="Paritet"
        fieldLabelFor="paritet"
      >
        <kodit-paritet-dropdown
          formCtrlId="paritet"
          [formCtrl]="racunFormConfig.racunForm.controls.paritet"
        >
        </kodit-paritet-dropdown>
      </kodit-field>

      <!-- Povezani racuni -->
      <kodit-povezani-racuni
        *ngIf="shouldDisplayVezaneRacune"
      ></kodit-povezani-racuni>

      <!-- Izmena osnovice (dokument o smanjenju / povecanju)-->
      <kodit-izmena-osnovice *ngIf="jeIzmenaOsnovice"></kodit-izmena-osnovice>
    </div>
    <div *ngIf="currentMonth === 0">
      <!-- Checkbox za prethodnu godine -->
      <kodit-field fieldLabelFor="prosla-godina">
        <kodit-checkbox
          class="koristi-godinu-checkbox"
          formCtrlId="prosla-godina"
          [labelText]="proslaGodinaLabel"
          [formCtrl]="
            racunFormConfig.racunForm.controls.koristitiPrethodnuGodinu
          "
        ></kodit-checkbox>
      </kodit-field>
    </div>
  </div>

  <!-- Rucni unos broja racuna -->
  <div
    class="p-mt-3 k-card podesavanja-racuna-card"
    card
    *ngIf="jeAvansniRacun"
  >
    <div
      class="p-d-flex p-flex-column p-flex-md-row p-flex-wrap gap-12 p-fluid"
    >
      <kodit-unos-broja-option
        *ngIf="!jeEditMode"
        (valueChanged)="handleUnosBrojaOptionChanged($event)"
      ></kodit-unos-broja-option>

      <div *ngIf="showUnosBrojAutomatski! && !jeEditMode">
        <!-- Pravilo za broj dropdown -->
        <kodit-field
          fieldLabel="Generiši broj po pravilu"
          fieldLabelFor="pravilo-za-broj"
        >
          <kodit-pravilo-za-broj-racuna-dropdown
            formCtrlId="pravilo-za-broj"
            [formCtrl]="
              racunFormConfig.racunForm.controls.praviloSerijskogBrojaId
            "
          >
          </kodit-pravilo-za-broj-racuna-dropdown>
        </kodit-field>
      </div>

      <!-- Pravilo za broj input -->
      <div class="p-d-flex p-flex-column gap-6 broj-input">
        <div>
          <kodit-broj-racuna-input
            *ngIf="!showUnosBrojAutomatski && !jeEditMode"
            [inputTooltip]="racunFormConfig.brojRacunaTooltip"
            [brojCtrl]="racunFormConfig.racunForm.controls.broj"
            (onInputLeft)="handleCheckIfBrojRacunaExists()"
          ></kodit-broj-racuna-input>
        </div>
        <div *ngIf="showMessage">
          <p-message severity="warn" [text]="messageText"></p-message>
        </div>
      </div>

      <!-- Valuta dropdown -->
      <kodit-valuta-dropdown-field
        [valutaCtrl]="racunFormConfig.racunForm.controls.valutaId"
        (onValutaChanged)="updateValutaText($event)"
      ></kodit-valuta-dropdown-field>

      <!-- Memorandum dropdown -->
      <kodit-field
        fieldLabel="Memorandum"
        fieldLabelFor="memorandum"
        *ngIf="vrstaRacunaUlazni !== racunFormConfig.racunForm.value.vrsta"
      >
        <kodit-memorandumi-dropdown
          formCtrlId="memorandum"
          [formCtrl]="racunFormConfig.racunForm.controls.memorandumId"
          (onMemorandumChanged)="onMemorandumChanged($event)"
        >
        </kodit-memorandumi-dropdown>
      </kodit-field>
    </div>
  </div>

  <!-- INFORMACIJE RACUNA I IZDAVALAC / PRIMALAC -->
  <div class="p-grid p-mt-3">
    <!-- INFORMACIJE RACUNA -->
    <kodit-card-container
      class="p-col-6"
      cardMinHeight="370"
      joyrideStep="izlaznaFakturaKreiranjeStep2"
      [stepContent]="izlaznaFakturaKreiranjeStep2"
      title="Osnovni podaci o racunu"
    >
      <div card-title>Info - {{ nazivRacuna }}</div>

      <div class="p-d-flex p-flex-column gap-12" card-body>
        <kodit-osnovne-informacije-racuna
          [racunFormGroup]="racunFormConfig.racunForm"
          [rokVazenjaCtrl]="rokVazenjaCtrl"
        ></kodit-osnovne-informacije-racuna>
        <div class="p-d-flex p-flex-column">
          <ng-content select="[osnovne-info-racuna-dodatni-input]">
          </ng-content>
        </div>
      </div>
    </kodit-card-container>

    <!-- IZDAVALAC / PRIMALAC -->
    <kodit-card-container
      *ngIf="showStranka"
      class="p-col-6"
      cardMinHeight="370"
      joyrideStep="izlaznaFakturaKreiranjeStep3"
      [stepContent]="izlaznaFakturaKreiranjeStep3"
      title="Osnovni podaci o kupcu"
    >
      <div card-title>
        {{
          vrstaRacunaUlazni === racunFormConfig.racunForm.value.vrsta
            ? 'Podaci dobavljača'
            : 'Podaci kupca'
        }}
      </div>

      <div class="p-d-flex p-flex-column gap-12" card-body>
        <kodit-stranka-racuna
          [racunFormGroup]="racunFormConfig.racunForm"
        ></kodit-stranka-racuna>
      </div>
    </kodit-card-container>

    <kodit-card-container
      class="p-col-6"
      cardMinHeight="370"
      *ngIf="!showStranka"
    >
      <div card-title>
        {{ 'Magacin za internu otpremnicu' }}
      </div>

      <div class="p-d-flex p-flex-column gap-12" card-body>
        <kodit-magacin-dropdown [magacinCtrl]="interniMagacinCtrl">
        </kodit-magacin-dropdown>
      </div>
    </kodit-card-container>
  </div>

  <!-- STAVKE -->
  <kodit-card-container cardClass="p-mt-3">
    <div card-title>Stavke</div>

    <div card-body title="Stavke racuna">
      <kodit-stavke-racuna-table
        [racunForm]="racunFormConfig.racunForm"
        [excludeTipovePredmeta]="excludeTipovePredmeta"
      ></kodit-stavke-racuna-table>
    </div>
  </kodit-card-container>

  <!-- AVANSNI RACUNI -->
  <div class="p-mt-3" *ngIf="showAvansneRacuneCard">
    <kodit-avansne-uplate-racuna-card
      [strankaId]="racunFormConfig.racunForm.value?.strankaRacunaDto?.id"
      [vrstaRacuna]="racunFormConfig.racunForm.value?.vrsta"
      [racunForm]="racunFormConfig.racunForm"
    ></kodit-avansne-uplate-racuna-card>
  </div>

  <!-- PREDRACUNI -->
  <kodit-card-container cardClass="p-mt-3" *ngIf="false">
    <div card-title>Predračuni</div>
  </kodit-card-container>

  <!-- OZNAKE -->
  <kodit-card-container cardClass="p-mt-3" *ngIf="prikaziOznake">
    <div card-title>Oznake</div>

    <div card-body>
      <kodit-oznake-racuna
        [racunForm]="racunFormConfig.racunForm"
      ></kodit-oznake-racuna>
    </div>
  </kodit-card-container>

  <!-- PODACI ZA UPLATU, INFO ISPORUKE, NAPOMENE I DODATNI PODACI -->
  <div class="p-grid p-mt-3">
    <!-- PODACI ZA UPLATU -->
    <kodit-card-container
      class="p-col-12"
      *ngIf="
        (jeDomaciRacun || jeIzmenaOsnovice) &&
        !jeAvansniRacun &&
        !jeOtpremnica &&
        !jeRadniNalog
      "
      joyrideStep="izlaznaFakturaKreiranjeStep5"
      [stepContent]="izlaznaFakturaKreiranjeStep5"
      title="Poddaci za uplatu"
    >
      <div card-title>Podaci za uplatu</div>

      <div card-body>
        <kodit-podaci-uplate-form
          [podaciUplateFormGroup]="
            racunFormConfig.racunForm.controls.podaciUplateDto
          "
          [tekuciRacunCtrl]="racunFormConfig.racunForm.controls.tekuciRacunId"
        ></kodit-podaci-uplate-form>
      </div>
    </kodit-card-container>

    <!-- PODACI ZA UPLATU DEVIZNI -->
    <kodit-card-container class="p-col-12" *ngIf="!jeDomaciRacun">
      <div card-title>Instrukcije za plaćanje</div>
      <div card-body>
        <kodit-podaci-uplate-devizni
          [jeEdit]="jeEditMode"
          [jeDuplikat]="jeDuplikat"
          [jeKonvertovanjeUFakturu]="jeKonvertovanjeOtpremniceUFakturu"
          [podaciUplateDevizniFormGroup]="
            racunFormConfig.racunForm.controls.podaciUplateDevizniDto
          "
        ></kodit-podaci-uplate-devizni>
      </div>
    </kodit-card-container>

    <!-- INFORMACIJE O ISPORUCI -->
    <div class="p-col-12" *ngIf="prikazInformacijaOIsporuci">
      <kodit-informacija-isporuke
        [jeEdit]="jeEditMode"
      ></kodit-informacija-isporuke>
    </div>

    <!-- SEKCIJA ZA RACUNANJE TEZINE -->

    <kodit-card-container
      class="p-col-12"
      *ngIf="prikaziSekcijuZaRacunanjeTezine"
    >
      <div card-title>Računanje težine</div>
      <div card-body>
        <kodit-sekcija-za-racunanje-tezine></kodit-sekcija-za-racunanje-tezine>
      </div>
    </kodit-card-container>

    <!-- NAPOMENE-->
    <kodit-card-container
      class="p-col-12"
      joyrideStep="izlaznaFakturaKreiranjeStep6"
      [stepContent]="izlaznaFakturaKreiranjeStep6"
      title="Napomene"
    >
      <div card-title>Napomene računa</div>

      <div card-body>
        <kodit-napomene-racuna
          [napOPoreskomOslobCtrl]="
            racunFormConfig.racunForm.controls.napomenaOPoreskomOslobadanju
          "
          [napomenaCtrl]="racunFormConfig.racunForm.controls.napomena"
          [shouldDisplayNapomenaOPoreskomOlobadjanju]="!jeRadniNalog"
        ></kodit-napomene-racuna>
      </div>
    </kodit-card-container>

    <!-- DODATNI PODACI -->
    <!-- Specificni podaci u zavisnosti od tipa dokumenta -->
    <kodit-card-container
      class="p-col-12"
      *ngIf="!jePredracun && !jeRadniNalog && !jePonuda"
      joyrideStep="izlaznaFakturaKreiranjeStep7"
      [stepContent]="izlaznaFakturaKreiranjeStep7"
      title="Dodatni podaci"
    >
      <div card-title>Dodatni podaci</div>
      <div class="p-fluid" card-body>
        <ng-content select="[dodatni-input]"></ng-content>
      </div>
    </kodit-card-container>
  </div>
</div>

<!-- AKCIJE -->
<div class="p-mt-3" card style="position: sticky; bottom: 0; z-index: 9">
  <kodit-akcije-racuna (saveClicked)="onSave()" (cancelClicked)="onCancel()">
    <ng-content select="[stampanje-racuna]"></ng-content>
  </kodit-akcije-racuna>
</div>
