import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import {
  GetTipoviRacunaQuery,
  SharedClient,
  TipRacuna,
} from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-tip-racuna-dropdown',
  templateUrl: './tip-racuna-dropdown.component.html',
  styleUrls: ['./tip-racuna-dropdown.component.scss'],
})
export class TipRacunaDropdownComponent implements OnInit {
  /** Props */
  items: SelectItem[] = [];
  @Input() allowedTypes: TipRacuna[] = [];
  /** I/O */

  @Input() formCtrlId: string;
  @Input() tipCtrl: FormControl | AbstractControl;
  @Input() isDisabled = false;
  @Output() onItemChange = new EventEmitter<TipRacuna>();
  private _subs: Subscription = new Subscription();

  constructor(private _client: SharedClient) {}

  ngOnInit(): void {
    this._load();
  }

  handleOnItemChange(event: any) {
    this.onItemChange.emit(event);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  private _load() {
    this._subs.add(
      this._client
        .getTipoviRacuna({
          allowedTypes: this.allowedTypes,
        } as GetTipoviRacunaQuery)
        .subscribe((res) => {
          this.items = res.data.tipoviRacunaDtoList.map((x) => ({
            value: x.tip,
            label: x.tipStrLong,
            title: x.opis,
          }));
        })
    );
  }
}
