<div class="p-d-flex p-flex-column gap-6">
  <div class="field-radiobutton p-d-flex p-ai-start">
    <p-radioButton
      [name]="'kurs-' + rowId"
      [value]="iznosPoSrednjemKursu"
      [(ngModel)]="odabraniKurs"
      inputId="srednji"
      (ngModelChange)="handleChange($event)"
    ></p-radioButton>
    <label for="srednji" class="p-ml-3"
      >srednji = {{ iznosPoSrednjemKursu | currencyNumber }}</label
    >
  </div>
  <div class="field-radiobutton p-d-flex p-ai-start">
    <p-radioButton
      [name]="'kurs-' + rowId"
      [value]="iznosPoProdajnomKursu"
      [(ngModel)]="odabraniKurs"
      inputId="prodajni"
      (ngModelChange)="handleChange($event)"
    ></p-radioButton>
    <label for="prodajni" class="p-ml-3"
      >prodajni = {{ iznosPoProdajnomKursu | currencyNumber }}</label
    >
  </div>
<kodit-divider></kodit-divider>
  <div class="field-radiobutton p-d-flex p-ai-start">
    <p-radioButton
      [name]="'kurs-' + rowId"
      [value]="iznosUStranojValuti"
      [(ngModel)]="odabraniKurs"
      inputId="iznos-u-stranoj-valuti"
      (ngModelChange)="handleChange($event)"
    ></p-radioButton>
    <label for="iznos-u-stranoj-valuti" class="p-ml-3"
      >Iznos iz cenovnika: {{ iznosUStranojValuti | currencyNumber : valutaText}}</label
    >
  </div>
</div>
