import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TipPristupa, TipRacuna } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { RacunTableService } from '../racun-table.service';
import { RacunTutorialsService } from '@kodit/core/core-ui';
import { TabPanel } from 'primeng/tabview';
import { AuthService } from '@kodit/core/services';

@Component({
  selector: 'kodit-izlazni-racun-page',
  templateUrl: './izlazni-racun-page.component.html',
  styleUrls: ['./izlazni-racun-page.component.scss'],
})
export class IzlazniRacunPageComponent implements OnInit, OnDestroy {
  private _subs = new Subscription();

  tipIzlaznaFaktura = TipRacuna.IZLAZNA_FAKTURA;
  tipAvansniRacun = TipRacuna.AVANSNI_RACUN;
  tipOdobrenje = TipRacuna.DOKUMENT_O_SMANJENJU;
  tipZaduzenje = TipRacuna.DOKUMENT_O_POVECANJU;
  activeIndex = 0;
  allowedIndecies = [0, 1, 2, 3, 4];

  shouldDisplayFakture: boolean;
  shouldDisplayAvansne: boolean;
  shouldDisplayKO: boolean;
  shouldDisplayKZ: boolean;
  shouldDisplayKomp: boolean;

  /** I/O */
  @ViewChild('Fakture') fakturePanel: TabPanel;
  @ViewChild('Avansni') avansniPanel: TabPanel;
  @ViewChild('DOS') koPanel: TabPanel;
  @ViewChild('KZ') kzPanel: TabPanel;
  @ViewChild('KOMP') kompPanel: TabPanel;

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _tableService: RacunTableService,
    private _racunTutorialService: RacunTutorialsService,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this._setPanelDisplay();

    this.route.queryParams.subscribe((params) => {
      if (params.activeTab) {
        const activeTabIdx = Number.parseInt(params.activeTab);
        if (!isNaN(activeTabIdx) && this.allowedIndecies.includes(0, 0)) {
          this.activeIndex = activeTabIdx;
          this._tableService.setActiveTab = activeTabIdx;
        }
      }
    });

    this._subs.add(
      this._tableService.getActiveTab.subscribe((tabIndex) => {
        this.activeIndex = tabIndex;
        this._racunTutorialService.setActiveTab(tabIndex, this._router.url);
      })
    );
  }
  private _setPanelDisplay() {
    // Fakture
    this.shouldDisplayFakture = this._authService.hasClaim([
      TipPristupa[TipPristupa.IZLAZNA_FAKTURA_CRUD],
      TipPristupa[TipPristupa.IZLAZNA_FAKTURA_VIEW],
    ]);

    // Avansni
    this.shouldDisplayAvansne = this._authService.hasClaim([
      TipPristupa[TipPristupa.AVANSNI_RACUN_CRUD],
      TipPristupa[TipPristupa.AVANSNI_RACUN_VIEW],
    ]);

    // KO
    this.shouldDisplayKO = this._authService.hasClaim([
      TipPristupa[TipPristupa.IZMENA_OSNOVICE_VIEW],
      TipPristupa[TipPristupa.IZMENA_OSNOVICE_CRUD],
    ]);

    // KZ
    this.shouldDisplayKZ = this.shouldDisplayKO;

    // Kompenzacije
    this.shouldDisplayKomp = this._authService.hasClaim([
      TipPristupa[TipPristupa.KOMPENZACIJA_CRUD],
      TipPristupa[TipPristupa.KOMPENZACIJA_VIEW],
    ]);
  }

  handleIndexChange(tabIndex: number) {
    if (this.fakturePanel && this.fakturePanel.selected) {
      this.activeIndex = 0;
    } else if (this.avansniPanel && this.avansniPanel.selected) {
      this.activeIndex = 1;
    } else if (this.koPanel && this.koPanel.selected) {
      this.activeIndex = 2;
    } else if (this.kzPanel && this.kzPanel.selected) {
      this.activeIndex = 3;
    }
    else{
      this.activeIndex = 4;
    }
    this._tableService.setActiveTab = this.activeIndex;
    this._racunTutorialService.setActiveTab(this.activeIndex, this._router.url);
  }

  ngOnDestroy(): void {
    // reset active tab if we're leaving the page
    if (
      this._router.routerState.snapshot.url.indexOf('izlazni-racuni') === -1
    ) {
      this._tableService.resetActiveTab();
    }
    this._subs.unsubscribe();
  }
}
