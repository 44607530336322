<div class="p-flex-column">
  <kodit-field fieldLabel="Tekst pre računa" class="p-fluid">
    <p-editor
            id="tekst-pre"
            [formControl]="form.controls.preamble!"
            [style]="{ height: '150px' }"
            >
            <ng-template pTemplate="header">
                <span class="ql-formats">
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                </span>
            </ng-template>
            </p-editor>
            <kodit-error-info-max-length
            [formCtrl]="form.controls.preamble!"
            ></kodit-error-info-max-length>
  </kodit-field>
  <kodit-field fieldLabel="Tekst posle računa" class="p-fluid">
    <p-editor
            id="tekst-posle"
            [formControl]="form.controls.message!"
            [style]="{ height: '150px' }"
            >
            <ng-template pTemplate="header">
                <span class="ql-formats">
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                </span>
            </ng-template>
            </p-editor>
            <kodit-error-info-max-length
            [formCtrl]="form.controls.message!"
            ></kodit-error-info-max-length>
  </kodit-field>
</div>
