import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AvansniRacunDto, AvansniRacuniClient } from '@kodit/core/data-api';
import { BaseService } from '@kodit/core/services';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AvansniRacunIzPredracunaResolverService
  extends BaseService
  implements Resolve<AvansniRacunDto> {
  constructor(private _client: AvansniRacuniClient) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | AvansniRacunDto
    | Observable<AvansniRacunDto>
    | Promise<AvansniRacunDto>
    | null {
    const id = Number.parseFloat(route.paramMap.get('predracunId'));
    return this._client.getAvansniForOtpremnica(id).pipe(
      map((x) => x.data),
      catchError(this.handleError),
      finalize(() => {
        ///
      })
    );
  }
}
