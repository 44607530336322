import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-only-numbers-text-input',
  templateUrl: './only-numbers-text-input.component.html',
  styleUrls: ['./only-numbers-text-input.component.scss'],
})
export class OnlyNumbersTextInputComponent implements OnInit {
  onlyNumbers: RegExp = /^-?[0-9]*$/;

  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string = 'only-numbers-input';
  @Input() formCtrlMaxLength: number;

  constructor() {}

  ngOnInit(): void {}
}
