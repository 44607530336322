import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddPredefinisaniTekstCommand, FiskalniRacuniClient, IPredefinisaniTekstDto} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogService } from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { PredefinisaniTekstFiskalniFormComponent } from '../predefinisani-tekst-fiskalni-form/predefinisani-tekst-fiskalni-form.component';

@Component({
  selector: 'kodit-predefinisani-tekst-fiskalni',
  templateUrl: './predefinisani-tekst-fiskalni.component.html',
  styleUrls: ['./predefinisani-tekst-fiskalni.component.scss']
})
export class PredefinisaniTekstFiskalniComponent implements OnInit, OnDestroy {

   /** Subs */
   private _subs: Subscription = new Subscription();

   /** Configuration */
   dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);
 
   /** Props */
   textBefore: string;
   textAfter: string;
   constructor(
     private _dialogService: DynamicDialogService,
     private _client: FiskalniRacuniClient,
     private _alertService: AlertService
   ) {}
 
   ngOnInit(): void {
     this.load();
   }
 
   onEdit() {
     this.dialogConfig.data = {
        textBefore: this.textBefore,
        textAfter: this.textAfter
     };
     this.dialogConfig.header = 'Izmena predefinisanog teksta';
     this.openDialog(this.dialogConfig);
   }
 
   openDialog(config: DynamicDialogConfig): void {
     const ref = this._dialogService.open(PredefinisaniTekstFiskalniFormComponent, config);
 
     this._subs.add(
      ref.onClose.subscribe((result) => {
        if (result) {
          this.load();
        }
      })
    );
   }
 
   private load() {
     this._subs.add(
       this._client.getPredefinisaniTekst().subscribe(
         (result) => {
           this.textBefore = result.data.textBefore;
           this.textAfter = result.data.textAfter;
         },
         (error) => {
           this._subs.add(
             error.subscribe((x: string) => {
               this._alertService.addFailedMsg(x);
             })
           );
         }
       )
     );
   }
 
   ngOnDestroy() {
     this._subs.unsubscribe();
   }

}
