import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PdvEvidencijeClient, PdvOpseg, PdvPeriodDto } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-period-mesec-dropdown',
  templateUrl: './period-mesec-dropdown.component.html',
  styleUrls: ['./period-mesec-dropdown.component.css'],
})
export class PeriodMesecDropdownComponent implements OnInit {
  /** Subs */
  private _subs: Subscription;

  /** Props */
  items: SelectItem[];
  pdvPeriodi: PdvPeriodDto[];

  /** I/O */
  @Input() formCtrl: AbstractControl | undefined;
  @Input() formCtrlId: string;
  @Input() parentCtrl: string;

  constructor(private _client: PdvEvidencijeClient) {
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client.getPdvPeriodDropdown().subscribe((res) => {
        if (!res.succeeded) {
          return;
        }
        this.pdvPeriodi = res.data!.responseList!;
        this._setMesecni();
      })
    );
  }

  private _setMesecni() {
    this.items = this.pdvPeriodi
      .filter((x) => x.opseg === PdvOpseg.MESECNO)
      .map((x) => ({
        value: x.period,
        label: x.periodStr,
        title: x.opis,
      }));
    this.items.unshift({
      value: null,
      label: 'Izaberite period',
      title: 'Izaberite period',
    });
  }
}
