import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { BaseService } from '@kodit/core/services';
import {
  OtpremnicaDto,
  OtpremniceClient,
  PredracunDto,
  PredracuniClient,
} from '@kodit/core/data-api';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PredracunIzPonudeResolver
  extends BaseService
  implements Resolve<PredracunDto> {
  constructor(private _client: PredracuniClient) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PredracunDto> | Promise<PredracunDto> | PredracunDto | null {
    const id = Number.parseInt(route.paramMap.get('racunId'));
    return this._client.getNewForPonuda(id).pipe(
      map((x) => x.data),
      catchError(this.handleError),
      finalize(() => {})
    );
  }
}
