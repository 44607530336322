import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CreatePdvEvidencijaZbirnaCommand,
  IPdvEvidencijaZbirnaDto,
  PdvEvidencijeClient,
  SefKorigujPdvEvidencijaZbirnaCommand,
  UpdatePdvEvidencijaZbirnaCommand,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService, LocationService, SharedService } from '@kodit/core/services';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { PdvEvidencijaService } from '../../pdv-evidencija.service';
import { PdvEvidencijaZbirnaFormService } from '../pdv-evidencija-zbirna-form.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ConfirmationService } from 'primeng/api';
@Component({
  selector: 'kodit-pdv-evidencija-zbirna-form',
  templateUrl: './pdv-evidencija-zbirna-form.component.html',
  styleUrls: ['./pdv-evidencija-zbirna-form.component.scss'],
})
export class PdvEvidencijaZbirnaFormComponent implements OnInit {
  private _subs: Subscription = new Subscription();

  form!: FormGroupTypeSafe<IPdvEvidencijaZbirnaDto>;
  isKoriguj: boolean;
  shouldDisableForm: boolean = false;

  constructor(
    private _fs: PdvEvidencijaZbirnaFormService,
    private _service: PdvEvidencijaService,
    private _client: PdvEvidencijeClient,
    private _alert: AlertService,
    private _route: ActivatedRoute,
    private _formHelper: FormHelper,
    private _locationService: LocationService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _breadcrumbService: BreadcrumbService,
    private _confirmationService: ConfirmationService,
    private _sharedService: SharedService
  ) {
    this.isKoriguj = false;
  }

  ngOnInit(): void {
    this.shouldDisableForm = this._route.snapshot.data['isPregledKorigovane'];
    this.isKoriguj = this._route.snapshot.data['isKoriguj'];
    this.form = this._fs.getForm(
      this._route.snapshot.data.evidencija,
      this.isKoriguj
    );
    this.form.controls.status?.disable();
    this._subs.add(
      this.form.controls.prometSNaknadomOsnovica20!.valueChanges.subscribe(
        (res) => {
          this.form.controls.prometSNaknadomPdv20?.patchValue(res! * 0.2);
          this.form.controls.prometSNaknadomUkupanIznos20?.patchValue(
            res! + res! * 0.2
          );
        }
      )
    );

    this._subs.add(
      this.form.controls.prometSNaknadomOsnovica10!.valueChanges.subscribe(
        (res) => {
          this.form.controls.prometSNaknadomPdv10?.patchValue(res! * 0.1);
          this.form.controls.prometSNaknadomUkupanIznos10?.patchValue(
            res! + res! * 0.1
          );
        }
      )
    );

    this._subs.add(
      this.form.controls.prometAvansOsnovica20!.valueChanges.subscribe((res) => {
        this.form.controls.prometAvansPdv20?.patchValue(res!* 0.2);
        this.form.controls.prometAvansUkupanIznos20?.patchValue(
          res! + res! * 0.2
        );
      })
    );

    this._subs.add(
      this.form.controls.prometAvansOsnovica10!.valueChanges.subscribe((res) => {
        this.form.controls.prometAvansPdv10?.patchValue(res! * 0.1);
        this.form.controls.prometAvansUkupanIznos10?.patchValue(
          res! + res! * 0.1
        );
      })
    );

    this._subs.add(
      this.form.controls.prometBezNaknadeOsnovica20!.valueChanges.subscribe(
        (res) => {
          this.form.controls.prometBezNaknadePdv20?.patchValue(res! * 0.2);
          this.form.controls.prometBezNaknadeUkupanIznos20?.patchValue(
            res! + res! * 0.2
          );
        }
      )
    );

    this._subs.add(
      this.form.controls.prometBezNaknadeOsnovica10!.valueChanges.subscribe(
        (res) => {
          this.form.controls.prometBezNaknadePdv10?.patchValue(res! * 0.1);
          this.form.controls.prometBezNaknadeUkupanIznos10?.patchValue(
            res! + res! * 0.1
          );
        }
      )
    );
    this._setBreadcrumb();
  }
  private _setBreadcrumb() {
    this._breadcrumbService.set(
      '@izmenaZbirnePdvEvidencije',
      `Izmena zbirne pdv evidencije  ${this.form.value.broj}`
    );
    this._breadcrumbService.set(
      '@korigovanjeZbirnePdvEvidencije',
      `Korigovanje zbirne pdv evidencije  ${this.form.getRawValue().broj}`
    );
    this._breadcrumbService.set(
      '@pregledKorigovaneZbirnePdvEvidencije',
      `Pregled korigovane pdv evidencije: ${this.form.getRawValue().broj}`
    );
  }

  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  handleCancel() {
    this._confirmationService.confirm({
      message: 'Ukoliko otkažete sav nesnimljen sadržaj će biti izgubljen.',
      acceptLabel: 'Otkaži',
      rejectLabel: 'Ostani na stranici',
      header: 'Potvrdite otkazivanje',
      icon: 'far fa-check-circle',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._locationService.routeToCancelPdvEvidencija();
      },
    });
  }

  handleEvidentiraj() {}

  handleSave() {
    // check is it valid
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    // everything is ok, let's save it
    if (this.isKoriguj) {
      this._sharedService.displayLoadingScreen(
        true,
        'Korigovanje zbirne pdv evidencije...'
      );
      this._subs.add(
        this._client
          .sefKorigujPdvEvidencijaZbirna(
            this.form.value.id!,
            this.form.getRawValue() as SefKorigujPdvEvidencijaZbirnaCommand
          )
          .subscribe((res) => {
            // if (res.succeeded) {
            // }
            this._alert.addSuccessMsg(res.messages![0]);
            this._locationService.routeBack();
          })
      );
    } else if (this._fs.jeEdit) {
      this._sharedService.displayLoadingScreen(
        true,
        'Ažuriranje zbirne pdv evidencije...'
      );
      this._subs.add(
        this._client
          .updateZbirna(
            this.form.value.id!,
            this.form.value as UpdatePdvEvidencijaZbirnaCommand
          )
          .subscribe((res) => {
            // if (res.succeeded) {
            // }
            this._alert.addSuccessMsg(res.messages![0]);
            this._locationService.routeBack();
          })
      );
    } else {
      this._sharedService.displayLoadingScreen(
        true,
        'Kreiranje zbirne pdv evidencije...'
      );
      this._subs.add(
        this._client
          .createZbirna(this.form.value as CreatePdvEvidencijaZbirnaCommand)
          .subscribe((res) => {
            // if (res.succeeded) {
            // }
            this._alert.addSuccessMsg(res.messages![0]);
            this._locationService.routeBack();
          })
      );
    }
  }

  handleRouteBack(){
    this._locationService.routeBack();
  }

  ngOnDestroy() {
    this._fs.resetForm();
    this._subs.unsubscribe();
  }
}
