import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { TipArtikla } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api/selectitem';

@Component({
  selector: 'kodit-tip-artikla-dropdown',
  templateUrl: './tip-artikla-dropdown.component.html',
  styleUrls: ['./tip-artikla-dropdown.component.scss'],
})
export class TipArtiklaDropdownComponent implements OnInit {
  /** props */
  items: SelectItem[];

  /** I/O */
  @Input() tipUplateCtrl: FormControl | AbstractControl;
  @Output() onItemChange = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {
    this.items = [
      { label: 'Roba', value: TipArtikla.ROBA },
      {
        label: 'Sirovine i osnovni materijal',
        value: TipArtikla.SIROVINE_MATERIJAL,
      },
      { label: 'Poluproizvod', value: TipArtikla.POLUPROIZVOD },
      { label: 'Proizvod', value: TipArtikla.PROIZVOD },
    ];
  }

  handleOnItemChange(event: any) {
    this.onItemChange.emit(event);
  }
}
