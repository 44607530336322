import { isObservable, NEVER, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import {
  ErrorResult,
  ProblemDetails,
  ValidationProblemDetails,
} from '@kodit/core/data-api';
import { AuthService, MixpanelService } from '../../index';
import { AlertService } from '../alert.service';
import { ButtonService } from '../button.service';

const ALLOWED_ANONYMOUS_ROUTES = [
  '/api/tokens',
  '/api/stranka/search-from-kps',
  '/api/identity/confirm-email',
  '/api/identity/reset-password-init',
  '/api/identity/reset-password',
  '/api/identity/update-user-access-password',
  '/api/identity/create-korisnik',
  '/api/sef/get-izlazne-status-notifications',
  '/api/paketi/get-base-dropdown',
  '/api/paketi/get-addition-dropdown',
  '/api/identity/check-password-validity',
];

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptorService implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _alertService: AlertService,
    private _btnService: ButtonService,
    private _mixpanelService: MixpanelService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // req = req.clone({
    //   setParams: { tenantKey: 'root' },
    // });

    // ToDo: Ovde mozda da mu kazem ukoliko je 401 idi na login,
    // ne vredi je ne mozemo da znamo koji je kod greske... problem je na backu
    // ne mozemo onako da radimo handlanje errora
    // ! za pocetak mozemo ovako da vidimo da li je istekao token
    if (
      !this._authService.isUserAuthenticated() &&
      !ALLOWED_ANONYMOUS_ROUTES.some((x) => req.url.includes(x))
    ) {
      this._authService.removeTokens();
      this._router.navigate(['/autentikacija/prijava']).then();
      window.location.reload();
      return NEVER;
    }

    return next.handle(req).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        this._btnService.enableButton();
        let errorMessage = this.handleError(error);
        if (
          typeof errorMessage === 'string' ||
          errorMessage instanceof String
        ) {
          this._mixpanelService.handleErrorInterceptor(
            error,
            error.status.toString()
          );
          this._alertService.addFailedMsg(errorMessage as string);
        } else if (isObservable<string[]>(errorMessage)) {
          (errorMessage as Observable<string[]>).subscribe(
            (x: string[] | string) => {
              this._mixpanelService.handleErrorInterceptor(
                x,
                error.status.toString()
              );
              if (typeof x === 'string') {
                this._alertService.addFailedMsg(x);
              } else {
                this._alertService.addFailedMsgs(x);
              }
            }
          );
        } else {
          (errorMessage as Observable<string>).subscribe((x: string) => {
            this._mixpanelService.handleErrorInterceptor(
              x,
              error.status.toString()
            );
            this._alertService.addFailedMsg(x);
          });
        }
        return throwError(() => errorMessage);
      })
    );
  }

  private handleError = (
    error: HttpErrorResponse
  ):
    | string
    | Observable<string>
    | Observable<string[]>
    | Observable<HtmlErrorResponse> => {
    //('handleError - doslo je do greske: ', error);
    switch (error.status) {
      case 0:
        return 'Proverite internet konekciju i podatke za pristup';

      case 400:
      case 422:
        return this.handleBadRequest(error);

      case 401:
        return this.handleUnauthorized(error);

      case 403:
        return this.handleForbidden(error);

      case 404:
        return this.handleNotFound(error);

      case 413:
        return 'Premašena dozvoljena veličina datoteka';

      default:
        return 'Došlo je do nepoznate greške';
    }
  };

  private handleValidation(error: HttpErrorResponse) {}

  private handleForbidden = (error: HttpErrorResponse) => {
    this._router
      .navigate(['/forbidden'], {
        queryParams: { returnUrl: this._router.url },
      })
      .then();
    return this.blobToTextErrors(error.error);
  };

  private handleUnauthorized = (error: HttpErrorResponse) => {
    if (this._router.url.startsWith('/autentikacija')) {
      return this.blobToTextErrors(error.error);
    } else {
      this._router
        .navigate(['/autentikacija/prijava'], {
          queryParams: { returnUrl: this._router.url },
        })
        .then();
      return this.blobToTextErrors(error.error);
    }
  };

  private handleNotFound = (error: HttpErrorResponse): Observable<string> => {
    // this._router.navigate(['/404']);
    // return error.message;
    return this.blobToTextErrors(error.error);
  };

  private handleBadRequest = (
    error: HttpErrorResponse
  ):
    | Observable<string>
    | Observable<string[]>
    | string
    | Observable<HtmlErrorResponse> => {
    return this.blobToTextErrorsArray(error.error);
  };

  blobToTextErrorsArray(blob: any): Observable<string[]> {
    return new Observable<string[]>((observer: any) => {
      if (!blob) {
        observer.next('');
        observer.complete();
      } else {
        let reader = new FileReader();
        reader.onload = (event) => {
          let errorText: string[] = [];

          const jsonResult = JSON.parse(reader.result as string); // default / api filters
          const errorIsErrorResult = ErrorResult.fromJS(jsonResult); // middleware
          const errorIsProblemDetails = ProblemDetails.fromJS(jsonResult); // api filters
          const errorIsValidationProblemDetails = ValidationProblemDetails.fromJS(
            jsonResult
          ); // api filters
          if (errorIsErrorResult.errorCode) {
            errorText.push(
              errorIsErrorResult.messages?.join(SPLIT_MESSAGE) ??
                'Tekst greške je prazan'
            );
          } else if (
            errorIsValidationProblemDetails.status &&
            errorIsValidationProblemDetails.errors
          ) {
            for (const error in errorIsValidationProblemDetails['errors']) {
              for (const key in errorIsValidationProblemDetails['errors'][
                error
              ]) {
                errorText.push(
                  errorIsValidationProblemDetails['errors'][error][key] +
                    SPLIT_MESSAGE
                );
              }
            }
          } else if (errorIsProblemDetails.status) {
            errorText.push(
              errorIsProblemDetails.detail ?? 'Tekst greške je prazan'
            );
          } else if (jsonResult.detail) {
            errorText.push(jsonResult.detail);
          } else if (jsonResult.errors) {
            let errorObj: any;
            let modelStateErrors = [];
            for (errorObj in jsonResult.errors) {
              for (let key in jsonResult.errors[errorObj]) {
                modelStateErrors.push(jsonResult.errors[errorObj][key]);
              }
            }
            errorText = modelStateErrors;
          } else if (jsonResult.title) {
            errorText = jsonResult.title;
          } else if (
            typeof jsonResult === 'string' ||
            jsonResult instanceof String
          ) {
            errorText.push(jsonResult.toString());
          } else {
            errorText.push('Došlo je do nepoznate greške');
          }

          observer.next(errorText);
          observer.complete();
        };
        reader.readAsText(blob);
      }
    });
  }

  blobToTextAsHtmlErrors(blob: any): Observable<HtmlErrorResponse> {
    return new Observable<HtmlErrorResponse>((observer: any) => {
      if (!blob) {
        observer.next('');
        observer.complete();
      } else {
        let reader = new FileReader();
        let jsonResult: any;
        reader.onload = (event) => {
          let result: HtmlErrorResponse = {};
          let errorText: string = '<ul>';
          jsonResult = JSON.parse(reader.result as string);
          if (jsonResult.detail) {
            errorText += `<li>${jsonResult.detail}</li></ul>`;
            result = { details: errorText };
          } else if (jsonResult.errors) {
            let propertyName: any;
            for (propertyName in jsonResult.errors) {
              errorText = '<ul>';
              for (var key in jsonResult.errors[propertyName]) {
                errorText += `<li>${jsonResult.errors[propertyName][key]}</li>`;
              }
              errorText += '</ul>';
              result = { ...result, [propertyName]: errorText };
            }
          } else if (jsonResult.title) {
            errorText = `<li>${jsonResult.title}</li>`;
          } else if (
            typeof jsonResult === 'string' ||
            jsonResult instanceof String
          ) {
            errorText = `<li>${jsonResult}</li>`;
          } else {
            ('<li>Došlo je do nepoznate greške</li>');
          }

          observer.next(result);
          observer.complete();
        };
        reader.readAsText(blob);
      }
    });
  }

  blobToTextErrors(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
      if (!blob) {
        observer.next('');
        observer.complete();
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const jsonResult = JSON.parse(reader.result as string);
          const errorIsErrorResult = ErrorResult.fromJS(jsonResult);
          const errorIsProblemDetails = ProblemDetails.fromJS(jsonResult);
          let errorText = '';

          if (errorIsErrorResult.errorCode) {
            errorText =
              errorIsErrorResult.messages?.join(SPLIT_MESSAGE) ??
              'Error text is empty';
          } else if (errorIsProblemDetails.status) {
            if (errorIsProblemDetails['errors']) {
              for (const error in errorIsProblemDetails['errors']) {
                for (const key in errorIsProblemDetails['errors'][error]) {
                  errorText +=
                    errorIsProblemDetails['errors'][error][key] + SPLIT_MESSAGE;
                }
              }
            } else {
              errorText =
                errorIsProblemDetails.detail ?? 'Tekst greške je prazan';
            }
          } else {
            errorText = 'Došlo je do nepoznate greške';
          }

          observer.next(errorText);
          observer.complete();
        };
        reader.readAsText(blob);
      }
    });
  }
}

export interface HtmlErrorResponse {
  [propertyName: string]: string;
}

export const SPLIT_MESSAGE = '[SPLIT]';
