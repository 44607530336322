<div class="p-d-flex p-flex-column paket-menu-wrapper">
  <div class="p-d-flex p-flex-row">
    <div class="p-d-flex p-flex-column title-wrapper flex-2">
      <div class="p-d-flex p-flex-row p-jc-between p-ai-center">
        <div class="funkcije-title">Odaberite željene funkcije</div>
      </div>
      <div class="paket-description">
        Ovde vršite odabir željenih funkcija/paketa u programu
      </div>
    </div>
    <div class="p-d-flex flex-1 izabrano-wrapper">
      <div class="p-d-flex p-flex-column">
        <div class="izabrano-title">Izabrano</div>
        <div class="izabrano-subtitle">Vaše izabrane funkcije</div>
      </div>
    </div>
  </div>
  <div class="p-d-flex p-flex-row paketi-wrapper">
    <div class="p-d-flex p-flex-column flex-2 spisak-paketa-wrapper">
      <ng-container [ngTemplateOutlet]="besplatno"></ng-container>
      <ng-container [ngTemplateOutlet]="fakture"></ng-container>
      <ng-container [ngTemplateOutlet]="veleprodaja"></ng-container>
      <ng-container [ngTemplateOutlet]="arhiva"></ng-container>
      <ng-container [ngTemplateOutlet]="fiskalnaKasa"></ng-container>
      <ng-container [ngTemplateOutlet]="maloprodaja"></ng-container>
    </div>
    <div class="p-d-flex flex-1 izabrani-paketi-wrapper">
      <div
        class="p-d-flex p-flex-column p-p-4 flex-1 izabrani-paketi-sub-wrapper"
      >
        <div
          *ngFor="let item of selectedPackages"
          class="p-d-flex p-flex-row p-jc-between p-ai-center p-mb-4"
        >
          <div class="selected-item-title">{{ item.name }}</div>
          <div class="selected-item-price">{{ item.price }}e</div>
        </div>
        <div
          *ngIf="form.controls.brojFiskalnihKasa.value > 0"
          class="p-d-flex p-flex-row p-jc-between p-ai-center p-mb-4"
        >
          <div class="selected-item-title">Broj fiskalnih kasa</div>
          <div class="selected-item-price">
            {{ form.controls.brojFiskalnihKasa.value }} x 20e
          </div>
        </div>
        <div
          *ngIf="form.controls.brojIstovremenoAktivnihKorisnika.value > 0"
          class="p-d-flex p-flex-row p-jc-between p-ai-center p-mb-4"
        >
          <div class="selected-item-title">
            Broj istovremeno aktivnih korisnika
          </div>
          <div class="selected-item-price">
            {{ form.controls.brojIstovremenoAktivnihKorisnika.value }} x 5e
          </div>
        </div>
        <div
          *ngIf="form.controls.kolicinaProstora.value > 0"
          class="p-d-flex p-flex-row p-jc-between p-ai-center p-mb-4"
        >
          <div class="selected-item-title">Dodatna količina prostora</div>
          <div class="selected-item-price">
            {{ form.controls.kolicinaProstora.value }} x 15e
          </div>
        </div>
        <div *ngIf="selectedPackages.length > 0" class="p-d-flex p-flex-column">
          <div class="horizontal-break-line"></div>
          <div class="p-d-flex p-flex-row p-jc-between p-ai-center">
            <div class="total-title">Total</div>
            <div class="total-price">{{ totalPackagesPrice }}e</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="p-d-flex p-jc-end">
  <div
    (click)="routeToNextStep()"
    class="p-d-flex p-jc-center p-ai-center dalje-button"
  >
    Dalje
  </div>
</div>

<ng-template #besplatno>
  <div class="p-d-flex p-flex-column paket-card">
    <div
      class="p-d-flex p-flex-row p-ai-center p-jc-between package-header"
      (click)="
        handlePackageHeaderClick(
          packagesForm.controls.besplatno,
          shouldDisableBesplatno
        )
      "
    >
      <div class="p-field-checkbox">
        <p-checkbox
          [binary]="true"
          [disabled]="shouldDisableBesplatno"
          [formControl]="packagesForm.controls.besplatno | formControl"
          (click)="
            handlePackageHeaderClick(
              packagesForm.controls.besplatno,
              shouldDisableBesplatno
            )
          "
        >
        </p-checkbox>
        <label
          [ngClass]="
            shouldDisableBesplatno ? 'package-name-disabled' : 'package-name'
          "
          >Besplatno</label
        >
      </div>
      <div class="efakture-price-text">0e</div>
    </div>
    <div *ngIf="packagesForm.controls.besplatno.value" class="p-p-4">
      <div class="p-d-flex p-flex-column">
        <div class="horizontal-break-line"></div>
        <div>Rad sa ulaznim računima</div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fakture>
  <div class="p-d-flex p-flex-column paket-card p-mt-4">
    <div
      class="p-d-flex p-flex-row p-ai-center p-jc-between package-header"
      (click)="
        handlePackageHeaderClick(
          packagesForm.controls.eFakture,
          shouldDisableFakture
        )
      "
    >
      <div class="p-field-checkbox">
        <p-checkbox
          [binary]="true"
          [disabled]="shouldDisableFakture"
          [formControl]="packagesForm.controls.eFakture | formControl"
          (click)="
            handlePackageHeaderClick(
              packagesForm.controls.eFakture,
              shouldDisableFakture
            )
          "
        >
        </p-checkbox>
        <label
          [ngClass]="
            shouldDisableFakture ? 'package-name-disabled' : 'package-name'
          "
          >eFakture</label
        >
      </div>
      <div class="efakture-price-text">20e</div>
    </div>
    <div *ngIf="packagesForm.controls.eFakture.value" class="p-p-4">
      <div class="p-d-flex p-flex-column">
        <div class="horizontal-break-line"></div>
        <div class="p-d-flex p-flex-row p-jc-between p-ai-center">
          <div class="aktivni-korisnici-text">
            Dodatni broj istovremeno aktivnih korisnika
          </div>
          <div class="p-d-flex p-flex-row p-ai-center">
            <div class="price-text">+5e</div>
            <div class="price-description-text p-ml-1">po korisniku</div>
          </div>
        </div>
        <div class="custom-number-input flex-1">
          <kodit-number-input
            [buttonLayout]="'horizontal'"
            [ctrlMinValue]="0"
            [ctrlOnlyIntegers]="true"
            [decrementButtonClass]="'p-button-danger'"
            [decrementButtonIcon]="'pi pi-minus'"
            [formCtrl]="form?.controls?.brojIstovremenoAktivnihKorisnika"
            [incrementButtonClass]="'p-button-success'"
            [incrementButtonIcon]="'pi pi-plus'"
            [showButtons]="true"
            [spinnerMode]="'horizontal'"
          >
          </kodit-number-input>
        </div>
        <div class="aktivni-korisnici-text p-mt-4 p-mb-4">
          Dodatne funkcionalnosti
        </div>
        <div class="p-d-flex p-flex-row p-jc-between p-ai-center p-mb-3">
          <div class="p-field-checkbox">
            <p-checkbox
              [binary]="true"
              [formControl]="packagesForm.controls.proizvodnja"
            ></p-checkbox>
            <label class="additional-package-name">Proizvodnja</label>
          </div>
          <div class="price-text">+5e</div>
        </div>
        <div class="p-d-flex p-flex-row p-jc-between p-ai-center">
          <div class="p-field-checkbox">
            <p-checkbox
              [binary]="true"
              [formControl]="packagesForm.controls.finansije"
            ></p-checkbox>
            <label class="additional-package-name">Finansije</label>
          </div>
          <div class="price-text">+10e</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #veleprodaja>
  <div class="p-d-flex p-flex-column paket-card p-mt-4">
    <div
      class="p-d-flex p-flex-row p-ai-center p-jc-between package-header"
      (click)="
        handlePackageHeaderClick(
          packagesForm.controls.veleprodaja,
          shouldDisableVeleprodaja
        )
      "
    >
      <div class="p-field-checkbox">
        <p-checkbox
          [binary]="true"
          [disabled]="shouldDisableVeleprodaja"
          [formControl]="packagesForm.controls.veleprodaja | formControl"
          (click)="
            handlePackageHeaderClick(
              packagesForm.controls.veleprodaja,
              shouldDisableVeleprodaja
            )
          "
        ></p-checkbox>
        <label
          [ngClass]="
            shouldDisableVeleprodaja ? 'package-name-disabled' : 'package-name'
          "
          class="package-name"
          >Veleprodaja</label
        >
      </div>
      <div class="efakture-price-text">25e</div>
    </div>
    <div *ngIf="packagesForm.controls.veleprodaja.value" class="p-p-4">
      <div class="p-d-flex p-flex-column">
        <div class="horizontal-break-line"></div>
        <div class="p-d-flex p-flex-row p-jc-between p-ai-center">
          <div class="aktivni-korisnici-text">
            Dodatni broj istovremeno aktivnih korisnika
          </div>
          <div class="p-d-flex p-flex-row p-ai-center">
            <div class="price-text">+5e</div>
            <div class="price-description-text p-ml-1">po korisniku</div>
          </div>
        </div>
        <div class="custom-number-input flex-1">
          <kodit-number-input
            [buttonLayout]="'horizontal'"
            [ctrlMinValue]="0"
            [ctrlOnlyIntegers]="true"
            [decrementButtonClass]="'p-button-danger'"
            [decrementButtonIcon]="'pi pi-minus'"
            [formCtrl]="form?.controls?.brojIstovremenoAktivnihKorisnika"
            [incrementButtonClass]="'p-button-success'"
            [incrementButtonIcon]="'pi pi-plus'"
            [showButtons]="true"
            [spinnerMode]="'horizontal'"
          >
          </kodit-number-input>
        </div>
        <div class="aktivni-korisnici-text p-mt-4 p-mb-4">
          Dodatne funkcionalnosti
        </div>
        <div class="p-d-flex p-flex-row p-jc-between p-ai-center p-mb-3">
          <div class="p-field-checkbox">
            <p-checkbox
              [binary]="true"
              [formControl]="packagesForm.controls.proizvodnja | formControl"
            ></p-checkbox>
            <label class="additional-package-name">Proizvodnja</label>
          </div>
          <div class="price-text">+5e</div>
        </div>
        <div class="p-d-flex p-flex-row p-jc-between p-ai-center">
          <div class="p-field-checkbox">
            <p-checkbox
              [binary]="true"
              [formControl]="packagesForm.controls.finansije | formControl"
            ></p-checkbox>
            <label class="additional-package-name">Finansije</label>
          </div>
          <div class="price-text">+10e</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #maloprodaja>
  <div class="p-d-flex p-flex-column paket-card p-mt-4">
    <div
      class="p-d-flex p-flex-row p-ai-center p-jc-between package-header"
      (click)="
        handlePackageHeaderClick(
          packagesForm.controls.maloprodaja,
          shouldDisableMaloprodaja
        )
      "
    >
      <div class="p-field-checkbox">
        <p-checkbox
          [binary]="true"
          [disabled]="shouldDisableMaloprodaja"
          [formControl]="packagesForm.controls.maloprodaja | formControl"
          (click)="
            handlePackageHeaderClick(
              packagesForm.controls.maloprodaja,
              shouldDisableMaloprodaja
            )
          "
        ></p-checkbox>
        <label
          [ngClass]="
            shouldDisableMaloprodaja ? 'package-name-disabled' : 'package-name'
          "
          >Maloprodaja</label
        >
      </div>
      <div class="efakture-price-text">20e</div>
    </div>
    <div *ngIf="packagesForm.controls.maloprodaja.value" class="p-p-4">
      <div class="p-d-flex p-flex-column">
        <div class="horizontal-break-line"></div>
        <div class="p-d-flex p-flex-row p-jc-between p-ai-center">
          <div class="aktivni-korisnici-text">Broj fiskalnih kasa</div>
          <div class="p-d-flex p-flex-row p-ai-center">
            <div class="price-text">+20e</div>
            <div class="price-description-text p-ml-1">po kasi</div>
          </div>
        </div>
        <div class="custom-number-input flex-1">
          <kodit-number-input
            [buttonLayout]="'horizontal'"
            [ctrlMinValue]="0"
            [ctrlOnlyIntegers]="true"
            [decrementButtonClass]="'p-button-danger'"
            [decrementButtonIcon]="'pi pi-minus'"
            [formCtrl]="form?.controls?.brojFiskalnihKasa"
            [incrementButtonClass]="'p-button-success'"
            [incrementButtonIcon]="'pi pi-plus'"
            [showButtons]="true"
            [spinnerMode]="'horizontal'"
          >
          </kodit-number-input>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fiskalnaKasa>
  <div class="p-d-flex p-flex-column paket-card p-mt-4">
    <div
      class="p-d-flex p-flex-row p-ai-center p-jc-between package-header"
      (click)="
        handlePackageHeaderClick(
          packagesForm.controls.fiskalnaKasa,
          shouldDisableFiskalnaKasa
        )
      "
    >
      <div class="p-field-checkbox">
        <p-checkbox
          [binary]="true"
          [disabled]="shouldDisableFiskalnaKasa"
          [formControl]="packagesForm.controls.fiskalnaKasa | formControl"
          (click)="
            handlePackageHeaderClick(
              packagesForm.controls.fiskalnaKasa,
              shouldDisableFiskalnaKasa
            )
          "
        ></p-checkbox>
        <label
          [ngClass]="
            shouldDisableFiskalnaKasa ? 'package-name-disabled' : 'package-name'
          "
          >Fiskalna kasa</label
        >
      </div>
      <div class="efakture-price-text">15e</div>
    </div>
    <div *ngIf="packagesForm.controls.fiskalnaKasa.value" class="p-p-4">
      <div class="p-d-flex p-flex-column">
        <div class="horizontal-break-line"></div>
        <div class="p-d-flex p-flex-row p-jc-between p-ai-center">
          <div class="aktivni-korisnici-text">Broj fiskalnih kasa</div>
          <div class="p-d-flex p-flex-row p-ai-center">
            <div class="price-text">+20e</div>
            <div class="price-description-text p-ml-1">po kasi</div>
          </div>
        </div>
        <div class="custom-number-input flex-1">
          <kodit-number-input
            [buttonLayout]="'horizontal'"
            [ctrlMinValue]="0"
            [ctrlOnlyIntegers]="true"
            [decrementButtonClass]="'p-button-danger'"
            [decrementButtonIcon]="'pi pi-minus'"
            [formCtrl]="form?.controls?.brojFiskalnihKasa"
            [incrementButtonClass]="'p-button-success'"
            [incrementButtonIcon]="'pi pi-plus'"
            [showButtons]="true"
            [spinnerMode]="'horizontal'"
          >
          </kodit-number-input>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #arhiva>
  <div class="p-d-flex p-flex-column paket-card p-mt-4">
    <div
      class="p-d-flex p-flex-row p-ai-center p-jc-between package-header"
      (click)="
        handlePackageHeaderClick(
          packagesForm.controls.arhivaIDelovodnik,
          shouldDisableArhiva
        )
      "
    >
      <div class="p-field-checkbox">
        <p-checkbox
          [binary]="true"
          [disabled]="shouldDisableArhiva"
          [formControl]="packagesForm.controls.arhivaIDelovodnik | formControl"
          (click)="
            handlePackageHeaderClick(
              packagesForm.controls.arhivaIDelovodnik,
              shouldDisableArhiva
            )
          "
        ></p-checkbox>
        <label
          [ngClass]="
            shouldDisableArhiva ? 'package-name-disabled' : 'package-name'
          "
          >Arhiva i delovodnik</label
        >
      </div>
      <div class="efakture-price-text">20e</div>
    </div>
    <div *ngIf="packagesForm.controls.arhivaIDelovodnik.value" class="p-p-4">
      <div class="p-d-flex p-flex-column">
        <div class="horizontal-break-line"></div>
        <div class="p-d-flex p-flex-row p-jc-between p-ai-center">
          <div class="aktivni-korisnici-text">Količina prostora</div>
          <div class="p-d-flex p-flex-row p-ai-center">
            <div class="price-text">+15e</div>
          </div>
        </div>
        <div class="p-d-flex p-flex-column flex-1 p-mt-4 slider">
          <p-slider
            [formControl]="form.controls.kolicinaProstora | formControl"
            [max]="4"
            [min]="0"
            [step]="1"
            class="flex-1"
          ></p-slider>
          <div class="p-d-flex p-flex-row p-jc-between p-mt-3">
            <div
              [ngClass]="
                form.controls.kolicinaProstora.value == 0
                  ? 'selected-kolicina-prostora'
                  : 'non-selected-kolicina-prostora'
              "
              class="kolicina-prostora-left"
            >
              1gb
            </div>
            <div
              [ngClass]="
                form.controls.kolicinaProstora.value == 25
                  ? 'selected-kolicina-prostora'
                  : 'non-selected-kolicina-prostora'
              "
              class="kolicina-prostora-left"
            >
              2gb
            </div>
            <div
              [ngClass]="
                form.controls.kolicinaProstora.value == 50
                  ? 'selected-kolicina-prostora'
                  : 'non-selected-kolicina-prostora'
              "
              class="kolicina-prostora-center"
            >
              5gb
            </div>
            <div
              [ngClass]="
                form.controls.kolicinaProstora.value == 75
                  ? 'selected-kolicina-prostora'
                  : 'non-selected-kolicina-prostora'
              "
              class="kolicina-prostora-right"
            >
              10gb
            </div>
            <div
              [ngClass]="
                form.controls.kolicinaProstora.value == 100
                  ? 'selected-kolicina-prostora'
                  : 'non-selected-kolicina-prostora'
              "
              class="kolicina-prostora-right"
            >
              50gb
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
