import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValutaDto } from '@kodit/core/data-api';

@Component({
  selector: 'kodit-valuta-dropdown-field',
  templateUrl: './valuta-dropdown-field.component.html',
  styleUrls: ['./valuta-dropdown-field.component.scss'],
})
export class ValutaDropdownFieldComponent implements OnInit {
  /** I/O */
  @Input() valutaCtrl: AbstractControl;
  @Output()
  onValutaChanged: EventEmitter<ValutaDto> = new EventEmitter<ValutaDto>();

  constructor() {}

  ngOnInit(): void {}

  handleValutaChanged(valuta: ValutaDto) {
    this.onValutaChanged?.emit(valuta);
  }
}
