<span
  class="kodit-btn-wrapper"
  [pTooltip]="shouldDisableWhenSefInactive && !isSefActive ? 'Akcija će biti dostupna kada SEF bude operativan' : btnTooltip"
  [tooltipPosition]="btnTooltipPosition"
>
  <button
    [id]="btnId"
    [type]="btnType"
    pButton
    pRipple
    [icon]="btnIcon"
    [ngClass]=" btnSeverity + ' kodit-btn ' + btnClass"
    (click)="handleBtnClick($event)"
    [disabled]="isBtnDisabled"
    [label]="btnLabel"
  ></button>
</span>
