<div [formGroup]="form" class="p-d-flex p-flex-row p-fluid">
  <kodit-filter-name class="flex-1-1" title="Filter artikala"></kodit-filter-name>
  <div class="flex-1-1 p-mr-4">
    <kodit-field fieldLabel="Naziv" fieldLabelFor="naziv">
      <kodit-text-input
        [formCtrl]="form.controls.naziv"
        formCtrlId="naziv"
      ></kodit-text-input>
    </kodit-field>
  
    <kodit-field fieldLabel="Šifra" fieldLabelFor="sifra">
      <kodit-text-input
        [formCtrl]="form.controls.sifra"
        formCtrlId="sifra"
      ></kodit-text-input>
    </kodit-field>
  </div>
</div>
