import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-vrsta-fiskalnog-racuna-radiobutton',
  templateUrl: './vrsta-fiskalnog-racuna-radiobutton.component.html',
  styleUrls: ['./vrsta-fiskalnog-racuna-radiobutton.component.scss'],
})
export class VrstaFiskalnogRacunaRadiobuttonComponent implements OnInit {
  /** I/O */
  @Input() name: string = '';
  @Input() value: boolean = false;
  @Input() label: string = '';
  @Input() labelId: string = '';

  @Input() items: SelectItem[] = [];
  @Input() selectedCategory!: FormControl;

  constructor() {}

  ngOnInit(): void {}

  handleClick(item: any) {
    this.selectedCategory.patchValue(item.value);
  }
}
