import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PdvEvidencijaTipDokumenta, PdvEvidencijeClient } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscribable, Subscription } from 'rxjs';
import { PdvEvidencijaPojedinacnaService } from '../../pojedinacna/pdv-evidencija-pojedinacna.service';

@Component({
  selector: 'kodit-tip-povezanog-dokumenta-dropdown',
  templateUrl: './tip-povezanog-dokumenta-dropdown.component.html',
  styleUrls: ['./tip-povezanog-dokumenta-dropdown.component.scss'],
})
export class TipPovezanogDokumentaDropdownComponent
  implements OnInit, OnDestroy {
  private _subs: Subscription;

  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string;
  @Input() parentCtrl: string;
  @Input() shouldDisable: boolean = false;
  @Output() onItemChange = new EventEmitter<any>();

  items: SelectItem[];
  fullItems: SelectItem[] = [];

  constructor(
    private _client: PdvEvidencijeClient,
    private _service: PdvEvidencijaPojedinacnaService
  ) {
    this._subs = new Subscription();
    this.items = [];
    this.formCtrl = undefined!;
    this.formCtrlId = 'tip-povezanog-dokumenta-dropdown';
    this.parentCtrl = 'body';
  }

  ngOnInit(): void {
    this._load();
    this._subs.add(
      this._service.getTipDokumentaSubjectObservable.subscribe(
        (tipDokumentaSubject) => {
          this._filterItems(tipDokumentaSubject);
        }
      )
    );
  }

  private _load() {
    this._subs.add(
      this._client
        .getTipPovezanogDokumentaEvidencijeDropdown()
        .subscribe((res) => {
          if (!res.succeeded) {
            return;
          }
          this.fullItems = res.data!.responseList!.map((x) => ({
            value: x.tipPovezanog,
            label:  x.tipStr,
            title: x.opis,
          }));
          this._filterItems(this._service.getTipDokumentaSubject);
        })
    );
  }

  private _filterItems(tipDokumentaSubject: PdvEvidencijaTipDokumenta) {
    if (tipDokumentaSubject == PdvEvidencijaTipDokumenta.FAKTURA) {
      this.items = this.fullItems.filter((item) =>
        ['Avansna faktura', 'Bez povezanih dokumenata'].includes(item.label!)
      );
      this._service.setIsAvansni = true;
    } else if (tipDokumentaSubject == PdvEvidencijaTipDokumenta.DOKUMENT_O_POVECANJU) {
      this.items = this.fullItems.filter((item) =>
        ['Faktura', 'Vremenski period'].includes(item.label!)
      );
      this._service.setIsAvansni = false;
    } else if (tipDokumentaSubject == PdvEvidencijaTipDokumenta.DOKUMENT_O_SMANJENJU) {
      this.items = this.fullItems.filter(
        (item) => !['Bez povezanih dokumenata'].includes(item.label!)
      );
      this._service.setIsAvansni = false;
    }
  }

  handleOnItemChange(event: any) {
    this.onItemChange.emit(event);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
