import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { CoreSharedModule } from 'libs/core/shared/src';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PodaciKompanijeComponent } from './register/podaci-kompanije/podaci-kompanije.component';
import { PodaciKorisnikaComponent } from './register/podaci-korisnika/podaci-korisnika.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegistrationStepperComponent } from './register/registration-stepper/registration-stepper.component';
import { PotvrdaEmailaComponent } from './register/potvrda-emaila/potvrda-emaila.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { UnosKorisnickogNalogaComponent } from './unos-korisnickog-naloga/unos-korisnickog-naloga.component';
import { OdabirPaketaComponent } from './register/odabir-paketa/odabir-paketa.component';
import { PotvrdaRegistracijeComponent } from './register/potvrda-registracije/potvrda-registracije.component';
import {CoreSharedSubformsModule} from "@kodit/core/shared-subforms";

@NgModule({
  imports: [CommonModule, AuthRoutingModule, CoreSharedModule, CoreSharedSubformsModule],
  declarations: [
    LoginComponent,
    RegisterComponent,
    PodaciKompanijeComponent,
    PodaciKorisnikaComponent,
    ForgotPasswordComponent,
    RegistrationStepperComponent,
    PotvrdaEmailaComponent,
    EmailVerificationComponent,
    UnosKorisnickogNalogaComponent,
    OdabirPaketaComponent,
    PotvrdaRegistracijeComponent,
  ],
})
export class CoreAuthModule {}
