import { Component, OnInit } from '@angular/core';
import { PackageType } from '@kodit/core/data-api';
import { AuthService } from '@kodit/core/services';

@Component({
  selector: 'kodit-liste-page',
  templateUrl: './liste-page.component.html',
  styleUrls: ['./liste-page.component.scss'],
})
export class ListePageComponent implements OnInit {
  hasRobno: boolean = false;

  constructor(private _authService: AuthService) {}

  ngOnInit(): void {
    this.hasRobno = this._authService.hasPackage([
      PackageType[PackageType.VELEPRODAJA],
    ]);
  }
}
