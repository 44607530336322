import { Injectable } from '@angular/core';
import {
  CenovniciClient,
  ICenovnikStavkaDto,
  StrankaDto,
} from '@kodit/core/data-api';
import { BaseService } from '@kodit/core/services';
import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CenovnikStavkeService extends BaseService {
  constructor(private _client: CenovniciClient) {
    super();
  }

  loadStavkeCenovnika(cenovnikId: number) {
    return this._client.getStavkeCenovnika(cenovnikId).pipe(
      map((res) => {
        this.setLevaLista = res.data.cenovnikStavke;
      }),
      catchError(this.handleError),
      finalize(() => {
        //this._configService.setIsBusy = false;
      })
    );
  }

  /**
   * predstavlja levu listu stavki (za postojeci cenovnik)
   */
  levaLista$ = new BehaviorSubject<ICenovnikStavkaDto[]>([]);

  get getLevaLista() {
    return this.levaLista$.asObservable();
  }
  set setLevaLista(data: ICenovnikStavkaDto[]) {
    this.levaLista$.next(data);
  }

  addToLevaLista(data: ICenovnikStavkaDto[]) {
    this.levaLista$.next([...this.levaLista$.value, ...data]);
  }

  /**
   * predstavlja desnu listu stavki (za nov cenovnik)
   */
  desnaLista$ = new BehaviorSubject<ICenovnikStavkaDto[]>([]);

  get getDesnaLista() {
    return this.desnaLista$.asObservable();
  }

  set setDesnaLista(data: ICenovnikStavkaDto[]) {
    this.desnaLista$.next(data);
  }

  addToDesnaLista(data: ICenovnikStavkaDto[]) {
    this.desnaLista$.next([...this.desnaLista$.value, ...data]);
  }

  /**
   *
   */
  strankeCenovnika$ = new BehaviorSubject<StrankaDto[]>([]);

  set setStrankeCenovnika(value: StrankaDto[]) {
    this.strankeCenovnika$.next(value);
  }

  get getStrankeCenovnika() {
    return this.strankeCenovnika$.asObservable();
  }

  resetListe() {
    this.setDesnaLista = [];
    this.setLevaLista = [];
  }
}
