<ng-container *ngIf="showDialog">
  <div class="arhivska-dialog">
    <kodit-arhivska-postoji-dialog></kodit-arhivska-postoji-dialog>
  </div>
</ng-container>

<div>
  <kodit-arhivska-does-not-exists-page
    *ngIf="!arhivskaExisted && showDialog == false"
  ></kodit-arhivska-does-not-exists-page>

  <kodit-arhivska-exists-page
    *ngIf="arhivskaExisted"
  ></kodit-arhivska-exists-page>
</div>
