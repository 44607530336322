import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor() {}

  /**
   * Vraca da li je isti user dva puta ulogovan (boolean) kao posledica promene ove promenljive otvara se IzlogujUpozorenjeDialog
   */
  showLogoutDialog$ = new BehaviorSubject<boolean>(false);

  set setShowLogoutDialog(value: boolean) {
    this.showLogoutDialog$.next(value);
  }

  get getShowLogoutDialog() {
    return this.showLogoutDialog$.asObservable();
  }
}
