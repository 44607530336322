import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { BaseService, LocationService } from '@kodit/core/services';
import {
  GetNewIzlaznaFakturaForOtpremnicaQuery,
  IzlaznaFakturaDto,
  IzlazneFaktureClient,
  TipRacuna,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IzlaznaFakturaZaOtpremniceResolver
  extends BaseService
  implements Resolve<IzlaznaFakturaDto> {
  constructor(
    private _client: IzlazneFaktureClient,
    private _router: Router,
    private _locationService: LocationService) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<IzlaznaFakturaDto>
    | Promise<IzlaznaFakturaDto>
    | IzlaznaFakturaDto
    | null {
    
    if(!this._router.getCurrentNavigation().extras.state){
      this._locationService.routeToRacunTable(TipRacuna.OTPREMNICA, VrstaRacuna.EMPTY);
    }
    return this._client
      .getNewForOtpremnice(
        new GetNewIzlaznaFakturaForOtpremnicaQuery({
          otpremnicaIdArray: this._router.getCurrentNavigation().extras.state
            .otpremnicaIdArray,
        })
      )
      .pipe(
        map((res) => res.data),
        catchError(this.handleError),
        finalize(() => {
          //this._configService.setIsBusy = false;
        })
      );
  }
}
