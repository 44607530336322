import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {
  ICreateMojaFirmaCommand,
  IPravnoLiceDto, TokensClient,
  UniqueCheckMojaFirmaCommand,
} from '@kodit/core/data-api';
import {FormHelper} from '@kodit/core/form-definitions';
import {MixpanelService} from '@kodit/core/services';
import {FormGroupTypeSafe} from 'angular-typesafe-reactive-forms-helper';
import {TekuciRacunFormService} from 'libs/core/form-definitions/src/lib/banka/tekuci-racun-form.service';
import {Subscription} from 'rxjs';
import {RegisterService} from '../register.service';

@Component({
  selector: 'kodit-podaci-kompanije',
  templateUrl: './podaci-kompanije.component.html',
  styleUrls: ['./podaci-kompanije.component.scss'],
})
export class PodaciKompanijeComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Form */
  form: FormGroupTypeSafe<ICreateMojaFirmaCommand>;

  /** Props */
  showError: boolean;
  adresa: string = '';
  kompanijaSelected: boolean = false;
  nazivPlaceholder: string = '';
  shouldClearText: boolean = false;

  constructor(
    private _router: Router,
    private _registerService: RegisterService,
    private _formHelper: FormHelper,
    private _client: TokensClient,
    private _tekuciRacunFS: TekuciRacunFormService,
    private _mixpanelService: MixpanelService
  ) {
  }

  ngOnInit(): void {
    this.form = this._registerService.getForm;
    this.kompanijaSelected = !!this.form.controls.pravnoLiceDto.value.naziv;
    this.nazivPlaceholder = this.form.controls.pravnoLiceDto.value.naziv;
  }

  routeToNextStep() {
    this.showError = false;
    if (!this.form.controls.pravnoLiceDto.valid) {
      this._formHelper.invalidateForm(
        this.form.controls.pravnoLiceDto as FormGroup
      );
      return;
    }

    this._subs.add(
      this._client
        .uniqueCheckKompanija(
          new UniqueCheckMojaFirmaCommand({
            pib: this.form.value.pravnoLiceDto.pib,
          })
        )
        .subscribe(() => {
          this._mixpanelService.registrationAdminStep(this.form.value);
          this._router
            .navigate(['/autentikacija', 'registracija', 'podaci-korisnika'])
            .then();
        })
    );
  }

  patchFormByModel(model: IPravnoLiceDto) {
    if (!model?.naziv) {
      this.kompanijaSelected = false;
      return;
    }
    this.shouldClearText = false;
    this.form.controls.pravnoLiceDto.patchValue(model);
    this.form.controls.pravnoLiceDto.updateValueAndValidity();
    this.adresa = model.adresa.ulicaIBroj + ', ' + model.adresa.grad;
    this.kompanijaSelected = true;
    (this.form.controls.pravnoLiceDto.get(
      'tekuciRacuniDto'
    ) as FormArray).clear();
    if (model.tekuciRacuniDto?.length > 0) {
      model.tekuciRacuniDto.forEach((b) => {
        (this.form.controls.pravnoLiceDto.get(
          'tekuciRacuniDto'
        ) as FormArray).push(
          this._tekuciRacunFS.GetTekuciRacunFormGroup(b, false)
        );
      });
    }
  }

  resetModel() {
    if (this.form.value.pravnoLiceDto.naziv) {
      this._registerService.resetForm();
      this.form = this._registerService.getForm;
      this.adresa = '';
    }
  }

  routeToPreviousStep() {
    this._router.navigate([
      '/autentikacija',
      'registracija',
      'odabir-paketa']);
  }

  izbrisiSelectedModel() {
    this.form.controls.pravnoLiceDto.reset();
    this.adresa = '';
    this.nazivPlaceholder = '';
    this.kompanijaSelected = false;
    this.shouldClearText = true;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
