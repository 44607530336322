import { Component, Input, OnDestroy, OnInit, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertService,
  ConfigService,
  LocationService,
  SharedService,
} from '@kodit/core/services';
import {
  CancelRadniNalogCommand,
  FilterChipDto,
  GetRacunRelevantniDokumentListZippedQuery,
  GetRacunTableQuery,
  GetRadniNalogTableQuery,
  IGetRadniNalogTableQuery,
  IPaginatedResultOfRadniNalogTableDto,
  IRacunFilterDto,
  IRadniNalogTableDto,
  PaginatedResultOfRadniNalogTableDto,
  RacunFilterDto,
  RacuniClient,
  RadniNalogTableDto,
  RadniNaloziClient,
  StatusRadnogNaloga,
  StatusRadnogNalogaDto,
  StrankaDto,
  TipPristupa,
  TipRacuna,
  UpdateStatusRadnogNalogaCommand,
  VezaniRacunDto,
  VrstaRacuna,
  VrstaRadnogNaloga,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import {
  ActionClass,
  ActionMode,
  ActionType,
  OnTableInit,
  SelectMode,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import * as printJS from 'print-js';
import {
  DialogSize,
  DynamicDialogConfig,
  OnDynamicDialogTypedInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { DynamicDialogService } from '@kodit/core/shared-ui';
import { isEmptyCheck, nameofFactory, toCamelCase } from '@kodit/core/shared';
import { PosaljiElektronskiFormComponent } from 'libs/core/shared-subforms/src/lib/racun/posalji-elektronski-form/posalji-elektronski-form.component';
import { RacunFilterService } from 'libs/core/shared-subforms/src/lib/racun/racun-filter/racun-filter.service';
import { RacunFilterComponent } from 'libs/core/shared-subforms/src/lib/racun/racun-filter/racun-filter.component';
import { RadniNalogService } from '../radni-nalog.service';

@Component({
  selector: 'kodit-radni-nalog-table',
  templateUrl: './radni-nalog-table.component.html',
  styleUrls: ['./radni-nalog-table.component.scss'],
})
export class RadniNalogTableComponent
  implements
    OnInit,
    OnDestroy,
    OnTableInit,
    OnDynamicDialogTypedInit<PosaljiElektronskiFormComponent> {
  private _subs: Subscription = new Subscription();

  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Helpers */
  private readonly _racunTableHeaders: { [key in TipRacuna]?: string } = {
    [TipRacuna.IZLAZNA_FAKTURA]: 'Izlazne fakture',
    [TipRacuna.ULAZNA_FAKTURA]: 'Ulazne fakture',
    [TipRacuna.PREDRACUN]: 'Predračuni',
    [TipRacuna.OTPREMNICA]: 'Otpremnice',
    [TipRacuna.DOKUMENT_O_SMANJENJU]: 'Dokumenti o smanjenju',
    [TipRacuna.DOKUMENT_O_POVECANJU]: 'Dokumenti o povećanju',
    [TipRacuna.AVANSNI_RACUN]: 'Avansni računi',
    [TipRacuna.RADNI_NALOG]: 'Radni nalozi',
    [TipRacuna.EMPTY]: 'Registar računa',
  };

  /** Props */
  paginatedData: IPaginatedResultOfRadniNalogTableDto;
  tableConfig: TableConfig;
  radniNalogTable: RadniNalogTableDto[] = [];

  strankaId: number;
  //save filter and paginate variables
  tableSessionStorage: IRacunFilterDto = {};
  routerChangeDetected: boolean;

  chipItems: FilterChipDto[] = [];
  private _skipFirst = 0;
  numberOfRowsDisplayed: number = 0;
  stranke: StrankaDto[] = [];
  statusi: StatusRadnogNalogaDto[] = [];
  broj: string;
  vezaniRacuni: VezaniRacunDto[] = [];

  /** I/O */
  @Input() tipRacuna: TipRacuna = TipRacuna.EMPTY;
  @Input() vrstaRacuna: VrstaRacuna = VrstaRacuna.EMPTY;

  constructor(
    private _racuniClient: RacuniClient,
    private _alertService: AlertService,
    private _configService: ConfigService,
    private _route: ActivatedRoute,
    private _sharedService: SharedService,
    private _racunFilterService: RacunFilterService,
    private _dialogService: DynamicDialogService,
    private _confirmationService: ConfirmationService,
    private _locationService: LocationService,
    private _radniNaloziClient: RadniNaloziClient,
    private _router: Router,
    private _radniNalogService: RadniNalogService
  ) {}

  ngOnInit(): void {
    // check if page is reloaded
    if (this._router.onSameUrlNavigation == 'reload') {
      this.routerChangeDetected = true;
    }
    // get router data
    this._route.data.subscribe((data) => {
      if (data['tipRacuna']) {
        this.tipRacuna = data['tipRacuna'] as TipRacuna;
      }
      if (data['vrstaRacuna']) {
        this.vrstaRacuna = data['vrstaRacuna'] as VrstaRacuna;
      }
    });
    this._route.params.subscribe((params) => {
      if (params['strankaId']) {
        this.strankaId = Number.parseInt(params['strankaId']);
      }
    });

    // this._subs.add(
    //   this._configService.getShouldShowSyncWithSefStatusButton.subscribe(
    //     (res) => {
    //       this.shouldShowSyncWithSefStatusButton = res;
    //     }
    //   )
    // );

    // config table
    this.setTableConfig().then();

    this._subs.add(
      this._racunFilterService.getAdvanceFilterSubmitted.subscribe(
        (filterResult) => {
          if (filterResult?.shouldApplyFilter) {
            this._updateCurrentPage();
            filterResult.filterData.pageNumber = this._skipFirst;
            filterResult.filterData.pageSize = this.numberOfRowsDisplayed;
            this.tableConfig.advancedFilter.data = filterResult.filterData;

            // if (!this.routerChangeDetected) {
            //   this._racunStorageService.set2(
            //     this.tipRacuna,
            //     this.vrstaRacuna,
            //     filterResult.filterData
            //   );
            // }
            this._load(this.tableConfig.advancedFilter.data);
          }
        }
      )
    );
  }

  //#region Table configs

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event) => {
        this._skipFirst = event.first;
        this.numberOfRowsDisplayed = event.rows;
        this.updateFilterDataAndReload();
      },
      selectMode: SelectMode.MULTI,
      tableHeader: this._racunTableHeaders[this.tipRacuna],
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete izabrane račune?',
      deleteMessage: 'Da li ste sigurni da želite da obrišete račun {param}?',
      deleteMessageParams: 'broj',
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      isSelectableRowDisabled: (rowData: IRadniNalogTableDto) => {
        return rowData?.vezaniRacuniDto?.length > 0;
      },
      columns: [
        {
          header: 'Tip',
          type: 'badge',
          field: 'tipRacunaDto',
          subField: 'tipStr',
          styleClassField: 'tipBadgeStr',
          styleClass: 'tip-racuna',
          tooltipField: 'opis',
          isVisible: this.tipRacuna == TipRacuna.EMPTY,
        },
        {
          field: 'broj',
          header: 'Broj',
          type: 'link',
          linkCallbackFunction: (itemIndex: number) =>
            this._goToInfo(itemIndex),
          columns: [
            {
              field: 'vezaniRacuniDto',
              subField: 'label',
              header: 'Vezani račun',
              type: 'link',
              linkCallbackFunction: (itemIndex: number, subIndex: number) =>
                this._goToVezaniRacunInfo(itemIndex, subIndex),
            },
          ],
        },

        {
          field: 'rutiranjeStrankeDto',
          subField: 'naziv',
          header: 'Stranka',
          type: 'link',
          isVisible: !this.strankaId,
          linkCallbackFunction: (itemIndex: number) =>
            this._goToStranka(itemIndex),
        },

        {
          field: 'iznos',
          header: 'Iznos',
          type: 'currency',
          currencyAlphaCharField: 'valutaText',
          columns: [
            {
              field: 'iznosRSD',
              header: '',
              type: 'currency',
              shouldDisplayByCondition: (fieldValue: number) => {
                return this._shouldDisplayIznosURSD(fieldValue);
              },
            },
          ],
        },
        {
          field: 'statusDto',
          subField: 'statusStr',
          header: 'Status',
          styleClass: 'status-radnog-naloga',
          styleClassField: 'statusBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
          isVisible: this.tipRacuna === TipRacuna.RADNI_NALOG,
        },
        {
          field: 'vrstaDto',
          subField: 'vrstaStr',
          header: 'Vrsta',
          styleClass: 'vrsta-radnog-naloga',
          styleClassField: 'vrstaBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
          isVisible: this.tipRacuna === TipRacuna.RADNI_NALOG,
        },
      ],
      headerActions: [
        /** Preuzimanje vise UF u PDF formatu */
        {
          type: ActionType.MULTI_CUSTOM,
          actionClass: ActionClass.OUTLINED,
          label: 'Preuzmi PDF',
          icon: 'fa-solid fa-download',
          tipPristupa: TipPristupa.RADNI_NALOG_CRUD,
          hasAccessTooltip: 'Preuzmite PDF fajlove selektovanih računa',
          noAccessTooltip: 'Nemate ovlašćenja za preuzimanje PDF-a računa',
          callback: (result: IRadniNalogTableDto[]) => {
            this._getRacunRelevantniDokumentListZipped(
              result.map((racun) => racun.id)
            );
          },
        },

        /* Unos racuna sa tabele*/
        {
          type: ActionType.CREATE,
          tipPristupa: this._sharedService.racunCreateTipPristupa[
            this.tipRacuna
          ],
          hasAccessTooltip: 'Unesite račun',
          noAccessTooltip: 'Nemate ovlašćenja za unos računa',
          isVisible:
            this.isSingleRacunMode &&
            this.tipRacuna !== TipRacuna.AVANSNI_RACUN,
          callback: () => this._goToCreateRacun(this.tipRacuna),
        },
      ],
      rowActions: [
        /* Izmena racuna */
        {
          type: ActionType.EDIT,
          tipPristupa: this._sharedService.racunUpdateTipPristupa[
            this.tipRacuna
          ],
          hasAccessTooltip: 'Izmenite račun',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu računa',
          callback: (index: number) => {
            this._goToEdit(index);
          },
          shouldDisplayByCondition: (rowData: RadniNalogTableDto) => {
            return this._shouldDisplayEdit(
              rowData.statusDto.status,
              rowData.vrstaDto.vrsta
            );
          },
        },
        /* Brisanje racuna */
        {
          type: ActionType.DELETE,
          tipPristupa: TipPristupa.RADNI_NALOG_CRUD,
          hasAccessTooltip: 'Obrišite račun',
          noAccessTooltip: 'Nemate ovlašćenja za brisanje računa',
          callback: () => {},
          shouldDisplayByCondition: (rowData: RadniNalogTableDto) => {
            return this._shouldDisplayDelete(rowData.statusDto.status);
          },
        },
        /* Slanje racuna na mail */
        // {
        //   mode: ActionMode.MULTI,
        //   type: ActionType.CUSTOM,
        //   tipPristupa: TipPristupa.RACUN_SEND_ELEKTRONSKI,
        //   label: 'Pošalji na E-Mail',
        //   icon: 'fa-solid fa-envelope',
        //   hasAccessTooltip: 'Pošaljite račun na E-mail klijentu',
        //   noAccessTooltip: 'Nemate ovlašćenja za slanje računa na E-mail',
        //   callback: (index: number) => {
        //     this.dialogConfig.header = 'Elektronsko slanje';
        //     let sendTo: IMailReceiverDto[] = [];
        //     let sendCc: IMailReceiverDto[] = [];
        //     let customPoruka: IMailReceiverDto;
        //     this.dialogConfig.data = {
        //       strankaId: this.racuniTable[index - this.skipFirst]
        //         .rutiranjeStrankeDto.strankaId,
        //       racunTip: this.racuniTable[index - this.skipFirst],
        //       racunId: this.racuniTable[index - this.skipFirst].id,
        //       sendTo: sendTo,
        //       sendCc: sendCc,
        //       customPoruka: customPoruka,
        //     };
        //     this.dialogConfig.customSubmitButton = {
        //       icon: 'fa-solid fa-envelope',
        //       label: 'Pošalji',
        //     };
        //     this.openDialog(PosaljiElektronskiFormComponent);
        //   },
        //   shouldDisplayByCondition: (rowData: RadniNalogTableDto) => {
        //     return this._shouldDisplaySendToEmail(rowData.statusDto.status, !!rowData.rutiranjeStrankeDto);
        //   },
        // },

        /* stampanje domaceg racuna */
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          icon: 'fas fa-print',
          hasAccessTooltip: 'Odštampajte račun u PDF formatu',
          noAccessTooltip: 'Nemate ovlašćenja za štampanje računa',
          callback: (index: number) => {
            this._subs.add(
              this._racuniClient
                .getPdf(this.radniNalogTable[index - this._skipFirst].id)
                .subscribe((result) => {
                  let fileURL = URL.createObjectURL(result.data);
                  printJS(fileURL);
                })
            );
          },
          shouldDisplayByCondition: (rowData: RadniNalogTableDto) => {
            return this._shouldDisplayPrint(rowData.statusDto.status);
          },
        },

        /* Konvertovanje radnog naloga u izlaznu fakturu*/
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          icon: 'fas fa-exchange-alt',
          label: 'Konvertuj u izlaznu fakturu',
          hasAccessTooltip: 'Konvertujte radni nalog u izlaznu fakturu',
          noAccessTooltip:
            'Nemate ovlašćenja za konvertovanje radnog naloga u  izlaznu fakturu',
          callback: (index: number) => {
            this._locationService.routeToRacunCreateForRadniNalog(
              this.radniNalogTable[index - this._skipFirst].id
            );
          },
          shouldDisplayByCondition: (rowData: RadniNalogTableDto) => {
            return this._shouldDisplayTransformRadniNalogToFaktura(
              rowData?.vezaniRacuniDto?.length > 0,
              rowData.statusDto?.status
            );
          },
        },

        /* Preuzimanje pdf računa */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          label: 'Preuzmi PDF računa',
          hasAccessTooltip: 'Preuzmite pdf fajl računa',
          noAccessTooltip: 'Nemate ovlašćenja za preuzimanje pdf-a računa',
          icon: 'fas fa-download',
          callback: (index: number) => {
            this._subs.add(
              this._racuniClient
                .getPdf(this.radniNalogTable[index - this._skipFirst].id)
                .subscribe((result) => {
                  this._sharedService.downloadBlobAsPDF(
                    result.data,
                    result.fileName
                  );
                })
            );
          },
          shouldDisplayByCondition: (rowData: RadniNalogTableDto) => {
            return this._shouldDisplayDownload(rowData.statusDto.status);
          },
        },

        /* Kreiranje duplikata */
        // {
        //   mode: ActionMode.MULTI,
        //   type: ActionType.CUSTOM,
        //   label: 'Napravi duplikat',
        //   hasAccessTooltip: 'Napravite duplikat računa',
        //   noAccessTooltip: 'Nemate ovlašćenja za kreiranje duplikata računa',
        //   icon: 'fa-regular fa-clone',
        //   callback: (index: number) => {
        //     this._confirmationService.confirm({
        //       message:
        //         'Da li ste sigurni da kreirate duplikat ovog računa? Otvoriće se forma računa gde ćete imati mogućnost da vršite izmene pre kreiranja novog dokumenta po uzoru na originalni.',
        //       acceptLabel: 'Da',
        //       rejectLabel: 'Ne',
        //       header: 'Potvrdite',
        //       icon: 'fa-regular fa-circle-question',
        //       rejectButtonStyleClass: 'p-button-outlined',
        //       accept: () => {
        //         this._goToDuplicate(index);
        //       },
        //     });
        //   },
        //   shouldDisplayByCondition: (rowData: RadniNalogTableDto) => {
        //     return this._shouldDisplayDuplicate(rowData.statusDto.status);
        //   },
        // },

        /* Promena statusa radnog naloga iz nacrt u zapocet */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          icon: 'fa-regular fa-pallet-boxes',
          label: 'Promeni status u - Započet',
          hasAccessTooltip: 'Promenite status radnog naloga u ZAPOČET',
          noAccessTooltip:
            'Nemate ovlašćenja za menjanje statusa radnog naloga u ZAPOČET',
          callback: (index: number) => {
            this._promeniStatusRadnogNaloga(
              this.radniNalogTable[index - this._skipFirst].id,
              StatusRadnogNaloga.ZAPOCET
            );
          },
          shouldDisplayByCondition: (rowData: RadniNalogTableDto) => {
            return this._shouldDisplayRadniNalogZapocet(
              rowData.statusDto.status
            );
          },
        },
        /* Promena statusa radnog naloga iz zapocet u zavrsen */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          icon: 'fa-regular fa-boxes-stacked',
          label: 'Promeni status u - Završen',
          hasAccessTooltip: 'Promenite status radnog naloga u ZAVRŠEN',
          noAccessTooltip:
            'Nemate ovlašćenja za menjanje statusa radnog naloga u ZAVRŠEN',
          callback: (index: number) => {
            this._promeniStatusRadnogNaloga(
              this.radniNalogTable[index - this._skipFirst].id,
              StatusRadnogNaloga.ZAVRSEN
            );
          },
          shouldDisplayByCondition: (rowData: RadniNalogTableDto) => {
            return this._shouldDisplayRadniNalogZavrsen(
              rowData.statusDto?.status
            );
          },
        },
        /* Promena statusa radnog naloga iz zavrsen u ponisten */
        {
          mode: ActionMode.MULTI,
          type: ActionType.CUSTOM,
          icon: 'fa-regular fa-file-slash',
          label: 'Promeni status u - Poništen',
          hasAccessTooltip: 'Promenite status radnog naloga u PONIŠTEN',
          noAccessTooltip:
            'Nemate ovlašćenja za menjanje statusa radnog naloga u PONIŠTEN',
          callback: (index: number) => {
            this._promeniStatusRadnogNaloga(
              this.radniNalogTable[index - this._skipFirst].id,
              StatusRadnogNaloga.OTKAZAN
            );
            this._confirmationService.confirm({
              message: 'Da li ste sigurni da želite da poništite radni nalog?',
              acceptLabel: 'Da',
              rejectLabel: 'Ne',
              header: ' Poništavanje radnog naloga',
              icon: 'fa-regular fa-circle-question',
              rejectButtonStyleClass: 'p-button-outlined',
              accept: () => {
                this._subs.add(
                  this._radniNaloziClient
                    .cancelRadniNalog(
                      new CancelRadniNalogCommand({
                        racunId: this.radniNalogTable[index - this._skipFirst]
                          .id,
                      })
                    )
                    .subscribe((res) => {
                      if (res.succeeded) {
                        this.updateFilterDataAndReload();
                        this._alertService.addSuccessMsg(res.data);
                      } else {
                        this._alertService.addWarnMsg(res.messages[0]);
                      }
                    })
                );
              },
            });
          },
          shouldDisplayByCondition: (rowData: RadniNalogTableDto) => {
            return this._shouldDisplayRadniNalogPonisten(
              rowData.statusDto.status,
              !isEmptyCheck(rowData.vezaniRacuniDto)
            );
          },
        },
      ],
      advancedFilter: {
        component: RacunFilterComponent,
        data: new RacunFilterDto(),
      },
    });
  }

  private _shouldDisplayRadniNalogZapocet(status: StatusRadnogNaloga) {
    return status === StatusRadnogNaloga.NACRT;
  }

  private _shouldDisplayRadniNalogPonisten(
    status: StatusRadnogNaloga,
    fakturaPostoji: boolean
  ) {
    return (
      (status === StatusRadnogNaloga.ZAPOCET ||
        status === StatusRadnogNaloga.ZAVRSEN) &&
      !fakturaPostoji
    );
  }

  private _shouldDisplayRadniNalogZavrsen(status: StatusRadnogNaloga) {
    return status === StatusRadnogNaloga.ZAPOCET;
  }

  private _promeniStatusRadnogNaloga(id: number, status: StatusRadnogNaloga) {
    this._confirmationService.confirm({
      message: 'Da li ste sigurni da želite da promenite status radnog naloga?',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      header: 'Promena statusa radnog naloga',
      icon: 'fa-regular fa-circle-question',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._subs.add(
          this._radniNaloziClient
            .updateStatus(
              new UpdateStatusRadnogNalogaCommand({
                id: id,
                status: status,
              })
            )
            .subscribe((res) => {
              if (res.succeeded) {
                this.updateFilterDataAndReload();
                this._alertService.addSuccessMsg(res.data);
              } else {
                this._alertService.addWarnMsg(res.messages[0]);
              }
            })
        );
      },
    });
  }

  private updateFilterDataAndReload() {
    this.tableConfig.advancedFilter.data.pageNumber = this._skipFirst;
    this.tableConfig.advancedFilter.data.pageSize = this.numberOfRowsDisplayed;
    this._load(this.tableConfig.advancedFilter.data);
  }

  private _handleChipRemove(removedChip: FilterChipDto) {
    const nameof = nameofFactory<GetRacunTableQuery>();

    const nameOfStatusi = nameof('statusi');
    const nameOfStatusiUplate = nameof('statusiUplateRacuna');

    if (removedChip.key.toLowerCase() === nameOfStatusi.toLowerCase()) {
      const idx = (this.tableConfig.advancedFilter
        .data as GetRacunTableQuery).statusi.findIndex(
        (x) => x.status === removedChip.defaultValue
      );
      (this.tableConfig.advancedFilter
        .data as GetRacunTableQuery).statusi.splice(idx, 1);
    } else if (
      removedChip.key.toLowerCase() === nameOfStatusiUplate.toLowerCase()
    ) {
      const idx = (this.tableConfig.advancedFilter
        .data as GetRacunTableQuery).statusiUplateRacuna.findIndex(
        (x) => x.status === removedChip.defaultValue
      );
      (this.tableConfig.advancedFilter
        .data as GetRacunTableQuery).statusiUplateRacuna.splice(idx, 1);
    } else {
      this.tableConfig.advancedFilter.data[toCamelCase(removedChip.key)] =
        removedChip.defaultValue;
    }

    this.updateFilterDataAndReload();
  }

  //#endregion Table configs

  //#region Routing

  private _goToStranka(itemIndex: number) {
    const racun = this.radniNalogTable[itemIndex - this._skipFirst];
    this._locationService.routeToStrankaKartica(
      racun.rutiranjeStrankeDto.strankaId,
      racun.rutiranjeStrankeDto.tipStranke
    );
  }

  private _goToInfo(rowIndex: number) {
    const racun = this._getPaginatedItem(rowIndex);
    this._locationService
      .routeToRadniNalogKartica(racun.id, this.tipRacuna)
      .then();
  }

  private _goToVezaniRacunInfo(rowIndex: number, subIndex: number) {
    const racun = this._getPaginatedItem(rowIndex).vezaniRacuniDto[subIndex];
    this._locationService
      .routeToRacunKartica(racun.racunId, racun.tip, racun.vrsta)
      .then();
  }

  private _goToEdit(rowIndex: number) {
    const racun = this._getPaginatedItem(rowIndex);
    this._locationService.routeToRadniNalogEdit(racun.id, this.tipRacuna);
  }

  private _goToDuplicate(rowIndex: number) {
    const racun = this._getPaginatedItem(rowIndex);
    this._locationService.routeToDuplicateRadniNalog(racun.id, this.tipRacuna);
  }

  private _goToCreateRacun(tip: TipRacuna) {
    this._locationService
      .routeToRacunCreate(tip, this.vrstaRacuna, this.strankaId)
      .then();
  }

  //#endregion Routing

  //#region Table buttons display logic

  private _shouldDisplayEdit(
    status: StatusRadnogNaloga,
    vrsta: VrstaRadnogNaloga
  ) {
    return (
      status === StatusRadnogNaloga.NACRT ||
      (status === StatusRadnogNaloga.ZAPOCET &&
        this._radniNalogService.jeUsluznaDelatnost(vrsta))
    );
  }

  private _shouldDisplayIznosURSD(fieldValue: number): boolean {
    return fieldValue !== 0;
  }

  private _shouldDisplayDelete(status: StatusRadnogNaloga) {
    return false;

    // obratiti paznju da ovako prikazuje i POCETNO_STANJE
    // return status === StatusRadnogNaloga.NACRT || status === StatusRadnogNaloga.PRIHVACENO;
  }

  // private _shouldDisplaySendToEmail(status: StatusRadnogNaloga, postojiStranka : boolean) {
  //   return (
  //     (status === StatusRadnogNaloga.NACRT ||
  //       status === StatusRadnogNaloga.ZAPOCET) &&
  //     postojiStranka
  //   );
  // }

  private _shouldDisplayPrint(status: StatusRadnogNaloga) {
    return (
      status === StatusRadnogNaloga.NACRT ||
      status === StatusRadnogNaloga.ZAPOCET ||
      status === StatusRadnogNaloga.ZAVRSEN
    );
  }

  private _shouldDisplayDownload(status: StatusRadnogNaloga) {
    return (
      status === StatusRadnogNaloga.NACRT ||
      status === StatusRadnogNaloga.ZAPOCET
    );
  }

  private _shouldDisplayDuplicate(status: StatusRadnogNaloga) {
    return status !== StatusRadnogNaloga.OTKAZAN;
  }

  private _shouldDisplayTransformRadniNalogToFaktura(
    hasVezanRacun: boolean,
    status: StatusRadnogNaloga
  ) {
    return status === StatusRadnogNaloga.ZAVRSEN && !hasVezanRacun;
  }

  //#endregion Table buttons display logic

  openDialog(formType: Type<PosaljiElektronskiFormComponent>): void {
    const ref = this._dialogService.open(formType, this.dialogConfig);
    this._subs.add(
      ref.onClose.subscribe((dto: any) => {
        if (dto) {
          this.updateFilterDataAndReload();
        }
        this.dialogConfig.hideFooter = false;
      })
    );
  }

  private _getRacunRelevantniDokumentListZipped(ufIdArray: number[]) {
    this._subs.add(
      this._racuniClient
        .getRacunRelevantniDokumentListZipped(
          new GetRacunRelevantniDokumentListZippedQuery({
            racunIds: ufIdArray,
          })
        )
        .subscribe((res) => {
          const url = window.URL.createObjectURL(res.data);
          window.open(url);
        })
    );
  }

  private _load(request?: IGetRadniNalogTableQuery) {
    this._configService.setIsBusy = true;
    if (!request) {
      request = new GetRadniNalogTableQuery({
        stranke: this.stranke,
        statusi: this.statusi,
        broj: this.broj,
      });
    } else {
      request.stranke = this.stranke;
      request.statusi = this.statusi;
      request.broj = this.broj;
    }

    this._subs.add(
      this._radniNaloziClient
        .getForTable(request as GetRadniNalogTableQuery)
        .subscribe(
          (res: PaginatedResultOfRadniNalogTableDto) => {
            this.radniNalogTable = res.data;
            this.paginatedData = res;
            this.chipItems = res.activeFilters;

            // this._summaryService.setRacunSaldo = res.summaryData;
            this._configService.setIsBusy = false;
          },
          () => {
            this._configService.setIsBusy = false;
          }
        )
    );
  }

  /**
   * Info da li prikazujemo sve tipove racuna ili samo neki odredeni
   */
  get isSingleRacunMode(): boolean {
    return this.tipRacuna !== TipRacuna.EMPTY;
  }

  private _getPaginatedIndex(index: number): number {
    return index - this._skipFirst;
  }

  private _getPaginatedItem(index: number): IRadniNalogTableDto {
    return this.radniNalogTable[this._getPaginatedIndex(index)];
  }

  private _updateCurrentPage(): void {
    this._skipFirst = 0;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
    // reset filter data
    this._racunFilterService.setAdvanceFilterSubmitted = null;
  }
}
