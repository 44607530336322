import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'kodit-napomena-kompenzacije',
  templateUrl: './napomena-kompenzacije.component.html',
  styleUrls: ['./napomena-kompenzacije.component.scss']
})
export class NapomenaKompenzacijeComponent implements OnInit {

  /** I/O */
  @Input() napomenaCtrl : FormControl;

  constructor() { }

  ngOnInit(): void {
  }

}
