import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  DialogSize,
  DynamicDialogConfig,
} from '../../../../../shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { ArhivskaKnjigaClient } from '../../../../../data-api/src/lib/eKompanija-api';
import { AlertService } from '../../../../../services/src/lib/alert.service';

@Component({
  selector: 'kodit-arhivska-knjiga-menu-page',
  templateUrl: './arhivska-knjiga-menu-page.component.html',
  styleUrls: ['./arhivska-knjiga-menu-page.component.scss'],
})
export class ArhivskaKnjigaMenuPageComponent implements OnInit, OnDestroy {
  //** Subs */
  private _sub: Subscription = new Subscription();

  //** Configs */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.MEDIUM_SMALL
  );

  //** Props */
  showDialog: boolean | undefined;
  arhivskaExisted?: boolean | undefined;

  constructor(
    private _arhivskaClient: ArhivskaKnjigaClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._sub.add(
      this._arhivskaClient.getArhivskaKnjigaExists().subscribe((res) => {
        this.arhivskaExisted = res.data?.arhivskaTableExists;
        this.showDialog = !res.data?.arhivskaTableEmpty;
      })
    );
  }

  seedKategorije() {
    this._sub.add(
      this._arhivskaClient.seedKategorijeDokumenata().subscribe(() => {
        this._alertService.addSuccessMsg(
          'Uspešno ste izgenerisali kategorije - Izmeniti response ovo'
        );
      })
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
