import { Injectable } from '@angular/core';
import { AdvancedFilterActionData } from '@kodit/core/shared-ui';
import { IGetLagerTransakcijeTableQuery } from 'libs/core/data-api/src/lib/eKompanija-api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LagerTransakcijeFilterService {
  constructor() { }

  /**
   * Advanced filter methods
   */
  advancedFilterSubmitted$ = new BehaviorSubject<AdvancedFilterActionData<IGetLagerTransakcijeTableQuery>>(null);

  get getAdvancedFilterSubmitted() {
    return this.advancedFilterSubmitted$.asObservable();
  }
  set setAdvancedFilterSubmitted(data: AdvancedFilterActionData<IGetLagerTransakcijeTableQuery>) {
    this.advancedFilterSubmitted$.next(data);
  }
}
