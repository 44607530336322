import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Type,
} from '@angular/core';
import {
  GetNotifikacijaKorisnikaListQuery,
  NotifikacijaKorisnikaDto,
  NotifikacijeClient,
  PaginatedResultOfNotifikacijaKorisnikaDto,
  ReadAllNotificationsComand,
} from '@kodit/core/data-api';
import {ActionClass, ActionType, SelectMode, TableConfig} from '@kodit/core/shared-ui';
import { selectFilterByKey } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.selectors';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { LazyLoadEvent } from 'primeng/api';
import { setFilter } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';
import { ConfigService } from '@kodit/core/services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'kodit-notifikacije-table',
  templateUrl: './notifikacije-table.component.html',
  styleUrls: ['./notifikacije-table.component.scss'],
})
export class NotifikacijeTableComponent
  implements OnInit, OnChanges, OnDestroy {
  private _subs: Subscription = new Subscription();

  //** Configs */
  tableConfig!: TableConfig;
  filters! : GetNotifikacijaKorisnikaListQuery;
  paginatedData!: PaginatedResultOfNotifikacijaKorisnikaDto;
  private _skipFirst = 0;
  numberOfRowsDisplayed = 0;
  private _isBusy = false;
  isFirstLoad = true;
  //** I/0 */
  @Input() selectbuttonBool: boolean = false;

  //** Props */
  tableItems: NotifikacijaKorisnikaDto[] = [];

  constructor(
    private _client: NotifikacijeClient,
    private _storage: Store,
    private _configService: ConfigService,
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.setTableConfig();
    this._subs.add(
      this._storage
        .select(selectFilterByKey('NOTIFICATION' as any))
        .subscribe((data) => {
          if (data) {
            this.filters = JSON.parse(data);
            this._skipFirst = this.filters.pageNumber ?? 0;
            this.numberOfRowsDisplayed = this.filters.pageSize ?? 0;
          } else {
            this.filters = new GetNotifikacijaKorisnikaListQuery({
              pageNumber: this._skipFirst,
              pageSize: this.numberOfRowsDisplayed,
              samoNeprocitane: this.selectbuttonBool
            });
          }

          this._loadNotification();
        })
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      selectMode: this.selectbuttonBool ? SelectMode.MULTI : undefined,
      isLazy: true,
      lazyCallbackFunction: (event: LazyLoadEvent) => {
        if (!this.isFirstLoad) {
          this._skipFirst = event.first!;
          this.numberOfRowsDisplayed = event.rows!;
        }
        this._updateFilterDataAndReload();
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        }
      },
      tableHeader: 'Obaveštenja',
      columns: [
        {
          field: 'iconDto',
          type: 'icon',
        },
        {
          field: 'tekst',
          header: 'Naslov',
          type: 'text',
        },
        {
          field: 'naslov',
          header: 'Vrsta',
          type: 'text',
        },
      ],
      headerActions: [
        {
          isVisible: this.selectbuttonBool,
          type: ActionType.MULTI_CUSTOM,
          label: 'Označi kao pročitano',
          actionClass: ActionClass.LINK,
          callback: (result: NotifikacijaKorisnikaDto[]) => {
            this._handleProcitajSve(result.map((x) => x.id!));
          },
        },
      ],
      // advancedFilter: {
      //   component: Type,
      //   data: [],
      //   availableFilters: [],
      // },
    });
  }

  private _loadNotification() {
    if (this._isBusy) {
      return;
    }

    this._configService.setIsBusy = true;
    this._isBusy = true;

    if (!this.filters) {
      this.filters = new GetNotifikacijaKorisnikaListQuery({
        pageNumber: this._skipFirst,
        pageSize: this.numberOfRowsDisplayed,
        samoNeprocitane: this.selectbuttonBool
      });
    }

    this.filters.pageNumber = this._skipFirst;
    this.filters.pageSize = this.numberOfRowsDisplayed;
    this.filters.samoNeprocitane = this.selectbuttonBool;

    this._subs.add(
      this._client
        .getNotifikacijaKorisnikaList(this.filters)
        .pipe(
          finalize(() => {
            this._configService.setIsBusy = false;
            this._isBusy = false;
          })
        )
        .subscribe((res) => {
          this.paginatedData = res;
          this.tableItems = res.data!;
        })
    );
  }

  private _handleProcitajSve(notificationIds: string[]) {
    this._subs.add(
      this._client
        .readAllNotificationsKorisnika(
          new ReadAllNotificationsComand({
            notifikacijeIds: notificationIds,
          })
        )
        .subscribe((res) => {
          if (res.succeeded) {
            window.location.reload();
          }
        })
    );
  }

  private _updateFilterDataAndReload() {
    if (
      (this._skipFirst !== this.filters.pageNumber ||
        this.numberOfRowsDisplayed !== this.filters.pageSize) &&
      !this.isFirstLoad
    ) {
      this.filters.pageNumber = this._skipFirst;
      this.filters.pageSize = this.numberOfRowsDisplayed;
      this._updateStorage();
    }
  }

  
  private _updateStorage() {
    this._storage.dispatch(
      setFilter({
        key: 'NOTIFICATION',
        filter: JSON.stringify(this.filters),
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
