import { FormArray, FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  ICreateOznakaCommand,
  IGetOznakaMultiselectResponse,
  IOznakaDto,
  TipEntiteta,
  TipOznake,
} from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OznakaFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  /**
   * Pravi FormArray od prosledene liste oznakaDto
   * @param oznaka Lista oznaka dto za pretvaranje u FormArray
   * @returns FormArray od prosledene liste oznakeDto
   */
  public GetOznakeFormArray(oznake?: IOznakaDto[]): FormArray {
    if (!oznake || oznake.length === 0) {
      return this._fb.array([]);
      //return this._fb.array([this.GetOznakaFormGroup()]);
    }

    let result: FormArray = this._fb.array([]);
    oznake.forEach((o) => {
      result.push(this.GetOznakaFormGroup(o));
    });
    return result;
  }

  /**
   * Pravi FormGroup od prosledene oznakaDto
   * @param oznaka Oznaka dto objekat za pretvaranje u FormGroup
   * @returns FormGroup od prosledene oznakeDto
   */
  public GetOznakaFormGroup(
    oznaka?: IOznakaDto,
    tipOznake: TipOznake = TipOznake.KORISNICKA,
    isNazivRequired: boolean = false
  ): FormGroupTypeSafe<IOznakaDto> {
    return this._fb.group<IOznakaDto>({
      id: new FormControl(oznaka?.id),
      naziv: new FormControl(oznaka?.naziv ?? '', {
        validators: isNazivRequired
          ? [Validators.required, Validators.maxLength(150)]
          : [Validators.nullValidator],
      }),
      punNaziv: new FormControl(oznaka?.punNaziv),
      kolicina: new FormControl(oznaka?.kolicina),
      iznos: new FormControl(oznaka?.iznos),
      parentId: new FormControl(oznaka?.parentId),
      tipOznake: new FormControl(oznaka?.tipOznake ?? tipOznake),
      tipEntiteta: new FormControl(oznaka?.tipEntiteta ?? TipEntiteta.EMPTY),
      nazivStavke: new FormControl(oznaka?.nazivStavke),
    });
  }

  /**
   * Pravi za create i update request
   */
  public GetOznakaRequestFormGroup(
    model?: ICreateOznakaCommand
  ): FormGroupTypeSafe<ICreateOznakaCommand> {
    return this._fb.group<ICreateOznakaCommand>({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv, {
        validators: [Validators.required, Validators.maxLength(128)],
      }),
      parent: this.GetOznakaFormGroup(
        model?.parent,
        TipOznake.SISTEMSKA,
        false
      ),
    });
  }

  public GetOznakaMultiselectFormArray(
    oznake?: IGetOznakaMultiselectResponse[]
  ): FormArray {
    if (!oznake || oznake.length === 0) {
      return this._fb.array([]);
    }

    let result: FormArray = this._fb.array([]);
    oznake.forEach((o) => {
      result.push(this.GetOznakaMultiselectFormGroup(o));
    });
    return result;
  }

  public GetOznakaMultiselectFormGroup(
    model?: IGetOznakaMultiselectResponse
  ): FormGroupTypeSafe<IGetOznakaMultiselectResponse> {
    return this._fb.group<IGetOznakaMultiselectResponse>({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv, {
        validators: [Validators.required, Validators.maxLength(128)],
      }),
    });
  }
}
