import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { BaseService } from '@kodit/core/services';
import {
  GetOtpremnicaQuery,
  OtpremnicaDto,
  OtpremniceClient,
} from '@kodit/core/data-api';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OtpremnicaResolver
  extends BaseService
  implements Resolve<OtpremnicaDto> {
  constructor(private _client: OtpremniceClient) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<OtpremnicaDto> | Promise<OtpremnicaDto> | OtpremnicaDto | null {
    const id = Number.parseInt(route.paramMap.get('id'));
    return this._client.getOtpremnica(new GetOtpremnicaQuery({ id: id })).pipe(
      map((x) => x.data),
      catchError(this.handleError),
      finalize(() => {
        //this._configService.setIsBusy = false;
      })
    );
  }
}
