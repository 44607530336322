import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SharedClient, IInstrukcijaPlacanjaDto } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-instrukcija-placanja-dropdown',
  templateUrl: './instrukcija-placanja-dropdown.component.html',
  styleUrls: ['./instrukcija-placanja-dropdown.component.scss'],
})
export class InstrukcijaPlacanjaDropdownComponent implements OnInit, OnDestroy {
  /**Subs */
  private _instrukcijaSub = new Subscription();

  /**Props */
  instrukcijePlacanja: IInstrukcijaPlacanjaDto[] = [];
  instrukcijeOptions: {
    value: string;
    opis: string;
  }[] = [];

  // form ctrl to update data (instrukcija placanja) in edit faktura
  modelCtrl: FormGroup;

  /**I/O */
  @Input() instrukcijePlacanjaCtrl: FormControl;
  @Output()
  onInstrukcijePlacanjaChanged = new EventEmitter<IInstrukcijaPlacanjaDto>();
  constructor(private _client: SharedClient, private _fb: FormBuilder) {}

  ngOnInit(): void {
    this.modelCtrl = this._fb.group({
      instrukcija: new FormControl(this.instrukcijePlacanjaCtrl.value),
    });

    this._loadInstrukcijePlacanja();
  }

  private _loadInstrukcijePlacanja() {
    this._instrukcijaSub.add(
      this._client.getInstrukcijePlacanja().subscribe((value) => {
        this.instrukcijePlacanja = value.data.instrukcije;
        this.instrukcijeOptions = value.data.instrukcije.map((v) => ({
          value: v.instrukcija,
          opis: v.opis,
        }));
      })
    );
  }

  handleOptionChange(event: any) {
    if (this.onInstrukcijePlacanjaChanged) {
      const instrukcijaPlacanja = this.instrukcijePlacanja.find(
        (d) => d.instrukcija === event.value
      );
      this.instrukcijePlacanjaCtrl.patchValue(instrukcijaPlacanja.instrukcija);
    }
  }

  ngOnDestroy(): void {
    this._instrukcijaSub.unsubscribe();
  }
}
