import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  DatumObracunaPdv,
  IAvansniRacunDto,
  IGetFormConfigurationResponse, TipEntiteta,
  TipRacuna,
} from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { BaseRacunFormService } from './_base_racun-form.service';
import {FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import { stringIsNullOrEmpty } from '@kodit/core/shared';

@Injectable({
  providedIn: 'root',
})
export class AvansniRacunFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _baseRacunForm: BaseRacunFormService
  ) {}

  /**
   * Konvertuje model i od njega pravi reaktivnu formu (form grupu) za avansni racun
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   * @param model model avansnog racuna
   * @returns Reaktivnu formu kao FormGroup za avansni racun
   */
  public GetAvansniRacunFormGroup(
    formConfiguration: IGetFormConfigurationResponse,
    model: IAvansniRacunDto
  ): FormGroupTypeSafe<IAvansniRacunDto> {
    const baseRacunForm = this._baseRacunForm.GetBaseRacunFormGroup(
      TipRacuna.AVANSNI_RACUN,
      model.racunDto.vrsta,
      TipEntiteta.AVANSNI_RACUN,
      formConfiguration,
      model.racunDto
    );
    return this._fb.group<IAvansniRacunDto>(
      {
        racunDto: baseRacunForm,
        preostaloPdv8: new FormControl(model?.preostaloPdv8),
        preostaloPdv10: new FormControl(model?.preostaloPdv10),
        preostaloPdv20: new FormControl(model?.preostaloPdv10),
        preostaloUkupnoOsnovica8: new FormControl(
          model?.preostaloUkupnoOsnovica8
        ),
        preostaloUkupnoOsnovica10: new FormControl(model?.preostaloPdv10),
        preostaloUkupnoOsnovica20: new FormControl(
          model?.preostaloUkupnoOsnovica20
        ),
        preostaloUkupnoOsnovicaAE10: new FormControl(
          model?.preostaloUkupnoOsnovicaAE10
        ),
        preostaloUkupnoOsnovicaAE20: new FormControl(
          model?.preostaloUkupnoOsnovicaAE20
        ),
        preostaloUkupnoOsnovicaE: new FormControl(
          model?.preostaloUkupnoOsnovicaE
        ),
        preostaloUkupnoOsnovicaN: new FormControl(
          model?.preostaloUkupnoOsnovicaN
        ),
        preostaloUkupnoOsnovicaO: new FormControl(
          model?.preostaloUkupnoOsnovicaO
        ),
        preostaloUkupnoOsnovicaOE: new FormControl(
          model?.preostaloUkupnoOsnovicaOE
        ),
        preostaloUkupnoOsnovicaR: new FormControl(
          model?.preostaloUkupnoOsnovicaR
        ),
        preostaloUkupnoOsnovicaSS: new FormControl(
          model?.preostaloUkupnoOsnovicaSS
        ),
        preostaloUkupnoOsnovicaZ: new FormControl(
          model?.preostaloUkupnoOsnovicaZ
        ),
        uplataId: new FormControl(model?.uplataId),
        ukupanIznosUplate: new FormControl(model?.ukupanIznosUplate),
        predracunIds: this._getPredracunIdsFormArray(model?.predracunIds),
      },
      {
        validators: this._dodatniPodaciValidator,
      }
    );
  }

  private _getPredracunIdsFormArray(array?: number[]): FormArray {
    if (!array || array.length === 0) {
      return this._fb.array([]);
    }
    const result: FormArray = this._fb.array([]);

    array.forEach((x) => {
      result.push(new FormControl(x));
    });

    return result;
  }

  private _dodatniPodaciValidator: ValidatorFn = (
    group: FormGroup
  ): ValidationErrors | null => {
    const narudzbenica = group.value.racunDto.brojNarudzbenice;
    const ugovor = group.value.racunDto.brojUgovora;
    const tender = group.value.racunDto.brojTendera;

    if (!stringIsNullOrEmpty(group.value.racunDto.strankaRacunaDto.jbkjs)) {
      return narudzbenica || ugovor || tender ? null : { dodatniPodaci: true };
    }

    return null;
  };
}
