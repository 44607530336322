<p-inputMask
  id="jmbg"
  mask="9999999999999"
  kodit-select-all-on-focus
  [formControl]="formCtrl"
  [placeholder]="ctrlPlaceholder"
  
></p-inputMask>
<kodit-error-info-max-length
  [formCtrl]="formCtrl"
></kodit-error-info-max-length> 
<kodit-error-info-required [formCtrl]="formCtrl"></kodit-error-info-required>