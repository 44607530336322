import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {SelectItem} from "primeng/api";
import {FiskalniRacuniClient} from "@kodit/core/data-api";
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'kodit-velicina-papira-dropdown',
  templateUrl: './velicina-papira-dropdown.component.html',
  styleUrls: ['./velicina-papira-dropdown.component.scss']
})
export class VelicinaPapiraDropdownComponent implements OnInit, OnDestroy {

  private _subs: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];

  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string;
  @Input() parentCtrl: any = 'body';

  constructor(private _client: FiskalniRacuniClient) { }

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client.getVelicinaPapira().subscribe((res) => {
        this.items = res.data.velicinaPapiraDtoList.map((x) => ({
          value: x.velicinaPapira,
          label: x.velicinaPapiraStr,
          title: x.opis,
        }));
      })
    );
  }
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
