import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SharedClient, ValutaDto } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { isEmptyCheck, SERBIA_CURRENCY_ALPHA_CHAR } from '@kodit/core/shared';

@Component({
  selector: 'kodit-valuta-dropdown',
  templateUrl: './valuta-dropdown.component.html',
  styleUrls: ['./valuta-dropdown.component.scss'],
})
export class ValutaDropdownComponent implements OnInit, OnDestroy {
  /** Subs */
  private _getSub: Subscription = new Subscription();

  /** Props */
  valuteOptions: {
    naziv: string;
    value: string;
    drzava: string;
    alfaChar: string;
  }[] = [];
  valute: ValutaDto[] = [];

  /** I/O */
  @Input() valutaCtrl: AbstractControl;
  @Output()
  onValutaChanged: EventEmitter<ValutaDto> = new EventEmitter<ValutaDto>();

  constructor(private _client: SharedClient) {}

  ngOnInit(): void {
    this._load();
  }

  valutaChanged(event: any) {
    if (this.onValutaChanged) {
      this.onValutaChanged.emit(this.valute.find((x) => x.id === event.value));
    }
  }

  private _load() {
    this._getSub = this._client.getValute().subscribe((result) => {
      this.valute = result.data.valute;
      this.valuteOptions = result.data.valute.map((v) => ({
        value: v.id,
        alfaChar: v.kodValuteAlfaChar,
        naziv: v.naziv,
        drzava: v.nazivDrzave,
      }));
      if (isEmptyCheck(this.valutaCtrl.value)) {
        this.valutaCtrl.patchValue(
          result.data.valute.find(
            (x) => x.kodValuteAlfaChar === SERBIA_CURRENCY_ALPHA_CHAR
          ).id
        );
      }
    });
  }

  ngOnDestroy() {
    this._getSub.unsubscribe();
  }
}
