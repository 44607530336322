import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import { ICreateMemorandumCommand } from '@kodit/core/data-api';
import { FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class MemorandumFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetMemorandumFormGroup(
    model?: ICreateMemorandumCommand
  ): FormGroupTypeSafe<ICreateMemorandumCommand> {
    return this._fb.group<ICreateMemorandumCommand>({
      id: new FormControl(model?.id ?? 0),
      naziv: new FormControl(model?.naziv),
      bojaTabele: new FormControl(model?.bojaTabele),
      napomenaOPoreskomOslobadjanju: new FormControl(
        model?.napomenaOPoreskomOslobadjanju,
        {
          validators: [Validators.maxLength(256)],
        }
      ),

      napomena: new FormControl(model?.napomena),
      prikaziZakonskiAktOValidnosti: new FormControl(
        model?.prikaziZakonskiAktOValidnosti
      ),
      website: new FormControl(model?.website),
      email: new FormControl(model?.email),
      phoneNumber: new FormControl(model?.phoneNumber),
      valutaId: new FormControl(model?.valutaId),
      valutaText: new FormControl(model?.valutaText),
      poslovnaJedinicaId: new FormControl(model?.poslovnaJedinicaId),
      logoFile: new FormControl(model?.logoFile),
      koristiKompanijskiLogo: new FormControl(
        model?.koristiKompanijskiLogo ?? true
      ),
      prikaziFooterSekciju: new FormControl(model?.prikaziFooterSekciju),
    });
  }
}
