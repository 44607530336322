<div class="p-fluid p-mb-5">
  <kodit-stranka-racuna-autocomplete
    (onPravnoLiceChange)="onSelectionChange($event)"
    (onNazivChange)="handleNazivChange($event)"
    (onClear)="onSelectionClear()"
  ></kodit-stranka-racuna-autocomplete>

  <div *ngIf="nazivNepronadjeneStranke" class="p-mt-3">
    <kodit-field
      [fieldLabel]="
        'U bazi nema rezultata za unetu vrednost [' +
        nazivNepronadjeneStranke +
        ']'
      "
    >
      <button
        pButton
        pRipple
        type="button"
        icon="fas fa-plus"
        class="p-mt-2 p-button-outlined btn-create-fizicko-lice"
        (click)="handleCreateFizickoLice()"
        label="Unesi fizičko lice"
        pTooltip="Unesite novo fizičko lice direktno sa račun forme"
      ></button>
    </kodit-field>
  </div>
</div>

<div
  [ngClass]="
    racunFormGroup.controls.strankaRacunaDto.touched &&
    racunFormGroup.controls.strankaRacunaDto.invalid
      ? 'stranka-invalid'
      : ''
  "
>
  <div class="p-field p-grid">
    <label class="p-col-fixed p-m-0" kodit-form-label>Naziv:</label>
    <div class="p-col stranka-text">
      <span>{{
        racunFormGroup.controls.strankaRacunaDto.value.id
          ? racunFormGroup.controls.strankaRacunaDto.value.naziv
          : racunFormGroup.controls.strankaRacunaDto.value.tipStranke ===
            fizickoLiceTip
          ? ''
          : racunFormGroup.controls.strankaRacunaDto.value.naziv
      }}</span>
    </div>
  </div>
  <div class="p-field p-grid">
    <label class="p-col-fixed p-m-0" kodit-form-label>Adresa:</label>
    <div class="p-col stranka-text">
      <span>{{
        racunFormGroup.controls.strankaRacunaDto.value.id
          ? racunFormGroup.controls.strankaRacunaDto.value.adresa
          : racunFormGroup.controls.strankaRacunaDto.value.tipStranke ===
            fizickoLiceTip
          ? ''
          : racunFormGroup.controls.strankaRacunaDto.value.adresa
      }}</span>
    </div>
  </div>
  <div class="p-field p-grid" *ngIf="!jeInostranoPravnoLice">
    <label class="p-col-fixed p-m-0" kodit-form-label>PIB:</label>
    <div class="p-col stranka-text">
      <span>{{ racunFormGroup.controls.strankaRacunaDto.value.pib }}</span>
    </div>
  </div>
  <div class="p-field p-grid" *ngIf="!jeInostranoPravnoLice">
    <label class="p-col-fixed p-m-0" kodit-form-label>MB:</label>
    <div class="p-col stranka-text">
      <span>{{ racunFormGroup.controls.strankaRacunaDto.value.mb }}</span>
    </div>
  </div>
  <div class="p-field p-grid" *ngIf="jeInostranoPravnoLice">
    <label class="p-col-fixed p-m-0" kodit-form-label
      >Registracioni broj:</label
    >
    <div class="p-col stranka-text">
      <span>{{
        racunFormGroup.controls.strankaRacunaDto.value.registracioniBroj
      }}</span>
    </div>
  </div>
</div>
<small
  *ngIf="
    !racunFormGroup.controls.strankaRacunaDto.valid &&
    racunFormGroup.controls.strankaRacunaDto.touched
  "
  id="stranka-help"
  class="p-invalid"
  >Morate izabrati primaoca.</small
>
<kodit-nosioc-javne-nabavke
  [formCtrl]="racunFormGroup.controls.nosiocJavneNabavke"
></kodit-nosioc-javne-nabavke>
