import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { ICenovnikDto, VrstaEntiteta } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { BaseStrankaFormService } from './_base_stranka-form.service';
import { CenovnikStavkeFormService } from './cenovnik-stavka-form.service';

@Injectable({
  providedIn: 'root',
})
export class CenovnikFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _strankaFormService: BaseStrankaFormService,
    private _stavkeCenovnikaFS: CenovnikStavkeFormService
  ) {}

  /**
   * Konvertuje model za cenovnik i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model cenovnika od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetCenovnikFormGroup(
    model?: ICenovnikDto
  ): FormGroupTypeSafe<ICenovnikDto> {
    return this._fb.group<ICenovnikDto>({
      id: new FormControl(model?.id),
      isSingleEntry: new FormControl(false),
      naziv: new FormControl(model?.naziv, {
        validators: [Validators.required, Validators.max(100)],
      }),
      createdBy: new FormControl(model?.createdBy),
      createdOn: new FormControl(model?.createdOn ?? new Date()),
      lastModifiedBy: new FormControl(model?.lastModifiedBy),
      lastModifiedOn: new FormControl(model?.lastModifiedOn ?? new Date()),
      stranke: this._strankaFormService.GetStrankeFormArray(model?.stranke),
      stavkeCenovnika: this._stavkeCenovnikaFS.GetStavkeCenovnikaFormArray(
        model?.stavkeCenovnika
      ),
      vrstaEntiteta: new FormControl(
        model?.vrstaEntiteta ?? VrstaEntiteta.KORISNICKI
      ),
    });
  }
}
