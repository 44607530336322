import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig} from "@kodit/core/shared-ui";

@Component({
  selector: 'kodit-has-avansna-uplata-dialog',
  templateUrl: './has-avansna-uplata-dialog.component.html',
  styleUrls: ['./has-avansna-uplata-dialog.component.scss']
})
export class HasAvansnaUplataDialogComponent implements OnInit {
  /** Props */
  brojAvansnog : string = '';
  datumIzdavanja : string = '';

  constructor(private _dialogConfig: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.brojAvansnog = this._dialogConfig.data?.brojAvansnog;
    this.datumIzdavanja = this._dialogConfig.data?.datumIzdavanja;
  }
}
