import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-global-notification',
  templateUrl: './global-notification.component.html',
  styleUrls: ['./global-notification.component.scss']
})
export class GlobalNotificationComponent implements OnInit {
  /** Conditional props */
  showDugovanjeNotification : boolean = false;

  constructor() { }

  ngOnInit(): void {
    this._shouldShowDugovanjeNotification();
  }

  private _shouldShowDugovanjeNotification(){
    const todaysDate = new Date();
    const month = todaysDate.getMonth();
    const year = todaysDate.getFullYear();
    const lastDayOfMonth = new Date(year, month + 1, 0).getDate();

    this.showDugovanjeNotification =
      todaysDate.getDate() >= 25 &&
      todaysDate.getDate() <= lastDayOfMonth;
  }
}
