import { Component, Input, OnInit } from '@angular/core';
import { TableColumn } from '../table-common';

@Component({
  selector: 'kodit-table-row-data',
  templateUrl: './table-row-data.component.html',
  styleUrls: ['./table-row-data.component.scss'],
})
export class TableRowDataComponent implements OnInit {
  /** Props */
  objectIsString = '[object string]';
  objectIsArray = '[object Array]';

  /** I/O */
  @Input() column: TableColumn;
  @Input() item: any;
  @Input() parent: any;
  @Input() rowIndex: number;

  constructor() {
  }

  ngOnInit(): void {}
}
