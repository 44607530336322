import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CreateKorisnikCommand,
  ICreateKorisnikCommand,
  IdentityClient,
} from '@kodit/core/data-api';
import { FormHelper, KorisnikFormService } from '@kodit/core/form-definitions';
import { AlertService } from '@kodit/core/services';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Message } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-unos-korisnickog-naloga',
  templateUrl: './unos-korisnickog-naloga.component.html',
  styleUrls: ['./unos-korisnickog-naloga.component.scss'],
})
export class UnosKorisnickogNalogaComponent implements OnInit, OnDestroy {
  private _subs = new Subscription();
  private _userEmail: string;
  private _roleId: string;
  private _tenantKey: string;

  errorMessage: Message[] = [];
  showError: boolean;
  showLoader: boolean = false;
  form: FormGroupTypeSafe<ICreateKorisnikCommand>;

  constructor(
    private _client: IdentityClient,
    private route: ActivatedRoute,
    private _router: Router,
    private _formHelper: FormHelper,
    private _alertService: AlertService,
    private _korisnikFS: KorisnikFormService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this._userEmail = params.userEmail;
      this._roleId = params.roleId;
      this._tenantKey = params.tenantKey;
    });
    this.form = this._korisnikFS.GetCreateKorisnikFormGroup(
      this._userEmail,
      this._roleId,
      this._tenantKey
    );
  }

  handleRegisterUser() {
    this.showError = false;
    this.errorMessage = [];
    this.showLoader = true;

    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      this.showLoader = false;
      return;
    }

    this._subs.add(
      this._client
        .createKorisnik(this.form.value as CreateKorisnikCommand)
        .subscribe(
          (res) => {
            if (res.succeeded) {
              this._alertService.addSuccessMsg(res.messages[0]);
              this._router.navigate(['/autentikacija', 'prijava']);
            } else {
              this._alertService.addFailedMsg(res.messages[0]);
            }

            this.showLoader = false;
          },
          (error) => {
            this.showLoader = false;
            this._subs.add(
              error.subscribe((x: string) => {
                x;
                this.errorMessage.push({
                  severity: 'error',
                  summary: 'Greška',
                  detail: x,
                  closable: false,
                });
                this.showError = true;
              })
            );
          }
        )
    );
  }

  handlePrijavaProblema() {}

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
