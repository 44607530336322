import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IzvestajiClient } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { GodinaRacunaDropdownService } from '@kodit/core/services';

@Component({
  selector: 'kodit-kpo-knjiga-pdf',
  templateUrl: './kpo-knjiga-pdf.component.html',
  styleUrls: ['./kpo-knjiga-pdf.component.scss'],
})
export class KpoKnjigaPdfComponent implements OnInit, OnDestroy {
  /** Subscriptions */
  private _subs: Subscription = new Subscription();

  /** Props */
  pdfFileUrl: string;
  pdfFileName: string;
  kpoInnerHtml: SafeHtml;
  isPdfAvailable: boolean = false;

  constructor(
    private _client: IzvestajiClient,
    private domSanitazer: DomSanitizer,
    private _dropdownService: GodinaRacunaDropdownService
  ) {}

  ngOnInit(): void {
    this._loadKpoPdf();

    this._subs.add(
      this._dropdownService.getForm().valueChanges.subscribe((res) => {
        this._loadKpoPdf();
      })
    );
  }

  private _loadKpoPdf() {
    this._subs.add(
      this._client.getKpoPdf().subscribe((res) => {
        this.pdfFileUrl = URL.createObjectURL(res.data);
        this.pdfFileName = (res.data as any).fileName;
        this.kpoInnerHtml = this.domSanitazer.bypassSecurityTrustHtml(
          "<object  data='" +
            this.pdfFileUrl +
            "' type='application/pdf' class='embed-responsive-item' >" +
            'Object ' +
            this.pdfFileUrl +
            ' failed' +
            '</object>'
        );
        this.isPdfAvailable = true;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
