import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import { IPodaciUplateDto } from '@kodit/core/data-api';

@Injectable({
  providedIn: 'root',
})
export class PodaciUplateFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetPodaciUplateFormGroup(
    model?: IPodaciUplateDto
  ): FormGroupTypeSafe<IPodaciUplateDto> {
    const formGroup = this._fb.group<IPodaciUplateDto>({
      model: new FormControl(model?.model, {
        validators: [Validators.maxLength(2), Validators.minLength(2)],
      }),
      pozivNaBroj: new FormControl(model?.pozivNaBroj, {
        validators: [Validators.maxLength(64)],
      }),
      sifraPlacanja: new FormControl(model?.sifraPlacanja, {
        validators: [Validators.maxLength(3)],
      }),
      svrhaPlacanja: new FormControl(model?.svrhaPlacanja, {
        validators: [Validators.maxLength(35)],
      }),
      instrukcijaPlacanja: new FormControl(model?.instrukcijaPlacanja, {
        validators: [Validators.maxLength(128)],
      }),
    });

    this.addPozivNaBrojValidator(formGroup);

    formGroup.get('model')?.valueChanges.subscribe(res =>{
      this.addPozivNaBrojValidator(formGroup);
    })

    return formGroup;
  }

  private addPozivNaBrojValidator(formGroup : FormGroupTypeSafe<IPodaciUplateDto>){
    const modelValue = formGroup.get('model')?.value;
    const pozivNaBrojControl = formGroup.get('pozivNaBroj');

    if(modelValue == '00' || modelValue == '97'){
      pozivNaBrojControl?.setValidators([Validators.maxLength(64), Validators.required]);
    }else{
      pozivNaBrojControl?.setValidators([Validators.maxLength(64)]);
    }

    pozivNaBrojControl?.updateValueAndValidity();
  }
}
