<div card class="k-card">
  <div class="p-d-flex p-jc-between _header">
    <div class="ek-w-70">
      <p class="_title p-mb-2">{{ listConfig.title }}</p>
      <p class="_subtitle">
        <span>{{ listConfig.subtitle }}</span>
      </p>
    </div>
    <div class="_actions" *ngIf="listConfig.actions?.length > 0">
      <button
        icon="fas fa-plus"
        pButton
        type="button"
        label="Unesi"
        class="p-button p-button-outlined"
        (click)="onCreate()"
      ></button>
    </div>
  </div>
  <kodit-divider></kodit-divider>

  <div>
    <p-dataView
      #dv
      [value]="items"
      [paginator]="showPaginator"
      [rows]="listConfig.rows"
      layout="list"
    >
      <!-- <ng-template pTemplate="header">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
              <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
            </div>
          </ng-template> -->
      <ng-template let-item pTemplate="listItem" let-rowIndex="rowIndex">
        <div class="p-col-12">
          <div class="item-list-item">
            <!-- <img
                  [src]="'assets/showcase/images/demo/product/' + product.image"
                  [alt]="product.name"
                /> -->
            <div class="item-list-detail p-mb-2">
              <p-panel
                expandIcon="fas fa-caret-down"
                collapseIcon="fas fa-caret-up"
                [toggleable]="listConfig.toggleable"
                [collapsed]="listConfig.collapsed"
                header="{{ item.value }}"
              >
                <ng-template pTemplate="icons">
                  <span *ngFor="let action of listConfig.actions">
                    <button
                      type="button"
                      pButton
                      pRipple
                      [icon]="action.icon | tableActionIcon: action.type"
                      class="p-button-text p-button p-component p-button-icon-only"
                      [ngClass]="
                        action.actionClass | tableRowActionClass: action.type
                      "
                      (click)="checkAction(action, rowIndex)"
                      [disabled]="!action.config.isAvailable"
                      [label]="
                        action.type == noActionsType
                          ? 'Nema dostupnih akcija'
                          : action.label
                      "
                    ></button>
                  </span>
                </ng-template>
                <div
                  *ngFor="let innerItem of item.innerItems; let i = index"
                  [attr.data-index]="i"
                >
                  <div class="p-d-flex p-mb-2">
                    <div class="p-pr-2 p-label">{{ innerItem.label }}:</div>
                    <div class="p-text">{{ innerItem.value }}</div>
                  </div>
                </div>
              </p-panel>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template let-item pTemplate="gridItem" let-rowIndex="rowIndex">
        <div class="p-col-12 p-md-4" style="text-align: center">
          <div class="item-grid-item p-mr-2 p-mb-2 card">
            <div class="item-grid-item-content">
              <img
                style="width: 5rem; height: 5rem"
                src="https://www.w3schools.com/tags/img_girl.jpg"
                alt="Girl in a jacket"
                width="500"
                height="600"
              />
              <!-- <div class="item-name">{{ item.name }}</div> -->
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>
</div>
