<div class="p-d-flex p-flex-md-column p-fluid" *ngIf="form">
  <kodit-field fieldLabel="Naziv firme">
    <kodit-text-input
      formCtrlId="nazivFirme"
      [formCtrl]="form.controls.naziv"
    ></kodit-text-input>
  </kodit-field>
  <kodit-field fieldLabel="Mesto">
    <kodit-text-input
      formCtrlId="mesto"
      [formCtrl]="form.controls.grad"
    ></kodit-text-input>
  </kodit-field>
  <kodit-field fieldLabel="Ulica i broj">
    <kodit-text-input
      formCtrlId="ulicaIBroj"
      [formCtrl]="form.controls.ulicaIBroj"
    ></kodit-text-input>
  </kodit-field>
  <kodit-field fieldLabel="PIB">
    <kodit-text-input
      formCtrlId="pib"
      [formCtrl]="form.controls.pib"
    ></kodit-text-input>
  </kodit-field>
  <kodit-field fieldLabel="Matični broj">
    <kodit-text-input
      formCtrlId="maticniBroj"
      [formCtrl]="form.controls.maticniBroj"
    ></kodit-text-input>
  </kodit-field>
  <kodit-field fieldLabel="Zastupnik">
    <kodit-text-input
      formCtrlId="zastupnik"
      [formCtrl]="form.controls.zastupnik"
    ></kodit-text-input>
  </kodit-field>
  <kodit-field fieldLabel="Datum">
    <kodit-calendar
      [formCtrl]="form.controls.datum!"
      class="p-fluid"
      formCtrlId="date"
    ></kodit-calendar>
  </kodit-field>
</div>

<div class="loading-card" *ngIf="!form">
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
      <div class="loading-skeleton-bar"></div>
    </div>

