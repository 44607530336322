import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { IBankaDto, SharedClient } from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

interface IRequest {
  searchValue: string;
}

@Component({
  selector: 'kodit-banka-autocomplete',
  templateUrl: './banka-autocomplete.component.html',
  styleUrls: ['./banka-autocomplete.component.scss'],
})
export class BankaAutocompleteComponent
  implements OnInit, OnDestroy, OnChanges {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  formGroup: FormGroupTypeSafe<IRequest>;
  bankaSuggestions: IBankaDto[] = [];
  bankaSelected: boolean;

  /** I/O */
  @Input() mustSelect: boolean = false;
  @Input() formCtrlId: string;
  @Input() inputInitValue: string;
  @Output() onBankaChange = new EventEmitter<IBankaDto>();

  constructor(
    private _client: SharedClient,
    private _fb: FormBuilderTypeSafe
  ) {}

  ngOnInit(): void {
    this.formGroup = this._fb.group<IRequest>({
      searchValue: new FormControl(this.inputInitValue ?? ''),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.inputInitValue.isFirstChange() &&
      changes.inputInitValue.currentValue &&
      changes.inputInitValue.currentValue !==
        changes.inputInitValue.previousValue
    ) {
      this.formGroup.controls.searchValue.patchValue(
        changes.inputInitValue.currentValue
      );
      this.bankaSelected = true;
    }
  }

  handleSearch(event: any) {
    if (this.formGroup.value.searchValue.length < 1) {
      return;
    }
    this._subs.add(
      this._client
        .getBanke(this.formGroup.value.searchValue)
        .subscribe((result) => {
          this.bankaSuggestions = [...result.data.bankeDtoList];
        })
    );
  }

  selectionChanged(value: IBankaDto) {
    this.onBankaChange.emit(value);
    this.bankaSelected = true;
  }

  // /**
  //  * Na svaki unos u autocomplete, pretrazuje stranke iz baze
  //  * @param event event poslat iz autocomplete
  //  */
  // searchStranka(event: any) {
  //   this.banke4Sugesstions = this.bankeResult
  //     .filter((x) =>
  //       x.naziv.toLocaleLowerCase().includes(event.query.toLocaleLowerCase())
  //     )
  //     .map((x) => x.naziv);
  // }

  // onSelectChange(naziv: string) {
  //   const banka = this.bankeResult.find((x) => x.naziv === naziv);
  //   this.bankaCtrl.patchValue(banka);
  //   this.onBankaChange.emit(banka);
  // }

  nazivChanged(event: any) {
    //this.onNazivChange.emit(event.originalTarget.value);
  }

  onClear() {
    //this.bankaCtrl.reset();
    this.bankaSelected = false;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
