import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  AvansniRacuniClient,
  GetAvansnaUplataRacunaTableQuery,
  IAvansnaUplataRacunaTableDto,
  IAvansniRacunTableDto,
  IStavkaRacunaDto,
  OznakaPdvKategorije,
  RacuniClient,
  RemoveVezaniRacunCommand,
  TipRacuna,
} from '@kodit/core/data-api';
import { AvansnaUplataRacunaFormService } from '@kodit/core/form-definitions';
import { AlertService, LocationService } from '@kodit/core/services';
import { TableConfig } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { RacunService } from '../../../../../../../services/src/lib/racun.service';

@Component({
  selector: 'kodit-avansna-uplata-racuna-table',
  templateUrl: './avansna-uplata-racuna-table.component.html',
  styleUrls: ['./avansna-uplata-racuna-table.component.scss'],
})
export class AvansnaUplataRacunaTableComponent implements OnInit, OnDestroy {
  /** Props */
  private _subs: Subscription = new Subscription();
  clonedUplate: { [s: string]: IAvansnaUplataRacunaTableDto } = {};
  avansneUplateTable: FormArray;
  odabraneAvansneUplateZaRacunNonModified: IAvansniRacunTableDto[] = [];
  shouldDisplayActions: boolean;
  racunId: number;
  shouldDisplayMsg: boolean;
  jeEditMode: boolean;

  //#region display column flags
  shouldDisplayS20: boolean;
  shouldDisplayPdv20: boolean;
  shouldDisplayS10: boolean;
  shouldDisplayPdv10: boolean;
  shouldDisplayS8: boolean;
  shouldDisplayPdv8: boolean;
  shouldDisplayAE10: boolean;
  shouldDisplayAE20: boolean;
  shouldDisplayO: boolean;
  shouldDisplayE: boolean;
  shouldDisplayR: boolean;
  shouldDisplayZ: boolean;
  shouldDisplaySS: boolean;
  shouldDisplayOE: boolean;
  shouldDisplayN: boolean;
  //#endregion display column flags

  stopaPoStavkama: { [stopa: string]: boolean }[] = [];

  /** Configurations */
  tableConfig: TableConfig;

  constructor(
    private _client: AvansniRacuniClient,
    private _locationService: LocationService,
    private _avansnaUplataRacunaFS: AvansnaUplataRacunaFormService,
    private _racunService: RacunService,
    private _route: ActivatedRoute,
    private _racunClient: RacuniClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) {
        this.racunId = Number.parseInt(params['id']);
      }
    });

    this._prepareOrLoad();

    this._subs.add(
      this._racunService.getOdabraneAvansneUplateZaRacunNonModified.subscribe(
        (res) => {
          this.odabraneAvansneUplateZaRacunNonModified = res;
          if (res.length > 0) {
            this._setColumnFlags();
            this.shouldDisplayMsg = false;
          } else {
            this.shouldDisplayMsg = true;
          }
        }
      )
    );

    this._subs.add(
      this._racunService.getPoreskaStopaOrKategorijaChangedObservable.subscribe(res => {
        if(res){
          this.shouldDisplayMsg = true;
          this.avansneUplateTable.clear();
        }
      })
    )

    this.jeEditMode = this._route.snapshot.data.racun?.racunDto?.id !== undefined;

    this._subs.add(
      this._racunService.getStavkeArray.valueChanges.subscribe(
        (stavke: IStavkaRacunaDto[]) => {
          this._setColumnForStavke();
        }
      )
    );
  }

  onRowDelete(rowIndex: number) {
    if (!this.jeEditMode) {
      const allAvansnaUplataTable = this.avansneUplateTable
        .value as IAvansnaUplataRacunaTableDto[];
      this._racunService.setOdabraneAvansneUplateZaRacunNonModified = this.odabraneAvansneUplateZaRacunNonModified.filter(
        (x) => x.id !== allAvansnaUplataTable[rowIndex].avansniRacunId
      );
      allAvansnaUplataTable.splice(rowIndex, 1);
      this._racunService.setAvansneUplateRacuna = allAvansnaUplataTable.map(
        (ostalaAvansnaUplataTable) => {
          const nonModified = this.odabraneAvansneUplateZaRacunNonModified.find(
            (x) => x.id === ostalaAvansnaUplataTable.avansniRacunId
          );
          return {
            id: ostalaAvansnaUplataTable.id,
            avansniRacunId: ostalaAvansnaUplataTable.avansniRacunId,
            avansniRacunBroj: ostalaAvansnaUplataTable.avansniRacunBroj,
            avansniRacunVrsta: ostalaAvansnaUplataTable.avansniRacunVrsta,
            //
            evidentiranPdv8: nonModified.pdv8,
            evidentiranPdv10: nonModified.pdv10,
            evidentiranPdv20: nonModified.pdv20,
            evidentiranaOsnovica8: nonModified.preostaloUkupnoOsnovica8,
            evidentiranaOsnovica10: nonModified.preostaloUkupnoOsnovica10,
            evidentiranaOsnovica20: nonModified.preostaloUkupnoOsnovica20,
            evidentiranaOsnovicaAE10: nonModified.preostaloUkupnoOsnovicaAE10,
            evidentiranaOsnovicaAE20: nonModified.preostaloUkupnoOsnovicaAE20,
            evidentiranaOsnovicaE: nonModified.preostaloUkupnoOsnovicaE,
            evidentiranaOsnovicaN: nonModified.preostaloUkupnoOsnovicaN,
            evidentiranaOsnovicaO: nonModified.preostaloUkupnoOsnovicaO,
            evidentiranaOsnovicaOE: nonModified.preostaloUkupnoOsnovicaOE,
            evidentiranaOsnovicaR: nonModified.preostaloUkupnoOsnovicaR,
            evidentiranaOsnovicaSS: nonModified.preostaloUkupnoOsnovicaSS,
            evidentiranaOsnovicaZ: nonModified.preostaloUkupnoOsnovicaZ,
          };
        }
      );
    } else {
      this._subs.add(
        this._racunClient
          .removeVezaniRacun({
            povezanChildRacunId: this.avansneUplateTable.controls[rowIndex]
              .value.avansniRacunId,
            tipPoveznogRacuna: TipRacuna.AVANSNI_RACUN,
            id: this.racunId,
          } as RemoveVezaniRacunCommand)
          .subscribe((res) => {
            if (res.succeeded) {
              location.reload();
              this._alertService.addSuccessMsg('Račun uspešno razvezan');
            } else {
              this._alertService.addFailedMsg('Račun nije moguće razvezati');
            }
          })
      );
    }

    this.shouldDisplayMsg = this.avansneUplateTable.controls.length === 0;
  }

  onRowEditInit(uplata: FormGroupTypeSafe<IAvansnaUplataRacunaTableDto>) {
    this.clonedUplate[uplata.value.avansniRacunId] = { ...uplata.value };
  }

  onRowEditSave(
    uplata: FormGroupTypeSafe<IAvansnaUplataRacunaTableDto>,
    rowIndex: number
  ) {
    this._racunService.setAvansneUplateRacuna = this.avansneUplateTable.value;
  }

  onRowEditCancel(
    uplata: FormGroupTypeSafe<IAvansnaUplataRacunaTableDto>,
    rowIndex: number
  ) {
    this.avansneUplateTable
      .at(rowIndex)
      .patchValue(this.clonedUplate[uplata.value.avansniRacunId]);
    delete this.clonedUplate[uplata.value.avansniRacunId];
    this._racunService.setAvansneUplateRacuna = this.avansneUplateTable.value;
  }

  goToAvansniRacun(rowIndex: number) {
    this._locationService.routeToRacunKartica(
      this.avansneUplateTable.at(rowIndex).value.avansniRacunId,
      TipRacuna.AVANSNI_RACUN,
      this.avansneUplateTable.at(rowIndex).value.avansniRacunVrsta,
      true
    );
  }

  private _prepareOrLoad() {
    if (this._racunService.shouldAllowAvansneAkcije) {
      this.avansneUplateTable = this._racunService.getAvansneUplateRacunaArray;
      this.shouldDisplayActions = true;
      this._setColumnFlags();
      return;
    }

    this._load();
  }

  private _load() {
    this._subs.add(
      this._client
        .getAvansneUplateRacuna(
          new GetAvansnaUplataRacunaTableQuery({
            racunId: this.racunId,
          })
        )
        .subscribe((res) => {
          res.data.responseList.forEach((a) =>
            this._addAvansnuUplatuNaRacun(a)
          );
          this._setColumnFlags();
          this._racunService.setOdabraneAvansneUplateZaRacunNonModified = res.data.responseList.map(
            (x) => ({
              broj: x.avansniRacunBroj,
              id: x.avansniRacunId,
              pdv8: x.evidentiranPdv8,
              pdv10: x.evidentiranPdv10,
              pdv20: x.evidentiranPdv20,
              vrsta: x.avansniRacunVrsta,
              datumIzdavanja: null,
              preostaloUkupnoOsnovica8: x.evidentiranaOsnovica8,
              preostaloUkupnoOsnovica10: x.evidentiranaOsnovica10,
              preostaloUkupnoOsnovica20: x.evidentiranaOsnovica20,
              preostaloUkupnoOsnovicaAE10: x.evidentiranaOsnovicaAE10,
              preostaloUkupnoOsnovicaAE20: x.evidentiranaOsnovicaAE20,
              preostaloUkupnoOsnovicaE: x.evidentiranaOsnovicaE,
              preostaloUkupnoOsnovicaN: x.evidentiranaOsnovicaN,
              preostaloUkupnoOsnovicaO: x.evidentiranaOsnovicaO,
              preostaloUkupnoOsnovicaOE: x.evidentiranaOsnovicaOE,
              preostaloUkupnoOsnovicaR: x.evidentiranaOsnovicaR,
              preostaloUkupnoOsnovicaSS: x.evidentiranaOsnovicaSS,
              preostaloUkupnoOsnovicaZ: x.evidentiranaOsnovicaZ,
            })
          );
        })
    );
  }

  private _addAvansnuUplatuNaRacun(
    avansnaUplata: IAvansnaUplataRacunaTableDto
  ) {
    if (!this.avansneUplateTable) {
      this.avansneUplateTable = this._avansnaUplataRacunaFS.GetAvansneUplateRacunaFormArray(
        []
      );
    }

    this.avansneUplateTable.push(
      this._avansnaUplataRacunaFS.GetAvansneUplateRacunaFormGroup(avansnaUplata)
    );
  }

  private _setColumnFlags() {
    if (!this.avansneUplateTable) {
      return;
    }
    this.shouldDisplayS20 = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranaOsnovica20 > 0
    );
    this.shouldDisplayPdv20 = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranPdv20 > 0
    );
    this.shouldDisplayS10 = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranaOsnovica10 > 0
    );
    this.shouldDisplayPdv10 = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranPdv10 > 0
    );
    this.shouldDisplayS8 = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranaOsnovica8 > 0
    );
    this.shouldDisplayPdv8 = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranPdv8 > 0
    );
    this.shouldDisplayAE10 = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranaOsnovicaAE10 > 0
    );
    this.shouldDisplayAE20 = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranaOsnovicaAE20 > 0
    );
    this.shouldDisplayE = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranaOsnovicaE > 0
    );
    this.shouldDisplayN = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranaOsnovicaN > 0
    );
    this.shouldDisplayO = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranaOsnovicaO > 0
    );
    this.shouldDisplayOE = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranaOsnovicaOE > 0
    );
    this.shouldDisplayR = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranaOsnovicaR > 0
    );
    this.shouldDisplaySS = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranaOsnovicaSS > 0
    );
    this.shouldDisplayZ = this.avansneUplateTable.value.some(
      (x: IAvansnaUplataRacunaTableDto) => x.evidentiranaOsnovicaZ > 0
    );

    if (this._racunService.shouldAllowAvansneAkcije) {
      this._setColumnForStavke();
    }
    this.shouldDisplayMsg = false;
  }

  private _setColumnForStavke() {
    const stavke = this._racunService.getStavkeArray
      .value as IStavkaRacunaDto[];

    this.shouldDisplayS8 = stavke.some((x) => x.stopaPDV == 8);
    this.shouldDisplayPdv8 = stavke.some((x) => x.stopaPDV == 8);

    this.shouldDisplayS10 = stavke.some((x) => x.stopaPDV == 10);
    this.shouldDisplayPdv10 = stavke.some((x) => x.stopaPDV == 10);

    this.shouldDisplayS20 = stavke.some((x) => x.stopaPDV == 20);
    this.shouldDisplayPdv20 = stavke.some((x) => x.stopaPDV == 20);

    this.shouldDisplayAE10 = stavke.some(
      (x) => x.pdvKategorija == OznakaPdvKategorije.AE10
    );
    this.shouldDisplayAE20 = stavke.some(
      (x) => x.pdvKategorija == OznakaPdvKategorije.AE20
    );
    this.shouldDisplayE = stavke.some(
      (x) => x.pdvKategorija == OznakaPdvKategorije.E
    );
    this.shouldDisplayN = stavke.some(
      (x) => x.pdvKategorija == OznakaPdvKategorije.N
    );
    this.shouldDisplayO = stavke.some(
      (x) => x.pdvKategorija == OznakaPdvKategorije.O
    );
    this.shouldDisplayOE = stavke.some(
      (x) => x.pdvKategorija == OznakaPdvKategorije.OE
    );
    this.shouldDisplayR = stavke.some(
      (x) => x.pdvKategorija == OznakaPdvKategorije.R
    );
    this.shouldDisplaySS = stavke.some(
      (x) => x.pdvKategorija == OznakaPdvKategorije.SS
    );
    this.shouldDisplayZ = stavke.some(
      (x) => x.pdvKategorija == OznakaPdvKategorije.Z
    );
  }

  ngOnDestroy() {
    this._racunService.setPoreskaStopaOrKategorijaChanged = false;
    this._subs.unsubscribe();
  }
}
