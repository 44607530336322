import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-akcija-fiskalnog-racuna-card',
  templateUrl: './akcija-fiskalnog-racuna-card.component.html',
  styleUrls: ['./akcija-fiskalnog-racuna-card.component.scss']
})
export class AkcijaFiskalnogRacunaCardComponent implements OnInit {
  applyClass: string = '';

  /**I/O */
  @Input() cardTitle: string = '';
  @Input() cardSubtitle: string = '';
  @Input() cardIcon: string = '';
  @Input() actionType: string = '';
  @Input() useFullRadius: boolean = false;
  @Input() useLeftRadius: boolean  = false;
  @Input() useRightRadius: boolean = false;
  @Input() useBorderNone: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if (this.useFullRadius) {
      this.applyClass = 'radius-full';
    } else if (this.useLeftRadius) {
      this.applyClass = 'radius-left';
    } else if (this.useRightRadius) {
      this.applyClass = 'radius-right';
    } else if (this.useBorderNone) {
      this.applyClass = 'border-middle-none';
    }
  }

  action() {

  }

}
