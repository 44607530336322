import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PoslovneJediniceClient } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-vrsta-poslovne-jedinice-dropdown',
  templateUrl: './vrsta-poslovne-jedinice-dropdown.component.html',
  styleUrls: ['./vrsta-poslovne-jedinice-dropdown.component.scss'],
})
export class VrstaPoslovneJediniceDropdownComponent implements OnInit,OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];

  /** I/O */
  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string;
  @Input() parentCtrl: any = 'body';

  constructor(private _client: PoslovneJediniceClient) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client.getVrstePoslovneJedinice().subscribe((res) => {
        this.items = res.data.vrstePoslovneJediniceDtoList.map((x) => ({
          value: x.vrstaPoslovneJedinice,
          label: x.vrstaPoslovneJediniceStr,
          title: x.opis,
        }));
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
