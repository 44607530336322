<form [formGroup]="form">
  <p-autoComplete
    #autocompleteRef
    [suggestions]="strankaSuggestionsStr"
    formControlName="searchValue"
    (completeMethod)="handleSearch($event)"
    (onSelect)="handleSelectChange($event)"
    placeholder="Naziv, Pib ili Jbkjs stranke..."
    [appendTo]="parentCtrl"
    (onClear)="handleClear()"
    emptyMessage="Stranka nije pronađena"
    dropdownMode="current"
    [disabled]="shouldDisable"
  >
  </p-autoComplete>
  <kodit-error-info-required
    [formCtrl]="form.controls.searchValue"
  ></kodit-error-info-required>
</form>
