import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  IInformacijaIsporukeDto,
  IOtpremnicaDto,
  VrstaOtpremnice,
} from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OtpremnicaService {
  constructor() {}

  /**
   * Postavljanje forme racuna
   */
  otpremnicaForm$ = new BehaviorSubject<OtpremnicaForm>(null);

  set setForm(value: OtpremnicaForm) {
    this.otpremnicaForm$.next(value);
  }

  get getFormObject(): OtpremnicaForm {
    return this.otpremnicaForm$.getValue();
  }
  get getVrsta(): FormControl {
    return this.getFormObject.controls.vrsta as FormControl;
  }
  get getInterniMagacinId(): FormControl {
    return this.getFormObject.controls.interniMagacinId as FormControl;
  }

  get getInformacijeIsporukeFormGroup(): InformacijaIsporukeForm {
    if (!this.getFormObject) {
      return null;
    }
    return this.getFormObject.getSafe<IInformacijaIsporukeDto>(
      (x) => x.informacijaIsporuke
    ) as InformacijaIsporukeForm;
  }

  resetService() {
    this.setForm = null;
  }
}

export declare type OtpremnicaForm = FormGroupTypeSafe<IOtpremnicaDto>;
export declare type InformacijaIsporukeForm = FormGroupTypeSafe<IInformacijaIsporukeDto>;
