import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IPodaciUplateDevizniDto, RacuniClient } from '@kodit/core/data-api';
import { stringIsNullOrEmpty } from '@kodit/core/shared';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-podaci-uplate-devizni',
  templateUrl: './podaci-uplate-devizni.component.html',
  styleUrls: ['./podaci-uplate-devizni.component.scss'],
})
export class PodaciUplateDevizniComponent implements OnInit, OnDestroy {
  @Input()
  podaciUplateDevizniFormGroup: FormGroupTypeSafe<IPodaciUplateDevizniDto>;
  @Input() jeEdit: boolean;
  @Input() jeDuplikat: boolean;
  @Input() jeKonvertovanjeUFakturu: boolean;

  private _sub: Subscription = new Subscription();

  constructor(private _client: RacuniClient) {}

  ngOnInit(): void {
    if (!this.jeEdit && !this.jeDuplikat && !this.jeKonvertovanjeUFakturu) {
      this._loadDeviznePodatkeUplate();
    }
  }

  private _loadDeviznePodatkeUplate() {
    this._sub = this._client.getFormConfigurationDevizni().subscribe((res) => {
      if (
        !res.data ||
        (stringIsNullOrEmpty(res.data.accountAddress) &&
          stringIsNullOrEmpty(res.data.accountName) &&
          stringIsNullOrEmpty(res.data.bank) &&
          stringIsNullOrEmpty(res.data.iban) &&
          stringIsNullOrEmpty(res.data.swift))
      ) {
        return;
      }

      this.podaciUplateDevizniFormGroup.controls.accountAddres.patchValue(
        res.data.accountAddress
      );
      this.podaciUplateDevizniFormGroup.controls.accountAddres.updateValueAndValidity();

      this.podaciUplateDevizniFormGroup.controls.accountName.patchValue(
        res.data.accountName
      );
      this.podaciUplateDevizniFormGroup.controls.accountName.updateValueAndValidity();

      this.podaciUplateDevizniFormGroup.controls.bank.patchValue(res.data.bank);
      this.podaciUplateDevizniFormGroup.controls.bank.updateValueAndValidity();

      this.podaciUplateDevizniFormGroup.controls.iban.patchValue(res.data.iban);
      this.podaciUplateDevizniFormGroup.controls.iban.updateValueAndValidity();

      this.podaciUplateDevizniFormGroup.controls.swift.patchValue(
        res.data.swift
      );
      this.podaciUplateDevizniFormGroup.controls.swift.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
