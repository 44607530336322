import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  GetOznakeQuery,
  IRacunDto,
  OznakaDto,
  OznakeClient,
  TipEntiteta,
} from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { OznakaFormService } from 'libs/core/form-definitions/src';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { RacunService } from '@kodit/core/services';

@Component({
  selector: 'kodit-oznake-racuna',
  templateUrl: './oznake-racuna.component.html',
  styleUrls: ['./oznake-racuna.component.scss'],
})
export class OznakeRacunaComponent implements OnInit, OnDestroy {
  /**
   * Nakon pretrage za autocomplete oznake popunjava se sa rezultatom pretrage
   */
  oznakeResults: OznakaDto[] = [];
  oznakeArray: FormArray;
  oznakeStavkiArray: FormArray;
  showKolicina: boolean;
  currentStavkaKolicina: number;
  currentStavkaKolicinaMax: number;
  currentStavkaIndex: number;
  vezatiZaItems: SelectItem[] = [{ label: 'Ceo račun', value: -1 }];
  @ViewChild('oznakaInput') oznakaInput: any;
  @Input()
  racunForm: FormGroupTypeSafe<IRacunDto>;
  private _searchSub: Subscription = new Subscription();
  private _oznakeSub: Subscription = new Subscription();
  private _oznakeForDeleteSub: Subscription = new Subscription();
  private _stavkeRacunaChangedSub: Subscription = new Subscription();

  constructor(
    private _racunService: RacunService,
    private _oznakaFS: OznakaFormService,
    private _oznakeClient: OznakeClient
  ) {}

  get stavkeRacunaIds(): number[] {
    return this.vezatiZaItems.map((x) => x.value);
  }

  get oznakeValues(): OznakaDto[] {
    return this.oznakeArray.value as OznakaDto[];
  }

  get stavkeRacuna(): FormArray {
    return this.racunForm.controls.stavkeDto as FormArray;
  }

  ngOnInit(): void {
    this.oznakeArray = this.racunForm.controls.oznakeDto as FormArray;

    this._loadOznake();

    this._oznakeForDeleteSub = this._racunService.getOznakeForDelete.subscribe(
      (index) => {
        if (index !== null) {
          this._removeOznake(index);
        }
      }
    );

    this._oznakeSub = this._racunService.getOznakePredmetaStavke.subscribe(
      (oznakePredmeta) => {
        if (oznakePredmeta.length > 0) {
          oznakePredmeta.forEach((o) => this.addOznakuRacuna(o));
        }
      }
    );

    this._stavkeRacunaChangedSub =
      this.racunForm.controls.stavkeDto.valueChanges.subscribe((stavke) => {
        // ovako imamo bug, nece da ocita odmah stavke u EDIT modu
        const noveStavke = stavke.filter(
          (x) =>
            x.predmetStavkeDto.id &&
            !this.stavkeRacunaIds.includes(x.predmetStavkeDto.id)
        );

        noveStavke.forEach((s) => {
          this.vezatiZaItems.push({
            label: s.predmetStavkeDto.naziv,
            value: s.predmetStavkeDto.id,
          });
        });
      });
  }

  filterOznaka(naziv: string): boolean {
    let alreadyAdded: boolean;
    this.oznakeValues.forEach((o) => {
      if (o.punNaziv && o.punNaziv.includes(naziv)) {
        alreadyAdded = true;
        return;
      } else if (o.naziv.includes(naziv)) {
        alreadyAdded = true;
        return;
      }
    });

    return (
      this.oznakeValues.findIndex((x) => x.naziv == naziv) === -1 &&
      !alreadyAdded
    );
  }

  //#region Autocomplete metode za oznake ()

  /**
   * Na svaki unos u autocomplete, pretrazuje oznake iz baze
   * @param event event poslat iz autocomplete
   */
  searchOznake(event: any) {
    this._searchSub = this._oznakeClient
      .get(
        new GetOznakeQuery({
          naziv: event.query,
          excludeTipoveEntiteta: [
            TipEntiteta.PRAVNO_LICE,
            TipEntiteta.FIZICKO_LICE,
          ],
        })
      )
      .subscribe((result) => {
        this.oznakeResults = result.oznake.filter((x) =>
          this.filterOznaka(x.naziv)
        );
      });
  }

  /**
   * Pri svakoj promeni / odabiru iz autocomplete suggestions za oznake
   * Ova funkcija se ne izvrsava ukoliko se unese slobodan tekst (ako se ne odabere iz liste ponudenih)
   * @param oznaka oznaka za dodavanje
   */
  onOznakaSelect(oznaka: OznakaDto) {
    // ukoliko smo vec dodali parent oznaku, zameni je sa ovom oznakom
    this.oznakeValues.forEach((o, i) => {
      if (oznaka.punNaziv && oznaka.punNaziv.includes(o.naziv)) {
        this.oznakeArray.removeAt(i);
        return;
      } else if (oznaka.naziv.includes(o.naziv)) {
        this.oznakeArray.removeAt(i);
        return;
      }
    });

    if (this.currentStavkaKolicina) {
      oznaka.kolicina = this.currentStavkaKolicina;
      oznaka.iznos =
        this.currentStavkaKolicina *
        this.stavkeRacuna.at(this.currentStavkaIndex).value.cenaPoJM;
      oznaka.nazivStavke = `Stavka ${
        this.stavkeRacuna.at(this.currentStavkaIndex).value.redniBroj
      }`;
      this.addOznakuStavkeRacuna(oznaka);
    }
    this.addOznakuRacuna(oznaka);

    // ocisti autocomplete input text
    this.oznakaInput.inputEL.nativeElement.value = '';
  }

  /**
   * Dodaje oznaku za odabranu stavku racuna
   * @param oznaka oznaka za dodavanje
   */
  addOznakuStavkeRacuna(oznaka: OznakaDto) {
    (
      this.stavkeRacuna
        .at(this.currentStavkaIndex)
        .get('oznakeDto') as FormArray
    ).push(this._oznakaFS.GetOznakaFormGroup(oznaka));
  }

  /**
   * Uklanja oznaku racuna nakon sto se klikne  na 'x'
   * @param index indeks oznake
   */
  onRemoveOznakaRacunaClicked(index: number, dto: OznakaDto) {
    this.removeOznakaFromDropdownList(dto);
    this.oznakeArray.removeAt(index);
  }

  /**
   * Uklanja oznaku stavke racuna nakon sto se klikne  na 'x'
   * @param index indeks oznake
   */
  onRemoveOznakaStavkeRacunaClicked(index: number, dto: OznakaDto) {
    const nazivOznaka = this.oznakeArray.at(index).get('punNaziv').value;
    this.stavkeRacuna.controls.forEach((c, i) => {
      (c.get('oznakeDto') as FormArray).controls.forEach((o, oi) => {
        if (o.get('punNaziv').value === nazivOznaka) {
          (this.stavkeRacuna.at(i).get('oznakeDto') as FormArray).removeAt(oi);
          return;
        }
      });
    });
    this.onRemoveOznakaRacunaClicked(index, dto);
  }

  handleOnItemChange(event: any) {
    if (event.value === -1) {
      this.showKolicina = false;
      this.currentStavkaKolicina = null;
      this.currentStavkaKolicinaMax = null;
      this.currentStavkaIndex = null;
      return;
    }
    this.currentStavkaIndex = this.stavkeRacuna.controls.findIndex(
      (x) => x.get('predmetStavkeDto').value.id == event.value
    );
    this.currentStavkaKolicina = this.stavkeRacuna.at(
      this.currentStavkaIndex
    ).value.kolicina;
    this.currentStavkaKolicinaMax = this.currentStavkaKolicina;
    this.showKolicina = true;
  }

  ngOnDestroy() {
    this._searchSub.unsubscribe();
    this._oznakeSub.unsubscribe();
    this._oznakeForDeleteSub.unsubscribe();
    this._stavkeRacunaChangedSub.unsubscribe();
    this._racunService.setOznakeForDelete = null;
  }

  /**
   * Dodaje oznaku u listu oznaka za racun samo ukoliko vec nije u listi
   * @param oznaka oznaka za dodavanje
   */
  private addOznakuRacuna(oznaka: OznakaDto) {
    if (
      !(this.oznakeArray.value as OznakaDto[]).some((x) => x.id === oznaka.id)
    ) {
      this.oznakeArray.push(this._oznakaFS.GetOznakaFormGroup(oznaka));
    }
  }

  //#endregion Autocomplete metode za oznake ()

  private removeOznakuRacuna(dto: OznakaDto) {
    const idx = this.oznakeValues.findIndex(
      (x) => x.id === dto.id || x.naziv === dto.naziv
    );
    if (idx === -1) {
      // oznaka nije pronadena
      return;
    }
    this.onRemoveOznakaRacunaClicked(idx, dto);
  }

  private removeOznakaFromDropdownList(dto: OznakaDto) {
    this.vezatiZaItems = this.vezatiZaItems.filter(
      (item) => !dto.naziv.includes(item.label.toLowerCase())
    );
  }

  private _loadOznake() {
    var oznakeToAdd = [];
    var stavkeToAdd = [];
    this.stavkeRacuna.value.forEach((stavka) => {
      var oznake = stavka.predmetStavkeDto.oznakeDto;
      if (oznake.length > 0) {
        oznake.forEach((oznaka) => {
          if (!oznakeToAdd.some((item) => item.id === oznaka.id)) {
            oznakeToAdd.push(oznaka);
            stavkeToAdd.push(stavka);
          }
        });
      }
    });

    for (let i = 0; i < oznakeToAdd.length; i++) {
      this.addOznakuRacuna(oznakeToAdd[i]);
      this.vezatiZaItems.push({
        label: stavkeToAdd[i].predmetStavkeDto.naziv,
        value: stavkeToAdd[i].predmetStavkeDto.id,
      });
    }
  }

  private _removeOznake(index: number) {
    var oznakeZaBrisanje =
      this._racunService.getStavkaByIndex(index).value.predmetStavkeDto
        .oznakeDto;
    if (this._canDeleteOznake(oznakeZaBrisanje)) {
      oznakeZaBrisanje.forEach((o) => this.removeOznakuRacuna(o));
    }
  }

  private _canDeleteOznake(oznakeZaBrisanje: OznakaDto[]) {
    var br = 0;
    oznakeZaBrisanje.forEach((oznakaZaBrisanje) => {
      this.stavkeRacuna.value.forEach((stavka) => {
        var oznake = stavka.predmetStavkeDto.oznakeDto;
        if (oznake.length > 0) {
          oznake.forEach((oznaka) => {
            if (
              oznaka.id === oznakaZaBrisanje.id ||
              oznaka.naziv === oznakaZaBrisanje.naziv
            ) {
              br++;
            }
          });
        }
      });
    });
    return !(br > 1);
  }
}
