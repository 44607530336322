import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { PredracunDto, PredracuniClient } from '@kodit/core/data-api';
import { BaseService } from '@kodit/core/services';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PredracunZaStrankuResolver
  extends BaseService
  implements Resolve<PredracunDto> {
  constructor(private _client: PredracuniClient, private _router: Router) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PredracunDto> | Promise<PredracunDto> | PredracunDto | null {
    return this._client.getNewForStranka(route.paramMap.get('strankaId')).pipe(
      map((res) => res.data),
      catchError(this.handleError),
      finalize(() => {
        //this._configService.setIsBusy = false;
      })
    );
  }
}
