<div class="p-grid">
  <div class="p-field p-col-12 p-md-6 p-lg-6">
    <label for="rok-za-izlazne" kodit-form-label
    >Rok plaćanja za izlazne dokumente</label
  >
  <p-inputNumber
    [formControl]="rokZaIzlazneCtrl"
    [showButtons]="true"
    buttonLayout="horizontal"
    inputId="rok-za-izlazne"
    spinnerMode="horizontal"
    [step]="1"
    decrementButtonClass="p-button-outlined"
    incrementButtonClass="p-button-outlined"
    incrementButtonIcon="fas fa-plus"
    decrementButtonIcon="fas fa-minus"
    [min]="0"
    [suffix]="getSuffixIzlazne"
    pTooltip="Definiši rok plaćanja za izlazne dokumente ka stranci"
    appendTo="body"
  >
  </p-inputNumber>

  </div>
 

  <div class="p-field p-col-12 p-md-6 p-lg-6">
  
  <label for="rok-za-ulazne" kodit-form-label
    >Rok plaćanja za ulazne dokumente</label
  >
  <p-inputNumber
    [formControl]="rokZaUlazneCtrl"
    [showButtons]="true"
    buttonLayout="horizontal"
    inputId="rok-za-ulazne"
    spinnerMode="horizontal"
    [step]="1"
    decrementButtonClass="p-button-outlined"
    incrementButtonClass="p-button-outlined"
    incrementButtonIcon="fas fa-plus"
    decrementButtonIcon="fas fa-minus"
    [min]="0"
    [suffix]="getSuffixUlazne"
    pTooltip="Definiši rok plaćanja za ulazne dokumente od stranke"
  >
  </p-inputNumber>

</div>

</div>
