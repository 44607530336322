import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit {
  /** I/O */
  @Input() formCtrl: AbstractControl | undefined;
  @Input() ctrlPlaceholder: string = '';
  @Input() formCtrlId: string = 'text-input';
  @Input() formCtrlType: string = 'text';
  @Input() formCtrlClass: string = '';
  @Input() isDisabled: boolean;
  @Output() keyReleased = new EventEmitter();
  @Output() onInputLeft = new EventEmitter<string | number>();

  constructor() {}

  ngOnInit(): void {}

  onKeyUp(value: any) {
    this.keyReleased.emit(value);
  }

  inputLeft(event: any) {
    if (this.onInputLeft) {
      this.onInputLeft.emit(event.target.value);
    }
  }
}
