<form [formGroup]="formGroup">
  <p-autoComplete
    [inputId]="formCtrlId"
    formControlName="searchValue"
    field="naziv"
    emptyMessage="Stranka nije pronađena"
    [suggestions]="pravnaLicaSuggestions"
    [appendTo]="parentCtrl"
    [placeholder]="ctrlPlaceholder"
    [forceSelection]="mustSelect"
    (completeMethod)="searchPravnoLice($event)"
    (onKeyUp)="nazivChanged($event)"
    (onClear)="onClear()"
    (onSelect)="selectionChanged($event)"
  ></p-autoComplete>
  <kodit-error-info-required
    [formCtrl]="formGroup.controls.searchValue"
  ></kodit-error-info-required>
</form>
