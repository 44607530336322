import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  GetRacuniKompenzacijaAutocompleteQuery,
  IRacunKompenzacijaDto,
  RacuniClient,
  VrstaRacuna,
} from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { KompenzacijaService } from '../../kompezacija.service';

interface IRequest {
  searchValue: string;
}

@Component({
  selector: 'kodit-racun-kompenzacija-autocomplete',
  templateUrl: './racun-kompenzacija-autocomplete.component.html',
  styleUrls: ['./racun-kompenzacija-autocomplete.component.scss'],
})
export class RacunKompenzacijaAutocompleteComponent
  implements OnInit, OnDestroy {
  _subs = new Subscription();

  formGroup: FormGroupTypeSafe<IRequest>;
  racunSuggestions: IRacunKompenzacijaDto[] = [];
  vrstaRacuna: VrstaRacuna;

  /* I/O **/
  @Input() formCtrlId: string;
  @Input() vrstaRacunaZaPretragu: VrstaRacuna;
  @Output() onChange = new EventEmitter<IRacunKompenzacijaDto>();
  @Output() onClear = new EventEmitter<void>();
  @Input() ctrlPlaceholder: string = 'Broj ili originalan broj...';
  @Input() disabled: boolean;

  constructor(
    private _client: RacuniClient,
    private _fb: FormBuilderTypeSafe,
    private _kompenzacijeService: KompenzacijaService
  ) {}

  ngOnInit(): void {
    this.formGroup = this._fb.group<IRequest>({
      searchValue: new FormControl(''),
    });
  }

  handleSearch(event: any) {
    if (this.formGroup.value.searchValue.length < 1) {
      return;
    }

    this._subs.add(
      this._client
        .getRacuniKompenzacijaAutocomplete(
          new GetRacuniKompenzacijaAutocompleteQuery({
            searchValue: event.query,
            maticniBroj: this._kompenzacijeService.getStrankaMb(),
            vrstaRacuna: this.vrstaRacunaZaPretragu,
            usedRacunIds: this._getUsedRacunIds(),
          })
        )
        .subscribe((result) => {
          this.racunSuggestions = result.data;
        })
    );
  }

  handleSelect(racun: IRacunKompenzacijaDto) {
    this.ctrlPlaceholder = racun.broj;
    this.onChange.emit(racun);
  }

  handleClear() {
    this.formGroup.reset();
    this.onClear.emit();
  }

  private _getUsedRacunIds(): number[] {
    var poverilackeObavezeIds = [];
    var duznickeObavezeIds = [];

    this._kompenzacijeService.getObavezePremaDuznikuArray.controls.forEach(
      (res) => {
        poverilackeObavezeIds.push(res.value.racunId);
      }
    );

    this._kompenzacijeService.getObavezePremaPoveriocuArray.controls.forEach(
      (res) => {
        duznickeObavezeIds.push(res.value.racunId);
      }
    );

    poverilackeObavezeIds = poverilackeObavezeIds.filter((x) => x !== null);
    duznickeObavezeIds = duznickeObavezeIds.filter((x) => x !== null);

    return poverilackeObavezeIds.concat(duznickeObavezeIds);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
