import { NgModule } from '@angular/core';

/** Moduli */
import { CoreSharedModule } from '@kodit/core/shared';

/** Components */
import { KontaktFormComponent } from './kontakt-form/kontakt-form.component';
import { KontaktTableComponent } from './kontakt-table/kontakt-table.component';
import { KontaktListComponent } from './kontakt-list/kontakt-list.component';

@NgModule({
  imports: [CoreSharedModule],
  declarations: [
    KontaktFormComponent,
    KontaktTableComponent,
    KontaktListComponent,
  ],
  exports: [KontaktFormComponent, KontaktTableComponent, KontaktListComponent],
})
export class CoreModuliKontaktModule {}
