<div class="wrapper center-item">
  <kodit-card-container [showDivider]="false" [showHeader]="false">
    <div card-body>
      <div class="p-mb-5">
        <kodit-welcome-message></kodit-welcome-message>
      </div>
      <div class="p-field-checkbox">
        <p-checkbox
          id="uslovni-checkbox"
          [formControl]="form.controls.prihvaceniUsloviKoriscenja"
          [binary]="true"
        ></p-checkbox>
        <label
          for="uslovni-checkbox"
          style="margin-left: 1rem; margin-right: 0"
          kodit-form-label
          >Slažem se sa
          <a href="/assets/ugovori/opsti_uslovi.pdf" target="_blank"
            >uslovima korišćenja</a
          ></label
        >
        <kodit-error-info-required
          [formCtrl]="form.controls.prihvaceniUsloviKoriscenja"
        ></kodit-error-info-required>
      </div>
    </div>
  </kodit-card-container>
  <div class="p-d-flex p-jc-end">
    <button
      pButton
      pRipple
      type="button"
      label="Potvrdi"
      class="p-button-primary p-mt-3 save-btn"
      pTooltip="Sačuvajte podatke"
      tooltipPosition="bottom"
      (click)="handleSave()"
    ></button>
  </div>
</div>
