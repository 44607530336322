<form [formGroup]="form" (keydown)="keyDownFunction($event)">
  <div class="p-fluid">
    <!-- Account name -->
    <kodit-text-input-field
      formCtrlId="accountName"
      formCtrlLabel="Account name"
      [formCtrl]="form.controls.accountName"
      ctrlPlaceholder="Account name"
    ></kodit-text-input-field>

    <!-- Account address -->
    <kodit-text-input-field
      formCtrlId="accountAddress"
      formCtrlLabel="Account address"
      [formCtrl]="form.controls.accountAddress"
      ctrlPlaceholder="Account address"
    ></kodit-text-input-field>

    <!-- Bank -->
    <kodit-text-input-field
      formCtrlId="bank"
      formCtrlLabel="Bank"
      [formCtrl]="form.controls.bank"
      ctrlPlaceholder="Bank"
    ></kodit-text-input-field>

    <!-- IBAN -->
    <kodit-text-input-field
      formCtrlId="iban"
      formCtrlLabel="IBAN"
      [formCtrl]="form.controls.iban"
      ctrlPlaceholder="IBAN"
    ></kodit-text-input-field>

    <!-- SWIFT -->
    <kodit-text-input-field
      formCtrlId="swift"
      formCtrlLabel="Swift"
      [formCtrl]="form.controls.swift"
      ctrlPlaceholder="SWIFT"
    ></kodit-text-input-field>
  </div>
</form>
