import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

/** Moduli */
import { CoreSharedModule } from '@kodit/core/shared';
import { RobaRoutingModule } from './roba-routing.module';
import { CoreSharedSubformsModule } from '@kodit/core/shared-subforms';

/** Components */
import { RobaTableComponent } from './roba-table/roba-table.component';
import { RobaFormComponent } from './roba-form/roba-form.component';
import { RobaFilterComponent } from './roba-filter/roba-filter.component';
import { RobaInfoComponent } from './roba-info/roba-info.component';
import { TipArtiklaDropdownComponent } from './ui/tip-artikla-dropdown/tip-artikla-dropdown.component';
import { SastavnicaFormComponent } from './sastavnica/sastavnica-form/sastavnica-form.component';
import { SastavnicaTableComponent } from './sastavnica/sastavnica-table/sastavnica-table.component';
import { StanjeMagacinaComponent } from './stanje-magacina/stanje-magacina.component';
import { RepromaterijalRobeMultiselectComponent } from './ui/repromaterijal-robe-multiselect/repromaterijal-robe-multiselect.component';
import { MagaciniMultiselectComponent } from './ui/magacini-multiselect/magacini-multiselect.component';

@NgModule({
  imports: [RobaRoutingModule, CoreSharedModule, CoreSharedSubformsModule],
  declarations: [
    RobaTableComponent,
    RobaFormComponent,
    RobaFilterComponent,
    RobaInfoComponent,
    TipArtiklaDropdownComponent,
    SastavnicaFormComponent,
    SastavnicaTableComponent,
    StanjeMagacinaComponent,
    RepromaterijalRobeMultiselectComponent,
    MagaciniMultiselectComponent,
  ],
  exports: [
    RobaTableComponent,
    RobaFormComponent,
    MagaciniMultiselectComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModuliRobaModule {}
