import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IMagacinTipDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MagacinTipFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  /**
   * Konvertuje model za magacin tip i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model tipa magacina od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetMagacinTipFormGroup(
    model?: IMagacinTipDto
  ): FormGroupTypeSafe<IMagacinTipDto> {
    return this._fb.group<IMagacinTipDto>({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv, {
        validators: [Validators.required, Validators.maxLength(20)],
      }),
      sifra: new FormControl(model?.sifra, {
        validators: [Validators.required, Validators.maxLength(100)],
      }),
    });
  }
}
