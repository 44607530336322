<form [formGroup]="formGroup" *ngIf="!bankaSelected">
  <p-autoComplete
    [inputId]="formCtrlId"
    [suggestions]="bankaSuggestions"
    formControlName="searchValue"
    [field]="bankaSuggestions.length > 0 ? 'naziv' : undefined"
    (completeMethod)="handleSearch($event)"
    (onSelect)="selectionChanged($event)"
    appendTo="body"
    pTooltip="Unesite naziv banke na pretragu"
    (onKeyUp)="nazivChanged($event)"
    (onClear)="onClear()"
  ></p-autoComplete>
  <kodit-error-info-required
    [formCtrl]="formGroup.controls.searchValue"
  ></kodit-error-info-required>
</form>

<form [formGroup]="formGroup" *ngIf="bankaSelected">
  <p-autoComplete
    [inputId]="formCtrlId"
    [suggestions]="bankaSuggestions"
    [formControlName]="'searchValue'"
    (completeMethod)="handleSearch($event)"
    (onSelect)="selectionChanged($event)"
    appendTo="body"
    pTooltip="Unesite naziv banke na pretragu"
    (onKeyUp)="nazivChanged($event)"
    (onClear)="onClear()"
  ></p-autoComplete>
  <kodit-error-info-required
    [formCtrl]="formGroup.controls.searchValue"
  ></kodit-error-info-required>
</form>
