<div class="p-fluid">
  <span class="p-input-icon-right input-box">
    <i class="fas fa-search"></i>
    <p-autoComplete
      (completeMethod)="searchOznake($event)"
      (onSelect)="onOznakaSelect($event)"
      (onUnselect)="onOznakaUnselect($event)"
      [(ngModel)]="oznakeModel"
      [multiple]="true"
      [suggestions]="oznakeSuggestions"
      appendTo="body"
      dataKey="id"
      field="naziv"
      placeholder="Naziv oznake..."
    >
    </p-autoComplete>
  </span>
</div>
