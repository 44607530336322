import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { VrstaRadnogNaloga } from '@kodit/core/data-api';
import { SelectItem, SelectItemGroup } from 'primeng/api';

@Component({
  selector: 'kodit-vrsta-radnog-naloga-dropdown',
  templateUrl: './vrsta-radnog-naloga-dropdown.component.html',
  styleUrls: ['./vrsta-radnog-naloga-dropdown.component.scss'],
})
export class VrstaRadnogNalogaDropdownComponent implements OnInit {
  //* Props */
  items: SelectItem[];
  groupedItems: SelectItemGroup[];

  //** I/O */
  @Input() formCtrl: FormControl | AbstractControl;
  @Input() parent: any = 'body';
  @Input() disabled: boolean;
  @Output() onItemChange = new EventEmitter<VrstaRadnogNaloga>();

  constructor() {}

  ngOnInit(): void {
    this.groupedItems = [
      {
        label: 'Proizvodnja',
        items: [
          {
            label: 'Proizvodnja po narudžbini',
            value: VrstaRadnogNaloga.PROIZVODNJA_PO_NARUDZBINI,
          },
          {
            label: 'Proizvodnja za lager',
            value: VrstaRadnogNaloga.PROIZVODNJA_ZA_LAGER,
          },
        ],
      },
      {
        label: 'Uslužna delatnost',
        items: [
          {
            label: 'Uslužna delatnost',
            value: VrstaRadnogNaloga.USLUZNA_DELATNOST,
          },
          { label: 'Građevina', value: VrstaRadnogNaloga.GRAĐEVINA },
          {
            label: 'Evidencija vremena i materijala',
            value: VrstaRadnogNaloga.EVIDENCIJA_VREMENA_I_MATERIJALA,
          },
        ],
      },
    ];
  }

  handleOnItemChange(event: any) {
    this.onItemChange.emit(event.value);
  }
}
