import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DatumPrometaOption,
  IOpstaPravilaKonfiguracijaDto,
  RacuniClient,
  ResultOfOpstaPravilaKonfiguracijaDto,
  UpdateOpstaPravilaCommand,
} from '@kodit/core/data-api';
import {
  FormHelper,
  OpstaPravilaFormService,
} from '@kodit/core/form-definitions';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogRef, DynamicDialogConfig } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-opsta-pravila-form',
  templateUrl: './opsta-pravila-form.component.html',
  styleUrls: ['./opsta-pravila-form.component.scss'],
})
export class OpstaPravilaFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<IOpstaPravilaKonfiguracijaDto>;
  datumPrometaOptions: SelectItem[] = [];

  constructor(
    public _dialogRef: DynamicDialogRef,
    public _dialogConfig: DynamicDialogConfig,
    private _alertService: AlertService,
    private _client: RacuniClient,
    private _fs: OpstaPravilaFormService,
    private _formHelper: FormHelper
  ) {}

  ngOnInit(): void {
    this.form = this._fs.GetOpstaPravilaFormGroup(
      this._dialogConfig?.data?.model
    );
    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
    this._loadDefaultDatumePrometa();
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.save();
    }
  }

  private save() {
    //('form: ', this.form);
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._subs.add(
      this._client
        .updateOpstaPravila(this.form.value as UpdateOpstaPravilaCommand)
        .subscribe(
          (result: ResultOfOpstaPravilaKonfiguracijaDto) => {
            this._alertService.addSuccessMsg(result.messages[0]);

            this._dialogRef.close(result.data);
          },
          (error) => {
            this._subs.add(
              error.subscribe((x: string) => {
                this._alertService.addFailedMsg(x);
              })
            );
          }
        )
    );
  }

  private _loadDefaultDatumePrometa() {
    this.datumPrometaOptions = [
      {
        value: DatumPrometaOption.DANASNJI_DATUM,
        label: 'Današnji datum',
      },
      {
        value: DatumPrometaOption.POSLEDNJI_DAN_TRENUTNI_MESEC,
        label: 'Poslednji dan u trenutnom mesecu',
      },
      {
        value: DatumPrometaOption.POSLEDNJI_DAN_PRETHODNI_MESEC,
        label: 'Poslednji dan u prethodnom mesecu',
      },
      {
        value: DatumPrometaOption.POSLEDNJI_DAN_NAREDNI_MESEC,
        label: 'Poslednji dan u narednom mesecu',
      },
    ];
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
