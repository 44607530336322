<div [formGroup]="addressForm">
  <div class="p-fluid">
    <kodit-drzava-dropdown
      [drzavaCtrl]="addressForm.controls.drzavaId"
    ></kodit-drzava-dropdown>

    <div class="p-grid">
      <div class="p-field p-col-12 p-md-6 p-lg-8">
        <label kodit-form-label>Grad *</label>
        <kodit-text-input
          [formCtrl]="addressForm.controls.grad"
        ></kodit-text-input>
      </div>
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label kodit-form-label>Poštanski broj</label>
        <kodit-text-input
          [formCtrl]="addressForm.controls.postanskiBroj"
        ></kodit-text-input>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-field p-col-12 p-md-6 p-lg-8">
        <label kodit-form-label>Ulica i broj</label>
        <kodit-text-input
          [formCtrl]="addressForm.controls.ulicaIBroj"
        ></kodit-text-input>
      </div>
    </div>
  </div>
</div>
