import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { SharedClient } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-paritet-dropdown',
  templateUrl: './paritet-dropdown.component.html',
  styleUrls: ['./paritet-dropdown.component.scss'],
})
export class ParitetDropdownComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];

  /** I/O */
  @Input() formCtrl: FormControl | AbstractControl;
  @Input() formCtrlId: string = '';
  @Input() parentCtrl: any = 'body';

  constructor(private _client: SharedClient) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client.getParitet().subscribe((res) => {
        this.items = res.data.paritetDtoList.map((x) => ({
          value: x.paritet,
          label: x.paritetStr,
          title: x.opis,
        }));
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
