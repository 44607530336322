import { Injectable } from '@angular/core';
import { BankaIzvoda } from '@kodit/core/data-api';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TekuciRacunDropdownService {
  constructor() {}

  tekuciRacuniZaBanku$ = new BehaviorSubject<BankaIzvoda>(BankaIzvoda.EMPTY);
  get getTekuciRacuniZaBanku() {
    return this.tekuciRacuniZaBanku$.asObservable();
  }
  set setTekuciRacuniZaBanku(data: BankaIzvoda) {
    this.tekuciRacuniZaBanku$.next(data);
  }

  reset() {
    this.setTekuciRacuniZaBanku = BankaIzvoda.EMPTY;
  }
}
