import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  IGetLagerTransakcijeTableQuery,
  TipLagerTransakcije,
} from 'libs/core/data-api/src/lib/eKompanija-api';

@Injectable({
  providedIn: 'root',
})
export class LagerTransakcijeFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetLagerTransakcijeTableRequest(
    model?: IGetLagerTransakcijeTableQuery
  ): FormGroupTypeSafe<IGetLagerTransakcijeTableQuery> {
    return this._fb.group<IGetLagerTransakcijeTableQuery>({
      tipTransakcije: new FormControl(
        model.tipTransakcije ?? TipLagerTransakcije.MANUELNI
      ),
      stranka: new FormControl(model.stranka),
      brojRacuna: new FormControl(model.brojRacuna),
      datumPrometa: new FormControl(model.datumPrometa),
    });
  }
}
