import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  CenovniciClient,
  CreateCenovnikCommand,
  ICenovnikDto,
  ICenovnikStavkaDto,
  UpdateCenovnikCommand,
} from '@kodit/core/data-api';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import {
  AlertService,
  ButtonService,
  Mode,
  SharedService,
  StrankaMultiselectService,
} from '@kodit/core/services';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import {
  BaseStrankaFormService,
  CenovnikFormService,
  CenovnikStavkeFormService,
} from 'libs/core/form-definitions/src';
import { CenovnikStavkeService } from '../../../../../services/src/lib/cenovnik-stavke.service';
import { FormArray } from '@angular/forms';
import { filterBy } from '@kodit/core/shared';

@Component({
  selector: 'kodit-cenovnik-form',
  templateUrl: './cenovnik-form.component.html',
  styleUrls: ['./cenovnik-form.component.scss'],
})
export class CenovnikFormComponent implements OnInit, OnDestroy {
  /** Subscriptions */
  private _getSub: Subscription = new Subscription();
  private _submitSub: Subscription = new Subscription();
  private _saveSub: Subscription = new Subscription();
  private _loadStavkeCenovnikaSub: Subscription = new Subscription();
  private _strankeSub: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<ICenovnikDto>;
  city: string;

  checked: boolean = false;

  stavkeCenovnika: ICenovnikStavkaDto[] = [];

  mode: Mode = Mode.NEW;

  cenovnikId: number = 1;
  baseCenovnikId: number = 1;

  constructor(
    private _alertService: AlertService,
    private _client: CenovniciClient,
    public _dialogRef: DynamicDialogRef,
    public _dialogConfig: DynamicDialogConfig,
    private _cenovnikFormService: CenovnikFormService,
    private _cenovnikStavkeService: CenovnikStavkeService,
    private _strankaMultiselectService: StrankaMultiselectService,
    private _strankaFS: BaseStrankaFormService,
    private _cenovnikStavkeFS: CenovnikStavkeFormService,
    private _sharedService : SharedService
  ) { }

  ngOnInit(): void {
    this.form = this._cenovnikFormService.GetCenovnikFormGroup();

    //  this.form.controls.isSingleEntry.patchValue(false);

    this.form.controls.stavkeCenovnika;

    // get mode and patch if edit
    if (this._dialogConfig.data && this._dialogConfig.data.id) {
      this._strankaMultiselectService.setStrankeCenovnika = [];
      this.baseCenovnikId = this._dialogConfig.data.id;
      this.getById(this._dialogConfig.data.id);
      this.mode = Mode.EDIT;
    } else {
      this._strankaMultiselectService.setStrankeCenovnika = [];
    }
    // sub on dialog save button
    this._submitSub = this._dialogRef.onSubmit.subscribe(() => {
      this.save();
    });

    this._loadStavkeCenovnikaSub = this._cenovnikStavkeService
      .loadStavkeCenovnika(this.cenovnikId)
      .subscribe((x) => {});

    this._strankeSub = this._strankaMultiselectService.getStrankeCenovnika.subscribe(
      (stranke) => {
        if (stranke.length > 0) {
          this.form.controls.stranke = this._strankaFS.GetStrankeFormArray(
            stranke
          );
        }
      }
    );
  }

  save(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this._loadStavkeCenovnikaSub = this._cenovnikStavkeService.getDesnaLista.subscribe(
      (x) => {
        (this.form.controls.stavkeCenovnika as FormArray).clear();
        x.forEach((s) => {
          (this.form.controls.stavkeCenovnika as FormArray).push(
            this._cenovnikStavkeFS.GetCenovnikStavkaFormGroup(s)
          );
        });
      }
    );

    // everything is ok, let's save it
    if (this.mode === Mode.EDIT) {
      this._sharedService.displayLoadingScreen(true, 'Ažuriranje cenovnika...');
      this._saveSub = this._client
        .update(this.form.value.id, this.form.value as UpdateCenovnikCommand)
        .subscribe((result) => {
          this._alertService.addSuccessMsg(result.messages[0]);
          this._dialogRef.close(result.data);
        });
    } else {
      this._sharedService.displayLoadingScreen(true, 'Kreiranje cenovnika...');
      this._saveSub = this._client
        .create(this.form.value as CreateCenovnikCommand)
        .subscribe((result) => {
          this._alertService.addSuccessMsg(result.messages[0]);
          this._dialogRef.close(result.data);
        });
    }
  }

  getById(id: number): void {
    this._getSub = this._client.getById(id).subscribe((model) => {
      this.form.patchValue(model.data);

      this._cenovnikStavkeService.setDesnaLista = model.data.stavkeCenovnika;
      this._strankaMultiselectService.setStrankeCenovnika = model.data.stranke;
    });
  }

  onCheckKopiraj(showKopiraj: boolean) {
    if (showKopiraj) {
      this._cenovnikStavkeService.setLevaLista = filterBy(
        this._cenovnikStavkeService.levaLista$.value,
        (x: ICenovnikStavkaDto) => {
          return (
            this._cenovnikStavkeService.desnaLista$.value.length === 0 ||
            this._cenovnikStavkeService.desnaLista$.value.findIndex(
              (d) => x.predmetStavkeId === d.predmetStavkeId
            ) === -1
          );
        }
      );
    }
    this.form.controls.isSingleEntry.patchValue(showKopiraj);
  }

  onCenovnikChange(cenovnikId: number) {
    this.cenovnikId = cenovnikId;
    this._loadStavkeCenovnikaSub = this._cenovnikStavkeService
      .loadStavkeCenovnika(this.cenovnikId)
      .subscribe((x) => {});
  }

  onStavkaCenovnikaChange(stavka: any) {
    this._loadStavkeCenovnikaSub = this._cenovnikStavkeService.getDesnaLista.subscribe(
      (existingStavke) => {
        this.stavkeCenovnika = existingStavke;
      }
    );

    this._loadStavkeCenovnikaSub.unsubscribe();

    this.stavkeCenovnika.unshift(stavka);

    this._cenovnikStavkeService.setDesnaLista = this.stavkeCenovnika;
  }

  ngOnDestroy(): void {
    this._getSub.unsubscribe();
    this._submitSub.unsubscribe();
    this._saveSub.unsubscribe();
    this._loadStavkeCenovnikaSub.unsubscribe();
    this._strankeSub.unsubscribe();
    this._cenovnikStavkeService.resetListe();
  }
}
