import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

//* Moduli */
import { NotifikacijeRoutingModule } from './notifikacije-routing';
import { CoreSharedModule } from 'libs/core/shared/src';

//* Components */
import { NotifikacijePageComponent } from './notifikacije-page/notifikacije-page.component';
import { NotifikacijeTableComponent } from './notifikacije-table/notifikacije-table.component';

@NgModule({
  imports: [CommonModule, NotifikacijeRoutingModule, CoreSharedModule],
  declarations: [NotifikacijePageComponent, NotifikacijeTableComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModuliNotifikacijeModule {}
