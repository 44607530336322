<kodit-card-container>
  <div card-title class="p-mb-3">
    Iskorišćenost avansnog računa ({{ racunInfoDto.procenatIznos }}%)
  </div>

  <p-progressBar
    card-subtitle
    [showValue]="false"
    [value]="racunInfoDto.procenatIznos"
  ></p-progressBar>

  <div class="p-d-flex p-flex-column" style="gap: 12px" card-right-side>
    <div
      [ngClass]="
        'p-text-center info-badge status status-uplate-racuna-' +
        racunInfoDto.statusUplateDto.statusBadgeStr
      "
    >
      {{ racunInfoDto.statusUplateDto.statusStr.toUpperCase() }}
    </div>
  </div>

  <div class="p-d-flex p-flex-column gap-12" card-body>
    <div class="p-d-flex p-jc-between">
      <div>
        <div>
          <div class="p-d-flex p-mb-2">
            <div class="p-pr-2 p-label">Preostali iznos:</div>
            <div class="p-text">{{ racunInfoDto.preostaliIznosAvansaStr }}</div>
          </div>
          <div class="p-d-flex p-mb-2">
            <div class="p-pr-2 p-label">Preostali PDV:</div>
            <div class="p-text">{{ racunInfoDto.preostaliIznosPDVStr }}</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <kodit-uplate-po-racunu-table
        [uplateTable]="racunInfoDto.uplateRacunaTable"
      ></kodit-uplate-po-racunu-table>
    </div>
  </div>
</kodit-card-container>
