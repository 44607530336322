import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import {
  IPdvPeriodDto,
  PdvEvidencijeClient,
  PdvOpseg,
  PdvPeriod,
} from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { RacunFilterService } from '../../racun-filter.service';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';

@Component({
  selector: 'kodit-racun-filter-opseg-dropdown',
  templateUrl: './racun-filter-opseg-dropdown.component.html',
  styleUrls: ['./racun-filter-opseg-dropdown.component.css'],
})
export class RacunFilterOpsegDropdownComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription;

  /** Props */
  items: SelectItem[];
  vremenskiOpsegCtrl: FormControl;

  /** I/O */
  @Input() formCtrlId: string;
  @Input() parentCtrl: string;
  @Input() periodCtrl: AbstractControl | undefined;

  constructor(
    private _service: RacunFilterService,
    private _client: PdvEvidencijeClient
  ) {
    this._subs = new Subscription();
    this.items = [];
    this.formCtrlId = 'racun-filter-opseg-dropdown';
    this.parentCtrl = 'body';
    this.vremenskiOpsegCtrl = new FormControl();
  }

  ngOnInit() {
    //this.vremenskiOpsegCtrl = new FormControl(pdvOpseg);

    this._load();
    this._subs.add(
      this.vremenskiOpsegCtrl.valueChanges.subscribe((res) => {
        this._service.setOpseg = res;
      })
    );
  }

  private _load() {
    this._subs.add(
      this._client.getPdvOpsegDropdown().subscribe((res) => {
        if (!res.succeeded) {
          return;
        }
        this.items = res.data!.responseList!.map((x) => ({
          value: x.opseg,
          label: x.opsegStr,
          title: x.opis,
        }));
      })
    );
    if (this.periodCtrl?.value > PdvPeriod.DECEMBAR) {
      this.vremenskiOpsegCtrl.patchValue(PdvOpseg.KVARTALNO);
    }
  }

  ngOnDestroy(): void {
    this._service.resetOpseg();
    this._subs.unsubscribe();
  }
}
