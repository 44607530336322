import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import {
  GetStrankaFilterMultiselectQuery,
  StrankaClient,
  StrankaDto,
  StrankaMultiSelectItem,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-stranka-filter-multiselect',
  templateUrl: './stranka-filter-multiselect.component.html',
  styleUrls: ['./stranka-filter-multiselect.component.scss'],
})
export class StrankaFilterMultiselectComponent
  implements OnInit, OnDestroy, OnChanges {
  /** Subs */
  private _searchSub: Subscription = new Subscription();

  /** Props */
  strankeSuggestions: any[] = [];
  strankeModel: StrankaMultiSelectItem[] = [];

  /** I/O */
  @Input() strankeFormArray: FormArray;
  @Input() reset: boolean;
  @Input() ctrlId: string;

  constructor(
    private _client: StrankaClient
  )
  {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.reset?.currentValue) {
      this.strankeModel = [];
    }
    if (
      changes.strankeFormArray.currentValue.length !=
      changes.strankeFormArray.previousValue?.length
    ) {
      this.strankeModel = this.strankeFormArray.value;
    }
  }

  searchStranka(event: any) {
    this._searchSub = this._client
      .getStrankeFilter(
        new GetStrankaFilterMultiselectQuery({
          searchValue: event.query,
        })
      )
      .subscribe((response) => {
        if (response.succeeded && response.data) {
          this.strankeSuggestions = response.data.stranke.filter((el) => {
            return this.strankeFormArray?.value.every((f: any) => {
              return f.id !== el.id;
            });
          });
        }
      });
  }

  onStrankaSelect(stranka: StrankaMultiSelectItem) {
    this.strankeFormArray.push(new FormControl(stranka));
  }

  onStrankaUnSelect(stranka: StrankaDto) {
    const idx = (this.strankeFormArray
      .value as Array<StrankaMultiSelectItem>).findIndex(
      (x) => x.id === stranka.id
    );
    this.strankeFormArray.removeAt(idx);
  }

  ngOnDestroy(): void {
    this._searchSub.unsubscribe();
  }
}
