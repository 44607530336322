import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DashboardClient,
  GetTopNStrankiDonutResponse,
  VrstaStranke,
} from '@kodit/core/data-api';
import { GodinaRacunaDropdownService } from '@kodit/core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-top-n-stranki-chart-donut',
  templateUrl: './top-n-stranki-chart-donut.component.html',
  styleUrls: ['./top-n-stranki-chart-donut.component.scss'],
})
export class TopNStrankiChartDonutComponent implements OnInit, OnDestroy {
  private _getSub: Subscription = new Subscription();

  /** I/O */
  @Input() vrstaStranke: VrstaStranke;
  @Input() N: number = 5;

  /** Props */
  nazivCard: string;
  tabelaNaziv: string;
  chartData: any = {};
  chartOptions: any;
  topNStrankiDonutResponseList: GetTopNStrankiDonutResponse[] = [];

  constructor(private _client: DashboardClient, private _godinaService: GodinaRacunaDropdownService) {}

  ngOnInit(): void {
    this._load();
    this._initCard();

    this._getSub.add(
      this._godinaService.getForm().valueChanges.subscribe(() => {
        this._load();
      })
    );
  }

  private _load() {
    this._getSub.add(
      this._client
        .getTopNStrankiDonut(this.vrstaStranke, this.N)
        .subscribe((res) => {
          this.topNStrankiDonutResponseList = res.data;
          this._initChart();
        })
    );
  }

  private _initChart() {
    // data
    this.chartData = {
      labels: [...this.topNStrankiDonutResponseList.map((x) => x.naziv)],
      datasets: [
        {
          data: [
            ...this.topNStrankiDonutResponseList.map((x) => x.ukupanIznos),
          ],
          backgroundColor: [
            ...this.topNStrankiDonutResponseList.map((x) => x.boja),
          ],
        },
      ],
    };
    // options
    this.chartOptions = {
      aspectRatio: 2,
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
    };
  }

  private _initCard() {
    this.tabelaNaziv =
      this.vrstaStranke === VrstaStranke.DOBAVLJAC ? 'Dobavljač' : 'Kupac';
    this.nazivCard =
      this.vrstaStranke === VrstaStranke.DOBAVLJAC ? 'Dobavljača' : 'Kupaca';
  }

  ngOnDestroy() {
    this._getSub.unsubscribe();
  }
}
