import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  GetOznakeQuery,
  IOznakaDto,
  OznakeClient,
  TipEntiteta,
  TipOznake,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-oznaka-autocomplete',
  templateUrl: './oznaka-autocomplete.component.html',
  styleUrls: ['./oznaka-autocomplete.component.scss'],
})
export class OznakaAutocompleteComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  oznakeSuggestions: IOznakaDto[] = [];
  oznakaModel: IOznakaDto;

  /** I/O */
  @Input() parentCtrl: any = 'body';
  @Input() oznakaFormGroup: FormGroup;
  @Input() excludeTipoveEntiteta: TipEntiteta[] = [];
  @Input() disabled: boolean = false;
  @Output() onOznakaClear= new EventEmitter<boolean>();

  constructor(
    private _client: OznakeClient,
  ) {}

  ngOnInit(): void {
    if (this.oznakaFormGroup?.value?.id) {
      this.oznakaModel = this.oznakaFormGroup.value;
    }
    this._subs.add(
      this.oznakaFormGroup.valueChanges.subscribe((oznaka) => {
        if (oznaka.id && this.oznakaModel?.id !== oznaka.id) {
          this.oznakaModel = oznaka;
        }
      })
    );
  }

  /**
   * Na svaki unos u autocomplete, pretrazuje oznake iz baze
   * @param event event poslat iz autocomplete
   */
  searchOznaka(event: any) {
    this._subs.add(
      this._client
        .get(
          new GetOznakeQuery({
            naziv: event.query,
            excludeTipoveEntiteta: this.excludeTipoveEntiteta,
            tipOznake: TipOznake.SISTEMSKA,
          })
        )
        .subscribe((result) => {
          this.oznakeSuggestions = result.oznake;
        })
    );
  }

  onSelectChange(oznaka: IOznakaDto) {
    this.oznakaFormGroup.patchValue(oznaka);
  }

  onClear() {
    this.onOznakaClear.emit(true);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
