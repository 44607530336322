import {FormControl, Validators} from '@angular/forms';
import {FormBuilderTypeSafe, FormGroupTypeSafe,} from 'angular-typesafe-reactive-forms-helper';
import {
  IGetFormConfigurationResponse,
  IRadniNalogDto,
  StatusRadnogNaloga,
  TipEntiteta,
  TipRacuna,
  VrstaRacuna,
  VrstaRadnogNaloga,
} from '@kodit/core/data-api';
import {Injectable} from '@angular/core';
import {BaseRacunFormService} from './_base_racun-form.service';

@Injectable({
  providedIn: 'root',
})
export class RadniNalogFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _baseRacunForm: BaseRacunFormService
  ) {}

  public GetRadniNalogFormGroup(
    formConfiguration: IGetFormConfigurationResponse,
    model?: IRadniNalogDto
  ): FormGroupTypeSafe<IRadniNalogDto> {
    const baseRacunForm = this._baseRacunForm.GetBaseRacunFormGroup(
      TipRacuna.RADNI_NALOG,
      VrstaRacuna.IZLAZNI,
      TipEntiteta.RADNI_NALOG,
      formConfiguration,
      model?.racunDto
    );
    return this._fb.group<IRadniNalogDto>({
      racunDto: baseRacunForm,
      rok: new FormControl(model?.rok ?? new Date(), {
        validators: [Validators.required],
      }),
      vrsta: new FormControl(model?.vrsta ?? VrstaRadnogNaloga.PROIZVODNJA_PO_NARUDZBINI, {
        validators: [Validators.required],
      }),
      statusRadnogNaloga: new FormControl(
        model?.statusRadnogNaloga ?? StatusRadnogNaloga.NACRT
      ),
    });
  }
}
