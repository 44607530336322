<!-- <div *ngIf="izvodiTable.length === 0 && isPageLoaded">
  <h4>Trenutno nema izvoda u bazi</h4>
  <kodit-izvod-fileupload
    (uploadedIzvodi)="onIzvodiUpload($event)"
  ></kodit-izvod-fileupload>
</div> -->

<!-- <kodit-page *ngIf="izvodiTable.length > 0 || !isPageLoaded"> -->

<!--<div class="p-mb-3 p-fluid" *ngIf="showMessage">
  <p-message severity="warn" [text]="messageText"></p-message>
</div>-->

<div class="p-mb-3 p-fluid" *ngIf="showMessage">
  <p-messages severity="warn">
    <ng-template pTemplate>
      <div class="p-d-flex p-jc-between" style="width: 100%">
        <div class="p-d-flex p-flex-column gap-6">
          <div *ngFor="let msg of messages">{{ msg }}</div>
        </div>
        <div class="p-d-flex p-ai-center p-jc-center">
          <span
            class="p-message-icon pi pi-exclamation-triangle"
            style="font-size: 4rem"
          ></span>
        </div>
      </div>
    </ng-template>
  </p-messages>
</div>

<kodit-page>
  <kodit-table
    [tableConfig]="tableConfig"
    [items]="izvodiTable"
    [chipItems]="chipItems"
  >
  </kodit-table>
</kodit-page>
