import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetKorisnikTenantAccessResponse } from '@kodit/core/data-api';
import {AuthService, MixpanelService, SharedService} from '@kodit/core/services';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'kodit-korisnik-tenant-dropdown',
  templateUrl: './korisnik-tenant-dropdown.component.html',
  styleUrls: ['./korisnik-tenant-dropdown.component.scss'],
})
export class KorisnikTenantDropdownComponent implements OnInit {
  /** Props */
  tenantOptions: SelectItem[] = [];
  selectedTenant: string;

  /** I/O */
  @Input() availableTenants: GetKorisnikTenantAccessResponse;

  constructor(
    private _authService: AuthService,
    private router: Router,
    private _mixpanelService: MixpanelService,
  ) {}

  ngOnInit(): void {
    this.tenantOptions = this.availableTenants.korisnikTenantAccesses.map(
      (t) => ({
        value: t.tenantKey,
        label: t.tenantName,
      })
    );
    this.selectedTenant = this._authService.getCurrentTenant;
  }

  tenantChanged(event: any) {
    this._mixpanelService.tenantChange();
    this.router.navigate(['/promena-firme'], {
      state: {
        korisnikEmail: this._authService.getUserData.email,
        tenantKey: event.value,
        tenantName: this.tenantOptions.find((x) => x.value === event.value)
          .label,
      },
    }).then();
  }
}
