import { Component, OnDestroy, OnInit } from '@angular/core';
import { DelovodniciClient, UpdateDelovodnikCommand } from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { DelovodnikService, UpdateDelovodnikForm } from 'libs/core/moduli/podesavanje/src/lib/delovodnik/delovodnik-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-update-delovodnik-form',
  templateUrl: './update-delovodnik-form.component.html',
  styleUrls: ['./update-delovodnik-form.component.scss'],
})
export class UpdateDelovodnikFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();
  /** Forms */
  form!: UpdateDelovodnikForm;

  constructor(
    private _delovodnikService: DelovodnikService,
    private _formHelper: FormHelper,
    private _client: DelovodniciClient,
    private _dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._client
        .getDelovodnik(this.dialogConfig.data.id)
        .subscribe((res) => {
          if (res.succeeded) {
            this.form = this._delovodnikService.getUpdateDelovodnikForm(
              res.data
            );
          }
        })
    );

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this._save();
    }
  }

  private _save(){
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._subs.add(
      this._client
        .updateDelovodnik(
          this.form.value as UpdateDelovodnikCommand
        )
        .subscribe((result) => {
          if (result.succeeded) {
            this._alertService.addSuccessMsg(result.messages![0]);
            this._dialogRef.close(result);
          } else {
            this._alertService.addWarnMsg(result.messages![0]);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
