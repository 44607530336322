import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TipPristupa } from '@kodit/core/data-api';
import { AuthService } from '@kodit/core/services';
import { ConfirmationService } from 'primeng/api';
import { AdvancedFilterItem } from '../filter/advanced-filter/advanced-filter-item';
import { ActionType, ITableConfig, TableAction } from '../table-common';

@Component({
  selector: 'kodit-table-toolbar',
  templateUrl: './table-toolbar.component.html',
  styleUrls: ['./table-toolbar.component.scss'],
})
export class TableToolbarComponent implements OnInit, OnChanges {
  /** Props */
  actionTypeMultiDelete: ActionType = ActionType.MULTIDELETE;
  actionTypeDropdown: ActionType = ActionType.DROPDOWN;
  actionTypeMultiCustom: ActionType = ActionType.MULTI_CUSTOM;
  advancedFilterItem: AdvancedFilterItem;
  singleActions: any[];
  dropdownActions: any[];

  /** I/O */
  @Input() tableConfig: ITableConfig;
  @Input() parent: any;
  @Input() selectedItems: any[];

  @Output() selectedItemsEvent  = new EventEmitter<any[]>();

  constructor(
    private _confirmationService: ConfirmationService,
    private _authService: AuthService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableConfig?.currentValue) {
      this._setTableActions();
    }
  }

  ngOnInit(): void {}

  showSidebar() {
    this.advancedFilterItem = new AdvancedFilterItem(
      this.tableConfig.advancedFilter.component,
      this.tableConfig.advancedFilter.data,
      this.tableConfig.advancedFilter.availableFilters
    );
  }

  checkAction(action: TableAction) {
    if (action.type !== ActionType.MULTIDELETE) {
      action.callback(
        action.type === ActionType.DROPDOWN ||action.type ===  ActionType.CUSTOM || action.type === ActionType.MULTI_CUSTOM
          ? this.selectedItems
          : null
      );
      this.selectedItemsEvent.emit([]);
      return;
    }
    this._confirmationService.confirm({
      message: this.tableConfig.multiDeleteMessage,
      header: 'Potvrdite brisanje',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        action.callback(this.selectedItems);
        // this.multiDeleteClicked.emit(this.selectedItems.map((x) => x.id));
      },
    });
  }

  checkActionAccess(action: TableAction) {
    if (this._authService.hasClaim(TipPristupa[action.tipPristupa])) {
      return { isAvailable: true, tooltip: action.hasAccessTooltip };
    }

    return {
      isAvailable: false,
      tooltip: action.noAccessTooltip,
    };
  }

  toggleMenu(menu: any, event: any) {
    // this.tableConfig.moreActionsItemIndex = rowIndex;
    menu.toggle(event);
  }

  /**
   * @deprecated
   */
  onDelete(action: TableAction) {
    this._confirmationService.confirm({
      message: this.tableConfig.multiDeleteMessage,
      header: 'Potvrdite brisanje',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        action.callback(this.selectedItems);
        // this.multiDeleteClicked.emit(this.selectedItems.map((x) => x.id));
      },
    });
  }

  private _setTableActions() {
    this.singleActions = this.tableConfig.headerActions
      ?.filter((a) => a.isVisible !== false && a.type !== ActionType.DROPDOWN)
      ?.map((a) => {
        const hasAccess =
          !a.tipPristupa ||
          this._authService.hasClaim(TipPristupa[a.tipPristupa]);

        return {
          type: a.type,
          isAvailable: hasAccess,
          icon: a.icon,
          label: a.label,
          actionClass: a.actionClass,
          joyrideStep: a.joyrideStep,
          shouldDisableWhenSefInactive: a.shouldDisableWhenSefInactive,
          tooltip: hasAccess ? a.hasAccessTooltip : a.noAccessTooltip,
          callback: (request?: any) => (hasAccess ? a.callback(request) : null),
        };
      });

    this.dropdownActions = this.tableConfig.headerActions
      ?.filter((a) => a.isVisible !== false && a.type === ActionType.DROPDOWN)
      .map((a) => ({
        tooltip: a.hasAccessTooltip,
        icon: a.icon,
        label: a.label,
        actionClass: a.actionClass,
        shouldDisableWhenSefInactive: a.shouldDisableWhenSefInactive,
        items: a.mutliActions
          .filter((ma) => ma.isVisible !== false)
          .map((ma) => ({
            label: ma.label,
            icon: ma.icon,
            title: ma.hasAccessTooltip,
            command: () => ma.callback(),
          })),
      }));
  }
}
