import { FormControl } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  IGetFormConfigurationResponse,
  IUlaznaFakturaDto,
  TipEntiteta,
  TipRacuna,
  VrstaFakture,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { BaseRacunFormService } from './_base_racun-form.service';

@Injectable({
  providedIn: 'root',
})
export class UlaznaFakturaFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _baseRacunForm: BaseRacunFormService
  ) {}

  /**
   * Konvertuje model i od njega pravi reaktivnu formu (form grupu) za domace fakture
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   * @param model model domace fakture
   * @returns Reaktivnu formu kao FormGroup za fakturu
   */
  public GetUlaznaFakturaFormGroup(
    model?: IUlaznaFakturaDto,
    formConfiguration?: IGetFormConfigurationResponse
  ): FormGroupTypeSafe<IUlaznaFakturaDto> {
    const baseRacunForm = this._baseRacunForm.GetBaseRacunFormGroup(
      TipRacuna.ULAZNA_FAKTURA,
      VrstaRacuna.ULAZNI,
      TipEntiteta.ULAZNA_FAKTURA,
      formConfiguration,
      model?.racunDto
    );
    return this._fb.group<IUlaznaFakturaDto>({
      racunDto: baseRacunForm,
      brojFiskalnogIsecka: new FormControl(model?.brojFiskalnogIsecka),
      vrstaFakture: new FormControl(model?.vrstaFakture ?? VrstaFakture.DOMACA),
    });
  }
}
