import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import {
    ObradaFormRequest,
  SefClient,
} from '@kodit/core/data-api';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class AutomatskaObradaSefRacunaResolver implements Resolve<ObradaFormRequest> {
  constructor(
    private _client: SefClient,
    private _router: Router,
    private _loaderService: LoaderService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<ObradaFormRequest>
    | Promise<ObradaFormRequest>
    | ObradaFormRequest
    | null {
    this._loaderService.setLoadingText =
      'Preuzimanje ulaznog računa sa SEF-a...';
    this._loaderService.setShowLoader = true;

    return this._client
      .getObradaForm(Number(route.paramMap.get('sefRacunId')))
      .pipe(
        map((x) => {
          x.data.neobradjenRacunDto.statusRacuna = this._router.getCurrentNavigation().extras.state.statusRacuna;
          return x.data;
        }),
        catchError((err) => {
          let currentUrl = this._router.url;
          if (currentUrl.includes('?')) {
            currentUrl = this._router.url.split('?')[0];
          }
          this._router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this._router.navigate([currentUrl], {
                queryParams: { activeTab: 5 },
              });
            });
          throw err;
        }),
        finalize(() => {
          this._loaderService.reset();
        })
      );
  }
}
