<div
  card
  [ngClass]="'k-card ' + cardClass"
  [style.min-height.px]="cardMinHeight"
>
  <ng-container *ngIf="showHeader">
  
  <ng-content select="[card-actions]"> </ng-content>

  
    <!-- Title bez ikonice -->
    <div
      class="p-d-flex p-jc-between _header"
      *ngIf="!isTitleWithIcon && !isCustomTitle"
    >
      <div class="k-w-50">
        <div class="_title">
          <ng-content select="[card-title]"> </ng-content>
        </div>
        <div class="_subtitle">
          <ng-content select="[card-subtitle]"> </ng-content>
        </div>
      </div>
      <div>
        <ng-content select="[card-right-side]"> </ng-content>
      </div>
    </div>

    <!-- Title sa ikonicom -->
    <div
      class="p-d-flex p-jc-between p-ai-center p-flex-column _header"
      *ngIf="isTitleWithIcon"
    >
      <div>
        <span class="fa-stack fa-3x info_icon">
          <i class="_icon_back fas fa-square fa-stack-2x"></i>
          <i [ngClass]="'_icon_front fa-stack-1x ' + titleIcon"></i>
        </span>
      </div>
      <div class="_title p-mt-2">
        <ng-content select="[card-title-with-icon]"> </ng-content>
      </div>
      <div class="_subtitle p-as-start p-mt-2">
        <ng-content select="[card-subtitle-with-icon]"> </ng-content>
      </div>
    </div>

    <!-- Title sa slikom -->
    <div
      class="p-d-flex p-jc-between p-ai-center p-flex-column _header"
      *ngIf="isCustomTitle"
    >
      <ng-content select="[card-custom-title]"> </ng-content>
    </div>
  </ng-container>
  <kodit-divider *ngIf="showDivider"></kodit-divider>

  <ng-content select="[card-body]"> </ng-content>

  
</div>
