<div class="p-d-flex p-flex-column p-flex-md-row">
  <!-- Poverilac -->
  <kodit-card-container class="p-col-6" cardMinHeight="350">
    <div card-title>Podaci poverioca</div>
    <div class="p-d-flex p-flex-column p-fluid" card-body>
      <ng-container
        *ngIf="jeMojaFirmaPoverilac; then mojaFirma; else drugaStrana"
      >
      </ng-container>
    </div>
  </kodit-card-container>

  <!-- Duznik -->
  <kodit-card-container class="p-col-6" cardMinHeight="350">
    <div card-title>Podaci dužnika</div>
    <div class="p-d-flex p-flex-column gap-12" card-body>
      <ng-container
        *ngIf="jeMojaFirmaPoverilac; then drugaStrana; else mojaFirma"
      >
      </ng-container>
    </div>
  </kodit-card-container>
</div>

<div class="p-d-flex p-flex-column">
  <div class="p-d-flex p-flex-column p-flex-md-row">
    <!-- Dužničke obaveze prema poveriocu -->
    <kodit-card-container class="p-col-6" cardMinHeight="350">
      <div card-title>
        {{ vrstaRacuna === vrstaRacunaIzlazni ?
          'Dužničke obaveze prema poveriocu' :
          'Poverilačke obaveze prema dužniku'
        }}
      </div>

      <div card-body>
        <kodit-obaveze-kompenzacija-table
          [jePremaPoveriocu]="vrstaRacuna === vrstaRacunaIzlazni ? false : true"
          [jeMojaFirmaPoverilac]="!jeMojaFirmaPoverilac"
        ></kodit-obaveze-kompenzacija-table>
      </div>
    </kodit-card-container>

    <!-- Poverilačke obaveze prema dužniku -->
    <kodit-card-container class="p-col-6" cardMinHeight="350">
      <div card-title>
        {{ vrstaRacuna === vrstaRacunaIzlazni ?
          'Poverilačke obaveze prema dužniku' :
          'Dužničke obaveze prema poveriocu'
        }}
      </div>

      <div card-body>
        <kodit-obaveze-kompenzacija-table
          [jePremaPoveriocu]="vrstaRacuna === vrstaRacunaIzlazni ? true : false"
          [jeMojaFirmaPoverilac]="jeMojaFirmaPoverilac"
        >
        </kodit-obaveze-kompenzacija-table>
      </div>
    </kodit-card-container>
  </div>
  <kodit-card-container class="p-col-12" cardMinHeight="150">
    <div card-title>Napomena</div>
    <div card-body >
      <kodit-napomena-kompenzacije
        [napomenaCtrl]="kompenzacijaForm.controls.napomena"
      ></kodit-napomena-kompenzacije>
    </div>
  </kodit-card-container>
</div>

<div class="p-mt-3" card style="position: sticky; bottom: 0; z-index: 9">
  <kodit-akcije-racuna (saveClicked)="onSave()" (cancelClicked)="onCancel()">
    <ng-content select="[stampanje-racuna]"></ng-content>
  </kodit-akcije-racuna>
</div>

<ng-template #mojaFirma>
  <kodit-moja-firma-kompenzacija-form> </kodit-moja-firma-kompenzacija-form>
  <kodit-field fieldLabel="Tekući račun">
    <br />
    <kodit-tekuci-racun-dropdown
      [tekuciRacunCtrl]="kompenzacijaForm.controls.tekuciRacunId"
    ></kodit-tekuci-racun-dropdown>
  </kodit-field>
</ng-template>

<ng-template #drugaStrana>
  <kodit-stranka-kompenzacija-form
    [strankaFormGroup]="kompenzacijaForm.controls.stranka"
  >
  </kodit-stranka-kompenzacija-form>
</ng-template>
