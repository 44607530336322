<kodit-card-container [showDivider]="false" [showHeader]="false">
  <div class="p-d-flex p-flex-column p-flex-md-row p-jc-between" card-body>
    <div class="left-side">
      <kodit-welcome-message></kodit-welcome-message>
    </div>
    <div class="p-d-flex p-jc-center p-ai-center right-side">
      <kodit-prvi-login-kompanija-form
        [form]="form"
      ></kodit-prvi-login-kompanija-form>
    </div>
  </div>
</kodit-card-container>
<div class="p-d-flex p-jc-end">
  <button
    pButton
    pRipple
    type="button"
    label="Potvrdi"
    class="p-button-primary p-mt-3 save-btn"
    pTooltip="Sačuvajte podatke"
    tooltipPosition="bottom"
    (click)="handleSave()"
  ></button>
</div>
