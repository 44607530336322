import { Component, Input, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Pdv39Form } from '../pdv-evidencija-pojedinacna.service';

@Component({
  selector: 'kodit-povezani-avansni-racuni-van-sefa',
  templateUrl: './povezani-avansni-racuni-van-sefa.component.html',
  styleUrls: ['./povezani-avansni-racuni-van-sefa.component.scss'],
})
export class PovezaniAvansniRacuniVanSefaComponent implements OnInit {
  @Input() form!: Pdv39Form;
  @Input() shouldDisable: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  onRemovePovezanAvansniRacun(index: number) {
    (this.form!.controls.povezaniVanSefa as FormArray).removeAt(index);
  }
}
