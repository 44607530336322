<div card class="page-wrapper p-p-0">
  <div class="steps">
    <div class="steps-wrapper">
      <div class="steps-title p-mb-5">POTREBNI KORACI</div>
      <div class="steps-container">
        <!-- Step 01 -->
        <div class="step-card step-card__active">
          <i class="fas fa-circle-check"></i>
          <p>Lista kat. dok. materijala</p>
        </div>

        <!-- Step 02 -->
        <div class="step-card step-card__inactive">
          <i class="fas fa-circle-check"></i>
          <p>Skladišne jedinice</p>
        </div>

        <!-- Step 03 -->
        <div class="step-card step-card__inactive">
          <i class="fas fa-circle-check"></i>
          <p>Popunjavanje fizičkih lokacija</p>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <!-- Step 01 page -->
    <div class="step-page-01" *ngIf="step01Visible">
      <kodit-arhivska-exist-step1></kodit-arhivska-exist-step1>
    </div>

    <!-- Step 02 page -->
    <div class="step-page-02" *ngIf="step02Visible">
      <kodit-arhivska-exist-step2></kodit-arhivska-exist-step2>
    </div>

    <!-- Step 03 page -->
    <div class="step-page-03" *ngIf="step03Visible">
      <kodit-arhivska-exist-step3></kodit-arhivska-exist-step3>
    </div>
  </div>
</div>

<div class="button-container">
  <kodit-button
    *ngIf="!potvrdiZavrsiVisible"
    [isBtnDisabled]="!sledeciKorakEnabled"
    btnClass="p-mt-4"
    btnLabel="Sledeći korak"
    btnIcon="fa-solid fa-arrow-right"
    (btnClicked)="goToSledeciKorak()"
  ></kodit-button>

  <button
    *ngIf="potvrdiZavrsiVisible"
    [disabled]="!potvrdiZavrsiEnabled"
    pButton
    type="button"
    label="Potvrdi i zavši"
    class="p-button-success potvrdi-btn"
    styleClass="p-button-sm"
    (click)="handlePotvrdiIZavrsi()"
  ></button>
</div>
