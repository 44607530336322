import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {AlertService, LocationService, SharedService} from '@kodit/core/services';
import {
  CreatePredracunCommand,
  IPredracunDto,
  PredracuniClient,
  TipRacuna,
  UpdatePredracunCommand,
} from '@kodit/core/data-api';
import { ConfirmationService } from 'primeng/api';
import { RacunService } from '../../../../../../services/src/lib/racun.service';
import {
  FormHelper,
  PredracunFormService,
} from 'libs/core/form-definitions/src';
import { ActivatedRoute } from '@angular/router';
import { RacunFormConfig } from '../../subforms/racun-form-container/racun-form.config';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { FormArray } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'kodit-predracun-form',
  templateUrl: './predracun-form.component.html',
  styleUrls: ['./predracun-form.component.scss'],
})
export class PredracunFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  racunForm: FormGroupTypeSafe<IPredracunDto>;
  racunFormConfig: RacunFormConfig;

  constructor(
    private _client: PredracuniClient,
    private _alertService: AlertService,
    private _confirmationService: ConfirmationService,
    private _locationService: LocationService,
    private _racunService: RacunService,
    private _formService: PredracunFormService,
    private _route: ActivatedRoute,
    private _formHelper: FormHelper,
    private _breadcrumbService: BreadcrumbService,
    private _sharedService : SharedService
  ) {}

  ngOnInit(): void {
    // get route data
    this.racunForm = this._formService.GetPredracunFormGroup(
      this.jePonuda,
      this._route.snapshot.data.formConfiguration,
      this._route.snapshot.data.racun
    );
    // configure labels
    this._racunService.setBrojRacunaLabel = 'Broj predračuna';
    this.racunFormConfig = {
      tip: this.jePonuda ? TipRacuna.PONUDA : TipRacuna.PREDRACUN,
      racunForm: this.racunForm.controls.racunDto,
      brojRacunaTooltip: 'Dodeljen broj predračuna',
      originalanBrojRacunaTooltip:
        'Unesite broj predračuna koji ste dobili od izdavaoca',
      saveCallback: () => this.onSave(),
      cancelCallback: () => this.onCancel(),
    };
    this._setBreadcrumb();
  }

  get jeEditMode(): boolean {
    return this._route.snapshot.data.racun?.racunDto?.id;
  }

  get jePonuda(): boolean {
    return this._route.snapshot.data.jePonuda ?? false;
  }

  get stavkeArray(): FormArray {
    return this.racunForm.get('stavke') as FormArray;
  }

  onSave() {
    this._formHelper.removeEmptyStavka(
      this.racunForm.controls.racunDto.get('stavkeDto') as FormArray
    );
    // check is it valid
    if (!this.racunForm.valid) {
      this._formHelper.invalidateForm(this.racunForm);
      return;
    }

    // everything is ok, so let's save it
    if (this.jeEditMode) {
      this._sharedService.displayLoadingScreen(true,"Ažuriranje ".concat(this.jePonuda ? "ponude" : "predračuna"));
      // it's edit
      this._subs.add(
        this._client
          .update(
            this.racunForm.value.racunDto.id,
            this.racunForm.value as UpdatePredracunCommand
          )
          .subscribe(() => {
            this._alertService.addSuccessMsg(
              `${
                this.jePonuda
                  ? 'Ponuda je uspešno izmenjena'
                  : 'Predračun je uspešno izmenjen'
              }`
            );
            this._locationService.routeBack();
          })
      );
    } else {
      // it's new
      this._sharedService.displayLoadingScreen(true,"Kreiranje ".concat(this.jePonuda ? "ponude" : "predračuna"));
      this._subs.add(
        this._client
          .create(this.racunForm.value as CreatePredracunCommand)
          .subscribe(() => {
            this._alertService.addSuccessMsg(
              `${
                this.jePonuda
                  ? 'Ponuda je uspešno kreirana.'
                  : 'Predračun je uspešno kreiran.'
              }`
            );
            this._locationService.routeBack();
          })
      );
    }
  }

  onCancel() {
    this._confirmationService.confirm({
      message: 'Ukoliko otkažete sav ne snimljen sadržaj će biti izgubljen.',
      acceptLabel: 'Ipak otkaži',
      rejectLabel: 'Ostani na stranici',
      header: 'Potvrdite otkazivanje',
      icon: 'far fa-check-circle',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._locationService.routeBack();
      },
    });
  }

  private _setBreadcrumb() {
    this._breadcrumbService.set(
      '@unosPredracunaZaStranku',
      `Unos predračuna za '${this.racunForm.value.racunDto.strankaRacunaDto.naziv}'`
    );
    this._breadcrumbService.set(
      '@izmenaPredracuna',
      `Izmena predračuna ${this.racunFormConfig.racunForm.value.broj}`
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
