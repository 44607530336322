import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import {
  IGetMagacinTableRequest,
  IMagacinDto,
  IMagacinTipDto,
} from '@kodit/core/data-api';
import { AdresaFormService } from '../common/adresa-form.service';

@Injectable({
  providedIn: 'root',
})
export class MagacinFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _adresaFS: AdresaFormService
  ) {}

  /**
   * Konvertuje model za magacin i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model magacina od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetMagacinFormGroup(
    model?: IMagacinDto
  ): FormGroupTypeSafe<IMagacinDto> {
    return this._fb.group<IMagacinDto>({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv, {
        validators: [Validators.required, Validators.maxLength(50)],
      }),
      createdBy: new FormControl(model?.createdBy),
      createdOn: new FormControl(model?.createdOn ?? new Date()),
      lastModifiedBy: new FormControl(model?.lastModifiedBy),
      lastModifiedOn: new FormControl(model?.lastModifiedOn ?? new Date()),
      magacinTipId: new FormControl(model?.magacinTipId, {
        validators: [Validators.required],
      }),
      sifra: new FormControl(model?.sifra, {
        validators: [Validators.maxLength(20)],
      }),
      adresa: this._adresaFS.GetAdresaFormGroup(model?.adresa),
      kontaktOsoba: new FormControl(model?.kontaktOsoba, {
        validators: [Validators.maxLength(50)],
      }),
      kontaktTelefon: new FormControl(model?.kontaktTelefon, {
        validators: [Validators.maxLength(20)],
      }),
      email: new FormControl(model?.email),
    });
  }

  public GetMagacinFilterFormGroup(
    model?: IGetMagacinTableRequest
  ): FormGroupTypeSafe<IGetMagacinTableRequest> {
    return this._fb.group<IGetMagacinTableRequest>({
      naziv: new FormControl(model?.naziv),
      sifra: new FormControl(model?.sifra),
      magacinTipId: new FormControl(model?.magacinTipId),
    });
  }

  private getMagacinTipFormGroup(
    model?: IMagacinTipDto
  ): FormGroupTypeSafe<IMagacinTipDto> {
    return this._fb.group<IMagacinTipDto>({
      id: new FormControl(model?.id, {
        validators: [Validators.required],
      }),
      naziv: new FormControl(model?.naziv),
      sifra: new FormControl(model?.sifra),
    });
  }

  public GetMagaciniFormArray(magacini?: IMagacinDto[]): FormArray {
    if (!magacini || magacini.length === 0) {
      return this._fb.array([]);
    }

    const result: FormArray = this._fb.array([]);

    magacini.forEach((x) => {
      result.push(this.GetMagacinFormGroup(x));
    });

    return result;
  }
}
