import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CreateKontaktFromEmailCommand,
  ICreateKontaktFromEmailCommand,
  KontaktiClient,
  MailReceiverDto,
  RacuniClient,
  TipRacuna,
} from '@kodit/core/data-api';
import { MailFormService, FormHelper } from '@kodit/core/form-definitions';
import { AlertService, AuthService, ButtonService } from '@kodit/core/services';
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  FileUploadService,
} from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import { KontaktiService } from '@kodit/core/moduli/kontakt';

@Component({
  selector: 'kodit-posalji-izjavu-za-odbitak-pdv-form',
  templateUrl: './posalji-izjavu-za-odbitak-pdv-form.component.html',
  styleUrls: ['./posalji-izjavu-za-odbitak-pdv-form.component.scss'],
})
export class PosaljiIzjavuZaOdbitakPdvFormComponent
  implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  form: FormGroup;
  tipRacuna: TipRacuna;
  strankaId: number;

  constructor(
    private _fb: FormBuilder,
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _formHelper: FormHelper,
    private _uploadService: FileUploadService,
    private _dialogConfig: DynamicDialogConfig,
    private _mailFS: MailFormService,
    private _buttonService: ButtonService,
    private _authService: AuthService,
    private _kontaktService: KontaktiService,
    private _client: RacuniClient,
    private _kontaktiClient: KontaktiClient
  ) {
    this.tipRacuna = this._dialogConfig.data.racunTip;
  }

  ngOnInit(): void {
    // stranka id iz racuna ->  this._dialogConfig.data...
    this.strankaId = this._dialogConfig.data.strankaId;

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.onSend();
      })
    );

    this.form = this._fb.group({
      strankaId: new FormControl(this._dialogConfig.data.strankaId),
      racunId: new FormControl(this._dialogConfig.data.racunId, {
        validators: Validators.required,
      }),
      to: this._mailFS.GetMailReceiversFormArray(
        this._dialogConfig.data.sendTo
      ),
      cc: this._mailFS.GetMailReceiversFormArray(
        this._dialogConfig.data.sendCc,
        false
      ),
    });
  }

  onSend() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._buttonService.disableButton();

    this._subs.add(
      this._client
        .sendIzjavaZaOdbitakPdv(
          this.form.value.racunId,
          this.strankaId,
          this.form.value.to.map((x: MailReceiverDto) => x.email),
          this.form.value.cc.map((x: MailReceiverDto) => x.email)
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.data);
          this._creteKontaktiFromNewEmails();
        })
    );
  }

  private _creteKontaktiFromNewEmails() {
    const req: ICreateKontaktFromEmailCommand = {
      strankaId: this.strankaId,
      kontakti: [
        ...this.form.value.to.filter((x) => x.isNew),
        ...this.form.value.cc.filter((x) => x.isNew),
      ],
    };

    if (req.kontakti.length > 0) {
      this._subs.add(
        this._kontaktiClient
          .createKontaktFromEmail(req as CreateKontaktFromEmailCommand)
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.messages[0]);
            this._kontaktService.loadKontakti.next(true);
            this._dialogRef.close(true);
          })
      );
    } else {
      this._dialogRef.close(true);
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
