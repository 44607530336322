import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  InformacijaIsporukeDto,
  ListItemDtoOfLong,
  StrankaClient,
  TipPristupa,
} from '@kodit/core/data-api';
import { AlertService, AuthService } from '@kodit/core/services';
import {
  ActionType,
  DynamicDialogConfig,
  DynamicDialogService,
  ListConfig,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  ActionConfigResponse,
  ListAction,
} from 'libs/core/shared-ui/src/lib/list/list.config';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { InformacijaIsporukeFormComponent } from '../informacija-isporuke-form/informacija-isporuke-form.component';

@Component({
  selector: 'kodit-informacija-isporuke-list',
  templateUrl: './informacija-isporuke-list.component.html',
  styleUrls: ['./informacija-isporuke-list.component.scss'],
})
export class InformacijaIsporukeListComponent implements OnInit {
  listConfig: ListConfig = new ListConfig();
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  informacijaIsporukeList: ListItemDtoOfLong[] = [];

  /** Subs */
  private _informacijeSub: Subscription = new Subscription();
  private _dialogCloseSub: Subscription = new Subscription();
  private _deleteSub: Subscription = new Subscription();

  @Input() strankaId: number;

  constructor(
    private _route: ActivatedRoute,
    private _dialogService: DynamicDialogService,
    private _authService: AuthService,
    private _client: StrankaClient,
    private _alertService: AlertService,
    private _confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    // get router data
    this._route.params.subscribe((params) => {
      if (params['strankaId']) {
        this.strankaId = Number.parseInt(params['strankaId']);
      }
    });
    this.setListConfig();
    this._getInformacijeOIsporuci();
  }

  private _getInformacijeOIsporuci() {
    this._informacijeSub.add(
      this._client
        .getInformacijaIsporukeForList(this.strankaId)
        .subscribe((res: any) => {
          this.informacijaIsporukeList = res.data;
        })
    );
  }

  async setListConfig(): Promise<void> {
    this.listConfig = {
      title: 'Informacije o isporuci',
      subtitle: 'Sve informacije o adresama isporuke',
      paginator: true,
      toggleable: true,
      rows: '5',
      collapsed: true,
      deleteMessage:
        'Da li ste sigurni da želite da obrišete ovu informaciju isporuke?',
      actions: this._getActions(),
      createCallbackFunction: () => this.onCreate(),
    };
  }

  private _getActions(): ListAction[] {
    return [
      new ListAction({
        type: ActionType.EDIT,
        config: this.getEditListConfig(),
        callback: (index: number) => {
          this.onEdit(index);
        },
      }),
      new ListAction({
        type: ActionType.DELETE,
        config: this.getDeleteListConfig(),
        callback: (index: number) => {
          this.onDelete(index);
        },
      }),
    ];
  }

  onCreate(): void {
    this.dialogConfig.data = { id: null, strankaId: this.strankaId };
    this.dialogConfig.header = 'Unos nove informacije o isporuci';
    this.openDialog(this.dialogConfig);
  }

  onEdit(itemIndex: number): void {
    this.dialogConfig.data = {
      id: this.informacijaIsporukeList[itemIndex].id,
      strankaId: this.strankaId,
    };
    this.dialogConfig.header = 'Izmena informacije o isporuci';
    this.openDialog(this.dialogConfig);
  }

  onDelete(itemIndex: number) {
    const informacijaIsporuke = this.informacijaIsporukeList[itemIndex];
    this._deleteSub.add(
      this._client.deleteInformacijaIsporuke(informacijaIsporuke.id).subscribe(
        () => {
          this._alertService.addSuccessMsg(
            `${this.informacijaIsporukeList[itemIndex].value} uspešno obrisan.`
          );
          this.informacijaIsporukeList.splice(itemIndex, 1);
          this._getInformacijeOIsporuci();
        },
        (error) => {
          this._alertService.addFailedMsg(error);
        }
      )
    );
  }

  private getEditListConfig(): ActionConfigResponse {
    if (this._authService.hasClaim(TipPristupa[TipPristupa.STRANKA_CRUD])) {
      return { isAvailable: true };
    }

    return {
      isAvailable: false,
    };
  }

  private getDeleteListConfig(): ActionConfigResponse {
    if (this._authService.hasClaim(TipPristupa[TipPristupa.STRANKA_CRUD])) {
      return { isAvailable: true };
    }

    return {
      isAvailable: false,
    };
  }

  openDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(
      InformacijaIsporukeFormComponent,
      config
    );
    this._dialogCloseSub = ref.onClose.subscribe(
      (dto: InformacijaIsporukeDto) => {
        if (dto) {
          const idx = this.informacijaIsporukeList.findIndex(
            (x) => x.id === dto.id
          );
          if (idx > -1) {
            //update
            this.informacijaIsporukeList[idx] = dto;
            this._getInformacijeOIsporuci();
          } else {
            // create
            this.informacijaIsporukeList.push(dto);
            this._getInformacijeOIsporuci();
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this._informacijeSub.unsubscribe();
    this._dialogCloseSub.unsubscribe();
    this._deleteSub.unsubscribe();
  }
}
