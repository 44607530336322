import { Injectable } from '@angular/core';
import { RacunSaldoDto } from '@kodit/core/data-api';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SummaryService {

  constructor() {}
  
  /**
   * RacunSaldo
   */
  racunSaldo$ = new BehaviorSubject<RacunSaldoDto>(
    null
  );
  get getRacunSaldo() {
    return this.racunSaldo$.asObservable();
  }
  set setRacunSaldo(data: RacunSaldoDto) {
    this.racunSaldo$.next(data);
  }
}


export class SummaryDto{
  label: string;
  value: number;
  isDivider: boolean;
  styleClass: string;

  /**
   *
   */
  constructor(label: string, val: number) {
    this.label = label;
    this.value = val;
    
  }
}