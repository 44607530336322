<kodit-loader
  [content]="content"
  [showLoader]="showLoader"
  loadingText="Provera korisnika"
></kodit-loader>

<kodit-blockable-div #content>
  <div class="login-wrapper">
    <div class="logo-wrapper">
      <div class="logo"></div>
      <div class="logo-content">
        <div class="title">Dobrodošli u eKompaniju!</div>
        <div class="logo-subtitle p-mt-6">
          <div class="p-text-left">
            Dobili ste pozivnicu za pristup kompaniji.
          </div>
          <div class="p-text-left">
            Molimo Vas da unesete potrebne podatke kako bismo kreirali Vaš
            nalog.
          </div>
        </div>
      </div>
    </div>

    <div class="form-wrapper">
      <div class="logo-mobile"></div>
      <div class="form-content p-d-flex p-flex-column p-ai-center p-jc-center">
        <h3>Registrovanje naloga</h3>
        <p-messages
          *ngIf="showError"
          [(value)]="errorMessage"
          [enableService]="false"
          [escape]="false"
        ></p-messages>
        <form
          [formGroup]="form"
          (ngSubmit)="handleRegisterUser()"
          class="p-fluid p-mt-5"
        >
          <div class="p-field p-text-left">
            <label for="email" kodit-form-label>Korisničko ime / E-Mail</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="fa fa-user"></i></span>
              <input
                pInputText
                id="email"
                type="email"
                [formControl]="form.controls.email"
                [attr.disabled]="true"
              />
            </div>
          </div>

          <div class="p-field p-text-left">
            <label for="password" kodit-form-label>Lozinka</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="fa fa-lock"></i></span>
              <input
                pInputText
                id="password"
                type="password"
                [formControl]="form.controls.password"
                autocomplete="off"
              />
            </div>
            <kodit-error-info-max-length
              [formCtrl]="form.controls.password"
            ></kodit-error-info-max-length>
            <kodit-error-info-min-length
              [formCtrl]="form.controls.password"
            ></kodit-error-info-min-length>
            <kodit-error-info-required
              [formCtrl]="form.controls.password"
            ></kodit-error-info-required>
          </div>

          <div class="p-field p-text-left">
            <label for="passwordCheck" kodit-form-label>Ponovite lozinku</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="fa fa-lock"></i></span>
              <input
                pInputText
                id="passwordCheck"
                type="password"
                [formControl]="form.controls.passwordCheck"
                autocomplete="off"
              />
            </div>
            <kodit-error-info-max-length
              [formCtrl]="form.controls.passwordCheck"
            ></kodit-error-info-max-length>
            <kodit-error-info-min-length
              [formCtrl]="form.controls.passwordCheck"
            ></kodit-error-info-min-length>
            <kodit-error-info-required
              [formCtrl]="form.controls.passwordCheck"
            ></kodit-error-info-required>
            <small
              *ngIf="
                form.errors?.['passwordMatchValidator'] &&
                (form.dirty || form.touched)
              "
              class="p-invalid"
            >
              Lozinke se ne podudaraju
            </small>
          </div>

          <kodit-field fieldLabel="Ime" fieldLabelFor="firstName">
            <kodit-text-input
              formCtrlId="firstName"
              [formCtrl]="form.controls.firstName"
            ></kodit-text-input>
          </kodit-field>

          <kodit-field fieldLabel="Prezime" fieldLabelFor="lastName">
            <kodit-text-input
              formCtrlId="lastName"
              [formCtrl]="form.controls.lastName"
            ></kodit-text-input>
          </kodit-field>

          <kodit-field fieldLabel="Mobilni telefon" fieldLabelFor="mobile">
            <kodit-mobilni-telefon-input
              formCtrlId="mobile"
              [mobilniFormGroup]="form.controls.mobilniDto"
            ></kodit-mobilni-telefon-input>
          </kodit-field>

          <button
            pButton
            label="Registruj se"
            type="submit"
            class="login-btn p-mt-4"
          ></button>
        </form>
        <!-- <div class="form-helper p-d-flex p-flex-column p-jc-center p-ai-center">
          <div>Imate problem sa prijavom?</div>
          <div>
            <a (click)="handlePrijavaProblema()" style="cursor: pointer"
              >Kliknite ovde</a
            >
            i dozvolite da Vam pomognemo.
          </div>
        </div> -->
      </div>
    </div>
  </div>
</kodit-blockable-div>
