import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  CenovnikDto,
  ExchangeRateDto,
  KontaktDto,
  TipEntiteta,
  TipPristupa,
  TipRacuna,
} from '@kodit/core/data-api';
import * as printJS from 'print-js';
import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';

export enum Mode {
  NEW = 1,
  EDIT = 2,
}

export enum BrojDokumentaEncoder {
  KOSA_CRTA = 'kosa-crta',
  RAZMAK = 'razmak',
}

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  cenovnikModel = new Subject<CenovnikDto>();
  kontaktModel = new Subject<KontaktDto>();

  /** events */
  onSave = new Subject<void>();

  constructor(
      private _loaderService: LoaderService) {}

  /**
   * Postavljanje modela cenovnika
   */
  cenovnikModel$ = new BehaviorSubject<CenovnikDto>(null);

  get getCenovnikModel() {
    return this.cenovnikModel$.asObservable();
  }

  set setCenovnikModel(data: CenovnikDto) {
    this.cenovnikModel$.next(data);
  }

  /**
   * Kurs evra za danasnji datum
   */
  kursEvra$ = new BehaviorSubject<ExchangeRateDto | null>(null);

  get getKursEvra() {
    return this.kursEvra$.asObservable();
  }

  set setKursEvra(data: ExchangeRateDto) {
    this.kursEvra$.next(data);
  }

  /**
   * Rating provera
   */
  // rating$ = new BehaviorSubject<any | null>(null); //// Izmeniti da ne bude ANY?

  // get getShouldDisplayRating() {
  //   return this.rating$.asObservable();
  // }

  // set setShouldDisplayRating(data: any) {
  //   this.rating$.next(data);
  // }

  racunNames: { [key in TipRacuna]?: string } = {
    [TipRacuna.IZLAZNA_FAKTURA]: 'Izlazna faktura',
    [TipRacuna.ULAZNA_FAKTURA]: 'Ulazna faktura',
    [TipRacuna.PREDRACUN]: 'Predračun',
    [TipRacuna.OTPREMNICA]: 'Otpremnica',
    [TipRacuna.DOKUMENT_O_POVECANJU]: 'Dokument o povećanju',
    [TipRacuna.DOKUMENT_O_SMANJENJU]: 'Dokument o smanjenju',
    [TipRacuna.AVANSNI_RACUN]: 'Avansni račun',
  };

  racunCreateTipPristupa: { [key in TipRacuna]?: TipPristupa } = {
    [TipRacuna.IZLAZNA_FAKTURA]: TipPristupa.IZLAZNA_FAKTURA_CRUD,
    [TipRacuna.ULAZNA_FAKTURA]: TipPristupa.ULAZNA_FAKTURA_CRUD,
    [TipRacuna.PREDRACUN]: TipPristupa.PREDRACUN_CRUD,
    [TipRacuna.OTPREMNICA]: TipPristupa.OTPREMNICA_CRUD,
    [TipRacuna.DOKUMENT_O_POVECANJU]: TipPristupa.IZMENA_OSNOVICE_CRUD,
    [TipRacuna.DOKUMENT_O_SMANJENJU]: TipPristupa.IZMENA_OSNOVICE_CRUD,
    [TipRacuna.AVANSNI_RACUN]: TipPristupa.AVANSNI_RACUN_CRUD,
  };

  racunUpdateTipPristupa: { [key in TipRacuna]?: TipPristupa } = {
    [TipRacuna.IZLAZNA_FAKTURA]: TipPristupa.IZLAZNA_FAKTURA_CRUD,
    [TipRacuna.ULAZNA_FAKTURA]: TipPristupa.ULAZNA_FAKTURA_CRUD,
    [TipRacuna.PREDRACUN]: TipPristupa.PREDRACUN_CRUD,
    [TipRacuna.OTPREMNICA]: TipPristupa.OTPREMNICA_CRUD,
    [TipRacuna.DOKUMENT_O_POVECANJU]: TipPristupa.IZMENA_OSNOVICE_CRUD,
    [TipRacuna.DOKUMENT_O_SMANJENJU]: TipPristupa.IZMENA_OSNOVICE_CRUD,
    [TipRacuna.AVANSNI_RACUN]: TipPristupa.AVANSNI_RACUN_CRUD,
  };

  //Skida fajl na osnovu urla koji se dobio iz bloba. Skinuti fajl ce biti onog tipa koji je definisan kao content type u blobu
  downloadByUrl(fileUrl: string, fileName: string = 'fajl') {
    let link = document.createElement('a');
    link.href = fileUrl;
    link.download = `${fileName}`;
    link.dispatchEvent(new MouseEvent('click'));
  }

  downloadBlobAsPDF(file: Blob, fileName: string = 'fajl') {
    const fileUrl = URL.createObjectURL(file);
    let link = document.createElement('a');
    link.href = fileUrl;
    link.download = `${fileName}.pdf`;
    link.dispatchEvent(new MouseEvent('click'));
  }

  downloadUrlAsPDF(fileUrl: string, fileName: string = 'fajl') {
    let link = document.createElement('a');
    link.href = fileUrl;
    link.download = `${fileName}.pdf`;
    link.dispatchEvent(new MouseEvent('click'));
  }

  downloadUrlAsXml(fileUrl: string, fileName: string = 'ublFajl') {
    let link = document.createElement('a');
    link.href = fileUrl;
    link.download = `${fileName}.xml`;
    link.dispatchEvent(new MouseEvent('click'));
  }

  downloadFileAsTXT(content: string, fileName: string, contentType: string) {
    const source = `data:${contentType};base64,${content}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}.txt`;
    link.click();
  }

  downloadFileAsExcel(content: string, fileName: string, contentType: string) {
    const byteCharacters = atob(content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.xlsx`;
    link.click();
  }

  displayPdf(base64Pdf: string) {
    const base64ByteArray = new Uint8Array(
      atob(base64Pdf)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    const blob = new Blob([base64ByteArray], {
      type: 'application/pdf',
    });
    const blobUrl = URL.createObjectURL(blob);
    printJS(blobUrl);
  }

  displayLoadingScreen(
    value: boolean,
    loadingText = 'Momenat',
    errorText = null
  ) {
    this._loaderService.setShowLoader = value;
    this._loaderService.setLoadingText = loadingText;
    this._loaderService.setErrorText = errorText;
  }

  getContentType(extension: string): string | undefined {
    if (extension === 'doc') {
      return 'application/msword';
    }
    else if (extension === 'docx') {
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }
    else if (extension === 'xls') {
      return 'application/vnd.ms-excel';
    }
     else if (extension === 'xlsx') {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    else if (extension === 'csv') {
      return 'text/csv';
    }
    else if (extension === "xml") {
      return 'text/xml';
    }
    else if (extension === "jpeg" || extension === "jpg" || extension === "png") {
      return "image/jpeg";
    }
     else if (extension === "pdf") {
      return 'application/pdf';
    }
    else {
      return 'application/json';
    }
  }
}

export const TIP_RACUNA_TO_TIP_ENTITETA: {
  [key in TipRacuna]?: TipEntiteta;
} = {
  [TipRacuna.IZLAZNA_FAKTURA]: TipEntiteta.IZLAZNA_FAKTURA,
  [TipRacuna.ULAZNA_FAKTURA]: TipEntiteta.ULAZNA_FAKTURA,
  [TipRacuna.PREDRACUN]: TipEntiteta.PREDRACUN,
  [TipRacuna.OTPREMNICA]: TipEntiteta.OTPREMNICA,
  [TipRacuna.DOKUMENT_O_POVECANJU]: TipEntiteta.DOKUMENT_O_POVECANJU,
  [TipRacuna.DOKUMENT_O_SMANJENJU]: TipEntiteta.DOKUMENT_O_SMANJENJU,
  [TipRacuna.AVANSNI_RACUN]: TipEntiteta.AVANSNI_RACUN,
};

export const b64toBlob = (
  b64Data: string,
  contentType = 'application/pdf',
  sliceSize = 512
): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
