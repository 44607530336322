import { FormArray, FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  IStavkaRacunaDto,
  IdentifikatorKlasifikacijeStavke,
  OznakaPdvKategorije,
  TipArtikla,
  TipPredmeta,
} from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import {
  PredmetStavkeFormGroupRequest,
  PredmetStavkeFormService,
} from '../predmet-stavke/predmet-stavke-form.service';
import { JedinicaMereFormService } from '../jedinica-mere/jedinica-mere-form.service';
import { OznakaFormService } from '../..';
import { StopaPdv } from '@kodit/core/shared';

interface GetStavkaFormGroupRequest {
  stavka?: IStavkaRacunaDto;
  redniBroj?: number;
  isJedinicaMereRequired?: boolean;
  isStrankaUSistemuPDVa?: boolean;
  isUlaznaFaktura?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class StavkaFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _predmetStavkeFS: PredmetStavkeFormService,
    private _jedinicaMereFS: JedinicaMereFormService,
    private _oznakeFS: OznakaFormService
  ) {}

  /**
   * Konvertuje model stavki dokumenta u form array
   *
   * Ukoliko se posalje prazan niz, kreira se niz sa 1 default stavkom
   * @param stavke Stavke dokumenata za convert (model -> formArray)
   * @returns Reaktivan form array od stavki dokumenta
   */
  public GetFormArray(
    stavke: IStavkaRacunaDto[],
    isJedinicaMereRequired: boolean = true,
    isStrankaUSistemuPDVa: boolean = true,
    isFakturaUlazna: boolean = false
  ): FormArray {
    if (!stavke || stavke.length === 0) {
      return this._fb.array([
        this.GetFormGroup({
          isJedinicaMereRequired: isJedinicaMereRequired,
          isStrankaUSistemuPDVa: isStrankaUSistemuPDVa,
          isUlaznaFaktura: isFakturaUlazna,
        }),
      ]);
    }

    // moramo da sortiramo po rednom broju jer sa servera stigne izmesano
    stavke.sort((a, b) => (a.redniBroj > b.redniBroj ? 1 : -1));

    let result: FormArray = this._fb.array([]);
    stavke.forEach((s) => {
      result.push(
        this.GetFormGroup({
          stavka: s,
          isJedinicaMereRequired: isJedinicaMereRequired,
          isStrankaUSistemuPDVa: isStrankaUSistemuPDVa,
          isUlaznaFaktura: isFakturaUlazna,
        })
      );
    });
    return result;
  }

  /**
   * Konvertuje model stavke racuna u form grupu
   *
   * Ukoliko se izostavi parametar stavka,
   * kreira se grupu sa default vrednostima
   *
   * @returns Reaktivna form grupa od prosledjene stavke
   */
  public GetFormGroup(
    request?: GetStavkaFormGroupRequest
  ): FormGroupTypeSafe<IStavkaRacunaDto> {
    const formGroup = this._fb.group<IStavkaRacunaDto>({
      id: new FormControl(request?.stavka?.id),
      naziv: new FormControl(request?.stavka?.naziv, {
        validators: [Validators.required, Validators.maxLength(2000)],
      }),
      redniBroj: new FormControl(
        request?.stavka?.redniBroj ?? request?.redniBroj ?? 1
      ),
      predmetStavkeDto: this._predmetStavkeFS.GetPredmetStavkeFormGroup(
        new PredmetStavkeFormGroupRequest({
          model: request?.stavka?.predmetStavkeDto,
          isCenaRequired: false,
          isJedinicaMereRequired: false,
        })
      ),
      jedinicaMere: this._jedinicaMereFS.GetJedinicuMereFormGroup(
        request?.stavka?.jedinicaMere,
        request.isJedinicaMereRequired
      ),
      kolicina: new FormControl(request?.stavka?.kolicina ?? 1, {
        validators: [Validators.required],
      }),
      nabavnaCenaBezPdv: new FormControl(
        request?.stavka?.nabavnaCenaBezPdv ?? 0,
        {
          validators: [Validators.required, Validators.min(0)],
        }
      ),
      prodajnaCenaBezPdv: new FormControl(
        request?.stavka?.prodajnaCenaBezPdv ?? 0,
        {
          validators: [Validators.min(0), Validators.required],
        }
      ),
      rabat: new FormControl(request?.stavka?.rabat ?? 0, {
        validators: [Validators.min(0)],
      }),
      poreskaOsnovica: new FormControl(request?.stavka?.poreskaOsnovica ?? 0, {
        validators: [Validators.required],
      }),
      stopaPDV: new FormControl(request?.stavka?.stopaPDV, {
        validators: [Validators.min(0), Validators.required],
      }),
      pdv: new FormControl(request?.stavka?.pdv ?? 0, {
        validators: [Validators.required],
      }),
      iznos: new FormControl(request?.stavka?.iznos ?? 0, {
        validators: [Validators.required],
      }),
      opis: new FormControl(request?.stavka?.opis),
      magacinId: new FormControl(request?.stavka?.magacinId, {
        validators: [
          request?.stavka?.predmetStavkeDto?.tipPredmeta == TipPredmeta.ROBA
            ? Validators.required
            : Validators.nullValidator,
        ],
      }),
      oznakeDto: this._oznakeFS.GetOznakeFormArray(request?.stavka?.oznakeDto),
      pdvKategorija: new FormControl(
        request.stavka?.pdvKategorija ??
          (request.isStrankaUSistemuPDVa ? OznakaPdvKategorije.S : null),
        {
          validators:
            request.isUlaznaFaktura || request.isStrankaUSistemuPDVa
              ? [Validators.nullValidator]
              : [Validators.required],
        }
      ),
      razlogPdvIzuzecaId: new FormControl(request?.stavka?.razlogPdvIzuzecaId, {
        validators:
          (request.isUlaznaFaktura || request.isStrankaUSistemuPDVa) &&
          (request.stavka?.stopaPDV === StopaPdv.OSNOVNA ||
            request.stavka?.stopaPDV === StopaPdv.POSEBNA ||
            request.stavka?.stopaPDV === StopaPdv.POSEBNA_POLJOPRIVREDNO_GAZDINSTVO ||
            request.stavka?.predmetStavkeDto?.tipArtikla ===
              TipArtikla.SIROVINE_MATERIJAL)
            ? [Validators.nullValidator]
            : [Validators.required],
      }),
      identifikator: new FormControl(request?.stavka?.identifikator ?? IdentifikatorKlasifikacijeStavke.EMPTY, {
        validators: [Validators.required]
      }),
      materijali: new FormArray([]),
      sifraKupca: new FormControl(request?.stavka?.sifraKupca),
      sifraDobavljaca: new FormControl(request?.stavka?.sifraDobavljaca),
    });

    if (request?.stavka?.materijali?.length > 0) {
      formGroup.controls.materijali = this.GetFormArray(
        request?.stavka?.materijali
      );
    }

    return formGroup;
  }
}
