import { Injectable } from '@angular/core';
import { INotifikacijaKorisnikaDto } from '@kodit/core/data-api';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  //#region  Racun

  racunNotificationCount$ = new BehaviorSubject<number | null>(null);
  incrementRacunNotificationCount() {
    this.racunNotificationCount$.next(this.racunNotificationCount$.value + 1);
  }
  get getRacunStatusChangeNotification() {
    return this.racunNotificationCount$.asObservable();
  }

  topbarNotifications$ = new BehaviorSubject<INotifikacijaKorisnikaDto[]>([]);
  addTopbarNotifications(notifications: INotifikacijaKorisnikaDto[]) {
    this.topbarNotifications$.next([
      ...this.topbarNotifications$.getValue(),
      ...notifications,
    ]);
  }
  removeTopbarNotification(id: string) {
    const idx = this.topbarNotifications$
      .getValue()
      .findIndex((x) => x.id === id);
    if (idx === -1) {
      return;
    }
    const temp = this.topbarNotifications$.getValue();
    temp.splice(idx, 1);
    this.topbarNotifications$.next(temp);
  }
  get getTopbarNotifications() {
    return this.topbarNotifications$.asObservable();
  }
  //#endregion  Racun
}
