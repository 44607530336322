import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'kodit-pib-input-mask',
  templateUrl: './pib-input-mask.component.html',
  styleUrls: ['./pib-input-mask.component.scss'],
})
export class PibInputMaskComponent implements OnInit {
  @Input()
  disabled = false;
  @Input()
  showLongLabel = false;
  @Input()
  showShortLabel = false;
  @Input()
  control: FormControl | AbstractControl;

  constructor() {}

  ngOnInit(): void {}
}
