import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsplatePageComponent } from './izvod/isplate-page/isplate-page.component';
import { IzvodTableComponent } from './izvod/izvod-table/izvod-table.component';
import { ObradaIzvodaPageComponent } from './izvod/obrada-izvoda-page/obrada-izvoda-page.component';
import { ObradaIzvodaResolver } from './izvod/resolvers/obrada-izvoda-resolver.service';
import { UplatePageComponent } from './izvod/uplate-page/uplate-page.component';

// const routes: Routes = [
//   {
//     path: '',
//     children: [
//       {
//         path: 'izvodi',
//         pathMatch: 'full',
//         component: IzvodTableComponent,
//       },
//       {
//         path: 'uplate',
//         pathMatch: 'full',
//         component: UplatePageComponent,
//       },
//       {
//         path: 'isplate',
//         pathMatch: 'full',
//         component: IsplatePageComponent,
//       },
//       {
//         path: 'izvodi/obrada-izvoda/:id',
//         pathMatch: 'full',
//         component: ObradaIzvodaPageComponent,
//         resolve: { izvod: ObradaIzvodaResolver },
//       },
//     ],
//   },
//   { path: '**', redirectTo: 'izvodi' },
// ];

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'izvodi',
        data: {
          breadcrumb: 'Izvodi',
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: IzvodTableComponent,
          },
          {
            path: 'obrada-izvoda/:id',
            pathMatch: 'full',
            component: ObradaIzvodaPageComponent,
            resolve: { izvod: ObradaIzvodaResolver },
            data: {
              breadcrumb: {
                alias: 'ObradaIzvoda',
              },
            },
          },
        ],
      },
      {
        path: 'uplate',
        pathMatch: 'full',
        component: UplatePageComponent,
        data: {
          breadcrumb: 'Uplate',
          jeUplata: true
        },
      },
      {
        path: 'isplate',
        pathMatch: 'full',
        component: IsplatePageComponent,
        data: {
          breadcrumb: 'Isplate',
          jeUplata: false
        },
      },
    ],
  },
  { path: '**', redirectTo: 'izvodi' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class BankaRoutingModule {}
