import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IOpstaPravilaKonfiguracijaDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { PrikazRabataFormService } from '../../common/prikaz-rabata-form.service';
import { PrikazOtpremniceFormService } from '../../common/prikaz-otpremnice-form.service';
import { PrikazRadnogNalogaFormService } from '../../common/prikaz-radnog-naloga-form.service';

@Injectable({
  providedIn: 'root',
})
export class OpstaPravilaFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _prikazRabataFS: PrikazRabataFormService,
    private _prikazOtpremniceFS: PrikazOtpremniceFormService,
    private _prikazRadnogNalogaFS: PrikazRadnogNalogaFormService
  ) {}

  public GetOpstaPravilaFormGroup(
    model?: IOpstaPravilaKonfiguracijaDto
  ): FormGroupTypeSafe<IOpstaPravilaKonfiguracijaDto> {
    return this._fb.group<IOpstaPravilaKonfiguracijaDto>({
      mestoIzdavanjaPrometa: new FormControl(model?.mestoIzdavanjaPrometa, {
        validators: [Validators.maxLength(256)],
      }),
      nadlezanSudZaSpor: new FormControl(model?.nadlezanSudZaSpor, {
        validators: [Validators.maxLength(256)],
      }),
      periodZaPrikazRacuna: new FormControl(model?.periodZaPrikazRacuna),
      periodZaPrikazRacunaStr: new FormControl(model?.periodZaPrikazRacunaStr),
      prikaziPonudu : new FormControl(model?.prikaziPonudu),
      prikaziNadlezniSudZaSpor: new FormControl(
        model?.prikaziNadlezniSudZaSpor
      ),
      prikaziSekcijuZaRacunanjeTezine: new FormControl(
        model?.prikaziSekcijuZaRacunanjeTezine
      ),
      prikaziSekcijuZaInformacijeOIsporuci: new FormControl(
        model?.prikaziSekcijuZaInformacijeOIsporuci
      ),
      modelPlacanja: new FormControl(model?.modelPlacanja, {
        validators: [Validators.maxLength(3)],
      }),
      sifraPlacanja: new FormControl(model?.sifraPlacanja, {
        validators: [Validators.maxLength(3)],
      }),
      instrukcijaPlacanja: new FormControl(model?.instrukcijaPlacanja, {
        validators: [Validators.maxLength(4)],
      }),
      prikaziOznake: new FormControl(model?.prikaziOznake),
      kreirajOtpremnicu: new FormControl(model?.kreirajOtpremnicu),
      prikazOtpremniceDto: this._prikazOtpremniceFS.GetPrikazOtpremniceFormGroup(
        model?.prikazOtpremniceDto
      ),
      prikazRadnogNalogaDto: this._prikazRadnogNalogaFS.GetPrikazRadnogNalogaFormGroup(
        model?.prikazRadnogNalogaDto
      ),
      prikazRabataDto: this._prikazRabataFS.GetPrikazRabataFormGroup(
        model?.prikazRabataDto
      ),
      defaultDatumPrometa: new FormControl(model?.defaultDatumPrometa),
      defaultDatumPrometaStr: new FormControl(model?.defaultDatumPrometaStr)
    });
  }
}
