import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  KontaktiClient,
  ListItemDtoOfLong,
  ResultOfKontaktDto,
} from '@kodit/core/data-api';
import {
  DynamicDialogConfig,
  DynamicDialogService,
  ListConfig,
} from '@kodit/core/shared-ui';

import { Subscription } from 'rxjs';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { ActivatedRoute } from '@angular/router';
import { KontaktFormComponent } from '../kontakt-form/kontakt-form.component';
import {
  ActionConfigResponse,
  ActionType,
  ListAction,
} from 'libs/core/shared-ui/src/lib/list/list.config';
import { AlertService } from '@kodit/core/services';

@Component({
  selector: 'kodit-kontakt-list',
  templateUrl: './kontakt-list.component.html',
  styleUrls: ['./kontakt-list.component.scss'],
})
export class KontaktListComponent implements OnInit, OnDestroy {
  /** Subscriptions */
  private _kontaktiSub: Subscription = new Subscription();
  private _dialogCloseSub: Subscription = new Subscription();
  private _deleteSub: Subscription = new Subscription();

  /** Configurations */
  listConfig: ListConfig = new ListConfig();
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  @Input() strankaId: number;
  kontaktList: ListItemDtoOfLong[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _dialogService: DynamicDialogService,
    private _alertService: AlertService,
    //private _authService: AuthService,
    private _client: KontaktiClient //private _kontaktService: KontaktiService
  ) {}

  ngOnInit(): void {
    // get router data
    this._route.params.subscribe((params) => {
      if (params['strankaId']) {
        this.strankaId = Number.parseInt(params['strankaId']);
      }
    });
    this.setListConfig().then();
    this._getKontaktList();
    // this._kontaktiSub.add(
    //   this._kontaktService.loadKontakti.subscribe((res) => {
    //     if (res) this._getKontaktList();
    //   })
    // );
  }

  async setListConfig(): Promise<void> {
    this.listConfig = {
      title: 'Kontakt',
      subtitle: 'Kontakt osobe',
      paginator: true,
      rows: '5',
      toggleable: true,
      collapsed: true,
      deleteMessage: 'Da li ste sigurni da želite da obrišete ovaj kontakt?',
      actions: this._getActions(),
      createCallbackFunction: () => this.onCreate(),
    };
  }

  private _getKontaktList() {
    this._kontaktiSub.add(
      this._client.getForList(this.strankaId).subscribe((res) => {
        this.kontaktList = res.data;
      })
    );
  }

  private _getActions(): ListAction[] {
    return [
      new ListAction({
        type: ActionType.EDIT,
        config: this.getEditListConfig(),
        callback: (index: number) => {
          this.onEdit(index);
        },
      }),
      new ListAction({
        type: ActionType.DELETE,
        config: this.getDeleteListConfig(),
        callback: (index: number) => {
          this.onDelete(index);
        },
      }),
    ];
  }

  private getEditListConfig(): ActionConfigResponse {
    return { isAvailable: true };
    /*if (this._authService.hasClaim(TipPristupa[TipPristupa.STRANKA_UPDATE])) {
              return { isAvailable: true };
            }

            return {
              isAvailable: false,
            };*/
  }

  private getDeleteListConfig(): ActionConfigResponse {
    return { isAvailable: true };
    /*if (this._authService.hasClaim(TipPristupa[TipPristupa.STRANKA_DELETE])) {
              return { isAvailable: true };
            }

            return {
              isAvailable: false,
            };*/
  }

  onCreate(): void {
    this.dialogConfig.data = { id: null, strankaId: this.strankaId };
    this.dialogConfig.header = 'Unos kontakta';
    this.openDialog(this.dialogConfig);
  }

  onEdit(itemIndex: number): void {
    this.dialogConfig.data = {
      id: this.kontaktList[itemIndex].id,
      strankaId: this.strankaId,
    };
    this.dialogConfig.header = 'Izmena kontakta';
    this.openDialog(this.dialogConfig);
  }

  onDelete(itemIndex: number) {
    const kontakt = this.kontaktList[itemIndex];
    this._deleteSub = this._client.delete(kontakt.id).subscribe(
      () => {
        this._alertService.addSuccessMsg(
          `Kontakt ${this.kontaktList[itemIndex].label} uspešno obrisan.`
        );
        this.kontaktList.splice(itemIndex, 1);
        this._getKontaktList();
      },
      (error) => {
        this._alertService.addFailedMsg(error);
      }
    );
  }

  openDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(KontaktFormComponent, config);

    this._dialogCloseSub = ref.onClose.subscribe((dto: ResultOfKontaktDto) => {
      if (dto) {
        const idx = this.kontaktList.findIndex((x) => x.id === dto.data.id);
        if (idx > -1) {
          // update
          this.kontaktList[idx] = dto.data;
          this._getKontaktList();
        } else {
          // create
          this.kontaktList.push(dto.data);
          this._getKontaktList();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._kontaktiSub.unsubscribe();
    this._dialogCloseSub.unsubscribe();
    this._deleteSub.unsubscribe();
  }
}
