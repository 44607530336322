import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-arhivska-exist-step3',
  templateUrl: './arhivska-exist-step3.component.html',
  styleUrls: ['./arhivska-exist-step3.component.scss']
})
export class ArhivskaExistStep3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
