import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import {
  ActionType,
  DynamicDialogConfig,
  DynamicDialogService,
  OnTableInit,
  TableConfig,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { CreateDelovodnikFormComponent } from '../create-delovodnik-form/create-delovodnik-form.component';
import { DelovodnikFilterComponent } from '../delovodnik-filter/delovodnik-filter.component';
import {
  DelovodniciClient,
  DelovodnikTableDto,
  FilterChipDto,
  GetDelovodnikTableQuery,
  PaginatedResultOfDelovodnikTableDto,
} from '@kodit/core/data-api';
import { ConfigService, LocationService } from '@kodit/core/services';
import { finalize } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectFilterByKey } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.selectors';
import { toCamelCase } from '@kodit/core/shared';
import {
  clearFilter,
  setFilter,
} from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';
import { UpdateDelovodnikFormComponent } from '../update-delovodnik-form/update-delovodnik-form.component';

@Component({
  selector: 'kodit-delovodnik-page',
  templateUrl: './delovodnik-page.component.html',
  styleUrls: ['./delovodnik-page.component.scss'],
})
export class DelovodnikPageComponent implements OnInit, OnDestroy, OnTableInit {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  paginatedData!: PaginatedResultOfDelovodnikTableDto;
  chipItems: FilterChipDto[] = [];
  filters!: GetDelovodnikTableQuery;
  private _skipFirst = 0;
  numberOfRowsDisplayed = 0;
  isFirstLoad = true;
  delovodnikTable: DelovodnikTableDto[] = [];

  /** Configurations */
  tableConfig!: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  constructor(
    private _dialogService: DynamicDialogService,
    private _configService: ConfigService,
    private _client: DelovodniciClient,
    private _storage: Store,
    private _location: LocationService
  ) {}

  ngOnInit(): void {
    this.setTableConfig().then();

    this._subs.add(
      this._storage
        .select(selectFilterByKey('DELOVODNIK'))
        .subscribe((data) => {
          if (data) {
            this.filters = JSON.parse(data);
            this._skipFirst = this.filters.pageNumber ?? 0;
            this.numberOfRowsDisplayed = this.filters.pageSize ?? 0;
          } else {
            this.filters = new GetDelovodnikTableQuery({
              pageNumber: this._skipFirst,
              pageSize: this.numberOfRowsDisplayed,
            });
          }
          this._load();
        })
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Delovodnici',
      isLazy: true,
      lazyCallbackFunction: (event) => {
        if (!this.isFirstLoad) {
          this._skipFirst = event.first!;
          this.numberOfRowsDisplayed = event.rows!;
        }
        this.updateFilterDataAndReload();
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        }
      },
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      tableFilterFields: ['data.naziv'],
      columns: [
        {
          field: 'naziv',
          emptyCellField: 'emptyText',
          header: 'Naziv',
          type: 'link',
          linkCallbackFunction: (rowIndex: number) =>
            this._location.routeToDelovodnikInfo(this._getPaginatedItem(rowIndex).id!),
        },
        {
          field: 'kategorija',
          header: 'Kategorija',
          emptyCellField: 'emptyText',
          type: 'text',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          hasAccessTooltip: 'Unesite delovodnik',
          noAccessTooltip: 'Nemate ovlašćenja za unos delovodnika',
          callback: () => {
            this._setDialogConfig({ id: null }, 'Unos delovodnika', false);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          hasAccessTooltip: 'Izmenite delovodnik',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu pravila',
          // ToDo: bice odradjeno u okviru EK-3070
          // shouldDisplayByCondition: (
          //   rowData: KonfiguracijaBrojaDelovodnogDokumentaTableDto
          // ) => {
          //   return rowData.isEditable;
          // },
          callback: (index: number) => {
            this._setDialogConfig(
              {
                id: this._getPaginatedItem(index).id,
                kategorijaStr: this._getPaginatedItem(index).kategorija,
              },
              'Izmena delovodnika',
              true
            );
          },
        },
      ],
      advancedFilter: {
        component: DelovodnikFilterComponent,
        data: new GetDelovodnikTableQuery(),
      },
    });
  }

  private _getPaginatedIndex(index: number): number {
    return index - this._skipFirst;
  }

  private _getPaginatedItem(index: number): DelovodnikTableDto {
    return this.delovodnikTable[this._getPaginatedIndex(index)];
  }

  private _setDialogConfig(data: any, header: string, isEdit: boolean) {
    this.dialogConfig.data = data;
    this.dialogConfig.header = header;
    isEdit
      ? this._openDialog(this.dialogConfig, UpdateDelovodnikFormComponent)
      : this._openDialog(this.dialogConfig, CreateDelovodnikFormComponent);
  }

  private _openDialog(
    config: DynamicDialogConfig,
    dialogType: Type<
      CreateDelovodnikFormComponent | UpdateDelovodnikFormComponent
    >
  ): void {
    const ref = this._dialogService.open(dialogType, config);

    this._subs.add(
      ref.onClose.subscribe((result) => {
        if (result) {
          this._load();
        }
      })
    );
  }

  private _handleChipRemove(removedChip: FilterChipDto) {
    this.tableConfig.advancedFilter!.data[toCamelCase(removedChip.key!)] =
      removedChip.defaultValue;

    this._updateFilters();
  }

  private _updateFilters() {
    if (this._suFilteriPrazni()) {
      this._storage.dispatch(
        clearFilter({
          key: 'DELOVODNIK',
        })
      );
      return;
    }

    this._storage.dispatch(
      setFilter({
        key: 'DELOVODNIK',
        filter: JSON.stringify(this.filters),
      })
    );
  }
  private _suFilteriPrazni() {
    for (const prop of Object.keys(this.filters) as Array<
      keyof GetDelovodnikTableQuery
    >) {
      if (this.filters[prop]) {
        return false;
      }
    }
    return true;
  }

  private _isBusy = false;
  private _load() {
    if (this._isBusy) {
      return;
    }

    this._configService.setIsBusy = true;
    this._isBusy = true;

    if (!this.filters) {
      this.filters = new GetDelovodnikTableQuery({
        pageNumber: this._skipFirst,
        pageSize: this.numberOfRowsDisplayed,
      });
    }

    this._subs.add(
      this._client
        .getDelovodnikTable(this.filters)
        .pipe(
          finalize(() => {
            this._configService.setIsBusy = false;
            this._isBusy = false;
          })
        )
        .subscribe((res) => {
          this.paginatedData = res;
          this.delovodnikTable = res.data!;
          this.chipItems = res.activeFilters!;
          this.tableConfig.advancedFilter!.data = this.filters;
        })
    );
  }

  private updateFilterDataAndReload() {
    if (
      (this._skipFirst !== this.filters.pageNumber ||
        this.numberOfRowsDisplayed !== this.filters.pageSize) &&
      !this.isFirstLoad
    ) {
      this.filters.pageNumber = this._skipFirst;
      this.filters.pageSize = this.numberOfRowsDisplayed;
      this._updateStorage();
    }
  }

  private _updateStorage() {
    this._storage.dispatch(
      setFilter({
        key: 'DELOVODNIK',
        filter: JSON.stringify(this.filters),
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
