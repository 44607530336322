<div class="p-d-flex p-flex-column gap-25">
  <div class="p-as-end">
    <p-selectButton
      [options]="tableOptions"
      [(ngModel)]="tableSelectOption"
      (onChange)="handleOnItemChange($event)"
    ></p-selectButton>
  </div>
  <div>
    <kodit-notifikacije-table
      [options]="tableOptions"
      [selectbuttonBool]="samoNeprocitane"
    ></kodit-notifikacije-table>
  </div>
</div>
