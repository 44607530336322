import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import {
  StatusRacuna,
  TipRacuna,
  TipStranke,
  UplataIsplataZaRacunDto,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  previousUrl: string;

  constructor(
    private _location: Location,
    private _router: Router,
    private _route: ActivatedRoute,
    private _alertService: AlertService
  ) {
    _router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
        //     console.log('previous url', events[0].urlAfterRedirects);
        //     console.log('current url', events[1].urlAfterRedirects);
      });
  }

  routeBack() {
    if (!this.previousUrl || this.previousUrl.includes('autentikacija')) {
      const urlParts = this._router.url.split('/');
      if (urlParts && urlParts.length > 1) {
        this._router.navigate([`/${[1]}/nepostojeca-ruta`], {
          //relativeTo: this._route,
        });
      } else {
        this._router.navigate(['/dashboard']);
      }
    } else {
      this._location.back();
    }
  }

  //#region Racun routes

  async routeToRacunKartica(
    id: number,
    tip: TipRacuna,
    vrsta: VrstaRacuna,
    openInNewTab: boolean = false
  ) {
    let url: string;
    switch (tip) {
      case TipRacuna.IZLAZNA_FAKTURA:
      case TipRacuna.ULAZNA_FAKTURA:
      case TipRacuna.DOKUMENT_O_SMANJENJU:
      case TipRacuna.DOKUMENT_O_POVECANJU:
      case TipRacuna.AVANSNI_RACUN:
        url = this._router.serializeUrl(
          this._router.createUrlTree([
            '/racuni',
            vrsta === VrstaRacuna.IZLAZNI ? 'izlazni-racuni' : 'ulazni-racuni',
            'kartica',
            id,
          ])
        );
        break;
      case TipRacuna.PREDRACUN:
        url = this._router.serializeUrl(
          this._router.createUrlTree(['/racuni', 'predracuni', 'kartica', id])
        );
        break;
      case TipRacuna.OTPREMNICA:
        url = this._router.serializeUrl(
          this._router.createUrlTree(['/racuni', 'otpremnice', 'kartica', id])
        );
        break;
      case TipRacuna.RADNI_NALOG:
        url = this._router.serializeUrl(
          this._router.createUrlTree(['/racuni', 'radni-nalozi', 'kartica', id])
        );
        break;

      case TipRacuna.PONUDA:
        url = this._router.serializeUrl(
          this._router.createUrlTree(['/racuni', 'ponude', 'kartica', id])
        );
        break;
      default:
        this._alertService.addWarnMsg(
          'routeToRacunKartica(): Tip računa nije podržan za rutiranje'
        );
        return;
    }
    if (openInNewTab) {
      window.open(url, '_blank');
    } else {
      await this._router.navigateByUrl(url);
    }
  }

  async routeToRadniNalogKartica(
    id: number,
    tip: TipRacuna,
    openInNewTab: boolean = false
  ) {
    let url: string;
    switch (tip) {
      case TipRacuna.RADNI_NALOG:
        url = this._router.serializeUrl(
          this._router.createUrlTree(['/racuni', 'radni-nalozi', 'kartica', id])
        );
        break;
      default:
        this._alertService.addWarnMsg(
          'routeToRacunKartica(): Tip računa nije podržan za rutiranje'
        );
        return;
    }
    if (openInNewTab) {
      window.open(url, '_blank');
    } else {
      await this._router.navigateByUrl(url);
    }
  }

  routeToArhivksaTable() {
    this._router.navigate(['/kompanija', 'arhivska-knjiga', 'arhivska-table']);
  }

  routeToRacunTable(
    tip: TipRacuna,
    vrsta: VrstaRacuna,
    activeTab?: 0 | 1 | 2 | 3 | 4 | 5
  ) {
    switch (tip) {
      case TipRacuna.IZLAZNA_FAKTURA:
      case TipRacuna.ULAZNA_FAKTURA:
      case TipRacuna.DOKUMENT_O_SMANJENJU:
      case TipRacuna.DOKUMENT_O_POVECANJU:
      case TipRacuna.AVANSNI_RACUN:
        this._router.navigate(
          [
            '/racuni',
            vrsta === VrstaRacuna.IZLAZNI ? 'izlazni-racuni' : 'ulazni-racuni',
          ],
          {
            queryParams: {
              activeTab: activeTab,
            },
          }
        );
        break;
      case TipRacuna.PREDRACUN:
        this._router.navigate(['/racuni', 'predracuni']);
        break;
      case TipRacuna.OTPREMNICA:
        this._router.navigate(['/racuni', 'otpremnice']);
        break;
      case TipRacuna.RADNI_NALOG:
        this._router.navigate(['/racuni', 'radni-nalozi']);
        break;
      default:
        this._alertService.addWarnMsg(
          'routeToRacunKartica(): Tip računa nije podržan za rutiranje'
        );
    }
  }

  routeToRacunEdit(id: number, tip: TipRacuna, vrsta: VrstaRacuna) {
    switch (tip) {
      case TipRacuna.IZLAZNA_FAKTURA:
      case TipRacuna.ULAZNA_FAKTURA:
      case TipRacuna.DOKUMENT_O_SMANJENJU:
      case TipRacuna.DOKUMENT_O_POVECANJU:
      case TipRacuna.AVANSNI_RACUN:
        this._router.navigate([
          '/racuni',
          vrsta === VrstaRacuna.IZLAZNI ? 'izlazni-racuni' : 'ulazni-racuni',
          'izmena',
          this._racunRoutes[tip],
          id,
        ]);
        break;
      case TipRacuna.PREDRACUN:
        this._router.navigate(['/racuni', 'predracuni', 'izmena', id]);
        break;
      case TipRacuna.PONUDA:
        this._router.navigate(['/racuni', 'ponude', 'izmena', id]);
        break;
      case TipRacuna.OTPREMNICA:
        this._router.navigate(['/racuni', 'otpremnice', 'izmena', id]);
        break;
      case TipRacuna.RADNI_NALOG:
        this._router.navigate(['/racuni', 'radni-nalozi', 'izmena', id]);
        break;
      default:
        this._alertService.addWarnMsg(
          'routeToRacunEdit(): Tip računa nije podržan za rutiranje'
        );
    }
  }

  routeToRadniNalogEdit(id: number, tip: TipRacuna) {
    switch (tip) {
      case TipRacuna.RADNI_NALOG:
        this._router.navigate(['/racuni', 'radni-nalozi', 'izmena', id]);
        break;
      default:
        this._alertService.addWarnMsg(
          'routeToRacunEdit(): Tip računa nije podržan za rutiranje'
        );
    }
  }

  routeToKompenzacijaCreate(vrsta: VrstaRacuna) {
    this._router.navigate([
      '/racuni',
      vrsta === VrstaRacuna.IZLAZNI ? 'izlazni-racuni' : 'ulazni-racuni',
      'unos',
      'kompenzacija',
    ]);
  }

  routeToKompenzacijaEdit(kompenzacijaId: number, vrsta: VrstaRacuna) {
    this._router.navigate([
      '/racuni',
      vrsta === VrstaRacuna.IZLAZNI ? 'izlazni-racuni' : 'ulazni-racuni',
      'izmena',
      'kompenzacija',
      kompenzacijaId,
    ]);
  }

  async routeToRacunCreate(
    tip: TipRacuna,
    vrsta: VrstaRacuna,
    strankaId?: number
  ) {
    if (strankaId > 0) {
      this.routeToRacunCreateForStranka(tip, vrsta, strankaId);
      return;
    }

    switch (tip) {
      case TipRacuna.IZLAZNA_FAKTURA:
      case TipRacuna.ULAZNA_FAKTURA:
      case TipRacuna.DOKUMENT_O_SMANJENJU:
      case TipRacuna.DOKUMENT_O_POVECANJU:
        await this._router.navigate([
          '/racuni',
          vrsta === VrstaRacuna.IZLAZNI ? 'izlazni-racuni' : 'ulazni-racuni',
          'unos',
          this._racunRoutes[tip],
        ]);
        break;
      case TipRacuna.PREDRACUN:
        await this._router.navigate(['/racuni', 'predracuni', 'unos']);
        break;
      case TipRacuna.PONUDA:
        await this._router.navigate(['/racuni', 'ponude', 'unos']);
        break;
      case TipRacuna.OTPREMNICA:
        await this._router.navigate(['/racuni', 'otpremnice', 'unos']);
        break;
      case TipRacuna.RADNI_NALOG:
        await this._router.navigate(['/racuni', 'radni-nalozi', 'unos']);
        break;
      default:
        this._alertService.addWarnMsg(
          'routeToRacunCreate(): Tip računa nije podržan za rutiranje'
        );
    }
  }

  routeToAvansniRacunCreate(vrsta: VrstaRacuna, stopaPDVa: number,  strankaId: string) {
    this._router.navigate(
      [
        'racuni',
        vrsta === VrstaRacuna.ULAZNI ? 'ulazni-racuni' : 'izlazni-racuni',
        'unos',
        'avansni-racun-za-pdv',
      ],
      {
        state: {
          stopaPDVa: stopaPDVa,
          strankaId: strankaId
        },
      }
    );
  }

  routeToRacunCreateForStranka(
    tip: TipRacuna,
    vrsta: VrstaRacuna,
    strankaId: number
  ) {
    switch (tip) {
      case TipRacuna.IZLAZNA_FAKTURA:
      case TipRacuna.ULAZNA_FAKTURA:
      case TipRacuna.DOKUMENT_O_SMANJENJU:
      case TipRacuna.DOKUMENT_O_POVECANJU:
        this._router.navigate([
          '/racuni',
          vrsta === VrstaRacuna.IZLAZNI ? 'izlazni-racuni' : 'ulazni-racuni',
          'unos',
          `${this._racunRoutes[tip]}-za-stranku`,
          strankaId,
        ]);
        break;
      case TipRacuna.PREDRACUN:
        this._router.navigate([
          '/racuni',
          'predracuni',
          'unos-za-stranku',
          strankaId,
        ]);
        break;
      case TipRacuna.OTPREMNICA:
        this._router.navigate([
          '/racuni',
          'otpremnice',
          'unos-za-stranku',
          strankaId,
        ]);
        break;
      default:
        this._alertService.addWarnMsg(
          'routeToRacunCreateForStranka(): Tip računa nije podržan za rutiranje'
        );
    }
  }

  routeToRacunCreateForOtpremnica(otpremnicaIdArray: number[]) {
    this._router.navigate(
      [
        '/racuni',
        'izlazni-racuni',
        'unos',
        `${this._racunRoutes[TipRacuna.IZLAZNA_FAKTURA]}-za-otpremnicu`,
      ],
      {
        state: {
          otpremnicaIdArray: otpremnicaIdArray,
        },
      }
    );
  }

  routeToOtpremnicaCreateForRacun(racunId: number) {
    this._router.navigate([
      '/racuni',
      'otpremnice',
      `unos-${this._racunRoutes[TipRacuna.OTPREMNICA]}-iz-fakture`,
      racunId,
    ]);
  }

  routeToPredracunForPonuda(racunId: number) {
    this._router.navigate([
      '/racuni',
      'predracuni',
      `unos-${this._racunRoutes[TipRacuna.PREDRACUN]}-iz-ponude`,
      racunId,
    ]);
  }

  routeToPojedinacnaPDVEvidencijaForRacun(racunId: number) {
    this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'pojedinacne',
      `unos-preko-fakture`,
      racunId,
    ]);
  }
  routeToRacunCreateForPredracun(id: number) {
    this._router.navigate(
      [
        '/racuni',
        'izlazni-racuni',
        'unos',
        `${this._racunRoutes[TipRacuna.IZLAZNA_FAKTURA]}-iz-predracuna`,
      ],
      {
        state: {
          predracunId: id,
        },
      }
    );
  }
  routeToRacunCreateForRadniNalog(id: number) {
    this._router.navigate(
      [
        '/racuni',
        'izlazni-racuni',
        'unos',
        `${this._racunRoutes[TipRacuna.IZLAZNA_FAKTURA]}-iz-radnog-naloga`,
      ],
      {
        state: {
          racunId: id,
        },
      }
    );
  }

  routeToOtpremnicaForPredracun(id: number) {
    this._router.navigate([
      '/racuni',
      'otpremnice',
      `unos-${this._racunRoutes[TipRacuna.OTPREMNICA]}-iz-predracuna`,
      id,
    ]);
  }

  routeToAvansniForPredracun(id: number) {
    this._router.navigate([
      '/racuni',
      'izlazni-racuni',
      'unos',
      `${this._racunRoutes[TipRacuna.AVANSNI_RACUN]}-iz-predracuna`,
      id,
    ]);
  }
  routeToAvansniForPredracunUplata(
    requestList: UplataIsplataZaRacunDto[],
    transakcijaId: number
  ) {
    this._router.navigate(
      [
        '/racuni',
        'izlazni-racuni',
        'unos',
        `${this._racunRoutes[TipRacuna.AVANSNI_RACUN]}-iz-predracun-uplate`,
      ],
      {
        state: {
          requestList: requestList,
          transakcijaIzvodaId: transakcijaId,
        },
      }
    );
  }

  routeToDuplicate(id: number, tip: TipRacuna, vrsta: VrstaRacuna) {
    switch (tip) {
      case TipRacuna.IZLAZNA_FAKTURA:
        this._router.navigate(
          [
            '/racuni',
            vrsta === VrstaRacuna.IZLAZNI ? 'izlazni-racuni' : 'ulazni-racuni',
            'unos',
            `${this._racunRoutes[tip]}-duplikat`,
          ],
          {
            state: {
              id: id,
            },
          }
        );
        break;
      case TipRacuna.ULAZNA_FAKTURA:
        this._router.navigate(
          [
            '/racuni',
            vrsta === VrstaRacuna.ULAZNI ? 'ulazni-racuni' : 'izlazni-racuni',
            'unos',
            `${this._racunRoutes[tip]}-duplikat`,
          ],
          {
            state: {
              id: id,
            },
          }
        );
        break;
      case TipRacuna.PREDRACUN:
        this._router.navigate(
          ['/racuni', 'predracuni', 'unos-predracun-duplikat'],
          {
            state: {
              id: id,
            },
          }
        );
        break;
      case TipRacuna.OTPREMNICA:
        this._router.navigate(
          ['/racuni', 'otpremnice', 'unos-otpremnice-duplikat'],
          {
            state: {
              id: id,
            },
          }
        );
        break;
      default:
        this._alertService.addWarnMsg(
          'routeToRacunEdit(): Tip računa nije podržan za rutiranje'
        );
    }
  }

  routeToDuplicateRadniNalog(id: number, tip: TipRacuna) {
    switch (tip) {
      case TipRacuna.RADNI_NALOG:
        this._router.navigate(
          ['/racuni', 'radni-nalozi', 'radni-nalog-duplikat'],
          {
            state: {
              id: id,
            },
          }
        );
        break;
      default:
        this._alertService.addWarnMsg(
          'routeToRacunEdit(): Tip računa nije podržan za rutiranje'
        );
    }
  }

  async routeToObradaSefRacuna(
    sefRacunId: number,
    status: StatusRacuna,
    jeAutomatskaObrada: boolean
  ) {
    await this._router.navigate(
      [
        '/racuni',
        'ulazni-racuni',
        jeAutomatskaObrada
          ? 'automatska-obrada-sef-racuna'
          : 'obrada-sef-racuna',
        sefRacunId,
      ],
      {
        state: {
          statusRacuna: status,
        },
      }
    );
  }

  private _racunRoutes: { [key in TipRacuna]?: string } = {
    [TipRacuna.IZLAZNA_FAKTURA]: 'faktura',
    [TipRacuna.ULAZNA_FAKTURA]: 'faktura',
    [TipRacuna.PREDRACUN]: 'predracun',
    [TipRacuna.OTPREMNICA]: 'otpremnica',
    [TipRacuna.DOKUMENT_O_POVECANJU]: 'dokument-o-povecanju',
    [TipRacuna.DOKUMENT_O_SMANJENJU]: 'dokument-o-smanjenju',
    [TipRacuna.AVANSNI_RACUN]: 'avansni-racun',
  };

  //#endregion Racun routes

  //#region Stranka routes

  routeToStrankaKartica(
    id: number,
    tip: TipStranke,
    openInNewTab: boolean = false
  ) {
    const url = this._router
      .createUrlTree([
        '/stranke',
        tip === TipStranke.FIZIKO_LICE ? 'fizicka-lica' : 'pravna-lica',
        'kartica',
        id,
      ])
      .toString();

    if (openInNewTab) {
      window.open(url, '_blank');
    } else {
      this._router.navigateByUrl(url);
    }
  }

  //#endregion Stranka routes

  //#region Pdv evidencije

  async routeToPdvEvidencijePojedinacnaCreate() {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'pojedinacne',
      'unos',
    ]);
  }

  async routeToPdvEvidencijePojedinacna39Create() {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'pojedinacne',
      'unos39',
    ]);
  }

  async routeToPdvEvidencijePojedinacnaUpdate(id: number) {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'pojedinacne',
      'izmena',
      id,
    ]);
  }

  async routeToPdvEvidencijePojedinacna39Update(id: number) {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'pojedinacne',
      'izmena39',
      id,
    ]);
  }

  async routeToPdvEvidencijePojedinacnaKoriguj(id: number) {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'pojedinacne',
      'korekcija',
      id,
    ]);
  }

  async routeToPdvEvidencijePojedinacna39Koriguj(id: number) {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'pojedinacne',
      'korekcija39',
      id,
    ]);
  }

  async routeToPdvEvidencijePojedinacnaKorigovana(id: number) {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'pojedinacne',
      'pregled-korigovane',
      id,
    ]);
  }

  async routeToPdvEvidencijePojedinacnaKorigovana39(id: number) {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'pojedinacne',
      'pregled-korigovane39',
      id,
    ]);
  }

  async routeToPdvEvidencijeZbirnaCreate() {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'zbirne',
      'unos',
    ]);
  }

  async routeToPdvEvidencijeZbirna39Create() {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'zbirne',
      'unos39',
    ]);
  }

  async routeToPdvEvidencijeZbirnaUpdate(id: number) {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'zbirne',
      'izmena',
      id,
    ]);
  }

  async routeToPdvEvidencijeZbirna39Update(id: number) {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'zbirne',
      'izmena39',
      id,
    ]);
  }
  async routeToPdvEvidencijeZbirnaKoriguj(id: number) {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'zbirne',
      'korekcija',
      id,
    ]);
  }

  async routeToPdvEvidencijeZbirna39Koriguj(id: number) {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'zbirne',
      'korekcija39',
      id,
    ]);
  }

  async routeToPdvEvidencijeZbirnaKorigovana(id: number) {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'zbirne',
      'pregled-korigovane',
      id,
    ]);
  }

  async routeToPdvEvidencijeZbirna39Korigovana(id: number) {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'zbirne',
      'pregled-korigovane39',
      id,
    ]);
  }

  async routeToPPPDVCreate() {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'pppdv',
      'unos',
    ]);
  }

  async routeToPPPDVUpdate(id: number) {
    await this._router.navigate([
      '/dokumenti',
      'pdv-evidencije',
      'pppdv',
      'izmena',
      id,
    ]);
  }

  async routeToCancelPdvEvidencija() {
    this._location.back();
  }

  //#endregion Pdv evidencije

  //#region Maloprodaja routes

  async routeToKreirajFiskalniSaOdabranimReferentnim(
    referentniFiskalniRacunId: string,
    jeKreirajAvansRefundaciju?: boolean
  ) {
    this._router.navigate(['/maloprodaja', 'fiskalni', 'unos'], {
      state: {
        referentniFiskalniRacunId: referentniFiskalniRacunId,
        jeKreirajAvansRefundaciju: jeKreirajAvansRefundaciju,
      },
    });
  }

  async routeToFiskalniInfo(id: string) {
    this._router.navigate(['/maloprodaja', 'fiskalni', 'info', id]);
  }

  //#endregion Maloprodaja routes

  //#region Delovodnik routes

  async routeToDelovodnikInfo(id: number) {
    this._router.navigate(['/kompanija', 'delovodnik', 'info', id]);
  }

  //#endregion Delovodnik routes
}
