<div class="p-field">
    <label for="pretraga-oznake" kodit-form-label>Kategorija</label>
    <span class="p-input-icon-left pretraga-box">
      <i class="fas fa-search"></i>
      <p-autoComplete
        [(ngModel)] = "oznakaModel"
        inputId="kategorija"
        [suggestions]="oznakeResults"
        placeholder="Naziv kategorije..."
        pTooltip="Unesi naziv kateogorije za pretragu"
        (completeMethod)="searchOznake($event)"
        (onSelect)="onOznakaSelect($event)"
        [field]="oznakaModel?.punNaziv ? 'punNaziv' : 'naziv'"
        >
        <ng-template let-oznaka pTemplate="item">
          <div>
            {{ oznaka.punNaziv ? oznaka.punNaziv : oznaka.naziv }}
          </div>
        </ng-template>
      </p-autoComplete>
    </span>
  </div>