import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';

import { IRacunUkupanIznosDto, TipRacuna } from '@kodit/core/data-api';

@Injectable({
  providedIn: 'root',
})
export class RacunUkupanIznosFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetFormGroup(
    model?: IRacunUkupanIznosDto,
    tip?: TipRacuna
  ): FormGroupTypeSafe<IRacunUkupanIznosDto> {
    return this._fb.group<IRacunUkupanIznosDto>({
      avansiranaOsnovica8: new FormControl(model?.avansiranaOsnovica8),
      avansiranaOsnovica10: new FormControl(model?.avansiranaOsnovica10),
      avansiranaOsnovica20: new FormControl(model?.avansiranaOsnovica20),
      avansiranaOsnovicaAE10: new FormControl(model?.avansiranaOsnovicaAE10),
      avansiranaOsnovicaAE20: new FormControl(model?.avansiranaOsnovicaAE20),
      avansiranaOsnovicaE: new FormControl(model?.avansiranaOsnovicaE),
      avansiranaOsnovicaN: new FormControl(model?.avansiranaOsnovicaN),
      avansiranaOsnovicaO: new FormControl(model?.avansiranaOsnovicaO),
      avansiranaOsnovicaOE: new FormControl(model?.avansiranaOsnovicaOE),
      avansiranaOsnovicaR: new FormControl(model?.avansiranaOsnovicaR),
      avansiranaOsnovicaS: new FormControl(model?.avansiranaOsnovicaS),
      avansiranaOsnovicaSS: new FormControl(model?.avansiranaOsnovicaSS),
      avansiranaOsnovicaZ: new FormControl(model?.avansiranaOsnovicaZ),
      avansiraniPdv8: new FormControl(model?.avansiraniPdv8),
      avansiraniPdv10: new FormControl(model?.avansiraniPdv10),
      avansiraniPdv20: new FormControl(model?.avansiraniPdv20),
      smanjenjeNaknade8: new FormControl(model?.smanjenjeNaknade8),
      smanjenjeNaknade10: new FormControl(model?.smanjenjeNaknade10),
      smanjenjeNaknade20: new FormControl(model?.smanjenjeNaknade20),
      smanjenjeNaknadeAE10: new FormControl(model?.smanjenjeNaknadeAE10),
      smanjenjeNaknadeAE20: new FormControl(model?.smanjenjeNaknadeAE20),
      smanjenjeNaknadeE: new FormControl(model?.smanjenjeNaknadeE),
      smanjenjeNaknadeN: new FormControl(model?.smanjenjeNaknadeN),
      smanjenjeNaknadeO: new FormControl(model?.smanjenjeNaknadeO),
      smanjenjeNaknadeOE: new FormControl(model?.smanjenjeNaknadeOE),
      smanjenjeNaknadeR: new FormControl(model?.smanjenjeNaknadeR),
      smanjenjeNaknadeSS: new FormControl(model?.smanjenjeNaknadeSS),
      smanjenjeNaknadeZ: new FormControl(model?.smanjenjeNaknadeZ),
      ukupanAvansiranIznos: new FormControl(model?.ukupanAvansiranIznos),
      ukupnoOsnovica8: new FormControl(model?.ukupnoOsnovica8),
      ukupnoOsnovica10: new FormControl(model?.ukupnoOsnovica10),
      ukupnoOsnovica20: new FormControl(model?.ukupnoOsnovica20),
      ukupnoOsnovicaAE10: new FormControl(model?.ukupnoOsnovicaAE10),
      ukupnoOsnovicaAE20: new FormControl(model?.ukupnoOsnovicaAE20),
      ukupnoOsnovicaE: new FormControl(model?.ukupnoOsnovicaE),
      ukupnoOsnovicaN: new FormControl(model?.ukupnoOsnovicaN),
      ukupnoOsnovicaO: new FormControl(model?.ukupnoOsnovicaO),
      ukupnoOsnovicaOE: new FormControl(model?.ukupnoOsnovicaOE),
      ukupnoOsnovicaR: new FormControl(model?.ukupnoOsnovicaR),
      ukupnoOsnovicaSS: new FormControl(model?.ukupnoOsnovicaSS),
      ukupnoOsnovicaZ: new FormControl(model?.ukupnoOsnovicaZ),
      ukupnoPdv8: new FormControl(model?.ukupnoPdv8),
      ukupnoPdv10: new FormControl(model?.ukupnoPdv10),
      ukupnoPdv20: new FormControl(model?.ukupnoPdv20),
      rsdUkupnoOsnovica8: new FormControl(model?.rsdUkupnoOsnovica8),
      rsdUkupnoOsnovica10: new FormControl(model?.rsdUkupnoOsnovica10),
      rsdUkupnoOsnovica20: new FormControl(model?.rsdUkupnoOsnovica20),
      rsdUkupnoOsnovicaAE10: new FormControl(model?.rsdUkupnoOsnovicaAE10),
      rsdUkupnoOsnovicaAE20: new FormControl(model?.rsdUkupnoOsnovicaAE20),
      rsdUkupnoOsnovicaE: new FormControl(model?.rsdUkupnoOsnovicaE),
      rsdUkupnoOsnovicaN: new FormControl(model?.rsdUkupnoOsnovicaN),
      rsdUkupnoOsnovicaO: new FormControl(model?.rsdUkupnoOsnovicaO),
      rsdUkupnoOsnovicaOE: new FormControl(model?.rsdUkupnoOsnovicaOE),
      rsdUkupnoOsnovicaR: new FormControl(model?.rsdUkupnoOsnovicaR),
      rsdUkupnoOsnovicaSS: new FormControl(model?.rsdUkupnoOsnovicaSS),
      rsdUkupnoOsnovicaZ: new FormControl(model?.rsdUkupnoOsnovicaZ),
      rsdUkupnoPdv8: new FormControl(model?.rsdUkupnoPdv8),
      rsdUkupnoPdv10: new FormControl(model?.rsdUkupnoPdv10),
      rsdUkupnoPdv20: new FormControl(model?.rsdUkupnoPdv20),
      ukupnoPdvUmanjenZaAvanse8: new FormControl(
        model?.ukupnoPdvUmanjenZaAvanse8
      ),
      ukupnoPdvUmanjenZaAvanse10: new FormControl(
        model?.ukupnoPdvUmanjenZaAvanse10
      ),
      ukupnoPdvUmanjenZaAvanse20: new FormControl(
        model?.ukupnoPdvUmanjenZaAvanse20
      ),
      ukupnoZaUplatu: new FormControl(model?.ukupnoZaUplatu ?? 0, {
        validators:
          tip === TipRacuna.AVANSNI_RACUN
            ? [Validators.required, Validators.min(0.000001)]
            : [Validators.required],
      }),
      umanjenaOsnovica8: new FormControl(model?.umanjenaOsnovica8),
      umanjenaOsnovica10: new FormControl(model?.umanjenaOsnovica10),
      umanjenaOsnovica20: new FormControl(model?.umanjenaOsnovica20),
      umanjenaOsnovicaAE10: new FormControl(model?.umanjenaOsnovicaAE10),
      umanjenaOsnovicaAE20: new FormControl(model?.umanjenaOsnovicaAE20),
      umanjenaOsnovicaE: new FormControl(model?.umanjenaOsnovicaE),
      umanjenaOsnovicaN: new FormControl(model?.umanjenaOsnovicaN),
      umanjenaOsnovicaO: new FormControl(model?.umanjenaOsnovicaO),
      umanjenaOsnovicaOE: new FormControl(model?.umanjenaOsnovicaOE),
      umanjenaOsnovicaR: new FormControl(model?.umanjenaOsnovicaR),
      umanjenaOsnovicaS: new FormControl(model?.umanjenaOsnovicaS),
      umanjenaOsnovicaSS: new FormControl(model?.umanjenaOsnovicaSS),
      umanjenaOsnovicaZ: new FormControl(model?.umanjenaOsnovicaZ),
      zbirStavki8: new FormControl(model?.zbirStavki8),
      zbirStavki10: new FormControl(model?.zbirStavki10),
      zbirStavki20: new FormControl(model?.zbirStavki20),
      zbirStavkiAE10: new FormControl(model?.zbirStavkiAE10),
      zbirStavkiAE20: new FormControl(model?.zbirStavkiAE20),
      zbirStavkiE: new FormControl(model?.zbirStavkiE),
      zbirStavkiN: new FormControl(model?.zbirStavkiN),
      zbirStavkiO: new FormControl(model?.zbirStavkiO),
      zbirStavkiOE: new FormControl(model?.zbirStavkiOE),
      zbirStavkiR: new FormControl(model?.zbirStavkiR),
      zbirStavkiS: new FormControl(model?.zbirStavkiS),
      zbirStavkiSS: new FormControl(model?.zbirStavkiSS),
      zbirStavkiZ: new FormControl(model?.zbirStavkiZ),
      iznosZaZaokruzivanje: new FormControl(model?.iznosZaZaokruzivanje),
    });
  }
}
