<div class="p-d-flex p-flex-column">
  <div class="p-fluid">
    <kodit-field
      fieldLabel="Identifikacija kupca"
      fieldLabelId="buyer-identification"
    >
      <kodit-buyer-identification-dropdown
        [formCtrl]="fiskalniRacunForm?.controls?.buyerIdentification!"
      >
      </kodit-buyer-identification-dropdown>
    </kodit-field>
  </div>
  <div class="p-fluid" *ngIf="shouldShowBuyer">
    <kodit-field fieldLabel="ID kupca" fieldLabelId="buyer-identification-id">
      <kodit-text-input
        *ngIf="!showPretragaPravnogLica"
        [ctrlPlaceholder]="'ID kupca'"
        [formCtrl]="fiskalniRacunForm?.controls?.buyerId"
      >
      </kodit-text-input>
      <small
        *ngIf="
          !showPretragaPravnogLica &&
          fiskalniRacunForm?.get('buyerId')?.invalid &&
          fiskalniRacunForm?.get('buyerId')?.value?.length > 0
        "
        id="password-check-help2"
        class="p-invalid"
      >Neispravan broj karaktera</small
      >
      <kodit-pravno-lice-kps-autocomplete
        *ngIf="showPretragaPravnogLica"
        [ctrlPlaceholder]="fiskalniRacunForm?.controls.buyerId?.value ?? 'Matični broj, PIB ili naziv...'"
        (onPravnoLiceChange)="selectionChanged($event)"
        (onPravnoLiceClear)="onClearPravnoLice()"
      ></kodit-pravno-lice-kps-autocomplete>
      <small
        *ngIf="
          showPretragaPravnogLica &&
          fiskalniRacunForm?.get('buyerId')?.invalid
        "
        id="password-check-help2"
        class="p-invalid"
      >Neispravno polje 'ID kupca', odaberite firmu iz padajuće liste</small
      >
    </kodit-field>
  </div>
  <div class="p-fluid">
    <kodit-field fieldLabel="Opciono polje kupca">
      <kodit-buyer-optional-field-dropdown
        [formCtrl]="fiskalniRacunForm?.controls?.buyerOptionalField!"
      >
      </kodit-buyer-optional-field-dropdown>
    </kodit-field>
  </div>
  <div class="p-fluid" *ngIf="shouldShowOptionalField">
    <kodit-field fieldLabel="Broj">
      <kodit-text-input
        [ctrlPlaceholder]="'Broj'"
        [formCtrl]="fiskalniRacunForm?.controls?.buyerCostCenterId"
      >
      </kodit-text-input>
    </kodit-field>
  </div>
</div>
