<p-multiSelect
  [options]="items"
  [(ngModel)]="selectedItems"
  appendTo="body"
  placeholder="Izaberite status računa"
  (onChange)="selectedItemsOnChange()"
  [formCtrlId]="formCtrlId"
  [formCtrl]="tipCtrl"
  (optionChanged)="handleOnItemChange($event)"
></p-multiSelect>
