import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DelovodniDokumentPrilog } from '@kodit/core/data-api';
import { DynamicDialogConfig } from '@kodit/core/shared-ui';

@Component({
  selector: 'kodit-delovodni-dokument-prilog-preview',
  templateUrl: './delovodni-dokument-prilog-preview.component.html',
  styleUrls: ['./delovodni-dokument-prilog-preview.component.scss'],
})
export class DelovodniDokumentPrilogPreviewComponent implements OnInit {
  /** Props */
  innerHtml: SafeHtml | undefined;
  prilog: DelovodniDokumentPrilog | undefined;

  constructor(
    private _dialogConfig: DynamicDialogConfig,
    private _domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.prilog = this._dialogConfig.data.prilog;
    this._setInnerHtml();
  }

  private _setInnerHtml() {
    if (this.prilog?.originalFileName?.includes('pdf')) {
      this.innerHtml = this._domSanitizer.bypassSecurityTrustHtml(
        `<object data="${this.prilog?.fileUrl}" type="application/pdf" class="embed-responsive-item">
     Object ${this.prilog?.fileUrl} failed
   </object>`
      );
      return;
    }

    this.innerHtml = this._domSanitizer.bypassSecurityTrustHtml(
      "<img src='" +
        this.prilog?.fileUrl +
        "' alt='Image preview' class='embed-responsive-item'>"
    );
  }
}
