import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IJedinicaMereDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JedinicaMereFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  /**
   * Konvertuje model za jedinicu mere i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model jedinice mere od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetJedinicuMereFormGroup(
    model?: IJedinicaMereDto,
    isRequired: boolean = true
  ): FormGroupTypeSafe<IJedinicaMereDto> {
    return this._fb.group<IJedinicaMereDto>({
      id: new FormControl(model?.id, {
        validators: isRequired
          ? [Validators.required, Validators.maxLength(20)]
          : [Validators.nullValidator],
      }),
      naziv: new FormControl(model?.naziv),
    });
  }
}
