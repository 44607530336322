import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {MojaFirmaClient} from '@kodit/core/data-api';
import {StopaPdv} from '@kodit/core/shared';
import {SelectItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {PdvStopaService} from './pdv-stopa.service';

@Component({
  selector: 'kodit-pdv-stopa-dropdown',
  templateUrl: './pdv-stopa-dropdown.component.html',
  styleUrls: ['./pdv-stopa-dropdown.component.scss'],
})
export class PdvStopaDropdownComponent implements OnInit, OnDestroy {
  /** Props */
  private _subs: Subscription = new Subscription();
  items: SelectItem[];

  /** I/O */
  @Input() pdvStopaCtrl: FormControl | AbstractControl;
  @Input() parent: any = 'body';
  @Input() shouldSetDefaultValue: boolean;
  @Output() onItemChange = new EventEmitter<any>();
  @Output() onDefaultValuteSet = new EventEmitter<void>();

  constructor(
    private _mojaFirmaClient: MojaFirmaClient,
    private _service: PdvStopaService,
    private _route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    // set pdv configuration
    this._load();

    this.items = [
      {label: 'Opšta stopa (20%)', value: StopaPdv.OSNOVNA},
      {label: 'Posebna stopa (10%)', value: StopaPdv.POSEBNA},
      {label: 'Posebna stopa (8%)', value: StopaPdv.POSEBNA_POLJOPRIVREDNO_GAZDINSTVO},
      {label: 'Bez PDV-a', value: StopaPdv.BEZ_PDV},
    ];
  }

  private _load() {
    this.shouldSetDefaultValue = this._route.snapshot.data[
      'useDefaultPdvValue'
      ];

    this._subs.add(
      this._mojaFirmaClient.getPdvConfiguration().subscribe((res) => {
        this._service.setJeKompanijaUPdv = res.data.uSistemuPDVa;
        if (this.shouldSetDefaultValue) {
          if (res.data.uSistemuPDVa) {
            this.pdvStopaCtrl.patchValue(res.data.stopaPDVa);
          } else {
            this.pdvStopaCtrl.patchValue(0);
          }
          this.pdvStopaCtrl.updateValueAndValidity();
          this.onDefaultValuteSet.emit();
        }
      })
    );
  }

  handleOnItemChange(event: any) {
    this.onItemChange.emit(event);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
