<kodit-card-container>
  <div card-title>Osnovne informacije</div>

  <div card-right-side>
    <button
      icon="far fa-edit"
      pButton
      type="button"
      label="Izmeni"
      class="p-button p-button-outlined"
      (click)="onEdit()"
    ></button>
  </div>

  <div
    class="p-d-flex p-flex-column p-flex-md-row p-jc-between gap-25"
    card-body
    *ngIf="dodatneInfoDto"
  >
    <!-- <h4>{{mojaFirma.punNaziv}}</h4> -->
    <div card-custom-title>
      <kodit-logo-upload></kodit-logo-upload>
    </div>

    <div class="p-d-flex p-flex-column p-jc-between">
      <!-- <kodit-card-body-text
        label="Pun naziv"
        [text]="mojaFirma.punNaziv"
      ></kodit-card-body-text> -->

      <kodit-card-body-text
        label="Adresa"
        [text]="mojaFirma.adresa"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="PIB"
        [text]="mojaFirma.pib"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Matični broj"
        [text]="mojaFirma.mb"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Pravni oblik"
        [text]="mojaFirma.pravniOblik"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Šifra delatnosti"
        [text]="mojaFirma.sifraDelatnosti"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Vlasnik / Vlasnici"
        [text]="dodatneInfoDto.vlasnici"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Zastupnik / Zastupnici"
        [text]="dodatneInfoDto.zastupnici"
      ></kodit-card-body-text>
    </div>

    <div class="p-d-flex p-flex-column p-jc-between p-mr-6">
      <kodit-card-body-text
        label="Datum osnivanja"
        [text]="mojaFirma.datumOsnivanjaStr"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Upisani kapital"
        [text]="dodatneInfoDto.upisaniKapital + ' ' + dodatneInfoDto.valutaText"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="AprBD"
        [text]="dodatneInfoDto.aprBD"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="PePDV"
        [text]="dodatneInfoDto.pePDV"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Email"
        [text]="dodatneInfoDto.email"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Website"
        [text]="dodatneInfoDto.websiteUrl"
      ></kodit-card-body-text>

      <kodit-card-body-icon
        *ngIf="dodatneInfoDto.uSistemuPDVa"
        label="U sistemu pdv-a"
        okIconClass="kodit-success fas fa-check"
      ></kodit-card-body-icon>

      <kodit-card-body-icon
        *ngIf="!dodatneInfoDto.uSistemuPDVa"
        label="U sistemu pdv-a"
        okIconClass="kodit-error fas fa-times"
      ></kodit-card-body-icon>
    </div>
  </div>
</kodit-card-container>
