import { FormControl } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';

import { OznakaFormService } from '../common/oznaka-form.service';
import { BaseStrankaFormService } from '../stranka/_base_stranka-form.service';
import { IRacunFilterDto, VrstaRacuna } from '@kodit/core/data-api';
import { StatusRacunaFormService } from './status-racuna-form.service';
import {FormHelper} from "../_helpers/form-helper";

@Injectable({
  providedIn: 'root',
})
export class RacunFilterFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _oznakaFormService: OznakaFormService,
    private _strankaFormService: BaseStrankaFormService,
    private _statusRacunaFormService: StatusRacunaFormService,
    private _formHelper : FormHelper
  ) {}

  public GetNaprednaPretragaRacunaFormGroup(
    model?: IRacunFilterDto
  ): FormGroupTypeSafe<IRacunFilterDto> {
    return this._fb.group<IRacunFilterDto>({
      broj: new FormControl(model?.broj),
      originalanBroj: new FormControl(model?.originalanBroj),
      gradStranke: new FormControl(model?.gradStranke),
      strankaId: new FormControl(model?.strankaId),
      // datumIzdavanjaOd: new FormControl(model?.datumIzdavanjaOd),
      // datumIzdavanjaDo: new FormControl(model?.datumIzdavanjaDo),
      datumSlanjaSefOd: this._formHelper.initializeDateFormControl(model?.datumSlanjaSefOd),
      datumSlanjaSefDo: this._formHelper.initializeDateFormControl(model?.datumSlanjaSefDo),
      datumPrometaOd: this._formHelper.initializeDateFormControl(model?.datumPrometaOd),
      datumPrometaDo: this._formHelper.initializeDateFormControl(model?.datumPrometaDo),
      dodatniOpisStavke: new FormControl(model?.dodatniOpisStavke),
      prometOd: new FormControl(model?.prometOd),
      prometDo: new FormControl(model?.prometDo),
      period: new FormControl(model?.period),
      datumValute: this._formHelper.initializeDateFormControl(model?.datumValute),
      valutaIsticeZa: new FormControl(model?.valutaIsticeZa),
      statusiUplateRacuna:
        this._statusRacunaFormService.GetStatusUplateFormArray(
          model?.statusiUplateRacuna
        ),
      statusi: this._statusRacunaFormService.GetStatusFormArray(model?.statusi),
      vrstaRacuna: new FormControl(model?.vrstaRacuna ?? VrstaRacuna.EMPTY),
      oznake: this._oznakaFormService.GetOznakaMultiselectFormArray(
        model?.oznake
      ),
      stranke: this._strankaFormService.GetStrankeFormArray(model?.stranke),
      vanValute: new FormControl(model?.vanValute),
    });
  }
}
