<form [formGroup]="form" (keydown)="keyDownFunction($event)">
  <div class="p-fluid">
    <kodit-field fieldLabel="Ime" fieldLabelFor="firstName">
      <kodit-text-input
        formCtrlId="firstName"
        [formCtrl]="form.controls.firstName"
      ></kodit-text-input>
    </kodit-field>

    <kodit-field fieldLabel="Prezime" fieldLabelFor="lastName">
      <kodit-text-input
        formCtrlId="lastName"
        [formCtrl]="form.controls.lastName"
      ></kodit-text-input>
    </kodit-field>

    <kodit-field fieldLabel="E-Mail / Korisničko ime" fieldLabelFor="email">
      <kodit-text-input
        formCtrlId="email"
        formCtrlType="email"
        [formCtrl]="form.controls.email"
        [isDisabled]="isEditMode"
      ></kodit-text-input>
    </kodit-field>

    <!-- <kodit-field
      fieldLabel="Šifra"
      fieldLabelFor="password"
      *ngIf="!isEditMode"
    >
      <kodit-text-input
        formCtrlId="password"
        formCtrlType="password"
        [formCtrl]="form.controls.password"
      ></kodit-text-input>
    </kodit-field>

    <kodit-field
      fieldLabel="Ponovite šifru"
      fieldLabelFor="passwordCheck"
      *ngIf="!isEditMode"
    >
      <kodit-text-input
        formCtrlId="passwordCheck"
        formCtrlType="password"
        [formCtrl]="form.controls.passwordCheck"
      ></kodit-text-input>
    </kodit-field> -->

    <kodit-field fieldLabel="Mobilni telefon" fieldLabelFor="mobile">
      <kodit-mobilni-telefon-input
        formCtrlId="mobile"
        [mobilniFormGroup]="form.controls.mobilniDto"
      ></kodit-mobilni-telefon-input>
    </kodit-field>
  </div>
</form>
