<div [formGroup]="form">
  <div class="p-mt-5">
    <kodit-card-container>
      <div card-body class="p-d-flex p-flex-column">
        <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
          <kodit-field
            fieldLabel="Broj Zbirne evidencije PDV"
            fieldLabelFor="pravilo-za-broj"
            class="flex-50"
          >
            <kodit-text-input
              [formCtrl]="form.controls.broj"
              [isDisabled]="shouldDisableForm"
            ></kodit-text-input>
          </kodit-field>

          <kodit-field
            fieldLabel="Godina"
            fieldLabelFor="godina"
            class="flex-50"
          >
            <kodit-number-input
              formCtrlId="godina"
              [formCtrl]="form.controls.godina"
              [ctrlMaxLength]="4"
              [ctrlMinValue]="1900"
              [ctrlOnlyIntegers]="true"
              [ctrlUseGrouping]="false"
              [isDisabled]="shouldDisableForm"
            ></kodit-number-input>
          </kodit-field>
          <kodit-field
            fieldLabel="Status"
            fieldLabelFor="status"
            class="flex-50"
          >
            <kodit-text-input
              [formCtrl]="form.controls.status"
              [isDisabled]="shouldDisableForm"
            ></kodit-text-input>
          </kodit-field>
          <kodit-field
            fieldLabelFor="datum-promene-statusa"
            fieldLabel="Datum evidentiranja"
            class="flex-50"
          >
            <kodit-calendar
              formCtrlId="datum-promene-statusa"
              [formCtrl]="form.controls.datumPromeneStatusa!"
              [disabled]="shouldDisableForm"
            ></kodit-calendar>
          </kodit-field>
        </div>

        <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
          <kodit-field
            fieldLabelFor="vremenski-opseg"
            fieldLabel="Poreski period"
            class="flex-50"
          >
            <kodit-pdv-opseg-dropdown
              formCtrlId="vremenski-opseg"
              [periodValue]="form.value.period!"
              [shouldDisable]="shouldDisableForm"
            ></kodit-pdv-opseg-dropdown>
          </kodit-field>
          <kodit-field
            fieldLabelFor="period"
            fieldLabel="Period"
            class="flex-50"
          >
            <kodit-pdv-period-dropdown
              formCtrlId="period"
              [formCtrl]="form.controls.period"
              [shouldDisable]="shouldDisableForm"
            ></kodit-pdv-period-dropdown>
          </kodit-field>
        </div>
      </div>
    </kodit-card-container>
  </div>
</div>

<div class="p-mt-5">
  <kodit-card-container>
    <div card-title>Promet uz naknadu</div>

    <div card-body class="p-d-flex p-flex-column">
      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabelFor="promet-s-naknadom-opis-20"
          fieldLabel="
                
                  Promet po stopi od 20%
                  "
          class="flex-50"
        >
        </kodit-field>

        <kodit-field
          fieldLabelFor="promet-s-naknadom-opis-10"
          fieldLabel="
                  Promet po stopi od 10%
                  "
          class="flex-50"
        >
        </kodit-field>
      </div>

      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabel="Osnovica"
          fieldLabelFor="promet-s-naknadom-osnovica-20"
          class="flex-50"
        >
          <kodit-number-input
            formCtrlId="promet-s-naknadom-osnovica-20"
            [formCtrl]="form.controls.prometSNaknadomOsnovica20"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
        <kodit-field
          fieldLabel="Osnovica"
          fieldLabelFor="promet-s-naknadom-osnovica-10"
          class="flex-50"
        >
          <kodit-number-input
            formCtrlId="promet-s-naknadom-osnovica-10"
            [formCtrl]="form.controls.prometSNaknadomOsnovica10"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
      </div>
      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabelFor="promet-s-naknadom-pdv-20"
          fieldLabel="
              Obračunati
              PDV"
          class="flex-50"
        >
          <kodit-number-input
            [isDisabled]="true"
            formCtrlId="promet-s-naknadom-pdv-20"
            [formCtrl]="form.controls.prometSNaknadomPdv20"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
        <kodit-field
          fieldLabelFor="promet-s-naknadom-pdv-10"
          fieldLabel="
              Obračunati
              PDV"
          class="flex-50"
        >
          <kodit-number-input
            [isDisabled]="true"
            formCtrlId="promet-s-naknadom-pdv-10"
            [formCtrl]="form.controls.prometSNaknadomPdv10"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
      </div>
      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabelFor="promet-s-naknadom-ukupan-iznos-20"
          fieldLabel="
              Ukupan
              iznos"
          class="flex-50"
        >
          <kodit-number-input
            [isDisabled]="true"
            formCtrlId="promet-s-naknadom-ukupan-iznos-20"
            [formCtrl]="form.controls.prometSNaknadomUkupanIznos20"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
        <kodit-field
          fieldLabelFor="promet-s-naknadom-ukupan-iznos-10"
          fieldLabel="
              Ukupan
              iznos"
          class="flex-50"
        >
          <kodit-number-input
            [isDisabled]="true"
            formCtrlId="promet-s-naknadom-ukupan-iznos-10"
            [formCtrl]="form.controls.prometSNaknadomUkupanIznos10"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
      </div>
    </div>
  </kodit-card-container>
</div>

<div class="p-mt-5">
  <kodit-card-container>
    <div card-title>Avansi za budući promet</div>

    <div card-body class="p-d-flex p-flex-column">
      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabelFor="promet-avans-opis-20"
          fieldLabel="
                  
                  Promet po stopi od 20%
                  "
          class="flex-50"
        >
        </kodit-field>
        <kodit-field
          fieldLabelFor="promet-avans-opis-10"
          fieldLabel="
                  
                  Promet po stopi od 10%
                  "
          class="flex-50"
        >
        </kodit-field>
      </div>

      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabel="Iznos avansne uplate"
          fieldLabelFor="promet-avansa-osnovica-20"
          class="flex-50"
        >
          <kodit-number-input
            formCtrlId="promet-s-naknadom-osnovica-20"
            [formCtrl]="form.controls.prometAvansOsnovica20"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
        <kodit-field
          fieldLabel="Iznos avansne uplate"
          fieldLabelFor="promet-avansa-osnovica-10"
          class="flex-50"
        >
          <kodit-number-input
            formCtrlId="promet-avansa-osnovica-10"
            [formCtrl]="form.controls.prometAvansOsnovica10"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
      </div>
      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabelFor="promet-avans-pdv-20"
          fieldLabel="
              Obračunati
              PDV"
          class="flex-50"
        >
          <kodit-number-input
            [isDisabled]="true"
            formCtrlId="promet-avans-pdv-20"
            [formCtrl]="form.controls.prometAvansPdv20"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
        <kodit-field
          fieldLabelFor="promet-avans-pdv-10"
          fieldLabel="
              Obračunati
              PDV"
          class="flex-50"
        >
          <kodit-number-input
            [isDisabled]="true"
            formCtrlId="promet-avans-pdv-10"
            [formCtrl]="form.controls.prometAvansPdv10"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
      </div>
    </div>
  </kodit-card-container>
</div>

<div class="p-mt-5">
  <kodit-card-container>
    <div card-title>Promet bez naknade</div>

    <div card-body class="p-d-flex p-flex-column">
      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabelFor="promet-bez-naknade-opis-20"
          fieldLabel="
                  Promet po stopi od 20%
                  "
          class="flex-50"
        >
        </kodit-field>
        <kodit-field
          fieldLabelFor="promet-bez-naknade-opis-10"
          fieldLabel="
                 
                  Promet po stopi od 10%
                  "
          class="flex-50"
        >
        </kodit-field>
      </div>

      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabel="Osnovica"
          fieldLabelFor="promet-bez-naknade-osnovica-20"
          class="flex-50"
        >
          <kodit-number-input
            formCtrlId="promet-bez-naknade-osnovica-20"
            [formCtrl]="form.controls.prometBezNaknadeOsnovica20"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
        <kodit-field
          fieldLabel="Osnovica"
          fieldLabelFor="promet-bez-naknade-osnovica-10"
          class="flex-50"
        >
          <kodit-number-input
            formCtrlId="promet-bez-naknade-osnovica-10"
            [formCtrl]="form.controls.prometBezNaknadeOsnovica10"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
      </div>
      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabelFor="promet-bez-naknade-pdv-20"
          fieldLabel="
              Obračunati
              PDV"
          class="flex-50"
        >
          <kodit-number-input
            [isDisabled]="true"
            formCtrlId="promet-bez-naknade-pdv-20"
            [formCtrl]="form.controls.prometBezNaknadePdv20"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
        <kodit-field
          fieldLabelFor="promet-bez-naknade-pdv-10"
          fieldLabel="
              Obračunati
              PDV"
          class="flex-50"
        >
          <kodit-number-input
            [isDisabled]="true"
            formCtrlId="promet-bez-naknade-pdv-10"
            [formCtrl]="form.controls.prometBezNaknadePdv10"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
      </div>
    </div>
  </kodit-card-container>
</div>

<div class="p-mt-5">
  <kodit-card-container>
    <div card-title>PDV na osnovu prometa</div>
    <div card-body class="p-d-flex p-flex-column">
      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabel="Smanjenje PDV"
          fieldLabelFor="smanjenje-pdv"
          class="flex-50"
        >
          <kodit-number-input
            formCtrlId="smanjenje-pdv"
            [formCtrl]="form.controls.smanjenjePdv"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
        <kodit-field
          fieldLabel="Povećanje PDV"
          fieldLabelFor="uvecanje-pdv"
          class="flex-50"
        >
          <kodit-number-input
            formCtrlId="uvecanje-pdv"
            [formCtrl]="form.controls.uvecanjePdv"
            [isDisabled]="shouldDisableForm"
          ></kodit-number-input>
        </kodit-field>
      </div>
    </div>
  </kodit-card-container>
</div>

<div class="p-mt-5" card style="position: sticky; bottom: 0; z-index: 9">
  <div class="p-d-flex p-flex-column p-flex-md-row p-jc-between">
    <div class="dodatne-akcije p-d-flex p-flex-column p-jc-around p-ml-3"></div>

    <div class="primarne-akcije p-as-center p-d-flex gap-6">
      <ng-content></ng-content>
      <button
        *ngIf="shouldDisableForm"
        pButton
        pRipple
        type="button"
        label="Zatvori"
        class="p-button-secondary p-button-outlined"
        pTooltip="Zatvori"
        tooltipPosition="bottom"
        (click)="handleRouteBack()"
        [disabled]="false"
      ></button>
      <button
        *ngIf="!shouldDisableForm"
        pButton
        pRipple
        icon="far fa-window-close"
        type="button"
        label="Otkaži"
        class="p-button-secondary p-button-outlined"
        pTooltip="Otkaži PDV evidenciju"
        tooltipPosition="bottom"
        (click)="handleCancel()"
        [disabled]="false"
      ></button>
      <button
        *ngIf="!shouldDisableForm"
        pButton
        icon="fas fa-save"
        type="submit"
        label="Sačuvaj"
        class="p-button-success"
        pTooltip="Sačuvaj PDV evidenciju"
        tooltipPosition="bottom"
        (click)="handleSave()"
        [disabled]="false"
        [loading]="false"
      ></button>
    </div>
  </div>
</div>
