import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  IPoslovnaJedinicaDto,
  PoslovneJediniceClient,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-poslovne-jedinice-dropdown',
  templateUrl: './poslovne-jedinice-dropdown.component.html',
  styleUrls: ['./poslovne-jedinice-dropdown.component.scss'],
})
export class PoslovneJediniceDropdownComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  options: {
    value: number;
    naziv: string;
  }[] = [];
  poslovneJedinice: IPoslovnaJedinicaDto[] = [];

  /** I/O */
  @Input() poslovnaJedinicaCtrl: FormControl;
  @Input() availableFilters: IPoslovnaJedinicaDto[];
  @Input() useAsFilter: boolean = false;
  @Input() disabled: boolean = false;
  @Output()
  onPoslovnaJedinicaChanged = new EventEmitter<IPoslovnaJedinicaDto>();

  constructor(private _client: PoslovneJediniceClient) {}

  ngOnInit(): void {
    if (this.availableFilters) {
      this.options = this.availableFilters.map((x) => ({
        value: x.id,
        naziv: x.naziv,
      }));
      this.poslovneJedinice = this.availableFilters;
      this._addEmptyFilter();
    } else {
      this._addEmptyFilter();
      this._load();
    }
  }

  private _load() {
    this._subs.add(
      this._client.getPoslovneJediniceAutocomplete().subscribe((res) => {
        if (res.data?.poslovnaJedinicaDtoList) {
          this.poslovneJedinice = res.data.poslovnaJedinicaDtoList;

          this.options = this.poslovneJedinice.map((b) => ({
            value: b.id,
            naziv: b.naziv,
          }));

          if (
            !this.useAsFilter &&
            (!this.poslovnaJedinicaCtrl?.value ||
              this.poslovnaJedinicaCtrl?.value === 0)
          ) {
            this.poslovnaJedinicaCtrl?.patchValue(this.poslovneJedinice[0].id);
          }
          this._addEmptyFilter();
        } else {
          this.options = [];
        }
      })
    );
  }

  private _addEmptyFilter() {
    if (this.useAsFilter) {
      this.options.splice(0, 0, {
        value: null,
        naziv: 'Izaberite poslovnu jedinicu',
      });
    }
  }

  handlePoslovnaJedinicaChange(event: any) {
    if (this.onPoslovnaJedinicaChanged) {
      const poslovnaJedinica = this.poslovneJedinice.find(
        (x) => x.id === event.value
      );
      this.onPoslovnaJedinicaChanged.emit(poslovnaJedinica);
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
