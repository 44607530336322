<form [formGroup]="predmetStavkeFormGroup">
  <span class="p-input-icon-left pretraga">
    <i class="fas fa-search"></i>
    <kodit-text-input
      [formCtrl]="predmetStavkeFormGroup.controls.naziv"
      ctrlPlaceholder="Šifra ili naziv..."
      (keyReleased)="onKeyReleased($event)"
    ></kodit-text-input>
  </span>
</form>
