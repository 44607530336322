import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  IOdobriOdbaciSefRacunCommand,
  OdobriOdbaciSefRacunCommand,
  SefClient,
  TipRacuna,
} from '@kodit/core/data-api';
import {
  FormHelper,
  OdobriOdbaciRacunFormService,
} from '@kodit/core/form-definitions';
import { AlertService, b64toBlob, MixpanelService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UnosBrojaOption } from '../../ui/unos-broja-option/unos-broja-option';
import { Validators } from '@angular/forms';

@Component({
  selector: 'kodit-odobravanje-odbacivanje-racuna-form',
  templateUrl: './odobravanje-odbacivanje-racuna-form.component.html',
  styleUrls: ['./odobravanje-odbacivanje-racuna-form.component.scss'],
})
export class OdobravanjeOdbacivanjeRacunaFormComponent
  implements OnInit, OnDestroy
{
  /** Form */
  form: FormGroupTypeSafe<IOdobriOdbaciSefRacunCommand>;
  /** Props */
  brojRacuna: string;
  akcija: string;
  pdfInnerHtml: SafeHtml;
  relevantniDokumentUrl: string;
  showUnosBrojaAutomatski = true;
  tipRacuna: TipRacuna;
  jeOdobravanje: boolean;
  /** Subscription */
  private _subs: Subscription = new Subscription();

  constructor(
    private _client: SefClient,
    private _alertService: AlertService,
    public _dialogRef: DynamicDialogRef,
    public _dialogConfig: DynamicDialogConfig,
    private _fs: OdobriOdbaciRacunFormService,
    private _formHelper: FormHelper,
    private _mixpanelService: MixpanelService,
    private _loader: LoaderService,
    private _domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.jeOdobravanje = this._dialogConfig.data.jeOdobravanje;

    /** init form */
    this.form = this._fs.GetFormGroup(
      this.jeOdobravanje,
      this._dialogConfig.data.sefIds
    );

    this.brojRacuna = this._dialogConfig.data?.brojRacuna;
    this.akcija = this.form.value.jeOdobravanje ? 'odobrite' : 'odbacite';
    this.tipRacuna = this._dialogConfig.data.tipRacuna;
    this._getRelevantneDokumente();

    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this._save();
    }
  }

  handleUnosBrojaOptionChanged(option: UnosBrojaOption) {
    this.showUnosBrojaAutomatski = option === UnosBrojaOption.AUTOMATSKI;

    if (option === UnosBrojaOption.MANUELNO) {
      this.form.controls.brojRacuna.setValidators([
        Validators.required,
        Validators.maxLength(128),
      ]);

      this.form.controls.praviloZaBrojRacuna.patchValue(null);
      this.form.controls.praviloZaBrojRacuna.clearValidators();
    } else {
      this.form.controls.praviloZaBrojRacuna.setValidators(
        Validators.nullValidator
      );

      this.form.controls.brojRacuna.patchValue(null);
      this.form.controls.brojRacuna.clearValidators();
    }
    this.form.controls.praviloZaBrojRacuna.updateValueAndValidity();
    this.form.controls.brojRacuna.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  private _save() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._loader.setLoadingText = this.jeOdobravanje
      ? 'Prihvatanje računa...'
      : 'Odbacivanje računa...';
    this._loader.setShowLoader = true;

    this._subs.add(
      this._client
        .odobriOdbaciRacun(this.form.value as OdobriOdbaciSefRacunCommand)
        .pipe(
          finalize(() => {
            this._loader.reset();
          })
        )
        .subscribe((res) => {
          if (!res.succeeded) {
            this._alertService.addWarnMsgs(res.messages);
          } else {
            this._alertService.addSuccessMsg(res.data);
            this._sendMixpanelReport(this.form.value.jeOdobravanje);
          }
          this._dialogRef.close(true);
        })
    );
  }

  private _getRelevantneDokumente() {
    this._subs.add(
      this._client
        .getSefDokumentUrl(this._dialogConfig.data.sefIds[0])
        .subscribe((res) => {
          if(res.succeeded){
            this._setPdfUrl(res.data);
          }
        })
    );
  }

  private _setPdfUrl(url: string) {
    this.pdfInnerHtml = this._domSanitizer.bypassSecurityTrustHtml(
      `<object data="${url}" type="application/pdf" class="embed-responsive-item">
     Object ${url} failed
   </object>`
    );
  }

  private _sendMixpanelReport(value: boolean) {
    value
      ? this._mixpanelService.ulaznaFakturaApproval()
      : this._mixpanelService.ulaznaFakturaRejection();
  }
}
