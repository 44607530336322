import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  IGetFormConfigurationResponse,
  IIzlaznaFakturaDto,
  TipEntiteta,
  TipRacuna,
  VrstaFakture,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { BaseRacunFormService } from './_base_racun-form.service';
import { InformacijaIsporukeFormService } from './informacija-isporuke-form.service';
import { stringIsNullOrEmpty } from '@kodit/core/shared';
import {VoziloFormService} from "../predmet-stavke/vozilo-form.service";
import {AvansnaUplataRacunaFormService} from "./avansna-uplata-racuna-form.service";

@Injectable({
  providedIn: 'root',
})
export class IzlaznaFakturaFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _baseRacunForm: BaseRacunFormService,
    private _informacijaIsporukeForm: InformacijaIsporukeFormService,
    private _voziloForm : VoziloFormService,
    private _avansnaUplataFS : AvansnaUplataRacunaFormService
  ) {}

  /**
   * Konvertuje model i od njega pravi reaktivnu formu (form grupu) za domace fakture
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   * @param model model domace fakture
   * @returns Reaktivnu formu kao FormGroup za fakturu
   */
  public GetIzlaznaFakturaFormGroup(
    formConfiguration: IGetFormConfigurationResponse,
    model?: IIzlaznaFakturaDto
  ): FormGroupTypeSafe<IIzlaznaFakturaDto> {
    const baseRacunForm = this._baseRacunForm.GetBaseRacunFormGroup(
      TipRacuna.IZLAZNA_FAKTURA,
      VrstaRacuna.IZLAZNI,
      TipEntiteta.IZLAZNA_FAKTURA,
      formConfiguration,
      model?.racunDto
    );
    return this._fb.group<IIzlaznaFakturaDto>(
      {
        racunDto: baseRacunForm,
        brojFiskalnogIsecka: new FormControl(model?.brojFiskalnogIsecka, {
          validators: [Validators.maxLength(21)],
        }),
        informacijaIsporuke: this._informacijaIsporukeForm.GetInformacijaIsporukeFormGroup(
          model?.informacijaIsporuke
        ),
        vozila : this._voziloForm.GetVozilaDropdownFormArray(model?.vozila),
        vrstaFakture: new FormControl(
          model?.vrstaFakture ?? VrstaFakture.DOMACA
        ),
        avansnaUplata : this._avansnaUplataFS.GetAvansneUplateRacunaFormGroup(model?.avansnaUplata)
      },
      {
        validators: this._dodatniPodaciValidator,
      }
    );
  }

  private _dodatniPodaciValidator: ValidatorFn = (
    group: FormGroup
  ): ValidationErrors | null => {
    const narudzbenica = group.value.racunDto.brojNarudzbenice;
    const ugovor = group.value.racunDto.brojUgovora;
    const tender = group.value.racunDto.brojTendera;

    if (!stringIsNullOrEmpty(group.value.racunDto.strankaRacunaDto.jbkjs)) {
      return narudzbenica || ugovor || tender ? null : { dodatniPodaci: true };
    }

    return null;
  };
}
