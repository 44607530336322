<div class="p-mb-5">
  <kodit-card-container [showDivider]="false">
    <div card-actions *ngIf="tipIzvestaja === dnevniIzvestaj">
      <button
        icon="far fa-print"
        pButton
        type="button"
        label="Štampaj"
        class="p-button p-button-outlined margin-right"
        [disabled]="isButtonDisabled"
        (click)="stampajDnevniIzvestaj(true, false)"
      ></button>
      <button
        icon="far fa-check"
        pButton
        type="button"
        label="Završi dan"
        class="p-button"
        [disabled]="isButtonDisabled"
        (click)="stampajDnevniIzvestaj(true, true)"
      ></button>
    </div>
    <div card-actions *ngIf="tipIzvestaja === periodicniIzvestaj">
      <form [formGroup]="periodicForm">
        <div class="p-d-flex p-flex-row">
          <div class="p-mr-2">
            <label for="start-date" kodit-form-label>Početni datum</label>
            <kodit-calendar
              class="p-fluid"
              formCtrlId="startDate"
              [formCtrl]="periodicForm.controls.startDate!"
            ></kodit-calendar>
          </div>
          <div class="p-mr-2">
            <label for="end-date" kodit-form-label>Krajnji datum</label>
            <kodit-calendar
              class="p-fluid"
              formCtrlId="endDate"
              [formCtrl]="periodicForm.controls.endDate!"
            ></kodit-calendar>
          </div>
          <div class="p-mt-2 padding-top">
            <button
              icon="fa-regular fa-magnifying-glass"
              pButton
              type="button"
              label="Pregledaj"
              class="p-button margin-right p-mt-2"
              [disabled]="isButtonDisabled"
              (click)="stampajPeriodicniIzvestaj(false)"
            ></button>
            <button
              icon="far fa-print"
              pButton
              type="button"
              label="Štampaj"
              class="p-button p-button-outlined"
              [disabled]="isButtonDisabled"
              (click)="stampajPeriodicniIzvestaj(true)"
            ></button>
          </div>
        </div>
      </form>
    </div>
  </kodit-card-container>
</div>
<div class="p-mb-5">
  <kodit-card-container *ngIf="fiskalnaKasaReport">
    <div card-title>
      {{
        tipIzvestaja === dnevniIzvestaj
          ? 'Dnevni izveštaj'
          : 'Periodični izveštaj'
      }}
    </div>

    <div class="p-d-flex p-flex-column p-flex-md-row gap-25" card-body>
      <div class="p-d-flex p-flex-column p-jc-between">
        <kodit-card-body-text
          label="Naziv poreskog obveznika"
          text="{{ fiskalnaKasaReport.businessName }}"
        ></kodit-card-body-text>

        <kodit-card-body-text
          label="JID"
          text="{{ fiskalnaKasaReport.uid }}"
        ></kodit-card-body-text>

        <kodit-card-body-text
          label="PFR vreme"
          text="{{ fiskalnaKasaReport.dateTime | date }}"
        ></kodit-card-body-text>

        <kodit-card-body-text
          label="PERIOD"
          text="{{ tipIzvestaja === periodicniIzvestaj ? period : fiskalnaKasaReport.dateTime | date}}"
        ></kodit-card-body-text>
      </div>
    </div>
  </kodit-card-container>
</div>
<div class="p-mb-5">
  <kodit-card-container *ngIf="fiskalnaKasaReport">
    <div card-title>Ukupan promet</div>

    <div class="p-grid" card-body>
      <div class="p-col-4">
        <div class="p-d-flex p-flex-column">
          <div class="uplaceno-ukupno-text-container">
            <div class="uplaceno-ukupno-text-left" style="font-weight: 600">
              Uplaćeno
            </div>
          </div>
          <div
            class="uplaceno-ukupno-text-container"
            *ngFor="let item of fiskalnaKasaReport.total?.payments"
          >
            <div class="uplaceno-ukupno-text-left">
              {{ vrstaPaymentStr.get(item.paymentType ?? 0)}}
            </div>
            <div class="uplaceno-ukupno-text-right">
              {{ item.amount ?? 0  | decimalNumber}}
            </div>
          </div>
          <div class="uplaceno-ukupno-text-container">
            <div class="uplaceno-ukupno-text-left" style="font-weight: 600">
              Ukupno uplaćeno
            </div>
            <div class="uplaceno-ukupno-text-right">
              {{
                fiskalnaKasaReport.total?.payments!
                  | sumPayments
                  | decimalNumber
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-8">
        <div class="uplaceno-ukupno-text-container">
          <div class="uplaceno-ukupno-text-left" style="font-weight: 600">
            Stope poreza
          </div>
        </div>
        <table width="100%" style="margin-left: 10px">
          <thead>
            <tr>
              <th align="left">Oznaka</th>
              <th align="left">Stopa</th>
              <th align="left">Ukupno</th>
              <th align="left">Porez</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of fiskalnaKasaReport.total?.taxItems">
              <td>{{ item.label }}</td>
              <td>{{ item.rate }}%</td>
              <td>{{ item.total ?? 0 | decimalNumber }}</td>
              <td>{{ item.amount ?? 0 | decimalNumber }}</td>
            </tr>
            <tr>
              <td style="font-weight: 600">Ukupan iznos poreza</td>
              <td></td>
              <td></td>
              <td style="font-weight: 600">
                {{
                  fiskalnaKasaReport.total?.taxItems!
                    | sumTaxItems
                    | decimalNumber
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </kodit-card-container>
</div>

<div class="p-mb-5">
  <kodit-card-container *ngIf="fiskalnaKasaReport">
    <div card-title>Promet po vrsti transakcije</div>

    <div
      class="p-grid"
      card-body
      *ngFor="let item of fiskalnaKasaReport?.perTransactionType"
    >
      <div class="p-col-12" style="font-weight: 600">
        {{ vrstaFiskalnogStr.get(item.invoiceType ?? 0) | uppercase
        }}-{{vrstaTransakcijeStr.get(item.transactionType ?? 0) | uppercase}}
      </div>
      <div class="p-col-4">
        <div class="p-d-flex p-flex-column">
          <div class="uplaceno-ukupno-text-container">
            <div class="uplaceno-ukupno-text-left" style="font-weight: 600">
              Uplaćeno
            </div>
          </div>
          <div
            class="uplaceno-ukupno-text-container"
            *ngFor="let payment of item?.payments"
          >
            <div class="uplaceno-ukupno-text-left">
              {{ vrstaPaymentStr.get(payment.paymentType ?? 0)}}
            </div>
            <div class="uplaceno-ukupno-text-right">
              {{ payment.amount ?? 0  | decimalNumber}}
            </div>
          </div>
          <div class="uplaceno-ukupno-text-container">
            <div class="uplaceno-ukupno-text-left" style="font-weight: 600">
              Ukupno uplaćeno
            </div>
            <div class="uplaceno-ukupno-text-right">
              {{ item.payments! | sumPayments | decimalNumber }}
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-8">
        <div class="uplaceno-ukupno-text-container">
          <div class="uplaceno-ukupno-text-left" style="font-weight: 600">
            Stope poreza
          </div>
        </div>
        <table width="100%" style="margin-left: 10px">
          <thead>
            <tr>
              <th align="left">Oznaka</th>
              <th align="left">Stopa</th>
              <th align="left">Ukupno</th>
              <th align="left">Porez</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tax of item.taxItems">
              <td>{{ tax.label }}</td>
              <td>{{ tax.rate }}%</td>
              <td>{{ tax.total ?? 0 | decimalNumber }}</td>
              <td>{{ tax.amount ?? 0 | decimalNumber }}</td>
            </tr>
            <tr>
              <td style="font-weight: 600">Ukupan iznos poreza</td>
              <td></td>
              <td></td>
              <td style="font-weight: 600">
                {{ item.taxItems! | sumTaxItems | decimalNumber }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </kodit-card-container>
</div>
