import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CenovniciClient, GetCenovniciTableQuery } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-cenovnik-dropdown',
  templateUrl: './cenovnik-dropdown.component.html',
  styleUrls: ['./cenovnik-dropdown.component.scss'],
})
export class CenovnikDropdownComponent implements OnInit, OnDestroy {
  /** Subs */
  private _getSub: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];

  /** Inputs */
  // @Input() cenovnikCtrl: AbstractControl;

  @Input() strankaId: number;

  @Output() onItemChange = new EventEmitter<any>();

  constructor(private _client: CenovniciClient) {}

  ngOnInit(): void {
    this._getSub = this._client
      .getForTable(new GetCenovniciTableQuery({ strankaId: this.strankaId }))
      .subscribe((result) => {
        this.items = result.data.cenovniciTable.map((x) => ({
          value: x.id,
          label: x.naziv,
          title: x.naziv,
        }));
      });
  }

  handleOnItemChange(event: any) {
    this.onItemChange.emit(event.value);
  }

  ngOnDestroy(): void {
    this._getSub.unsubscribe();
  }
}
