<div [formGroup]="form">
  <p-autoComplete
    [inputId]="formCtrlId"
    formControlName="searchValue"
    field="referentDocumentNumber"
    #vezaniSearch
    appendTo="body"
    emptyMessage="Fiskalni račun nije pronađen"
    [suggestions]="referentniRacunSuggestions"
    [placeholder]="ctrlPlaceholder"
    (completeMethod)="handleSearch($event)"
    (onSelect)="handleSelect($event)"
    (onClear)="handleClear()"
  >
    <ng-template let-item pTemplate="item">
      <div class="item">
        <div class="p-text-bold">
          {{ item.referentDocumentNumber }}
        </div>
      </div>
    </ng-template>
  </p-autoComplete>
  <kodit-error-info-required
    [formCtrl]="form.controls.searchValue"
  ></kodit-error-info-required>
</div>
