import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PdvStopaService {
  constructor() {}

  /**
   *
   */
  jeKompanijaUPdv = new BehaviorSubject<boolean>(true);

  get getJeKompanijaUPdv() {
    return this.jeKompanijaUPdv.asObservable();
  }

  set setJeKompanijaUPdv(data: boolean) {
    this.jeKompanijaUPdv.next(data);
  }
}
