<div
  class="p-d-flex p-flex-column p-jc-center p-ai-center"
>
  <div class="p-d-flex p-flex-column p-jc-center">
    <div class="step-subtitle">Korak 3</div>
    <div class="step-title">Podaci korisnika</div>
  </div>
  <div class="p-d-flex p-flex-column unos-wrapper">
    <div class="unos-title">Unesite vaše podatke</div>
    <div class="p-d-flex p-flex-row">
      <div class="p-d-flex flex-1 p-pr-3">
        <div class="p-field flex-1">
          <label kodit-form-label>Email</label>
          <div class="p-field flex-1">
            <span class="p-d-flex p-flex-row p-ai-center p-input-icon-left">
                <i class="far fa-envelope"></i>
                <input
                  *ngIf="!shouldDisableInput"
                  type="text"
                   class="flex-1"
                   pInputText
                   (blur)="checkIsExistingUser()"
                   [formControl]="form.controls.podaciKorisnikaDto.get('email') | formControl"/>
                <input
                  *ngIf="shouldDisableInput"
                  type="text"
                  class="flex-1"
                  pInputText
                  disabled
                  (blur)="checkIsExistingUser()"
                  [formControl]="form.controls.podaciKorisnikaDto.get('email') | formControl"/>
            </span>
          </div>
          <kodit-error-info-max-length
            [formCtrl]="form.controls.podaciKorisnikaDto.get('email')"
          ></kodit-error-info-max-length>
          <kodit-error-info-min-length
            [formCtrl]="form.controls.podaciKorisnikaDto.get('email')"
          ></kodit-error-info-min-length>
          <kodit-error-info-required
            [formCtrl]="form.controls.podaciKorisnikaDto.get('email')"
          ></kodit-error-info-required>
          <small
            *ngIf="
                form.controls.podaciKorisnikaDto?.get('email') &&
                form.controls.podaciKorisnikaDto?.get('email').touched &&
                form.controls.podaciKorisnikaDto?.get('email').value?.length == 0
              "
            id="password-check-help2"
            class="p-invalid"
          >E-Mail nije validan.</small
          >
        </div>
      </div>
      <div class="p-d-flex flex-1 p-pl-3">
        <div class="p-field flex-1">
          <label kodit-form-label>Broj telefona</label>
          <div class="p-field flex-1">
            <span class="p-d-flex p-flex-row p-ai-center p-input-icon-left">
                <div class="pozivni-broj">+381</div>
                <input
                  *ngIf="!shouldDisableInput"
                   pKeyFilter="pint"
                   type="text"
                   class="broj-telefona flex-1"
                   pInputText
                   [formControl]="form.controls.podaciKorisnikaDto.get('mobilniDto.brojTelefona') | formControl"/>
              <input
                  *ngIf="shouldDisableInput"
                   pKeyFilter="pint"
                   type="text"
                   class="broj-telefona flex-1"
                   pInputText
                   disabled
                   [formControl]="form.controls.podaciKorisnikaDto.get('mobilniDto.brojTelefona') | formControl"/>
            </span>
          </div>
          <kodit-error-info-required
            [formCtrl]="form.controls.podaciKorisnikaDto.get('mobilniDto.brojTelefona')"
          ></kodit-error-info-required>
          <small
            *ngIf="
              form.controls.podaciKorisnikaDto.get('mobilniDto.brojTelefona').errors?.pattern &&
              form.controls.podaciKorisnikaDto.get('mobilniDto.brojTelefona').touched
            "
            class="p-invalid"
          >Mobilni telefon nije u validnom formatu.</small
          >
        </div>
      </div>
    </div>
    <div class="p-d-flex p-flex-row">
      <div class="p-d-flex flex-1 p-pr-3 p-mt-4">
        <div class="p-field flex-1">
          <label kodit-form-label>Ime</label>
          <div class="p-field flex-1">
            <span class="p-d-flex p-flex-row p-ai-center p-input-icon-left">
                <i class="far fa-user"></i>
                <input type="text"
                  *ngIf="!shouldDisableInput"
                   class="flex-1"
                   pInputText
                   [formControl]="form.controls.podaciKorisnikaDto.get('ime') | formControl"/>
              <input type="text"
                  *ngIf="shouldDisableInput"
                   class="flex-1"
                   pInputText
                   disabled
                   [formControl]="form.controls.podaciKorisnikaDto.get('ime') | formControl"/>
            </span>
          </div>
          <kodit-error-info-required
            [formCtrl]="form.controls.podaciKorisnikaDto.get('ime')"
          ></kodit-error-info-required>
        </div>
      </div>
      <div class="p-d-flex flex-1 p-pl-3 p-mt-4">
        <div class="p-field flex-1">
          <label kodit-form-label>Prezime</label>
          <div class="p-field flex-1">
            <span class="p-d-flex p-flex-row p-ai-center p-input-icon-left">
                <i class="far fa-user"></i>
                <input type="text"
                  *ngIf="!shouldDisableInput"
                   class="flex-1"
                   pInputText
                   [disabled]="shouldDisableInput"
                   [formControl]="form.controls.podaciKorisnikaDto.get('prezime') | formControl"/>
              <input type="text"
                  *ngIf="shouldDisableInput"
                   class="flex-1"
                   pInputText
                   disabled
                   [disabled]="shouldDisableInput"
                   [formControl]="form.controls.podaciKorisnikaDto.get('prezime') | formControl"/>
            </span>
          </div>
          <kodit-error-info-required
            [formCtrl]="form.controls.podaciKorisnikaDto.get('prezime')"
          ></kodit-error-info-required>
        </div>
      </div>
    </div>
    <div class="p-d-flex p-flex-row">
      <div class="p-d-flex flex-1 p-pr-3 p-mt-4">
        <div class="p-field flex-1">
          <label kodit-form-label>Šifra</label>
          <div class="p-field flex-1">
            <span class="p-d-flex p-flex-row p-ai-center p-input-icon-left">
              <i class="far fa-lock"></i>
               <input
                 *ngIf="!shouldDisableInput"
                 class="flex-1"
                 pInputText
                 id="password"
                 type="password"
                 [formControl]="form.controls.podaciKorisnikaDto.get('sifra') | formControl"
                 autocomplete="off"
               />
              <input
                 *ngIf="shouldDisableInput"
                 class="flex-1"
                 pInputText
                 disabled
                 id="password"
                 type="password"
                 [formControl]="form.controls.podaciKorisnikaDto.get('sifra') | formControl"
                 autocomplete="off"
               />
            </span>
            <kodit-error-info-max-length
              [formCtrl]="form.controls.podaciKorisnikaDto.get('sifra')"
            ></kodit-error-info-max-length>
            <kodit-error-info-min-length
              [formCtrl]="form.controls.podaciKorisnikaDto.get('sifra')"
            ></kodit-error-info-min-length>
            <kodit-error-info-required
              [formCtrl]="form.controls.podaciKorisnikaDto.get('sifra')"
            ></kodit-error-info-required>
          </div>
        </div>
      </div>
      <div class="p-d-flex flex-1 p-pl-3 p-mt-4">
        <div class="p-field flex-1">
          <label kodit-form-label>Potvrdite šifru</label>
          <div class="p-field flex-1">
            <span class="p-d-flex p-flex-row p-ai-center p-input-icon-left">
              <i class="far fa-lock"></i>
              <input
                *ngIf="!shouldDisableInput"
                class="flex-1"
                pInputText
                id="password-repeat"
                type="password"
                [formControl]="form.controls.podaciKorisnikaDto.get('sifraRepeat') | formControl"
                autocomplete="off"
              />
              <input
                *ngIf="shouldDisableInput"
                class="flex-1"
                pInputText
                disabled
                id="password-repeat"
                type="password"
                [formControl]="form.controls.podaciKorisnikaDto.get('sifraRepeat') | formControl"
                autocomplete="off"
              />
            </span>
            <kodit-error-info-max-length
              [formCtrl]="form.controls.podaciKorisnikaDto.get('sifraRepeat')"
            ></kodit-error-info-max-length>
            <kodit-error-info-min-length
              [formCtrl]="form.controls.podaciKorisnikaDto.get('sifraRepeat')"
            ></kodit-error-info-min-length>
            <kodit-error-info-required
              [formCtrl]="form.controls.podaciKorisnikaDto.get('sifraRepeat')"
            ></kodit-error-info-required>
            <small
              *ngIf="
                  form.controls.podaciKorisnikaDto.errors?.passwordMatchValidator &&
                  form.controls.podaciKorisnikaDto.get('sifraRepeat').touched &&
                  form.controls.podaciKorisnikaDto.get('sifraRepeat').value.length > 0
                "
              id="password-check-help3"
              class="p-invalid"
            >Lozinke se ne podudaraju.</small
            >
          </div>
        </div>
      </div>
    </div>
    <div class="p-d-flex p-flex-row p-jc-between">
      <div class="nazad-button p-ai-center" (click)="routeToPreviousStep()">Nazad</div>
      <div class="dalje-button p-ai-center" (click)="routeToNextStep()">Dalje</div>
    </div>
  </div>
</div>
