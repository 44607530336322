import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MojaFirmaClient } from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { FileUpload } from 'primeng/fileupload';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-logo-upload',
  templateUrl: './logo-upload.component.html',
  styleUrls: ['./logo-upload.component.scss'],
})
export class LogoUploadComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  /** Props */
  @ViewChild('editLogoRef') editLogoRef: FileUpload;
  uploadCompleted: boolean;
  logoImage: string;
  triggerEdit: boolean = false;

  constructor(
    private _alertService: AlertService,
    private _client: MojaFirmaClient
  ) {}

  ngOnInit(): void {
    this._getLogo();
  }

  onEditLogo(event: any) {
    if (event.files[0]) {
      this.onUpload(event.files[0]);
      this.editLogoRef.clear();
    }
  }

  onUpload(file: File) {
    this.uploadCompleted = false;
    this._subs.add(
      this._client
        .uploadLogo({ data: file, fileName: file.name })
        .subscribe((res) => {
          this.uploadCompleted = true;
          this._alertService.addSuccessMsg(res.messages[0]);
          this._getLogo();
        })
    );
  }

  private _getLogo() {
    this._subs.add(
      this._client.getLogo().subscribe((res) => {
        this.logoImage = res.data;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
