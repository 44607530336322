import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import {
  GetKontaktiOdStrankaResponse,
  IMailReceiverDto,
  KontaktiClient,
} from '@kodit/core/data-api';
import { MailFormService } from '@kodit/core/form-definitions';
import { isEmailValid } from 'libs/core/shared/src/lib/common-functions';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

export interface EmailReceiver {
  model: IMailReceiverDto;
  type: ReceiverType;
}

export enum ReceiverType {
  TO,
  CC,
}

@Component({
  selector: 'kodit-email-receivers-form',
  templateUrl: './email-receivers-form.component.html',
  styleUrls: ['./email-receivers-form.component.scss'],
})
export class EmailReceiversFormComponent implements OnInit, OnDestroy {
  [x: string]: any;

  /** */
  typeCC = ReceiverType.CC;
  receiversSuggestions: IMailReceiverDto[] = [];
  allReceivers: IMailReceiverDto[] = [];
  kontaktiSub = new Subscription();
  daNeOptions: SelectItem[] = [];


  /** I/O */
  @Input() toFormArray: FormArray;
  @Input() ccFormArray: FormArray;
  @Input() naslovEmail: FormControl;
  @Input() customPoruka: FormControl;
  @Input() attachmentsFormArray: FormArray;
  @Input() ctrlFormGroup: FormGroup;
  @Input() bodyCtrl: AbstractControl;
  @Input() strankaId: number;
  @Input() showNew: boolean = true;

  /** Props */
  uploadCompleted: boolean;

  constructor(
    private _mailFS: MailFormService,
    private _client: KontaktiClient
  ) {}

  ngOnInit(): void {
    this.loadKontaktiForStranka();
    (this.toFormArray as FormArray).controls.forEach((c) => {
      this.allReceivers.push(c.value);
    });
    if(this.ccFormArray != null) {
      (this.ccFormArray as FormArray).controls.forEach((c) => {
        this.allReceivers.push(c.value);
      });
    }
    this.daNeOptions = [
      { label: 'Da', value: true },
      { label: 'Ne', value: false },
    ];
  }

  loadKontaktiForStranka() {
    this.kontaktiSub.add(
      this._client
        .getKontaktiByStrankaId(this.strankaId)
        .pipe(map((x) => x.data))
        .subscribe((res) => {
          this.receiversSuggestions = res.kontakti;
          this.allReceivers = res.kontakti;
          res.kontakti.forEach((r) => {
            this.toFormArray.push(
              this._mailFS.GetMailReceiverFormGroup({
                email: r.email,
                name: r.name,
                isNew: r.isNew,
              })
            );
          });
        })
    );
  }

  handleUpload(files: File[]) {
    files.forEach((file) => {
      this.attachmentsFormArray.push(new FormControl(file));
    });
  }

  handleSearch(searchValue: string, type: ReceiverType = ReceiverType.TO) {
    this.receiversSuggestions = this.allReceivers.filter(
      (receiver) =>
        ((type === ReceiverType.TO &&
          !this.toFormArray.value.some(
            (x: IMailReceiverDto) => x.email == receiver.email
          )) ||
          (type === ReceiverType.CC &&
            !this.ccFormArray.value.some(
              (x: IMailReceiverDto) => x.email == receiver.email
            ))) &&
        (receiver.name.toLowerCase().startsWith(searchValue.toLowerCase()) ||
          receiver.email.toLowerCase().startsWith(searchValue.toLowerCase()))
    );
  }

  handleSelect(model: IMailReceiverDto, type: ReceiverType = ReceiverType.TO) {
    if (
      type === ReceiverType.TO &&
      !this.toFormArray.value.find(
        (x: IMailReceiverDto) => x.email === model.email
      )
    ) {
      (this.toFormArray as FormArray).push(
        this._mailFS.GetMailReceiverFormGroup(model)
      );
    } else if (
      type === ReceiverType.CC &&
      !this.ccFormArray.value.find(
        (x: IMailReceiverDto) => x.email === model.email
      )
    ) {
      (this.ccFormArray as FormArray).push(
        this._mailFS.GetMailReceiverFormGroup(model, false)
      );
    }
  }

  handleUnselect(
    model: IMailReceiverDto,
    type: ReceiverType = ReceiverType.TO
  ) {
    if (type === ReceiverType.TO) {
      const idx = this.toFormArray.value.findIndex(
        (x: IMailReceiverDto) => x.email === model.email
      );
      this.toFormArray.removeAt(idx);
      this.toFormArray.updateValueAndValidity();
    } else {
      const idx = this.ccFormArray.value.findIndex(
        (x: IMailReceiverDto) => x.email === model.email
      );
      this.ccFormArray.removeAt(idx);
      this.ccFormArray.updateValueAndValidity();
    }
  }

  handleEnter(searchValue: string, type: ReceiverType = ReceiverType.TO) {
    if (!isEmailValid(searchValue)) {
      return;
    }

    if (
      type === ReceiverType.TO &&
      !this.toFormArray.value.some(
        (x: IMailReceiverDto) => x.email === searchValue
      )
    ) {
      (this.toFormArray as FormArray).push(
        this._mailFS.GetMailReceiverFormGroup({
          email: searchValue,
          name: searchValue,
          isNew: !this.allReceivers.some((x) => x.email === searchValue),
        })
      );
      this.toFormArray.updateValueAndValidity();
    } else if (
      type === ReceiverType.CC &&
      !this.ccFormArray.value.some(
        (x: IMailReceiverDto) => x.email === searchValue
      )
    ) {
      (this.ccFormArray as FormArray).push(
        this._mailFS.GetMailReceiverFormGroup({
          email: searchValue,
          name: searchValue,
          isNew: !this.allReceivers.some((x) => x.email === searchValue),
        })
      );
      this.ccFormArray.updateValueAndValidity();
    }
  }
  ngOnDestroy(): void {
    this.kontaktiSub.unsubscribe();
  }
}
