import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @Input() labelText: string;
  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string = 'checkbox';

  constructor() {}

  ngOnInit(): void {}
}
