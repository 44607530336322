import { FormControl } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IPrikazRabataDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrikazRabataFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetPrikazRabataFormGroup(
    model?: IPrikazRabataDto
  ): FormGroupTypeSafe<IPrikazRabataDto> {
    return this._fb.group<IPrikazRabataDto>({
      opis: new FormControl(model?.opis),
      prikazRabata: new FormControl(model?.prikazRabata),
      prikazRabataStr: new FormControl(model?.prikazRabataStr),
    });
  }
}
