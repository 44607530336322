import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import {
  ArhivskaKnjigaClient,
  ArhivskaPodesavanjaResponse,
  UpdateArhivskaPodesavanjaCommand,
} from '@kodit/core/data-api';
import { AlertService, LocationService } from '@kodit/core/services';
import { ArhivskaKnjigaService } from 'libs/core/moduli/podesavanje/src/lib/arhivska-knjiga/arhivska-knjiga-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-arhivska-exists-page',
  templateUrl: './arhivska-exists-page.component.html',
  styleUrls: ['./arhivska-exists-page.component.scss'],
})
export class ArhivskaExistsPageComponent implements OnInit, OnDestroy {
  /**Subs */
  private _sub: Subscription = new Subscription();

  /** Props */
  sledeciKorakEnabled: boolean = false;
  potvrdiZavrsiEnabled: boolean = false;
  potvrdiZavrsiVisible: boolean = false;
  step01Visible: boolean | undefined = false;
  step02Visible: boolean | undefined = false;
  step03Visible: boolean | undefined = false;
  stepsArray: any[] = [];

  constructor(
    private _client: ArhivskaKnjigaClient,
    private _arhivskaService: ArhivskaKnjigaService,
    private _alertService: AlertService,
    private elem: ElementRef,
    private _locationService: LocationService
  ) {}

  ngOnInit(): void {
    this._setPageVisibility();
    this._sub.add(
      this._arhivskaService.getSledeciKorakEnabled.subscribe((res) => {
        this.sledeciKorakEnabled = res;
      })
    );
    this._sub.add(
      this._arhivskaService.getPotvrdiIZavrsiEnabled.subscribe((res) => {
        this.potvrdiZavrsiEnabled = res;
      })
    );
    this.stepsArray = Array.from(
      this.elem.nativeElement.querySelectorAll('.step-card')
    );
  }

  private _setPageVisibility() {
    this._sub.add(
      this._client.getArhivskaPodesavanja().subscribe((res) => {
        if (
          !res.data?.step01Completed &&
          !res.data?.step02Completed &&
          !res.data?.step03Completed
        ) {
          this.step01Visible = true;
        } else {
          this._setCurrentPage(res.data);
        }
      })
    );
  }

  private _setCurrentPage(data: ArhivskaPodesavanjaResponse) {
    if (data.step01Completed && !data.step02Completed) {
      this.step01Visible = false;
      this.step02Visible = true;
      this.step01CompletedClassHandle();
    }
    if (data.step02Completed && !data.step03Completed) {
      this.step02Visible = false;
      this.step03Visible = true;
      this.potvrdiZavrsiVisible = true;
      this.step02CompletedClassHandle();
    }
  }

  goToSledeciKorak() {
    this._sub.add(
      this._client
        .updateArhivskaPodesavanja(
          new UpdateArhivskaPodesavanjaCommand({
            step01: this._arhivskaService.getStep01CompletedValue(),
            step02: this._arhivskaService.getStep02CompletedValue(),
            step03: this._arhivskaService.getStep03CompletedValue(),
            kategorijaAutomatskiCreated: this._arhivskaService.getKategorijeImportovaneAutomatskiValue(),
          })
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.messages![0]);
          location.reload();
        })
    );
  }

  private step01CompletedClassHandle() {
    this.stepsArray[0].classList.replace(
      'step-card__active',
      'step-card__completed'
    );
    this.stepsArray[1].classList.replace(
      'step-card__inactive',
      'step-card__active'
    );
  }

  private step02CompletedClassHandle() {
    this.step01CompletedClassHandle();

    this.stepsArray[1].classList.replace(
      'step-card__active',
      'step-card__completed'
    );

    this.stepsArray[2].classList.replace(
      'step-card__inactive',
      'step-card__active'
    );
  }

  handlePotvrdiIZavrsi() {
    this._sub.add(
      this._client
        .updateArhivskaPodesavanja(
          new UpdateArhivskaPodesavanjaCommand({
            arhivskaExisted: false,
          })
        )
        .subscribe((res) => {
          location.reload();
        })
    );

    // location.reload();
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
