<kodit-info-container>
  <div body-full>
    <div class="kodit-card-tab">
      <kodit-opsta-pravila></kodit-opsta-pravila>
    </div>

    <div class="p-mt-3 kodit-card-tab">
      <kodit-konfiguracija-deviznog-racuna></kodit-konfiguracija-deviznog-racuna>
    </div>

    <div class="p-mt-3 kodit-card-tab">
      <p-tabView>
        <p-tabPanel header="Memorandumi">
          <div class="p-mt-3">
            <kodit-memorandum-table></kodit-memorandum-table>
            <!-- <kodit-magacin-tip-table></kodit-magacin-tip-table> -->
          </div>
        </p-tabPanel>
        <p-tabPanel header="Pravila brojeva računa">
          <div class="p-mt-3">
            <kodit-pravila-za-broj></kodit-pravila-za-broj>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
    
  </div>

  <!-- <div class="p-d-flex p-flex-column p-jc-between gap-12" body-1-of-3>
          <div>
            <kodit-pdf-prikaz></kodit-pdf-prikaz>
          </div>
          <div>
            <kodit-opomene></kodit-opomene>
          </div>
        </div> -->
</kodit-info-container>

<!-- 
<kodit-info-container>
  <div body-full>
    <div class="p-mt-5 kodit-card-tab">
      <p-tabView>
        <p-tabPanel header="Pravila za generisanje broja računa">
          <div class="p-mt-4">
            <kodit-jedinica-mere-table></kodit-jedinica-mere-table>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Memorandumi">
          <div class="p-mt-4">
            <kodit-magacin-tip-table></kodit-magacin-tip-table>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</kodit-info-container> -->
