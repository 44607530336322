import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

Injectable({
  providedIn: 'root',
});
/** @deprecated */
export abstract class BaseService {
  constructor() {}

  protected handleError(errorObject: any) {
    // if (errorObject instanceof Observable) {
    //   return errorObject.subscribe((errorMessage) => {
    //     return throwError(errorMessage);
    //   });
    // }
    // zato sto ga handle u token.interceptor
    // i vec dobijemo poruku o gresci kao string
    if (typeof errorObject === 'string' || errorObject instanceof String) {
      return throwError(errorObject);
    }

    var applicationError = errorObject.headers.get('Application-Error');

    if (errorObject.statusText === 'Unknown Error') {
      return throwError('Proverite konekciju sa serverom.');
    }

    // by status
    switch (errorObject.status) {
      case 400:
        // errorObject from identity server has error as array of code and description
        if (Array.isArray(errorObject.error)) {
          let message = '';
          errorObject.error.forEach((e: any) => {
            message += e.description + '\n';
          });
          return throwError(message);
        }
        if (errorObject.error && errorObject.error.errors) {
          let errorObj: any;
          let modelStateErrors = '';
          for (errorObj in errorObject.error.errors) {
            for (var key in errorObject.error.errors[errorObj]) {
              modelStateErrors +=
                errorObject.error.errors[errorObj][key] + '\n';
            }
          }
          modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
          return throwError(modelStateErrors || 'Server error');
        }
        return throwError(errorObject.error);
      case 403:
      case 401:
        return throwError('Nemate dozvolu da izvršite akciju.');
      case 404:
        return throwError('Traženi resurs nije pronađen.');
    }

    // either application-error in header or model error in body
    if (applicationError) {
      return throwError(applicationError);
    }

    var modelStateErrors: string = '';

    if (errorObject.error.errors) {
      let errorObj: any;
      for (errorObj in errorObject.error.errors) {
        for (var key in errorObject.error.errors[errorObj]) {
          modelStateErrors += errorObject.error.errors[errorObj][key] + '\n';
        }
      }
      modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
      return throwError(modelStateErrors || 'Server error');
    }

    if (errorObject.error.title) {
      return throwError(errorObject.error.title);
    }

    // for now just concatenate the error descriptions, alternative we could simply pass the entire error response upstream
    for (var key in errorObject.error) {
      if (errorObject.error[key])
        modelStateErrors += errorObject.error[key].description + '\n';
    }

    modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;

    return throwError(modelStateErrors || 'Server error');
  }
}
