<div class="p-grid">
  <div class="invoice-preview">
    <div style="position: sticky; top: 100px">
      <div class="letter">
        <section>
          <p class="float-l">
            Kreirano u programu - eKompanija
            <span>01.01.2022</span>
          </p>
          <p class="float-r w-50 ta-r">www.moja.ekompanija.com</p>
        </section>

        <div class="clear"></div>

        <section *ngIf="selectedHeader?.key == 'h1'" class="header" style="margin-bottom: 10px">
          <div class="float-l">
            <p class="bold-text" style="
                text-transform: uppercase;
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 5px;
              ">
              KODIT DOO
            </p>
            <table class="w-100 header-company-info">
              <tr>
                <td>PIB: 112146306</td>
              </tr>
              <tr>
                <td>MB: 21615749</td>
              </tr>
              <tr>
                <td>Adresa: Novosadski Put 68, 21203 Veternik</td>
              </tr>
              <tr>
                <td>Broj telefona: {{ brojTelefona }}</td>
              </tr>
              <tr>
                <td>Tekući račun: 340-0000011027703-29</td>
              </tr>
              <tr *ngIf="prikaziWebsite">
                <td>Websajt: {{ website }}</td>
              </tr>
              <tr *ngIf="prikaziEmail">
                <td>Email: {{ email }}</td>
              </tr>
            </table>
          </div>

          <div class="logo-wrapper float-r" *ngIf="logoImage" [ngStyle]="{
              visibility:
                selectedLogoOption.key != 'no-logo' ? 'visible' : 'hidden'
            }">
            <p-image [src]="'data:image/png;base64,' + logoImage" alt="logo Vaše kompanije" width="80">
            </p-image>
          </div>
          <div class="logo-wrapper float-r" *ngIf="!logoImage" [ngStyle]="{
              visibility:
                selectedLogoOption.key != 'no-logo' ? 'visible' : 'hidden'
            }">
            <p-image src="/assets/podesavanje/images/logo_example.png" alt="logo Vaše kompanije" width="100"></p-image>
          </div>
        </section>

        <section *ngIf="selectedHeader?.key == 'h2'" class="header" style="margin-bottom: 10px">
          <div class="float-r">
            <p class="bold-text" style="
                text-transform: uppercase;
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 5px;
              ">
              KODIT DOO
            </p>
            <table class="w-100 header-company-info">
              <tr>
                <td>PIB: 112146306</td>
              </tr>
              <tr>
                <td>MB: 21615749</td>
              </tr>
              <tr>
                <td>Adresa: Novosadski Put 68, 21203 Veternik</td>
              </tr>
              <tr>
                <td>Tekući račun: 340-0000011027703-29</td>
              </tr>
              <tr *ngIf="prikaziWebsite">
                <td>Websajt: {{ website }}</td>
              </tr>
              <tr *ngIf="prikaziEmail">
                <td>Email: {{ email }}</td>
              </tr>
            </table>
          </div>

          <div class="logo-wrapper float-l" *ngIf="logoImage" [ngStyle]="{
              visibility:
                selectedLogoOption.key != 'no-logo' ? 'visible' : 'hidden'
            }">
            <p-image [src]="'data:image/png;base64,' + logoImage" alt="logo Vaše kompanije" width="200">
            </p-image>
          </div>
          <div class="logo-wrapper float-l" *ngIf="!logoImage" [ngStyle]="{
              visibility:
                selectedLogoOption.key != 'no-logo' ? 'visible' : 'hidden'
            }">
            <p-image src="/assets/podesavanje/images/logo_example.png" alt="logo Vaše kompanije" width="100"></p-image>
          </div>
        </section>

        <div class="clear"></div>

        <div style="border-top: 1px solid #ced4da" class="mt-10">
          <table cellpadding="0" cellspacing="0" width="100%" style="margin-bottom: 20px; margin-top: 10px">
            <tr>
              <td>
                <table cellpadding="0" cellspacing="0" width="40%" style="max-width: 800px">
                  <tr>
                    <td class="invoice-number-label">Račun broj:</td>
                    <td class="invoice-number">001/2022</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>

          <div class="row">
            <div style="float: left; width: 60%; padding: 5px">
              <table class="invoice-details" cellpadding="0" cellspacing="0" width="100%" style="max-width: 800px">
                <tr>
                  <td class="bold-text">
                    Mesto prometa dobara/usluga: Novi Sad
                  </td>
                </tr>
                <tr>
                  <td>Datum prometa dobara/usluga: 01.01.2022</td>
                </tr>
                <tr>
                  <td>Mesto izdavanja računa: Novi Sad</td>
                </tr>
                <tr>
                  <td>Datum izdavanja računa: 01.01.2022</td>
                </tr>
                <tr>
                  <td>Valuta plaćanja: 15.01.2022</td>
                </tr>
              </table>
            </div>
            <div style="
                float: right;
                width: 40%;
                padding: 5px;
                border: 1px solid #ced4da;
              ">
              <table cellpadding="0" cellspacing="0" width="100%" style="margin-bottom: 10px">
                <tr>
                  <td>
                    <table cellpadding="0" cellspacing="0" width="100%" style="max-width: 800px">
                      <tr>
                        <td style="padding-bottom: 5px">Kupac:</td>
                      </tr>
                      <tr>
                        <td style="text-transform: uppercase; font-weight: 600">
                          Microsoft Corporation
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
              <table class="company-details" cellpadding="0" cellspacing="0" width="100%" style="margin-bottom: 20px">
                <tr>
                  <td>
                    <table cellpadding="0" cellspacing="0" width="100%" style="max-width: 800px">
                      <tr>
                        <td>Redmond, Washington, United States</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="clear"></div>

        <section class="table mt-25">
          <table class="w-100">
            <tr class="ta-l tableHeader bg-blue" style="height: 30px" [ngStyle]="{ background: tableHeaderColor }">
              <th>R.b.</th>
              <th>Šifra</th>
              <th>Naziv</th>
              <th>Količina</th>
              <th>JM</th>
              <th class="ta-r">Cena</th>
              <th class="ta-r">PDV%</th>
              <th class="ta-r">Popust</th>
              <th class="ta-r">Iznos</th>
            </tr>

            <tr class="tableRow">
              <td class="number">1</td>
              <td>122654</td>
              <td>Poslovni program</td>
              <td>1</td>
              <td>kom</td>
              <td class="ta-r">5.900,00</td>
              <td class="ta-r">20</td>
              <td class="ta-r">0</td>
              <td class="ta-r">7.080,00</td>
            </tr>
          </table>
        </section>

        <div class="clear"></div>

        <section class="mt-25">
          <table class="calculation float-r">
            <tr class="ta-r">
              <td>Popust:</td>
              <td class="fw-semi">0</td>
            </tr>

            <tr class="ta-r">
              <td>Poreska osnovica:</td>
              <td class="fw-semi">5.900,00</td>
            </tr>
            <tr class="ta-r">
              <td>PDV:</td>
              <td class="fw-semi">1.180,00</td>
            </tr>

            <tr class="ta-r fw-semi mainRow">
              <td>Ukupno:</td>
              <td class="fw-semi" style="text-transform: capitalize">
                7.080,00
              </td>
            </tr>
          </table>
        </section>

        <div class="clear"></div>

        <section class="mt-30">
          <div class="note w-55 float-l">
            <p style="text-align: justify"></p>
            <p [ngStyle]="{
                visibility: prikaziAktOValidnosti ? 'visible' : 'hidden'
              }">
              Račun je generisan elektronski i punovažan je bez pečata i
              potpisa. Na osnovu Zakona o elektronskom dokumentu, elektronskoj
              identifikaciji i uslugama od poverenja u elektronskom poslovanju
              (“Sl.glasnik RS”, br. 94/2017) i saglasno članu 9. Zakona o
              računovodstvu (“Sl.glasnik RS”, br.62/2013).
            </p>
            <span [ngStyle]="{
                visibility: prikaziNapomenu ? 'visible' : 'hidden'
              }">
              {{ napomena }}
            </span>

            <br />
            <br />
          </div>

          <div class="signature w-30 float-r">
            <div class="line w-100"></div>

            <table cellpadding="0" cellspacing="0" width="100%" style="max-width: 800px"></table>
          </div>
        </section>

        <div class="clear"></div>

        <section class="p-mb-5">
          <div class="p-d-flex p-flex-wrap">
            <p class="bold-text p-mr-2">Napomena o poreskom oslobađanju:</p>
            <p>{{ napomenaOPoreskomOslobadjanju }}</p>
          </div>
          <p class="bold-text">
            Molimo Vas da se prilikom plaćanja pozovete na broj računa.
          </p>
          <p class="bold-text">
            Za kašnjenja u plaćanju obračunavamo zakonsku zateznu kamatu.
          </p>
        </section>
        <div class="clear"></div>
        <section *ngIf="selectedFooter?.key == 'f1'">
          <table cellpadding="0" cellspacing="0" width="100%" [ngStyle]="{
              visibility: prikaziFooterSekciju ? 'visible' : 'hidden'
            }">
            <tbody>
              <tr>
                <td>PIB: 112146306</td>
                <td class="ta-c">Matični broj: 21615749</td>
                <td class="ta-r">Šifra delatnosti: 6201</td>
              </tr>
              <tr>
                <td>Upisan kapital: 10.000,00 EUR</td>
                <td class="ta-c">Registrovan kod APR RS BD: 3322</td>
                <td class="ta-r">PE-PDV: 113/22</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
  </div>

  <div class="memorandum-form p-d-flex p-flex-column p-jc-between gap-12">
    <div card>
      <div class="p-fluid">
        <h5>Osnovne informacije</h5>

        <kodit-field fieldLabel="Naziv memoranduma:" fieldLabelFor="nazivMemorandum">
          <kodit-text-input ctrlPlaceholder="Osnovni memorandum" [formCtrl]="form.controls.naziv"
            formCtrlId="nazivMemorandum">
          </kodit-text-input>
        </kodit-field>

        <div class="p-field-checkbox">
          <p-checkbox [(ngModel)]="koristiInformacijePoslovneJedincie" binary="true" inputId="poslovna-jedinica">
          </p-checkbox>
          <label for="poslovna-jedinica">Koristi informacije poslovne jedinice</label>
        </div>

        <div class="p-field">
          <label>Poslovna jedinica:</label>
          <div>
            <kodit-poslovne-jedinice-dropdown [useAsFilter]="true" [disabled]="!koristiInformacijePoslovneJedincie"
              [poslovnaJedinicaCtrl]="form.controls.poslovnaJedinicaId"></kodit-poslovne-jedinice-dropdown>
          </div>
        </div>

        <kodit-valuta-dropdown-field [valutaCtrl]="form.controls.valutaId"></kodit-valuta-dropdown-field>

        <div class="p-field">
          <label>Izaberite boju tabele:</label>
          <div>
            <p-colorPicker [formControl]="form.controls.bojaTabele" [(ngModel)]="tableHeaderColor"></p-colorPicker>
          </div>
        </div>

        <div class="p-field">
          <label>Napomena o poreskom oslobađanju:</label>
          <div>
            <textarea [formControl]="form.controls.napomenaOPoreskomOslobadjanju" rows="1" cols="30"
              [(ngModel)]="napomenaOPoreskomOslobadjanju" pInputTextarea></textarea>
          </div>
        </div>

        <div class="p-field-checkbox">
          <p-checkbox [(ngModel)]="prikaziNapomenu" binary="true" inputId="binary"></p-checkbox>
          <label for="binary">Prikaži tekst napomene na svim računima</label>
        </div>

        <div class="p-field">
          <label>Napomena:</label>
          <div>
            <textarea [formControl]="form.controls.napomena" [disabled]="!prikaziNapomenu" rows="2" cols="30"
              [(ngModel)]="napomena" pInputTextarea></textarea>
          </div>
        </div>

        <div class="p-field-checkbox">
          <p-checkbox [(ngModel)]="prikaziAktOValidnosti" binary="true" inputId="akt-o-validnosti"
            [formControl]="form.controls.prikaziZakonskiAktOValidnosti"></p-checkbox>
          <label for="akt-o-validnosti">Prikaži zakonski akt o validnosti elektronskog računa</label>
        </div>

        <div class="p-field-checkbox">
          <p-checkbox [(ngModel)]="prikaziFooterSekciju" binary="true" inputId="footer-sekcija"
            [formControl]="form.controls.prikaziFooterSekciju"></p-checkbox>
          <label for="footer-sekcija">Prikazati footer sekciju na računu</label>
        </div>

        <kodit-divider></kodit-divider>
        <h5>Podešavanja header sekcije</h5>
        <p style="color: #828f99">
          Podešavanja izgleda i sadržaja header sekcije
        </p>

        <div class="p-fluid p-mt-5">
          
          <div *ngIf="mode == editMode">
            <div class="p-field-checkbox">
              <p-radioButton [formControl]="form.controls.koristiKompanijskiLogo" name="groupname" [value]="true"
                (onClick)="onCheck(true)" label="Koristi logo kompanije" class="p-mr-5"></p-radioButton>

              <p-radioButton [formControl]="form.controls.koristiKompanijskiLogo" name="groupname" [value]="false"
                (onClick)="onCheck(false)" label="Koristi novi logo" class="p-mr-3"></p-radioButton>
            </div>

            <div class="p-field" *ngIf="koristiLogoKompanije == false">
              <kodit-file-upload (onUpload)="onUpload($event)" [uploadCompleted]="uploadCompleted"
                fileTypes=".jpg,.png,.jpeg" descriptionText="Izaberite logo klikom na plus ili ga prevucite ovde">
              </kodit-file-upload>
            </div>
          </div>
          <kodit-field fieldLabel="Kompanijski websajt:" fieldLabelFor="websajt">
            <kodit-text-input ctrlPlaceholder="websajt kompanije" [formCtrl]="form.controls.website"
              formCtrlId="websajt">
            </kodit-text-input>
          </kodit-field>

          <kodit-field fieldLabelFor="email" fieldLabel="Kompanijski email:">
            <kodit-text-input ctrlPlaceholder="e-mail kompanije" [formCtrl]="form.controls.email" formCtrlId="email">
            </kodit-text-input>
          </kodit-field>

          <kodit-field fieldLabel="Kompanijski telefon:" fieldLabelFor="telefon">
            <kodit-text-input ctrlPlaceholder="Broj telefona" [formCtrl]="form.controls.phoneNumber"
              formCtrlId="telefon">
            </kodit-text-input>
          </kodit-field>
        </div>

        <!-- <div class="header-selection-wrapper">
          <div *ngFor="let header of headerOptions" class="field-checkbox">
            <div class="field-radiobutton p-mb-3">
              <p-radioButton
                [inputId]="header.key"
                name="header"
                [value]="header"
                [(ngModel)]="selectedHeader"
              ></p-radioButton>
              <label [for]="header.key">
                <p-image
                  class="image_example"
                  [src]="header.labelUrl"
                  alt="logo Vaše kompanije"
                  width="400"
                >
                </p-image>
              </label>
            </div>
          </div>
        </div>
        <kodit-divider></kodit-divider>
        <h5>Podešavanja izgleda i sadržaja footer sekcije</h5>
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam.
        </p>
        <div class="header-selection-wrapper">
          <div *ngFor="let footer of footerOptions" class="field-checkbox">
            <div class="field-radiobutton p-mb-3">
              <p-radioButton
                [inputId]="footer.key"
                name="footer"
                [value]="footer"
                [(ngModel)]="selectedFooter"
              ></p-radioButton>
              <label [for]="footer.key">
                <p-image
                  *ngIf="footer.labelUrl != ''"
                  class="image_example"
                  [src]="footer.labelUrl"
                  alt="logo Vaše kompanije"
                  width="400"
                ></p-image>
                <span class="image_example" *ngIf="footer.labelUrl == ''"
                  >Generisi pdf fakture bez footer sekcije</span
                >
              </label>
            </div>
          </div>
        </div>
        <kodit-divider></kodit-divider> -->
      </div>
    </div>
    <div card style="position: sticky; bottom: 0">
      <div class="p-d-flex p-flex-column p-flex-md-row p-jc-between">
        <div class="dodatne-akcije p-d-flex p-flex-column p-jc-around p-ml-3">
          <!-- <button
          pButton
          pRipple
          icon="fas fa-file-export"
          type="button"
          label="Sačuvaj šablon"
          class="p-button-secondary"
          pTooltip="Sačuvaj račun kao šablon"
          (click)="onTemplateSave()"
        ></button>
        <ng-content></ng-content> -->
        </div>

        <div class="primarne-akcije p-as-center">
          <button pButton pRipple icon="far fa-window-close" type="button" label="Otkaži"
            class="p-button-secondary p-button-outlined p-mr-3" pTooltip="Otkaži kreiranje računa"
            tooltipPosition="bottom" (click)="onCancel()"></button>
          <button pButton icon="fas fa-save" type="submit" label="Sačuvaj" class="p-button-success p-mr-3"
            pTooltip="Sačuvaj račun" tooltipPosition="bottom" (click)="onSave()"></button>
        </div>
      </div>
    </div>
  </div>
</div>