import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-dashboard-promet-card',
  templateUrl: './dashboard-promet-card.component.html',
  styleUrls: ['./dashboard-promet-card.component.scss'],
})
export class DashboardPrometCardComponent implements OnInit {
  @Input() value: number;
  @Input() percentage: number;
  @Input() isPercentageNegative: boolean;
  @Input() periodPrefix: string;
  @Input() periodCurrent: string;
  @Input() periodBefore: string;

  constructor() {}

  ngOnInit(): void {}
}
