import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  IUpdateSefApiKeyCommand,
  MojaFirmaClient,
  UpdateSefApiKeyCommand,
} from '@kodit/core/data-api';
import { AlertService, ButtonService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
//import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'kodit-moja-firma-api-card-form',
  templateUrl: './moja-firma-api-card-form.component.html',
  styleUrls: ['./moja-firma-api-card-form.component.scss'],
})
export class MojaFirmaApiCardFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  form: FormGroupTypeSafe<IUpdateSefApiKeyCommand>;

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _client: MojaFirmaClient,
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    //private _loader: LoaderService
    private _buttonService: ButtonService
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group<IUpdateSefApiKeyCommand>({
      apiKeySEF: new FormControl(this._dialogConfig.data.apiKeySEF),
    });
    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.onSave();
      })
    );
    //this._loader.setLoadingText = 'Povezujem se sa SEF-om...';
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.onSave();
    }
  }

  onSave() {
    //this._loader.setShowLoader = true;
    this._buttonService.disableButton();
    this._subs.add(
      this._client
        .updateSefApiKey(this.form.value as UpdateSefApiKeyCommand)
        .pipe(
          finalize(() => {
            this._buttonService.enableButton();
          })
        )
        .subscribe((result) => {
          this._alertService.addSuccessMsgs(result.messages);
          this._dialogRef.close(result);
        })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
