import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DatotekaDto, DmsClient, IDatotekaTreeDto } from '@kodit/core/data-api';
import { SharedService, b64toBlob } from '@kodit/core/services';
import { Subscription } from 'rxjs';
import { DatotekaService } from '../../datoteka-service';
import { TreeNode } from 'primeng/api';
import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'kodit-dokument-info-card',
  templateUrl: './dokument-info-card.component.html',
  styleUrls: ['./dokument-info-card.component.scss'],
})
export class DokumentInfoCardComponent implements OnInit, OnDestroy {
  /** SUBS */
  private _subs: Subscription = new Subscription();

  /** I/O */
  @Input() datoteka!: DatotekaDto;

  /** PROPS */
  velicinaDokumenta!: string;

  constructor(
    private _client: DmsClient,
    private _sharedService: SharedService,
    private _datotekaService: DatotekaService,
    private _loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.velicinaDokumenta = this._calculateSize(
      this.datoteka.velicinaDokumenta!
    );
  }

  onClick() {
    this._datotekaService.setSelectedDatoteka = {
      data: {
        vrstaDatoteke: this.datoteka.vrsta,
        id: this.datoteka.datotekaId,
        naziv: this.datoteka.naziv,
        childrenDto: this.datoteka.children,
        velicinaDokumenta: this.datoteka.velicinaDokumenta,
      },
    } as TreeNode<IDatotekaTreeDto>;
  }

  download($event: any) {
    $event.stopPropagation();
    this._loader.setLoadingText = 'Preuzimanje je u toku...';
    this._loader.setShowLoader = true;
    this._subs.add(
      this._client
        .getDokumentContent(this.datoteka.datotekaId)
        .pipe(
          finalize(() => {
            this._loader.reset();
          })
        )
        .subscribe((res) => {
          if (res.succeeded) {
            const blob = b64toBlob(
              res.data?.dto?.fileUrl!,
              this._sharedService.getContentType(res.data?.dto?.extension!)
            );
            const blobUrl = URL.createObjectURL(blob);

            this._sharedService.downloadByUrl(blobUrl, this.datoteka.naziv);
          }
        })
    );
  }

  private _calculateSize(bytes: number) {
    const decimals = 2;

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
