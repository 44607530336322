<div
  [ngClass]="
    ovlascenjeDtoFromArray.errors?.required &&
    (ovlascenjeDtoFromArray.dirty || ovlascenjeDtoFromArray.touched)
      ? 'ovlascenja-invalid'
      : ''
  "
>
  <small
    *ngIf="
      ovlascenjeDtoFromArray.errors?.required &&
      (ovlascenjeDtoFromArray.dirty || ovlascenjeDtoFromArray.touched)
    "
    class="p-invalid"
    >Odaberite bar 1 ovlašćenje</small
  >

  <kodit-divider dividerLabel="Ovlašćenja"></kodit-divider>

  <div *ngFor="let grupa of grupe | keyvalue; let grupaIdx = index">
    <div class="p-d-flex p-jc-between p-ai-center">
      <div kodit-form-label>{{ grupa.key }}</div>
      <div
        class="tip-pristupa-wrapper p-d-flex p-flex-wrap gap-12 p-jc-start gap-12"
      >
        <div
          *ngFor="let tipPristupa of grupa.value"
          class="field-checkbox"
          style="min-width: 250px"
        >
          <p-checkbox
            [inputId]="tipPristupa.key"
            name="tipPristupa"
            [value]="tipPristupa"
            value="tipPristupa"
            [(ngModel)]="selectedValues"
            (onChange)="handleCheckboxChange($event)"
          ></p-checkbox>
          <label [for]="tipPristupa.key" class="p-ml-2"
            >{{ tipPristupa.value }}
          </label>
        </div>
      </div>
    </div>
    <kodit-divider></kodit-divider>
  </div>
</div>
