<kodit-info-container>
  <div body-full>
    <div class="p-mt-5 kodit-card-tab">
      <p-tabView>
        <p-tabPanel header="Artikli" *ngIf="hasRobno">
          <div class="p-mt-4">
            <kodit-roba-table></kodit-roba-table>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Usluge">
          <div class="p-mt-4">
            <kodit-usluga-table></kodit-usluga-table>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Repromaterijal" *ngIf="hasRobno">
          <div class="p-mt-4">
            <kodit-repromaterijal-table></kodit-repromaterijal-table>
          </div>
        </p-tabPanel>
        <!-- <p-tabPanel header="Jedinice mere">
          <div class="p-mt-4">
            <kodit-jedinica-mere-table></kodit-jedinica-mere-table>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Tipovi magacina">
          <div class="p-mt-4">
            <kodit-magacin-tip-table></kodit-magacin-tip-table>
          </div>
        </p-tabPanel> -->
      </p-tabView>
    </div>
  </div>
</kodit-info-container>
