import { Pipe, PipeTransform } from '@angular/core';
import { ActionClass, ActionType } from './table-common';

@Pipe({
  name: 'tableRowActionClass',
  pure: true,
})
export class GetRowActionClassPipe implements PipeTransform {
  transform(actionClass: string, actionType: ActionType): any {
    let result = 'p-button-text ';
    switch (actionType) {
      case ActionType.EDIT:
        result += ActionClass.EDIT;
        break;
      case ActionType.DELETE:
        result += ActionClass.DELETE;
        break;
      case ActionType.CUSTOM:
        result += actionClass;
        break;
      case ActionType.ARCHIVE:
        result += actionClass;
        break;
    }

    return result;

    // return actionClass
    //   ? `p-button-text ${actionClass.toString()}`
    //   : 'p-button-text';
  }
}

@Pipe({
  name: 'tableHeaderActionClass',
  pure: true,
})
export class GetHeaderActionClassPipe implements PipeTransform {
  transform(actionClass: ActionClass, actionType: ActionType): any {
    if (!actionClass) {
      return 'p-button';
    }
    switch (actionType) {
      default:
        return `p-button ${actionClass.toString()}`;
    }
  }
}

@Pipe({
  name: 'tableActionIcon',
  pure: true,
})
export class GetActionIconPipe implements PipeTransform {
  transform(actionIcon: string, actionType: ActionType): any {
    if (actionIcon) return actionIcon;

    switch (actionType) {
      case ActionType.EDIT:
        return 'far fa-edit';
      case ActionType.DELETE:
      case ActionType.MULTIDELETE:
        return 'far fa-trash-alt';
      case ActionType.CREATE:
        return 'fas fa-plus';
      case ActionType.ARCHIVE:
        return 'fa-light fa-box-archive';
      default:
        return actionIcon ?? '';
    }
  }
}

@Pipe({
  name: 'tableActionLabel',
  pure: true,
})
export class GetActionLabelPipe implements PipeTransform {
  transform(actionLabel: string, actionType: ActionType): any {
    if (actionLabel) return actionLabel;
    switch (actionType) {
      case ActionType.CREATE:
        return 'Unesi';
      default:
        return actionLabel ?? '';
    }
  }
}
