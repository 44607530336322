import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'kodit-jmbg-input-mask-field',
  templateUrl: './jmbg-input-mask-field.component.html',
  styleUrls: ['./jmbg-input-mask-field.component.scss']
})
export class JmbgInputMaskFieldComponent implements OnInit {
 
  @Input() formCtrl: AbstractControl;

  constructor() { }

  ngOnInit(): void {
  }

}
