
import { createSelector } from '@ngrx/store';
import { FilterState } from './filter.reducer';
import { AppState } from '../app.state';

// Select the filters feature slice from the root state
export const selectFilters = (state: AppState) => state.filters; 

// Create a selector to get the filter for a specific TipRacuna
export const selectFilterByKey = (key: string) =>
  createSelector(
    selectFilters,
    (state: FilterState) => (state[key] && state[key].filter) ?? null
  );