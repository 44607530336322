import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[kodit-currency-directive]',
})
export class CurrencyDirective implements OnInit {
  @Input() currencyValue: string | number;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.setStyle(this.elRef.nativeElement, 'font-weight', '600');
    const color = this.currencyValue > 0 ? '#10B981' : '#FF5959';
    this.renderer.setStyle(this.elRef.nativeElement, 'color', color);
  }
}
