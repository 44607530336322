<kodit-dropdown
  [formCtrlId]="formCtrlId"
  [formCtrl]="formCtrl"
  [ctrlDisabled]="disabled"
  [options]="options"
  [ctrlPlaceholder]="useAsFilter ? '' : 'Izaberite memorandum'"
  (optionChanged)="handleMemorandumChange($event)"
>
  <ng-template #selectedItem let-obj>
    <div class="p-text-left">{{ obj.naziv }}</div>
  </ng-template>

  <ng-template #item let-obj>
    <ng-container>
      <div>{{ obj.naziv }}</div>
    </ng-container>
  </ng-template>
</kodit-dropdown>
