import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ResultOfTekuciRacunDto,
  TekuciRacunDto,
  TekuciRacuniClient,
  TipPristupa,
} from '@kodit/core/data-api';
import {
  ActionType,
  DynamicDialogConfig,
  DynamicDialogService,
  TableConfig,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { TekuciRacunFormComponent } from '../tekuci-racun-form/tekuci-racun-form/tekuci-racun-form.component';

@Component({
  selector: 'kodit-tekuci-racun-table',
  templateUrl: './tekuci-racun-table.component.html',
  styleUrls: ['./tekuci-racun-table.component.scss'],
})
export class TekuciRacunTableComponent implements OnInit, OnDestroy {
  /** Configurations */
  tableConfig: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  private _subs: Subscription = new Subscription();
  tekuciRacunTable: TekuciRacunDto[];

  /** I/O */
  @Input() showCardBlock: boolean = false;

  constructor(
    private _client: TekuciRacuniClient,
    private _dialogService: DynamicDialogService
  ) {}

  ngOnInit(): void {
    this._load();
    // set table config
    this.setTableConfig().then();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Tekući računi',
      deleteMessage: 'Da li ste sigurni da želite da obrišete račun {param}?',
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete selektovane račune?',
      deleteMessageParams: 'ceoBrojRacuna',
      columns: [
        {
          field: 'nazivBanke',
          header: 'Banka',
          type: 'text',
        },
        {
          field: 'ceoBrojRacuna',
          header: 'Broj računa',
          type: 'text',
        },
        {
          field: 'iban',
          header: 'IBAN',
          type: 'text',
        },
        {
          field: 'isDefault',
          header: 'Podrazumevano',
          type: 'bool',
        },
        {
          field: 'prikaziNaPdf',
          header: 'Prikazati na fakturi',
          type: 'bool',
        },
        {
          field: 'isActive',
          header: 'Aktivan',
          type: 'bool',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          tipPristupa: TipPristupa.ADMIN_CRUD,
          hasAccessTooltip: 'Unesite tekući račun',
          noAccessTooltip: 'Nemate ovlašćenja za unos tekućih računa',
          callback: () => {
            this.dialogConfig.data = { id: null };
            this.dialogConfig.header = 'Unos podataka tekućeg računa';
            this.openDialog(this.dialogConfig);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          tipPristupa: TipPristupa.ADMIN_CRUD,
          hasAccessTooltip: 'Izmenite tekući račun',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu tekućih račun',
          callback: (index: number) => {
            this.dialogConfig.data = { id: this.tekuciRacunTable[index]?.id };
            this.dialogConfig.header = 'Izmena podataka tekućeg računa';
            this.openDialog(this.dialogConfig);
          },
        },
      ],
    });
  }

  openDialog(config: DynamicDialogConfig) {
    const ref = this._dialogService.open(TekuciRacunFormComponent, config);

    this._subs.add(
      ref.onClose.subscribe((dto: ResultOfTekuciRacunDto) => {
        if (dto) {
          this._load();
        }
      })
    );
  }

  private _load() {
    this._subs.add(
      this._client.getAll().subscribe((res) => {
        this.tekuciRacunTable = res.data.tekuciRacunDtoList;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
