import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import {
  IStavkaRacunaDto,
  IdentifikatorKlasifikacijeStavke,
  NeobradjenRacunStavkaDto,
  OznakaPdvKategorije,
  PredmetStavkeDto,
  TipArtikla,
  TipPredmeta,
  TipRacuna,
} from '@kodit/core/data-api';
import { RacunService } from '@kodit/core/services';
import { KalkulacijeService } from '../../kalkulacije.service';
import { PredmetStavkeResult } from '../../subforms/rezultat-pretrage-predmeta-stavke/rezultat-pretrage-predmeta-stavke.component';
import { Inplace } from 'primeng/inplace';

@Component({
  selector: 'kodit-neobradjen-racun-obrada-stavke-card',
  templateUrl: './neobradjen-racun-obrada-stavke-card.component.html',
  styleUrls: ['./neobradjen-racun-obrada-stavke-card.component.scss'],
})
export class NeobradjenRacunObradaStavkeCardComponent implements OnInit {
  /* Props */
  stavkeRacuna: FormArray;
  oznakeArray: FormArray;
  shouldDisplayRezultate: boolean;
  redniBrojZaRezultate: number = 0;
  rezultatiSearchValue: string;
  rezultatiIznosValue: number;
  rezultatiJmValue: string;
  tipPredmetaRoba: TipPredmeta = TipPredmeta.ROBA;

  @ViewChild('kolicinaInplace') kolicinaInplace!: Inplace;
  @ViewChild('jmInplace') jmInplace!: Inplace;
  @ViewChild('cenaInplace') cenaInplace!: Inplace;

  excludeTipovePredmeta: TipPredmeta[] = [
    TipPredmeta.PREDMET_IZMENE_OSNOVICE,
    TipPredmeta.DATI_AVANS,
    TipPredmeta.PRIMLJENI_AVANS,
  ];

  /* I/O */
  @Input() neobradjeneStavke: NeobradjenRacunStavkaDto[] = [];
  @Input() tipRacuna: TipRacuna = TipRacuna.ULAZNA_FAKTURA;

  constructor(
    private _service: RacunService,
    private _kalkulacijeService: KalkulacijeService
  ) {}

  ngOnInit(): void {
    this.stavkeRacuna = this._service.getStavkeArray;
    this.oznakeArray = this._service.getOznakeArray;

    this._calculateKalkulacijaOnInit();
  }

  //Mapiramo oznake za stavke racuna i dodajemo ih na formu
  loadOznake() {
    const oznakeToAdd = [];

    this.stavkeRacuna.value.forEach((stavka: IStavkaRacunaDto) => {
      const oznake = stavka.predmetStavkeDto.oznakeDto;
      if (oznake.length > 0) {
        oznake.forEach((oznaka) => {
          if (!oznakeToAdd.some((item) => item.id === oznaka.id)) {
            oznakeToAdd.push(oznaka);
          }
        });
      }
    });
    this._service.setOznakePredmetaStavke = oznakeToAdd;
  }

  updateRezultatePretrage(changedNaziv: string, stavkaIndex: number) {
    this.redniBrojZaRezultate = stavkaIndex;
    this.rezultatiSearchValue = changedNaziv;
    this.rezultatiIznosValue = this.neobradjeneStavke[stavkaIndex].cenaPoJM;
    this.rezultatiJmValue = this.neobradjeneStavke[stavkaIndex].jedinicaMere;
    this.shouldDisplayRezultate = true;
  }

  handleProdajnaCenaChanged(idx: number) {
    const neobradjenaStavka = this.neobradjeneStavke[idx];
    const prodajnaCenaBezPdv = this._service.getStavkaByIndex(idx).value
      .predmetStavkeDto.prodajnaCenaBezPdv;
    const predmetStavke = this._kalkulacijeService.calculateProdajnaCenaBezPdvChanged(
      prodajnaCenaBezPdv,
      new PredmetStavkeDto(
        this.stavkeRacuna.at(idx).get('predmetStavkeDto').value
      ),
      neobradjenaStavka
    );

    this._service.handleKalkulacijaCena(
      predmetStavke,
      idx,
      predmetStavke.prodajnaCenaBezPdv
    );

    this.updateIznos(idx);
  }

  handleProdajnaCenaSaPdvChanged(idx: number) {
    const neobradjenaStavka = this.neobradjeneStavke[idx];
    const prodajnaCenaSaPdv = this._service.getStavkaByIndex(idx).value
      .predmetStavkeDto.prodajnaCenaSaPdv;
    const predmetStavke = this._kalkulacijeService.calculateProdajnaCenaSaPdvChanged(
      prodajnaCenaSaPdv,
      new PredmetStavkeDto(
        this.stavkeRacuna.at(idx).get('predmetStavkeDto').value
      ),
      neobradjenaStavka
    );

    this._service.handleKalkulacijaCena(
      predmetStavke,
      idx,
      predmetStavke.prodajnaCenaBezPdv
    );

    this.updateIznos(idx);
  }

  handleMarzaChanged(idx: number) {
    const neobradjenaStavka = this.neobradjeneStavke[idx];
    const updatedMarza = this._service.getStavkaByIndex(idx).value
      .predmetStavkeDto.marza;
    const predmetStavke = this._kalkulacijeService.calculateMarzaCenaChanged(
      updatedMarza,
      new PredmetStavkeDto(
        this.stavkeRacuna.at(idx).get('predmetStavkeDto').value
      ),
      neobradjenaStavka
    );

    this._service.handleKalkulacijaCena(
      predmetStavke,
      idx,
      predmetStavke.prodajnaCenaBezPdv
    );

    this.updateIznos(idx);
  }

  handleRazlikaUCeniChanged(idx: number) {
    const neobradjenaStavka = this.neobradjeneStavke[idx];
    const updatedRazlika = this._service.getStavkaByIndex(idx).value
      .predmetStavkeDto.razlikaUCeni;
    const predmetStavke = this._kalkulacijeService.calculateRazlikaUCeniChanged(
      updatedRazlika,
      new PredmetStavkeDto(
        this.stavkeRacuna.at(idx).get('predmetStavkeDto').value
      ),
      neobradjenaStavka
    );

    this._service.handleKalkulacijaCena(
      predmetStavke,
      idx,
      predmetStavke.prodajnaCenaBezPdv
    );

    this.updateIznos(idx);
  }

  handleNabavnaCenaChanged(idx: number) {
    const updatedNabavna = this._service.getStavkaByIndex(idx).value
      .nabavnaCenaBezPdv;

    const predmetStavke = this._kalkulacijeService.calculateNabavnaCenaChanged(
      updatedNabavna,
      new PredmetStavkeDto(
        this.stavkeRacuna.at(idx).get('predmetStavkeDto').value
      )
    );

    this._service.handleKalkulacijaCena(
      predmetStavke,
      idx,
      predmetStavke.prodajnaCenaBezPdv
    );

    this.updateIznos(idx);
  }

  predmetStavkeAdded(data: PredmetStavkeResult) {
    const neobradjenaStavka = this.neobradjeneStavke[this.redniBrojZaRezultate];

    if (data == null || data.predmetStavke == null) {
      this._resetRezultati();
      return;
    }

    if (
      neobradjenaStavka.cenaPoJM <= 0 ||
      data.predmetStavke.tipArtikla === TipArtikla.USLUGA
    ) {
      // Resetujemo sve vrednosti
      data.predmetStavke.nabavnaCena = 0;
      data.predmetStavke.marza = 0;
      data.predmetStavke.razlikaUCeni = 0;
      data.predmetStavke.prodajnaCenaSaPdv = 0;
      this._service.patchPredmetStavke(
        data.predmetStavke,
        this.redniBrojZaRezultate
      );
    } else {
      // Za tacan iznos nabavne cene moramo oduzeti i popust
      data.predmetStavke.nabavnaCena =
        neobradjenaStavka.cenaPoJM -
        neobradjenaStavka.rabatIznos / neobradjenaStavka.kolicina;

      /// Racunamo marzu, razliku i prodajnu sa PDV
      /// na osnovu nabavne cene sa primljenog racuna
      data.predmetStavke.marza =
        ((data.predmetStavke.prodajnaCenaBezPdv - neobradjenaStavka.cenaPoJM) /
          data.predmetStavke.nabavnaCena) *
        100;

      data.predmetStavke.razlikaUCeni =
        data.predmetStavke.prodajnaCenaBezPdv - neobradjenaStavka.cenaPoJM;

      data.predmetStavke.prodajnaCenaSaPdv =
        data.predmetStavke.prodajnaCenaBezPdv +
        data.predmetStavke.prodajnaCenaBezPdv *
          (neobradjenaStavka.pdvStopa / 100);

      this._service.handleKalkulacijaCena(
        data.predmetStavke,
        this.redniBrojZaRezultate,
        data.predmetStavke.prodajnaCenaBezPdv
      );
    }

    // dodavanje Standard,Buyer and SellerItemIdentification for AutomatskaObradaSefRacuna
    data.predmetStavke.buyerItemIdentification =
      neobradjenaStavka.buyerItemIdentification;
    data.predmetStavke.sellerItemIdentification =
      neobradjenaStavka.sellersItemIdentification;
    data.predmetStavke.standardItemIdentification =
      neobradjenaStavka.standardItemIdentification;

    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('naziv')
      .patchValue(data.predmetStavke.naziv);
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('naziv')
      .updateValueAndValidity();

    // Magacin is mandatory for roba.
    if (data.predmetStavke.tipPredmeta == TipPredmeta.ROBA) {
      this.stavkeRacuna
        .at(this.redniBrojZaRezultate)
        .get('magacinId')
        .setValidators([Validators.required]);

      this.stavkeRacuna
        .at(this.redniBrojZaRezultate)
        .get('magacinId')
        .patchValue(data.predmetStavke.defaultMagacinId);

      this.stavkeRacuna
        .at(this.redniBrojZaRezultate)
        .get('magacinId')
        .updateValueAndValidity();
    } else if (data.predmetStavke.tipPredmeta == TipPredmeta.USLUGA) {
      this.stavkeRacuna
        .at(this.redniBrojZaRezultate)
        .get('magacinId')
        .setValidators([Validators.nullValidator]);

      this.stavkeRacuna
        .at(this.redniBrojZaRezultate)
        .get('magacinId')
        .updateValueAndValidity();
    }

    // ProdajnaCena
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('prodajnaCenaBezPdv')
      .patchValue(data.predmetStavke.prodajnaCenaBezPdv);

    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('prodajnaCenaBezPdv')
      .updateValueAndValidity();

    // update nabavnaCenaBezPdv value
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('nabavnaCenaBezPdv')
      .patchValue(data.predmetStavke.nabavnaCena);
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('nabavnaCenaBezPdv')
      .updateValueAndValidity();

    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('jedinicaMere')
      .patchValue(data.predmetStavke.jedinicaMereDto);
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('jedinicaMere')
      .updateValueAndValidity();

    // predmet stavke
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('predmetStavkeDto')
      .patchValue(data.predmetStavke);
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('predmetStavkeDto')
      .updateValueAndValidity();
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('stopaPDV')
      .patchValue(neobradjenaStavka.pdvStopa);
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('stopaPDV')
      .updateValueAndValidity();

    // pdv stopa i kategorija
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('pdvKategorija')
      .patchValue(OznakaPdvKategorije[neobradjenaStavka.pdvKategorija]);
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('pdvKategorija')
      .updateValueAndValidity();

    // identifikator za 10% i 20%
    if (
      neobradjenaStavka.pdvStopa === 10 ||
      neobradjenaStavka.pdvStopa === 20
    ) {
      this.stavkeRacuna
        .at(this.redniBrojZaRezultate)
        .get('identifikator')
        .patchValue(IdentifikatorKlasifikacijeStavke.EMPTY);

      this.stavkeRacuna
        .at(this.redniBrojZaRezultate)
        .get('identifikator')
        .updateValueAndValidity();
    }

    if (neobradjenaStavka.pdvStopa === 0) {
      this.stavkeRacuna
        .at(this.redniBrojZaRezultate)
        .get('razlogPdvIzuzecaId')
        .setValidators(Validators.required);
      this.stavkeRacuna
        .at(this.redniBrojZaRezultate)
        .get('razlogPdvIzuzecaId')
        .patchValue(neobradjenaStavka.razlogPdvIzuzecaId);
      this.stavkeRacuna
        .at(this.redniBrojZaRezultate)
        .get('razlogPdvIzuzecaId')
        .updateValueAndValidity();
    } else {
      this.stavkeRacuna
        .at(this.redniBrojZaRezultate)
        .get('razlogPdvIzuzecaId')
        .setValidators(Validators.nullValidator);
      this.stavkeRacuna
        .at(this.redniBrojZaRezultate)
        .get('razlogPdvIzuzecaId')
        .updateValueAndValidity();
    }

    //Kolicina
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('kolicina')
      .patchValue(neobradjenaStavka.kolicina);
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('kolicina')
      .updateValueAndValidity();

    // Cena
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('predmetStavkeDto.nabavnaCena')
      .patchValue(neobradjenaStavka.cenaPoJM);
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('predmetStavkeDto.nabavnaCena')
      .updateValueAndValidity();

    // Popust
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('rabat')
      .patchValue(neobradjenaStavka.rabatIznos);
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('rabat')
      .updateValueAndValidity();

    // Poreska osnovica
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('poreskaOsnovica')
      .patchValue(neobradjenaStavka.poreskaOsnovica);
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('poreskaOsnovica')
      .updateValueAndValidity();

    // Poreska osnovica
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('iznos')
      .patchValue(neobradjenaStavka.iznos);
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('iznos')
      .updateValueAndValidity();

    // PDV
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('pdv')
      .patchValue(neobradjenaStavka.pdvIznos);
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('pdv')
      .updateValueAndValidity();

    //Redni broj
    this.stavkeRacuna
      .at(this.redniBrojZaRezultate)
      .get('redniBroj')
      .patchValue(this.redniBrojZaRezultate + 1);

    //Oznake
    data.predmetStavke.oznakeDto.forEach((o) => {
      this._service.addOznakuRacuna(o);
    });

    this._resetRezultati();
  }

  updateIznos(stavkaIndex: number) {
    this._calculatePoreskaOsnovica(stavkaIndex);
    this._calculatePdv(stavkaIndex);
    this._calclulateIznos(stavkaIndex);
  }

  cancelStavka(stavkaIndex: number) {
    this._service.setOznakeForDelete = stavkaIndex;

    this.stavkeRacuna.at(stavkaIndex).reset({
      redniBroj: stavkaIndex,
    });

    this.stavkeRacuna.at(stavkaIndex).updateValueAndValidity();
  }

  hideContentKolicina() {
    this.kolicinaInplace.deactivate();
  }

  hideContentJM() {
    this.jmInplace.deactivate();
  }

  hideCenaContent(idx: number) {
    this.handleNabavnaCenaChanged(idx);
    this.cenaInplace.deactivate();
  }

  private _calculateKalkulacijaOnInit() {
    this.stavkeRacuna.controls.forEach((res: FormGroup) => {
      const nabavnaCena = res.controls.nabavnaCenaBezPdv.value;
      const prodajnaCena =
        res.controls.predmetStavkeDto.value.prodajnaCenaBezPdv;
      const stopaPdv = res.controls.predmetStavkeDto.value.stopaPDV;

      /// Treba nam radi provere da li se treira kompenzacija na BE
      res.controls.prodajnaCenaBezPdv.patchValue(prodajnaCena);

      // ovo je potrebno jer sa back-a vracamo NabavnuCenu sa StavkeRacuna a ovde nam treba iz PredmetStavkeDto
      res.controls.predmetStavkeDto
        .get('nabavnaCena')
        .patchValue(res.controls.nabavnaCenaBezPdv.value);

      res.controls.predmetStavkeDto
        .get('prodajnaCenaSaPdv')
        .patchValue(
          this._kalkulacijeService.calculateProdajnaCenaSaPdv(
            prodajnaCena,
            stopaPdv
          )
        );

      res.controls.predmetStavkeDto
        .get('razlikaUCeni')
        .patchValue(
          this._kalkulacijeService.calculateRazlikaUCeni(
            nabavnaCena,
            prodajnaCena
          )
        );

      res.controls.predmetStavkeDto
        .get('marza')
        .patchValue(
          this._kalkulacijeService.calculateMarza(nabavnaCena, prodajnaCena)
        );
    });
  }

  /**
   * (Re)Kalkulacija poreske osnovice stavke dokumenta
   * @param stavkaIndex index stavke dokumenta za rekalkulaciju
   */
  private _calculatePoreskaOsnovica(stavkaIndex: number) {
    const poreskaOsnovicaBezPopusta =
      this.stavkeRacuna.at(stavkaIndex).get('kolicina').value *
      this.stavkeRacuna.at(stavkaIndex).get('predmetStavkeDto.nabavnaCena')
        .value;

    this.stavkeRacuna
      .at(stavkaIndex)
      .get('poreskaOsnovica')
      .patchValue(
        poreskaOsnovicaBezPopusta -
          this.stavkeRacuna.at(stavkaIndex).value.rabat
      );
  }

  /**
   * (Re)Kalkulacija pdv-a stavke dokumenta
   * @param stavkaIndex index stavke dokumenta za rekalkulaciju
   */
  private _calculatePdv(stavkaIndex: number) {
    const pdv =
      (this.stavkeRacuna.at(stavkaIndex).get('poreskaOsnovica').value *
        this.stavkeRacuna.at(stavkaIndex).get('predmetStavkeDto.stopaPDV')
          .value) /
      100;
    this.stavkeRacuna.at(stavkaIndex).get('pdv').patchValue(pdv);
  }

  /**
   * (Re)Kalkulacija iznosa stavke dokumenta
   * @param stavkaIndex index stavke dokumenta za rekalkulaciju
   */
  private _calclulateIznos(stavkaIndex: number) {
    const iznos =
      this.stavkeRacuna.at(stavkaIndex).get('poreskaOsnovica').value +
      this.stavkeRacuna.at(stavkaIndex).get('pdv').value;
    this.stavkeRacuna.at(stavkaIndex).get('iznos').patchValue(iznos);
  }

  private _resetRezultati() {
    this.redniBrojZaRezultate = -1;
    this.shouldDisplayRezultate = false;
  }
}
