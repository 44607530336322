import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  FiskalniRacuniClient,
  InvoiceType,
  IReferentniFiskalniRacunDto,
  TransactionType,
} from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { FiskalniRacunService } from '../../fiskalni-racun.service';

interface IRequest {
  searchValue: string;
}

@Component({
  selector: 'kodit-referentni-racun-fiskalnog-racuna-autocomplete',
  templateUrl:
    './referentni-racun-fiskalnog-racuna-autocomplete.component.html',
  styleUrls: [
    './referentni-racun-fiskalnog-racuna-autocomplete.component.scss',
  ],
})
export class ReferentniRacunFiskalnogRacunaAutocompleteComponent
  implements OnInit {
  @ViewChild('vezaniSearch') vezaniSearch: any;

  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  form!: FormGroupTypeSafe<IRequest>;
  referentniRacunSuggestions: IReferentniFiskalniRacunDto[] = [];
  invoiceType!: InvoiceType | undefined;
  transactionType!: TransactionType | undefined;

  /** I/O */
  @Input() ctrlDisabled!: boolean;
  @Input() formCtrlId!: string;
  @Input() ctrlPlaceholder: string = '';
  @Output() onChange = new EventEmitter<IReferentniFiskalniRacunDto>();
  @Output() onClear = new EventEmitter<void>();

  constructor(
    private _fiskalniClient: FiskalniRacuniClient,
    private _fb: FormBuilderTypeSafe,
    private _fiskalniService: FiskalniRacunService
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group<IRequest>({
      searchValue: new FormControl('', {
        validators: [Validators.required],
      }),
    });

    // patchujemo vrednost referentDocumentNumber
    const tempReferentDocumentNumber = this._fiskalniService.getFormObject
      .controls.referentniFiskalniRacunDto?.value?.referentDocumentNumber;

    this.form.controls.searchValue.patchValue(tempReferentDocumentNumber);
    this.ctrlPlaceholder = tempReferentDocumentNumber!;

    // uzmimamo vrednost InvoiceType i TransactionType za Search query
    this.invoiceType = this._fiskalniService.getFormObject.controls.invoiceType?.value;
    this.transactionType = this._fiskalniService.getFormObject.controls.transactionType?.value;

    this._subs.add(
      this._fiskalniService.getFormSaveClicked.subscribe((res) => {
        if (res) {
          if (this.form.invalid) {
            this.form.markAllAsTouched();
          }
        }
      })
    );
  }

  /**
   * Na svaki unos u autocomplete, pretrazuje predmete stavki iz baze
   * @param event event poslat iz autocomplete
   */
  handleSearch(event: any) {
    if (this.form.value.searchValue.length < 1) {
      return;
    }

    this._subs.add(
      this._fiskalniClient
        .getReferentniFiskalniRacunAutocomplete(
          event.query,
          this.invoiceType,
          this.transactionType
        )
        .subscribe((result) => {
          this.referentniRacunSuggestions = result.data?.listReferentniFiskalniRacuniDto!;
        })
    );
  }

  handleSelect(value: IReferentniFiskalniRacunDto) {
    this._fiskalniService.setReferentniRacun(value);
    this.onChange.emit(value);
  }

  handleClear() {
    this._fiskalniService.resetReferentniRacun();
    this.form.reset();
    this.ctrlPlaceholder = '';
    this.onClear.emit();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
