import { Component, Input, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'kodit-podesavanja-card',
  templateUrl: './podesavanja-card.component.html',
  styleUrls: ['./podesavanja-card.component.scss'],
})
export class PodesavanjaCardComponent implements OnInit {
  @Input() cardTitle: string;
  @Input() cardSubtitle: string;
  @Input() cardIcon: string;
  @Input() cardLink: string;

  constructor(private _router: Router) {}

  ngOnInit(): void {}

  redirectToLink() {
    this._router.navigate(['/podesavanja', this.cardLink]);
  }
}
