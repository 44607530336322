import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  OznakaDto,
  RacuniClient,
  RemoveOznakaRacunaCommand,
} from '@kodit/core/data-api';
import { AlertService, SharedService } from '@kodit/core/services';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { AddOznakaFormComponent } from './add-oznaka-form/add-oznaka-form.component';
import { RemoveOznakaDialogComponent } from './remove-oznaka-dialog/remove-oznaka-dialog.component';

@Component({
  selector: 'kodit-oznake-racuna-info',
  templateUrl: './oznake-racuna-info.component.html',
  styleUrls: ['./oznake-racuna-info.component.scss'],
})
export class OznakeRacunaInfoComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** I/O */
  @Input() oznake: OznakaDto[] | undefined;
  @Input() racunId: number | undefined;
  @Input() canDelete: boolean | undefined;

  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  constructor(
    private _alertService: AlertService,
    private _dialogService: DynamicDialogService
  ) {}

  ngOnInit(): void {}

  onRemoveOznaka(index: number, oznaka: OznakaDto) {
    if (!this.racunId || !oznaka || !oznaka.id) {
      this._alertService.addFailedMsg('Racun ID ili oznaka ID nisu prisutne');
      return;
    }

    this.openRemoveOznakaDialog(oznaka, index);

    // this._sharedService.displayLoadingScreen(true, 'Brisanje oznake...');
    // this._subs.add(
    //   this._client
    //     .removeOznaka(
    //       new RemoveOznakaRacunaCommand({
    //         racunId: this.racunId,
    //         oznakaId: oznakaId,
    //       })
    //     )
    //     .subscribe((res) => {
    //       if (res.succeeded) {
    //         this.oznake.splice(index, 1);
    //         this._alertService.addSuccessMsg(res.messages[0]);
    //       } else {
    //         this._alertService.addFailedMsg(res.messages[0]);
    //       }
    //     })
    // );
  }

  openAddOznakaDialog() {
    this.dialogConfig.data = { racunId: this.racunId };
    this.dialogConfig.header = 'Dodavanje oznaka/e';
    this.dialogConfig.customSubmitButton = {
      label: 'Dodaj',
      icon: 'fas fa-plus',
    } as any;

    const ref = this._dialogService.open(
      AddOznakaFormComponent,
      this.dialogConfig
    );

    this._subs.add(
      ref.onClose.subscribe((result) => {
        if (result) {
          this.oznake = this.oznake.concat(result);
        }
      })
    );
  }

  openRemoveOznakaDialog(oznaka: OznakaDto, index: number) {
    this.dialogConfig.data = { racunId: this.racunId, oznaka: oznaka };
    this.dialogConfig.header = 'Brisanje oznake';
    this.dialogConfig.maximisable = false;
    this.dialogConfig.customSubmitButton = {
      label: 'Obriši',
      icon: 'far fa-trash-alt',
    } as any;

    const ref = this._dialogService.open(
      RemoveOznakaDialogComponent,
      this.dialogConfig
    );

    this._subs.add(
      ref.onClose.subscribe((result) => {
        if(result){
          this.oznake.splice(index, 1);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
