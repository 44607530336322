import { Component, OnInit } from '@angular/core';
import {
  IPredmetStavkeFilterDto,
  PredmetStavkeFilterDto,
} from '@kodit/core/data-api';
import { RobaFormService } from '@kodit/core/form-definitions';
import { AdvancedFilter } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { RobaFilterService } from './roba-filter.service';

@Component({
  selector: 'kodit-roba-filter',
  templateUrl: './roba-filter.component.html',
  styleUrls: ['./roba-filter.component.scss'],
})
export class RobaFilterComponent
  implements OnInit, AdvancedFilter<IPredmetStavkeFilterDto> {
  /** Props */
  filterData: IPredmetStavkeFilterDto;
  availableFilters?: {};
  form: FormGroupTypeSafe<IPredmetStavkeFilterDto>;

  constructor(
    private _robaFS: RobaFormService,
    private _filterService: RobaFilterService
  ) {}

  ngOnInit(): void {
    this.form = this._robaFS.GetRobaTableRequest(this.filterData);
  }

  onFilterSubmit() {
    this._filterService.setAdvanceFilterSubmitted = {
      filterData: this.form.value,
      shouldApplyFilter: true,
    };
  }

  onFilterHide(shouldReset: boolean) {
    this._filterService.setAdvanceFilterSubmitted = {
      filterData: shouldReset ? new PredmetStavkeFilterDto() : this.form.value,
      shouldApplyFilter: shouldReset,
    };
  }
}
