import { RouterModule, Routes } from '@angular/router';
import { DelovodnikPageComponent } from './delovodnik-page/delovodnik-page.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { DelovodnikInfoComponent } from './delovodnik-info/delovodnik-info.component';

const routes: Routes = [
  {
    path: '',
    component: DelovodnikPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'info/:id',

    data: {
      breadcrumb: 'Info',
    },
    component: DelovodnikInfoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DelovodnikRoutingModule {}
