import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { BaseService } from '@kodit/core/services';
import {
  GetRacunTableQuery,
  IPaginatedResultOfRacunTableDto,
  RacuniClient,
} from '@kodit/core/data-api';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KorisnikRacunTableResolver
  extends BaseService
  implements Resolve<IPaginatedResultOfRacunTableDto> {
  constructor(private _client: RacuniClient) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<IPaginatedResultOfRacunTableDto>
    | Promise<IPaginatedResultOfRacunTableDto>
    | IPaginatedResultOfRacunTableDto {
    const korisnikEmail = route.paramMap.get('email');
    return this._client
      .getForTable(
        new GetRacunTableQuery({
          createByEmail: korisnikEmail,
          pageNumber: 0,
          pageSize: 10,
        })
      )
      .pipe(
        map((x) => x),
        catchError(this.handleError),
        finalize(() => {
          //this._configService.setIsBusy = false;
        })
      );
  }
}
