<div class="p-flex p-flex-column">
  <div class="p-flex align-items-start justify-content-center subtitle">
    Vrsta računa
  </div>
  <div class="p-mt-3">
    <kodit-vrsta-fiskalnog-racuna-radiobutton
      [items]="saleItems"
      [selectedCategory]="formControl"
    ></kodit-vrsta-fiskalnog-racuna-radiobutton>
  </div>
  <div class="p-flex align-items-start justify-content-center p-mt-3 subtitle">
    Refundacija
  </div>
  <div class="p-d-flex p-ai-center p-jc-center p-mt-3">
    <kodit-vrsta-fiskalnog-racuna-radiobutton
      [items]="refundItems"
      [selectedCategory]="formControl"
    ></kodit-vrsta-fiskalnog-racuna-radiobutton>
  </div>
  <div class="p-flex align-items-start p-mt-3">
    <p-checkbox
      class="default_option"
      label="Primeni kao default opciju"
      [binary]="true"
    ></p-checkbox>
  </div>
</div>