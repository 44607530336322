import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  ConfirmPhoneCommand,
  IConfirmPhoneCommand,
  IResult,
  KorisniciClient,
  Result,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogRef, DynamicDialogConfig } from '@kodit/core/shared-ui';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'kodit-confirm-mobilni-telefon-form',
  templateUrl: './confirm-mobilni-telefon-form.component.html',
  styleUrls: ['./confirm-mobilni-telefon-form.component.scss'],
})
export class ConfirmMobilniTelefonFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _saveSub: Subscription = new Subscription();
  private _submitSub: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<IConfirmPhoneCommand>;
  mobilniTelefon: string;

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _dialogRef: DynamicDialogRef,
    private _alertService: AlertService,
    private _client: KorisniciClient,
    private _formHelper: FormHelper,
    private _dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group<IConfirmPhoneCommand>({
      code: new FormControl(null, {
        validators: [Validators.required],
      }),
      korisnikEmail: new FormControl(this._dialogConfig.data.korisnikEmail, {
        validators: [Validators.required],
      }),
    });
    this.mobilniTelefon = this._dialogConfig.data.mobilniTelefon;
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.save();
    }
  }

  private save() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._saveSub = this._client
      .confirmPhone(new ConfirmPhoneCommand(this.form.value))
      .subscribe(
        (result) => {
          this.blobToTextResult(result.data).subscribe((res) => {
            if (res.succeeded) {
              this._alertService.addSuccessMsg('Telefon je uspešno potvrđen.');
              this._dialogRef.close();
            } else {
              this._alertService.addFailedMsg(res.messages.join(', '));
            }
          });
        },
        (error) => {
          error.subscribe((e) => {
            this._alertService.addFailedMsg(e);
          });
        }
      );
  }

  blobToTextResult(blob: any): Observable<IResult> {
    return new Observable<IResult>((observer: any) => {
      if (!blob) {
        observer.next('');
        observer.complete();
      } else {
        let reader = new FileReader();
        let jsonResult: any;
        reader.onload = (event) => {
          let result: Result = new Result({ messages: [], succeeded: true });

          jsonResult = JSON.parse(reader.result as string);

          if (!jsonResult.succeeded) {
            if (jsonResult.messages) {
              jsonResult.messages.forEach((m: string) => {
                result.messages.push(m);
                result.succeeded = false;
              });
            } else {
              result.messages.push('Došlo je do nepoznate greške.');
              result.succeeded = false;
            }
          }

          observer.next(result);
          observer.complete();
        };
        reader.readAsText(blob);
      }
    });
  }

  ngOnDestroy(): void {
    this._submitSub.unsubscribe();
    this._saveSub.unsubscribe();
  }
}
