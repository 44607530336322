import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  IGetOznakaMultiselectResponse,
  OznakeClient,
  TipEntiteta,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { OznakaFormService } from '@kodit/core/form-definitions';

@Component({
  selector: 'kodit-oznaka-multiselect',
  templateUrl: './oznaka-multiselect.component.html',
  styleUrls: ['./oznaka-multiselect.component.scss'],
})
export class OznakaMultiselectComponent implements OnInit, OnDestroy {
  /** Props */
  oznakeSuggestions: IGetOznakaMultiselectResponse[] = [];
  oznakeModel: IGetOznakaMultiselectResponse[] = [];
  /** I/O */
  @Input() oznakeFormArray: FormArray;
  @Input() excludeTipoveEntiteta: TipEntiteta[] = [];
  /** Subs */
  private _searchSub: Subscription = new Subscription();

  constructor(
    private _client: OznakeClient,
    private _oznakaFs: OznakaFormService
  ) {}

  ngOnInit(): void {
    if (this.oznakeFormArray.value) {
      this.oznakeModel = this.oznakeFormArray.value;
    }
  }

  searchOznake(event: any) {
    this._searchSub = this._client
      .getForMultiselect(event.query)
      .subscribe((result) => {
        this.oznakeSuggestions = result.data;
      });
  }

  onOznakaSelect(oznaka: IGetOznakaMultiselectResponse) {
    this.oznakeFormArray.push(
      this._oznakaFs.GetOznakaMultiselectFormGroup(oznaka)
    );
  }

  onOznakaUnselect(oznaka: IGetOznakaMultiselectResponse) {
    const idx = (
      this.oznakeFormArray.value as Array<IGetOznakaMultiselectResponse>
    ).findIndex((x) => x.id === oznaka.id);
    this.oznakeFormArray.removeAt(idx);
  }

  ngOnDestroy() {
    this._searchSub.unsubscribe();
  }
}
