import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RacunTableComponent } from 'libs/core/shared-subforms/src/lib/racun/racun-table/racun-table.component';
import { RacunInfoComponent } from './info/racun-info/racun-info.component';
import { TipRacuna, VrstaRacuna } from '@kodit/core/data-api';
import { IzlaznaFakturaFormComponent } from './izlazne-fakture/izlazna-faktura-form/izlazna-faktura-form.component';
import { UlaznaFakturaFormComponent } from './ulazne-fakture/ulazna-faktura-form/ulazna-faktura-form.component';
import { IzlaznaFakturaResolver } from './izlazne-fakture/resolvers/izlazna-faktura-resolver';
import { UlaznaFakturaResolver } from './ulazne-fakture/resolvers/ulazna-faktura-resolver';
import { PredracunFormComponent } from './predracun/predracun-form/predracun-form.component';
import { PredracunResolver } from './predracun/resolvers/predracun-resolver';
import { IzlaznaFakturaZaUplatuResolver } from './izlazne-fakture/resolvers/izlazna-faktura-za-uplatu-resolver.service';
import { IzlaznaFakturaZaStrankuResolver } from './izlazne-fakture/resolvers/izlazna-faktura-za-stranku-resolver.service';
import { AvansniRacunFormComponent } from './avansni-racun/avansni-racun-form/avansni-racun-form.component';
import { AvansniRacunZaTransakcijuIzvodaResolver } from './avansni-racun/resolvers/avansni-racun-za-transakciju-izvoda-resolver.service';
import { IzlaznaFakturaZaPredracunResolver } from './izlazne-fakture/resolvers/izlazna-faktura-za-predracun-resolver.service';
import { IzlaznaFakturaZaRadniNalogResolver } from './izlazne-fakture/resolvers/izlazna-faktura-za-radni-nalog-resolver.service';
import { OtpremnicaFormComponent } from './otpremnica/otpremnica-form/otpremnica-form.component';
import { OtpremnicaResolver } from './otpremnica/resolvers/otpremnica-resolver.service';
import { IzlaznaFakturaZaOtpremniceResolver } from './izlazne-fakture/resolvers/izlazna-faktura-za-otpremnice-resolver.service';
import { IzlazniRacunPageComponent } from './izlazni-racun-page/izlazni-racun-page.component';
import { PredracunZaStrankuResolver } from './predracun/resolvers/predracun-za-stranku-resolver.service';
import { UlazniRacunPageComponent } from './ulazni-racun-page/ulazni-racun-page.component';
import { UlaznaFakturaZaIsplatuResolver } from './ulazne-fakture/resolvers/ulazna-faktura-za-isplatu-resolver.service';
import { UlaznaFakturaZaStrankuResolver } from './ulazne-fakture/resolvers/ulazna-faktura-za-stranku-resolver.service';
import { DokumentOPovecanjuResolver } from './knjizno-zaduzenje/resolver/dokument-o-povecanju-resolver copy';
import { NeobradjenRacunFormComponent } from './neobradjeni-racuni/neobradjen-racun-form/neobradjen-racun-form.component';
import { AvansniRacunResolver } from './avansni-racun/resolvers/avansni-racun-resolver';
import { RacunFormConfigurationResolver } from './resolvers/racun-form-configuration-resolver';
import { IzlaznaFakturaDuplikatResolver } from './izlazne-fakture/resolvers/izlazna-faktura-duplikat-resolver.service';
import { OtpremnicaDuplikatResolver } from './izlazne-fakture/resolvers/otpremnica-duplikat-resolver.service';
import { PredracunDuplikatResolver } from './izlazne-fakture/resolvers/predracun-duplikat-resolver.service';
import { RadniNalogFormComponent } from './radni-nalog/radni-nalog-form/radni-nalog-form.component';
import { OtpremnicaIzFaktureResolver } from './otpremnica/resolvers/otpremnica-iz-fakture-resolver.service';
import { OtpremnicaIzPredracunaResolver } from './otpremnica/resolvers/otpremnica-iz-predracuna-resolver.service';
import { AvansniRacunIzPredracunaResolverService } from './avansni-racun/resolvers/avansni-racun-iz-predracuna-resolver.service';
import { RadniNalogResolver } from './radni-nalog/resolvers/radni-nalog-resolver.service';
import { KompenzacijaFormComponent } from './kompenzacije/kompenzacija-form/kompenzacija-form.component';
import { KompenzacijaResolver } from './kompenzacije/resolver/kompenzacija-resolver';
import { AuthGuard } from '@kodit/core/services';
import { RadniNalogTableComponent } from './radni-nalog/radni-nalog-table/radni-nalog-table.component';
import { AvansniRacunZaPdvResolver } from './avansni-racun/resolvers/avansni-racun-za-pdv.service';
import { AutomatskaObradaSefRacunaResolver } from './resolvers/automatska-obrada-sef-racuna-resolver';
import { RadniNalogDuplikatResolver } from './radni-nalog/resolvers/radni-nalog-duplikat-resolver.service';
import { PredracunIzPonudeResolver } from './otpremnica/resolvers/predracun-iz-ponude-resolver.service';
import { UlaznaFakturaDuplikatResolver } from './ulazne-fakture/resolvers/ulazna-faktura-duplikat-resolver.service';
import { AvansniRacunIzPredracunUplateResolver } from './avansni-racun/resolvers/avansni-racuni-iz-predracun-uplata-resolver';
import { DokumentOSmanjenjuFormComponent } from './knjizno-odobrenje/dokument-o-smanjenju-form/dokument-o-smanjenju-form.component';
import { DokumentOSmanjenjuResolver } from './knjizno-odobrenje/resolver/dokument-o-smanjenju-resolver';
import { DokumentOPovecanjuFormComponent } from './knjizno-zaduzenje/dokument-o-povecanju-form/dokument-o-povecanju-form.component';

const routes: Routes = [
  {
    path: '',
    children: [
      //
      // Izlazni racuni
      //
      {
        path: 'izlazni-racuni',
        canActivate: [AuthGuard],
        data: {
          vrstaRacuna: VrstaRacuna.IZLAZNI,
          breadcrumb: 'Izlazni računi',
        },
        children: [
          {
            canActivate: [AuthGuard],
            path: '',
            pathMatch: 'full',
            component: IzlazniRacunPageComponent,
          },
          // UNOS
          {
            path: 'unos',
            canActivate: [AuthGuard],
            data: {
              breadcrumb: { skip: true },
            },
            resolve: {
              formConfiguration: RacunFormConfigurationResolver,
            },
            children: [
              //#region Unos izlazne fakture
              {
                path: 'faktura',
                data: {
                  breadcrumb: 'Unos izlazne fakture',
                  useDefaultPdvValue: true,
                },
                component: IzlaznaFakturaFormComponent,
              },
              {
                path: 'faktura-za-uplatu',
                data: {
                  breadcrumb: 'Unos izlazne fakture za uplatu'
                },
                component: IzlaznaFakturaFormComponent,
                resolve: {
                  racun: IzlaznaFakturaZaUplatuResolver,
                },
              },
              {
                path: 'faktura-za-otpremnicu',
                pathMatch: 'full',
                component: IzlaznaFakturaFormComponent,
                resolve: {
                  racun: IzlaznaFakturaZaOtpremniceResolver,
                },
                data: {
                  breadcrumb: 'Unos izlazne fakture za otpremnicu',
                  jeKonvertovanje: true,
                  jeKonvertovanjeOtpremniceUFakturu: true,
                },
              },
              {
                path: 'faktura-iz-predracuna',
                pathMatch: 'full',
                component: IzlaznaFakturaFormComponent,
                resolve: {
                  racun: IzlaznaFakturaZaPredracunResolver,
                },
                data: {
                  breadcrumb: 'Unos izlazne fakture od predračuna',
                },
              },
              {
                path: 'faktura-iz-radnog-naloga',
                pathMatch: 'full',
                component: IzlaznaFakturaFormComponent,
                resolve: {
                  racun: IzlaznaFakturaZaRadniNalogResolver,
                },
                data: {
                  breadcrumb: 'Unos izlazne fakture od radnog naloga',
                },
              },
              {
                path: 'faktura-duplikat',
                pathMatch: 'full',
                component: IzlaznaFakturaFormComponent,
                resolve: {
                  racun: IzlaznaFakturaDuplikatResolver,
                },
                data: {
                  breadcrumb: 'Kreiranje duplikata fakture',
                  jeDuplikat: true,
                },
              },
              {
                path: 'faktura-za-stranku/:strankaId',
                pathMatch: 'full',
                component: IzlaznaFakturaFormComponent,
                resolve: {
                  racun: IzlaznaFakturaZaStrankuResolver,
                },
                data: {
                  breadcrumb: {
                    alias: 'unosIzlazneFaktureZaStranku',
                  },
                },
              },
              {
                path: 'faktura-za-predracun/:predracunId',
                pathMatch: 'full',
                component: IzlaznaFakturaFormComponent,
                resolve: {
                  racun: IzlaznaFakturaZaPredracunResolver,
                },
                data: {
                  breadcrumb: 'Unos izlazne fakture za predračun',
                },
              },
              //#endregion Unos izlazne fakture
              {
                path: 'avansni-racun-za-uplatu',
                data: {
                  breadcrumb: 'Unos avansnog računa za uplatu',
                },
                component: AvansniRacunFormComponent,
                resolve: {
                  racun: AvansniRacunZaTransakcijuIzvodaResolver,
                },
              },
              {
                path: 'avansni-racun-za-pdv',
                data: {
                  breadcrumb: 'Unos avansnog računa za pdv',
                  jeAvansniPrekoTabele: true,
                },
                component: AvansniRacunFormComponent,
                resolve: {
                  racun: AvansniRacunZaPdvResolver,
                },
              },
              {
                path: 'avansni-racun-iz-predracuna/:predracunId',
                pathMatch: 'full',
                component: AvansniRacunFormComponent,
                resolve: {
                  racun: AvansniRacunIzPredracunaResolverService,
                  formConfiguration: RacunFormConfigurationResolver,
                },
                data: {
                  breadcrumb: 'Unos avansnog računa iz predračuna',
                  jeAvansniPrekoPredracuna: true,
                },
              },
              {
                path: 'avansni-racun-iz-predracun-uplate',
                component: AvansniRacunFormComponent,
                resolve: {
                  racun: AvansniRacunIzPredracunUplateResolver,
                  formConfiguration: RacunFormConfigurationResolver,
                },
                data: {
                  breadcrumb: 'Unos avansnog računa iz uplate za predračun',
                  shouldUpdateModel: true
                },
              },
              //#region Unos dokumenta o smanjenju
              {
                path: 'dokument-o-smanjenju',
                data: {
                  breadcrumb: 'Unos dokumenta o smanjenju',
                  useDefaultPdvValue: true,
                },
                component: DokumentOSmanjenjuFormComponent,
              },
              // {
              //   path: 'dokument-o-smanjenju-za-stranku/:strankaId',
              //   pathMatch: 'full',
              //   component: DokumentOSmanjenjuFormComponent,
              //   resolve: {
              //     racun: DokumentOSmanjenjuZaStrankuResolver,
              //   },
              //   data: {
              //     breadcrumb: {
              //       alias: 'unosDokumentaOSmanjenjuZaStranku',
              //     },
              //   },
              // },
              //#endregion Unos dokumenta o smanjenju
              //#region Unos dokumenta o povecanju
              {
                path: 'dokument-o-povecanju',
                data: {
                  breadcrumb: 'Unos dokumenta o povećanju',
                  useDefaultPdvValue: true,
                },
                component: DokumentOPovecanjuFormComponent,
              },
              //#endregion Unos dokumenta o povecanju
              //#region Unos kompenzacije
              {
                path: 'kompenzacija',
                data: {
                  breadcrumb: 'Unos izlazne kompenzacije',
                },
                component: KompenzacijaFormComponent,
              },
              //#endregion
            ],
          },
          // IZMENA
          {
            path: 'izmena',
            canActivate: [AuthGuard],
            data: {
              breadcrumb: { skip: true },
            },
            resolve: {
              formConfiguration: RacunFormConfigurationResolver,
            },
            children: [
              {
                path: 'faktura/:id',
                data: {
                  breadcrumb: {
                    alias: 'izmenaIzlazneFakture',
                  },
                },
                component: IzlaznaFakturaFormComponent,
                resolve: {
                  racun: IzlaznaFakturaResolver,
                },
              },
              {
                path: 'avansni-racun/:id',
                data: {
                  breadcrumb: {
                    alias: 'izmenaAvansnogRacuna',
                  },
                },
                component: AvansniRacunFormComponent,
                resolve: {
                  racun: AvansniRacunResolver,
                },
              },
              {
                path: 'dokument-o-smanjenju/:id',
                data: {
                  breadcrumb: {
                    alias: 'izmenaDokumentaOSmanjenju',
                  },
                },
                component: DokumentOSmanjenjuFormComponent,
                resolve: {
                  racun: DokumentOSmanjenjuResolver,
                },
              },
              {
                path: 'dokument-o-povecanju/:id',
                data: {
                  breadcrumb: {
                    alias: 'izmenaDokumentaOPovecanju',
                  },
                },
                component: DokumentOPovecanjuFormComponent,
                resolve: {
                  racun: DokumentOPovecanjuResolver,
                },
              },
              {
                path: 'kompenzacija/:id',
                data: {
                  breadcrumb: {
                    alias: 'izmenaKompenzacije',
                  },
                },
                component: KompenzacijaFormComponent,
                resolve: {
                  racun: KompenzacijaResolver,
                },
              },
            ],
          },
          // INFO
          {
            path: 'kartica/:id',
            component: RacunInfoComponent,
            data: {
              breadcrumb: {
                alias: 'racunInfo',
              },
            },
          },
          // DEFAULT
          { path: '**', redirectTo: '' },
        ],
      },

      //
      // Ulazni racuni
      //
      {
        path: 'ulazni-racuni',
        canActivate: [AuthGuard],
        data: {
          vrstaRacuna: VrstaRacuna.ULAZNI,
          breadcrumb: 'Ulazni računi',
        },
        children: [
          {
            canActivate: [AuthGuard],
            path: '',
            pathMatch: 'full',
            component: UlazniRacunPageComponent,
          },
          // UNOS
          {
            path: 'unos',
            canActivate: [AuthGuard],
            data: {
              breadcrumb: { skip: true },
            },
            resolve: {
              formConfiguration: RacunFormConfigurationResolver,
            },
            children: [
              //#region Unos ulazne fakture
              {
                path: 'faktura',
                data: {
                  breadcrumb: 'Unos ulazne fakture',
                  useDefaultPdvValue: true,
                },
                component: UlaznaFakturaFormComponent,
              },
              {
                path: 'faktura-za-isplatu',
                data: {
                  breadcrumb: 'Unos ulazne fakture za isplatu',
                },
                component: UlaznaFakturaFormComponent,
                resolve: {
                  racun: UlaznaFakturaZaIsplatuResolver,
                },
              },
              {
                path: 'faktura-za-stranku/:strankaId',
                pathMatch: 'full',
                component: UlaznaFakturaFormComponent,
                resolve: {
                  racun: UlaznaFakturaZaStrankuResolver,
                },
                data: {
                  breadcrumb: {
                    alias: 'unosUlazneFaktureZaStranku',
                  },
                },
              },
              {
                path: 'faktura-duplikat',
                pathMatch: 'full',
                component: UlaznaFakturaFormComponent,
                resolve: {
                  racun: UlaznaFakturaDuplikatResolver,
                },
                data: {
                  breadcrumb: 'Kreiranje duplikata fakture',
                  jeDuplikat: true,
                },
              },
              //#endregion Unos izlazne fakture
              {
                path: 'avansni-racun-za-isplatu',
                data: {
                  breadcrumb: 'Unos avansnog računa za isplatu',
                  useDefaultPdvValue: false,
                },
                component: AvansniRacunFormComponent,
                resolve: {
                  racun: AvansniRacunZaTransakcijuIzvodaResolver,
                },
              },
              {
                path: 'avansni-racun-za-pdv',
                data: {
                  breadcrumb: 'Unos avansnog računa za pdv',
                  jeAvansniPrekoTabele: true,
                  useDefaultPdvValue: false,
                },
                component: AvansniRacunFormComponent,
                resolve: {
                  racun: AvansniRacunZaPdvResolver,
                },
              },
              //#region Unos dokumenta o smanjenju
              {
                path: 'dokument-o-smanjenju',
                data: {
                  breadcrumb: 'Unos dokumenta o smanjenju',
                  useDefaultPdvValue: true,
                },
                component: DokumentOSmanjenjuFormComponent,
              },
              // {
              //   path: 'dokument-o-smanjenju-za-stranku/:strankaId',
              //   pathMatch: 'full',
              //   component: DokumentOSmanjenjuFormComponent,
              //   resolve: {
              //     racun: DokumentOSmanjenjuZaStrankuResolver,
              //   },
              //   data: {
              //     breadcrumb: {
              //       alias: 'unosDokumentaOSmanjenjuZaStranku',
              //     },
              //   },
              // },
              //#endregion Unos dokumenta o smanjenju
              //#region Unos dokumenta o povecanju
              {
                path: 'dokument-o-povecanju',
                data: {
                  breadcrumb: 'Unos dokumenta o povećanju',
                  useDefaultPdvValue: true,
                },
                component: DokumentOPovecanjuFormComponent,
              },
              // {
              //   path: 'dokument-o-povecanju-za-stranku/:strankaId',
              //   pathMatch: 'full',
              //   component: DokumentOPovecanjuFormComponent,
              //   resolve: {
              //     racun: DokumentOPovecanjuZaStrankuResolver,
              //   },
              //   data: {
              //     breadcrumb: {
              //       alias: 'unosDokumentaOPovecanjuZaStranku',
              //     },
              //   },
              // },
              //#endregion Unos dokumenta o povecanju
              //#region Unos kompenzacije
              {
                path: 'kompenzacija',
                data: {
                  breadcrumb: 'Unos ulazne kompenzacije',
                  useDefaultPdvValue: true,
                },
                component: KompenzacijaFormComponent,
              },
              //#endregion
            ],
          },
          // IZMENA
          {
            path: 'izmena',
            canActivate: [AuthGuard],
            data: {
              breadcrumb: { skip: true },
            },
            resolve: {
              formConfiguration: RacunFormConfigurationResolver,
            },
            children: [
              {
                path: 'faktura/:id',
                data: {
                  breadcrumb: {
                    alias: 'izmenaUlazneFakture',
                  },
                },
                component: UlaznaFakturaFormComponent,
                resolve: {
                  racun: UlaznaFakturaResolver,
                },
              },
              {
                path: 'avansni-racun/:id',
                data: {
                  breadcrumb: {
                    alias: 'izmenaAvansnogRacuna',
                  },
                },
                component: AvansniRacunFormComponent,
                resolve: {
                  racun: AvansniRacunResolver,
                },
              },
              {
                path: 'dokument-o-smanjenju/:id',
                data: {
                  breadcrumb: {
                    alias: 'izmenaDokumentaOSmanjenju',
                  },
                },
                component: DokumentOSmanjenjuFormComponent,
                resolve: {
                  racun: DokumentOSmanjenjuResolver,
                },
              },
              {
                path: 'dokument-o-povecanju/:id',
                data: {
                  breadcrumb: {
                    alias: 'izmenaDokumentaOPovecanju',
                  },
                },
                component: DokumentOPovecanjuFormComponent,
                resolve: {
                  racun: DokumentOPovecanjuResolver,
                },
              },
              {
                path: 'kompenzacija/:id',
                data: {
                  breadcrumb: {
                    alias: 'izmenaKompenzacije',
                  },
                },
                component: KompenzacijaFormComponent,
                resolve: {
                  racun: KompenzacijaResolver,
                },
              },
            ],
          },
          // INFO
          {
            path: 'kartica/:id',
            component: RacunInfoComponent,
            data: {
              breadcrumb: {
                alias: 'racunInfo',
              },
            },
          },
          // OBRADA
          // {
          //   path: 'obrada-sef-racuna/:sefRacunId',
          //   canActivate: [AuthGuard],
          //   pathMatch: 'full',
          //   component: NeobradjenRacunFormComponent,
          //   data: {
          //     breadcrumb: 'Obrada ulaznog računa sa SEF-a',
          //   },
          //   resolve: {
          //     formConfiguration: RacunFormConfigurationResolver,
          //   },
          // },
          // AUTOMATSKA OBRADA
          {
            path: 'automatska-obrada-sef-racuna/:sefRacunId',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: NeobradjenRacunFormComponent,
            data: {
              breadcrumb: 'Automatska obrada ulaznog računa sa SEF-a',
            },
            resolve: {
              formConfiguration: RacunFormConfigurationResolver,
              neobradjenRacun: AutomatskaObradaSefRacunaResolver,
            },
          },
          // DEFAULT
          { path: '**', redirectTo: '' },
        ],
      },

      //
      // Predracuni
      //
      {
        path: 'predracuni',
        canActivate: [AuthGuard],
        data: {
          tipRacuna: TipRacuna.PREDRACUN,
          vrstaRacuna: VrstaRacuna.IZLAZNI,
          breadcrumb: 'Predračuni',
        },
        children: [
          {
            canActivate: [AuthGuard],
            path: '',
            pathMatch: 'full',
            component: RacunTableComponent,
          },
          {
            path: 'unos',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: PredracunFormComponent,
            data: {
              breadcrumb: 'Unos predračuna',
              useDefaultPdvValue: true,
            },
            resolve: {
              formConfiguration: RacunFormConfigurationResolver,
            },
          },
          {
            path: 'unos-za-stranku/:strankaId',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: PredracunFormComponent,
            resolve: {
              racun: PredracunZaStrankuResolver,
              formConfiguration: RacunFormConfigurationResolver,
            },
            data: {
              breadcrumb: {
                alias: 'unosPredracunaZaStranku',
              },
            },
          },
          {
            path: 'unos-predracun-duplikat',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: PredracunFormComponent,
            resolve: {
              racun: PredracunDuplikatResolver,
              formConfiguration: RacunFormConfigurationResolver,
            },
            data: {
              breadcrumb: 'Kreiranje duplikata predracuna',
              jeDuplikat: true
            },
          },
          {
            path: 'izmena/:id',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: PredracunFormComponent,
            resolve: {
              racun: PredracunResolver,
              formConfiguration: RacunFormConfigurationResolver,
            },
            data: {
              breadcrumb: {
                alias: 'izmenaPredracuna',
              },
            },
          },
          {
            path: 'kartica/:id',
            pathMatch: '',
            component: RacunInfoComponent,
            data: {
              breadcrumb: {
                alias: 'racunInfo',
              },
            },
          },
          {
            path: 'unos-predracun-iz-ponude/:racunId',
            pathMatch: 'full',
            component: PredracunFormComponent,
            resolve: {
              racun: PredracunIzPonudeResolver,
              formConfiguration: RacunFormConfigurationResolver,
            },
            data: {
              breadcrumb: 'Unos predračuna iz ponude',
            },
          },

          { path: '**', redirectTo: '' },
        ],
      },

      //
      // Otpremnice
      //
      {
        path: 'otpremnice',
        canActivate: [AuthGuard],
        data: {
          tipRacuna: TipRacuna.OTPREMNICA,
          vrstaRacuna: VrstaRacuna.IZLAZNI,
          breadcrumb: 'Otpremnice',
        },
        children: [
          {
            canActivate: [AuthGuard],
            path: '',
            pathMatch: 'full',
            component: RacunTableComponent,
          },
          {
            path: 'unos',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: OtpremnicaFormComponent,
            data: {
              breadcrumb: 'Unos otpremnice',
              useDefaultPdvValue: true,
            },
            resolve: {
              formConfiguration: RacunFormConfigurationResolver,
            },
          },
          {
            path: 'unos-otpremnica-iz-fakture/:racunId',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: OtpremnicaFormComponent,
            resolve: {
              racun: OtpremnicaIzFaktureResolver,
              formConfiguration: RacunFormConfigurationResolver,
            },
            data: {
              breadcrumb: 'Unos otpremnice iz izlazne fakture',
              jeDuplikat: true,
              jeKonvertovanje: true
            },
          },
          {
            path: 'unos-otpremnica-iz-predracuna/:predracunId',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: OtpremnicaFormComponent,
            resolve: {
              racun: OtpremnicaIzPredracunaResolver,
              formConfiguration: RacunFormConfigurationResolver,
            },
            data: {
              breadcrumb: 'Unos otpremnice iz predračuna',
            },
          },

          {
            path: 'unos-otpremnice-duplikat',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: OtpremnicaFormComponent,
            resolve: {
              racun: OtpremnicaDuplikatResolver,
              formConfiguration: RacunFormConfigurationResolver,
            },
            data: {
              breadcrumb: 'Kreiranje duplikata otpremnice',
              jeDuplikat: true,
            },
          },
          {
            path: 'unos-za-stranku/:strankaId',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: OtpremnicaFormComponent,
            resolve: {
              racun: PredracunZaStrankuResolver,
              formConfiguration: RacunFormConfigurationResolver,
            },
            data: {
              breadcrumb: {
                alias: 'unosOtpremniceZaStranku',
              },
            },
          },
          {
            path: 'izmena/:id',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: OtpremnicaFormComponent,
            resolve: {
              racun: OtpremnicaResolver,
              formConfiguration: RacunFormConfigurationResolver,
            },
            data: {
              breadcrumb: {
                alias: 'izmenaOtpremnice',
              },
            },
          },
          {
            path: 'kartica/:id',
            pathMatch: '',
            component: RacunInfoComponent,
            data: {
              breadcrumb: {
                alias: 'racunInfo',
              },
            },
          },
          { path: '**', redirectTo: '' },
        ],
      },

      //
      // Radni Nalozi
      //
      {
        path: 'radni-nalozi',
        canActivate: [AuthGuard],
        data: {
          tipRacuna: TipRacuna.RADNI_NALOG,
          vrstaRacuna: VrstaRacuna.IZLAZNI,
          breadcrumb: 'Radni nalozi',
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: RadniNalogTableComponent,
          },
          {
            path: 'unos',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: RadniNalogFormComponent,
            data: {
              breadcrumb: 'Unos radnog naloga',
              useDefaultPdvValue: true,
            },
            resolve: {
              formConfiguration: RacunFormConfigurationResolver,
            },
          },
          {
            path: 'radni-nalog-duplikat',
            pathMatch: 'full',
            component: RadniNalogFormComponent,
            resolve: {
              racun: RadniNalogDuplikatResolver,
              formConfiguration: RacunFormConfigurationResolver,
            },
            data: {
              breadcrumb: 'Unos duplikata radnog naloga',
            },
          },
          {
            path: 'izmena/:id',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: RadniNalogFormComponent,
            resolve: {
              racun: RadniNalogResolver,
              formConfiguration: RacunFormConfigurationResolver,
            },
            data: {
              breadcrumb: {
                alias: 'izmenaRadnogNaloga',
              },
            },
          },
          {
            path: 'kartica/:id',
            pathMatch: '',
            component: RacunInfoComponent,
            data: {
              breadcrumb: {
                alias: 'racunInfo',
              },
            },
          },
          { path: '**', redirectTo: '' },
        ],
      },

      //
      // Ponude
      //
      {
        path: 'ponude',
        canActivate: [AuthGuard],
        data: {
          tipRacuna: TipRacuna.PONUDA,
          vrstaRacuna: VrstaRacuna.IZLAZNI,
          breadcrumb: 'Ponude',
        },
        children: [
          {
            canActivate: [AuthGuard],
            path: '',
            pathMatch: 'full',
            component: RacunTableComponent,
          },
          {
            path: 'unos',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: PredracunFormComponent,
            data: {
              breadcrumb: 'Unos ponude',
              useDefaultPdvValue: true,
              jePonuda: true,
            },
            resolve: {
              formConfiguration: RacunFormConfigurationResolver,
            },
          },
          {
            path: 'izmena/:id',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            component: PredracunFormComponent,
            resolve: {
              racun: PredracunResolver,
              formConfiguration: RacunFormConfigurationResolver,
            },
            data: {
              jePonuda: true,
              breadcrumb: {
                alias: 'izmenaPonude',
              },
            },
          },
          {
            path: 'kartica/:id',
            pathMatch: '',
            component: RacunInfoComponent,
            data: {
              breadcrumb: {
                alias: 'racunInfo',
              },
            },
          },
          { path: '**', redirectTo: '' },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'izlazni-racuni',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class RacunRoutingModule { }
