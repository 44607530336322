import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FilterChipDto,
  GetPravnoLiceTableQuery, IPravnoLiceFilterDto,
  PravnaLicaClient,
  PravnoLiceFilterDto,
  PravnoLiceTableDto,
  ResultOfGetTableResponseOfPravnoLiceTableDto,
  ResultOfPravnoLiceVm,
  SaldoStrankaDto,
  SaldoStrankeClient,
  TipPravnogLica,
  TipPristupa,
  TipStranke,
} from '@kodit/core/data-api';
import { LocationService } from '@kodit/core/services';
import {
  DynamicDialogConfig,
  DynamicDialogRef,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import {
  DialogSize,
  OnDynamicDialogInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  ActionClass,
  ActionType,
  OnTableInit,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { MenuItem } from 'primeng/api/menuitem';
import { Subscription } from 'rxjs';
import { PravnoLiceFilterComponent } from '../pravno-lice-filter/pravno-lice-filter.component';
import { PravnoLiceFormComponent } from '../pravno-lice-form/pravno-lice-form.component';
import {nameofFactory, toCamelCase} from '@kodit/core/shared';
import { Router } from '@angular/router';
import { PravnoLiceExportFormComponent } from '../pravno-lice-export-form/pravno-lice-export-form.component';
import { Store } from '@ngrx/store';
import { selectFilterByKey } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.selectors';
import { clearFilter, setFilter } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';

@Component({
  selector: 'kodit-pravno-lice-table',
  templateUrl: './pravno-lice-table.component.html',
  styleUrls: ['./pravno-lice-table.component.scss'],
})
/** Subs */
export class PravnoLiceTableComponent
  implements OnInit, OnDestroy, OnTableInit, OnDynamicDialogInit {
  private _subs: Subscription = new Subscription();

  /** Props */
  pravnaLicaTable: PravnoLiceTableDto[] = [];
  chipItems: FilterChipDto[] = [];
  saldoStrankaDto: SaldoStrankaDto;
  filters: PravnoLiceFilterDto;

  /** Configs */
  tableConfig: TableConfig;
  skipFirst = 0;
  numberOfRowsDisplayed = 0;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.LARGE);
  dialogConfigExport: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.SMALL
  );

  //save filter and paginate variables
  tableSessionStorage: IPravnoLiceFilterDto;
  routerChangeDetected: boolean;

  constructor(
    private _dialogService: DynamicDialogService,
    private _client: PravnaLicaClient,
    private _saldoClient: SaldoStrankeClient,
    private _locationService: LocationService,
    private _router: Router,
    private _storage: Store
  ) {}

  ngOnInit(): void {
    //check if page is reloaded
    if (this._router.onSameUrlNavigation == 'reload') {
      this.routerChangeDetected = true;
    }

    this._subs.add(
      this._storage
        .select(selectFilterByKey('PRAVNA_LICA'))
        .subscribe((data) => {
          if (data) {
            this.filters = JSON.parse(data);
          } else {
            this.filters = new PravnoLiceFilterDto();
          }
          this._load();
        })
    );
    // set table config
    this.setTableConfig();
  }

  //#region Table configs

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Pravna lica',
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete izabrana pravna lica i sve njihove podatke?',
      deleteMessage:
        'Da li ste sigurni da želite da izbrišete pravno lice {param} i sve njegove podatke?',
      deleteMessageParams: 'naziv',
      columns: [
        {
          field: 'naziv',
          header: '',
          type: 'initials',
        },
        {
          field: 'naziv',
          header: 'Naziv',
          type: 'link',
          linkCallbackFunction: (itemIndex: number) => this._onInfo(itemIndex),
          columns: [{ field: 'adresa', header: 'Adresa', type: 'text' }],
        },
        {
          field: 'jeKupac',
          header: 'Tip',
          type: 'badge',
          styleClass: 'tip-pravnog-lica',
          columns: [
            {
              field: 'jeDobavljac',
              header: 'Tip',
              styleClass: 'tip-pravnog-lica',
              type: 'badge',
            },
          ],
        },
        {
          field: 'jeUSistemuPDV',
          header: 'U sistemu pdv-a',
          type: 'badge',
          styleClass: 'je-u-sistemu-pdv',
        },
      ],
      headerActions: [
        {
          type: ActionType.CUSTOM,
          label: 'Unesi domaće',
          icon: 'fas fa-plus',
          tipPristupa: TipPristupa.STRANKA_CRUD,
          hasAccessTooltip: 'Unesite domaće pravno lice',
          noAccessTooltip: 'Nemate ovlašćenja za unos pravnog lica',
          callback: () => {
            this.dialogConfig.data = {
              tipPravnogLica: TipPravnogLica.DOMACE,
            };
            this.dialogConfig.header = 'Unos podataka domaćeg pravnog lica';
            this.openDialog(this.dialogConfig);
          },
        },
        {
          type: ActionType.CUSTOM,
          label: 'Unesi inostrano',
          icon: 'fas fa-plus',
          tipPristupa: TipPristupa.STRANKA_CRUD,
          hasAccessTooltip: 'Unesite inostrano',
          noAccessTooltip: 'Nemate ovlašćenja za unos pravnog lica',
          callback: () => {
            this.dialogConfig.data = {
              tipPravnogLica: TipPravnogLica.STRANO,
            };
            this.dialogConfig.header = 'Unos podataka inostranog pravnog lica';
            this.openDialog(this.dialogConfig);
          },
        },
        {
          type: ActionType.CUSTOM,
          actionClass: ActionClass.OUTLINED,
          label: 'Izvezi',
          icon: 'fas fa-file-export',
          tipPristupa: TipPristupa.STRANKA_CRUD,
          hasAccessTooltip: 'Izvezi pravna lica',
          noAccessTooltip: 'Nemate ovlašćenja za izvoz pravnih lica',
          callback: () => {
            this.dialogConfigExport.header = 'Izvoz pravnih lica';
            this.dialogConfigExport.maximisable = false;
            this._dialogService.open(
              PravnoLiceExportFormComponent,
              this.dialogConfigExport
            );
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          tipPristupa: TipPristupa.STRANKA_CRUD,
          hasAccessTooltip: 'Izmenite pravno lice',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu pravnog lica',
          callback: (index: number) => {
            const forEdit = this.pravnaLicaTable[index];
            this.dialogConfig.data = {
              id: forEdit?.id,
              tipPravnogLica: forEdit.tipPravnogLica,
            };
            const tipText =
              forEdit.tipPravnogLica === TipPravnogLica.DOMACE
                ? 'domaćeg'
                : 'inostranog';
            this.dialogConfig.header = `Izmena podataka ${tipText} pravnog lica`;
            this.openDialog(this.dialogConfig);
          },
        },
        {
          type: ActionType.CUSTOM,
          icon: 'fab fa-buffer',
          hasAccessTooltip: 'Pogledajte detalje pravnog lica',
          noAccessTooltip: 'Nemate ovlašćenja za pregled detalja pravnog lica',
          callback: (index: number) => {
            this._onInfo(index);
          },
        },
      ],
      advancedFilter: {
        component: PravnoLiceFilterComponent,
        data: new PravnoLiceFilterDto(),
      },
    });
  }

  private _handleChipRemove(removedChip: FilterChipDto) {
    const nameof = nameofFactory<GetPravnoLiceTableQuery>();

    const nameOfStranke = nameof('stranke');

    if (removedChip.key.toLowerCase() === nameOfStranke.toLowerCase()) {
      const idx = (this.tableConfig.advancedFilter
        .data as GetPravnoLiceTableQuery).stranke.findIndex(
        (x) => x.naziv === removedChip.defaultValue
      );
      (this.tableConfig.advancedFilter
        .data as GetPravnoLiceTableQuery).stranke.splice(idx, 1);
    } else {
      this.tableConfig.advancedFilter.data[toCamelCase(removedChip.key)] =
        removedChip.defaultValue;
    }
    this._updateFilters();
  }

  private _updateFilters() {
    if (this._suFilteriPrazni()) {
      this._storage.dispatch(
        clearFilter({
          key: 'PRAVNA_LICA',
        })
      );
      return;
    }

    this._storage.dispatch(
      setFilter({
        key: 'PRAVNA_LICA',
        filter: JSON.stringify(this.filters),
      })
    );
  }

  private _suFilteriPrazni() {
    for (const prop of Object.keys(this.filters) as Array<
      keyof PravnoLiceFilterDto
    >) {
      if (this.filters[prop] !== '' && this.filters[prop]!== null && this.filters[prop].length > 0) {
        return false;
      }
    }
    return true;
  }

  getMoreActionsConfig(): MenuItem[] {
    return [
      {
        label: '',
        icon: 'far fa-eye',
        command: (itemIndex: number) => {
          this._onInfo(itemIndex);
        },
        title: 'Prikaz detalje pravnog lica',
      },
    ];
  }

  private _onInfo(itemIndex: number) {
    this._locationService.routeToStrankaKartica(
      this.pravnaLicaTable[itemIndex].id,
      TipStranke.PRAVNO_LICE
    );
  }

  //#endregion Table configs

  openDialog(config: DynamicDialogConfig) {
    let ref: DynamicDialogRef = this._dialogService.open(
      PravnoLiceFormComponent,
      config
    );

    this._subs.add(
      ref.onClose.subscribe((result: ResultOfPravnoLiceVm) => {
        if (result) {
          const idx = this.pravnaLicaTable.findIndex(
            (x) => x.id === result.data.pravnoLiceTableDto.id
          );
          if (idx > -1) {
            // update
            this.pravnaLicaTable[idx] = result.data.pravnoLiceTableDto;
          } else {
            // create
            this.pravnaLicaTable.push(result.data.pravnoLiceTableDto);
          }
        }
      })
    );
  }

  private _load() {
    this._subs.add(
      this._client
        .getForTable(this.filters as GetPravnoLiceTableQuery)
        .subscribe((res: ResultOfGetTableResponseOfPravnoLiceTableDto) => {
          this.pravnaLicaTable = res.data.responseList;
          this.chipItems = res.data.activeFilters;
          this.tableConfig.advancedFilter.data = this.filters;
        })
    );

    this._subs.add(
      this._saldoClient.getSaldoStranke().subscribe((res) => {
        this.saldoStrankaDto = res.data.saldoStrankaDto;
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
