import { Injectable } from '@angular/core';
import {
  KorisniciClient,
  ResultOfGetKorisnikTenantAccessResponse,
} from '@kodit/core/data-api';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const REFRESH_TOKEN_REMAINING_TIME_STRING =
  'REFRESH_TOKEN_REMAINING_TIME';
export const REFRESH_TOKEN_REMAINING_TIME_IN_SECONDS = 1800;
export const REFRESH_TOKEN_DIALOG_SHOW_SECONDS = 120;

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(private _korisniciClient: KorisniciClient) {

  }

  getKorisnikTenantAccesses(
    korisnikMail: string
  ): Observable<ResultOfGetKorisnikTenantAccessResponse> {
    return this._korisniciClient.getKorisnikTenantAccesses(korisnikMail).pipe(
      map((x) => x.data)
      //catchError()
    );
  }

  /**
   * Local storage save RefreshTokenRemainingTime
   */

  setRemainingTimeToLocalStorage(time: number) {
    localStorage.setItem(REFRESH_TOKEN_REMAINING_TIME_STRING, time.toString());
  }

  /**
   * Timer koji nam sluzi za cuvanje preostalog vremena trajanja refresh i auth tokena
   */
  remainingTimerSeconds$ = new BehaviorSubject<number>(
    REFRESH_TOKEN_REMAINING_TIME_IN_SECONDS
  );

  set setRemainingTimerSeconds(data: number) {
    this.remainingTimerSeconds$.next(data);
  }

  get getRemainingTimerSeconds() {
    return this.remainingTimerSeconds$.asObservable();
  }

  getRefreshTimerRemainingSecondsValue(): number {
    return this.remainingTimerSeconds$.value;
  }

  resetRemainingTimerSeconds() {
    this.setRemainingTimerSeconds = REFRESH_TOKEN_REMAINING_TIME_IN_SECONDS;
  }

  /**
   * U slucaju da dode do greske, da mozemo da prikazemo odgovarajucu poruku
   */
  coreError$ = new BehaviorSubject<string>(null);

  get getCoreError() {
    return this.coreError$.asObservable();
  }

  set setCoreError(data: string) {
    this.coreError$.next(data);
  }

  /**
   * Kada se pristupi ocitaju (onda je i auth prosao), pa vratimo info da je sve ok
   */
  coreOk$ = new BehaviorSubject<boolean>(false);

  get getCoreOk() {
    return this.coreOk$.asObservable();
  }

  set setCoreOk(data: boolean) {
    this.coreOk$.next(data);
  }

  jePrviLoginKorisnika$ = new BehaviorSubject<boolean>(false);

  get getJePrviLoginKorisnika() {
    return this.jePrviLoginKorisnika$.asObservable();
  }

  set setJePrviLoginKorisnika(data: boolean) {
    this.jePrviLoginKorisnika$.next(data);
  }

  jePrviLoginKompanije$ = new BehaviorSubject<boolean>(false);

  get getJePrviLoginKompanije() {
    return this.jePrviLoginKompanije$.asObservable();
  }

  set setJePrviLoginKompanije(data: boolean) {
    this.jePrviLoginKompanije$.next(data);
  }
}
