import { Injectable } from '@angular/core';
import {
  IDokumentOSmanjenjuDto,
  IDokumentOPovecanjuDto,
} from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IzmenaOsnoviceService {
  constructor() {}

  /**
   * Postavljanje forme racuna
   */
  izmenaOsnoviceForm$ = new BehaviorSubject<
  DokumentOSmanjenjuForm | DokumentOPovecanjuForm
  >(null);

  set setForm(value:  | DokumentOPovecanjuForm) {
    this.izmenaOsnoviceForm$.next(value);
  }

  get getFormObject(): DokumentOSmanjenjuForm | DokumentOPovecanjuForm {
    return this.izmenaOsnoviceForm$.getValue();
  }

  resetService() {
    this.setForm = null;
  }
}

export declare type DokumentOSmanjenjuForm = FormGroupTypeSafe<IDokumentOSmanjenjuDto>;
export declare type DokumentOPovecanjuForm = FormGroupTypeSafe<IDokumentOPovecanjuDto>;
