import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { DelovodniciClient } from '@kodit/core/data-api';
import { PraviloOption } from 'libs/core/shared-ui/src/lib/ui/pravilo-za-broj-racuna-dropdown/pravilo-za-broj-racuna-dropdown';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-pravilo-za-broj-delovodnog-dokumenta-dropdown',
  templateUrl: './pravilo-za-broj-delovodnog-dokumenta-dropdown.component.html',
  styleUrls: ['./pravilo-za-broj-delovodnog-dokumenta-dropdown.component.scss'],
})
export class PraviloZaBrojDelovodnogDokumentaDropdownComponent
  implements OnInit, OnDestroy {
  /** Subs */
  private _pravilaSub: Subscription = new Subscription();

  /** Props */
  pravilaOptions: PraviloOption[] = [];
  placeholder: string = 'Izaberite pravilo';

  /** I/O */
  @Input() formCtrl: AbstractControl | undefined;
  @Input() formCtrlId: string = 'pravilo-za-broj';

  constructor(private _client: DelovodniciClient) {}

  ngOnInit(): void {
    this._loadPravila().then();
  }

  private async _loadPravila() {
    this._pravilaSub = this._client.getPravilaDropdown().subscribe((result) => {
      if (result.succeeded) {
        result.data!.forEach((p) => {
          this.pravilaOptions.push({
            label: p.naziv!,
            value: p.id!,
            pravilo: '#DODAJ_PRAVILO',
          });
        });
        if (this.formCtrl?.value) {
          this.placeholder = this.pravilaOptions.find(
            (item) => item.value === this.formCtrl?.value
          )?.label!;
          console.log(this.placeholder);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._pravilaSub.unsubscribe();
  }
}
