import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RacunService } from '../../../../../../services/src/lib/racun.service';

@Component({
  selector: 'kodit-broj-racuna-input',
  templateUrl: './broj-racuna-input.component.html',
  styleUrls: ['./broj-racuna-input.component.scss'],
})
export class BrojRacunaInputComponent implements OnInit, OnDestroy {
  /** Subs */
  private _brojLabelSub: Subscription = new Subscription();

  /** Props */
  brojLabel: string;

  /** I/O */
  @Input() inputLabel: string = null;
  @Input() inputTooltip: string = 'Unesite broj računa';
  @Input() brojCtrl: FormControl;
  @Input() shouldBeDisabled: boolean = false;
  @Output() onInputLeft = new EventEmitter<string | number>();

  constructor(private _racunService: RacunService) {}

  ngOnInit(): void {
    // nema potrebe da se subscribe ako smo prosledili input
    if (!this.inputLabel) {
      this._brojLabelSub = this._racunService.getBrojRacunaLabel.subscribe(
        (label) => {
          this.brojLabel = label;
        }
      );
    } else {
      this.brojLabel = this.inputLabel;
    }
  }

  inputLeft(value: string) {
    this.onInputLeft.emit(value);
  }

  ngOnDestroy() {
    this._brojLabelSub.unsubscribe();
  }
}
