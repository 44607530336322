import { Injectable } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { IGetUplateQuery, IStatusTransakcijeIzvodaDto, IUplateIsplateSummaryDto, StatusTransakcijeIzvoda, StatusTransakcijeIzvodaDto, UplateIsplateSummaryDto } from '@kodit/core/data-api';
import { AdvancedFilterActionData } from '@kodit/core/shared-ui';
import { FormBuilderTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UplateIsplateFilterService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  /**
   * Advanced filter methods
   */

  advancedFilterSubmitted$ = new BehaviorSubject<
    AdvancedFilterActionData<IGetUplateQuery>
  >(null);

  get getAdvencedFilterSubmitted() {
    return this.advancedFilterSubmitted$.asObservable();
  }
  set setAdvancedFilterSubmitted(
    data: AdvancedFilterActionData<IGetUplateQuery>
  ) {
    this.advancedFilterSubmitted$.next(data);
  }

  transakcijeSummary$ = new BehaviorSubject<IUplateIsplateSummaryDto>({
    brojRekorda: '0',
    ukupanIznos: '0',
  });

  get getTransakcijeSymmary() {
    return this.transakcijeSummary$.asObservable();
  }
  set setTransakcijeSummary(data: IUplateIsplateSummaryDto) {
    this.transakcijeSummary$.next(data);
  }

  public GetStatusFormArray(statusi: StatusTransakcijeIzvodaDto[]): FormArray {
    if (!statusi || statusi.length === 0) {
      return this._fb.array([]);
    }
    let result: FormArray = this._fb.array([]);

    statusi.forEach((status) => {
      result.push(this.GetStatusFormGroup(status));
    });
    return result;
  }
  
  GetStatusFormGroup(model?: IStatusTransakcijeIzvodaDto): import("@angular/forms").AbstractControl {
    return this._fb.group<IStatusTransakcijeIzvodaDto>({
      status: new FormControl(model?.status),
    });
  }
}
