<form [formGroup]="formGroup">
  <p-autoComplete
    [inputId]="formCtrlId"
    [suggestions]="korisnikSuggestions"
    formControlName="searchValue"
    field="naziv"
    (completeMethod)="handleSearch($event)"
    (onSelect)="selectionChanged($event)"
    appendTo="body"
    [forceSelection]="mustSelect"
    (onKeyUp)="nazivChanged($event)"
    (onClear)="onClear()"
  >
    <ng-template let-item pTemplate="item">
      <div>{{ item.naziv }}</div>
      <div class="p-text-italic">({{ item.email }})</div>
      <div class="p-text-bold">{{ item.roles }}</div>
    </ng-template>
  </p-autoComplete>
  <kodit-error-info-required
    [formCtrl]="formGroup.controls.searchValue"
  ></kodit-error-info-required>
</form>
