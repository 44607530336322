import { Injectable } from '@angular/core';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { IPPPDVEvidencijaDto } from '@kodit/core/data-api';

@Injectable({
  providedIn: 'root',
})
export class PPPdvService {
  constructor(
  ) {}
}

export declare type PPPdvForm = FormGroupTypeSafe<IPPPDVEvidencijaDto> | null;
