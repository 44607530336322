import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CreateDelovodnikCommand,
  DelovodniciClient,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogRef } from '@kodit/core/shared-ui';
import {
  CreateDelovodnikForm,
  DelovodnikService,
} from 'libs/core/moduli/podesavanje/src/lib/delovodnik/delovodnik-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-delovodnik-form',
  templateUrl: './create-delovodnik-form.component.html',
  styleUrls: ['./create-delovodnik-form.component.scss'],
})
export class CreateDelovodnikFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();
  /** Forms */
  form!: CreateDelovodnikForm;

  constructor(
    private _service: DelovodnikService,
    private _dialogRef: DynamicDialogRef,
    private _formHelper: FormHelper,
    private _client: DelovodniciClient,
    private _alertService: AlertService
  ) {
    this.form = this._service.getCreateDelovodnikForm();
  }

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  private save() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._subs.add(
      this._client
        .createDelovodnik(this.form.value as CreateDelovodnikCommand)
        .subscribe((result) => {
          if (result.succeeded) {
            this._alertService.addSuccessMsg(result.messages![0]);
            this._dialogRef.close(result);
          } else {
            this._alertService.addWarnMsg(result.messages![0]);
          }
        })
    );
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
       this.save();
    }
  }

  ngOnDestroy(): void {
      this._subs.unsubscribe();
  }
}
