import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FilterChipDto,
  GetPdvEvidencijaPojedinacna39TableQuery,
  IPdvEvidencijaPojedinacna39TableDto,
  PaginatedResultOfPdvEvidencijaPojedinacna39TableDto,
  PdvEvidencijaStatus,
  PdvEvidencijeClient,
  SefEvidentirajPdvEvidencijaPojedinacna39Command,
  SefPonistiPdvEvidencijaPojedinacna39Command,
  TipPristupa,
} from '@kodit/core/data-api';
import {
  AlertService,
  ConfigService,
  LocationService,
} from '@kodit/core/services';
import {
  ActionMode,
  ActionType,
  DynamicDialogConfig,
  OnTableInit,
  SelectMode,
  TableConfig,
} from '@kodit/core/shared-ui';
import { Store } from '@ngrx/store';
import {
  clearFilter,
  setFilter,
} from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';
import { selectFilterByKey } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.selectors';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PdvEvidencijaPojedinacna39FilterComponent } from './pdv-evidencija-pojedinacna39-filter/pdv-evidencija-pojedinacna39-filter.component';
import { nameofFactory, toCamelCase } from '@kodit/core/shared';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'kodit-pdv-evidencija-pojedinacna-table39',
  templateUrl: './pdv-evidencija-pojedinacna-table39.component.html',
  styleUrls: ['./pdv-evidencija-pojedinacna-table39.component.scss'],
})
export class PdvEvidencijaPojedinacnaTable39Component
  implements OnInit, OnDestroy, OnTableInit {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  paginatedData!: PaginatedResultOfPdvEvidencijaPojedinacna39TableDto;
  chipItems: FilterChipDto[] = [];
  filters!: GetPdvEvidencijaPojedinacna39TableQuery;
  private _skipFirst = 0;
  numberOfRowsDisplayed = 0;
  private _isBusy = false;

  isFirstLoad = true;

  evidencijaTable: IPdvEvidencijaPojedinacna39TableDto[] = [];

  /** Configurations */
  tableConfig!: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.MEDIUM_LARGE
  );

  /** I/O */

  constructor(
    private _locationService: LocationService,
    private _storage: Store,
    private _configService: ConfigService,
    private _client: PdvEvidencijeClient,
    private _alertService: AlertService,
    private _confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.setTableConfig().then();

    // reload tabele kada se filter promeni
    this._subs.add(
      this._storage
        .select(selectFilterByKey('PDV|Pojedinacne39'))
        .subscribe((data) => {
          if (data) {
            this.filters = JSON.parse(data);
            this._skipFirst = this.filters.pageNumber ?? 0;
            this.numberOfRowsDisplayed = this.filters.pageSize ?? 0;
          } else {
            this.filters = new GetPdvEvidencijaPojedinacna39TableQuery({
              pageNumber: this._skipFirst,
              pageSize: this.numberOfRowsDisplayed,
            });
          }
          this._load();
        })
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event) => {
        if (!this.isFirstLoad) {
          this._skipFirst = event.first!;
          this.numberOfRowsDisplayed = event.rows!;
        }
        this.updateFilterDataAndReload();
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        }
      },
      selectMode: SelectMode.SINGLE,
      tableHeader: 'Pojedinačne pdv evidencije',
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete izabrane pdv evidenciju?',
      deleteMessage:
        'Da li ste sigurni da želite da obrišete pdv evidenciju {param}?',
      deleteMessageParams: 'broj',
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      columns: [
        {
          header: 'Tip',
          type: 'badge',
          field: 'tipDto',
          subField: 'tipStr',
          styleClassField: 'tipBadgeStr',
          styleClass: 'tip-dokumenta-evidencije',
          tooltipField: 'opis',
        },
        {
          field: 'broj',
          header: 'Broj',
          type: 'text',
          // linkCallbackFunction: (itemIndex: number) =>
          //   this._goToInfo(itemIndex),
          columns: [
            {
              field: 'brojPovezaneFakture',
              header: 'Vezani račun',
              type: 'text',
              //   linkCallbackFunction: (itemIndex: number, subIndex: number) =>
              //     this._goToVezaniRacunInfo(itemIndex, subIndex),
            },
          ],
        },
        {
          field: 'pdv',
          header: 'PDV',
          type: 'currency',
        },
        {
          field: 'datumPromeneStatusa',
          header: 'Datum promene statusa',
          type: 'text',
        },
        {
          field: 'periodDto',
          subField: 'periodStr',
          header: 'Period',
          styleClass: 'pdv-period',
          styleClassField: 'periodBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
        },
        {
          field: 'statusDto',
          subField: 'statusStr',
          header: 'Status',
          styleClass: 'pdv-status',
          styleClassField: 'statusBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          tipPristupa: TipPristupa.PDV_CRUD,
          hasAccessTooltip: 'Unesite pdv evidenciju',
          noAccessTooltip: 'Nemate ovlašćenja za pdv evidencije',
          callback: () => this._routeToCreate(),
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          tipPristupa: TipPristupa.PDV_CRUD,
          hasAccessTooltip: 'Izmenite pdv evidenciju',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu pdv evidencije',
          callback: (index: number) => {
            this._routeToEdit(index);
          },
          shouldDisplayByCondition: (
            rowData: IPdvEvidencijaPojedinacna39TableDto
          ) => {
            return this._shouldDisplayEdit(rowData.statusDto!.status!);
          },
        },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.PDV_CRUD,
          icon: 'fa-light fa-clipboard',
          //label: 'Evidentiraj',
          hasAccessTooltip: 'Evidentirajte pdv evidenciju',
          noAccessTooltip: 'Nemate ovlašćenja za evidentiranje pdv evidencije',
          shouldDisableWhenSefInactive: true,
          callback: (index: number) => {
            this._subs.add(
              this._client
                .sefEvidentirajPdvEvidencijaPojedinacna39(
                  new SefEvidentirajPdvEvidencijaPojedinacna39Command({
                    evidencijaId: this._getPaginatedItem(index).id,
                  })
                )
                .subscribe((res) => {
                  this._alertService.addSuccessMsg(res.messages![0]);
                  this._load();
                })
            );
          },
          shouldDisplayByCondition: (
            rowData: IPdvEvidencijaPojedinacna39TableDto
          ) => {
            return this._shouldDisplayEvidentiraj(rowData.statusDto!.status!);
          },
        },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.PDV_CRUD,
          icon: 'fa-light fa-pen-to-square',
          hasAccessTooltip: 'Korigujte pdv evidenciju',
          noAccessTooltip: 'Nemate ovlašćenja za korigovanje pdv evidencije',
          shouldDisableWhenSefInactive: true,
          callback: (index: number) => {
            this._routeToKoriguj(index);
          },
          shouldDisplayByCondition: (
            rowData: IPdvEvidencijaPojedinacna39TableDto
          ) => {
            return this._shouldDisplayKoriguj(rowData.statusDto!.status!);
          },
        },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.PDV_CRUD,
          icon: 'fa-solid fa-eye',
          hasAccessTooltip: 'Pregledajte korigovanu pdv evidenciju',
          noAccessTooltip:
            'Nemate ovlašćenja za pregled Korigovane pdv evidencije',
          shouldDisableWhenSefInactive: false,
          callback: (index: number) => {
            this.routeToPregledKorigovane(index);
          },
          shouldDisplayByCondition: (
            rowData: IPdvEvidencijaPojedinacna39TableDto
          ) => {
            return this._shouldDisplayPregledKorigovane(
              rowData.statusDto!.status!
            );
          },
        },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.PDV_CRUD,
          icon: 'fa-light fa-xmark-large',
          hasAccessTooltip: 'Poništite pdv evidenciju',
          noAccessTooltip: 'Nemate ovlašćenja za poništavanje pdv evidencije',
          shouldDisableWhenSefInactive: true,
          callback: (index: number) => {
            this._confirmationService.confirm({
              header: 'Potvrda poništavanja',
              message: 'Da li želite da poništite evidenciju?',
              acceptLabel: 'Poništi',
              rejectLabel: 'Odustani',
              rejectButtonStyleClass: 'p-button-outlined',
              accept: () => {
                this._subs.add(
                  this._client
                    .sefCancelPdvEvidencijaPojedinacna39(
                      new SefPonistiPdvEvidencijaPojedinacna39Command({
                        id: this._getPaginatedItem(index).id,
                      })
                    )
                    .subscribe((res) => {
                      if (res.succeeded) {
                        this._alertService.addSuccessMsg(res.data!);
                        this._load();
                      }
                      this._alertService.addFailedMsg(res.messages![0]);
                    })
                );
              },
            });
          },
          shouldDisplayByCondition: (
            rowData: IPdvEvidencijaPojedinacna39TableDto
          ) => {
            return this._shouldDisplayCancel(rowData.statusDto!.status!);
          },
        },
      ],
      advancedFilter: {
        component: PdvEvidencijaPojedinacna39FilterComponent,
        data: new GetPdvEvidencijaPojedinacna39TableQuery(),
      },
    });
  }
  private _shouldDisplayCancel(status: PdvEvidencijaStatus): boolean {
    return status === PdvEvidencijaStatus.EVIDENTIRANO;
  }

  private _load() {
    if (this._isBusy) {
      return;
    }

    this._configService.setIsBusy = true;
    this._isBusy = true;

    if (!this.filters) {
      this.filters = new GetPdvEvidencijaPojedinacna39TableQuery({
        pageNumber: this._skipFirst,
        pageSize: this.numberOfRowsDisplayed,
      });
    }

    this._subs.add(
      this._client
        .getForTable39(this.filters as GetPdvEvidencijaPojedinacna39TableQuery)
        .pipe(
          finalize(() => {
            this._configService.setIsBusy = false;
            this._isBusy = false;
          })
        )
        .subscribe(
          (res) => {
            this.paginatedData = res;
            this.evidencijaTable = res.data!;
            this.chipItems = res.activeFilters!;
            this.tableConfig.advancedFilter!.data = this.filters;
          },
          (error) => {
            this._configService.setIsBusy = false;
          }
        )
    );
  }

  private _updateFilters() {
    if (this._suFilteriPrazni()) {
      this._storage.dispatch(
        clearFilter({
          key: 'PDV|Pojedinacne39',
        })
      );
      return;
    }

    this._storage.dispatch(
      setFilter({
        key: 'PDV|Pojedinacne39',
        filter: JSON.stringify(this.filters),
      })
    );
  }
  private _suFilteriPrazni() {
    for (const prop of Object.keys(this.filters) as Array<
      keyof GetPdvEvidencijaPojedinacna39TableQuery
    >) {
      if (this.filters[prop]) {
        return false;
      }
    }
    return true;
  }

  private updateFilterDataAndReload() {
    if (
      (this._skipFirst !== this.filters.pageNumber ||
        this.numberOfRowsDisplayed !== this.filters.pageSize) &&
      !this.isFirstLoad
    ) {
      this.filters.pageNumber = this._skipFirst;
      this.filters.pageSize = this.numberOfRowsDisplayed;
      this._updateStorage();
    }
  }

  private _updateStorage() {
    this._storage.dispatch(
      setFilter({
        key: 'PDV|Pojedinacne39',
        filter: JSON.stringify(this.filters),
      })
    );
  }

  private _getPaginatedIndex(index: number): number {
    return index - this._skipFirst;
  }

  private _getPaginatedItem(
    index: number
  ): IPdvEvidencijaPojedinacna39TableDto {
    return this.evidencijaTable![this._getPaginatedIndex(index)];
  }

  private _routeToCreate() {
    this._locationService.routeToPdvEvidencijePojedinacna39Create().then();
  }

  private _routeToEdit(rowIndex: number) {
    this._locationService
      .routeToPdvEvidencijePojedinacna39Update(
        this._getPaginatedItem(rowIndex).id!
      )
      .then();
  }

  private _routeToKoriguj(rowIndex: number) {
    this._locationService
      .routeToPdvEvidencijePojedinacna39Koriguj(
        this._getPaginatedItem(rowIndex).id!
      )
      .then();
  }

  async routeToPregledKorigovane(index: number) {
    this._locationService
      .routeToPdvEvidencijePojedinacnaKorigovana39(this._getPaginatedItem(index).id!)
      .then();
  }

  private _handleChipRemove(removedChip: FilterChipDto) {
    const nameof = nameofFactory<GetPdvEvidencijaPojedinacna39TableQuery>();
    const nameOfStatusi = nameof('statusi');
    const nameOfTipovi = nameof('tipoviDokumenta');

    if (removedChip.key!.toLowerCase() === nameOfStatusi.toLowerCase()) {
      const idx = (this.tableConfig.advancedFilter!
        .data as GetPdvEvidencijaPojedinacna39TableQuery).statusi!.findIndex(
        (x) => x === removedChip.defaultValue
      );
      (this.tableConfig.advancedFilter!
        .data as GetPdvEvidencijaPojedinacna39TableQuery).statusi!.splice(
        idx,
        1
      );
    } else if (removedChip.key!.toLowerCase() === nameOfTipovi.toLowerCase()) {
      const idx = (this.tableConfig.advancedFilter!
        .data as GetPdvEvidencijaPojedinacna39TableQuery).tipoviDokumenta!.findIndex(
        (x) => x === removedChip.defaultValue
      );
      (this.tableConfig.advancedFilter!
        .data as GetPdvEvidencijaPojedinacna39TableQuery).tipoviDokumenta!.splice(
        idx,
        1
      );
    } else {
      this.tableConfig.advancedFilter!.data[toCamelCase(removedChip.key!)] =
        removedChip.defaultValue;
    }
    this._updateFilters();
  }

  //#region Helpers

  private _shouldDisplayEdit(status: PdvEvidencijaStatus) {
    return status === PdvEvidencijaStatus.U_PRIPREMI;
  }

  private _shouldDisplayEvidentiraj(status: PdvEvidencijaStatus) {
    return status === PdvEvidencijaStatus.U_PRIPREMI;
  }

  private _shouldDisplayKoriguj(status: PdvEvidencijaStatus) {
    return status === PdvEvidencijaStatus.EVIDENTIRANO;
  }

  private _shouldDisplayPregledKorigovane(status: PdvEvidencijaStatus) {
    return status === PdvEvidencijaStatus.KORIGOVANO;
  }

  //#endregion Helpers

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
