import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  GetPredmeteStavkiQuery,
  IPredmetStavkeDto,
  PredmetStavkiClient,
  TipArtikla,
  TipPredmeta,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';

@Component({
  selector: 'kodit-predmet-stavke-autocomplete',
  templateUrl: './predmet-stavke-autocomplete.component.html',
  styleUrls: ['./predmet-stavke-autocomplete.component.scss'],
})
export class PredmetStavkeAutocompleteComponent implements OnInit, OnDestroy {
  /** Subs */
  private _searchPredmetStavkeSub: Subscription = new Subscription();

  /** Props */
  stavke4Suggestions: string[] = [];
  stavkeResults: IPredmetStavkeDto[] = [];
  tipoviPredmeta: TipPredmeta[] = [
    TipPredmeta.PREDMET_IZMENE_OSNOVICE,
    TipPredmeta.PREDMET_STAVKE,
    TipPredmeta.ROBA,
    TipPredmeta.USLUGA,
    TipPredmeta.VOZILO,
    TipPredmeta.DATI_AVANS,
    TipPredmeta.PRIMLJENI_AVANS,
  ];

  /** I/O */
  @Input() predmetStavkeCtrl: FormGroupTypeSafe<IPredmetStavkeDto>;
  @Input() ctrlPlaceholder: string = 'Šifra ili naziv...';
  @Input() parentCtrl: any = 'body';
  @Input() mustSelect: boolean = false;
  @Input() excludeTipovePredmeta: TipPredmeta[] = [];
  @Input() excludePredmeteStavkiIds: number[] = [];
  @Input() excludeTipoveArtikla: TipArtikla[] = [];

  @Output() onNazivChange = new EventEmitter<string>();
  @Output() onPredmetStavkeChange = new EventEmitter<IPredmetStavkeDto>();

  constructor(private _client: PredmetStavkiClient) {}

  ngOnInit(): void {}

  intersect(a: TipPredmeta[], b: TipPredmeta[]) {
    let temp: TipPredmeta[];
    if (b.length > a.length) (temp = b), (b = a), (a = temp); // indexOf to loop over shorter
    return a.filter(function (e) {
      return b.indexOf(e) > -1;
    });
  }

  /**
   * Na svaki unos u autocomplete, pretrazuje predmete stavki iz baze
   * @param event event poslat iz autocomplete
   */
  searchPredmetStavke(event: any) {
    this._searchPredmetStavkeSub = this._client
      .getPredmeteStavke(
        new GetPredmeteStavkiQuery({
          sifraIliNaziv: event.query,
          excludeTipovePredmeta: this.intersect(
            this.tipoviPredmeta,
            this.excludeTipovePredmeta
          ),
          excludePredmeteStavkiIds: this.excludePredmeteStavkiIds,
          excludeTipoveArtikla: this.excludeTipoveArtikla,
        })
      )
      .subscribe((result) => {
        this.stavkeResults = result.predmetiStavki;
        this.stavke4Suggestions = this.stavkeResults.map((x) =>
          x.sifra ? `${x.sifra} | ${x.naziv}` : x.naziv
        );
      });
  }

  onSelectChange(nazivSaSifrom: string) {
    const temp = nazivSaSifrom.split(' | ');
    let predmetStavke: IPredmetStavkeDto;
    if (temp.length === 1) {
      // znaci da nema sifru, pa citaj prvi -> temp[0]
      predmetStavke = this.stavkeResults.find((x) => x.naziv === temp[0]);
    } else {
      // znaci da ima sifru, pa trebamo da je preskocimo -> temp[1]
      predmetStavke = this.stavkeResults.find((x) => x.naziv === temp[1]);
    }
    this.predmetStavkeCtrl.patchValue(predmetStavke);
    this.onPredmetStavkeChange.emit(predmetStavke);
  }

  nazivChanged(event: any) {
    if (this.onNazivChange) {
      this.onNazivChange.emit(event.target.value);
    }
  }

  onClear() {
    this.predmetStavkeCtrl.reset();
  }

  ngOnDestroy() {
    this._searchPredmetStavkeSub.unsubscribe();
  }
}
