
<div class="dokument-info-card p-d-flex p-flex-column flex-1 p-jc-between" (click)="onClick()">
  <div class="p-d-flex ikonica">
    <i class="fa-solid fa-file-lines"></i>
  </div>
  <div class="p-d-flex p-flex-column">
    <div class="dokument-title p-text-truncate">
      {{ datoteka.naziv }}
    </div>
    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-between p-ai-center p-mt-4">
      <div class="dokument-size">{{ velicinaDokumenta }}</div>
      <button 
      pButton 
        type="button" 
        icon="fa-regular fa-inbox-in" 
        class="p-button-text download-button"
        (click)="download($event)">
      </button>
    </div>
  </div>
</div>
