<div class="p-d-flex p-flex-column">
  <div class="p-d-flex p-flex-row p-jc-between">
    <div class="header">Poreske stope</div>
    <div>
      <kodit-button
        btnLabel="Iščitaj iz PFR"
        btnIcon="fa-solid fa-download"
        (click)="iscitajIzPfr()"
      ></kodit-button>
    </div>
  </div>
  <div class="p-mt-3">
    <p-tree
      [value]="poreskeStope"
      [layout]="displayType"
      [emptyMessage]="emptyMessage"
      selectionMode="single"
    ></p-tree>
  </div>
</div>
