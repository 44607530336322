import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaketiClient, TenantPackageInfoDto } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-paket-info',
  templateUrl: './paket-info.component.html',
  styleUrls: ['./paket-info.component.scss'],
})
export class PaketInfoComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  paketInfo: TenantPackageInfoDto;

  constructor(private _client: PaketiClient, private _router: Router) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client.get().subscribe((res) => {
        this.paketInfo = res.data;
      })
    );
  }

  handleBiranjePaketa() {
    this._router.navigate(['/podesavanja', 'upravljanje-pretplatom']);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
