<!-- Template za kreiranje izlazne fakture step 7(content) -->
<ng-template #izlaznaFakturaKreiranjeStep8>
  <p>Klikom na dugme ce se faktura sacuvati</p>
</ng-template>


<div class="p-d-flex p-flex-column p-flex-md-row p-jc-between">
  <div class="dodatne-akcije p-d-flex p-flex-column p-jc-around p-ml-3"></div>

  <div class="primarne-akcije p-as-center p-d-flex gap-6">
    <ng-content></ng-content>
    <button
      pButton
      pRipple
      [icon]="cancelIcon"
      type="button"
      [label]="cancelLabel"
      class="p-button-secondary p-button-outlined"
      [pTooltip]="cancelTooltip"
      tooltipPosition="bottom"
      (click)="onCancel()"
      [disabled]="shouldDisableBtn"
    ></button>
    <button
      joyrideStep="izlaznaFakturaKreiranjeStep8"
      [stepContent]="izlaznaFakturaKreiranjeStep8"
      title="Cuvanje faktura"
      (done)="backToTable()"
      pButton
      [icon]="saveIcon"
      type="submit"
      [label]="saveLabel"
      class="p-button-success"
      [pTooltip]="saveTooltip"
      tooltipPosition="bottom"
      (click)="onSave()"
      [disabled]="shouldDisableBtn"
      [loading]="shouldDisableBtn"
    ></button>
  </div>
</div>
