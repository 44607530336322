import { createAction, props } from '@ngrx/store';

export const setFilter = createAction(
  '[Filters] Set Filter',
  props<{ key: string; filter: string }>()
);
export const clearFilter = createAction(
  '[Filters] Clear Filter',
  props<{ key: string }>()
);


export const CLEAR_FILTERS = '[Filters] Delete All Filters';
export const deleteAllFilters = createAction('[Filters] Delete All Filters');
