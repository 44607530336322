import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  CreateKorisnickaGrupaCommand,
  ICreateKorisnickaGrupaCommand,
  IUpdateKorisnickaGrupaCommand,
  KorisniciClient,
  UpdateKorisnickaGrupaCommand,
  ResultOfString,
} from '@kodit/core/data-api';
import {
  FormHelper,
  KorisnickaGrupaFormService,
} from '@kodit/core/form-definitions';
import { AlertService, Mode } from '@kodit/core/services';
import { DynamicDialogRef, DynamicDialogConfig } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { KorisnickaGrupaService } from '../korisnicka-grupa.service';

@Component({
  selector: 'kodit-korisnicka-grupa-form',
  templateUrl: './korisnicka-grupa-form.component.html',
  styleUrls: ['./korisnicka-grupa-form.component.scss'],
})
export class KorisnickaGrupaFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<
    ICreateKorisnickaGrupaCommand | IUpdateKorisnickaGrupaCommand
  >;
  mode: Mode = Mode.NEW;

  constructor(
    private _fs: KorisnickaGrupaFormService,
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _client: KorisniciClient,
    private _formHelper: FormHelper,
    private _service: KorisnickaGrupaService
  ) {}

  ngOnInit(): void {
    this.form = this._fs.GetKorisnickaGrupaFormGroup();
    if (this._dialogConfig?.data?.id) {
      this._getKorisnickaGrupe(this._dialogConfig.data.id);
      this.mode = Mode.EDIT;
    }
    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.saveKorisnickaGrupa();
      })
    );
  }

  private saveKorisnickaGrupa() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    if (this.mode === Mode.EDIT) {
      this._subs.add(
        this._client
          .updateKorisnickaGrupa(
            this.form.value.id,
            this.form.value as UpdateKorisnickaGrupaCommand
          )
          .subscribe(
            (result: ResultOfString) => {
              if (result.data) {
                this._alertService.addSuccessMsg(result.messages[0]);
              } else {
                this._alertService.addWarnMsg(result.messages[0]);
              }
              this._dialogRef.close();
              this._service.setShouldReloadCards = true;
            },
            (error) => {
              this._subs.add(
                error.subscribe((x: string) => {
                  this._alertService.addFailedMsg(x);
                })
              );
            }
          )
      );
    } else {
      this._subs.add(
        this._client
          .createKorisnickaGrupa(
            this.form.value as CreateKorisnickaGrupaCommand
          )
          .subscribe(
            (result) => {
              if (result.data) {
                this._alertService.addSuccessMsg(result.messages[0]);
              } else {
                this._alertService.addWarnMsg(result.messages[0]);
              }
              this._dialogRef.close();
              this._service.setShouldReloadCards = true;
            },
            (error) => {
              this._subs.add(
                error.subscribe((x: string) => {
                  this._alertService.addFailedMsg(x);
                })
              );
            }
          )
      );
    }
  }

  private _getKorisnickaGrupe(id: string) {
    this._subs.add(
      this._client.getKorisnickaGrupa(id).subscribe(
        (model) => {
          this.form.patchValue(model.data);

          model.data.ovlascenjaDto.forEach((o) => {
            (this.form.controls.ovlascenjaDto as FormArray).push(
              this._fs.GetOvlascenjeDtoFormGroup(o)
            );
          });
        },
        (error) => {
          this._subs.add(
            error.subscribe((x: string) => {
              this._alertService.addFailedMsg(x);
            })
          );
        }
      )
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
