import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {UslugeClient} from "@kodit/core/data-api";
import {AlertService} from "@kodit/core/services";
import {FileUploadService} from "@kodit/core/shared-ui";

@Component({
  selector: 'kodit-usluge-import',
  templateUrl: './usluge-import.component.html',
  styleUrls: ['./usluge-import.component.scss']
})
export class UslugeImportComponent implements OnInit {
  private _subs: Subscription = new Subscription();

  /** Props */
  uploadCompleted: boolean;
  readonly MAX_FILE_SIZE: number = 10000000;

  constructor(
    private _client: UslugeClient,
    private _alertService: AlertService,
    private _uploadService: FileUploadService
  ) {
  }

  ngOnInit(): void {
    this._uploadService.setMaxAttachmentSize = this.MAX_FILE_SIZE;
  }

  onUpload(file: File) {
    this.uploadCompleted = false;
    this._subs.add(
      this._client
        .import({
          data: file,
          fileName: '',
        })
        .subscribe(
          (res) => {
            this.uploadCompleted = true;
            this._alertService.addSuccessMsg(res.messages[0]);
            location.reload();
          },
          () => {
            this.uploadCompleted = true;
          }
        )
    );
  }

  downloadTemplate() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute(
      'href',
      '/assets/podesavanje/import_files/usluge_sablon.csv'
    );
    link.setAttribute('download', 'usluge_sablon.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
