import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { OznakaPdvKategorije, SharedClient } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-pdv-kategorija-dropdown',
  templateUrl: './pdv-kategorija-dropdown.component.html',
  styleUrls: ['./pdv-kategorija-dropdown.component.scss'],
})
export class PdvKategorijaDropdownComponent implements OnInit, OnDestroy {
  /** Props */
  private _subs: Subscription = new Subscription();
  items: {
    naziv: string;
    opis: string;
    value: number;
  }[] = [];

  /** I/O */
  @Input() pdvKategorijaCtrl: AbstractControl;
  @Output() onItemChange = new EventEmitter<any>();

  constructor(private _client: SharedClient) {}

  ngOnInit(): void {
    this._load();
  }

  handleOnItemChange(event: any) {
    this.onItemChange.emit(event);
  }

  private _load() {
    this._subs.add(
      this._client.getOznakaPdvKategorijeDropdown().subscribe((res) => {
        this.items = res.data.oznakaPdvKategorijeDtoList
          .filter(
            (x) =>
              x.oznakaPdvKategorije !== OznakaPdvKategorije.S &&
              x.oznakaPdvKategorije !== OznakaPdvKategorije.S10 &&
              x.oznakaPdvKategorije !== OznakaPdvKategorije.S20 &&
              x.oznakaPdvKategorije !== OznakaPdvKategorije.AE &&
              x.oznakaPdvKategorije !== OznakaPdvKategorije.EMPTY
          )
          .map((x) => ({
            value: x.oznakaPdvKategorije,
            naziv: x.oznakaPdvKategorijeStr,
            opis: x.opis,
          }));
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
