<div>
  <div
    card
    class="p-mb-3 k-card promet-card p-d-flex p-jc-between p-ai-center p-flex-wrap"
  >
    <div class="p-d-flex p-flex-row">
      <div class="p-pr-2 p-label p-mb-2">Ukupno zapisa:</div>
      <div class="p-text-left p-mb-2">
        {{ isplateSummary.brojRekorda }}
      </div>
    </div>
    <div class="p-d-flex p-flex-row">
      <div class="p-text-left p-mb-2">
        <h3 style="margin: 0">{{ isplateSummary.ukupanIznos }}</h3>
      </div>
    </div>
  </div>

  <kodit-page>
    <kodit-isplata-table></kodit-isplata-table>
  </kodit-page>
</div>
