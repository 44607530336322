<div class="p-d-flex p-flex-column p-fluid">
  <div class="even-columns gap-12 p-flex-md-row">
    <kodit-field fieldLabel="Instrukcija plaćanja">
      <kodit-instrukcija-placanja-dropdown
        [instrukcijePlacanjaCtrl]="
          podaciUplateFormGroup.controls.instrukcijaPlacanja
        "
      >
      </kodit-instrukcija-placanja-dropdown>
    </kodit-field>
    <kodit-field fieldLabel="Šifra plaćanja">
      <kodit-sifra-placanja-dropdown
        [sifrePlacanjaCtrl]="podaciUplateFormGroup.controls.sifraPlacanja"
      >
      </kodit-sifra-placanja-dropdown>
    </kodit-field>
    <kodit-text-input-field
      formCtrlId="model"
      formCtrlLabel="Model"
      [formCtrl]="podaciUplateFormGroup.controls.model"
    ></kodit-text-input-field>
    <kodit-text-input-field
      formCtrlId="poziv-na-broj"
      formCtrlLabel="Poziv na broj"
      [formCtrl]="podaciUplateFormGroup.controls.pozivNaBroj"
    ></kodit-text-input-field>
    <kodit-field fieldLabel="Tekući račun">
      <kodit-tekuci-racun-dropdown
        [tekuciRacunCtrl]="tekuciRacunCtrl"
      ></kodit-tekuci-racun-dropdown>
    </kodit-field>
  </div>

  <div class="p-field">
    <label for="svrha" kodit-form-label>Svrha uplate</label>
    <textarea
      id="svrha"
      [rows]="5"
      [cols]="30"
      pInputTextarea
      [formControl]="podaciUplateFormGroup.controls.svrhaPlacanja"
      [autoResize]="true"
    ></textarea>
    <kodit-error-info-max-length
      [formCtrl]="podaciUplateFormGroup.controls.svrhaPlacanja"
    ></kodit-error-info-max-length>
  </div>

  <div>
    <p-message
      class="test"
      severity="info"
      text="Ukoliko je MODEL 97, POZIV NA BROJ ne sme sadržati specijalne karaktere poput ( /, %, $, #, -)"
      styleClass="p-mr-2"
    ></p-message>
  </div>
</div>
