import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { BaseService } from '@kodit/core/services';
import { MojaFirmaClient, MojaFirmaDto } from '@kodit/core/data-api';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MojaFirmaResolver
  extends BaseService
  implements Resolve<MojaFirmaDto> {
  constructor(private _client: MojaFirmaClient) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<MojaFirmaDto> | Promise<MojaFirmaDto> | MojaFirmaDto {
    return this._client.get().pipe(
      map((x) => x.data),
      catchError(this.handleError),
      finalize(() => {
        //this._configService.setIsBusy = false;
      })
    );
  }
}
