import {Component, Input, OnInit} from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit{
  /** I/O */
  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string;
  @Input() ctrlPlaceholder: string;
  @Input() parent: any = 'body';
  @Input() numberOfMonths: number = 2;
  @Input() invalidDates: Array<Date>;
  @Input() maxDate: Date;
  @Input() inline: boolean;
  @Input() showTime: boolean;
  @Input() showSeconds: boolean;
  @Input() disabled : boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
