import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DashboardClient,
  IGetDashboardPrometCardsResponse,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import {GodinaRacunaDropdownService} from "@kodit/core/services";

@Component({
  selector: 'kodit-dashboard-promet-cards',
  templateUrl: './dashboard-promet-cards.component.html',
  styleUrls: ['./dashboard-promet-cards.component.scss'],
})
export class DashboardPrometCardsComponent implements OnInit, OnDestroy {
  private _getSub: Subscription = new Subscription();

  /** Props */
  prometCardsResponse: IGetDashboardPrometCardsResponse;

  constructor(
    private _client: DashboardClient,
    private _godinaService : GodinaRacunaDropdownService
  ) {}

  ngOnInit(): void {
    this._loadPromet();
    this._getSub.add(
      this._godinaService.getForm().valueChanges.subscribe(() => {
        this._loadPromet();
      })
    );
  }

  private _loadPromet() {
    this._getSub.add(
      this._client.getPrometCards().subscribe((res) => {
        this.prometCardsResponse = res.data;
      })
    );
  }

  ngOnDestroy() {
    this._getSub.unsubscribe();
  }
}
