import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  KonfiguracijaBrojaRacunaDto,
  RacuniClient,
  ResultOfKonfiguracijaBrojaRacunaDto,
} from '@kodit/core/data-api';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  ActionType,
  OnTableInit,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { Subscription } from 'rxjs';
import { PravilaZaBrojFormComponent } from '../pravila-za-broj-form/pravila-za-broj-form.component';
import { AlertService } from '@kodit/core/services';

@Component({
  selector: 'kodit-pravila-za-broj',
  templateUrl: './pravila-za-broj.component.html',
  styleUrls: ['./pravila-za-broj.component.scss'],
})
export class PravilaZaBrojComponent implements OnInit, OnDestroy, OnTableInit {
  /** Configuration */
  tableConfig: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.MEDIUM_SMALL
  );
  /** Props */
  pravilaTable: KonfiguracijaBrojaRacunaDto[] = [];
  /** Subscription */
  private _subs: Subscription = new Subscription();

  constructor(
    private _client: RacuniClient,
    private _dialogService: DynamicDialogService,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._load();
    // set table config
    this.setTableConfig().then();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Pravila za generisanje broja računa',
      deleteMessage: 'Da li ste sigurni da želite da obrišete pravilo {param}?',
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete izabrana pravila?',
      deleteMessageParams: 'naziv',
      columns: [
        {
          field: 'naziv',
          header: 'Naziv',
          type: 'text',
        },
        {
          header: 'Tip',
          type: 'badge',
          field: 'tipRacunaDto',
          subField: 'tipStrLong',
          styleClassField: 'tipBadgeStr',
          styleClass: 'tip-racuna-table',
          tooltipField: 'opis',
        },
        {
          field: 'prefiks',
          header: 'Prefiks',
          type: 'text',
        },
        {
          field: 'brojac',
          header: 'Brojač',
          type: 'text',
        },
        {
          field: 'sufiks',
          header: 'Sufiks',
          type: 'text',
        },
        {
          field: 'isDefault',
          header: 'Podrazumevano',
          type: 'text',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          hasAccessTooltip: 'Unesite pravilo',
          noAccessTooltip: 'Nemate ovlašćenja za unos pravila',
          callback: () => {
            this._setDialogConfig({ id: null }, 'Unos pravila');
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          hasAccessTooltip: 'Izmenite pravilo',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu pravila',
          callback: (index: number) => {
            this._subs.add(
              this._client
                .getPravilaZaBrojKonfiguracija(this.pravilaTable[index].id)
                .subscribe((model) => {
                  if (!model.data.isEditable) {
                    this._alertService.addWarnMsg(
                      'Za ovo pravilo postoji kreiran dokument i nije ga moguće menjati. Kreirajte novo pravilo'
                    );
                  }
                  this._setDialogConfig(
                    {
                      id: this.pravilaTable[index].id,
                      editable: model.data.isEditable,
                    },
                    'Izmena pravila'
                  );
                })
            );
          },
        },
      ],
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  private _setDialogConfig(data: any, header: string) {
    this.dialogConfig.data = data;
    this.dialogConfig.header = header;
    this._openDialog(this.dialogConfig);
  }

  private _openDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(PravilaZaBrojFormComponent, config);

    this._subs.add(
      ref.onClose.subscribe((result: ResultOfKonfiguracijaBrojaRacunaDto) => {
        if (result) {
          this._load();
        }
      })
    );
  }

  private _load() {
    this._subs.add(
      this._client.getPravilaZaBrojKonfiguracija().subscribe((res) => {
        this.pravilaTable = res.data.konfiguracijeBrojaRacunaDto;
      })
    );
  }
}
