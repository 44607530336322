import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AvansniRacuniClient,
  IAvansniRacunTableDto,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import {
  RacunService,
  RacunUkupanIznosForm,
} from '../../../../../../services/src/lib/racun.service';

@Component({
  selector: 'kodit-avansne-uplate-racuna-card',
  templateUrl: './avansne-uplate-racuna-card.component.html',
  styleUrls: ['./avansne-uplate-racuna-card.component.scss'],
})
export class AvansneUplateRacunaCardComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  odabraneAvansneUplateZaRacunNonModified: IAvansniRacunTableDto[] = [];
  shouldDisplayUkupanIznos: boolean;
  shouldAllowButton: boolean;
  ukupanIznos: RacunUkupanIznosForm;

  constructor(
    private _racunService: RacunService,
    private _client: AvansniRacuniClient
  ) {}

  ngOnInit(): void {
    this.ukupanIznos = this._racunService.getUkupanIznos;
    if (this._racunService.shouldLoadNonModified) {
      this._loadEvidentiraneUplateRacuna();
    }

    this._subs.add(
      this._racunService.getOdabraneAvansneUplateZaRacunNonModified.subscribe(
        (res) => {
          this.odabraneAvansneUplateZaRacunNonModified = res;
          this.shouldDisplayUkupanIznos =
            res?.length > 0 && this._racunService.shouldAllowAvansneAkcije;
        }
      )
    );

    this._subs.add(
      this._racunService.getShouldUpdateAvans.subscribe((res) => {
        if (res) {
          this._racunService.setAvansneUplateRacuna = this._racunService.getAvansneUplateRacuna.map(
            (trenutnaAvansnaUplata) => {
              const nonModifiedAvansnaUplata = this.odabraneAvansneUplateZaRacunNonModified.find(
                (x) => x.id === trenutnaAvansnaUplata.avansniRacunId
              );
              return {
                id: trenutnaAvansnaUplata.id,
                avansniRacunId: trenutnaAvansnaUplata.avansniRacunId,
                avansniRacunBroj: trenutnaAvansnaUplata.avansniRacunBroj,
                avansniRacunVrsta: trenutnaAvansnaUplata.avansniRacunVrsta,
                evidentiranPdv10: nonModifiedAvansnaUplata.pdv10,
                evidentiranPdv20: nonModifiedAvansnaUplata.pdv20,
                evidentiranaOsnovica10:
                  nonModifiedAvansnaUplata.preostaloUkupnoOsnovica10,
                evidentiranaOsnovica20:
                  nonModifiedAvansnaUplata.preostaloUkupnoOsnovica20,
                evidentiranaOsnovicaAE10:
                  nonModifiedAvansnaUplata.preostaloUkupnoOsnovicaAE10,
                evidentiranaOsnovicaAE20:
                  nonModifiedAvansnaUplata.preostaloUkupnoOsnovicaAE20,
                evidentiranaOsnovicaE:
                  nonModifiedAvansnaUplata.preostaloUkupnoOsnovicaE,
                evidentiranaOsnovicaN:
                  nonModifiedAvansnaUplata.preostaloUkupnoOsnovicaN,
                evidentiranaOsnovicaO:
                  nonModifiedAvansnaUplata.preostaloUkupnoOsnovicaO,
                evidentiranaOsnovicaOE:
                  nonModifiedAvansnaUplata.preostaloUkupnoOsnovicaOE,
                evidentiranaOsnovicaR:
                  nonModifiedAvansnaUplata.preostaloUkupnoOsnovicaR,
                evidentiranaOsnovicaSS:
                  nonModifiedAvansnaUplata.preostaloUkupnoOsnovicaSS,
                evidentiranaOsnovicaZ:
                  nonModifiedAvansnaUplata.preostaloUkupnoOsnovicaZ,
              };
            }
          );
        }
      })
    );

    this.shouldAllowButton = this._racunService.shouldAllowAvansneAkcije;
  }

  private _loadEvidentiraneUplateRacuna() {
    this._subs.add(
      this._client
        .getEvidentiraneUplateRacuna(
          this._racunService.getRacunFormObject.value.id
        )
        .subscribe((res) => {
          this._racunService.setOdabraneAvansneUplateZaRacunNonModified =
            res.data.responseList;
        })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
