import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GetOznakeQuery, IOznakaDto, OznakaDto, OznakeClient, TipEntiteta } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-oznaka-kategorija-autocomplete',
  templateUrl: './oznaka-kategorija-autocomplete.component.html',
  styleUrls: ['./oznaka-kategorija-autocomplete.component.css']
})
export class OznakaKategorijaAutocompleteComponent implements OnInit,OnDestroy {
  private _subs : Subscription;

  /**Props */
  oznakeResults: IOznakaDto[] = [];
  oznakaModel : IOznakaDto;

  /** I/O */
  @Input() oznakaGroup: FormGroup;
  @Input() fromCtrlId : string;

  constructor(private _oznakeClient: OznakeClient) {
    this._subs = new Subscription();
  }
  
  ngOnInit(): void {
     if (this.oznakaGroup?.value?.id) {
      this.oznakaModel = this.oznakaGroup.value;
    }
    this._subs.add(
      this.oznakaGroup.valueChanges.subscribe((oznaka) => {
        if (oznaka.id && this.oznakaModel?.id !== oznaka.id) {
          this.oznakaModel = oznaka;
        }
      })
    );
  }

  searchOznake(event: any) {
    this._subs = this._oznakeClient
      .get(
        new GetOznakeQuery({
          naziv: event.query,
          excludeTipoveEntiteta: [
            TipEntiteta.PRAVNO_LICE,
            TipEntiteta.FIZICKO_LICE,
          ],
        })
      )
      .subscribe((result) => {
        this.oznakeResults = result.oznake;
      });
  }

  onOznakaSelect(oznaka: OznakaDto) {
    oznaka.tipEntiteta = TipEntiteta.KATEGORIJA_ROBE;
    this.oznakaGroup.patchValue(oznaka);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
