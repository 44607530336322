import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '@kodit/core/core-ui';
import {
  ITokenRequest, LoginType,
  TokenRequest,
  TokensClient,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import {
  AuthService,
  MixpanelRequest,
  MixpanelService,
} from '@kodit/core/services';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { REFRESH_TOKEN_REMAINING_TIME_IN_SECONDS } from 'libs/core/core-ui/src/lib/core-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  private _returnUrl: string;
  form: FormGroupTypeSafe<ITokenRequest>;
  showLoader: boolean = false;
  showPassword: boolean = false;

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _formHelper: FormHelper,
    private _router: Router,
    private _route: ActivatedRoute,
    private _client: TokensClient,
    private _authService: AuthService,
    private _coreService: CoreService,
    private _mixpanelService: MixpanelService
  ) {}

  ngOnInit(): void {
    this._authService.removeTokens();
    this.form = this._fb.group<ITokenRequest>({
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email],
      }),
      password: new FormControl(null, {
        validators: [Validators.required],
      }),
      loginType : new FormControl(LoginType.WEB)
    });
    this._returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
    if (this._returnUrl === '/promena-firme') {
      this._returnUrl = '/dashboard';
    }
  }

  loginUser() {
    this.showLoader = true;
    if (this.form.invalid) {
      this._formHelper.invalidateForm(this.form);
      this.showLoader = false;
      return;
    }

    this._subs.add(
      this._client.getToken(new TokenRequest(this.form.value)).subscribe(
        (result) => {
          this._authService.setTokens(
            result.data.token,
            result.data.refreshToken,
            result.data.sessionId
          );
          this._sendStatisticsToMixpanel();
          this._coreService.setRemainingTimeToLocalStorage(
            REFRESH_TOKEN_REMAINING_TIME_IN_SECONDS
          );
          this._router.navigate([this._returnUrl]);
          this.showLoader = false;
        },
        (error: any) => {
          this.showLoader = false;
        }
      )
    );
  }

  private _sendStatisticsToMixpanel() {
    const tenantKey = this._authService.getCurrentTenant;
    const email = this._authService.getUserData.email;

    this._mixpanelService.loginUserSuccessful({
      tenantKey: tenantKey,
      email: email,
    } as MixpanelRequest);
  }

  onPasswordEnterPressed(event : KeyboardEvent){
    if(event.key === 'Enter'){
      this.loginUser();
    }
  }

  onZaboravljenaLozinka() {
    this._router.navigate(['/autentikacija/','zaboravljena-sifra'])
  }

  onRegistration() {
    this._router.navigate(['/autentikacija', 'registracija']);
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
