<div
  class="p-d-flex p-flex-column p-jc-center p-ai-center podaci-kompanije-wrapper"
>
  <div class="p-d-flex p-flex-column p-jc-center">
    <div class="step-subtitle">Korak 2</div>
    <div class="step-title">Podaci kompanije</div>
  </div>
  <div class="p-d-flex p-flex-column pretraga-wrapper">
    <div class="pretraga-title">Pretraga</div>
    <div class="p-d-flex p-ai-center p-flex-row pretraga-layout">
      <div class="p-d-flex">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66699 17.5V5.83333C6.66699 5.05836 6.66699 4.67087 6.75218 4.35295C6.98335 3.49022 7.65721 2.81635 8.51995 2.58519C8.83786 2.5 9.22535 2.5 10.0003 2.5C10.7753 2.5 11.1628 2.5 11.4807 2.58519C12.3434 2.81635 13.0173 3.49022 13.2485 4.35295C13.3337 4.67087 13.3337 5.05836 13.3337 5.83333V17.5M4.33366 17.5H15.667C16.6004 17.5 17.0671 17.5 17.4236 17.3183C17.7372 17.1586 17.9922 16.9036 18.152 16.59C18.3337 16.2335 18.3337 15.7668 18.3337 14.8333V8.5C18.3337 7.56658 18.3337 7.09987 18.152 6.74335C17.9922 6.42975 17.7372 6.17478 17.4236 6.01499C17.0671 5.83333 16.6004 5.83333 15.667 5.83333H4.33366C3.40024 5.83333 2.93353 5.83333 2.57701 6.01499C2.2634 6.17478 2.00844 6.42975 1.84865 6.74335C1.66699 7.09987 1.66699 7.56658 1.66699 8.5V14.8333C1.66699 15.7668 1.66699 16.2335 1.84865 16.59C2.00844 16.9036 2.2634 17.1586 2.57701 17.3183C2.93353 17.5 3.40024 17.5 4.33366 17.5Z"
            stroke="#98A2B3"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="p-d-flex flex-1 p-ml-2">
        <kodit-pravno-lice-kps-autocomplete
          class="flex-1"
          formCtrlId="pravno-lice"
          [shouldClearText]="shouldClearText"
          (onPravnoLiceChange)="patchFormByModel($event)"
          (onNazivChange)="resetModel()"
          (onPravnoLiceClear)="izbrisiSelectedModel()"
          [ctrlPlaceholder]="nazivPlaceholder"
        ></kodit-pravno-lice-kps-autocomplete>
      </div>
      <div
        class="p-d-flex p-ml-2 izbrisi-action"
        *ngIf="kompanijaSelected"
        (click)="izbrisiSelectedModel()"
      >
        Izbriši
      </div>
    </div>
    <div class="p-d-flex p-flex-column" *ngIf="kompanijaSelected">
      <div class="pretraga-title p-mt-4">Podaci o kompaniji</div>
      <div class="p-d-flex p-flex-column podaci-kompanije">
        <div class="p-d-flex p-flex-row">
          <div class="p-d-flex p-flex-row p-ai-center details-box flex-1">
            <div class="details-icon">
              <i class="fa-regular fa-briefcase"></i>
            </div>
            <div class="p-d-flex p-flex-column p-ml-3">
              <div class="podaci-kompanije-title">Naziv firme</div>
              <div class="podaci-kompanije-description">{{form.controls.pravnoLiceDto.value.naziv ?? '--'}}</div>
            </div>
          </div>
          <div class="p-d-flex p-flex-row p-ai-center details-box flex-1">
            <div class="details-icon">
              <i class="fa-regular fa-calendar"></i>
            </div>
            <div class="p-d-flex p-flex-column p-ml-3">
              <div class="podaci-kompanije-title">Datum osnivanja</div>
              <div
                class="podaci-kompanije-description">{{(form.controls.pravnoLiceDto.value.datumOsnivanja | date) ?? '--'}}</div>
            </div>
          </div>
        </div>
        <div class="p-d-flex p-flex-row">
          <div class="p-d-flex p-flex-row p-ai-center details-box flex-1">
            <div class="details-icon">
              <i class="fa-regular fa-book-open"></i>
            </div>
            <div class="p-d-flex p-flex-column p-ml-3">
              <div class="podaci-kompanije-title">Matični broj</div>
              <div class="podaci-kompanije-description">{{form.controls.pravnoLiceDto.value.maticniBroj ?? '--'}}</div>
            </div>
          </div>
          <div class="p-d-flex p-flex-row p-ai-center details-box flex-1">
            <div class="details-icon">
              <i class="fa-regular fa-book-open"></i>
            </div>
            <div class="p-d-flex p-flex-column p-ml-3">
              <div class="podaci-kompanije-title">PIB</div>
              <div class="podaci-kompanije-description">{{form.controls.pravnoLiceDto.value.pib ?? '--'}}</div>
            </div>
          </div>
        </div>
        <div class="p-d-flex p-flex-row">
          <div class="p-d-flex p-flex-row p-ai-center details-box flex-1">
            <div class="details-icon">
              <i class="fa-regular fa-location-dot"></i>
            </div>
            <div class="p-d-flex p-flex-column p-ml-3">
              <div class="podaci-kompanije-title">Adresa</div>
              <div
                class="podaci-kompanije-description">{{form.controls.pravnoLiceDto.value.adresa.punaAdresa ?? '--'}}</div>
            </div>
          </div>
          <div class="p-d-flex p-flex-row p-ai-center details-box flex-1">
            <div class="details-icon">
              <i class="fa-regular fa-pen"></i>
            </div>
            <div class="p-d-flex p-flex-column p-ml-3">
              <div class="podaci-kompanije-title">Šifra delatnosti</div>
              <div
                class="podaci-kompanije-description">{{form.controls.pravnoLiceDto.value.sifraDelatnosti ?? '--'}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-d-flex p-flex-row p-jc-between">
      <div class="nazad-button p-ai-center" (click)="routeToPreviousStep()">Nazad</div>
      <div class="dalje-button p-ai-center" (click)="routeToNextStep()">Dalje</div>
    </div>
  </div>
</div>
