import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PravniOblik } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';

const PravniOblici = [
  { naziv: 'Akcionarsko društvo', vrednost: PravniOblik.AKCIONARSKO_DRUSTVO },
  { naziv: 'Društvo s ograničenom odgovornošću', vrednost: PravniOblik.DOO },
  { naziv: 'Ortačko društvo', vrednost: PravniOblik.ORTACKO_DRUSTVO },
  { naziv: 'Preduzetnik', vrednost: PravniOblik.PREDUZETNIK },
  { naziv: 'Komanditno društvo', vrednost: PravniOblik.KOMANDITNO_DRUSTVO },
  {
    naziv: 'Poljoprivredno gazdinstvo',
    vrednost: PravniOblik.POLJOPRIVREDNO_GAZDINSTVO,
  },
];

@Component({
  selector: 'kodit-pravni-oblik-dropdown',
  templateUrl: './pravni-oblik-dropdown.component.html',
  styleUrls: ['./pravni-oblik-dropdown.component.scss'],
})
export class PravniOblikDropdownComponent implements OnInit {
  items: SelectItem[];

  @Input() formCtrl: FormControl;
  @Input() formCtrlId: string;

  constructor() {}

  ngOnInit() {
    this.items = [
      {
        label: 'Izaberi pravni oblik',
        value: PravniOblik.EMPTY,
      },
      {
        label: PravniOblici.find(
          (x) => x.vrednost === PravniOblik.AKCIONARSKO_DRUSTVO
        ).naziv,
        value: PravniOblik.AKCIONARSKO_DRUSTVO,
      },
      {
        label: PravniOblici.find((x) => x.vrednost === PravniOblik.DOO).naziv,
        value: PravniOblik.DOO,
      },
      {
        label: PravniOblici.find(
          (x) => x.vrednost === PravniOblik.ORTACKO_DRUSTVO
        ).naziv,
        value: PravniOblik.ORTACKO_DRUSTVO,
      },
      {
        label: PravniOblici.find((x) => x.vrednost === PravniOblik.PREDUZETNIK)
          .naziv,
        value: PravniOblik.PREDUZETNIK,
      },
      {
        label: PravniOblici.find(
          (x) => x.vrednost === PravniOblik.KOMANDITNO_DRUSTVO
        ).naziv,
        value: PravniOblik.KOMANDITNO_DRUSTVO,
      },
      {
        label: PravniOblici.find(
          (x) => x.vrednost === PravniOblik.POLJOPRIVREDNO_GAZDINSTVO
        ).naziv,
        value: PravniOblik.POLJOPRIVREDNO_GAZDINSTVO,
      },
    ];
  }
}
