import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DynamicDialogConfig } from '@kodit/core/shared-ui';

@Component({
  selector: 'kodit-relevantni-dokument-preview',
  templateUrl: './relevantni-dokument-preview.component.html',
  styleUrls: ['./relevantni-dokument-preview.component.scss'],
})
export class RelevantniDokumentPreviewComponent implements OnInit {
  pdfInnerHtml: SafeHtml;

  constructor(
    private _dialogConfig: DynamicDialogConfig,
    private _domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.pdfInnerHtml = this._domSanitizer.bypassSecurityTrustHtml(
      `<object data="${this._dialogConfig.data.fileUrl}" type="application/pdf" class="embed-responsive-item">
     Object ${this._dialogConfig.data.fileUrl} failed
   </object>`
    );
  }

}
