import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  IInformacijaIsporukeDto,
  IStrankaRacunaDto,
  TipRacuna,
} from '@kodit/core/data-api';
import { StrankaRacunaForm } from '@kodit/core/services';
import { Subscription } from 'rxjs';
import { RacunService } from '../../..';
import {
  InformacijaIsporukeForm,
  IzlaznaFakturaService,
} from '../../izlazne-fakture/izlazna-faktura.service';
import {OtpremnicaService} from "../../otpremnica/otpremnica.service";

@Component({
  selector: '<kodit-informacija-isporuke>',
  templateUrl: './informacija-isporuke.component.html',
  styleUrls: ['./informacija-isporuke.component.scss'],
})
export class InformacijaIsporukeComponent implements OnInit, OnDestroy {
  /* Subs */
  private _sub: Subscription = new Subscription();

  /* Props */
  showDropdown: boolean;
  disableDropdown: boolean;
  informacijaIsporukeDropdownItems: IInformacijaIsporukeDto[];
  informacijaIsporukeFormGroup: InformacijaIsporukeForm;
  strankaRacuna: StrankaRacunaForm;

  /* I/O */
  @Input() jeEdit: boolean;

  constructor(
    private _izlaznaFakturaService: IzlaznaFakturaService,
    private _otpremnicaService: OtpremnicaService,
    private _racunService: RacunService
  ) {
    this.showDropdown = false;
    this.disableDropdown = false;
    this.informacijaIsporukeDropdownItems = [];
    this.jeEdit = false;
  }

  ngOnInit(): void {
    this._setFormGroup();
    this._setStranka();

    if (this.jeEdit) {
      this._setInformacijeIsporukeDropdownOptions();
    }

    this._traceInfoIsporukeChanges();
  }

  setInformacijeIsporuke(item: IInformacijaIsporukeDto) {
    this.informacijaIsporukeFormGroup.patchValue({
      imeLokacijeMestaIsporuke: item.imeLokacijeMestaIsporuke,
      gln: item.gln,
      ulicaIBroj: item.ulicaIBroj,
      grad: item.grad,
      postanskiBroj: item.postanskiBroj,
      drzavaId: item.drzavaId,
      shouldCreate : false
    });
  }

  private _setFormGroup() {
    if (this._racunService.getTipRacuna === TipRacuna.IZLAZNA_FAKTURA) {
      this.informacijaIsporukeFormGroup = this._izlaznaFakturaService.getInformacijeIsporukeFormGroup;
    } else if (this._racunService.getTipRacuna === TipRacuna.OTPREMNICA) {
      this.informacijaIsporukeFormGroup = this._otpremnicaService.getInformacijeIsporukeFormGroup;
    } else {
      alert(`kodit-informacija-isporuke ne podržava tip računa: ${this._racunService.getTipRacuna}`);
    }
  }

  private _setStranka() {
    this.strankaRacuna = this._racunService.getStrankaRacunaFormGroup;
    this._initDropdownOptions();
    this._traceStrankaChanges();
  }

  private _traceStrankaChanges() {
    this._sub.add(this.strankaRacuna.valueChanges.subscribe((strankaRacunaDto: IStrankaRacunaDto) => {
      this.informacijaIsporukeDropdownItems = [];
      this.informacijaIsporukeFormGroup.reset({ shouldCreate: false });

      if (!strankaRacunaDto || !strankaRacunaDto.informacijeIsporuke) {
        this.showDropdown = false;
        return;
      }

      if (strankaRacunaDto.informacijeIsporuke.length === 1) {
        // setujemo info isporuke i sakrivamo dropdown, jer ima samo 1
        this.setInformacijeIsporuke(strankaRacunaDto.informacijeIsporuke[0]);
        this.showDropdown = false;
      } else {
        // setujemo items za dropdown
        this._setInformacijeIsporukeDropdownOptions(strankaRacunaDto.informacijeIsporuke);
      }
    }));
  }

  private _traceInfoIsporukeChanges() {
    this._sub.add(this.informacijaIsporukeFormGroup.controls.shouldCreate.valueChanges.subscribe((res: boolean) => {
      this.disableDropdown = res;
      if (res) {
        this.informacijaIsporukeFormGroup.controls.imeLokacijeMestaIsporuke.patchValue('');
      }
    }));
  }

  private _initDropdownOptions() {
    this.informacijaIsporukeFormGroup.patchValue({ shouldCreate: false });
    this._setInformacijeIsporukeDropdownOptions();
  }

  private _setInformacijeIsporukeDropdownOptions(informacijeIsporuke?: IInformacijaIsporukeDto[]) {
    if (!informacijeIsporuke) {
      informacijeIsporuke = this.strankaRacuna?.value?.informacijeIsporuke;
    }

    this.showDropdown = informacijeIsporuke.length > 1;
    this.informacijaIsporukeDropdownItems = informacijeIsporuke;
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
