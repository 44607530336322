import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { BaseService } from '@kodit/core/services';
import {
  IdentityClient,
  KorisniciClient,
  KorisnikTableDto,
} from '@kodit/core/data-api';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KorisnikTableResolver
  extends BaseService
  implements Resolve<KorisnikTableDto[]> {
  constructor(private _client: KorisniciClient) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<KorisnikTableDto[]>
    | Promise<KorisnikTableDto[]>
    | KorisnikTableDto[] {
    return this._client.getForTable().pipe(
      map((res) => res.data.korisnici),
      catchError(this.handleError),
      finalize(() => {})
    );
  }
}
