<div class="izvor-wrapper p-fluid">
  <kodit-field fieldLabel="Format" fieldLabelFor="format-fajla">
    <kodit-dropdown
      formCtrlId="format-fajla"
      [formCtrl]="form.controls.format"
      [options]="dropdownItems"
    ></kodit-dropdown>
  </kodit-field>
  <div class="p-d-flex p-flex-column">
    <span class="note" *ngIf="!shouldShowWarningMessage">
      *** {{ racuni.length > 0 ? 'Biće eskportovano ' + racuni.length + " račun/a" : 'Biće eksportovani svi računi'}}
    </span>
    <span class="note" *ngIf="shouldShowWarningMessage">
      *** Za tip export-a Informatika.txt morate odabrati barem jedan račun
    </span>
  </div>
</div>
