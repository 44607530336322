import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  IGetFormConfigurationResponse,
  IPredracunDto,
  TipEntiteta,
  TipRacuna,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { BaseRacunFormService } from './_base_racun-form.service';

@Injectable({
  providedIn: 'root',
})
export class PredracunFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _baseRacunForm: BaseRacunFormService
  ) {}

  /**
   * Konvertuje model i od njega pravi reaktivnu formu (form grupu) za predracun
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   * @param model model predracuna
   * @returns Reaktivnu formu kao FormGroup za predracun
   */
  public GetPredracunFormGroup(
    jePonuda: boolean,
    formConfiguration: IGetFormConfigurationResponse,
    model?: IPredracunDto,
    
  ): FormGroupTypeSafe<IPredracunDto> {
    const baseRacunForm = this._baseRacunForm.GetBaseRacunFormGroup(
      jePonuda ? TipRacuna.PONUDA : TipRacuna.PREDRACUN,
      VrstaRacuna.IZLAZNI,
      TipEntiteta.PREDRACUN,
      formConfiguration,
      model?.racunDto
    );
    return this._fb.group<IPredracunDto>({
      racunDto: baseRacunForm,
      // domaci predracun props
      rokVazenja: new FormControl(model?.rokVazenja ?? 7, {
        validators: [Validators.required, Validators.min(1)],
      }),
      jePonuda : new FormControl(jePonuda)
    });
  }
}
