import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  IKompenzacijaDto,
} from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KompenzacijaService {
  /**
   * Postavljanje forme
   */
  form$ = new BehaviorSubject<KompenzacijaForm>(null);
  set setForm(value: KompenzacijaForm) {
    this.form$.next(value);
  }

  getStrankaMb() {
    return this.form$.value.value.stranka.mb;
  }

  get getForm(): Observable<KompenzacijaForm> {
    return this.form$.asObservable();
  }

  get getFormObject(): KompenzacijaForm {
    return this.form$.getValue();
  }

  get getObavezePremaPoveriocuArray(): FormArray {
    if (!this.getFormObject) {
      return new FormArray([]);
    }
    return this.getFormObject.controls.obavezePremaPoveriocu as FormArray;
  }

  get getObavezePremaDuznikuArray(): FormArray {
    if (!this.getFormObject) {
      return new FormArray([]);
    }
    return this.getFormObject.controls.obavezePremaDuzniku as FormArray;
  }
}

export declare type KompenzacijaForm = FormGroupTypeSafe<IKompenzacijaDto>;
