import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BezbednosniElementInfoDto,
  FiskalniRacuniClient,
  IPoslovnaJedinicaDto,
  TipBezbednosnogElementa,
  UpdateBezbednosniElementCommand,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService, ButtonService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-update-bezbednosni-element-form',
  templateUrl: './update-bezbednosni-element-form.component.html',
  styleUrls: ['./update-bezbednosni-element-form.component.scss'],
})
export class UpdateBezbednosniElementFormComponent implements OnInit {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  isSertifikat: Boolean;
  imaMagacina: boolean = true;
  nazivMagacina: string = '';

  /** Form */
  form: FormGroup = undefined;

  constructor(
    private _dialogConfig: DynamicDialogConfig,
    private _fiskalniClient: FiskalniRacuniClient,
    private _fb: FormBuilder,
    private _buttonService: ButtonService,
    private _dialogRef: DynamicDialogRef,
    private _formHelper: FormHelper,
    private _alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.isSertifikat =
      this._dialogConfig.data.tipBezbednosnogElementa ===
      TipBezbednosnogElementa.VPFR;

    this._subs.add(
      this._fiskalniClient
        .getBezbednosniElementInfo(this._dialogConfig.data.bezbednosniElementId)
        .subscribe((res) => {
          if (res.succeeded) {
            this.isSertifikat
              ? this._initSertifikatForm(res.data)
              : this._initLPFRForm(res.data);
          }
        })
    );

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );
  }
  private _save() {
    if (this.form.invalid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._subs.add(
      this._fiskalniClient
        .updateBezbednosniElement(
          this.form.value as UpdateBezbednosniElementCommand
        )
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.messages![0]);
            this._dialogRef.close(res);
            return;
          }

          this._alertService.addWarnMsg(res.messages![0]);
        })
    );
  }

  private _initSertifikatForm(dto: BezbednosniElementInfoDto) {
    this.form = this._fb.group({
      id: new FormControl(dto.id, {
        validators: [Validators.required],
      }),
      naziv: new FormControl(dto.naziv, {
        validators: [Validators.required],
      }),
      jid: new FormControl(dto.jid, {
        validators: [Validators.required],
      }),
      lozinka: new FormControl(dto.lozinka, {
        validators: [Validators.required],
      }),
      pak: new FormControl(dto.pak, {
        validators: [Validators.required],
      }),
      poslovnaJedinicaId: new FormControl(dto.poslovnaJedinicaId, {
        validators: [Validators.required],
      }),
    });
  }

  private _initLPFRForm(dto: BezbednosniElementInfoDto) {
    this.form = this._fb.group({
      id: new FormControl(dto.id, {
        validators: [Validators.required],
      }),
      poslovnaJedinicaId: new FormControl(dto.poslovnaJedinicaId, {
        validators: [Validators.required],
      }),
    });
  }

  handlePoslovnaJedinicaChanged(poslovnaJedinica: IPoslovnaJedinicaDto) {
    if (poslovnaJedinica.magacini?.length === 0) {
      this.imaMagacina = false;
      this._buttonService.disableButton();
      return;
    }

    poslovnaJedinica.magacini?.forEach((x) => {
      this.nazivMagacina += x.naziv + ',';
    });

    this.imaMagacina = true;
    this._buttonService.enableButton();
  }
}
