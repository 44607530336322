import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {FormGroupTypeSafe} from "angular-typesafe-reactive-forms-helper";
import {IInvoiceRequestDto} from "@kodit/core/data-api";
import {FiskalniRacunService} from "../../fiskalni-racun.service";
import {DynamicDialogRef} from "@kodit/core/shared-ui";

@Component({
  selector: 'kodit-esir-vreme-fiskalnog-racuna-dialog',
  templateUrl: './esir-vreme-fiskalnog-racuna-dialog.component.html',
  styleUrls: ['./esir-vreme-fiskalnog-racuna-dialog.component.scss']
})
export class EsirVremeFiskalnogRacunaDialogComponent implements OnInit,OnDestroy{
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Form */
  fiskalniRacunForm!: FormGroupTypeSafe<IInvoiceRequestDto>;

  constructor(
    private _fiskalniRacunService : FiskalniRacunService,
    private _dialogRef: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    this.fiskalniRacunForm = this._fiskalniRacunService.getFormObject;
    console.log(this.fiskalniRacunForm.value);

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._dialogRef.close(true);
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
