import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FilterChipDto,
  GetPdvEvidencijaPojedinacnaTableQuery,
  GetPdvEvidencijaZbirnaTableQuery,
  IPaginatedResultOfRacunTableDto,
  IPdvEvidencijaPojedinacnaTableDto,
  IPdvEvidencijaZbirnaTableDto,
  PdvEvidencijaStatus,
  PdvEvidencijeClient,
  SefCancelPdvEvidencijaZbirnaCommand,
  SefEvidentirajPdvEvidencijaZbirnaCommand,
  TipPristupa,
} from '@kodit/core/data-api';
import {
  ActionMode,
  ActionType,
  OnTableInit,
  SelectMode,
  TableConfig,
} from '@kodit/core/shared-ui';
import {
  AlertService,
  ConfigService,
  LocationService,
} from '@kodit/core/services';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { selectFilterByKey } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.selectors';
import { nameofFactory, toCamelCase } from '@kodit/core/shared';
import {
  clearFilter,
  setFilter,
} from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';
import { finalize } from 'rxjs/operators';
import { PdvEvidencijaZbirnaFilterComponent } from './pdv-evidencija-zbirna-filter/pdv-evidencija-zbirna-filter.component';

@Component({
  selector: 'kodit-pdv-evidencija-zbirna-table',
  templateUrl: './pdv-evidencija-zbirna-table.component.html',
  styleUrls: ['./pdv-evidencija-zbirna-table.component.scss'],
})
export class PdvEvidencijaZbirnaTableComponent
  implements OnInit, OnDestroy, OnTableInit {
  /** Props */
  private _subs: Subscription = new Subscription();
  paginatedData: IPaginatedResultOfRacunTableDto | undefined;
  tableConfig!: TableConfig;
  private _skipFirst: number | undefined = 0;
  numberOfRowsDisplayed: number | undefined = 0;
  tableArray: IPdvEvidencijaZbirnaTableDto[] | undefined = [];
  chipItems: FilterChipDto[] = [];

  filters!: GetPdvEvidencijaPojedinacnaTableQuery;
  isFirstLoad = true;
  private _isBusy = false;

  constructor(
    private _client: PdvEvidencijeClient,
    private _alertService: AlertService,
    private _locationService: LocationService,
    private _confirmationService: ConfirmationService,
    private _storage: Store,
    private _configService: ConfigService
  ) {
    this.tableArray = [];
  }

  ngOnInit(): void {
    this.setTableConfig();

    // reload tabele kada se filter promeni
    this._subs.add(
      this._storage
        .select(selectFilterByKey('PDV|Zbirne'))
        .subscribe((data) => {
          if (data) {
            this.filters = JSON.parse(data);
            this._skipFirst = this.filters.pageNumber ?? 0;
            this.numberOfRowsDisplayed = this.filters.pageSize ?? 0;
          } else {
            this.filters = new GetPdvEvidencijaZbirnaTableQuery({
              pageNumber: this._skipFirst,
              pageSize: this.numberOfRowsDisplayed,
            });
          }
          this._load();
        })
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event) => {
        if (!this.isFirstLoad) {
          this._skipFirst = event.first!;
          this.numberOfRowsDisplayed = event.rows;
        }
        this.updateFilterDataAndReload();
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        }
      },
      selectMode: SelectMode.SINGLE,
      tableHeader: 'Zbirne pdv evidencije',
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete izabranu pdv evidenciju?',
      deleteMessage:
        'Da li ste sigurni da želite da obrišete pdv evidenciju {param}?',
      deleteMessageParams: 'broj',
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      columns: [
        {
          field: 'broj',
          header: 'Broj',
          type: 'text',
          // linkCallbackFunction: (itemIndex: number) =>
          //   this._goToInfo(itemIndex),
        },
        {
          field: 'periodDto',
          subField: 'periodStr',
          header: 'Period',
          styleClass: 'pdv-period',
          styleClassField: 'periodBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
        },
        {
          field: 'statusDto',
          subField: 'statusStr',
          header: 'Status',
          styleClass: 'pdv-status',
          styleClassField: 'statusBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
        },
      ],
      headerActions: [
        // {
        //   type: ActionType.CREATE,
        //   tipPristupa: TipPristupa.PDV_CRUD,
        //   hasAccessTooltip: 'Unesite pdv evidenciju',
        //   noAccessTooltip: 'Nemate ovlašćenja za pdv evidencije',
        //   callback: () => this._routeToCreate(),
        // },
      ],
      rowActions: [
        // {
        //   type: ActionType.EDIT,
        //   tipPristupa: TipPristupa.PDV_CRUD,
        //   hasAccessTooltip: 'Izmenite pdv evidenciju',
        //   noAccessTooltip: 'Nemate ovlašćenja za izmenu pdv evidencije',
        //   callback: (index: number) => {
        //     this._routeToEdit(index);
        //   },
        //   shouldDisplayByCondition: (rowData: IPdvEvidencijaZbirnaTableDto) => {
        //     return this._shouldDisplayEdit(rowData.statusDto!.status!);
        //   },
        // },
        // {
        //   mode: ActionMode.SINGLE,
        //   type: ActionType.CUSTOM,
        //   tipPristupa: TipPristupa.PDV_CRUD,
        //   icon: 'fa-light fa-clipboard',
        //   hasAccessTooltip: 'Evidentirajte pdv evidenciju',
        //   noAccessTooltip: 'Nemate ovlašćenja za evidentiranje pdv evidencije',
        //   shouldDisableWhenSefInactive: true,
        //   callback: (index: number) => {
        //     this._subs.add(
        //       this._client
        //         .sefEvidentirajPdvEvidencijaZbirna(
        //           new SefEvidentirajPdvEvidencijaZbirnaCommand({
        //             evidencijaId: this._getPaginatedItem(index).id,
        //           })
        //         )
        //         .subscribe((res) => {
        //           this._alertService.addSuccessMsg(res.messages![0]);
        //           this._load();
        //         })
        //     );
        //   },
        //   shouldDisplayByCondition: (rowData: IPdvEvidencijaZbirnaTableDto) => {
        //     return this._shouldDisplayEvidentiraj(rowData.statusDto!.status!);
        //   },
        // },
        // {
        //   mode: ActionMode.SINGLE,
        //   type: ActionType.CUSTOM,
        //   tipPristupa: TipPristupa.PDV_CRUD,
        //   icon: 'fa-light fa-pen-to-square',
        //   hasAccessTooltip: 'Korigujte pdv evidenciju',
        //   noAccessTooltip: 'Nemate ovlašćenja za korigovanje pdv evidencije',
        //   shouldDisableWhenSefInactive: true,
        //   callback: (index: number) => {
        //     this._locationService
        //       .routeToPdvEvidencijeZbirnaKoriguj(
        //         this._getPaginatedItem(index).id!
        //       )
        //       .then();
        //   },
        //   shouldDisplayByCondition: (rowData: IPdvEvidencijaZbirnaTableDto) => {
        //     return this._shouldDisplayKoriguj(rowData.statusDto!.status!);
        //   },
        // },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.PDV_CRUD,
          icon: 'fa-solid fa-eye',
          hasAccessTooltip: 'Pregledajte korigovanu pdv evidenciju',
          noAccessTooltip:
            'Nemate ovlašćenja za pregled korigovane pdv evidencije',
          shouldDisableWhenSefInactive: false,
          callback: (index: number) => {
            this._locationService
              .routeToPdvEvidencijeZbirnaKorigovana(
                this._getPaginatedItem(index).id!
              )
              .then();
          },
          shouldDisplayByCondition: (
            rowData: IPdvEvidencijaPojedinacnaTableDto
          ) => {
            return this._shouldDisplayPregledKorigovane(
              rowData.statusDto!.status!
            );
          },
        },
        // {
        //   mode: ActionMode.SINGLE,
        //   type: ActionType.CUSTOM,
        //   tipPristupa: TipPristupa.PDV_CRUD,
        //   icon: 'fa-light fa-xmark-large',
        //   hasAccessTooltip: 'Poništite pdv evidenciju',
        //   noAccessTooltip: 'Nemate ovlašćenja za poništavanje pdv evidencije',
        //   shouldDisableWhenSefInactive: true,
        //   callback: (index: number) => {
        //     this._confirmationService.confirm({
        //       header: 'Potvrda poništavanja',
        //       message: 'Da li želite da poništite evidenciju?',
        //       acceptLabel: 'Poništi',
        //       rejectLabel: 'Odustani',
        //       rejectButtonStyleClass: 'p-button-outlined',
        //       accept: () => {
        //         this._subs.add(
        //           this._client
        //             .sefCancelPdvEvidencijaZbirna(
        //               new SefCancelPdvEvidencijaZbirnaCommand({
        //                 evidencijaId: this._getPaginatedItem(index).id,
        //               })
        //             )
        //             .subscribe((res) => {
        //               if (res.succeeded) {
        //                 this._alertService.addSuccessMsg(res.messages![0]);
        //                 this._load();
        //               }
        //             })
        //         );
        //       },
        //     });
        //   },
        //   shouldDisplayByCondition: (rowData: IPdvEvidencijaZbirnaTableDto) => {
        //     return this._shouldDisplayCancel(rowData.statusDto!.status!);
        //   },
        // },
      ],
      advancedFilter: {
        component: PdvEvidencijaZbirnaFilterComponent,
        data: new GetPdvEvidencijaZbirnaTableQuery(),
      },
    });
  }

  private _handleChipRemove(removedChip: FilterChipDto) {
    const nameof = nameofFactory<GetPdvEvidencijaPojedinacnaTableQuery>();
    const nameOfStatusi = nameof('statusi');

    if (removedChip.key!.toLowerCase() === nameOfStatusi.toLowerCase()) {
      const idx = (this.tableConfig.advancedFilter!
        .data as GetPdvEvidencijaPojedinacnaTableQuery).statusi!.findIndex(
        (x) => x === removedChip.defaultValue
      );
      (this.tableConfig.advancedFilter!
        .data as GetPdvEvidencijaPojedinacnaTableQuery).statusi!.splice(idx, 1);
    } else if (removedChip!.key!.toLocaleLowerCase() === nameof('mesec')) {
      this.tableConfig.advancedFilter!.data[
        toCamelCase(removedChip.key!)
      ] = undefined;
    } else {
      this.tableConfig.advancedFilter!.data[toCamelCase(removedChip.key!)] =
        removedChip.defaultValue;
    }
    this._updateFilters();
  }

  private _updateFilters() {
    if (this._suFilteriPrazni()) {
      this._storage.dispatch(
        clearFilter({
          key: 'PDV|Zbirne',
        })
      );
      return;
    }

    this._storage.dispatch(
      setFilter({
        key: 'PDV|Zbirne',
        filter: JSON.stringify(this.filters),
      })
    );
  }
  private _suFilteriPrazni() {
    for (const prop of Object.keys(this.filters) as Array<
      keyof GetPdvEvidencijaPojedinacnaTableQuery
    >) {
      if (this.filters[prop]) {
        return false;
      }
    }
    return true;
  }

  private _updateStorage() {
    this._storage.dispatch(
      setFilter({
        key: 'PDV|Zbirne',
        filter: JSON.stringify(this.filters),
      })
    );
  }

  private updateFilterDataAndReload() {
    if (
      (this._skipFirst !== this.filters.pageNumber ||
        this.numberOfRowsDisplayed !== this.filters.pageSize) &&
      !this.isFirstLoad
    ) {
      this.filters.pageNumber = this._skipFirst;
      this.filters.pageSize = this.numberOfRowsDisplayed;
      this._updateStorage();
    }
  }

  private _load() {
    if (this._isBusy) {
      return;
    }

    this._configService.setIsBusy = true;
    this._isBusy = true;

    if (!this.filters) {
      this.filters = new GetPdvEvidencijaZbirnaTableQuery({
        pageNumber: this._skipFirst,
        pageSize: this.numberOfRowsDisplayed,
      });
    }
    this._subs.add(
      this._client
        .getForTable2(this.filters as GetPdvEvidencijaZbirnaTableQuery)
        .pipe(
          finalize(() => {
            this._configService.setIsBusy = false;
            this._isBusy = false;
          })
        )
        .subscribe(
          (res) => {
            this.paginatedData = res;
            this.tableArray = res.data;
            this.chipItems = res.activeFilters!;
            this.tableConfig.advancedFilter!.data = this.filters;
          },
          (error) => {
            //this._configService.setIsBusy = false;
          }
        )
    );
  }

  private _getPaginatedIndex(index: number): number {
    return index - this._skipFirst!;
  }

  private _getPaginatedItem(index: number): IPdvEvidencijaPojedinacnaTableDto {
    return this.tableArray![this._getPaginatedIndex(index)];
  }

  private _routeToEdit(rowIndex: number) {
    const entity = this._getPaginatedItem(rowIndex);
    this._locationService.routeToPdvEvidencijeZbirnaUpdate(entity.id!).then();
  }

  private _routeToCreate() {
    this._locationService.routeToPdvEvidencijeZbirnaCreate().then();
  }

  private _shouldDisplayEdit(status: PdvEvidencijaStatus) {
    return status === PdvEvidencijaStatus.U_PRIPREMI;
  }

  private _shouldDisplayEvidentiraj(status: PdvEvidencijaStatus) {
    return status === PdvEvidencijaStatus.U_PRIPREMI;
  }

  private _shouldDisplayCancel(status: PdvEvidencijaStatus) {
    return status === PdvEvidencijaStatus.EVIDENTIRANO;
  }

  private _shouldDisplayKoriguj(status: PdvEvidencijaStatus) {
    return status === PdvEvidencijaStatus.EVIDENTIRANO;
  }

  private _shouldDisplayPregledKorigovane(status: PdvEvidencijaStatus) {
    return status === PdvEvidencijaStatus.KORIGOVANO;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
