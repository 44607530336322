import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';

@Component({
  selector: 'kodit-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy, OnChanges {
  private _subs: Subscription = new Subscription();

  @Input() content: any;
  @Input() showLoader: boolean;
  @Input() loadingText: string;
  @Input() showError: boolean;
  @Input() errorText: string;

  constructor(private _service: LoaderService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showLoader.currentValue != changes.showLoader.previousValue) {
      this.showLoader = changes.showLoader.currentValue;
    }
  }

  ngOnInit(): void {
    if (!this.loadingText) {
      this._subs.add(
        this._service.getLoadingText.subscribe((text) => {
          this.loadingText = text;
        })
      );
    }

    if (!this.errorText) {
      this._subs.add(
        this._service.getErrorText.subscribe((text) => {
          this.errorText = text;
        })
      );
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
