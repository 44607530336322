<kodit-page>
  <kodit-table
    *ngIf="!showTableMassage"
    [tableConfig]="tableConfig"
    [paginatedData]="paginatedData"
    [chipItems]="chipItems"
    [showTableMessage]="showTableMassage"
  >
  </kodit-table>

  <div card *ngIf="showTableMassage" class="container">
    <div class="icon-container">
      <div class="p-d-flex icon"></div>
    </div>

    <p class="text">Neophodno je uneti SEF API kluč</p>
    <kodit-button
      btnLabel="Unesi"
      btnIcon="fa-solid fa-plus"
      (btnClicked)="handleAddApiKey()"
    ></kodit-button>
  </div>
</kodit-page>
