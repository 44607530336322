import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  CreateRadniNalogCommand,
  IRadniNalogDto,
  RadniNaloziClient,
  StatusRadnogNaloga,
  TipArtikla,
  TipPredmeta,
  TipRacuna,
  UpdateRadniNalogCommand,
  VrstaRadnogNaloga,
} from '@kodit/core/data-api';
import {
  FormHelper,
  RadniNalogFormService,
} from '@kodit/core/form-definitions';
import {
  AlertService,
  LocationService,
  SharedService,
} from '@kodit/core/services';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';
import { RacunService } from '../../../../../../services/src/lib/racun.service';
import { RacunFormConfig } from '../../subforms/racun-form-container/racun-form.config';
import { RadniNalogService } from '../radni-nalog.service';

@Component({
  selector: 'kodit-radni-nalog-form',
  templateUrl: './radni-nalog-form.component.html',
  styleUrls: ['./radni-nalog-form.component.scss'],
})
export class RadniNalogFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Form */
  racunForm: FormGroupTypeSafe<IRadniNalogDto>;
  racunFormConfig: RacunFormConfig;

  /** Props */
  excludedTipovePredmeta: TipPredmeta[] = [];
  excludedTipoveArtikla: TipArtikla[] = []; // ToDo: prebaciti u neki service..?
  shouldBeDisabled: boolean;

  constructor(
    private _client: RadniNaloziClient,
    private _alertService: AlertService,
    private _confirmationService: ConfirmationService,
    private _locationService: LocationService,
    private _racunService: RacunService,
    private _formService: RadniNalogFormService,
    private _route: ActivatedRoute,
    private _formHelper: FormHelper,
    private _breadcrumbService: BreadcrumbService,
    private _radniNalogService: RadniNalogService,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    // get route data
    this.racunForm = this._formService.GetRadniNalogFormGroup(
      this._route.snapshot.data.formConfiguration,
      this._route.snapshot.data.racun
    );

    this.racunFormConfig = {
      tip: TipRacuna.RADNI_NALOG,
      racunForm: this.racunForm.controls.racunDto,
      brojRacunaTooltip: 'Dodeljen broj radnog naloga',
      originalanBrojRacunaTooltip:
        'Unesite broj radnog naloga koji ste dobili od izdavaoca',
      saveCallback: () => this.onSave(),
      cancelCallback: () => this.onCancel(),
    };

    this._racunService.setBrojRacunaLabel = 'Broj radnog naloga';

    this.excludedTipovePredmeta = [
      TipPredmeta.PREDMET_STAVKE,
      TipPredmeta.VOZILO,
    ];

    this._setBreadcrumb();

    // Potrebno prilikom Edit radnog naloga (ovo je neophodno zbog UsluznaDelatnost)
    !!this.racunForm.controls.vrsta
      ? this.emitVrstaRadnogNalogaValue(this.racunForm.controls.vrsta.value)
      : this.emitVrstaRadnogNalogaValue(null);

    this.shouldBeDisabled = this._shouldVrstaRadnogNalogaBeDisabled();
    this._setValidators(this.racunForm.controls.vrsta.value);
  }

  get jeEditMode(): boolean {
    return this._route.snapshot.data.racun?.racunDto?.id;
  }

  onSave() {
    this._formHelper.removeEmptyStavka(
      this.racunForm.controls.racunDto.get('stavkeDto') as FormArray
    );

    // Tekuci racun ne postoji na Radnom nalogu (da bi izbegli 'Ne postoji referenca ka obaveznom polju')
    this.racunForm.controls.racunDto.get('tekuciRacunId').patchValue(null);

    // check is it valid
    if (!this.racunForm.valid) {
      this._formHelper.invalidateForm(this.racunForm);
      return;
    }

    // everything is ok, so let's save it
    if (this.jeEditMode) {
      this._sharedService.displayLoadingScreen(
        true,
        'Ažuriranje radnog naloga...'
      );
      // it's edit
      this._subs.add(
        this._client
          .update(this.racunForm.value as UpdateRadniNalogCommand)
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.messages[0]);
            this._locationService.routeBack();
          })
      );
    } else {
      this._sharedService.displayLoadingScreen(
        true,
        'Kreiranje radnog naloga...'
      );
      // it's new
      this._subs.add(
        this._client
          .create(this.racunForm.value as CreateRadniNalogCommand)
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.messages[0]);
            this._locationService.routeBack();
          })
      );
    }
  }

  onCancel() {
    this._confirmationService.confirm({
      message: 'Ukoliko otkažete sav ne snimljen sadržaj će biti izgubljen.',
      acceptLabel: 'Otkaži',
      rejectLabel: 'Ostani na stranici',
      header: 'Potvrdite otkazivanje',
      icon: 'far fa-check-circle',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._locationService.routeBack();
      },
    });
  }

  private _setBreadcrumb() {
    // this._breadcrumbService.set(
    //   '@unosOtpremniceZaStranku',
    //   `Unos otpremnice za '${this.racunForm.value.racunDto.strankaRacunaDto.naziv}'`
    // );
    this._breadcrumbService.set(
      '@izmenaRadnogNaloga',
      `Izmena radnog naloga ${this.racunFormConfig.racunForm.value.broj}`
    );
  }

  handleVrstaRadnogNalogaChange(vrsta: VrstaRadnogNaloga) {
    // setVrstaRadnogNaloga u customObject kako bi u stavke-racuna-table znali da li jeUsluznaDelatnost
    this._radniNalogService.changeVrstaRadnogNaloga(vrsta);
    this.emitVrstaRadnogNalogaValue(vrsta);
    this._setValidators(vrsta);
  }

  emitVrstaRadnogNalogaValue(vrsta: VrstaRadnogNaloga) {
    this._radniNalogService.jeUsluznaDelatnost(vrsta)
      ? (this._racunService.setCustomObject = true)
      : (this._racunService.setCustomObject = false);
  }

  private _shouldVrstaRadnogNalogaBeDisabled() {
    return (
      this.jeEditMode &&
      this._radniNalogService.jeUsluznaDelatnost(
        this.racunForm.controls.vrsta.value
      ) &&
      this.racunForm.controls.statusRadnogNaloga.value !==
        StatusRadnogNaloga.NACRT
    );
  }

  private _setValidators(vrsta: VrstaRadnogNaloga) {
    if (vrsta === VrstaRadnogNaloga.PROIZVODNJA_PO_NARUDZBINI) {
      this._racunService.setStrankaRequiredValidator();
    } else {
      this._racunService.resetStrankaRequiredValidator();
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
