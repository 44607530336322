import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  BuyerReference,
  CheckIfBrojRacunaExistsQuery,
  GetKursZaValuteQuery,
  IMemorandumAutocompleteDto,
  IOpstaPravilaKonfiguracijaDto,
  RacuniClient,
  SharedClient,
  TipPredmeta,
  TipRacuna,
  ValutaDto,
  VezaniRacunDto,
  VrstaOtpremnice,
  VrstaRacuna,
  VrstaRadnogNaloga,
} from '@kodit/core/data-api';
import { RacunFormConfig } from './racun-form.config';
import { MenuItem } from 'primeng/api';
import { DynamicDialogConfig } from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { RacunService } from '../../../../../../services/src/lib/racun.service';
import { Subscription } from 'rxjs';
import {SERBIA_CURRENCY_ALPHA_CHAR, SERBIA_CURRENCY_CODE} from '@kodit/core/shared';
import { AlertService } from '@kodit/core/services';
import { UnosBrojaOption } from '../../ui/unos-broja-option/unos-broja-option';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { OpstaPravilaFormService } from '@kodit/core/form-definitions';
import { RadniNalogService } from '../../radni-nalog/radni-nalog.service';
import { OtpremnicaService } from '../../otpremnica/otpremnica.service';

@Component({
  selector: 'kodit-racun-form-container',
  templateUrl: './racun-form-container.component.html',
  styleUrls: ['./racun-form-container.component.scss'],
})
export class RacunFormContainerComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  /* Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.EXTRA_LARGE
  );

  /** Subs */
  private _subs: Subscription = new Subscription();

  opstaPravilaKonfiguracija: FormGroupTypeSafe<IOpstaPravilaKonfiguracijaDto>;
  jeKonvertovanjeOtpremniceUFakturu: boolean;
  vezaniRacuni: VezaniRacunDto[] = [];
  dodatniPodaciText: string = '';
  buttons: MenuItem[];
  showAvansneRacuneCard: boolean;
  showInterniBrojZaRutiranje: boolean = true;
  nazivRacuna: string;
  btnStatus: boolean;
  shouldDisplayOdabirIzvorneFakture: boolean;
  shouldDisplayVezaneRacune: boolean;
  prikaziOznake: boolean;
  napomenaOPoreskomOslobadjanju: string;
  dodatnaNapomena: string;
  currentMonth: number = new Date().getMonth();
  proslaGodinaLabel: string = `Koristi godinu ${new Date().getFullYear() - 1}`;
  tip: TipRacuna;
  interniMagacinCtrl: FormControl;

  /** Conditional props */
  showMessage: boolean = false;
  messageText: string = '';
  showStranka: boolean = true;
  prikazInformacijaOIsporuci: boolean = true;
  jeEditMode: boolean;
  jeAvansniPrekoPredracuna: boolean;
  jeDomaciRacun: boolean = true;
  jePredracun: boolean;
  jePonuda: boolean;
  jeAvansniRacun: boolean;
  jeIzmenaOsnovice: boolean;
  jeOtpremnica: boolean;
  jeIzlaznaFaktura: boolean;
  jeUlaznaFaktura: boolean;
  jeRadniNalog: boolean;
  jeDuplikat: boolean;
  showUnosBrojAutomatski: boolean = true;
  prikaziSekcijuZaRacunanjeTezine: boolean = false;

  /** Constants */
  vrstaRacunaUlazni: VrstaRacuna = VrstaRacuna.ULAZNI;
  vrstaRacunaIzlazni: VrstaRacuna = VrstaRacuna.IZLAZNI;

  /** I/O */
  @Input() racunFormConfig: RacunFormConfig;
  @Input() rokVazenjaCtrl: AbstractControl;
  @Input() excludeTipovePredmeta: TipPredmeta[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _racunService: RacunService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _client: SharedClient,
    private _alertService: AlertService,
    private _opstaPravilaService: OpstaPravilaFormService,
    private _racuniClient: RacuniClient,
    private _radniNalogService: RadniNalogService,
    private _otpremnicaService: OtpremnicaService
  ) {
    this.messageText = 'Broj već postoji';
  }

  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    // this._loadFormConfiguration();
    this._racunService.setRacunForm = this.racunFormConfig.racunForm;

    this.prikaziOznake =
      this._route.snapshot.data.formConfiguration.prikaziOznake;

    this.jeEditMode =
      this._route.snapshot.data.racun?.racunDto?.id != null;

    this.jeDuplikat = this._route.snapshot.data.jeDuplikat;

    this.jeKonvertovanjeOtpremniceUFakturu =
      this._route.snapshot.data.jeKonvertovanjeOtpremniceUFakturu;

    // Ukoliko imamo vezani racun za racun iz kog pravimo duplikat potrebno je
    // da ponovo prekalkulisemo iznose jer se VezaniRacuni ne ubacuju na duplikat racuna
    if(this.jeDuplikat){
      this._racunService.calculateUkupno();
    }

    if(!!this._route.snapshot.data.racun?.racunDto?.valutaText){
      this.jeDomaciRacun = this._route.snapshot.data.racun?.racunDto?.valutaText === SERBIA_CURRENCY_ALPHA_CHAR;
      if(!this.jeDomaciRacun){
        this.updateSrednjiKurs();
      }
    }

    if (
      !this.jeEditMode &&
      !this.jeDuplikat &&
      !this.jeKonvertovanjeOtpremniceUFakturu
    ) {
      this.racunFormConfig.racunForm.controls.podaciUplateDevizniDto.get(
        'srednjiKursNaDan'
      ).value = 0;
    }

    if (this.racunFormConfig.tip === TipRacuna.OTPREMNICA) {
      this.interniMagacinCtrl = this._otpremnicaService.getInterniMagacinId;

      // Postavi validator prilikom ucitavanja racuna (EDIT)
      this._setValidatorsForInternaOtpremnicaMagacin(
        this._otpremnicaService.getVrsta.value
      );

      this._subs.add(
        this._otpremnicaService.getVrsta.valueChanges.subscribe((res) => {
          this._setValidatorsForInternaOtpremnicaMagacin(res);
        })
      );
    }

    // Prikazi na ucitavanje racuna (EDIT)
    this._setPrikaziSekcijuZaRacunanjeTezine();
    this._setPrikaziInterniBrojZaRutiranje();

    switch (this.racunFormConfig.tip) {
      case TipRacuna.IZLAZNA_FAKTURA:
        this.dodatniPodaciText = 'Dodatni podaci izlazne fakture';
        this.nazivRacuna = 'računa';
        this.jeIzlaznaFaktura = true;
        break;
      case TipRacuna.ULAZNA_FAKTURA:
        this.dodatniPodaciText = 'Dodatni podaci ulazne fakture';
        this.nazivRacuna = 'računa';
        this.jeUlaznaFaktura = true;
        break;
      case TipRacuna.PREDRACUN:
        this.jePredracun = true;
        this.nazivRacuna = 'predračuna';
        break;
      case TipRacuna.PONUDA:
        this.jePonuda = true;
        this.nazivRacuna = 'ponude';
        break;
      case TipRacuna.OTPREMNICA:
        this.dodatniPodaciText = 'Dodatni podaci otpremnice';
        this.jeOtpremnica = true;
        this.nazivRacuna = 'otpremnice';
        break;
      case TipRacuna.DOKUMENT_O_POVECANJU:
        this.nazivRacuna = 'dokumenta o povećanju';
        this.jeIzmenaOsnovice = true;
        break;
      case TipRacuna.DOKUMENT_O_SMANJENJU:
        this.nazivRacuna = 'dokumenta o smanjenju';
        this.jeIzmenaOsnovice = true;
        break;
      case TipRacuna.AVANSNI_RACUN:
        this.jeAvansniRacun = true;
        this.nazivRacuna = 'avansnog računa';
        break;
      case TipRacuna.RADNI_NALOG:
        this.nazivRacuna = 'radnog naloga';
        this.jeRadniNalog = true;
        break;
    }

    this.jeAvansniPrekoPredracuna =
      this._route.snapshot.data['jeAvansniPrekoPredracuna'];

    this.prikazInformacijaOIsporuci =
      (this.jeIzlaznaFaktura || this.jeOtpremnica) &&
      this._route.snapshot.data.formConfiguration.prikaziSekcijuZaInformacijeOIsporuci

    this._subscriptions();
  }

  handleVrstaOtpremniceChanged(vrstaOtpremnice: VrstaOtpremnice) {
    if (
      vrstaOtpremnice === VrstaOtpremnice.STANDARDNA ||
      vrstaOtpremnice === VrstaOtpremnice.EMPTY
    ) {
      this._racunService.setStrankaRequiredValidator();
      this.showStranka = true;
    } else {
      this._racunService.resetStrankaRequiredValidator();
      this.showStranka = false;
    }
  }

  handleCheckIfBrojRacunaExists() {
    if (
      this.racunFormConfig.tip == TipRacuna.AVANSNI_RACUN ||
      this.racunFormConfig.tip == TipRacuna.PREDRACUN
    )
      this._subs.add(
        this._racuniClient
          .checkIfBrojRacunaExists(
            new CheckIfBrojRacunaExistsQuery({
              broj: this.racunFormConfig.racunForm.controls.broj.value,
              vrstaRacuna: this.racunFormConfig.racunForm.controls.vrsta.value,
            })
          )
          .subscribe((res) => {
            this.showMessage = res.data;
          })
      );
  }

  onSave() {
    console.log(this.racunFormConfig.racunForm);
    this.racunFormConfig.saveCallback();
  }

  onCancel() {
    this.racunFormConfig.cancelCallback();
  }

  onMemorandumChanged(dto: IMemorandumAutocompleteDto) {
    this.racunFormConfig.racunForm.controls.napomena.patchValue(dto.napomena);
    this.racunFormConfig.racunForm.controls.napomenaOPoreskomOslobadanju.patchValue(
      dto.napomenaOPoreskomOslobadanju
    );
  }

  onPrint() {
    window.print();
  }

  updateValutaText(valuta: ValutaDto) {
    this.racunFormConfig.racunForm.controls.valutaText.patchValue(
      valuta.kodValuteAlfaChar
    );

    this.jeDomaciRacun = valuta.kod === SERBIA_CURRENCY_CODE;
    this._racunService.setJeDomaciRacun = this.jeDomaciRacun;

    if (!this.jeDomaciRacun) {
      this._subs.add(
        this._client
          .getKursZaValute(
            new GetKursZaValuteQuery({ currencyCodes: [valuta.kod] })
          )
          .subscribe((res) => {
            this._racunService.setCurrencyMiddleRate =
              res.data[0]?.middleRate ?? 0;
            if (res.data[0] == undefined) {
              this._alertService.addWarnMsg(
                'U sistemu Narodne Banke Srbije ne postoji vrednost srednjeg kursa valute koju ste odabrali.'
              );
            }
          })
      );
    } else {
      this._racunService.setCurrencyMiddleRate = null;
    }
  }

  updateSrednjiKurs() {
    this._racunService.setCurrencyMiddleRate =
      this.racunFormConfig.racunForm.get(
        'podaciUplateDevizniDto.srednjiKursNaDan'
      ).value;
  }

  handleUnosBrojaOptionChanged(option: UnosBrojaOption) {
    this.showUnosBrojAutomatski = option === UnosBrojaOption.AUTOMATSKI;

    if (option === UnosBrojaOption.MANUELNO) {
      this.racunFormConfig.racunForm.controls.broj.setValidators([
        Validators.required,
        Validators.maxLength(128),
      ]);
    } else {
      this.racunFormConfig.racunForm.controls.broj.setValidators(
        Validators.nullValidator
      );
    }
    this.racunFormConfig.racunForm.controls.broj.updateValueAndValidity();
  }


  // private _loadFormConfiguration() {
  //   this._subs.add(
  //     this._client.getFormConfiguration().subscribe((res) => {
  //       this.formConfDto = res.data;
  //       // set opsta pravila to racun if new
  //       if (!this.jeEditMode) {
  //         this.racunFormConfig.racunForm
  //           .get('mestoIzdavanja')
  //           .patchValue(this.formConfDto.mestoIzdavanjaPrometa);
  //         this.racunFormConfig.racunForm
  //           .get('mestoPrometa')
  //           .patchValue(this.formConfDto.mestoIzdavanjaPrometa);
  //         this.racunFormConfig.racunForm
  //           .get('prikazRabata')
  //           .patchValue(this.formConfDto.prikazRabata);
  //         this.racunFormConfig.racunForm
  //           .get('mestoIzdavanja')
  //           .updateValueAndValidity();
  //         this.racunFormConfig.racunForm
  //           .get('mestoPrometa')
  //           .updateValueAndValidity();
  //       }
  //     })
  //   );
  // }

  private _setPrikaziSekcijuZaRacunanjeTezine() {
    this.opstaPravilaKonfiguracija =
      this._opstaPravilaService.GetOpstaPravilaFormGroup(
        this._route.snapshot.data.formConfiguration
      );

    if (
      this.opstaPravilaKonfiguracija.controls.prikaziSekcijuZaRacunanjeTezine
        .value &&
      this._racunService.getTipRacuna === TipRacuna.IZLAZNA_FAKTURA
    ) {
      this.prikaziSekcijuZaRacunanjeTezine = true;
    }
  }

  // region Validators

  private _setPrikaziInterniBrojZaRutiranje() {
    !!this.racunFormConfig.racunForm.controls.podtipDokumenta.value
      ? (this.showInterniBrojZaRutiranje = false)
      : (this.showInterniBrojZaRutiranje = true);
  }

  // endregion Validator

  private _setValidatorsForInternaOtpremnicaMagacin(vrsta: VrstaOtpremnice) {
    if (vrsta == VrstaOtpremnice.EMPTY || vrsta == VrstaOtpremnice.INTERNA) {
      this.interniMagacinCtrl.setValidators(Validators.required);
    } else {
      this.interniMagacinCtrl.clearValidators();
    }
    this.interniMagacinCtrl.updateValueAndValidity();
  }

  private _subscriptions(){
    if (this.jeIzlaznaFaktura || this.jeUlaznaFaktura) {
      this._subs.add(
        this._racunService.getStrankaHasAvansneRacune.subscribe((res) => {
          this.showAvansneRacuneCard =
            res ||
            this.racunFormConfig.racunForm.value.avansneUplateRacunaTableDto
              .length > 0;
        })
      );
    }
    this._subs.add(
      this._racunService.hasVezaniRacun().subscribe((hasSomeVezaniRacun) => {
        this.shouldDisplayOdabirIzvorneFakture =
          !hasSomeVezaniRacun && this.jeIzmenaOsnovice;
        this.shouldDisplayVezaneRacune = hasSomeVezaniRacun;
      })
    );
    this._subs.add(
      this._racunService.getJeDomaciRacun.subscribe((val) => {
        this.jeDomaciRacun = val;
      })
    );
    this._subs.add(
      this._racunService.getRacunFormObject.controls.podtipDokumenta.valueChanges.subscribe(
        (res: BuyerReference) => {
          if (res) {
            this.showInterniBrojZaRutiranje = false;
            this._racunService.getRacunFormObject.controls.interniBrojZaRutiranje.patchValue(
              null
            );
          } else {
            this.showInterniBrojZaRutiranje = true;
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this._radniNalogService.vrstaRadnogNaloga$.next(
      VrstaRadnogNaloga.PROIZVODNJA_PO_NARUDZBINI
    );
    this._subs.unsubscribe();
    this._racunService.resetService();
  }
}
