import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {
  IdentityClient,
  IResetPasswordCommand,
  IResetPasswordInitCommand,
  ResetPasswordCommand,
  ResetPasswordInitCommand,
  UpdateUserAccessPasswordCommand,
  UserAccessVia,
} from '@kodit/core/data-api';
import {
  FormHelper,
  MobilniTelefonFormService,
  UpdatePasswordFormService,
} from '@kodit/core/form-definitions';
import {AlertService} from '@kodit/core/services';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {Message, SelectItem} from 'primeng/api';
import {from as observableFrom, Subscription} from 'rxjs';
import {concatMap, finalize} from 'rxjs/operators';

@Component({
  selector: 'kodit-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  requestForm: FormGroupTypeSafe<IResetPasswordInitCommand>;
  resetForm: FormGroupTypeSafe<IResetPasswordCommand>;
  availableUserTenants: string[] = [];

  errorMessage: Message[] = [];
  showError: boolean;
  showResetForm: boolean = false;
  resetViaEmail: UserAccessVia = UserAccessVia.EMAIL;

  methodOptions: SelectItem[];

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _formHelper: FormHelper,
    private _router: Router,
    private _client: IdentityClient,
    private _mobilniFS: MobilniTelefonFormService,
    private _alertService: AlertService,
    private _updatePasswordService: UpdatePasswordFormService
  ) {
  }

  ngOnInit(): void {
    this.requestForm = this._fb.group<IResetPasswordInitCommand>({
      korisnikEmail: new FormControl('', {
        validators: [Validators.required, Validators.email],
      }),
      resetVia: new FormControl(UserAccessVia.EMAIL),
      mobilniTelefonDto: this._mobilniFS.GetMobilniFormGroup(null, false),
    });

    this.resetForm = this._fb.group<IResetPasswordCommand>(
      {
        korisnikEmail: new FormControl(
          this.requestForm.value.korisnikEmail
        ),
        resetVia: new FormControl(this.requestForm.value.resetVia),
        mobilniTelefonDto: this._mobilniFS.GetMobilniFormGroup(
          this.requestForm.value.mobilniTelefonDto,
          false
        ),
        newPassword: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(50),
          ],
        }),
        newPasswordCheck: new FormControl(null, {
          validators: [Validators.required, Validators.maxLength(50)],
        }),
        confirmationCode: new FormControl(null, {
          validators: [Validators.required],
        }),
      },
      {validators: this._updatePasswordService.passwordMatchValidator}
    );

    this.methodOptions = [
      {
        label: 'E-Maila',
        value: UserAccessVia.EMAIL,
        icon: 'far fa-envelope',
      },
      {
        label: 'Telefona',
        value: UserAccessVia.MOBILE,
        icon: 'fas fa-mobile-alt',
      },
    ];
    this.onResetViaChange();
  }

  sendRequest() {
    this.showError = false;
    this.errorMessage = [];

    if (this.requestForm.invalid) {
      this._formHelper.invalidateForm(this.requestForm);
      return;
    }

    this._subs.add(this._client
      .resetPasswordInit(this.requestForm.value as ResetPasswordInitCommand)
      .pipe(
        finalize(() => {
        })
      )
      .subscribe((result) => {
        if (!result.succeeded) {
          this._alertService.addWarnMsg(result.messages[0]);
          return;
        }
        this.availableUserTenants = result.data.userAvailableTenants;
        this._alertService.addSuccessMsg(result.messages[0]);

        this.resetForm = this._fb.group<IResetPasswordCommand>(
          {
            korisnikEmail: new FormControl(
              this.requestForm.value.korisnikEmail
            ),
            resetVia: new FormControl(this.requestForm.value.resetVia),
            mobilniTelefonDto: this._mobilniFS.GetMobilniFormGroup(
              this.requestForm.value.mobilniTelefonDto,
              false
            ),
            newPassword: new FormControl(null, {
              validators: [
                Validators.required,
                Validators.minLength(9),
                Validators.maxLength(50),
              ],
            }),
            newPasswordCheck: new FormControl(null, {
              validators: [Validators.required, Validators.maxLength(50)],
            }),
            confirmationCode: new FormControl(null, {
              validators: [Validators.required],
            }),
          },
          {validators: this._updatePasswordService.passwordMatchValidator}
        );
        this.showResetForm = true;
      }));
  }

  resetPassword() {
    this.showError = false;
    this.errorMessage = [];
    if (this.resetForm.invalid) {
      this._formHelper.invalidateForm(this.resetForm);
      return;
    }

    this._subs.add(
      this._client.resetPassword(
        this.resetForm.value as ResetPasswordCommand
      )
        .subscribe((res) => {
          this._updateUserAccessPassword(res.messages[0]);
        }, error => {
          this._alertService.addFailedMsg(error.messages);
          this._router.navigate(['/autentikacija', 'prijava']).then();
        })
    );
  }

  private _updateUserAccessPassword(successMessage: string) {
    this._subs.add(
      this._client
        .updateUserAccessPassword(
          new UpdateUserAccessPasswordCommand({
            korisnikEmail: this.resetForm.value.korisnikEmail,
            newPassword: this.resetForm.value.newPassword,
            newPasswordCheck: this.resetForm.value.newPasswordCheck,
          })
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(successMessage);
          this._router.navigate(['/autentikacija', 'prijava']).then();
        })
    );
  }

  onResetViaChange() {
    if (this.requestForm.value.resetVia === this.resetViaEmail) {
      this.requestForm.controls.korisnikEmail.setValidators([
        Validators.required,
        Validators.email,
      ]);
      this._mobilniFS.SetMobilniTelefonValidators(
        this.requestForm.controls.mobilniTelefonDto as FormGroup,
        false
      );
    } else {
      this.requestForm.controls.korisnikEmail.clearValidators();
      this._mobilniFS.SetMobilniTelefonValidators(
        this.requestForm.controls.mobilniTelefonDto as FormGroup,
        true
      );
    }

    this.requestForm.controls.korisnikEmail.updateValueAndValidity();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
