import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IKonfiguracijaDeviznogRacunaDto,
  RacuniClient,
  ResultOfKonfiguracijaDeviznogRacunaDto,
  UpdateKonfiguracijaDeviznihCommand,
} from '@kodit/core/data-api';
import {
  FormHelper,
  KonfiguracijaDeviznogRacunaFormService,
} from '@kodit/core/form-definitions';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-konfiguracija-deviznog-racuna-form',
  templateUrl: './konfiguracija-deviznog-racuna-form.component.html',
  styleUrls: ['./konfiguracija-deviznog-racuna-form.component.scss'],
})
export class KonfiguracijaDeviznogRacunaFormComponent
  implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<IKonfiguracijaDeviznogRacunaDto>;

  constructor(
    public _dialogRef: DynamicDialogRef,
    public _dialogConfig: DynamicDialogConfig,
    private _alertService: AlertService,
    private _client: RacuniClient,
    private _fs: KonfiguracijaDeviznogRacunaFormService,
    private _formHelper: FormHelper
  ) {}

  ngOnInit(): void {
    this.form = this._fs.GetKonfiguracijaDeviznogRacunaFormGroup(
      this._dialogConfig?.data?.model
    );
    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.save();
    }
  }

  private save() {
    //('form: ', this.form);
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._subs.add(
      this._client
        .updateKonfiguracijaDeviznog(
          this.form.value as UpdateKonfiguracijaDeviznihCommand
        )
        .subscribe(
          (result: ResultOfKonfiguracijaDeviznogRacunaDto) => {
            this._alertService.addSuccessMsg(result.messages[0]);

            this._dialogRef.close(result.data);
          },
          (error) => {
            this._subs.add(
              error.subscribe((x: string) => {
                this._alertService.addFailedMsg(x);
              })
            );
          }
        )
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
