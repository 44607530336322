import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SefClient } from '@kodit/core/data-api';
import { SefService } from '@kodit/core/services';
import { async } from 'rxjs/internal/scheduler/async';

@Injectable({
  providedIn: 'root',
})
export class AfterLoginService implements OnDestroy {
  private readonly _subs: Subscription;

  constructor(private _sefClient: SefClient, private _sefService: SefService) {
    this._subs = new Subscription();
  }

  /**
   * Vraca nam potrebne podatke (konfiguracione) koji su nam neophodni nakon prijave korisnika
   */
  getDataAfterLogin() {
    this._getSefHealthInfo();
  }

  private _getSefHealthInfo() {
    this._subs.add(
      this._sefClient.getSefHealhStatus().subscribe((isSefActive) => {
        if (isSefActive.succeeded) {
          this._sefService.setIsSefActive = {
            isActive: isSefActive.data.isActive,
            background: isSefActive.data.background,
          };
        }
      })
    );
  }

  private _openApiKeyDialog() {}

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
