import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import {
  FiskalniRacuniClient,
  GetPeriodicReportQuery,
  IGetPeriodicReportQuery,
  InvoiceReportResponse,
  InvoiceType,
  TransactionType,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService } from '@kodit/core/services';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  vrstaPaymentStr,
  vrstaTransakcijeStr
} from 'libs/core/moduli/maloprodaja/src/lib/fiskalni-racun/fiskalni-racun.service';
import { vrstaFiskalnogDescriptionMap } from 'libs/core/moduli/maloprodaja/src/lib/fiskalni-racun/subforms/akcije-fiskalnog-racuna/akcije-fiskalnog-racuna.component';
import { Subscription } from 'rxjs';

export enum TipIzvestajaFiskalneKase {
  DNEVNI = 0,
  PERIODICNI = 1,
}

@Component({
  selector: 'kodit-izvestaj-fiskalne-kase-page',
  templateUrl: './izvestaj-fiskalna-kasa-page.component.html',
  styleUrls: ['./izvestaj-fiskalna-kasa-page.component.scss'],
})
export class IzvestajFiskalnaKasaPageComponent implements OnInit, OnDestroy {
  /** Maps */
  vrstaPaymentStr: Map<number, string> = vrstaPaymentStr;
  vrstaFiskalnogStr: Map<InvoiceType, string> = vrstaFiskalnogDescriptionMap;
  vrstaTransakcijeStr : Map<TransactionType, string> = vrstaTransakcijeStr;

  /** Subs */
  private _subs: Subscription;

  /** Props */
  tipIzvestaja: TipIzvestajaFiskalneKase;
  dnevniIzvestaj: TipIzvestajaFiskalneKase = TipIzvestajaFiskalneKase.DNEVNI;
  periodicniIzvestaj: TipIzvestajaFiskalneKase =
    TipIzvestajaFiskalneKase.PERIODICNI;
  isButtonDisabled: boolean = false;

  /** Report */
  fiskalnaKasaReport: InvoiceReportResponse;
  periodicForm: FormGroupTypeSafe<IGetPeriodicReportQuery>;

  /** Html report variables */
  ukupanPrometUkupnoUplaceno: number = 0;
  ukupanPrometIznosPoreza: number = 0;
  period: string = '';

  constructor(
    private _route: ActivatedRoute,
    private _fiskalniClient: FiskalniRacuniClient,
    private _fb: FormBuilderTypeSafe,
    private _formHelper: FormHelper,
    private _alertService: AlertService
  ) {
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this.tipIzvestaja = this._route.snapshot.data['tipIzvestaja'];

    if (this.tipIzvestaja === this.dnevniIzvestaj) {
      this.stampajDnevniIzvestaj(false, false);
    }

    if (this.tipIzvestaja === this.periodicniIzvestaj) {
      this.periodicForm = this._fb.group<IGetPeriodicReportQuery>({
        startDate: new FormControl(null, {
          validators: [Validators.required],
        }),
        endDate: new FormControl(null, {
          validators: [Validators.required],
        }),
        print: new FormControl(null),
      });
    }
  }

  stampajDnevniIzvestaj(print: boolean, closeDay: boolean) {
    this.isButtonDisabled = true;
    this._subs.add(
      this._fiskalniClient.getDailyReport(print, closeDay).subscribe((res) => {
        this.fiskalnaKasaReport = res.data!;
        this._calculateIzvestaj();
        if (print) {
          this._alertService.addWarnMsg('Štampanje u toku!');
        }
        this.isButtonDisabled = false;
      })
    );
  }

  stampajPeriodicniIzvestaj(print: boolean) {
    if (this.periodicForm.invalid) {
      this._formHelper.invalidateForm(this.periodicForm);
      return;
    }

    this.isButtonDisabled = true;
    this.periodicForm.controls.print?.patchValue(print);

    this._subs.add(
      this._fiskalniClient
        .getPeriodicReport(this.periodicForm.value as GetPeriodicReportQuery)
        .subscribe((res) => {
          this.fiskalnaKasaReport = res.data!;
          this._calculateIzvestaj();
          this._setPeriod();
          this.periodicForm.reset();
          if (print) {
            this._alertService.addWarnMsg('Štampanje u toku!');
          }
          this.isButtonDisabled = false;
        })
    );
  }

  private _calculateIzvestaj() {
    console.log(this.fiskalnaKasaReport);
    this.fiskalnaKasaReport.total?.taxItems?.forEach((x) => {
      this.ukupanPrometUkupnoUplaceno += x.total ?? 0;
      this.ukupanPrometIznosPoreza += x.amount ?? 0;
    });
  }

  private _setPeriod() {
    var datePipe = new DatePipe('en-US');

    const startDate = datePipe.transform(
      this.periodicForm?.controls.startDate?.value,
      'dd.MM.yyyy'
    );
    const endDate = datePipe.transform(
      this.periodicForm?.controls.endDate?.value,
      'dd.MM.yyyy'
    );

    this.period = startDate + ' - ' + endDate;
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
