import { filter as _filter } from 'lodash';
import { concat as _concat } from 'lodash';
import { isNull as _isNull } from 'lodash';

/**
 * Iterates over elements of collection, returning an array of all elements predicate returns truthy for.
 * The predicate is invoked with three arguments: (value, index|key, collection).
 * @param collection The collection to iterate over.
 * @param predicate The function invoked per iteration.
 * @returns Returns the new filtered array.
 */
export function filterBy<T>(
  collection: Array<T>,
  predicate: (value: any, index?: number, collection?: Array<T>) => any
): Array<T> {
  return _filter<T>(collection, (x) => {
    return predicate(x);
  });
}

/**
 * Creates a new array concatenating array with any additional arrays and/or values.
 * @param currentArray The array to concatenate.
 * @param values The values to concatenate.
 * @returns Returns the new concatenated array.
 */
export function concat<T>(
  currentArray: Array<T>,
  values: Array<T>,
  removeNulls: boolean = true
): Array<T> {
  if (removeNulls) {
    currentArray = _filter(currentArray, (el) => !_isNull(el));
    values = _filter(values, (el) => !_isNull(el));
  }
  return _concat(currentArray, values);
}
