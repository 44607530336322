import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import {
  ICreateKorisnikCommand,
  IUpdateKorisnikCommand,
} from '@kodit/core/data-api';
import { MobilniTelefonFormService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class KorisnikFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _mobilniFS: MobilniTelefonFormService
  ) {}

  /**
   * Konvertuje model za korisnika i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model korisnika od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetKorisnikFormGroup(
    isEditMode: boolean
  ): FormGroupTypeSafe<ICreateKorisnikCommand | IUpdateKorisnikCommand> {
    return this._fb.group<ICreateKorisnikCommand | IUpdateKorisnikCommand>(
      {
        id: new FormControl(null),
        firstName: new FormControl(null, {
          validators: [Validators.required, Validators.maxLength(30)],
        }),
        lastName: new FormControl(null, {
          validators: [Validators.required, Validators.maxLength(50)],
        }),
        email: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.email,
            Validators.maxLength(50),
          ],
        }),
        mobilniDto: this._mobilniFS.GetMobilniFormGroup(null, true),
        password: new FormControl(null, {
          validators: isEditMode
            ? Validators.nullValidator
            : [
                Validators.required,
                Validators.minLength(6),
                Validators.maxLength(50),
              ],
        }),
        passwordCheck: new FormControl(null, {
          validators: isEditMode
            ? Validators.nullValidator
            : [Validators.required, Validators.maxLength(50)],
        }),
        image: new FormControl(null),
      },
      {
        validators: isEditMode
          ? Validators.nullValidator
          : this.PasswordMatchValidator,
      }
    );
  }

  public GetCreateKorisnikFormGroup(
    userMail: string,
    roleId: string,
    tenantKey: string
  ): FormGroupTypeSafe<ICreateKorisnikCommand> {
    return this._fb.group<ICreateKorisnikCommand>(
      {
        firstName: new FormControl(null, {
          validators: [Validators.required, Validators.maxLength(30)],
        }),
        lastName: new FormControl(null, {
          validators: [Validators.required, Validators.maxLength(50)],
        }),
        email: new FormControl(userMail, {
          validators: [
            Validators.required,
            Validators.email,
            Validators.maxLength(50),
          ],
        }),
        mobilniDto: this._mobilniFS.GetMobilniFormGroup(null, true),
        password: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(50),
          ],
        }),
        passwordCheck: new FormControl(null, {
          validators: [Validators.required, Validators.maxLength(50)],
        }),
        roleId: new FormControl(roleId),
        tenantKey: new FormControl(tenantKey),
      },
      {
        validators: this.PasswordMatchValidator,
      }
    );
  }

  public PasswordMatchValidator: ValidatorFn = (
    group: FormGroup
  ): ValidationErrors | null => {
    const password = group.get('password');
    const passwordCheck = group.get('passwordCheck');

    return password && passwordCheck && password.value !== passwordCheck.value
      ? { passwordMatchValidator: true }
      : null;
  };
}
