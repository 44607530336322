import { Injectable } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import * as dataApi from '@kodit/core/data-api';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';

@Injectable({
  providedIn: 'root',
})
export class StatusRacunaFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  //Status racuna
  public GetStatusFormArray(statusi: dataApi.StatusRacunaDto[]): FormArray {
    if (!statusi || statusi.length === 0) {
      return this._fb.array([]);
    }
    let result: FormArray = this._fb.array([]);

    statusi.forEach((status) => {
      result.push(this.GetStatusFormGroup(status));
    });
    return result;
  }

  public GetStatusFormGroup(
    model?: dataApi.IStatusRacunaDto
  ): FormGroupTypeSafe<dataApi.IStatusRacunaDto> {
    return this._fb.group<dataApi.IStatusRacunaDto>({
      status: new FormControl(model?.status ?? dataApi.StatusRacuna.EMPTY),
    });
  }

  //Status uplate racuna
  public GetStatusUplateFormArray(statusi: dataApi.StatusUplateRacunaDto[]): FormArray {
    if (!statusi || statusi.length === 0) {
      return this._fb.array([]);
    }
    let result: FormArray = this._fb.array([]);

    statusi.forEach((status) => {
      result.push(this.GetStatusUplateFormGroup(status));
    });
    return result;
  }

  public GetStatusUplateFormGroup(
    model?: dataApi.IStatusUplateRacunaDto
  ): FormGroupTypeSafe<dataApi.IStatusUplateRacunaDto> {
    return this._fb.group<dataApi.IStatusUplateRacunaDto>({
      status: new FormControl(model?.status ?? dataApi.StatusUplateRacuna.EMPTY),
    });
  }

  //Status racuna dropdown
  
}
