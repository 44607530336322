import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
@Component({
  selector: 'kodit-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss'],
})
export class TextareaInputComponent implements OnInit {
  /** I/O */
  @Input() formCtrl: AbstractControl | undefined;
  @Input() ctrlPlaceholder: string = '';
  @Input() formCtrlId: string = 'textarea-input';
  @Input() isDisabled: boolean;
  @Output() keyReleased = new EventEmitter();
  @Output() onInputLeft = new EventEmitter<string | number>();

  constructor() {}

  ngOnInit(): void {}

  onKeyUp(value: any) {
    this.keyReleased.emit(value);
  }

  inputLeft(event: any) {
    if (this.onInputLeft) {
      this.onInputLeft.emit(event.target.value);
    }
  }
}
