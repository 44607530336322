import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IRacunInfoDto,
  StatusRacuna,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { LocationService, SharedService } from '@kodit/core/services';
import { Subscription } from 'rxjs';
import { RacunInfoService } from '../racun-info/racun-info.service';

@Component({
  selector: 'kodit-osnovne-informacije',
  templateUrl: './osnovne-informacije.component.html',
  styleUrls: ['./osnovne-informacije.component.scss'],
})
export class OsnovneInformacijeComponent implements OnInit, OnDestroy {
  private _getSub = new Subscription();

  /** Props */
  racunInfoDto: IRacunInfoDto;
  naziv: string;
  jeUlazniRacun: boolean;
  shouldDisplayVezaneRacune: boolean;
  razlogLabel: string;

  constructor(
    private _location: LocationService,
    private _sharedService: SharedService,
    private _racunInfoService: RacunInfoService
  ) {}

  ngOnInit(): void {
    this._getSub.add(
      this._racunInfoService.getRacunInfoDto.subscribe((res) => {
        if (res) {
          this.racunInfoDto = res;
          this.jeUlazniRacun = this.racunInfoDto.vrsta === VrstaRacuna.ULAZNI;
          this.naziv = this._sharedService.racunNames[this.racunInfoDto.tip];
          this.shouldDisplayVezaneRacune =
            this.racunInfoDto.vezaniRacuniDto?.length > 0;
          this._setRazlogText();
        }
      })
    );
  }

  goToStranka() {
    this._location.routeToStrankaKartica(
      this.racunInfoDto.strankaId,
      this.racunInfoDto.tipStranke
    );
  }

  private _setRazlogText() {
    switch (this.racunInfoDto.statusDto.status) {
      case StatusRacuna.OTKAZANO:
        this.razlogLabel = 'otkazivanja';
        break;
      case StatusRacuna.ODBIJENO:
        this.razlogLabel = 'odbijanja';
        break;
      case StatusRacuna.STORNIRANO:
        this.razlogLabel = 'storniranja';
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this._getSub.unsubscribe();
  }
}
