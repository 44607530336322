import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'kodit-akcije-racuna',
  templateUrl: './akcije-racuna.component.html',
  styleUrls: ['./akcije-racuna.component.scss'],
})
export class AkcijeRacunaComponent implements OnInit {
  /** Props */
  shouldDisableBtn: boolean = false;

  /** I/O */
  @Input() saveLabel: string = 'Sačuvaj';
  @Input() cancelLabel: string = 'Otkaži';
  @Input() saveIcon: string = 'fas fa-save';
  @Input() cancelIcon: string = 'far fa-window-close';
  @Input() saveTooltip: string = 'Sačuvaj račun';
  @Input() cancelTooltip: string = 'Otkaži kreiranje računa';
  @Output() saveClicked = new EventEmitter<void>();
  @Output() cancelClicked = new EventEmitter<void>();

  constructor(
    private _router: Router       
    ) {}

  ngOnInit(): void {}

  onSave() {
    this.saveClicked.emit();
  }

  onCancel() {
    this.cancelClicked.emit();
  }

  backToTable(){
    this._router.navigate(['/racuni/izlazni-racuni']);
  }
}
