<kodit-dropdown
  [ctrlDisabled]="disabled"
  [options]="options"
  [formCtrl]="poslovnaJedinicaCtrl"
  [ctrlPlaceholder]="useAsFilter ? '' : 'Izaberite poslovnu jedinicu'"
  (optionChanged)="handlePoslovnaJedinicaChange($event)"
>
  <ng-template #selectedItem let-obj>
    <div class="p-text-left">{{ obj.naziv }}</div>
  </ng-template>

  <ng-template #item let-obj>
    <ng-container>
      <div>{{ obj.naziv }}</div>
    </ng-container>
  </ng-template>
</kodit-dropdown>
