<div class="p-fluid p-mb-5">
  <kodit-stranka-racuna-autocomplete
    (onPravnoLiceChange)="onSelectionChange($event)"
    (onClear)="onSelectionClear()"
  ></kodit-stranka-racuna-autocomplete>
</div>

<div
  [formGroup]="strankaFormGroup"
  [ngClass]="
    !strankaFormGroup.controls.naziv.valid &&
    strankaFormGroup.controls.naziv.touched
      ? 'stranka-invalid'
      : ''
  "
>
  <div class="p-field p-grid">
    <label class="p-col-fixed p-m-0" kodit-form-label>Naziv:</label>
    <div class="p-col stranka-text">
      <span>{{ strankaFormGroup.value.naziv }}</span>
    </div>
  </div>
  <div class="p-field p-grid">
    <label class="p-col-fixed p-m-0" kodit-form-label>Adresa:</label>
    <div class="p-col stranka-text">
      <span>{{ strankaFormGroup.value.adresa }}</span>
    </div>
  </div>
  <div class="p-field p-grid">
    <label class="p-col-fixed p-m-0" kodit-form-label>PIB:</label>
    <div class="p-col stranka-text">
      <span>{{ strankaFormGroup.value.pib }}</span>
    </div>
  </div>
  <div class="p-field p-grid">
    <label class="p-col-fixed p-m-0" kodit-form-label>MB:</label>
    <div class="p-col stranka-text">
      <span>{{ strankaFormGroup.value.mb }}</span>
    </div>
  </div>
</div>
<small
  *ngIf="
    !strankaFormGroup.controls.naziv.valid &&
    strankaFormGroup.controls.naziv.touched
  "
  id="stranka-help"
  class="p-invalid"
  >Morate izabrati primaoca.
</small>
