import { Component, OnDestroy, OnInit } from '@angular/core';
import { CreateRepromaterijalCommand, IRepromaterijalDto, RepromaterijaliClient, UpdateRepromaterijalCommand } from '@kodit/core/data-api';
import { FormHelper, RepromaterijalFormService } from '@kodit/core/form-definitions';
import { AlertService, Mode } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-repromaterijal-form',
  templateUrl: './repromaterijal-form.component.html',
  styleUrls: ['./repromaterijal-form.component.scss']
})
export class RepromaterijalFormComponent implements OnInit, OnDestroy {
  /** Props */
  private _subs: Subscription = new Subscription();
  repromaterijalForm!: FormGroupTypeSafe<IRepromaterijalDto>;
  mode: Mode = Mode.NEW;

  constructor(
    private _repromaterijalFS: RepromaterijalFormService,
    private _dialogRef: DynamicDialogRef,
    private _formHelper: FormHelper,
    private _dialogConfig: DynamicDialogConfig,
    private _client: RepromaterijaliClient,
    private _alertService : AlertService
  ) { }

  ngOnInit(): void {
     // init form
    this.repromaterijalForm = this._repromaterijalFS.GetRepromaterijalFormGroup();
    //

    if (this._dialogConfig.data && this._dialogConfig.data.id) {
      this.getRepromaterijal(this._dialogConfig.data.id);
      this.mode = Mode.EDIT;
    }

    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.saveRepromaterijal();
      })
    );
  }

  private saveRepromaterijal() {
    if (!this.repromaterijalForm.valid) {
      this._formHelper.invalidateForm(this.repromaterijalForm);
      return;
    }

    if (this.mode === Mode.EDIT) {
      this._subs.add(
        this._client.update(
          this.repromaterijalForm.value.id!,
          this.repromaterijalForm.value as UpdateRepromaterijalCommand
        ).subscribe((res) => {
          this._alertService.addSuccessMsg(res.messages![0]);
          this._dialogRef.close();
        })
      )
    }
    else {
      this._subs.add(
        this._client.createRepromaterijal(this.repromaterijalForm.value as CreateRepromaterijalCommand)
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.messages![0]);
            this._dialogRef.close();
          })
      );
    }
  }

  getRepromaterijal(repromaterijalId : number) {
    this._subs.add(
      this._client.getRepromaterijalById(repromaterijalId).subscribe((model) => {
        this.repromaterijalForm.patchValue(model.data!);
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
