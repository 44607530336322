<div [formGroup]="form" class="p-d-flex p-flex-row p-fluid">
  <div class="flex-1-1 p-mr-4">
    <!-- Tip  /// Koji input da iskoristimo? -->
    <kodit-field fieldLabel="Tip računa" fieldLabelFor="tip">
      <kodit-tip-racuna-dropdown
        [allowedTypes]="tipoviRacuna"
        [tipCtrl]="form.controls.tip"
        formCtrlId="tip"
      ></kodit-tip-racuna-dropdown>
    </kodit-field>

    <!-- Broj -->
    <kodit-field fieldLabel="Broj" fieldLabelFor="broj">
      <kodit-text-input
        class="show"
        ctrlPlaceholder="Broj računa"
        [formCtrl]="form.controls.broj"
        formCtrlId="broj"
      ></kodit-text-input>
    </kodit-field>
  </div>

  <div class="flex-1-1 p-mr-4">
    <div class="p-d-flex gap-12">
      <!-- Datum prometa od -->
      <kodit-field fieldLabel="Datum prometa od" fieldLabelFor="datumPrometaOd">
        <kodit-calendar
          ctrlPlaceholder="Datum prometa od"
          formCtrlId="datumPrometaOd"
          [formCtrl]="form.controls.datumPrometaOd"
        ></kodit-calendar>
      </kodit-field>

      <!-- Datum prometa do -->
      <kodit-field fieldLabel="Datum prometa do" fieldLabelFor="datumPrometaDo">
        <kodit-calendar
          ctrlPlaceholder="Datum prometa do"
          formCtrlId="datumPrometaDo"
          [formCtrl]="form.controls.datumPrometaDo"
        ></kodit-calendar>
      </kodit-field>
    </div>
    <!-- Stranka -->
    <kodit-field fieldLabel="Stranka" fieldLabelFor="stranka">
      <kodit-text-input
        ctrlPlaceholder="Naziv ili Pib"
        class="show"
        [formCtrl]="form.controls.stranka"
        formCtrlId="status"
      ></kodit-text-input>
    </kodit-field>
  </div>

  <div class="flex-1-1 p-mr-4">
    <!-- Status -->
    <kodit-field fieldLabel="Status" fieldLabelFor="status">
      <kodit-status-racuna-multiselect
        ctrlPlaceholder="Status računa"
        formCtrlId="statusi"
        [statusFormArray]="form.controls.statusi"
        (selectedStatusiRacuna)="selectedStatusiRacuna($event)"
        [allowedStatuses]="statusiRacuna"
      ></kodit-status-racuna-multiselect>
    </kodit-field>

    <!-- Period -->
    <kodit-field fieldLabel="Period" fieldLabelFor="period">
      <kodit-period-mesec-dropdown
        formCtrlId="period"
        [formCtrl]="form.controls.period"
      ></kodit-period-mesec-dropdown>
    </kodit-field>
  </div>
</div>

<!-- <div class="flex-1-1 p-mr-4">
    <!-- Status -->
<!-- <kodit-field fieldLabel="Status" fieldLabelFor="status">
      <kodit-status-slanja-racuna-dropdown
        ctrlPlaceholder="Status računa"
        formCtrlId="status"
        [formCtrl]="form.controls.statusi"
      ></kodit-status-slanja-racuna-dropdown>
    </kodit-field>
  </div>  -->
