import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
} from '@angular/router';
import {
  KompenzacijaDto,
  KompenzacijeClient,
} from '@kodit/core/data-api';
import { BaseService } from '@kodit/core/services';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KompenzacijaResolver
  extends BaseService
  implements Resolve<KompenzacijaDto> {
  constructor(private _client: KompenzacijeClient) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
  ):
    | Observable<KompenzacijaDto>
    | Promise<KompenzacijaDto>
    | KompenzacijaDto
    | null {
    const id = Number.parseInt(route.paramMap.get('id'));
    return this._client.getKompenzacijaById(id).pipe(
      map((res) => {
        return res.data;
      }),
      catchError(this.handleError),
      finalize(() => {
      })
    );
  }
}
