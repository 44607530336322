<kodit-card-container class="p-col-12">
  <div card-title>Informacije o isporuci</div>
  <div card-right-side>
    <div class="p-d-flex p-flex-row gap-12">
      <kodit-informacija-isporuke-stranke-dropdown
        *ngIf="showDropdown"
        [items]="informacijaIsporukeDropdownItems"
        [shouldDisable]="disableDropdown"
        (onItemChanged)="setInformacijeIsporuke($event)"
      >
      </kodit-informacija-isporuke-stranke-dropdown>

      <kodit-informacija-isporuke-stranke-checkbox
        *ngIf="!jeEdit"
        [formCtrl]="informacijaIsporukeFormGroup.controls.shouldCreate"
      >
      </kodit-informacija-isporuke-stranke-checkbox>
    </div>
  </div>

  <div card-body class="p-d-flex p-flex-column p-fluid">
    <div class="even-columns gap-12 p-flex-md-row">
      <kodit-field
        fieldLabel="Ime lokacije mesta isporuke"
        fieldLabelFor="imeLokacijeMestaIsporuke"
      >
        <kodit-text-input
          formCtrlId="ime_lokacije_mesta_isporuke"
          [formCtrl]="
            informacijaIsporukeFormGroup.controls.imeLokacijeMestaIsporuke
          "
        ></kodit-text-input>
      </kodit-field>
      <kodit-field fieldLabel="GLN" fieldLabelFor="gln">
        <kodit-text-input
          formCtrlId="gln"
          [formCtrl]="informacijaIsporukeFormGroup.controls.gln"
        ></kodit-text-input>
      </kodit-field>

      <kodit-drzava-dropdown
        [drzavaCtrl]="informacijaIsporukeFormGroup.controls.drzavaId"
      ></kodit-drzava-dropdown>
    </div>
    <div class="even-columns gap-12 p-flex-md-row">
      <kodit-field fieldLabel="Postanski broj" fieldLabelFor="postanskiBroj">
        <kodit-text-input
          formCtrlId="postanski_broj"
          [formCtrl]="informacijaIsporukeFormGroup.controls.postanskiBroj"
        ></kodit-text-input>
      </kodit-field>
      <kodit-field fieldLabel="Grad" fieldLabelFor="grad">
        <kodit-text-input
          formCtrlId="grad"
          [formCtrl]="informacijaIsporukeFormGroup.controls.grad"
        ></kodit-text-input>
      </kodit-field>
      <kodit-field fieldLabel="Ulica i broj" fieldLabelFor="ulica_i_broj">
        <kodit-text-input
          formCtrlId="ulica_i_broj"
          [formCtrl]="informacijaIsporukeFormGroup.controls.ulicaIBroj"
        ></kodit-text-input>
      </kodit-field>
    </div>
  </div>
</kodit-card-container>
