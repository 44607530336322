import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SharedClient } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-prikaz-radnog-naloga-dropdown',
  templateUrl: './prikaz-radnog-naloga-dropdown.component.html',
  styleUrls: ['./prikaz-radnog-naloga-dropdown.component.scss'],
})
export class PrikazRadnogNalogaDropdownComponent implements OnInit {
  private _subs: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];

  /** I/O */
  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string;
  @Input() parentCtrl: any = 'body';

  constructor(private _client: SharedClient) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client.getPrikazRadnogNaloga().subscribe((res) => {
        this.items = res.data.prikazRadnogNalogaDtoList.map((x) => ({
          value: x.prikazRadnogNaloga,
          label: x.prikazRadnogNalogaStr,
          title: x.opis,
        }));
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
