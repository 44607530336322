<div [formGroup]="form" class="p-d-flex p-flex-row p-fluid">
  <kodit-filter-name
    class="flex-1-1"
    title="Filter transakcija"
  ></kodit-filter-name>

  <div class="flex-1-1 p-mr-4">
    <kodit-field fieldLabel="Stranka" fieldLabelFor="stranka">
      <kodit-text-input
        class="show"
        [formCtrl]="form.controls.stranka"
        formCtrlId="stranka"
      >
      </kodit-text-input>
    </kodit-field>

    <kodit-field fieldLabel="Broj računa" fieldLabelFor="brojRacuna">
      <kodit-text-input
        class="show"
        [formCtrl]="form.controls.brojRacuna"
        formCtrlId="brojRacuna"
      >
      </kodit-text-input>
    </kodit-field>
  </div>

  <!-- Moze se primeniti kada se podesi Querry  -->
  <!-- <div class="flex-1-1 p-mr-4">
    <kodit-field fieldLabel="Datum prometa" fieldLabelFor="datumPrometa">
      <kodit-calendar
        ctrlPlaceholder="Datum prometa"
        formCtrlId="datumIzdavanja"
        [formCtrl]="form.controls.datumPrometa"
      ></kodit-calendar>
    </kodit-field> -->

  <div class="flex-1-1 p-mr-4">
    <kodit-field fieldLabel="Tip transakcije" fieldLabelFor="tipTransakcije">
      <kodit-tip-lager-transakcije-dropdown
        ctrlPlaceholder="Tip transakcije"
        formCtrlId="tipTransakcije"
        [tipCtrl]="form.controls.tipTransakcije"
      ></kodit-tip-lager-transakcije-dropdown>
    </kodit-field>
  </div>
</div>
