<p-fileUpload
  [customUpload]="customUpload"
  (uploadHandler)="otpremiDatoteku($event)"
  [multiple]="importMultiple"
>
  <ng-template let-file pTemplate="file">
    <div class="file-container">
      <div class="container-left">
        <i class="fa-solid fa-file-lines file-icon"></i>
        <div>
          <div class="file-name">{{ file.name }}</div>
          <div class="file-size">{{ file.size }} kb</div>
        </div>
      </div>

      <div>
        <kodit-grupa-kategorija-dropdown></kodit-grupa-kategorija-dropdown>
      </div>
    </div>
  </ng-template>
</p-fileUpload>
