import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { KorisniciClient } from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogService } from '@kodit/core/shared-ui';
import {
  DialogSize,
  DynamicDialogConfig,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { BiranjeOvlascenjaFromComponent } from '../biranje-ovlascenja-from/biranje-ovlascenja-from.component';
import { DodavanjeKorisnikaFormComponent } from '../../dodavanje-korisnika-form/dodavanje-korisnika-form.component';
import { KorisnickaGrupaFormComponent } from '../korisnicka-grupa-form/korisnicka-grupa-form.component';
import { KorisnickaGrupaService } from '../korisnicka-grupa.service';

@Component({
  selector: 'kodit-user-role-card',
  templateUrl: './user-role-card.component.html',
  styleUrls: ['./user-role-card.component.scss'],
})
export class UserRoleCardComponent implements OnInit, OnDestroy {
  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.MEDIUM_SMALL
  );

  /** Props */
  private _subs: Subscription = new Subscription();
  isNotDefaultRole: boolean;

  /** I/O */
  @Input() roleName: string;
  @Input() roleDescription: string;
  @Input() isCreateNew: boolean;
  @Input() roleId: number;

  constructor(
    private _dialogService: DynamicDialogService,
    private _client: KorisniciClient,
    private _alertService: AlertService,
    private _confirmationService: ConfirmationService,
    private _service: KorisnickaGrupaService
  ) {}

  ngOnInit(): void {
    if (!this.isCreateNew) {
      this.isNotDefaultRole =
        this.roleName.toLowerCase() !== 'admin' &&
        this.roleName.toLowerCase() !== 'knjigovođa';
    }
  }

  handleEditKorisnickaGrupa() {
    this.dialogConfig.header = 'izmena korisničke grupe';
    this.dialogConfig.data = { id: this.roleId };
    this._openGrupaDialog(this.dialogConfig);
  }

  handleIzmenaKorisnika() {
    this.dialogConfig.header = `izmena korisnika za '${this.roleName}'`;
    this.dialogConfig.data = {
      nazivGrupe: this.roleName,
    };
    this.dialogConfig.setDialogSize = DialogSize.SMALL;
    this._openIzmenaKorisnikaDialog(this.dialogConfig);
  }

  handleCreateKorisnickaGrupa() {
    this.dialogConfig.header = 'unos korisničke grupe';
    this._openGrupaDialog(this.dialogConfig);
  }

  handleDeleteKorisnickaGrupa() {
    this._confirmationService.confirm({
      message: `Da li ste sigurni da želite da obrišete korisničku grupu ${this.roleName}`,
      header: 'Potvrdite brisanje',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._subs.add(
          this._client
            .deleteKorisnickaGrupa(this.roleId.toString())
            .subscribe((res) => {
              if (res.data) {
                this._alertService.addSuccessMsg(res.messages[0]);
              } else {
                this._alertService.addWarnMsg(res.messages[0]);
              }
              this._service.setShouldReloadCards = true;
            })
        );
      },
    });
  }

  private _openIzmenaKorisnikaDialog(config?: DynamicDialogConfig): void {
    const ref = this._dialogService.open(
      DodavanjeKorisnikaFormComponent,
      config
    );
    this._subs.add(
      ref.onClose.subscribe((result: string) => {
        if (result) {
          this.roleDescription = result;
        }
      })
    );
  }

  private _openGrupaDialog(config?: DynamicDialogConfig): void {
    this.dialogConfig.setDialogSize = DialogSize.MEDIUM_SMALL;
    const ref = this._dialogService.open(KorisnickaGrupaFormComponent, config);
    this._subs.add(
      ref.onClose.subscribe((result: string) => {
        if (result) {
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
