import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ArhivskaKnjigaClient, GetKategorijeDropdownQuery, IKategorijaDokMaterijalaDto, KategorijaDokMaterijalaDto } from '@kodit/core/data-api';
import { AutoComplete } from 'primeng/autocomplete';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-dokumentarna-kategorija-autocomplete',
  templateUrl: './dokumentarna-kategorija-autocomplete.component.html',
  styleUrls: ['./dokumentarna-kategorija-autocomplete.component.scss'],
})
export class DokumentarnaKategorijaAutocompleteComponent
  implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  kategorijaModel: any;
  kategorijeSuggestions: KategorijaDokMaterijalaDto[] = [];

  /** I/0 */
  @Input() formCtrl: AbstractControl | undefined;
  @Input() selectedKategorija: string | undefined;
  @Input() jeUnosDelovodnogDokumenta: boolean = false;

  @ViewChild('autoComplete') autoComplete!: AutoComplete;

  constructor(private _arhivskaClient: ArhivskaKnjigaClient) {}

  ngOnInit(): void {
    if (this.selectedKategorija) {
      this.kategorijaModel = { naziv: this.selectedKategorija };
    }
  }

  searchOznakeNaziv(event: any) {
    this._subs.add(
      this._arhivskaClient
        .getKategorijeDropdown(
          new GetKategorijeDropdownQuery({
            naziv: event.query,
          })
        )
        .subscribe((res) => {
          this.kategorijeSuggestions = [...res.data?.kategorijeDropdown!];
        })
    );
  }

  onSelectChange(event: IKategorijaDokMaterijalaDto) {
    this.formCtrl?.patchValue(event.id);
    this._setCursorToStart();
  }

  private _setCursorToStart() {
    if (this.autoComplete && this.autoComplete.inputEL) {
      const inputElement = this.autoComplete.inputEL.nativeElement;
      inputElement.setSelectionRange(0, 0);
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
