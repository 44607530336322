import { FormControl } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IGetNeplaceniRacunTableQuery, VrstaRacuna } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NeplaceniRacunTableFilterFormService {
  constructor(
    private _fb: FormBuilderTypeSafe
    ) {}

  public GetFormGroup(
    strankaId: number,
    vrstaRacuna: VrstaRacuna,
    jeDeviznaTransakcija: boolean,

    ): FormGroupTypeSafe<IGetNeplaceniRacunTableQuery> {
    const form = this._fb.group<IGetNeplaceniRacunTableQuery>({
      vrstaRacuna: new FormControl(vrstaRacuna ?? null),
      strankaId: new FormControl(strankaId ?? null),
      jeDeviznaTransakcija: new FormControl(jeDeviznaTransakcija?? null),
      brojRacuna: new FormControl(null),
      stranke: this._fb.array([])
    });
    return form;
  }
}
