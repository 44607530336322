import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepromaterijalTableComponent } from './repromaterijal-table/repromaterijal-table.component';
import { CoreSharedUiModule } from '@kodit/core/shared-ui';
import { RepromaterijalRoutingModule } from './repromaterijal-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RepromaterijalFormComponent } from './repromaterijal-form/repromaterijal-form.component';

@NgModule({
  imports: [
    CommonModule,
    CoreSharedUiModule,
    ReactiveFormsModule,
    RepromaterijalRoutingModule
  ],
  declarations: [
    RepromaterijalTableComponent,
    RepromaterijalFormComponent
  ],
  exports: [
    RepromaterijalTableComponent
  ]
})
export class CoreModuliRepromaterijalModule {}
