<div class="p-field" *ngIf="!isVertial" [pTooltip]="formCtrlTooltip">
  <label [for]="formCtrlId" kodit-form-label
    >{{ formCtrlLabel }}{{ formCtrl.errors?.required ? ' *' : '' }}</label
  >
  <kodit-text-input
    [formCtrlId]="formCtrlId"
    [formCtrl]="formCtrl"
    [formCtrlType]="formCtrlType"
    [ctrlPlaceholder]="ctrlPlaceholder"
    [isDisabled]="isDisabled"
  ></kodit-text-input>
</div>

<div class="p-field p-grid" [pTooltip]="formCtrlTooltip" *ngIf="isVertial">
  <label
    [for]="formCtrlId"
    class="p-col-fixed p-m-0"
    kodit-left-side-label
    [labelWidth]="formCtrlLabelWidth"
    >{{ formCtrlLabel }}:</label
  >
  <div class="p-col">
    <kodit-text-input
      [formCtrlId]="formCtrlId"
      [formCtrl]="formCtrl"
      [isDisabled]="isDisabled"
    ></kodit-text-input>
  </div>
</div>
