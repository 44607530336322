import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  FileParameter,
  KontaktiClient,
  SefClient,
  TipRacuna,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import {AlertService, ButtonService, MixpanelService, SharedService} from '@kodit/core/services';
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  FileUploadService,
} from '@kodit/core/shared-ui';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'kodit-posalji-na-sef-form',
  templateUrl: './posalji-na-sef-form.component.html',
  styleUrls: ['./posalji-na-sef-form.component.scss'],
})
export class PosaljiNaSefFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  form: FormGroup;
  crfOptions: SelectItem[] = [];
  readonly MAX_FILE_SIZE: number = 75000000;
  prikaziCrf: boolean;
  showSaveEmail: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private _client: SefClient,
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _formHelper: FormHelper,
    private _uploadService: FileUploadService,
    private _buttonService: ButtonService,
    private _kontaktiClient: KontaktiClient,
    private _mixpanelService : MixpanelService,
    private _sharedService : SharedService
  ) {}

  ngOnInit(): void {
    this._uploadService.setMaxAttachmentSize = this.MAX_FILE_SIZE;

    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._sendToSef();
      })
    );
    this.form = this._fb.group({
      racunId: new FormControl(this._dialogConfig.data.racunId, {
        validators: Validators.required,
      }),

      attachments: new FormArray([]),
      posaljiNaCrf: new FormControl(false),
      kontaktEmail: new FormControl(null),
    });
    this.prikaziCrf = this._dialogConfig.data.tip === TipRacuna.IZLAZNA_FAKTURA;

    this.crfOptions = [
      { label: 'Da', value: true },
      { label: 'Ne', value: false },
    ];

    this._subs.add(
      this._uploadService.getAttachments.subscribe((attachments: File[]) => {
        (this.form.controls.attachments as FormArray).clear();
        attachments.forEach((x) => {
          (this.form.controls.attachments as FormArray).push(
            new FormControl(x)
          );
        });
      })
    );

    this._getKontaktiForStranka();
  }

  handleUpload(files: File[]) {
    files.forEach((file) => {
      (this.form.controls.attachments as FormArray).push(new FormControl(file));
    });
  }

  private _sendToSef() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._mixpanelService.izlaznaFakturaSentToSefSubmitted();

    this._buttonService.disableButton();

    const attachments: FileParameter[] = this.form.value.attachments
      ? this.form.value.attachments.map((x: File) => ({
          data: x,
          fileName: x.name,
        }))
      : null;

    // everything is ok, let's send it
    this._sharedService.displayLoadingScreen(true,"Slanje računa na SEF u toku");
    this._subs.add(
      this._client
        .sendToSef(
          this._dialogConfig.data.racunId,
          attachments,
          this.form.value.posaljiNaCrf,
          this.form.value.kontaktEmail
        )
        .subscribe((res) => {
          if(res.succeeded){
            this._mixpanelService.izlaznaFakturaSentToSefSucceeded();
            this._alertService.addSuccessMsg(res.data);
            this._dialogRef.close(true);
            return;
          }
          this._alertService.addFailedMsg(res.messages[0]);
          this._dialogRef.close(true);
        })
    );
  }

  private _getKontaktiForStranka() {
    this._subs.add(
      this._kontaktiClient
        .getKontaktiByStrankaId(this._dialogConfig.data.strankaId)
        .pipe(map((x) => x.data))
        .subscribe((res) => {
          if(res.kontakti.length > 0){
            this.showSaveEmail = false;
            this._removeKontaktEmailValidator();
          }
          else{
            this.showSaveEmail = true;
            this._addKontaktEmailValidator();
          }
        })
    );
  }

  private _addKontaktEmailValidator(){
    this.form.get('kontaktEmail').addValidators([Validators.required,Validators.email]);
    this.form.get('kontaktEmail').updateValueAndValidity();
  }

  private _removeKontaktEmailValidator(){
    this.form.get('kontaktEmail').clearValidators();
    this.form.get('kontaktEmail').updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
