import { Injectable } from '@angular/core';
// import { PrometTableDto, UplataIsplataTableDto } from '@kodit/core/data-api';
import { BehaviorSubject } from 'rxjs';
import { SERBIA_CURRENCY_ALPHA_CHAR } from '@kodit/core/shared';

@Injectable({
  providedIn: 'root',
})
export class BankaService {
  constructor() {}

  /**
   * Postavljanje modela za tabelu nakon pretrage
   */
  // prometSearchModel$ = new BehaviorSubject<PrometTableDto[]>(null);
  // get getPrometSearchModel() {
  //   return this.prometSearchModel$.asObservable();
  // }
  // set setPrometSearchModel(data: PrometTableDto[]) {
  //   this.prometSearchModel$.next(data);
  // }

  /**
   * Postavljanje modela za tabelu nakon pretrage
   */
  // uplataIsplataSearchModel$ = new BehaviorSubject<UplataIsplataTableDto[]>(
  //   null
  // );
  // get getUplataIsplataSearchModel() {
  //   return this.prometSearchModel$.asObservable();
  // }
  // set setUplataIsplataSearchModel(data: UplataIsplataTableDto[]) {
  //   this.prometSearchModel$.next(data);
  // }

  jeDevizniIzvod$ = new BehaviorSubject<boolean>(false);

  get getJeDevizniIzvod() {
    return this.jeDevizniIzvod$.asObservable();
  }

  set setJeDevizniIzvod(valutaText: string) {
    this.jeDevizniIzvod$.next(valutaText != SERBIA_CURRENCY_ALPHA_CHAR);
  }

  reset() {
    this.setJeDevizniIzvod = '';
  }
}
