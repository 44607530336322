import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilderTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { IBankaDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BankaFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  /**
   * Pravi FormGroup od prosledene bankaDto
   * @param model BankaDto objekat za pretvaranje u FormGroup
   * @returns FormGroup od prosledene bankaDto
   */
  public GetBankaFormGroup(model?: IBankaDto): FormGroup {
    return this._fb.group<IBankaDto>({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv),
      bic: new FormControl(model?.bic),
      kodBanke: new FormControl(model?.kodBanke),
    });
  }
}
