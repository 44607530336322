import { FormArray, FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import { IOdobriOdbaciSefRacunCommand } from '@kodit/core/data-api';

@Injectable({
  providedIn: 'root',
})
export class OdobriOdbaciRacunFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetFormGroup(
    jeOdobravanje: boolean,
    sefIds: number[]
  ): FormGroupTypeSafe<IOdobriOdbaciSefRacunCommand> {
    const form = this._fb.group<IOdobriOdbaciSefRacunCommand>({
      jeOdobravanje: new FormControl(jeOdobravanje),
      komentar: new FormControl(null, {
        validators: jeOdobravanje
          ? [Validators.nullValidator]
          : [Validators.required, Validators.maxLength(64)],
      }),
      racunIds: new FormArray([]),
      brojRacuna: new FormControl(null),
      praviloZaBrojRacuna: new FormControl(null),
      oznakeDto: this._fb.array([]),
    });

    sefIds.forEach((id) => [
      (form.controls.racunIds as FormArray).push(new FormControl(id)),
    ]);

    return form;
  }
}
