<kodit-card-container>
  <div card-title>SEF API menadžment</div>

  <div card-right-side>
    <!-- <button
      icon="far fa-bars-progress"
      pButton
      type="button"
      label="Kreiraj odobrene ulazne račune"
      class="p-button p-button-outlined"
      (click)="onKreiraj()"
      style="margin-right: 5px;"
    ></button> -->

    <button
      icon="far fa-edit"
      pButton
      type="button"
      label="Izmeni"
      class="p-button p-button-outlined"
      (click)="onEdit()"
    ></button>
  </div>

  <div class="p-d-flex p-flex-column gap-12" card-body>
    <kodit-card-body-text
      label="SEF API ključ"
      [text]="apiMenadzmentDto?.apiKeySEF"
    ></kodit-card-body-text>

    <kodit-card-body-text
      label="Adresa za primanje notifikacija o izlaznim fakturama"
      [text]="apiMenadzmentDto?.endpointZaIzlazneNotifikacije"
    ></kodit-card-body-text>

    <kodit-card-body-text
      label="Adresa za primanje notifikacija o ulaznim fakturama"
      [text]="apiMenadzmentDto?.endpointZaUlazneNotifikacije"
    ></kodit-card-body-text>
  </div>
</kodit-card-container>
