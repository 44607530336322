<div
  class="p-d-flex p-flex-column p-flex-md-row p-ai-center p-fluid p-flex-wrap header-box"
>
  <div class="p-field p-mr-2">
    <label for="select-vezatiZa" kodit-form-label>Veži oznaku za</label>

    <kodit-dropdown
      formCtrlId="select-vezatiZa"
      [options]="vezatiZaItems"
      (optionChanged)="handleOnItemChange($event)"
    ></kodit-dropdown>
  </div>

  <div class="p-field p-mr-2 kolicina-field" *ngIf="showKolicina">
    <label for="kolicina" kodit-form-label>Količina</label>
    <p-inputNumber
      inputId="kolicina"
      [(ngModel)]="currentStavkaKolicina"
      [min]="0"
      [max]="currentStavkaKolicinaMax"
      [useGrouping]="false"
    ></p-inputNumber>
  </div>
  <div class="p-field">
    <label for="pretraga-oznake" kodit-form-label>Oznaka</label>
    <span class="p-input-icon-left pretraga-box">
      <i class="fas fa-search"></i>
      <p-autoComplete
        inputId="pretraga-oznake"
        #oznakaInput
        [suggestions]="oznakeResults"
        field="naziv"
        placeholder="Naziv oznake..."
        pTooltip="Unesi naziv oznake za pretragu"
        (completeMethod)="searchOznake($event)"
        (onSelect)="onOznakaSelect($event)"
      >
        <ng-template let-oznaka pTemplate="item">
          <div>
            {{ oznaka.punNaziv ? oznaka.punNaziv : oznaka.naziv }}
          </div>
        </ng-template>
      </p-autoComplete>
    </span>
  </div>
</div>

<div class="p-d-flex p-flex-wrap kodit-chip-container" [formGroup]="racunForm">
  <ng-container formArrayName="oznakeDto">
    <div
      class="p-mr-2 p-mb-2 my-p-chip"
      *ngFor="let oznaka of oznakeArray.controls; index as i"
    >
      <ng-container [formGroupName]="i">
        <span class="pi pi-tags my-p-chip-icon"></span>
        <div>
          <div class="my-p-chip-text">
            {{
              oznaka.get('punNaziv').value
                ? oznaka.get('punNaziv').value
                : oznaka.get('naziv').value
            }}
          </div>
          <div class="my-p-chip-text" *ngIf="oznaka.get('kolicina').value">
            {{
              '[' +
                oznaka.get('nazivStavke').value +
                ']' +
                ' x ' +
                [oznaka.get('kolicina').value]
            }}
          </div>
        </div>
        <span
          class="far fa-times-circle my-p-chip-remove-icon"
          (click)="
            oznaka.get('kolicina').value
              ? onRemoveOznakaStavkeRacunaClicked(i, oznaka.value)
              : onRemoveOznakaRacunaClicked(i, oznaka.value)
          "
          pTooltip="Ukloni oznaku iz računa"
          tooltipPosition="bottom"
        ></span>
      </ng-container>
    </div>
  </ng-container>
</div>
