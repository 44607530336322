<kodit-card-container [showDivider]="false" [showHeader]="false">
  <div
    class="p-d-flex p-flex-column p-flex-md-row p-ai-center card-container"
    card-body
  >
    <div class="p-d-flex p-flex-column p-jc-center gap-6 package-name">
      <div class="p-text-uppercase blue-text">{{ paketInfo?.name }} paket</div>
      <div>
        <span class="blue-text">{{
          paketInfo?.price | currencyNumber: 'EUR'
        }}</span>
        <span class="p-ml-2 gray-text">mesečno</span>
      </div>
    </div>
    <div class="users-info p-d-flex p-flex-column p-jc-center p-ai-center">
      <i style="font-size: 18px" class="fas fa-users"></i>
      <span style="font-size: 20px"
        >{{ paketInfo?.currentNumberOfUsers }}/{{ paketInfo?.maxNumOfUsers }}
        korisnika
      </span>
    </div>
    <div class="p-d-flex p-jc-center p-ai-center package-description">
      <div style="max-width: 50rem">
        {{ paketInfo?.description }}
      </div>
      <!-- <div>
                    <button
                      pButton
                      type="button"
                      label="Izaberi drugi paket"
                      class="p-button"
                      (click)="handleBiranjePaketa()"
                    ></button>
                  </div> -->
    </div>
  </div>
</kodit-card-container>
