import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  GetVezaniRacunAutocompleteQuery,
  IVezaniRacunDto,
  PdvEvidencijaTipPovezaneFakture,
  PdvEvidencijaTipPovezanogInternog,
  RacuniClient,
  StatusRacuna,
  TipRacuna,
} from '@kodit/core/data-api';
import { enumToNumberArray } from '@kodit/core/shared';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { PdvEvidencijaPojedinacnaService } from 'libs/core/moduli/pdv-evidencija/src/lib/pojedinacna/pdv-evidencija-pojedinacna.service';
import { Subscription } from 'rxjs';

interface IRequest {
  searchValue: string;
}

@Component({
  selector: 'kodit-racun-autocomplete',
  templateUrl: './racun-autocomplete.component.html',
  styleUrls: ['./racun-autocomplete.component.scss'],
})
export class RacunAutocompleteComponent implements OnInit, OnDestroy {
  /** Subs */
  private _searchSub: Subscription = new Subscription();

  @ViewChild('avansniSearch') avansniSearch: any;

  /** Props */
  formGroup: FormGroupTypeSafe<IRequest>;
  vezaniRacunSuggestions: IVezaniRacunDto[] = [];
  @Input() ctrlPlaceholder: string = 'Broj ili poziv na broj...';

  /** I/O */
  @Input() ctrlDisabled: boolean;
  @Input() formCtrlId: string;
  @Input() jeIzmenaOsnovice: boolean;
  @Input() includeTipoveRacuna: TipRacuna[];
  @Input() includeStatuseRacuna: StatusRacuna[];
  @Input() shouldDisable: boolean = false;
  @Input() isUnosPojedinacneEvidencije: boolean = false;
  @Output() onChange = new EventEmitter<IVezaniRacunDto>();
  @Output() onClear = new EventEmitter<void>();

  constructor(
    private _client: RacuniClient,
    private _fb: FormBuilderTypeSafe,
    private _pdvPojedinacnaService: PdvEvidencijaPojedinacnaService
  ) {
    this.includeTipoveRacuna = enumToNumberArray(TipRacuna);
    this.includeStatuseRacuna = enumToNumberArray(StatusRacuna);
  }

  ngOnInit(): void {
    this.formGroup = this._fb.group<IRequest>({
      searchValue: new FormControl(''),
    });
  }

  /**
   * Na svaki unos u autocomplete, pretrazuje predmete stavki iz baze
   * @param event event poslat iz autocomplete
   */
  handleSearch(event: any) {
    if (this.formGroup.value.searchValue.length < 1) {
      return;
    }

    if (this.isUnosPojedinacneEvidencije) {
      if (this._pdvPojedinacnaService.getIsIsporuka) {
        this.includeTipoveRacuna =
          this._pdvPojedinacnaService.getPdv39FormObject.value
            .tipPovezaneFakture === PdvEvidencijaTipPovezaneFakture.AVANSNA_FAKTURA
            ? [TipRacuna.AVANSNI_RACUN]
            : [TipRacuna.IZLAZNA_FAKTURA];
      }
      else{
        this.includeTipoveRacuna =
          this._pdvPojedinacnaService.getPdv39FormObject.value
            .tipPovezanogInternog ===
          PdvEvidencijaTipPovezanogInternog.INTERNI_RACUN_ZA_AVANS
            ? [TipRacuna.AVANSNI_RACUN]
            : [TipRacuna.IZLAZNA_FAKTURA];
      }
    }

    this._searchSub.unsubscribe();
    this._searchSub = this._client
      .getVezaniRacunAutocomplete(
        new GetVezaniRacunAutocompleteQuery({
          searchValue: event.query,
          jeIzmenaOsnovice: this.jeIzmenaOsnovice,
          includeTipoveRacuna: this.includeTipoveRacuna,
          includeStatuseRacuna: this.includeStatuseRacuna,
        })
      )
      .subscribe((result) => {
        this.vezaniRacunSuggestions = result.data;
      });
  }

  handleSelect(value: IVezaniRacunDto) {
    this.onChange.emit(value);

    this.avansniSearch.inputEL.nativeElement.value = '';
  }

  handleClear() {
    this.formGroup.reset();
    this.onClear.emit();
  }

  ngOnDestroy() {
    this._searchSub.unsubscribe();
  }
}
