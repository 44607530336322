import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {IKonfiguracijaMojeFirmeDto} from "@kodit/core/data-api";

@Injectable({ providedIn: 'root' })
export class InitService {
  constructor() {
  }

  /** Konfiguracija moje firme */
  konfiguracijaMojeFirme$ = new BehaviorSubject<IKonfiguracijaMojeFirmeDto>(null);

  get getKonfiguracijuMojeFirme() {
    return this.konfiguracijaMojeFirme$.asObservable();
  }

  set setKonfiguracijuMojeFirme(data: IKonfiguracijaMojeFirmeDto) {
    this.konfiguracijaMojeFirme$.next(data);
  }

  getKonfiguracijaMojeFirmeValue() : IKonfiguracijaMojeFirmeDto{
    return this.konfiguracijaMojeFirme$.value;
  }
}
