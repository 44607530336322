import { Component, OnInit } from '@angular/core';
import { PackageType, VrstaStranke } from '@kodit/core/data-api';
import { AuthService } from '@kodit/core/services';
import {Router} from "@angular/router";

@Component({
  selector: 'kodit-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  /** Props */
  vrstaStrankeDobavljac = VrstaStranke.DOBAVLJAC;
  vrstaStrankeKupac = VrstaStranke.KUPAC;
  shouldDisplayPromet: boolean = true;
  shouldDisplayTopNStranki: boolean = true;

  constructor(private _authService: AuthService, private _router : Router) {}

  ngOnInit() {
    if(!this._authService.hasPackage(PackageType[PackageType.FAKTURISANJE])){
      this._shouldRouteToSelectedPackagePage();
    }

    this.shouldDisplayPromet = this._authService.hasPackage(
      PackageType[PackageType.FAKTURISANJE]
    );
    this.shouldDisplayTopNStranki = this._authService.hasPackage(
      PackageType[PackageType.FAKTURISANJE]
    );
  }

  private _shouldRouteToSelectedPackagePage(){
    if(this._authService.hasPackage(PackageType[PackageType.ARHIVSKA_KNJIGA_I_DELOVODNIK])){
      this._router.navigate(['/kompanija', 'arhivska-knjiga']).then();
    }

    if(this._authService.hasPackage(PackageType[PackageType.MALOPRODAJA])){
      this._router.navigate(['/maloprodaja', 'fiskalni']).then()
    }

    return;
  }
}
