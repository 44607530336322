import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RobaDto, RobeClient, TipArtikla } from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { RobaFormComponent } from '../roba-form/roba-form.component';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'kodit-roba-info',
  templateUrl: './roba-info.component.html',
  styleUrls: ['./roba-info.component.scss'],
})
export class RobaInfoComponent implements OnInit, OnDestroy {
  /** Subs */
  private _dialogCloseSub: Subscription = new Subscription();
  private _loadSub: Subscription = new Subscription();
  private _errorSub: Subscription = new Subscription();

  /** Props */
  robaDto: RobaDto;
  robaId: number;
  showSastavnica: boolean;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.MEDIUM
  );

  constructor(
    private _route: ActivatedRoute,
    private _dialogService: DynamicDialogService,
    private _client: RobeClient,
    private _alertService: AlertService,
    private _breadcrumbService: BreadcrumbService
  ) {}
  ngOnInit(): void {
    // get router data
    this._route.params.subscribe((params) => {
      if (params['id']) {
        this.robaId = Number.parseInt(params['id']);
      }
    });
    this._load();
  }

  onEdit() {
    this.dialogConfig.data = { id: this.robaDto.predmetStavkeDto.id };
    this.dialogConfig.header = 'Izmena podataka artikla';
    const ref = this._dialogService.open(RobaFormComponent, this.dialogConfig);
    this._dialogCloseSub = ref.onClose.subscribe(() => {
      this._load();
    });
  }

  onSubmit() {}

  private _load() {
    this._loadSub = this._client.getById(this.robaId).subscribe(
      (res) => {
        this.robaDto = res.data;
        this.showSastavnica = this.robaDto.predmetStavkeDto.tipArtikla !== TipArtikla.ROBA;
        this.setBr();
      },
      (error) => {
        this._errorSub = error.subscribe((x: string) => {
          this._alertService.addFailedMsg(x);
        });
      }
    );
  }

  private setBr() {
    this._breadcrumbService.set(
      '@lagerId',
      this.robaDto.predmetStavkeDto.naziv
    );
  }

  ngOnDestroy() {
    this._dialogCloseSub.unsubscribe();
    this._loadSub.unsubscribe();
    this._errorSub.unsubscribe();
  }
}
