<kodit-dropdown
  [options]="items"
  [formCtrl]="pdvStopaCtrl"
  (optionChanged)="handleOnItemChange($event)"
>
  <ng-template #selectedItem let-obj>
    {{ obj.value | stopaPdvText }}
  </ng-template>

  <ng-template #Item let-obj>
    {{ obj.label }}
  </ng-template>
</kodit-dropdown>
