import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  BankaDto,
  BankaIzvoda,
  FileParameter,
  FileType,
  IIzvodFajlIzvorDropdownDto,
  IIzvodTableDto,
  IzvodiClient,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService } from '@kodit/core/services';
import {
  DynamicDialogRef,
  FileUploadService,
  TekuciRacunDropdownService,
} from '@kodit/core/shared-ui';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'kodit-izvod-fileupload',
  templateUrl: './izvod-fileupload.component.html',
  styleUrls: ['./izvod-fileupload.component.scss'],
})
export class IzvodFileuploadComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  options: SelectItem[];
  value: VrstaAikIzvodaOption = VrstaAikIzvodaOption.STARI;

  /** Props */
  uploadCompleted: boolean;
  showCsvUpload: boolean;
  jeAsseco: boolean;
  jeAik: boolean;
  miniForm: FormGroup;
  bankaId: number;

  readonly MAX_FILE_SIZE: number = 10000000;

  /**
   *
   */
  @Output() uploadedIzvodi = new EventEmitter<IIzvodTableDto[]>();

  constructor(
    private _fb: FormBuilder,
    private _client: IzvodiClient,
    private _alertService: AlertService,
    private _formHelper: FormHelper,
    private _uploadService: FileUploadService,
    private _tekuciRacunService: TekuciRacunDropdownService,
    @Optional() private _dialogRef: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.miniForm = this._fb.group({
      banka: new FormControl(),
      brojIzvoda: new FormControl(),
      tekuciRacunId: new FormControl(),
    });

    this.options = [
      { label: 'Stari', value: VrstaAikIzvodaOption.STARI },
      { label: 'Novi', value: VrstaAikIzvodaOption.NOVI }
    ];

    this._setFormValidations();
    this._uploadService.setMaxAttachmentSize = this.MAX_FILE_SIZE;
  }

  handleIzvorFajlaChanged(event: IIzvodFajlIzvorDropdownDto) {
    this.showCsvUpload = event.fileType === FileType.CSV;
    this._tekuciRacunService.setTekuciRacuniZaBanku =
      event.fileType === FileType.CSV ? event.banka : BankaIzvoda.EMPTY;
    this.jeAsseco = event.banka == BankaIzvoda.ASSECO_DEVIZNI;
    this.jeAik = event.banka === BankaIzvoda.AIK_BANKA;

    this._setFormValidations();
  }

  onUpload(files: File[]) {
    if (this.miniForm.invalid) {
      this._formHelper.invalidateForm(this.miniForm);
      this._uploadService.setShouldReset = true;
      return;
    }

    this.uploadCompleted = false;
    const filesRequest: FileParameter[] = [];
    files.forEach((file) => {
      filesRequest.push({
        data: file,
        fileName: file.name,
      });
    });

    if (this.showCsvUpload) {
      this._subs.add(
        this._client
          .importCsvIzvode(
            filesRequest,
            this.miniForm.value.banka,
            this.miniForm.value.brojIzvoda,
            this.miniForm.value.tekuciRacunId
          )
          .pipe(
            finalize(() => {
              this.uploadCompleted = true;
            })
          )
          .subscribe((res) => {
            if (this._dialogRef) {
              this._dialogRef.close(res.data.izvodTableDtoList);
            } else {
              this.uploadedIzvodi.emit(res.data.izvodTableDtoList);
            }
            this._alertService.addSuccessMsg(res.messages[0]);
          })
      );
    } else {
      this._subs.add(
        this._client
          .importXmlIzvode(
            filesRequest,
            this.miniForm.value.banka,
            null,
            null,
            this.bankaId,
            this.value === VrstaAikIzvodaOption.NOVI ? false : true
          )
          .pipe(
            finalize(() => {
              this.uploadCompleted = true;
            })
          )
          .subscribe((res) => {
            if (this._dialogRef) {
              this._dialogRef.close(res.data.izvodTableDtoList);
            } else {
              this.uploadedIzvodi.emit(res.data.izvodTableDtoList);
            }
            this._alertService.addSuccessMsg(res.messages[0]);
          })
      );
    }
  }

  onBankaSelected(banka: BankaDto) {
    this.bankaId = banka.id;
  }

  private _setFormValidations() {
    if (this.showCsvUpload) {
      this.miniForm.controls.brojIzvoda.setValidators([Validators.required]);
      this.miniForm.controls.tekuciRacunId.setValidators([Validators.required]);
    } else {
      this.miniForm.controls.brojIzvoda.setValidators([
        Validators.nullValidator,
      ]);
      this.miniForm.controls.tekuciRacunId.setValidators([
        Validators.nullValidator,
      ]);
    }
    this.miniForm.controls.brojIzvoda.updateValueAndValidity();
    this.miniForm.controls.tekuciRacunId.updateValueAndValidity();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}

export enum VrstaAikIzvodaOption  {
  NOVI = 0,
  STARI = 1,
}
