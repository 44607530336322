<div class="p-field">
  <label kodit-form-label
    >Jedinstveni matični broj građanina - JMBG{{
      formCtrl.errors?.required ? ' *' : ''
    }}</label
  >
  <kodit-jmbg-input-mask
    [formCtrl]="formCtrl"
    ctrlPlaceholder="Jmbg"
  ></kodit-jmbg-input-mask>
</div>
