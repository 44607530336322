import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-card-container',
  templateUrl: './card-container.component.html',
  styleUrls: ['./card-container.component.scss'],
})
export class CardContainerComponent implements OnInit {
  @Input() isTitleWithIcon: boolean;
  @Input() isCustomTitle: boolean;
  @Input() titleIcon: string;
  @Input() cardClass: string;
  @Input() cardMinHeight: string = 'auto';
  @Input() showDivider: boolean = true;
  @Input() showHeader: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
