import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IPravnoLiceFilterDto,
  PravnoLiceFilterDto,
  TipStranke,
} from '@kodit/core/data-api';
import { PravnoLiceFormService } from '@kodit/core/form-definitions';
import { AdvancedFilter } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { PravnoLiceFilterService } from '../../fizicko-lice/fizicko-lice-filter/pravno-lice-filter.service';
import { Store } from '@ngrx/store';
import { setFilter } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';

@Component({
  selector: 'kodit-pravno-lice-filter',
  templateUrl: './pravno-lice-filter.component.html',
  styleUrls: ['./pravno-lice-filter.component.scss'],
})
export class PravnoLiceFilterComponent
  implements OnInit, OnDestroy, AdvancedFilter<IPravnoLiceFilterDto> {
  /** Props */
  filterData: IPravnoLiceFilterDto;
  form: FormGroupTypeSafe<IPravnoLiceFilterDto>;
  tipStrankePravnoLice = TipStranke.PRAVNO_LICE;

  constructor(
    private _formService: PravnoLiceFormService,
    private _pravnoLiceFilterService: PravnoLiceFilterService,
    private _storage: Store
  ) {}

  ngOnInit(): void {
    this.form = this._formService.GetPravnoLiceTableRequest(this.filterData);
  }

  onFilterSubmit() {
    this._storage.dispatch(
      setFilter({
        key: 'PRAVNA_LICA',
        filter: JSON.stringify(this.form.value),
      })
    );
  }

  onFilterHide(shouldReset: boolean) {
    this._pravnoLiceFilterService.setAdvanceFilterSubmitted = {
      filterData: shouldReset ? new PravnoLiceFilterDto() : this.form.value,
      shouldApplyFilter: shouldReset,
    };
  }

  ngOnDestroy(): void {}
}
