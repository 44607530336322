import { FormControl } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IPrikazRadnogNalogaDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrikazRadnogNalogaFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetPrikazRadnogNalogaFormGroup(
    model?: IPrikazRadnogNalogaDto
  ): FormGroupTypeSafe<IPrikazRadnogNalogaDto> {
    return this._fb.group<IPrikazRadnogNalogaDto>({
      opis: new FormControl(model?.opis),
      prikazRadnogNaloga: new FormControl(model?.prikazRadnogNaloga),
      prikazRadnogNalogaStr: new FormControl(model?.prikazRadnogNalogaStr),
    });
  }
}
