<div
  class="p-d-flex p-jc-between p-ai-center pocetno-stanje p-p-3 p-mt-3 p-mb-5"
>
  <div class="p-text-left header">Početno stanje</div>
  <span>{{ pocetnoStanje | currencyNumber }}</span>
  <div>
    <button
      type="button"
      pButton
      pRipple
      class="p-button-text"
      [label]="isEvidentirano ? 'Poništi' : 'Evidentiraj'"
      icon="fa-light fa-ballot-check"
      (click)="
        isEvidentirano ? onPonistavanjeEvidencijeUplate() : onEvidencijaUplate()
      "
      [disabled]="pocetnoStanje === 0"
    ></button>
  </div>
</div>
