<input
  [id]="formCtrlId"
  pInputText
  [formControl]="formCtrl"
  [pKeyFilter]="telefonFilter"
/>
<kodit-error-info-required [formCtrl]="formCtrl"> </kodit-error-info-required>
<kodit-error-info-max-length
  [formCtrl]="formCtrl"
></kodit-error-info-max-length>
<kodit-error-info-min-length
  [formCtrl]="formCtrl"
></kodit-error-info-min-length>
<kodit-error-info-min [formCtrl]="formCtrl"></kodit-error-info-min>
