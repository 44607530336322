<div class="p-grid p-mb-5">
  <ng-template ngFor let-grupa [ngForOf]="korisnickeGrupe" let-i="index">
    <kodit-user-role-card
      class="p-col-12 p-md-6 p-lg-4"
      [roleName]="grupa.naziv"
      [roleId]="grupa.id"
      [roleDescription]="grupa.opis"
    ></kodit-user-role-card>
  </ng-template>
  <kodit-user-role-card
    [isCreateNew]="true"
    class="p-col-12 p-md-6 p-lg-4"
  ></kodit-user-role-card>
</div>
