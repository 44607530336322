import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { ICreateDatotekaCommand, IDatotekaTreeDto, VrstaDatoteke } from 'libs/core/data-api/src/lib/eKompanija-api';
import { TreeNode } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatotekaService {
  form: DatotekaForm | null | undefined;

  changeOccured$ = new BehaviorSubject<boolean>(false);

  get getChangeOccured() {
    return this.changeOccured$.asObservable();
  }

  set setChangeOccured(value: boolean) {
    this.changeOccured$.next(value);
  }

  selectedDatoteka$ = new BehaviorSubject<
    TreeNode<IDatotekaTreeDto> | undefined
  >(undefined);

  get getSelectedDatoteka() {
    return this.selectedDatoteka$.asObservable();
  }

  set setSelectedDatoteka(value: TreeNode<IDatotekaTreeDto> | undefined) {
    this.selectedDatoteka$.next(value);
  }

  constructor(private _fb: FormBuilderTypeSafe) {}

  getForm(vrsta?: VrstaDatoteke, model?: ICreateDatotekaCommand) {
    if (!this.form) {
      this.form = this._getDatotekaFormGroup(vrsta!, model);
    }
    return this.form;
  }

  resetForm() {
    this.form = null;
  }

  private _getDatotekaFormGroup(
    vrsta: VrstaDatoteke,
    model?: ICreateDatotekaCommand
  ): FormGroupTypeSafe<ICreateDatotekaCommand> {
    return this._fb.group<ICreateDatotekaCommand>({
      naziv: new FormControl(model?.naziv, {
        validators: [Validators.required],
      }),
      parentId: new FormControl(model?.parentId),
      vrstaDatoteke: new FormControl(vrsta, {
        validators: [Validators.required],
      }),
    });
  }
}

export declare type DatotekaForm = FormGroupTypeSafe<ICreateDatotekaCommand>;