<div [formGroup]="formGroup">
  <p-autoComplete
    [inputId]="formCtrlId"
    formControlName="searchValue"
    field="label"
    [disabled]="disabled"
    emptyMessage="Račun nije pronađen"
    [suggestions]="racunSuggestions"
    [placeholder]="ctrlPlaceholder"
    (completeMethod)="handleSearch($event)"
    (onSelect)="handleSelect($event)"
    (onClear)="handleClear()"
  >
    <ng-template let-item pTemplate="item">
      <div class="p-d-flex p-jc-between" style="width: 200px">
        <div>{{ item.broj }}</div>
        <div class="p-text-bold">{{ item.iznos | currencyNumber }}</div>
      </div>
    </ng-template>
  </p-autoComplete>
  <kodit-error-info-required
    [formCtrl]="formGroup.controls.searchValue"
  ></kodit-error-info-required>
</div>
