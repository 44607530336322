import { FormArray, FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IStrankaDto, IStrankaMultiSelectItem, TipStranke } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { TekuciRacunFormService } from '../banka/tekuci-racun-form.service';
import { AdresaFormService } from '../common/adresa-form.service';

@Injectable({
  providedIn: 'root',
})
export class BaseStrankaFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _bankovniNalogForm: TekuciRacunFormService,
    private _adresaForm: AdresaFormService
  ) {}

  /**
   * Konvertuje bazni model za stranku i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Bazni model stranke od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetBaseStrankaFormGroup(
    tipStranke: TipStranke,
    model?: IStrankaDto
  ): FormGroupTypeSafe<IStrankaDto> {
    return this._fb.group<IStrankaDto>({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv, {
        validators: [
          tipStranke === TipStranke.FIZIKO_LICE
            ? Validators.nullValidator
            : Validators.required,
          Validators.maxLength(150),
        ],
      }),
      kupac: new FormControl(model?.kupac),
      dobavljac: new FormControl(model?.dobavljac),
      napomena: new FormControl(model?.napomena, {
        validators: [Validators.maxLength(150)],
      }),
      tipStranke: new FormControl(tipStranke),
      adresa: this._adresaForm.GetAdresaFormGroup(model?.adresa),
      tekuciRacuniDto: this._bankovniNalogForm.GetTekuciRacunFormArray(
        model?.tekuciRacuniDto,
        model?.id && model.id > 0
      ),
      rokPlacanjaZaIzlazne: new FormControl(model?.rokPlacanjaZaIzlazne ?? 0),
      rokPlacanjaZaUlazne: new FormControl(model?.rokPlacanjaZaUlazne ?? 0),
      createdBy: new FormControl(model?.createdBy),
      createdOn: new FormControl(model?.createdOn ?? new Date()),
      lastModifiedBy: new FormControl(model?.lastModifiedBy),
      lastModifiedOn: new FormControl(model?.lastModifiedOn),
      uSistemuPDVa: new FormControl(model?.uSistemuPDVa ?? false),
      jeDobavljac: new FormControl(model?.jeDobavljac ?? false),
      jeKupac: new FormControl(model?.jeKupac ?? false),
      isActive : new FormControl(model?.isActive ?? true),
      valutaPoslovanjaId: new FormControl(model?.valutaPoslovanjaId),
    });
  }

  public GetBaseStrankaFilterFormGroup(
    model?: IStrankaMultiSelectItem
  ){
    return this._fb.group<IStrankaDto>({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv, {
        validators: [
          Validators.required,
          Validators.maxLength(150),
        ],
      })
    });
  }

  public GetStrankeFormArray(stranke: IStrankaMultiSelectItem[]): FormArray {
    if (!stranke || stranke.length === 0) {
      return this._fb.array([]);
    }
    let result: FormArray = this._fb.array([]);

    stranke.forEach((stranka) => {
      result.push(this.GetBaseStrankaFilterFormGroup(stranka));
    });
    return result;
  }
}
