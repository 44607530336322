<p-autoComplete
  #autoComplete
  [suggestions]="kategorijeSuggestions"
  [(ngModel)]="kategorijaModel"
  (onSelect)="onSelectChange($event)"
  placeholder="Naziv kategorije..."
  (completeMethod)="searchOznakeNaziv($event)"
  [dropdown]="true"
  field="naziv"
  [disabled]="jeUnosDelovodnogDokumenta && selectedKategorija != undefined"
>
</p-autoComplete>
