<div [formGroup]="form" class="p-d-flex p-flex-column p-fluid">
  <div class="p-d-flex p-flex-column p-flex-md-row p-mr-4 p-fluid gap-8">
    <!-- Broj obracuna -->
    <div class="flex-1">
      <kodit-field fieldLabel="Broj obračuna" fieldLabelFor="brojObracuna">
        <kodit-text-input
          class="show"
          [formCtrl]="form.controls.brojObracuna"
          formCtrlId="brojObracuna"
        ></kodit-text-input>
      </kodit-field>
    </div>
    <!-- Originalni broj -->
    <div class="flex-1">
      <kodit-field fieldLabel="Originalni broj" fieldLabelFor="originalniBroj">
        <kodit-text-input
          class="show"
          [formCtrl]="form.controls.originalniBroj"
          formCtrlId="originalniBroj"
        ></kodit-text-input>
      </kodit-field>
    </div>
  </div>
  <div class="p-d-flex p-flex-column p-flex-md-row p-mr-4 gap-8">
    <!-- Datum  od -->
    <div class="flex-1">
      <kodit-field fieldLabel="Datum od" fieldLabelFor="datumOd">
        <kodit-calendar
          [formCtrl]="form.controls.datumOd!"
          formCtrlId="datumOd"
        ></kodit-calendar>
      </kodit-field>
    </div>
    <!-- Datum izdavanja do -->
    <div class="flex-1">
      <kodit-field fieldLabel="Datum do" fieldLabelFor="datumDo">
        <kodit-calendar
          [formCtrl]="form.controls.datumDo!"
          formCtrlId="datumDo"
        ></kodit-calendar>
      </kodit-field>
    </div>
    <!-- Tip dokumenta -->
    <div class="flex-1">
      <kodit-field fieldLabel="Tip dokumenta" fieldLabelFor="tipDokumenta">
        <kodit-tip-dokumenta-evidencije-multiselect
          [tipFormArray]="form.controls.tipoviDokumenta"
          formCtrlId="tipDokumenta"
        ></kodit-tip-dokumenta-evidencije-multiselect>
      </kodit-field>
    </div>
    <!-- Status -->
    <div class="flex-1">
      <kodit-field fieldLabel="Status" fieldLabelFor="status">
        <kodit-status-evidencije-multiselect
          [tipFormArray]="form.controls.statusi"
          formCtrlId="status"
        ></kodit-status-evidencije-multiselect>
      </kodit-field>
    </div>
  </div>
</div>

