import {Component, OnDestroy, OnInit} from '@angular/core';
import {BuyerIdentification, BuyerOptionalField, IPravnoLiceDto,} from '@kodit/core/data-api';
import {DynamicDialogRef} from '@kodit/core/shared-ui';
import {Subscription} from 'rxjs';
import {FiskalniRacunForm, FiskalniRacunService,} from '../../fiskalni-racun.service';
import {FiskalniRacunFormService} from '../../fiskalni-racun-form.service';
import {AlertService} from '@kodit/core/services';

@Component({
  selector: 'kodit-odabir-kupca-fiskalnog-racuna-dialog',
  templateUrl: './odabir-kupca-fiskalnog-racuna-dialog.component.html',
  styleUrls: ['./odabir-kupca-fiskalnog-racuna-dialog.component.scss'],
})
export class OdabirKupcaFiskalnogRacunaDialogComponent implements OnInit, OnDestroy {
  /** Subscription */
  private _subs: Subscription = new Subscription();

  /** Props */
  fiskalniRacunForm!: FiskalniRacunForm;
  showPretragaPravnogLica = false;
  shouldShowBuyer = false;
  shouldShowOptionalField = false;

  constructor(
    private _fiskalniService: FiskalniRacunService,
    private _fiskalniFS: FiskalniRacunFormService,
    private _dialogRef: DynamicDialogRef,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.fiskalniRacunForm = this._fiskalniService.getFormObject;

    this._waitForFiskalniRacunForm().then(()=>{
      this.showPretragaPravnogLica =
        this.fiskalniRacunForm?.controls?.buyerIdentification?.value ===
        BuyerIdentification.PIB_KUPCA;

      this.shouldShowBuyer =
        this.fiskalniRacunForm?.controls?.buyerIdentification?.value !==
        BuyerIdentification.EMPTY;

      this.shouldShowOptionalField =
        this.fiskalniRacunForm?.controls?.buyerOptionalField?.value !==
        BuyerOptionalField.Empty;
    })

    this._subscriptions();
  }

  selectionChanged(value: IPravnoLiceDto) {
    this.fiskalniRacunForm.controls.buyerId?.patchValue(value.pib);
  }

  onClearPravnoLice() {
    this.fiskalniRacunForm.get('buyerId')?.reset();
  }

  private _setValidatorsForBuyerIdentificationType(res: BuyerIdentification) {
    let numberOfCharacters: number;

    switch (res) {
      case BuyerIdentification.BROJ_LICNE:
      case BuyerIdentification.PIB_KUPCA:
        numberOfCharacters = 9;
        break;
      case BuyerIdentification.JMBG:
        numberOfCharacters = 13;
        break;
      case BuyerIdentification.PPIB_I_JBKJS:
        numberOfCharacters = 5;
        break;
      default:
        numberOfCharacters = 0;
    }

    const validator = numberOfCharacters > 0 ? this._fiskalniFS.exactlyNumberOfCharactersValidator(numberOfCharacters) : null;

    this.fiskalniRacunForm.get('buyerId')?.setValidators(validator);
    this.fiskalniRacunForm.get('buyerId')?.updateValueAndValidity();
  }

  private async _waitForFiskalniRacunForm(): Promise<void> {
    return new Promise((resolve) => {
      const checkValue = () => {
        if (
          this.fiskalniRacunForm?.controls.buyerIdentification?.value
        ) {
          resolve();
        } else {
          setTimeout(checkValue, 10); // Proveri ponovo posle 10ms
        }
      };
      checkValue();
    });
  }

  private _subscriptions(){
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        if (this.fiskalniRacunForm.get('buyerId')?.invalid) {
          this.fiskalniRacunForm.controls.buyerId?.markAsDirty();
          this._alertService.addWarnMsg("Neispravan broj karaktera za polje 'ID kupca'");
          return;
        }

        // Reset-ovanje polja ako se klikne odustani (prosledjuje se true ako jeSubmit)
        this._dialogRef.close(true);
      })
    );

    this._subs.add(
      this._dialogRef.onClose.subscribe(() => {
        if (this.fiskalniRacunForm.get('buyerId')?.invalid &&
          this.fiskalniRacunForm.get('buyerId')?.value?.length > 0) {
          this.fiskalniRacunForm.controls.buyerId?.clearValidators();
          this.fiskalniRacunForm.controls.buyerId?.reset();
        }
      })
    )

    this._subs.add(
      this.fiskalniRacunForm.controls.buyerIdentification?.valueChanges.subscribe(
        (res) => {
          this.shouldShowBuyer = res !== BuyerIdentification.EMPTY;
          this.showPretragaPravnogLica = res === BuyerIdentification.PIB_KUPCA;

          if (res !== BuyerIdentification.PIB_KUPCA) {
            this.fiskalniRacunForm.controls.buyerId?.reset();
          }

          this._setValidatorsForBuyerIdentificationType(res ?? BuyerIdentification.EMPTY);
        }
      )
    );

    this._subs.add(
      this.fiskalniRacunForm.controls.buyerOptionalField?.valueChanges.subscribe(
        (res) => {
          this.shouldShowOptionalField = res !== BuyerOptionalField.Empty;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
