import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {Subscription} from "rxjs";
import {RacunService} from "@kodit/core/services";

@Component({
  selector: 'kodit-nosioc-javne-nabavke',
  templateUrl: './nosioc-javne-nabavke.component.html',
  styleUrls: ['./nosioc-javne-nabavke.component.scss']
})
export class NosiocJavneNabavkeComponent implements OnInit,OnDestroy {
  /** Subs */
  private _subs : Subscription = new Subscription();

  /** I/O */
  @Input() formCtrl!: AbstractControl;

  /** Props */
  value : boolean = false;
  nosiocJavneNabavkeValidator :any;

  constructor(private _racunService : RacunService) { }

  ngOnInit(): void {
    this.value = !!this.formCtrl?.value;
    this._createValidatorForNosiocJavneNabavke();

    // OnEdit
    if(this.value){
      this._setAdditionalRacunValidators();
    }
  }

  checkboxValueChange(value){
    if(!value.checked){
      this.formCtrl.reset();
      this._removeAdditionalRacunValidators();
    }
    else{
      this._setAdditionalRacunValidators();
    }
  }

  private _setAdditionalRacunValidators(){
    this._racunService.getRacunFormObject.controls.brojNarudzbenice.addValidators(this.nosiocJavneNabavkeValidator);
    this._racunService.getRacunFormObject.controls.brojNarudzbenice.updateValueAndValidity();
    this._racunService.getRacunFormObject.controls.brojUgovora.addValidators(this.nosiocJavneNabavkeValidator);
    this._racunService.getRacunFormObject.controls.brojUgovora.updateValueAndValidity();
  }

  private _removeAdditionalRacunValidators(){
    this._racunService.getRacunFormObject.controls.brojNarudzbenice.removeValidators(this.nosiocJavneNabavkeValidator);
    this._racunService.getRacunFormObject.controls.brojNarudzbenice.updateValueAndValidity();
    this._racunService.getRacunFormObject.controls.brojUgovora.removeValidators(this.nosiocJavneNabavkeValidator);
    this._racunService.getRacunFormObject.controls.brojUgovora.updateValueAndValidity();
  }

  private _createValidatorForNosiocJavneNabavke(){
    this.nosiocJavneNabavkeValidator = (control: FormControl): { [key: string]: any } | null => {
      const brojNarudzbenice = control.parent.get('brojNarudzbenice').value;
      const brojUgovora = control.parent.get('brojUgovora').value;

      if (!brojNarudzbenice && !brojUgovora) {
        return { bothFieldsEmpty: true };
      }

      return null;
    };
  }

  ngOnDestroy(): void {
   this._subs.unsubscribe();
  }
}
