import { Injectable } from '@angular/core';
import {
  IInformacijaIsporukeDto,
  IIzlaznaFakturaDto,
} from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IzlaznaFakturaService {
  constructor() {

  }

  /**
   * Postavljanje forme racuna
   */
  izlaznaFakturaForm$ = new BehaviorSubject<IzlaznaFakturaForm>(null);

  set setForm(value: IzlaznaFakturaForm) {
    this.izlaznaFakturaForm$.next(value);
  }

  get getFormObject(): IzlaznaFakturaForm {
    return this.izlaznaFakturaForm$.getValue();
  }

  get getInformacijeIsporukeFormGroup(): InformacijaIsporukeForm {
    if (!this.getFormObject) {
      return null;
    }
    return this.getFormObject.getSafe<IInformacijaIsporukeDto>(
      (x) => x.informacijaIsporuke
    ) as InformacijaIsporukeForm;
  }

  resetService() {
    this.setForm = null;
  }
}

export declare type IzlaznaFakturaForm = FormGroupTypeSafe<IIzlaznaFakturaDto>;
export declare type InformacijaIsporukeForm = FormGroupTypeSafe<IInformacijaIsporukeDto>;
