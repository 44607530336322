import { FilterChipDto, TipPristupa } from '@kodit/core/data-api';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { AdvancedFilterItem } from './filter/advanced-filter/advanced-filter-item';

export interface ITableConfig {
  /** props */
  //  Text in table header.
  tableHeader?: string;
  headerDescription?: string;
  //  Determine whether table is grouped or classic.
  type?: TableType;
  //  Determine whether create button is single button or dropdown button.
  createMode?: CreateMode;
  //  Determine whether selection should be single or multiple.
  selectMode?: SelectMode | null;
  isSelectableRowDisabled?: (rowData: any) => boolean;
  //  Message shown in confirm dialog when one row is being deleted.
  deleteMessage?: string;
  //  Message shown in confirm dialog when multiple rows are being deleted.
  multiDeleteMessage?: string;
  //  Parameter representing row name or other information
  //  that will be displayed in delete message.
  deleteMessageParams?: string;
  // Po kojim parametetrima mozemo da filtriramo tabelu
  tableFilterFields?: string[];
  // lista kolona
  columns: TableColumn[];
  // u slucaju kada header treba da bude neki template
  isCustomHeader?: boolean;
  refreshCallbackFunction?: () => void;
  onChipRemove?: (availableChips: FilterChipDto) => void;
  // akcije
  headerActions?: TableAction[];
  rowActions?: TableAction[];
  // napredni filter
  advancedFilter?: AdvancedFilterItem;
  // ? proveriti da li nam ovi ispod i dalje trebaju
  showSaveAsTamplate?: boolean;
  // templateItems?: SelectItem[];
  //multiCreateAction?: Promise<MultiAction>;
  moreActionsItemIndex?: number;
  rowsPerPage?: number;
  updateActions$?: BehaviorSubject<number>;
  isLazy?: boolean;
  lazyCallbackFunction?: (event: LazyLoadEvent) => void;
}

// export interface MultiAction {
//   items?: MenuItem[];
//   tooltip: string;
//   label?: string;
//   isAvailable: boolean;
// }

export declare interface OnTableInit {
  /**
   * U ovoj metodi trebamo da inicijalizujemo tableConfig
   */

  tableConfig: TableConfig;

  setTableConfig(): Promise<void>;

  /**
   * U ovom funkciji trebamo da inicijalizujemo tableItems
   */
  //setTableItems(): void;
  /**
   * Vraca create akciju
   */
  //getCreateAction?(): TableAction;
  /**
   * Vraca multi create akcije
   */
  //getMultitCreateActions?(): Promise<MultiAction>;
  /**
   * Vraca mulit delete akciju
   */
  //getMultiDeleteAction?(): Promise<MultiAction>;
  /**
   * Vraca nam listu signle akcija vezanih za 1 row iz tabele
   * @param rowIndex Redni broj row-a
   */
  //getRowActions?(rowIndex: number, itemId: number): TableAction[];
  /**
   * Vraca nam listu multi akcija vezanih za 1 row iz tabele
   * @param rowIndex Redni broj row-a
   */

  //getRowMultiActions?(rowIndex: number): Promise<MenuItem[]>;
  /**
   * Radi ponovni fetch ka API-ju i osvežava tabelu
   */
  _onTableRefresh?(data: any): void;
}

// export declare interface ActionConfigResponse {
//   isAvailable: boolean;
//   tooltip: string;
//   menuItems?: MenuItem[];
// }

// interface TableActionData {
//   type: ActionType;
//   label?: string;
//   icon?: string;
//   actionClass?: ActionClass;
//   config?: Promise<ActionConfigResponse> | ActionConfigResponse;
//   callback?: (request?: any) => void;
// }

// export class TableAction implements TableActionData {
//   public type: ActionType;
//   public label?: string;
//   public icon?: string;
//   public actionClass?: ActionClass;
//   public config?: Promise<ActionConfigResponse> | ActionConfigResponse;
//   public callback?: (request?: any) => void;

//   constructor(data: TableActionData) {
//     if (!data) {
//       throw new Error('Data objekat je null!');
//     }
//     this.type = data.type;
//     this.label = data.label;
//     this.icon = data.icon;
//     this.actionClass = data.actionClass ?? ActionClass.PRIMARY;
//     this.config = data.config;
//     this.callback = data.callback;
//   }
// }

export enum ActionType {
  CREATE = 0,
  EDIT = 1,
  DELETE = 2,
  CUSTOM = 3,
  NO_ACTIONS = 4,
  MULTIDELETE = 5,
  DROPDOWN = 6,
  DROPDOWN_ITEM = 7,
  MULTI_CUSTOM = 8,
  ARCHIVE = 9,
}

export enum ActionMode {
  SINGLE = 1,
  MULTI = 2,
}

export enum TableType {
  CLASSIC = 0,
  GROUPED = 1,
}

export enum CreateMode {
  NONE = 0,
  SINGLE = 1,
  MULTI = 2,
}

export enum SelectMode {
  SINGLE = 'single',
  MULTI = 'multiple',
}

export enum ActionClass {
  PRIMARY = 'p-button-primary',
  SECONDARY = 'p-button-secondary',
  TEXT = 'p-button-text',
  EDIT = 'p-button-success',
  DELETE = 'p-button-danger',
  OUTLINED = 'p-button-outlined',
  LINK = 'p-button-link',
}

export interface TableColumn {
  field: string;
  subField?: string;
  emptyCellField?: string;
  header?: string;
  type:
    | 'text'
    | 'number'
    | 'badge'
    | 'currency'
    | 'list'
    | 'link'
    | 'initials'
    | 'icon'
    | 'bool';
  styleClass?: string;
  styleClassField?: string;
  tooltipField?: string;
  helperText?: string;
  isVisible?: boolean;
  shouldDisplayByCondition?: (fieldValue: any) => boolean;
  useColorsForCurrency?: boolean;
  dropdownItems?: SelectItem[];
  dropdownCallback?: (rowIndex: number, value: any) => void;
  linkCallbackFunction?: (rowIndex: number, subIndex?: number) => any;
  columns?: TableColumn[];
  currencyAlphaCharField?: string;
}

// /** @deprecated */
// export interface TableRow {
//   id: number | string;
//   data: any;
//   actions: TableAction[];
//   multiActions?: Promise<MenuItem[]>;
//   styleClass?: string;
// }

/** NOVO */
export class TableConfig implements ITableConfig {
  tableHeader: string;
  headerDescription?: string;
  type?: TableType;
  createMode?: CreateMode;
  selectMode?: SelectMode;
  deleteMessage?: string;
  multiDeleteMessage?: string;
  deleteMessageParams?: string;
  tableFilterFields?: string[];
  columns: TableColumn[];
  isCustomHeader?: boolean;
  refreshCallbackFunction?: () => void;
  onChipRemove?: (chips: FilterChipDto) => void;
  isSelectableRowDisabled?: (rowData: any) => boolean;
  headerActions?: TableAction[];
  rowActions?: TableAction[];
  advancedFilter?: AdvancedFilterItem;
  showSaveAsTamplate?: boolean;
  // templateItems?: SelectItem<any>[];
  moreActionsItemIndex?: number;
  rowsPerPage?: number;
  updateActions$?: BehaviorSubject<number>;
  isLazy?: boolean;
  lazyCallbackFunction?: (event: LazyLoadEvent) => void;

  /**
   * Postavljamo default vrednosti
   */
  constructor(data: ITableConfig) {
    this.tableHeader = data.tableHeader;
    this.headerDescription = data.headerDescription;
    this.type = data.type ?? TableType.CLASSIC;
    this.createMode = data.createMode ?? CreateMode.SINGLE;
    this.selectMode = data.selectMode ?? null;
    this.rowsPerPage = data.rowsPerPage ?? 10;
    this.deleteMessage = data.deleteMessage;
    this.multiDeleteMessage = data.multiDeleteMessage;
    this.deleteMessageParams = data.deleteMessageParams;
    this.columns = data.columns;
    this.headerActions = data.headerActions;
    this.rowActions = data.rowActions;
    this.advancedFilter = data.advancedFilter;
    this.onChipRemove = data.onChipRemove;
    this.updateActions$ = data.updateActions$;
    this.isSelectableRowDisabled = data.isSelectableRowDisabled;
    this.isLazy = data.isLazy ?? false;
    this.lazyCallbackFunction = data.lazyCallbackFunction;
  }

  get getUpdateActions() {
    return this.updateActions$?.asObservable();
  }

  set setUpdateActions(data: number) {
    this.updateActions$.next(data);
  }
}

export interface TableAction {
  mode?: ActionMode;
  type: ActionType;
  joyrideStep?: string;
  label?: string;
  icon?: string;
  tipPristupa?: TipPristupa;
  hasAccessTooltip?: string;
  noAccessTooltip?: string;
  mutliActions?: TableAction[];
  actionClass?: string;
  isVisible?: boolean;
  shouldDisplayByCondition?: (rowData: any) => boolean;
  callback?: (request?: any) => void;
  shouldDisableWhenSefInactive?: boolean;
}

export interface IPaginatedResultTableDto {
  data?: any[];
  activeFilters?: FilterChipDto[] | undefined;
  currentPage?: number;
  totalPages?: number;
  totalCount?: number;
  pageSize?: number;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}
