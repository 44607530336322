import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { OsnovOdabiraDokumentaDto, PdvEvidencijeClient } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-osnov-odabira-dokumenta-dropdown',
  templateUrl: './osnov-odabira-dokumenta-dropdown.component.html',
  styleUrls: ['./osnov-odabira-dokumenta-dropdown.component.css'],
})
export class OsnovOdabiraDokumentaDropdownComponent
  implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription;

  /** Props */
  items: SelectItem[];
  osnovOdabiraDokumentaList: OsnovOdabiraDokumentaDto[] = [];

  /** I/O */
  @Input() formCtrl: AbstractControl | undefined;
  @Input() formCtrlId: string;
  @Input() parentCtrl: string;
  @Input() shouldDisable: boolean = false;
  @Output() onItemChange = new EventEmitter<any>();

  constructor(private _client: PdvEvidencijeClient) {
    this._subs = new Subscription();
    this.items = [];
    this.formCtrlId = 'osnov-odabira-dokumenta-dropdown';
    this.parentCtrl = 'body';
    this.formCtrl = undefined!;
  }

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client.getOsnovOdabiraDokumentaDropdownQuery().subscribe((res) => {
        if (!res.succeeded) {
          return;
        }
        this.osnovOdabiraDokumentaList = res.data!.responseList!;

        this.items = this.osnovOdabiraDokumentaList.map((x) => ({
          value: x.osnov,
          label: x.osnovStr,
          title: x.opis,
        }));
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  handleOnItemChange(event: any) {
    this.onItemChange.emit(event);
  }
}
