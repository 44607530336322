import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MojaFirmaPageComponent } from './moja-firma/moja-firma-page/moja-firma-page.component';
import { MojaFirmaResolver } from './moja-firma/resolvers/moja-firma-resolver.service';
import { KorisnikTableResolver } from './korisnik/resolvers/korisnik-table-resolver';
import { KorisnikPageComponent } from './korisnik/korisnik-page/korisnik-page.component';
import { KorisnikFormComponent } from './korisnik/korisnik-form/korisnik-form.component';
import { KorisnikResolver } from './korisnik/resolvers/korisnik-resolver';
import { KorisnikInfoComponent } from './korisnik/korisnik-info/korisnik-info.component';
import { KorisnikRacunTableResolver } from './korisnik/resolvers/korisnik-racun-table-resolver';
import { PodesavanjaPageComponent } from './podesavanja-page/podesavanja-page.component';
import { MojProfilPageComponent } from './moj-profil/moj-profil-page/moj-profil-page.component';
import { PodesavanjeRacunaPageComponent } from './racun/podesavanje-racuna-page/podesavanje-racuna-page.component';
import { ListePageComponent } from './liste/liste-page/liste-page.component';
import { PocetnoStanjePageComponent } from './pocetno-stanje/pocetno-stanje-page/pocetno-stanje-page.component';
import { OznakaPageComponent } from './oznaka/oznaka-page/oznaka-page.component';
import { MemorandumFormComponent } from './racun/memorandum-form/memorandum-form.component';
import { PretplataPageComponent } from './pretplata/pretplata-page/pretplata-page.component';
import { FiskalnaKasaPageComponent } from './fiskalna-kasa/fiskalna-kasa-page/fiskalna-kasa-page.component';
import { ArhivskaKnjigaPageComponent } from './arhivska-knjiga/arhivska-knjiga-page/arhivska-knjiga-page.component';
import {ApiMenadzmentPageComponent} from "./api-menadzment/api-menadzment-page/api-menadzment-page.component";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: PodesavanjaPageComponent,
      },
      {
        path: 'kompanija',
        pathMatch: 'full',
        component: MojaFirmaPageComponent,
        resolve: {
          mojaFirma: MojaFirmaResolver,
        },
        data: {
          breadcrumb: 'Kompanija',
        },
      },
      {
        path: 'upravljanje-pretplatom',
        pathMatch: 'full',
        component: PretplataPageComponent,
        data: {
          breadcrumb: 'Upravljanje pretplatom',
        },
      },
      //
      // Racun
      //
      {
        path: 'racun',
        //pathMatch: 'full',
        //component: PodesavanjeRacunaPageComponent,
        data: {
          breadcrumb: 'Račun',
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: PodesavanjeRacunaPageComponent,
          },
          {
            path: 'unos-memoranduma',
            pathMatch: 'full',
            component: MemorandumFormComponent,
            data: {
              breadcrumb: 'Unos memoranduma',
            },
          },
          {
            path: 'izmena-memoranduma/:id',
            pathMatch: 'full',
            component: MemorandumFormComponent,
            data: {
              breadcrumb: {
                alias: 'IzmenaMemoranduma',
              },
            },
          },
        ],
      },
      //
      // Korisnicki nalozi
      //
      {
        path: 'korisnicki-nalozi',
        resolve: {
          korisnici: KorisnikTableResolver,
        },
        data: {
          breadcrumb: 'Korisnicki nalozi',
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: KorisnikPageComponent,
          },
          {
            path: 'unos',
            pathMatch: 'full',
            component: KorisnikFormComponent,
          },
          {
            path: 'izmena/:email',
            pathMatch: 'full',
            component: KorisnikFormComponent,
            resolve: {
              korisnik: KorisnikResolver,
            },
          },
          {
            path: 'kartica/:email',
            pathMatch: 'full',
            component: KorisnikInfoComponent,
            resolve: {
              korisnik: KorisnikResolver,
              racuni: KorisnikRacunTableResolver,
            },
            data: {
              breadcrumb: {
                alias: 'KarticaEmail',
              },
            },
          },
        ],
      },
      //
      // Moj profil
      //
      {
        path: 'moj-profil',
        pathMatch: 'full',
        component: MojProfilPageComponent,
        data: {
          breadcrumb: 'Moj profil',
        },
      },

      //
      // Liste
      //
      {
        path: 'liste',
        pathMatch: 'full',
        component: ListePageComponent,
        data: {
          breadcrumb: 'Registri',
        },
      },

      //
      // Oznake
      //
      {
        path: 'oznake',
        pathMatch: 'full',
        component: OznakaPageComponent,
        data: {
          breadcrumb: 'Oznake',
        },
      },

      //
      // Pocetno stanje
      //
      {
        path: 'pocetna-stanja',
        pathMatch: 'full',
        component: PocetnoStanjePageComponent,
        data: {
          breadcrumb: 'Početna stanja',
        },
      },
      //
      // Arhivska knjiga
      //
      {
        path: 'arhivska-knjiga',
        pathMatch: 'full',
        component: ArhivskaKnjigaPageComponent,
        data: {
          breadcrumb: 'Arhivska knjiga',
        },
      },
      //
      // Fiskalni račun
      //
      {
        path: 'fiskalna-kasa',
        pathMatch: 'full',
        component: FiskalnaKasaPageComponent,
        data: {
          breadcrumb: 'Fiskalna kasa',
        },
      },
      //
      // API menadzment
      //
      {
        path: 'api-menadzment',
        pathMatch: 'full',
        component: ApiMenadzmentPageComponent,
        data: {
          breadcrumb: 'API menadžment',
        },
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class PodesavanjeRoutingModule {}
