<div class="p-mt-5">
  <div class="component-title">Sledeći koraci:</div>

  <div class="koraci-container p-mt-3 p-p-4">
    <!-- Preuzmi -->
    <div class="koraci-card p-jc-between">
      <div class="p-d-flex">
        <div class="redni-broj">01</div>
        <div>
          <div class="naslov">Preuzmi</div>
          <p class="opis">Preuzmite šablon za kategorije.</p>
        </div>
      </div>

      <button
        pButton
        pRipple
        type="button"
        label="Preuzmi datoteku"
        class="p-button-text icon"
        icon="fa-regular fa-circle-down"
        iconPos="left"
        (click)="preuzmiSablon()"
      ></button>
    </div>

    <hr />

    <!-- Popuni -->
    <div class="koraci-card">
      <div class="redni-broj">02</div>
      <div>
        <div class="naslov">Popuni</div>
        <p class="opis">Popunite šablon.</p>
      </div>
    </div>

    <hr />

    <!-- Otpremi -->
    <div class="koraci-card-import p-jc-between">
      <div class="p-d-flex">
        <div class="redni-broj">03</div>
        <div>
          <div class="naslov">Otpremi</div>
          <p class="opis">Otpemite popunjeni šablon.</p>
        </div>
      </div>

      <button
        pButton
        pRipple
        type="button"
        label="Otpremi datoteku"
        class="p-button-text icon"
        icon="fa-regular fa-upload"
        iconPos="left"
        (click)="showImportSection = true"
      ></button>
    </div>

    <div class="import-section" *ngIf="showImportSection">
      <kodit-arhivska-file-upload
        [uploadCompleted]="uploadComleted"
      ></kodit-arhivska-file-upload>
    </div>
  </div>
</div>
