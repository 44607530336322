import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AlertService,
  LocationService,
  RacunService,
  SharedService,
} from '@kodit/core/services';
import {
  CreateOtpremnicaCommand,
  IOtpremnicaDto, IVoziloDto,
  OtpremniceClient,
  StrankaRacunaDto,
  TipPredmeta,
  TipRacuna,
  UpdateOtpremnicaCommand,
  VrstaOtpremnice,
} from '@kodit/core/data-api';
import { ConfirmationService } from 'primeng/api';
import {
  FormHelper,
  OtpremnicaFormService, VoziloFormService,
} from 'libs/core/form-definitions/src';
import { ActivatedRoute } from '@angular/router';
import { RacunFormConfig } from '../../subforms/racun-form-container/racun-form.config';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { FormArray } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { OtpremnicaService } from '../otpremnica.service';

@Component({
  selector: 'kodit-otpremnica-form',
  templateUrl: './otpremnica-form.component.html',
  styleUrls: ['./otpremnica-form.component.scss'],
})
export class OtpremnicaFormComponent implements OnInit, OnDestroy {
  /** Props */
  racunForm: FormGroupTypeSafe<IOtpremnicaDto>;
  racunFormConfig: RacunFormConfig;
  useOnlyRoba: TipPredmeta[] = [];
  private _subs: Subscription = new Subscription();

  constructor(
    private _client: OtpremniceClient,
    private _alertService: AlertService,
    private _confirmationService: ConfirmationService,
    private _locationService: LocationService,
    private _racunService: RacunService,
    private _formService: OtpremnicaFormService,
    private _route: ActivatedRoute,
    private _formHelper: FormHelper,
    private _breadcrumbService: BreadcrumbService,
    private _service: OtpremnicaService,
    private _sharedService: SharedService,
    private _voziloService : VoziloFormService
  ) {}

  get jeEditMode(): boolean {
    return this._route.snapshot.data.racun?.racunDto?.id;
  }

  ngOnInit(): void {
    // get route data
    this.racunForm = this._formService.GetOtpremnicaFormGroup(
      this._route.snapshot.data.formConfiguration,
      this._route.snapshot.data.racun
    );

    this._service.setForm = this.racunForm;

    // configure labels
    this._racunService.setBrojRacunaLabel = 'Broj otpremnice';
    this.racunFormConfig = {
      tip: TipRacuna.OTPREMNICA,
      racunForm: this.racunForm.controls.racunDto,
      brojRacunaTooltip: 'Dodeljen broj otpremnice',
      originalanBrojRacunaTooltip:
        'Unesite broj otpremnice koji ste dobili od izdavaoca',
      saveCallback: () => this.onSave(),
      cancelCallback: () => this.onCancel(),
    };
    //
    this.useOnlyRoba = [
      TipPredmeta.PREDMET_IZMENE_OSNOVICE,
      TipPredmeta.PREDMET_STAVKE,
      TipPredmeta.USLUGA,
      TipPredmeta.VOZILO,
      TipPredmeta.DATI_AVANS,
      TipPredmeta.PRIMLJENI_AVANS,
    ];
    this._setBreadcrumb();
  }

  onSave() {
    this._formHelper.removeEmptyStavka(
      this.racunForm.controls.racunDto.get('stavkeDto') as FormArray
    );

    // Tekuci racun ne postoji na Otpremnicama (da bi izbegli 'Ne postoji referenca ka obaveznom polju')
    this.racunForm.controls.racunDto.get('tekuciRacunId').patchValue(null);

    //add informacija isporuke if informacijaIsporukeId == null
    this.racunForm.value.informacijaIsporuke.strankaId = this.racunForm.controls.racunDto.value.strankaRacunaDto.id;

    // Proveravamo sve potrebno za tip Interna otpremnica
    if (this.racunForm.controls.vrsta.value === VrstaOtpremnice.INTERNA) {
      // Brisemo stranku (kreiramo Empty zbog Adrese i Id) sa Otpremnice sobzirom da nam nije potrebna za tip Interna otpremnica
      this.racunForm.controls.racunDto.value.strankaRacunaDto = new StrankaRacunaDto();

      // proveravamo da li je magacin iz koga prebacujemo isti kao i onaj u koji ubacujemo
      if (this._checkInterniMagacinDuplicateEntry()) {
        return;
      }
    } else {
      // ukoliko je standardna otpremnica onda postavljamo interniMagacina na null
      this.racunForm.controls.interniMagacinId.patchValue(null);
    }

    // check is it valid
    if (!this.racunForm.valid) {
      this._formHelper.invalidateForm(this.racunForm);
      return;
    }

    // everything is OK, so let's save it
    if (this.jeEditMode) {
      this._sharedService.displayLoadingScreen(
        true,
        'Ažuriranje otpremnice...'
      );
      // it's edit
      this._subs.add(
        this._client
          .update(
            this.racunForm.value.racunDto.id,
            this.racunForm.value as UpdateOtpremnicaCommand
          )
          .subscribe(() => {
            this._alertService.addSuccessMsg(
              `Otpremnica ${this.racunForm.value.racunDto.broj} je uspešno izmenjena.`
            );
            this._locationService.routeBack();
          })
      );
    } else {
      this._sharedService.displayLoadingScreen(true, 'Kreiranje otpremnice...');
      // it's new
      this._subs.add(
        this._client
          .create(this.racunForm.value as CreateOtpremnicaCommand)
          .subscribe(() => {
            this._alertService.addSuccessMsg(`Otpremnica je uspešno kreirana.`);
            this._locationService.routeBack();
          })
      );
    }
  }

  onCancel() {
    this._confirmationService.confirm({
      message: 'Ukoliko otkažete sav ne snimljen sadržaj će biti izgubljen.',
      acceptLabel: 'Ipak otkaži',
      rejectLabel: 'Ostani na stranici',
      header: 'Potvrdite otkazivanje',
      icon: 'far fa-check-circle',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._locationService.routeBack();
      },
    });
  }

  selectedVozila(selectedItems : any){
    (this.racunForm.controls.vozila as FormArray).clear();
    selectedItems.forEach((res : IVoziloDto) => {
      (this.racunForm.controls.vozila as FormArray).push(
        this._voziloService.GetVoziloDropdownFormGroup(res)
      );
    });
  }

  private _checkInterniMagacinDuplicateEntry(): boolean {
    var postojiDuplikat = false;
    const interniMagacinId = this.racunForm.controls.interniMagacinId.value;

    if (!interniMagacinId) {
      this._service.getInterniMagacinId.invalid;
      this._formHelper.invalidateForm(this.racunForm);
      return true;
    }

    this.racunForm.controls.racunDto.value.stavkeDto.forEach((stavka) => {
      if (stavka.magacinId === interniMagacinId) {
        this._alertService.addWarnMsg(
          'Ne možete prebacivati robu u magacin iz magacina u kom vam se trenutno nalazi roba!'
        );
        postojiDuplikat = true;
      }
    });
    return postojiDuplikat;
  }

  private _setBreadcrumb() {
    this._breadcrumbService.set(
      '@unosOtpremniceZaStranku',
      `Unos otpremnice za '${this.racunForm.value.racunDto.strankaRacunaDto.naziv}'`
    );
    this._breadcrumbService.set(
      '@izmenaOtpremnice',
      `Izmena otpremnice ${this.racunFormConfig.racunForm.value.broj}`
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
    this._service.resetService();
  }
}
