import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { PdvEvidencijeClient } from '@kodit/core/data-api';
import { PdvEvidencijaPojedinacnaService } from '../../pojedinacna/pdv-evidencija-pojedinacna.service';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'kodit-tip-dokumenta-evidencije-dropdown',
  templateUrl: './tip-dokumenta-evidencije-dropdown.html',
  styleUrls: ['./tip-dokumenta-evidencije-dropdown.scss'],
})
export class TipDokumentaEvidencijeDropdownComponent implements OnInit, OnDestroy {

  @Input() formCtrl: FormControl | AbstractControl | undefined;
  @Input() shouldDisable: boolean = false;
  @Output() onItemChange = new EventEmitter<any>();
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];
  fullItems: SelectItem[] = []; 

  constructor(private _client: PdvEvidencijeClient, private _service: PdvEvidencijaPojedinacnaService) {}

  ngOnInit() {
    this._loadItems();
    this._subs.add(
      this._service.getIsIsporukaObservable.subscribe(isIsporuka => {
        if(isIsporuka){
          this.items = this.fullItems.filter((item) =>
            ['Faktura', 'Avansna faktura', 'Dokument o povećanju', 'Dokument o smanjenju'].includes(item.label!)
          );
        }else{
          this.items = this.fullItems.filter((item) =>
            ['Interni račun za promet stranog lica', 'Drugi interni račun'].includes(item.label!)
          );
        }
      })
    );
  }


  private _loadItems() {
    this._subs.add(
      this._client.getTipDokumentaEvidencijeDropdown().subscribe((res) => {
        if (!res.succeeded) {
          return;
        }
        this.fullItems = res.data!.responseList!.map((x) => ({
          value: x.tip,
          label: x.tipStr,
          title: x.opis,
        }));
        if (this._service.getIsIsporuka) {
          this.items = this.fullItems.filter((item) =>
            [
              'Faktura',
              'Avansna faktura',
              'Dokument o povećanju',
              'Dokument o smanjenju',
            ].includes(item.label!)
          );
        } else {
          this.items = this.fullItems.filter((item) =>
            [
              'Interni račun za promet stranog lica',
              'Drugi interni račun',
            ].includes(item.label!)
          );
        }
      })
    );
  }

  handleOnItemChange(event: any) {
    this.onItemChange.emit(event);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
