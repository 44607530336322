import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CenovniciClient,
  CenovnikStavkaDto,
  GetCenovnikStavkeQuery,
  ICenovnikStavkaDto,
} from '@kodit/core/data-api';
import { CenovnikFormService } from '@kodit/core/form-definitions';
import { CenovnikStavkeService } from '@kodit/core/services';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-cenovnik-stavka-autocomplete',
  templateUrl: './cenovnik-stavka-autocomplete.component.html',
  styleUrls: ['./cenovnik-stavka-autocomplete.component.scss'],
})
export class CenovnikStavkaAutocompleteComponent implements OnInit, OnDestroy {
  /** Subs */
  private _searchSub: Subscription = new Subscription();
  private _loadStavkeCenovnikaSub: Subscription = new Subscription();

  /** Props */
  stavkeSuggestions: string[] = [];
  stavkeResults: ICenovnikStavkaDto[] = [];
  existingStavkeCenovnika: ICenovnikStavkaDto[];

  @ViewChild('stavkaAutocompleteInput') stavkaAutocompleteInput: any;

  /** I/O */
  @Input() ctrlPlaceholder: string = 'Pretražite artikle po šifri ili nazivu';
  @Input() parentCtrl: any = 'body';

  @Output() onNazivChange = new EventEmitter<string>();
  @Output() onStavkaCenovnikaChange = new EventEmitter<ICenovnikStavkaDto>();

  constructor(
    private _client: CenovniciClient,
    private _cenovnikStavkeService: CenovnikStavkeService
  ) {}

  ngOnInit(): void {}

  searchRacuni(event: any) {
    this._loadStavkeCenovnikaSub = this._cenovnikStavkeService.getDesnaLista.subscribe(
      (x) => {
        this.existingStavkeCenovnika = x;
      }
    );

    this._loadStavkeCenovnikaSub.unsubscribe();

    this._searchSub = this._client
      .getCenovnikStavke(
        new GetCenovnikStavkeQuery({
          searchValue: event.query,
        })
      )
      .subscribe((result) => {
        this.stavkeResults =
          this.existingStavkeCenovnika.length > 0
            ? result.data.cenovnikStavke.filter((e) => {
                return !this.existingStavkeCenovnika.some(
                  (item) => item.predmetStavkeId === e.predmetStavkeId
                );
              })
            : result.data.cenovnikStavke;

        this.stavkeSuggestions = this.stavkeResults.map((x) =>
          x.sifra
            ? `${x.sifra} | ${x.nazivPredmetaStavke}`
            : x.nazivPredmetaStavke
        );
      });
  }

  onSelectChange(nazivSaSifrom: string) {
    const temp = nazivSaSifrom.split(' | ');
    let stavkaCenovnika: ICenovnikStavkaDto;
    if (temp.length === 1) {
      stavkaCenovnika = this.stavkeResults.find(
        (x) => x.nazivPredmetaStavke === temp[0]
      );
    } else {
      stavkaCenovnika = this.stavkeResults.find(
        (x) => x.nazivPredmetaStavke === temp[1]
      );
    }

    this.onStavkaCenovnikaChange.emit(stavkaCenovnika);
    this.stavkaAutocompleteInput.inputEL.nativeElement.value = '';
  }

  nazivChanged(event: any) {
    this.onNazivChange.emit(event.originalTarget.value);
  }

  onClear() {
    //this.stavkeCenovnikaCtrl.reset();
  }

  ngOnDestroy(): void {
    this._searchSub.unsubscribe();
    this._loadStavkeCenovnikaSub.unsubscribe();
  }
}
