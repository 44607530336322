<kodit-racun-form-container [racunFormConfig]="racunFormConfig">
  <div dodatni-input class="p-flex-md-row gap-12">
    <div class="p-flex-md-row even-columns gap-12">
      <kodit-text-input-field
        formCtrlId="broj-fiskalnog-isecka"
        formCtrlLabel="Broj fiskalnog isečka"
        [formCtrl]="fakturaForm.controls.brojFiskalnogIsecka"
        [isVertial]="false"
        formCtrlLabelWidth="150px"
      ></kodit-text-input-field>

      <kodit-field fieldLabel="Broj ugovora" fieldLabelFor="broj-ugovora">
        <kodit-text-input
          formCtrlId="broj-ugovora"
          [formCtrl]="fakturaForm.controls.racunDto.get('brojUgovora')"
          [formCtrlClass]="{
            'p-invalid':
              fakturaForm.errors?.dodatniPodaci &&
              fakturaForm.controls.racunDto.get('brojUgovora').touched
          }"
        >
        </kodit-text-input>
      </kodit-field>

      <!-- <kodit-field fieldLabel="Interni broj za rutiranje" fieldLabelFor="interniBrojZaRutiranje">
        <kodit-text-input
          formCtrlId="interniBrojZaRutiranje"
          [formCtrl]="fakturaForm.controls.racunDto.get('interniBrojZaRutiranje')"
        >
        </kodit-text-input>
      </kodit-field> -->

      <kodit-field
        fieldLabel="Broj narudžbenice/fakture/ponude"
        fieldLabelFor="broj-narudzbenice"
      >
        <kodit-text-input
          formCtrlId="broj-narudzbenice"
          [formCtrl]="fakturaForm.controls.racunDto.get('brojNarudzbenice')"
          [formCtrlClass]="{
            'p-invalid':
              fakturaForm.errors?.dodatniPodaci &&
              fakturaForm.controls.racunDto.get('brojNarudzbenice').touched
          }"
        >
        </kodit-text-input>
      </kodit-field>

      <kodit-field fieldLabel="Broj tendera" fieldLabelFor="broj-tendera">
        <kodit-text-input
          formCtrlId="broj-tendera"
          [formCtrl]="fakturaForm.controls.racunDto.get('brojTendera')"
          [formCtrlClass]="{
            'p-invalid':
              fakturaForm.errors?.dodatniPodaci &&
              fakturaForm.controls.racunDto.get('brojTendera').touched
          }"
        >
        </kodit-text-input>
      </kodit-field>
    </div>

    <hr />

    <div class="p-flex-md-row even-columns gap-12">
      <kodit-field
        fieldLabel="Datum narudžbenice"
        fieldLabelFor="datum-narudzbenice"
      >
        <kodit-calendar
          formCtrlId="datum-narudzbenice"
          [formCtrl]="fakturaForm.controls.racunDto.get('datumNarudzbenice')"
          class="flex-50"
        ></kodit-calendar>
      </kodit-field>
      <kodit-field fieldLabel="Broj otpremnice" fieldLabelFor="broj-otpremnice">
        <kodit-text-input
          formCtrlId="broj-otpremnice"
          [formCtrl]="fakturaForm.controls.racunDto.get('brojOtpremnice')"
          class="flex-50"
        >
        </kodit-text-input>
      </kodit-field>

      <kodit-field
        fieldLabel="Datum otpremnice"
        fieldLabelFor="datum-otpremnice"
      >
        <kodit-calendar
          formCtrlId="datum-otpremnice"
          [formCtrl]="fakturaForm.controls.racunDto.get('datumOtpremnice')"
          class="flex-50"
        ></kodit-calendar>
      </kodit-field>

      <kodit-field
        fieldLabel="Ovlašćen za potpisivanje"
        fieldLabelFor="ovlasceno-lice"
      >
        <kodit-text-input
          formCtrlId="ovlasceno-lice"
          [formCtrl]="
            fakturaForm.controls.racunDto.get('imeOsobeOvlasceneZaPotpisivanje')
          "
          class="flex-50"
        >
        </kodit-text-input>
      </kodit-field>
      <kodit-field fieldLabel="Vozila" fieldLabelFor="vozila">
        <kodit-vozilo-multiselect class="component-width"
            [voziloFormArray]="fakturaForm.controls.vozila"
            (selectedVozila)="selectedVozila($event)"
        >
        </kodit-vozilo-multiselect>
      </kodit-field>
    </div>

    <hr />

    <div class="p-flex-md-row even-columns gap-12" style="width: 200px;">
      <kodit-field fieldLabel="Šifra objekta" fieldLabelFor="sifra-objekta" >
        <kodit-text-input
          formCtrlId="sifra-objekta"
          [formCtrl]="fakturaForm.controls.racunDto.get('sifraObjekta')"
          class="flex-50"
        >
        </kodit-text-input>
      </kodit-field>
    </div>

    <div
      *ngIf="
        fakturaForm.errors?.dodatniPodaci &&
        (fakturaForm.controls.racunDto.get('brojUgovora').touched ||
          fakturaForm.controls.racunDto.get('brojNarudzbenice').touched ||
          fakturaForm.controls.racunDto.get('brojTendera').touched)
      "
      class="p-text-center"
    >
      <small class="p-invalid"
        >Obavezno je uneti jedno od polja: Broj ugovora ili Broj narudžbenice
        ili Broj tendera</small
      >
    </div>
  </div>
</kodit-racun-form-container>
