import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  CenovniciClient,
  CenovnikCreateUpdateResponse,
  CenovnikTableDto,
  GetCenovniciTableQuery,
  TipPristupa,
  VrstaEntiteta,
} from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import { CenovnikFormComponent } from '../cenovnik-form/cenovnik-form.component';

import {
  DialogSize,
  OnDynamicDialogInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  ActionType,
  OnTableInit,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';

@Component({
  selector: 'kodit-cenovnik-table',
  templateUrl: './cenovnik-table.component.html',
  styleUrls: ['./cenovnik-table.component.scss'],
})
export class CenovnikTableComponent
  implements
    OnInit,
    OnDestroy,
    AfterViewInit,
    OnTableInit,
    OnDynamicDialogInit {
  /** Subscriptions */
  private _cenovniciSub: Subscription = new Subscription();
  private _deleteSub: Subscription = new Subscription();
  private _tableRefreshSub: Subscription = new Subscription();
  private _dialogCloseSub: Subscription = new Subscription();

  /** Configurations */
  tableConfig: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.EXTRA_LARGE
  );

  /** Props */
  cenovniciTable: CenovnikTableDto[] = [];
  @Input() strankaId: number;
  @Input() showCardBlock: boolean = true;

  constructor(
    private _alertService: AlertService,
    private _dialogService: DynamicDialogService,
    private _client: CenovniciClient
  ) {}

  ngOnInit(): void {
    this.setTableConfig();
  }

  ngAfterViewInit(): void {
    this._loadCenovnici();
  }

  //#region  OnTableInit
  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Cenovnici',
      deleteMessage:
        'Da li ste sigurni da želite da arhivirate cenovnik: {param}?',
      multiDeleteMessage:
        'Da li ste sigurni da želite da arhivirate izabrane cenovnike?',
      deleteMessageParams: 'naziv',
      columns: [{ field: 'naziv', header: 'Naziv', type: 'text' }],
      headerActions: [
        {
          type: ActionType.CREATE,
          tipPristupa: TipPristupa.CENOVNIK_CRUD,
          hasAccessTooltip: 'Unesite cenovnik',
          noAccessTooltip: 'Nemate ovlašćenja za unos cenovnika',
          callback: () => {
            this.dialogConfig.data = { id: null, strankaId: this.strankaId };
            this.dialogConfig.header = 'Unos cenovnika';
            this.openDialog(this.dialogConfig);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          tipPristupa: TipPristupa.CENOVNIK_CRUD,
          hasAccessTooltip: 'Izmenite cenovnik',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu cenovnik',
          callback: (index: number) => {
            if (
              this.cenovniciTable[index].vrstaEntiteta ==
              VrstaEntiteta.SISTEMSKI
            ) {
              this._alertService.addWarnMsg(
                'Nije dozvoljeno menjati sistemski cenovnik'
              );
            } else {
              this.dialogConfig.data = {
                id: this.cenovniciTable[index].id,
                strankaId: this.strankaId,
              };
              this.dialogConfig.header = 'Izmena cenovnika';
              this.openDialog(this.dialogConfig);
            }
          },
        },
        // {
        //   type: ActionType.DELETE,
        //   tipPristupa: TipPristupa.CENOVNIK_DELETE,
        //   hasAccessTooltip: 'Arhivirajte cenovnik',
        //   noAccessTooltip: 'Nemate ovlašćenja za arhiviranje cenovnika',
        //   callback: (index: number) => {
        //     const forDelete = this.cenovniciTable[index];
        //     this._deleteSub = this._client.archive(forDelete.id).subscribe(
        //       () => {
        //         this.cenovniciTable.splice(index, 1);
        //         this._alertService.addSuccessMsg(
        //           `Cenovnik ${forDelete.naziv} | ${forDelete.naziv}  uspešno arhiviran.`
        //         );
        //       },
        //       (error) => {
        //         this._alertService.addFailedMsg(error);
        //       }
        //     );
        //   },
        // },
      ],
    });
  }

  //#endregion OnTableInit

  openDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(CenovnikFormComponent, config);

    this._dialogCloseSub = ref.onClose.subscribe(
      (res: CenovnikCreateUpdateResponse) => {
        if (res) {
          const idx = this.cenovniciTable.findIndex(
            (x) => x.id === res.cenovnikTableDto.id
          );
          if (idx > -1) {
            // update
            this.cenovniciTable[idx] = res.cenovnikTableDto;
          } else {
            // create
            this.cenovniciTable.push(res.cenovnikTableDto);
          }
        }
      }
    );
  }

  private _loadCenovnici() {
    this._cenovniciSub = this._client
      .getForTable(new GetCenovniciTableQuery({ strankaId: this.strankaId }))
      .subscribe(
        (result) => {
          this.cenovniciTable = result.data.cenovniciTable;
        },
        (error) => {
          this._alertService.addFailedMsg(error);
        }
      );
  }

  /** OnDestroy */
  ngOnDestroy(): void {
    this._cenovniciSub.unsubscribe();
    this._deleteSub.unsubscribe();
    this._tableRefreshSub.unsubscribe();
    this._dialogCloseSub.unsubscribe();
  }
}
