import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CreateRacunFromSefRacunCommand,
  IApiMenadzmentDto,
  MojaFirmaClient,
  ResultOfApiMenadzmentDto,
  SefClient,
} from '@kodit/core/data-api';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { MojaFirmaApiCardFormComponent } from '../moja-firma-api-card-form/moja-firma-api-card-form.component';
import { AlertService } from '@kodit/core/services';

@Component({
  selector: 'kodit-moja-firma-api-card',
  templateUrl: './moja-firma-api-card.component.html',
  styleUrls: ['./moja-firma-api-card.component.scss'],
})
export class MojaFirmaApiCardComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Configuration */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  apiMenadzmentDto: IApiMenadzmentDto;

  constructor(
    private _dialogService: DynamicDialogService,
    private _client: MojaFirmaClient,
    private _sefClient: SefClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._load();
  }

  onEdit() {
    this.dialogConfig.data = {
      apiKeySEF: this.apiMenadzmentDto.apiKeySEF,
    };
    this.dialogConfig.header = 'Izmena API menadžmenta';
    this.openDialog(this.dialogConfig);
  }

  onKreiraj() {
    this._subs.add(
      this._sefClient
        .createRacunFromSefRacun(new CreateRacunFromSefRacunCommand())
        .subscribe((res) => {
          if (!res.succeeded) {
            this._alertService.addWarnMsgs(res.messages);
          } else {
            this._alertService.addSuccessMsg(res.data);
          }
        })
    );
  }

  openDialog(config: DynamicDialogConfig): void {
    const ref = this._dialogService.open(MojaFirmaApiCardFormComponent, config);

    this._subs.add(
      ref.onClose.subscribe((dto: ResultOfApiMenadzmentDto) => {
        if (dto) {
          this.apiMenadzmentDto = dto.data;
        }
      })
    );
  }

  private _load() {
    this._subs.add(
      this._client.getApiMenadzment().subscribe((result) => {
        this.apiMenadzmentDto = result.data;
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
