import { Component, OnDestroy, OnInit } from '@angular/core';
import { FilterChipDto, GetRepromaterijalTableQuery, RepromaterijaliClient, RepromaterijalTableDto, TipPristupa } from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { ActionType, DynamicDialogConfig, DynamicDialogService, TableConfig } from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { RepromaterijalFormComponent } from '../repromaterijal-form/repromaterijal-form.component';

@Component({
  selector: 'kodit-repromaterijal-table',
  templateUrl: './repromaterijal-table.component.html',
  styleUrls: ['./repromaterijal-table.component.scss']
})
export class RepromaterijalTableComponent implements OnInit, OnDestroy {
  /** Configs */
  tableConfig!: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  private _subs: Subscription = new Subscription();
  repromaterijalTable: RepromaterijalTableDto[] = [];
  chipItems: FilterChipDto[] = [];

  constructor(
    private _client: RepromaterijaliClient,
    private _alertService: AlertService,
    private _dialogService: DynamicDialogService,
  ) { }

  ngOnInit(): void {
     this._load(new RepromaterijalTableDto());
    // set table config
    this.setTableConfig();
  }

  //#region Table configs

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Repromaterijali',
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete izabrane repromaterijale?',
      deleteMessage: 'Da li ste sigurni da želite da obrišete  repromaterijal {param}?',
      deleteMessageParams: 'naziv',
      columns: [
        {
          field: 'naziv',
          header: 'Naziv',
          type: 'text',
        },
        {
          field: 'tarifniBrojStr',
          header: 'Tarifni Broj',
          type: 'text',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          //tipPristupa: TipPristupa.REPROMATERIJAL_CREATE,
          hasAccessTooltip: 'Unesite repromaterijal',
          noAccessTooltip: 'Nemate ovlašćenja za unos repromaterijala',
          callback: () => {
            this.dialogConfig.data = { id: null };
            this.dialogConfig.header = 'Unos podataka repromaterijala';
            this.openDialog(this.dialogConfig);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          //tipPristupa: TipPristupa.REPROMATERIJAL_UPDATE,
          hasAccessTooltip: 'Izmenite uslugu',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu usluge',
          callback: (index: number) => {
            this.dialogConfig.data = { id: this.repromaterijalTable[index]?.id };
            this.dialogConfig.header = 'Izmena podataka usluge';
            this.openDialog(this.dialogConfig);
          },
        },
      ],
    });
  }

  //#endregion Table configs

  private _load(request: RepromaterijalTableDto) {
    this._subs.add(
      this._client.getForTable(request as GetRepromaterijalTableQuery).subscribe(
        (res) => {
          this.repromaterijalTable = res.data?.responseList!;
        },
        (error) => {
          this._subs.add(
            error.subscribe((x: string) => {
              this._alertService.addFailedMsg(x);
            })
          );
        }
      )
    );
  }

  openDialog(config: DynamicDialogConfig) {
    const ref = this._dialogService.open(RepromaterijalFormComponent, config);

    this._subs.add(
      ref.onClose.subscribe(() => {
        this._load(new RepromaterijalTableDto());
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}