import { Component, Input, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { IPdvEvidencijaPojedinacnaDto } from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Pdv39Form, PdvEvidencijaPojedinacnaService } from '../pdv-evidencija-pojedinacna.service';

@Component({
  selector: 'kodit-povezani-avansni-racuni',
  templateUrl: './povezani-avansni-racuni.component.html',
  styleUrls: ['./povezani-avansni-racuni.component.scss'],
})
export class PovezaniAvansniRacuniComponent implements OnInit {
  @Input() form!: FormGroupTypeSafe<IPdvEvidencijaPojedinacnaDto>;
  @Input() form39!: Pdv39Form;
  @Input() shouldDisable: boolean = false;

  constructor(
  ) {}

  ngOnInit(): void {
  }

  onRemovePovezanAvansniRacun(index: number) {
    if(this.form){
      (this.form.controls.povezaniDokumenti as FormArray).removeAt(index);
      return;
    }
    
    (this.form39!.controls.povezaniRacuni as FormArray).removeAt(index);
  }
}
