import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ListItemDtoOfLong } from '@kodit/core/data-api';
import { areArraysEqual } from '@kodit/core/shared';
import { ConfirmationService } from 'primeng/api';
import { ActionType, ListAction, ListConfig } from './list.config';

@Component({
  selector: 'kodit-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, OnChanges {
  @Input() items: ListItemDtoOfLong[] = [];
  @Input() public listConfig: ListConfig;
  showPaginator: boolean;

  constructor(private _confirmationService: ConfirmationService) {
    this.listConfig = new ListConfig();
  }

  ngOnInit(): void {
    this.showPaginator =
      this.listConfig.paginator &&
      this.items.length > Number(this.listConfig.rows);
    this.listConfig = new ListConfig(this.listConfig);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !areArraysEqual(changes.items?.currentValue, changes.items?.previousValue)
    ) {
      this.items = changes.items?.currentValue;
    }
  }

  onCreate() {
    this.listConfig.createCallbackFunction();
  }

  onEdit(itemIndex: number) {
    this.listConfig.editCallbackFunction(itemIndex);
  }

  checkAction(action: ListAction, itemIndex: number) {
    if (action.type !== ActionType.DELETE) {
      action.callback(itemIndex);
      return;
    }
    this.onDelete(action, itemIndex);
  }

  onDelete(action: ListAction, itemIndex: Number) {
    const params = this.listConfig.deleteMessageParams?.split(',');
    let param = '';
    // params?.forEach((p) => {
    //   param += this.item.data[p] ?? p;
    // });
    this._confirmationService.confirm({
      message: this.listConfig.deleteMessage.replace('{param}', param),
      header: 'Potvrdite brisanje',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-secondary p-button-outlined',
      accept: () => {
        action.callback(itemIndex);
      },
    });
  }
}
