import { Component, OnDestroy, OnInit } from '@angular/core';
import { IUplateIsplateSummaryDto, UplateIsplateSummaryDto } from '@kodit/core/data-api';
import { UplateIsplateFilterService } from 'libs/core/shared-subforms/src/lib/izvod/uplate-isplate-filter/uplate-isplate-filter.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-isplate-page',
  templateUrl: './isplate-page.component.html',
  styleUrls: ['./isplate-page.component.scss'],
})
export class IsplatePageComponent implements OnInit, OnDestroy {
  /** Props */
  private _subs: Subscription = new Subscription();

  isplateSummary: IUplateIsplateSummaryDto;

  constructor(private _uplateIsplateService: UplateIsplateFilterService) {}

  ngOnInit(): void {
    this._subs.add(
      this._uplateIsplateService.getTransakcijeSymmary.subscribe(
        (res) => (this.isplateSummary = res)
      )
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
