import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@kodit/core/services';
import { DynamicDialogRef } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-izloguj-upozorenje-dialog',
  templateUrl: './izloguj-upozorenje-dialog.component.html',
  styleUrls: ['./izloguj-upozorenje-dialog.component.scss'],
})
export class IzlogujUpozorenjeDialogComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription;

  /** Props */
  remainingTime: number = 10;
  timer: any;

  constructor(
    private _authService: AuthService,
    private _dialogRef: DynamicDialogRef
  ) {
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._izloguj();
      })
    );

    this.timer = setInterval(() => {
      this.remainingTime--;

      if (this.remainingTime <= 0) {
        this._izloguj();
      }
    }, 1000);
  }

  private _izloguj() {
    this._dialogRef.close();
    this._authService.logout();
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
    this._subs.unsubscribe();
  }
}
