import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { OsnovOdabiraDokumentaDto, PdvEvidencijaOsnovOdabiraDokumenta, PdvEvidencijeClient, TipPovezanogInternogDto } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { PdvEvidencijaPojedinacnaService } from '../../pojedinacna/pdv-evidencija-pojedinacna.service';

@Component({
  selector: 'kodit-tip-povezanog-internog-dropdown',
  templateUrl: './tip-povezanog-internog-dropdown.component.html',
  styleUrls: ['./tip-povezanog-internog-dropdown.component.scss'],
})
export class TipPovezanogInternogDropdownComponent
  implements OnInit, OnDestroy {
  private _subs: Subscription;

  /** Props */
  items: SelectItem[];
  fullItems: SelectItem[] = [];
  tipPovezanogInternogRacuna: TipPovezanogInternogDto[] = [];

  /** I/O */
  @Input() formCtrl: AbstractControl | undefined;
  @Input() formCtrlId: string;
  @Input() parentCtrl: string;
  @Input() shouldDisable: boolean = false;
  @Output() onItemChange = new EventEmitter<any>();

  constructor(
    private _client: PdvEvidencijeClient,
    private _service: PdvEvidencijaPojedinacnaService
  ) {
    this._subs = new Subscription();
    this.items = [];
    this.formCtrlId = 'tip-povezanog-internog-dropdown';
    this.parentCtrl = 'body';
    this.formCtrl = undefined!;
  }

  ngOnInit(): void {
    this._load();
    this._subs.add(
      this._service.getOsnovOdabiraObservable.subscribe((osnovOdabira) => {
        this._filterItems(osnovOdabira);
      })
    );
  }

  private _load() {
    this._subs.add(
      this._client.getTipPovezanogInternogDropdownQuery().subscribe((res) => {
        if (!res.succeeded) {
          return;
        }
        this.tipPovezanogInternogRacuna = res.data!.responseList!;

        this.fullItems = this.tipPovezanogInternogRacuna.map((x) => ({
          value: x.osnov,
          label: x.osnovStr,
          title: x.opis,
        }));
        this.items = this.fullItems;

        this._filterItems(this._service.getOsnovOdabira);
      })
    );
  }

  private _filterItems(osnovOdabira: PdvEvidencijaOsnovOdabiraDokumenta) {
    if (osnovOdabira == PdvEvidencijaOsnovOdabiraDokumenta.PROMET) {
      this.items = this.fullItems.filter((item) =>
        ['Interni račun za avans', 'Bez povezanih dokumenata'].includes(
          item.label!
        )
      );
    } else if (osnovOdabira == PdvEvidencijaOsnovOdabiraDokumenta.POVECANJE) {
      this.items = this.fullItems.filter((item) =>
        ['Interni račun za promet'].includes(item.label!)
      );
    } else if (osnovOdabira == PdvEvidencijaOsnovOdabiraDokumenta.SMANJENJE) {
      this.items = this.fullItems.filter(
        (item) => !['Bez povezanih dokumenata'].includes(item.label!)
      );
    }
  }

  handleOnItemChange(event: any) {
    this.onItemChange.emit(event);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
