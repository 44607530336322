<div>
  <div class="steps-title p-mb-5">KORAK 03</div>

  <h2>Popunjavanje fizičkih lokacija</h2>
  <p>Unesite fizičke lokacije na kojima čuvate dokumentaciju.</p>

  <div class="table-header">FIZIČKE LOKACIJE</div>

  <kodit-fizicke-lokacije-tree></kodit-fizicke-lokacije-tree>
</div>
