/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Component, OnInit } from '@angular/core';
import {
  CreateInformacijaIsporukeCommand,
  IInformacijaIsporukeDto,
  InformacijaIsporukeDto,
  StrankaClient,
} from '@kodit/core/data-api';
import { InformacijaIsporukeStrankeService } from '@kodit/core/form-definitions';
import { AlertService, Mode } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-informacija-isporuke-form',
  templateUrl: './informacija-isporuke-form.component.html',
  styleUrls: ['./informacija-isporuke-form.component.scss'],
})
export class InformacijaIsporukeFormComponent implements OnInit {
  /** Subscriptions */
  private _getSub: Subscription = new Subscription();
  private _saveSub: Subscription = new Subscription();
  private _submitSub: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<IInformacijaIsporukeDto>;
  mode: Mode = Mode.NEW;
  strankaId: number;
  informacijaIsporukeId: number;

  constructor(
    private _client: StrankaClient,
    private _alertService: AlertService,
    public _dialogConfig: DynamicDialogConfig,
    public _dialogRef: DynamicDialogRef,
    private _informacijaIsporukeFS: InformacijaIsporukeStrankeService
  ) {}

  ngOnInit(): void {
    this.form = this._informacijaIsporukeFS.GetInformacijaIsporukeStrankeFormGroup(
      this._dialogConfig.data?.strankaId
    );
    this.informacijaIsporukeId = this._dialogConfig.data.id;
    this.strankaId = this._dialogConfig.data.strankaId;
    if (this.informacijaIsporukeId != null) {
      this.getById(this.informacijaIsporukeId);
    }
    this._submitSub.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.save();
    }
  }

  save(): void {
    this.form.controls.strankaId.patchValue(this.strankaId);
    const request = new InformacijaIsporukeDto(this.form.value);

    if (
      this.informacijaIsporukeId == null ||
      this.informacijaIsporukeId == undefined
    ) {
      this._saveSub.add(
        this._client
          .createInformacijaIsporukeStranka(
            request as CreateInformacijaIsporukeCommand
          )
          .subscribe((result) => {
            this._alertService.addSuccessMsg(result.messages[0]);
            this._dialogRef.close(result);
          })
      );
    } else {
      this.form.controls.id.patchValue(this.informacijaIsporukeId);
      this._saveSub.add(
        this._client
          .updateInformacijaIsporuke(
            this.informacijaIsporukeId,
            this.form.value as InformacijaIsporukeDto
          )
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.messages[0]);
            this._dialogRef.close(res);
          })
      );
    }
  }

  getById(id: number): void {
    this._getSub.add(
      this._client.getInformacijaIsporukeStrankaById(id).subscribe(
        (model) => {
          this.form.patchValue(model.data);
        },
        (error) => {
          this._alertService.addFailedMsg(error);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this._getSub.unsubscribe();
    this._submitSub.unsubscribe();
    this._saveSub.unsubscribe();
  }

}
