import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IGetDelovodnikTableQuery } from '@kodit/core/data-api';
import { AdvancedFilter } from '@kodit/core/shared-ui';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { clearFilter, setFilter } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'kodit-delovodnik-filter',
  templateUrl: './delovodnik-filter.component.html',
  styleUrls: ['./delovodnik-filter.component.scss'],
})
export class DelovodnikFilterComponent
  implements OnInit, AdvancedFilter<IGetDelovodnikTableQuery> {
  /**Props */
  filterData!: IGetDelovodnikTableQuery;
  form!: FormGroupTypeSafe<IGetDelovodnikTableQuery>;

  /** I/O */
  @Output() onSubmitForm = new EventEmitter<IGetDelovodnikTableQuery>();

  constructor(
    private _storage: Store,
    private _fb: FormBuilderTypeSafe
    ) {}

  ngOnInit(): void {
    this.form = this._getDelovodnikTableFilterFormGroup(this.filterData);
  }

  onFilterSubmit() {
    this._storage.dispatch(
      setFilter({
        key: 'DELOVODNIK',
        filter: JSON.stringify(this.form.value),
      })
    );
  }

  onFilterHide(shouldReset: boolean) {
    if (shouldReset) {
      this._storage.dispatch(
        clearFilter({
          key: 'DELOVODNIK',
        })
      );
    }

    // this._uplateIsplateFilterService.setAdvancedFilterSubmitted = {
    //   filterData: shouldReset ? new GetUplateQuery() : this.form.value,
    //   shouldApplyFilter: shouldReset,
    // };
  }

  private _getDelovodnikTableFilterFormGroup(
    model: IGetDelovodnikTableQuery // | IGetIsplateQuery dodati
  ): FormGroupTypeSafe<IGetDelovodnikTableQuery> {
    return this._fb.group<IGetDelovodnikTableQuery>({
      naziv: new FormControl(model.naziv),
    });
  }
}
