import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss']
})
export class WelcomeMessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
