import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  IInviteKorisnikCommand,
  InviteKorisnikCommand,
  KorisniciClient,
} from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogRef, DynamicDialogConfig } from '@kodit/core/shared-ui';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-invite-korisnik-form',
  templateUrl: './invite-korisnik-form.component.html',
  styleUrls: ['./invite-korisnik-form.component.scss'],
})
export class InviteKorisnikFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  form: FormGroupTypeSafe<IInviteKorisnikCommand>;

  constructor(
    private _client: KorisniciClient,
    private _formHelper: FormHelper,
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _fb: FormBuilderTypeSafe
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group<IInviteKorisnikCommand>({
      email: new FormControl('', {
        validators: [Validators.required],
      }),
      roleId: new FormControl(null, {
        validators: [Validators.required],
      }),
    });

    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._handleInvite();
      })
    );
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this._handleInvite();
    }
  }

  private _handleInvite() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }
    this._subs.add(
      this._client
        .inviteUser(this.form.value as InviteKorisnikCommand)
        .subscribe(
          (res) => {
            if (res.succeeded) {
              this._alertService.addSuccessMsg(res.messages[0]);
            } else {
              this._alertService.addFailedMsg(res.messages[0]);
            }
            this._dialogRef.close(res);
          },
          (error) => {
            this._subs.add(
              error.subscribe((e: string) => {
                this._alertService.addFailedMsg(e);
              })
            );
          }
        )
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
