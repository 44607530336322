<input
  [id]="formCtrlId"
  pInputText
  [formControl]="formCtrl"
  [pKeyFilter]="onlyNumbers"
  [pValidateOnly]="true"
  [maxlength]="formCtrlMaxLength ? formCtrlMaxLength : null"
/>
<kodit-error-info-required [formCtrl]="formCtrl"> </kodit-error-info-required>
<kodit-error-info-max-length
  [formCtrl]="formCtrl"
></kodit-error-info-max-length>
<kodit-error-info-min-length
  [formCtrl]="formCtrl"
></kodit-error-info-min-length>
<kodit-error-info-min [formCtrl]="formCtrl"></kodit-error-info-min>
<small
  *ngIf="
    formCtrl.errors?.validatePattern === false &&
    (formCtrl.dirty || formCtrl.touched)
  "
  class="p-invalid"
  >U polje se mogu uneti samo brojevi</small
>
