<kodit-racun-form-container [racunFormConfig]="racunFormConfig">
  <div dodatni-input class="p-flex-md-row gap-12">
    <div class="p-flex-md-row even-columns gap-12">
      <kodit-field fieldLabel="Broj ugovora" fieldLabelFor="broj-ugovora">
        <kodit-text-input
          formCtrlId="broj-ugovora"
          [formCtrl]="racunForm.controls.racunDto.get('brojUgovora')"
        >
        </kodit-text-input>
      </kodit-field>

      <kodit-field
        fieldLabel="Broj narudžbenice/fakture/ponude"
        fieldLabelFor="broj-narudzbenice"
      >
        <kodit-text-input
          formCtrlId="broj-narudzbenice"
          [formCtrl]="racunForm.controls.racunDto.get('brojNarudzbenice')"
        >
        </kodit-text-input>
      </kodit-field>

      <kodit-field fieldLabel="Broj tendera" fieldLabelFor="broj-tendera">
        <kodit-text-input
          formCtrlId="broj-tendera"
          [formCtrl]="racunForm.controls.racunDto.get('brojTendera')"
        >
        </kodit-text-input>
      </kodit-field>
      <kodit-field fieldLabel="Šifra objekta" fieldLabelFor="sifra-objekta">
        <kodit-text-input
          formCtrlId="sifra-objekta"
          [formCtrl]="racunForm.controls.racunDto.get('sifraObjekta')"
          class="flex-50"
        >
        </kodit-text-input>
      </kodit-field>
    </div>
  </div>
</kodit-racun-form-container>
