import { Component, OnDestroy, OnInit } from '@angular/core';


@Component({
  selector: 'kodit-ios-izvestaj-page',
  templateUrl: './ios-izvestaj-page.component.html',
  styleUrls: ['./ios-izvestaj-page.component.scss']
})
export class IosIzvestajPageComponent implements OnInit, OnDestroy {

  constructor() {}

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    
  }

}
