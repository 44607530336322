import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import {
  IRepromaterijalDto,
  RepromaterijalDto,
} from '@kodit/core/data-api';

@Injectable({
  providedIn: 'root',
})
export class RepromaterijalFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
  ) {}

  public GetRepromaterijalFormGroup(model?: RepromaterijalDto): FormGroupTypeSafe<IRepromaterijalDto> {
      return this._fb.group<IRepromaterijalDto>({
        id : new FormControl(model?.id),
        naziv: new FormControl(model?.naziv, [Validators.required]),
        tarifniBrojStr : new FormControl(model?.tarifniBrojStr, [Validators.required,Validators.minLength(2)])
    });
  }
}
