import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IAvansniRacunTableDto,
  IVezaniRacunDto,
  RacuniClient,
  RemoveVezaniRacunCommand,
} from '@kodit/core/data-api';
import { AlertService, LocationService } from '@kodit/core/services';
import { Subscription } from 'rxjs';
import { RacunService } from '../../../../../../services/src/lib/racun.service';
import { FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'kodit-povezani-racuni',
  templateUrl: './povezani-racuni.component.html',
  styleUrls: ['./povezani-racuni.component.scss'],
})
export class PovezaniRacuniComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  vezaniRacuni: IVezaniRacunDto[] = [];
  avansneUplateTable: FormArray;
  odabraneAvansneUplateZaRacunNonModified: IAvansniRacunTableDto[] = [];
  racunId: number;
  jeKonvertovanje: boolean;

  constructor(
    private _racunService: RacunService,
    private _locationService: LocationService,
    private _racunClient: RacuniClient,
    private _routing: ActivatedRoute,
    private _alertService: AlertService
  ) {
  }

  ngOnInit(): void {
    this.avansneUplateTable = this._racunService.getAvansneUplateRacunaArray;
    this.racunId = this._routing.snapshot.data.racun?.racunDto?.id;
    this.jeKonvertovanje = this._routing.snapshot.data.jeKonvertovanje ?? false
    this.vezaniRacuni = this._racunService.getVezaniRacunArray.value;
    this._subs.add(
      this._racunService.getVezaniRacunArray.valueChanges.subscribe((res) => {
        this.vezaniRacuni = res;
      })
    );
  }

  async onClick(racun: IVezaniRacunDto) {
    await this._locationService.routeToRacunKartica(
      racun.racunId,
      racun.tip,
      racun.vrsta
    );
  }

  onRemove(index: number) {
    this._subs.add(
      this._racunClient
        .removeVezaniRacun({
          povezanChildRacunId: this.vezaniRacuni[index].racunId,
          tipPoveznogRacuna: this.vezaniRacuni[index].tip,
          id: this.racunId,
        } as RemoveVezaniRacunCommand)
        .subscribe((res) => {
          if (res.succeeded) {
            location.reload();
            this._alertService.addSuccessMsg('Račun uspešno razvezan');
          } else {
            this._alertService.addWarnMsg(res.messages[0]);
          }
        })
    );

    // if (this.vezaniRacuni[index].tip == TipRacuna.AVANSNI_RACUN) {
    //   const allAvansneUplateTable = this.avansneUplateTable
    //     .value as IAvansnaUplataRacunaTableDto[];

    //   allAvansneUplateTable.splice(index, 1);

    //   this._racunService.setAvansneUplateRacuna = allAvansneUplateTable.map(
    //     (ostaliAvansni) => ({
    //       id: ostaliAvansni.id,
    //       avansniRacunId: ostaliAvansni.avansniRacunId,
    //       avansniRacunBroj: ostaliAvansni.avansniRacunBroj,
    //       avansniRacunVrsta: ostaliAvansni.avansniRacunVrsta,
    //     })
    //   );

    //   this.avansneUplateTable = this._racunService.getAvansneUplateRacunaArray;
    // }

    // this._racunService.setVezaniRacunArray = [
    //   ...this.vezaniRacuni.filter((x, i) => i !== index),
    // ];
    // if (
    //   this._racunService.getTipRacuna === TipRacuna.DOKUMENT_O_SMANJENJU ||
    //   this._racunService.getTipRacuna === TipRacuna.DOKUMENT_O_POVECANJU
    // ) {
    //   this._racunService.setReferentniDokument = {
    //     broj: null,
    //     datum: null,
    //   };
    // }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
