import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  GetOznakeQuery,
  IOznakaDto,
  OznakaDto,
  OznakeClient,
  TipEntiteta,
} from '@kodit/core/data-api';
import { OznakaFormService } from '@kodit/core/form-definitions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-odobravanje-oznake-form',
  templateUrl: './odobravanje-oznake-form.component.html',
  styleUrls: ['./odobravanje-oznake-form.component.scss'],
})
export class OdobravanjeOznakeFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();
  
  /** Props */
  oznakeResults: OznakaDto[] = [];

  /** I/O */
  @Input() oznakeArray: FormArray;
  @ViewChild('oznakaInput') oznakaInput: any;

  constructor(
    private oznakeClient: OznakeClient,
    private oznakaFormService: OznakaFormService
  ) {}

  ngOnInit(): void {}

  get oznakeValues(): OznakaDto[] {
    return this.oznakeArray.value as OznakaDto[];
  }

  searchOznake(event: any) {
    this._subs.add(
      this.oznakeClient
        .get(
          new GetOznakeQuery({
            naziv: event.query,
            excludeTipoveEntiteta: [
              TipEntiteta.PRAVNO_LICE,
              TipEntiteta.FIZICKO_LICE,
            ],
          })
        )
        .subscribe((result) => {
          this.oznakeResults = result.oznake.filter((x) =>
            this.filterOznaka(x.naziv)
          );
        })
    );
  }

  filterOznaka(naziv: string): boolean {
    let alreadyAdded: boolean;
    this.oznakeValues.forEach((o) => {
      if (o.punNaziv && o.punNaziv.includes(naziv)) {
        alreadyAdded = true;
        return;
      } else if (o.naziv.includes(naziv)) {
        alreadyAdded = true;
        return;
      }
    });

    return (
      this.oznakeValues.findIndex((x) => x.naziv == naziv) === -1 &&
      !alreadyAdded
    );
  }

  onOznakaSelect(event: IOznakaDto) {
    this.oznakeArray.push(this.oznakaFormService.GetOznakaFormGroup(event));
    this.oznakaInput.inputEL.nativeElement.value = '';
  }

  onRemoveOznakaRacunaClicked(idx: number) {
    this.oznakeArray.removeAt(idx);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
