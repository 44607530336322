import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

/** Moduli */
import { ModuliStrankeRoutingModule } from './moduli-stranke-routing.module';
import { CoreModuliBankaModule } from '@kodit/core/moduli/banka';
import { CoreModuliCenovnikModule } from '@kodit/core/moduli/cenovnik';
import { CoreModuliKontaktModule } from '@kodit/core/moduli/kontakt';
import { CoreSharedModule } from 'libs/core/shared/src';
import { CoreSharedSubformsModule } from '@kodit/core/shared-subforms';

/** Components */
import { DelatnostDropdownComponent } from './pravno-lice/ui/delatnost-dropdown/delatnost-dropdown.component';
import { PibInputMaskComponent } from './pravno-lice/ui/pib-input-mask/pib-input-mask.component';
import { MbInputMaskComponent } from './pravno-lice/ui/mb-input-mask/mb-input-mask.component';
import { RokPlacanjaInputNumbersComponent } from './ui/rok-placanja-input-numbers/rok-placanja-input-numbers.component';
import { StrankaInfoComponent } from './stranka-info/stranka-info.component';
import { PravnoLiceTableComponent } from './pravno-lice/pravno-lice-table/pravno-lice-table.component';
import { PravnoLiceInfoComponent } from './pravno-lice/pravno-lice-info/pravno-lice-info.component';
import { PravnoLiceFormComponent } from './pravno-lice/pravno-lice-form/pravno-lice-form.component';
import { PravnoLiceFilterComponent } from './pravno-lice/pravno-lice-filter/pravno-lice-filter.component';
import { FizickoLiceTableComponent } from './fizicko-lice/fizicko-lice-table/fizicko-lice-table.component';
import { FizickoLiceInfoComponent } from './fizicko-lice/fizicko-lice-info/fizicko-lice-info.component';
import { FizickoLiceFormComponent } from './fizicko-lice/fizicko-lice-form/fizicko-lice-form.component';
import { FizickoLiceFilterComponent } from './fizicko-lice/fizicko-lice-filter/fizicko-lice-filter.component';
import { PravnoLiceTipFormComponent } from './pravno-lice/pravno-lice-tip-form/pravno-lice-tip-form.component';
import { PoslovnaJedinicaTableComponent } from './poslovna-jedinica/poslovna-jedinica-table/poslovna-jedinica-table.component';
import { PoslovnaJedinicaFormComponent } from './poslovna-jedinica/poslovna-jedinica-form/poslovna-jedinica-form.component';
import { SlanjePodsetnikaFormComponent } from './slanje-podsetnika-form/slanje-podsetnika-form.component';
import { JbkjsInputMaskComponent } from './pravno-lice/ui/jbkjs-input-mask/jbkjs-input-mask.component';
import { InformacijaIsporukeListComponent } from './informacija-isporuke/informacija-isporuke-list/informacija-isporuke-list.component';
import { InformacijaIsporukeFormComponent } from './informacija-isporuke/informacija-isporuke-form/informacija-isporuke-form.component';
import { PravnoLiceExportFormComponent } from './pravno-lice/pravno-lice-export-form/pravno-lice-export-form.component';
import { CoreModuliRobaModule } from '@kodit/core/moduli/roba';
import { VrstaPoslovneJediniceDropdownComponent } from './poslovna-jedinica/ui/vrsta-poslovne-jedinice-dropdown/vrsta-poslovne-jedinice-dropdown.component';

@NgModule({
  imports: [
    ModuliStrankeRoutingModule,
    CoreModuliBankaModule,
    CoreModuliCenovnikModule,
    CoreModuliKontaktModule,
    CoreSharedModule,
    CoreSharedSubformsModule,
    CoreModuliRobaModule,
  ],
  declarations: [
    DelatnostDropdownComponent,
    PibInputMaskComponent,
    MbInputMaskComponent,
    PravnoLiceTableComponent,
    PravnoLiceFilterComponent,
    PravnoLiceInfoComponent,
    PravnoLiceFormComponent,
    PravnoLiceTipFormComponent,
    FizickoLiceFilterComponent,
    FizickoLiceTableComponent,
    FizickoLiceInfoComponent,
    FizickoLiceFormComponent,
    StrankaInfoComponent,
    RokPlacanjaInputNumbersComponent,
    PoslovnaJedinicaTableComponent,
    PoslovnaJedinicaFormComponent,
    SlanjePodsetnikaFormComponent,
    JbkjsInputMaskComponent,
    InformacijaIsporukeListComponent,
    InformacijaIsporukeFormComponent,
    PravnoLiceExportFormComponent,
    VrstaPoslovneJediniceDropdownComponent,
  ],
  exports: [
    PoslovnaJedinicaTableComponent,
    FizickoLiceFormComponent,
    InformacijaIsporukeListComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModuliStrankaModule {}
