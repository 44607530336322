import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { VrstaFiskalnogRacuna } from '@kodit/core/data-api';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { FiskalniRacunService } from '../../fiskalni-racun.service';

@Component({
  selector: 'kodit-vrsta-fiskalnog-racuna-dialog',
  templateUrl: './vrsta-fiskalnog-racuna-dialog.component.html',
  styleUrls: ['./vrsta-fiskalnog-racuna-dialog.component.scss'],
})
export class VrstaFiskalnogRacunaDialogComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  saleItems: SelectItem[] = [];
  refundItems: SelectItem[] = [];
  selectedVrstaFiskalnogRacuna!: VrstaFiskalnogRacuna;
  formControl: FormControl;

  constructor(
    private _fiskalniService: FiskalniRacunService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig
  ) {
    this.formControl = new FormControl(VrstaFiskalnogRacuna.PROMET);
  }

  ngOnInit(): void {
    this._load();
    this.selectedVrstaFiskalnogRacuna = this._dialogConfig.data.vrstaRacunaSelected;
    this.formControl.patchValue(this.selectedVrstaFiskalnogRacuna);
  }

  private _load() {
    this.saleItems = this._getSaleItems();
    this.refundItems = this._getRefundItems();

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._odaberiVrstuFiskalnogRacuna(this.formControl.value);
        this._dialogRef.close();
      })
    );
  }

  private _odaberiVrstuFiskalnogRacuna(item: VrstaFiskalnogRacuna) {
    this._fiskalniService.setVrstaFiskalnogRacuna(item);
    this._dialogRef.close();
  }

  private _getSaleItems(): SelectItem[] {
    return [
      {
        value: VrstaFiskalnogRacuna.PROMET,
        label: 'Promet',
      },
      {
        value: VrstaFiskalnogRacuna.AVANS,
        label: 'Avans',
      },
      {
        value: VrstaFiskalnogRacuna.PREDRACUN,
        label: 'Predracun',
      },
      {
        value: VrstaFiskalnogRacuna.OBUKA,
        label: 'Obuka',
      },
    ];
  }

  private _getRefundItems() {
    return [
      {
        value: VrstaFiskalnogRacuna.PROMET_REFUNDACIJA,
        label: 'Promet refundacija',
      },
      {
        value: VrstaFiskalnogRacuna.AVANS_REFUNDACIJA,
        label: 'Avans refundacija',
      },
      {
        value: VrstaFiskalnogRacuna.PREDRACUN_REFUNDACIJA,
        label: 'Predracun refundacija',
      },
      {
        value: VrstaFiskalnogRacuna.OBUKA_REFUNDACIJA,
        label: 'Obuka refundacija',
      },
    ];
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
