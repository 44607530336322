import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kodit-arhivska-exist-step2',
  templateUrl: './arhivska-exist-step2.component.html',
  styleUrls: ['./arhivska-exist-step2.component.scss'],
})
export class ArhivskaExistStep2Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
