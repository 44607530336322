<form [formGroup]="form" (keydown)="keyDownFunction($event)">
  <div class="p-fluid">
    <kodit-field fieldLabel="Nova šifra" fieldLabelFor="newPassword">
      <kodit-text-input
        formCtrlId="newPassword"
        formCtrlType="password"
        [formCtrl]="form.controls.newPassword"
      ></kodit-text-input>
    </kodit-field>

    <kodit-field fieldLabel="Ponovite šifru" fieldLabelFor="newPasswordCheck">
      <kodit-text-input
        formCtrlId="newPasswordCheck"
        formCtrlType="password"
        [formCtrl]="form.controls.newPasswordCheck"
      ></kodit-text-input>
    </kodit-field>
  </div>
</form>
