import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  IDokumentOPovecanjuDto,
  IDokumentOSmanjenjuDto,
  IGetFormConfigurationResponse,
  IPrikazIzmenaOsnoviceDto,
  PrikazIzmenaOsnovice,
  TipEntiteta,
  TipRacuna,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { BaseRacunFormService } from './_base_racun-form.service';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class IzmenaOsnoviceFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _baseRacunForm: BaseRacunFormService
  ) {}

  /**
   * Konvertuje model i od njega pravi reaktivnu formu (form grupu) za izmenu osnovice
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   * @param model model izmene osnovice
   * @returns Reaktivnu formu kao FormGroup za izmenu osnovice
   */
  public GetFormGroup(
    tipRacuna: TipRacuna,
    vrstaRacuna: VrstaRacuna,
    formConfiguration: IGetFormConfigurationResponse,
    model?: IDokumentOSmanjenjuDto | IDokumentOPovecanjuDto
  ): FormGroupTypeSafe<IDokumentOSmanjenjuDto | IDokumentOPovecanjuDto> {
    const baseRacunForm = this._baseRacunForm.GetBaseRacunFormGroup(
      tipRacuna,
      vrstaRacuna,
      tipRacuna === TipRacuna.DOKUMENT_O_SMANJENJU
        ? TipEntiteta.DOKUMENT_O_SMANJENJU
        : TipEntiteta.DOKUMENT_O_POVECANJU,
      formConfiguration,
      model?.racunDto,
      true
    );
    return this._fb.group<IDokumentOSmanjenjuDto | IDokumentOPovecanjuDto>({
      racunDto: baseRacunForm,
      periodDo: new FormControl(model?.periodDo),
      periodOd: new FormControl(model?.periodOd),
      prikazIzmenaOsnoviceDto: this._getPrikazDokumentaOSmanjenjuIPovecanjuFormGroup(
        model?.prikazIzmenaOsnoviceDto
      ),
      brojReferentnogDokumenta : new FormControl(model?.brojReferentnogDokumenta)
    });
  }

  private _getPrikazDokumentaOSmanjenjuIPovecanjuFormGroup(
    model?: IPrikazIzmenaOsnoviceDto
  ): FormGroupTypeSafe<IPrikazIzmenaOsnoviceDto> {
    return this._fb.group<IPrikazIzmenaOsnoviceDto>({
      prikazIzmenaOsnovice: new FormControl(
        model?.prikazIzmenaOsnovice ?? PrikazIzmenaOsnovice.FAKTURE_U_PERIODU
      ),
      prikazIzmenaOsnoviceStr: new FormControl(model?.prikazIzmenaOsnoviceStr),
      opis: new FormControl(model?.opis),
    });
  }
}
