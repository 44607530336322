import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { BaseService } from '@kodit/core/services';
import {
  PdvEvidencijeClient, PPPDVEvidencijaDto
} from '@kodit/core/data-api';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PPPDVResolver
  extends BaseService
  implements Resolve<PPPDVEvidencijaDto> {
  constructor(private _client: PdvEvidencijeClient) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<PPPDVEvidencijaDto>
    | Promise<PPPDVEvidencijaDto>
    | PPPDVEvidencijaDto
    | null {
    const id = Number.parseInt(route.paramMap.get('id') ?? '');
    return this._client.getPPPDVEvidencija(id).pipe(
      map((x) => x.data),
      catchError(this.handleError),
      finalize(() => {
        //this._configService.setIsBusy = false;
      })
    );
  }
}
