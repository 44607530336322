import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ExchangeRateDto,
  GetKursZaValuteQuery,
  SharedClient,
} from '@kodit/core/data-api';
import { SharedService } from '@kodit/core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-srednji-kurs-za-valute',
  templateUrl: './srednji-kurs-za-valute.component.html',
  styleUrls: ['./srednji-kurs-za-valute.component.scss'],
})
export class SrednjiKursZaValuteComponent implements OnInit, OnDestroy {
  private _sub: Subscription = new Subscription();

  exchangeRateDtoList: ExchangeRateDto[] = [];

  constructor(private _client: SharedClient, private _service: SharedService) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._sub = this._client
      .getKursZaValute(new GetKursZaValuteQuery())
      .subscribe((res) => {
        this.exchangeRateDtoList = res.data;
        this._service.setKursEvra = res.data.find((x) =>
          x.currencyConversion.includes('EUR')
        );
      });
  }

  ngOnDestroy(): void {
    this._service.setKursEvra = null;
    this._sub.unsubscribe();
  }
}
