import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { BaseService } from '@kodit/core/services';
import {
  AvansniRacunDto,
  AvansniRacuniClient,
  GetNewAvansniForStopaPdvQuery,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AvansniRacunZaPdvResolver
  extends BaseService
  implements Resolve<AvansniRacunDto> {
  constructor(private _client: AvansniRacuniClient, private _router: Router) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<AvansniRacunDto>
    | Promise<AvansniRacunDto>
    | AvansniRacunDto
    | null {
    return this._client
      .getNewForStopaPdv(
        new GetNewAvansniForStopaPdvQuery({
          vrstaRacuna: route.data['vrstaRacuna'] as VrstaRacuna,
          stopaPdv: this._router.getCurrentNavigation()?.extras?.state?.stopaPDVa,
          strankaId: this._router.getCurrentNavigation()?.extras?.state?.strankaId
        })
      )
      .pipe(
        map((res) => res.data),
        catchError(this.handleError),
        finalize(() => {
          //this._configService.setIsBusy = false;
        })
      );
  }
}
