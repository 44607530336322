import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  GetKorisnikQuery,
  ICreateKorisnikCommand,
  KorisniciClient,
  ResultOfKorisnikTableDto,
  UpdateKorisnikCommand,
} from '@kodit/core/data-api';
import { FormHelper, KorisnikFormService } from '@kodit/core/form-definitions';
import { AlertService, Mode } from '@kodit/core/services';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';

@Component({
  selector: 'kodit-korisnik-form',
  templateUrl: './korisnik-form.component.html',
  styleUrls: ['./korisnik-form.component.scss'],
})
export class KorisnikFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<ICreateKorisnikCommand>;
  mode: Mode = Mode.NEW;
  korisnikEmail: string;
  isEditMode: boolean;
  korisnikId: string;

  constructor(
    private _client: KorisniciClient,
    private _korisnikFS: KorisnikFormService,
    private _formHelper: FormHelper,
    private _alertService: AlertService,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.form = this._korisnikFS.GetKorisnikFormGroup(
      this._dialogConfig?.data?.email
    );
    this.korisnikId = this._dialogConfig.data.id;

    if (this._dialogConfig.data && this._dialogConfig.data.email) {
      this._load(this._dialogConfig.data.email);
      this.mode = Mode.EDIT;
      this.isEditMode = true;
    }

    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.onSave();
      })
    );
  }

  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.onSave();
    }
  }

  onSave() {
    this.form;
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    if (this.mode == Mode.EDIT) {
      this._subs.add(
        this._client
          .update(
            this.korisnikId,
            this.form.value as UpdateKorisnikCommand
          )
          .subscribe((result: ResultOfKorisnikTableDto) => {
            this._alertService.addSuccessMsg(result.messages[0]);
            this._dialogRef.close(result);
          })
      );
    } else {
      // it's new
      // this._subs.add(
      //   this._client.create(this.form.value as CreateKorisnikCommand).subscribe(
      //     (res) => {
      //       if (res.succeeded) {
      //         this._alertService.addSuccessMsg(res.messages[0]);
      //       } else {
      //         this._alertService.addFailedMsg(res.messages[0]);
      //       }
      //       this._dialogRef.close(res);
      //     },
      //     (error) => {
      //       this._subs.add(
      //         error.subscribe((e: string) => {
      //           this._alertService.addFailedMsg(e);
      //         })
      //       );
      //     }
      //   )
      // );
    }
  }

  private _load(korisnikEmail: string) {
    this._subs.add(
      this._client
        .get(
          new GetKorisnikQuery({
            email: korisnikEmail,
          })
        )
        .subscribe((res) => {
          this.form.patchValue(res.data);
          this.form.updateValueAndValidity();
        })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
