import { Injectable } from '@angular/core';
import { Tutorial, TutorialService } from '@kodit/core/services';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RacunTutorialsService implements TutorialService {
  activeTabIdx = 0;
  tutorials$ = new BehaviorSubject<Tutorial[] | []>([]);

  prepareTutorials(currentRoute: string) {
    //Izlazni racuni
    if (currentRoute === '/racuni/izlazni-racuni') {
      this._setTutorialsIzlazni();
      return;
    }
    if (currentRoute === '/racuni/izlazni-racuni/unos/faktura') {
      this._setTutorialsUnosIzlazna();
      return;
    } else if (currentRoute === '/racuni/ulazni-racuni') {
      // ToDo: ...
    } else if (currentRoute === '/racuni/predracuni') {
      // ToDo: ...
    } else if (currentRoute === '/racuni/otpremnice') {
      // ToDo: ...
    } else if (currentRoute === '/racuni/ponudu') {
      // ToDo: ...
    }

    //Ulazni racuni
    // else if (currentRoute === '/racuni/ulazni-racuni') {
    //   tutorial = {
    //     steps: [],
    //     naziv: 'Kako kreirati ulazni racun?',
    //   };
    //   tutorials.push(tutorial);
    //   return tutorials;
    // } else if (currentRoute === '/racuni/predracuni') {
    //   tutorial = {
    //     steps: [],
    //     naziv: 'Kako kreirati predracun?',
    //   };
    //   tutorials.push(tutorial);
    //   return tutorials;
    // } else if (currentRoute === '/racuni/ponude') {
    //   tutorial = {
    //     steps: [],
    //     naziv: 'Kako kreirati ponudu?',
    //   };
    //   tutorials.push(tutorial);
    //   return tutorials;
    // } else if (currentRoute === '/dokumenti/pdv-evidencije') {
    //   tutorial = {
    //     steps: [],
    //     naziv: 'Kako kreirati pdv evidenciju?',
    //   };
    //   tutorials.push(tutorial);
    //   return tutorials;
    // } else if (currentRoute === '/racuni/otpremnice') {
    //   tutorial = {
    //     steps: [],
    //     naziv: 'Kako kreirati otpremnicu?',
    //   };
    //   tutorials.push(tutorial);
    //   return tutorials;
    // } else if (currentRoute === '/racuni/radni-nalozi') {
    //   tutorial = {
    //     steps: [],
    //     naziv: 'Kako kreirati radni nalog?',
    //   };
    //   tutorials.push(tutorial);
    //   return tutorials;
    // }

    this.setTutorials = [];
  }

  getTutorials(currentRoute: string) {
    this.prepareTutorials(currentRoute);
    return this.tutorials$.asObservable();
  }

  set setTutorials(value: Tutorial[] | []) {
    this.tutorials$.next(value);
  }

  setActiveTab(idx: number, currentRoute: string) {
    this.activeTabIdx = idx;
    this.prepareTutorials(currentRoute);
  }

  private _setTutorialsIzlazni(currentRoute: string = '') {
    switch (this.activeTabIdx) {
      case 0:
        this.setTutorials = this._getTutorialsIzlaznaFaktura();
        return;
      // case 1:
      //   this.setTutorials = this._getTutorialsIzlazniAvansniRacun();
      //   return;
      default:
        this.setTutorials = [];
    }
  }

  private _setTutorialsUnosIzlazna() {
    this.setTutorials = this._getTutorialsUnosIzlaznaFaktura();
  }

  private _getTutorialsUnosIzlaznaFaktura() {
    return [
      // unos izlazne fakture preko tabele
      {
        steps: [...this._getStepsUnosFakture()],
        naziv: 'Kako kreirati izlazni racun?',
      },
    ];
  }

  private _getTutorialsIzlaznaFaktura(): Tutorial[] {
    return [
      // unos izlazne fakture preko tabele
      {
        steps: [
          'izlaznaFakturaKreiranjeStep1@racuni/izlazni-racuni',
          ...this._getStepsUnosFakture(),
        ],
        naziv: 'Kako kreirati izlazni racun?',
      },
      // slanje na sef
      {
        steps: ['slanjeNaSefStep'],
        naziv: 'Kako poslati fakturu na SEF?',
      },
      // slanje na mail
      {
        steps: ['slanjeNaEmailStep'],
        naziv: 'Kako poslati faukturu na E-mail?',
      },
      // dupliranje if
      {
        steps: ['pravljenjeDuplikataStep'],
        naziv: 'Kako kreirati duplikat fakture?',
      },
    ];
  }

  private _getStepsUnosFakture(): string[] {
    return [
      'izlaznaFakturaKreiranjeStep2@racuni/izlazni-racuni/unos/faktura',
      'izlaznaFakturaKreiranjeStep3@racuni/izlazni-racuni/unos/faktura',
      'izlaznaFakturaKreiranjeStep4@racuni/izlazni-racuni/unos/faktura',
      'izlaznaFakturaKreiranjeStep5@racuni/izlazni-racuni/unos/faktura',
      'izlaznaFakturaKreiranjeStep6@racuni/izlazni-racuni/unos/faktura',
      'izlaznaFakturaKreiranjeStep7@racuni/izlazni-racuni/unos/faktura',
      'izlaznaFakturaKreiranjeStep8@racuni/izlazni-racuni/unos/faktura',
    ];
  }

  private _getTutorialsIzlazniAvansniRacun(): Tutorial[] {
    return [
      // unos izlazne fakture
      {
        steps: [
          'izlaznaFakturaKreiranjeStep1@racuni/izlazni-racuni',
          'izlaznaFakturaKreiranjeStep2@racuni/izlazni-racuni/unos/avansni-racun',
          'izlaznaFakturaKreiranjeStep3@racuni/izlazni-racuni/unos/avansni-racun',
          'izlaznaFakturaKreiranjeStep4@racuni/izlazni-racuni/unos/avansni-racun',
          'izlaznaFakturaKreiranjeStep5@racuni/izlazni-racuni/unos/avansni-racun',
          'izlaznaFakturaKreiranjeStep6@racuni/izlazni-racuni/unos/avansni-racun',
          'izlaznaFakturaKreiranjeStep7@racuni/izlazni-racuni/unos/avansni-racun',
          'izlaznaFakturaKreiranjeStep8@racuni/izlazni-racuni/unos/avansni-racun',
        ],
        naziv: 'Kako kreirati avansni racun?',
      },
      // slanje na sef
      {
        steps: ['embededVideoStep'],
        naziv: 'Kako poslati avansni racun na SEF?',
      },
      // slanje na mail
      {
        steps: ['embededVideoStep'],
        naziv: 'Kako poslati avansni racun na E-mail?',
      },
    ];
  }

  private _setTutorialsUlazni() {}
}
