import { Component, OnDestroy, OnInit } from '@angular/core';
import { ArhivskaKnjigaClient } from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { ArhivskaKnjigaService } from 'libs/core/moduli/podesavanje/src/lib/arhivska-knjiga/arhivska-knjiga-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-arhivska-postoji-dialog',
  templateUrl: './arhivska-postoji-dialog.component.html',
  styleUrls: ['./arhivska-postoji-dialog.component.scss'],
})
export class ArhivskaPostojiDialogComponent implements OnInit, OnDestroy {
  //** Subs */
  private _subs: Subscription = new Subscription();

  //** Props */
  arhivskaPostoji: boolean = false;

  constructor(
    private _arhivskaClient: ArhivskaKnjigaClient,
    private _alertServise: AlertService,
    private _arhivskaService: ArhivskaKnjigaService
  ) {}

  ngOnInit(): void {}

  selectOption(answer: boolean) {
    this.arhivskaPostoji = answer;
  }

  submitAnwser() {
    this._subs.add(
      this._arhivskaClient
        .setArhivskaKnjigaExists(this.arhivskaPostoji, true)
        .subscribe((res) => {
          this._alertServise.addSuccessMsg(res.messages![0]);
          location.reload();
        })
    );

    this._arhivskaService.setArhivskaKnjigaExisted = this.arhivskaPostoji;
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
