import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IStanjeLageraDto, LageriClient } from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-stanje-magacina',
  templateUrl: './stanje-magacina.component.html',
  styleUrls: ['./stanje-magacina.component.scss'],
})
export class StanjeMagacinaComponent implements OnInit, OnDestroy {
  /** Subs */
  private _loadSub: Subscription = new Subscription();
  private _errorSub: Subscription = new Subscription();

  /** Props */
  stanjaLagera: IStanjeLageraDto[] = [];

  /** I/O */
  @Input() robaId: number;

  constructor(
    private _client: LageriClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._loadSub = this._client.getStanje(this.robaId).subscribe(
      (res) => {
        this.stanjaLagera = res.data.stanjeLageraDtoList;
      },
      (error) => {
        this._errorSub = error.subscribe((x: string) => {
          this._alertService.addFailedMsg(x);
        });
      }
    );
  }

  ngOnDestroy(): void {
    this._loadSub.unsubscribe();
    this._errorSub.unsubscribe();
  }
}
