<form [formGroup]="form">
  <div class="p-fluid">
    <kodit-field
      fieldLabel="Naziv skladišne jedinice"
      fieldLabelFor="naziv"
    ></kodit-field>

    <kodit-text-input
      [formCtrl]="form.controls.naziv"
      formCtrlId="naziv"
    ></kodit-text-input>
  </div>
</form>
