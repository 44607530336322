<p-fileUpload
  #fileUpload
  name="upload"
  [maxFileSize]="maxFileSize"
  [accept]="fileTypes"
  chooseIcon="fas fa-plus"
  [showUploadButton]="false"
  [showCancelButton]="false"
  [customUpload]="true"
  [multiple]="allowMultipleFiles"
  [ngClass]="{
    'show-header': hasFiles,
    'hide-header': !hasFiles
  }"
  (onRemove)="removeFiles($event)"
>
  <!-- HEADER -->
  <ng-template pTemplate="toolbar">
    <ng-container *ngIf="!shouldAutoUpload">
      <div class="p-d-flex p-jc-between _header">
        <div class="_title" *ngIf="fileUploadRef?.files?.length > 1">
          Učitani fajlovi
        </div>
        <div class="_title" *ngIf="fileUploadRef?.files?.length === 1">
          Učitani fajl
        </div>
        <div>
          <button
            pButton
            pRipple
            type="button"
            icon="fas fa-upload"
            label="Otpremi"
            (click)="upload()"
            [disabled]="showProgressBar"
          ></button>
        </div>
      </div>
      <p-progressBar
        *ngIf="showProgressBar"
        mode="indeterminate"
        [style]="{ height: '6px' }"
      ></p-progressBar>
    </ng-container>
    <ng-container *ngIf="shouldAutoUpload">
      <div class="_title" *ngIf="fileUploadRef?.files?.length > 1">
        Odabrani fajlovi
      </div>
      <div class="_title" *ngIf="fileUploadRef?.files?.length === 1">
        Odabrani fajl
      </div>
    </ng-container>
    <kodit-divider></kodit-divider>
  </ng-template>

  <!-- KADA NEMA FAJLOVA -->
  <ng-template pTemplate="content" let-files>
    <div *ngIf="files.length === 0">
      <ng-container *ngIf="allowMultipleFiles">
        <button
          pButton
          pRipple
          type="button"
          icon="fas fa-plus"
          class="p-button-sm p-button-rounded p-button-outlined"
          (click)="fileInputRef.click()"
        ></button>
        <input
          [id]="fileInputId + '-multiple'"
          #fileInputRef
          multiple
          type="file"
          [accept]="fileTypes"
          style="display: none"
          (input)="fileSelected($event)"
        />
      </ng-container>

      <ng-container *ngIf="!allowMultipleFiles">
        <button
          pButton
          pRipple
          type="button"
          icon="fas fa-plus"
          class="p-button-sm p-button-rounded p-button-outlined"
          (click)="fileInputRef.click()"
        ></button>
        <input
          [id]="fileInputId + '-single'"
          #fileInputRef
          type="file"
          [accept]="fileTypes"
          style="display: none"
          (input)="fileSelected($event)"
        />
      </ng-container>
      <div class="p-mt-3">
        {{ descriptionText }}
      </div>
      <!-- dodati poruku o gresci za size... -->
      <small *ngIf="fileSizeExceeded" class="p-invalid">{{ errorMsg }}</small>
    </div>
  </ng-template>

  <!-- Prikaz fajlova -->
  <ng-template let-file let-i="index" pTemplate="file">
    <div class="ui-fileupload-row p-d-flex p-jc-between p-p-2">
      <div class="file-name">{{ file.name }}</div>
      <div>{{ fileUpload.formatSize(file.size) }}</div>
      <div>
        <button
          type="button"
          icon="fa-regular fa-xmark"
          pButton
          (click)="removeFiles(i)"
        ></button>
      </div>
    </div>
  </ng-template>
</p-fileUpload>
