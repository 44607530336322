import { Injectable } from '@angular/core';
import {
  StrankaDto,
} from '@kodit/core/data-api';
import { BaseService } from '@kodit/core/services';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StrankaMultiselectService extends BaseService {

  constructor() {
    super();
  }

  strankeCenovnika$ = new BehaviorSubject<StrankaDto[]>([]);
  
  set setStrankeCenovnika(value: StrankaDto[]) {
    this.strankeCenovnika$.next(value);
  }
  get getStrankeCenovnika() {
    return this.strankeCenovnika$.asObservable();
  }

}