import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  ICreateMojaFirmaCommand,
  IPaketDropdownDto,
  PackageType,
} from '@kodit/core/data-api';
import { RegisterService } from '../../../../../auth/src/lib/register/register.service';
import {
  AbstractControl,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@kodit/core/services';

interface Packages {
  besplatno: boolean;
  eFakture: boolean;
  maloprodaja: boolean;
  fiskalnaKasa: boolean;
  veleprodaja: boolean;
  arhivaIDelovodnik: boolean;
  proizvodnja: boolean;
  finansije: boolean;
}

/** Constants */
const CENA_ZA_DODATNOG_KORISNIKA: number = 5;
const CENA_ZA_FISKALNU_KASU: number = 20;
const CENA_ZA_DODATNU_KOLICINU_PROSTORA: number = 15;

@Component({
  selector: 'kodit-odabir-paketa-form',
  templateUrl: './odabir-paketa-form.component.html',
  styleUrls: ['./odabir-paketa-form.component.scss'],
})
export class OdabirPaketaFormComponent implements OnInit, OnDestroy {
  /** Form */
  form: FormGroupTypeSafe<ICreateMojaFirmaCommand>;
  packagesForm: FormGroupTypeSafe<Packages>;
  basePackages: FormArray;
  additionalPackages: FormArray;
  /** Odabir paketa props */
  allPackages: IPaketDropdownDto[] = [];
  selectedPackages: IPaketDropdownDto[] = [];
  totalPackagesPrice: number = 0;
  /** Conditional props */
  shouldDisableVeleprodaja: boolean = false;
  shouldDisableBesplatno: boolean = false;
  shouldDisableFakture: boolean = false;
  shouldDisableMaloprodaja: boolean = false;
  shouldDisableFiskalnaKasa: boolean = false;
  shouldDisableArhiva: boolean = false;
  shouldLoadFromQueryParameters: boolean = false;
  /** Route parameters (ako pakete dobijamo sa sajta) */
  pckgArray: number[] = [];
  numOfUsers: number = 0;
  numOfKasa: number = 0;
  numOfSpace: number = 0;
  /** Subs */
  private _subs: Subscription = new Subscription();

  constructor(
    private _registerService: RegisterService,
    private _fb: FormBuilderTypeSafe,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.form = this._registerService.getForm;
    this.basePackages = this._registerService.getBasePackages;
    this.additionalPackages = this._registerService.getAdditionalPackages;
    this._initPackagesForm();
    this._initPackages();

    //get query parameters
    this._getOdabirPaketaQueryParameters();

    //patch value if edit
    if (this.shouldLoadFromQueryParameters) {
      this._patchValueFromQueryParameters();
    } else {
      this._patchValueOnEdit();
    }

    this._subs.add(
      this.packagesForm.valueChanges.subscribe((res) => {
        this._updateSelectedPackages(res);
        this._shouldDisablePackages();
        this.calculateTotalPackagesPrice();
      })
    );

    this._subs.add(
      this.form.controls.brojFiskalnihKasa.valueChanges.subscribe(() => {
        this.calculateTotalPackagesPrice();
      })
    );

    this._subs.add(
      this.form.controls.brojIstovremenoAktivnihKorisnika.valueChanges.subscribe(
        () => {
          this.calculateTotalPackagesPrice();
        }
      )
    );

    this._subs.add(
      this.form.controls.kolicinaProstora.valueChanges.subscribe(() => {
        this.calculateTotalPackagesPrice();
      })
    );
  }

  routeToNextStep() {
    this._patchValuesToForm();

    if (!this._validateSelectedPackages()) {
      this._alertService.addWarnMsg(
        'Potrebno je odabrati bar jedan osnovni paket!'
      );
      return;
    }

    this._router
      .navigate(['/autentikacija', 'registracija', 'podaci-kompanije'])
      .then();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  private _updateSelectedPackages(packages: Packages) {
    this.selectedPackages = [];

    const findPackage = (type: PackageType) =>
      this.allPackages.find((x) => x.type === type);

    if (!packages.eFakture && !packages.veleprodaja) {
      this.form.controls.brojIstovremenoAktivnihKorisnika.patchValue(0);
    }

    if (packages.besplatno) {
      this.selectedPackages.push(findPackage(PackageType.DZABE));
      return;
    }

    if (packages.eFakture) {
      this.selectedPackages.push(findPackage(PackageType.FAKTURISANJE));
    }

    if (packages.proizvodnja) {
      this.selectedPackages.push(findPackage(PackageType.PROIZVODNJA));
    }

    if (packages.arhivaIDelovodnik) {
      this.selectedPackages.push(
        findPackage(PackageType.ARHIVSKA_KNJIGA_I_DELOVODNIK)
      );
    } else {
      this.form.controls.kolicinaProstora.patchValue(0);
    }

    if (packages.finansije) {
      this.selectedPackages.push(findPackage(PackageType.FINANSIJE));
    }

    if (packages.maloprodaja) {
      this.selectedPackages.push(findPackage(PackageType.MALOPRODAJA));
    } else {
      this.form.controls.brojFiskalnihKasa.patchValue(0);
    }

    if (packages.fiskalnaKasa) {
      this.selectedPackages.push(findPackage(PackageType.FISKALNA_KASA));
    } else {
      this.form.controls.brojFiskalnihKasa.patchValue(0);
    }

    if (packages.veleprodaja) {
      this.selectedPackages.push(findPackage(PackageType.VELEPRODAJA));
    }
  }

  private calculateTotalPackagesPrice() {
    this.totalPackagesPrice =
      this.form.controls.brojFiskalnihKasa.value * CENA_ZA_FISKALNU_KASU +
      this.form.controls.brojIstovremenoAktivnihKorisnika.value *
        CENA_ZA_DODATNOG_KORISNIKA +
      this.form.controls.kolicinaProstora.value *
        CENA_ZA_DODATNU_KOLICINU_PROSTORA +
      this.selectedPackages.map((x) => x.price).reduce((x, y) => x + y, 0);
  }

  private _patchValueOnEdit() {
    this.selectedPackages = [
      ...this.form.controls.basePackages.value,
      ...this.form.controls.additionalPackages.value,
    ];

    this._patchPackagesFormFromSelectedPackages();
    this._shouldDisablePackages();
    this.calculateTotalPackagesPrice();
  }

  private _patchValueFromQueryParameters() {
    const packages = this._getPackagesFromPckgArray();
    this._updateSelectedPackages(packages);
    this._patchPackagesFormFromSelectedPackages();
    this._shouldDisablePackages();
    this.form.controls.brojFiskalnihKasa.patchValue(this.numOfKasa);
    this.form.controls.kolicinaProstora.patchValue(this.numOfSpace);
    this.form.controls.brojIstovremenoAktivnihKorisnika.patchValue(
      this.numOfUsers
    );
    this.calculateTotalPackagesPrice();
    this.routeToNextStep();
  }

  private _patchValuesToForm() {
    this.additionalPackages.clear();
    this.basePackages.clear();
    this.selectedPackages.forEach((x) => {
      x.type === PackageType.PROIZVODNJA || x.type === PackageType.FINANSIJE
        ? this.additionalPackages.push(
            this._registerService.GetPackageFormGroup(x)
          )
        : this.basePackages.push(this._registerService.GetPackageFormGroup(x));
    });
  }

  private _patchPackagesFormFromSelectedPackages() {
    const packageTypes = {
      [PackageType.DZABE]: 'besplatno',
      [PackageType.FAKTURISANJE]: 'eFakture',
      [PackageType.MALOPRODAJA]: 'maloprodaja',
      [PackageType.FISKALNA_KASA]: 'fiskalnaKasa',
      [PackageType.VELEPRODAJA]: 'veleprodaja',
      [PackageType.ARHIVSKA_KNJIGA_I_DELOVODNIK]: 'arhivaIDelovodnik',
      [PackageType.PROIZVODNJA]: 'proizvodnja',
      [PackageType.FINANSIJE]: 'finansije',
    };

    this.selectedPackages.forEach((item) => {
      const packageType = packageTypes[item.type];
      if (packageType && this.packagesForm.controls[packageType]) {
        this.packagesForm.controls[packageType].patchValue(true);
      }
    });
  }

  private _getOdabirPaketaQueryParameters() {
    this._activatedRoute.queryParams.subscribe((params) => {
      if (!params['pckg']) {
        this.shouldLoadFromQueryParameters = false;
        return;
      }

      if (Array.isArray(params['pckg'])) {
        this.pckgArray = params['pckg'].map((value: string) =>
          parseInt(value, 10)
        );
      } else {
        this.pckgArray.push(parseInt(params['pckg'], 10));
      }

      this.numOfUsers = parseInt(params['userCount'], 10) || 0;
      this.numOfKasa = parseInt(params['registerCount'], 10) || 0;
      this.numOfSpace = parseInt(params['storage'], 10) || 0;
      this.shouldLoadFromQueryParameters = true;
    });
  }

  private _getPackagesFromPckgArray(): Packages {
    const updatedPackages: Packages = {
      besplatno: false,
      eFakture: false,
      maloprodaja: false,
      fiskalnaKasa: false,
      veleprodaja: false,
      finansije: false,
      proizvodnja: false,
      arhivaIDelovodnik: false,
    };
    this.pckgArray.forEach((x) => {
      switch (x) {
        case PackageType.DZABE:
          updatedPackages.besplatno = true;
          break;
        case PackageType.MALOPRODAJA:
          updatedPackages.maloprodaja = true;
          break;
        case PackageType.FISKALNA_KASA:
          updatedPackages.fiskalnaKasa = true;
          break;
        case PackageType.VELEPRODAJA:
          updatedPackages.veleprodaja = true;
          break;
        case PackageType.ARHIVSKA_KNJIGA_I_DELOVODNIK:
          updatedPackages.arhivaIDelovodnik = true;
          break;
        case PackageType.FAKTURISANJE:
          updatedPackages.eFakture = true;
          break;
        case PackageType.PROIZVODNJA:
          updatedPackages.proizvodnja = true;
          break;
        case PackageType.FINANSIJE:
          updatedPackages.finansije = true;
          break;
      }
    });
    return updatedPackages;
  }

  private _shouldDisablePackages() {
    // logicka grupa veleprodaja & maloprodaja
    this.shouldDisableVeleprodaja = this.selectedPackages.some((el) =>
      el.type == PackageType.DZABE ||
      el.type == PackageType.FISKALNA_KASA ||
      el.type == PackageType.FAKTURISANJE
    );
    this.shouldDisableMaloprodaja = this.selectedPackages.some(
      (el) =>
        el.type == PackageType.DZABE ||
        el.type == PackageType.FISKALNA_KASA ||
        el.type == PackageType.FAKTURISANJE
    );
    // logicka grupa fiskalna kasa & fakturisanje
    this.shouldDisableFiskalnaKasa = this.selectedPackages.some(
      (el) =>
        el.type == PackageType.DZABE ||
        el.type == PackageType.MALOPRODAJA ||
        el.type == PackageType.VELEPRODAJA
    );
    this.shouldDisableFakture = this.selectedPackages.some(
      (el) =>
        el.type == PackageType.DZABE ||
        el.type == PackageType.MALOPRODAJA ||
        el.type == PackageType.VELEPRODAJA
    );

    this.shouldDisableArhiva = this.selectedPackages.some(
      (el) => el.type == PackageType.DZABE
    );
    this.shouldDisableBesplatno = this.selectedPackages.some(
      (el) => el.type != PackageType.DZABE
    );
  }

  private _initPackages() {
    this.allPackages = [
      {
        name: 'Besplatno',
        type: PackageType.DZABE,
        price: 0,
      } as IPaketDropdownDto,
      {
        name: 'eFakture',
        type: PackageType.FAKTURISANJE,
        price: 20,
      } as IPaketDropdownDto,
      {
        name: 'Maloprodaja',
        type: PackageType.MALOPRODAJA,
        price: 20,
      } as IPaketDropdownDto,
      {
        name: 'Fiskalna kasa',
        type: PackageType.FISKALNA_KASA,
        price: 15,
      } as IPaketDropdownDto,
      {
        name: 'Veleprodaja',
        type: PackageType.VELEPRODAJA,
        price: 25,
      } as IPaketDropdownDto,
      {
        name: 'Proizvodnja',
        type: PackageType.PROIZVODNJA,
        price: 5,
      } as IPaketDropdownDto,
      {
        name: 'Finansije',
        type: PackageType.FINANSIJE,
        price: 10,
      } as IPaketDropdownDto,
      {
        name: 'Arhiva i delovodnik',
        type: PackageType.ARHIVSKA_KNJIGA_I_DELOVODNIK,
        price: 20,
      } as IPaketDropdownDto,
    ];
  }

  private _initPackagesForm() {
    this.packagesForm = this._fb.group<Packages>({
      besplatno: new FormControl(false, {
        validators: [Validators.nullValidator],
      }),
      eFakture: new FormControl(false, {
        validators: [Validators.nullValidator],
      }),
      maloprodaja: new FormControl(false, {
        validators: [Validators.nullValidator],
      }),
      fiskalnaKasa: new FormControl(false, {
        validators: [Validators.nullValidator],
      }),
      veleprodaja: new FormControl(false, {
        validators: [Validators.nullValidator],
      }),
      arhivaIDelovodnik: new FormControl(false, {
        validators: [Validators.nullValidator],
      }),
      proizvodnja: new FormControl(false, {
        validators: [Validators.nullValidator],
      }),
      finansije: new FormControl(false, {
        validators: [Validators.nullValidator],
      }),
    });
  }

  private _validateSelectedPackages(): boolean {
    return !(
      this.basePackages.length == 0 ||
      (this.additionalPackages.length > 0 && this.basePackages.length == 0)
    );
  }

  handlePackageHeaderClick(control: AbstractControl, isDisabled: boolean) {
    if (isDisabled) {
      return;
    }
    control.patchValue(!control.value);
  }
}
