import { Component, OnInit } from '@angular/core';
import {
  GetLageriTableQuery,
  IGetLageriTableQuery,
  TipRacuna,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { LagerFormService } from '@kodit/core/form-definitions';
import { AdvancedFilter } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { LagerFilterService } from './lager-filter.service';
import { Store } from '@ngrx/store';
import { setFilter } from '../../racun/state/filters/filter.actions';

@Component({
  selector: 'kodit-lager-filter',
  templateUrl: './lager-filter.component.html',
  styleUrls: ['./lager-filter.component.scss'],
})
export class LagerFilterComponent
  implements OnInit, AdvancedFilter<IGetLageriTableQuery> {
  /** Props */
  filterData: IGetLageriTableQuery;
  availableFilters?: {};
  form: FormGroupTypeSafe<IGetLageriTableQuery>;

  constructor(
    private _formService: LagerFormService,
    private _filterService: LagerFilterService,
    private _storage: Store
  ) {}

  ngOnInit(): void {
    this.form = this._formService.GetLagerTableRequest(this.filterData);
  }

  onFilterSubmit() {
    this._storage.dispatch(
      setFilter({
        key: 'LAGERI',
        filter: JSON.stringify(this.form.value),
      })
    );
  }

  onFilterHide(shouldReset: boolean) {
    this._filterService.setAdvanceFilterSubmitted = {
      filterData: shouldReset ? new GetLageriTableQuery() : this.form.value,
      shouldApplyFilter: shouldReset,
    };
  }
}
