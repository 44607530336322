<span class="p-input-icon-right date-box">
  <i class="far fa-calendar-alt"></i>
  <p-calendar
    [inputId]="formCtrlId"
    [appendTo]="parent"
    [numberOfMonths]="numberOfMonths"
    [formControl]="formCtrl"
    [inputStyleClass]="formCtrl.touched && !formCtrl.valid ? 'p-invalid' : ''"
    [disabledDates]="invalidDates"
    [maxDate]="maxDate"
    [inline]="inline"
    [placeholder]="ctrlPlaceholder"
    [showTime]="showTime"
    [showSeconds]="showSeconds"
    [disabled]="disabled"
  ></p-calendar>
</span>
<kodit-error-info-required [formCtrl]="formCtrl"></kodit-error-info-required>
