import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import {
  AvansniRacunDto,
  AvansniRacuniClient,
  GetNewAvansniFromPredracunUplataQuery,
} from '@kodit/core/data-api';
import { BaseService } from '@kodit/core/services';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AvansniRacunIzPredracunUplateResolver
  extends BaseService
  implements Resolve<AvansniRacunDto> {
  constructor(private _client: AvansniRacuniClient, private _router: Router) {
    super();
  }

  resolve(
  ):
    | AvansniRacunDto
    | Observable<AvansniRacunDto>
    | Promise<AvansniRacunDto>
    | null {
    const body = {
      predracunList: this._router.getCurrentNavigation().extras.state
        .requestList,
      transakcijaIzvodaId: this._router.getCurrentNavigation().extras.state
        .transakcijaIzvodaId,
    };
    return this._client
      .getAvansniForPredracunUplata(body as GetNewAvansniFromPredracunUplataQuery)
      .pipe(
        map((x) => x.data),
        catchError(this.handleError),
        finalize(() => {
          ///
        })
      );
  }
}
