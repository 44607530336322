<div class="p-flex-column">
  <div class="p-fluid">
    <kodit-field fieldLabel="Stari račun">
      <kodit-referentni-stari-racun-radiobutton
        [formCtrl]="form.controls.jeStariRacun"
      >
      </kodit-referentni-stari-racun-radiobutton>
    </kodit-field>
    <kodit-field fieldLabel="Broj referentnog računa" *ngIf="!form.controls.jeStariRacun?.value">
      <kodit-referentni-racun-fiskalnog-racuna-autocomplete
        [ctrlPlaceholder]="'Broj referentnog računa'"
        formCtrlId="referentDocumentNumber"
        (onChange)="handleIzaberiReferentniRacun($event)"
      ></kodit-referentni-racun-fiskalnog-racuna-autocomplete>
    </kodit-field>
    <kodit-field fieldLabel="Broj starog referentnog računa" *ngIf="form.controls.jeStariRacun?.value">
      <kodit-text-input
        [ctrlPlaceholder]="placeholder"
        [formCtrl]="form.controls.referentDocumentNumber"
      > </kodit-text-input>
    </kodit-field>
    <kodit-field fieldLabel="Datum i vreme referentnog računa">
      <kodit-calendar
        class="p-fluid"
        formCtrlId="startDate"
        [formCtrl]="form.controls.referentDocumentDT!"
        [showTime]="true"
        [showSeconds]="true"
      ></kodit-calendar>
    </kodit-field>
  </div>
</div>
