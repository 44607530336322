import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IzvestajiClient } from '@kodit/core/data-api';
import { GodinaRacunaDropdownService } from '@kodit/core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-ios-izvestaj-pdf',
  templateUrl: './ios-izvestaj-pdf.component.html',
  styleUrls: ['./ios-izvestaj-pdf.component.scss']
})
export class IosIzvestajPdfComponent implements OnInit {
  private _subs: Subscription = new Subscription();

  /** Props */
  pdfFileUrl: string;
  pdfFileName: string;
  iosInnerHtml: SafeHtml;
  isPdfAvailable: boolean = false;

  constructor(
    private _client: IzvestajiClient,
    private domSanitazer: DomSanitizer,
    private _dropdownService: GodinaRacunaDropdownService
  ) {}

  ngOnInit(): void {
    this._loadIosPdf();

    this._subs.add(
      this._dropdownService.getForm().valueChanges.subscribe((res) => {
        this._loadIosPdf();
      })
    );
  }

  private _loadIosPdf() {
    this._subs.add(
      this._client.getIosPdf().subscribe((res) => {
        this.pdfFileUrl = URL.createObjectURL(res.data);
        this.pdfFileName = (res.data as any).fileName;
        this.iosInnerHtml = this.domSanitazer.bypassSecurityTrustHtml(
          "<object  data='" +
            this.pdfFileUrl +
            "' type='application/pdf' class='embed-responsive-item' >" +
            'Object ' +
            this.pdfFileUrl +
            ' failed' +
            '</object>'
        );
        this.isPdfAvailable = true;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
