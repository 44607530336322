import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'kodit-odabir-paketa',
  templateUrl: './odabir-paketa.component.html',
  styleUrls: ['./odabir-paketa.component.scss']
})
export class OdabirPaketaComponent implements OnInit {

  constructor(private _router : Router) { }

  ngOnInit(): void {
  }
}
