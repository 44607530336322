import { Component, OnInit } from '@angular/core';
import { Message } from 'primeng/api';

@Component({
  selector: 'kodit-pretplata-page',
  templateUrl: './pretplata-page.component.html',
  styleUrls: ['./pretplata-page.component.scss'],
})
export class PretplataPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
