<div class="folder-info-card p-d-flex p-flex-column flex-1 p-jc-between" (click)="onClick()">
  <div class="p-d-flex flex-gap-1 ikonica p-ai-center">
    <i class="fa-solid fa-folder-closed"></i> 
    <div class="folder-title p-text-truncate">
      {{datoteka.naziv}}
    </div>
  </div>
  <div class="p-d-flex p-flex-column">
    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-between p-ai-center p-mt-4">
      <div class="folder-size">{{ brojFajlova }}</div>
    </div>
  </div>
</div>

