import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileManagerPageComponent } from './file-manager-page/file-manager-page.component';

/** Moduli */
import { FileManagerRoutingModule } from './file-manager-routing';
import { FolderMenuComponent } from './folder-menu/folder-menu.component';
import { FolderInfoComponent } from './folder-info/folder-info.component';
import { CoreSharedModule } from 'libs/core/shared/src';
import { FolderMenuItemComponent } from './folder-menu/folder-menu-item/folder-menu-item.component';

import { FileManagerFilesFormComponent } from './file-manager-files-form/file-manager-files-form-component.component';
import { DostupanProstorCardComponent } from './dostupan-prostor-card/dostupan-prostor-card.component';
import { MenuModule } from 'primeng/menu';
import { UnosDatotekeFormComponent } from './file-manager-page/unos-datoteke-form/unos-datoteke-form.component';
import { DropdownModule } from 'primeng/dropdown';
import { DatotekaTreeComponent } from './file-manager-page/datoteka-tree/datoteka-tree/datoteka-tree.component';
import { TreeModule } from 'primeng/tree';
import { DokumentPreviewComponent } from './dokument-preview/dokument-preview.component';
import { FolderPreviewComponent } from './folder-preview/folder-preview.component';
import { FolderInfoCardComponent } from './folder-preview/folder-info-card/folder-info-card.component';
import { DelovodnikInfoCardComponent } from './folder-preview/delovodnik-info-card/delovodnik-info-card.component';
import { DokumentInfoCardComponent } from './folder-preview/dokument-info-card/dokument-info-card.component';

@NgModule({
  imports: [
    CommonModule,
    CoreSharedModule,
    FileManagerRoutingModule,
    MenuModule,
    DropdownModule,
    TreeModule,
  ],
  declarations: [
    FileManagerPageComponent,
    FolderMenuComponent,
    FolderInfoComponent,
    FolderMenuItemComponent,
    FolderInfoCardComponent,
    FileManagerFilesFormComponent,
    DostupanProstorCardComponent,
    UnosDatotekeFormComponent,
    DatotekaTreeComponent,
    DokumentPreviewComponent,
    FolderPreviewComponent,
    DelovodnikInfoCardComponent,
    DokumentInfoCardComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModuliFileManagerModule {}
