import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ArhivskaKnjigaClient,
  IFizickaLokacijaTreeDto,
} from '@kodit/core/data-api';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { SelectItem, TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';
import { FiziceLokacijeFormComponent } from '../../fizicke-lokacije-form/fizice-lokacije-form/fizice-lokacije-form.component';
import { AlertService, Mode } from '@kodit/core/services';
import { ArhivskaKnjigaService } from '../../../arhivska-knjiga-service';

@Component({
  selector: 'kodit-fizicke-lokacije-tree',
  templateUrl: './fizicke-lokacije-tree.component.html',
  styleUrls: ['./fizicke-lokacije-tree.component.scss'],
})
export class FizickeLokacijeTreeComponent implements OnInit, OnDestroy {
  /** Subs */
  private _sub: Subscription = new Subscription();

  /** Config */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  fizickeLokacije: TreeNode<any>[] = [];
  layout: string = 'horizontal';
  emptyMessage: string = 'Nema lokacija u bazi';
  selectedLokacija: TreeNode;
  editEnabled: boolean;
  deleteEnabled: boolean;
  displayType: string = 'horizontal';
  displayTypes: SelectItem[];
  nodeId: number;

  constructor(
    private _client: ArhivskaKnjigaClient,
    private _dialogService: DynamicDialogService,
    private _alertService: AlertService,
    private _arhivskaService: ArhivskaKnjigaService
  ) {}

  ngOnInit(): void {
    this._loadTree();
    this.displayTypes = [
      { value: 'vertical', label: 'Vertikalni prikaz' },
      { value: 'horizontal', label: 'Horizontalni prikaz' },
    ];
  }

  private _loadTree() {
    this._sub.add(
      this._client.getForTree().subscribe((res) => {
        const temp: TreeNode<any>[] = [];
        res.data.fizickeLokacijeTree.forEach((f) =>
          temp.push(this._setLokacijeTree(f))
        );
        this.fizickeLokacije.push({
          label: 'Početak',
          children: temp,
          icon: 'fas fa-hashtag',
          expanded: true,
          selectable: false,
        });

        if (res.data.fizickeLokacijeTree.length !== 0) {
          this.arhivskaConfig();
        }
      })
    );
  }

  private arhivskaConfig() {
    this._arhivskaService.setPotvrdiIZavrsiEnabled = true;
    this._arhivskaService.setStep01Completed = true;
    this._arhivskaService.setStep02Completed = true;
    this._arhivskaService.setStep03Completed = true;
  }

  nodeSelect(event: any) {
    this.editEnabled = true;
    this.deleteEnabled = true;
    this.nodeId = event.node.data.id;

    event.node.children.length > 0
      ? (this.deleteEnabled = false)
      : (this.deleteEnabled = true);
  }

  nodeUnselect() {
    this.editEnabled = false;
    this.deleteEnabled = false;
    this.nodeId = null;
  }

  onCreate() {
    this.dialogConfig.header = 'Unos fizičke lokacije';
    this.dialogConfig.data = { mode: Mode.NEW, id: this.nodeId };
    this._openDialog(this.dialogConfig);
  }

  onEdit() {
    this.dialogConfig.header = 'Izmena lokacije';
    this.dialogConfig.data = { mode: Mode.EDIT, id: this.nodeId };
    this._openDialog(this.dialogConfig);
  }

  onDelete() {
    this._sub.add(
      this._client.delete(this.nodeId).subscribe((res) => {
          this._alertService.addSuccessMsg(res.messages[0]);
          location.reload();
      })
    );
  }

  expandAll() {
    this.fizickeLokacije.forEach((node) => {
      this._expandRecursive(node, true);
    });
  }

  collapseAll() {
    this.fizickeLokacije.forEach((node) => {
      this._expandRecursive(node, false);
    });
  }

  private _expandRecursive(node: TreeNode, isExpand: boolean) {
    if (node.children?.length > 0) {
      node.expanded = isExpand;
      node.children.forEach((childNode) => {
        this._expandRecursive(childNode, isExpand);
      });
    }
  }

  private _setLokacijeTree(
    lokacijaTree: IFizickaLokacijaTreeDto
  ): TreeNode<IFizickaLokacijaTreeDto> {
    let result: TreeNode<IFizickaLokacijaTreeDto> = {
      label: lokacijaTree.naziv,
      children: [],
      data: lokacijaTree,
      selectable: true,
    };

    if (lokacijaTree.childrenDto.length > 0) {
      result.icon = 'fas fa-tags';
      lokacijaTree.childrenDto.forEach((x) =>
        result.children.push(this._setLokacijeTree(x))
      );
    } else {
      result.icon = 'fas fa-tag';
    }

    return result;
  }

  private _openDialog(config: DynamicDialogConfig) {
    const ref = this._dialogService.open(FiziceLokacijeFormComponent, config);

    this._sub.add(
      ref.onClose.subscribe((dto: any) => {
        if (dto) {
          this.fizickeLokacije = [];
          this._loadTree();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
