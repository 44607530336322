<kodit-info-container p-m-5>
  <div body-full>
    <div *ngIf="showKoraciKomponent" class="p-mb-3 koraci-section">
      <div class="p-d-flex p-jc-between">
        <div>
          <div class="card-title">Potrebni koraci</div>
          <p class="card-text">
            Odradite sve potrebne korake kako bi ste svu potrebnu dokumentaciju
            predali nadležnom arhivu
          </p>
        </div>

        <div>
          <!-- <kodit-button
            btnLabel="Pogledaj video uputstva"
            btnClass="p-button-link"
          ></kodit-button> -->

          <kodit-button
            btnLabel="Označi kao urađeno"
            btnIcon="fas fa-circle-check"
            btnClass="p-button-success p-mt-3"
            [isBtnDisabled]="!uradjenoEnabled"
            (btnClicked)="oznaciKaoUradjenoHandle()"
          ></kodit-button>
        </div>
      </div>

      <div class="koraci-container p-mb-5">
        <!-- Step 01 -->
        <div class="k-card p-mr-3 p-col" card>
          <div>
            <div class="step">Korak 1</div>
            <p class="step-text">Preuzmite potrebna dokumenta</p>
            <kodit-button
              btnLabel="Preuzmi"
              btnIcon="fas fa-download"
              (btnClicked)="openArhivskaDokumentaDialog()"
            ></kodit-button>
          </div>

          <i class="fas fa-download icon"></i>
        </div>

        <!-- Step 02 -->
        <div class="k-card p-mr-3 p-col" card>
          <div>
            <div class="step">Korak 2</div>
            <p class="step-text">Poslati mejl nadležnom arhivu</p>
            <kodit-button
              btnLabel="Pronađi arhiv"
              btnIcon="fas fa-print-magnifying-glass"
              (btnClicked)="preusmeriNaAdresu()"
            ></kodit-button>
          </div>

          <i class="fas fa-print-magnifying-glass icon"></i>
        </div>

        <!-- Step 03 -->
        <div class="k-card p-col" card>
          <div>
            <div class="step">Korak 3</div>
            <p class="step-text">Učitaj izdato rešenje</p>
            <p-fileUpload
              #fileUpload
              mode="basic"
              [customUpload]="true"
              chooseLabel="Učitaj"
              chooseIcon="pi pi-upload"
              (uploadHandler)="uploadResenjeFile($event)"
            ></p-fileUpload>
          </div>

          <i class="fas fa-up-from-bracket icon"></i>
        </div>
      </div>
    </div>

    <kodit-arhivska-knjiga-table></kodit-arhivska-knjiga-table>
  </div>
</kodit-info-container>
