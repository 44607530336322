<div class="step1-container">
  <div class="steps-title p-mb-5">KORAK 01</div>

  <h2>Lista kategorija dokumentarnog materijala</h2>
  <p>
    Ubacite postojeće kategorije za koje ste dobili rešenje poreske uprave ili
    odaberite predefinisane kategorije koje smo pripremili za Vas.
  </p>

  <h3>Izabrati:</h3>

  <div class="buttons-container">
    <div class="button" (click)="importKategorijeManual()">
      <div class="fa-solid fa-upload icon"></div>
      <p>Importuj postojeće kategorije</p>
    </div>

    <div class="button" (click)="importKategorijeAuto()">
      <div class="fa-solid fa-wand-magic-sparkles icon"></div>
      <p>Automatsko popunjavanje kategorija</p>
    </div>
  </div>

  <!-- Obavestenje za automatsko popunjavanje -->
  <div *ngIf="showObavestenjeBox" class="container-obavestenje p-mt-5">
    <div class="container-title p-m-3">
      <i class="fa-solid fa-circle-info info-icon p-p-2"></i>
      <div class="p-ml-3">
        <div class="title">Obaveštenje</div>
        <p>
          Ukoliko se vaše prethodne kategorije razlikuju od predefinisanih,
          odabirom automatskog popunjavanja u obavezi ste da pošaljete nove
          kategorije nadležnom arhivu. Proces je vrlo jednostavan, a svu
          potrebnu dokumentaciju aplikacija generiše za Vas.
        </p>
      </div>
    </div>

    <div class="p-d-flex p-p-4 koraci-container">
      <!-- Korak01 -->
      <div card class="koraci-card">
        <div class="koraci-title">Korak 1</div>
        <p class="koraci-text">Preuzmi potrebna dokumenta</p>
      </div>

      <!-- Korak02 -->
      <div card class="koraci-card">
        <div class="koraci-title">Korak 2</div>
        <p class="koraci-text">Poslati mejl nadležnom arhivu</p>
      </div>
      <!-- Korak03 -->
      <div card class="koraci-card">
        <div class="koraci-title">Korak 3</div>
        <p class="koraci-text">Učitaj izdato rešenje</p>
      </div>
    </div>
  </div>

  <!-- Importovanje postojecih kategorija -->
  <div *ngIf="showImportComponent">
    <kodit-import-kategorije></kodit-import-kategorije>
  </div>
</div>
