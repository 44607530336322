import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray} from "@angular/forms";
import {VozilaClient, VoziloDropdownDto} from "@kodit/core/data-api";
import {SelectItem} from "primeng/api";
import {Subscription} from "rxjs";

@Component({
  selector: 'kodit-vozilo-multiselect',
  templateUrl: './vozilo-multiselect.component.html',
  styleUrls: ['./vozilo-multiselect.component.scss']
})
export class VoziloMultiselectComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs : Subscription = new Subscription();

  /** Props */
  items: SelectItem[];
  selectedItems: VoziloDropdownDto[] = [];

  /** I/O */
  @Input() voziloFormArray: FormArray | AbstractControl;
  @Output() selectedVozila = new EventEmitter<VoziloDropdownDto[]>();

  constructor(private _client: VozilaClient) {}
  ngOnInit(): void {
    this._loadVozila();
  }

  private _loadVozila() {
    console.log(this.voziloFormArray.value);
    this.selectedItems = [...this.voziloFormArray.value.map((x) => x)];

    this._subs.add(
      this._client.getVozilaDropdown().subscribe((result) => {
        if (result.data) {
          this.items = result.data.map((b) => ({
            label: b.registarskaOznaka,
            value: b,
          }));
        }
      })
    );
  }

  selectedItemsOnChange() {
    this.selectedVozila.next(this.selectedItems);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
