<p-inputNumber
  *ngIf="isDisabled"
  [inputId]="formCtrlId"
  [formControl]="formCtrl"
  [suffix]="suffix"
  [prefix]="prefix"
  [maxlength]="ctrlMaxLength"
  [min]="ctrlMinValue"
  [showButtons]="showButtons"
  [buttonLayout]="buttonLayout"
  [spinnerMode]="spinnerMode"
  [decrementButtonClass]="decrementButtonClass"
  [incrementButtonClass]="incrementButtonClass"
  [incrementButtonIcon]="incrementButtonIcon"
  [decrementButtonIcon]="decrementButtonIcon"
  [useGrouping]="ctrlUseGrouping"
  locale="sr-Latn"
  autocomplete="off"
  [placeholder]="ctrlPlaceholder"
  [mode]="ctrlOnlyIntegers ? undefined : 'decimal'"
  [minFractionDigits]="ctrlOnlyIntegers ? undefined : minFractionDigits"
  [maxFractionDigits]="maxFractionDigits"
  (onFocus)="inputFocused($event)"
  (onBlur)="inputLeft($event)"
  [ngClass]="
    (formCtrl.dirty || formCtrl.touched) && !formCtrl.valid ? 'p-invalid' : ''
  "
  [styleClass]="
    (formCtrl.dirty || formCtrl.touched) && !formCtrl.valid ? 'p-invalid' : ''
  "
  inputStyleClass="p-text-right"
  [disabled]="true"
></p-inputNumber>

<p-inputNumber
  *ngIf="!isDisabled"
  [inputId]="formCtrlId"
  [formControl]="formCtrl"
  [showButtons]="showButtons"
  [buttonLayout]="buttonLayout"
  [spinnerMode]="spinnerMode"
  [decrementButtonClass]="decrementButtonClass"
  [incrementButtonClass]="incrementButtonClass"
  [incrementButtonIcon]="incrementButtonIcon"
  [decrementButtonIcon]="decrementButtonIcon"
  [suffix]="suffix"
  [prefix]="prefix"
  [maxlength]="ctrlMaxLength"
  [min]="ctrlMinValue"
  [useGrouping]="ctrlUseGrouping"
  locale="sr-Latn"
  autocomplete="off"
  [placeholder]="ctrlPlaceholder"
  [mode]="ctrlOnlyIntegers ? undefined : 'decimal'"
  [minFractionDigits]="ctrlOnlyIntegers ? undefined : minFractionDigits"
  [maxFractionDigits]="maxFractionDigits"
  (onFocus)="inputFocused($event)"
  (onBlur)="inputLeft($event)"
  [ngClass]="
    (formCtrl.dirty || formCtrl.touched) && !formCtrl.valid ? 'p-invalid' : ''
  "
  [styleClass]="
    (formCtrl.dirty || formCtrl.touched) && !formCtrl.valid ? 'p-invalid' : ''
  "
  inputStyleClass="p-text-right"
></p-inputNumber>

<kodit-error-info-required [formCtrl]="formCtrl"> </kodit-error-info-required>
<kodit-error-info-min [formCtrl]="formCtrl"></kodit-error-info-min>
<kodit-error-info-max [formCtrl]="formCtrl"></kodit-error-info-max>
