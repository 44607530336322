import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { CoreService } from '@kodit/core/core-ui';
import { AuthGuard, AuthService, MixpanelService } from '@kodit/core/services';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import {
  REFRESH_TOKEN_REMAINING_TIME_STRING,
  REFRESH_TOKEN_DIALOG_SHOW_SECONDS,
} from 'libs/core/core-ui/src/lib/core-service';
import { RefreshTokenConfirmationDialogComponent } from 'libs/core/core-ui/src/lib/ui/refresh-token-confirmation-dialog/refresh-token-confirmation-dialog.component';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ekompanija-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /** Subs */
  private _subs: Subscription;

  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);
  isDialogOpened: boolean = false;
  timer: any;
  refreshWorker: Worker;
  dialogRef: any;

  constructor(
    private _mixpanelService: MixpanelService,
    private _authService: AuthService,
    private _router: Router,
    private _coreService: CoreService,
    private _authGuard: AuthGuard,
    private _dialogService: DynamicDialogService
  ) {
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    this._mixpanelService.init();

    //this._handleRouteChanged();
    this._initRefreshWorker();
    this._authService.getAppInitialized.subscribe((res) => {
      res ? this._startRefreshWorker() : this._clearTimer();
    });
  }

  // private _handleRouteChanged() {
  //   this._router.events.subscribe((event: RouterEvent) => {
  //     if (event instanceof NavigationEnd) {
  //       if (this._authService.getAppInitializedValue()) {
  //         this._coreService.resetRemainingTimerSeconds();
  //         this._authGuard.tryRefreshingTokens();
  //       }
  //     }
  //   });
  // }

  private _startRefreshWorker() {
    if (!this.refreshWorker) {
      this._initRefreshWorker();
    }
    this.refreshWorker.postMessage('hello');
  }

  private _initRefreshWorker() {
    if (typeof Worker !== 'undefined' && !this.refreshWorker) {
      this.refreshWorker = new Worker(new URL('./app.worker', import.meta.url));
      this.refreshWorker.onmessage = ({ data }) => {
        this._starRefreshTimer();
      };
    }
  }

  private _starRefreshTimer() {
    const tempRemainingTime = localStorage.getItem(
      REFRESH_TOKEN_REMAINING_TIME_STRING
    );

    if (!tempRemainingTime || Number.parseInt(tempRemainingTime) <= 0) {
      this._coreService.resetRemainingTimerSeconds();
    } else {
      this._coreService.setRemainingTimerSeconds = Number.parseInt(
        tempRemainingTime
      );
    }

    this.timer = setInterval(() => {
      this._coreService.setRemainingTimerSeconds =
        this._coreService.getRefreshTimerRemainingSecondsValue() - 1;

      this._coreService.setRemainingTimeToLocalStorage(
        this._coreService.getRefreshTimerRemainingSecondsValue()
      );

      if (
        this._coreService.getRefreshTimerRemainingSecondsValue() <=
        REFRESH_TOKEN_DIALOG_SHOW_SECONDS
      ) {
        if (!this.isDialogOpened) {
          this._showRefreshDialog();
          this.isDialogOpened = true;
        }
      }

      if (this._coreService.getRefreshTimerRemainingSecondsValue() <= 0) {
        this.dialogRef.close();
        this._authService.logout();
      }
    }, 1000);
  }

  private _showRefreshDialog() {
    this.dialogConfig.header = 'Upozorenje!';
    this.dialogConfig.closable = false;
    this.dialogConfig.customSubmitButton = { label: 'Nastavi rad' } as any;
    this.dialogConfig.customCancelButton = { label: 'Odjavi se' } as any;
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closeOnEscape = false;

    this.dialogRef = this._dialogService.open(
      RefreshTokenConfirmationDialogComponent,
      this.dialogConfig
    );

    this._subs.add(
      this.dialogRef.onClose.subscribe((res) => {
        if (!res) {
          this._authService.logout();
        }

        this._clearTimer();
        this.isDialogOpened = false;
      })
    );
  }

  private _clearTimer() {
    clearInterval(this.timer);
  }

  ngOnDestroy() {
    this._clearTimer();
    this.isDialogOpened = false;
    this._subs.unsubscribe();
    this.refreshWorker.terminate();
    this._authService.setAppInitialized = false;
  }
}
