<form [formGroup]="form">
  <div class="p-fluid">
    <!-- Naziv -->
    <kodit-text-input-field
      [formCtrl]="form.controls.naziv"
      formCtrlId="naziv"
      formCtrlLabel="Naziv"
    ></kodit-text-input-field>

    <!-- Opis -->
    <kodit-text-input-field
      [formCtrl]="form.controls.opis"
      formCtrlId="opis"
      formCtrlLabel="Opis"
    ></kodit-text-input-field>

    <!-- Ovlascenja -->
    <kodit-biranje-ovlascenja-from
      [ovlascenjeDtoFromArray]="form.controls.ovlascenjaDto"
    ></kodit-biranje-ovlascenja-from>
  </div>
</form>
