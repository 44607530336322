import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-text-input-field',
  templateUrl: './text-input-field.component.html',
  styleUrls: ['./text-input-field.component.scss'],
})
export class TextInputFieldComponent implements OnInit {
  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string = 'text-input-field';
  @Input() formCtrlType: string = 'text';
  @Input() formCtrlLabel: string = '';
  @Input() formCtrlTooltip: string = '';
  @Input() ctrlPlaceholder: string = '';
  @Input() formCtrlLabelWidth: string;
  @Input() isVertial: boolean;
  @Input() isDisabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
