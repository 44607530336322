import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DmsClient,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-dostupan-prostor-card',
  templateUrl: './dostupan-prostor-card.component.html',
  styleUrls: ['./dostupan-prostor-card.component.scss'],
})
export class DostupanProstorCardComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  private dostupanProstor: number | undefined;
  private ukupanProstor: number | undefined;
  public iskoristenProstorStr: string | undefined;
  public ukupanProstorStr: string | undefined;
  public procenat: number | undefined;

  constructor(private _client: DmsClient) {}

  ngOnInit(): void {
    this._subs.add(
      this._client.getPreostaliProstor().subscribe((res) => {
        this.dostupanProstor = res.data?.dostupanProstor;
        this.ukupanProstor = res.data?.ukupanProstor;
        if (this.ukupanProstor && this.dostupanProstor) {
          //dobijamo string na dve decimale uz toFixed i zatim uz pomoc Number ga vracamo ponovo u broj
          this.procenat =Number((100 - (this.dostupanProstor / this.ukupanProstor) * 100).toFixed(2));
          this.ukupanProstorStr = this.calculateProstor(this.ukupanProstor*1024);
          this.iskoristenProstorStr = (this.ukupanProstor - this.dostupanProstor) > 0 ? this.calculateProstor((this.ukupanProstor - this.dostupanProstor)*1024) : '0 B';
        }
      })
    );
  }
  calculateProstor(bytes: number) {
    const decimals = 2;

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
