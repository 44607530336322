<kodit-dropdown
  formCtrlId="instrukcija-placanja-select"
  [options]="instrukcijeOptions"
  [canFilter]="true"
  [formCtrl]="modelCtrl.controls.instrukcija"
  ctrlFilterBy="value,opis"
  ctrlPlaceholder="Unesite instrukciju plaćanja"
  (optionChanged)="handleOptionChange($event)"
>
  <ng-template #selectedItem let-obj>
    <div class="p-text-left">{{ obj.value }}</div>
  </ng-template>

  <ng-template #item let-obj>
    <div class="p-text-bold">{{ obj.value }}</div>
    <div class="p-text-left">{{ obj.opis }}</div>
  </ng-template>
</kodit-dropdown>
