import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  IInformacijaIsporukeDto,
} from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InformacijaIsporukeStrankeService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetInformacijaIsporukeStrankeFormGroup(
    model?: IInformacijaIsporukeDto
  ): FormGroupTypeSafe<IInformacijaIsporukeDto> {
    return this._fb.group<IInformacijaIsporukeDto>({
      id: new FormControl(model?.id),
      imeLokacijeMestaIsporuke: new FormControl(
        model?.imeLokacijeMestaIsporuke
      ),
      gln: new FormControl(model?.gln, {
        validators: [Validators.maxLength(13)],
      }),
      ulicaIBroj: new FormControl(model?.ulicaIBroj),
      grad: new FormControl(model?.grad),
      postanskiBroj: new FormControl(model?.postanskiBroj),
      drzavaId: new FormControl(model?.drzavaId),
      strankaId: new FormControl(model?.strankaId),
    });
  }
}
