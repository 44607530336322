import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {
  ArhivskaKnjigaClient,
  CreateSkladisnaJedinicaCommand,
  ISkladisnaJedinicaDto,
  UpdateSkladisnaJedinicaCommand,
} from '@kodit/core/data-api';
import {AlertService, ButtonService} from '@kodit/core/services';
import {DynamicDialogConfig, DynamicDialogRef} from '@kodit/core/shared-ui';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {Mode} from 'libs/core/services/src/lib/shared.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'kodit-skladisne-jedinice-form',
  templateUrl: './skladisne-jedinice-form.component.html',
  styleUrls: ['./skladisne-jedinice-form.component.scss'],
})
export class SkladisneJediniceFormComponent implements OnInit, OnDestroy {
  /** Props */
  form: FormGroupTypeSafe<ISkladisnaJedinicaDto>;
  mode: Mode = Mode.NEW;

  /** Sub */
  private _subs: Subscription = new Subscription();

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _dialogConfig: DynamicDialogConfig,
    private _dialogRef: DynamicDialogRef,
    private _client: ArhivskaKnjigaClient,
    private _alertService: AlertService,
    private _btnService: ButtonService
  ) {
  }

  ngOnInit(): void {
    /** Init form */
    this.form = this._fb.group<ISkladisnaJedinicaDto>({
      id: new FormControl(null),
      naziv: new FormControl('', {
        validators: [Validators.required],
      }),
    });

    if (this._dialogConfig.data && this._dialogConfig.data.id) {
      this._load(this._dialogConfig.data.id);
      this.mode = Mode.EDIT;
    }

    /** sub to save */
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  private save() {
    this._btnService.disableButton();

    if (this.mode === Mode.NEW) {
      this._subs.add(
        this._client
          .createSkladisnaJedinica(
            this.form.value as CreateSkladisnaJedinicaCommand
          )
          .subscribe(() => {
            this._alertService.addSuccessMsg(
              'Skladišna jedinica uspešno kreirana'
            );
            this._dialogRef.close(true);
          })
      );
    } else {
      this._subs.add(
        this._client
          .update(this.form.value as UpdateSkladisnaJedinicaCommand)
          .subscribe((res) => {
            if (res.data) {
              this._alertService.addSuccessMsg(
                'Skladišna jedinica uspešno izmenjena'
              );
            }
            this._dialogRef.close(true);
          })
      );
    }
  }

  private _load(id: number) {
    this._subs.add(
      this._client.getSkladisnaJedinica(id).subscribe((res) => {
        this.form.controls.id?.patchValue(res.data.id);
        this.form.controls.naziv.patchValue(res.data.naziv);
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
