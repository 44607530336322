<div class="p-d-flex p-flex-row p-ai-start p-jc-center">
  <div class="p-field-checkbox">
    <p-checkbox (onChange)="checkboxValueChange($event)" [(ngModel)]="value" binary="true"
                inputId="binary"></p-checkbox>
    <label for="binary">Dodaj nosioca javne nabavke</label>
  </div>
  <div class="flex-1 p-ml-4">
    <div *ngIf="value" class="p-field flex-1 value-wrapper">
      <label kodit-form-label>JBKJS nosioca javne nabavke</label>
      <p-inputMask
        *ngIf="value"
        [formControl]="formCtrl"
        id="jbkjs"
        mask="99999"
      ></p-inputMask>
    </div>
  </div>
</div>
