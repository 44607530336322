import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MemorandumiClient, MemorandumTableDto } from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { ActionType, OnTableInit, TableConfig } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-memorandum-table',
  templateUrl: './memorandum-table.component.html',
  styleUrls: ['./memorandum-table.component.scss'],
})
export class MemorandumTableComponent
  implements OnInit, OnDestroy, OnTableInit {
  /** Subscriptions */
  private _subs: Subscription = new Subscription();

  /** Configurations */
  tableConfig: TableConfig;
  memorandumTableDtoList: MemorandumTableDto[] = [];

  @Input() showCardBlock: boolean = true;

  constructor(
    private _router: Router,
    private _client: MemorandumiClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._load();
    this.setTableConfig();
  }

  //#region  OnTableInit
  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Memorandumi',
      deleteMessage:
        'Da li ste sigurni da želite da obrišete memorandum {param}?',
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete selektovane memorandume?',
      deleteMessageParams: 'naziv',
      columns: [
        {
          field: 'naziv',
          header: 'Naziv',
          type: 'text',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          hasAccessTooltip: 'Unesite memorandum',
          noAccessTooltip: 'Nemate ovlašćenja za unos memoranduma',
          callback: () => {
            this._router.navigate([
              '/podesavanja',
              'racun',
              'unos-memoranduma',
            ]);
          },
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          hasAccessTooltip: 'Izmenite poslovnu jedinicu',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu poslovne jedinice',
          callback: (index: number) => {
            this._router.navigate([
              '/podesavanja',
              'racun',
              'izmena-memoranduma',
              this.memorandumTableDtoList[index]?.id,
            ]);
            // this.dialogConfig.data = {
            //   id: this.poslovneJedinicaTable[index]?.id,
            //   isEdit: true,
            // };
            // this.dialogConfig.header = 'Izmena podataka poslovne jedinice';
            // this.openDialog(this.dialogConfig);
          },
        },
      ],
    });
  }

  //#endregion OnTableInit

  private _load() {
    this._subs.add(
      this._client.getAll().subscribe(
        (res) => {
          this.memorandumTableDtoList = res.data.memorandumTableDtoList;
        },
        (error) => {
          this._subs.add(
            error.subscribe((x: string) => {
              this._alertService.addFailedMsg(x);
            })
          );
        }
      )
    );
  }

  /** OnDestroy */
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
