import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  EventEmitter,
  Output
} from '@angular/core';
import {
  ActionType,
  ListAction,
  ListConfig,
} from 'libs/core/shared-ui/src/lib/list/list.config';
import { Subscription } from 'rxjs';
import { areArraysEqual } from '@kodit/core/shared';
import {
  FileType,
  IRelevantniDokumentListItemDto,
  RacuniClient,
  SefClient,
  SyncStatusPojedinacnogIzlaznihRacunaCommand,
} from '@kodit/core/data-api';
import { AlertService, SharedService } from '@kodit/core/services';
import { RacunInfoService } from '../racun-info/racun-info.service';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { RelevantniDokumentPreviewComponent } from '../relevantni-dokument-preview/relevantni-dokument-preview.component';

@Component({
  selector: 'kodit-relevantni-dokumenti-po-racunu',
  templateUrl: './relevantni-dokumenti-po-racunu.component.html',
  styleUrls: ['./relevantni-dokumenti-po-racunu.component.scss'],
})
export class RelevantniDokumentiPoRacunuComponent
  implements OnInit, OnDestroy, OnChanges {
  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.MEDIUM
  );

  /** Subscriptions */
  private _subs: Subscription = new Subscription();

  /** Props */
  listConfig: ListConfig;
  maxRows: number = 5;
  racunId: number;

  /** I/O */
  @Input() showSyncBtn: boolean = false;
  @Input() btnLabel: string = '';
  @Input() createIcon: string = 'fa-light fa-rotate';
  @Input() shouldLoad: boolean;
  @Input() dokumentList: IRelevantniDokumentListItemDto[] = [];
  @Input() title: string = 'Relevantni dokumenti';
  @Input() showPaginator: boolean;
  @Input() shouldDisableDownloadUbl: boolean;
  @Input() shouldDisplayDownloadUbl: boolean;
  @Output() downloadUblClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private _sharedService: SharedService,
    private _sefClient: SefClient,
    private _racunService: RacunInfoService,
    private _alertService: AlertService,
    private _racunClient: RacuniClient,
    private _dialogService: DynamicDialogService
  ) {
    this.listConfig = new ListConfig();
  }

  ngOnInit(): void {
    this._racunService.getRacunId.subscribe((res) => {
      this.racunId = res;
    });

    this.setListConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !areArraysEqual(
        changes.dokumentList.currentValue,
        changes.dokumentList.previousValue
      )
    ) {
      this.dokumentList = changes.dokumentList.currentValue;
    }
  }

  setListConfig() {
    this.listConfig = {
      title: 'Relevantni dokumenti',
      paginator: true,
      rows: '5',
      toggleable: true,
      collapsed: true,
      deleteMessage: 'Da li ste sigurni da želite da obrišete dokument?',
      actions: this._getActions(),
      createCallbackFunction: () => this._onAdd(),
    };
  }

  handlePreview(item: IRelevantniDokumentListItemDto) {
    //const pdfFileUrl = 'https://ekompanija-dev.fra1.cdn.digitaloceanspaces.com/root/Racuni/IzlazneFakture/iiiza78_2024_kodit_doo_0391923c_fbc0_42e8_af32_fa2dbb1c39e9?AWSAccessKeyId=DO00YPFR6YZP4CVDL34T&Expires=1716388824&Signature=M1iq1Yu5Ks2E6j6Kix13OVA0o9U%3D';
    //printJS(this.pdfFileUrl);
    this._getBlobOfBase64ByteArray(item.storageFileName, false);
  }

  handleDownload(item: IRelevantniDokumentListItemDto) {
    if (item.fileType !== FileType.APPLICATION_PDF) {
      alert('Tip fajla nije podržan za preuzimanje');
      return;
    }
    this._getBlobOfBase64ByteArray(item.storageFileName, true);
  }

  onDownloadUblClicked() {
    this.downloadUblClicked.emit();
  }

  onCreate() {
    if (this.racunId) {
      this._subs.add(
        this._sefClient
          .syncDocPojedinacnogIzlaznogRacuna({
            racunId: this.racunId,
          } as SyncStatusPojedinacnogIzlaznihRacunaCommand)
          .subscribe((res) => {
            this._racunService.setReloadDocuments = this.racunId;
            this._alertService.addSuccessMsg(
              'Sinhronizacija dokumenta je uspešna'
            );
          })
      );
    }
  }

  private _getActions(): ListAction[] {
    if (!this.showSyncBtn) {
      return [];
    }
    return [
      new ListAction({
        type: ActionType.EDIT,
        //config: this.getEditListConfig(),
        callback: (index: number) => {
          // this.onEdit(index);
        },
      }),
      new ListAction({
        type: ActionType.DELETE,
        //config: this.getDeleteListConfig(),
        callback: (index: number) => {
          // this.onDelete(index);
        },
      }),
    ];
  }

  private _getBlobOfBase64ByteArray(
    originalFileName: string,
    isDownload: boolean
  ) {
    if (isDownload) {
      this._subs.add(
        this._racunClient
          .getRelevantniDokument(originalFileName)
          .subscribe((res) => {
            const base64ByteArray = new Uint8Array(
              atob(res.data.file)
                .split('')
                .map((char) => char.charCodeAt(0))
            );
            const blob = new Blob([base64ByteArray], {
              type: 'application/pdf',
            });
            const blobUrl = URL.createObjectURL(blob);

            this._sharedService.downloadUrlAsPDF(blobUrl, originalFileName);
          })
      );
      return;
    }

    this._subs.add(
      this._racunClient
        .getRelevantniDokumentUrl(originalFileName)
        .subscribe((res) => {
          // const base64ByteArray = new Uint8Array(
          //   atob(res.data.file)
          //     .split('')
          //     .map((char) => char.charCodeAt(0))
          // );
          // const blob = new Blob([base64ByteArray], { type: 'application/pdf' });
          // const blobUrl = URL.createObjectURL(blob);

          this.dialogConfig.data = {
            fileUrl: res.data.file,
          };
          this.dialogConfig.header = "Pregled dokumenta"
          this.dialogConfig.maximisable = true;
          this.dialogConfig.maximized = true;
          this.dialogConfig.hideSubmit = true;
          this._dialogService.open(
            RelevantniDokumentPreviewComponent,
            this.dialogConfig
          );
        })
    );
  }

  private _onAdd() {
    alert('ToDo');
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
