<form [formGroup]="form" (keydown)="keyDownFunction($event)">
  <div class="p-fluid">
    <kodit-field fieldLabel="SEF API ključ" fieldLabelFor="sef-api-key">
      <kodit-text-input
        formCtrlId="sef-api-key"
        [formCtrl]="form.controls.apiKeySEF"
      ></kodit-text-input>
    </kodit-field>
  </div>
</form>
