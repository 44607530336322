<form [formGroup]="form">
  <div class="p-fluid">
    <div class="p-text-center pitanje p-mb-6">
      Da li ste sigurni da želite da otkažete račun broj
      <span class="p-text-bold">{{ brojRacuna }}</span
      >?
    </div>
    <kodit-field fieldLabel="Komentar" fieldLabelFor="komentar">
      <kodit-textarea-input
        formCtrlId="komentar"
        [formCtrl]="form.controls.komentar"
      ></kodit-textarea-input>
    </kodit-field>
  </div>
</form>
