import { Component, OnDestroy, OnInit } from '@angular/core';
import { OznakaDto, OznakeClient, RacuniClient, RemoveOznakaRacunaCommand } from '@kodit/core/data-api';
import { AlertService, SharedService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-remove-oznaka-dialog',
  templateUrl: './remove-oznaka-dialog.component.html',
  styleUrls: ['./remove-oznaka-dialog.component.scss'],
})
export class RemoveOznakaDialogComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  oznaka: OznakaDto;

  constructor(
    private _client: RacuniClient,
    private _dialogRef: DynamicDialogRef,
    private _alertService: AlertService,
    public dialogConfig: DynamicDialogConfig,
     private _sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this.oznaka = this.dialogConfig.data.oznaka;

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  private save() {
    this._sharedService.displayLoadingScreen(true, 'Brisanje oznake...');
    this._subs.add(
      this._client
        .removeOznaka(
          new RemoveOznakaRacunaCommand({
            racunId: this.dialogConfig.data.racunId,
            oznakaId: this.oznaka.id,
          })
        )
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.messages[0]);
            this._dialogRef.close(true);
          } else {
            this._alertService.addFailedMsg(res.messages[0]);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
