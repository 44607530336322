import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FilterChipDto,
  GetPdvEvidencijaZbirna39TableQuery,
  IPdvEvidencijaZbirna39TableDto,
  PaginatedResultOfPdvEvidencijaZbirna39TableDto,
  PdvEvidencijaStatus,
  PdvEvidencijeClient,
  SefEvidentirajPdvEvidencijaZbirna39Command,
  SefPonistiPdvEvidencijaZbirna39Command,
  TipPristupa,
} from '@kodit/core/data-api';
import {
  AlertService,
  ConfigService,
  LocationService,
  SharedService,
} from '@kodit/core/services';
import {
  ActionMode,
  ActionType,
  DynamicDialogConfig,
  OnTableInit,
  SelectMode,
  TableConfig,
} from '@kodit/core/shared-ui';
import { Store } from '@ngrx/store';
import { selectFilterByKey } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.selectors';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { Subscription } from 'rxjs';
import { PdvEvidencijaZbirnaTable39FilterComponent } from './pdv-evidencija-zbirna-table39-filter/pdv-evidencija-zbirna-table39-filter.component';
import { finalize } from 'rxjs/operators';
import {
  clearFilter,
  setFilter,
} from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';
import { nameofFactory, toCamelCase } from '@kodit/core/shared';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'kodit-pdv-evidencija-zbirna-table39',
  templateUrl: './pdv-evidencija-zbirna-table39.component.html',
  styleUrls: ['./pdv-evidencija-zbirna-table39.component.scss'],
})
export class PdvEvidencijaZbirnaTable39Component
  implements OnInit, OnDestroy, OnTableInit {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  paginatedData!: PaginatedResultOfPdvEvidencijaZbirna39TableDto;
  chipItems: FilterChipDto[] = [];
  filters!: GetPdvEvidencijaZbirna39TableQuery;
  private _skipFirst = 0;
  numberOfRowsDisplayed = 0;
  private _isBusy = false;

  isFirstLoad = true;

  evidencijaTable: IPdvEvidencijaZbirna39TableDto[] = [];

  /** Configurations */
  tableConfig!: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.MEDIUM_LARGE
  );

  /** I/O */

  constructor(
    private _locationService: LocationService,
    private _storage: Store,
    private _configService: ConfigService,
    private _client: PdvEvidencijeClient,
    private _confirmationService: ConfirmationService,
    private _alert: AlertService,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.setTableConfig().then();

    // reload tabele kada se filter promeni
    this._subs.add(
      this._storage
        .select(selectFilterByKey('PDV|Zbirne39'))
        .subscribe((data) => {
          if (data) {
            this.filters = JSON.parse(data);
            this._skipFirst = this.filters.pageNumber ?? 0;
            this.numberOfRowsDisplayed = this.filters.pageSize ?? 0;
          } else {
            this.filters = new GetPdvEvidencijaZbirna39TableQuery({
              pageNumber: this._skipFirst,
              pageSize: this.numberOfRowsDisplayed,
            });
          }
          this._load();
        })
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      isLazy: true,
      lazyCallbackFunction: (event) => {
        if (!this.isFirstLoad) {
          this._skipFirst = event.first!;
          this.numberOfRowsDisplayed = event.rows!;
        }
        this.updateFilterDataAndReload();
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        }
      },
      selectMode: SelectMode.SINGLE,
      tableHeader: 'Zbirne pdv evidencije',
      multiDeleteMessage:
        'Da li ste sigurni da želite da obrišete izabranu pdv evidenciju?',
      deleteMessage:
        'Da li ste sigurni da želite da obrišete pdv evidenciju {param}?',
      deleteMessageParams: 'broj',
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      columns: [
        {
          field: 'broj',
          header: 'Broj',
          type: 'text',
        },
        {
          field: 'periodDto',
          subField: 'periodStr',
          header: 'Period',
          styleClass: 'pdv-period',
          styleClassField: 'periodBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
        },
        {
          field: 'godina',
          header: 'Godina',
          type: 'number',
        },
        {
          field: 'statusDto',
          subField: 'statusStr',
          header: 'Status',
          styleClass: 'pdv-status',
          styleClassField: 'statusBadgeStr',
          tooltipField: 'opis',
          type: 'badge',
        },
        {
          field: 'datumPromeneStatusa',
          header: 'Datum promene statusa',
          type: 'text',
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          tipPristupa: TipPristupa.PDV_CRUD,
          hasAccessTooltip: 'Unesite pdv evidenciju',
          noAccessTooltip: 'Nemate ovlašćenja za pdv evidencije',
          callback: () => this._routeToCreate(),
        },
      ],
      rowActions: [
        {
          type: ActionType.EDIT,
          tipPristupa: TipPristupa.PDV_CRUD,
          hasAccessTooltip: 'Izmenite pdv evidenciju',
          noAccessTooltip: 'Nemate ovlašćenja za izmenu pdv evidencije',
          callback: (index: number) => {
            this._routeToEdit(index);
          },
          shouldDisplayByCondition: (
            rowData: IPdvEvidencijaZbirna39TableDto
          ) => {
            return this._shouldDisplayEdit(rowData.statusDto!.status!);
          },
        },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.PDV_CRUD,
          icon: 'fa-light fa-clipboard',
          //label: 'Evidentiraj',
          hasAccessTooltip: 'Evidentirajte pdv evidenciju',
          noAccessTooltip: 'Nemate ovlašćenja za evidentiranje pdv evidencije',
          shouldDisableWhenSefInactive: true,
          callback: (index: number) => {
            this._sharedService.displayLoadingScreen(
              true,
              'Evidentiranje zbirne pdv evidencije...'
            );
            this._subs.add(
              this._client
                .sefEvidentirajPdvEvidencijaZbirna39(
                  new SefEvidentirajPdvEvidencijaZbirna39Command({
                    id: this._getPaginatedItem(index).id,
                  })
                )
                .subscribe((res) => {
                  if (res.succeeded) {
                    this._alert.addSuccessMsg(res.data!);
                    this._load();
                  }
                  this._alert.addFailedMsg(res.messages![0]);
                })
            );
          },
          shouldDisplayByCondition: (
            rowData: IPdvEvidencijaZbirna39TableDto
          ) => {
            return this._shouldDisplayEdit(rowData.statusDto!.status!);
          },
        },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.PDV_CRUD,
          icon: 'fa-light fa-pen-to-square',
          hasAccessTooltip: 'Korigujte pdv evidenciju',
          noAccessTooltip: 'Nemate ovlašćenja za korigovanje pdv evidencije',
          shouldDisableWhenSefInactive: true,
          callback: (index: number) => {
            this.routeToKoriguj(index);
          },
          shouldDisplayByCondition: (
            rowData: IPdvEvidencijaZbirna39TableDto
          ) => {
            return this._shouldDisplayCancel(rowData.statusDto!.status!);
          },
        },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.PDV_CRUD,
          icon: 'fa-solid fa-eye',
          hasAccessTooltip: 'Pregledajte korigovanu pdv evidenciju',
          noAccessTooltip:
            'Nemate ovlašćenja za pregled Korigovane pdv evidencije',
          shouldDisableWhenSefInactive: false,
          callback: (index: number) => {
            this.routeToPregledKorigovane(index);
          },
          shouldDisplayByCondition: (
            rowData: IPdvEvidencijaZbirna39TableDto
          ) => {
            return this._shouldDisplayPregledKorigovane(
              rowData.statusDto!.status!
            );
          },
        },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.PDV_CRUD,
          icon: 'fa-light fa-xmark-large',
          hasAccessTooltip: 'Poništite pdv evidenciju',
          noAccessTooltip: 'Nemate ovlašćenja za poništavanje pdv evidencije',
          shouldDisableWhenSefInactive: true,
          callback: (index: number) => {
            this._confirmationService.confirm({
              header: 'Potvrda poništavanja',
              message: 'Da li želite da poništite evidenciju?',
              acceptLabel: 'Poništi',
              rejectLabel: 'Odustani',
              rejectButtonStyleClass: 'p-button-outlined',
              accept: () => {
                this._sharedService.displayLoadingScreen(
                  true,
                  'Poništavanje zbirne pdv evidencije...'
                );
                this._subs.add(
                  this._client
                    .sefCancelPdvEvidencijaZbirna39(
                      new SefPonistiPdvEvidencijaZbirna39Command({
                        id: this._getPaginatedItem(index).id,
                      })
                    )
                    .subscribe((res) => {
                      if (res.succeeded) {
                        this._alert.addSuccessMsg(res.data!);
                        this._load();
                      }
                      this._alert.addFailedMsg(res.messages![0]);
                    })
                );
              },
            });
          },
          shouldDisplayByCondition: (
            rowData: IPdvEvidencijaZbirna39TableDto
          ) => {
            return this._shouldDisplayCancel(rowData.statusDto!.status!);
          },
        },
        {
          mode: ActionMode.SINGLE,
          type: ActionType.CUSTOM,
          tipPristupa: TipPristupa.PDV_CRUD,
          icon: 'fa-light fa-trash',
          hasAccessTooltip: 'Obrišite pdv evidenciju',
          noAccessTooltip: 'Nemate ovlašćenja za brisanje pdv evidencije',
          shouldDisableWhenSefInactive: true,
          callback: (index: number) => {
            this._confirmationService.confirm({
              header: 'Potvrda brisanja',
              message: 'Da li želite da obrišete evidenciju?',
              acceptLabel: 'Obriši',
              rejectLabel: 'Odustani',
              rejectButtonStyleClass: 'p-button-outlined',
              accept: () => {
                this._sharedService.displayLoadingScreen(
                  true,
                  'Brisanje zbirne pdv evidencije...'
                );
                this._subs.add(
                  this._client
                    .deleteZbirna39(this._getPaginatedItem(index).id!)
                    .subscribe((res) => {
                      if (res.succeeded) {
                        this._alert.addSuccessMsg(res.data!);
                        this._load();
                      }
                      this._alert.addFailedMsg(res.messages![0]);
                    })
                );
              },
            });
          },
          shouldDisplayByCondition: (
            rowData: IPdvEvidencijaZbirna39TableDto
          ) => {
            return this._shouldDisplayEdit(rowData.statusDto!.status!);
          },
        },
      ],
      advancedFilter: {
        component: PdvEvidencijaZbirnaTable39FilterComponent,
        data: new GetPdvEvidencijaZbirna39TableQuery(),
      },
    });
  }

  private _load() {
    if (this._isBusy) {
      return;
    }

    this._configService.setIsBusy = true;
    this._isBusy = true;

    if (!this.filters) {
      this.filters = new GetPdvEvidencijaZbirna39TableQuery({
        pageNumber: this._skipFirst,
        pageSize: this.numberOfRowsDisplayed,
      });
    }

    this._subs.add(
      this._client
        .getForTableZbirna39(this.filters as GetPdvEvidencijaZbirna39TableQuery)
        .pipe(
          finalize(() => {
            this._configService.setIsBusy = false;
            this._isBusy = false;
          })
        )
        .subscribe(
          (res) => {
            this.paginatedData = res;
            this.evidencijaTable = res.data!;
            this.chipItems = res.activeFilters!;
            this.tableConfig.advancedFilter!.data = this.filters;
          },
          (error) => {
            this._configService.setIsBusy = false;
          }
        )
    );
  }

  private _updateFilters() {
    if (this._suFilteriPrazni()) {
      this._storage.dispatch(
        clearFilter({
          key: 'PDV|Zbirne39',
        })
      );
      return;
    }

    this._storage.dispatch(
      setFilter({
        key: 'PDV|Zbirne39',
        filter: JSON.stringify(this.filters),
      })
    );
  }
  private _suFilteriPrazni() {
    for (const prop of Object.keys(this.filters) as Array<
      keyof GetPdvEvidencijaZbirna39TableQuery
    >) {
      if (this.filters[prop]) {
        return false;
      }
    }
    return true;
  }

  private updateFilterDataAndReload() {
    if (
      (this._skipFirst !== this.filters.pageNumber ||
        this.numberOfRowsDisplayed !== this.filters.pageSize) &&
      !this.isFirstLoad
    ) {
      this.filters.pageNumber = this._skipFirst;
      this.filters.pageSize = this.numberOfRowsDisplayed;
      this._updateStorage();
    }
  }

  private _updateStorage() {
    this._storage.dispatch(
      setFilter({
        key: 'PDV|Zbirne39',
        filter: JSON.stringify(this.filters),
      })
    );
  }

  private _getPaginatedIndex(index: number): number {
    return index - this._skipFirst;
  }

  private _getPaginatedItem(index: number): IPdvEvidencijaZbirna39TableDto {
    return this.evidencijaTable![this._getPaginatedIndex(index)];
  }

  private _handleChipRemove(removedChip: FilterChipDto) {
    const nameof = nameofFactory<GetPdvEvidencijaZbirna39TableQuery>();
    const nameOfStatusi = nameof('statusi');

    if (removedChip.key!.toLowerCase() === nameOfStatusi.toLowerCase()) {
      const idx = (this.tableConfig.advancedFilter!
        .data as GetPdvEvidencijaZbirna39TableQuery).statusi!.findIndex(
        (x) => x === removedChip.defaultValue
      );
      (this.tableConfig.advancedFilter!
        .data as GetPdvEvidencijaZbirna39TableQuery).statusi!.splice(idx, 1);
    } else if (removedChip!.key!.toLocaleLowerCase() === nameof('mesec')) {
      this.tableConfig.advancedFilter!.data[
        toCamelCase(removedChip.key!)
      ] = undefined;
    } else {
      this.tableConfig.advancedFilter!.data[toCamelCase(removedChip.key!)] =
        removedChip.defaultValue;
    }
    this._updateFilters();
  }

  _routeToCreate() {
    this._locationService.routeToPdvEvidencijeZbirna39Create().then();
  }

  private _routeToEdit(rowIndex: number) {
    this._locationService
      .routeToPdvEvidencijeZbirna39Update(this._getPaginatedItem(rowIndex).id!)
      .then();
  }

  async routeToKoriguj(index: number) {
    this._locationService
      .routeToPdvEvidencijeZbirna39Koriguj(this._getPaginatedItem(index).id!)
      .then();
  }

  async routeToPregledKorigovane(index: number) {
    this._locationService
      .routeToPdvEvidencijeZbirna39Korigovana(this._getPaginatedItem(index).id!)
      .then();
  }

  private _shouldDisplayEdit(status: PdvEvidencijaStatus) {
    return status === PdvEvidencijaStatus.U_PRIPREMI;
  }

  private _shouldDisplayCancel(status: PdvEvidencijaStatus) {
    return status === PdvEvidencijaStatus.EVIDENTIRANO;
  }

  private _shouldDisplayPregledKorigovane(status: PdvEvidencijaStatus) {
    return status === PdvEvidencijaStatus.KORIGOVANO;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
