<p-blockUI
  *ngIf="showLoader"
  [target]="content"
  [blocked]="true"
  styleClass="blockUI"
>
  <div class="block-wrapper p-d-flex p-jc-center p-ai-center">
    <div class="loading p-d-flex p-flex-column p-ai-center" *ngIf="!showError">
      <div class="p-text-left loading-text">{{ loadingText }}</div>
      <div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div *ngIf="showError" class="p-d-flex p-flex-column p-ai-center">
      <div>
        <h3>Došlo je do greške</h3>
      </div>
      <div>{{ errorText }}</div>
    </div>
  </div>
</p-blockUI>
