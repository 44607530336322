import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormatIzvoza, PravnaLicaClient } from '@kodit/core/data-api';
import { AlertService, SharedService } from '@kodit/core/services';
import { DynamicDialogRef } from '@kodit/core/shared-ui';
import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-pravno-lice-export-form',
  templateUrl: './pravno-lice-export-form.component.html',
  styleUrls: ['./pravno-lice-export-form.component.scss'],
})
export class PravnoLiceExportFormComponent implements OnInit, OnDestroy {
  /** Subscription */
  _subs: Subscription;

  /** Props */
  dropdownItems: SelectItem[];
  form: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _dialogRef: DynamicDialogRef,
    private _client: PravnaLicaClient,
    private _sharedService: SharedService,
    private _alertService: AlertService,
    private _loaderService: LoaderService
  ) {
    this._subs = new Subscription();
  }

  ngOnInit(): void {
    // init form and dropdown
    this._initForm();
    this._initDropdown();

    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._export();
      })
    );
  }

  private _export() {
    this._loaderService.setLoadingText =
      'Izvoz pravnih lica je u toku. Molimo sačekajte. Izvoz može trajati i do 15 sekundi.';
    this._loaderService.setShowLoader = true;

    this._subs.add(
      this._client.exportPravnoLice(this.form.value).subscribe((res: any) => {
        this._sharedService.downloadFileAsTXT(
          res.data.content,
          res.data.fileName,
          res.data.contentType
        );
        this._alertService.addSuccessMsg('Pravna lica su uspešno izvežena!');
        this._dialogRef.close(true);
      }, (error) => { }, () => {
        this._loaderService.reset();
      })
    );
  }

  private _initForm() {
    this.form = this._fb.group({
      format: new FormControl(FormatIzvoza.INFORMATIKA_TXT),
    });
  }

  private _initDropdown() {
    this.dropdownItems = [
      { label: 'Informatika (.txt)', value: FormatIzvoza.INFORMATIKA_TXT },
    ];
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
