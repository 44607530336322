import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DatotekaDto, IDatotekaTreeDto } from '@kodit/core/data-api';
import { DatotekaService } from '../../datoteka-service';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'kodit-folder-info-card',
  templateUrl: './folder-info-card.component.html',
  styleUrls: ['./folder-info-card.component.scss'],
})
export class FolderInfoCardComponent implements OnInit, OnDestroy {
  /** I/O */
  @Input() datoteka!: DatotekaDto;

  /** PROPS */
  brojFajlova!: string;

  constructor(private _datotekaService: DatotekaService) {}

  ngOnInit(): void {
    if (this.datoteka.children) {
      this.brojFajlova =
        this.datoteka.children.length + this._getDatotekeString();
    } else {
      this.brojFajlova = '0 datoteka';
    }
  }
  private _getDatotekeString(): string {
    if (
      this.datoteka.children &&
      this.datoteka.children.length >= 2 &&
      this.datoteka.children.length <= 4
    ) {
      return ' datoteke';
    }
    return ' datoteka';
  }

  onClick() {
    this._datotekaService.setSelectedDatoteka = {
      data: {
        vrstaDatoteke: this.datoteka.vrsta,
        id: this.datoteka.datotekaId,
        naziv: this.datoteka.naziv,
        childrenDto: this.datoteka.children,
        velicinaDokumenta: this.datoteka.velicinaDokumenta,
      },
    } as TreeNode<IDatotekaTreeDto>;
  }

  ngOnDestroy(): void {}
}
