<div class="p-field">
  <label *ngIf="showLabel" for="delatnost-select" kodit-form-label
    >Delatnost {{ formCtrl.errors?.required ? ' *' : '' }}</label
  >

  <kodit-dropdown
    *ngIf="delatnosti.length > 0"
    formCtrlId="delatnost-select"
    [options]="delatnosti"
    [formCtrl]="formCtrl"
    ctrlPlaceholder="Izaberi delatnost"
    [isGrouped]="true"
    [canFilter]="true"
    [canClear]="true"
    ctrlFilterPlaceholder="Šifra ili naziv delatnosti za pretragu"
  >
    <ng-template #item let-obj>
      <div class="">
        <span>{{ obj.label }}</span>
      </div>
    </ng-template>
  </kodit-dropdown>

  <div *ngIf="delatnosti.length == 0" id="delatnost-select">
    Učitavanje delatnosti...
  </div>
</div>
