import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-table-filter-form',
  templateUrl: './table-filter-form.component.html',
  styleUrls: ['./table-filter-form.component.scss']
})
export class TableFilterFormComponent implements OnInit, OnDestroy {
  /** Subscriptions */
  private _getSub: Subscription = new Subscription();
  private _submitSub: Subscription = new Subscription();
  private _saveSub: Subscription = new Subscription();
  private _loadStavkeCenovnikaSub: Subscription = new Subscription();
  
  @ViewChild('filterSablonNaziv') filterSablonNaziv: any;
  

  constructor(
    public _dialogRef: DynamicDialogRef,
    public _dialogConfig: DynamicDialogConfig,
  ) { }
  

  ngOnInit(): void {
    this._submitSub = this._dialogRef.onSubmit.subscribe(() => {
      this.save();
    });
  }

  save(): void {
    this._dialogRef.close(this.filterSablonNaziv.nativeElement.value);
  }

  ngOnDestroy(): void {
    this._getSub.unsubscribe();
    this._submitSub.unsubscribe();
    this._saveSub.unsubscribe();
    this._loadStavkeCenovnikaSub.unsubscribe();
  }
}
