import {Component, OnDestroy, OnInit} from '@angular/core';
import {InitService} from "../init.service";
import {Subscription} from "rxjs";
import {MojaFirmaClient} from "@kodit/core/data-api";

@Component({
  selector: 'kodit-init',
  templateUrl: './init.component.html',
  styleUrls: ['./init.component.scss']
})
export class InitComponent implements OnInit,OnDestroy{
  /** Subs */
  private _subs : Subscription = new Subscription();

  constructor(
    private _initService : InitService,
    private _mojaFirmaClient : MojaFirmaClient
  ) { }

  ngOnInit(): void {
    this._initKonfiguracijaMojeFirme();
  }

  private _initKonfiguracijaMojeFirme(){
    this._subs.add(
      this._mojaFirmaClient.getKonfiguracija().subscribe((res)=>{
        this._initService.setKonfiguracijuMojeFirme = res.data;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
