<kodit-page [showCardBlock]="showCardBlock">
  <kodit-table
    [tableConfig]="tableConfig"
    [items]="lagerTransakcijeTable"
    [chipItems]="chipItems"
  >
    <!-- <div custom-header>
          Spisak uplata
          <div>ukupno potražuje: {{ izvod.uplateTable.length }}</div>
        </div> -->
  </kodit-table>
</kodit-page>
