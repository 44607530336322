<kodit-racun-form-container
  [racunFormConfig]="racunFormConfig"
  [excludeTipovePredmeta]="useOnlyRoba"
>
  <div dodatni-input class="p-flex-md-row even-columns gap-12">
    <kodit-field
      fieldLabel="Broj narudžbenice"
      fieldLabelFor="broj-narudzbenice"
    >
      <kodit-text-input
        [formCtrlClass]="{
          'p-invalid':
            racunForm.errors?.dodatniPodaci &&
            racunForm.controls.racunDto.get('brojNarudzbenice').touched
        }"
        [formCtrl]="racunForm.controls.racunDto.get('brojNarudzbenice')"
        formCtrlId="broj-narudzbenice"
      >
      </kodit-text-input>
    </kodit-field>

    <kodit-field
      dodatni-input
      fieldLabel="Datum narudžbenice"
      fieldLabelFor="datum-narudzbenice"
    >
      <kodit-calendar
        formCtrlId="datum-narudzbenice"
        [formCtrl]="racunForm.controls.racunDto.get('datumNarudzbenice')"
        class="flex-50"
      ></kodit-calendar>
    </kodit-field>
    <kodit-field fieldLabel="Vozila" fieldLabelFor="vozila">
      <kodit-vozilo-multiselect class="component-width"
          [voziloFormArray]="racunForm.controls.vozila"
          (selectedVozila)="selectedVozila($event)"
      >
      </kodit-vozilo-multiselect>
    </kodit-field>
  </div>
</kodit-racun-form-container>
