import {
  Component,
  Inject,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  CreateKontaktFromEmailCommand,
  ICreateKontaktFromEmailCommand,
  ISendPodsetnikPlacanjaRacunaCommand,
  KontaktiClient,
  RacuniClient,
  SendPodsetnikPlacanjaRacunaCommand,
} from '@kodit/core/data-api';
import { FormHelper, MailFormService } from '@kodit/core/form-definitions';
import { AlertService } from '@kodit/core/services';
import { DynamicDialogRef, DynamicDialogConfig } from '@kodit/core/shared-ui';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { KontaktiService } from 'libs/core/moduli/kontakt/src/lib/kontakti.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'kodit-slanje-podsetnika-form',
  templateUrl: './slanje-podsetnika-form.component.html',
  styleUrls: ['./slanje-podsetnika-form.component.scss'],
})
export class SlanjePodsetnikaFormComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  strankaId: number;
  form: FormGroupTypeSafe<ISendPodsetnikPlacanjaRacunaCommand>;

  constructor(
    private _fs: FormBuilderTypeSafe,
    private _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _formHelper: FormHelper,
    private _route: ActivatedRoute,
    private _racuniClient: RacuniClient,
    private _kontaktiClient: KontaktiClient,
    private _alertService: AlertService,
    private _kontaktService: KontaktiService,
    private _mailFS: MailFormService
  ) {}

  ngOnInit(): void {
    this.strankaId = this._dialogConfig.data.strankaId;

    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.onSend();
      })
    );
    this.form = this._fs.group<ISendPodsetnikPlacanjaRacunaCommand>({
      strankaId: new FormControl(this._dialogConfig.data.strankaId, {
        validators: Validators.required,
      }),
      to: this._mailFS.GetMailReceiversFormArray(
        this._dialogConfig.data.sendTo
      ),
      cc: this._mailFS.GetMailReceiversFormArray(
        this._dialogConfig.data.sendCc,
        false
      ),
    });
  }

  onSend() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._subs.add(
      this._racuniClient
        .posaljiPodsetnikZaPlacanje(
          this.form.value as SendPodsetnikPlacanjaRacunaCommand
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.messages[0]);
          this._creteKontaktiFromNewEmails();
        })
    );
  }

  private _creteKontaktiFromNewEmails() {
    const req: ICreateKontaktFromEmailCommand = {
      strankaId: this.strankaId,
      kontakti: [
        ...this.form.value.to.filter((x) => x.isNew),
        ...this.form.value.cc.filter((x) => x.isNew),
      ],
    };
    if (req.kontakti.length > 0) {
      this._subs.add(
        this._kontaktiClient
          .createKontaktFromEmail(req as CreateKontaktFromEmailCommand)
          .subscribe((res) => {
            this._alertService.addSuccessMsg(res.messages[0]);
            this._kontaktService.loadKontakti.next(true);
            this._dialogRef.close();
          })
      );
    } else {
      this._dialogRef.close();
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
