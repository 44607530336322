import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FiskalniRacuniClient } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-buyer-identification-dropdown',
  templateUrl: './buyer-identification-dropdown.component.html',
  styleUrls: ['./buyer-identification-dropdown.component.scss']
})
export class BuyerIdentificationDropdownComponent implements OnInit,OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];

  /** I/O */
  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string;
  @Input() parentCtrl: any = 'body';

  constructor(
    private _client : FiskalniRacuniClient,
    private _cdr : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this._load().then();
  }

  private async _load() {
    this._subs.add(
      this._client.getBuyerIdentification().subscribe((res) => {
        this.items = res.data!.buyerIdentificationDtoList!.map((x) => ({
          value: x.buyerIdentification,
          label: x.buyerIdentificationStr,
          title: x.opis,
        }));
        this._cdr.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
