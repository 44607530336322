import { FormArray, FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import { BankaFormService } from './banka-form.service';
import { ITekuciRacunDto } from '@kodit/core/data-api';

@Injectable({
  providedIn: 'root',
})
export class TekuciRacunFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _bankaForm: BankaFormService
  ) {}

  /**
   * Pravi FormArray od prosledene liste bankovniNalogDto
   * @param oznaka Lista bankovnih naloga dto za pretvaranje u FormArray
   * @returns FormArray od prosledene liste bankovniNalogDto
   */
  public GetTekuciRacunFormArray(
    model?: ITekuciRacunDto[],
    isStrankaRequired: boolean = true
  ): FormArray {
    if (!model || model.length === 0) {
      return this._fb.array([]);
    }

    let result: FormArray = this._fb.array([]);
    model.forEach((o) => {
      result.push(this.GetTekuciRacunFormGroup(o, isStrankaRequired));
    });
    return result;
  }

  /**
   * Pravi FormGroup od prosledene bankovniNalogDto
   * @param model BankovniNalogDto objekat za pretvaranje u FormGroup
   * @returns FormGroup od prosledene bankovniNalogDto
   */
  public GetTekuciRacunFormGroup(
    model?: ITekuciRacunDto,
    isStrankaRequired: boolean = true
  ): FormGroupTypeSafe<ITekuciRacunDto> {
    return this._fb.group<ITekuciRacunDto>({
      id: new FormControl(model?.id),
      bankaDto: this._bankaForm.GetBankaFormGroup(model?.bankaDto),
      iban: new FormControl(model?.iban, {
        validators: [Validators.maxLength(34)],
      }),
      ceoBrojRacuna: new FormControl(model?.ceoBrojRacuna, {
        validators: [Validators.required, Validators.maxLength(30)],
      }),
      strankaId: new FormControl(model?.strankaId, {
        validators: isStrankaRequired
          ? [Validators.required, Validators.min(1)]
          : [Validators.nullValidator],
      }),
      isDefault: new FormControl(model?.isDefault ?? false),
      prikaziNaPdf: new FormControl(model?.isDefault ?? false),
      isActive : new FormControl(model?.isActive ?? true)
    });
  }
}
