<div class="p-d-flex p-jc-between p-mb-3">
  <div class="p-d-flex gap-12">
    <button
      pButton
      type="button"
      label="Unesi novu lokaciju"
      icon="fas fa-plus"
      (click)="onCreate()"
    ></button>
    <button
      pButton
      type="button"
      label="Izmeni"
      class="p-button-outlined p-button-success"
      [disabled]="!editEnabled"
      icon="fas fa-pencil-alt"
      (click)="onEdit()"
    ></button>
    <button
      pButton
      type="button"
      label="Obriši"
      class="p-button-outlined p-button-danger"
      icon="far fa-trash-alt"
      [disabled]="!deleteEnabled"
      (click)="onDelete()"
    ></button>
  </div>
  <p-selectButton
    [options]="displayTypes"
    [(ngModel)]="displayType"
  ></p-selectButton>
</div>
<div class="p-d-flex p-jc-end gap-12 p-mb-3">
  <button
    pButton
    type="button"
    class="p-button-outlined"
    label="Raširi celo stablo"
    (click)="expandAll()"
  ></button>
  <button
    pButton
    type="button"
    class="p-button-outlined"
    label="Skupi celo stablo"
    (click)="collapseAll()"
  ></button>
</div>

<p-tree
  [value]="fizickeLokacije"
  [layout]="displayType"
  [emptyMessage]="emptyMessage"
  [filter]="true"
  selectionMode="single"
  [(selection)]="selectedLokacija"
  (onNodeSelect)="nodeSelect($event)"
  (onNodeUnselect)="nodeUnselect()"
></p-tree>
