import { Component, Input, OnInit } from '@angular/core';
import {
  IKorisnikZaGrupuDto,
  KorisniciClient,
  RemoveKorisnikFromGrupaCommand,
} from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { TableConfig, ActionType, OnTableInit } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-korisnik-za-grupu-table',
  templateUrl: './korisnik-za-grupu-table.component.html',
  styleUrls: ['./korisnik-za-grupu-table.component.scss'],
})
export class KorisnikZaGrupuTableComponent implements OnInit, OnTableInit {
  /** Configurations */
  tableConfig: TableConfig;

  /** Props */
  private _subs: Subscription = new Subscription();

  /** I/O */
  @Input() korisniciTable: IKorisnikZaGrupuDto[] = [];
  @Input() nazivGrupe: string;

  constructor(
    private _client: KorisniciClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.setTableConfig();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      columns: [
        {
          field: 'naziv',
          header: 'Ime',
          type: 'text',
        },
        {
          field: 'email',
          header: 'Korisničko ime / E-Mail',
          type: 'text',
        },
      ],
      rowActions: [
        {
          type: ActionType.DELETE,
          hasAccessTooltip: 'Uklonite korisnika iz grupe',
          callback: (index: number) => {
            this._subs.add(
              this._client
                .removeKorisnikFromGrupa(
                  new RemoveKorisnikFromGrupaCommand({
                    korisnikId: this.korisniciTable[index].id,
                    nazivGrupe: this.nazivGrupe,
                  })
                )
                .subscribe(
                  (res) => {
                    this.korisniciTable.splice(index, 1);
                    this._alertService.addSuccessMsg(res.messages[0]);
                  },
                  (error) => {
                    this._subs.add(
                      error.subscribe((x: string) => {
                        this._alertService.addFailedMsg(x);
                      })
                    );
                  }
                )
            );
          },
        },
      ],
    });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
