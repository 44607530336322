import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DynamicDialogConfig,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import { AutomatskoKreiranjeKategorijaDialogComponent } from '../../automatsko-kreiranje-kategorija-dialog/automatsko-kreiranje-kategorija-dialog.component';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { ArhivskaKnjigaService } from 'libs/core/moduli/podesavanje/src/lib/arhivska-knjiga/arhivska-knjiga-service';

@Component({
  selector: 'kodit-arhivska-exist-step1',
  templateUrl: './arhivska-exist-step1.component.html',
  styleUrls: ['./arhivska-exist-step1.component.scss'],
})
export class ArhivskaExistStep1Component implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  showObavestenjeBox: boolean = false;
  showImportComponent: boolean = false;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  constructor(
    private _dialogService: DynamicDialogService,
    private arhivskaService: ArhivskaKnjigaService
  ) {}

  ngOnInit(): void {}

  importKategorijeAuto() {
    this.dialogConfig.header = 'Upozorenje';
    this.dialogConfig.customSubmitButton = {
      label: 'Izgeneriši',
      icon: 'fa-solid fa-square-plus',
    };

    const ref = this._dialogService.open(
      AutomatskoKreiranjeKategorijaDialogComponent,
      this.dialogConfig
    );
    
    this.showObavestenjeBox = true;
    this.showImportComponent = false;
  }

  importKategorijeManual() {
    this.arhivskaService.setKategorijeImportovaneAutomatski = false;
    this.showObavestenjeBox = false;
    this.showImportComponent = true;
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
