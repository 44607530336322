import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalNumber',
})
export class DecimalNumberPipe implements PipeTransform {
  constructor(private _decimalPipe: DecimalPipe) {}

  transform(value: number | string, ...args: any[]): any {
    return this._decimalPipe.transform(value, '.2-6');
  }
}
