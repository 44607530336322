import { Component, OnDestroy, OnInit } from '@angular/core';
import { GetIosTableQuery, IIosRacunDto, IosRacunDto, IzvestajiClient, PaginatedResultOfIosRacunDto } from '@kodit/core/data-api';
import { ConfigService, GodinaRacunaDropdownService, LocationService } from '@kodit/core/services';
import { TableConfig } from '@kodit/core/shared-ui';
import { Store } from '@ngrx/store';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { selectFilterByKey } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.selectors';
import { setFilter } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'kodit-ios-izvestaj-table',
  templateUrl: './ios-izvestaj-table.component.html',
  styleUrls: ['./ios-izvestaj-table.component.scss']
})
export class IosIzvestajTableComponent implements OnInit, OnDestroy {

  paginatedData: PaginatedResultOfIosRacunDto;
  tableConfig!: TableConfig;
  faktureTable: IosRacunDto[] | undefined;

  filters: GetIosTableQuery;

  skipFirst: number | undefined = 0;
  numberOfRowsDisplayed: number | undefined = 0;
  isFirstLoad = true;
  private _isBusy = false;

  private _subs: Subscription = new Subscription();

  constructor(
    private _client: IzvestajiClient,
    private _storage: Store,
    private _locationService: LocationService,
    private _configService: ConfigService,
    private _godinaService: GodinaRacunaDropdownService
  ) { }


  ngOnInit(): void {

    this._subs.add(
      this._storage.select(selectFilterByKey('IOS') as any).subscribe((data) => {
        if (data) {
          this.filters = JSON.parse(data as string);
          this.skipFirst = this.filters.pageNumber ?? 0;
          this.numberOfRowsDisplayed = this.filters.pageSize ?? 0;
        } else {
          this.filters = new GetIosTableQuery({
            pageNumber: this.skipFirst,
            pageSize: this.numberOfRowsDisplayed
          });
        }
        this._loadTable();
      })
    );
    this.setTableConfig();
    this._subs.add(
      this._godinaService.getForm().valueChanges.subscribe(() => {
        this.skipFirst = 0;
        this.filters.pageNumber = 0;
        this._updateStorage();
        this._loadTable();
      })
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'IOS izveštaj',
      isLazy: true,
      lazyCallbackFunction: (event: LazyLoadEvent) => {
        if (!this.isFirstLoad) {
          this.skipFirst = event.first;
          this.numberOfRowsDisplayed = event.rows;
        }
        this._updateFilterDataAndReload();
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        }
      },
      columns: [
        {
          field: 'brojFakture',
          header: 'Broj i stranka',
          type: 'link',
          linkCallbackFunction: (itemIndex: number) =>
            this._goToInfo(itemIndex),
          columns: [
            {
              field: 'rutiranjeStranke',
              subField: 'naziv',
              header: '',
              type: 'link',
              linkCallbackFunction: (itemIndex: number) =>
                this._goToStranka(itemIndex),
            },
          ],
        },
        {
          field: 'datumIzdavanja',
          header: 'Datum izdavanja',
          type: 'text'
        },
        {
          field: 'datumValute',
          header: 'Datum valute',
          type: 'text'
        },
        {
          field: 'duguje',
          header: 'Duguje',
          type: 'currency'
        },
        {
          field: 'potrazuje',
          header: 'Potražuje',
          type: 'currency'
        },
        {
          field: 'uplaceno',
          header: 'Uplaćeno',
          type: 'currency'
        },
        {
          field: 'saldo',
          header: 'Saldo',
          type: 'currency'
        }
      ]
    });
  }

  private _loadTable() {

    if (this._isBusy) {
      return;
    }

    this._configService.setIsBusy = true;
    this._isBusy = true;

    if (!this.filters) {
      this.filters = new GetIosTableQuery({
        pageNumber: this.skipFirst,
        pageSize: this.numberOfRowsDisplayed
      });
    }

    this.filters.pageNumber = this.skipFirst;
    this.filters.pageSize = this.numberOfRowsDisplayed;

    this._subs.add(
      this._client.getForTableIos(this.filters)
        .pipe(
          finalize(() => {
            this._configService.setIsBusy = false;
            this._isBusy = false;
          })
        )
        .subscribe((res) => {
          this.paginatedData = res;
          this.faktureTable = res.data;
        })
    );
  }

  private _getPaginatedIndex(index: number): number {
    return index - this.skipFirst!;
  }

  private _getPaginatedItem(index: number): IIosRacunDto {
    return this.faktureTable![this._getPaginatedIndex(index)];
  }

  private _updateFilterDataAndReload() {
    if (
      (this.skipFirst !== this.filters.pageNumber ||
        this.numberOfRowsDisplayed !== this.filters.pageSize) &&
      !this.isFirstLoad
    ) {
      this.filters.pageNumber = this.skipFirst;
      this.filters.pageSize = this.numberOfRowsDisplayed;
      this._updateStorage();
    }
  }

  private _updateStorage() {
    this._storage.dispatch(
      setFilter({
        key: 'IOS',
        filter: JSON.stringify(this.filters),
      })
    );
  }

  private _goToInfo(rowIndex: number): void {
    const racun = this._getPaginatedItem(rowIndex);
    this._locationService.routeToRacunKartica(
      racun.id!,
      racun.tipRacuna!,
      racun.vrstaRacuna!,
      true
    );
  }

  private _goToStranka(rowIndex: number) {
    const racun = this._getPaginatedItem(rowIndex);
    this._locationService.routeToStrankaKartica(
      racun.rutiranjeStranke!.strankaId!,
      racun.rutiranjeStranke!.tipStranke!,
      true
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}


