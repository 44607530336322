import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceReportTaxItem } from '@kodit/core/data-api';

@Pipe({
  name: 'sumTaxItems',
})
export class SumTaxItemsPipe implements PipeTransform {
  transform(values: InvoiceReportTaxItem[]): number {
    return values.reduce((acc, curr) => acc + curr.amount!, 0);
  }
}
