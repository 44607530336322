import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  GetFizickaLicaTableQuery,
  IGetFizickaLicaTableQuery,
} from '@kodit/core/data-api';
import { AdvancedFilter } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { FizickoLiceFormService } from 'libs/core/form-definitions/src/lib/stranka/fizicko-lice-form.service';
import { Subscription } from 'rxjs';
import { FizickoLiceFilterService } from './fizicko-lice-filter.service';
import { Store } from '@ngrx/store';
import { setFilter } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';

@Component({
  selector: 'kodit-fizicko-lice-filter',
  templateUrl: './fizicko-lice-filter.component.html',
  styleUrls: ['./fizicko-lice-filter.component.scss'],
})
export class FizickoLiceFilterComponent
  implements OnInit, OnDestroy, AdvancedFilter<IGetFizickaLicaTableQuery> {
  /** Props */
  filterData: IGetFizickaLicaTableQuery;
  form: FormGroupTypeSafe<IGetFizickaLicaTableQuery>;

  /** Subs */
  private _subs: Subscription = new Subscription();

  @Output() onSubmitForm = new EventEmitter<IGetFizickaLicaTableQuery>();

  constructor(
    private _formService: FizickoLiceFormService,
    private _fizickoLiceFilterService: FizickoLiceFilterService,
    private _storage: Store
  ) {}

  ngOnInit(): void {
    this.form = this._formService.GetFizickaLicaTableRequest(this.filterData);
  }

  onFilterSubmit() {
    this._storage.dispatch(
      setFilter({
        key: 'FIZICKA_LICA',
        filter: JSON.stringify(this.form.value),
      })
    );
    // this._fizickoLiceFilterService.setAdvanceFilterSubmitted = {
    //   filterData: this.form.value,
    //   shouldApplyFilter: true,
    // };
  }

  onFilterHide(shouldReset: boolean) {
    this._fizickoLiceFilterService.setAdvanceFilterSubmitted = {
      filterData: shouldReset
        ? new GetFizickaLicaTableQuery()
        : this.form.value,
      shouldApplyFilter: shouldReset,
    };
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
