import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { replace } from '@kodit/core/shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-error-info-min-length',
  templateUrl: './error-info-min-length.component.html',
  styleUrls: ['./error-info-min-length.component.scss'],
})
export class ErrorInfoMinLengthComponent implements OnInit, OnDestroy {
  /** Props */
  private _sub = new Subscription();
  initMsg: string =
    'Polje mora imati najmanje #MIN karaktera, a trenutno ima #CURR';
  errorMsg: string;

  /** I/O */
  @Input() formCtrl: AbstractControl;

  constructor() {}

  ngOnInit(): void {
    this.setErrorMsg();

    this._sub = this.formCtrl.valueChanges.subscribe((newValue) => {
      this.setErrorMsg();
    });
  }

  private setErrorMsg() {
    if (this.formCtrl.errors?.['minlength']) {
      this.errorMsg = replace(
        this.initMsg,
        '#CURR',
        this.formCtrl.errors?.['minlength']?.actualLength
      ).replace('#MIN', this.formCtrl.errors?.['minlength']?.requiredLength);
    }
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
