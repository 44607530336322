import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { environment } from 'apps/core/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private IsBusy$ = new BehaviorSubject<boolean>(false);

  public ShowSpinner: Subject<boolean>;

  constructor() {
    this.ShowSpinner = new Subject<boolean>();
  }

  get resourceApiURI() {
    return `${environment.apiBaseUrl}/api`;
  }

  get getIsBusy() {
    return this.IsBusy$.asObservable();
  }

  set setIsBusy(data: boolean) {
    this.IsBusy$.next(data);
  }

  /**
   * U slucaju kada se radi promena firme i zelimo da prikazemo loader
   */
  tenantSwitchLoader$ = new BehaviorSubject<boolean>(false);
  get getTenantSwitchLoader() {
    return this.tenantSwitchLoader$.asObservable();
  }
  set setTenantSwitchLoader(data: boolean) {
    this.tenantSwitchLoader$.next(data);
  }

  /**
   * Govori nam da li na tabeli racuna treba da prikazamo button za sync statusa sa SEFOM
   */
  // shouldShowSyncWithSefStatusButton$ = new BehaviorSubject<boolean>(false);
  // get getShouldShowSyncWithSefStatusButton() {
  //   return this.shouldShowSyncWithSefStatusButton$.asObservable();
  // }
  // set setShouldShowSyncWithSefStatusButton(data: boolean) {
  //   this.shouldShowSyncWithSefStatusButton$.next(data);
  // }
}
