import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

/** Moduli  */
import { CoreSharedModule } from '@kodit/core/shared';
import { CoreSharedUiModule } from '@kodit/core/shared-ui';
import { CenovnikRoutingModule } from './cenovnik-routing.module';

/** PrimeNg */
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { FieldsetModule } from 'primeng/fieldset';
import { DropdownModule } from 'primeng/dropdown';

/** Components */
import { CenovnikTableComponent } from './cenovnik-table/cenovnik-table.component';
import { CenovnikFormComponent } from './cenovnik-form/cenovnik-form.component';
import { CenovnikStavkeTableComponent } from './cenovnik-stavke-table/cenovnik-stavke-table.component';
import { DefinisanjeCenaFormComponent } from './definisanje-cena/definisanje-cena-form/definisanje-cena-form.component';
import { CenovnikListComponent } from './cenovnik-list/cenovnik-list.component';

@NgModule({
  imports: [
    CommonModule,
    CoreSharedModule,
    CenovnikRoutingModule,
    ReactiveFormsModule,
    CoreSharedUiModule,
    InputTextModule,
    CheckboxModule,
    AutoCompleteModule,
    DialogModule,
    FieldsetModule,
    DropdownModule,
  ],
  declarations: [
    CenovnikTableComponent,
    CenovnikFormComponent,
    CenovnikStavkeTableComponent,
    DefinisanjeCenaFormComponent,
    CenovnikListComponent
  ],
  exports: [
    CenovnikTableComponent,
    CenovnikFormComponent,
    CenovnikStavkeTableComponent,
    CenovnikListComponent
  ],
})
export class CoreModuliCenovnikModule {}
