import { Injectable } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ICreateMojaFirmaCommand, IKompenzacionaObavezaDto, IPaketDropdownDto,
  IPodaciKorisnikaDto,
  TipPravnogLica,
} from '@kodit/core/data-api';
import {
  MobilniTelefonFormService,
  PravnoLiceFormService,
} from '@kodit/core/form-definitions';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RegisterService {
  form: FormGroupTypeSafe<ICreateMojaFirmaCommand>;
  isInitialized: boolean;

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _pravnoLiceFS: PravnoLiceFormService,
    private _mobilniFS: MobilniTelefonFormService
  ) {}

  get getForm(): FormGroupTypeSafe<ICreateMojaFirmaCommand> {
    if (!this.isInitialized) {
      this.init();
      this.isInitialized = true;
    }
    return this.form;
  }

  resetForm() {
    this.init();
  }

  showLoader$ = new BehaviorSubject<boolean>(false);

  get getShowLoader() {
    return this.showLoader$.asObservable();
  }

  set setShowLoader(value: boolean) {
    this.showLoader$.next(value);
  }

  updatePackage(packageType: number) {
    /* this.form.controls.basePackages.patchValue(packageType);
                             this.form.controls.packageType.updateValueAndValidity();*/

    console.error('add update package!!!');
  }

  private init() {
    this.form = this._fb.group<ICreateMojaFirmaCommand>({
      pravnoLiceDto: this._pravnoLiceFS.GetPravnoLiceFormGroup({
        tipPravnogLica: TipPravnogLica.DOMACE,
      }),
      podaciKorisnikaDto: this.getPodatkeKorisnikaFormGroup(),
      confirmationCode: new FormControl(null, {
        validators: [Validators.required],
      }),
      basePackages: this.GetPackagesFormArray([],true),
      additionalPackages : this.GetPackagesFormArray([],false),
      prihvaceniUsloviKoriscenja: new FormControl(false, {
        validators: [Validators.requiredTrue],
      }),
      brojFiskalnihKasa : new FormControl(0,{
        validators : [Validators.nullValidator]
      }),
      brojIstovremenoAktivnihKorisnika : new FormControl(0,{
        validators : [Validators.nullValidator]
      }),
      kolicinaProstora : new FormControl(0,{
        validators : [Validators.nullValidator]
      }),
      isTrial : new FormControl(false)
    });
  }

  get getBasePackages(): FormArray {
    if (!this.getForm) {
      return new FormArray([]);
    }
    return this.getForm.controls.basePackages as FormArray;
  }

  get getAdditionalPackages(): FormArray {
    if (!this.getForm) {
      return new FormArray([]);
    }
    return this.getForm.controls.additionalPackages as FormArray;
  }

  public GetPackagesFormArray(packages?: IPaketDropdownDto[], jeBasePackage? : boolean): FormArray {
    if (!packages || packages.length === 0) {
      return this._fb.array([], {
        validators: jeBasePackage ? Validators.required : Validators.nullValidator,
      });
    }
    const result: FormArray = this._fb.array([]);

    packages.forEach((packageItem) => {
      result.push(this.GetPackageFormGroup(packageItem));
    });

    return result;
  }

  public GetPackageFormGroup(
    model?: IPaketDropdownDto
  ): FormGroupTypeSafe<IPaketDropdownDto> {
    return this._fb.group<IPaketDropdownDto>({
      name : new FormControl(model?.name),
      type: new FormControl(model?.type),
      price: new FormControl(model?.price)
    });
  }

  private _passwordMatchValidator: ValidatorFn = (
    group: FormGroup
  ): ValidationErrors | null => {
    const sifra = group.get('sifra');
    const sifraRepeat = group.get('sifraRepeat');

    return sifra && sifraRepeat && sifra.value !== sifraRepeat.value
      ? { passwordMatchValidator: true }
      : null;
  };

  private getPodatkeKorisnikaFormGroup(): FormGroupTypeSafe<IPodaciKorisnikaDto> {
    return this._fb.group<IPodaciKorisnikaDto>(
      {
        ime: new FormControl(null, {
          validators: [Validators.required, Validators.maxLength(100)],
        }),
        mobilniDto: this._mobilniFS.GetMobilniFormGroup(null, true),
        prezime: new FormControl(null, {
          validators: [Validators.required, Validators.maxLength(100)],
        }),
        email: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.email,
            Validators.maxLength(50),
          ],
        }),
        sifra: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(50),
          ],
        }),
        sifraRepeat: new FormControl(null, {
          validators: [Validators.required, Validators.maxLength(50)],
        }),
      },
      { validators: this._passwordMatchValidator }
    );
  }

  private _atLeastOne: ValidatorFn = (
    array: FormArray
  ): ValidationErrors | null => {
    return !array || array.value.length === 0
      ? { atLeastOneValidator: true }
      : null;
  };
}
