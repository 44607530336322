import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  VrstaRacuna,
  UplataIsplataZaRacunDto,
  RacuniClient,
} from '@kodit/core/data-api';
import { AlertService } from '@kodit/core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-obrada-pocetnog-stanja',
  templateUrl: './obrada-pocetnog-stanja.component.html',
  styleUrls: ['./obrada-pocetnog-stanja.component.scss'],
})
export class ObradaPocetnogStanjaComponent implements OnInit, OnDestroy {
  /** Subs */
  private _getSub: Subscription = new Subscription();
  private _errorSub: Subscription = new Subscription();
  checked1: boolean = false;

  checked2: boolean = true;
  /** Props */
  pocetnoStanje: number;
  racunId: number;
  isEvidentirano: boolean;

  /** I/O */
  @Input() strankaId: number;
  @Input() vrstaRacuna: VrstaRacuna = VrstaRacuna.EMPTY;
  @Input() ukupanIznosUplate: number = 0;
  @Output() onEvidentirajUplatu = new EventEmitter<UplataIsplataZaRacunDto>();
  @Output() onPonistiEvidencijuUplate = new EventEmitter<number>();

  constructor(
    private _racuniClient: RacuniClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._load();
  }

  onEvidencijaUplate() {
    if (this.ukupanIznosUplate == 0) {
      alert('ToDo: nemate dovoljno sredstava...');
      return;
    }
    let uplaceniIznos = 0;
    if (this.ukupanIznosUplate >= this.pocetnoStanje) {
      // imamo dovoljno sredstava pa cemo da skinemo ceo preoostali iznos
      uplaceniIznos = this.pocetnoStanje;
    } else {
      // nemamo dovoljno sredstava da isplatimo ceo racun, pa cemo to odraditi delimicno
      uplaceniIznos = this.ukupanIznosUplate;
    }
    this.onEvidentirajUplatu.emit(
      new UplataIsplataZaRacunDto({
        racunId: this.racunId,
        iznos: uplaceniIznos,
      })
    );
    this.isEvidentirano = true;
  }

  onPonistavanjeEvidencijeUplate() {
    this.onPonistiEvidencijuUplate.emit(this.racunId);
    this.isEvidentirano = false;
  }

  private _load() {
    this._getSub = this._racuniClient
      .getPocetnoStanje(this.strankaId, this.vrstaRacuna)
      .subscribe(
        (res) => {
          this.pocetnoStanje = res?.data.iznos ?? 0;
          this.racunId = res?.data.racunId;
        },
        (error) => {
          this._errorSub = error.subscribe((x: string) => {
            this._alertService.addFailedMsg(x);
          });
        }
      );
  }

  ngOnDestroy(): void {
    this._getSub.unsubscribe();
    this._errorSub.unsubscribe();
  }
}
