import { Injectable } from '@angular/core';
import { TableColumnIconDto, VrstaJoba } from '@kodit/core/data-api';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ProgressItem {
  naziv?: string | undefined;
  iconDto?: TableColumnIconDto | undefined;
  progress?: number | undefined;
  vrstaJoba: VrstaJoba;
}

// export interface IProgressService {
//   //prepareTutorials(currentRoute: string): void;
//   progresses$: BehaviorSubject<ProgressItem[] | []>;
//   progresses: ProgressItem[];
//   getProgresses(curretnRoute: string): Observable<ProgressItem[] | []>;
//   set setProgresses(value: ProgressItem[] | []);
// }

@Injectable({ providedIn: 'root' })
export class ProgressService {
  progresses$ = new BehaviorSubject<ProgressItem[] | []>([]);
  progresses: ProgressItem[] = [];
  progressesMap = new Map<VrstaJoba, BehaviorSubject<number>>();

  constructor() {}

  getBehaviorSubjectForVrstaJoba(vrstaJoba: VrstaJoba) {
    return this.progressesMap.get(vrstaJoba);
  }

  getProgresses(): Observable<ProgressItem[] | []> {
    return this.progresses$.asObservable();
  }

  addProgresses(vrstaJoba: VrstaJoba) {
    if (this.canBeAdded(vrstaJoba)) {
      var progressItem: ProgressItem = { vrstaJoba: vrstaJoba };
      this.progresses.push(progressItem);
      this.progressesMap.set(vrstaJoba, new BehaviorSubject<number>(0));
      this.progresses$.next(this.progresses);
      // console.log('signal poslat u topbar');
      // console.log(this.progresses);
    }
  }

  deleteProgress(vrstaJoba: VrstaJoba) {
    this.progresses = this.progresses.filter(
      (item) => item.vrstaJoba !== vrstaJoba
    );
    this.progressesMap.delete(vrstaJoba);
    this.progresses$.next(this.progresses);
  }

  updateProgress(progresVrsta: VrstaJoba, procenat: number) {
    this.progressesMap.get(progresVrsta).next(procenat);
  }

  getProgressByVrsta(vrstaJoba: VrstaJoba): ProgressItem {
    this.progresses.forEach((progress) => {
      if (progress.vrstaJoba == vrstaJoba) {
        return progress;
      }
    });
    return null;
  }

  canBeAdded(vrstaJoba: VrstaJoba): boolean {
    var check = true;
    this.progresses.forEach((progress) => {
      if (progress.vrstaJoba == vrstaJoba) {
        check = false;
      }
    });
    return check;
  }
}
