import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  IFizickoLiceDto,
  PolFizickogLica,
  TipStranke,
  IGetFizickaLicaTableQuery,
} from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { BaseStrankaFormService } from './_base_stranka-form.service';

@Injectable({
  providedIn: 'root',
})
export class FizickoLiceFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private __baseStrankaForm: BaseStrankaFormService
  ) {}

  /**
   * Konvertuje model za fizicko lice i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model fizickog lica od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetFizickoLiceFormGroup(
    model?: IFizickoLiceDto
  ): FormGroupTypeSafe<IFizickoLiceDto> {
    const baseStrankaForm = this.__baseStrankaForm.GetBaseStrankaFormGroup(
      TipStranke.FIZIKO_LICE,
      model
    );

    return this._fb.group<IFizickoLiceDto>({
      id: baseStrankaForm.controls.id as FormControl,
      naziv: baseStrankaForm.controls.naziv as FormControl,
      kupac: baseStrankaForm.controls.kupac as FormControl,
      dobavljac: baseStrankaForm.controls.dobavljac as FormControl,
      napomena: baseStrankaForm.controls.napomena as FormControl,
      tipStranke: baseStrankaForm.controls.tipStranke as FormControl,
      adresa: baseStrankaForm.controls.adresa as FormGroup,
      tekuciRacuniDto: baseStrankaForm.controls.tekuciRacuniDto as FormArray,
      uSistemuPDVa: baseStrankaForm.controls.uSistemuPDVa as FormControl,
      isActive: baseStrankaForm.controls.isActive as FormControl,
      rokPlacanjaZaIzlazne: baseStrankaForm.controls
        .rokPlacanjaZaIzlazne as FormControl,
      rokPlacanjaZaUlazne: baseStrankaForm.controls
        .rokPlacanjaZaUlazne as FormControl,
      // kreirao: baseStrankaForm.controls.kreirao as FormControl,
      // kreiran: baseStrankaForm.controls.kreiran as FormControl,
      // poslednjeIzmenjen: baseStrankaForm.controls
      //   .poslednjeIzmenjen as FormControl,
      // poslednjiIzmenio: baseStrankaForm.controls
      //   .poslednjiIzmenio as FormControl,
      datumRodjenja: new FormControl(model?.datumRodjenja),
      ime: new FormControl(model?.ime, {
        validators: [Validators.required, Validators.maxLength(35)],
      }),
      prezime: new FormControl(model?.prezime, {
        validators: [Validators.required, Validators.maxLength(50)],
      }),
      jmbg: new FormControl(model?.jmbg, {
        validators: [Validators.minLength(13), Validators.maxLength(13)],
      }),
      pol: new FormControl(model?.pol ?? PolFizickogLica.EMPTY),
      valutaPoslovanjaId: new FormControl(model?.valutaPoslovanjaId),
      mobilniTelefon: new FormControl(model?.mobilniTelefon,{
        validators: [Validators.maxLength(20)]
      }),
    });
  }

  public GetFizickaLicaTableRequest(
    model?: IGetFizickaLicaTableQuery
  ): FormGroupTypeSafe<IGetFizickaLicaTableQuery> {
    return this._fb.group<IGetFizickaLicaTableQuery>({
      ime: new FormControl(model.ime),
      adresa: new FormControl(model.adresa),
      // ime: new FormControl(model?.ime),
      // prezime: new FormControl(model?.prezime),
      // jmbg: new FormControl(model?.jmbg),
    });
  }
}
