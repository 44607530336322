import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IKontaktDto } from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';

@Injectable({
  providedIn: 'root',
})
export class KontaktFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  /**
   * Konvertuje model za kontakt i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model kontakta od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetKontaktFormGroup(
    strankaId: number,
    model?: IKontaktDto
  ): FormGroupTypeSafe<IKontaktDto> {
    return this._fb.group<IKontaktDto>({
      id: new FormControl(model?.id),
      strankaId: new FormControl(strankaId, {
        validators: [Validators.required, Validators.min(1)],
      }),
      email: new FormControl(model?.email, {
        validators: [Validators.maxLength(50)],
      }),
      naziv: new FormControl(model?.naziv, {
        validators: [Validators.required, Validators.maxLength(150)],
      }),
      telefon1: new FormControl(model?.telefon1, {
        validators: [Validators.maxLength(20)],
      }),
      telefon2: new FormControl(model?.telefon2, {
        validators: [Validators.maxLength(20)],
      }),
      telefon3: new FormControl(model?.telefon3, {
        validators: [Validators.maxLength(20)],
      }),
      websiteUrl: new FormControl(model?.websiteUrl, {
        validators: [Validators.maxLength(150)],
      }),
      fax: new FormControl(model?.fax, {
        validators: [Validators.maxLength(50)],
      }),
      funkcija: new FormControl(model?.funkcija, {
        validators: [Validators.maxLength(50)],
      })
    });
  }
}
