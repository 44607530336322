import {
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {
  IPravnoLiceDto,
  PravnoLiceDto,
  StrankaClient,
} from '@kodit/core/data-api';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {Subscription} from 'rxjs';

interface IRequest {
  searchValue: string;
}

@Component({
  selector: 'kodit-pravno-lice-kps-autocomplete',
  templateUrl: './pravno-lice-kps-autocomplete.component.html',
  styleUrls: ['./pravno-lice-kps-autocomplete.component.scss'],
})
export class PravnoLiceKpsAutocompleteComponent implements OnInit, OnDestroy, OnChanges {
  /** Subs */
  private _searchSub: Subscription = new Subscription();

  /** Props */
  formGroup: FormGroupTypeSafe<IRequest>;
  pravnaLicaSuggestions: PravnoLiceDto[] = [];
  showEmptyMsg: boolean = false;

  /** I/O */
  @Input() parentCtrl: any = 'body';
  @Input() mustSelect: boolean = false;
  @Input() formCtrlId: string;
  @Input() shouldClearText: boolean = false;
  @Input() ctrlPlaceholder: string = 'Matični broj, PIB ili naziv...';
  @Output() onNazivChange = new EventEmitter<string>();
  @Output() onPravnoLiceChange = new EventEmitter<IPravnoLiceDto>();
  @Output() onPravnoLiceClear = new EventEmitter<void>();

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _strankaClient: StrankaClient
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.shouldClearText?.currentValue !=
      changes?.shouldClearText?.previousValue && this.shouldClearText
    ) {
      this.onClear();
      this.shouldClearText = false;
    }
  }

  ngOnInit(): void {
    this.formGroup = this._fb.group<IRequest>({
      searchValue: new FormControl(''),
    });
  }

  /**
   * Sluzi da nam da predlozena pravna lica na osnovu pretrage
   * Ukoliko forma nije tacna, nece se ni raditi pretraga
   */
  searchPravnoLice(event: any) {
    if (this.formGroup.value.searchValue.length < 1) {
      return;
    }
    this._searchSub.unsubscribe();

    this._searchSub = this._strankaClient
      .searchFromKPS(this.formGroup.value.searchValue)
      .subscribe((result) => {
        if (result.length > 0) {
          this.pravnaLicaSuggestions = result;
          this.showEmptyMsg = false;
        } else {
          this.pravnaLicaSuggestions = [];
          this.showEmptyMsg = true;
        }
      });
  }

  /**
   * Pri odabiru pravnog lica iz liste ponudenih setuje model u servisu
   * @param value odabrano pravno lice
   */
  selectionChanged(value: IPravnoLiceDto) {
    if (this.onPravnoLiceChange) {
      value.isActive = true;
      this.onPravnoLiceChange.emit(value);
    }
  }

  nazivChanged(event: any) {
    if (this.onNazivChange && event.target?.value) {
      this.onNazivChange.emit(event.target.value);
    }
  }

  onClear() {
    this.formGroup?.reset();
    if (this.onPravnoLiceClear) {
      this.onPravnoLiceClear.emit();
    }
  }

  ngOnDestroy(): void {
    this._searchSub.unsubscribe();
  }
}
