import { Injectable } from '@angular/core';
import { IGetFizickaLicaTableQuery } from '@kodit/core/data-api';
import { AdvancedFilterActionData } from '@kodit/core/shared-ui';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FizickoLiceFilterService {
  constructor() {}

  /**
   * Advanced filter methods
   */
  advanceFilterSubmitted$ = new BehaviorSubject<
    AdvancedFilterActionData<IGetFizickaLicaTableQuery>
  >(null);
  get getAdvanceFilterSubmitted() {
    return this.advanceFilterSubmitted$.asObservable();
  }
  set setAdvanceFilterSubmitted(
    data: AdvancedFilterActionData<IGetFizickaLicaTableQuery>
  ) {
    this.advanceFilterSubmitted$.next(data);
  }
}
