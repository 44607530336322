import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {
  IzmeneOsnovicaClient,
  DokumentOPovecanjuDto,
  GetDokumentOPovecanjuQuery,
} from '@kodit/core/data-api';
import { BaseService } from '@kodit/core/services';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DokumentOPovecanjuResolver
  extends BaseService
  implements Resolve<DokumentOPovecanjuDto> {
  constructor(private _client: IzmeneOsnovicaClient) {
    super();
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<DokumentOPovecanjuDto>
    | Promise<DokumentOPovecanjuDto>
    | DokumentOPovecanjuDto
    | null {
    const id = Number.parseInt(route.paramMap.get('id'));
    return this._client
      .getDokumentOPovecanju(new GetDokumentOPovecanjuQuery({ id: id }))
      .pipe(
        map((x) => x.data),
        catchError(this.handleError),
        finalize(() => {
          //this._configService.setIsBusy = false;
        })
      );
  }
}
