<form [formGroup]="predmetStavkeCtrl">
  <span class="p-input-icon-left pretraga">
    <i class="fas fa-search"></i>
    <p-autoComplete
      [suggestions]="stavke4Suggestions"
      [formControlName]="'naziv'"
      (completeMethod)="searchPredmetStavke($event)"
      (onSelect)="onSelectChange($event)"
      [placeholder]="ctrlPlaceholder"
      [appendTo]="parentCtrl"
     
      [forceSelection]="mustSelect"
      (onKeyUp)="nazivChanged($event)"
      (onClear)="onClear()"
      [inputStyleClass]="'p-pl-5'"
      [ngClass]="
        predmetStavkeCtrl.controls.naziv.touched &&
        !predmetStavkeCtrl.controls.naziv.valid
          ? 'ng-dirty'
          : ''
      "
    ></p-autoComplete>
  </span>
  <kodit-error-info-required
    [formCtrl]="predmetStavkeCtrl.controls.naziv"
  ></kodit-error-info-required>
</form>
