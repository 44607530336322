<div class="p-fluid">
  <kodit-field fieldLabel="Dodaj novog korisnika" fieldLabelFor="add-korisnik">
    <kodit-korisnik-autocomplete
      formCtrlId="dodavanja-korisnika"
      (onKorisnikChange)="handleKorisnikChange($event)"
    ></kodit-korisnik-autocomplete>
  </kodit-field>
</div>

<kodit-korisnik-za-grupu-table
  *ngIf="korisniciZaGrupu.length > 0"
  [korisniciTable]="korisniciZaGrupu"
  [nazivGrupe]="nazivGrupe"
></kodit-korisnik-za-grupu-table>
