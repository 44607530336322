import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import { IUpdateUserAccessPasswordCommand } from '@kodit/core/data-api';

@Injectable({
  providedIn: 'root',
})
export class UpdatePasswordFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  /**
   * Konvertuje model za korisnika i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model korisnika od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetFormGroup(
    korisnikEmail: string = null
  ): FormGroupTypeSafe<IUpdateUserAccessPasswordCommand> {
    return this._fb.group<IUpdateUserAccessPasswordCommand>(
      {
        korisnikEmail: new FormControl(korisnikEmail, {
          validators: Validators.required,
        }),
        newPassword: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(50),
          ],
        }),

        newPasswordCheck: new FormControl(null, {
          validators: [Validators.required, Validators.maxLength(50)],
        }),
      },
      {
        validators: this.passwordMatchValidator,
      }
    );
  }

  passwordMatchValidator: ValidatorFn = (
    group: FormGroup
  ): ValidationErrors | null => {
    const newPassword = group.get('newPassword');
    const newPasswordCheck = group.get('newPasswordCheck');

    return newPassword &&
      newPasswordCheck &&
      newPassword.value !== newPasswordCheck.value
      ? { passwordMatchValidator: true }
      : null;
  };
}
