import { Component, OnDestroy, OnInit } from '@angular/core';
import { Mode } from '@kodit/core/services';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-lager-transakcije-form',
  templateUrl: './lager-transakcije-form.component.html',
  styleUrls: ['./lager-transakcije-form.component.scss'],
})
export class LagerTransakcijeFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _getSub: Subscription = new Subscription();
  private _saveSub: Subscription = new Subscription();
  private _submitSub: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<any>;
  mode: Mode = Mode.NEW;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._getSub.unsubscribe();
    this._saveSub.unsubscribe();
    this._submitSub.unsubscribe();
  }
}
