import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { UplatnicaForm, UplatnicaFormService } from './uplatnica-form.service';
import {
  GetUplatnicaPdfQuery,
  ITekuciRacunDropdownDto,
  RacuniClient,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { FormHelper } from '@kodit/core/form-definitions';
import { SharedService } from '@kodit/core/services';

@Component({
  selector: 'kodit-uplatnica-form',
  templateUrl: './uplatnica-form.component.html',
  styleUrls: ['./uplatnica-form.component.scss'],
})
export class UplatnicaFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs = new Subscription();

  /** Props */
  racunId: number;

  /** Form */
  form: UplatnicaForm;

  constructor(
    private _dialogConfig: DynamicDialogConfig,
    private _dialogRef: DynamicDialogRef,
    private _formHelper: FormHelper,
    private _uplatnicaFormService: UplatnicaFormService,
    private _client: RacuniClient,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.racunId = this._dialogConfig.data.racunId;
    this.form = this._uplatnicaFormService.GetUplatnicaFormGroup(this.racunId);
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._submit();
      })
    );
  }
  private _submit() {
    if (!this.form?.valid) {
      this._formHelper.invalidateForm(this.form!);
      return;
    }

    this._subs.add(
      this._client
        .getUplatnicaPdf(this.form.value as GetUplatnicaPdfQuery)
        .subscribe((res) => {
          if (res.data) {
            this._download(URL.createObjectURL(res.data));
            this._dialogRef.close(true);
          }
        })
    );
  }
  private _download(pdfFileUrl: string) {
    this._sharedService.downloadUrlAsPDF(pdfFileUrl, 'Uplatnice');
  }

  updateTekuciRacun(tekuciRacunDto: ITekuciRacunDropdownDto) {
    this.form.controls.tekuciRacunPrimaoca.patchValue(
      tekuciRacunDto.ceoBrojRacuna
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
