<form [formGroup]="uslugaForm">
  <div class="p-fluid">
    <div class="p-field">
      <label for="naziv" kodit-form-label>Naziv</label>
      <kodit-text-input
        formCtrlId="naziv"
        [formCtrl]="uslugaForm.controls.predmetStavkeDto.get('naziv')"
      ></kodit-text-input>
    </div>

    <kodit-valuta-dropdown-field
      [valutaCtrl]="uslugaForm.controls.predmetStavkeDto.get('valutaId')"
    ></kodit-valuta-dropdown-field>

    <kodit-field
      fieldLabel="Jedinica mere"
      fieldLabelFor="jm"
      [isFieldRequired]="
        uslugaForm.controls.predmetStavkeDto.get('jedinicaMereDto').get('id')
          .errors?.required
      "
    >
      <kodit-jedinica-mere-dropdown
        [jedinicaMereGroup]="
          uslugaForm.controls.predmetStavkeDto.get('jedinicaMereDto')
        "
        [jedinicaMereDefaultValue]="jmDefaultValue"
        formCtrlId="jm"
      ></kodit-jedinica-mere-dropdown>
    </kodit-field>

    <kodit-text-input-field
      *ngIf="mode === editModeValue"
      [isDisabled] = true
      formCtrlLabel="Label (Oznaka)"
      [formCtrl]="uslugaForm.controls.predmetStavkeDto.get('label')"
    ></kodit-text-input-field>

    <kodit-field fieldLabel="Stopa PDV-a" *ngIf="showStopaPdv">
      <kodit-pdv-stopa-dropdown
        [pdvStopaCtrl]="uslugaForm.controls.predmetStavkeDto.get('stopaPDV')"
        [shouldSetDefaultValue]="shouldSetDefaultValue"
      ></kodit-pdv-stopa-dropdown>
    </kodit-field>

    <kodit-field fieldLabel="Oznaka PDV kategorije" *ngIf="shouldShowRazlogPdvIzuzeca">
      <kodit-pdv-kategorija-dropdown
        [pdvKategorijaCtrl]="uslugaForm.controls.predmetStavkeDto.get('oznakaPdvKategorije')"
      ></kodit-pdv-kategorija-dropdown>
    </kodit-field>

    <kodit-field fieldLabel="Razlog PDV izuzeća" *ngIf="shouldShowRazlogPdvIzuzeca">
      <kodit-pdv-razlog-izuzeca-dropdown
        [isVisible]="mode === editModeValue ? false : true"
        [pdvRazlogIzuzecaCtrl]="uslugaForm.controls.predmetStavkeDto.get('razlogPdvIzuzecaId')"
        [oznakaPdvKategorije]="uslugaForm.controls.predmetStavkeDto.get('oznakaPdvKategorije').value"
      >
      </kodit-pdv-razlog-izuzeca-dropdown>
    </kodit-field>

    <kodit-field fieldLabelFor="prodajnaCenaBezPdv" fieldLabel="ProdajnaCena">
      <kodit-number-input
        formCtrlId="prodajnaCenaBezPdv"
        [formCtrl]="
          uslugaForm.controls.predmetStavkeDto.get('prodajnaCenaBezPdv')
        "
      ></kodit-number-input>
    </kodit-field>

    <div class="p-grid">
      <div class="p-field p-col-12 p-md-12 p-lg-12">
        <label for="naziv" kodit-form-label>Šifra</label>
        <kodit-text-input
          formCtrlId="sifra"
          [formCtrl]="uslugaForm.controls.predmetStavkeDto.get('sifra')"
        ></kodit-text-input>
      </div>
    </div>
  </div>
</form>
