import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  DelovodniciClient,
  DelovodnikInfoDto,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-delovodnik-info',
  templateUrl: './delovodnik-info.component.html',
  styleUrls: ['./delovodnik-info.component.scss'],
})
export class DelovodnikInfoComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  delovodnikId: number | undefined;
  delovodnikDto: DelovodnikInfoDto | undefined;

  constructor(
    private _client: DelovodniciClient,
    private _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      if (params['id']) {
        this.delovodnikId = params['id'];
        this._load(this.delovodnikId!);
      }
    });
  }

  private _load(delovodnikId: number) {
    this._subs
      .add(
      this._client.getDelovodnikInfo(delovodnikId).subscribe((res) => {
        if (res.succeeded) {
          this.delovodnikDto = res.data;
        }
      })
      )
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
