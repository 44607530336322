<kodit-info-container>
  <ng-container body-2-of-3>
    <div class="p-d-flex p-jc-between">
      <div>
        <button
          icon="fas fa-unlock-alt"
          pButton
          type="button"
          label="Promeni šifru"
          class="p-mr-2 p-button p-button-outlined"
          (click)="openPasswordChangeDialog()"
        ></button>
        <button
          icon="fas fa-envelope"
          pButton
          type="button"
          label="Potvrdi E-Mail"
          class="p-mr-2 p-button p-button-outlined"
          (click)="sendVerificationEmail()"
        ></button>
        <button
          icon="fas fa-mobile-alt"
          pButton
          type="button"
          label="Potvrdi mobilni telefon"
          class="p-mr-2 p-button p-button-outlined"
          (click)="confirmMobile()"
        ></button>
      </div>
      <div>
        <button
          icon="far fa-edit"
          pButton
          type="button"
          label="Izmeni nalog"
          class="p-mr-2 p-button p-button-success p-button-outlined"
          (click)="onEdit()"
        ></button>
        <button
          [icon]="
            korisnikDto.isActive ? 'fas fa-user-times' : 'fas fa-user-check'
          "
          pButton
          type="button"
          [label]="
            korisnikDto.isActive ? 'Deaktiviraj nalog' : 'Aktiviraj nalog'
          "
          [ngClass]="{
            'p-button-danger': korisnikDto.isActive,
            'p-button-success': !korisnikDto.isActive
          }"
          class="p-mr-2 p-button p-button-outlined"
          (click)="changeActivity()"
        ></button>
      </div>
    </div>
    <div class="p-mt-5">
      <kodit-racun-table></kodit-racun-table>
    </div>
  </ng-container>

  <kodit-card-container
    body-1-of-3
    [isTitleWithIcon]="true"
    titleIcon="far fa-user"
  >
    <div card-title-with-icon>
      {{ korisnikDto.email }}
    </div>

    <div class="p-d-flex p-flex-column p-jc-between gap-12" card-body>
      <kodit-card-body-text
        label="Ime"
        [text]="korisnikDto.firstName"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Prezime"
        [text]="korisnikDto.lastName"
      ></kodit-card-body-text>

      <kodit-card-body-text
        label="Mobilni telefon"
        [text]="korisnikDto.mobilniDto.getMobilniTelefon"
      ></kodit-card-body-text>

      <kodit-card-body-icon
        *ngIf="korisnikDto.phoneNumberConfirmed"
        label="Mobilni telefon potvđen"
        okIconClass="kodit-success fas fa-check"
      ></kodit-card-body-icon>

      <div
        *ngIf="!korisnikDto.phoneNumberConfirmed"
        class="p-d-flex p-ai-baseline"
      >
        <span class="p-badge p-badge-danger fas fa-times"></span>
        <button
          pButton
          type="button"
          label="Potvrdi mobilni telefon"
          class="p-button-link"
          (click)="confirmMobile()"
        ></button>
      </div>

      <kodit-card-body-icon
        *ngIf="korisnikDto.emailConfirmed"
        label="E-Mail potvđen"
        okIconClass="kodit-success fas fa-check"
      ></kodit-card-body-icon>

      <div *ngIf="!korisnikDto.emailConfirmed" class="p-d-flex p-ai-baseline">
        <span class="p-badge p-badge-danger fas fa-times"></span>
        <button
          pButton
          type="button"
          label="Potvrdi E-Mail"
          class="p-button-link"
          (click)="sendVerificationEmail()"
        ></button>
      </div>
    </div>
  </kodit-card-container>
</kodit-info-container>
