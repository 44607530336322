import {FormArray, FormControl, RequiredValidator, Validators} from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import {
  IGetVozilaTableQuery, IKompenzacionaObavezaDto, IVoziloDropdownDto,
  IVoziloDto,
  IVoziloMarkaDto,
  IVoziloModelDto,
  IVoziloTipDto,
  TipArtikla,
  TipPredmeta,
  VrstaGoriva,
} from '@kodit/core/data-api';
import {
  PredmetStavkeFormGroupRequest,
  PredmetStavkeFormService,
} from '../predmet-stavke/predmet-stavke-form.service';

@Injectable({
  providedIn: 'root',
})
export class VoziloFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _basePredmetStavkeForm: PredmetStavkeFormService
  ) {}

  /**
   * Konvertuje model za vozilo i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model vozila od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetVoziloFormGroup(model?: IVoziloDto): FormGroupTypeSafe<IVoziloDto> {
    return this._fb.group<IVoziloDto>({
      predmetStavkeDto: this._basePredmetStavkeForm.GetPredmetStavkeFormGroup(
        new PredmetStavkeFormGroupRequest({
          model: model?.predmetStavkeDto,
          tipPredmeta: TipPredmeta.VOZILO,
          tipArtikla: TipArtikla.VOZILO,
          isCenaRequired: false,
          isJedinicaMereRequired: false,
          isNazivRequired: false,
        })
      ),
      adresaKorisnika: new FormControl(model?.adresaKorisnika, {
        validators: [Validators.maxLength(150)],
      }),
      adresaVlasnika: new FormControl(model?.adresaVlasnika, {
        validators: [Validators.maxLength(50)],
      }),
      boja: new FormControl(model?.boja, {
        validators: [Validators.maxLength(20)],
      }),
      brojKilovata: new FormControl(model?.brojKilovata, {}),
      brojKubika: new FormControl(model?.brojKubika, {}),
      brojMestaZaSedenje: new FormControl(model?.brojMestaZaSedenje),
      brojMestaZaStajanje: new FormControl(model?.brojMestaZaStajanje),
      brojMotora: new FormControl(model?.brojMotora, {
        validators: [Validators.maxLength(50)],
      }),
      brojOsovina: new FormControl(model?.brojOsovina),
      brojSaobracajne: new FormControl(model?.brojSaobracajne, {
        validators: [Validators.maxLength(50)],
      }),
      brojSasije: new FormControl(model?.brojSasije, {}),
      datumIsteka: new FormControl(model?.datumIsteka),
      datumIstekaRegistracije: new FormControl(model?.datumIstekaRegistracije),
      datumIzdavanja: new FormControl(model?.datumIzdavanja),
      datumPrveRegistracije: new FormControl(model?.datumPrveRegistracije),
      datumZabraneOtudjenja: new FormControl(model?.datumZabraneOtudjenja),
      drzavaIzdavanja: new FormControl(model?.drzavaIzdavanja, {
        validators: [Validators.maxLength(50)],
      }),
      godinaProizvodnje: new FormControl(model?.godinaProizvodnje),
      imeKorisnika: new FormControl(model?.imeKorisnika, {
        validators: [Validators.maxLength(50)],
      }),
      imeVlasnika: new FormControl(model?.imeVlasnika, {
        validators: [Validators.maxLength(50)],
      }),
      maksimalnaDozvoljenaTezina: new FormControl(
        model?.maksimalnaDozvoljenaTezina
      ),
      maticniBrojIliJMBGKorisnika: new FormControl(
        model?.maticniBrojIliJMBGKorisnika,
        {
          validators: [Validators.maxLength(13)],
        }
      ),
      maticniBrojIliJMBGVlasnika: new FormControl(
        model?.maticniBrojIliJMBGVlasnika,
        { validators: [Validators.maxLength(13)] }
      ),
      nosivost: new FormControl(model?.nosivost),
      odnosSnageITezine: new FormControl(model?.odnosSnageITezine),
      organIzdavanja: new FormControl(model?.organIzdavanja, {
        validators: [Validators.maxLength(50)],
      }),
      prezimeIliPoslovnoImeKorisnika: new FormControl(
        model?.prezimeIliPoslovnoImeKorisnika,
        { validators: [Validators.maxLength(150)] }
      ),
      prezimeIliPoslovnoImeVlasnika: new FormControl(
        model?.prezimeIliPoslovnoImeVlasnika,
        { validators: [Validators.maxLength(150)] }
      ),
      registarskaOznaka: new FormControl(model?.registarskaOznaka, {}),
      serijskiBroj: new FormControl(model?.serijskiBroj, {
        validators: [Validators.maxLength(50)],
      }),
      tezina: new FormControl(model?.tezina),
      trenutnaKilometraza: new FormControl(model?.trenutnaKilometraza),
      upravaIzdavanja: new FormControl(model?.upravaIzdavanja, {
        validators: [Validators.maxLength(50)],
      }),
      modelDto: this.getVoziloModelFormGroup(model?.modelDto),
      voziloMarkaDto: this.getVoziloMarkaFormGroup(model?.voziloMarkaDto),
      voziloTipDto: this.getVoziloTipFormGroup(model?.voziloTipDto),
      vrstaGoriva: new FormControl(model?.vrstaGoriva ?? VrstaGoriva.EMPTY, {
        validators: [Validators.maxLength(50)],
      }),
    });
  }

  public GetVozilaFormArray(vozila?: IVoziloDto[]): FormArray {
    if (!vozila || vozila.length === 0) {
      return this._fb.array([], {
        validators: [Validators.required],
      });
    }
    const result: FormArray = this._fb.array([]);

    vozila.forEach((obaveza) => {
      result.push(this.GetVoziloFormGroup(obaveza));
    });

    return result;
  }

  public GetVozilaDropdownFormArray(vozila?: IVoziloDropdownDto[]): FormArray {
    if (!vozila || vozila.length === 0) {
      return this._fb.array([], {
        validators: [Validators.nullValidator],
      });
    }
    const result: FormArray = this._fb.array([]);

    vozila.forEach((obaveza) => {
      result.push(this.GetVoziloDropdownFormGroup(obaveza));
    });

    return result;
  }

  public GetVoziloDropdownFormGroup(model?: IVoziloDropdownDto): FormGroupTypeSafe<IVoziloDropdownDto> {
    return this._fb.group<IVoziloDropdownDto>({
      predmetStavkeId : new FormControl(model?.predmetStavkeId),
      registarskaOznaka : new FormControl(model?.registarskaOznaka)
    });
  }

  private getVoziloModelFormGroup(
    model?: IVoziloModelDto,
    isRequired = true
  ): FormGroupTypeSafe<IVoziloModelDto> {
    return this._fb.group<IVoziloModelDto>({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv, {
        validators: isRequired
          ? [Validators.required, Validators.maxLength(50)]
          : [Validators.nullValidator],
      }),
    });
  }

  private getVoziloMarkaFormGroup(
    model?: IVoziloMarkaDto,
    isRequired = true
  ): FormGroupTypeSafe<IVoziloMarkaDto> {
    return this._fb.group<IVoziloMarkaDto>({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv, {
        validators: isRequired
          ? [Validators.required]
          : [Validators.nullValidator],
      }),
    });
  }

  private getVoziloTipFormGroup(
    model?: IVoziloTipDto,
    isRequired = true
  ): FormGroupTypeSafe<IVoziloTipDto> {
    return this._fb.group<IVoziloTipDto>({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv, {
        validators: isRequired
          ? [Validators.required]
          : [Validators.nullValidator],
      }),
    });
  }

  public GetVoziloTableRequest(
    model?: IGetVozilaTableQuery
  ): FormGroupTypeSafe<IGetVozilaTableQuery> {
    return this._fb.group<IGetVozilaTableQuery>({
      model: this.getVoziloModelFormGroup(model?.model, false),
      voziloMarka: this.getVoziloMarkaFormGroup(model?.voziloMarka, false),
      voziloTip: this.getVoziloTipFormGroup(model?.voziloTip, false),
      sifra: new FormControl(model?.sifra),
      naziv: new FormControl(model?.naziv),
    });
  }
}
