import {Injectable} from "@angular/core";
import {FormBuilderTypeSafe, FormGroupTypeSafe} from "angular-typesafe-reactive-forms-helper";
import {PrikazRabataFormService} from "../../common/prikaz-rabata-form.service";
import {VelicinaPapiraFormService} from "../../common/velicina-papira-form-service";
import {IOpstaPravilaFisklaniDto, IOpstaPravilaKonfiguracijaDto} from "@kodit/core/data-api";

@Injectable({
  providedIn: 'root',
})

export class OpstaPravilaFiskalnaFormService {

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _velicinaPapiraFS: VelicinaPapiraFormService,
  ) {
  }


  public GetOpstaPravilaFiskalnaFormGroup(
    model?: IOpstaPravilaFisklaniDto
  ): FormGroupTypeSafe<IOpstaPravilaFisklaniDto> {
    return this._fb.group<IOpstaPravilaFisklaniDto>({
      velicinaPapiraDto: this._velicinaPapiraFS.GetVelicinaPapiraFormGroup(
        model?.velicinaPapiraDto
      )
    });
  }
}
