<div
  #mask
  [ngClass]="{'p-dialog-mask':true, 'p-component-overlay p-dialog-mask-scrollblocker': config.modal !== false}"
>
  <div
    [ngClass]="{'p-dialog p-dynamic-dialog p-component':true, 'p-dialog-rtl': config.rtl, 'p-dialog-maximized': config.maximized }"
    [ngStyle]="config.style"
    [class]="config.styleClass"
    [@animation]="{value: 'visible', params: {transform: transformOptions, transition: config.transitionOptions || '150ms cubic-bezier(0, 0, 0.2, 1)'}}"
    (@animation.start)="onAnimationStart($event)"
    (@animation.done)="onAnimationEnd($event)"
    role="dialog"
    *ngIf="visible"
    [style.width]="config.width"
    [style.height]="config.height"
  >
    <div
      class="p-dialog-header"
      *ngIf="config.showHeader === false ? false: true"
    >
      <span class="p-dialog-title p-text-uppercase">{{config.header}}</span>
      <div class="p-dialog-header-icons">
        <button
          *ngIf="config.maximisable"
          type="button"
          [ngClass]="{'p-dialog-header-icon p-dialog-header-maximize p-link':true}"
          (click)="maximize()"
          (keydown.enter)="maximize()"
          tabindex="-1"
          pRipple
        >
          <span
            class="p-dialog-header-maximize-icon"
            [ngClass]="config.maximized ? config.minimizeIcon : config.maximizeIcon"
          ></span>
        </button>
        <button
          [ngClass]="'p-dialog-header-icon p-dialog-header-maximize p-link'"
          type="button"
          (click)="hide()"
          (keydown.enter)="hide()"
          *ngIf="config.closable !== false"
        >
          <span class="p-dialog-header-close-icon pi pi-times"></span>
        </button>
      </div>
    </div>
    <div class="p-dialog-content" [ngStyle]="config.contentStyle">
      <ng-template pDynamicDialogContent></ng-template>
    </div>
    <div class="p-dialog-footer" *ngIf="!config.hideFooter">
      <button
        *ngIf="!config.hideCancel"
        pButton
        pRipple
        [icon]="config.customCancelButton ?
        config.customCancelButton.icon : 'far fa-window-close'"
        type="button"
        [label]="config.customCancelButton ? config.customCancelButton.label :
        'Otkaži'"
        [ngClass]="config.customCancelButton?.class ?
        'p-button-secondary p-button-outlined ' +
        config.customCancelButton.class : 'p-button-secondary p-button-outlined'"
        (click)="hide()"
        [disabled]="shouldDisableBtn"
      ></button>

      <button
        *ngIf="!config.hideSubmit"  
        pButton
        [loading]="shouldDisableBtn"
        pRipple
        [icon]="config.customSubmitButton ? config.customSubmitButton.icon : 'fas fa-save'"
        type="submit"
        [label]="config.customSubmitButton ? config.customSubmitButton.label : 'Sačuvaj'"
        [ngClass]="config.customSubmitButton?.class ? 'p-button-primary ' + config.customSubmitButton.class : 'p-button-primary'"
        (click)="submit()"
        [disabled]="shouldDisableBtn"
      ></button>
    </div>
  </div>
</div>
