import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  EvidentirajIsplatuCommand,
  EvidentirajUplatuCommand,
  IzvodiClient,
  TipRacuna,
  UplataIsplataZaRacunDto,
  VrstaRacuna,
} from '@kodit/core/data-api';
import {AlertService, ButtonService} from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef, DynamicDialogService } from '@kodit/core/shared-ui';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { IzvodiService } from '../izvodi.service';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { EvidentiranjePredracunaOptionDialogComponent } from './evidentiranje-predracuna-option-dialog/evidentiranje-predracuna-option-dialog.component';

@Component({
  selector: 'kodit-obrada-uplate-form',
  templateUrl: './obrada-transakcije-izvoda-form.component.html',
  styleUrls: ['./obrada-transakcije-izvoda-form.component.scss'],
})
export class ObradaTransakcijeIzvodaComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  transakcijaIzvodaId: number;
  ukupanIznos: number = 0;
  evidentirano: number = 0;
  pocetnoEvidentirano: number = 0;
  raspolozivo: number = 0;
  requestList: UplataIsplataZaRacunDto[] = [];
  vrstaNeplacenihRacuna: VrstaRacuna = VrstaRacuna.IZLAZNI;
  vrstaPocetnogStanjaRacuna: VrstaRacuna = VrstaRacuna.POCETNO_STANJE_IZLAZNI;
  jeIsplata: boolean = false;
  jeFizickoLice: boolean = false;
  prikaziFakturaZa: string = 'selektovana';
  strankaId: number;
  createAvansniRacunItems: MenuItem[];
  jeDevizna: boolean;
  valutaText: string;
  pozivNaBrojDrugeStrane: string;

  /** Config */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  // ToDo: Ideja je da nekada dodamo inpute da se bira datum...
  datumIzvoda: Date = null;

  constructor(
    private _client: IzvodiClient,
    public _dialogRef: DynamicDialogRef,
    private _dialogConfig: DynamicDialogConfig,
    private _alertService: AlertService,
    private _buttonService : ButtonService,
    private _router: Router,
    private _izvodiService: IzvodiService,
    private _dialogService: DynamicDialogService
  ) {}

  ngOnInit(): void {
    // init form group regarding dialog data
    if (this._dialogConfig.data.jeIsplata) {
      this.jeIsplata = true;
      this.vrstaNeplacenihRacuna = VrstaRacuna.ULAZNI;
      this.vrstaPocetnogStanjaRacuna = VrstaRacuna.POCETNO_STANJE_ULAZNI;
    }
    //this.jeDevizna = this._dialogConfig.data.jeDevizna;
    // get
    this.valutaText = this._dialogConfig.data.valutaText;
    this.strankaId = this._dialogConfig.data.strankaId;
    this._izvodiService.setShowStrankaSearch = this.strankaId;
    this.pozivNaBrojDrugeStrane = this._dialogConfig.data.pozivNaBrojDrugeStrane;
    if (this._dialogConfig.data.jeFizickoLice) {
      this.jeFizickoLice = true;
      if (!this.strankaId) {
        this.prikaziFakturaZa = 'sve';
      }
    }
    this.transakcijaIzvodaId = this._dialogConfig.data.id;
    this.ukupanIznos = this._dialogConfig.data.ukupanIznos;
    this.pocetnoEvidentirano =
      this.ukupanIznos - this._dialogConfig.data.raspolozivIznos;
    this.evidentirano = this.pocetnoEvidentirano;

    this._subscriptions();
    this._calculateRaspolozivo();
    this._setAvansniRacunItems();
  }

  onPotvrdiUplatu() {
    if (this.requestList.length === 0) {
      this._buttonService.disableButton();
      this._dialogRef.close();
      return;
    }

    if (this.requestList.some((el) => el.tipRacuna === TipRacuna.PREDRACUN)) {
      //otovri dialog
      this._openPredracunOptionDialog();
    } else {
      this.evidentirajUplate();
    }
  }

  onPotvrdiIsplatu() {
    if (this.requestList.length === 0) {
      this._buttonService.disableButton();
      this._dialogRef.close();
      return;
    }

    if (!this.transakcijaIzvodaId) {
      this._alertService.addWarnMsg('Pogrešna transakcija izvoda');
      this._buttonService.disableButton();
      return;
    }

    this._subs.add(
      this._client
      .evidentirajIsplatu(
        new EvidentirajIsplatuCommand({
          transakcijaIzvodaId: this.transakcijaIzvodaId,
          uplataIsplataZaRacunList: this.requestList,
        })
      )
      .subscribe((result) => {
        this._alertService.addSuccessMsg(result.messages[0]);
        this._buttonService.disableButton();
        this._dialogRef.close(result.data);
      }));
  }

  addToRequestList(uplata: UplataIsplataZaRacunDto) {
    this.requestList.push(uplata);
    this.evidentirano += uplata.iznos;
    this._calculateRaspolozivo();
  }

  removeFromRequestList(racunId: number) {
    const idx = this.requestList.findIndex((x) => x.racunId === racunId);
    this.requestList.splice(idx, 1);
    this._calculateEvidentirano();
    this._calculateRaspolozivo();
  }

  onRadioButtonChange() {
    if (this.prikaziFakturaZa === 'selektovana') {
      this._izvodiService.setShowStrankaSearch = this.strankaId;
    } else {
      this._izvodiService.setShowStrankaSearch = null;
    }
  }

  createFaktura() {
    if (this.raspolozivo == 0) {
      this._alertService.addWarnMsg('Nema dovoljno sredstava');
      return;
    }

    this._dialogRef.close();

    this._router.navigate(
      [
        'racuni',
        this.jeIsplata ? 'ulazni-racuni' : 'izlazni-racuni',
        'unos',
        this.jeIsplata ? 'faktura-za-isplatu' : 'faktura-za-uplatu',
      ],
      {
        state: {
          strankaId: this.strankaId > 0 ? this.strankaId : null,
          datumPrometa: this.datumIzvoda,
          datumIzdavanja: this.datumIzvoda,
          transakcijaIzvodaId: this._dialogConfig.data.id,
        },
      }
    );
  }

  private _openPredracunOptionDialog() {
    this._buttonService.disableButton();
    this.dialogConfig.data = {
      requestList: this.requestList,
      transakcijaIzvodaId: this.transakcijaIzvodaId
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.customSubmitButton = { label: 'Potvrdi' } as any;
    this.dialogConfig.customCancelButton = { label: 'Otkaži' } as any;
    this.dialogConfig.header = 'Kreiranje računa uz predračun(e)';
    this._dialogService.open(
      EvidentiranjePredracunaOptionDialogComponent,
      this.dialogConfig
    );
    this._dialogRef.close();
  }

  private evidentirajUplate() {
    if (!this.transakcijaIzvodaId) {
      this._buttonService.disableButton();
      this._alertService.addWarnMsg('Pogrešna transakcija izvoda');
      return;
    }

    this._subs.add(this._client
      .evidentirajUplatu(
        new EvidentirajUplatuCommand({
          transakcijaIzvodaId: this.transakcijaIzvodaId,
          uplataIsplataZaRacunList: this.requestList,
        })
      )
      .subscribe((result) => {
        this._buttonService.disableButton();
        this._alertService.addSuccessMsg(result.messages[0]);
        this._dialogRef.close(result.data);
      }));
  }

  private _createAvansniRacun(stopaPDVa: number) {
    if (this.raspolozivo == 0) {
      this._alertService.addWarnMsg('Nema dovoljno sredstava');
      return;
    }
    this._dialogRef.close();
    this._router.navigate(
      [
        'racuni',
        this.jeIsplata ? 'ulazni-racuni' : 'izlazni-racuni',
        'unos',
        this.jeIsplata ? 'avansni-racun-za-isplatu' : 'avansni-racun-za-uplatu',
      ],
      {
        state: {
          strankaId: this.strankaId > 0 ? this.strankaId : null,
          datumPrometa: this.datumIzvoda,
          datumIzdavanja: this.datumIzvoda,
          transakcijaIzvodaId: this._dialogConfig.data.id,
          stopaPDVa: stopaPDVa,
        },
      }
    );
  }

  private _calculateRaspolozivo() {
    this.raspolozivo = this.ukupanIznos - this.evidentirano;
  }

  private _calculateEvidentirano() {
    this.evidentirano = this.pocetnoEvidentirano;
    this.requestList.forEach((x) => {
      this.evidentirano += x.iznos;
    });
  }

  private _setAvansniRacunItems() {
    this.createAvansniRacunItems = [
      {
        label: 'PDV 20%',
        command: () => this._createAvansniRacun(20),
      },
      {
        label: 'PDV 10%',
        command: () => this._createAvansniRacun(10),
      },
      {
        label: 'PDV 0%',
        command: () => this._createAvansniRacun(0),
      },
    ];
  }

  private _subscriptions(){
    // subscribe on dialog save button
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._buttonService.disableButton();
        if (this.jeIsplata) {
          this.onPotvrdiIsplatu();
        } else {
          this.onPotvrdiUplatu();
        }
      }));
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
