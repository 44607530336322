import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { OtpremniceClient, VrstaOtpremnice } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { OtpremnicaService } from '../../otpremnica.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'kodit-vrsta-otpremnice-dropdown',
  templateUrl: './vrsta-otpremnice-dropdown.component.html',
  styleUrls: ['./vrsta-otpremnice-dropdown.component.scss'],
})
export class VrstaOtpremniceDropdownComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];
  formCtrl: FormControl;
  jeKonvertovanje: boolean;

  /** I/O */
  @Input() isDisabled = false;
  @Output() vrstaChanged = new EventEmitter();

  constructor(
    private _client: OtpremniceClient,
    private _service: OtpremnicaService,
    private _routing: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.jeKonvertovanje = this._routing.snapshot.data.jeKonvertovanje ?? false;
    this._load();
    this.formCtrl = this._service.getVrsta;
    this.vrstaChanged.emit(this.formCtrl.value);
  }

  handleVrstaChanged(event: any) {
    this.vrstaChanged.emit(event.value);
  }

  private _load() {
    this._subs.add(
      this._client.getVrstaOtpremnice().subscribe((res) => {
        this.items = res.data.vrstaOtpremniceDtoList.map((x) => ({
          value: x.vrsta,
          label: x.vrstaStr,
          title: x.opis,
        }));
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
