import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-jmbg-input-mask',
  templateUrl: './jmbg-input-mask.component.html',
  styleUrls: ['./jmbg-input-mask.component.scss'],
})
export class JmbgInputMaskComponent implements OnInit, OnDestroy {
  private _subs = new Subscription();
  @Input() formCtrl: AbstractControl;
  @Input() ctrlPlaceholder: string = 'JMBG';
  @Output() keyReleased = new EventEmitter();

  @Input() disabled = false;

  ngOnInit(): void {
    this._subs = this.formCtrl.valueChanges.subscribe((res) => {
      if (res === '') {
        this.formCtrl.patchValue(null);
      }
    });
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onKeyUp(value: any) {
    this.keyReleased.emit(value);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
