import {Component, Input, OnInit} from '@angular/core';
import {SelectItem} from "primeng/api";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'kodit-referentni-stari-racun-radiobutton',
  templateUrl: './referentni-stari-racun-radiobutton.component.html',
  styleUrls: ['./referentni-stari-racun-radiobutton.component.scss']
})
export class ReferentniStariRacunRadiobuttonComponent implements OnInit {
  /** I/O */
  @Input() formCtrl!: FormControl;

  /** Props */
  options: SelectItem[] = [];

  constructor() {}

  ngOnInit(): void {
    this.options = [
      { label: 'Da', value: true },
      { label: 'Ne', value: false },
    ];
  }

  handleClick(item: any) {
    this.formCtrl.patchValue(item.value);
  }
}
