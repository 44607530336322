import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

/** Moduli */
import { CoreSharedModule } from 'libs/core/shared/src';
import { PodesavanjeRoutingModule } from './moduli-podesavanje-routing.module';
import { CoreModuliStrankaModule } from '@kodit/core/moduli/stranka';
import { CoreSharedSubformsModule } from '@kodit/core/shared-subforms';
import { CoreModuliBankaModule } from '@kodit/core/moduli/banka';

/** PrimeNG */
import { TreeModule } from 'primeng/tree';
import { ColorPickerModule } from 'primeng/colorpicker';

/** Components */
import { TipoviMagacinaComponent } from './platforma/tipovi-magacina/tipovi-magacina.component';
import { MojaFirmaPageComponent } from './moja-firma/moja-firma-page/moja-firma-page.component';
import { MojaFirmaInfoComponent } from './moja-firma/moja-firma-info/moja-firma-info.component';
import { ListePageComponent } from './liste/liste-page/liste-page.component';
import { JedinicaMereTableComponent } from './liste/jedinica-mere/jedinica-mere-table/jedinica-mere-table.component';
import { JedinicaMereFormComponent } from './liste/jedinica-mere/jedinica-mere-form/jedinica-mere-form.component';
import { PodesavanjaPageComponent } from './podesavanja-page/podesavanja-page.component';
import { KorisnikTableComponent } from './korisnik/korisnik-table/korisnik-table.component';
import { KorisnikPageComponent } from './korisnik/korisnik-page/korisnik-page.component';
import { KorisnikFormComponent } from './korisnik/korisnik-form/korisnik-form.component';
import { KorisnikInfoComponent } from './korisnik/korisnik-info/korisnik-info.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordFormComponent } from './korisnik/change-password-form/change-password-form.component';
import { ConfirmMobilniTelefonFormComponent } from './korisnik/confirm-mobilni-telefon-form/confirm-mobilni-telefon-form.component';
import { PodesavanjaCardComponent } from './ui/podesavanja-card/podesavanja-card.component';
import { GeneralnoPageComponent } from './generalno/generalno-page/generalno-page.component';
import { MojProfilPageComponent } from './moj-profil/moj-profil-page/moj-profil-page.component';
import { MojaFirmaDodatneInformacijeComponent } from './moja-firma/moja-firma-dodatne-informacije/moja-firma-dodatne-informacije.component';
import { MojaFirmaDodatneInformacijeFormComponent } from './moja-firma/moja-firma-dodatne-informacije-form/moja-firma-dodatne-informacije-form.component';
import { KorisnickaGrupaFormComponent } from './korisnik/korisnicka-grupa/korisnicka-grupa-form/korisnicka-grupa-form.component';
import { MagacinTipTableComponent } from './liste/magacin-tip/magacin-tip-table/magacin-tip-table.component';
import { MagacinTipFormComponent } from './liste/magacin-tip/magacin-tip-form/magacin-tip-form.component';
import { PodesavanjeRacunaPageComponent } from './racun/podesavanje-racuna-page/podesavanje-racuna-page.component';
import { OpstaPravilaComponent } from './racun/opsta-pravila/opsta-pravila.component';
import { OpstaPravilaFormComponent } from './racun/opsta-pravila-form/opsta-pravila-form.component';
import { PravilaZaBrojComponent } from './racun/pravila-za-broj/pravila-za-broj.component';
import { PravilaZaBrojFormComponent } from './racun/pravila-za-broj-form/pravila-za-broj-form.component';
import { OpomeneComponent } from './racun/opomene/opomene.component';
import { OpomeneFormComponent } from './racun/opomene-form/opomene-form.component';
import { PdfPrikazComponent } from './racun/pdf-prikaz/pdf-prikaz.component';
import { PdfPrikazFormComponent } from './racun/pdf-prikaz-form/pdf-prikaz-form.component';
import { PocetnoStanjePageComponent } from './pocetno-stanje/pocetno-stanje-page/pocetno-stanje-page.component';
import { KupciIDobavljaciImportComponent } from './pocetno-stanje/kupci-i-dobavljaci-import/kupci-i-dobavljaci-import.component';
import { OznakaPageComponent } from './oznaka/oznaka-page/oznaka-page.component';
import { OznakaFormComponent } from './oznaka/oznaka-form/oznaka-form.component';
import { PaketInfoComponent } from './paket/paket-info/paket-info.component';
import { KorisnickaGrupaDropdownComponent } from './ui/korisnicka-grupa-dropdown/korisnicka-grupa-dropdown.component';
import { MemorandumFormComponent } from './racun/memorandum-form/memorandum-form.component';
import { MemorandumTableComponent } from './memorandum/memorandum-table/memorandum-table.component';
import { DodavanjeKorisnikaFormComponent } from './korisnik/dodavanje-korisnika-form/dodavanje-korisnika-form.component';
import { UserRoleCardComponent } from './korisnik/korisnicka-grupa/user-role-card/user-role-card.component';
import { KorisnickaGrupaListComponent } from './korisnik/korisnicka-grupa/korisnicka-grupa-list/korisnicka-grupa-list.component';
import { KorisnikZaGrupuTableComponent } from './korisnik/korisnicka-grupa/korisnik-za-grupu-table/korisnik-za-grupu-table.component';
import { BiranjeOvlascenjaFromComponent } from './korisnik/korisnicka-grupa/biranje-ovlascenja-from/biranje-ovlascenja-from.component';
import { InviteKorisnikFormComponent } from './korisnik/invite-korisnik-form/invite-korisnik-form.component';
import { CoreModuliRobaModule } from '@kodit/core/moduli/roba';
import { CoreModuliUslugaModule } from '@kodit/core/moduli/usluga';
import { MojaFirmaApiCardComponent } from './moja-firma/moja-firma-api-card/moja-firma-api-card.component';
import { MojaFirmaApiCardFormComponent } from './moja-firma/moja-firma-api-card-form/moja-firma-api-card-form.component';
import { PretplataPageComponent } from './pretplata/pretplata-page/pretplata-page.component';
import { UpdatePasswordFormComponent } from './korisnik/update-password-form/update-password-form.component';
import { KupciIDobavljaciInostraniImportComponent } from './pocetno-stanje/kupci-i-dobavljaci-inostrani-import/kupci-i-dobavljaci-inostrani-import.component';
import { KonfiguracijaDeviznogRacunaFormComponent } from './racun/konfiguracija-deviznog-racuna-form/konfiguracija-deviznog-racuna-form.component';
import { KonfiguracijaDeviznogRacunaComponent } from './racun/konfiguracija-deviznog-racuna/konfiguracija-deviznog-racuna.component';
import { CoreModuliRepromaterijalModule } from '@kodit/core/moduli/repromaterijal';
import { FiskalnaKasaPageComponent } from './fiskalna-kasa/fiskalna-kasa-page/fiskalna-kasa-page.component';
import { ArhivskaKnjigaPageComponent } from './arhivska-knjiga/arhivska-knjiga-page/arhivska-knjiga-page.component';
import { SkladisneJediniceTableComponent } from './arhivska-knjiga/skladisne-jedinice/skladisne-jedinice-table/skladisne-jedinice-table.component';
import { SkladisneJediniceFormComponent } from './arhivska-knjiga/skladisne-jedinice/skladisne-jedinice-form/skladisne-jedinice-form.component';
import { FizickeLokacijeTreeComponent } from './arhivska-knjiga/fizicke-lokacije/fizicke-lokacije-tree/fizicke-lokacije-tree/fizicke-lokacije-tree.component';
import { FiziceLokacijeFormComponent } from './arhivska-knjiga/fizicke-lokacije/fizicke-lokacije-form/fizice-lokacije-form/fizice-lokacije-form.component';
import { SkladisnaJedinicaDropdownComponent } from './arhivska-knjiga/skladisne-jedinice/skladisna-jedinica-dropdown/skladisna-jedinica-dropdown.component';
import { PoreskeStopFiskalneKaseComponent } from './fiskalna-kasa/poreske-stop-fiskalne-kase/poreske-stop-fiskalne-kase.component';
import { DelovodniDokumentPravilaZaBrojComponent } from './delovodnik/delovodni-dokument-pravila-za-broj/delovodni-dokument-pravila-za-broj.component';
import { DelovodniDokumentPraviloFormComponent } from './delovodnik/delovodni-dokument-pravilo-form/delovodni-dokument-pravilo-form.component';
import { BezbednosniElementiTableComponent } from './fiskalna-kasa/bezbednosni-elementi-table/bezbednosni-elementi-table.component';
import { SertifikatFiskalnaUploadFormComponent } from './fiskalna-kasa/sertifikat-fiskalna-upload-form/sertifikat-fiskalna-upload-form.component';
import { ApiMenadzmentPageComponent } from './api-menadzment/api-menadzment-page/api-menadzment-page.component';
import { BezbednosniElementInfoDialogComponent } from './fiskalna-kasa/bezbednosni-elementi-table/bezbednosni-element-info-dialog/bezbednosni-element-info-dialog.component';
import { UpdateBezbednosniElementFormComponent } from './fiskalna-kasa/bezbednosni-elementi-table/update-bezbednosni-element-form/update-bezbednosni-element-form.component';
import { OpstaPravilaFiskalnaComponent } from './fiskalna-kasa/opsta-pravila-fiskalna/opsta-pravila-fiskalna.component';
import { OpstaPravilaFiskalnaFormComponent } from './fiskalna-kasa/opsta-pravila-fiskalna-form/opsta-pravila-fiskalna-form.component';
import { PredefinisaniTekstFiskalniComponent } from './fiskalna-kasa/predefinisani-tekst-fiskalni/predefinisani-tekst-fiskalni.component';
import { PredefinisaniTekstFiskalniFormComponent } from './fiskalna-kasa/predefinisani-tekst-fiskalni-form/predefinisani-tekst-fiskalni-form.component';
import { VelicinaPapiraDropdownComponent } from './fiskalna-kasa/velicina-papira-dropdown/velicina-papira-dropdown.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CoreSharedModule,
    PodesavanjeRoutingModule,
    CoreModuliStrankaModule,
    CoreSharedSubformsModule,
    CoreModuliBankaModule,
    TreeModule,
    ColorPickerModule,
    CoreModuliRobaModule,
    CoreModuliUslugaModule,
    CoreModuliRepromaterijalModule,
  ],
  declarations: [
    KorisnikFormComponent,
    TipoviMagacinaComponent,
    MojaFirmaPageComponent,
    MojaFirmaInfoComponent,
    ListePageComponent,
    JedinicaMereTableComponent,
    JedinicaMereFormComponent,
    PodesavanjaPageComponent,
    KorisnikTableComponent,
    KorisnikPageComponent,
    KorisnikInfoComponent,
    ChangePasswordFormComponent,
    ConfirmMobilniTelefonFormComponent,
    PodesavanjaCardComponent,
    GeneralnoPageComponent,
    MojProfilPageComponent,
    MojaFirmaDodatneInformacijeComponent,
    MojaFirmaDodatneInformacijeFormComponent,
    KorisnickaGrupaFormComponent,
    MagacinTipTableComponent,
    MagacinTipFormComponent,
    PodesavanjeRacunaPageComponent,
    OpstaPravilaComponent,
    OpstaPravilaFormComponent,
    PravilaZaBrojComponent,
    PravilaZaBrojFormComponent,
    OpomeneComponent,
    OpomeneFormComponent,
    PdfPrikazComponent,
    PdfPrikazFormComponent,
    PocetnoStanjePageComponent,
    KupciIDobavljaciImportComponent,
    OznakaPageComponent,
    OznakaFormComponent,
    PaketInfoComponent,
    UserRoleCardComponent,
    KorisnickaGrupaListComponent,
    DodavanjeKorisnikaFormComponent,
    KorisnikZaGrupuTableComponent,
    BiranjeOvlascenjaFromComponent,
    InviteKorisnikFormComponent,
    KorisnickaGrupaDropdownComponent,
    MemorandumFormComponent,
    MemorandumTableComponent,
    MojaFirmaApiCardComponent,
    MojaFirmaApiCardFormComponent,
    PretplataPageComponent,
    UpdatePasswordFormComponent,
    KupciIDobavljaciInostraniImportComponent,
    KonfiguracijaDeviznogRacunaFormComponent,
    KonfiguracijaDeviznogRacunaComponent,
    FiskalnaKasaPageComponent,
    ArhivskaKnjigaPageComponent,
    SkladisneJediniceTableComponent,
    SkladisneJediniceFormComponent,
    FizickeLokacijeTreeComponent,
    FiziceLokacijeFormComponent,
    SkladisnaJedinicaDropdownComponent,
    BezbednosniElementiTableComponent,
    PoreskeStopFiskalneKaseComponent,
    DelovodniDokumentPravilaZaBrojComponent,
    DelovodniDokumentPraviloFormComponent,
    SertifikatFiskalnaUploadFormComponent,
    ApiMenadzmentPageComponent,
    BezbednosniElementInfoDialogComponent,
    UpdateBezbednosniElementFormComponent,
    OpstaPravilaFiskalnaComponent,
    OpstaPravilaFiskalnaFormComponent,
    PredefinisaniTekstFiskalniComponent,
    PredefinisaniTekstFiskalniFormComponent,
    VelicinaPapiraDropdownComponent,
  ],
  exports: [SkladisneJediniceTableComponent, FizickeLokacijeTreeComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModuliPodesavanjeModule {}
