<kodit-dropdown
  [formCtrlId]="formCtrlId"
  [options]="pravilaOptions"
  [formCtrl]="formCtrl"
  ctrlPlaceholder="Izaberite pravilo"
  ctrlStyleClass="pravilo-select"
>
  <ng-template #item let-obj>
    <div class="p-text-left">{{ obj.label }}</div>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <div class="p-text-bold">{{ item.label }}</div>
    <div class="p-text-left">{{ 'dodaj pravilo' }}</div>
  </ng-template>
</kodit-dropdown>
