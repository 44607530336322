<div
  card
  class="k-card promet-card p-d-flex p-jc-between p-ai-center p-flex-wrap"
>
  <div *ngIf="label1 != ''" class="p-d-flex p-flex-column">
    <div class="p-pr-2 p-label p-mb-2">
      {{ label1 }}:
      <kodit-helper-icon
        [helperText]="jeAvans ? 'Odnosi se na ukupan avansiran iznos' : 'Odnosi se na račune u statusu NACRT, POSLATO, PRIHVACENO, NOVO, SLANJE'"
        class="p-ml-2"
      ></kodit-helper-icon>
    </div>
    <div class="p-text-left summary-value">
      {{ jeAvans
      ? (racuniSaldo?.ukupanAvansiranIznos | currencyNumber)
      : (racuniSaldo?.ukupanIznos | currencyNumber) }}
    </div>
  </div>
  <div *ngIf="label2 != ''" class="p-d-flex p-flex-column">
    <div class="p-pr-2 p-label p-mb-2">
      {{ label2 }}:
      <kodit-helper-icon
        [helperText]="jeAvans ? 'Odnosi se na avansiran iznos koji smo povezali/iskoristili na fakturama' : 'Odnosi se na račune u statusu POSLATO i PRIHVACENO'"
        class="p-ml-2"
      ></kodit-helper-icon>
    </div>
    <div class="p-text-left summary-value">
      {{ jeAvans
      ? ('u izradi')
      : (racuniSaldo?.placanja | currencyNumber) }}
    </div>
  </div>

  <div class="p-d-flex p-flex-column">
    <div class="p-pr-2 p-label p-mb-2">
      {{ jeAvans ? 'Preostalo' : 'Saldo' }}:
      <kodit-helper-icon
        [helperText]="jeAvans ? 'Odnosi se na avansiran iznos koji  još uvek nismo povezali/iskoristili na fakturama' : 'Odnosi se na račune u statusu NACRT, POSLATO, PRIHVACENO, NOVO, SLANJE'"
        class="p-ml-2"
      ></kodit-helper-icon>
    </div>
    <div
      [ngClass]="
        racuniSaldo?.placanja - racuniSaldo?.ukupanIznos >= 0
          ? 'positive'
          : 'negative'
      "
      class="summary-value"
    >
      {{ jeAvans
      ? ('u izradi')
      : (racuniSaldo?.placanja - racuniSaldo?.ukupanIznos | currencyNumber) }}
    </div>
  </div>

  <div
    *ngIf="label3 != '' || label4 != ''"
    style="border-right: 2px solid #2E394B"
  >
    <hr />
  </div>

  <div *ngIf="label3 != ''" class="p-d-flex p-flex-column">
    <div class="p-pr-2 p-label p-mb-2">{{ label3 }}:</div>
    <div class="p-text-left summary-value">
      {{ racuniSaldo?.iznosPDV10 | currencyNumber }}
    </div>
  </div>
  <div *ngIf="label4 != ''" class="p-d-flex p-flex-column">
    <div class="p-pr-2 p-label p-mb-2">{{ label4 }}:</div>
    <div class="summary-value">
      {{ racuniSaldo?.iznosPDV20 | currencyNumber }}
    </div>
  </div>
</div>
<!--<p-skeleton styleClass="p-mb-2" width="10rem"></p-skeleton>-->
