<div [formGroup]="form">
  <div class="p-fluid">
    <!-- Stopa PDV i USistemuPDV-->
    <kodit-field
      fieldLabel="Da li je kompanija u sistemu PDV-a?"
      fieldLabelFor="u-pdv"
    >
      <p-selectButton
        [options]="options"
        [formControl]="form.controls.uSistemuPDVa"
      ></p-selectButton>
      <kodit-error-info-required
        [formCtrl]="form.controls.uSistemuPDVa"
      ></kodit-error-info-required>
    </kodit-field>
    <kodit-field fieldLabel="Podrazumevana stopa">
      <kodit-pdv-stopa-dropdown
        [pdvStopaCtrl]="form.controls.stopaPDV"
      ></kodit-pdv-stopa-dropdown>
    </kodit-field>
    <!-- Razlog pdv izuzeca -->
    <kodit-field fieldLabel="Oznaka PDV kategorije" *ngIf="shouldShowRazlogPdvIzuzeca">
      <kodit-pdv-kategorija-dropdown
        [pdvKategorijaCtrl]="form.controls.oznakaPdvKategorije"
      ></kodit-pdv-kategorija-dropdown>
    </kodit-field>
    <kodit-field fieldLabel="Razlog PDV izuzeća" *ngIf="shouldShowRazlogPdvIzuzeca">
      <kodit-pdv-razlog-izuzeca-dropdown
        [isVisible]="true"
        [pdvRazlogIzuzecaCtrl]="form.controls.razlogPdvIzuzecaId"
        [oznakaPdvKategorije]="form.controls.oznakaPdvKategorije.value"
      >
      </kodit-pdv-razlog-izuzeca-dropdown>
    </kodit-field>
    <!-- Pravni oblik -->
    <kodit-field fieldLabel="Pravni oblik" fieldLabelFor="pravni-oblik">
      <kodit-pravni-oblik-dropdown
        formCtrlId="pravni-oblik"
        [formCtrl]="form.controls.pravniOblik"
      ></kodit-pravni-oblik-dropdown>
    </kodit-field>
    <kodit-field fieldLabel="Dodajte logo Vaše kompanije" fieldLabelFor="logo">
      <kodit-logo-upload></kodit-logo-upload>
    </kodit-field>
  </div>
</div>
