import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IKonfiguracijaMojeFirmeDto } from '@kodit/core/data-api';

@Injectable({
  providedIn: 'root',
})
export class KonfiguracijaMojeFirmeFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  /**
   * Konvertuje model za konfiguraciju moje firme i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Model konfiguracije od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetKonfiguracijaMojeFirmeFormGroup(
    model?: IKonfiguracijaMojeFirmeDto
  ): FormGroupTypeSafe<IKonfiguracijaMojeFirmeDto> {
    return this._fb.group<IKonfiguracijaMojeFirmeDto>({
      logoNaziv: new FormControl(model?.logoNaziv),
      upisaniKapital: new FormControl(model?.upisaniKapital),
      aprBD: new FormControl(model?.aprBD),
      pePDV: new FormControl(model?.pePDV),
      websiteUrl: new FormControl(model?.websiteUrl),
      email: new FormControl(model?.email),
      vlasnici: new FormControl(model?.vlasnici),
      zastupnici: new FormControl(model?.zastupnici),
      valutaId: new FormControl(model?.valutaId),
      valutaText: new FormControl(model?.valutaText),
      uSistemuPDVa: new FormControl(model?.uSistemuPDVa ?? false),
      stopaPDV: new FormControl(model?.stopaPDV),
      ulicaIBroj: new FormControl(model?.ulicaIBroj),
      grad: new FormControl(model?.grad),
      postanskiBroj: new FormControl(model?.postanskiBroj),
      imeOsobeOvlasceneZaPotpisivanje: new FormControl(
        model?.imeOsobeOvlasceneZaPotpisivanje
      ),
      gln: new FormControl(model?.gln),
      razlogPdvIzuzecaId : new FormControl(model?.razlogPdvIzuzecaId),
      oznakaPdvKategorije : new FormControl(model?.oznakaPdvKategorije)
    });
  }
}
