import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CreatePdvEvidencijaZbirna39Command,
  IPdvEvidencijaZbirna39Dto,
  PdvEvidencijeClient,
  SefKorigujPdvEvidencijaZbirna39Command,
  UpdatePdvEvidencijaZbirna39Command,
} from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { PdvEvidencijaZbirna39FormService } from '../pdv-evidencija-zbirna39-form.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import {
  AlertService,
  LocationService,
  SharedService,
} from '@kodit/core/services';
import { FormHelper } from '@kodit/core/form-definitions';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'kodit-pdv-evidencija-zbirna-form39',
  templateUrl: './pdv-evidencija-zbirna-form39.component.html',
  styleUrls: ['./pdv-evidencija-zbirna-form39.component.scss'],
})
export class PdvEvidencijaZbirnaForm39Component implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  form!: FormGroupTypeSafe<IPdvEvidencijaZbirna39Dto>;
  isKoriguj: boolean;
  shouldDisableForm: boolean = false;

  constructor(
    private _fs: PdvEvidencijaZbirna39FormService,
    private _route: ActivatedRoute,
    private _confirmationService: ConfirmationService,
    private _locationService: LocationService,
    private _formHelper: FormHelper,
    private _sharedService: SharedService,
    private _client: PdvEvidencijeClient,
    private _alert: AlertService,
    private _breadcrumbService: BreadcrumbService
  ) {
    this.isKoriguj = false;
  }

  ngOnInit(): void {
    this.shouldDisableForm = this._route.snapshot.data['isPregledKorigovane'];
    this.isKoriguj = this._route.snapshot.data['isKoriguj'];
    this.form = this._fs.getForm(
      this._route.snapshot.data['evidencija'],
      this.isKoriguj
    );

    this.form.controls.status?.disable();

    this._setBreadcrumb();

    this._subs.add(
      this.form.controls.avansIznosUplate20!.valueChanges.subscribe((res) => {
        this.form.controls.avansOsnovica20?.patchValue(res! / 1.2);
        this.form.controls.avansObracunatiPdv20?.patchValue(res! - res! / 1.2);
      })
    );

    this._subs.add(
      this.form.controls.avansIznosUplate10!.valueChanges.subscribe((res) => {
        this.form.controls.avansOsnovica10?.patchValue(res! / 1.1);
        this.form.controls.avansObracunatiPdv10?.patchValue(res! - res! / 1.1);
      })
    );
  }

  handleCancel() {
    this._confirmationService.confirm({
      message: 'Ukoliko otkažete sav nesnimljen sadržaj će biti izgubljen.',
      acceptLabel: 'Otkaži',
      rejectLabel: 'Ostani na stranici',
      header: 'Potvrdite otkazivanje',
      icon: 'far fa-check-circle',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._locationService.routeToCancelPdvEvidencija();
      },
    });
  }

  handleRouteBack() {
    this._locationService.routeBack();
  }

  handleSave() {
    // check is it valid
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    // everything is ok, let's save it
    if (this.isKoriguj) {
      this._sharedService.displayLoadingScreen(
        true,
        'Korigovanje zbirne pdv evidencije...'
      );
      this._subs.add(
        this._client
          .sefKorigujPdvEvidencijaZbirna39(
            this.form.value.id!,
            this.form.value as SefKorigujPdvEvidencijaZbirna39Command
          )
          .subscribe((res) => {
            if (res.succeeded) {
              this._alert.addSuccessMsg(res.data!);
              this._locationService.routeBack();
            }
            this._alert.addFailedMsg(res.messages![0]);
          })
      );
    } else if (this._fs.jeEdit) {
      this._sharedService.displayLoadingScreen(
        true,
        'Ažuriranje zbirne pdv evidencije...'
      );
      this._subs.add(
        this._client
          .updateZbirna39(
            this.form.value.id!,
            this.form.value as UpdatePdvEvidencijaZbirna39Command
          )
          .subscribe((res) => {
            if (res.succeeded) {
              this._alert.addSuccessMsg(res.data!);
              this._locationService.routeBack();
            }
            this._alert.addFailedMsg(res.messages![0]);
          })
      );
    } else {
      this._sharedService.displayLoadingScreen(
        true,
        'Kreiranje zbirne pdv evidencije...'
      );
      this._subs.add(
        this._client
          .createZbirna39(this.form.value as CreatePdvEvidencijaZbirna39Command)
          .subscribe((res) => {
            if (res.succeeded) {
              this._alert.addSuccessMsg(res.data!);
              this._locationService.routeBack();
            }
            this._alert.addFailedMsg(res.messages![0]);
          })
      );
    }
  }

  private _setBreadcrumb() {
    this._breadcrumbService.set(
      '@izmenaZbirnePdvEvidencije',
      `Izmena zbirne pdv evidencije  ${this.form.value.broj}`
    );
    this._breadcrumbService.set(
      '@korigovanjeZbirnePdvEvidencije',
      `Korigovanje zbirne pdv evidencije  ${this.form.getRawValue().broj}`
    );
    this._breadcrumbService.set(
      '@pregledKorigovaneZbirnePdvEvidencije',
      `Pregled korigovane pdv evidencije: ${this.form.getRawValue().broj}`
    );
  }

  ngOnDestroy() {
    this._fs.resetForm();
    this._subs.unsubscribe();
  }
}
