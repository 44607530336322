export const apiBaseUrlClean = 'api.dev.ekompanija.com';
export const kpsBaseUrlClean = 'kps.demo.ekompanija.com';
export const mixpanelToken = 'cfdb4ed2e350ca7b27a24c74aac59530';

export const environment = {
  production: true,
  apiBaseUrl: `https://${apiBaseUrlClean}`,
  kpsBaseUrl: `https://${kpsBaseUrlClean}`,
  version: '1.97.0',
  firebase: {
    apiKey: 'AIzaSyBIn6t7-jzO4ZLhsZo93dxWxoJifAxo2lQ',
    authDomain: 'ekompanija-4fde1.firebaseapp.com',
    projectId: 'ekompanija-4fde1',
    storageBucket: 'ekompanija-4fde1.appspot.com',
    messagingSenderId: '142074200795',
    appId: '1:142074200795:web:b3449af77c42766d9342fe',
    measurementId: 'G-H0BGE7FFFZ',
  },
};
