<ul class="topbar-menu">
  <li
    class="profile-item"
    [ngClass]="{
      'active-menuitem fadeInDown': appLayout.topbarCompanyMenuActive
    }"
  >
    <a
      class="profile-item-a"
      href="#"
      (click)="appLayout.onTopbarCompanyMenuButtonClick($event)"
    >
      <!-- <div id="company-initials" [ngStyle]="companyImageStyle"></div> -->
      <span style="margin-left: 8px; font-size: 14px;" class="company-name" [pTooltip]="tenantNameTooltip">{{
        tenantName
      }}</span>
    </a>
    <ul class="profile-menu fade-in-up company-menu">
      <li></li>
      <li>
        <a routerLink="/podesavanja/kompanija">
          <i class="pi pi-cog"></i>
          <span>Podešavanje kompanije</span>
        </a>
      </li>
    </ul>
  </li>
</ul>
