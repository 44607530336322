import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { UslugaRoutingModule } from './usluga-routing.module';

/** Moduli */
import { CoreSharedUiModule } from '@kodit/core/shared-ui';

/** PrimeNg */
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';

/** Components */
import { UslugaTableComponent } from './usluga-table/usluga-table.component';
import { UslugaFormComponent } from './usluga-form/usluga-form.component';
import { UslugaFilterComponent } from './usluga-filter/usluga-filter.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UslugaRoutingModule,
    CoreSharedUiModule,
    InputTextModule,
    DropdownModule,
  ],
  declarations: [
    UslugaTableComponent,
    UslugaFormComponent,
    UslugaFilterComponent,
  ],
  exports: [UslugaTableComponent, UslugaFormComponent],
})
export class CoreModuliUslugaModule {}
