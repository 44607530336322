import { Component, Input, OnInit } from '@angular/core';
import { IAdresaDto } from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';

@Component({
  selector: 'kodit-adresa-form',
  templateUrl: './adresa-form.component.html',
  styleUrls: ['./adresa-form.component.scss'],
})
export class AdresaFormComponent implements OnInit {
  /** I/O */
  @Input() addressForm: FormGroupTypeSafe<IAdresaDto>;

  constructor() {}

  ngOnInit(): void {}
}
