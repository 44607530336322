import { FormArray, FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IMailReceiverDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MailFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetMailReceiversFormArray(
    modelList?: IMailReceiverDto[],
    isRequired: boolean = true
  ): FormArray {
    if (!modelList || modelList.length === 0) {
      return this._fb.array([], {
        validators: isRequired
          ? [Validators.required]
          : Validators.nullValidator,
      });
    }

    const result: FormArray = this._fb.array([], {
      validators: isRequired ? Validators.required : Validators.nullValidator,
    });
    modelList.forEach((o) => {
      result.push(this.GetMailReceiverFormGroup(o, isRequired));
    });
    return result;
  }

  public GetMailReceiverFormGroup(
    model?: IMailReceiverDto,
    isRequired: boolean = true
  ): FormGroupTypeSafe<IMailReceiverDto> {
    return this._fb.group<IMailReceiverDto>({
      email: new FormControl(model?.email, {
        validators: isRequired
          ? [Validators.required, Validators.email]
          : [Validators.email],
      }),
      name: new FormControl(model?.name),
      isNew: new FormControl(model?.isNew ?? false),
    });
  }
}
