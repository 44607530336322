import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { GetLagerTransakcijeTableQuery, IGetLagerTransakcijeTableQuery } from '@kodit/core/data-api';
import { AdvancedFilter } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { LagerTransakcijeFormService } from 'libs/core/form-definitions/src/lib/lager/lager-transakcije-form.service';
import { Subscription } from 'rxjs';
import { LagerTransakcijeFilterService } from './lager-transakcije-filter.service';

@Component({
  selector: 'kodit-lager-transakcije-filter',
  templateUrl: './lager-transakcije-filter.component.html',
  styleUrls: ['./lager-transakcije-filter.component.scss']
})
export class LagerTransakcijeFilterComponent implements OnInit, OnDestroy, 
  AdvancedFilter<IGetLagerTransakcijeTableQuery> {

  /** Props */
  filterData: IGetLagerTransakcijeTableQuery;
  form: FormGroupTypeSafe<IGetLagerTransakcijeTableQuery>;

  editMode: boolean = true;

  /** Subs */
  private _subs: Subscription = new Subscription();

  @Output() onSubmitForm = new EventEmitter<IGetLagerTransakcijeTableQuery>();

  constructor(
    private _formService: LagerTransakcijeFormService,
    private _lagerTransakcijeFilterService: LagerTransakcijeFilterService
  ) {}

  ngOnInit(): void {
    this.form = this._formService.GetLagerTransakcijeTableRequest(this.filterData);
  }

  onFilterSubmit() {
    this._lagerTransakcijeFilterService.setAdvancedFilterSubmitted = {
      filterData: this.form.value,
      shouldApplyFilter: true,
    }
  }

  onFilterHide(shouldReset: boolean) {
    this._lagerTransakcijeFilterService.setAdvancedFilterSubmitted = {
      filterData: shouldReset
        ? new GetLagerTransakcijeTableQuery()
        : this.form.value,
      shouldApplyFilter: shouldReset,
    };
  }

  ngOnDestroy(): void {
      this._subs.unsubscribe()
  }
}
