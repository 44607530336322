import {round as _round} from 'lodash';

/**
 * Computes number rounded to precision.
 * @param number The number to round.
 * @param precision The precision to round to.
 * @returns Returns the rounded number.
 */
export function round(number: number, precision: number = 0): number {
  return _round(number, precision);
}

/**
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators#Unary_plus objasnjenje za + operator }
 */
export function sumIfNotNull(...numbers: number[]): number | null {
  let result = null;
  for (let i = 0; i < numbers.length; ++i) {
    if (numbers[i] === null || numbers[i] === undefined) {
      continue;
    }
    result += numbers[i];
  }
  return result === null ? null : +result.toFixed(2);
}

/**
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators#Unary_plus objasnjenje za + operator }
 */
export function subIfNotNull(...numbers: number[]): number | null {
  let result = null;
  for (let i = 0; i < numbers.length; ++i) {
    if (numbers[i] === null || numbers[i] === undefined) {
      continue;
    }
    if (!result) {
      result = numbers[i];
    } else {
      result -= numbers[i];
    }
  }
  return result === null ? null : +result.toFixed(2);
}

/**
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators#Unary_plus objasnjenje za + operator }
 */
export function multiplyIfNotNull(...numbers: number[]): number | null {
  let result = null;
  for (let i = 0; i < numbers.length; ++i) {
    if (numbers[i] === null || numbers[i] === undefined) {
      continue;
    }
    if (!result) {
      result = numbers[i];
    } else {
      result *= numbers[i];
    }
  }
  return result === null ? null : +result.toFixed(2);
}

/**
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators#Unary_plus objasnjenje za + operator }
 * @param numForCondition parametar nad kojem radimo uslog (ako je null vraticemo null)
 * @param otherNums parametri koje zeleimo da mnozimo, ukoliko prvi param nije null
 */
export function multiplyIfNotNullConditionally(
  numForCondition: number | null,
  ...otherNums: number[]
): number | null {
  if (!numForCondition) {
    return null;
  }

  let result = numForCondition;
  for (let i = 0; i < otherNums.length; ++i) {
    if (otherNums[i] === null || otherNums[i] === undefined) {
      continue;
    }
    result *= otherNums[i];
  }
  return +result.toFixed(2);
}
