import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  ISyncUlazneRacuneCommand,
  SefClient,
  SyncUlazneRacuneCommand,
} from '@kodit/core/data-api';
import { AlertService, SharedService } from '@kodit/core/services';
import { DynamicDialogRef } from '@kodit/core/shared-ui';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-sinhronizacija-form',
  templateUrl: './sinhronizacija-form.component.html',
  styleUrls: ['./sinhronizacija-form.component.scss'],
})
export class SinhronizacijaFormComponent implements OnInit {
  /** Subs */
  private _subs = new Subscription();

  /** Props */
  statusOptions: SelectItem[] = [];
  maxAllowedDate: Date = new Date();

  /** Report */
  form: FormGroupTypeSafe<ISyncUlazneRacuneCommand>;

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _dialogRef: DynamicDialogRef,
    private _sefClient: SefClient,
    private _sharedService: SharedService,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._loadStatuses();
    this._loadForm();

    // on first dialog open
    this.maxAllowedDate.setDate(
      this.form.controls?.datumOd?.value?.getDate() + 30
    );

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );

    this._subs.add(
      this.form.controls.datumOd.valueChanges.subscribe((res) => {
        this.maxAllowedDate = new Date();
        this.maxAllowedDate.setDate(res.getDate() + 30);
      })
    );
  }

  private _save() {
    if (!this._dateValidators()) {
      return;
    }

    // everything is ok , let's sync it
    this._sharedService.displayLoadingScreen(
      true,
      'Sinhronizacija sa SEF-om...'
    );
    this._subs.add(
      this._sefClient
        .syncUlazneRacune(this.form.value as SyncUlazneRacuneCommand)
        .subscribe((res) => {
          this._alertService.addSuccessMsg(res.data);
          if (res.messages[0].length > 0) {
            this._alertService.addWarnMsg(res.messages[0]);
          }
          this._dialogRef.close(true);
        })
    );
  }

  private _loadForm() {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    this.form = this._fb.group<ISyncUlazneRacuneCommand>({
      statusRacuna: new FormControl('New', {
        validators: [Validators.required],
      }),
      datumOd: new FormControl(firstDayOfMonth, {
        validators: [Validators.required],
      }),
      datumDo: new FormControl(currentDate, {
        validators: [Validators.required],
      }),
    });
  }

  private _dateValidators(): boolean {
    const datumDo: Date = this.form.controls.datumDo.value;
    const datumOd: Date = this.form.controls.datumOd.value;

    if (!datumOd || !datumDo) {
      return false;
    }

    if (datumDo < datumOd) {
      this._alertService.addWarnMsg('Datum do ne može biti pre datuma od!');
      return false;
    }
    const razlika = Math.ceil(
      (datumDo.getTime() - datumOd.getTime()) / (1000 * 3600 * 24)
    );

    if (razlika > 30) {
      this._alertService.addWarnMsg(
        'Maksimalni dozvoljen opseg izmedju datuma je 30 dana!'
      );
      return false;
    }

    return true;
  }

  private _loadStatuses() {
    this.statusOptions = [
      {
        value: 'New',
        label: 'Novo',
      },
      {
        value: 'Seen',
        label: 'Pregledano',
      },
      {
        value: 'Approved',
        label: 'Prihvaćeno',
      },
      {
        value: 'Rejected',
        label: 'Odbijeno',
      },
      {
        value: 'Storno',
        label: 'Stornirano',
      },
    ];
  }
}
