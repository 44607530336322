<div class="p-field-checkbox">
  <label
    [for]="formCtrlId"
    style="margin-left: 0; margin-right: 1rem"
    kodit-form-label
    >{{ labelText }}</label
  >
  <p-checkbox
    [id]="formCtrlId"
    [formControl]="formCtrl"
    [binary]="true"
  ></p-checkbox>
</div>
