import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LagerTableComponent } from 'libs/core/shared-subforms/src/lib/lager/lager-table/lager-table.component';
import { RobaInfoComponent } from './roba-info/roba-info.component';
import { RobaTableComponent } from './roba-table/roba-table.component';

const routes: Routes = [
  {
    path: '',
    component: LagerTableComponent,
    //resolve: { robe: RobeResolver }, ucitao u app-routing na ceo module
  },
  {
    path: ':id',
    component: RobaInfoComponent,
    data: {
      breadcrumb: {
        alias: 'robaId',
      },
    },
  },
  // {
  //   path: ':id/:lagerId',
  //   component: RobaInfoComponent,
  //   data: {
  //     breadcrumb: {
  //       alias: 'lagerId',
  //     },
  //   },
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class RobaRoutingModule {}
