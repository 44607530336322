import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
  IObradiSefRacunCommand,
  IRelevantniDokumentListItemDto,
  IStavkaRacunaDto,
  NeobradjenRacunDto,
  ObradaFormRequest,
  ObradiSefRacunCommand,
  RacunDto,
  RacuniClient,
  SefClient,
  StatusRacuna,
  TipRacuna,
  VrstaFakture,
  VrstaRacuna,
} from '@kodit/core/data-api';
import {
  AlertService,
  LocationService,
  RacunService,
  SharedService,
  TIP_RACUNA_TO_TIP_ENTITETA,
} from '@kodit/core/services';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { FormHelper } from '@kodit/core/form-definitions';
import {
  DynamicDialogConfig,
  DynamicDialogRef,
  DynamicDialogService,
} from '@kodit/core/shared-ui';
import { OdobravanjeOdbacivanjeRacunaFormComponent } from '../odobravanje-odbacivanje-racuna-form/odobravanje-odbacivanje-racuna-form.component';
import {
  DialogSize,
  OnDynamicDialogInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { LoaderService } from 'libs/core/shared-ui/src/lib/loader/loader.service';
import { finalize } from 'rxjs/operators';
import { FormArray, FormControl } from '@angular/forms';
import { BaseRacunFormService } from 'libs/core/form-definitions/src/lib/racun/_base_racun-form.service';

@Component({
  selector: 'kodit-neobradjen-racun-form',
  templateUrl: './neobradjen-racun-form.component.html',
  styleUrls: ['./neobradjen-racun-form.component.scss'],
})
export class NeobradjenRacunFormComponent
  implements OnInit, OnDestroy, OnDynamicDialogInit {
  /** Props */
  racunDto: RacunDto;
  neobradjenRacun: NeobradjenRacunDto;
  pdfInnerHtml: SafeHtml;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);
  showOdobriOdbaci: boolean;
  isAutomatski: boolean;
  racunForm: FormGroupTypeSafe<IObradiSefRacunCommand>;
  dokumentOSmanjenjuValue: TipRacuna = TipRacuna.DOKUMENT_O_SMANJENJU;
  relevantniDokumenti: IRelevantniDokumentListItemDto[] = [];
  private _subs: Subscription = new Subscription();

  constructor(
    private _client: SefClient,
    private _racunClient: RacuniClient,
    private _alertService: AlertService,
    private _route: ActivatedRoute,
    private _domSanitizer: DomSanitizer,
    private _service: RacunService,
    private _locationService: LocationService,
    private _formHelper: FormHelper,
    private _dialogService: DynamicDialogService,
    private _loader: LoaderService,
    private _fs: FormBuilderTypeSafe,
    private _baseRacunForm: BaseRacunFormService,
    private _racunService: RacunService,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    const formConfiguration = this._route.snapshot.data.formConfiguration;
    if (
      this._route.snapshot.data.neobradjenRacun instanceof ObradaFormRequest
    ) {
      this.neobradjenRacun = this._route.snapshot.data.neobradjenRacun.neobradjenRacunDto;
      this.racunDto = this._route.snapshot.data.neobradjenRacun.racunDto;
      this.isAutomatski = true;
    } else {
      this.neobradjenRacun = this._route.snapshot.data.neobradjenRacun;
    }

    this._getRelevantneDokumente();

    this.showOdobriOdbaci =
      this.neobradjenRacun.statusRacuna === StatusRacuna.NOVO ||
      this.neobradjenRacun.statusRacuna ===
        StatusRacuna.PODSETNIK_PONOVO_POSLAT ||
      this.neobradjenRacun.statusRacuna === StatusRacuna.PREGLEDANO ||
      this.neobradjenRacun.statusRacuna === StatusRacuna.PRIMLJENO;

    this.racunForm = this._fs.group<IObradiSefRacunCommand>({
      brojFiskalnogIsecka: new FormControl(),
      racunDto: this.isAutomatski
        ? this._baseRacunForm.GetBaseRacunFormGroup(
            this.neobradjenRacun.tipRacunaDto.tip,
            VrstaRacuna.ULAZNI,
            TIP_RACUNA_TO_TIP_ENTITETA[this.neobradjenRacun.tipRacunaDto.tip],
            formConfiguration,
            this.racunDto
          )
        : this._service.initRacunFormFromNeobradjenRacun(
            this.neobradjenRacun,
            formConfiguration
          ),
      sefId: new FormControl(this.neobradjenRacun.sefId),
      vrstaFakture: new FormControl(VrstaFakture.DOMACA),
    });

    this._racunService.setRacunForm = this.racunForm.controls.racunDto as any;
  }

  handleObradi() {
    console.log(this.racunForm.value);

    // check is it valid
    if (!this.racunForm.valid) {
      console.log(this.racunForm);
      // check all stavke pdv da li se poklapaju
      for (var i = 0; i < this.neobradjenRacun.stavkeDto.length; i++) {
        if (
          this.neobradjenRacun.stavkeDto[i].pdvStopa !==
          this.racunForm.value.racunDto.stavkeDto[i].stopaPDV
        ) {
          this._alertService.addWarnMsg(
            `Nije moguće vezivanje za ${
              i + 1
            }. stavku. PDV stope se ne podudaraju.`
          );
          return;
        }
      }
      this._formHelper.invalidateForm(this.racunForm);
      return;
    }

    // Popunjavamo stavku zbog racunanja na BE
    (this.racunForm.controls.racunDto.get(
      'stavkeDto'
    ) as FormArray).value.forEach((stavka: IStavkaRacunaDto) => {
      stavka.prodajnaCenaBezPdv = stavka.predmetStavkeDto.prodajnaCenaBezPdv;
    });

    this._loader.setLoadingText = 'Obrađujem ulazni račun...';
    this._loader.setShowLoader = true;

    //this._service.calculateUkupno();
    this._subs.add(
      this._client
        .obradi(this.racunForm.value as ObradiSefRacunCommand)
        .pipe(
          finalize(() => {
            this._loader.reset();
          })
        )
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsgs(res.messages);
            this._locationService.routeBack();
          } else {
            this._alertService.addFailedMsgs(res.messages);
          }
        })
    );
  }

  handleOdbaci() {
    this.dialogConfig.data = {
      jePrihvaceno: false,
      maximized: true,
      racunId: this.neobradjenRacun.sefId,
      brojRacuna: this.neobradjenRacun.broj,
    };
    this.dialogConfig.header = 'Odbacivanje računa';
    this.openDialog(this.dialogConfig);
  }

  handleOdobri() {
    this.dialogConfig.data = {
      jePrihvaceno: true,
      maximized: true,
      racunId: this.neobradjenRacun.sefId,
      brojRacuna: this.neobradjenRacun.broj,
    };
    this.dialogConfig.header = 'Prihvatanje računa';
    this.openDialog(this.dialogConfig);
  }

  onReturn() {
    this._locationService.routeToRacunTable(
      TipRacuna.ULAZNA_FAKTURA,
      VrstaRacuna.ULAZNI,
      4
    );
  }

  openDialog(config: DynamicDialogConfig) {
    config.customSubmitButton = {
      icon: 'fas fa-save',
      label: 'Da',
      class: 'mw-90',
    };
    config.customCancelButton = {
      icon: 'far fa-window-close',
      label: 'Ne',
    };
    let ref: DynamicDialogRef = this._dialogService.open(
      OdobravanjeOdbacivanjeRacunaFormComponent,
      config
    );

    this._subs.add(
      ref.onClose.subscribe((res?: boolean) => {
        if (res) {
          this._locationService.routeToRacunTable(
            TipRacuna.ULAZNA_FAKTURA,
            VrstaRacuna.ULAZNI,
            0
          );
        } else if (res === false) {
          this._locationService.routeToRacunTable(
            TipRacuna.ULAZNA_FAKTURA,
            VrstaRacuna.ULAZNI,
            4
          );
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    this._service.resetService();
  }

  private _getRelevantneDokumente() {
    this._subs.add(
      this._racunClient
        .getRelevantneDokumente(this.racunDto.id)
        .subscribe((res) => {
          if (res.succeeded) {
            this.relevantniDokumenti = res.data.responseList;
            this._setPdfFromStorage(
              res.data.responseList.find((x) => !x.jePrilog).storageFileName
            );
          }
          // ako res nije succeeded, znaci da nemamo nista u storage,a trebali bismo pa uploadujemo
          else {
            this._sharedService.displayLoadingScreen(
              true,
              'Pribavljanje dokumenata...'
            );
            this._subs.add(
              this._racunClient.uploadMissingPdf(this.racunDto.id).subscribe((res) => {
                if (res.succeeded) {
                  this.relevantniDokumenti = res.data
                    .responseList as IRelevantniDokumentListItemDto[];
                  this._setPdfUrl(
                    res.data.responseList.find((x) => !x.jePrilog).file
                  );
                }
              })
            );
          }
        })
    );
  }

  private _setPdfFromStorage(originalFileName: string) {
    if (!originalFileName) {
      this._alertService.addWarnMsg('Ne postoji podrazumevani pdf fajl');
      return;
    }

    this._subs.add(
      this._racunClient
        .getRelevantniDokumentUrl(originalFileName)
        .subscribe((res) => {
          this._setPdfUrl(res.data.file);
        })
    );
  }

  private _setPdfUrl(url: string) {
    this.pdfInnerHtml = this._domSanitizer.bypassSecurityTrustHtml(
      `<object data="${url}" type="application/pdf" class="embed-responsive-item">
     Object ${url} failed
   </object>`
    );
  }
}
