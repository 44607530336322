import { Component, OnDestroy, OnInit } from '@angular/core';
import { IKontaktDto, KontaktDto, KontaktiClient } from '@kodit/core/data-api';
import { AlertService, Mode } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';
import { KontaktFormService } from '@kodit/core/form-definitions';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';

@Component({
  selector: 'kodit-kontakt-form',
  templateUrl: './kontakt-form.component.html',
  styleUrls: ['./kontakt-form.component.scss'],
})
export class KontaktFormComponent implements OnInit, OnDestroy {
  /** Subscriptions */
  private _getSub: Subscription = new Subscription();
  private _saveSub: Subscription = new Subscription();
  private _submitSub: Subscription = new Subscription();

  /** Props */
  form: FormGroupTypeSafe<IKontaktDto>;
  mode: Mode = Mode.NEW;

  constructor(
    private _client: KontaktiClient,
    private _alertService: AlertService,
    public _dialogConfig: DynamicDialogConfig,
    public _dialogRef: DynamicDialogRef,
    private _kontakFS: KontaktFormService
  ) {}

  ngOnInit(): void {
    this.form = this._kontakFS.GetKontaktFormGroup(
      this._dialogConfig.data?.strankaId
    );

    this._submitSub = this._dialogRef.onSubmit.subscribe(() => {
      this.save();
    });

    // get mode and patch if edit
    if (this._dialogConfig.data && this._dialogConfig.data.id) {
      this.getById(this._dialogConfig.data.id);
      this.mode = Mode.EDIT;
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.save();
    }
  }

  save(): void {
    if (!this.form.valid) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.get(key).markAsDirty();
      });
      return;
    }

    const request = new KontaktDto(this.form.value);

    if (this.mode === Mode.EDIT) {
      this._saveSub = this._client
        .update(this.form.value.id, request)
        .subscribe(
          (result) => {
            this._alertService.addSuccessMsg(`Kontakt je uspešno izmenjen.`);
            this._dialogRef.close(result);
          },
          (error) => {
            this._alertService.addFailedMsg(error);
          }
        );
    } else {
      this._saveSub = this._client.create(request).subscribe(
        (result) => {
          this._alertService.addSuccessMsg(`Kontakt je uspešno kreiran.`);
          this._dialogRef.close(result);
        },
        (error) => {
          this._alertService.addFailedMsg(error);
        }
      );
    }
  }

  getById(id: number): void {
    this._getSub = this._client.getById(id).subscribe(
      (model) => {
        this.form.patchValue(model.data);
      },
      (error) => {
        this._alertService.addFailedMsg(error);
      }
    );
  }

  ngOnDestroy(): void {
    this._getSub.unsubscribe();
    this._submitSub.unsubscribe();
    this._saveSub.unsubscribe();
  }
}
