import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  DmsClient,
  IDatotekaTreeDto,
  VrstaDatoteke,
} from '@kodit/core/data-api';
import { AlertService, SharedService, b64toBlob } from '@kodit/core/services';
import { Subscription } from 'rxjs';
import { DatotekaService } from '../datoteka-service';

@Component({
  selector: 'kodit-dokument-preview',
  templateUrl: './dokument-preview.component.html',
  styleUrls: ['./dokument-preview.component.scss'],
})
export class DokumentPreviewComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  innerHtml: SafeHtml | undefined;
  blobUrl!: string;
  jePdfOrImage: boolean | undefined = true;

  selectedDokument!: IDatotekaTreeDto | undefined;

  constructor(
    private _client: DmsClient,
    private _alertService: AlertService,
    private _domSanitizer: DomSanitizer,
    private _datotekaService: DatotekaService,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._datotekaService.getSelectedDatoteka.subscribe((datoteka) => {
        if (
          datoteka &&
          datoteka.data?.vrstaDatoteke === VrstaDatoteke.DOKUMENT
        ) {
          if (
            !this.selectedDokument ||
            datoteka.data.id !== this.selectedDokument.id
          ) {
            this.selectedDokument = datoteka.data;
            this._initialize(datoteka.data.id!);
          }
        } else {
          this.innerHtml = undefined;
          this.selectedDokument = undefined;
        }
      })
    );
  }

  download() {
    this._sharedService.downloadByUrl(
      this.blobUrl,
      this.selectedDokument!.naziv
    );
  }

  private _initialize(dokumentId: number) {
    this._subs.add(
      this._client.getDokumentForPreview(dokumentId).subscribe((res) => {
        if (res.succeeded) {
          if (this._isImageOrPdf(res.data?.dto?.extension!)) {
            this._setPdfOrImage(
              res.data?.dto?.fileUrl!,
              res.data?.dto?.extension!
            );
            this.jePdfOrImage = true;
            return;
          }
          this.jePdfOrImage = false;
        } else {
          this._alertService.addWarnMsg(
            'Došlo je do greške prilikom učitavanja dokumenta.'
          );
        }
      })
    );
  }

  private _isImageOrPdf(extension: string) {
    return (
      extension === 'jpg' ||
      extension === 'png' ||
      extension === 'jpeg' ||
      extension === 'pdf'
    );
  }

  private _setPdfOrImage(fileUrl: string, extension: string) {
    if (extension === 'jpg' || extension === 'png' || extension === 'jpeg') {
      this.innerHtml = this._domSanitizer.bypassSecurityTrustHtml(
        "<img src='" +
          fileUrl +
          "' alt='Image preview' class='embed-responsive-item'>"
      );
    } else if (extension === 'pdf') {
      this.innerHtml = this._domSanitizer.bypassSecurityTrustHtml(
        `<object data="${fileUrl}" type="application/pdf" class="embed-responsive-item-pdf">
          Object ${fileUrl} failed
        </object>`
      );
    }
  }

  ngOnDestroy(): void {
    this.jePdfOrImage = undefined;
    this._subs.unsubscribe();
  }
}
