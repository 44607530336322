import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  PravnoLiceDto,
  PoslovnaJedinicaTableDto,
  PoslovneJediniceClient,
} from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-moja-firma-page',
  templateUrl: './moja-firma-page.component.html',
  styleUrls: ['./moja-firma-page.component.scss'],
})
export class MojaFirmaPageComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /** Props */
  activeIndex: number = 0;
  mojaFirma: PravnoLiceDto;
  poslovneJedinice: PoslovnaJedinicaTableDto[] = [];
  activeIndex1: number = 0;

  uSistemuPdv: boolean = true;
  text: string;
  constructor(
    private _route: ActivatedRoute,
    private _poslovneJediniceClient: PoslovneJediniceClient
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._poslovneJediniceClient
        .getForTable()
        .subscribe((res ) => {
          this.poslovneJedinice = res.data.responseList;
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
