import { Component, OnInit } from '@angular/core';
//import { CoreAuthIdpService } from '@kodit/core/auth-idp';
import { PrimeNGConfig } from 'primeng/api';

const SERBIAN_CALENDAR = {
  firstDayOfWeek: 1,
  dayNames: [
    'Nedelja',
    'Ponedeljak',
    'Utorak',
    'Sreda',
    'Četvrtak',
    'Petak',
    'Subota',
  ],
  dayNamesShort: ['Ned', 'Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub'],
  dayNamesMin: ['Ne', 'Po', 'Ut', 'Sr', 'Če', 'Pe', 'Su'],
  monthNames: [
    'Januar',
    'Februar',
    'Mart',
    'April',
    'Maj',
    'Jun',
    'Jul',
    'Avgust',
    'Septembar',
    'Oktobar',
    'Novembar',
    'Decembar',
  ],
  monthNamesShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Maj',
    'Jun',
    'Jul',
    'Avg',
    'Sep',
    'Okt',
    'Nov',
    'Dec',
  ],
  today: 'Danas',
  clear: 'Obriši',
  dateFormat: 'dd.mm.yy',
  weekHeader: 'Wk',
};

@Component({
  selector: 'kodit-core-app',
  templateUrl: './core-app.component.html',
  styleUrls: ['./core-app.component.scss'],
})
export class CoreAppComponent implements OnInit {
  constructor(private _config: PrimeNGConfig) {}

  ngOnInit() {
    this._config.setTranslation({
      accept: 'Prihvati',
      reject: 'Odbaci',
      ...SERBIAN_CALENDAR,
      emptyMessage: 'Nema podataka.',
      noFilter: 'noFil',
      emptyFilterMessage: 'Ne postoji',
      upload: 'Uvezi',
      cancel: 'Otkaži',
      dateFormat: 'dd.mm.yy',
      clear: 'Obriši',
      choose: 'Odaberi',
    });
  }
}
