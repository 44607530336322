import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  IPrviLoginMojaFirmaCommand,
  MojaFirmaClient,
  PrviLoginMojaFirmaCommand,
} from '@kodit/core/data-api';
import { AlertService, MixpanelService } from '@kodit/core/services';
import {
  FormGroupTypeSafe,
  FormBuilderTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { CoreService } from '../../core-service';

@Component({
  selector: 'kodit-welcome-kompanija-card',
  templateUrl: './welcome-kompanija-card.component.html',
  styleUrls: ['./welcome-kompanija-card.component.scss'],
})
export class WelcomeKompanijaCardComponent implements OnInit, OnDestroy {
  private _sub = new Subscription();

  form: FormGroupTypeSafe<IPrviLoginMojaFirmaCommand>;

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _client: MojaFirmaClient,
    private _alertService: AlertService,
    private _coreService: CoreService,
    private _mixpanelService: MixpanelService
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group<IPrviLoginMojaFirmaCommand>({
      pravniOblik: new FormControl(null, {
        validators: [Validators.required, Validators.min(1)],
      }),
      uSistemuPDVa: new FormControl(null, {
        validators: [Validators.required],
      }),
      stopaPDV: new FormControl(null, {
        validators: [Validators.required],
      }),
      razlogPdvIzuzecaId : new FormControl(null,{
        validators : [Validators.nullValidator]
      }),
      oznakaPdvKategorije : new FormControl(null,{
        validators : [Validators.nullValidator]
      })
    });
  }

  handleSave() {
    if (this.form.invalid) {
      this.form.controls.pravniOblik.markAsDirty();
      this.form.controls.uSistemuPDVa.markAsDirty();
      return;
    }

    this._sub = this._client
      .prviLoginMojaFirma(this.form.value as PrviLoginMojaFirmaCommand)
      .subscribe((res) => {
        this._mixpanelService.useAndTermsSubmitted();
        this._alertService.addSuccessMsg(res.data);
        this._coreService.setJePrviLoginKompanije = false;
      });
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
