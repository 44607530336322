<!-- Template za joyride step 1 za kreiranje ulazne fakture(tutorial button) -->
<ng-template #step1UlazneFaktureCreate>
  <p>Kliknemo na dugme "Unesi"</p>
</ng-template>

<p-toolbar styleClass="p-mb-2">
  <ng-template pTemplate="left">
    <p class="_header">{{ tableConfig.tableHeader }}</p>
    <!-- <p class="_subheader" *ngIf="tableConfig.headerDescription">
                                              {{ tableConfig.headerDescription }}
                                            </p> -->
  </ng-template>

  <ng-template pTemplate="right">
    <div *ngIf="singleActions?.length > 0">
      <span *ngFor="let action of singleActions" class="p-mr-2">
        <span
          *ngIf="action.joyrideStep"
          class="joyride-step"
          [joyrideStep]="action.joyrideStep"
          [stepContent]="step1UlazneFaktureCreate"
        >
          <kodit-button
            [btnLabel]="action.label | tableActionLabel: action.type"
            [btnIcon]="action.icon | tableActionIcon: action.type"
            [btnClass]="
              action.actionClass | tableHeaderActionClass: action.type
            "
            [shouldDisableWhenSefInactive]="action.shouldDisableWhenSefInactive"
            [btnTooltip]="action.tooltip"
            [isBtnDisabled]="
              !action.isAvailable ||
              ((action.type == actionTypeMultiDelete ||
                action.type == actionTypeDropdown ||
                action.type == actionTypeMultiCustom) &&
                (!selectedItems || !selectedItems.length))
            "
            (btnClicked)="checkAction(action)"
          >
          </kodit-button>
        </span>

        <kodit-button
          *ngIf="!action.joyrideStep"
          [btnLabel]="action.label | tableActionLabel: action.type"
          [btnIcon]="action.icon | tableActionIcon: action.type"
          [btnClass]="action.actionClass | tableHeaderActionClass: action.type"
          [shouldDisableWhenSefInactive]="action.shouldDisableWhenSefInactive"
          [btnTooltip]="action.tooltip"
          [isBtnDisabled]="
            !action.isAvailable ||
            ((action.type == actionTypeMultiDelete ||
              action.type == actionTypeDropdown ||
              action.type == actionTypeMultiCustom) &&
              (!selectedItems || !selectedItems.length))
          "
          (btnClicked)="checkAction(action)"
        >
        </kodit-button>
      </span>
    </div>

    <!-- dodatne akcije -->
    <div *ngIf="dropdownActions?.length > 0">
      <span
        *ngFor="let action of dropdownActions"
        tooltipPosition="top"
        class="p-mr-2"
        [pTooltip]="action.tooltip"
      >
        <p-menu
          #menu
          [popup]="true"
          [model]="action.items"
          [appendTo]="parent"
        ></p-menu>
        <kodit-button
          [btnLabel]="action.label | tableActionLabel: action.type"
          [btnIcon]="action.icon | tableActionIcon: action.type"
          [btnClass]="action.actionClass | tableHeaderActionClass: action.type"
          (btnClicked)="toggleMenu(menu, $event)"
        >
        </kodit-button>
      </span>
    </div>

    <kodit-button
      *ngIf="tableConfig.advancedFilter"
      btnClass="p-button p-button-outlined"
      btnIcon="fas fa-sliders-h"
      (btnClicked)="showSidebar()"
      btnTooltip="Prikaži filtere"
    ></kodit-button>

    <div
      *ngIf="tableConfig.multiDeleteAction"
      [pTooltip]="
        !selectedItems || !selectedItems.length
          ? (tableConfig.multiDeleteAction | async)?.tooltip
          : 'Izbriši izabrano'
      "
    >
      <kodit-button
        btnIcon="far fa-trash-alt"
        btnClass="p-button-danger"
        [isBtnDisabled]="
          !(tableConfig.multiDeleteAction | async)?.isAvailable ||
          !selectedItems ||
          !selectedItems.length
        "
        (btnClicked)="onDelete(null)"
      >
      </kodit-button>
    </div>
  </ng-template>
</p-toolbar>

<!-- <div class="p-mb-5">
  <kodit-divider></kodit-divider>
</div> -->

<kodit-advanced-filter
  [advancedFilterItem]="advancedFilterItem"
></kodit-advanced-filter>
