import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor() {}

  shouldReset$ = new BehaviorSubject<boolean>(false);
  get getShouldReset() {
    return this.shouldReset$.asObservable();
  }
  set setShouldReset(data: boolean) {
    this.shouldReset$.next(data);
  }

  maxAttachmentSize$ = new BehaviorSubject<number>(5000000);
  get getMaxAttachmentSize() {
    return this.maxAttachmentSize$.asObservable();
  }

  set setMaxAttachmentSize(data: number) {
    this.maxAttachmentSize$.next(data);
  }

  attachments$ = new BehaviorSubject<File[]>([]);
  get getAttachments() {
    return this.attachments$.asObservable();
  }

  set setAttachments(data: File[]) {
    this.attachments$.next(data);
  }
}
