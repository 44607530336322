<div class="p-d-flex p-flex-column gap-12">
  <div class="p-d-flex p-flex-row">
    <div class="label">Kompanija:</div>
    <div class="p-ml-2 value">{{ softwareInformation?.kompanija }}</div>
  </div>
  <div class="p-d-flex p-flex-row">
    <div class="label">Adresa:</div>
    <div class="p-ml-2 value">{{ softwareInformation?.adresa }}</div>
  </div>
  <div class="p-d-flex p-flex-row">
    <div class="label">Postanski broj i grad:</div>
    <div class="p-ml-2 value">{{ softwareInformation?.postanskiBroj }}, {{ softwareInformation?.grad }}</div>
  </div>
  <div class="p-d-flex p-flex-row">
    <div class="label">PIB:</div>
    <div class="p-ml-2 value">{{ softwareInformation?.pib }}</div>
    <div class="label p-ml-4">MBR:</div>
    <div class="p-ml-2 value">{{ softwareInformation?.mbr }}</div>
  </div>
  <div class="p-d-flex p-flex-row">
    <div class="label">eKompanija:</div>
    <div class="p-ml-2 value">{{ softwareVersion }}</div>
  </div>
  <div class="p-d-flex p-flex-row">
    <div class="label">eKompanija ESIR:</div>
    <div class="p-ml-2 value">{{ softwareInformation?.esir }}</div>
  </div>
  <div class="p-d-flex p-flex-row">
    <div class="label">Istorija izmena:</div>
    <div class="p-ml-2 value"><a href="https://ekompanija.gitbook.io/ekompanija-istorija-izmena" class="link">{{softwareVersion}} | eKompanija - Istorija izmena</a></div>
  </div>
</div>
