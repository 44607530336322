<kodit-field>
  <p-editor
    id="napomena_kompenzacija"
    [formControl]="napomenaCtrl"
    [style]="{ height: '150px' }"
  >
    <ng-template pTemplate="header">
        <span class="ql-formats">
          <button type="button" class="ql-bold" aria-label="Bold"></button>
          <button type="button" class="ql-italic" aria-label="Italic"></button>
        </span>
    </ng-template>
  </p-editor>
  <kodit-error-info-max-length
    [formCtrl]="napomenaCtrl"
  ></kodit-error-info-max-length>
</kodit-field>
