<div
  class="p-d-flex p-flex-column p-flex-md-row p-ai-center gap-25 p-mb-4"
  *ngIf="chipItems.length > 0"
>
  <span style="font-weight: 600">Aktivni filteri:</span>
  <div class="p-d-flex p-flex-column p-flex-md-row p-flex-wrap gap-12">
    <p-chip
      *ngFor="let item of chipItems; let i = index"
      [attr.data-index]="i"
      [label]="item.value"
      styleClass="p-mr-2"
      [removable]="true"
      (onRemove)="onRemoveChip(item)"
    ></p-chip>
  </div>
</div>
