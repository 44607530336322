import { FormControl, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IKonfiguracijaDeviznogRacunaDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class KonfiguracijaDeviznogRacunaFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetKonfiguracijaDeviznogRacunaFormGroup(
    model?: IKonfiguracijaDeviznogRacunaDto
  ): FormGroupTypeSafe<IKonfiguracijaDeviznogRacunaDto> {
    return this._fb.group<IKonfiguracijaDeviznogRacunaDto>({
      accountName: new FormControl(model?.accountName, {
        validators: [Validators.maxLength(256)],
      }),
      accountAddress: new FormControl(model?.accountAddress, {
        validators: [Validators.maxLength(256)],
      }),
      bank: new FormControl(model?.bank, {
        validators: [Validators.maxLength(128)],
      }),
      iban: new FormControl(model?.iban, {
        validators: [Validators.maxLength(33)],
      }),
      swift: new FormControl(model?.swift, {
        validators: [Validators.maxLength(50)],
      }),
    });
  }
}
