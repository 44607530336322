import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  IAvansnaUplataRacunaTableDto,
  VrstaRacuna,
} from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class AvansnaUplataRacunaFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetAvansneUplateRacunaFormArray(
    avansneUplate: IAvansnaUplataRacunaTableDto[]
  ): FormArray {
    if (!avansneUplate || avansneUplate.length === 0) {
      return this._fb.array([]);
    }

    let result: FormArray = this._fb.array([]);
    avansneUplate.forEach((x) => {
      result.push(this.GetAvansneUplateRacunaFormGroup(x));
    });
    return result;
  }

  public GetAvansneUplateRacunaFormGroup(
    model?: IAvansnaUplataRacunaTableDto
  ): FormGroupTypeSafe<IAvansnaUplataRacunaTableDto> {
    return this._fb.group<IAvansnaUplataRacunaTableDto>({
      id: new FormControl(model?.id),
      avansniRacunBroj: new FormControl(model?.avansniRacunBroj),
      avansniRacunId: new FormControl(model?.avansniRacunId ?? 0),
      avansniRacunVrsta: new FormControl(
        model?.avansniRacunVrsta ?? VrstaRacuna.EMPTY
      ),
      avansniRacunDatumIzdavanja : new FormControl(model?.avansniRacunDatumIzdavanja ?? new Date()),
      evidentiranPdv8: new FormControl(model?.evidentiranPdv8),
      evidentiranPdv10: new FormControl(model?.evidentiranPdv10),
      evidentiranPdv20: new FormControl(model?.evidentiranPdv20),
      evidentiranaOsnovica8: new FormControl(model?.evidentiranaOsnovica8),
      evidentiranaOsnovica10: new FormControl(model?.evidentiranaOsnovica10),
      evidentiranaOsnovica20: new FormControl(model?.evidentiranaOsnovica20),
      evidentiranaOsnovicaAE10: new FormControl(model?.evidentiranaOsnovicaAE10),
      evidentiranaOsnovicaAE20: new FormControl(model?.evidentiranaOsnovicaAE20),
      evidentiranaOsnovicaE: new FormControl(model?.evidentiranaOsnovicaE),
      evidentiranaOsnovicaN: new FormControl(model?.evidentiranaOsnovicaN),
      evidentiranaOsnovicaO: new FormControl(model?.evidentiranaOsnovicaO),
      evidentiranaOsnovicaOE: new FormControl(model?.evidentiranaOsnovicaOE),
      evidentiranaOsnovicaR: new FormControl(model?.evidentiranaOsnovicaR),
      evidentiranaOsnovicaSS: new FormControl(model?.evidentiranaOsnovicaSS),
      evidentiranaOsnovicaZ: new FormControl(model?.evidentiranaOsnovicaZ),
    });
  }
}
