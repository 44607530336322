<p-sidebar
  #NavSideBar
  [showCloseIcon]="false"
  [visible]="visible"
  position="top"
  [transitionOptions]="'.4s cubic-bezier(.08,.82,.17,1)'"
  [baseZIndex]="999999"
  (onHide)="onHide()"
  (visibleChange)="onShow($event)"
  (keydown)="keyDownFunction($event)"
  blockScroll="true"
>
  <p-scrollPanel class="filter-content-wrapper" [style]="{ width: '100%', height: '89%' }">

      <div class="p-d-flex p-flex-column p-jc-between">
        <div class="p-sidebar-content">
          <ng-template advancedFilterContent></ng-template>
        </div>
      </div>

  </p-scrollPanel>

  <div class="p-d-flex p-jc-end gap-12 p-mr-4">
    <kodit-button
      btnIcon="fas fa-xmark"
      btnLabel="Poništi"
      btnTooltip="Poništite sve filtere"
      btnClass="p-button-outlined"
      (btnClicked)="onReset()"
    >
    </kodit-button>

    <kodit-button
      btnIcon="fas fa-search"
      btnLabel="Primeni"
      btnTooltip="Primenite filter"
      (btnClicked)="onSubmit()"
    >
    </kodit-button>
  </div>
</p-sidebar>
