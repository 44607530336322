import { Injectable } from '@angular/core';
import { BaseService, Mode, SharedService } from '@kodit/core/services';
import {
  IStrankaRacunaDto,
  ResultOfFizickoLiceDto,
  ResultOfPravnoLiceDto,
} from '@kodit/core/data-api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StrankeService extends BaseService {
  strankaModel: ResultOfPravnoLiceDto | ResultOfFizickoLiceDto;

  constructor() {
    super();
  }

  //#region BehaviorSubjects

  /**
   * Stranka model
   */
  strankaModel$ = new BehaviorSubject<
    ResultOfPravnoLiceDto | ResultOfFizickoLiceDto
  >(null);
  /** @deprecated */
  get getStrankaModel() {
    return this.strankaModel$.asObservable();
  }
  /** @deprecated */
  set setStrankaModel(data: ResultOfPravnoLiceDto | ResultOfFizickoLiceDto) {
    this.strankaModel$.next(data);
  }

  strankaEditId$ = new BehaviorSubject<number>(0);
  get getStrankaEditId() {
    return this.strankaEditId$.asObservable();
  }
  set setStrankaEditId(data: number) {
    this.strankaEditId$.next(data);
  }
  //#endregion

  setStrankaModelObsolete(
    model: ResultOfPravnoLiceDto | ResultOfFizickoLiceDto
  ) {
    this.strankaModel = model;
  }
}
