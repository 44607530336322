<div [formGroup]="form" class="p-d-flex p-flex-row p-fluid">
    <div class="flex-1-1 p-mr-4">
        <kodit-field fieldLabel="Naziv" fieldLabelFor="naziv">
      <kodit-text-input
        class="show"
        [formCtrl]="form.controls.naziv"
        formCtrlId="naziv"
      ></kodit-text-input>
    </kodit-field>
    </div>
</div>
