import { Injectable } from "@angular/core";
import { IPdvEvidencijaZbirna39Dto } from "@kodit/core/data-api";
import { FormGroupTypeSafe } from "angular-typesafe-reactive-forms-helper";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class PdvEvidencijaZbirna39Service {
  pdvForm$ = new BehaviorSubject<PdvForm>(null);

  set setPdvForm(value: PdvForm) {
    this.pdvForm$.next(value);
  }

  get getPdvFormObject(): PdvForm {
    return this.pdvForm$.getValue();
  }

  resetForm() {
    this.setPdvForm = null;
  }
}

export declare type PdvForm = FormGroupTypeSafe<IPdvEvidencijaZbirna39Dto> | null;
