<div class="p-field">
  <label *ngIf="showLongLabel" for="poreski-broj" kodit-form-label
    >Poreski identifikacioni broj - PIB</label
  >
  <label *ngIf="showShortLabel" for="poreski-broj" kodit-form-label>PIB</label>
  <p-inputMask
    id="poreski-broj"
    mask="999999999"
    placeholder="999999999"
    [formControl]="control"
    [disabled]="disabled"
    [ngClass]="
      (control.dirty || control.touched) && !control.valid ? 'p-invalid' : ''
    "
    [styleClass]="
      (control.dirty || control.touched) && !control.valid ? 'p-invalid' : ''
    "
  ></p-inputMask>
  <kodit-error-info-required [formCtrl]="control"></kodit-error-info-required>
</div>
