import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'kodit-rok-vazenja-input',
  templateUrl: './rok-vazenja-input.component.html',
  styleUrls: ['./rok-vazenja-input.component.scss'],
})
export class RokVazenjaInputComponent implements OnInit {
  @Input() formCtrl: AbstractControl;
  @Input() formCtrlId: string;

  constructor() {}

  ngOnInit(): void {}
}
