import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ArhivskaKnjigaClient,
  IGrupaKategorijaDto,
} from '@kodit/core/data-api';
import { ArhivskaKnjigaService } from 'libs/core/moduli/podesavanje/src/lib/arhivska-knjiga/arhivska-knjiga-service';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-grupa-kategorija-dropdown',
  templateUrl: './grupa-kategorija-dropdown.component.html',
  styleUrls: ['./grupa-kategorija-dropdown.component.scss'],
})
export class GrupaKategorijaDropdownComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  ctrlOptions: SelectItem[] | undefined = [];
  izabraniItem: any;

  constructor(
    private _client: ArhivskaKnjigaClient,
    private _arhivskaService: ArhivskaKnjigaService
  ) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client.getGrupeKategorijeDropdown().subscribe((res) => {
        this.ctrlOptions = res.data?.kategorijeList!.map((x) => ({
          value: x.id,
          label: x.naziv,
          imported: x.jeImportovana,
        }));
        this.ctrlOptions?.unshift({ value: 0, label: 'Izaberite Kategoriju' });
      })
    );
  }

  itemChanged(event: any) {
    this._arhivskaService.setGrupaKategorijaId = event.value;
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
