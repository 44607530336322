import {Component, OnDestroy, OnInit} from '@angular/core';
import {FiskalniRacuniClient} from "@kodit/core/data-api";
import {Subscription} from "rxjs";
import {SelectItem} from "primeng/api";
import {
  SertifikatFiskalnogRacunaDropdownService
} from '../../../../../../../services/src/lib/sertifikat-fiskalnog-racuna-dropdown.service';

@Component({
  selector: 'kodit-sertifikati-fiskalnog-racuna-dropdown',
  templateUrl: './sertifikati-fiskalnog-racuna-dropdown.component.html',
  styleUrls: ['./sertifikati-fiskalnog-racuna-dropdown.component.scss']
})
export class SertifikatiFiskalnogRacunaDropdownComponent implements OnInit, OnDestroy {
  private _subs : Subscription = new Subscription();

  /** Props */
  items: SelectItem[] = [];
  selectedSertifikat! : string;

  constructor(
    private _client : FiskalniRacuniClient,
    private _sertifikatFS : SertifikatFiskalnogRacunaDropdownService) { }

  ngOnInit(): void {
    this._load();
  }

  setSertifikatToHeader(){
    this._sertifikatFS.sertifikatJid = this.selectedSertifikat;
  }

  private _load(){
    this._subs.add(
      this._client.getSertifikatiFiskalneKaseDropdown().subscribe((res)=>{
        this.items = res.data!.map((x) => ({
          value: x.jid,
          label: x.naziv,
          title: x.naziv,
        }));
        this.selectedSertifikat = this.items[0].value;
        this.setSertifikatToHeader();
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
