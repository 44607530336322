import { FormArray, FormControl } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { ICenovnikStavkaDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class CenovnikStavkeFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,

  ) {}

  public GetStavkeCenovnikaFormArray(
    stavkeCenovnika: ICenovnikStavkaDto[],
  ): FormArray {
    if (!stavkeCenovnika || stavkeCenovnika.length === 0) {
      return this._fb.array([
        this.GetCenovnikStavkaFormGroup(),
      ]);
    }

    let result: FormArray = this._fb.array([]);
    stavkeCenovnika.forEach((s) => {
      result.push(
        this.GetCenovnikStavkaFormGroup(s)
      );
    });
    return result;
  }
  
  public GetCenovnikStavkaFormGroup(
    request?: ICenovnikStavkaDto
  ): FormGroupTypeSafe<ICenovnikStavkaDto> {
    return this._fb.group<ICenovnikStavkaDto>({
      id: new FormControl(request?.id),
      sifra: new FormControl(request?.sifra),
      nazivPredmetaStavke: new FormControl(request?.nazivPredmetaStavke),
      nazivJediniceMere: new FormControl(request?.nazivJediniceMere),
      ulaznaCena: new FormControl(request?.ulaznaCena),
      prodajnaCena: new FormControl(request?.prodajnaCena),
      cenovnikId: new FormControl(request?.cenovnikId),
      predmetStavkeId: new FormControl(request?.predmetStavkeId),
    });
  }
}
