 <div
    class="preview-container"
    *ngIf="innerHtml && jePdfOrImage && selectedDokument"     
    style="height: 100vh"
    [innerHTML]="innerHtml"
></div>

<div
    *ngIf="!jePdfOrImage && selectedDokument"
    class="p-d-flex p-flex-column download-container"
>
    <div>
        Tip fajla dokumenta koji ste izabrali nije podržan za prikaz.
    </div>
    <div>
    <button
        class="p-mt-3"      
        pButton
        label="Preuzmi"
        type="button"
        icon="fa-light fa-download"
        (click)="download()"
        ></button>
    </div>
</div>