import { Injectable } from '@angular/core';
import { IGetLageriTableQuery, IPredmetStavkeFilterDto } from '@kodit/core/data-api';
import { BaseFilterService } from '@kodit/core/services';
import { AdvancedFilterActionData } from '@kodit/core/shared-ui';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LagerFilterService
  implements BaseFilterService<IGetLageriTableQuery> {
  constructor() {}

  advanceFilterSubmitted$ = new BehaviorSubject<
    AdvancedFilterActionData<IGetLageriTableQuery>
  >(null);
  get getAdvanceFilterSubmitted() {
    return this.advanceFilterSubmitted$.asObservable();
  }
  set setAdvanceFilterSubmitted(
    data: AdvancedFilterActionData<IGetLageriTableQuery>
  ) {
    this.advanceFilterSubmitted$.next(data);
  }
}
