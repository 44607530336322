import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceReportPayment } from '@kodit/core/data-api';

@Pipe({
  name: 'sumPayments',
})
export class SumPaymentsPipe implements PipeTransform {
  transform(values: InvoiceReportPayment[]): number {
    return values.reduce((acc, curr) => acc + curr.amount!, 0);
  }
}
