import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { IMobilniTelefonDto } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MobilniTelefonFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  /**
   * Pravi FormGroup od prosledeni mobilniDto
   * @param model Mobilni dto objekat za pretvaranje u FormGroup
   * @returns FormGroup od prosledenog mobilnogDto
   */
  public GetMobilniFormGroup(
    model?: IMobilniTelefonDto,
    isRequired: boolean = false
  ): FormGroupTypeSafe<IMobilniTelefonDto> {
    const form = this._fb.group<IMobilniTelefonDto>({
      pozivniBroj: new FormControl(model?.pozivniBroj ?? '+381'),
      brojTelefona: new FormControl(model?.brojTelefona),
    });

    this.SetMobilniTelefonValidators(form, isRequired);

    return form;
  }

  SetMobilniTelefonValidators(form: FormGroup, isRequired: boolean) {
    form.controls.brojTelefona.setValidators([
      isRequired ? Validators.required : Validators.nullValidator,
      Validators.maxLength(15),
      Validators.pattern(/^6[0-9]+$/),
      Validators.pattern(/^(\+381)?6[0-9]{0,14}$/),
    ]);

    form.controls.pozivniBroj.setValidators(
      isRequired ? Validators.required : Validators.nullValidator
    );

    form.controls.brojTelefona.updateValueAndValidity();
    form.controls.pozivniBroj.updateValueAndValidity();
  }
}
