<div class="p-d-flex p-flex-row p-flex-md-column page-container card">
  <div class="p-d-flex">
    <div
      class="posto-30 p-d-flex p-jc-between p-ai-center border-right border-bottom"
    >
      <div class="_header">
        <div class="title">Datoteke</div>
      </div>
      <div>
        <button
          pButton
          type="button"
          icon="fas fa-plus"
          (click)="menu.toggle($event)"
        ></button>
        <p-menu #menu [popup]="true" [model]="items"></p-menu>
      </div>
    </div>
    <div class="posto-70 p-d-flex p-jc-between p-ai-center border-bottom">
      <div class="search-bar">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input type="text" pInputText placeholder="Traži">
        </span>
      </div>

      <div class="filter-dugme">
        <button
          pButton
          type="button"
          icon="fa-solid fa-sliders"
          style="background-color: white; color: #0F67D1;"
        ></button>
    </div>
    </div>
  </div>

  <div class="p-d-flex flex-grow">
    <div class="posto-30 p-d-flex p-flex-column p-jc-between folders">
      <div>
        <kodit-datoteka-tree></kodit-datoteka-tree>
      </div>
      <div>
        <kodit-dostupan-prostor-card></kodit-dostupan-prostor-card>
      </div>
    </div>
    <div class="posto-70 p-d-flex dokument-preview p-jc-center">
      <kodit-folder-preview *ngIf="jeFolder" class="p-d-flex p-flex-column"></kodit-folder-preview>
      <kodit-dokument-preview *ngIf="jeDokument"></kodit-dokument-preview>
    </div>
  </div>
</div>
