import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[kodit-left-side-label]',
})
export class LeftSideLabelDirective implements OnInit {
  @Input() labelWidth: string;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.setStyle(
      this.elRef.nativeElement,
      'width',
      this.labelWidth ?? '250px'
    );
    this.renderer.setStyle(this.elRef.nativeElement, 'font-weight', '600');
  }
}
