import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[kodit-form-label]',
})
export class FormLabelDirective implements OnInit {
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    //this.renderer.setStyle(this.elRef.nativeElement, 'font-weight', '600');
    this.renderer.addClass(this.elRef.nativeElement, 'field-label');
  }
}
