import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  IKeyValuePairOfStringAndString,
  KeyValuePairOfStringAndString,
  KorisniciClient,
} from '@kodit/core/data-api';
import { KorisnickaGrupaFormService } from '@kodit/core/form-definitions';
import { areArraysEqual } from '@kodit/core/shared';
import { AbstractControlTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

interface OvlascenjePoGrupama {
  [grupa: string]: { key: string; value: string }[];
}

@Component({
  selector: 'kodit-biranje-ovlascenja-from',
  templateUrl: './biranje-ovlascenja-from.component.html',
  styleUrls: ['./biranje-ovlascenja-from.component.scss'],
})
export class BiranjeOvlascenjaFromComponent implements OnInit, OnDestroy {
  /** Props */
  private _subs: Subscription = new Subscription();
  grupe: OvlascenjePoGrupama[] = [];
  selectedValues: IKeyValuePairOfStringAndString[];

  /** I/O */
  @Input() ovlascenjeDtoFromArray: AbstractControlTypeSafe<
    KeyValuePairOfStringAndString[]
  >;

  constructor(
    private _client: KorisniciClient,
    private _fs: KorisnickaGrupaFormService
  ) {}

  ngOnInit(): void {
    this._load();
    this._subs.add(
      this.ovlascenjeDtoFromArray.valueChanges.subscribe((res) => {
        if (res.length > 0) {
          this.selectedValues = res;
        }
      })
    );
  }

  handleCheckboxChange(event: any) {
    (this.ovlascenjeDtoFromArray as FormArray).clear();
    this.selectedValues.forEach((x) => {
      (this.ovlascenjeDtoFromArray as FormArray).push(
        this._fs.GetOvlascenjeDtoFormGroup(x)
      );
    });
    this.ovlascenjeDtoFromArray.updateValueAndValidity();
  }

  private _load() {
    this._subs.add(
      this._client.getOvlascenja().subscribe((res) => {
        let currentGrupa: string;
        let currentValues: IKeyValuePairOfStringAndString[];
        for (let i = 0; i < res.data.ovlascenjeDtoListPoGrupi.length; ++i) {
          currentGrupa = res.data.ovlascenjeDtoListPoGrupi[i]['groupName'];
          currentValues = [];
          for (
            let j = 0;
            j < res.data.ovlascenjeDtoListPoGrupi[i]['persmissions'].length;
            ++j
          ) {
            currentValues.push({
              key:
                res.data.ovlascenjeDtoListPoGrupi[i]['persmissions'][j]['key'],
              value:
                res.data.ovlascenjeDtoListPoGrupi[i]['persmissions'][j][
                  'value'
                ],
            });
          }
          this.grupe[currentGrupa] = currentValues;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
