<div class="p-d-flex p-flex-column gap-12 p-fluid">
  <div class="even-columns gap-12 p-flex-md-row">
    <kodit-field fieldLabel="Account name" fieldLabelFor="account-name">
      <kodit-text-input
        formCtrlId="account-name"
        [formCtrl]="podaciUplateDevizniFormGroup.controls.accountName"
      ></kodit-text-input>
    </kodit-field>
    <kodit-field fieldLabel="Account address" fieldLabelFor="account-address">
      <kodit-text-input
        formCtrlId="account-address"
        [formCtrl]="podaciUplateDevizniFormGroup.controls.accountAddres"
      ></kodit-text-input>
    </kodit-field>
  </div>

  <div class="even-columns gap-12 p-flex-md-row">
    <kodit-field fieldLabel="Bank" fieldLabelFor="bank">
      <kodit-text-input
        formCtrlId="bank"
        [formCtrl]="podaciUplateDevizniFormGroup.controls.bank"
      >
      </kodit-text-input>
    </kodit-field>
    <kodit-field fieldLabel="IBAN" fieldLabelFor="iban">
      <kodit-text-input
        formCtrlId="iban"
        [formCtrl]="podaciUplateDevizniFormGroup.controls.iban"
      ></kodit-text-input>
    </kodit-field>
    <kodit-field fieldLabel="SWIFT" fieldLabelFor="swift">
      <kodit-text-input
        formCtrlId="swift"
        [formCtrl]="podaciUplateDevizniFormGroup.controls.swift"
      >
      </kodit-text-input>
    </kodit-field>
  </div>
</div>
