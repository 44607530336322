<div [formGroup]="form">
  <kodit-card-container>
    <div card-body class="p-d-flex p-flex-column">
      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <div class="flex-50">
          <kodit-field
            fieldLabelFor="povezani-dokument"
            fieldLabel="Broj dokumenta"
            class="flex-50"
          >
            <kodit-dokument-for-pdv-autocomplete
              [dokumentCtrl]="form.controls.dokument!"
              formCtrlId="povezani-dokument"
              [shouldDisable]="shouldDisableForm"
            ></kodit-dokument-for-pdv-autocomplete>
          </kodit-field>
        </div>

        <div *ngIf="!shouldDisableForm" class="flex-50 page-description-badge">
          <p-message
            severity="info"
            text="Unesite broj fakture u polje 'Broj povezanog dokumenta' i ostali podaci će automatski biti popunjeni"
          ></p-message>
        </div>
      </div>
      <kodit-divider></kodit-divider>

      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabel="Broj Pojedinačne evidencije PDV"
          fieldLabelFor="pravilo-za-broj"
          class="flex-50"
        >
          <kodit-text-input [formCtrl]="form.controls.broj" [isDisabled]="isKoriguj || shouldDisableForm"></kodit-text-input>
        </kodit-field>

        <kodit-field fieldLabelFor="smer" fieldLabel="Promet" class="flex-50">
          <kodit-vrsta-racuna-dropdown
            [formCtrl]="form.controls.dokument!.get('smer')!"
            [shouldDisable]="shouldDisableForm"
          ></kodit-vrsta-racuna-dropdown>
        </kodit-field>
      </div>

      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <div class="flex-50">
          <kodit-field fieldLabelFor="tip" fieldLabel="Tip dokumenta">
            <kodit-tip-dokumenta-evidencije-dropdown
              [formCtrl]="form.controls.tip!"
              formCtrlId="tip"
              [shouldDisable]="shouldDisableForm"
            >
            </kodit-tip-dokumenta-evidencije-dropdown>
          </kodit-field>
        </div>
        <div class="flex-50">
          <kodit-field
            fieldLabel="Godina"
            fieldLabelFor="godina"
            class="flex-50"
          >
            <kodit-number-input
              formCtrlId="godina"
              [formCtrl]="form.controls.godina"
              [ctrlMaxLength]="4"
              [ctrlMinValue]="1900"
              [ctrlOnlyIntegers]="true"
              [ctrlUseGrouping]="false"
              [isDisabled]="shouldDisableForm"
            ></kodit-number-input>
          </kodit-field>
        </div>
      </div>

      <div
        *ngIf="isDrugiInterniObracun"
        class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid"
      >
        <kodit-field
          fieldLabelFor="osnov-odabira-dokumenta"
          fieldLabel="Osnov odabira dokumenta"
          class="flex-50"
        >
          <kodit-osnov-odabira-dokumenta-dropdown
            formCtrlId="osnov-odabira-dokumenta"
            [formCtrl]="form.controls.osnovOdabiraDokumenta"
            [shouldDisable]="shouldDisableForm"
          ></kodit-osnov-odabira-dokumenta-dropdown>
        </kodit-field>
        <div class="flex-50"></div>
      </div>

      <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
        <kodit-field
          fieldLabelFor="vremenski-opseg"
          fieldLabel="Poreski period"
          class="flex-50"
        >
          <kodit-pdv-opseg-dropdown
            formCtrlId="vremenski-opseg"
            [periodValue]="form.value.period!"
            [shouldDisable]="shouldDisableForm"
          ></kodit-pdv-opseg-dropdown>
        </kodit-field>
        <kodit-field fieldLabelFor="period" fieldLabel="Period" class="flex-50">
          <kodit-pdv-period-dropdown
            formCtrlId="period"
            [formCtrl]="form.controls.period"
            [shouldDisable]="shouldDisableForm"
          ></kodit-pdv-period-dropdown>
        </kodit-field>
      </div>
    </div>
  </kodit-card-container>
  <div class="p-mt-5">
    <kodit-card-container>
      <div card-title>Podaci stranke</div>

      <div
        card-body
        class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid"
      >
        <kodit-field
          fieldLabelFor="stranka"
          fieldLabel="Stranka"
          class="flex-50"
        >
          <kodit-stranka-for-pdv-autocomplete
            [strankaCtrl]="form.controls.stranka!"
            formCtrlId="stranka"
            [shouldDisable]="shouldDisableForm"
          ></kodit-stranka-for-pdv-autocomplete>
        </kodit-field>
        <kodit-field
          fieldLabelFor="stranka-id"
          fieldLabel="Identifikator lica kojem se vrši promet (PIB/ PIB i JBKJS/ Poreski broj stranog lica)"
          class="flex-50"
        >
          <kodit-text-input
            formCtrlId="stranka-id"
            [formCtrl]="form.controls.stranka!.get('pibOrJbkjs')!"
            [isDisabled]="shouldDisableForm"
          ></kodit-text-input>
        </kodit-field>
      </div>
    </kodit-card-container>
  </div>
  <div class="p-mt-5">
    <kodit-card-container>
      <div card-title>Povezane avansne fakture</div>

      <div card-body>
        <kodit-racun-autocomplete
          formCtrlId="izvorna-faktura"
          [includeTipoveRacuna]="useOnlyTipove"
          (onChange)="handleVezaniRacunChange($event)"
          [shouldDisable]="shouldDisableForm"
        ></kodit-racun-autocomplete>
        <kodit-povezani-avansni-racuni
          formCtrlId="povezaniDokumenti"
          [form]="form"
          [shouldDisable]="shouldDisableForm"
        >
        </kodit-povezani-avansni-racuni>
      </div>
    </kodit-card-container>
  </div>

  <div class="p-mt-5">
    <kodit-card-container>
      <div card-title>Promet</div>

      <div card-body class="p-d-flex p-flex-column">
        <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
          <kodit-field
            fieldLabelFor="datum-valute"
            fieldLabel="Datum naplate/plaćanja avansa"
            class="flex-50"
          >
            <kodit-calendar
              formCtrlId="datum-valute"
              [formCtrl]="form.controls.datumValute!"
              [disabled]="shouldDisableForm"
            ></kodit-calendar>
          </kodit-field>
          <kodit-field
            fieldLabelFor="datum-prometa"
            fieldLabel="Datum evidentiranja"
            class="flex-50"
          >
            <kodit-calendar
              [disabled]="isKoriguj"
              formCtrlId="datum-prometa"
              [formCtrl]="form.controls.datumPrometa!"
              [disabled]="shouldDisableForm"
            ></kodit-calendar>
          </kodit-field>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
          <kodit-field
            fieldLabelFor="opis20"
            fieldLabel="Opis prometa po stopi od 20%"
            class="flex-50"
          >
            <kodit-textarea-input
              formCtrlId="opis20"
              [formCtrl]="form.controls.opisPrometa20"
              [isDisabled]="shouldDisableForm"
            ></kodit-textarea-input>
          </kodit-field>

          <kodit-field
            fieldLabelFor="opis10"
            fieldLabel="Opis prometa po stopi od 10%"
            class="flex-50"
          >
            <kodit-textarea-input
              formCtrlId="opis10"
              [formCtrl]="form.controls.opisPrometa10"
              [isDisabled]="shouldDisableForm"
            ></kodit-textarea-input>
          </kodit-field>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
          <kodit-field
            fieldLabelFor="osnovica20"
            fieldLabel="Osnovica po stopi od 20%"
            class="flex-50"
          >
            <kodit-number-input
              [isDisabled]="false"
              formCtrlId="osnovica20"
              [formCtrl]="form.controls.osnovica20"
              [isDisabled]="shouldDisableForm"
            ></kodit-number-input>
          </kodit-field>
          <kodit-field
            fieldLabelFor="osnovica10"
            fieldLabel="Osnovica po stopi od 10%"
            class="flex-50"
          >
            <kodit-number-input
              [isDisabled]="false"
              formCtrlId="osnovica10"
              [formCtrl]="form.controls.osnovica10"
              [isDisabled]="shouldDisableForm"
            ></kodit-number-input>
          </kodit-field>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
          <kodit-field
            fieldLabelFor="obracunatPdv20"
            fieldLabel="Obračunat PDV po stopi od 20%"
            class="flex-50"
          >
            <kodit-number-input
              [isDisabled]="false"
              formCtrlId="obracunatPdv20"
              [formCtrl]="form.controls.obracunatPdv20"
              [isDisabled]="shouldDisableForm"
            ></kodit-number-input>
          </kodit-field>
          <kodit-field
            fieldLabelFor="obracunatPdv10"
            fieldLabel="Obračunat PDV po stopi od 10%"
            class="flex-50"
          >
            <kodit-number-input
              [isDisabled]="false"
              formCtrlId="obracunatPdv10"
              [formCtrl]="form.controls.obracunatPdv10"
              [isDisabled]="shouldDisableForm"
            ></kodit-number-input>
          </kodit-field>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
          <kodit-field
            fieldLabelFor="obracunatPdv"
            fieldLabel="Ukupno obračunati PDV"
            class="flex-50"
          >
            <kodit-number-input
              [isDisabled]="false"
              formCtrlId="obracunatPdv"
              [formCtrl]="form.controls.obracunatPdv"
              [isDisabled]="shouldDisableForm"
            ></kodit-number-input>
          </kodit-field>

          <kodit-field
            fieldLabel="Ukupan iznos naknade/vrednosti"
            fieldLabelFor="ukupan-iznos-naknade"
            class="flex-50"
          >
            <kodit-number-input
              [isDisabled]="false"
              formCtrlId="ukupan-iznos-naknade"
              [formCtrl]="form.controls.ukupanIznosNaknade"
              [isDisabled]="shouldDisableForm"
            ></kodit-number-input>
          </kodit-field>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
          <div class="flex-50"></div>
        </div>
      </div>
    </kodit-card-container>
  </div>
  <div class="p-mt-5" card style="position: sticky; bottom: 0; z-index: 9">
    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-between">
      <div
        class="dodatne-akcije p-d-flex p-flex-column p-jc-around p-ml-3"
      ></div>

      <div class="primarne-akcije p-as-center p-d-flex gap-6">
        <ng-content></ng-content>
        <button
          *ngIf="shouldDisableForm"
          pButton
          pRipple
          type="button"
          label="Zatvori"
          class="p-button-secondary p-button-outlined"
          pTooltip="Zatvori"
          tooltipPosition="bottom"
          (click)="handleRouteBack()"
          [disabled]="false"
        ></button>
        <button
          *ngIf="!shouldDisableForm"
          pButton
          pRipple
          icon="far fa-window-close"
          type="button"
          label="Otkaži"
          class="p-button-secondary p-button-outlined"
          pTooltip="Otkaži PDV evidenciju"
          tooltipPosition="bottom"
          (click)="handleCancel()"
          [disabled]="false"
        ></button>
        <button
        *ngIf="!shouldDisableForm"
          pButton
          icon="fas fa-save"
          type="submit"
          label="Sačuvaj"
          class="p-button-success"
          pTooltip="Sačuvaj PDV evidenciju"
          tooltipPosition="bottom"
          (click)="handleSave()"
          [disabled]="false"
          [loading]="false"
        ></button>
      </div>
    </div>
  </div>
</div>
